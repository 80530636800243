<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="9">
        <v-row class="d-flex justify-center text-h4">
          Faça seu cadastro gratuitamente!
        </v-row>
        <v-row class="d-flex justify-center">
          <small>
            Você poderá usar o software gratuitamente. A qualquer momento é
            possível fazer upgrade ao plano PRO para obter ainda mais
            funcionalidades.
          </small>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="9">
        <!-- <v-stepper v-model="step" non-linear>
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1" editable>
              Passo 1
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2" editable>
              Passo 2
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1"> -->
        <v-form ref="form1" v-model="valid1">
          <v-container>
            <v-row class="d-flex justify-center my-4">
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  :prepend-icon="icons.mdiAccount"
                  v-model="nome"
                  :rules="rules.requiredField"
                  label="Nome"
                  required
                ></v-text-field>
              </v-col>
              <!-- </v-row>
                  <v-row class="d-flex justify-center"> -->
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  :prepend-icon="icons.mdiEmail"
                  v-model="email"
                  :rules="rules.emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="4">
                <v-alert
                  v-show="alerts.emailOk"
                  dense
                  type="error"
                  :icon="icons.mdiAlert"
                  >Email indisponível</v-alert
                >
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  :prepend-icon="icons.mdiLock"
                  v-model="senha"
                  :append-icon="show ? icons.mdiEye : icons.mdiEyeOff"
                  :type="show ? 'text' : 'password'"
                  :rules="rules.passwordLength"
                  label="Senha"
                  required
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
              <!-- </v-row>
                  <v-row class="d-flex justify-center"> -->
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  :prepend-icon="icons.mdiLock"
                  v-model="senha2"
                  :append-icon="show ? icons.mdiEye : icons.mdiEyeOff"
                  :type="show ? 'text' : 'password'"
                  :rules="rules.passwordLength"
                  label="Confirmar Senha"
                  required
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-alert
                v-if="!senhasIguais"
                dense
                type="error"
                :icon="icons.mdiAlert"
                >Senhas não iguais!</v-alert
              >
            </v-row>
          </v-container>
        </v-form>
        <v-row align="center">
          <v-col class="text-center" cols="12">
            <v-btn large color="#c5d13f" @click="submit1">Prosseguir</v-btn>
          </v-col>
        </v-row>
        <!-- </v-stepper-content>

            <v-stepper-content step="2">
              <v-row class="d-flex justify-center text-h6">
                Informações do cartão de crédito
              </v-row>
              <v-form ref="form2" v-model="valid2">
                <v-container>
                  <v-row class="d-flex justify-center my-4">
                    <v-col cols="12" md="6">
                      <v-text-field
                        solo
                        :prepend-icon="icons.mdiAccount"
                        v-model="nomeNoCartao"
                        :rules="rules.requiredField"
                        label="Nome no cartão"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center my-4">
                    <v-col cols="12" md="6">
                      <v-text-field
                        solo
                        :prepend-icon="icons.mdiCreditCardSearchOutline"
                        v-model="numeroCartao"
                        :rules="rules.numbersOnly"
                        label="Número do cartão"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" md="2">
                      <v-select
                        :items="itemsMes"
                        label="Mês"
                        v-model="mes"
                        required
                        solo
                      ></v-select>
                    </v-col>
                    <v-col cols="4" md="2">
                      <v-select
                        :items="itemsAno"
                        label="Ano"
                        v-model="ano"
                        required
                        solo
                      ></v-select
                    ></v-col>
                    <v-col cols="4" md="2">
                      <v-text-field
                        :rules="rules.numbersOnly"
                        label="CVC"
                        v-model="cvc"
                        required
                        solo
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </v-container>
              </v-form>
              <v-row align="center">
                <v-col class="text-center" cols="12">
                  <v-btn large color="#c5d13f" @click="submit2"
                    >Prosseguir</v-btn
                  >
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiAccount,
  mdiWeb,
  mdiLock,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
  mdiStore,
  mdiAlert,
  mdiCreditCardSearchOutline,
} from "@mdi/js";
// import router from "../../router";

export default {
  name: "cadastro",
  data: function () {
    return {
      step: 1,
      numeroCartao: null,
      nomeNoCartao: "",
      mes: null,
      ano: null,
      cvc: null,
      itemsMes: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      itemsAno: [
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
      ],
      icons: {
        mdiWeb,
        mdiLock,
        mdiEmail,
        mdiEye,
        mdiEyeOff,
        mdiStore,
        mdiAccount,
        mdiAlert,
        mdiCreditCardSearchOutline,
      },
      show: false,
      planos: ["TESTE", "BASIC", "PRO"],
      plano: "",
      nome: "",
      email: "",
      // nomeClinica: "",
      // nomeSite: "",
      senha: "",
      senha2: "",
      valid1: false,
      valid2: false,
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
        passwordLength: [(v) => v.length >= 8 || "Senha com mais que 8 letras"],
        siteRules: [
          (v) => v.length <= 24 || "Nome com menos que 24 letras",
          (v) => !/[^A-Za-z0-9]+/.test(v) || "Use apenas letras e números",
        ],
        emailRules: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+/.test(v) || "E-mail invalido",
        ],
        numbersOnly: [(v) => !/[^0-9]+/.test(v) || "Apenas números"],
        // numbersOnly: [(v) => !!v || "Apenas números"],
      },
    };
  },
  computed: {
    alerts: function () {
      return this.$store.state.firestore.alerts;
    },
    senhasIguais: function () {
      return this.senha == this.senha2;
    },
  },
  methods: {
    submit1: function () {
      if (this.$refs.form1.validate()) {
        // Ausstehend: dispatch userJoin ans Ende vom stepper
        this.$store.dispatch("firestore/userJoin", {
          email: this.email,
          password: this.senha,
          // nomeClinica: this.nomeClinica,
          // nomeSite: this.nomeSite,
          nome: this.nome,
          // plano
        });
        // if (this.plano != "TESTE") {
        //   this.$store.dispatch("firestore/acquirePlano", {
        //     planoToAcquire: this.plano,
        //   });
        // }
        this.step = 2;
      }
    },
    // submit2: function () {
    //   if (this.$refs.form2.validate()) {
    //     this.$store.dispatch("firestore/userJoin", {
    //       email: this.email,
    //       password: this.senha,
    //       // nomeClinica: this.nomeClinica,
    //       // nomeSite: this.nomeSite,
    //       nome: this.nome,
    //       // plano
    //     });
    //     // if (this.plano != "TESTE") {
    //     //   this.$store.dispatch("firestore/acquirePlano", {
    //     //     planoToAcquire: this.plano,
    //     //   });
    //     // }

    //     // router.replace("/home");
    //   }
    // },
  },
  mounted() {},
};
</script>