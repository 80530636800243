<template>
  <div>
    <v-dialog v-model="showDialog" max-width="800">
      <v-card v-if="!saved && !saving">
        <v-row class="d-flex justify-center" v-if="!editMode">
          <v-img :src="empresaToShow.logo" contain height="72"></v-img>
        </v-row>
        <v-card-title v-if="!editMode">
          <v-row class="d-flex justify-center">{{ empresaToShow.name }}</v-row>
        </v-card-title>
        <v-card-title class="d-flex justify-center" v-if="editMode">
          <v-text-field
            label="Nome da empresa"
            v-model="empresaToShow.name"
          ></v-text-field>
        </v-card-title>
        <v-card-text v-if="!editMode">
          <v-row class="d-flex justify-center">
            <strong>Descrição curta: </strong>{{ empresaToShow.descr }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Endereço: </strong>{{ empresaToShow.endereco }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Telefone: </strong>{{ empresaToShow.telefone }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Resumo da vantagem: </strong
            >{{ empresaToShow.vantagemResumo }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Vantagem: </strong>{{ empresaToShow.vantagem }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Cidade: </strong>{{ empresaToShow.cidade }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Ramo de atividade: </strong>{{ empresaToShow.atividade }}
          </v-row>
        </v-card-text>
        <v-card-text v-if="editMode">
          <v-row class="d-flex justify-center">
            <v-file-input
              accept="image/*"
              label="Logomarca"
              @change="handleLogomarca"
            ></v-file-input
          ></v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              label="Desrição curta"
              v-model="empresaToShow.descr"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              label="Endereço"
              v-model="empresaToShow.endereco"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              label="Telefone"
              v-model="empresaToShow.telefone"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              label="Resumo da vantagem"
              v-model="empresaToShow.vantagemResumo"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              label="Vantagem"
              v-model="empresaToShow.vantagem"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              label="Cidade"
              v-model="empresaToShow.cidade"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              label="Ramo de atividade"
              v-model="empresaToShow.atividade"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red"
                v-bind="attrs"
                v-on="on"
                class="mr-2 mx-2 black--text"
                icon
                @click="wantDeleteEmpresa(empresa)"
              >
                <v-icon color="red">mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>Deletar empresa</span>
          </v-tooltip>
          <v-btn @click="editMode = true" v-if="!editMode" class="mx-1">
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

          <v-btn
            color="#614021"
            @click="showDialog = false"
            text
            class="mx-1"
            v-if="!editMode"
          >
            Fechar
          </v-btn>
          <v-btn @click="editMode = false" v-if="editMode" color="grey">
            Parar edição
          </v-btn>

          <v-btn @click="saveEmpresaEdit" v-if="editMode" color="#c5d13f">
            <v-icon left> mdi-content-save </v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="saving && !saved">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-center"> Salvando ... </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else-if="saved && !saving">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
          <v-row class="d-flex justify-center"> Salvo! </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="fecharRecarregar"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newEmpresaDialog" max-width="800">
      <v-card v-if="!saved && !saving">
        <v-card-title> Adicionar Empresa Parceira </v-card-title>
        <v-card-text>
          <v-form ref="newForm">
            <v-row class="d-flex justify-center">
              <v-text-field
                label="Nome da empresa"
                v-model="empresaToAdd.name"
              ></v-text-field>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-file-input
                accept="image/*"
                label="Logomarca"
                @change="handleLogomarca"
              ></v-file-input
            ></v-row>
            <v-row class="d-flex justify-center">
              <v-text-field
                label="Desrição curta"
                v-model="empresaToAdd.descr"
              ></v-text-field>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-text-field
                label="Endereço"
                v-model="empresaToAdd.endereco"
              ></v-text-field>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-text-field
                label="Telefone"
                v-model="empresaToAdd.telefone"
              ></v-text-field>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-text-field
                label="Resumo da vantagem"
                v-model="empresaToAdd.vantagemResumo"
              ></v-text-field>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-text-field
                label="Vantagem"
                v-model="empresaToAdd.vantagem"
              ></v-text-field>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-text-field
                label="Cidade"
                v-model="empresaToAdd.cidade"
              ></v-text-field>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-text-field
                label="Ramo de atividade"
                v-model="empresaToAdd.atividade"
              ></v-text-field>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            @click="newEmpresaDialog = false"
            text
            class="mx-2"
          >
            Fechar
          </v-btn>
          <v-btn color="#c5d13f" class="mx-2" @click="saveEmpresaNew">
            <v-icon color="#614021" left>mdi-content-save-outline</v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="saving && !saved">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-center"> Salvando ... </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else-if="saved && !saving">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
          <v-row class="d-flex justify-center"> Salvo! </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="fecharRecarregar"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="800">
      <v-card v-if="!deleted">
        <v-card-title>Deletar {{ empresaToDelete.name }} ?</v-card-title>
        <v-card-text
          >Tem certeza que quer tirar a empresa "{{ empresaToDelete.name }}" da
          lista do Clube de Vantagens dde forma irrevogável ?</v-card-text
        >
        <v-card-actions>
          <v-btn text @click="deleteDialog = false"> fechar </v-btn>
          <v-btn @click="deleteEmpresa">deletar empresa </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>"{{ empresaToDelete.name }}" deletado!</v-card-title>
        <v-card-actions>
          <v-btn text @click="deleteDialog = false"> fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title>
        <v-row class="d-flex justify-space-between text-h6">
          <!-- <v-col cols="9">  -->
          Clube de Vantagens
          <!-- </v-col>
          <v-col cols="3"> -->
          <v-btn color="#c5d13f" @click="newEmpresaDialog = true">
            <v-icon color="#614021">mdi-plus-circle-outline</v-icon>
            Adicionar Empresa Parceira
          </v-btn>
          <!-- </v-col> -->
        </v-row>
        <!-- <v-row>
          <v-col cols="6" md="3">
            <v-checkbox
              label="Filtrar por Cidade"
              v-model="filtrarPorCidade"
              @change="checkFilters"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" md="3">
            <v-autocomplete
              :items="cidades"
              :disabled="!filtrarPorCidade"
              v-model="cityFilter"
              @change="filterByCity(cityFilter)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3">
            <v-checkbox
              label="Filtrar por Atividade"
              v-model="filtrarPorAtividade"
              @change="checkFilters"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" md="3">
            <v-autocomplete
              :items="atividades"
              :disabled="!filtrarPorAtividade"
              v-model="activityFilter"
              @change="filterByActivity(activityFilter)"
            ></v-autocomplete>
          </v-col>
        </v-row> -->
      </v-card-title>
      <v-card-text v-if="empresasPorCidadePorAtividade.length > 0">
        <v-card
          v-for="(cityList, cityListIndex) in empresasPorCidadePorAtividade"
          :key="cityListIndex"
          outlined
          color="#d8b18d"
          class="my-1"
        >
          <v-card-title class="font-weight-regular text-body-1">
            Cidade: <strong>{{ cityList[0][0].cidade }}</strong>
          </v-card-title>
          <v-card-text>
            <v-card
              v-for="(subList, subListIndex) in cityList"
              :key="subListIndex"
              outlined
              color="#d9e184"
              class="my-1"
            >
              <v-card-title class="font-weight-regular text-body-1">
                Ramo de atividade: <strong>{{ subList[0].atividade }}</strong>
              </v-card-title>
              <v-card
                v-for="empresa in subList"
                :key="empresa.id"
                outlined
                class="mx-1 my-1"
                color="white"
              >
                <v-card-text>
                  <v-row class="d-flex align-center">
                    <v-col cols="2" class="d-flex justify-center">
                      <v-img :src="empresa.logo" contain height="36"></v-img>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-start">
                      <strong>{{ empresa.name }} : </strong>
                      {{ empresa.vantagemResumo }}
                    </v-col>
                    <!-- </v-row>
                  <v-row class="d-flex justify-center"> -->
                    <!-- <v-col cols="10">
                      {{ empresa.vantagemResumo }}
                    </v-col> -->
                    <v-col cols="2">
                      <v-btn @click="show(empresa)" color="#c5d13f">
                        Ver mais
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- <v-card-actions>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2 mx-2 black--text"
                        icon
                        @click="editEmpresa(empresa)"
                      >
                        <v-icon color="black">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar empresa</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2 mx-2 black--text"
                        icon
                        @click="wantDeleteEmpresa(empresa)"
                      >
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar empresa</span>
                  </v-tooltip>
                </v-card-actions> -->
              </v-card>
            </v-card>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <v-progress-circular
          :size="50"
          :width="5"
          color="#614021"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";
// import router from "../router";
import { mdiPlaylistPlus, mdiEye } from "@mdi/js";
export default {
  data: function () {
    return {
      logomarca: null,
      normal: true,
      showDialog: false,
      deleteDialog: false,
      editMode: false,
      empresaToDelete: {
        logo: "",
        id: "",
        name: "",
        descr: "",
        vantagemResumo: "",
        vantagem: "",
        cidade: "",
        atividade: "",
        endereco: "",
        telefone: "",
      },
      empresaToShow: {
        logo: "",
        id: "",
        name: "",
        descr: "",
        vantagemResumo: "",
        vantagem: "",
        cidade: "",
        atividade: "",
        endereco: "",
        telefone: "",
      },
      empresaToAdd: {
        logo: "",
        id: "",
        name: "",
        descr: "",
        vantagemResumo: "",
        vantagem: "",
        cidade: "",
        atividade: "",
        endereco: "",
        telefone: "",
      },
      deleted: false,
      saved: false,
      saving: false,
      newEmpresaDialog: false,
      icons: {
        mdiPlaylistPlus,
        mdiEye,
      },
      empresasPorCidadePorAtividade: [],
      cidades: [],
      atividades: [],
      filtrarPorAtividade: false,
      filtrarPorCidade: false,
      activityFilter: null,
      cityFilter: null,
    };
  },
  computed: {
    empresas() {
      return this.$store.state.firestore.clubeDeVantagens;
    },
  },
  methods: {
    // checkFilters() {
    //   var filterCidade = function (city, empresas) {
    //     if (city !== null) {
    //       var empresasCity = empresas.filter(function (el) {
    //         if (el.cidade.length > 0) {
    //           return el.cidade == city;
    //         } else {
    //           return false;
    //         }
    //       });
    //       return empresasCity;
    //     } else {
    //       return empresas;
    //     }
    //   };
    //   var filterAtividade = function (activity, empresasOfCity) {
    //     if (activity !== null) {
    //       var empresasActivity = empresasOfCity.filter(function (el) {
    //         if (el.atividade.length > 0) {
    //           return el.atividade == activity;
    //         } else {
    //           return false;
    //         }
    //       });
    //       return empresasActivity;
    //     } else {
    //       return empresasOfCity;
    //     }
    //   };

    //   var empresas = this.empresas;
    //   if (this.filtrarPorCidade && !this.filtrarPorAtividade) {
    //     this.atividades = this.empresas.map(function (empresa) {
    //       return empresa.atividade;
    //     });
    //     var empresasPorCidadePorAtividade1 = this.cidades.map(function (city) {
    //       var allOfCity = filterCidade(city, empresas);
    //       var atividadesOfCity = allOfCity.map(function (empresa) {
    //         return empresa.atividade;
    //       });
    //       return atividadesOfCity.map(function (activity) {
    //         return filterAtividade(activity, allOfCity);
    //       });
    //     });
    //     this.empresasPorCidadePorAtividade = empresasPorCidadePorAtividade1;
    //     console.log(this.empresasPorCidadePorAtividade);
    //   }

    //   if (this.filtrarPorAtividade && !this.filtrarPorCidade) {
    //     this.cidades = this.empresas.map(function (empresa) {
    //       return empresa.cidade;
    //     });
    //     var atividadesOfCity1 = this.atividades;
    //     var empresasPorCidadePorAtividade2 = this.cidades.map(function (city) {
    //       var allOfCity = filterCidade(city, empresas);
    //       return atividadesOfCity1.map(function (activity) {
    //         return filterAtividade(activity, allOfCity);
    //       });
    //     });
    //     this.empresasPorCidadePorAtividade = empresasPorCidadePorAtividade2;
    //     console.log(this.empresasPorCidadePorAtividade);
    //   }

    //   if (this.filtrarPorCidade && this.filtrarPorAtividade) {
    //     var atividadesOfCity2 = this.atividades;
    //     var empresasPorCidadePorAtividade3 = this.cidades.map(function (city) {
    //       var allOfCity = filterCidade(city, empresas);
    //       return atividadesOfCity2.map(function (activity) {
    //         return filterAtividade(activity, allOfCity);
    //       });
    //     });
    //     this.empresasPorCidadePorAtividade = empresasPorCidadePorAtividade3;
    //     console.log(this.empresasPorCidadePorAtividade);
    //   }

    //   if (!this.filtrarPorAtividade && !this.filtrarPorCidade) {
    //     this.fillWithAll();
    //     console.log(this.empresasPorCidadePorAtividade);
    //   }
    // },
    // filterByCity(city) {
    //   var empresas = this.empresas;
    //   var cidades;
    //   if (this.filtrarPorCidade) {
    //     cidades = [city];
    //   } else {
    //     cidades = this.empresas.map(function (empresa) {
    //       return empresa.cidade;
    //     });
    //   }
    //   this.cidades = cidades;

    //   var filterCidade = function (city, empresas) {
    //     if (city !== null) {
    //       var empresasCity = empresas.filter(function (el) {
    //         if (el.cidade.length > 0) {
    //           return el.cidade == city;
    //         } else {
    //           return false;
    //         }
    //       });
    //       return empresasCity;
    //     } else {
    //       return empresas;
    //     }
    //   };
    //   var filterAtividade = function (activity, empresasOfCity) {
    //     if (activity !== null) {
    //       var empresasActivity = empresasOfCity.filter(function (el) {
    //         if (el.atividade.length > 0) {
    //           return el.atividade == activity;
    //         } else {
    //           return false;
    //         }
    //       });
    //       return empresasActivity;
    //     } else {
    //       return empresasOfCity;
    //     }
    //   };

    //   var empresasPorCidadePorAtividade = cidades.map(function (city) {
    //     var allOfCity = filterCidade(city, empresas);
    //     var atividadesOfCity = allOfCity.map(function (empresa) {
    //       return empresa.atividade;
    //     });
    //     return atividadesOfCity.map(function (activity) {
    //       return filterAtividade(activity, allOfCity);
    //     });
    //   });
    //   this.empresasPorCidadePorAtividade = empresasPorCidadePorAtividade;
    //   this.atividades = empresasPorCidadePorAtividade.map(function (empresa) {
    //     return empresa.atividade;
    //   });
    // },
    // filterByActivity(activity) {
    //   var empresas = this.empresas;
    //   var cidades = this.cidades;
    //   var atividades;
    //   if (this.filtrarPorAtividade) {
    //     atividades = [activity];
    //   } else {
    //     atividades = this.empresas.map(function (empresa) {
    //       return empresa.atividade;
    //     });
    //   }
    //   this.atividades = atividades;

    //   var filterCidade = function (city, empresas) {
    //     if (city !== null) {
    //       var empresasCity = empresas.filter(function (el) {
    //         if (el.cidade.length > 0) {
    //           return el.cidade == city;
    //         } else {
    //           return false;
    //         }
    //       });
    //       return empresasCity;
    //     } else {
    //       return empresas;
    //     }
    //   };
    //   var filterAtividade = function (activity, empresasOfCity) {
    //     if (activity !== null) {
    //       var empresasActivity = empresasOfCity.filter(function (el) {
    //         if (el.atividade.length > 0) {
    //           return el.atividade == activity;
    //         } else {
    //           return false;
    //         }
    //       });
    //       return empresasActivity;
    //     } else {
    //       return empresasOfCity;
    //     }
    //   };

    //   var empresasPorCidadePorAtividade = cidades.map(function (city) {
    //     var allOfCity = filterCidade(city, empresas);
    //     // var atividadesOfCity = allOfCity.map(function (empresa) {
    //     //   return empresa.atividade;
    //     // });
    //     return atividades.map(function (activity) {
    //       return filterAtividade(activity, allOfCity);
    //     });
    //   });
    //   this.empresasPorCidadePorAtividade = empresasPorCidadePorAtividade;
    //   this.cidades = empresasPorCidadePorAtividade.map(function (empresa) {
    //     return empresa.cidade;
    //   });
    // },
    filterCidade(city) {
      if (city !== null) {
        var empresasCity = this.empresas.filter(function (el) {
          if (el.data.cidade.length > 0) {
            return el.data.cidade == city;
          } else {
            return false;
          }
        });
        return empresasCity;
      } else {
        return this.empresas;
      }
    },
    handleLogomarca(file) {
      this.logomarca = file;
    },
    filterAtividade(activity) {
      if (activity !== null) {
        var empresasActivity = this.empresas.filter(function (el) {
          if (el.data.atividade.length > 0) {
            return el.data.atividade == activity;
          } else {
            return false;
          }
        });
        return empresasActivity;
      } else {
        return this.empresas;
      }
    },
    fillWithAll() {
      var cidades = this.empresas.map(function (empresa) {
        return empresa.cidade;
      });
      cidades = cidades.filter(function (city, pos) {
        return cidades.indexOf(city) == pos;
      })
      this.cidades = cidades;

      var atividades = this.empresas.map(function (empresa) {
        return empresa.atividade;
      });
      this.atividades = atividades;

      var empresas = this.empresas;

      var filterCidade = function (city, empresas) {
        if (city !== null) {
          var empresasCity = empresas.filter(function (el) {
            return el.cidade == city;
          });
          return empresasCity;
        } else {
          return empresas;
        }
      };
      var filterAtividade = function (activity, empresasOfCity) {
        if (activity !== null) {
          var empresasActivity = empresasOfCity.filter(function (el) {
            if (el.atividade.length > 0) {
              return el.atividade == activity;
            } else {
              return false;
            }
          });
          return empresasActivity;
        } else {
          return empresasOfCity;
        }
      };
      var empresasPorCidadePorAtividade = cidades.map(function (city) {
        var allOfCity = filterCidade(city, empresas);
        var atividadesOfCity = allOfCity.map(function (empresa) {
          return empresa.atividade;
        });
        return atividadesOfCity.map(function (activity) {
          return filterAtividade(activity, allOfCity);
        });
      });
      this.empresasPorCidadePorAtividade = empresasPorCidadePorAtividade;
    },
    async saveEmpresaEdit() {
      // console.log(alimento.data)
      this.saving = true;

      var infos = {};
      if (this.logomarca !== null) {
        console.log("Enviando logomarca!");
        var nutri = sessionStorage.getItem("current-user");
        var d = new Date();
        var millis = d.getTime();
        await firebase
          .storage()
          .ref("logos")
          .child(nutri.toString() + "_" + millis.toString() + "_logomarca")
          .put(this.logomarca)
          .then(async (snapshot) => {
            infos.logomarca = await snapshot.ref.getDownloadURL();
            console.log(infos.logomarca);
          });

        this.empresaToShow.logo = infos.logomarca;
      } else {
        infos.logomarca = null;
      }

      await firebase
        .firestore()
        // .collection("users")
        // .doc(sessionStorage.getItem("current-user"))
        .collection("clubeDeVantagens")
        .doc(this.empresaToShow.id)
        .update(this.empresaToShow);
      this.saving = false;
      this.saved = true;
      this.logomarca = null;
    },
    async saveEmpresaNew() {
      this.saving = true;

      var newIdRef = firebase
        .firestore()
        // .collection("users")
        // .doc(sessionStorage.getItem("current-user"))
        .collection("clubeDeVantagens")
        .doc();

      var newId = newIdRef.id;
      this.empresaToAdd.id = newId;

      var infos = {};
      if (this.logomarca !== null) {
        console.log("Enviando logomarca!");
        var nutri = sessionStorage.getItem("current-user");
        var d = new Date();
        var millis = d.getTime();
        await firebase
          .storage()
          .ref("logos")
          .child(nutri.toString() + "_" + millis.toString() + "_logomarca")
          .put(this.logomarca)
          .then(async (snapshot) => {
            infos.logomarca = await snapshot.ref.getDownloadURL();
            console.log(infos.logomarca);
          });

        this.empresaToAdd.logo = infos.logomarca;
      } else {
        infos.logomarca = null;
        this.empresaToAdd.logo = null;
      }

      firebase
        .firestore()
        // .collection("users")
        // .doc(sessionStorage.getItem("current-user"))
        .collection("clubeDeVantagens")
        .doc(newId)
        .set(this.empresaToAdd);
      this.saving = false;
      this.saved = true;
      this.logomarca = null;
    },
    wantDeleteEmpresa(empresa) {
      this.empresaToDelete = empresa;
      this.deleteDialog = true;
    },
    deleteEmpresa() {
      firebase
        .firestore()
        // .collection("users")
        // .doc(sessionStorage.getItem("current-user"))
        .collection("clubeDeVantagens")
        .doc(this.empresaToDelete.id)
        .delete()
        .then(function () {
          console.log("deleted");
        })
        .catch(function (error) {
          console.log(error);
        });
      this.$store.dispatch("firestore/getClubeDeVantagens");
      this.deleted = true;
    },
    show(empresa) {
      this.empresaToShow = empresa;
      this.showDialog = true;
    },
    save() {
      this.saving = false;
      this.saved = true;
    },
    fecharRecarregar() {
      this.$store.dispatch("firestore/getClubeDeVantagens");
      this.showDialog = false;
      this.newEmpresaDialog = false;
      this.saved = false;
    },
  },
  mounted() {
    this.$store.dispatch("firestore/getClubeDeVantagens");
    setTimeout(() => {
      this.fillWithAll();
    }, 2000);
  },
};
</script>