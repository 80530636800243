<template>
  <div>
    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="wrongData" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Preenche corretamente o formulário
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon large color="red">mdi-close-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="visualizarFuncionarioDialog" max-width="600">
      <v-card>
        <v-card-title v-if="!editavel && !adicao"
          >Dados do funcionário</v-card-title
        >
        <v-card-title v-else-if="editavel && !adicao"
          >Editar funcionário</v-card-title
        >
        <v-card-title v-else-if="adicao && !editavel"
          >Adicionar funcionário</v-card-title
        >
        <v-card-text>
          <v-row class="d-flex justify-center mx-4">
            <v-autocomplete
              :items="papeis"
              v-model="funcionario.papel"
              label="Cargo"
              :readonly="!editavel && !adicao"
            ></v-autocomplete>
          </v-row>
          <v-row class="d-flex justify-center mx-4">
            <v-text-field
              v-model="funcionario.nome"
              label="Nome"
              :readonly="!editavel && !adicao"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center mx-4">
            <v-text-field
              v-model="funcionario.senha"
              label="Senha"
              :readonly="!editavel && !adicao"
            ></v-text-field>
          </v-row>
          <v-row
            class="d-flex justify-center mx-4"
            v-if="funcionario.assinatura !== ''"
          >
            <v-img
              :src="funcionario.assinatura"
              max-width="100"
              max-height="100"
              class="my-3 mx-3"
            ></v-img>
          </v-row>

          <v-row class="text-subtitle-1 mx-4" v-else>
            <v-col cols="12">
              <v-file-input
                accept="image/*"
                label="Assinatura digital"
                @change="handleAssinatura"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="text-subtitle-1 mx-4" v-if="funcionario.assinatura !== '' && editavel">
            <v-col cols="12">
              <v-file-input
                accept="image/*"
                label="Trocar assinatura digital"
                @change="handleAssinatura"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mx-4">
            <v-text-field
              v-model="funcionario.crn"
              label="CRN"
              :readonly="!editavel && !adicao"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="d-flex justify-center"
          v-if="!editavel && !adicao"
        >
          <v-btn
            @click="visualizarFuncionarioDialog = false"
            color="#614021"
            class="white--text"
            >Fechar</v-btn
          >
        </v-card-actions>
        <v-card-actions
          class="d-flex justify-center"
          v-else-if="editavel && !adicao"
        >
          <v-btn
            @click="visualizarFuncionarioDialog = false"
            color="#614021"
            class="white--text"
            >Fechar</v-btn
          >
          <v-btn @click="saveOverrideFuncionario" color="#c5d13f"
            >Salvar</v-btn
          ></v-card-actions
        >
        <v-card-actions
          class="d-flex justify-center"
          v-else-if="adicao && !editavel"
        >
          <v-btn
            @click="visualizarFuncionarioDialog = false"
            color="#614021"
            class="white--text"
            >Fechar</v-btn
          >
          <v-btn @click="saveAsNewFuncionario" color="#c5d13f"
            >Salvar</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="deletarDialog" max-width="600">
      <v-card>
        <v-card-title>Deletar um funcionário</v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center mx-4">
            Quer deletar este funcionário de forma irrevogável?
          </v-row>
          <v-row class="d-flex justify-center mx-4">
            <v-text-field
              v-model="funcionario.papel"
              label="Cargo"
              readonly
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center mx-4">
            <v-text-field
              v-model="funcionario.nome"
              label="Nome"
              readonly
            ></v-text-field>
          </v-row>
          <v-row
            class="d-flex justify-center mx-4"
            v-if="funcionario.assinatura !== ''"
          >
            <v-img
              :src="funcionario.assinatura"
              max-width="100"
              max-height="100"
              class="my-3 mx-3"
            ></v-img>
          </v-row>

          <v-row class="d-flex justify-center text-subtitle-1 mx-4" v-else>
            sem assinatura
          </v-row>
          <v-row class="d-flex justify-center mx-4">
            <v-text-field
              v-model="funcionario.crn"
              label="CRN"
              readonly
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            @click="deletarDialog = false"
            color="#614021"
            class="white--text"
            >Fechar</v-btn
          ><v-btn
            @click="deleteFuncionarioNow(funcionario)"
            color="red"
            class="white--text"
            >Deletar Funcionário</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="mx-4 d-flex justify-center text-h5" v-if="isFranquia">
      Dados da franquia
    </v-row>
    <v-row class="mx-4 d-flex justify-center text-h5" v-else>
      Dados da clínica
    </v-row>
    <v-form ref="form" v-model="valid" v-if="isFranquia">
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-text-field
              :prepend-icon="icons.mdiAccount"
              v-model="userInfos.name"
              :rules="rules.requiredField"
              label="nome da clínica"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-text-field
              :prepend-icon="icons.mdiShieldAccount"
              v-model="userInfos.cnpj"
              :rules="rules.requiredField"
              label="CNPJ"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiPhoneLock"
              v-model="userInfos.phone"
              :rules="rules.requiredField"
              label="Telefone para contato interno"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiPhoneIncoming"
              v-model="userInfos.phoneClients"
              :rules="rules.requiredField"
              label="Telefone para clientes"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-text-field
              :prepend-icon="icons.mdiDirections"
              v-model="userInfos.addressRoad"
              :rules="rules.requiredField"
              label="Endereço"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-text-field
              :prepend-icon="icons.mdiMapMarker"
              v-model="userInfos.addressComplement"
              :rules="rules.requiredField"
              label="Complemento"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiCrosshairs"
              v-model="userInfos.addressCep"
              :rules="rules.requiredField"
              label="CEP"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiHomeCity"
              v-model="userInfos.addressBairro"
              :rules="rules.requiredField"
              label="Bairro"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiMapSearchOutline"
              v-model="userInfos.addressCity"
              :rules="rules.requiredField"
              label="Cidade"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiFlag"
              v-model="userInfos.addressCountry"
              :rules="rules.requiredField"
              label="País"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-form ref="form_nF" v-model="valid_nF" v-else>
      <v-container>
        <div class="d-flex justify-center">
          <v-avatar width="50%" height="50%" class="pr-2">
            <img :src="userInfos.profilePicture" aspect-ratio="1" height="" />
          </v-avatar>
        </div>
        <v-file-input
          accept="image/*"
          label="Logomarca da clínica"
          :prepend-icon="icons.mdiImage"
          @change="handlePicture"
        ></v-file-input>
        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-text-field
              :prepend-icon="icons.mdiAccount"
              v-model="userInfos.name"
              :rules="rules.requiredField"
              label="nome da clínica"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-text-field
              :prepend-icon="icons.mdiShieldAccount"
              v-model="userInfos.cnpj"
              :rules="rules.requiredField"
              label="CNPJ"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiPhoneLock"
              v-model="userInfos.phone"
              :rules="rules.requiredField"
              label="Telefone para contato interno"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiPhoneIncoming"
              v-model="userInfos.phoneClients"
              :rules="rules.requiredField"
              label="Telefone para clientes"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-text-field
              :prepend-icon="icons.mdiDirections"
              v-model="userInfos.addressRoad"
              :rules="rules.requiredField"
              label="Endereço"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-text-field
              :prepend-icon="icons.mdiMapMarker"
              v-model="userInfos.addressComplement"
              :rules="rules.requiredField"
              label="Complemento"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiCrosshairs"
              v-model="userInfos.addressCep"
              :rules="rules.requiredField"
              label="CEP"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiHomeCity"
              v-model="userInfos.addressBairro"
              :rules="rules.requiredField"
              label="Bairro"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiMapSearchOutline"
              v-model="userInfos.addressCity"
              :rules="rules.requiredField"
              label="Cidade"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiFlag"
              v-model="userInfos.addressCountry"
              :rules="rules.requiredField"
              label="País"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-row class="d-flex justify-center">
      <v-btn large color="#c5d13f" @click="submit" class="mt-4 mb-8"
        >Salvar / Atualizar</v-btn
      >
    </v-row>
    <v-divider></v-divider>

    <v-row class="my-4 d-flex justify-center text-h5"> Funcionários </v-row>

    <v-card
      v-for="funcionario in funcionarios"
      :key="funcionario.papel"
      outlined
      class="my-2 mx-2"
    >
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex justify-space-between">
          <v-row class="text-subtitle-1 ml-2 d-flex align-center">
            {{ funcionario.papel }}, {{ funcionario.nome }}
          </v-row>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#614021"
                v-bind="attrs"
                v-on="on"
                class="mr-2 mx-2 black--text"
                icon
                @click="visualizarFuncionario(funcionario)"
              >
                <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
              </v-btn>
            </template>
            <span>Visualizar funcionário</span>
          </v-tooltip>

          <v-btn
            color="#614021"
            class="mr-2 mx-2 black--text"
            @click="editarFuncionario(funcionario)"
            text
          >
            <v-icon color="#c5d13f" left>{{ icons.mdiAccountEdit }}</v-icon>
            Editar
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red"
                v-bind="attrs"
                v-on="on"
                class="mr-2 mx-2 black--text"
                icon
                @click="deleteFuncionario(funcionario)"
              >
                <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
              </v-btn>
            </template>
            <span>Deletar funcionário</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="my-2 d-flex justify-center">
      <v-btn text @click="adicaoDialog">
        <v-icon left color="#c5d13f">mdi-plus-circle-outline</v-icon>
        adicionar funcionário
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import {
  mdiClipboardAccount,
  mdiShieldAccount,
  mdiPhoneLock,
  mdiPhoneIncoming,
  mdiDirections,
  mdiMapMarker,
  mdiHomeCity,
  mdiCrosshairs,
  mdiMapSearchOutline,
  mdiFlag,
  mdiImage,
  mdiAccount,
  mdiEye,
  mdiAccountEdit,
  mdiTrashCanOutline,
} from "@mdi/js";

import firebase from "firebase";

export default {
  data: function () {
    return {
      papeis: [
        "Nutricionista 1",
        "Nutricionista 2",
        "Nutricionista 3",
        "Recepcionista",
        "Esteticista",
        "Administradora",
        "Proprietária",
        "Estagiária",
      ],
      visualizarFuncionarioDialog: false,
      adicao: false,
      editavel: false,
      saved: false,
      progressDialog: false,
      deletarDialog: false,
      wrongData: false,
      funcionario: {},
      userInfos: {},
      profilePicture: "",
      icons: {
        mdiClipboardAccount,
        mdiShieldAccount,
        mdiPhoneLock,
        mdiPhoneIncoming,
        mdiDirections,
        mdiMapMarker,
        mdiHomeCity,
        mdiCrosshairs,
        mdiMapSearchOutline,
        mdiFlag,
        mdiImage,
        mdiAccount,
        mdiEye,
        mdiAccountEdit,
        mdiTrashCanOutline,
      },
      show: false,
      valid: false,
      valid_nF: false,
      useLogo: false,
      pictureFile: null,
      assinaturaDigital: null,
      logo: null,
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
      },
    };
  },
  firestore() {
    return {
      userInfos: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user")),
    };
  },
  computed: {
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    alerts: function () {
      return this.$store.state.firestore.alerts;
    },
    funcionarios() {
      return this.$store.state.firestore.funcionarios;
    },
  },
  methods: {
    async submit() {
      this.saved = false;
      if (this.isFranquia) {
        this.progressDialog = true;
        if (this.$refs.form.validate()) {
          this.$store.dispatch("firestore/setUserPersonalData_noPic", {
            userInfos: this.userInfos,
            userEmail: sessionStorage.getItem("current-user"),
            // userPictureUrl:
            //   infos.userPictureUrl == null ? "" : infos.userPictureUrl,
            // userLogoUrl: infos.userLogoUrl == null ? "" : infos.userLogoUrl,
            // userSignatureUrl:
            //   infos.userSignatureUrl == null ? "" : infos.userSignatureUrl,
            // useLogo: this.useLogo,
          });
          this.saved = true;
        } else {
          this.wrongData = true;
        }
      } else {
        if (this.$refs.form_nF.validate()) {
          this.progressDialog = true;
          var infos = {};
          console.log(this.pictureFile);
          if (this.pictureFile !== null) {
            console.log("Enviando imagem!!");
            await firebase
              .storage()
              .ref("profileImages")
              .child(sessionStorage.getItem("current-user"))
              .put(this.pictureFile)
              .then(async (snapshot) => {
                infos.userPictureUrl = await snapshot.ref.getDownloadURL();
                console.log(infos.userPictureUrl);
              });

            // if (this.assinaturaDigital !== null) {
            //   console.log("Enviando assinatura");
            //   await firebase
            //     .storage()
            //     .ref("assinaturasDigitais")
            //     .child(sessionStorage.getItem("current-user") + "_assinatura")
            //     .put(this.assinaturaDigital)
            //     .then(async (snapshot) => {
            //       infos.userSignatureUrl = await snapshot.ref.getDownloadURL();
            //       console.log(infos.userSignatureUrl);
            //     });
            // }

            console.log(infos);
            this.$store.dispatch("firestore/setUserPersonalData", {
              userInfos: this.userInfos,
              userEmail: sessionStorage.getItem("current-user"),
              userPictureUrl:
                infos.userPictureUrl == null ? "" : infos.userPictureUrl,
              // userSignatureUrl:
              //   infos.userSignatureUrl == null ? "" : infos.userSignatureUrl,
            });
          } else {
            this.$store.dispatch("firestore/setUserPersonalData_noPic", {
              userInfos: this.userInfos,
              userEmail: sessionStorage.getItem("current-user"),
            });
          }
          this.saved = true;
          this.progressDialog = true;
        } else {
          this.wrongData = true;
        }
      }
    },
    handlePicture(file) {
      this.pictureFile = file;
    },
    handleAssinatura(file) {
      this.assinaturaDigital = file;
    },
    getFuncionarios() {
      this.$store.dispatch("firestore/getFuncionarios", {
        uId: sessionStorage.getItem("current-user"),
      });
    },
    async saveOverrideFuncionario() {
      this.saved = false;
      var d = new Date();
      var millis = d.getTime().toString();

      this.progressDialog = true;

      var infos = {};
      if (this.assinaturaDigital !== null) {
        console.log("Enviando assinatura");
        await firebase
          .storage()
          .ref("assinaturasDigitais")
          .child(
            sessionStorage.getItem("current-user") + "_assinatura_" + millis
          )
          .put(this.assinaturaDigital)
          .then(async (snapshot) => {
            infos.userSignatureUrl = await snapshot.ref.getDownloadURL();
            console.log(infos.userSignatureUrl);
          });
      }

      var id = this.funcionario.id;

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("funcionarios")
        .doc(id)
        .update({
          papel: this.funcionario.papel,
          crn: this.funcionario.crn,
          assinatura:
            infos.userSignatureUrl == null ? "" : infos.userSignatureUrl,
          nome: this.funcionario.nome,
          senha: this.funcionario.senha
        });

      this.saved = true;
      this.visualizarFuncionarioDialog = false;
    },
    adicaoDialog() {
      this.saved = false;
      this.funcionario = {
        papel: "",
        assinatura: "",
        nome: "",
        crn: "",
        senha: ""
      };
      this.visualizarFuncionarioDialog = true;
      this.editavel = false;
      this.adicao = true;
    },
    visualizarFuncionario(funcionario) {
      this.visualizarFuncionarioDialog = true;
      this.editavel = false;
      this.adicao = false;
      this.funcionario = funcionario;
    },
    editarFuncionario(funcionario) {
      this.saved = false;
      this.visualizarFuncionarioDialog = true;
      this.editavel = true;
      this.adicao = false;
      this.funcionario = funcionario;
    },
    deleteFuncionario(funcionario) {
      this.saved = false;
      this.deletarDialog = true;
      this.funcionario = funcionario;
    },
    async deleteFuncionarioNow(funcionario) {
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("funcionarios")
        .doc(funcionario.id)
        .delete();

      this.deletarDialog = false;
      this.getFuncionarios();
    },
    async saveAsNewFuncionario() {
      this.saved = false;
      var d = new Date();
      var millis = d.getTime().toString();

      this.progressDialog = true;

      var infos = {};
      if (this.assinaturaDigital !== null) {
        console.log("Enviando assinatura");
        await firebase
          .storage()
          .ref("assinaturasDigitais")
          .child(
            sessionStorage.getItem("current-user") + "_assinatura_" + millis
          )
          .put(this.assinaturaDigital)
          .then(async (snapshot) => {
            infos.userSignatureUrl = await snapshot.ref.getDownloadURL();
            console.log(infos.userSignatureUrl);
          });
      }

      var ref = firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("funcionarios")
        .doc();

      var id = ref.id;

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("funcionarios")
        .doc(id)
        .set({
          papel: this.funcionario.papel,
          crn: this.funcionario.crn,
          assinatura:
            infos.userSignatureUrl == null ? "" : infos.userSignatureUrl,
          nome: this.funcionario.nome,
          id: id,
          senha: this.funcionario.senha
        });

      this.getFuncionarios();
      this.saved = true;
      this.visualizarFuncionarioDialog = false;
    },
  },
  mounted() {
    this.funcionario = this.funcionarios[0];
    // this.getFuncionarios();

    // setTimeout(() => {
    //   this.getFuncionarios();
    // }, 1000);

    window.onbeforeunload = function () {
      return "";
    };
  },
};
</script>