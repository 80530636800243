<template>
  <v-app-bar>
    <v-img
      :src="logo"
      contain
      max-height="60"
      max-width="180"
    ></v-img>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          class="mx-2 white--text"
          color="#c5d13f"
          v-bind="attrs"
          v-on="on"
          href="https://www.clubedanutricionista.com.br/franquia"
        >FRANQUIA</v-chip>
      </template>
      <span>Seja Franqueado!</span>
    </v-tooltip>
    <v-spacer></v-spacer>

    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1"
          value="agenda"
          v-bind="attrs"
          v-on="on"
          to="/AgendaPaciente"
          color="#c5d13f"
        >
          <v-icon
            color="#614021"
            left
          >{{ icons.mdiCalendar }}</v-icon>Agenda
        </v-btn>
      </template>
      <span>Agenda</span>
    </v-tooltip> -->
    <v-spacer></v-spacer>

    <v-btn
      class="mx-1 white--text"
      color="#614021"
      value="logout"
      to="/"
      @click="logout"
    >
      <span>Logout</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {
  mdiCalendar,
  mdiMagnify,
  mdiAccountPlus,
  mdiAccountGroup,
  mdiAccountHeartOutline,
  mdiCardAccountDetailsOutline,
} from "@mdi/js";

import firebase from "firebase";
import router from "../../router";

export default {
  data: function () {
    return {
      logo: null,
      userInfos: {},
      showPlanosDialog: false,
      pacientesMouse: false,
      icons: {
        mdiCalendar,
        mdiAccountPlus,
        mdiMagnify,
        mdiAccountGroup,
        mdiAccountHeartOutline,
        mdiCardAccountDetailsOutline,
      },
    };
  },
  firestore() {
    return {
      userInfos: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user")),
    };
  },
  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(function () {
          router.replace("/LoginParceiro");
        })
        .catch(function (error) {
          console.log(error);
        });
      sessionStorage.setItem('current-user', null);
      sessionStorage.removeItem('isAuthenticated');
    },
  },
  mounted() {
    this.logo = require("@/assets/logoCompleto.png");
  }
  // mounted() {
  //   if (this.$store.state.firestore.pacientes.length === 0) {
  //     this.$store.dispatch("firestore/getPacientes");
  //   }
  // }
};
</script>

<style scoped>
</style>


