<template>
  <div>
    <v-row>
      <v-card
        color="#614021"
        elevation="5"
        width="100%"
        height="36"
        class="d-flex justify-center align-center text-h6 white--text"
      >
        <v-icon color="#c5d13f">mdi-calendar</v-icon> ESTÉTICA
      </v-card>
    </v-row>
    <!-- <v-row class="d-flex justify-center">
      <v-btn
        class="mx-2"
        color="#c5d13f"
        @click="createJsonString"
        label="Exportar"
      >
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-textarea
        solo
        readonly
        :value="jsonString"
        label="Copy the content below"
      ></v-textarea>
    </v-row> -->
    <v-page style="heigth: 100%">
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Hoje
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typesPt[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Dia</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mês</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 dias</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600" @mousemove="hourInfoMouseFollow">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              locale="pt"
              first-time="08:00"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @click:time="openEventForm"
              @mousemove:time="getCalendarDayTime"
              @mousedown:event="startDrag"
              @mouseup:event="endDrag"
              @change="getEvents"
            >
              <template v-slot:event="{ event, eventSummary }">
                <div class="v-event-draggable" v-html="eventSummary()">
                  <!-- <template v-slot:day-body="{ date, week }">
                    <v-chip
                      id="DateChip"
                      class="v-current-time"
                      :class="{ first: date === week[0].date }"
                      :style="{ width: 100 + 'px' }"
                    >
                      Hora: {{ currentTime }}
                    </v-chip>
                  </template> -->
                </div>
              </template>
            </v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon @click="editEvent = !editEvent">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="deleteEvent(selectedEvent)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <div v-if="editEvent == false">
                    <v-row class="d-flex justify-center">
                      <p v-if="selectedEvent.newPatient">
                        <strong>Paciente novo:</strong>
                        {{ selectedEvent.patient }}
                      </p>
                      <div v-else>
                        <p>{{ selectedEvent.patient }}</p>
                        <v-btn
                          class="mx-2"
                          dense
                          small
                          color="grey"
                          @click="seeInMeusPacientes(selectedEvent.patientId)"
                          >Ver histórico</v-btn
                        >
                      </div>
                    </v-row>
                    <v-row class="d-flex justify-center mt-4">
                      <p>Obs.: {{ selectedEvent.details }}</p>
                    </v-row>
                    <v-row class="d-flex justify-center mt-4">
                      <v-checkbox
                        class=""
                        v-model="naoCompareceu"
                        color="red"
                        @change="
                          makeNaoCompareceu(selectedEvent, naoCompareceu)
                        "
                        label="Não compareceu"
                      ></v-checkbox>
                    </v-row>
                  </div>
                  <div v-else>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Tipo de atendimento"
                            v-model="selectedEvent.name"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="selectedEvent.niceDateShort"
                            :items="dias"
                            menu-props="auto"
                            label="Data *"
                            hide-details
                            prepend-icon="mdi-calendar"
                            single-line
                            @change="changeStartEnd"
                          ></v-select>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            v-model="selectedEvent.startTime"
                            :items="horarios"
                            menu-props="auto"
                            label="Horário de inicio *"
                            hide-details
                            prepend-icon="mdi-clock-time-four-outline"
                            single-line
                            @change="changeStartEnd"
                          ></v-select>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            v-model="selectedEvent.endTime"
                            :items="horarios"
                            menu-props="auto"
                            label="Horário de termino *"
                            hide-details
                            prepend-icon="mdi-clock-time-four-outline"
                            single-line
                            @change="changeStartEnd"
                          ></v-select>
                        </v-col>

                        <v-col
                          cols="3"
                          v-if="
                            selectedEvent.name !== 'BONUS' &&
                              selectedEvent.name !==
                                'Avaliação com a Esteticista' &&
                              selectedEvent.name !==
                                'Sessão de LIP./HIB. (Giftcard)'
                          "
                        >
                          <v-checkbox
                            v-model="selectedEvent.newPatient"
                            color="#614021"
                            @click="patient = ''"
                            label="Paciente novo"
                          ></v-checkbox>
                        </v-col>

                        <v-col
                          cols="9"
                          v-if="
                            selectedEvent.name !== 'BONUS' &&
                              selectedEvent.name !==
                                'Avaliação com a Esteticista' &&
                              selectedEvent.name !==
                                'Sessão de LIP./HIB. (Giftcard)'
                          "
                        >
                          <v-autocomplete
                            v-if="!selectedEvent.newPatient"
                            v-model="selectedEvent.patientId"
                            label="Paciente *"
                            required="true"
                            :items="pacientes"
                            no-data-text="..."
                          ></v-autocomplete>
                          <v-text-field
                            label="Paciente *"
                            v-else
                            v-model="selectedEvent.patient"
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-text-field
                            label="Observação"
                            v-model="selectedEvent.details"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    color="secondary"
                    @click="
                      selectedOpen = false;
                      editEvent = false;
                    "
                  >
                    Fechar
                  </v-btn>

                  <v-btn
                    v-if="editEvent"
                    color="secondary"
                    text
                    @click="updateEvent(selectedEvent)"
                  >
                    Salvar
                  </v-btn>
                  <div v-else></div>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Agendamento</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      :items="atendimentoTipos"
                      label="Tipo de atendimento *"
                      v-model="atendimento"
                      no-data-text="..."
                      @change="checkGetGiftcards"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" v-if="atendimento == 'BONUS'">
                    <v-autocomplete
                      :items="atendimentoTiposBonus"
                      label="Sessão de ..."
                      v-model="bonusTipo"
                      no-data-text="..."
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="niceDateShort"
                      :items="dias"
                      menu-props="auto"
                      label="Data *"
                      hide-details
                      prepend-icon="mdi-calendar"
                      single-line
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="startTime"
                      :items="horarios"
                      menu-props="auto"
                      label="Horário de inicio *"
                      hide-details
                      prepend-icon="mdi-clock-time-four-outline"
                      single-line
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="endTime"
                      :items="horarios"
                      menu-props="auto"
                      label="Horário de termino *"
                      hide-details
                      prepend-icon="mdi-clock-time-four-outline"
                      single-line
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="3"
                    v-if="atendimento !== 'Sessão de LIP./HIB. (Giftcard)'"
                  >
                    <v-checkbox
                      v-model="newPatient"
                      color="#614021"
                      @click="patient = ''"
                      label="Paciente novo"
                    ></v-checkbox>
                  </v-col>

                  <v-col
                    cols="9"
                    v-if="atendimento !== 'Sessão de LIP./HIB. (Giftcard)'"
                  >
                    <div v-if="!newPatient">
                      <v-autocomplete
                        v-model="patient"
                        label="Paciente *"
                        required="true"
                        :items="pacientes"
                        @change="pacienteSelected(patient)"
                        no-data-text="..."
                      ></v-autocomplete>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-text-field
                          label="Nome do paciente novo"
                          v-model="patient"
                          :rules="rules.requiredField"
                        ></v-text-field> </v-row
                      ><v-row>
                        <v-text-field
                          label="Email do paciente novo"
                          v-model="patientId"
                          :rules="rules.requiredField"
                        ></v-text-field>
                      </v-row>
                      <v-row
                        v-if="
                          atendimento == 'BONUS' ||
                            atendimento == 'Avaliação com a Esteticista'
                        "
                      >
                        <v-col cols="6">
                          <v-text-field
                            label="Telefone"
                            v-model="p_telefone"
                            prepend-icon="mdi-phone"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="WhatsApp"
                            v-model="p_whatsapp"
                            prepend-icon="mdi-whatsapp"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          atendimento == 'BONUS' ||
                            atendimento == 'Avaliação com a Esteticista'
                        "
                      >
                        <v-radio-group v-model="p_sexo" row>
                          <v-radio
                            color="#ff00ff"
                            label="feminino"
                            value="feminino"
                          ></v-radio>
                          <v-radio
                            color="#00ffff"
                            label="masculino"
                            value="masculino"
                          ></v-radio>
                        </v-radio-group>
                      </v-row>
                      <v-row
                        v-if="
                          atendimento == 'BONUS' ||
                            atendimento == 'Avaliação com a Esteticista'
                        "
                      >
                        <v-autocomplete
                          prepend-icon="mdi-bullseye-arrow"
                          v-model="p_objetivo"
                          :items="objetivos"
                          label="Objetivo"
                        ></v-autocomplete>
                      </v-row>
                      <v-row
                        v-if="
                          atendimento == 'BONUS' ||
                            atendimento == 'Avaliação com a Esteticista'
                        "
                      >
                        <v-autocomplete
                          prepend-icon="mdi-account-voice"
                          v-model="p_meioContato"
                          :items="meios"
                          label="Meio de contato"
                        ></v-autocomplete>
                      </v-row>
                      <v-row
                        v-if="
                          atendimento == 'BONUS' ||
                            atendimento == 'Avaliação com a Esteticista'
                        "
                      >
                        <v-text-field
                          prepend-icon="mdi-ear-hearing"
                          v-model="p_detalhesImportantes"
                          label="Detahles importantes"
                        ></v-text-field>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  v-if="atendimento == 'Sessão de LIP./HIB. (Giftcard)'"
                  class="d-flex justify-center align-center"
                >
                  <v-autocomplete
                    @change="checkAgendado"
                    :items="giftcardIds"
                    label="Código"
                    v-model="giftcardIdTyped"
                    outlined
                    style="max-width: 50%"
                  >
                  </v-autocomplete>
                  <v-icon
                    v-if="agendadoOk && giftcardIdTyped !== null"
                    color="green"
                    class="mx-1"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon
                    v-else-if="!agendadoOk && giftcardIdTyped !== null"
                    color="red"
                    class="mx-1"
                    >mdi-close-circle</v-icon
                  >
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-text-field label="Observação" v-model="details">
                  </v-text-field>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#614021" text @click="clearInfo"> Fechar </v-btn>
              <v-btn
                v-if="
                  newPatient &&
                    (atendimento == 'BONUS' ||
                      atendimento == 'Avaliação com a Esteticista')
                "
                color="#c5d13f"
                @click="addEvent"
                :disabled="savingEvent"
              >
                <v-progress-circular
                  v-if="savingEvent"
                  :size="20"
                  :width="3"
                  color="#614021"
                  indeterminate
                ></v-progress-circular>
                <span v-else>Agendar + Salvar prospecto</span>
              </v-btn>
              <v-btn
                v-else-if="
                  newPatient &&
                    (atendimento !== 'BONUS' ||
                      atendimento !== 'Avaliação com a Esteticista')
                "
                color="#c5d13f"
                @click="addEvent"
                :disabled="savingEvent"
              >
                <v-progress-circular
                  v-if="savingEvent"
                  :size="20"
                  :width="3"
                  color="#614021"
                  indeterminate
                ></v-progress-circular>
                <span v-else>Agendar + Cadastrar Paciente</span>
              </v-btn>
              <v-btn
                v-else-if="!newPatient"
                color="#c5d13f"
                @click="addEvent"
                :disabled="savingEvent"
              >
                <v-progress-circular
                  v-if="savingEvent"
                  :size="20"
                  :width="3"
                  color="#614021"
                  indeterminate
                ></v-progress-circular>
                <span v-else>Agendar</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-page>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Agenda",
  data() {
    return {
      jsonString: "",
      bonusTipo: null,
      savingEvent: false,
      atendimentoTipos: [
        "BONUS",
        "Avaliação com a Esteticista",
        "Sessão de NARL",
        "Sessão de HIBRIUS",
        "Sessão de LIPO VEGANA",
        "Sessão de MINI LIPO VEGANA",
        "Sessão de DUAL SOON",
        "Sessão de MASSAGEM REDUTORA ou DRENAGEM CORPO TODO",
        "Sessão de MASSAGEM REDUTORA ou DRENAGEM LOCAL",
        "Sessão de MASSAGEM RELAXANTE",
        "Sessão de CORRENTE RUSSA",
        "Protocolo 1 dia: HIBRIUS + MASSAGEM REDUTORA + BIONANOTECNOLOGIA",
        "Protocolo 1 dia: NARL + MASSAGEM REDUTORA + BIONANOTECNOLOGIA",
        "Sessão de LIP./HIB. (Giftcard)",
        "Sessão de REFLEXOLOGIA PODAL",
        "Sessão de REFLEXOLOGIA PODAL + ESCALDA PES",
        "Sessão de TERAPIA COM VENTOSAS",
        "Sessão de LIMPEZA DE PELE",
        "Sessão de MICROAGULHAMENTO",
        "Sessão de AURICULOTERAPIA",
        "Sessão de DRY NEEDLING",
      ],
      atendimentoTiposBonus: [
        "Sessão de NARL",
        "Sessão de HIBRIUS",
        "Sessão de DUAL SOON",
        "Sessão de MASSAGEM REDUTORA ou DRENAGEM CORPO TODO",
        "Sessão de MASSAGEM REDUTORA ou DRENAGEM LOCAL",
        "Sessão de MASSAGEM RELAXANTE",
        "Sessão de CORRENTE RUSSA",
        "Protocolo 1 dia: HIBRIUS + MASSAGEM REDUTORA + BIONANOTECNOLOGIA",
        "Protocolo 1 dia: NARL + MASSAGEM REDUTORA + BIONANOTECNOLOGIA",
        "Sessão de LIP./HIB. (Giftcard)",
        "Sessão de REFLEXOLOGIA PODAL",
        "Sessão de REFLEXOLOGIA PODAL + ESCALDA PES",
        "Sessão de TERAPIA COM VENTOSAS",
        "Sessão de LIMPEZA DE PELE",
        "Sessão de MICROAGULHAMENTO",
        "Sessão de AURICULOTERAPIA",
        "Sessão de DRY NEEDLING",
      ],
      // sessoesEsteticos: [
      //   "Sessão de NARL",
      //   "Sessão de HIBRIUS",
      //   "Sessão de DRENAGEM",
      //   "Sessão de MASSAGEM REDUTORA",
      //   "Sessão de MASSAGEM RELAXANTE",
      //   "Sessão de CORRENTE RUSSA",
      // ],
      // sessoesEsteticosValores: [65, 250, 120, 60, 120, 65],
      horarios: [
        "7:00",
        "7:15",
        "7:30",
        "7:45",
        "8:00",
        "8:15",
        "8:30",
        "8:45",
        "9:00",
        "9:15",
        "9:30",
        "9:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00",
        "16:15",
        "16:30",
        "16:45",
        "17:00",
        "17:15",
        "17:30",
        "17:45",
        "18:00",
        "18:15",
        "18:30",
        "18:45",
        "19:00",
        "19:15",
        "19:30",
        "19:45",
        "20:00",
        "20:15",
        "20:30",
        "20:45",
        "21:00",
        "21:15",
        "21:30",
        "21:45",
        "22:00",
      ],
      diasIndices: [
        -30,
        -29,
        -28,
        -27,
        -26,
        -25,
        -24,
        -23,
        -22,
        -21,
        -20,
        -19,
        -18,
        -17,
        -16,
        -15,
        -14,
        -13,
        -12,
        -11,
        -10,
        -9,
        -8,
        -7,
        -6,
        -5,
        -4,
        -3,
        -2,
        -1,
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
      ],
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
      },
      menu: null,
      menu2: null,
      dragEvent: null,
      niceDateShort: null,
      atendimento: null,
      startTime: null,
      currentTime: "",
      newPatient: false,
      details: null,
      focus: "",
      patient: "",
      patientId: "",
      selectedTime: null,
      selectedEvent: {},
      dialog: false,
      naoCompareceu: false,
      type: "week",
      typesPt: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
        "4day": "4 Dias",
      },
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedElement: null,
      selectedOpen: false,
      editEvent: false,
      events: [],
      agendadoOk: false,
      giftcardIdTyped: null,
      giftcardIds: [],
      objetivos: [
        "Emagrecer",
        "Tratar um Problema de Saúde",
        "Ganhar Massa Magra",
        "Procedimento Estético",
        "Exames",
      ],
      meios: [
        // meio de contato para agendamento
        "Ligou para telefone fixo",
        "Ligou para celular",
        "Ligou para WhatsApp",
        "Mandou mensagem no WhatsApp",
        "Mandou mensagem no Instagram",
        "Agendou direto na recepção",
      ],
      p_telefone: null,
      p_sexo: null,
      p_whatsapp: null,
      p_objetivo: null,
      p_detalhesImportantes: null,
      p_meioContato: null,
    };
  },
  computed: {
    dias() {
      if (this.selectedTime !== null) {
        var chosenDate = new Date(
          `${this.selectedTime.date} ${this.selectedTime.time}:00`
        );
        chosenDate = chosenDate.getTime();
        var list = this.diasIndices.map(function(index) {
          var newDateNr = 86400000 * index + chosenDate;
          var newDate = new Date(newDateNr);
          var diasPtBr = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayName = diasPtBr[newDate.getDay()];
          var dayStr;
          if (newDate.getDate() < 10) {
            dayStr = "0" + newDate.getDate().toString();
          } else {
            dayStr = newDate.getDate().toString();
          }
          var monthStr;
          var month = newDate.getMonth() + 1;
          if (month < 10) {
            monthStr = "0" + month.toString();
          } else {
            monthStr = month.toString();
          }
          var year = newDate.getYear() - 100;
          var yearStr = year.toString();

          return dayName + ", " + dayStr + "/" + monthStr + "/" + yearStr;
        });
        return list;
      } else if (this.selectedEvent.start !== undefined) {
        var chosenDateEvent = new Date(this.selectedEvent.start);
        chosenDateEvent = chosenDateEvent.getTime();
        var listEvent = this.diasIndices.map(function(index) {
          var newDateNr = 86400000 * index + chosenDateEvent;
          var newDate = new Date(newDateNr);
          var diasPtBr = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayName = diasPtBr[newDate.getDay()];
          var dayStr;
          if (newDate.getDate() < 10) {
            dayStr = "0" + newDate.getDate().toString();
          } else {
            dayStr = newDate.getDate().toString();
          }
          var monthStr;
          var month = newDate.getMonth() + 1;
          if (month < 10) {
            monthStr = "0" + month.toString();
          } else {
            monthStr = month.toString();
          }
          var year = newDate.getYear() - 100;
          var yearStr = year.toString();

          return dayName + ", " + dayStr + "/" + monthStr + "/" + yearStr;
        });
        return listEvent;
      } else {
        var d = new Date();
        var currentDate = d.getTime();
        var listCurrent = this.diasIndices.map(function(index) {
          var newDateNr = 86400000 * index + currentDate;
          var newDate = new Date(newDateNr);
          var diasPtBr = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayName = diasPtBr[newDate.getDay()];
          var dayStr;
          if (newDate.getDate() < 10) {
            dayStr = "0" + newDate.getDate().toString();
          } else {
            dayStr = newDate.getDate().toString();
          }
          var monthStr;
          var month = newDate.getMonth() + 1;
          if (month < 10) {
            monthStr = "0" + month.toString();
          } else {
            monthStr = month.toString();
          }
          var year = newDate.getYear() - 100;
          var yearStr = year.toString();

          return dayName + ", " + dayStr + "/" + monthStr + "/" + yearStr;
        });
        return listCurrent;
      }
    },
    chosenDataYMD() {
      var splits = this.niceDateShort.split("/");
      var year = splits[2];
      var month = splits[1];
      var days = splits[0];
      return "20" + year + "-" + month + "-" + days.substring(6);
    },
    endTime() {
      if (this.startTime !== null) {
        var splitsR = this.startTime.split(":");
        var hoursR = parseInt(splitsR[0]);
        var minutesR = parseInt(splitsR[1]);

        if (
          this.atendimento == "Sessão de MASSAGEM RELAXANTE" ||
          this.atendimento ==
            "Sessão de MASSAGEM REDUTORA ou DRENAGEM CORPO TODO" ||
          this.atendimento == "Sessão de DUAL SOON" ||
          this.atendimento == "Sessão de LIPO VEGANA" ||
          this.atendimento == "Sessão de MICROAGULHAMENTO" ||
          this.atendimento == "Sessão de DRY NEEDLING" ||
          this.atendimento == "Sessão de AURICULOTERAPIA" ||
          this.atendimento == "Sessão de LIMPEZA DE PELE"
        ) {
          hoursR += 1;
        } else {
          minutesR += 30;
        }

        if (minutesR > 59) {
          minutesR -= 60;
          hoursR += 1;
        }
        if (minutesR == 0) {
          minutesR = "00";
        }
        var newEndTimeR = hoursR
          .toString()
          .concat(":")
          .concat(minutesR.toString());
        return newEndTimeR;
      } else {
        return null;
      }
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
  },
  methods: {
    createJsonString() {
      const now = new Date();
      this.jsonString = JSON.stringify(this.events.filter((e) => {
        let startMillis = new Date(e.start).getTime();
        return startMillis > now.getTime();
      }), null, 2);
    },
    async salvarProspecto() {
      console.log("salvarProspecto");
      var d = new Date();
      var millis = d.getTime();
      // var millis7 = 7 * 24 * 60 * 60 * 1000 + millis;
      var timeId = millis.toString();
      // var timeId7 = millis7.toString();
      var ano = d.getFullYear();
      var mes0 = d.getMonth();
      var mes = mes0 + 1;
      var dia = d.getDate();
      var hoursStr;
      if (d.getHours() > 9) {
        hoursStr = d.getHours().toString();
      } else {
        hoursStr = "0" + d.getHours().toString();
      }
      var minStr;
      if (d.getMinutes() > 9) {
        minStr = d.getMinutes().toString();
      } else {
        minStr = "0" + d.getMinutes().toString();
      }
      var horarioLigacao = hoursStr + ":" + minStr;
      if (this.patientId == undefined) {
        this.patientId = "";
      }
      if (this.patient == undefined) {
        this.patient = "";
      }

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("ligacoes")
        .doc(timeId)
        .set({
          telefone: this.p_telefone,
          whatsapp: this.p_whatsapp,
          nome: this.patient,
          email: this.patientId,
          sexo: this.p_sexo,
          objetivo: this.p_objetivo,
          motivoRecusao: this.atendimento,
          motivoRecusaoOutro: null,
          horarioLigacao: horarioLigacao,
          meioContato: this.p_meioContato,
          detalhesImportantes: this.p_detalhesImportantes,
          conversion: false,
          ano: ano,
          mes: mes,
          dia: dia,
          millis: millis,
        });
    },
    async checkGetGiftcards() {
      if (this.atendimento == "Sessão de LIP./HIB. (Giftcard)") {
        var giftcardsGot = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("giftcards")
          .get()
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return doc.data();
            })
          );
        var giftcardsGotObjs = giftcardsGot.map(function(g) {
          return {
            text:
              g.id + " - " + g.recipienteNome + " (" + g.compradorNome + ")",
            value: g.id,
          };
        });
        this.giftcardIds = giftcardsGotObjs;
      } 
    },
    async checkAgendado() {
      var gId = this.giftcardIdTyped;
      var gDoc = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("giftcards")
        .doc(gId)
        .get()
        .then(function(doc) {
          return doc.data();
        });
      var agendadoBool = gDoc.agendadoEstetica;
      this.agendadoOk = !agendadoBool;
      this.details = "Giftcard " + gDoc.descr + ": " + gDoc.recipienteNome;
      this.patient = gDoc.recipienteNome;
      this.patientId = this.giftcardIdTyped;
    },
    async makeNaoCompareceu(event, boolean) {
      if (boolean) {
        event.naoCompareceu = true;

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agendaEstetica")
          .doc(event.id)
          .update({
            oldColor: "blue",
            color: "#cccccc",
            naoCompareceu: true,
          });

        await firebase
          .firestore()
          .collection("users")
          .doc(event.pacienteId)
          .collection("agenda")
          .doc(event.id)
          .update({
            oldColor: "blue",
            color: "#cccccc",
            naoCompareceu: true,
          });
        event.color = "#cccccc";
      } else {
        event.color = "blue";
        event.naoCompareceu = false;

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agendaEstetica")
          .doc(event.id)
          .update({
            color: "blue",
            naoCompareceu: false,
          });

        await firebase
          .firestore()
          .collection("users")
          .doc(event.pacienteId)
          .collection("agenda")
          .doc(event.id)
          .update({
            color: "blue",
            naoCompareceu: false,
          });
      }
    },
    seeInMeusPacientes(pId) {
      this.$store.dispatch("firestore/persistPaciente", {
        pId: pId,
      });
      this.$router.push("MeusPacientes");
    },
    async saveEvent(event) {
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .doc(event.id)
        .set(event);

      if (event.patientId !== "") {
        await firebase
          .firestore()
          .collection("users")
          .doc(event.patientId)
          .collection("agenda")
          .doc(event.id)
          .set(event);
      }
      this.editEvent = false;
      this.getEvents();

      console.log(event);
    },
    async updateEvent(event) {
      var startDate = new Date(event.start);
      var endDate = new Date(event.end);
      var millis = startDate.getTime();
      var sub = 24 * 60 * 60 * 1000;
      var millis_1 = millis - sub;
      var startMinStr;
      var startMinStrX = startDate.getMinutes();
      if (startMinStrX < 10) {
        startMinStr = "0" + startMinStrX.toString();
      } else {
        startMinStr = startMinStrX.toString();
      }
      var endMinStr;
      var endMinStrX = endDate.getMinutes();
      if (endMinStrX < 10) {
        endMinStr = "0" + endMinStrX.toString();
      } else {
        endMinStr = endMinStrX.toString();
      }

      var startTime = startDate.getHours().toString() + ":" + startMinStr;
      var endTime = endDate.getHours().toString() + ":" + endMinStr;

      var startYStrX = startDate.getYear() + 1900;
      var startYStr = startYStrX.toString();
      var startYStrShortX = startDate.getYear() - 100;
      var startYStrShort = startYStrShortX.toString();

      var startMStr;
      var startMStrX = startDate.getMonth() + 1;
      if (startMStrX < 10) {
        startMStr = "0" + startMStrX.toString();
      } else {
        startMStr = startMStrX.toString();
      }

      var startDStr;
      var startDStrX = startDate.getDate();
      if (startDStrX < 10) {
        startDStr = "0" + startDStrX.toString();
      } else {
        startDStr = startDStrX.toString();
      }

      var startStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + startTime + ":00";
      var endStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + endTime + ":00";
      var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
      var day = diasPtBr[startDate.getDay()];
      var niceDateShort =
        day + ", " + startDStr + "/" + startMStr + "/" + startYStrShort;
      var niceDateStr =
        day +
        ", " +
        startDStr +
        "/" +
        startMStr +
        "/" +
        startYStrShort +
        ", " +
        startTime +
        "-" +
        endTime;

      var updateDoc = {
        niceDate: niceDateStr,
        niceDateShort: niceDateShort,
        start: startStr,
        end: endStr,
        startTime: startTime,
        endTime: endTime,
        autoset: false
      };

      var updateDocLembrete = {
        done: false,
        status: "notDueYet",
        dueDate: millis_1,
        niceDate: niceDateStr,
        niceDateShort: niceDateShort,
        start: startStr,
        end: endStr,
        startTime: startTime,
        endTime: endTime,
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .doc(event.id)
        .update(updateDoc);

      if (event.patientId !== "" && event.patientId.toString().includes("@")) {
        await firebase
          .firestore()
          .collection("users")
          .doc(event.patientId)
          .collection("agenda")
          .doc(event.id)
          .update(updateDoc);

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("lembretes")
          .doc(event.id)
          .update(updateDocLembrete);
      }

      this.editEvent = false;
      this.getEvents();
    },
    endDrag() {
      this.updateEvent(this.dragEvent);
      this.dragEvent = null;
    },
    startDrag({ event }) {
      if (event) {
        this.dragEvent = event;
      }
    },
    toYMD(string) {
      var splits = string.split("/");
      var year = splits[2];
      var month = splits[1];
      var days = splits[0];
      return "20" + year + "-" + month + "-" + days.substring(6);
    },
    changeStartEnd() {
      this.selectedEvent.start = `${this.toYMD(
        this.selectedEvent.niceDateShort
      )} ${this.selectedEvent.startTime}:00`;
      this.selectedEvent.end = `${this.toYMD(
        this.selectedEvent.niceDateShort
      )} ${this.selectedEvent.endTime}:00`;
    },
    pacienteSelected(pacienteId) {
      if (pacienteId != undefined) {
        var paciente = this.pacientes.find((item) => item.value == pacienteId);
        this.patient = paciente.text;
        this.patientId = pacienteId;
      }
    },
    clearInfo() {
      this.savingEvent = false;
      this.dialog = false;
      this.bonusTipo = null;
      this.patient = "";
      this.patientId = "";
      this.newPatient = false;
      this.startTime = null;
      this.endTime = null;
      this.details = null;
      this.selectedTime = null;
      this.niceDateShort = null;
      this.giftcardIdTyped = null;
      this.agendadoOk = false;
    },
    hourInfoMouseFollow(e) {
      try {
        var chip = document.getElementById("DateChip");
        if (
          (this.type === "week" || this.type === "day") &&
          e.target.className == "v-calendar-daily__day-interval"
        ) {
          chip.style.display = "block";
          chip.style.left = e.clientX - 300 + "px";
          chip.style.top = e.layerY + "px";
        } else {
          chip.style.display = "none";
        }
      } catch (err) {
        err;
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    async deleteEvent(event) {
      var other_type;
      if (this.type !== "week") {
        other_type = "week";
      } else {
        other_type = "day";
      }
      var a = this.type;
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .doc(event.id)
        .delete();

      if (
        event.name !== "BONUS" &&
        event.name !== "Avaliação com a Esteticista" &&
        event.patientId !== null &&
        event.patientId.toString().includes("@")
      ) {
        await firebase
          .firestore()
          .collection("users")
          .doc(event.patientId)
          .collection("agenda")
          .doc(event.id)
          .delete();
      }

      if (event.name == "Sessão de LIP./HIB. (Giftcard)") {
        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("giftcards")
          .doc(event.patientId)
          .update({
            agendadoEstetica: false,
          });
      }

      this.type = other_type;
      setTimeout(() => {
        this.type = a;
      }, 1);
      this.selectedOpen = false;
    },
    async getEvents() {
      var events = [];
      var eventList = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      eventList.forEach(function (event) {
        var colorEvent = function(e) {
          if (e.autoset == undefined) {
              return e.color;
          } else {
            if (e.autoset) {
              return "#99ff99"
            } else {
              return e.color;
            }
          }
        };
        events.push({
          name: event.name,
          patient: event.patient,
          patientId: event.patientId,
          newPatient: event.newPatient,
          start: new Date(event.start),
          end: new Date(event.end),
          details: event.details,
          color: colorEvent(event),
          timed: event.timed,
          id: event.id,
          startTime: event.startTime,
          endTime: event.endTime,
          niceDateShort: event.niceDateShort,
        })
    });
      this.events = events;
    },
    makeColor(atendimentoTipo) {
      if (
        atendimentoTipo == "Sessão de HIBRIUS" ||
        atendimentoTipo == "Sessão de DUAL SOON"
      ) {
        return "#FFB400";
      } else if (
        atendimentoTipo == "Avaliação com a Esteticista"
      ) {
        return "#ff8080";
      } else if (atendimentoTipo.startsWith("BONUS")) {
        return "#ff8080";
      }
       else {
        return "blue";
      }
    },
    openEventForm(time) {
      this.dialog = true;
      // this.selectedTime = time;
      var splitsT = time.time.split(":");
      var hoursT = parseInt(splitsT[0]);
      var minutesT = parseInt(splitsT[1]);
      if (minutesT < 15) {
        minutesT = "00";
      } else if (minutesT >= 15 && minutesT < 30) {
        minutesT = "15";
      } else if (minutesT >= 30 && minutesT < 45) {
        minutesT = "30";
      } else if (minutesT >= 45 && minutesT < 59) {
        minutesT = "45";
        // hoursT += 1;
      }
      var roundedTime = hoursT
        .toString()
        .concat(":")
        .concat(minutesT);
      this.startTime = roundedTime; //time.time;
      var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
      var dayStr;
      var day = time.day;
      if (day < 10) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var monthStr;
      var month = time.month;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }
      var year = time.year - 2000;
      var yearStr = year.toString();

      this.niceDateShort =
        diasPtBr[time.weekday] + ", " + dayStr + "/" + monthStr + "/" + yearStr;
      time.time = roundedTime;
      this.selectedTime = time;
    },
    async addEvent() {
      if (
        this.startTime !== null &&
        this.endTime !== null &&
        this.patient !== "" &&
        this.atendimento
      ) {
        this.savingEvent = true;

        var ref = firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agendaEstetica")
          .doc();

        var id = ref.id;

        var d = new Date(`${this.chosenDataYMD} ${this.startTime}:00`);
        console.log(d);
        var millis = d.getTime();
        var sub = 24 * 60 * 60 * 1000;
        var millis_1 = millis - sub;

        if (this.patient == undefined) {
          this.patient = "";
          this.patientId = null;
        }
        if (this.atendimento == "BONUS" && this.bonusTipo !== null) {
          this.atendimento = this.atendimento + ": " + this.bonusTipo;
          this.details = this.details + ", " + this.bonusTipo;
        }

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agendaEstetica")
          .doc(id)
          .set({
            name: this.atendimento,
            patient: this.patient,
            patientId: this.patientId,
            newPatient: this.newPatient,
            start: `${this.chosenDataYMD} ${this.startTime}:00`,
            details: this.details,
            end: `${this.chosenDataYMD} ${this.endTime}:00`,
            startTime: this.startTime,
            endTime: this.endTime,
            autoset: false,
            niceDateShort: this.niceDateShort,
            color: this.makeColor(this.atendimento),
            // this.atendimento === "Sessão de HIBRIUS" ? "#FFB400" : "blue",
            timed: true,
            id: id,
          });

        if (
          this.patient !== "" &&
          this.patient !== undefined &&
          this.patient !== null &&
          this.patientId !== "" &&
          this.patientId !== undefined &&
          this.patientId !== null &&
          this.patientId.toString().includes("@")
        ) {
          await firebase
            .firestore()
            .collection("users")
            .doc(this.patientId)
            .collection("agenda")
            .doc(id)
            .set({
              name: this.atendimento,
              patient: this.patient,
              patientId: this.patientId,
              newPatient: this.newPatient,
              start: `${this.chosenDataYMD} ${this.startTime}:00`,
              details: this.details,
              end: `${this.chosenDataYMD} ${this.endTime}:00`,
              startTime: this.startTime,
              endTime: this.endTime,
              autoset: false,
              niceDateShort: this.niceDateShort,
              color: this.makeColor(this.atendimento),
              // this.atendimento === "Sessão de HIBRIUS" ? "#FFB400" : "blue",
              timed: true,
              id: id,
            });
        }

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("lembretes")
          .doc(id)
          .set({
            tipo: "confirm-estetica",
            done: false,
            status: "notDueYet",
            dueDate: millis_1,
            name: this.atendimento,
            patient: this.patient,
            patientId: this.patientId,
            newPatient: this.newPatient,
            start: `${this.chosenDataYMD} ${this.startTime}:00`,
            details: this.details,
            end: `${this.chosenDataYMD} ${this.endTime}:00`,
            startTime: this.startTime,
            endTime: this.endTime,
            niceDateShort: this.niceDateShort,
            timed: true,
            id: id,
          });

        this.$refs.calendar.checkChange();
        this.$refs.calendar.$forceUpdate();
        this.type = "day";
        setTimeout(() => {
          this.type = "week";
        }, 30);

        if (
          this.newPatient &&
          this.atendimento !== "Avaliação com a Esteticista" &&
          !this.atendimento.startsWith("BONUS")
        ) {
          this.$store.dispatch("firestore/agendaPacienteNome", {
            obj: {
              nome: this.patient,
              email: this.patientId,
            },
          });
        } else if (
          this.newPatient &&
          (this.atendimento == "Avaliação com a Esteticista" ||
            this.atendimento.startsWith("BONUS"))
        ) {
          this.salvarProspecto();
        }

        if (this.atendimento == "Sessão de LIP./HIB. (Giftcard)") {
          var gId = this.giftcardIdTyped;
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("giftcards")
            .doc(gId)
            .update({
              agendadoEstetica: true,
            });
        }

        this.clearInfo();
      } else {
        if (
          this.atendimento.startsWith("BONUS") ||
          this.atendimento == "Avaliação com a Esteticista"
        ) {
          var refB = firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agendaEstetica")
            .doc();

          var idB = refB.id;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agendaEstetica")
            .doc(idB)
            .set({
              name: this.atendimento,
              patient: null,
              patientId: null,
              newPatient: true,
              start: `${this.chosenDataYMD} ${this.startTime}:00`,
              details: this.details,
              end: `${this.chosenDataYMD} ${this.endTime}:00`,
              startTime: this.startTime,
              endTime: this.endTime,
              autoset: false,
              niceDateShort: this.niceDateShort,
              color: this.makeColor(this.atendimento),
              // this.atendimento === "Sessão de HIBRIUS" ? "#FFB400" : "blue",
              timed: true,
              id: idB,
            });

          this.$refs.calendar.checkChange();
          this.$refs.calendar.$forceUpdate();
          this.type = "day";
          setTimeout(() => {
            this.type = "week";
          }, 30);
          this.dialog = false;
        } else {
          alert("Preencha os campos antes de continuar");
        }
      }
    },
    getCalendarDayTime(time) {
      // this.currentTime = time.time;
      var splitsT = time.time.split(":");
      var hoursT = parseInt(splitsT[0]);
      var minutesT = parseInt(splitsT[1]);
      if (minutesT < 15) {
        minutesT = "00";
      } else if (minutesT >= 15 && minutesT < 30) {
        minutesT = "15";
      } else if (minutesT >= 30 && minutesT < 45) {
        minutesT = "30";
      } else if (minutesT >= 45 && minutesT < 59) {
        minutesT = "45";
      }
      var roundedTime = hoursT
        .toString()
        .concat(":")
        .concat(minutesT);
      this.currentTime = roundedTime;

      if (this.dragEvent !== null) {
        var start = new Date(this.dragEvent.start);
        var end = new Date(this.dragEvent.end);
        var startS = start.getTime();
        var endS = end.getTime();
        var duration = endS - startS;

        var dayStr;
        var day = time.day;
        if (day < 10) {
          dayStr = "0" + day.toString();
        } else {
          dayStr = day.toString();
        }
        var monthStr;
        var month = time.month;
        if (month < 10) {
          monthStr = "0" + month.toString();
        } else {
          monthStr = month.toString();
        }
        var string =
          time.year.toString() +
          "-" +
          monthStr +
          "-" +
          dayStr +
          " " +
          roundedTime +
          ":00";
        var newStart = new Date(string);
        var newStartS = newStart.getTime();
        var newEndS = newStartS + duration;
        var newEnd = new Date(newEndS);

        var newEndMinutes;
        if (newEnd.getMinutes() < 10) {
          newEndMinutes = "0" + newEnd.getMinutes().toString();
        } else {
          newEndMinutes = newEnd.getMinutes().toString();
        }
        this.dragEvent.start = string;
        this.dragEvent.end =
          time.year.toString() +
          "-" +
          monthStr +
          "-" +
          dayStr +
          " " +
          newEnd.getHours().toString() +
          ":" +
          newEndMinutes +
          ":00";

        this.dragEvent.startTime = roundedTime;
        this.dragEvent.endTime =
          newEnd.getHours().toString() + ":" + newEndMinutes;
      }
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
    // this.getPatients();
    if (this.$store.state.firestore.pacientes.length === 0) {
      this.$store.dispatch("firestore/getPacientes");
    }
    this.getEvents();
  },
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
