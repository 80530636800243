<template>
  <div>
    <v-dialog v-model="choosePacienteDialog" max-width="600" persistent>
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Selecione um paciente
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-autocomplete
            label="Nome do Paciente"
            single-line
            class="my-2"
            v-model="pacienteId"
            :items="pacientes"
            no-data-text="..."
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            dense
            @click="prescreverQuest(pacienteId)"
            color="rgba(37, 63, 75, 1)"
            class="white--text"
            v-if="pacienteId !== null"
          >
            <v-icon left color="white">mdi-file-send-outline</v-icon>
            Prescrever Questionário
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="progressDialog" max-width="600" persistent>
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="fecharProgressDialog">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sobreescrDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center text-subtitle-2">
          Sobre-escrever ou salvar como novo?
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="salvarQuestSobre" color="red" class="white--text"
            >Sobre-escrever</v-btn
          >
          <v-btn @click="salvarQuestNovo" color="green" class="mx-2 white--text"
            >Salvar como novo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visDialog" width="80%">
      <v-card class="my-2" outlined>
        <v-card-title class="d-flex justify-center text-h6">
          {{ quest.titulo }}
        </v-card-title>
        <v-card-subtitle
          class="d-flex justify-center"
          v-if="quest.autor !== null && quest.autor !== 'externo'"
        >
          criado por: {{ quest.autor }}
        </v-card-subtitle>
        <v-card-text>
          <v-row
            class="d-flex justify-center"
            v-for="(perg, pergIndex) in quest.pergs"
            :key="pergIndex"
          >
            <v-card
              class="my-2"
              outlined
              style="background-color: rgba(37, 63, 75, 0.1)"
              width="90%"
            >
              <v-card-title>
                <v-row class="d-flex justify-center text-subtitle-2">
                  {{ perg.pergunta }}
                </v-row>
              </v-card-title>
              <v-card-text v-if="perg.tipo == 'number'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-row class="d-flex justify-center align-bottom">
                      <v-text-field
                        label="resposta"
                        dense
                        outlined
                        v-model="perg.textResp"
                        type="number"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="perg.tipo == 'text-field'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-row class="d-flex justify-center align-bottom">
                      <v-text-field
                        label="resposta"
                        dense
                        outlined
                        v-model="perg.textResp"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="perg.tipo == 'textarea'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-row class="d-flex justify-center align-bottom">
                      <v-textarea
                        label="resposta"
                        dense
                        outlined
                        v-model="perg.textResp"
                      ></v-textarea>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="perg.tipo == 'checkboxes'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-row
                      class="d-flex justify-center"
                      v-for="respCheckbox in perg.checkboxes"
                      :key="respCheckbox.label"
                    >
                      <v-checkbox hide-details :label="respCheckbox.label">
                      </v-checkbox>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="perg.tipo == 'radio'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-radio-group column v-model="perg.arrayResp">
                      <v-radio
                        v-for="respRadio in perg.radios"
                        :key="respRadio.label"
                        :label="respRadio.label"
                      >
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialogToNovo" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Criar um questionário novo?
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center red--text">
          Sem salvar o questionário abaixo...?
        </v-card-subtitle>
        <v-card-actions class="d-flex justify-center">
          <v-btn text color="#614021" @click="alertDialogToNovo = false"
            >Fechar</v-btn
          >
          <v-btn
            class="mx-2 white--text"
            color="red"
            @click="confirmSetModeNovo"
            >Criar novo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialogToEdit" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Editar um outro questionário?
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center red--text">
          Sem salvar o questionário abaixo...?
        </v-card-subtitle>
        <v-card-actions class="d-flex justify-center">
          <v-btn text color="#614021" @click="alertDialogToEdit = false"
            >Fechar</v-btn
          >
          <v-btn
            class="mx-2 white--text"
            color="red"
            @click="confirmSetModeEdit"
            >Editar outro</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialogToPrescr" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Prescrever um outro questionário?
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center red--text">
          Sem salvar/prescrever o questionário abaixo...?
        </v-card-subtitle>
        <v-card-actions class="d-flex justify-center">
          <v-btn text color="#614021" @click="alertDialogToPrescr = false"
            >Fechar</v-btn
          >
          <v-btn
            class="mx-2 white--text"
            color="red"
            @click="confirmSetModePrescr"
            >Prescrever outro questionário</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="d-flex justify-center">
      <v-card
        outlined
        style="background-color: rgba(197, 209, 63, 0.6)"
        width="98%"
      >
        <v-card-title class="d-flex justify-center">
          Questionários
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center">
          Criar e liberar questionários
        </v-card-subtitle>
      </v-card></v-row
    >
    <v-row class="d-flex justify-center">
      <v-card
        class="my-2"
        outlined
        style="background-color: rgba(94, 64, 33, 0.6)"
        width="98%"
      >
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-btn
              color="rgba(94, 64, 33, 0.8)"
              class="white--text"
              dense
              @click="setModeNovo"
            >
              <v-icon color="white" left>mdi-plus</v-icon>
              Criar novo questionário
            </v-btn>
            <v-btn
              color="rgba(94, 64, 33, 0.8)"
              class="white--text mx-2"
              dense
              @click="setModeEdit"
            >
              <v-icon color="white" left>mdi-pencil</v-icon>
              Editar questionário salvo
            </v-btn>
            <v-btn
              color="rgba(197, 209, 63, 0.7)"
              class="brown--text"
              dense
              @click="setModePrescr"
            >
              <v-icon color="#614021" left>mdi-file-send</v-icon>
              Prescrever questionário
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card></v-row
    >
    <v-row
      class="d-flex justify-center"
      v-if="mode == 'edit' || mode == 'prescr'"
    >
      <v-card
        class="my-2"
        outlined
        style="background-color: rgba(94, 64, 33, 0.4)"
        width="98%"
      >
        <v-card-text>
          <v-row
            class="d-flex justify-center align-center black--text"
            v-if="manualSetQuest !== null"
          >
            {{ manualSetQuest }}
            <v-tooltip
              bottom
              color="rgba(94, 64, 33, 0.8)"
              v-if="!showQuestSalvas"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-1 white--text"
                  color="rgba(94, 64, 33, 0.8)"
                  @click="showQuestSalvas = true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#614021">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>selecionar outro questionário salvo</span>
            </v-tooltip>
          </v-row>
          <v-row v-if="showQuestSalvas">
            <v-col cols="0" md="2"></v-col>
            <v-col cols="12" md="8">
              <v-row class="d-flex justify-center">
                <v-select
                  label="Selecionar questionário salvo"
                  :items="questSalvasSelection"
                  v-model="questEdit"
                  @change="setQuest(questEdit)"
                  color="#614021"
                  no-data-text="Nenhum questionário salvo"
                >
                  <template v-slot:prepend-item v-if="isFranquia">
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      v-for="questSalv in questSalvFranq"
                      :key="questSalv.id"
                      @click="setQuestCentral(questSalv)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ questSalv.titulo }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ questSalv.autor }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider> </template
                ></v-select>
              </v-row>
            </v-col>
            <v-col cols="0" md="2"></v-col>
          </v-row>
        </v-card-text> </v-card
    ></v-row>
    <v-row class="d-flex justify-center">
      <v-card
        v-if="
          (mode == 'edit' && quest.titulo !== undefined) ||
            (mode == 'prescr' && quest.titulo !== undefined) ||
            mode == 'novo'
        "
        class="my-2"
        outlined
        style="background-color: rgba(37, 63, 75, 0.2)"
        width="98%"
      >
        <v-row class="d-flex justify-center">
          <v-btn
            color="rgba(37, 63, 75, 1)"
            v-if="mode !== 'prescr'"
            class="my-2 white--text"
            @click="salvarQuest"
          >
            <v-icon left color="white">mdi-content-save</v-icon>
            Salvar questionário
          </v-btn>

          <v-btn
            color="rgba(37, 63, 75, 1)"
            v-if="mode == 'prescr'"
            class="my-2 white--text"
            @click="choosePacienteDialog = true"
          >
            <v-icon left color="white">mdi-file-send-outline</v-icon>
            Prescrever questionário
          </v-btn>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-btn
            color="rgba(37, 63, 75, 0.1)"
            class="my-2"
            @click="visDialog = true"
          >
            <v-icon left color="rgba(37, 63, 75, 1)">mdi-eye</v-icon>
            Visualizar questionário
          </v-btn>
        </v-row>
        <v-card-title>
          <v-row>
            <v-col cols="0" md="2"></v-col>
            <v-col cols="12" md="8">
              <v-row class="d-flex justify-center align-bottom">
                <v-text-field
                  class="text-h6"
                  label="título"
                  v-model="quest.titulo"
                  dense
                ></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="0" md="2"></v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle
          class="d-flex justify-center"
          v-if="quest.autor !== null && quest.autor !== 'externo'"
        >
          criado por: {{ quest.autor }}
        </v-card-subtitle>
        <v-card-text v-if="quest.titulo !== null">
          <v-row
            class="d-flex justify-center my-2"
            v-for="(perg, pergIndex) in quest.pergs"
            :key="pergIndex"
          >
            <v-card
              outlined
              style="background-color: rgba(37, 63, 75, 0.1)"
              width="90%"
            >
              <v-row class="d-flex justify-center my-1">
                <v-btn
                  rounded
                  outlined
                  @click="insertQuest(pergIndex)"
                  x-small
                  color="green"
                  class="mx-1"
                >
                  <v-icon left small color="green"
                    >mdi-arrow-collapse-up</v-icon
                  >
                  Inserir questão acima
                </v-btn>
                <v-btn
                  v-if="pergIndex > 0"
                  rounded
                  outlined
                  @click="removeQuest(pergIndex)"
                  x-small
                  color="red"
                  class="mx-1"
                >
                  <v-icon left small color="red">mdi-trash-can-outline</v-icon>
                  Remover questão
                </v-btn>
              </v-row>
              <v-row dense>
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    class="text-subtitle-2"
                    label="pergunta"
                    v-model="perg.pergunta"
                    outlined
                  ></v-text-field>
                  <v-select
                    label="tipo de resposta"
                    v-model="perg.tipo"
                    :items="respostaTipos"
                  ></v-select>
                </v-col>
                <v-col cols="0" md="2"></v-col>
              </v-row>

              <!-- <v-row dense>
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-select
                    label="tipo de resposta"
                    v-model="perg.tipo"
                    :items="respostaTipos"
                  ></v-select>
                </v-col>
                <v-col cols="0" md="2"></v-col>
              </v-row> -->

              <v-row v-if="perg.tipo == 'number'">
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-row class="d-flex justify-center align-bottom">
                    <v-text-field
                      label="resposta"
                      dense
                      outlined
                      v-model="perg.textResp"
                      type="number"
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-col cols="0" md="2"></v-col>
              </v-row>

              <v-row v-if="perg.tipo == 'text-field'">
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-row class="d-flex justify-center align-bottom">
                    <v-text-field
                      label="resposta"
                      dense
                      outlined
                      v-model="perg.textResp"
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-col cols="0" md="2"></v-col>
              </v-row>

              <v-row v-else-if="perg.tipo == 'textarea'">
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-row class="d-flex justify-center">
                    <v-textarea
                      label="resposta"
                      dense
                      outlined
                      v-model="perg.textResp"
                    ></v-textarea>
                  </v-row>
                </v-col>
                <v-col cols="0" md="2"></v-col>
              </v-row>

              <v-row v-else-if="perg.tipo == 'checkboxes'">
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-row
                    class="d-flex justify-center align-center"
                    v-for="(respCheckbox, respCheckboxIndex) in perg.checkboxes"
                    :key="respCheckboxIndex"
                    dense
                    ><v-col cols="2" class="d-flex justify-end">
                      <v-checkbox hide-details> </v-checkbox>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        class="text-caption"
                        v-model="respCheckbox.label"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" class="d-flex align-center">
                      <v-btn
                        icon
                        @click="removeCheckbox(pergIndex, respCheckboxIndex)"
                        x-small
                        color="red"
                      >
                        <v-icon left small color="red"
                          >mdi-trash-can-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" dense>
                    <v-btn
                      rounded
                      outlined
                      @click="addCheckbox(pergIndex)"
                      x-small
                      color="green"
                    >
                      <v-icon small color="green"
                        >mdi-plus-circle-outline</v-icon
                      >
                      Adicionar opção
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col cols="0" md="2"></v-col>
              </v-row>

              <v-row v-else-if="perg.tipo == 'radio'">
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-row
                    dense
                    v-for="(respRadio, respRadioIndex) in perg.radios"
                    :key="respRadioIndex"
                  >
                    <v-col cols="2" class="d-flex justify-end">
                      <v-radio> </v-radio>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        class="text-caption"
                        v-model="respRadio.label"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" class="d-flex align-center">
                      <v-btn
                        icon
                        @click="removeRadio(pergIndex, respRadioIndex)"
                        x-small
                        color="red"
                      >
                        <v-icon small color="red">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" dense>
                    <v-btn
                      rounded
                      outlined
                      @click="addRadio(pergIndex)"
                      x-small
                      color="green"
                    >
                      <v-icon left small color="green"
                        >mdi-plus-circle-outline</v-icon
                      >
                      Adicionar opção
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col cols="0" md="2"></v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="d-flex justify-center">
                <v-btn
                  rounded
                  outlined
                  @click="addQuest(pergIndex)"
                  x-small
                  color="green"
                  class="my-4"
                >
                  <v-icon left small color="green"
                    >mdi-plus-circle-outline</v-icon
                  >
                  Inserir questão abaixo
                </v-btn>
              </v-row>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  data() {
    return {
      choosePacienteDialog: false,
      visDialog: false,
      respostaTipos: [
        {
          text: "Número",
          value: "number",
        },
        {
          text: "Texto curto",
          value: "text-field",
        },
        {
          text: "Texto longo",
          value: "textarea",
        },
        {
          text: "Lista",
          value: "checkboxes",
        },
        {
          text: "Escolha",
          value: "radio",
        },
      ],
      manualSetQuest: null,
      showQuestSalvas: true,
      questSalvFranq: [
        {
          titulo: "Questionário metabólico",
          autor: "Central",
          pergs: [
            {
              pergunta: null,
              tipo: null,
              textResp: null,
              arrayResp: [],
              checkboxes: [
                {
                  label: null,
                },
              ],
              radios: [
                {
                  label: null,
                },
              ],
            },
          ],
        },
        {
          titulo: "Questionário metabólico I",
          autor: "Central",
          pergs: [
            {
              pergunta: null,
              tipo: null,
              textResp: null,
              arrayResp: [],
              checkboxes: [
                {
                  label: null,
                },
              ],
              radios: [
                {
                  label: null,
                },
              ],
            },
          ],
        },
      ],
      questSalvas: [],
      quest: {
        answered: false,
        titulo: null,
        autor: null,
        pergs: [
          {
            pergunta: null,
            tipo: null,
            textResp: null,
            arrayResp: [],
            checkboxes: [
              {
                label: null,
              },
            ],
            radios: [
              {
                label: null,
              },
            ],
          },
        ],
      },
      questEdit: null,
      mode: null,
      alertDialogToNovo: false,
      alertDialogToEdit: false,
      alertDialogToPrescr: false,
      sobreescrDialog: false,
      progressDialog: false,
      saved: false,
      pacienteId: null,
    };
  },
  methods: {
    fecharProgressDialog() {
      this.progressDialog = false;
      this.saved = false;
      this.sobreescrDialog = false;
      this.mode = null;
      this.quest = {
        titulo: null,
        autor: null,
        pergs: [
          {
            pergunta: null,
            tipo: null,
            textResp: null,
            arrayResp: [],
            checkboxes: [
              {
                label: null,
              },
            ],
            radios: [
              {
                label: null,
              },
            ],
          },
        ],
      };
      this.getQuestFranq();
      this.getQuestSalvas();
    },
    async salvarQuest() {
      console.log("this.quest", this.quest)
      this.quest.pergs = this.quest.pergs.map(function(perg) {
        perg.textResp = null;
        perg.arrayResp = [];
        return perg;
      });
      if (this.mode == "edit") {
        this.sobreescrDialog = true;
      } else if (this.mode == "novo") {
        this.progressDialog = true;
        var refId = firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("questionarios")
          .doc();

        var id = refId.id;

        this.quest.id = id;
        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("questionarios")
          .doc(id)
          .set(this.quest);

        this.saved = true;
      }
    },
    async salvarQuestNovo() {
      console.log("this.quest", this.quest);
      this.quest.pergs = this.quest.pergs.map(function(perg) {
        perg.textResp = null;
        perg.arrayResp = [];
        return perg;
      });
      this.progressDialog = true;
      var refId = firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("questionarios")
        .doc();
      var id = refId.id;

      this.quest.id = id;

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("questionarios")
        .doc(id)
        .set(this.quest);
      this.saved = true;
    },
    async salvarQuestSobre() {
      console.log("this.quest", this.quest);
      this.quest.pergs = this.quest.pergs.map(function(perg) {
        perg.textResp = null;
        perg.arrayResp = [];
        return perg;
      });
      this.progressDialog = true;
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("questionarios")
        .doc(this.quest.id)
        .update(this.quest);
      this.saved = true;
    },
    async prescreverQuest(pacienteId) {
      console.log("this.quest", this.quest);
      this.quest.pergs = this.quest.pergs.map(function(perg) {
        perg.textResp = null;
        perg.arrayResp = [];
        return perg;
      });
      this.choosePacienteDialog = false;
      this.progressDialog = true;

      var d = new Date();

      var dayStr;
      if (d.getDate() < 10) {
        dayStr = "0" + d.getDate().toString();
      } else {
        dayStr = d.getDate().toString();
      }

      var monthStr;
      var month = d.getMonth() + 1;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }

      var yearStr = d.getFullYear().toString();

      var dateStr = dayStr + "/" + monthStr + "/" + yearStr;

      this.quest.dateStr = dateStr;
      this.quest.answered = false;

      var refId = firebase
        .firestore()
        .collection("users")
        .doc(pacienteId)
        .collection("questionarios")
        .doc();
      this.quest.id = refId.id;

      await firebase
        .firestore()
        .collection("users")
        .doc(pacienteId)
        .collection("questionarios")
        .doc(refId.id)
        .set(this.quest);
      this.saved = true;
    },
    insertQuest(i) {
      var pElem = {
        pergunta: null,
        tipo: null,
        textResp: null,
        arrayResp: [],
        checkboxes: [
          {
            label: null,
          },
        ],
        radios: [
          {
            label: null,
          },
        ],
      };
      this.quest.pergs.splice(i, 0, pElem);
    },
    addQuest(i) {
      var pElem = {
        pergunta: null,
        tipo: null,
        textResp: null,
        arrayResp: [],
        checkboxes: [
          {
            label: null,
          },
        ],
        radios: [
          {
            label: null,
          },
        ],
      };
      var i1 = i + 1;
      this.quest.pergs.splice(i1, 0, pElem);
    },
    removeQuest(i) {
      this.quest.pergs.splice(i, 1);
    },
    addCheckbox(i) {
      var cElem = {
        label: null,
      };
      this.quest.pergs[i].checkboxes.push(cElem);
    },
    removeCheckbox(iP, iC) {
      this.quest.pergs[iP].checkboxes.splice(iC, 1);
    },
    addRadio(i) {
      var cElem = {
        label: null,
      };
      this.quest.pergs[i].radios.push(cElem);
    },
    removeRadio(iP, iR) {
      this.quest.pergs[iP].radios.splice(iR, 1);
    },
    setQuest(qEdit) {
      console.log("qEdit", qEdit);
      //   var qEdit = this.questEdit;
      var qSall = this.questSalvas;
      var qS = qSall.filter(function(q) {
        return q.id == qEdit;
      });
      var questGot = qS[0];
      //   var questGot = {
      //     titulo: this.questEdit,
      //     autor: "Test",
      //   };
      console.log("questGot", questGot);
      this.quest = questGot;
    },
    setQuestCentral(q) {
      this.manualSetQuest = "selecionado: " + q.titulo;
      this.quest = q;
      //   this.questEdit;
      this.showQuestSalvas = false;
    },
    setModeNovo() {
      if (this.mode == null) {
        this.confirmSetModeNovo();
      } else {
        this.alertDialogToNovo = true;
      }
    },
    confirmSetModeNovo() {
      this.manualSetQuest = null;
      this.alertDialogToNovo = false;
      this.mode = "novo";
      var gotAutor;
      var niceNome = function(m) {
        if (m == "bc@clubedanutricionista.com.br") {
          return "Clube da Nutricionista - Balneário Camboriú";
        } else if (m == "niteroi@clubedanutricionista.com.br") {
          return "Clube da Nutricionista - Niteroí";
        } else if (m == "umarizalbelem@clubedanutricionista.com.br") {
          return "Clube da Nutricionista - Belém Umarizal";
        } else if (m == "fortaleza.salinas@clubedanutricionista.com.br") {
          return "Clube da Nutricionista - Fortaleza Salinas";
        } else if (m == "test@clubedanutricionista.com.br") {
          return "Clube da Nutricionista - Test";
        } else if (m == "admin@clubedanutricionista.com.br") {
          return "Clube da Nutricionista";
        }
      };
      if (this.isFranquia) {
        gotAutor = niceNome(sessionStorage.getItem("current-user"));
      } else {
        gotAutor = "externo";
      }
      this.quest.autor = gotAutor;
    },
    setModeEdit() {
      if (this.mode == null) {
        this.confirmSetModeEdit();
      } else {
        this.alertDialogToEdit = true;
      }
    },
    setModePrescr() {
      if (this.mode == null) {
        this.confirmSetModePrescr();
      } else {
        this.alertDialogToPrescr = true;
      }
    },
    confirmSetModeEdit() {
      this.alertDialogToEdit = false;
      this.manualSetQuest = null;
      this.showQuestSalvas = true;
      this.mode = "edit";
      this.quest = {};
    },
    confirmSetModePrescr() {
      this.alertDialogToPrescr = false;
      this.manualSetQuest = null;
      this.showQuestSalvas = true;
      this.mode = "prescr";
      this.quest = {};
      this.$store.dispatch("firestore/getPacientes");
    },
    async getQuestFranq() {
      if (this.isFranquia) {
        var questFranqs = await firebase
          .firestore()
          .collection("questionarios")
          .get()
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              let data = doc.data();
              //   data.id = doc.id;
              return data;
            })
          );
        this.questSalvFranq = questFranqs;
      } else {
        this.questSalvFranq = {
          titulo: null,
          autor: null,
          pergs: [
            {
              pergunta: null,
              tipo: null,
              textResp: null,
              arrayResp: [],
              checkboxes: [
                {
                  label: null,
                },
              ],
              radios: [
                {
                  label: null,
                },
              ],
            },
          ],
        };
      }
    },
    async getQuestSalvas() {
      var questSalvas = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("questionarios")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            return data;
          })
        );
      this.questSalvas = questSalvas;
      console.log("questSalvas", questSalvas);
    },
  },
  computed: {
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    questSalvasSelection() {
      return this.questSalvas.map(function(q) {
        return {
          text: q.titulo,
          value: q.id,
        };
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getQuestFranq();
    }, 1500);
    this.getQuestSalvas();
  },
};
</script>
