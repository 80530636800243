
import Welcome from "../pages/Welcome.vue";
import Login from "../pages/Login.vue";
import Cadastrar from "../pages/Cadastrar.vue";
import Home from "../pages/Home.vue";
import CadastrarRedirected from "../pages/CadastrarRedirected.vue";
import PersonalDataForm from "../components/PersonalDataFormNew.vue";
import PlanoAlimentarCalcularNovo from "../components/PlanoAlimentarCalcularNovo.vue";
import PlanoAlimentarAuto from "../components/PlanoAlimentarAuto.vue";
import MeusPacientes from "../components/MeusPacientes.vue";
import PacienteNovo from "../components/PacienteNovo_220205.vue";
import PreAtendimento from "../components/PreAtendimento.vue";
import Agenda from "../components/Agenda.vue";
import AgendaPaciente from "../components/areaDePaciente/AgendaPaciente.vue";
import AgendaEstetica from "../components/AgendaEstetica.vue";
import CriarReceitaNova from "../components/CriarReceitaNova.vue";
import ReceitasSalvas from "../components/ReceitasSalvas.vue";
import ReceitasTodas from "../components/ReceitasTodas.vue";
import EditarReceita from "../components/EditarReceita.vue";
import EditarFormula from "../components/EditarFormula.vue";
import FormulaCriar from "../components/FormulaCriar.vue";
import FormulasSalvas from "../components/FormulasSalvas.vue";
import Exames from "../components/Exames.vue";
import Orientacoes from "../components/Orientacoes.vue";
import FecharPlano from "../components/FecharPlano.vue";
import LoginDePaciente from "../pages/LoginDePaciente.vue";
// import AreaDoPaciente from "../pages/AreaDoPaciente.vue";
import AreaDoPacienteWork from "../pages/AreaDoPaciente_work.vue";
import PacienteDietas from "../components/areaDePaciente/PacienteDietas.vue";
import PacienteEvolucao from "../components/areaDePaciente/PacienteEvolucao.vue";
import PacienteContrato from "../components/areaDePaciente/PacienteContrato.vue";
import Contrato from "../components/Contrato.vue";
import ContratoEstet from "../components/ContratoEstet.vue";
import PacienteBioimpedancias from "../components/areaDePaciente/PacienteBioimpedancias.vue";
import PacienteExames from "../components/areaDePaciente/PacienteExames.vue";
import PacienteOrientacoes from "../components/areaDePaciente/PacienteOrientacoes.vue";
import PacienteFormulas from "../components/areaDePaciente/PacienteFormulas.vue";
import PacienteVideoAulas from "../components/areaDePaciente/PacienteVideoAulas.vue";
import LiberarVideoAulas from "../components/LiberarVideoAulas.vue";
import TreinamentosSoftware from "../components/TreinamentosSoftware.vue";
import TreinamentosClube from "../components/TreinamentosClube.vue";
import PacienteEventos from "../components/areaDePaciente/PacienteEventos.vue";
import PacienteCompras from "../components/areaDePaciente/PacienteCompras.vue";
import PacienteProdutos from "../components/areaDePaciente/PacienteProdutos.vue";
import PacienteFotos from "../components/areaDePaciente/PacienteFotos.vue";
import DietasSalvas from "../components/DietasSalvas.vue";
import CartaoComponent from "../components/CartaoComponent.vue";
import NovoAtendimento from "../components/NovoAtendimento.vue";
import FinanceiroVendaGasto from "../components/FinVenGas_220205.vue";
import FinanceiroOrcamento from "../components/FinanceiroOrcamento.vue";
import FinanceiroOrcamentoEditar from "../components/FinanceiroOrcamentoEditar.vue";
import FinanceiroLivro from "../components/FinanceiroLivro.vue";
import ClubeDeVantagens from "../components/ClubeDeVantagens.vue";
import AvaliacaoGratuitaSalvas from "../components/AvaliacaoGratuitaSalvas.vue";
import AvaliacaoGratuitaNova from "../components/AvaliacaoGratuitaNova.vue";
import AdminAlimentos from "../components/home/AdminAlimentos.vue";
import AdminHome from "../pages/AdminHome.vue";
import AdminCriarReceitaNova from "../components/admin/AdminCriarReceitaNova.vue";
import AdminEditarReceita from "../components/admin/AdminEditarReceita.vue";
import AdminReceitasTodas from "../components/admin/AdminReceitasTodas.vue";
import AdminReceitasSalvas from "../components/admin/AdminReceitasSalvas.vue";
import AdminFormulaCriar from "../components/admin/AdminFormulaCriar.vue";
import AdminFormulasTodas from "../components/admin/AdminFormulasTodas.vue";
import AdminEditarFormula from "../components/admin/AdminEditarFormula.vue";
import AdminFormulasSalvas from "../components/admin/AdminFormulasSalvas.vue";
import AdminFinanceiroLivro from "../components/admin/AdminFinanceiroLivro.vue";
import AdminGestaoPublicidade from "../components/admin/AdminGestaoPublicidade.vue";
import AdminTreinamentosSoftware from "../components/admin/AdminTreinamentosSoftware.vue";
import AdminTreinamentosClube from "../components/admin/AdminTreinamentosClube.vue";
import FundoPublicidade from "../components/FundoPublicidade.vue";
import PacientesLista from "../components/PacientesLista.vue";
import Prospectos from "../components/Prospectos.vue";
import AgendarPosCompra from "../components/AgendarPosCompra_220205.vue";
import AgendarPosCompraEdit from "../components/AgendarPosCompraEdit.vue";
import QuestionarioNovo from "../components/QuestionarioNovo.vue";
// import PacientePreAtendimento from "../components/areaDePaciente/PacientePreAtendimento.vue";
import FormulasTodas from "../components/FormulasTodas.vue";
import FormulaPrescrever from "../components/FormulaPrescrever.vue";
import LoginParceiro from "../pages/LoginDeParceiro.vue";
import AreaDoParceiro from "../pages/AreaDoParceiro.vue";
import ChatN from "../components/ChatN.vue";
import PacienteChat from "../components/areaDePaciente/PacienteChat.vue";
import PacienteClubeDeVantagens from "../components/areaDePaciente/PacienteClubeDeVantagens.vue";
import Lembretes from "../components/home/Lembretes.vue";
import Parceiros from "../components/Parceiros.vue";

export default [
  {
    path: "/",
    component: Welcome
  },
  {
    path: "/login",
    component: Login
  }, 
  {
    path: "/cadastrar",
    component: Cadastrar
  },
  {
    path: "/cadastrarRedirected",
    name: "redirect",
    component: CadastrarRedirected
  },
  {
    path: "/FecharPlano",
    name: "FecharPlano",
    component: FecharPlano
  },
  {
    path: "/LoginDePaciente",
    name: "LoginDePaciente",
    component: LoginDePaciente
  },
  {
    path: "/LoginParceiro",
    name: "LoginParceiro",
    component: LoginParceiro
  },
  {
    path: "/AreaDoParceiro",
    name: "AreaDoParceiro",
    component: AreaDoParceiro,
  },
  // {
  //   path: "/AreaDoPaciente",
  //   name: "AreaDoPaciente",
  //   component: AreaDoPaciente,
  //   meta: {
  //     requiresAuth: true
  //   },
  //   props (route) {
  //     const matched = route.matched;
  //     if (matched.length > 1) {
  //       return matched[1].meta;
  //     }
  //     return {};
  //   },
  //   children: [
  //     {
  //       path: "/PacienteEvolucao",
  //       name: "PacienteEvolucao",
  //       component: PacienteEvolucao
  //     },
  //     {
  //       path: "/AgendaPaciente",
  //       name: "AgendaPaciente",
  //       component: AgendaPaciente
  //     },
  //     {
  //       path: "/PacienteBioimpedancias",
  //       name: "PacienteBioimpedancias",
  //       component: PacienteBioimpedancias,
  //     },
  //     {
  //       path: "/PacienteFotos",
  //       name: "PacienteFotos",
  //       component: PacienteFotos
  //     },
  //     {
  //       path: "/PacienteDietas",
  //       name: "PacienteDietas",
  //       component: PacienteDietas
  //     },
  //     {
  //       path: "/PacienteExames",
  //       name: "PacienteExames",
  //       component: PacienteExames
  //     },
  //     {
  //       path: "/PacienteFormulas",
  //       name: "PacienteFormulas",
  //       component: PacienteFormulas
  //     },
  //     {
  //       path: "/PacienteVideoAulas",
  //       name: "PacienteVideoAulas",
  //       component: PacienteVideoAulas
  //     },
  //     {
  //       path: "/PacienteEventos",
  //       name: "PacienteEventos",
  //       component: PacienteEventos
  //     },
  //     {
  //       path: "/PacienteProdutos",
  //       name: "PacienteProdutos",
  //       component: PacienteProdutos
  //     },
  //     {
  //       path: "/PacienteCompras",
  //       name: "PacienteCompras",
  //       component: PacienteCompras
  //     },
  //     {
  //       path: "/PacienteContrato",
  //       name: "PacienteContrato",
  //       component: PacienteContrato
  //     },
  //     {
  //       path: "/PacienteChat",
  //       name: "PacienteChat",
  //       component: PacienteChat
  //     },
  //     {
  //       path: "/PacienteClubeDeVantagens",
  //       name: "PacienteClubeDeVantagens",
  //       component: PacienteClubeDeVantagens
  //     }
  //     // {
  //     //   path: "/PacientePreAtendimento",
  //     //   name: "PacientePreAtendimento",
  //     //   component: PacientePreAtendimento
  //     // }
  //   ]
  // },
  {
    path: "/AreaDoPacienteWork",
    name: "AreaDoPacienteWork",
    component: AreaDoPacienteWork,
    meta: {
      requiresAuth: true
    },
    props (route) {
      const matched = route.matched;
      if (matched.length > 1) {
        return matched[1].meta;
      }
      return {};
    },
    children: [
      {
        path: "/PacienteEvolucao",
        name: "PacienteEvolucao",
        component: PacienteEvolucao
      },
      {
        path: "/AgendaPaciente",
        name: "AgendaPaciente",
        component: AgendaPaciente
      },
      {
        path: "/PacienteBioimpedancias",
        name: "PacienteBioimpedancias",
        component: PacienteBioimpedancias,
      },
      {
        path: "/PacienteFotos",
        name: "PacienteFotos",
        component: PacienteFotos
      },
      {
        path: "/PacienteDietas",
        name: "PacienteDietas",
        component: PacienteDietas
      },
      {
        path: "/PacienteExames",
        name: "PacienteExames",
        component: PacienteExames
      },
      {
        path: "/PacienteOrientacoes",
        name: "PacienteOrientacoes",
        component: PacienteOrientacoes
      },
      {
        path: "/PacienteFormulas",
        name: "PacienteFormulas",
        component: PacienteFormulas
      },
      {
        path: "/PacienteVideoAulas",
        name: "PacienteVideoAulas",
        component: PacienteVideoAulas
      },
      {
        path: "/PacienteEventos",
        name: "PacienteEventos",
        component: PacienteEventos
      },
      {
        path: "/PacienteProdutos",
        name: "PacienteProdutos",
        component: PacienteProdutos
      },
      {
        path: "/PacienteCompras",
        name: "PacienteCompras",
        component: PacienteCompras
      },
      {
        path: "/PacienteContrato",
        name: "PacienteContrato",
        component: PacienteContrato
      },
      {
        path: "/PacienteChat",
        name: "PacienteChat",
        component: PacienteChat
      },
      {
        path: "/PacienteClubeDeVantagens",
        name: "PacienteClubeDeVantagens",
        component: PacienteClubeDeVantagens
      }
      // {
      //   path: "/PacientePreAtendimento",
      //   name: "PacientePreAtendimento",
      //   component: PacientePreAtendimento
      // }
    ]
  },
  {
    path: "/home",
    // redirect: "/cadastrarRedirected",
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    },
    props (route) {
      const matched = route.matched;
      if (matched.length > 1) {
        return matched[1].meta;
      }
      return {};
    },
    children: [
      {
        path: "/Chat",
        name: "Chat",
        component: ChatN
      },
      {
        path: "/Lembretes",
        name: "Lembretes",
        component: Lembretes
      },
      {
        path: '/cartao',
        name: 'cartao',
        component: CartaoComponent,
      },
      {
        path: "/NovoAtendimento",
        name: "NovoAtendimento",
        component: NovoAtendimento
      },
      {
        path: "/PlanoAlimentarCalcularNovo",
        name: "PlanoAlimentarCalcularNovo",
        component: PlanoAlimentarCalcularNovo
      },
      {
        path: "/PlanoAlimentarAuto",
        name: "PlanoAlimentarAuto",
        component: PlanoAlimentarAuto
      },
      {
        path: "/DietasSalvas",
        name: "DietasSalvas",
        component: DietasSalvas
      },      
      {
        path: "/personalData",
        // redirect: "/cadastrarRedirected",
        component: PersonalDataForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/PacienteNovo",
        name: "PacienteNovo",
        component: PacienteNovo
      },
      {
        path: "/MeusPacientes",
        name: "MeusPacientes",
        component: MeusPacientes
      },
      {
        path: "/PacientesLista",
        name: "PacientesLista",
        component: PacientesLista
      },
      {
        path: "/Prospectos",
        name: "Prospectos",
        component: Prospectos
      },
      {
        path: "/PreAtendimento",
        name: "PreAtendimento",
        component: PreAtendimento
      },
      {
        path: "/Agenda",
        name: "Agenda",
        component: Agenda
      },
      {
        path: "/AgendarPosCompra",
        name: "AgendarPosCompra",
        component: AgendarPosCompra
      },
      {
        path: "/AgendarPosCompraEdit",
        name: "AgendarPosCompraEdit",
        component: AgendarPosCompraEdit
      },
      {
        path: "/AdminAlimentos",
        name: "AdminAlimentos",
        component: AdminAlimentos
      },
      {
        path: "/AgendaEstetica",
        name: "AgendaEstetica",
        component: AgendaEstetica
      },      
      {
        path: "/CriarReceitaNova",
        name: "CriarReceitaNova",
        component: CriarReceitaNova
      },
      {
        path: "/EditarReceita",
        name: "EditarReceita",
        component: EditarReceita
      },
      {
        path: "/ReceitasSalvas",
        name: "ReceitasSalvas",
        component: ReceitasSalvas
      },
      {
        path: "/ReceitasTodas",
        name: "ReceitasTodas",
        component: ReceitasTodas
      },
      {
        path: "/FormulaCriar",
        name: "FormulaCriar",
        component: FormulaCriar
      },
      {
        path: "/FormulasSalvas",
        name: "FormulasSalvas",
        component: FormulasSalvas
      },
      {
        path: "/FormulasTodas",
        name: "FormulasTodas",
        component: FormulasTodas
      },
      {
        path: "/EditarFormula",
        name: "EditarFormula",
        component: EditarFormula
      },
      {
        path: "/FormulaPrescrever",
        name: "FormulaPrescrever",
        component: FormulaPrescrever
      },
      {
        path: "/Exames",
        name: "Exames",
        component: Exames
      },
      {
        path: "/Orientacoes",
        name: "Orientacoes",
        component: Orientacoes
      },
      {
        path: "/LiberarVideoAulas",
        name: "LiberarVideoAulas",
        component: LiberarVideoAulas
      },
      {
        path: "/FinanceiroVendaGasto",
        name: "FinanceiroVendaGasto",
        component: FinanceiroVendaGasto
      },
      {
        path: "/FinanceiroLivro",
        name: "FinanceiroLivro",
        component: FinanceiroLivro
      },
      {
        path: "/FinanceiroOrcamento",
        name: "FinanceiroOrcamento",
        component: FinanceiroOrcamento
      },
      {
        path: "/FinanceiroOrcamentoEditar",
        name: "FinanceiroOrcamentoEditar",
        component: FinanceiroOrcamentoEditar
      },
      {
        path: "/FundoPublicidade",
        name: "FundoPublicidade",
        component: FundoPublicidade
      },
      {
        path: "/Contrato",
        name: "Contrato",
        component: Contrato
      },
      {
        path: "/ContratoEstet",
        name: "ContratoEstet",
        component: ContratoEstet
      },
      {
        path: "/ClubeDeVantagens",
        name: "ClubeDeVantagens",
        component: ClubeDeVantagens
      },
      {
        path: "/Parceiros",
        name: "Parceiros",
        component: Parceiros
      },
      {
        path: "/AvaliacaoGratuitaNova",
        name: "AvaliacaoGratuitaNova",
        component: AvaliacaoGratuitaNova
      },
      {
        path: "/AvaliacaoGratuitaSalvas",
        name: "AvaliacaoGratuitaSalvas",
        component: AvaliacaoGratuitaSalvas
      },
      {
        path: "/TreinamentosSoftware",
        name: "TreinamentosSoftware",
        component: TreinamentosSoftware
      },
      {
        path: "/TreinamentosClube",
        name: "TreinamentosClube",
        component: TreinamentosClube
      },
      {
        path: "/QuestionarioNovo",
        name: "QuestionarioNovo",
        component: QuestionarioNovo,
      },
    ]
  },
  {
    path: "/adminHome",
    name: 'adminHome',
    component: AdminHome,
    meta: {
      requiresAuth: true
    },
    props (route) {
      const matched = route.matched;
      if (matched.length > 1) {
        return matched[1].meta;
      }
      return {};
    },
    children: [
      {
        path: "/AdminAlimentos",
        name: "AdminAlimentos",
        component: AdminAlimentos
      },
      {
        path: "/AdminCriarReceitaNova",
        name: "AdminCriarReceitaNova",
        component: AdminCriarReceitaNova
      },
      {
        path: "/AdminEditarReceita",
        name: "AdminEditarReceita",
        component: AdminEditarReceita
      },
      {
        path: "/AdminReceitasTodas",
        name: "AdminReceitasTodas",
        component: AdminReceitasTodas
      },
      {
        path: "/AdminReceitasSalvas",
        name: "AdminReceitasSalvas",
        component: AdminReceitasSalvas
      },
      {
        path: "/AdminFormulaCriar",
        name: "AdminFormulaCriar",
        component: AdminFormulaCriar
      },
      {
        path: "/AdminFormulasTodas",
        name: "AdminFormulasTodas",
        component: AdminFormulasTodas
      },
      {
        path: "/AdminEditarFormula",
        name: "AdminEditarFormula",
        component: AdminEditarFormula
      },
      {
        path: "/AdminFormulasSalvas",
        name: "AdminFormulasSalvas",
        component: AdminFormulasSalvas
      },
      {
        path: "/AdminFinanceiroLivro",
        name: "AdminFinanceiroLivro",
        component: AdminFinanceiroLivro
      },
      {
        path: "/AdminGestaoPublicidade",
        name: "AdminGestaoPublicidade",
        component: AdminGestaoPublicidade
      },
      {
        path: "/AdminTreinamentosSoftware",
        name: "AdminTreinamentosSoftware",
        component: AdminTreinamentosSoftware
      },
      {
        path: "/AdminTreinamentosClube",
        name: "AdminTreinamentosClube",
        component: AdminTreinamentosClube
      },
    ]
  }
];
