<template>
  <div>
    <v-dialog max-width="600" v-model="chooseDateTimeDialog">
      <v-card>
        <v-card-title class="d-flex justify-center">
          "{{ eventEdit.name }}"
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="eventEdit.niceDateShort"
                :items="dias"
                menu-props="auto"
                label="Data *"
                hide-details
                prepend-icon="mdi-calendar"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="eventEdit.startTime"
                :items="horarios"
                menu-props="auto"
                label="Horário de inicio *"
                hide-details
                prepend-icon="mdi-clock-time-four-outline"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="eventEdit.endTime"
                :items="horarios"
                menu-props="auto"
                label="Horário de termino *"
                hide-details
                prepend-icon="mdi-clock-time-four-outline"
                single-line
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="transformStartEnd"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-model="chooseDateTimeDialogE">
      <v-card>
        <v-card-title class="d-flex justify-center">
          "{{ eventEdit.name }}"
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="eventEdit.niceDateShort"
                :items="dias"
                menu-props="auto"
                label="Data *"
                hide-details
                prepend-icon="mdi-calendar"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="eventEdit.startTime"
                :items="horarios"
                menu-props="auto"
                label="Horário de inicio *"
                hide-details
                prepend-icon="mdi-clock-time-four-outline"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="eventEdit.endTime"
                :items="horarios"
                menu-props="auto"
                label="Horário de termino *"
                hide-details
                prepend-icon="mdi-clock-time-four-outline"
                single-line
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="transformStartEndE"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-stepper non-linear>
      <v-stepper-header>
        <v-stepper-step editable step="1"> Consultas </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable step="2">
          Tratamentos Estéticos
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row class="d-flex justify-center">
            <v-card width="95%">
              <div
                v-if="
                  mov.tipo === 'Programa 12S Online' ||
                    mov.tipo === 'Programa 12S' ||
                    mov.tipo === 'Programa 12S com Estética' ||
                    mov.tipo === 'Programa Clínico Funcional' ||
                    mov.tipo === 'Programa Modulação Intestinal' ||
                    mov.tipo === 'Programa Clínico Funcional (plano família)' ||
                    mov.tipo ===
                      'Programa Modulação Intestinal (plano família)' ||
                    mov.tipo === 'Programa Clínico Funcional com Estética' ||
                    mov.tipo === 'Programa Modulação Intestinal com Estética' ||
                    mov.tipo === 'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)' ||
                    mov.tipo === 'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)'
                "
              >
                <v-row class="mx-1">
                  <v-col cols="12" md="3">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        :color="isAlertEvent(dataToSetList[0])"
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDateShort }}, {{ dataToSetList[0].startTime }}-{{ dataToSetList[0].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                        :color="isAlertEvent(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDateShort }}, {{ dataToSetList[1].startTime }}-{{ dataToSetList[1].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[2].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[2])"
                        :color="isAlertEvent(dataToSetList[2])"
                      >
                        {{ dataToSetList[2].niceDateShort }}, {{ dataToSetList[2].startTime }}-{{ dataToSetList[2].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[3].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[3])"
                        :color="isAlertEvent(dataToSetList[3])"
                      >
                        {{ dataToSetList[3].niceDateShort }}, {{ dataToSetList[3].startTime }}-{{ dataToSetList[3].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div
                v-else-if="mov.tipo === 'Pacote 90 dias Protocolo Kirchhoff'"
              >
                <v-row class="mx-1">
                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                        :color="isAlertEvent(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDateShort }}, {{ dataToSetList[0].startTime }}-{{ dataToSetList[0].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                        :color="isAlertEvent(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDateShort }}, {{ dataToSetList[1].startTime }}-{{ dataToSetList[1].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[2].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[2])"
                        :color="isAlertEvent(dataToSetList[2])"
                      >
                        {{ dataToSetList[2].niceDateShort }}, {{ dataToSetList[2].startTime }}-{{ dataToSetList[2].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Consulta'">
                <v-row class="mx-1">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                        :color="isAlertEvent(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDateShort }}, {{ dataToSetList[0].startTime }}-{{ dataToSetList[0].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Pacote Estético'">
                <v-row class="d-flex justify-center">
                  <v-btn @click="step = 2" color="#c5d13f">
                    <v-icon left color="#614021">mdi-calendar-edit</v-icon>
                    Agendar Pacotes de Estética</v-btn
                  >
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Sessão Estética'">
                <v-row class="d-flex justify-center">
                  <v-btn @click="step = 2" color="#c5d13f">
                    <v-icon left color="#614021">mdi-calendar-edit</v-icon>
                    Agendar Sessão de Estética</v-btn
                  >
                </v-row>
              </div>
              <div
                v-else-if="mov.tipo === 'Protocolo Harmonização Corporal 24H'"
              >
                <v-row class="mx-1">
                  <v-col cols="12" md="6">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        :color="isAlertEvent(dataToSetList[0])"
                        @click="chooseDataTime(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDateShort }}, {{ dataToSetList[0].startTime }}-{{ dataToSetList[0].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                        :color="isAlertEvent(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDateShort }}, {{ dataToSetList[1].startTime }}-{{ dataToSetList[1].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div
                v-else-if="mov.tipo === 'Protocolo Detox + Lipodraining 24H'"
              >
                <v-row class="mx-1">
                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                        :color="isAlertEvent(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDateShort }}, {{ dataToSetList[0].startTime }}-{{ dataToSetList[0].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                        :color="isAlertEvent(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDateShort }}, {{ dataToSetList[1].startTime }}-{{ dataToSetList[1].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[2].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[2])"
                        :color="isAlertEvent(dataToSetList[2])"
                      >
                        {{ dataToSetList[2].niceDateShort }}, {{ dataToSetList[2].startTime }}-{{ dataToSetList[2].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Protocolo Lipodraining 24H'">
                <v-row class="d-flex justify-center mx-1">
                  <v-alert type="warning">
                    Não inclui consultas nutricionais
                  </v-alert>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Protocolo Glúteo de Ouro'">
                <v-row class="d-flex justify-center mx-1">
                  <v-alert type="warning">
                    Não inclui consultas nutricionais
                  </v-alert>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Power Shape 30'">
                <v-row class="mx-1">
                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                        :color="isAlertEvent(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDateShort }}, {{ dataToSetList[0].startTime }}-{{ dataToSetList[0].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                        :color="isAlertEvent(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDateShort }}, {{ dataToSetList[1].startTime }}-{{ dataToSetList[1].endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Hoje
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ typesPt[type] }}</span>
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Dia</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Semana</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Mês</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 dias</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600" @mousemove="hourInfoMouseFollow">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  locale="pt"
                  first-time="08:00"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @mousemove:time="getCalendarDayTime"
                  @mousedown:event="startDrag"
                  @mouseup:event="endDrag"
                  @change="getEvents"
                >
                  <template v-slot:event="{ event, eventSummary }">
                    <div
                      class="v-event-draggable"
                      v-html="eventSummary()"
                    ></div>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-row class="d-flex justify-center">
            <v-card width="95%">
              <div v-if="mov.tipo === 'Protocolo Harmonização Corporal 24H'">
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.nr"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                          :color="isAlertEvent(sess)"
                        >
                          {{ sess.niceDateShort }}, {{ sess.startTime }}-{{ sess.endTime }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-if="mov.tipo === 'Protocolo Lipodraining 24H'">
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.id"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                          :color="isAlertEvent(sess)"
                        >
                          {{ sess.niceDateShort }}, {{ sess.startTime }}-{{ sess.endTime }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-if="mov.tipo === 'Protocolo Glúteo de Ouro'">
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.id"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                          :color="isAlertEvent(sess)"
                        >
                          {{ sess.niceDateShort }}, {{ sess.startTime }}-{{ sess.endTime }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-if="mov.tipo === 'Protocolo Detox + Lipodraining 24H'">
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.id"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                          :color="isAlertEvent(sess)"
                        >
                          {{ sess.niceDateShort }}, {{ sess.startTime }}-{{ sess.endTime }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-if="mov.tipo === 'Power Shape 30'">
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.id"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                          :color="isAlertEvent(sess)"
                        >
                          {{ sess.niceDateShort }}, {{ sess.startTime }}-{{ sess.endTime }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Consulta'">
                <v-row class="d-flex justify-center">
                  <v-btn large color="#c5d13f" to="/MeusPacientes">
                    Fechar
                  </v-btn>
                </v-row>
              </div>
              <div
                v-else-if="
                  mov.tipo === 'Pacote Estético' ||
                    mov.tipo === 'Programa 12S com Estética' ||
                    mov.tipo === 'Programa Clínico Funcional com Estética' ||
                    mov.tipo === 'Programa Modulação Intestinal com Estética'
                "
              >
                <div
                  v-for="(referredMesItem, refMesIndex) in referredMesPacList"
                  :key="refMesIndex"
                >
                  <v-row class="text-caption d-flex justify-center">
                    {{ referredMesItem.mesName }}
                  </v-row>
                  <v-row
                    v-for="pac in referredMesItem.pacs"
                    :key="pac.index"
                    class="d-flex justify-center"
                  >
                    <v-card outlined width="95%">
                      <v-card-title class="text-caption d-flex justify-center">
                        {{ pac.def2 }}</v-card-title
                      ><v-card-text>
                        <v-row
                          v-for="sess in pac.sessoes"
                          :key="sess.nr"
                          class="d-flex justify-center"
                        >
                          <v-chip
                            class="d-flex justify-center text-caption my-1"
                            @click="chooseDataTimeE(sess)"
                            :color="isAlertEvent(sess)"
                          >
                            {{ sess.niceDateShort }}, {{ sess.startTime }}-{{ sess.endTime }}
                          </v-chip>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </div>
              </div>
              <div v-else-if="mov.tipo === 'Sessão Estética'">
                <v-row class="mx-1">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTimeE(dataToSetList[0])"
                        :color="isAlertEvent(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDateShort }}, {{ sess.startTime }}-{{ sess.endTime }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div
                v-else-if="
                  mov.tipo === 'Programa 12S Online' ||
                    mov.tipo === 'Programa 12S' ||
                    mov.tipo === 'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)' ||
                    mov.tipo === 'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)'
                "
              >
                <v-row class="d-flex justify-center mx-1">
                  <v-alert type="warning">
                    Não inclui tratamentos estéticos
                  </v-alert>
                </v-row>
              </div>
            </v-card>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Hoje
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ typesPt[type] }}</span>
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Dia</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Semana</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Mês</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 dias</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600" @mousemove="hourInfoMouseFollow">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  locale="pt"
                  first-time="08:00"
                  :events="eventsEstetica"
                  :event-color="getEventColor"
                  :type="type"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @mousemove:time="getCalendarDayTime"
                  @mousedown:event="startDrag"
                  @mouseup:event="endDragEstetica"
                  @change="getEventsEstetica"
                >
                  <template v-slot:event="{ event, eventSummary }">
                    <div
                      class="v-event-draggable"
                      v-html="eventSummary()"
                    ></div>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Agenda",
  data() {
    return {
      referredMesPacList: [],
      pacoteToShow: { def2: "", sessoes: [{ nr: 0, niceDateStr: "" }] },
      eventEdit: {
        name: "1ª Consulta",
        niceDate: "seg.,",
        niceDateShort: "seg.,",
        patient: "Paciente Exemplo",
        //   patientId: this.$store.state.firestore.mov.paciente,
        patientId: "paciente@exemplo.com",
        newPatient: false,
        start: "2021-02-07 8:00:00",
        end: "2021-02-07 9:00:00",
        details: "Agendamento provisório",
        color: "blue",
        timed: true,
        id: "abc",
        startTime: "8:00",
        endTime: "9:00",
      },
      dataToSetList: [
        {
          name: "1ª Consulta",
          niceDate: "seg.,",
          patient: "Paciente Exemplo",
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: "paciente@exemplo.com",
          newPatient: false,
          start: "2021-02-07 8:00:00",
          end: "2021-02-07 9:00:00",
          details: "Agendamento provisório",
          color: "blue",
          timed: true,
          id: "abc",
          startTime: "8:00",
          endTime: "9:00",
        },
        {
          name: "1ª Consulta",
          niceDate: "seg.,",
          patient: "Paciente Exemplo",
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: "paciente@exemplo.com",
          newPatient: false,
          start: "2021-02-07 8:00:00",
          end: "2021-02-07 9:00:00",
          details: "Agendamento provisório",
          color: "blue",
          timed: true,
          id: "abc",
          startTime: "8:00",
          endTime: "9:00",
        },
        {
          name: "1ª Consulta",
          niceDate: "seg.,",
          patient: "Paciente Exemplo",
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: "paciente@exemplo.com",
          newPatient: false,
          start: "2021-02-07 8:00:00",
          end: "2021-02-07 9:00:00",
          details: "Agendamento provisório",
          color: "blue",
          timed: true,
          id: "abc",
          startTime: "8:00",
          endTime: "9:00",
        },
        {
          name: "1ª Consulta",
          niceDate: "seg.,",
          patient: "Paciente Exemplo",
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: "paciente@exemplo.com",
          newPatient: false,
          start: "2021-02-07 8:00:00",
          end: "2021-02-07 9:00:00",
          details: "Agendamento provisório",
          color: "blue",
          timed: true,
          id: "abc",
          startTime: "8:00",
          endTime: "9:00",
        },
      ],
      dataToSetPacM1: [],
      dataToSetPacM2: [],
      dataToSetPacM3: [],
      chooseDateTimeDialogE: false,
      chooseDateTimeDialog: false,
      diasPtBr: ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."],
      atendimentoTipos: [
        "1ª Consulta",
        "2ª Consulta",
        "3ª Consulta",
        "Avaliação Final",
      ],
      atendimentoTiposKirchhoff: [
        "1ª Avaliação Protocolo Kirchhoff",
        "2ª Avaliação Pacote Protocolo Kirchhoff",
        "3ª Avaliação Pacote Protocolo Kirchhoff",
      ],
      atendimentoTiposColor: {
        "1ª Consulta": "#FFB400",
        "Retorno de Consulta Avulsa": "#0000FF",
        "2ª Consulta": "#0000FF",
        "3ª Consulta": "#0000FF",
        "Avaliação Final": "#0000FF",
        Bioimpedância: "#06c980",
        "Coleta de Exames": "#06c980",
        "Leitura de Exames": "#06c980",
        "1ª Avaliação Protocolo Kirchhoff": "#7806c9",
        "2ª Avaliação Pacote Protocolo Kirchhoff": "#c78cf1",
        "3ª Avaliação Pacote Protocolo Kirchhoff": "#c78cf1",
      },
      dateToSet: {
        nome: null,
      },
      horarios: [
        "7:00",
        "7:15",
        "7:30",
        "7:45",
        "8:00",
        "8:15",
        "8:30",
        "8:45",
        "9:00",
        "9:15",
        "9:30",
        "9:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00",
        "16:15",
        "16:30",
        "16:45",
        "17:00",
        "17:15",
        "17:30",
        "17:45",
        "18:00",
        "18:15",
        "18:30",
        "18:45",
        "19:00",
        "19:15",
        "19:30",
        "19:45",
        "20:00",
        "20:15",
        "20:30",
        "20:45",
        "21:00",
        "21:15",
        "21:30",
        "21:45",
        "22:00",
      ],
      diasIndices: [
        -15,
        -14,
        -13,
        -12,
        -11,
        -10,
        -9,
        -8,
        -7,
        -6,
        -5,
        -4,
        -3,
        -2,
        -1,
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
      ],
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
      },
      menu: null,
      menu2: null,
      dragEvent: null,
      niceDateShort: null,
      atendimento: null,
      startTime: null,
      currentTime: "",
      newPatient: false,
      description: null,
      focus: "",
      patient: "",
      patientId: "",
      selectedTime: null,
      selectedEvent: {},
      dialog: false,
      type: "week",
      typesPt: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
        "4day": "4 Dias",
      },
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedElement: null,
      selectedOpen: false,
      editEvent: false,
      events: [],
      eventsEstetica: [],
      // pacotes1month: [
      //   { index: 0, nome: "", sessoes: [{ nr: 0, niceDateStr: "" }] },
      // ],
      // pacotes2month: [
      //   { index: 0, nome: "", sessoes: [{ nr: 0, niceDateStr: "" }] },
      // ],
      // pacotes3month: [
      //   { index: 0, nome: "", sessoes: [{ nr: 0, niceDateStr: "" }] },
      // ],
    };
  },
  computed: {
    mov() {
      return this.$store.state.firestore.mov;
    },
    pacienteId() {
      return this.$store.state.firestore.mov.paciente;
    },
    patientName() {
      return this.$store.state.firestore.mov.pacienteName;
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    dias() {
      if (this.eventEdit.start !== "2021-02-07 8:00:00") {
        var chosenDate = new Date(this.eventEdit.start);
        chosenDate = chosenDate.getTime();
        var list = this.diasIndices.map(function(index) {
          var newDateNr = 86400000 * index + chosenDate;
          var newDate = new Date(newDateNr);
          var diasPtBr = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayName = diasPtBr[newDate.getDay()];
          var dayStr;
          if (newDate.getDate() < 10) {
            dayStr = "0" + newDate.getDate().toString();
          } else {
            dayStr = newDate.getDate().toString();
          }
          var monthStr;
          var month = newDate.getMonth() + 1;
          if (month < 10) {
            monthStr = "0" + month.toString();
          } else {
            monthStr = month.toString();
          }
          var year = newDate.getYear() - 100;
          var yearStr = year.toString();

          return dayName + ", " + dayStr + "/" + monthStr + "/" + yearStr;
        });
        return list;
      } else {
        var d = new Date();
        var currentDate = d.getTime();
        var listCurrent = this.diasIndices.map(function(index) {
          var newDateNr = 86400000 * index + currentDate;
          var newDate = new Date(newDateNr);
          var diasPtBr = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayName = diasPtBr[newDate.getDay()];
          var dayStr;
          if (newDate.getDate() < 10) {
            dayStr = "0" + newDate.getDate().toString();
          } else {
            dayStr = newDate.getDate().toString();
          }
          var monthStr;
          var month = newDate.getMonth() + 1;
          if (month < 10) {
            monthStr = "0" + month.toString();
          } else {
            monthStr = month.toString();
          }
          var year = newDate.getYear() - 100;
          var yearStr = year.toString();

          return dayName + ", " + dayStr + "/" + monthStr + "/" + yearStr;
        });
        return listCurrent;
      }
    },
    chosenDataYMD() {
      var splits = this.niceDateShort.split("/");
      var year = splits[2];
      var month = splits[1];
      var days = splits[0];
      return "20" + year + "-" + month + "-" + days.substring(6);
    },
    endTime() {
      if (
        this.startTime != null &&
        this.atendimento == this.atendimentoTipos[0]
      ) {
        var splits = this.startTime.split(":");
        var hours = parseInt(splits[0]);
        hours += 1;
        var newEndTime = hours
          .toString()
          .concat(":")
          .concat(splits[1]);
        return newEndTime;
      } else if (
        this.startTime != null &&
        this.atendimento != this.atendimentoTipos[0]
      ) {
        var splitsR = this.startTime.split(":");
        var hoursR = parseInt(splitsR[0]);
        var minutesR = parseInt(splitsR[1]);
        minutesR += 30;
        if (minutesR > 59) {
          minutesR -= 60;
          hoursR += 1;
        }
        if (minutesR == 0) {
          minutesR = "00";
        }
        var newEndTimeR = hoursR
          .toString()
          .concat(":")
          .concat(minutesR.toString());
        return newEndTimeR;
      } else {
        return null;
      }
    },
  },
  methods: {
    isAlertEvent(evDoc) {
      if (evDoc.alertEvent !== undefined) {
        if (evDoc.alertEvent) {
          return "red";
        } else {
          return "grey";
        }
      } else {
        return "grey";
      }
    },
    async mountingFunction() {
      // function roundTimeQuarterHour(time) {
      //   var timeToReturn = new Date(time);

      //   timeToReturn.setMilliseconds(
      //     Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000
      //   );
      //   timeToReturn.setSeconds(Math.ceil(timeToReturn.getSeconds() / 60) * 60);
      //   timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);
      //   return timeToReturn;
      // }

      // var aDate = new Date();
      // var aRoundedDate = roundTimeQuarterHour(aDate);
      console.log("mov", this.mov);
      var movPaciente = this.mov.paciente;
      var movPacienteName = this.mov.pacienteName;
      var movMillis = this.mov.millis.toString();
      const eventsPac = this.events.filter(function(a) {
        return a.patientId == movPaciente;
      });
      const eventsEstetPac = this.eventsEstetica.filter(function(a) {
        return a.patientId == movPaciente;
      });
      console.log("eventsPac", eventsPac);

      var filterForEvent = function(name, area) {
        var eventList = eventsEstetPac.filter(function(e) {
          return e.name == name;
        });

        var alertDocEstet = {
          name: name,
          niceDate: "??/??/???? ??:??",
          niceDateShort: "??/??/??",
          patient: movPacienteName,
          patientId: movPaciente,
          newPatient: false,
          start: null,
          end: null,
          details: area,
          color: "blue",
          timed: true,
          id: null,
          startTime: null,
          endTime: null,
          autoset: true,
          area: area,
          alertEvent: true,
        };

        if (eventList.length > 0) {
          return eventList[0];
        } else {
          return alertDocEstet;
        }
      };

      if (
        this.mov.tipo == "Programa Clínico Funcional" ||
        this.mov.tipo == "Programa Clínico Funcional (plano família)"
      ) {
        for (var i = 0; i < 4; i++) {
          var nome = this.atendimentoTipos[i];
          var gotEvent;

          var alertDoc = {
            name: this.atendimentoTipos[i],
            niceDate: "??/??/???? ??:??",
            niceDateShort: "??/??/??",
            patient: this.mov.pacienteName,
            patientId: this.mov.paciente,
            newPatient: false,
            start: null,
            end: null,
            details: "Clínico Funcional",
            color: "red",
            timed: true,
            id: null,
            startTime: null,
            endTime: null,
          autoset: true,
            alertEvent: true,
          };

          var eventsPacName = eventsPac.filter(function(a) {
            if (a.movMillis == undefined) {
              return a.name == nome && a.details == "Clínico Funcional";
            } else {
              return a.name == nome && a.movMillis == movMillis;
            }
          });

          if (eventsPacName.length > 0) {
            gotEvent = eventsPacName[0];
          } else {
            gotEvent = alertDoc;
          }

          this.dataToSetList[i] = gotEvent;
        }
      } else if (
        this.mov.tipo == "Programa Modulação Intestinal" ||
        this.mov.tipo == "Programa Modulação Intestinal (plano família)"
      ) {
        for (var j = 0; j < 4; j++) {
          var nomeJ = this.atendimentoTipos[j];
          var gotEventJ;

          var alertDocJ = {
            name: this.atendimentoTipos[j],
            niceDate: "??/??/???? ??:??",
            niceDateShort: "??/??/??",
            patient: this.mov.pacienteName,
            patientId: this.mov.paciente,
            newPatient: false,
            start: null,
            end: null,
            details: "Modulação Intestinal",
            color: "red",
            timed: true,
            id: null,
            startTime: null,
            endTime: null,
          autoset: true,
            alertEvent: true,
          };

          var eventsPacNameJ = eventsPac.filter(function(a) {
            // return a.name == nomeJ && a.details == "Modulação Intestinal";
            if (a.movMillis == undefined) {
              return a.name == nomeJ && a.details == "Modulação Intestinal";
            } else {
              return a.name == nomeJ && a.movMillis == movMillis;
            }
          });

          if (eventsPacNameJ.length > 0) {
            gotEventJ = eventsPacNameJ[0];
          } else {
            gotEventJ = alertDocJ;
          }

          this.dataToSetList[j] = gotEventJ;
        }
      } else if (
        this.mov.tipo == "Programa 12S Online" ||
        this.mov.tipo == "Programa 12S" ||
        this.mov.tipo === 'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)' ||
        this.mov.tipo === 'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)'
      ) {
        for (var iPo = 0; iPo < 4; iPo++) {
          var nomeiPo = this.atendimentoTipos[iPo];
          var gotEventiPo;

          var alertDociPo = {
            name: this.atendimentoTipos[iPo],
            niceDate: "??/??/???? ??:??",
            niceDateShort: "??/??/??",
            patient: this.mov.pacienteName,
            patientId: this.mov.paciente,
            newPatient: false,
            start: null,
            end: null,
            details: "12S Básico",
            color: "red",
            timed: true,
            id: null,
            startTime: null,
            endTime: null,
          autoset: true,
            alertEvent: true,
          };

          var eventsPacNameiPo = eventsPac.filter(function(a) {
            // return a.name == nomeiPo && a.details == "12S Básico";
            if (a.movMillis == undefined) {
              return a.name == nomeiPo && a.details == "12S Básico";
            } else {
              return a.name == nomeiPo && a.movMillis == movMillis;
            }
          });

          if (eventsPacNameiPo.length > 0) {
            gotEventiPo = eventsPacNameiPo[0];
          } else {
            gotEventiPo = alertDociPo;
          }

          this.dataToSetList[iPo] = gotEventiPo;
        }
      } else if (this.mov.tipo === "Pacote Estético") {
        console.log("mov", this.mov);
        console.log("mov.pacotes", this.mov.pacotes);

        var monthsFromNowList = [];
        var sessoesList = [];

        for (var iPx = 0; iPx < this.mov.pacotes.length; iPx++) {
          var sessoes1pac = this.mov.pacotes[iPx];
          var monthsFromNow = parseInt(sessoes1pac.dataInicio);

          if (sessoes1pac.def1 == "TRIPLO") {
            sessoes1pac.area =
              sessoes1pac.area1 +
              " + " +
              sessoes1pac.area2 +
              " + " +
              sessoes1pac.area3;
          } else if (sessoes1pac.def1 == "DUO") {
            sessoes1pac.area = sessoes1pac.area1; // + " + " + sessoes1pac.area2;
          } else if (sessoes1pac.def1 == "UNO") {
            sessoes1pac.area = sessoes1pac.area1;
          }

          if (sessoes1pac.def2 == "HIBRIUS") {
            sessoes1pac.sessoes = [
              filterForEvent("HIBRIUS + Massagem (1)", sessoes1pac.area),
              filterForEvent("HIBRIUS + Massagem (2)", sessoes1pac.area),
              filterForEvent("HIBRIUS + Massagem (3)", sessoes1pac.area),
              filterForEvent("HIBRIUS + Massagem (4)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "DUALSOON") {
            sessoes1pac.sessoes = [
              filterForEvent("DUALSOON (1)", sessoes1pac.area),
              filterForEvent("DUALSOON (2)", sessoes1pac.area),
              filterForEvent("DUALSOON (3)", sessoes1pac.area),
              filterForEvent("DUALSOON (4)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "ENDERMOTERAPIA") {
            sessoes1pac.sessoes = [
              filterForEvent("ENDERMOTERAPIA (1)", sessoes1pac.area),
              filterForEvent("ENDERMOTERAPIA (2)", sessoes1pac.area),
              filterForEvent("ENDERMOTERAPIA (3)", sessoes1pac.area),
              filterForEvent("ENDERMOTERAPIA (4)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "DRENAGEM LINFATICA") {
            sessoes1pac.sessoes = [
              filterForEvent("DRENAGEM LINFATICA (1)", sessoes1pac.area),
              filterForEvent("DRENAGEM LINFATICA (2)", sessoes1pac.area),
              filterForEvent("DRENAGEM LINFATICA (3)", sessoes1pac.area),
              filterForEvent("DRENAGEM LINFATICA (4)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "MASSAGEM RELAXANTE") {
            sessoes1pac.sessoes = [
              filterForEvent("MASSAGEM RELAXANTE (1)", sessoes1pac.area),
              filterForEvent("MASSAGEM RELAXANTE (2)", sessoes1pac.area),
              filterForEvent("MASSAGEM RELAXANTE (3)", sessoes1pac.area),
              filterForEvent("MASSAGEM RELAXANTE (4)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "REFLEXOLOGIA PODAL (10 sessões)") {
            sessoes1pac.sessoes = [
              filterForEvent("REFLEXOLOGIA PODAL (1)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL (2)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL (3)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL (4)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL (5)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL (6)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL (7)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL (8)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL (9)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL (10)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)") {
            sessoes1pac.sessoes = [
              filterForEvent("REFLEXOLOGIA PODAL + ESCALDA PES (1)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL + ESCALDA PES (2)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL + ESCALDA PES (3)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL + ESCALDA PES (4)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL + ESCALDA PES (5)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL + ESCALDA PES (6)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL + ESCALDA PES (7)", sessoes1pac.area),
              filterForEvent("REFLEXOLOGIA PODAL + ESCALDA PES (8)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "TERAPIA COM VENTOSAS (10 sessões)") {
            sessoes1pac.sessoes = [
              filterForEvent("TERAPIA COM VENTOSAS (1)", sessoes1pac.area),
              filterForEvent("TERAPIA COM VENTOSAS (2)", sessoes1pac.area),
              filterForEvent("TERAPIA COM VENTOSAS (3)", sessoes1pac.area),
              filterForEvent("TERAPIA COM VENTOSAS (4)", sessoes1pac.area),
              filterForEvent("TERAPIA COM VENTOSAS (5)", sessoes1pac.area),
              filterForEvent("TERAPIA COM VENTOSAS (6)", sessoes1pac.area),
              filterForEvent("TERAPIA COM VENTOSAS (7)", sessoes1pac.area),
              filterForEvent("TERAPIA COM VENTOSAS (8)", sessoes1pac.area),
              filterForEvent("TERAPIA COM VENTOSAS (9)", sessoes1pac.area),
              filterForEvent("TERAPIA COM VENTOSAS (10)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "DRY NEEDLING (10 sessões)") {
            sessoes1pac.sessoes = [
              filterForEvent("DRY NEEDLING (1)", sessoes1pac.area),
              filterForEvent("DRY NEEDLING (2)", sessoes1pac.area),
              filterForEvent("DRY NEEDLING (3)", sessoes1pac.area),
              filterForEvent("DRY NEEDLING (4)", sessoes1pac.area),
              filterForEvent("DRY NEEDLING (5)", sessoes1pac.area),
              filterForEvent("DRY NEEDLING (6)", sessoes1pac.area),
              filterForEvent("DRY NEEDLING (7)", sessoes1pac.area),
              filterForEvent("DRY NEEDLING (8)", sessoes1pac.area),
              filterForEvent("DRY NEEDLING (9)", sessoes1pac.area),
              filterForEvent("DRY NEEDLING (10)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "AURICULOTERAPIA (10 sessões)") {
            sessoes1pac.sessoes = [
              filterForEvent("AURICULOTERAPIA (1)", sessoes1pac.area),
              filterForEvent("AURICULOTERAPIA (2)", sessoes1pac.area),
              filterForEvent("AURICULOTERAPIA (3)", sessoes1pac.area),
              filterForEvent("AURICULOTERAPIA (4)", sessoes1pac.area),
              filterForEvent("AURICULOTERAPIA (5)", sessoes1pac.area),
              filterForEvent("AURICULOTERAPIA (6)", sessoes1pac.area),
              filterForEvent("AURICULOTERAPIA (7)", sessoes1pac.area),
              filterForEvent("AURICULOTERAPIA (8)", sessoes1pac.area),
              filterForEvent("AURICULOTERAPIA (9)", sessoes1pac.area),
              filterForEvent("AURICULOTERAPIA (10)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "MASSAGEM REDUTORA") {
            sessoes1pac.sessoes = [
              filterForEvent("MASSAGEM REDUTORA (1)", sessoes1pac.area),
              filterForEvent("MASSAGEM REDUTORA (2)", sessoes1pac.area),
              filterForEvent("MASSAGEM REDUTORA (3)", sessoes1pac.area),
              filterForEvent("MASSAGEM REDUTORA (4)", sessoes1pac.area),
              filterForEvent("MASSAGEM REDUTORA (5)", sessoes1pac.area),
              filterForEvent("MASSAGEM REDUTORA (6)", sessoes1pac.area),
              filterForEvent("MASSAGEM REDUTORA (7)", sessoes1pac.area),
              filterForEvent("MASSAGEM REDUTORA (8)", sessoes1pac.area),
            ];
          } else if (sessoes1pac.def2 == "CORRENTE RUSSA") {
            sessoes1pac.sessoes = [
              filterForEvent("CORRENTE RUSSA (1)", sessoes1pac.area),
              filterForEvent("CORRENTE RUSSA (2)", sessoes1pac.area),
              filterForEvent("CORRENTE RUSSA (3)", sessoes1pac.area),
              filterForEvent("CORRENTE RUSSA (4)", sessoes1pac.area),
              filterForEvent("CORRENTE RUSSA (5)", sessoes1pac.area),
              filterForEvent("CORRENTE RUSSA (6)", sessoes1pac.area),
              filterForEvent("CORRENTE RUSSA (7)", sessoes1pac.area),
              filterForEvent("CORRENTE RUSSA (8)", sessoes1pac.area),
            ];
          } else {
            sessoes1pac.sessoes = [];
          }
          monthsFromNowList.push(monthsFromNow);
          sessoesList.push(sessoes1pac);
        }

        this.referredMesPacList = monthsFromNowList
          .filter(function(item, pos) {
            return monthsFromNowList.indexOf(item) == pos;
          })
          .map(function(mfN) {
            return {
              mesName: mfN,
              pacs: sessoesList.filter(function(el) {
                return el.dataInicio == mfN;
              }),
            };
          });
      } else if (
        this.mov.tipo === "Programa 12S com Estética" ||
        this.mov.tipo === "Programa Clínico Funcional com Estética" ||
        this.mov.tipo === "Programa Modulação Intestinal com Estética"
      ) {
        for (var iPo12 = 0; iPo12 < 4; iPo12++) {
          var nomeiPo12 = this.atendimentoTipos[iPo12];
          var gotEventiPo12;

          var alertDociPo12 = {
            name: this.atendimentoTipos[iPo12],
            niceDate: "??/??/???? ??:??",
            niceDateShort: "??/??/??",
            patient: this.mov.pacienteName,
            patientId: this.mov.paciente,
            newPatient: false,
            start: null,
            end: null,
            details: "12S",
            color: "red",
            timed: true,
            id: null,
            startTime: null,
            endTime: null,
          autoset: true,
            alertEvent: true,
          };

          var eventsPacNameiPo12 = eventsPac.filter(function(a) {
            // return a.name == nomeiPo12 && a.details == "12S";
            if (a.movMillis == undefined) {
              return a.name == nomeiPo12; // && a.details == "12S";
            } else {
              return a.name == nomeiPo12 && a.movMillis == movMillis;
            }
          });

          if (eventsPacNameiPo12.length > 0) {
            gotEventiPo12 = eventsPacNameiPo12[0];
          } else {
            gotEventiPo12 = alertDociPo12;
          }

          this.dataToSetList[iPo12] = gotEventiPo12;
        }

        var monthsFromNowList12s = [];
        var sessoesList12s = [];

        for (var iP12x = 0; iP12x < this.mov.pacotes.length; iP12x++) {
          var sessoes1pac12s = this.mov.pacotes[iP12x];
          var monthsFromNow12s = parseInt(sessoes1pac12s.dataInicio);

          if (sessoes1pac12s.def1 == "TRIPLO") {
            sessoes1pac12s.area =
              sessoes1pac12s.area1 +
              " + " +
              sessoes1pac12s.area2 +
              " + " +
              sessoes1pac12s.area3;
          } else if (sessoes1pac12s.def1 == "DUO") {
            sessoes1pac12s.area = sessoes1pac12s.area1; // + " + " + sessoes1pac12s.area2;
          } else if (sessoes1pac12s.def1 == "UNO") {
            sessoes1pac12s.area = sessoes1pac12s.area1;
          }

          if (sessoes1pac12s.def2 == "HIBRIUS") {
            sessoes1pac12s.sessoes = [
              filterForEvent("HIBRIUS + Massagem (1)", sessoes1pac12s.area),
              filterForEvent("HIBRIUS + Massagem (2)", sessoes1pac12s.area),
              filterForEvent("HIBRIUS + Massagem (3)", sessoes1pac12s.area),
              filterForEvent("HIBRIUS + Massagem (4)", sessoes1pac12s.area),
            ];
          } else if (sessoes1pac12s.def2 == "DUALSOON") {
            sessoes1pac12s.sessoes = [
              filterForEvent("DUALSOON (1)", sessoes1pac12s.area),
              filterForEvent("DUALSOON (2)", sessoes1pac12s.area),
              filterForEvent("DUALSOON (3)", sessoes1pac12s.area),
              filterForEvent("DUALSOON (4)", sessoes1pac12s.area),
            ];
          } else if (sessoes1pac12s.def2 == "ENDERMOTERAPIA") {
            sessoes1pac12s.sessoes = [
              filterForEvent("ENDERMOTERAPIA (1)", sessoes1pac12s.area),
              filterForEvent("ENDERMOTERAPIA (2)", sessoes1pac12s.area),
              filterForEvent("ENDERMOTERAPIA (3)", sessoes1pac12s.area),
              filterForEvent("ENDERMOTERAPIA (4)", sessoes1pac12s.area),
            ];
          } else if (sessoes1pac12s.def2 == "DRENAGEM LINFATICA") {
            sessoes1pac12s.sessoes = [
              filterForEvent("DRENAGEM LINFATICA (1)", sessoes1pac12s.area),
              filterForEvent("DRENAGEM LINFATICA (2)", sessoes1pac12s.area),
              filterForEvent("DRENAGEM LINFATICA (3)", sessoes1pac12s.area),
              filterForEvent("DRENAGEM LINFATICA (4)", sessoes1pac12s.area),
            ];
          } else if (sessoes1pac12s.def2 == "MASSAGEM RELAXANTE") {
            sessoes1pac12s.sessoes = [
              filterForEvent("MASSAGEM RELAXANTE (1)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM RELAXANTE (2)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM RELAXANTE (3)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM RELAXANTE (4)", sessoes1pac12s.area),
            ];
          } else if (sessoes1pac12s.def2 == "REFLEXOLOGIA PODAL") {
            sessoes1pac12s.sessoes = [
              filterForEvent("REFLEXOLOGIA PODAL (1)", sessoes1pac12s.area),
              filterForEvent("REFLEXOLOGIA PODAL (2)", sessoes1pac12s.area),
              filterForEvent("REFLEXOLOGIA PODAL (3)", sessoes1pac12s.area),
              filterForEvent("REFLEXOLOGIA PODAL (4)", sessoes1pac12s.area),
              filterForEvent("REFLEXOLOGIA PODAL (5)", sessoes1pac12s.area),
              filterForEvent("REFLEXOLOGIA PODAL (6)", sessoes1pac12s.area),
              filterForEvent("REFLEXOLOGIA PODAL (7)", sessoes1pac12s.area),
              filterForEvent("REFLEXOLOGIA PODAL (8)", sessoes1pac12s.area),
            ];
          } else if (sessoes1pac12s.def2 == "MASSAGEM REDUTORA") {
            sessoes1pac12s.sessoes = [
              filterForEvent("MASSAGEM REDUTORA (1)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM REDUTORA (2)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM REDUTORA (3)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM REDUTORA (4)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM REDUTORA (5)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM REDUTORA (6)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM REDUTORA (7)", sessoes1pac12s.area),
              filterForEvent("MASSAGEM REDUTORA (8)", sessoes1pac12s.area),
            ];
          } else if (sessoes1pac12s.def2 == "CORRENTE RUSSA") {
            sessoes1pac12s.sessoes = [
              filterForEvent("CORRENTE RUSSA (1)", sessoes1pac12s.area),
              filterForEvent("CORRENTE RUSSA (2)", sessoes1pac12s.area),
              filterForEvent("CORRENTE RUSSA (3)", sessoes1pac12s.area),
              filterForEvent("CORRENTE RUSSA (4)", sessoes1pac12s.area),
              filterForEvent("CORRENTE RUSSA (5)", sessoes1pac12s.area),
              filterForEvent("CORRENTE RUSSA (6)", sessoes1pac12s.area),
              filterForEvent("CORRENTE RUSSA (7)", sessoes1pac12s.area),
              filterForEvent("CORRENTE RUSSA (8)", sessoes1pac12s.area),
            ];
          } else {
            sessoes1pac12s.sessoes = [];
          }
          monthsFromNowList12s.push(monthsFromNow12s);
          sessoesList12s.push(sessoes1pac12s);
        }

        this.referredMesPacList = monthsFromNowList12s
          .filter(function(item, pos) {
            return monthsFromNowList12s.indexOf(item) == pos;
          })
          .map(function(mfN) {
            return {
              mesName: mfN,
              pacs: sessoesList12s.filter(function(el) {
                return el.dataInicio == mfN;
              }),
            };
          });
      } else if (this.mov.tipo === "Pacote 90 dias Protocolo Kirchhoff") {
        for (var jk = 0; jk < 3; jk++) {
          var nomeJk = this.atendimentoTiposKirchhoff[jk];
          var gotEventJk;

          var alertDocJk = {
            name: nomeJk,
            niceDate: "??/??/???? ??:??",
            niceDateShort: "??/??/??",
            patient: this.mov.pacienteName,
            patientId: this.mov.paciente,
            newPatient: false,
            start: null,
            end: null,
            details: "Protocolo Kirchhoff",
            color: "red",
            timed: true,
            id: null,
            startTime: null,
            endTime: null,
          autoset: true,
            alertEvent: true,
          };

          var eventsPacNameJk = eventsPac.filter(function(a) {
            // return a.name == nomeJk && a.details == "Protocolo Kirchhoff";
            if (a.movMillis == undefined) {
              return a.name == nomeJk && a.details == "Protocolo Kirchhoff";
            } else {
              return a.name == nomeJk && a.movMillis == movMillis;
            }
          });

          if (eventsPacNameJk.length > 0) {
            gotEventJk = eventsPacNameJk[0];
          } else {
            gotEventJk = alertDocJk;
          }

          this.dataToSetList[jk] = gotEventJk;
        }
      } else if (this.mov.tipo === "Protocolo Harmonização Corporal 24H") {
        for (var iP_H = 0; iP_H < 2; iP_H++) {
          var HatendimentoTipos = [
            "1ª Consulta Protocolo Harmonização Corporal 24H",
            "Avaliação Final Protocolo Harmonização Corporal 24H",
          ];
          var nomeP_H = HatendimentoTipos[iP_H];
          var gotEventP_H;

          var alertDocP_H = {
            name: nomeP_H,
            niceDate: "??/??/???? ??:??",
            niceDateShort: "??/??/??",
            patient: this.mov.pacienteName,
            patientId: this.mov.paciente,
            newPatient: false,
            start: null,
            end: null,
            details: "Protocolo Harmonização Corporal 24H",
            color: "red",
            timed: true,
            id: null,
            startTime: null,
            endTime: null,
          autoset: true,
            alertEvent: true,
          };

          var eventsPacNameP_H = eventsPac.filter(function(a) {
            // return a.name == nomeP_H && a.details == "Protocolo Harmonização Corporal 24H";
            if (a.movMillis == undefined) {
              return (
                a.name == nomeP_H &&
                a.details == "Protocolo Harmonização Corporal 24H"
              );
            } else {
              return a.name == nomeP_H && a.movMillis == movMillis;
            }
          });

          if (eventsPacNameP_H.length > 0) {
            gotEventP_H = eventsPacNameP_H[0];
          } else {
            gotEventP_H = alertDocP_H;
          }

          this.dataToSetList[iP_H] = gotEventP_H;
        }
        var sessoes1P_H = this.mov.pacotes[0];
        var areaStrP_H = sessoes1P_H.area1 + " + " + sessoes1P_H.area2;

        sessoes1P_H.sessoes = [
          filterForEvent("Massagem Modeladora (1)", areaStrP_H),
          filterForEvent("Massagem Modeladora (2)", areaStrP_H),
          filterForEvent("Massagem Modeladora (3)", areaStrP_H),
          filterForEvent("Massagem Modeladora (4)", areaStrP_H),
          filterForEvent("Massagem Modeladora (5)", areaStrP_H),
          filterForEvent("Massagem Modeladora (6)", areaStrP_H),
          filterForEvent("Massagem Modeladora (7)", areaStrP_H),
          filterForEvent("Massagem Modeladora (8)", areaStrP_H),
        ];

        this.pacoteToShow = sessoes1P_H;
      } else if (this.mov.tipo === "Protocolo Detox + Lipodraining 24H") {
        for (var iP_dl = 0; iP_dl < 3; iP_dl++) {
          var DlatendimentoTipos = [
            "1ª Consulta Protocolo Detox + Lipodraining 24H",
            "Retorno Protocolo Detox + Lipodraining 24H",
            "Avaliação Final Protocolo Detox + Lipodraining 24H",
          ];
          var nomeP_dl = DlatendimentoTipos[iP_dl];
          var gotEventP_dl;

          var alertDocP_dl = {
            name: nomeP_dl,
            niceDate: "??/??/???? ??:??",
            niceDateShort: "??/??/??",
            patient: this.mov.pacienteName,
            patientId: this.mov.paciente,
            newPatient: false,
            start: null,
            end: null,
            details: "Protocolo Detox + Lipodraining 24H",
            color: "red",
            timed: true,
            id: null,
            startTime: null,
            endTime: null,
          autoset: true,
            alertEvent: true,
          };

          var eventsPacNameP_dl = eventsPac.filter(function(a) {
            // return a.name == nomeP_dl && a.details == "Protocolo Harmonização Corporal 24H";
            if (a.movMillis == undefined) {
              return (
                a.name == nomeP_dl &&
                a.details == "Protocolo Detox + Lipodraining 24H"
              );
            } else {
              return a.name == nomeP_dl && a.movMillis == movMillis;
            }
          });

          if (eventsPacNameP_dl.length > 0) {
            gotEventP_dl = eventsPacNameP_dl[0];
          } else {
            gotEventP_dl = alertDocP_dl;
          }

          this.dataToSetList[iP_dl] = gotEventP_dl;
        }

        var sessoes1P_dl = this.mov.pacotes[0];
        var areaStrP_dl;
        if (this.mov.pacotes[0].def1 == "2 áreas") {
          areaStrP_dl = sessoes1P_dl.area1 + " + " + sessoes1P_dl.area2;

          sessoes1P_dl.sessoes = [
            filterForEvent("Drenagem Linfática (1)", areaStrP_dl),
            filterForEvent("Drenagem Linfática (2)", areaStrP_dl),
            filterForEvent("Drenagem Linfática (3", areaStrP_dl),
            filterForEvent("Drenagem Linfática (4)", areaStrP_dl),
            filterForEvent("Drenagem Linfática (5)", areaStrP_dl),
            filterForEvent("Drenagem Linfática (6)", areaStrP_dl),
            filterForEvent("Drenagem Linfática (7)", areaStrP_dl),
            filterForEvent("Drenagem Linfática (8)", areaStrP_dl),
          ];

          this.pacoteToShow = sessoes1P_dl;
        } else {
          areaStrP_dl = "Corpo inteiro";

          sessoes1P_dl.sessoes = [
            filterForEvent("Drenagem Linfática Corporal (1)", areaStrP_dl),
            filterForEvent("Drenagem Linfática Corporal (2)", areaStrP_dl),
            filterForEvent("Drenagem Linfática Corporal (3)", areaStrP_dl),
            filterForEvent("Drenagem Linfática Corporal (4)", areaStrP_dl),
          ];

          this.pacoteToShow = sessoes1P_dl;
        }
      } else if (this.mov.tipo === "Power Shape 30") {
        for (var iPs30 = 0; iPs30 < 2; iPs30++) {
          var Ps30atendimentoTipos = [
            "Bioimpedância inicial Power Shape 30",
            "Bioimpedância final Power Shape 30",
          ];
          var nomePs30 = Ps30atendimentoTipos[iPs30];
          var gotEventPs30;

          var alertDocPs30 = {
            name: nomePs30,
            niceDate: "??/??/???? ??:??",
            niceDateShort: "??/??/??",
            patient: this.mov.pacienteName,
            patientId: this.mov.paciente,
            newPatient: false,
            start: null,
            end: null,
            details: "Power Shape 30",
            color: "red",
            timed: true,
            id: null,
            startTime: null,
            endTime: null,
          autoset: true,
            alertEvent: true,
          };

          var eventsPacNamePs30 = eventsPac.filter(function(a) {
            // return a.name == nomePs30 && a.details == "Protocolo Harmonização Corporal 24H";
            if (a.movMillis == undefined) {
              return a.name == nomePs30 && a.details == "Power Shape 30";
            } else {
              return a.name == nomePs30 && a.movMillis == movMillis;
            }
          });

          if (eventsPacNamePs30.length > 0) {
            gotEventPs30 = eventsPacNamePs30[0];
          } else {
            gotEventPs30 = alertDocPs30;
          }

          this.dataToSetList[iPs30] = gotEventPs30;
        }

        var sessoes1Ps30 = this.mov.pacotes[0];
        var areaStrPs30 = sessoes1Ps30.area1;

        // var monthsFromNowPs30 = parseInt(sessoes1Ps30.dataInicio);

        if (sessoes1Ps30.def2 == "HIBRIUS") {
          sessoes1Ps30.sessoes = [
            filterForEvent("HIBRIUS + Massagem (1)", areaStrPs30),
            filterForEvent("HIBRIUS + Massagem (2)", areaStrPs30),
            filterForEvent("HIBRIUS + Massagem (3)", areaStrPs30),
            filterForEvent("HIBRIUS + Massagem (4)", areaStrPs30),
          ];
        } else if (sessoes1Ps30.def2 == "DUALSOON") {
          sessoes1Ps30.sessoes = [
            filterForEvent("DUALSOON (1)", areaStrPs30),
            filterForEvent("DUALSOON (2)", areaStrPs30),
            filterForEvent("DUALSOON (3)", areaStrPs30),
            filterForEvent("DUALSOON (4)", areaStrPs30),
          ];
        } else if (sessoes1Ps30.def2 == "ENDERMOTERAPIA") {
          sessoes1Ps30.sessoes = [
            filterForEvent("ENDERMOTERAPIA (1)", areaStrPs30),
            filterForEvent("ENDERMOTERAPIA (2)", areaStrPs30),
            filterForEvent("ENDERMOTERAPIA (3)", areaStrPs30),
            filterForEvent("ENDERMOTERAPIA (4)", areaStrPs30),
          ];
        } else if (sessoes1Ps30.def2 == "DRENAGEM LINFATICA") {
          sessoes1Ps30.sessoes = [
            filterForEvent("DRENAGEM LINFATICA (1)", areaStrPs30),
            filterForEvent("DRENAGEM LINFATICA (2)", areaStrPs30),
            filterForEvent("DRENAGEM LINFATICA (3)", areaStrPs30),
            filterForEvent("DRENAGEM LINFATICA (4)", areaStrPs30),
          ];
        } else if (sessoes1Ps30.def2 == "MASSAGEM RELAXANTE") {
          sessoes1Ps30.sessoes = [
            filterForEvent("MASSAGEM RELAXANTE (1)", areaStrPs30),
            filterForEvent("MASSAGEM RELAXANTE (2)", areaStrPs30),
            filterForEvent("MASSAGEM RELAXANTE (3)", areaStrPs30),
            filterForEvent("MASSAGEM RELAXANTE (4)", areaStrPs30),
          ];
        } else if (sessoes1Ps30.def2 == "REFLEXOLOGIA PODAL") {
          sessoes1Ps30.sessoes = [
            filterForEvent("REFLEXOLOGIA PODAL (1)", areaStrPs30),
            filterForEvent("REFLEXOLOGIA PODAL (2)", areaStrPs30),
            filterForEvent("REFLEXOLOGIA PODAL (3)", areaStrPs30),
            filterForEvent("REFLEXOLOGIA PODAL (4)", areaStrPs30),
            filterForEvent("REFLEXOLOGIA PODAL (5)", areaStrPs30),
            filterForEvent("REFLEXOLOGIA PODAL (6)", areaStrPs30),
            filterForEvent("REFLEXOLOGIA PODAL (7)", areaStrPs30),
            filterForEvent("REFLEXOLOGIA PODAL (8)", areaStrPs30),
          ];
        } else if (sessoes1Ps30.def2 == "MASSAGEM REDUTORA") {
          sessoes1Ps30.sessoes = [
            filterForEvent("MASSAGEM REDUTORA (1)", areaStrPs30),
            filterForEvent("MASSAGEM REDUTORA (2)", areaStrPs30),
            filterForEvent("MASSAGEM REDUTORA (3)", areaStrPs30),
            filterForEvent("MASSAGEM REDUTORA (4)", areaStrPs30),
            filterForEvent("MASSAGEM REDUTORA (5)", areaStrPs30),
            filterForEvent("MASSAGEM REDUTORA (6)", areaStrPs30),
            filterForEvent("MASSAGEM REDUTORA (7)", areaStrPs30),
            filterForEvent("MASSAGEM REDUTORA (8)", areaStrPs30),
          ];
        } else if (sessoes1Ps30.def2 == "CORRENTE RUSSA") {
          sessoes1Ps30.sessoes = [
            filterForEvent("CORRENTE RUSSA (1)", areaStrPs30),
            filterForEvent("CORRENTE RUSSA (2)", areaStrPs30),
            filterForEvent("CORRENTE RUSSA (3)", areaStrPs30),
            filterForEvent("CORRENTE RUSSA (4)", areaStrPs30),
            filterForEvent("CORRENTE RUSSA (5)", areaStrPs30),
            filterForEvent("CORRENTE RUSSA (6)", areaStrPs30),
            filterForEvent("CORRENTE RUSSA (7)", areaStrPs30),
            filterForEvent("CORRENTE RUSSA (8)", areaStrPs30),
          ];
        } else {
          sessoes1Ps30.sessoes = [];
        }

        this.pacoteToShow = sessoes1Ps30;
      } else if (this.mov.tipo === "Protocolo Lipodraining 24H") {
        var sessoes1P_dl0 = this.mov.pacotes[0];
        var areaStrP_dl0;
        if (this.mov.pacotes[0].def1 == "2 áreas") {
          areaStrP_dl0 = sessoes1P_dl0.area1 + " + " + sessoes1P_dl0.area2;

          sessoes1P_dl0.sessoes = [
            filterForEvent("Drenagem Linfática (1)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática (2)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática (3)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática (4)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática (5)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática (6)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática (7)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática (8)", areaStrP_dl0),
          ];

          this.pacoteToShow = sessoes1P_dl0;
        } else {
          areaStrP_dl0 = "Corpo inteiro";

          sessoes1P_dl.sessoes = [
            filterForEvent("Drenagem Linfática Corporal (1)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática Corporal (2)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática Corporal (3)", areaStrP_dl0),
            filterForEvent("Drenagem Linfática Corporal (4)", areaStrP_dl0),
          ];

          this.pacoteToShow = sessoes1P_dl0;
        }
      } else if (this.mov.tipo === "Protocolo Glúteo de Ouro") {
        var sessoes1Gl = this.mov.pacotes[0];
        var areaStrGl = "GLÚTEO";

        sessoes1Gl.sessoes = [
          filterForEvent("Glúteo de Ouro (1)", areaStrGl),
          filterForEvent("Glúteo de Ouro (2)", areaStrGl),
          filterForEvent("Glúteo de Ouro (3)", areaStrGl),
          filterForEvent("Glúteo de Ouro (4)", areaStrGl),
          filterForEvent("Glúteo de Ouro (5)", areaStrGl),
          filterForEvent("Glúteo de Ouro (6)", areaStrGl),
          filterForEvent("Glúteo de Ouro (7)", areaStrGl),
          filterForEvent("Glúteo de Ouro (8)", areaStrGl),
        ];

        this.pacoteToShow = sessoes1Gl;
      }
    },
    async updateEvent(event) {
      var startDate = new Date(event.start);
      var millis = startDate.getTime();
      var sub = 24 * 60 * 60 * 1000;
      var millis_1 = millis - sub;
      var endDate = new Date(event.end);
      var startMinStr;
      var startMinStrX = startDate.getMinutes();
      if (startMinStrX < 10) {
        startMinStr = "0" + startMinStrX.toString();
      } else {
        startMinStr = startMinStrX.toString();
      }
      var endMinStr;
      var endMinStrX = endDate.getMinutes();
      if (endMinStrX < 10) {
        endMinStr = "0" + endMinStrX.toString();
      } else {
        endMinStr = endMinStrX.toString();
      }

      var startTime = startDate.getHours().toString() + ":" + startMinStr;
      var endTime = endDate.getHours().toString() + ":" + endMinStr;

      var startYStrX = startDate.getYear() + 1900;
      var startYStr = startYStrX.toString();
      var startYStrShortX = startDate.getYear() - 100;
      var startYStrShort = startYStrShortX.toString();

      var startMStr;
      var startMStrX = startDate.getMonth() + 1;
      if (startMStrX < 10) {
        startMStr = "0" + startMStrX.toString();
      } else {
        startMStr = startMStrX.toString();
      }

      var startDStr;
      var startDStrX = startDate.getDate();
      if (startDStrX < 10) {
        startDStr = "0" + startDStrX.toString();
      } else {
        startDStr = startDStrX.toString();
      }

      var startStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + startTime + ":00";
      var endStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + endTime + ":00";
      var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
      var day = diasPtBr[startDate.getDay()];
      var niceDateShort =
        day + ", " + startDStr + "/" + startMStr + "/" + startYStrShort;
      var niceDateStr =
        day +
        ", " +
        startDStr +
        "/" +
        startMStr +
        "/" +
        startYStrShort +
        ", " +
        startTime +
        "-" +
        endTime;

      var updateDoc = {
        niceDate: niceDateStr,
        niceDateShort: niceDateShort,
        start: startStr,
        end: endStr,
        startTime: startTime,
        endTime: endTime,
          autoset: false,
      };

      var updateDocLembrete = {
        done: false,
        status: "notDueYet",
        dueDate: millis_1,
        niceDate: niceDateStr,
        niceDateShort: niceDateShort,
        start: startStr,
        end: endStr,
        startTime: startTime,
        endTime: endTime,
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .doc(event.id)
        .update(updateDoc);

      if (event.patientId !== "") {
        await firebase
          .firestore()
          .collection("users")
          .doc(event.patientId)
          .collection("agenda")
          .doc(event.id)
          .update(updateDoc);
      }

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(event.id)
        .update(updateDocLembrete);

      this.getEvents();
      this.getEventsEstetica();
    },
    async updateEventEstetica(event) {
      var startDate = new Date(event.start);
      var endDate = new Date(event.end);
      var startMinStr;
      var startMinStrX = startDate.getMinutes();
      if (startMinStrX < 10) {
        startMinStr = "0" + startMinStrX.toString();
      } else {
        startMinStr = startMinStrX.toString();
      }
      var endMinStr;
      var endMinStrX = endDate.getMinutes();
      if (endMinStrX < 10) {
        endMinStr = "0" + endMinStrX.toString();
      } else {
        endMinStr = endMinStrX.toString();
      }

      var startTime = startDate.getHours().toString() + ":" + startMinStr;
      var endTime = endDate.getHours().toString() + ":" + endMinStr;

      var startYStrX = startDate.getYear() + 1900;
      var startYStr = startYStrX.toString();
      var startYStrShortX = startDate.getYear() - 100;
      var startYStrShort = startYStrShortX.toString();

      var startMStr;
      var startMStrX = startDate.getMonth() + 1;
      if (startMStrX < 10) {
        startMStr = "0" + startMStrX.toString();
      } else {
        startMStr = startMStrX.toString();
      }

      var startDStr;
      var startDStrX = startDate.getDate();
      if (startDStrX < 10) {
        startDStr = "0" + startDStrX.toString();
      } else {
        startDStr = startDStrX.toString();
      }

      var startStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + startTime + ":00";
      var endStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + endTime + ":00";
      var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
      var day = diasPtBr[startDate.getDay()];
      var niceDateShort =
        day + ", " + startDStr + "/" + startMStr + "/" + startYStrShort;
      var niceDateStr =
        day +
        ", " +
        startDStr +
        "/" +
        startMStr +
        "/" +
        startYStrShort +
        ", " +
        startTime +
        "-" +
        endTime;

      var updateDoc = {
        niceDate: niceDateStr,
        niceDateShort: niceDateShort,
        start: startStr,
        end: endStr,
        startTime: startTime,
        endTime: endTime,
          autoset: false,
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .doc(event.id)
        .update(updateDoc);

      if (event.patientId !== "") {
        await firebase
          .firestore()
          .collection("users")
          .doc(event.patientId)
          .collection("agenda")
          .doc(event.id)
          .update(updateDoc);
      }
      this.getEvents();
    },
    endDrag() {
      this.updateEvent(this.dragEvent);
      this.dragEvent = null;
    },
    endDragEstetica() {
      this.updateEventEstetica(this.dragEvent);
      this.dragEvent = null;
    },
    startDrag({ event }) {
      if (event) {
        this.dragEvent = event;
      }
    },
    clearInfo() {
      this.dialog = false;
      this.patient = "";
      this.patientId = "";
      this.newPatient = false;
      this.startTime = null;
      this.endTime = null;
      this.description = null;
      this.selectedTime = null;
      this.niceDateShort = null;
    },
    hourInfoMouseFollow(e) {
      try {
        var chip = document.getElementById("DateChip");
        if (
          (this.type === "week" || this.type === "day") &&
          e.target.className == "v-calendar-daily__day-interval"
        ) {
          chip.style.display = "block";
          chip.style.left = e.clientX - 300 + "px";
          chip.style.top = e.layerY + "px";
        } else {
          chip.style.display = "none";
        }
      } catch (err) {
        err;
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      if (event.autoset == undefined) {
        return event.color;
      } else {
        if (event.autoset) {
          return "#9999ff";
        } else {
          return event.color;
        }
      }
    },
    async deleteEvent(eventId) {
      var other_type;
      if (this.type !== "week") {
        other_type = "week";
      } else {
        other_type = "day";
      }
      var a = this.type;
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .doc(eventId)
        .delete();
      this.type = other_type;
      setTimeout(() => {
        this.type = a;
      }, 1);
      this.selectedOpen = false;
    },
    async getEvents() {
      var events = [];
      var eventList = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      eventList.forEach(function(event) {
        var autosetColor = function(e) {
          if (e.autoset == undefined) {
            return e.color;
          } else {
            if (e.autoset) {
              return "#99ff99";
            } else {
              return e.color;
            }
          }
        };
        events.push({
          name: event.name,
          patient: event.patient,
          patientId: event.patientId,
          newPatient: event.newPatient,
          start: new Date(event.start),
          end: new Date(event.end),
          details: event.details,
          color: autosetColor(event),
          timed: event.timed,
          id: event.id,
          startTime: event.startTime,
          endTime: event.endTime,
          niceDateShort: event.niceDateShort,
        })
    });
      this.events = events;
    },
    async getEventsEstetica() {
      var eventsEstetica = [];
      var eventListEstetica = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      eventListEstetica.forEach(function(event) {
        var autosetColor = function(e) {
          if (e.autoset == undefined) {
            return e.color;
          } else {
            if (e.autoset) {
              return "#99ff99";
            } else {
              return e.color;
            }
          }
        };
        eventsEstetica.push({
          name: event.name,
          patient: event.patient,
          patientId: event.patientId,
          newPatient: event.newPatient,
          start: new Date(event.start),
          end: new Date(event.end),
          details: event.details,
          color: autosetColor(event),
          timed: event.timed,
          id: event.id,
          startTime: event.startTime,
          endTime: event.endTime,
          niceDateShort: event.niceDateShort,
        })
    });
      this.eventsEstetica = eventsEstetica;
    },
    getCalendarDayTime(time) {
      // this.currentTime = time.time;
      var splitsT = time.time.split(":");
      var hoursT = parseInt(splitsT[0]);
      var minutesT = parseInt(splitsT[1]);
      if (minutesT < 15) {
        minutesT = "00";
      } else if (minutesT >= 15 && minutesT < 30) {
        minutesT = "15";
      } else if (minutesT >= 30 && minutesT < 45) {
        minutesT = "30";
      } else if (minutesT >= 45 && minutesT < 59) {
        minutesT = "45";
      }
      var roundedTime = hoursT
        .toString()
        .concat(":")
        .concat(minutesT);
      this.currentTime = roundedTime;

      if (this.dragEvent !== null) {
        var start = new Date(this.dragEvent.start);
        var end = new Date(this.dragEvent.end);
        var startS = start.getTime();
        var endS = end.getTime();
        var duration = endS - startS;

        var dayStr;
        var day = time.day;
        if (day < 10) {
          dayStr = "0" + day.toString();
        } else {
          dayStr = day.toString();
        }
        var monthStr;
        var month = time.month;
        if (month < 10) {
          monthStr = "0" + month.toString();
        } else {
          monthStr = month.toString();
        }
        var string =
          time.year.toString() +
          "-" +
          monthStr +
          "-" +
          dayStr +
          " " +
          roundedTime +
          ":00";
        var newStart = new Date(string);
        var newStartS = newStart.getTime();
        var newEndS = newStartS + duration;
        var newEnd = new Date(newEndS);

        var newEndMinutes;
        if (newEnd.getMinutes() < 10) {
          newEndMinutes = "0" + newEnd.getMinutes().toString();
        } else {
          newEndMinutes = newEnd.getMinutes().toString();
        }
        this.dragEvent.start = string;
        this.dragEvent.end =
          time.year.toString() +
          "-" +
          monthStr +
          "-" +
          dayStr +
          " " +
          newEnd.getHours().toString() +
          ":" +
          newEndMinutes +
          ":00";

        this.dragEvent.startTime = roundedTime;
        this.dragEvent.endTime =
          newEnd.getHours().toString() + ":" + newEndMinutes;
      }
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    chooseDataTime(event) {
      this.eventEdit = event;
      this.chooseDateTimeDialog = true;
    },
    chooseDataTimeE(event) {
      this.eventEdit = event;
      this.chooseDateTimeDialogE = true;
    },
    toYMD(string) {
      var splits = string.split("/");
      var year = splits[2];
      var month = splits[1];
      var days = splits[0];
      return "20" + year + "-" + month + "-" + days.substring(6);
    },
    async transformStartEnd() {
      this.eventEdit.start = `${this.toYMD(this.eventEdit.niceDateShort)} ${
        this.eventEdit.startTime
      }:00`;
      this.eventEdit.end = `${this.toYMD(this.eventEdit.niceDateShort)} ${
        this.eventEdit.endTime
      }:00`;

      this.eventEdit.niceDate =
        this.eventEdit.niceDateShort +
        ", " +
        this.eventEdit.startTime +
        "-" +
        this.eventEdit.endTime;

      console.log(this.eventEdit);

      var eventToSave = {
        niceDate: this.eventEdit.niceDate,
        niceDateShort: this.eventEdit.niceDateShort,
        start: this.eventEdit.start,
        end: this.eventEdit.end,
        startTime: this.eventEdit.startTime,
        endTime: this.eventEdit.endTime,
        autoset: false
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .doc(this.eventEdit.id)
        .update(eventToSave);

      await firebase
        .firestore()
        .collection("users")
        .doc(this.mov.paciente)
        .collection("agenda")
        .doc(this.eventEdit.id)
        .update(eventToSave);

      this.getEvents();
      this.chooseDateTimeDialog = false;
    },
    async transformStartEndE() {
      this.eventEdit.start = `${this.toYMD(this.eventEdit.niceDateShort)} ${
        this.eventEdit.startTime
      }:00`;
      this.eventEdit.end = `${this.toYMD(this.eventEdit.niceDateShort)} ${
        this.eventEdit.endTime
      }:00`;

      this.eventEdit.niceDate =
        this.eventEdit.niceDateShort +
        ", " +
        this.eventEdit.startTime +
        "-" +
        this.eventEdit.endTime;

      console.log(this.eventEdit);

      var eventToSave = {
        niceDate: this.eventEdit.niceDate,
        niceDateShort: this.eventEdit.niceDateShort,
        start: this.eventEdit.start,
        end: this.eventEdit.end,
        startTime: this.eventEdit.startTime,
        endTime: this.eventEdit.endTime,
        autoset: false
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .doc(this.eventEdit.id)
        .update(eventToSave);

      await firebase
        .firestore()
        .collection("users")
        .doc(this.mov.paciente)
        .collection("agenda")
        .doc(this.eventEdit.id)
        .update(eventToSave);

      this.getEventsEstetica();
      this.chooseDateTimeDialogE = false;
    },
  },
  mounted() {
    // this.$refs.calendar.checkChange();

    if (this.$store.state.firestore.pacientes.length === 0) {
      this.$store.dispatch("firestore/getPacientes");
    }

    // var patientName = this.$store.state.firestore.mov.pacienteName;
    // var patientName = this.mov.pacienteName;
    // console.log(patientName);
    this.getEvents();
    this.getEventsEstetica();
    setTimeout(() => {
      this.mountingFunction();
    }, 3000);
    // this.mountingFunction();
  },
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
