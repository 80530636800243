<template>
  <div class="page">
    <CadastrarHeader></CadastrarHeader>
    <CadastrarRedirectedForm></CadastrarRedirectedForm>
    <WelcomeFooter></WelcomeFooter>
  </div>
</template>

<script>
import WelcomeFooter from "../components/welcome/WelcomeFooter";
import CadastrarHeader from "../components/cadastro/CadastrarHeader";
import CadastrarRedirectedForm from "../components/cadastro/CadastrarRedirectedForm";

export default {
  components: { WelcomeFooter, CadastrarHeader, CadastrarRedirectedForm }
};
</script>

<style>
</style>