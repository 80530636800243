import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import VueResource from "vue-resource";
import router from "./router";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import "@/firebase/";
import { firestorePlugin } from "vuefire";
// import { RichTextEditorPlugin } from '@syncfusion/ej2-vue-richtexteditor';
import VCalendar from 'v-calendar';
import LoadScript from 'vue-plugin-load-script';
// import VStripeElements from "v-stripe-elements/lib";
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
// import firebaseMessaging from './firebase';

router.beforeEach(
  /*async*/(to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (
      requiresAuth &&
      !(/*await firebase.getCurrentUser()*/ sessionStorage?.getItem('isAuthenticated'))
    ) {
      next("/cadastrarRedirected");
    } else {
      next();
    }
  }
);

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('firebase-messaging-sw.js')
//     .then(reg => {
//       console.log(`Service Worker Registration (Scope: ${reg.scope})`);
//     })
//     .catch(error => {
//       const msg = `Service Worker Error (${error})`;
//       console.error(msg);
//     });
// } else {
//   // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
//   console.warn('Service Worker not available');
// }

// Vue.prototype.$messaging = firebaseMessaging
Vue.use(Vuetify);
Vue.use(VCalendar, {
  locale: 'pt' // Spanish
});
// Vue.use(VStripeElements);
Vue.use(LoadScript)
Vue.use(VueResource);
Vue.use(firestorePlugin);
Vue.use(VuePlyr, {
  plyr: {}
})
// Vue.use(RichTextEditorPlugin);

const opts = {
  icons: {
    iconfont: "mdi"
  }
};

new Vue({
  render: (h) => h(App),
  router: router,
  store,
  vuetify: new Vuetify(opts)
}).$mount("#app");


