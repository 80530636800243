<template>
  <div>
    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card outlined style="background-color: rgba(197, 209, 63, 0.4)">
      <v-card-title class="d-flex justify-center">
        Orçamento
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        <v-autocomplete
          label="Nome do Paciente"
          single-line
          solo
          class="mt-3 mx-3"
          style="width: 100%"
          v-model="mov.paciente"
          :items="pacientes"
          :rules="mandatory"
          no-data-text="..."
          @change="getCpf(mov.paciente)"
        ></v-autocomplete>
      </v-card-text>
      <v-card-text>
        <v-card outlined style="background-color: rgba(197, 209, 63, 0.2)">
          <v-card-title class="d-flex justify-center text-subtitle-2">
            Sugestão de tratamento
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="0" md="1"></v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  label="Tipo de tratamento"
                  :items="getItems"
                  v-model="mov.tipo"
                  class="mt-5"
                  single-line
                  solo
                  style="width: 100%"
                  @change="changeTratSugg(mov.tipo)"
                  no-data-text="..."
                ></v-autocomplete> </v-col
              ><v-col cols="0" md="1"></v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="12" class="d-flex justify-center text-h6">
                {{ niceString(mov.valor) }}
              </v-col>
            </v-row>

            <div
              v-if="
                mov.tipo == 'Programa 12S com Estética' ||
                  mov.tipo == 'Programa Modulação Intestinal com Estética' ||
                  mov.tipo == 'Programa Clínico Funcional com Estética'
              "
            >
              <v-row class="d-flex justify-center mt-5">
                Define a quantidade de pacotes estéticos
              </v-row>
              <v-row>
                <v-col cols="0" md="4"></v-col>
                <v-col cols="12" md="4">
                  <v-select
                    label="número de pacotes"
                    v-model="numberPacote"
                    @change="setNrPacotes(numberPacote, mov.tipo)"
                    :items="numbers"
                  ></v-select>
                </v-col>
                <v-col cols="0" md="4"></v-col>
              </v-row>
            </div>

            <v-row v-if="mov.tipo == 'Protocolo Harmonização Corporal 24H'">
              <v-col cols="0" md="2"></v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="pacH1area"
                  :items="areas"
                  label="área 1"
                  @change="setPacIncl"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="pacH2area"
                  :items="areas"
                  label="área 2"
                  @change="setPacIncl"
                ></v-select>
              </v-col>
              <v-col cols="0" md="2"></v-col>
            </v-row>
            <v-row
              v-if="
                mov.tipo == 'Protocolo Lipodraining 24H' ||
                  mov.tipo == 'Protocolo Detox + Lipodraining 24H'
              "
            >
              <v-col cols="0" md="4" class="d-flex justify-center">
                <v-checkbox
                  v-model="pacDldef1"
                  color="#614021"
                  label="Corpo inteiro"
                  @change="setPacIncl"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :disabled="pacDldef1"
                  v-model="pacDl1area"
                  :items="areas"
                  label="área 1"
                  @change="setPacIncl"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :disabled="pacDldef1"
                  v-model="pacDl2area"
                  :items="areas"
                  label="área 2"
                  @change="setPacIncl"
                ></v-select>
              </v-col>
            </v-row>
            <div v-if="mov.tipo == 'Pacote Estético'">
              <v-row class="d-flex justify-center">
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-card
                    v-for="pac in mov.pacotes"
                    :key="pac.index"
                    outlined
                    class="my-1"
                    style="background-color: rgba(197, 209, 63, 0.2)"
                  >
                    <v-row class="mx-1">
                      <v-col cols="12" md="4"
                        ><v-select
                          label="tipo de pacote"
                          v-model="pac.def1"
                          :items="getNames(pacotesEsteticos1)"
                          @change="setValor(mov.tipo, mov.descr)"
                          :readonly="
                            pac.def2 == 'REFLEXOLOGIA PODAL (10 sessões)' ||
                              pac.def2 == 'TERAPIA COM VENTOSAS (10 sessões)' ||
                              pac.def2 == 'AURICULOTERAPIA (10 sessões)' ||
                              pac.def2 == 'DRY NEEDLING (10 sessões)' ||
                              pac.def2 ==
                                'REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)'
                          "
                        ></v-select
                      ></v-col>
                      <v-col cols="12" md="5"
                        ><v-select
                          label="procedimento"
                          v-model="pac.def2"
                          :items="pacotesEsteticos2"
                          @change="checkReflexPodal(pac)"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                          label="mês do procedimento"
                          v-model="pac.dataInicio"
                          @change="setMillisPacote(pac.index)"
                          :items="meses"
                          :rules="mandatory"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row class="mx-1">
                      <v-col cols="12" md="4">
                        <v-select
                          label="área 1"
                          v-model="pac.area1"
                          :items="getCorrectAreas(pac.def1)"
                          :rules="mandatory"
                          v-if="
                            pac.def2 !== 'REFLEXOLOGIA PODAL (10 sessões)' &&
                              pac.def2 !==
                                'REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)' &&
                              pac.def2 !==
                                'TERAPIA COM VENTOSAS (10 sessões)' &&
                              pac.def2 !== 'AURICULOTERAPIA (10 sessões)' &&
                              pac.def2 !== 'DRY NEEDLING (10 sessões)'
                          "
                        >
                        </v-select>
                        <v-text-field
                          label="área 1"
                          v-model="pac.area1"
                          readonly
                          v-else
                        >
                        </v-text-field
                      ></v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          label="área 2"
                          v-model="pac.area2"
                          :items="areas"
                          :disabled="disableArea2(pac.def1, pac.def2)"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          label="área 3"
                          v-model="pac.area3"
                          :items="areas"
                          :disabled="disableArea3(pac.def1, pac.def2)"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="0" md="2" class="d-flex align-center">
                  <v-tooltip bottom color="#614021">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="#614021"
                        v-bind="attrs"
                        v-on="on"
                        @click="pushPacote"
                        ><v-icon color="#614021"
                          >mdi-plus-circle-outline</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>Adicionar Pacote</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>

            <div v-if="mov.tipo == 'Power Shape 30'">
              <v-row class="d-flex justify-center">
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-card
                    v-for="pac in mov.pacotes"
                    :key="pac.index"
                    outlined
                    class="my-1"
                    style="background-color: rgba(197, 209, 63, 0.2)"
                  >
                    <v-row class="mx-1">
                      <v-col cols="12" md="4"
                        ><v-select
                          label="tipo de pacote"
                          v-model="pac.def1"
                          :items="getNames(pacotesEsteticos1)"
                          readonly
                          hint="Somente DUO (áreas pares)"
                        ></v-select
                      ></v-col>
                      <v-col cols="12" md="5"
                        ><v-select
                          label="procedimento"
                          v-model="pac.def2"
                          :items="pacotesEsteticos2"
                          @change="checkReflexPodal(pac)"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                          label="mês do procedimento"
                          v-model="pac.dataInicio"
                          @change="setMillisPacote(pac.index)"
                          :items="meses"
                          :rules="mandatory"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center mx-4">
                      <v-select
                        label="área"
                        v-model="pac.area1"
                        :items="getCorrectAreas(pac.def1)"
                        :rules="mandatory"
                        v-if="
                          pac.def2 !== 'REFLEXOLOGIA PODAL (10 sessões)' &&
                            pac.def2 !==
                              'REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)' &&
                            pac.def2 !== 'TERAPIA COM VENTOSAS (10 sessões)' &&
                            pac.def2 !== 'AURICULOTERAPIA (10 sessões)' &&
                            pac.def2 !== 'DRY NEEDLING (10 sessões)'
                        "
                      >
                      </v-select>
                      <v-text-field
                        label="área 1"
                        v-model="pac.area1"
                        readonly
                        v-else
                      >
                      </v-text-field>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="0" md="2" class="d-flex align-center"> </v-col>
              </v-row>
            </div>

            <div
              v-if="
                mov.tipo == 'Programa 12S com Estética' ||
                  mov.tipo == 'Programa Modulação Intestinal com Estética' ||
                  mov.tipo == 'Programa Clínico Funcional com Estética'
              "
            >
              <v-row class="d-flex justify-center">
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="8">
                  <v-card
                    v-for="pac in mov.pacotes"
                    :key="pac.index"
                    outlined
                    class="my-1"
                    style="background-color: rgba(197, 209, 63, 0.2)"
                  >
                    <v-row class="mx-1">
                      <v-col cols="12" md="5"
                        ><v-select
                          label="procedimento"
                          v-model="pac.def2"
                          :items="pacotesEsteticos2_12S"
                          @change="checkReflexPodal(pac)"
                          :rules="mandatory"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                          label="mês do procedimento"
                          v-model="pac.dataInicio"
                          @change="setMillisPacote(pac.index)"
                          :items="meses12s"
                          :rules="mandatory"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          label="área"
                          v-model="pac.area1"
                          :items="areasPares"
                          :rules="mandatory"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="0" md="2" class="d-flex align-center"> </v-col>
              </v-row>
            </div>

            <div v-if="mov.tipo == '1 sessão estética avulsa'">
              <v-form ref="formSessao" v-model="validSessao">
                <v-row>
                  <v-col cols="0" md="3"></v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      :value="mov.descr"
                      v-model="mov.descr"
                      :items="getNames(sessoesEsteticos)"
                      solo
                      label="Sessão"
                      style="width: 100%"
                      @change="setValor(mov.tipo, mov.descr)"
                      :rules="mandatory"
                    >
                    </v-select> </v-col
                  ><v-col cols="0" md="3"></v-col>
                </v-row>
              </v-form>
            </div>

            <v-divider></v-divider>
            <div v-if="descrNesc">
              <v-row>
                <v-col cols="3" md="2" class="d-flex align-center justify-end">
                  Descrição:
                </v-col>
                <v-col cols="9" md="4">
                  <v-text-field
                    label="mais detalhes importantes..."
                    single-line
                    solo
                    class="mt-3 mx-3"
                    style="width: 100%"
                    v-model="mov.descr"
                    :rules="mandatory"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="2" class="d-flex align-center justify-end">
                  Valor:
                </v-col>
                <v-col cols="9" md="4">
                  <v-text-field
                    label='ex.: "167.50"'
                    single-line
                    solo
                    class="mt-3 mx-3"
                    style="width: 100%"
                    v-model="mov.valor"
                    :rules="hasDots"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12" md="3" class="d-flex align-center justify-end">
                <v-checkbox
                  v-model="descontoAct"
                  label="Desconto"
                  color="#614021"
                  @change="descontoImpact"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label='desconto em R$, ex.: "100"'
                  single-line
                  :solo="descontoAct"
                  class="mt-3 mx-3"
                  style="width: 100%"
                  v-model="mov.desconto"
                  @change="adjustValorDesc"
                  :disabled="!descontoAct"
                  type="number"
                ></v-text-field> </v-col
              ><v-col cols="12" md="3"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-title class="d-flex justify-center text-subtitle-2"
        >Fim da oferta</v-card-title
      >
      <v-card-text>
        <v-row class="d-flex justify-center">
          <v-date-picker
            ref="picker"
            locale="pt-br"
            v-model="orcamentoFim"
            :value="orcamentoFim"
            min="2021-01-01"
            color="#614021"
          ></v-date-picker>
        </v-row>
        <v-row class="my-6">
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="white--text"
              large
              @click="downloadAv"
            >
              <v-icon color="#c5d13f" left>mdi-content-save</v-icon>
              Salvar e baixar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <v-card class="d-flex justify-center">
          <v-card-title>Em desenvolvimento</v-card-title>
        </v-card> -->
  </div>
</template>

<script>
import {
  mdiEmail,
  mdiAccount,
  mdiPhone,
  mdiWhatsapp,
  mdiCalendar,
  mdiWeightKilogram,
  mdiHumanMaleHeightVariant,
} from "@mdi/js";
import firebase from "firebase";
import jspdf from "jspdf";
import "jspdf-autotable";

export default {
  data: function() {
    return {
      orcamentoFim: null,
      numberPacote: 0,
      numbers: [1, 2, 3, 4, 5, 6],
      areas: [
        "ABDOMEN INFERIOR",
        "ABDOMEN SUPERIOR",
        "GLUTEO DIREITO",
        "GLÚTEO ESQUERDO",
        "POSTERIOR DE COXA ESQUERDO",
        "POSTERIOR DE COXA DIREITO",
        "FLANCO ESQUERDO",
        "FLANCO DIREITO",
        "BRAÇO DIREITO",
        "BRAÇO ESQUERDO",
        "INTERNO DE COXA DIREITO",
        "INTERNO DE COXA ESQUERDO",
        "LATERAL DE COXA DIREITO",
        "LATERAL DE COXA ESQUERDO",
        "PAPADA",
        "PEITORAL",
      ],
      areasPares: [
        "ABDOMEN INFERIOR + SUPERIOR",
        "GLUTEO DIREITO + GLÚTEO ESQUERDO",
        "POSTERIOR DE COXA ESQUERDO + DIREITO",
        "FLANCO ESQUERDO + DIREITO",
        "BRAÇO DIREITO + BRAÇO ESQUERDO",
        "INTERNO DE COXA DIREITO + INTERNO DE COXA ESQUERDO",
        "LATERAL DE COXA DIREITO + LATERAL DE COXA ESQUERDO",
        "PAPADA",
        "PEITORAL",
      ],
      pacotesEsteticos1: [
        {
          nome: "UNO",
          valor: { balcam: 480, fortal: 480, belemx: 480 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "DUO",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "TRIPLO",
          valor: { balcam: 960, fortal: 960, belemx: 960 },
          transactModus: ["any"],
          duracao: 30,
        },
      ],
      pacotesEsteticos2: [
        "HIBRIUS",
        "DUALSOON",
        "ENDERMOTERAPIA",
        "CORRENTE RUSSA", //2x
        "MASSAGEM REDUTORA", // 2x
        "DRENAGEM LINFATICA", // 1x
        "MASSAGEM RELAXANTE", // 1x
        "REFLEXOLOGIA PODAL (10 sessões)", // 2x
        "REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)", // 2x
        "TERAPIA COM VENTOSAS (10 sessões)", // 2x
        "AURICULOTERAPIA (10 sessões)", // 2x
        "DRY NEEDLING (10 sessões)", // 2x
      ],
      pacotesEsteticos2_12S: [
        "HIBRIUS",
        "DUALSOON",
        "ENDERMOTERAPIA",
        "CORRENTE RUSSA", //2x
        "MASSAGEM REDUTORA", // 2x
        "DRENAGEM LINFATICA", // 1x
        "MASSAGEM RELAXANTE", // 1x
        // "REFLEXOLOGIA PODAL", // 2x
      ],
      sessoesEsteticos: [
        // "Sessão de NARL",
        {
          nome: "Sessão de HIBRIUS",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de DUALSOON",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de LIPOSOON",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de MASSAGEM REDUTORA ou DRENAGEM CORPO TODO",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de MASSAGEM REDUTORA ou DRENAGEM LOCAL",
          valor: { balcam: 65, fortal: 65, belemx: 65 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de MASSAGEM RELAXANTE",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de CORRENTE RUSSA",
          valor: { balcam: 65, fortal: 65, belemx: 65 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de REFLEXOLOGIA PODAL",
          valor: { balcam: 80, fortal: 80, belemx: 80 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de REFLEXOLOGIA PODAL + ESCALDA PES",
          valor: { balcam: 100, fortal: 100, belemx: 100 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de TERAPIA COM VENTOSAS",
          valor: { balcam: 80, fortal: 80, belemx: 80 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de LIMPEZA DE PELE",
          valor: { balcam: 180, fortal: 180, belemx: 180 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de MICROAGULHAMENTO",
          valor: { balcam: 250, fortal: 250, belemx: 250 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de AURICULOTERAPIA",
          valor: { balcam: 80, fortal: 80, belemx: 80 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de DRY NEEDLING",
          valor: { balcam: 80, fortal: 80, belemx: 80 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de PEELING",
          valor: { balcam: 220, fortal: 220, belemx: 220 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        //   sessoesEsteticosValores: [/*65, */120, 120, 65, 120, 65, 65],
      ],
      mandatory: [(v) => !!v || v == 0 || "Campo obrigatório"],
      menuDataNascimento: false,
      displayDataNasc: "",
      panel0: [0],
      panel1: [0],
      panel2: [0],
      progressDialog: false,
      saved: false,
      sexo: true,
      gestante: false,
      dataNascimento: null,
      email: null,
      telefone: null,
      whatsapp: null,
      pesoAtual: null,
      altura: null,
      valor: 0,
      avEscrita: "...",
      questSaude: {
        bebAlc: null,
        bebAlcFreq: null,
        fuma: null,
        fumaParar: null,
        atiFis: null,
        atiFisFreq: null,
        press: null,
        pressCtrl: null,
        pressMed: null,
        coracao: null,
        trigl: null,
        diabet: null,
        diagResp: null,
        diagCanc: null,
        diagDepr: null,
        satisSaude: null,
        satisVida: null,
      },
      questCompo: {
        one: null,
        two: null,
        tre: null,
        for: null,
        fiv: null,
        six: null,
        sev: null,
      },
      questEstet: {
        enx: null,
        fec: null,
        cel: null,
        gor: null,
        fla: null,
        rou: null,
        def: null,
        ton: null,
      },
      vendaItems: [
        // {
        //   nome: "1 consulta nutricional patrocinada",
        //   valor: { balcam: 0, fortal: 0, belemx: 0 },
        //   transactModus: ["none"],
        //   duracao: 30, //acessoTipo acessoFim
        // },
        {
          nome: "1 consulta nutricional Crédito / Débito",
          valor: { balcam: 330, fortal: 300, belemx: 350 },
          transactModus: ["Cartão de Crédito", "Cartão de Débito"],
          duracao: 30,
        },
        {
          nome: "1 consulta nutricional PIX / Transferência",
          valor: { balcam: 330, fortal: 300, belemx: 350 },
          transactModus: ["PIX", "Transferência Bancária"],
          duracao: 30,
        },
        // !!!!
        {
          nome: "1 consulta nutricional (plano família, mínimo 2 pessoas)",
          valor: { balcam: 250, fortal: 250, belemx: 300 },
          transactModus: ["any"],
          duracao: 30,
        },
        // !!!!
        {
          nome: "1 consulta nutricional SEM RETORNO",
          valor: { balcam: 180, fortal: 180, belemx: 200 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Programa Clínico Funcional", //"1 programa BÁSICO NUTRICIONAL (90 dias)",
          valor: { balcam: 900, fortal: 900, belemx: 990 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Clínico Funcional (plano família)", // "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)",
          valor: { balcam: 810, fortal: 810, belemx: 810 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Clínico Funcional com Estética", // "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)",
          valor: { balcam: 1400, fortal: 1400, belemx: 1400 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Modulação Intestinal", //"1 programa MODULAÇÃO INTESTINAL (90 dias)",
          valor: { balcam: 990, fortal: 990, belemx: 1100 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Modulação Intestinal (plano família)", //"1 programa de MODULAÇÃO INTESTINAL plano família (90 dias)",
          valor: { balcam: 900, fortal: 900, belemx: 900 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Modulação Intestinal com Estética", // "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)",
          valor: { balcam: 1490, fortal: 1490, belemx: 1490 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S Online", //"1 programa de 3 consultas + 3 meses de Estética (1 área)",
          valor: { balcam: 990, fortal: 990, belemx: 990 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S", //"1 programa de 3 consultas + 3 meses de Estética (1 área)",
          valor: { balcam: 990, fortal: 990, belemx: 990 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S com Estética", //"1 programa de 3 consultas + 3 meses de Estética (1 área)",
          valor: { balcam: 1490, fortal: 1490, belemx: 1490 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)",
          valor: { balcam: 1990, fortal: 1990, belemx: 1990 },
          transactModus: ["any"],
          duracao: 12 * 7,
          incl:
            "Durante 12 semanas: 3 consultas nutricionais + dietas personalizadas + prescrição de supplementos + video-aulas + livro/diário do programa + exame nutrigenético",
        },
        {
          nome: "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)",
          valor: { balcam: 2190, fortal: 2190, belemx: 2190 },
          transactModus: ["any"],
          duracao: 12 * 7,
          incl:
            "Durante 12 semanas: 3 consultas nutricionais + dietas personalizadas + prescrição de supplementos + video-aulas + livro/diário do programa + exame nutrigenético",
        },
        {
          nome: "Power Shape 30",
          valor: { balcam: 560, fortal: 560, belemx: 560 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Glúteo de Ouro",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Lipodraining 24H",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Detox + Lipodraining 24H",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Harmonização Corporal 24H",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Pacote Estético",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "1 sessão estética avulsa",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "1 exame de bioimpedância",
          valor: { balcam: 100, fortal: 100, belemx: 100 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame de Intolerância e Hipersensibilidade alimentar",
          valor: { balcam: 1910, fortal: 1910, belemx: 1910 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame de Microbioma Intestinal",
          valor: { balcam: 1970, fortal: 1970, belemx: 1970 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame Nutrigenético (DNA equilibrio) (a vista)",
          valor: { balcam: 1350, fortal: 1350, belemx: 1350 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame Nutrigenético (DNA equilibrio) (parcelado)",
          valor: { balcam: 1500, fortal: 1500, belemx: 1500 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame Genético Esportivo",
          valor: { balcam: 2760, fortal: 2760, belemx: 2760 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "Combo exames de Microbioma Intestinal + Hipersensibilidade alimentar + Nutrigenético",
          valor: { balcam: 5976, fortal: 5976, belemx: 5976 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "Protocolo Kirchhoff",
          valor: { balcam: 180, fortal: 180, belemx: 180 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Pacote 90 dias Protocolo Kirchhoff",
          valor: { balcam: 590, fortal: 590, belemx: 590 },
          transactModus: ["any"],
          duracao: 90,
        },
        // {
        //   nome: "Giftcard",
        //   valor: { balcam: 0, fortal: 0, belemx: 0 },
        //   transactModus: ["any"],
        //   duracao: 90,
        // },
        {
          nome: "HydraGloss",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Protocolo de Clareamento",
          valor: { balcam: 180, fortal: 180, belemx: 180 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Protocolo de Clareamento (Pacote de 4 sessões)",
          valor: { balcam: 600, fortal: 600, belemx: 600 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Entrada 1ª Consulta",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "Outro",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 0,
        },
      ],
      icons: {
        mdiAccount,
        mdiEmail,
        mdiPhone,
        mdiWhatsapp,
        mdiCalendar,
        mdiWeightKilogram,
        mdiHumanMaleHeightVariant,
      },
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
        emailRules: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+/.test(v) || "E-mail invalido",
          (v) => /^\S*$/.test(v) || "E-mail com espaço",
        ],
      },
      descontoAct: false,
      mov: {
        direction: true,
        tipo: null,
        descr: null,
        paciente: null,
        comprovante: null,
        valor: null,
        desconto: 0,
        soldObjs: [],
        pacotes: [
          {
            def1: null,
            def2: null,
            area1: null,
            area2: null,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            // index: ix,
          },
        ],
        forma: null,
        formas: {
          cartaoDeCredito: 0,
          cartaoDeDebito: 0,
          dinheiro: 0,
          deposito: 0,
          pix: 0,
          transferenciaBancaria: 0,
        },
      },
      preloadSignature: new Image(),
      preloadLogo: new Image(),
      nome: null,
    };
  },
  computed: {
    userInfo() {
        return this.$store.state.firestore.userInfo;
    },
    meses() {
      var monthTexts = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Augosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Decembro",
      ];
      var indexList = [0, 1, 2, 3, 4, 5];
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth();
      var monthIndexList = indexList.map(function(i) {
        return i + month;
      });

      var makeMonthStr = function(index) {
        var monthYear = year;
        var getIndex = index;
        if (index > 11) {
          monthYear = year + 1;
          getIndex = index - 12;
        }
        var monthName = monthTexts[getIndex];
        var monthStr = monthName + "/" + monthYear;
        return monthStr;
      };

      var strList = monthIndexList.map(function(index) {
        return { text: makeMonthStr(index), value: index - month };
      });

      return strList;
    },
    meses12s() {
      var monthTexts = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Augosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Decembro",
      ];
      var indexList = [0, 1, 2];
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth();
      var monthIndexList = indexList.map(function(i) {
        return i + month;
      });

      var makeMonthStr = function(index) {
        var monthYear = year;
        var getIndex = index;
        if (index > 11) {
          monthYear = year + 1;
          getIndex = index - 12;
        }
        var monthName = monthTexts[getIndex];
        var index1 = index - month + 1;
        var monthStr = index1 + "° mês (" + monthName + "/" + monthYear + ")";
        return monthStr;
      };

      var strList = monthIndexList.map(function(index) {
        return { text: makeMonthStr(index), value: index - month };
      });

      return strList;
    },
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    descrNesc() {
      if (
        this.mov.tipo === "Outro" ||
        this.mov.tipo === "Entrada 1ª Consulta"
      ) {
        return true;
      } else if (!this.mov.direction) {
        return true;
      } else {
        return false;
      }
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
    localCode() {
      if (
        sessionStorage.getItem("current-user") ==
        "umarizalbelem@clubedanutricionista.com.br"
      ) {
        return "belemx";
      } else if (
        sessionStorage.getItem("current-user") ==
        "bc@clubedanutricionista.com.br"
      ) {
        return "balcam";
      } else if (
        sessionStorage.getItem("current-user") ==
        "fortaleza.salinas@clubedanutricionista.com.br"
      ) {
        return "fortal";
      } else if (
        sessionStorage.getItem("current-user") == "thayanalucy@gmail.com"
      ) {
        return "belemx";
      } else {
        return "balcam";
      }
    },
    isPrograma() {
      var programasList = [
        "Programa Clínico Funcional",
        "Programa Modulação Intestinal",
        "Programa Clínico Funcional com Estética",
        "Programa Modulação Intestinal com Estética",
        "Programa Clínico Funcional (plano família)",
        "Programa Modulação Intestinal (plano família)",
        "Power Shape 30",
        "Programa 12S Online",
        "Programa 12S",
        "Programa 12S com Estética",
        "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)",
        "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)",
      ];
      return programasList.includes(this.mov.tipo);
    },
    getItems() {
      return this.vendaItems.map(function(item) {
        return item.nome;
      });
    },
    bmi() {
      var bmi = this.pesoAtual / ((this.altura * this.altura) / 10000);

      return Math.round(bmi * 100) / 100;
    },
    bmiColor() {
      if (this.bmi < 18.5) {
        return "#ff0000";
      } else if (this.bmi > 18.5 && this.bmi < 25) {
        return "#209c05";
      } else if (this.bmi > 25 && this.bmi < 30) {
        return "#ffff00";
      } else if (this.bmi > 30 && this.bmi < 35) {
        return "#ff8000";
      } else if (this.bmi > 35) {
        return "#ff0000";
      } else {
        return "#c0c0c0";
      }
    },
  },
  methods: {
    getMov() {
      this.mov = this.$store.state.firestore.orcamentoEditar.mov;
      this.nome = this.$store.state.firestore.orcamentoEditar.nome;
      this.descontoAct =
        parseFloat(this.$store.state.firestore.orcamentoEditar.mov.desconto) >
        0;
      var isoStr = new Date(
        this.$store.state.firestore.orcamentoEditar.orcamentoFim
      )
        .toISOString()
        .substring(0, 10);
      this.orcamentoFim = isoStr;
    },
    checkReflexPodal(pac) {
      if (pac.def2 == "REFLEXOLOGIA PODAL (10 sessões)") {
        pac.area1 = "PÉ";
        pac.def1 = "DUO";
        this.setValor(this.mov.tipo, this.mov.descr);
      } else if (pac.def2 == "REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)") {
        pac.def1 = "DUO";
        pac.area1 = "PÉ";
        this.setValor(this.mov.tipo, this.mov.descr);
      } else if (pac.def2 == "TERAPIA COM VENTOSAS (10 sessões)") {
        pac.area1 = "---";
        pac.def1 = "DUO";
        this.setValor(this.mov.tipo, this.mov.descr);
      } else if (pac.def2 == "AURICULOTERAPIA (10 sessões)") {
        pac.area1 = "---";
        pac.def1 = "DUO";
        this.setValor(this.mov.tipo, this.mov.descr);
      } else if (pac.def2 == "DRY NEEDLING (10 sessões)") {
        pac.area1 = "---";
        pac.def1 = "DUO";
        this.setValor(this.mov.tipo, this.mov.descr);
      }
      pac.dataInicio = 0;
      this.setMillisPacote(pac.index);
      console.log(this.mov.pacotes);
    },
    setMillisPacote(index) {
      var date = new Date();
      var dateI =
        this.mov.pacotes[index].dataInicio * 28 * 24 * 60 * 60 * 1000 +
        date.getTime();
      var dateII = new Date(dateI);
      var millis = dateII.getTime();
      this.mov.pacotes[index].millisInicio = millis;
    },
    setPacIncl() {
      if (this.mov.tipo == "Programa 12S Plano Básico") {
        this.mov.pacotes[0] = {
          def1: "1 área",
          def2: "Protocolo HIBRIUS 12S",
          area1: this.pac12S1area,
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (this.mov.tipo == "Programa 12S Plano Premium") {
        this.mov.pacotes[0] = {
          def1: "2 áreas",
          def2: "Protocolo HIBRIUS 12S",
          area1: this.pac12S1area,
          area2: this.pac12S2area,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (this.mov.tipo == "Programa 12S Plano VIP") {
        this.mov.pacotes[0] = {
          def1: "3 áreas",
          def2: "Protocolo HIBRIUS 12S",
          area1: this.pac12S1area,
          area2: this.pac12S2area,
          area3: this.pac12S3area,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (this.mov.tipo == "Protocolo Harmonização Corporal 24H") {
        this.mov.pacotes[0] = {
          def1: "2 áreas",
          def2: "Massagem Moduladora",
          area1: this.pacH1area,
          area2: this.pacH2area,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (this.mov.tipo == "Protocolo Glúteo de Ouro") {
        this.mov.pacotes[0] = {
          def1: "Glúteo",
          def2: "8 passos",
          area1: "GLÚTEO",
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (
        this.mov.tipo == "Protocolo Lipodraining 24H" ||
        this.mov.tipo == "Protocolo Detox + Lipodraining 24H"
      ) {
        if (this.pacDldef1) {
          this.pacDl1area = null;
          this.pacDl2area = null;
          this.mov.pacotes[0] = {
            def1: "Corpo inteiro",
            def2: "Drenagem Linfática",
            area1: null,
            area2: null,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            index: 0,
          };
        } else {
          this.mov.pacotes[0] = {
            def1: "2 áreas",
            def2: "Drenagem Linfática",
            area1: this.pacDl1area,
            area2: this.pacDl2area,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            index: 0,
          };
        }
      }
    },
    getCorrectAreas(def1) {
      if (def1 == "DUO") {
        return this.areasPares;
      } else {
        return this.areas;
      }
    },
    async getCpf(id) {
      var el = this.pacientes.find((item) => item.value == id);
      if (el.data.cpf != undefined) {
        this.cpfOk = true;
        this.mov.pacienteCpf = el.data.cpf;
        this.nome = el.data.nome;
      } else {
        var cpfGot = await firebase
          .firestore()
          .collection("users")
          .doc(this.mov.paciente)
          .get()
          .then(function(doc) {
            return doc.data().cpf;
          });

        if (cpfGot != undefined) {
          this.cpfOk = true;
          this.mov.pacienteCpf = cpfGot;
        } else {
          this.cpfOk = false;
          this.mov.pacienteCpf = null;
        }
      }
    },
    setNrPacotes(nr, tipo) {
      if (tipo == "Programa Clínico Funcional com Estética") {
        if (nr == 1) {
          this.mov.valor = 900 + 500;
        } else if (nr == 2) {
          this.mov.valor = 900 + 500 + 400;
        } else if (nr >= 3) {
          this.mov.valor = (nr - 2) * 300 + 900 + 500 + 400;
        }
      } else {
        if (nr == 1) {
          this.mov.valor = 990 + 500;
        } else if (nr == 2) {
          this.mov.valor = 990 + 500 + 400;
        } else if (nr >= 3) {
          this.mov.valor = (nr - 2) * 300 + 990 + 500 + 400;
        }
      }
      this.mov.pacotes = [];
      for (var ix = 0; ix < nr; ix++) {
        this.mov.pacotes.push({
          def1: null,
          def2: null,
          area1: null,
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: ix,
        });
      }
    },
    save(date) {
      this.$refs.menu.save(date);
      var dStr = date.substring(8, 10);
      var mStr = date.substring(5, 7);
      var aStr = date.substring(0, 4);
      var niceDate = dStr + "/" + mStr + "/" + aStr;
      this.displayDataNasc = niceDate;
      console.log("this.dataNascimento", this.dataNascimento);
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async preloadImages() {
      console.log(
        "this.funcionarioOnline.assinatura",
        this.funcionarioOnline.assinatura
      );
      if (
        this.funcionarioOnline.assinatura !== undefined &&
        this.funcionarioOnline.assinatura !== ""
      ) {
        this.toDataURL(this.funcionarioOnline.assinatura, (data) => {
          this.preloadSignature.src = data;
        });
      } else {
        this.preloadSignature.src = require("@/assets/signature_none.jpg");
      }

      if (this.isFranquia) {
        this.preloadLogo.src = require("@/assets/logoCompleto.png");
      } else if (
        this.userInfo.profilePicture !== undefined &&
        this.userInfo.profilePicture !== "" &&
        !this.isFranquia
      ) {
        this.toDataURL(this.userInfo.profilePicture, (data) => {
          this.preloadLogo.src = data;
        });
      } else {
        this.preloadLogo.src = require("@/assets/signature_none.jpg");
      }

      this.preloadLogo.onload = function() {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    colorPerc(perc) {
      if (perc <= 25) {
        return "#660000";
      } else if (perc > 25 && perc <= 50) {
        return "#ff0000";
      } else if (perc > 50 && perc <= 75) {
        return "#ff8000";
      } else if (perc > 75 && perc <= 88) {
        return "#ffff1f";
      } else if (perc > 88 && perc <= 95) {
        return "#cccc00";
      } else if (perc > 95) {
        return "#008000";
      } else {
        return "#c0c0c0";
      }
    },
    async downloadAv() {
      this.progressDialog = true;
      this.saved = false;
      var id = this.$store.state.firestore.orcamentoEditar.id;
      var d = new Date();
      var millis = d.getTime();
      var day = d.getDate();
      var month = d.getMonth() + 1;
      var year = d.getFullYear();
      var dayStr;
      if (day > 9) {
        dayStr = day.toString();
      } else {
        dayStr = "0" + day.toString();
      }

      var monthStr;
      if (month > 9) {
        monthStr = month.toString();
      } else {
        monthStr = "0" + month.toString();
      }

      var yearStr = year.toString();

      var dateStr = dayStr + "/" + monthStr + "/" + yearStr;

      var orcamentoDate = new Date(this.orcamentoFim + " 18:00:00");
      var orcamentoFimMillis = orcamentoDate.getTime();

      var avDoc = {
        id: id,
        mov: this.mov,
        date: millis,
        dateStr: dateStr,
        orcamentoFim: orcamentoFimMillis,
        nome: this.nome,
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("avGratuita")
        .doc(id)
        .update(avDoc);

      await firebase
        .firestore()
        .collection("users")
        .doc(this.mov.paciente)
        .collection("orcamentos")
        .doc(id)
        .update(avDoc);

      var doc = new jspdf("p");
      var fontSize = 12;
      doc.setFontSize(fontSize);
      doc.setFont("Times");
      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function(
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc.setFont("Roboto").splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(textList, centered = false, textStart = textStartYPos) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page
        
        for (var i = 0; i < textList.length; i++) {
            var lines = doc.setFont("Montserrat-Medium").splitTextToSize(textList[i], 180);
            
            for (var j = 0; j < lines.length; j++) {
                // Check if the current line would go past the threshold
                if (textStart > pageHeightThreshold) {
                    doc.addPage();
                    textStart = newYStart; // Reset the text start position for the new page
                }
                
                // Whether to center the text or not
                if (centered) {
                    centeredText(lines[j], textStart);
                } else {
                    doc.text(startXPos, textStart, lines[j]);
                }
                
                textStart += (fontSize / 2); // Increment the y position for each line of text
            }

            // Increment after each text block rather than each line
            if (!centered) {
                textStart += (fontSize / 2); // This accounts for the space after each block of text
            }
        }
        
        return textStart; // Return the updated text start position
    };

      var descrTratamento = function(tipo) {
        if (tipo == "1 consulta nutricional patrocinada") {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (tipo == "1 consulta nutricional Crédito / Débito") {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (tipo == "1 consulta nutricional PIX / Transferência") {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (
          tipo == "1 consulta nutricional (plano família, mínimo 2 pessoas)"
        ) {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (tipo == "1 consulta nutricional SEM RETORNO") {
          return "Uma consulta nutricional + cardápios personalizados + prescrição de supplementos";
        } else if (tipo == "Programa Clínico Funcional") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa Modulação Intestinal") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa Clínico Funcional com Estética") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas + tratamentos estéticos semanais";
        } else if (tipo == "Programa Modulação Intestinal com Estética") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas + tratamentos estéticos semanais";
        } else if (tipo == "Programa Clínico Funcional (plano família)") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa Modulação Intestinal (plano família)") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)" || tipo == "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)") {
          return "Durante 12 semanas: 3 consultas nutricionais + cardápios personalizados + prescrição de supplementos + video-aulas + livro/diário do programa + exame nutrigenético";
        }  else if (tipo == "Programa 12S Online") {
          return "Durante 12 semanas: 3 consultas nutricionais + cardápios personalizados + prescrição de supplementos + video-aulas + livro/diário do programa";
          // } else if (tipo == "Programa 12S Plano Básico") {
          //   return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + tratamentos estéticos semanais + video-aulas + livro/diário do programa";
          // } else if (tipo == "Programa 12S Plano Premium") {
          //   return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + tratamentos estéticos semanais + video-aulas + livro/diário do programa";
          // } else if (tipo == "Programa 12S Plano VIP") {
          //   return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + tratamentos estéticos semanais + video-aulas + livro/diário do programa";
        } else if (tipo == "Programa 12S") {
          return "Durante 12 semanas: 3 consultas nutricionais + cardápios personalizados + prescrição de supplementos + video-aulas + livro/diário do programa";
        } else if (tipo == "Programa 12S com Estética") {
          return "Durante 12 semanas: 3 consultas nutricionais + cardápios personalizados + prescrição de supplementos + video-aulas + livro/diário do programa + tratamentos estéticos semanais";
        } else if (tipo == "Protocolo Harmonização Corporal 24H") {
          return "Durante 4 semanas: 1 consulta nutricional + massagem moduladora 2 vezes por semana";
        } else if (tipo == "Protocolo Glúteo de Ouro") {
          return "8 sessões: Peeling Diamante, Ultracavitação ou velox, Endermo corporal com bioativos, Vacuoterapia, Radiofrequência, Pump Up, Corrente Russa, Crio Home care";
        } else if (tipo == "Pacote Estético") {
          return "Tratamentos estéticos semanais";
        } else if (tipo == "1 sessão estética avulsa") {
          return "Tratamento estético";
        } else if (tipo == "1 exame de bioimpedância") {
          return "Exame que mede a quantidade de água, massa magra e gordura em cada área do corpo";
        } else if (
          tipo == "1 exame de Intolerância e Hipersensibilidade alimentar"
        ) {
          return "Exame de intolerância e hipersensibilidade alimentar de 216 alimentos";
        } else if (tipo == "Protocolo Kirchhoff") {
          return "Uma consulta nutricional + prescrição de cardápios personalizados + prescrição de suplementos, para fortalecer a imunidade";
        } else if (tipo == "Pacote 90 dias Protocolo Kirchhoff") {
          return "Durante 90 dias: 3 consultas nutricionais + prescrição de cardápios personalizados + prescrição de suplementos, para fortalecer a imunidade";
        } else if (tipo == "Outro") {
          return "";
        } else {
          return tipo;
        }
      };

      //   var localCode = this.localCode;
      //   var adding = function(accumulator, a) {
      //     return accumulator + a;
      //   };
      //   var valorArray = this.mov.soldObjs.map(function(obj) {
      //     return obj.valor[localCode];
      //   });

      var valorOriginal =
        parseFloat(this.mov.valor) + parseFloat(this.mov.desconto); //valorArray.reduce(adding, 0);

      var niceString = function(valor) {
        var valorKomma;

        var decimals = Math.round((valor - Math.floor(valor)) * 100);
        var decimalsStr;
        if (decimals < 1) {
          decimalsStr = "00";
        } else if (decimals < 10) {
          decimalsStr = "0" + decimals.toString();
        } else {
          decimalsStr = decimals.toString();
        }

        var valorGanz = Math.floor(valor);

        if (valorGanz > 1000) {
          var tusiger = Math.floor(valor / 1000);
          var rest = valorGanz % 1000;
          var restStr;
          if (rest < 10) {
            restStr = "00" + rest.toString();
          } else if (rest < 100) {
            restStr = "0" + rest.toString();
          } else {
            restStr = rest.toString();
          }
          valorKomma = tusiger.toString() + "." + restStr;
        } else {
          valorKomma = valorGanz.toString();
        }
        return "R$ " + valorKomma + "," + decimalsStr;
      };

      var createTablesForSug = function(mov, startPosY) {
        var bodyAvText = [
          [mov.tipo],
          [`${descrTratamento(mov.tipo)}`],
          [`Valor original: ${niceString(valorOriginal)}`],
          [`Desconto: ${niceString(mov.desconto)}`],
          [`Valor com desconto: ${niceString(mov.valor)}`],
          [`Voucher de desconto: ${id}`],
        ];

        doc.autoTable({
          startY: startPosY, //(lastPos / 2) * 0.7 + startPosY,
          startx: startXPos,
          head: [["Sugestão de tratamento"]],
          body: bodyAvText,
          headStyles: {
            fillColor: [226, 232, 159],
            textColor: 40,
            fontStyle: "regular",
          },
          bodyStyles: {
            fillColor: [243, 245, 216],
          },
          alternateRowStyles: {
            fillColor: [249, 250, 235],
          },
        });
        startPosY = doc.previousAutoTable.finalY;
        return doc.previousAutoTable.finalY;
      };

      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      var dStr = this.orcamentoFim.substring(8, 10);
      var mStr = this.orcamentoFim.substring(5, 7);
      var aStr = this.orcamentoFim.substring(0, 4);
      var prazo = dStr + "/" + mStr + "/" + aStr;

      var newEnd1 = createTexts(
        [`Orçamento: (Prazo: ${prazo})`, `${this.nome}`],
        true,
        textStartYPos + 16 // + 1 * (fontSize * (3 / 4))
      );

      var newEnd3 = createTablesForSug(this.mov, newEnd1 + 24);

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;
      textStartYPos = imgHeight + newEnd3 + 16;

      doc.addImage({
        imageData: this.preloadSignature,
        y: newEnd3 + 16,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          `Nutricionista ${this.funcionarioOnline.nome}`,
          `CRN: ${this.funcionarioOnline.crn}`,
          `Data: ${day}/${month}/${year}`,
          "",
          `${this.userInfo.addressRoad} - ${this.userInfo.addressComplement}`,
          `${this.userInfo.addressCep} ${this.userInfo.addressCity} - ${this.userInfo.addressBairro}`,
          `tel.: ${this.userInfo.phoneClients}`,
        ],
        true,
        textStartYPos // + texts.length * (fontSize * (3 / 4))
      );

      this.saved = true;
      alert("Gerando PDF");
      doc.save("orcamento.pdf");
    },
    adjustValorDesc() {
      this.mov.valor -= this.mov.desconto;
    },
    descontoImpact() {
      if (!this.descontoAct) {
        var localCode = this.localCode;
        var adding = function(accumulator, a) {
          return accumulator + a;
        };
        var valorArray = this.mov.soldObjs.map(function(obj) {
          return obj.valor[localCode];
        });

        this.mov.valor = valorArray.reduce(adding, 0);
      } else {
        this.mov.valor -= this.mov.desconto;
      }
    },
    niceString(valor) {
      var valorKomma;

      var decimals = Math.round((valor - Math.floor(valor)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valor);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valor / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    changeTratSugg(tipoNew) {
      this.numberPacote = 0;
      this.mov.soldObjs = [];
      this.mov.descr = null;
      this.mov.comprovante = null;
      this.mov.desconto = 0;
      this.mov.pacotes = [
        {
          def1: null,
          def2: null,
          area1: null,
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        },
      ];
      this.mov.forma = null;
      this.warnMessages = [];
      this.setValor(tipoNew, this.mov.descr);
    },
    setValor(tipo, descr) {
      var tipoFilter = function(el) {
        return el.nome == tipo;
      };
      var descrFilter = function(el) {
        return el.nome == descr;
      };
      // push soldObjs
      var vendaItemList = this.vendaItems.filter(tipoFilter);
      var vendaItem = vendaItemList[0];
      this.mov.soldObjs.push(vendaItem);

      if (this.mov.descr !== null) {
        var aVendaItem;
        var sessaoEstetList = this.sessoesEsteticos.filter(descrFilter);
        if (sessaoEstetList.length > 0) {
          aVendaItem = sessaoEstetList[0];
          this.mov.soldObjs.push(aVendaItem);
        }
      }

      console.log(this.mov.pacotes.length);
      if (this.mov.pacotes.length > 0) {
        for (var j = 0; j < this.mov.pacotes.length; j++) {
          if (this.mov.pacotes[j].def1 !== null) {
            var pacDef1 = this.mov.pacotes[j].def1;
            var pacItemList = this.pacotesEsteticos1.filter(function(el) {
              return el.nome == pacDef1;
            });
            var pacItem = pacItemList[0];
            this.mov.soldObjs.push(pacItem);
          }
        }
      }

      console.log(this.mov.soldObjs);

      // localCode to map through soldObjs

      var localCode = this.localCode;
      var valorArray = this.mov.soldObjs.map(function(obj) {
        return obj.valor[localCode];
      });

      var adding = function(accumulator, a) {
        return accumulator + a;
      };

      console.log(valorArray);

      this.mov.valor = valorArray.reduce(adding, 0);
    },

    setNull(dir) {
      this.mov = {
        direction: dir,
        tipo: null,
        descr: null,
        paciente: null,
        comprovante: null,
        valor: null,
        pacotes: [
          {
            def1: null,
            def2: null,
            area1: null,
            area2: null,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            index: 0,
          },
        ],
        forma: null,
      };
      this.temasToLiberate = [];
    },
  },
  mounted() {
    setTimeout(() => {
      this.preloadImages();
      this.getMov();
    }, 500);
  },
};
</script>
