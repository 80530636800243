<template>
  <div>
    <v-dialog v-model="alertNovaMensagem" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Uma mensagem nova!</v-card-title
        >
        <v-card-subtitle class="d-flex justify-center"
          >Um(a) paciente mandou uma nova mensagem</v-card-subtitle
        >
        <v-card-text class="d-flex justify-center">
          <v-icon large color="red">mdi-email-alert-outline</v-icon>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="alertNovaMensagem = false" color="#D6DBDF">Fechar</v-btn>
          <v-btn to="/Chat" @click="alertNovaMensagem = false" color="#c5d13f">Visualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      v-model="drawer"
      class="grey lighten-5"
      :expand-on-hover="expandOnHover"
      :mini-variant="miniVariant"
      :right="right"
      :permanent="permanent"
      v-if="userInfos !== null && isFranquia"
    >
      <v-list-item
        two-line
        :class="miniVariant && 'px-0'"
        v-if="
          funcionarioOnline.papel === 'Proprietária' ||
          funcionarioOnline.papel === 'Administradora' ||
          funcionarioOnline.papel === 'Nutricionista 1'
        "
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" to="/personalData">
              <v-icon left>{{ icons.mdiAccountDetailsOutline }}</v-icon>
            </v-chip>
          </template>
          <span>Editar dados da clínica / Dados pessoais</span>
        </v-tooltip>
        <v-list-item-content>
          <v-list-item-title>{{ userInfos.name }}</v-list-item-title>
          <v-list-item-subtitle>{{
            userInfos.addressCity
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav class="py-0">
        <v-divider></v-divider>

        <div
          v-if="
            funcionarioOnline.papel === 'Proprietária' ||
            funcionarioOnline.papel === 'Administradora'
          "
        >
          <v-list-item v-for="item in itemsAdmin" :key="item.title" link>
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Nutricionista 1'">
          <v-list-item
            v-for="item in itemsNutricionista1"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Nutricionista 2'">
          <v-list-item
            v-for="item in itemsNutricionista2"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Nutricionista 3'">
          <v-list-item
            v-for="item in itemsNutricionista2"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Recepcionista'">
          <v-list-item
            v-for="item in itemsRecepcionista"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Esteticista'">
          <v-list-item v-for="item in itemsEsteticista" :key="item.title" link>
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Estagiária'">
          <v-list-item v-for="item in itemsEstagiaria" :key="item.title" link>
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-else-if="userInfos !== null && !isFranquia"
      v-model="drawer"
      class="grey lighten-5"
      :expand-on-hover="expandOnHover"
      :mini-variant="miniVariant"
      :right="right"
      :permanent="permanent"
    >
      <v-list-item
        two-line
        :class="miniVariant && 'px-0'"
        v-if="
          funcionarioOnline.papel === 'Proprietária' ||
          funcionarioOnline.papel === 'Administradora' ||
          funcionarioOnline.papel === 'Nutricionista 1'
        "
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" to="/personalData">
              <v-icon left>{{ icons.mdiAccountDetailsOutline }}</v-icon>
            </v-chip>
          </template>
          <span>Editar dados da clínica / Dados pessoais</span>
        </v-tooltip>
        <v-list-item-content>
          <v-list-item-title>{{ userInfos.name }}</v-list-item-title>
          <v-list-item-subtitle>{{
            userInfos.addressCity
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav class="py-0">
        <v-divider></v-divider>

        <div
          v-if="
            funcionarioOnline.papel === 'Proprietária' ||
            funcionarioOnline.papel === 'Administradora'
          "
        >
          <v-list-item v-for="item in itemsAdmin_nF" :key="item.title" link>
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Nutricionista 1'">
          <v-list-item
            v-for="item in itemsNutricionista1_nF"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Nutricionista 2'">
          <v-list-item
            v-for="item in itemsNutricionista2_nF"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Nutricionista 3'">
          <v-list-item
            v-for="item in itemsNutricionista2_nF"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Recepcionista'">
          <v-list-item
            v-for="item in itemsRecepcionista_nF"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Esteticista'">
          <v-list-item
            v-for="item in itemsEsteticista_nF"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>

        <div v-if="funcionarioOnline.papel === 'Estagiária'">
          <v-list-item
            v-for="item in itemsEstagiaria_nF"
            :key="item.title"
            link
          >
            <v-menu offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-if="chatUnansweredP(item.title)" color="red">{{
                      icons.mdiChatAlert
                    }}</v-icon>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
              <v-list>
                <v-banner color="grey lighten-3" single-line>
                  <v-avatar slot="icon" color="#c5d13f" size="35">
                    <v-icon size="80%">{{ item.icon }}</v-icon>
                  </v-avatar>
                  {{ item.title }}
                  <template v-slot:actions>
                    <v-btn icon>
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                </v-banner>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(subitem, index) in item.subitems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.route }"
                    style="text-decoration: none; color: inherit"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    replace
                  >
                    <v-list-item
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      :href="href"
                      @click="navigate"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="chatUnansweredP(subitem.title)"
                          color="red"
                          >{{ icons.mdiChatAlert }}</v-icon
                        >
                        <v-icon v-else>{{ subitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="drawer"
      class="grey lighten-5"
      :expand-on-hover="expandOnHover"
      :mini-variant="miniVariant"
      :right="right"
      :permanent="permanent"
      v-else-if="userInfos == undefined || userInfos == null"
    >
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  mdiAccountNetwork,
  mdiHandshakeOutline,
  mdiFoodApple,
  mdiFoodAppleOutline,
  mdiDraw,
  // mdiBookshelf,
  mdiTelevisionPlay,
  // mdiChartBarStacked,
  mdiCurrencyUsd,
  // mdiHelpCircleOutline,
  mdiAccountDetailsOutline,
  mdiFileDocumentEditOutline,
  mdiClose,
  mdiCalculatorVariantOutline,
  mdiTextBoxPlusOutline,
  mdiTextBoxCheckOutline,
  // mdiAttachment,
  // mdiFormatListBulleted,
  // mdiCached,
  mdiNeedle,
  mdiClipboardAlertOutline,
  mdiBookAccountOutline,
  mdiBookPlusOutline,
  mdiBookSearchOutline,
  // mdiCupWater,
  // mdiCoffee,
  // mdiNoodles,
  // mdiCupcake,
  // mdiFoodDrumstick,
  // mdiHospitalBuilding,
  // mdiAccountSupervisor,
  // mdiGenderMaleFemale,
  // mdiHumanCane,
  // mdiChartLine,
  mdiBookOpenVariant,
  mdiBullhornOutline,
  mdiTableEdit,
  mdiDatabaseCog,
  // mdiTableCheck,
  // mdiFormatListChecks,
  mdiPlaylistPlus,
  mdiPlaylistEdit,
  mdiTextSearch,
  mdiCash100,
  mdiCartPlus,
  mdiTextBoxSearchOutline,
  mdiTextBoxOutline,
  mdiHelpNetwork,
  mdiFileQuestion,
  mdiTelevisionGuide,
  mdiChat,
  mdiChatProcessing,
  mdiChatAlert,
  mdiCalendarCheck,
  mdiBookshelf,
  mdiBookCogOutline,
  // mdiBookEducationOutline, //Treinamentos !!!
} from "@mdi/js";

import firebase from "firebase";

export default {
  data() {
    return {
      userInfos: null,
      anyUnansweredN: false,
      alertNovaMensagem: false,
      drawer: null,
      itemsAdmin: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Plano Alimentar",
          icon: mdiFoodApple,
          subitems: [
            {
              title: "Criar Nova Dieta",
              icon: mdiCalculatorVariantOutline,
              route: "PlanoAlimentarCalcularNovo",
            },
            {
              title: "Dietas Salvas",
              icon: mdiTableEdit,
              route: "DietasSalvas",
            },
            {
              title: "Banco de dados de Alimentos",
              icon: mdiDatabaseCog,
              route: "AdminAlimentos",
            },
            // {
            //   title: "Modelos de Dietas",
            //   icon: mdiTableCheck,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Anexar Dietas e Orientações",
            //   icon: mdiAttachment,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Orientações Gerais",
            //   icon: mdiTextBoxOutline,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Lista de Compras",
            //   icon: mdiFormatListBulleted,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Tabela de Substituição",
            //   icon: mdiCached,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
          ],
        },
        {
          title: "Receitas",
          icon: mdiFoodAppleOutline,
          subitems: [
            {
              title: "Criar Receita Nova",
              icon: mdiPlaylistPlus,
              route: "CriarReceitaNova",
            },
            {
              title: "Lista de Receitas",
              icon: mdiPlaylistEdit,
              route: "ReceitasTodas",
            },
            {
              title: "Pesquisar Receitas",
              icon: mdiTextSearch,
              route: "ReceitasSalvas",
            },
          ],
        },
        {
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          subitems: [
            {
              title: "Prescrição de Fórmulas",
              icon: mdiDraw,
              route: "FormulaPrescrever",
            },
            {
              title: "Armazenar Fórmula",
              icon: mdiTextBoxPlusOutline,
              route: "FormulaCriar",
            },
            {
              title: "Lista de Formulas",
              icon: mdiTextBoxCheckOutline,
              route: "FormulasTodas",
            },
            {
              title: "Pesquisar Formulas",
              icon: mdiTextBoxSearchOutline,
              route: "FormulasSalvas",
            },
          ],
        },
        {
          title: "Exames Laboratoriais",
          icon: mdiNeedle,
          subitems: [
            {
              title: "Prescrever Exame",
              icon: mdiNeedle,
              route: "Exames",
            },
          ],
        },
        {
          title: "Orientações",
          icon: mdiClipboardAlertOutline ,
          subitems: [
            {
              title: "Nova orientação nutricional",
              icon: mdiClipboardAlertOutline,
              route: "Orientacoes",
            },
          ],
        },
        {
          title: "Avaliação gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Nova avaliação gratuita",
              icon: mdiBookPlusOutline,
              route: "AvaliacaoGratuitaNova",
            },
            {
              title: "Avaliações gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Vídeo-Cursos/Ebooks",
          icon: mdiTelevisionPlay,
          subitems: [
            {
              title: "Liberar Acesso aos Cursos",
              icon: mdiTelevisionGuide,
              route: "LiberarVideoAulas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
            {
              title: "Livro Caixa",
              icon: mdiBookOpenVariant,
              route: "FinanceiroLivro",
            },
            {
              title: "Fundo Publicidade",
              icon: mdiBullhornOutline,
              route: "FundoPublicidade",
            },
          ],
        },
        {
          title: "Contratos",
          icon: mdiFileDocumentEditOutline,
          subitems: [
            {
              title: "Contrato de Prestação de Serviços (atendimento padrão)",
              icon: mdiFileDocumentEditOutline,
              route: "Contrato",
            },
            {
              title: "Contrato de Prestação de Serviços (apenas estética)",
              icon: mdiFileDocumentEditOutline,
              route: "ContratoEstet",
            },
          ],
        },
        // {
        //   title: "Ajuda",
        //   icon: mdiHelpCircleOutline,
        //   subitems: [
        //     {
        //       title: "Manual",
        //       icon: mdiBookOpenVariant,
        //       route: "PlanoAlimentarCalcularNovo",
        //     },
        //     {
        //       title: "Suporte e Dúvidas",
        //       icon: mdiHelpCircleOutline,
        //       route: "PlanoAlimentarCalcularNovo",
        //     },
        //   ],
        // },
        {
          title: "Parceiros",
          icon: mdiAccountNetwork,
          subitems: [
            {
              title: "Gerir Parceiros",
              icon: mdiAccountNetwork,
              route: "Parceiros",
            },
          ],
        },
        {
          title: "Clube de Vantagens",
          icon: mdiHandshakeOutline, //shake hands
          subitems: [
            {
              title: "Clube de Vantagens",
              icon: mdiHandshakeOutline, //shake hands
              route: "ClubeDeVantagens",
            },
          ],
        },
        {
          title: "Treinamentos",
          icon: mdiBookshelf,
          subitems: [
            {
              title: "Treinamentos Software",
              icon: mdiBookCogOutline,
              route: "TreinamentosSoftware",
            },
            // {
            //   title: "Treinamentos Clínica",
            //   icon: mdiBookEducationOutline,
            //   route: "TreinamentosClube",
            // },
          ],
        },
      ],
      itemsNutricionista1: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Plano Alimentar",
          icon: mdiFoodApple,
          subitems: [
            {
              title: "Criar Nova Dieta",
              icon: mdiCalculatorVariantOutline,
              route: "PlanoAlimentarCalcularNovo",
            },
            {
              title: "Dietas Salvas",
              icon: mdiTableEdit,
              route: "DietasSalvas",
            },
            {
              title: "Banco de dados de Alimentos",
              icon: mdiDatabaseCog,
              route: "AdminAlimentos",
            },
            // {
            //   title: "Criar Nova Dieta Automática",
            //   icon: mdiCalculatorVariantOutline,
            //   route: "PlanoAlimentarAuto",
            // },
            // {
            //   title: "Modelos de Dietas",
            //   icon: mdiTableCheck,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Anexar Dietas e Orientações",
            //   icon: mdiAttachment,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Orientações Gerais",
            //   icon: mdiTextBoxOutline,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Lista de Compras",
            //   icon: mdiFormatListBulleted,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Tabela de Substituição",
            //   icon: mdiCached,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
          ],
        },
        {
          title: "Receitas",
          icon: mdiFoodAppleOutline,
          subitems: [
            {
              title: "Criar Receita Nova",
              icon: mdiPlaylistPlus,
              route: "CriarReceitaNova",
            },
            {
              title: "Lista de Receitas",
              icon: mdiPlaylistEdit,
              route: "ReceitasTodas",
            },
            {
              title: "Pesquisar Receitas",
              icon: mdiTextSearch,
              route: "ReceitasSalvas",
            },
          ],
        },
        {
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          subitems: [
            {
              title: "Prescrição de Fórmulas",
              icon: mdiDraw,
              route: "FormulaPrescrever",
            },
            {
              title: "Armazenar Fórmula",
              icon: mdiTextBoxPlusOutline,
              route: "FormulaCriar",
            },
            {
              title: "Lista de Formulas",
              icon: mdiTextBoxCheckOutline,
              route: "FormulasTodas",
            },
            {
              title: "Pesquisar Formulas",
              icon: mdiTextBoxSearchOutline,
              route: "FormulasSalvas",
            },
          ],
        },
        {
          title: "Exames Laboratoriais",
          icon: mdiNeedle,
          subitems: [
            {
              title: "Prescrever Exame",
              icon: mdiNeedle,
              route: "Exames",
            },
          ],
        },
        {
          title: "Orientações",
          icon: mdiClipboardAlertOutline ,
          subitems: [
            {
              title: "Nova orientação nutricional",
              icon: mdiClipboardAlertOutline,
              route: "Orientacoes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Nova avaliação Gratuita",
              icon: mdiBookPlusOutline,
              route: "AvaliacaoGratuitaNova",
            },
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Vídeo-Cursos/Ebooks",
          icon: mdiTelevisionPlay,
          subitems: [
            {
              title: "Liberar Acesso aos Cursos",
              icon: mdiTelevisionGuide,
              route: "LiberarVideoAulas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
            {
              title: "Livro Caixa",
              icon: mdiBookOpenVariant,
              route: "FinanceiroLivro",
            },
            {
              title: "Fundo Publicidade",
              icon: mdiBullhornOutline,
              route: "FundoPublicidade",
            },
          ],
        },
        {
          title: "Contratos",
          icon: mdiFileDocumentEditOutline,
          subitems: [
            {
              title: "Contrato de Prestação de Serviços (atendimento padrão)",
              icon: mdiFileDocumentEditOutline,
              route: "Contrato",
            },
            {
              title: "Contrato de Prestação de Serviços (apenas estética)",
              icon: mdiFileDocumentEditOutline,
              route: "ContratoEstet",
            },
          ],
        },
        {
          title: "Parceiros",
          icon: mdiAccountNetwork,
          subitems: [
            {
              title: "Gerir Parceiros",
              icon: mdiAccountNetwork,
              route: "Parceiros",
            },
          ],
        },
        {
          title: "Clube de Vantagens",
          icon: mdiHandshakeOutline, //shake hands
          subitems: [
            {
              title: "Clube de Vantagens",
              icon: mdiHandshakeOutline, //shake hands
              route: "ClubeDeVantagens",
            },
          ],
        },
        {
          title: "Treinamentos",
          icon: mdiBookshelf,
          subitems: [
            {
              title: "Treinamentos Software",
              icon: mdiBookCogOutline,
              route: "TreinamentosSoftware",
            },
            // {
            //   title: "Treinamentos Clínica",
            //   icon: mdiBookEducationOutline,
            //   route: "TreinamentosClube",
            // },
          ],
        },
      ],
      itemsNutricionista2: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Plano Alimentar",
          icon: mdiFoodApple,
          subitems: [
            {
              title: "Criar Nova Dieta",
              icon: mdiCalculatorVariantOutline,
              route: "PlanoAlimentarCalcularNovo",
            },
            {
              title: "Dietas Salvas",
              icon: mdiTableEdit,
              route: "DietasSalvas",
            },
            {
              title: "Banco de dados de Alimentos",
              icon: mdiDatabaseCog,
              route: "AdminAlimentos",
            },
            // {
            //   title: "Modelos de Dietas",
            //   icon: mdiTableCheck,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Anexar Dietas e Orientações",
            //   icon: mdiAttachment,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Orientações Gerais",
            //   icon: mdiTextBoxOutline,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Lista de Compras",
            //   icon: mdiFormatListBulleted,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Tabela de Substituição",
            //   icon: mdiCached,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
          ],
        },
        {
          title: "Receitas",
          icon: mdiFoodAppleOutline,
          subitems: [
            {
              title: "Criar Receita Nova",
              icon: mdiPlaylistPlus,
              route: "CriarReceitaNova",
            },
            {
              title: "Lista de Receitas",
              icon: mdiPlaylistEdit,
              route: "ReceitasTodas",
            },
            {
              title: "Pesquisar Receitas",
              icon: mdiTextSearch,
              route: "ReceitasSalvas",
            },
          ],
        },
        {
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          subitems: [
            {
              title: "Prescrição de Fórmulas",
              icon: mdiDraw,
              route: "FormulaPrescrever",
            },
            {
              title: "Armazenar Fórmula",
              icon: mdiTextBoxPlusOutline,
              route: "FormulaCriar",
            },
            {
              title: "Lista de Formulas",
              icon: mdiTextBoxCheckOutline,
              route: "FormulasTodas",
            },
            {
              title: "Pesquisar Formulas",
              icon: mdiTextBoxSearchOutline,
              route: "FormulasSalvas",
            },
          ],
        },
        {
          title: "Exames Laboratoriais",
          icon: mdiNeedle,
          subitems: [
            {
              title: "Prescrever Exame",
              icon: mdiNeedle,
              route: "Exames",
            },
          ],
        },
        {
          title: "Orientações",
          icon: mdiClipboardAlertOutline ,
          subitems: [
            {
              title: "Nova orientação nutricional",
              icon: mdiClipboardAlertOutline,
              route: "Orientacoes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Nova avaliação Gratuita",
              icon: mdiBookPlusOutline,
              route: "AvaliacaoGratuitaNova",
            },
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Vídeo-Cursos/Ebooks",
          icon: mdiTelevisionPlay,
          subitems: [
            {
              title: "Liberar Acesso aos Cursos",
              icon: mdiTelevisionGuide,
              route: "LiberarVideoAulas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
          ],
        },
        {
          title: "Contratos",
          icon: mdiFileDocumentEditOutline,
          subitems: [
            {
              title: "Contrato de Prestação de Serviços (atendimento padrão)",
              icon: mdiFileDocumentEditOutline,
              route: "Contrato",
            },
            {
              title: "Contrato de Prestação de Serviços (apenas estética)",
              icon: mdiFileDocumentEditOutline,
              route: "ContratoEstet",
            },
          ],
        },
        {
          title: "Clube de Vantagens",
          icon: mdiHandshakeOutline, //shake hands
          subitems: [
            {
              title: "Clube de Vantagens",
              icon: mdiHandshakeOutline, //shake hands
              route: "ClubeDeVantagens",
            },
          ],
        },
        {
          title: "Treinamentos",
          icon: mdiBookshelf,
          subitems: [
            {
              title: "Treinamentos Software",
              icon: mdiBookCogOutline,
              route: "TreinamentosSoftware",
            },
            // {
            //   title: "Treinamentos Clínica",
            //   icon: mdiBookEducationOutline,
            //   route: "TreinamentosClube",
            // },
          ],
        },
      ],
      itemsEstagiaria: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Plano Alimentar",
          icon: mdiFoodApple,
          subitems: [
            {
              title: "Criar Nova Dieta",
              icon: mdiCalculatorVariantOutline,
              route: "PlanoAlimentarCalcularNovo",
            },
            {
              title: "Dietas Salvas",
              icon: mdiTableEdit,
              route: "DietasSalvas",
            },
            {
              title: "Banco de dados de Alimentos",
              icon: mdiDatabaseCog,
              route: "AdminAlimentos",
            },
            // {
            //   title: "Modelos de Dietas",
            //   icon: mdiTableCheck,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Anexar Dietas e Orientações",
            //   icon: mdiAttachment,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Orientações Gerais",
            //   icon: mdiTextBoxOutline,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Lista de Compras",
            //   icon: mdiFormatListBulleted,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Tabela de Substituição",
            //   icon: mdiCached,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
          ],
        },
        {
          title: "Receitas",
          icon: mdiFoodAppleOutline,
          subitems: [
            {
              title: "Criar Receita Nova",
              icon: mdiPlaylistPlus,
              route: "CriarReceitaNova",
            },
            {
              title: "Lista de Receitas",
              icon: mdiPlaylistEdit,
              route: "ReceitasTodas",
            },
            {
              title: "Pesquisar Receitas",
              icon: mdiTextSearch,
              route: "ReceitasSalvas",
            },
          ],
        },
        {
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          subitems: [
            {
              title: "Prescrição de Fórmulas",
              icon: mdiDraw,
              route: "FormulaPrescrever",
            },
            {
              title: "Armazenar Fórmula",
              icon: mdiTextBoxPlusOutline,
              route: "FormulaCriar",
            },
            {
              title: "Lista de Formulas",
              icon: mdiTextBoxCheckOutline,
              route: "FormulasTodas",
            },
            {
              title: "Pesquisar Formulas",
              icon: mdiTextBoxSearchOutline,
              route: "FormulasSalvas",
            },
          ],
        },
        {
          title: "Exames Laboratoriais",
          icon: mdiNeedle,
          subitems: [
            {
              title: "Prescrever Exame",
              icon: mdiNeedle,
              route: "Exames",
            },
          ],
        },
        {
          title: "Orientações",
          icon: mdiClipboardAlertOutline ,
          subitems: [
            {
              title: "Nova orientação nutricional",
              icon: mdiClipboardAlertOutline,
              route: "Orientacoes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Vídeo-Cursos/Ebooks",
          icon: mdiTelevisionPlay,
          subitems: [
            {
              title: "Liberar Acesso aos Cursos",
              icon: mdiTelevisionGuide,
              route: "LiberarVideoAulas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
          ],
        },
        {
          title: "Contratos",
          icon: mdiFileDocumentEditOutline,
          subitems: [
            {
              title: "Contrato de Prestação de Serviços (atendimento padrão)",
              icon: mdiFileDocumentEditOutline,
              route: "Contrato",
            },
            {
              title: "Contrato de Prestação de Serviços (apenas estética)",
              icon: mdiFileDocumentEditOutline,
              route: "ContratoEstet",
            },
          ],
        },
        {
          title: "Parceiros",
          icon: mdiAccountNetwork,
          subitems: [
            {
              title: "Gerir Parceiros",
              icon: mdiAccountNetwork,
              route: "Parceiros",
            },
          ],
        },
        {
          title: "Clube de Vantagens",
          icon: mdiHandshakeOutline, //shake hands
          subitems: [
            {
              title: "Clube de Vantagens",
              icon: mdiHandshakeOutline, //shake hands
              route: "ClubeDeVantagens",
            },
          ],
        },
        {
          title: "Treinamentos",
          icon: mdiBookshelf,
          subitems: [
            {
              title: "Treinamentos Software",
              icon: mdiBookCogOutline,
              route: "TreinamentosSoftware",
            },
            // {
            //   title: "Treinamentos Clínica",
            //   icon: mdiBookEducationOutline,
            //   route: "TreinamentosClube",
            // },
          ],
        },
      ],
      itemsRecepcionista: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Vídeo-Cursos/Ebooks",
          icon: mdiTelevisionPlay,
          subitems: [
            {
              title: "Liberar Acesso aos Cursos",
              icon: mdiTelevisionGuide,
              route: "LiberarVideoAulas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
          ],
        },
        {
          title: "Contratos",
          icon: mdiFileDocumentEditOutline,
          subitems: [
            {
              title: "Contrato de Prestação de Serviços (atendimento padrão)",
              icon: mdiFileDocumentEditOutline,
              route: "Contrato",
            },
            {
              title: "Contrato de Prestação de Serviços (apenas estética)",
              icon: mdiFileDocumentEditOutline,
              route: "ContratoEstet",
            },
          ],
        },
        {
          title: "Parceiros",
          icon: mdiAccountNetwork,
          subitems: [
            {
              title: "Gerir Parceiros",
              icon: mdiAccountNetwork,
              route: "Parceiros",
            },
          ],
        },
        {
          title: "Clube de Vantagens",
          icon: mdiHandshakeOutline, //shake hands
          subitems: [
            {
              title: "Clube de Vantagens",
              icon: mdiHandshakeOutline, //shake hands
              route: "ClubeDeVantagens",
            },
          ],
        },
        {
          title: "Treinamentos",
          icon: mdiBookshelf,
          subitems: [
            {
              title: "Treinamentos Software",
              icon: mdiBookCogOutline,
              route: "TreinamentosSoftware",
            },
            // {
            //   title: "Treinamentos Clínica",
            //   icon: mdiBookEducationOutline,
            //   route: "TreinamentosClube",
            // },
          ],
        },
      ],
      itemsEsteticista: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Contratos",
          icon: mdiFileDocumentEditOutline,
          subitems: [
            {
              title: "Contrato de Prestação de Serviços (atendimento padrão)",
              icon: mdiFileDocumentEditOutline,
              route: "Contrato",
            },
            {
              title: "Contrato de Prestação de Serviços (apenas estética)",
              icon: mdiFileDocumentEditOutline,
              route: "ContratoEstet",
            },
          ],
        },
        {
          title: "Clube de Vantagens",
          icon: mdiHandshakeOutline, //shake hands
          subitems: [
            {
              title: "Clube de Vantagens",
              icon: mdiHandshakeOutline, //shake hands
              route: "ClubeDeVantagens",
            },
          ],
        },
        {
          title: "Treinamentos",
          icon: mdiBookshelf,
          subitems: [
            {
              title: "Treinamentos Software",
              icon: mdiBookCogOutline,
              route: "TreinamentosSoftware",
            },
            // {
            //   title: "Treinamentos Clínica",
            //   icon: mdiBookEducationOutline,
            //   route: "TreinamentosClube",
            // },
          ],
        },
      ],
      itemsAdmin_nF: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Plano Alimentar",
          icon: mdiFoodApple,
          subitems: [
            {
              title: "Criar Nova Dieta",
              icon: mdiCalculatorVariantOutline,
              route: "PlanoAlimentarCalcularNovo",
            },
            {
              title: "Dietas Salvas",
              icon: mdiTableEdit,
              route: "DietasSalvas",
            },
            // {
            //   title: "Admin",
            //   icon: mdiDatabaseCog,
            //   route: "AdminAlimentos",
            // },
            // {
            //   title: "Modelos de Dietas",
            //   icon: mdiTableCheck,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Anexar Dietas e Orientações",
            //   icon: mdiAttachment,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Orientações Gerais",
            //   icon: mdiTextBoxOutline,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Lista de Compras",
            //   icon: mdiFormatListBulleted,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Tabela de Substituição",
            //   icon: mdiCached,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
          ],
        },
        {
          title: "Receitas",
          icon: mdiFoodAppleOutline,
          subitems: [
            {
              title: "Criar Receita Nova",
              icon: mdiPlaylistPlus,
              route: "CriarReceitaNova",
            },
            {
              title: "Lista de Receitas",
              icon: mdiPlaylistEdit,
              route: "ReceitasTodas",
            },
            {
              title: "Pesquisar Receitas",
              icon: mdiTextSearch,
              route: "ReceitasSalvas",
            },
          ],
        },
        {
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          subitems: [
            {
              title: "Prescrição de Fórmulas",
              icon: mdiDraw,
              route: "FormulaPrescrever",
            },
            {
              title: "Armazenar Fórmula",
              icon: mdiTextBoxPlusOutline,
              route: "FormulaCriar",
            },
            {
              title: "Lista de Formulas",
              icon: mdiTextBoxCheckOutline,
              route: "FormulasTodas",
            },
            {
              title: "Pesquisar Formulas",
              icon: mdiTextBoxSearchOutline,
              route: "FormulasSalvas",
            },
          ],
        },
        {
          title: "Exames Laboratoriais",
          icon: mdiNeedle,
          subitems: [
            {
              title: "Prescrever Exame",
              icon: mdiNeedle,
              route: "Exames",
            },
          ],
        },
        {
          title: "Orientações",
          icon: mdiClipboardAlertOutline ,
          subitems: [
            {
              title: "Nova orientação nutricional",
              icon: mdiClipboardAlertOutline,
              route: "Orientacoes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Nova avaliação Gratuita",
              icon: mdiBookPlusOutline,
              route: "AvaliacaoGratuitaNova",
            },
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
            {
              title: "Livro Caixa",
              icon: mdiBookOpenVariant,
              route: "FinanceiroLivro",
            },
            {
              title: "Fundo Publicidade",
              icon: mdiBullhornOutline,
              route: "FundoPublicidade",
            },
          ],
        },
        // {
        //   title: "Ajuda",
        //   icon: mdiHelpCircleOutline,
        //   subitems: [
        //     {
        //       title: "Manual",
        //       icon: mdiBookOpenVariant,
        //       route: "PlanoAlimentarCalcularNovo",
        //     },
        //     {
        //       title: "Suporte e Dúvidas",
        //       icon: mdiHelpCircleOutline,
        //       route: "PlanoAlimentarCalcularNovo",
        //     },
        //   ],
        // },
      ],
      itemsNutricionista1_nF: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Plano Alimentar",
          icon: mdiFoodApple,
          subitems: [
            {
              title: "Criar Nova Dieta",
              icon: mdiCalculatorVariantOutline,
              route: "PlanoAlimentarCalcularNovo",
            },
            {
              title: "Dietas Salvas",
              icon: mdiTableEdit,
              route: "DietasSalvas",
            },
          ],
        },
        {
          title: "Receitas",
          icon: mdiFoodAppleOutline,
          subitems: [
            {
              title: "Criar Receita Nova",
              icon: mdiPlaylistPlus,
              route: "CriarReceitaNova",
            },
            {
              title: "Lista de Receitas",
              icon: mdiPlaylistEdit,
              route: "ReceitasTodas",
            },
            {
              title: "Pesquisar Receitas",
              icon: mdiTextSearch,
              route: "ReceitasSalvas",
            },
          ],
        },
        {
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          subitems: [
            {
              title: "Prescrição de Fórmulas",
              icon: mdiDraw,
              route: "FormulaPrescrever",
            },
            {
              title: "Armazenar Fórmula",
              icon: mdiTextBoxPlusOutline,
              route: "FormulaCriar",
            },
            {
              title: "Lista de Formulas",
              icon: mdiTextBoxCheckOutline,
              route: "FormulasTodas",
            },
            {
              title: "Pesquisar Formulas",
              icon: mdiTextBoxSearchOutline,
              route: "FormulasSalvas",
            },
          ],
        },
        {
          title: "Exames Laboratoriais",
          icon: mdiNeedle,
          subitems: [
            {
              title: "Prescrever Exame",
              icon: mdiNeedle,
              route: "Exames",
            },
          ],
        },
        {
          title: "Orientações",
          icon: mdiClipboardAlertOutline ,
          subitems: [
            {
              title: "Nova orientação nutricional",
              icon: mdiClipboardAlertOutline,
              route: "Orientacoes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Nova avaliação Gratuita",
              icon: mdiBookPlusOutline,
              route: "AvaliacaoGratuitaNova",
            },
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
            {
              title: "Livro Caixa",
              icon: mdiBookOpenVariant,
              route: "FinanceiroLivro",
            },
            {
              title: "Fundo Publicidade",
              icon: mdiBullhornOutline,
              route: "FundoPublicidade",
            },
          ],
        },
      ],
      itemsNutricionista2_nF: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Plano Alimentar",
          icon: mdiFoodApple,
          subitems: [
            {
              title: "Criar Nova Dieta",
              icon: mdiCalculatorVariantOutline,
              route: "PlanoAlimentarCalcularNovo",
            },
            {
              title: "Dietas Salvas",
              icon: mdiTableEdit,
              route: "DietasSalvas",
            },
            // {
            //   title: "Banco de dados de Alimentos",
            //   icon: mdiDatabaseCog,
            //   route: "AdminAlimentos",
            // },
            // {
            //   title: "Modelos de Dietas",
            //   icon: mdiTableCheck,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Anexar Dietas e Orientações",
            //   icon: mdiAttachment,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Orientações Gerais",
            //   icon: mdiTextBoxOutline,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Lista de Compras",
            //   icon: mdiFormatListBulleted,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Tabela de Substituição",
            //   icon: mdiCached,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
          ],
        },
        {
          title: "Receitas",
          icon: mdiFoodAppleOutline,
          subitems: [
            {
              title: "Criar Receita Nova",
              icon: mdiPlaylistPlus,
              route: "CriarReceitaNova",
            },
            {
              title: "Lista de Receitas",
              icon: mdiPlaylistEdit,
              route: "ReceitasTodas",
            },
            {
              title: "Pesquisar Receitas",
              icon: mdiTextSearch,
              route: "ReceitasSalvas",
            },
          ],
        },
        {
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          subitems: [
            {
              title: "Prescrição de Fórmulas",
              icon: mdiDraw,
              route: "FormulaPrescrever",
            },
            {
              title: "Armazenar Fórmula",
              icon: mdiTextBoxPlusOutline,
              route: "FormulaCriar",
            },
            {
              title: "Lista de Formulas",
              icon: mdiTextBoxCheckOutline,
              route: "FormulasTodas",
            },
            {
              title: "Pesquisar Formulas",
              icon: mdiTextBoxSearchOutline,
              route: "FormulasSalvas",
            },
          ],
        },
        {
          title: "Exames Laboratoriais",
          icon: mdiNeedle,
          subitems: [
            {
              title: "Prescrever Exame",
              icon: mdiNeedle,
              route: "Exames",
            },
          ],
        },
        {
          title: "Orientações",
          icon: mdiClipboardAlertOutline ,
          subitems: [
            {
              title: "Nova orientação nutricional",
              icon: mdiClipboardAlertOutline,
              route: "Orientacoes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Nova avaliação Gratuita",
              icon: mdiBookPlusOutline,
              route: "AvaliacaoGratuitaNova",
            },
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
          ],
        },
      ],
      itemsEstagiaria_nF: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Plano Alimentar",
          icon: mdiFoodApple,
          subitems: [
            {
              title: "Criar Nova Dieta",
              icon: mdiCalculatorVariantOutline,
              route: "PlanoAlimentarCalcularNovo",
            },
            {
              title: "Dietas Salvas",
              icon: mdiTableEdit,
              route: "DietasSalvas",
            },
            // {
            //   title: "Banco de dados de Alimentos",
            //   icon: mdiDatabaseCog,
            //   route: "AdminAlimentos",
            // },
            // {
            //   title: "Modelos de Dietas",
            //   icon: mdiTableCheck,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Anexar Dietas e Orientações",
            //   icon: mdiAttachment,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Orientações Gerais",
            //   icon: mdiTextBoxOutline,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Lista de Compras",
            //   icon: mdiFormatListBulleted,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
            // {
            //   title: "Tabela de Substituição",
            //   icon: mdiCached,
            //   route: "PlanoAlimentarCalcularNovo",
            // },
          ],
        },
        {
          title: "Receitas",
          icon: mdiFoodAppleOutline,
          subitems: [
            {
              title: "Criar Receita Nova",
              icon: mdiPlaylistPlus,
              route: "CriarReceitaNova",
            },
            {
              title: "Lista de Receitas",
              icon: mdiPlaylistEdit,
              route: "ReceitasTodas",
            },
            {
              title: "Pesquisar Receitas",
              icon: mdiTextSearch,
              route: "ReceitasSalvas",
            },
          ],
        },
        {
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          subitems: [
            {
              title: "Prescrição de Fórmulas",
              icon: mdiDraw,
              route: "FormulaPrescrever",
            },
            {
              title: "Armazenar Fórmula",
              icon: mdiTextBoxPlusOutline,
              route: "FormulaCriar",
            },
            {
              title: "Lista de Formulas",
              icon: mdiTextBoxCheckOutline,
              route: "FormulasTodas",
            },
            {
              title: "Pesquisar Formulas",
              icon: mdiTextBoxSearchOutline,
              route: "FormulasSalvas",
            },
          ],
        },
        {
          title: "Exames Laboratoriais",
          icon: mdiNeedle,
          subitems: [
            {
              title: "Prescrever Exame",
              icon: mdiNeedle,
              route: "Exames",
            },
          ],
        },
        {
          title: "Orientações",
          icon: mdiClipboardAlertOutline ,
          subitems: [
            {
              title: "Nova orientação nutricional",
              icon: mdiClipboardAlertOutline,
              route: "Orientacoes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
          ],
        },
      ],
      itemsRecepcionista_nF: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
        {
          title: "Questionários",
          icon: mdiHelpNetwork,
          subitems: [
            {
              title: "Questionários",
              icon: mdiFileQuestion,
              route: "QuestionarioNovo",
            },
          ],
        },
        {
          title: "Fale com a Nutri",
          icon: mdiChat,
          subitems: [
            {
              title: "Chats com Pacientes",
              icon: mdiChatProcessing,
              route: "Chat",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Venda Nova / Gasto Novo",
              icon: mdiCash100,
              route: "FinanceiroVendaGasto",
            },
            {
              title: "Orçamento",
              icon: mdiCartPlus,
              route: "FinanceiroOrcamento",
            },
          ],
        },
      ],
      itemsEsteticista_nF: [
        {
          title: "Pendências",
          icon: mdiCalendarCheck,
          subitems: [
            {
              title: "Tarefas do dia",
              icon: mdiCalendarCheck,
              route: "Lembretes",
            },
          ],
        },
        {
          title: "Avaliação Gratuita",
          icon: mdiBookAccountOutline,
          subitems: [
            {
              title: "Avaliações Gratuitas salvas",
              icon: mdiBookSearchOutline,
              route: "AvaliacaoGratuitaSalvas",
            },
          ],
        },
      ],
      icons: {
        mdiAccountDetailsOutline,
        mdiClose,
        mdiChatAlert,
        mdiFileDocumentEditOutline,
      },
      color: "primary",
      colors: ["primary", "blue", "success", "red", "teal"],
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: false,
      background: false,
    };
  },
  computed: {
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    // anyUnansweredN() {
    //   return this.$store.state.firestore.anyUnansweredN;
    // },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
  },
  methods: {
    async getUnansweredNs() {
      // this.$store.dispatch("firestore/getAnyUnansweredN");
      var pacienteUnansweredList = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        // .collection("pacientes")
        .get()
        .then(function (doc) {
          return doc.data().unansweredArray;
        });

      var countList = [];

      if (pacienteUnansweredList !== undefined && pacienteUnansweredList.length > 0) {
        countList = pacienteUnansweredList.map(function (e) {
          if (e !== undefined && e) {
            return 1;
          } else {
            return 0;
          }
        });
      }

      var sum = 0;
      countList.forEach(function (item) {
        sum += item;
      });

      var bool;
      if (sum > 0) {
        bool = true;
      } else {
        bool = false;
      }

      this.anyUnansweredN = bool;
      if (bool) {
        this.alertNovaMensagem = true;
      } else {
        this.alertNovaMensagem = false;
      }
    },
    chatUnansweredP(title) {
      if (title == "Fale com a Nutri" || title == "Chats com Pacientes") {
        return this.anyUnansweredN;
      } else {
        return false;
      }
    },
  },
  firestore() {
    return {
      userInfos: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user")),
      // .doc(firebase.auth().currentUser.email),
    };
  },
  mounted() {
    // this.getUnansweredNs();
    this.getUnansweredNs();
    setInterval(() => {
      this.getUnansweredNs();
    }, 60000);
  },
};
</script>