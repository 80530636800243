<template>
  <div>
    <v-stepper non-linear>
      <v-stepper-header>
        <v-stepper-step editable step="1">
          Peso e Composição corporal
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable step="2"> Antropometría </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable step="3"> Outros </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" style="min-width: 600px">
          <div class="small">
            <line-chart :chart-data="datacollectionCompos"></line-chart>
          </div>
          <v-row class="d-flex justify-center mx-4">
            <div v-for="element in checkCompos" :key="element.nome">
              <v-checkbox
                class="pr-4"
                v-model="element.show"
                :label="element.label"
                :color="element.color"
                @click="fillDataCompos"
              ></v-checkbox>
            </div>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2" style="min-width: 600px">
          <div class="small">
            <line-chart :chart-data="datacollectionMedidas"></line-chart>
          </div>
          <v-row class="d-flex justify-center mx-4">
            <div v-for="element in checkMedidas" :key="element.nome">
              <v-checkbox
                class="pr-4"
                v-model="element.show"
                :label="element.label"
                :color="element.color"
                @click="fillDataMedidas"
              ></v-checkbox>
            </div>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="3" style="min-width: 600px">
          <div class="small">
            <line-chart :chart-data="datacollectionSangue"></line-chart>
          </div>
          <v-row class="d-flex justify-center mx-4">
            <div v-for="element in checkSangue" :key="element.nome">
              <v-checkbox
                class="pr-4"
                v-model="element.show"
                :label="element.label"
                :color="element.color"
                @click="fillDataMedidas"
              ></v-checkbox>
            </div>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
// import firebase from "firebase";
import LineChart from "./LineChart.js";

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      datacollectionCompos: null,
      datacollectionPeso: null,
      datacollectionMedidas: null,
      datacollectionSangue: null,
      checkCompos: [
        {
          show: true,
          nome: "pesoIdeal",
          color: "#ff7f00",
          label: "peso ideal [kg]",
        },
        {
          show: true,
          nome: "pesoAtual",
          color: "#000000",
          label: "peso atual [kg]",
        },
        {
          show: true,
          nome: "percGorduraAbs",
          color: "#ff0000",
          label: "gordura [kg]",
        },
        {
          show: true,
          nome: "massaMagraAbs",
          color: "#008000",
          label: "massa magra [kg]",
        },
        { show: true, nome: "aguaAbs", color: "#0000ff", label: "água [kg]" },
      ],
      checkMedidas: [
        {
          show: true,
          nome: "abdomen",
          color: "#ff0000",
          label: "abdomen [cm]",
        },
        {
          show: false,
          nome: "bracoContraido",
          color: "#810f7c",
          label: "braço contraído [cm]",
        },
        {
          show: false,
          nome: "bracoRelaxado",
          color: "#8856a7",
          label: "braço relaxado [cm]",
        },
        {
          show: true,
          nome: "cintura",
          color: "#ff7f00",
          label: "cintura [cm]",
        },
        { show: false, nome: "coxa", color: "#fb9a99", label: "coxa [cm]" },
        {
          show: false,
          nome: "panturrilha",
          color: "#33a02c",
          label: "panturrilha [cm]",
        },
        { show: true, nome: "perna", color: "#1f78b4", label: "perna [cm]" },
        {
          show: false,
          nome: "quadril",
          color: "#a6cee3",
          label: "quadril [cm]",
        },
        { show: false, nome: "torax", color: "#cab2d6", label: "torax [cm]" },
      ],
      //   atendimentos: [],
    };
  },
  computed: {
    atendimentos() {
      return this.$store.state.firestore.pacienteAtendimentosList;
    },
    labels() {
      var labelList = this.atendimentos.map(function (atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });
      return labelList;
    },
    checkSangue() {
      var concatList = [];
      for (var i = 0; i < this.atendimentos.length; i++) {
        concatList = concatList.concat(this.atendimentos[i]);
      }
      function arrayUnique(array) {
        var a = array.concat();
        for (var i = 0; i < a.length; ++i) {
          for (var j = i + 1; j < a.length; ++j) {
            if (a[i].nome === a[j].nome) a.splice(j--, 1);
          }
        }
        return a;
      }
      var sangueList = arrayUnique(concatList);
      console.log(sangueList);

      function getRandomColor() {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      var sangueObjsList = sangueList.map(function (obj) {
        return {
          show: false,
          nome: obj.index,
          color: getRandomColor(),
          label: obj.nome,
        };
      });

      return sangueObjsList;
    },
  },
  //   firestore() {
  //     return {
  //       atendimentos: firebase
  //         .firestore()
  //         .collection("users")
  //         .doc(sessionStorage.getItem("current-user"))
  //         .collection("atendimentos"),
  //     };
  //   },
  mounted() {
    // if (this.atendimentos.length < 1) {
    this.$store.dispatch("firestore/pacienteAtendimentosList");
    // }

    setTimeout(() => {
      this.fillDataCompos();
      this.fillDataMedidas();
      this.fillDataSangue();
    }, 2000);
  },
  methods: {
    fillDataMedidas() {
      function validMedidas(element) {
        return element.medidas.abdomen > 0;
      }
      var atendimentos = this.atendimentos.filter(validMedidas);

      var labelsMedidas = atendimentos.map(function (atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });

      function checkShow(element) {
        return element.show;
      }

      this.datacollectionMedidas = {
        labels: labelsMedidas,
        datasets: this.checkMedidas.filter(checkShow).map(function (element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function (atendimento) {
              return atendimento.medidas[element.nome];
            }),
          };
        }),
      };
    },
    fillDataCompos() {
      function validCompos(element) {
        return element.compos.agua > 0;
      }
      var atendimentos = this.atendimentos.filter(validCompos);

      var labelsCompos = atendimentos.map(function (atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });

      function checkShow(element) {
        return element.show;
      }

      this.datacollectionCompos = {
        labels: labelsCompos,
        datasets: this.checkCompos.filter(checkShow).map(function (element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function (atendimento) {
              return atendimento.compos[element.nome];
            }),
          };
        }),
      };
    },
    fillDataSangue() {
      function validSangue(element) {
        if (element.sangue[0].value) {
          return true;
        } else {
          return false;
        }
      }
      var atendimentos = this.atendimentos.filter(validSangue);

      var labelsSangue = atendimentos.map(function (atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });

      function checkShow(element) {
        return element.show;
      }

      this.datacollectionSangue = {
        labels: labelsSangue,
        datasets: this.checkSangue.filter(checkShow).map(function (element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function (atendimento) {
              if (atendimento.sangue[element.nome].value) {
                return parseInt(atendimento.sangue[element.nome].value);
              } else {
                return null;
              }
            }),
          };
        }),
      };
    },
  },
};
</script>

<style>
.small {
  max-width: 700px;
  margin: 10px 150px 10px 150px;
}
</style>