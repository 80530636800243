<template>
  <div>
    <v-dialog v-model="dialogDshow" max-width="750">
      <v-card>
        <v-row class="d-flex align-center mx-4">
          <v-col cols="8">
            <v-row>{{ dialogDelement.tipo }}</v-row
            ><v-row>
              <small>
                {{ dialogDelement.descr }}
              </small>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-row>
              <v-chip
                :color="colorDirection(dialogDelement.direction)"
                class="white--text"
              >
                {{ niceString(dialogDelement.valor) }}
              </v-chip>
              <v-icon
                v-if="dialogDelement.descontoAct"
                color="orange"
                class="mx-1"
              >
                mdi-sale
              </v-icon>
            </v-row>
            <v-row class="text-caption mt-1">
              <v-icon color="black" left small>{{
                formaIcon(dialogDelement.forma)
              }}</v-icon>
              {{ formaStr(dialogDelement.forma) }}
            </v-row>
            <v-row>
              <small> {{ dialogDelement.comissao }} </small>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom v-if="dialogDelement.comprovante">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  @click="openComprovanteDialog(dialogDelement.comprovante)"
                >
                  <v-icon color="#614021">mdi-note-text-outline</v-icon>
                </v-btn>
              </template>
              <span>Comprovante de pagamento</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="dialogDelement.pacienteName != ''" class="mx-4">
          {{ dialogDelement.pacienteName }}
        </v-row>
        <v-row v-if="dialogDelement.forma == 7" class="mx-4">
          <v-data-table
            :headers="formaHeaders"
            :items="[dialogDelement.formas]"
            class="elevation-1"
            hide-default-footer
          ></v-data-table>
        </v-row>
        <v-divider v-if="dialogDelement.forma == 7"></v-divider>
        <v-row
          v-if="
            dialogDelement.pacotes[0] !== undefined &&
              dialogDelement.pacotes[0].dataInicio !== undefined &&
              dialogDelement.pacotes[0].dataInicio !== null
          "
          class="mx-4"
        >
          <v-data-table
            :headers="pacoteHeaders"
            :items="dialogDelement.pacotes"
            class="elevation-1"
            hide-default-footer
          ></v-data-table>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogComissoes" max-width="750">
      <v-card>
        <v-card outlined class="mx-4" v-if="funcInclProprietario">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Propretário </v-col>
            <v-col cols="4">
              {{ niceString(comissaoPropretario) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclNutricionista1">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Nutricionista 1</v-col>
            <v-col cols="4">
              {{ niceString(comissaoNutricionista1) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclNutricionista2">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Nutricionista 2</v-col>
            <v-col cols="4">
              {{ niceString(comissaoNutricionista2) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclNutricionista3">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Nutricionista 3</v-col>
            <v-col cols="4">
              {{ niceString(comissaoNutricionista3) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclEsteticista">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Esteticista </v-col>
            <v-col cols="4">
              {{ niceString(comissaoEsteticista) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclRecepcionista">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Recepcionista </v-col>
            <v-col cols="4">
              {{ niceString(comissaoRecepcionista) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8">
              <strong>Total de comissões a pagar</strong>
            </v-col>
            <v-col cols="4">
              <strong>{{ niceString(comissaoFranquia) }}</strong>
            </v-col>
          </v-row>
        </v-card>
        <v-divider class="my-4"></v-divider>
        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Taxa do Software </v-col>
            <v-col cols="4">
              {{ niceString(taxaSoftware) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Taxa de Publicidade </v-col>
            <v-col cols="4">
              {{ niceString(taxaPublicidade) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Royalts </v-col>
            <v-col cols="4">
              {{ niceString(taxaRoyalts) }}
            </v-col>
          </v-row>
        </v-card>

        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8"> <strong>Total de taxas a pagar</strong> </v-col>
            <v-col cols="4">
              <strong>{{ niceString(taxaSoma) }}</strong>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMshow" max-width="750">
      <v-card>
        <v-row class="d-flex align-center mx-4">
          <v-col cols="8">
            <v-row>{{ dialogMelement.dia }}</v-row>
          </v-col>
          <v-col cols="4">
            <v-chip
              :color="colorDirection(dialogMelement.somaNr > 0)"
              class="white--text"
            >
              {{ niceString(dialogMelement.somaNr) }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-data-table
            :headers="dayHeaders"
            :items="dialogMelement.elements"
            class="elevation-1"
          ></v-data-table>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTshow" max-width="750">
      <v-card>
        <v-row class="d-flex align-center mx-4">
          <v-col cols="8">
            <v-row>{{ dialogTelement.ano }}</v-row>
          </v-col>
          <v-col cols="4">
            <v-chip
              :color="colorDirection(dialogTelement.somaNr > 0)"
              class="white--text"
            >
              {{ niceString(dialogTelement.somaNr) }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-data-table
            :headers="monthHeaders"
            :items="dialogTelement.monthList"
            class="elevation-1"
          ></v-data-table>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="livroCaixaDialog">
      <v-card class="mr-2">
        <v-row class="d-flex justify-center mx-2">
          <v-card color="grey lighten-3" width="95%" class="mb-3">
            <v-row class="d-flex align-center text-h6">
              <v-col cols="5">
                <v-row>
                  <v-select
                    v-model="vision"
                    :items="periods"
                    label="Período"
                    class="mx-4"
                    @change="updateFaturamento"
                  ></v-select>
                </v-row>
                <v-row v-if="vision === 'Dia'" class="text-subtitle-2">
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="visionD"
                      :items="periodsD"
                      label="Dia"
                      class="mx-2"
                      @change="updateFaturamento"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      v-model="visionM"
                      :items="periodsM"
                      label="Mês"
                      class="mx-2"
                      @change="updateFaturamento"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      v-model="visionA"
                      :items="periodsA"
                      label="Ano"
                      class="mx-2"
                      @change="updateFaturamento"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-else-if="vision === 'Mês'">
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="visionM"
                      :items="periodsM"
                      label="Mês"
                      class="mx-2"
                      @change="updateFaturamento"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      v-model="visionA"
                      :items="periodsA"
                      label="Ano"
                      class="mx-2"
                      @change="updateFaturamento"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-else-if="vision === 'Total'">
                  <!-- <v-col cols="12" md="6">
                <v-select
                  v-model="visionM"
                  :items="periodsM"
                  label="Mês"
                  class="mx-2"
                  @change="getFaturamento"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="visionA"
                  :items="periodsA"
                  label="Ano"
                  class="mx-2"
                  @change="getFaturamento"
                ></v-select>
              </v-col> -->
                </v-row>
              </v-col>
              <v-col cols="4" class="d-flex justify-center">
                {{ titulo }}
              </v-col>
              <v-col cols="3">
                <v-row class="text-subtitle-2 green--text my-1"
                  ><v-icon color="green" small class="mr-1"
                    >mdi-plus-circle-outline</v-icon
                  >
                  {{ entrouSoma }}</v-row
                ><v-row class="text-subtitle-2 red--text my-1"
                  ><v-icon color="red" small class="mr-1"
                    >mdi-minus-circle-outline</v-icon
                  >
                  {{ gastosSoma }}</v-row
                >
                <v-row>{{ faturamento }}</v-row>
                <v-row v-if="vision === 'Dia'" class="text-subtitle-2"
                  >Dinheiro: {{ faturamentoDdin }}</v-row
                >
                <v-row
                  v-else-if="vision === 'Mês'"
                  class="d-flex justify-start text-subtitle-2 my-1"
                >
                  <v-btn dense small @click="getComissoes">
                    Comissões e Taxas
                  </v-btn>
                </v-row>
                <v-row
                  v-else-if="vision === 'Total'"
                  class="d-flex justify-start text-heading-6 my-4"
                >
                  Payback: {{ payback }}%
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row class="d-flex justify-center mx-2">
          <v-stepper non-linear>
            <v-stepper-header>
              <v-stepper-step editable step="1"> Livro Caixa </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step editable step="2">
                Estatísticas
              </v-stepper-step></v-stepper-header
            >
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row
                  v-if="vision === 'Dia'"
                  class="d-flex justify-center mx-2"
                >
                  <v-card
                    v-for="el in elementsD"
                    :key="el.id"
                    width="95%"
                    outlined
                  >
                    <v-row class="d-flex align-center mx-4">
                      <v-col cols="4">
                        <v-row>{{ el.tipo }}</v-row>
                        <v-row
                          ><small> {{ el.descr }}</small></v-row
                        >
                      </v-col>
                      <v-col cols="3">
                        {{ el.pacienteName }}
                      </v-col>
                      <v-col cols="1">
                        <v-row class="d-flex justify-center">
                          <v-icon color="black">{{
                            formaIcon(el.forma)
                          }}</v-icon>
                        </v-row>
                        <v-row class="d-flex justify-center">
                          <small> {{ el.comissao }} </small>
                        </v-row>
                      </v-col>
                      <v-col cols="2" v-if="el.direction" class="green--text">
                        + {{ niceString(el.valor) }}
                      </v-col>
                      <v-col cols="2" v-else class="red--text">
                        {{ niceString(el.valor) }}
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon @click="dialogD(el.data)">
                          <v-icon color="black">mdi-eye</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon @click="makeRecibo(el.data)">
                          <v-icon color="black"
                            >mdi-file-download-outline</v-icon
                          >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>
                <v-row
                  v-else-if="vision === 'Mês'"
                  class="d-flex justify-center mx-2"
                >
                  <v-card
                    width="95%"
                    outlined
                    style="background-color: #c0c0c0"
                  >
                    <v-row class="d-flex align-center mx-4">
                      <v-col cols="4"> Dia </v-col>
                      <v-col cols="3"> Faturamento </v-col>
                      <v-col cols="3"> Gasto </v-col>
                      <v-col cols="2"> Detalhes </v-col>
                    </v-row>
                  </v-card>
                  <v-card
                    v-for="del in daysM"
                    :key="del.dia"
                    width="95%"
                    outlined
                  >
                    <v-row class="d-flex align-center mx-4">
                      <v-col cols="4">
                        {{ del.dia }}
                      </v-col>
                      <v-col
                        cols="3"
                        v-if="del.inSomaNr > 0"
                        class="green--text"
                      >
                        + {{ niceString(del.inSomaNr) }}
                      </v-col>
                      <v-col cols="3" v-else class="black--text">
                        {{ niceString(del.inSomaNr) }}
                      </v-col>
                      <v-col
                        cols="3"
                        v-if="del.outSomaNr < 0"
                        class="red--text"
                      >
                        {{ niceString(del.outSomaNr) }}
                      </v-col>
                      <v-col cols="3" v-else class="black--text">
                        {{ niceString(del.outSomaNr) }}
                      </v-col>
                      <v-col cols="2">
                        <v-btn icon @click="dialogM(del)">
                          <v-icon color="black">mdi-eye</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>
                <v-row
                  v-else-if="vision === 'Total'"
                  class="d-flex justify-center mx-2"
                >
                  <v-card
                    v-for="ano in anosObjs"
                    :key="ano"
                    width="95%"
                    outlined
                  >
                    <v-row class="d-flex align-center mx-4">
                      <v-col cols="5">
                        {{ ano.ano }}
                      </v-col>
                      <v-col cols="5" v-if="ano.somaNr > 0" class="green--text">
                        + {{ niceString(ano.somaNr) }}
                      </v-col>
                      <v-col cols="5" v-else class="red--text">
                        {{ niceString(ano.somaNr) }}
                      </v-col>
                      <v-col cols="2">
                        <v-btn icon @click="dialogT(ano)">
                          <v-icon color="black">mdi-eye</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="2">
                <!-- sort & count vendas por tipo -->
                <v-row class="d-flex justify-center mx-2">
                  <v-card outlined width="1000">
                    <v-card-title class="d-flex justify-center">
                      Analise de vendas
                    </v-card-title>
                    <v-card-text
                      v-if="
                        loadedTiposVendidos &&
                          !zeroTiposVendidos &&
                          !loadingTiposVendidos
                      "
                    >
                      <v-card color="grey">
                        <!-- <v-row class="d-flex justify-center">
                      <v-col cols="12" class="d-flex justify-space-between"> -->
                        <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                          <v-col cols="5"> Tipo de venda: </v-col>
                          <v-col cols="3"> Quantidade vendido: </v-col>
                          <v-col cols="4"> Valor somado: </v-col>
                        </v-row>
                        <!-- </v-col>
                    </v-row> -->
                      </v-card>
                      <div
                        v-for="tipoVendidoEl in tiposVendidos"
                        :key="tipoVendidoEl.tipo"
                      >
                        <v-card outlined>
                          <!-- <v-row class="d-flex justify-center">
                        <v-col cols="12" class="d-flex justify-space-between"> -->
                          <v-row
                            class="text-subtitle-1 ml-2 d-flex align-center"
                          >
                            <v-col cols="5"> {{ tipoVendidoEl.tipo }} </v-col>
                            <v-col cols="3"> {{ tipoVendidoEl.qtd }} </v-col>
                            <v-col cols="4"> {{ tipoVendidoEl.valor }} </v-col>
                          </v-row>
                          <!-- </v-col>
                      </v-row> -->
                        </v-card>
                      </div>
                    </v-card-text>
                    <v-card-text
                      v-else-if="
                        !loadedTiposVendidos &&
                          !zeroTiposVendidos &&
                          loadingTiposVendidos
                      "
                    >
                      <v-progress-circular
                        :size="50"
                        :width="5"
                        color="#614021"
                        indeterminate
                      ></v-progress-circular>
                    </v-card-text>
                    <v-card-text
                      v-else-if="
                        loadedTiposVendidos &&
                          zeroTiposVendidos &&
                          !loadingTiposVendidos
                      "
                    >
                      <v-alert type="error">
                        Nenhuma venda no periodo escolhido!
                      </v-alert>
                    </v-card-text>
                    <v-card-text
                      v-else-if="
                        !loadedTiposVendidos &&
                          !zeroTiposVendidos &&
                          !loadingTiposVendidos
                      "
                    >
                      <v-alert type="error">
                        Escolhe um periodo para analisar!
                      </v-alert>
                    </v-card-text>
                  </v-card>
                </v-row>
                <v-row
                  class="d-flex justify-center mx-2"
                  v-if="vision == 'Mês'"
                >
                  <v-card outlined width="1000">
                    <v-card-title class="d-flex justify-center"
                      >Contribuições dos funcionários</v-card-title
                    >
                    <v-card-text v-if="contribuicoesNotCalc"> </v-card-text>
                    <v-card-text v-else>
                      <v-row
                        class="d-flex justify-center mx-1"
                        v-if="funcInclNutricionista1"
                      >
                        <v-card outlined class="my-2 mx-4" width="90%">
                          <v-row class="d-flex align-center">
                            <v-col cols="6">
                              Contribuição Nutricionista 1
                            </v-col>
                            <v-col cols="6">
                              {{ niceString(contribuicaoNutricionista1) }}
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-row>
                      <v-row
                        class="d-flex justify-center mx-1"
                        v-if="funcInclNutricionista2"
                      >
                        <v-card outlined class="my-2 mx-4" width="90%">
                          <v-row class="d-flex align-center">
                            <v-col cols="6">
                              Contribuição Nutricionista 2
                            </v-col>
                            <v-col cols="6">
                              {{ niceString(contribuicaoNutricionista2) }}
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-row>
                      <v-row
                        class="d-flex justify-center mx-1"
                        v-if="funcInclNutricionista3"
                      >
                        <v-card outlined class="my-2 mx-4" width="90%">
                          <v-row class="d-flex align-center">
                            <v-col cols="6">
                              Contribuição Nutricionista 3
                            </v-col>
                            <v-col cols="6">
                              {{ niceString(contribuicaoNutricionista3) }}
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-row>
                      <v-row
                        class="d-flex justify-center mx-1"
                        v-if="funcInclRecepcionista"
                      >
                        <v-card outlined class="my-2 mx-4" width="90%">
                          <v-row class="d-flex align-center">
                            <v-col cols="6"> Contribuição Recepcionista </v-col>
                            <v-col cols="6">
                              {{ niceString(contribuicaoRecepcionista) }}
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-row>
                      <v-row
                        class="d-flex justify-center mx-1"
                        v-if="funcInclEsteticista"
                      >
                        <v-card outlined class="my-2 mx-4" width="90%">
                          <v-row class="d-flex align-center">
                            <v-col cols="6"> Contribuição Esteticista </v-col>
                            <v-col cols="6">
                              {{ niceString(contribuicaoEsteticista) }}
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-row>
                      <v-row
                        class="d-flex justify-center mx-1"
                        v-if="funcInclEstagiaria"
                      >
                        <v-card outlined class="my-2 mx-4" width="90%">
                          <v-row class="d-flex align-center">
                            <v-col cols="6"> Contribuição Estagiária </v-col>
                            <v-col cols="6">
                              {{ niceString(contribuicaoEstagiaria) }}
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                      <v-btn
                        color="#614021"
                        class="white--text"
                        @click="calculateContribuicoes"
                        v-if="contribuicoesNotCalc"
                      >
                        <v-icon left color="#c5d13f">mdi-cash-multiple</v-icon>
                        Ver contribuições
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-row>

                <!-- por nutri: nr de atendimentos + nr de programas + faturamento do periodo -->
                <v-row class="d-flex justify-center mx-2">
                  <v-card outlined width="1000">
                    <v-card-title class="d-flex justify-center">
                      Performance de conversões das nutricionistas
                    </v-card-title>
                    <v-card-text v-if="eventsLoaded && !loadingNutriStats">
                      <v-row class="d-flex justify-center">
                        <v-card outlined v-if="funcInclNutricionista1">
                          <div class="small">
                            <pie-chart :chart-data="dataNutri1"></pie-chart>
                          </div>
                        </v-card>
                        <v-card outlined v-if="funcInclNutricionista2">
                          <div class="small">
                            <pie-chart :chart-data="dataNutri2"></pie-chart>
                          </div>
                        </v-card>
                        <v-card outlined v-if="funcInclNutricionista3">
                          <div class="small">
                            <pie-chart :chart-data="dataNutri3"></pie-chart>
                          </div>
                        </v-card>
                      </v-row>
                    </v-card-text>
                    <v-card-text
                      v-else-if="!eventsLoaded && !loadingNutriStats"
                    >
                      <v-row class="d-flex justify-center">
                        <v-btn
                          dense
                          color="#614021"
                          class="white--text"
                          @click="loadNutriStats"
                        >
                          <v-icon color="#c5d13f" left>mdi-chart-pie</v-icon>
                          Ver estatísticas de conversões
                        </v-btn>
                      </v-row>
                    </v-card-text>
                    <v-card-text v-else-if="!eventsLoaded && loadingNutriStats">
                      <v-row class="d-flex justify-center">
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="#614021"
                          indeterminate
                        ></v-progress-circular>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ratingsDialog" max-width="80%">
      <v-card>
        <v-card-title>Avaliações</v-card-title>
        <v-card-text>
          <v-row class="mx-1">
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Software/App: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingSoftw"
                    color="#c5d13f"
                    background-color="#614021"
                    dense
                    readonly
                    large
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Cardápios: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingCarda"
                    color="#c5d13f"
                    background-color="#614021"
                    dense
                    readonly
                    large
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Resultados do tratamento: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingTrata"
                    color="#c5d13f"
                    background-color="#614021"
                    readonly
                    large
                    dense
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Experiência como um todo: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingTotal"
                    color="#c5d13f"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-row class="mx-4">
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="8"> Contribuição Propretário </v-col>
                <v-col cols="4">
                  {{ niceString(contribuicaoPropretario) }}
                </v-col>
              </v-row>
            </v-card>
          </v-row> -->
          <v-row class="d-flex justify-center mx-1">
            <v-card outlined class="my-2" width="98%">
              <v-row class="d-flex align-center">
                <v-col cols="8" class="text-caption"
                  >Avaliação de todos os nutris
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingNutri"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row
            class="d-flex justify-center mx-1"
            v-if="funcInclRecepcionista"
          >
            <v-card outlined class="my-2" width="98%">
              <v-row class="d-flex align-center">
                <v-col cols="8" class="text-caption">
                  Avaliação da recepcionista
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingRecip"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="d-flex justify-center mx-1" v-if="funcInclEsteticista">
            <v-card outlined class="my-2" width="98%">
              <v-row class="d-flex align-center">
                <v-col cols="8" class="text-caption">
                  Avaliação da esteticista
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingEstet"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="clientsDialog" max-width="80%">
      <v-data-table
        :headers="clientStatHeader"
        :items="pacMList"
        class="elevation-1"
      ></v-data-table>
    </v-dialog>

    <v-row v-for="(franqObj, franqObjIndex) in franqObjs" :key="franqObjIndex">
      <v-card width="95%" class="my-1" outlined :style="franqObj.style">
        <v-card-title class="d-flex justify-center align-center my-3">
          <v-avatar tile width="36" height="27" v-if="franqObj.flag !== null">
            <img :src="franqObj.flag" /> </v-avatar
          ><v-avatar tile width="36" height="27" v-else> </v-avatar
          >{{ franqObj.city }}-{{ franqObj.state }},
          {{ franqObj.bairro }}
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="d-flex justify-center align-center my-1" v-if="loading"
          >calcular faturamento (mês {{ mesString }}):
          <v-progress-circular
            :size="20"
            :width="2"
            color="#614021"
            indeterminate
          ></v-progress-circular
        ></v-row>
        <div v-else>
          <v-row class="d-flex justify-center align-center my-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 mx-2 black--text"
                  icon
                  @click="showDetails(franqObj.email)"
                >
                  <v-icon color="#000000">mdi-book-open-variant</v-icon>
                </v-btn>
              </template>
              <span>Ver livro caixa</span>
            </v-tooltip>
            <p class="mr-1 mt-4">mês {{ mesString }}:</p>
          </v-row>
          <v-row class="d-flex justify-center align-center my-1">
            <p>faturamento:</p>
            <p class="green--text ml-1" v-if="franqObj.entrouSum > 30000">
              <strong>{{ niceString(franqObj.entrouSum) }}</strong>
            </p>
            <p v-else class="red--text ml-1">
              <strong>{{ niceString(franqObj.entrouSum) }}</strong>
            </p>
            <p class="ml-1">
              (sem descontos {{ niceString(franqObj.sumSemDesc) }})
            </p>
          </v-row>
          <v-row class="d-flex justify-center align-center my-1">
            <p>gastos:</p>
            <p class="red--text ml-1" v-if="franqObj.gastosSum < -15000">
              <strong>{{ niceString(franqObj.gastosSum) }}</strong>
            </p>
            <p class="ml-1" v-else>
              <strong>{{ niceString(franqObj.gastosSum) }}</strong>
            </p>
          </v-row>
          <v-row class="d-flex justify-center align-center my-1">
            <p>
              taxa: <strong>{{ niceString(franqObj.taxaFranquia) }}</strong>
            </p>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="2" class="d-flex justify-center align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 mx-2 black--text"
                  icon
                  @click="showClients(franqObj.email)"
                >
                  <v-icon color="#000000">mdi-account-group</v-icon>
                </v-btn>
              </template>
              <span>Ver pacientes</span>
            </v-tooltip>
            {{ franqObj.sumClients }} (+{{ franqObj.sumClientsMnow }})
          </v-col>
          <v-col cols="2" class="d-flex justify-center align-center">
            <v-icon color="#000000">mdi-account-sync</v-icon>
            CRR: {{ franqObj.crr }}%
          </v-col>
          <v-col
            cols="3"
            class="d-flex justify-center align-center"
            v-if="franqObj.nps !== null"
            ><v-icon color="#000000" class="mx-1">mdi-account-voice</v-icon>
            NPS: {{ franqObj.nps }}%
          </v-col>
          <v-col cols="3" class="d-flex justify-center align-center" v-else>
          </v-col>
          <v-col cols="3" class="d-flex justify-center align-center"
            ><v-icon color="#000000" class="mx-1">mdi-account-cash</v-icon> CLV:
            {{ niceString(franqObj.clv) }}
          </v-col>
          <v-col cols="2" class="d-flex justify-center align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 mx-2 black--text"
                  icon
                  @click="showRatings(franqObj.email)"
                >
                  <v-icon color="#000000">mdi-star</v-icon>
                </v-btn>
              </template>
              <span>Ver avaliações</span>
            </v-tooltip>
            {{ franqObj.ratingTotalExact }}
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import {
  mdiCreditCardCheckOutline,
  mdiCash100,
  mdiCreditCardClockOutline,
  mdiBankTransfer,
  mdiHelpRhombusOutline,
  mdiInfinity,
  mdiWalletPlusOutline,
  mdiCheckbook,
  mdiPiggyBank,
} from "@mdi/js";
import PieChart from "@/components/PieChart.js";
import firebase from "firebase";
export default {
  components: { PieChart },
  data: function() {
    return {
      contribuicoesNotCalc: true,
      events: [],
      eventsLoaded: false,
      loadingNutriStats: false,
      vendedores: [],
      tiposVendidos: [{ tipo: null, qtd: null, valor: null }],
      loadingTiposVendidos: false,
      loadedTiposVendidos: false,
      zeroTiposVendidos: false,
      dataNutri1: null,
      dataNutri2: null,
      dataNutri3: null,
      comprovanteLink: "",
      dialogComprovante: false,
      vision: "Dia",
      periods: ["Dia", "Mês", "Total"],
      periodsM: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      periodsD: [],
      anos: [],
      anosObjs: [],
      nameMonths: [
        "None",
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthList: [
        {
          mes: "Janeiro",
          m: 1,
          somaNr: 0,
        },
        {
          mes: "Fevereiro",
          m: 2,
          somaNr: 0,
        },
        {
          mes: "Março",
          m: 3,
          somaNr: 0,
        },
        {
          mes: "Abril",
          m: 4,
          somaNr: 0,
        },
        {
          mes: "Maio",
          m: 5,
          somaNr: 0,
        },
        {
          mes: "Junho",
          m: 6,
          somaNr: 0,
        },
        {
          mes: "Julho",
          m: 7,
          somaNr: 0,
        },
        {
          mes: "Agosto",
          m: 8,
          somaNr: 0,
        },
        {
          mes: "Setembro",
          m: 9,
          somaNr: 0,
        },
        {
          mes: "Outubro",
          m: 10,
          somaNr: 0,
        },
        {
          mes: "Novembro",
          m: 11,
          somaNr: 0,
        },
        {
          mes: "Dezembro",
          m: 12,
          somaNr: 0,
        },
      ],
      icons: {
        mdiCreditCardCheckOutline,
        mdiCash100,
        mdiCreditCardClockOutline,
        mdiBankTransfer,
        mdiHelpRhombusOutline,
        mdiInfinity,
        mdiWalletPlusOutline,
        mdiCheckbook,
        mdiPiggyBank,
      },
      pacoteHeaders: [
        { text: "Pacote", value: "nome" },
        { text: "Mês do programa", value: "dataInicio" },
        { text: "Area", value: "area" },
      ],
      formaHeaders: [
        { text: "Cartão de Crédito", value: "cartaoDeCredito" },
        { text: "Cartão de Débito", value: "cartaoDeDebito" },
        // { text: "Cheque", value: "cheque" },
        { text: "Dinheiro", value: "dinheiro" },
        { text: "PIX", value: "pix" },
        { text: "Transferência Bancária", value: "transferenciaBancaria" },
        { text: "Depósito Bancário", value: "deposito" },
      ],
      dayHeaders: [
        { text: "Tipo de venda/gasto", value: "tipo" },
        { text: "Descrição", value: "descr" },
        { text: "Paciente", value: "pacienteName" },
        { text: "Valor", value: "niceValor" },
      ],
      monthHeaders: [
        { text: "Mês", value: "mes" },
        { text: "Lucro", value: "somaNr" },
        { text: "Faturamento", value: "faturamento" },
        { text: "Gastos", value: "gastos" },
      ],
      dialogDshow: false,
      dialogMshow: false,
      dialogTshow: false,
      dialogComissoes: false,
      comissaoPropretario: 0,
      comissaoNutricionista1: 0,
      comissaoNutricionista2: 0,
      comissaoNutricionista3: 0,
      comissaoEsteticista: 0,
      comissaoRecepcionista: 0,
      comissaoFranquia: 0,
      contribuicaoPropretario: 0,
      contribuicaoNutricionista1: 0,
      contribuicaoNutricionista2: 0,
      contribuicaoNutricionista3: 0,
      contribuicaoRecepcionista: 0,
      contribuicaoEsteticista: 0,
      contribuicaoEstagiaria: 0,
      taxaPublicidade: 0,
      taxaRoyalts: 0,
      taxaSoftware: 0,
      taxaSoma: 0,
      entrouSoma: 0,
      gastosSoma: 0,
      dialogDelement: {
        descr: "",
        pacienteName: "",
        valor: 0,
        tipo: "",
        pacotes: [{ dataInicio: null }],
      },
      dialogMelement: {
        y: 0,
        m: 0,
        d: 0,
        dia: "",
        somaNr: 0,
        elements: [
          {
            pacienteName: "",
            niceValor: "",
            descr: "",
            tipo: "",
          },
        ],
      },
      dialogTelement: {
        ano: 0,
        somaNr: 0,
        monthList: [],
      },
      titulo: "",
      faturamento: "",
      faturamentoT: 0,
      faturamentoDdin: "",
      visionD: null,
      visionM: null,
      visionA: null,
      elementsM: [],
      daysM: [],
      elementsD: [],

      livroCaixaDialog: false,
      loading: true,
      caixa: [],
      franqObjs: [
        {
          city: "BC 1",
          state: "SC",
          bairro: "Centro",
          email: "bc2@clubedanutricionista.com.br",
          style: "background-color: #e9edc9",
          caixa: [],
          flag: null,
          sumClients: 0,
          sumClientsMnow: 0,
          sumClientsM: [],
          ratingNutri: 0,
          ratingEstet: 0,
          ratingRecip: 0,
          ratingTrata: 0,
          ratingCarda: 0,
          ratingTotal: 0,
          ratingSoftw: 0,
        },
        {
          city: "Bélem 1",
          state: "PA",
          bairro: "Bélem",
          email: "umarizal@clubedanutricionista.com.br",
          style: "background-color: #ccd5ae",
          caixa: [],
          flag: null,
          sumClients: 0,
          sumClientsMnow: 0,
          sumClientsM: [],
          ratingNutri: 0,
          ratingEstet: 0,
          ratingRecip: 0,
          ratingTrata: 0,
          ratingCarda: 0,
          ratingTotal: 0,
          ratingSoftw: 0,
        },
        {
          city: "Umarizal",
          state: "PA",
          bairro: "Umarizal",
          email: "umarizalbelem@clubedanutricionista.com.br",
          style: "background-color: #e9edc9",
          caixa: [],
          flag: null,
          sumClients: 0,
          sumClientsMnow: 0,
          sumClientsM: [],
          ratingNutri: 0,
          ratingEstet: 0,
          ratingRecip: 0,
          ratingTrata: 0,
          ratingCarda: 0,
          ratingTotal: 0,
          ratingSoftw: 0,
        },
        {
          city: "Fortaleza",
          state: "CE",
          bairro: "Salinas",
          email: "fortaleza.salinas@clubedanutricionista.com.br",
          style: "background-color: #ccd5ae",
          caixa: [],
          flag: null,
          sumClients: 0,
          sumClientsMnow: 0,
          sumClientsM: [],
          ratingNutri: 0,
          ratingEstet: 0,
          ratingRecip: 0,
          ratingTrata: 0,
          ratingCarda: 0,
          ratingTotal: 0,
          ratingSoftw: 0,
        },
        {
          city: "BC 2",
          state: "PA",
          bairro: "Centro",
          email: "bc@clubedanutricionista.com.br",
          style: "background-color: #e9edc9",
          caixa: [],
          flag: null,
          sumClients: 0,
          sumClientsMnow: 0,
          sumClientsM: [],
          ratingNutri: 0,
          ratingEstet: 0,
          ratingRecip: 0,
          ratingTrata: 0,
          ratingCarda: 0,
          ratingTotal: 0,
          ratingSoftw: 0,
        },
        {
          city: "Niteroí",
          state: "RJ",
          bairro: "",
          email: "niteroi@clubedanutricionista.com.br",
          style: "background-color: #ccd5ae",
          caixa: [],
          flag: null,
          sumClients: 0,
          sumClientsMnow: 0,
          sumClientsM: [],
          ratingNutri: 0,
          ratingEstet: 0,
          ratingRecip: 0,
          ratingTrata: 0,
          ratingCarda: 0,
          ratingTotal: 0,
          ratingSoftw: 0,
        },
      ],
      ratingNutri: 0,
      ratingEstet: 0,
      ratingRecip: 0,
      ratingTrata: 0,
      ratingCarda: 0,
      ratingTotal: 0,
      ratingSoftw: 0,
      ratingsDialog: false,
      clientsDialog: false,
      pacMList: [],
    };
  },
  methods: {
    async getVendedores(user) {
      var funcionarios = await firebase
        .firestore()
        .collection("users")
        .doc(user)
        .collection("funcionarios")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );
      this.vendedores = funcionarios.map(function(funcionario) {
        return funcionario.papel;
      });
    },
    async getFaturamentos() {
      for (var i = 0; i < this.franqObjs.length; i++) {
        var faturObj = await this.getFaturamento(this.franqObjs[i].email);
        this.franqObjs[i].entrouSum = faturObj.entrouSum;
        this.franqObjs[i].gastosSum = faturObj.gastosSum;
        this.franqObjs[i].sumM = faturObj.sumM;
        this.franqObjs[i].sumSemDesc = faturObj.sumSemDesc;
        this.franqObjs[i].taxaFranquia = faturObj.taxaFranquia;
        this.franqObjs[i].clv = faturObj.clv;
      }
    },
    async getFaturamento(user) {
      console.log(user);
      var userCaixa = await firebase
        .firestore()
        .collection("users")
        .doc(user)
        .collection("caixa")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );
      var emailList = this.franqObjs.map(function(obj) {
        return obj.email;
      });
      var index = emailList.indexOf(user);
      this.franqObjs[index].caixa = userCaixa;
      var entrouSum = 0;
      var gastosSum = 0;
      var filterM = function(list, c1, c2) {
        return list.filter(function(el) {
          return el.m === c1 && el.y === c2;
        });
      };
      var filter6Mpos = function(list) {
        var now = new Date();
        var nowMillis = now.getTime();
        var subs6M = 6 * 30 * 24 * 60 * 60 * 1000;
        var millis6M = nowMillis - subs6M;

        return list.filter(function(el) {
          return el.millis > millis6M && el.direction;
        });
      };

      var d = new Date();
      var nowMonth = d.getMonth() + 1;
      var nowYear = d.getFullYear();

      var elementsM = filterM(userCaixa, nowMonth, nowYear);
      console.log(elementsM.length);
      var elementsMpos = elementsM.filter(function(el) {
        return el.direction;
      });
      var elementsMneg = elementsM.filter(function(el) {
        return !el.direction;
      });

      var sumM = 0;
      var sumSemDesc = 0;
      elementsM.forEach(function(item) {
        sumM += parseFloat(item.valor);
      });

      var getValorSemDesc = function(valor, desc) {
        return parseInt(valor) + parseInt(desc);
      };
      elementsMpos.forEach(function(item) {
        // sumSemDesc += getValorFromTipoAndLoc(item.tipo, user);
        sumSemDesc += parseInt(getValorSemDesc(item.valor, item.desconto));
      });

      elementsMpos.forEach(function(item) {
        entrouSum += parseFloat(item.valor);
      });
      elementsMneg.forEach(function(item) {
        gastosSum += parseFloat(item.valor);
      });

      var taxaPublicidade = (entrouSum / 100) * 2;
      var taxaRoyalts = (entrouSum / 100) * 8;

      var taxaSoftware = 160; // 160
      taxaPublicidade = Math.max(taxaPublicidade, 600); // 2% elementsVenda, min 600
      taxaRoyalts = Math.max(taxaRoyalts, 1200); // 8% elementsVenda, min 1200
      var taxaSoma = taxaSoftware + taxaPublicidade + taxaRoyalts;
      console.log(taxaSoma);

      var elements6Mpos = filter6Mpos(userCaixa);
      var entrouSum6M = 0;
      elements6Mpos.forEach(function(item) {
        entrouSum6M += parseFloat(item.valor);
      });

      var avgValor = entrouSum6M / elements6Mpos.length;

      var buyers = elements6Mpos.map(function(el) {
        return el.paciente;
      });
      var uniqueBuyers = buyers.filter(function(buyer, pos) {
        return buyers.indexOf(buyer) == pos;
      });
      var avgFreq = elements6Mpos.length / uniqueBuyers.length;

      // var lifetimes = uniqueBuyers.map(function (pId) {
      //   var acquisitions = elements6Mpos.filter(function (el) {
      //     return el.paciente == pId;
      //   });
      //   var lifetime = {
      //     "1 Consulta": 0.25,
      //     "1 Consulta Nutricional Crédito / Débito": 0.25,
      //     "1 Consulta Nutricional PIX / Transferência": 0.25,
      //     "1 Consulta Nutricional (plano família, mínimo 2 pessoas)": 0.25,
      //     "1 consulta nutricional (plano família, mínimo 2 pessoas)": 0.25,
      //     "1 Consulta Nutricional SEM BIOIMPEDÂNCIA E RETORNO": 0.25,
      //     "1 Consulta Nutricional com Dra Thayana": 0.25,
      //     "3 Consultas + Pacote Estético": 0.5,
      //     "3 Consultas + 2 Pacote Estético": 0.5,
      //     "3 Consultas + 3 Pacote Estético": 0.5,
      //     "1 programa de 3 consultas + 3 meses de Estética (3 áreas)": 0.5,
      //     "1 programa de MODULAÇÃO INTESTINAL plano família (90 dias)": 0.5,
      //     "1 programa de MODULAÇÃO INTESTINAL (90 dias)": 0.5,
      //     "1 programa BÁSICO NUTRICIONAL (90 dias)": 0.5,
      //     "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)": 0.5,
      //     "1 programa de 3 consultas (90 dias)": 0.5,
      //     "1 programa de 3 consultas plano família (90 dias)": 0.5,
      //     "1 programa de 3 consultas + 3 meses de Estética (1 área)": 0.5,
      //     "1 programa de 3 consultas + 3 meses de Estética (2 áreas)": 0.5,
      //     "Adesão ao Programa de Acompanhamento Nutricional de 90 Dias": 0.5,
      //     "Adesão ao Programa de Acompanhamento Nutricional de 90 Dias + Pacote Estético": 0.5,
      //     "Protocolo Kirchhoff": 0.25,
      //     "Pacote 90 dias Protocolo Kirchhoff": 0.5,
      //     "Pacote Estético": 0.25,
      //     "1 sessão estética avulsa": 0.05,
      //     "Sessão Estética": 0.05,
      //     Bioimpedância: 0.05,
      //     "1 exame de bioimpedância": 0.05,
      //     "Exame de Intolerância e Hipersensibilidade Alimentar": 0.25,
      //     "1 exame de intolerância e hipersensibilidade alimentar": 0.25,
      //     Produto: 0.01,
      //     Outro: 0.01,
      //     "Programa Clínico Funcional": 0.5,
      //     "Programa Modulação Intestinal": 0.5,
      //     "Programa Clínico Funcional (plano família)": 0.5,
      //     "Programa Modulação Intestinal (plano família)": 0.5,
      //     "Programa 12S Online": 0.5,
      //     "Programa 12S Plano Básico": 0.5,
      //     "Programa 12S Plano Premium": 0.5,
      //     "Programa 12S Plano VIP": 0.5,
      //     "Protocolo Harmonização Corporal 24H": 0.25,
      //     "Protocolo Lipodraining 24H": 0.25,
      //     "Protocolo Detox + Lipodraining 24H": 0.25,
      //   };

      //   var sumLives = 0;
      //   acquisitions.forEach(function (acq) {
      //     if (lifetime[acq.tipo] !== undefined) {
      //       sumLives += lifetime[acq.tipo];
      //     } else {
      //       sumLives += 0.25;
      //     }
      //   });

      //   return sumLives;
      // });
      // var lifetimesSum = 0;
      // lifetimes.forEach(function (lt) {
      //   lifetimesSum += lt;
      // });
      // var avgLife = lifetimesSum / uniqueBuyers.length;
      console.log(sumSemDesc);

      var clv = avgValor * avgFreq; //* avgLife;

      return {
        sumM: sumM,
        entrouSum: entrouSum,
        gastosSum: gastosSum,
        sumSemDesc: sumSemDesc,
        taxaFranquia: taxaSoma,
        clv: clv,
      };
    },
    niceString(valor) {
      if (valor) {
        var valorKomma;

        var decimals = Math.round((valor - Math.floor(valor)) * 100);
        var decimalsStr;
        if (decimals < 1) {
          decimalsStr = "00";
        } else if (decimals < 10) {
          decimalsStr = "0" + decimals.toString();
        } else {
          decimalsStr = decimals.toString();
        }

        var valorGanz = Math.floor(valor);

        if (valorGanz > 1000) {
          var tusiger = Math.floor(valor / 1000);
          var rest = valorGanz % 1000;
          var restStr;
          if (rest < 10) {
            restStr = "00" + rest.toString();
          } else if (rest < 100) {
            restStr = "0" + rest.toString();
          } else {
            restStr = rest.toString();
          }
          valorKomma = tusiger.toString() + "." + restStr;
        } else {
          valorKomma = valorGanz.toString();
        }
        return "R$ " + valorKomma + "," + decimalsStr;
      } else {
        return "R$ 0,00";
      }
    },
    showDetails(user) {
      this.getVendedores(user);

      var d = new Date();
      var y = d.getFullYear();
      var anosList = [];
      for (var ano = 2021; ano <= y; ano++) {
        anosList.push(ano);
      }
      this.visionA = y;
      var monthNow = d.getMonth() + 1;
      this.visionM = monthNow;
      this.visionD = d.getDate();
      //   this.getFaturamento();
      //   this.preloadImages();
      this.anos = anosList;
      this.setPeriodsD(d.getFullYear(), monthNow);

      var emailList = this.franqObjs.map(function(obj) {
        return obj.email;
      });
      var index = emailList.indexOf(user);

      this.caixa = this.franqObjs[index].caixa;
      this.updateFaturamento();
      this.livroCaixaDialog = true;
    },
    async countClients(user) {
      var pacientesList = await firebase
        .firestore()
        .collection("users")
        .doc(user)
        // .doc(firebase.auth().currentUser.email)
        .collection("pacientes")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );

      var emailList = this.franqObjs.map(function(obj) {
        return obj.email;
      });
      var index = emailList.indexOf(user);
      this.franqObjs[index].sumClients = pacientesList.length;

      var meses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      var d = new Date();
      var mesNow = d.getMonth() + 1;
      var yearNow = d.getFullYear();
      var yearNowIndex = yearNow - 2021;
      var years = [];
      for (var j = 2021; j <= yearNow; j++) {
        years.push(j);
      }

      var pacPerMes = years.map(function(year) {
        var monthPacList = meses.map(function(mes) {
          var pacMes = pacientesList.filter(function(pac) {
            var joinDate = new Date(pac.joinedAt.seconds * 1000);
            var joinYear = joinDate.getFullYear();
            var joinMes = joinDate.getMonth() + 1;
            return joinYear == year && joinMes == mes;
          });
          var nrPacMes = pacMes.length;
          return nrPacMes;
        });
        return monthPacList;
      });

      this.franqObjs[index].sumClientsMnow =
        pacPerMes[yearNowIndex][mesNow - 1];

      var cEnd = pacientesList.filter(function(pac) {
        var now = new Date();
        var nowMillis = now.getTime();
        return pac.programaFim !== undefined && pac.programaFim > nowMillis;
      }).length;
      var cNew = pacientesList.filter(function(pac) {
        var joinDate = new Date(pac.joinedAt.seconds * 1000);
        var joinMillis = joinDate.getTime();
        var now = new Date();
        var nowMillis = now.getTime();
        var subs3M = 3 * 30 * 24 * 60 * 60 * 1000;
        var limitMillis = nowMillis - subs3M;
        return pac.programaFim !== undefined && joinMillis > limitMillis;
      }).length;
      var cStart = pacientesList.filter(function(pac) {
        var joinDate = new Date(pac.joinedAt.seconds * 1000);
        var joinMillis = joinDate.getTime();
        var now = new Date();
        var nowMillis = now.getTime();
        var subs3M = 3 * 30 * 24 * 60 * 60 * 1000;
        var limitMillis = nowMillis - subs3M;
        return (
          pac.programaFim !== undefined &&
          joinMillis < limitMillis &&
          pac.programaFim > limitMillis
        );
      }).length;
      console.log(cEnd, cNew, cStart);
      var crr = Math.round(((cEnd - cNew) / cStart) * 10000) / 100;
      this.franqObjs[index].crr = crr;
      var pacPerMesShow = meses.map(function(mes) {
        var pac0 = pacPerMes[0][mes - 1];
        var pac1 = pacPerMes[1][mes - 1];
        return {
          mes: mes,
          2021: pac0,
          2022: pac1,
        };
      });
      this.franqObjs[index].sumClientsM = pacPerMesShow;

      // ratings
      var hasRatingNutri = function(el) {
        return el.ratingNutri !== undefined && el.ratingNutri !== null;
      };
      var hasRatingEstet = function(el) {
        return el.ratingEstet !== undefined && el.ratingEstet !== null;
      };
      var hasRatingRecip = function(el) {
        return el.ratingRecip !== undefined && el.ratingRecip !== null;
      };
      var hasRatingTrata = function(el) {
        return el.ratingTrata !== undefined && el.ratingTrata !== null;
      };
      var hasRatingCarda = function(el) {
        return el.ratingCarda !== undefined && el.ratingCarda !== null;
      };
      var hasRatingTotal = function(el) {
        return el.ratingTotal !== undefined && el.ratingTotal !== null;
      };
      var hasRatingSoftw = function(el) {
        return el.ratingSoftw !== undefined && el.ratingSoftw !== null;
      };
      var hasRatingProb = function(el) {
        return el.probRec !== undefined && el.probRec !== null;
      };

      var ratingsNutriArray = pacientesList
        .filter(hasRatingNutri)
        .map(function(paciente) {
          return parseInt(paciente.ratingNutri);
        });
      var ratingsEstetArray = pacientesList
        .filter(hasRatingEstet)
        .map(function(paciente) {
          return parseInt(paciente.ratingEstet);
        });
      var ratingsRecipArray = pacientesList
        .filter(hasRatingRecip)
        .map(function(paciente) {
          return parseInt(paciente.ratingRecip);
        });
      var ratingsTrataArray = pacientesList
        .filter(hasRatingTrata)
        .map(function(paciente) {
          return parseInt(paciente.ratingTrata);
        });
      var ratingsCardaArray = pacientesList
        .filter(hasRatingCarda)
        .map(function(paciente) {
          return parseInt(paciente.ratingCarda);
        });
      var ratingsTotalArray = pacientesList
        .filter(hasRatingTotal)
        .map(function(paciente) {
          return parseInt(paciente.ratingTotal);
        });
      var ratingsSoftwArray = pacientesList
        .filter(hasRatingSoftw)
        .map(function(paciente) {
          return parseInt(paciente.ratingSoftw);
        });
      var probArray = pacientesList
        .filter(hasRatingProb)
        .map(function(paciente) {
          return parseInt(paciente.probRec);
        });
      var probs = probArray.length;
      if (probs > 0) {
        var promoters = probArray.filter(function(nr) {
          return nr > 8;
        });
        var detractors = probArray.filter(function(nr) {
          return nr < 7;
        });
        var nps;
        var npsPlus = (promoters.length / probs) * 100;
        var npsMinus = (detractors.length / probs) * 100;
        var npsExact = npsPlus - npsMinus;
        nps = Math.round(npsExact * 100) / 100;
      } else {
        nps = null;
      }

      var ratingsNutriSum = ratingsNutriArray.reduce((a, b) => a + b, 0);
      var ratingsEstetSum = ratingsEstetArray.reduce((a, b) => a + b, 0);
      var ratingsRecipSum = ratingsRecipArray.reduce((a, b) => a + b, 0);
      var ratingsTrataSum = ratingsTrataArray.reduce((a, b) => a + b, 0);
      var ratingsCardaSum = ratingsCardaArray.reduce((a, b) => a + b, 0);
      var ratingsTotalSum = ratingsTotalArray.reduce((a, b) => a + b, 0);
      var ratingsSoftwSum = ratingsSoftwArray.reduce((a, b) => a + b, 0);

      var ratingsNutriAvg = ratingsNutriSum / ratingsNutriArray.length;
      var ratingsEstetAvg = ratingsEstetSum / ratingsEstetArray.length;
      var ratingsRecipAvg = ratingsRecipSum / ratingsRecipArray.length;
      var ratingsTrataAvg = ratingsTrataSum / ratingsTrataArray.length;
      var ratingsCardaAvg = ratingsCardaSum / ratingsCardaArray.length;
      var ratingsTotalAvg = ratingsTotalSum / ratingsTotalArray.length;
      var ratingsSoftwAvg = ratingsSoftwSum / ratingsSoftwArray.length;

      this.franqObjs[index].ratingNutri = Math.round(ratingsNutriAvg);
      this.franqObjs[index].ratingEstet = Math.round(ratingsEstetAvg);
      this.franqObjs[index].ratingRecip = Math.round(ratingsRecipAvg);
      this.franqObjs[index].ratingTrata = Math.round(ratingsTrataAvg);
      this.franqObjs[index].ratingCarda = Math.round(ratingsCardaAvg);
      this.franqObjs[index].ratingTotal = Math.round(ratingsTotalAvg);
      this.franqObjs[index].ratingSoftw = Math.round(ratingsSoftwAvg);
      this.franqObjs[index].ratingTotalExact = ratingsTotalAvg.toFixed(2);
      this.franqObjs[index].nps = nps;
    },
    showClients(user) {
      var emailList = this.franqObjs.map(function(obj) {
        return obj.email;
      });
      var index = emailList.indexOf(user);
      this.pacMList = this.franqObjs[index].sumClientsM;

      this.clientsDialog = true;
    },
    showRatings(user) {
      this.getVendedores(user);

      var emailList = this.franqObjs.map(function(obj) {
        return obj.email;
      });
      var index = emailList.indexOf(user);
      this.ratingNutri = this.franqObjs[index].ratingNutri;
      this.ratingEstet = this.franqObjs[index].ratingEstet;
      this.ratingRecip = this.franqObjs[index].ratingRecip;
      this.ratingTrata = this.franqObjs[index].ratingTrata;
      this.ratingCarda = this.franqObjs[index].ratingCarda;
      this.ratingTotal = this.franqObjs[index].ratingTotal;
      this.ratingSoftw = this.franqObjs[index].ratingSoftw;
      this.ratingsDialog = true;
    },
    makeRecibo(elData) {
      alert("indisponível");
      console.log(elData);
    },
    getTitulo() {
      var tituloStr;
      if (this.vision == "Mês") {
        tituloStr = this.nameMonths[this.visionM];
      } else if (this.vision === "Dia") {
        var monthStr;
        if (this.visionM < 10) {
          monthStr = "0" + this.visionM.toString();
        } else {
          monthStr = this.visionM.toString();
        }

        var dayStr;
        if (this.visionD < 10) {
          dayStr = "0" + this.visionD.toString();
        } else {
          dayStr = this.visionD.toString();
        }

        tituloStr = dayStr + "/" + monthStr + "/" + this.visionA.toString();
      }
      this.titulo = tituloStr;
    },
    calculateContribuicoes() {
      var elementsM = [];
      if (this.vision == "Mês") {
        var filterM = function(list, c1, c2) {
          return list.filter(function(el) {
            return el.m === c1 && el.y === c2;
          });
        };
        elementsM = filterM(this.caixa, this.visionM, this.visionA);

        elementsM = elementsM.filter(function(el) {
          return el.direction;
        });
      }
      // else {
      //   var filterA = function (list, c1) {
      //     return list.filter(function (el) {
      //       return el.y === c1;
      //     });
      //   };
      //   elementsM = filterA(this.caixa, this.visionA)
      // }

      var filterComissao = function(list, cond) {
        return list.filter(function(el) {
          return el.comissao == cond && el.direction;
        });
      };
      var filterComissao1 = function(list, cond1, cond2) {
        return list.filter(function(el) {
          if (el.direction) {
            return el.comissao == cond1 || el.comissao == cond2;
          } else {
            return false;
          }
        });
      };

      var elementsPropretario = filterComissao(elementsM, "Propretário");
      var elementsNutricionista1 = filterComissao1(
        elementsM,
        "Nutricionista",
        "Nutricionista 1"
      );
      var elementsNutricionista2 = filterComissao(elementsM, "Nutricionista 2");
      var elementsNutricionista3 = filterComissao(elementsM, "Nutricionista 3");
      var elementsRecepcionista = filterComissao(elementsM, "Recepcionista");
      var elementsEsteticista = filterComissao(elementsM, "Esteticista");
      var elementsEstagiaria = filterComissao(elementsM, "Estagiária");

      var comissaoPropretario = 0;
      if (elementsPropretario.length > 0) {
        elementsPropretario.forEach(function(item) {
          comissaoPropretario += parseFloat(item.valor);
        });
      }

      var comissaoNutricionista1 = 0;
      if (elementsNutricionista1.length > 0) {
        elementsNutricionista1.forEach(function(item) {
          comissaoNutricionista1 += parseFloat(item.valor);
        });
      }

      var comissaoNutricionista2 = 0;
      if (elementsNutricionista2.length > 0) {
        elementsNutricionista2.forEach(function(item) {
          comissaoNutricionista2 += parseFloat(item.valor);
        });
      }

      var comissaoNutricionista3 = 0;
      if (elementsNutricionista3.length > 0) {
        elementsNutricionista3.forEach(function(item) {
          comissaoNutricionista3 += parseFloat(item.valor);
        });
      }

      var comissaoRecepcionista = 0;
      if (elementsRecepcionista.length > 0) {
        elementsRecepcionista.forEach(function(item) {
          comissaoRecepcionista += parseFloat(item.valor);
        });
      }

      var comissaoEsteticista = 0;
      if (elementsEsteticista.length > 0) {
        elementsEsteticista.forEach(function(item) {
          comissaoEsteticista += parseFloat(item.valor);
        });
      }

      var comissaoEstagiaria = 0;
      if (elementsEstagiaria.length > 0) {
        elementsEstagiaria.forEach(function(item) {
          comissaoEstagiaria += parseFloat(item.valor);
        });
      }

      this.contribuicaoPropretario = comissaoPropretario;
      this.contribuicaoNutricionista1 = comissaoNutricionista1;
      this.contribuicaoNutricionista2 = comissaoNutricionista2;
      this.contribuicaoNutricionista3 = comissaoNutricionista3;
      this.contribuicaoRecepcionista = comissaoRecepcionista;
      this.contribuicaoEsteticista = comissaoEsteticista;
      this.contribuicaoEstagiaria = comissaoEstagiaria;
      this.contribuicoesNotCalc = false;
    },
    loadNutriStats() {
      this.loadingNutriStats = true;
      this.getEvents();
    },
    async getEvents() {
      var events = [];
      var eventList = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      eventList.forEach(function(event) {
        if (event.nutriAtendendo != undefined) {
          events.push({
            name: event.name,
            nutriAtendendo: event.nutriAtendendo,
            patient: event.patient,
            patientId: event.patientId,
            newPatient: event.newPatient,
            start: new Date(event.start),
            end: new Date(event.end),
            details: event.details,
            color: event.color,
            timed: event.timed,
            id: event.id,
            startTime: event.startTime,
            endTime: event.endTime,
            niceDateShort: event.niceDateShort,
          });
        } else {
          events.push({
            name: event.name,
            patient: event.patient,
            patientId: event.patientId,
            newPatient: event.newPatient,
            start: new Date(event.start),
            end: new Date(event.end),
            details: event.details,
            color: event.color,
            timed: event.timed,
            id: event.id,
            startTime: event.startTime,
            endTime: event.endTime,
            niceDateShort: event.niceDateShort,
          });
        }
      });
      this.events = events;

      var elementsFramePos = [];

      if (this.vision == "Mês") {
        var filterM = function(list, c1, c2) {
          return list.filter(function(el) {
            return el.m === c1 && el.y === c2;
          });
        };
        var elementsFrameM = filterM(this.caixa, this.visionM, this.visionA);

        elementsFramePos = elementsFrameM.filter(function(el) {
          return el.direction;
        });
      }

      var pacSemEstet = [
        "Programa 12S",
        "Programa 12S Online",
        "Programa Clínico Funcional",
        "Programa Modulação Intestinal",
        "Programa Clínico Funcional (plano família)",
        "Programa Modulação Intestinal (plano família)",
      ];

      var pacComEstet = [
        "Programa 12S com Estética",
        "Protocolo Harmonização Corporal 24H",
        "Protocolo Detox + Lipodraining 24H",
        "Protocolo Lipodraining 24H",
        "Protocolo Glúteo de Ouro",
        "Pacote Estético",
      ];

      var totalCons1 = this.events.filter(function(event) {
        return (
          event.nutriAtendendo == "Nutricionista 1" ||
          event.nutriAtendendo == "Nutricionista"
        );
      }).length;

      var nutri1Mpacs = elementsFramePos.filter(function(el) {
        var comissaoCond1 =
          el.comissao == "Nutricionista 1" || el.comissao == "Nutricionista";
        var tipoCondS1 = pacSemEstet.includes(el.tipo);
        return comissaoCond1 && tipoCondS1;
      }).length;

      var nutri1Mpacc = elementsFramePos.filter(function(el) {
        var comissaoCond1 =
          el.comissao == "Nutricionista 1" || el.comissao == "Nutricionista";
        var tipoCondC1 = pacComEstet.includes(el.tipo);
        return comissaoCond1 && tipoCondC1;
      }).length;

      var nutri1Mcons = totalCons1 - nutri1Mpacc - nutri1Mpacs;
      if (nutri1Mcons < 0) {
        nutri1Mcons = 0;
      }

      var nutri1Mdata = [nutri1Mcons, nutri1Mpacs, nutri1Mpacc];

      this.dataNutri1 = {
        labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
        datasets: [
          {
            backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
            data: nutri1Mdata, // filter events by m and by category
          },
        ],
      };

      var totalCons2 = this.events.filter(function(event) {
        return event.nutriAtendendo == "Nutricionista 2";
      }).length;

      var nutri2Mpacs = elementsFramePos.filter(function(el) {
        var comissaoCond2 = el.comissao == "Nutricionista 2";
        var tipoCondS2 = pacSemEstet.includes(el.tipo);
        return comissaoCond2 && tipoCondS2;
      }).length;

      var nutri2Mpacc = elementsFramePos.filter(function(el) {
        var comissaoCond2 = el.comissao == "Nutricionista 2";
        var tipoCondC2 = pacComEstet.includes(el.tipo);
        return comissaoCond2 && tipoCondC2;
      }).length;

      var nutri2Mcons = totalCons2 - nutri2Mpacc - nutri2Mpacs;
      if (nutri2Mcons < 0) {
        nutri2Mcons = 0;
      }

      var nutri2Mdata = [nutri2Mcons, nutri2Mpacs, nutri2Mpacc];

      this.dataNutri2 = {
        labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
        datasets: [
          {
            backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
            data: nutri2Mdata,
          },
        ],
      };

      var totalCons3 = this.events.filter(function(event) {
        return event.nutriAtendendo == "Nutricionista 3";
      }).length;

      var nutri3Mpacs = elementsFramePos.filter(function(el) {
        var comissaoCond3 = el.comissao == "Nutricionista 3";
        var tipoCondS3 = pacSemEstet.includes(el.tipo);
        return comissaoCond3 && tipoCondS3;
      }).length;

      var nutri3Mpacc = elementsFramePos.filter(function(el) {
        var comissaoCond3 = el.comissao == "Nutricionista 3";
        var tipoCondC3 = pacComEstet.includes(el.tipo);
        return comissaoCond3 && tipoCondC3;
      }).length;

      var nutri3Mcons = totalCons3 - nutri3Mpacc - nutri3Mpacs;
      if (nutri3Mcons < 0) {
        nutri3Mcons = 0;
      }

      var nutri3Mdata = [nutri3Mcons, nutri3Mpacs, nutri3Mpacc];
      console.log(nutri3Mdata);

      this.dataNutri3 = {
        labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
        datasets: [
          {
            backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
            data: nutri3Mdata,
          },
        ],
      };

      this.loadingNutriStats = false;
      this.eventsLoaded = true;
    },
    // getVendedores() {
    //   this.vendedores = this.funcionarios.map(function (funcionario) {
    //     return funcionario.papel;
    //   });
    //   print(this.vendedores);
    // },
    setPeriodsD(year, month) {
      function daysInMonth(y, m) {
        return new Date(y, m, 0).getDate();
      }
      var listOfDays = [];
      for (var j = 1; j <= daysInMonth(year, month); j++) {
        listOfDays.push(j);
      }
      this.periodsD = listOfDays;
    },
    updateFaturamento() {
      var sum;
      var entrouSum = 0;
      var gastosSum = 0;
      if (this.vision == "Mês") {
        var filterM = function(list, c1, c2) {
          return list.filter(function(el) {
            return el.m === c1 && el.y === c2;
          });
        };
        var elementsM = filterM(this.caixa, this.visionM, this.visionA);
        var elementsMpos = elementsM.filter(function(el) {
          return el.direction;
        });
        var elementsMneg = elementsM.filter(function(el) {
          return !el.direction;
        });
        var daysDef = new Date(this.visionA, this.visionM, 0);
        var daysInMonth = daysDef.getDate();
        var mDays = [];
        for (var dr = 1; dr <= daysInMonth; dr++) {
          mDays.push(dr);
        }

        var y = this.visionA;
        var m = this.visionM;

        this.daysM = mDays.map(function(day) {
          var somaNr = 0;
          var inSomaNr = 0;
          var outSomaNr = 0;

          function filterDm(list, c1, c2, c3) {
            return list.filter(function(el) {
              return el.d === c1 && el.m === c2 && el.y === c3;
            });
          }
          var somaNrEls = filterDm(elementsM, day, m, y);
          somaNrEls.forEach(function(item) {
            somaNr += parseFloat(item.valor);
          });

          somaNrEls.forEach(function(item) {
            if (parseFloat(item.valor) > 0) {
              inSomaNr += parseFloat(item.valor);
            } else {
              inSomaNr += 0;
            }
          });

          somaNrEls.forEach(function(item) {
            if (parseFloat(item.valor) < 0) {
              outSomaNr += parseFloat(item.valor);
            } else {
              outSomaNr += 0;
            }
          });

          var monthStr;
          if (m < 10) {
            monthStr = "0" + m.toString();
          } else {
            monthStr = m.toString();
          }

          var dayStr;
          if (day < 10) {
            dayStr = "0" + day.toString();
          } else {
            dayStr = day.toString();
          }

          var dataStr = dayStr + "/" + monthStr + "/" + y.toString();

          return {
            dia: dataStr,
            somaNr: somaNr,
            inSomaNr: inSomaNr,
            outSomaNr: outSomaNr,
            y: y,
            m: m,
            d: day,
          };
        });

        this.elementsM = elementsM;

        var sumM = 0;
        elementsM.forEach(function(item) {
          sumM += parseFloat(item.valor);
        });
        sum = sumM;

        elementsMpos.forEach(function(item) {
          entrouSum += parseFloat(item.valor);
        });
        elementsMneg.forEach(function(item) {
          gastosSum += parseFloat(item.valor);
        });

        var arrangedListM = [];
        var arrangedListMtipos = [];
        var doneTiposM = [];

        for (var iM = 0; iM < elementsMpos.length; iM++) {
          var iMtipo = elementsMpos[iM].tipo;
          var iMvalor = parseFloat(elementsMpos[iM].valor);
          if (doneTiposM.includes(iMtipo)) {
            var index = arrangedListMtipos.indexOf(iMtipo);
            arrangedListM[index].qtd = arrangedListM[index].qtd + 1;
            arrangedListM[index].valor = arrangedListM[index].valor + iMvalor;
          } else {
            doneTiposM.push(iMtipo);
            arrangedListMtipos.push(iMtipo);
            arrangedListM.push({ tipo: iMtipo, qtd: 1, valor: iMvalor });
          }
        }

        this.tiposVendidos = arrangedListM;

        this.loadingTiposVendidos = false;
        this.loadedTiposVendidos = true;
        this.zeroTiposVendidos = arrangedListM.length == 0;

        if (this.eventsLoaded) {
          var pacSemEstet = [
            "Programa 12S",
            "Programa 12S Online",
            "Programa Clínico Funcional",
            "Programa Modulação Intestinal",
            "Programa Clínico Funcional (plano família)",
            "Programa Modulação Intestinal (plano família)",
          ];

          var pacComEstet = [
            "Programa 12S com Estética",
            "Protocolo Harmonização Corporal 24H",
            "Protocolo Detox + Lipodraining 24H",
            "Protocolo Lipodraining 24H",
            "Protocolo Glúteo de Ouro",
            "Pacote Estético",
          ];

          var totalCons1 = this.events.filter(function(event) {
            return (
              event.nutriAtendendo == "Nutricionista 1" ||
              event.nutriAtendendo == "Nutricionista"
            );
          }).length;

          var nutri1Mpacs = elementsMpos.filter(function(el) {
            var comissaoCond1 =
              el.comissao == "Nutricionista 1" ||
              el.comissao == "Nutricionista";
            var tipoCondS1 = pacSemEstet.includes(el.tipo);
            return comissaoCond1 && tipoCondS1;
          }).length;

          var nutri1Mpacc = elementsMpos.filter(function(el) {
            var comissaoCond1 =
              el.comissao == "Nutricionista 1" ||
              el.comissao == "Nutricionista";
            var tipoCondC1 = pacComEstet.includes(el.tipo);
            return comissaoCond1 && tipoCondC1;
          }).length;

          var nutri1Mcons = totalCons1 - nutri1Mpacc - nutri1Mpacs;
          if (nutri1Mcons < 0) {
            nutri1Mcons = 0;
          }

          var nutri1Mdata = [nutri1Mcons, nutri1Mpacs, nutri1Mpacc];

          this.dataNutri1 = {
            labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
            datasets: [
              {
                backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
                data: nutri1Mdata, // filter events by m and by category
              },
            ],
          };

          var totalCons2 = this.events.filter(function(event) {
            return event.nutriAtendendo == "Nutricionista 2";
          }).length;

          var nutri2Mpacs = elementsMpos.filter(function(el) {
            var comissaoCond2 = el.comissao == "Nutricionista 2";
            var tipoCondS2 = pacSemEstet.includes(el.tipo);
            return comissaoCond2 && tipoCondS2;
          }).length;

          var nutri2Mpacc = elementsMpos.filter(function(el) {
            var comissaoCond2 = el.comissao == "Nutricionista 2";
            var tipoCondC2 = pacComEstet.includes(el.tipo);
            return comissaoCond2 && tipoCondC2;
          }).length;

          var nutri2Mcons = totalCons2 - nutri2Mpacc - nutri2Mpacs;
          if (nutri2Mcons < 0) {
            nutri2Mcons = 0;
          }

          var nutri2Mdata = [nutri2Mcons, nutri2Mpacs, nutri2Mpacc];

          this.dataNutri2 = {
            labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
            datasets: [
              {
                backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
                data: nutri2Mdata,
              },
            ],
          };

          var totalCons3 = this.events.filter(function(event) {
            return event.nutriAtendendo == "Nutricionista 3";
          }).length;

          var nutri3Mpacs = elementsMpos.filter(function(el) {
            var comissaoCond3 = el.comissao == "Nutricionista 3";
            var tipoCondS3 = pacSemEstet.includes(el.tipo);
            return comissaoCond3 && tipoCondS3;
          }).length;

          var nutri3Mpacc = elementsMpos.filter(function(el) {
            var comissaoCond3 = el.comissao == "Nutricionista 3";
            var tipoCondC3 = pacComEstet.includes(el.tipo);
            return comissaoCond3 && tipoCondC3;
          }).length;

          var nutri3Mcons = totalCons3 - nutri3Mpacc - nutri3Mpacs;
          if (nutri3Mcons < 0) {
            nutri3Mcons = 0;
          }

          var nutri3Mdata = [nutri3Mcons, nutri3Mpacs, nutri3Mpacc];
          console.log(nutri3Mdata);

          this.dataNutri3 = {
            labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
            datasets: [
              {
                backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
                data: nutri3Mdata,
              },
            ],
          };
        }
      } else if (this.vision === "Dia") {
        this.setPeriodsD(this.visionA, this.visionM);
        var filterD = function(list, c1, c2, c3) {
          return list.filter(function(el) {
            return el.d === c1 && el.m === c2 && el.y === c3;
          });
        };
        var filterDdin = function(list) {
          return list.filter(function(el) {
            return el.forma === 1;
          });
        };
        var filterDmul = function(list) {
          return list.filter(function(el) {
            return el.forma === 7;
          });
        };

        var elementsD = filterD(
          this.caixa,
          this.visionD,
          this.visionM,
          this.visionA
        );
        var elementsDpos = elementsD.filter(function(el) {
          return el.direction;
        });
        var elementsDneg = elementsD.filter(function(el) {
          return !el.direction;
        });
        var elementsDdin = filterDdin(elementsD);
        var elementsDmul = filterDmul(elementsD);

        this.elementsD = elementsD;

        var sumD = 0;
        elementsD.forEach(function(item) {
          sumD += parseFloat(item.valor);
        });
        sum = sumD;

        var sumDdin = 0;
        elementsDdin.forEach(function(item) {
          sumDdin += parseFloat(item.valor);
        });
        var sumDmul = 0;
        elementsDmul.forEach(function(item) {
          if (item.formas) {
            sumDmul += parseFloat(item.formas.dinheiro);
          } else {
            sumDmul += 0;
          }
        });

        elementsDpos.forEach(function(item) {
          entrouSum += parseFloat(item.valor);
        });
        elementsDneg.forEach(function(item) {
          gastosSum += parseFloat(item.valor);
        });

        var sumDdimmul = sumDmul + sumDdin;

        this.faturamentoDdin = this.niceString(sumDdimmul);
      } else if (this.vision === "Total") {
        var filterA = function(list, c1) {
          return list.filter(function(el) {
            return el.y === c1;
          });
        };

        var caixa = this.caixa;
        this.anosObjs = this.anos.map(function(ano) {
          var sumA = 0;
          var elementsA = filterA(caixa, ano);
          elementsA.forEach(function(item) {
            sumA += parseFloat(item.valor);
          });

          return {
            ano: ano,
            somaNr: sumA,
          };
        });

        var sumTotal = 0;
        caixa.forEach(function(item) {
          sumTotal += parseFloat(item.valor);
        });

        var caixaPos = caixa.filter(function(el) {
          return el.direction;
        });
        // =================================================================
        // var sumLivia = 0;
        // var caixaLivia = caixaPos.filter(function (el) {
        //   return el.comissao == "Nutricionista 1" || el.comissao == "Nutricionista";
        // });
        // var livia300 = caixaLivia.filter(function (el) {
        //   return el.valor <= 300;
        // });
        // var livia690sum = 0;
        // var livia690 = caixaLivia.filter(function (el) {
        //   return el.valor == 690 || el.valor == 990;
        // });
        // var livia990sum = 0;
        // var livia990 = caixaLivia.filter(function (el) {
        //   return el.valor > 690 && el.valor !== 990;
        // });

        // caixaLivia.forEach(function (item) {
        //   sumLivia += parseFloat(item.valor);
        // });
        // livia990.forEach(function (item) {
        //   livia990sum += parseFloat(item.valor);
        // });
        // livia690.forEach(function (item) {
        //   livia690sum += parseFloat(item.valor);
        // });

        // console.log("sumLivia", sumLivia);
        // console.log("livia300", livia300.length)
        // console.log("livia690", livia690.length)
        // console.log("livia990", livia990.length)
        // console.log(livia990sum)
        // console.log(livia690sum)

        // =================================================================
        var sumEntrou = 0;
        caixaPos.forEach(function(item) {
          sumEntrou += parseFloat(item.valor);
        });

        var caixaNeg = caixa.filter(function(el) {
          return !el.direction;
        });
        var sumGastos = 0;
        caixaNeg.forEach(function(item) {
          sumGastos += parseFloat(item.valor);
        });

        this.faturamentoT = sumTotal;
        sum = sumTotal;
        entrouSum = sumEntrou;
        gastosSum = sumGastos;

        var arrangedListY = [];
        var arrangedListYtipos = [];
        var doneTiposY = [];

        for (var iY = 0; iY < caixaPos.length; iY++) {
          var iYtipo = caixaPos[iY].data.tipo;
          var iYvalor = parseFloat(caixaPos[iY].data.valor);
          if (doneTiposY.includes(iYtipo)) {
            var indexY = arrangedListYtipos.indexOf(iYtipo);
            arrangedListY[indexY].qtd = arrangedListY[indexY].qtd + 1;
            arrangedListY[indexY].valor = arrangedListY[indexY].valor + iYvalor;
          } else {
            doneTiposY.push(iYtipo);
            arrangedListYtipos.push(iYtipo);
            arrangedListY.push({ tipo: iYtipo, qtd: 1, valor: iYvalor });
          }
        }

        this.tiposVendidos = arrangedListY;

        this.loadingTiposVendidos = false;
        this.loadedTiposVendidos = true;
        this.zeroTiposVendidos = arrangedListY.length == 0;
      }

      this.getTitulo();
      this.faturamento = this.niceString(sum);
      this.entrouSoma = this.niceString(entrouSum);
      this.gastosSoma = this.niceString(gastosSum);
    },
    getComissoes() {
      var filterM = function(list, c1, c2) {
        return list.filter(function(el) {
          return el.m === c1 && el.y === c2;
        });
      };
      var filterComissao = function(list, cond) {
        return list.filter(function(el) {
          return el.comissao == cond && el.direction;
        });
      };
      var filterComissao1 = function(list, cond1, cond2) {
        return list.filter(function(el) {
          if (el.direction) {
            return el.comissao == cond1 || el.comissao == cond2;
          } else {
            return false;
          }
        });
      };
      var filterVendas = function(list) {
        return list.filter(function(el) {
          return el.direction;
        });
      };

      var elementsM = filterM(this.caixa, this.visionM, this.visionA);

      var elementsPropretario = filterComissao(elementsM, "Propretário");
      var elementsNutricionista1 = filterComissao1(
        elementsM,
        "Nutricionista",
        "Nutricionista 1"
      );
      var elementsNutricionista2 = filterComissao(elementsM, "Nutricionista 2");
      var elementsNutricionista3 = filterComissao(elementsM, "Nutricionista 3");
      var elementsRecepcionista = filterComissao(elementsM, "Recepcionista");
      var elementsEsteticista = filterComissao(elementsM, "Esteticista");
      var elementsVenda = filterVendas(elementsM);

      var comissaoFranquia = 0;
      elementsVenda.forEach(function(item) {
        comissaoFranquia += parseFloat(item.valor);
      });

      var taxaPublicidade = 0;
      elementsVenda.forEach(function(item) {
        taxaPublicidade += parseFloat(item.valor);
      });

      var taxaRoyalts = 0;
      elementsVenda.forEach(function(item) {
        taxaRoyalts += parseFloat(item.valor);
      });

      var comissaoPropretario = 0;
      if (elementsPropretario.length > 0) {
        elementsPropretario.forEach(function(item) {
          comissaoPropretario += parseFloat(item.valor);
        });
      }

      var comissaoNutricionista1 = 0;
      if (elementsNutricionista1.length > 0) {
        elementsNutricionista1.forEach(function(item) {
          comissaoNutricionista1 += parseFloat(item.valor);
        });
      }

      var comissaoNutricionista2 = 0;
      if (elementsNutricionista2.length > 0) {
        elementsNutricionista2.forEach(function(item) {
          comissaoNutricionista2 += parseFloat(item.valor);
        });
      }

      var comissaoNutricionista3 = 0;
      if (elementsNutricionista3.length > 0) {
        elementsNutricionista3.forEach(function(item) {
          comissaoNutricionista3 += parseFloat(item.valor);
        });
      }

      var comissaoRecepcionista = 0;
      if (elementsRecepcionista.length > 0) {
        elementsRecepcionista.forEach(function(item) {
          comissaoRecepcionista += parseFloat(item.valor);
        });
      }

      var comissaoEsteticista = 0;
      if (elementsEsteticista.length > 0) {
        elementsEsteticista.forEach(function(item) {
          comissaoEsteticista += parseFloat(item.valor);
        });
      }

      comissaoPropretario = (comissaoPropretario / 100) * 2;
      comissaoNutricionista1 = (comissaoNutricionista1 / 100) * 2;
      comissaoNutricionista2 = (comissaoNutricionista2 / 100) * 2;
      comissaoNutricionista3 = (comissaoNutricionista3 / 100) * 2;
      comissaoRecepcionista = (comissaoRecepcionista / 100) * 2;
      comissaoEsteticista = (comissaoEsteticista / 100) * 2;
      comissaoFranquia = (comissaoFranquia / 100) * 2;
      taxaPublicidade = (taxaPublicidade / 100) * 2;
      taxaRoyalts = (taxaRoyalts / 100) * 8;

      comissaoPropretario = Math.round(comissaoPropretario * 100) / 100;
      comissaoNutricionista1 = Math.round(comissaoNutricionista1 * 100) / 100;
      comissaoNutricionista2 = Math.round(comissaoNutricionista2 * 100) / 100;
      comissaoNutricionista3 = Math.round(comissaoNutricionista3 * 100) / 100;
      comissaoRecepcionista = Math.round(comissaoRecepcionista * 100) / 100;
      comissaoEsteticista = Math.round(comissaoEsteticista * 100) / 100;
      comissaoFranquia = Math.round(comissaoFranquia * 100) / 100;
      taxaPublicidade = Math.round(taxaPublicidade * 100) / 100;
      taxaRoyalts = Math.round(taxaRoyalts * 100) / 100;

      this.comissaoPropretario = comissaoPropretario;
      this.comissaoNutricionista1 = comissaoNutricionista1;
      this.comissaoNutricionista2 = comissaoNutricionista2;
      this.comissaoNutricionista3 = comissaoNutricionista3;
      this.comissaoRecepcionista = comissaoRecepcionista;
      this.comissaoEsteticista = comissaoEsteticista;
      this.comissaoFranquia = comissaoFranquia;

      this.taxaSoftware = 160; // 160
      this.taxaPublicidade = Math.max(taxaPublicidade, 600); // 2% elementsVenda, min 600
      this.taxaRoyalts = Math.max(taxaRoyalts, 1200); // 8% elementsVenda, min 1200
      this.taxaSoma = 160 + this.taxaPublicidade + this.taxaRoyalts;

      this.dialogComissoes = true;
    },
    dialogD(element) {
      this.dialogDelement = element;

      this.dialogDshow = true;
    },
    dialogT(element) {
      var caixa = this.caixa;
      var y = element.ano;
      var func = this.niceString;

      var filterM = function(list, c1, c2) {
        return list.filter(function(el) {
          return el.m === c1 && el.y === c2;
        });
      };

      var filledMonthList = this.monthList.map(function(mo) {
        var elementsM = filterM(caixa, mo.m, y);

        var somaNr = 0;
        var faturamento = 0;
        var gastos = 0;
        elementsM.forEach(function(item) {
          somaNr += parseFloat(item.valor);
        });
        elementsM.forEach(function(item) {
          if (item.direction) {
            faturamento += parseFloat(item.valor);
          }
        });
        elementsM.forEach(function(item) {
          if (!item.direction) {
            gastos += parseFloat(item.valor);
          }
        });

        return {
          mes: mo.mes,
          somaNr: func(somaNr),
          faturamento: func(faturamento),
          gastos: func(gastos),
        };
      });

      var elementComplete = {
        ano: element.ano,
        somaNr: element.somaNr,
        monthList: filledMonthList,
      };
      this.dialogTelement = elementComplete;
      this.dialogTshow = true;
    },
    dialogM(element) {
      function filterDm(list, c1, c2, c3) {
        return list.filter(function(el) {
          return el.d === c1 && el.m === c2 && el.y === c3;
        });
      }
      element.elements = filterDm(
        this.caixa,
        element.d,
        element.m,
        element.y
      ).map(function(e) {
        function niceString(valor) {
          var minus = valor < 0;
          var valorAbs = Math.abs(valor);
          var valorKomma;

          var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
          var decimalsStr;
          if (decimals < 1) {
            decimalsStr = "00";
          } else if (decimals < 10) {
            decimalsStr = "0" + decimals.toString();
          } else {
            decimalsStr = decimals.toString();
          }

          var valorGanz = Math.floor(valorAbs);

          if (valorGanz > 1000) {
            var tusiger = Math.floor(valorAbs / 1000);
            var rest = valorGanz % 1000;
            var restStr;
            if (rest < 10) {
              restStr = "00" + rest.toString();
            } else if (rest < 100) {
              restStr = "0" + rest.toString();
            } else {
              restStr = rest.toString();
            }
            valorKomma = tusiger.toString() + "." + restStr;
          } else {
            valorKomma = valorGanz.toString();
          }

          if (minus) {
            return "- R$ " + valorKomma + "," + decimalsStr;
          } else {
            return "R$ " + valorKomma + "," + decimalsStr;
          }
        }

        e.niceValor = niceString(e.valor);
        return e;
      });
      this.dialogMelement = element;
      this.dialogMshow = true;
    },
    colorDirection(dirBool) {
      if (dirBool) {
        return "green";
      } else {
        return "red";
      }
    },
    formaIcon(forma) {
      if (forma === 1) {
        return this.icons.mdiCash100;
      } else if (forma === 2) {
        return this.icons.mdiCreditCardCheckOutline;
      } else if (forma === 3) {
        return this.icons.mdiCreditCardClockOutline;
      } else if (forma === 4) {
        return this.icons.mdiBankTransfer;
      } else if (forma === 5) {
        return this.icons.mdiInfinity;
      } else if (forma === 6) {
        return this.icons.mdiCheckbook;
      } else if (forma === 7) {
        return this.icons.mdiWalletPlusOutline;
      } else if (forma === 8) {
        return this.icons.mdiPiggyBank;
      } else {
        return this.icons.mdiHelpRhombusOutline;
      }
    },
    formaStr(forma) {
      if (forma === 1) {
        return "Dinheiro";
      } else if (forma === 2) {
        return "Cartão de Débito";
      } else if (forma === 3) {
        return "Cartão de Crédito";
      } else if (forma === 4) {
        return "Transferência Bancária";
      } else if (forma === 5) {
        return "PIX";
      } else if (forma === 6) {
        return "Cheque";
      } else if (forma === 7) {
        return "multiplas formas";
      } else if (forma === 8) {
        return "Depósito Bancário";
      } else {
        return "forma de pagamento não especificada";
      }
    },
    openComprovanteDialog(link) {
      this.dialogComprovante = true;
      this.comprovanteLink = link;
    },
    setFlags() {
      this.franqObjs.forEach(function(franqObj) {
        var img;
        if (
          franqObj.email == "umarizalbelem@clubedanutricionista.com.br" ||
          franqObj.email == "umarizal@clubedanutricionista.com.br"
        ) {
          img = require("@/assets/para_flag.png");
        } else if (
          franqObj.email == "bc@clubedanutricionista.com.br" ||
          franqObj.email == "bc2@clubedanutricionista.com.br"
        ) {
          img = require("@/assets/sc_flag.png");
        } else if (franqObj.email == "niteroi@clubedanutricionista.com.br") {
          img = require("@/assets/rj_flag.png");
        } else if (
          franqObj.email == "fortaleza.salinas@clubedanutricionista.com.br"
        ) {
          img = require("@/assets/ceara_flag.png");
        }
        franqObj.flag = img;
      });
    },
  },
  computed: {
    clientStatHeader() {
      var d = new Date();
      var y = d.getFullYear();
      var list = ["mes"];
      for (var x = 2021; x <= y; x++) {
        list.push(x);
      }
      return list.map(function(item) {
        return {
          text: item.toString(),
          value: item.toString(),
        };
      });
    },
    funcInclProprietario() {
      if (
        this.vendedores.includes("Proprietário") ||
        this.vendedores.includes("Proprietária")
      ) {
        return true;
      } else {
        return false;
      }
    },
    funcInclNutricionista1() {
      if (
        this.vendedores.includes("Nutricionista 1") ||
        this.vendedores.includes("Nutricionista")
      ) {
        return true;
      } else {
        return false;
      }
    },
    funcInclNutricionista2() {
      return this.vendedores.includes("Nutricionista 2");
    },
    funcInclNutricionista3() {
      return this.vendedores.includes("Nutricionista 3");
    },
    funcInclRecepcionista() {
      return this.vendedores.includes("Recepcionista");
    },
    funcInclEsteticista() {
      return this.vendedores.includes("Esteticista");
    },
    funcInclEstagiaria() {
      return this.vendedores.includes("Estagiária");
    },
    payback() {
      var investimento = 160000;
      return Math.round((this.faturamentoT / investimento) * 100);
    },
    // setPeriodsD() {
    //   function daysInMonth(year, month) {
    //     return new Date(year, month, 0).getDate();
    //   }
    //   var d = new Date();
    //   var monthNow = d.getMonth() + 1;
    //   var listOfDays = [];
    //   for (var j = 1; j <= daysInMonth(d.getFullYear(), monthNow); j++) {
    //     listOfDays.push(j);
    //   }
    //   return listOfDays;
    // },
    periodsA() {
      var d = new Date();
      var y = d.getFullYear();
      var indexes = [-2, -1, 0, 1, 2];
      return indexes.map(function(yr) {
        return y + yr;
      });
    },
    mesString() {
      var d = new Date();
      var mes = d.getMonth() + 1;
      var monthString;
      if (mes <= 9) {
        monthString = "0" + mes.toString();
      } else {
        monthString = mes.toString();
      }
      return monthString;
    },
  },
  mounted() {
    this.setFlags();
    this.getFaturamentos();
    for (var i = 0; i < this.franqObjs.length; i++) {
      this.countClients(this.franqObjs[i].email);
    }
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
};
</script>
