<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        md="4"
      >
        <v-alert
          dense
          type="error"
          :icon="icons.mdiAlert"
        >Você foi redirecionada á página de Login</v-alert>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        md="4"
      >
        <v-alert
          dense
          colored-border
          elevation="3"
          border="bottom"
          color="#c5d13f"
          type="success"
          :icon="icons.mdiHelpCircle"
        >Já tem cadastro? Faça Login novamente
          <v-row align="center">
            <v-col
              class="text-center"
              cols="12"
            >
              <v-btn
                large
                class="mx-4"
                color="#c5d13f"
                value="login"
                to="/login"
              >
                <span>Login</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        md="4"
      >
        <v-alert
          dense
          colored-border
          elevation="3"
          border="bottom"
          color="#614021"
          type="warning"
          :icon="icons.mdiHelpCircle"
        >Acabou de criar um novo cadastro? Deu erro! Crie novamente.</v-alert>
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="valid"
    >
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              solo
              :prepend-icon="icons.mdiAccount"
              v-model="nome"
              :rules="rules.requiredField"
              label="Nome"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              solo
              :prepend-icon="icons.mdiStore"
              v-model="nomeClinica"
              :rules="rules.requiredField"
              label="Nome da Clínica"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            md="4"
          >
            <v-alert
              v-show="alerts.emailOk"
              dense
              type="error"
              :icon="icons.mdiAlert"
            >Email indisponível</v-alert>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              solo
              :prepend-icon="icons.mdiEmail"
              v-model="email"
              :rules="rules.emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              solo
              :prepend-icon="icons.mdiLock"
              v-model="senha"
              :append-icon="show ? icons.mdiEye : icons.mdiEyeOff"
              :type="show ? 'text' : 'password'"
              :rules="rules.passwordLength"
              label="Senha"
              required
              @click:append="show = !show"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            md="4"
          >
            <v-alert
              v-show="alerts.nomeClinicaExists"
              dense
              type="error"
              :icon="icons.mdiAlert"
            >Nome do site indisponível</v-alert>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="6"
            md="4"
          >
            <v-text-field
              solo
              :prepend-icon="icons.mdiWeb"
              v-model="nomeSite"
              required
              :rules="rules.siteRules"
              label="Nome do Site"
            ></v-text-field>
          </v-col>
          <v-banner
            color="white"
            tile
          >.clubedanutricionista.app.br</v-banner>
        </v-row>
      </v-container>
    </v-form>
    <v-row align="center">
      <v-col
        class="text-center"
        cols="12"
      >
        <v-btn
          large
          color="#614021"
          class="white--text"
          @click="submit"
        >Prosseguir</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiAccount,
  mdiWeb,
  mdiLock,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
  mdiStore,
  mdiAlert,
  mdiHelpCircle,
} from "@mdi/js";

export default {
  name: "redirect",
  data: function () {
    return {
      icons: {
        mdiWeb,
        mdiLock,
        mdiEmail,
        mdiEye,
        mdiEyeOff,
        mdiStore,
        mdiAccount,
        mdiAlert,
        mdiHelpCircle,
      },
      show: false,
      nome: "",
      email: "",
      nomeClinica: "",
      nomeSite: "",
      senha: "",
      valid: false,
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
        passwordLength: [(v) => v.length >= 8 || "Senha com mais que 8 letras"],
        siteRules: [
          (v) => v.length <= 24 || "Nome com menos que 24 letras",
          (v) => !/[^A-Za-z0-9]+/.test(v) || "Use apenas letras e números",
        ],
        emailRules: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+/.test(v) || "E-mail invalido",
        ],
      },
    };
  },
  computed: {
    alerts: function () {
      return this.$store.state.firestore.alerts;
    },
  },
  methods: {
    submit: function () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("firestore/userJoin", {
          email: this.email,
          password: this.senha,
          nomeClinica: this.nomeClinica,
          nomeSite: this.nomeSite,
          nome: this.nome,
        });
      }
    },
  },
};
</script>
