<template>
  <div style="width: 100%" class="d-flex justify-center">
    <!-- this is the operational version  -->
    <v-dialog v-model="scoreDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center text-h5">
          Teste de imunidade concluído!
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center text-h6">
            <v-col> Resultado: {{ getScoreDef(score) }} </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="8" class="d-flex align-center">
              <v-progress-linear
                :value="scorePerc"
                :color="getScoreColor(score)"
              ></v-progress-linear>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-start">
              {{ score }}
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center"> Testes anteriores</v-row>
          <v-row
            class="d-flex justify-center"
            v-for="teste in imunidadeTestesDone"
            :key="teste.id"
          >
            <v-col cols="2" class="text-caption">
              {{ teste.dateStr }}
            </v-col>
            <v-col cols="4" class="d-flex align-center">
              <v-progress-linear
                :value="makePerc(teste.score)"
                :color="getScoreColor(teste.score)"
              ></v-progress-linear>
            </v-col>
            <v-col cols="1" class="d-flex align-center justify-start">
              {{ teste.score }}
            </v-col>
            <v-col cols="5" class="d-flex align-center justify-center">
              <strong>{{ getScoreDef(teste.score) }}</strong>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            dense
            class="my-2 white--text"
            color="#614021"
            @click="scoreDialog = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!savedQuest">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="allScoresDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center text-h5">
          Todos os testes
        </v-card-title>
        <v-card-text>
          <v-row v-for="teste in imunidadeTestesDone" :key="teste.id">
            <v-col cols="2" class="text-caption">
              {{ teste.dateStr }}
            </v-col>
            <v-col cols="4" class="d-flex align-center">
              <v-progress-linear
                :value="makePerc(teste.score)"
                :color="getScoreColor(teste.score)"
              ></v-progress-linear>
            </v-col>
            <v-col cols="1" class="d-flex align-center justify-center">
              {{ teste.score }}
            </v-col>
            <v-col cols="5" class="d-flex align-center justify-center">
              <strong>{{ getScoreDef(teste.score) }}</strong>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dadosErradosDialog" max-width="600">
      <v-card>
        <v-card-title> Erro! </v-card-title>
        <v-card-text class="d-flex justify-center">
          Preencha todos os campos obrigatórios no início do questionário!
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="dadosErradosDialog = false" dense>
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card
      outlined
      class="my-2"
      width="90%"
      v-if="userInfo.preAtendimentoToFill && preAtendimentoQuestShow"
      :style="{ border: '2px solid grey' }"
    >
      <v-card-title class="d-flex justify-center">
        Questionário Pré-Consulta</v-card-title
      >
      <v-card-subtitle class="d-flex justify-center">
        Preencha todas as perguntas abaixo, com o máximo de detalhes para que
        possamos elaborar seu tratamento nutricional.
      </v-card-subtitle>
      <v-stepper vertical v-model="openStep">
        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 1"
          step="1"
          class="d-flex justify-center"
          >Dados gerais</v-stepper-step
        >
        <v-stepper-step-content
          step="1"
          v-if="openStep == 1"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form1" v-model="valid1">
                <!-- <v-row class="d-flex justify-center">
                  <v-badge content="!" color="red">
                    <p class="red--text">Campos obrigatórios!</p>
                  </v-badge>
                </v-row> -->
                <!-- <v-divider></v-divider> -->
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="2"> </v-col>
                  <v-col cols="12" md="6">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiMapMarker"
                      v-model="pacienteInfos.endereco"
                      label="Endereço"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiHomeCityOutline"
                      v-model="pacienteInfos.bairro"
                      label="Bairro"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="2"> </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiMailboxOpenOutline"
                      v-model="pacienteInfos.cep"
                      label="CEP"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiCity"
                      v-model="pacienteInfos.cidade"
                      label="Cidade"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiFlagVariantOutline"
                      v-model="pacienteInfos.estado"
                      label="Estado / País"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="2"> </v-col>
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiAccountDetailsOutline"
                      v-model="pacienteInfos.cpf"
                      label="CPF"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="2"> </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-select
                      :prepend-icon="icons.mdiHandHeart"
                      v-model="pacienteInfos.estadoCivil"
                      :items="estadoCivilItems"
                      label="Estado civil"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiBabyCarriage"
                      v-model="pacienteInfos.filhos"
                      label="Filhos"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiHumanMaleChild"
                      v-model="pacienteInfos.idadeFilhos"
                      label="Idade dos filhos"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.profissao"
                >
                  <v-col cols="12" md="6">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiAccountHardHat"
                      v-model="pacienteInfos.profissao"
                      label="Profissão"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-if="pacIndText"
                      label="Como você ficou sabendo dos nossos serviços?"
                      v-model="pacienteInfos.indicacao"
                      @change="pacIndTextCheck"
                      :rules="indRules"
                    ></v-text-field>
                    <v-select
                      v-else
                      :prepend-icon="icons.mdiAccountVoice"
                      v-model="pacienteInfos.indicacao"
                      label="Como você ficou sabendo dos nossos serviços?"
                      :rules="mandatory"
                      :items="channels"
                      @change="pacIndTextCheck"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStep1">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-step-content>
        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 2"
          step="2"
          class="d-flex justify-center"
          >Física</v-stepper-step
        >
        <v-stepper-step-content
          step="2"
          v-if="openStep == 2"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form2" v-model="valid2">
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-row>
                      <v-col cols="6">
                        <small class="red--text"
                          >Peso em kg. Por exemplo: "80", sem vírgula ou
                          ponto</small
                        >
                      </v-col>
                      <v-col cols="6">
                        <!-- <v-badge content="!" color="red" overlap> -->
                        <v-text-field
                          :prepend-icon="icons.mdiWeightKilogram"
                          v-model="pacienteInfos.pesoAtual"
                          label="peso atual (kg)"
                          :rules="numbersOnly"
                        ></v-text-field>
                        <!-- </v-badge> -->
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-row>
                      <v-col cols="6">
                        <small class="red--text"
                          >Altura em cm. Por exemplo: "177", sem vírgula ou
                          ponto</small
                        >
                      </v-col>
                      <v-col cols="6">
                        <!-- <v-badge content="!" color="red" overlap> -->
                        <v-text-field
                          :prepend-icon="icons.mdiHumanMaleHeightVariant"
                          v-model="pacienteInfos.altura"
                          label="altura (cm)"
                          type="number"
                          :rules="numbersOnly"
                        ></v-text-field>
                        <!-- </v-badge> -->
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-container fluid>
                      <p>Em relação ao seu peso, você se considera...</p>
                      <v-radio-group
                        v-model="pacienteInfos.pesoConsidera"
                        column
                        :rules="mandatory"
                      >
                        <v-radio
                          label="Baixo peso"
                          value="Baixo peso"
                          color="#d7191c"
                        ></v-radio>
                        <v-radio
                          label="Peso normal"
                          value="Peso normal"
                          color="#1a9641"
                        ></v-radio>
                        <v-radio
                          label="Um pouco acima do peso"
                          value="Um pouco acima do peso"
                          color="#fdae61"
                        ></v-radio>
                        <v-radio
                          label="Muito acima do peso"
                          value="Muito acima do peso"
                          color="#d7191c"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStep2">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-step-content>

        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 3"
          step="3"
          class="d-flex justify-center"
          >Saúde</v-stepper-step
        >
        <v-stepper-step-content
          step="3"
          v-if="openStep == 3"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form3" v-model="valid3">
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Descreva detalhadamente todos os seus
                      <strong>
                        problemas de saúde / sinais / sintomas para os quais
                        você deseja tratamento nutricional</strong
                      >.
                    </p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.problemasSaude"
                      label="..."
                      :rules="mandatory"
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.gastrointestinal"
                >
                  <v-container fluid>
                    <p>
                      Você percebe algum
                      <strong>problema gastrointestinal</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.gastrointestinal"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.gastrointestinal"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.gastrointestinalComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.unhasCabelo"
                >
                  <v-container fluid>
                    <p>
                      Você percebe alguma
                      <strong>alteração nas unhas, cabelo ou pele</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.unhasCabelo"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.unhasCabelo"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.unhasCabeloComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.ansiedade"
                >
                  <v-container fluid>
                    <p>
                      Você sofre de
                      <strong>ansiedade, depressão, sindrome do pânico</strong>
                      ou qualquer transtorno psiquiátrico?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.ansiedade"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.ansiedade"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.ansiedadeComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.imunidade"
                >
                  <v-container fluid>
                    <p>
                      Você percebe que sua <strong>imunidade é baixa</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.imunidade"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.imunidade"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.imunidadeComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStep3">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-step-content>

        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 4"
          step="4"
          class="d-flex justify-center"
          >Estresse</v-stepper-step
        >
        <v-stepper-step-content
          step="4"
          v-if="openStep == 4"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form4" v-model="valid4">
                <v-row class="d-flex justify-center" v-if="pacienteInfos.sono">
                  <v-container fluid>
                    <p>
                      Você percebe alguma <strong>alteração no sono</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.sono"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.sono"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.sonoComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.estressada"
                >
                  <v-container fluid>
                    <p>
                      Você se acha uma
                      <strong>pessoa muita estressada</strong> ou tem uma rotina
                      muito estressante?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.estressada"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.estressada"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.estressadaComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStep4">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions></v-card
          >
        </v-stepper-step-content>

        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 5"
          step="5"
          class="d-flex justify-center"
          >Dietas anteriores</v-stepper-step
        >
        <v-stepper-step-content
          step="5"
          v-if="openStep == 5"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form5" v-model="valid5">
                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.outraDieta"
                >
                  <v-container fluid>
                    <p>
                      Você já tentou fazer <strong>outras dietas</strong> com
                      alguma nutricionista antes?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.outraDieta"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.outraDieta"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.outraDietaComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você faz uso de algum
                      <strong>
                        suplemento, proteico, polivitamínico ou remédio
                        natural</strong
                      >? Se sim, quais?
                    </p>
                    <v-text-field
                      solo
                      v-model="pacienteInfos.suplemento"
                      label="..."
                    >
                    </v-text-field>
                  </v-container>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStep5">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions></v-card
          >
        </v-stepper-step-content>

        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 6"
          step="6"
          class="d-flex justify-center"
          >Medicação e diagnósticos</v-stepper-step
        >
        <v-stepper-step-content
          step="6"
          v-if="openStep == 6"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form6" v-model="valid6">
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você toma algum <strong>medicamento</strong>? Descreva-o
                      nome e as doses.
                    </p>
                    <v-text-field
                      solo
                      v-model="pacienteInfos.medicamento"
                      label="..."
                      :rules="mandatory"
                    >
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você fez <strong>exames de sangue</strong> recentemente ou
                      tem
                      <strong
                        >histórico de alguma alteração / deficiência</strong
                      >? Descreva o que for relevante para seu tratamento
                      nutricional.
                    </p>
                    <v-text-field
                      solo
                      v-model="pacienteInfos.sangue"
                      label="..."
                    >
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você fez algum
                      <strong>exame de imagem recentemente</strong>? Foi
                      encontrado algo fora da normalidade no laudo? Comente.
                    </p>
                    <v-text-field
                      solo
                      v-model="pacienteInfos.imagem"
                      label="..."
                    >
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você percebe ou já foi diagnosticada com alguma
                      <strong>alergia ou intolerância alimentar</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Glúten"
                      label="Glúten"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Leite de vaca"
                      label="Leite de vaca"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Lactose"
                      label="Lactose"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Frutos do mar"
                      label="Frutos do mar"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Ovos"
                      label="Ovos"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Abacaxi"
                      label="Abacaxi"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Castanhas"
                      label="Castanhas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-text-field
                      v-model="pacienteInfos.alergiaOutro"
                      label="Outros?"
                    ></v-text-field>
                  </v-container>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStep6">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions></v-card
          >
        </v-stepper-step-content>
        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 7"
          step="7"
          class="d-flex justify-center"
          >Objetivo</v-stepper-step
        >
        <v-stepper-step-content
          step="7"
          v-if="openStep == 7"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form7" v-model="valid7">
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      <strong>Que resultado no seu corpo</strong> você deseja
                      obter <strong>após seguir</strong> nossas orientações
                      nutricionais? Liste todas as suas expectativas quanto ao
                      tratamento.
                    </p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.resultado"
                      label="..."
                      :rules="mandatory"
                    >
                    </v-textarea>
                  </v-container>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStep7">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions></v-card
          >
        </v-stepper-step-content>

        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 8"
          step="8"
          class="d-flex justify-center"
          >Rotína alimentar</v-stepper-step
        >
        <v-stepper-step-content
          step="8"
          v-if="openStep == 8"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form8" v-model="valid8">
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Descreva os <strong>horários e os locais</strong> em que
                      você normalmente faz <strong>suas refeições</strong>.
                    </p>
                    <p class="grey--text darken-3">
                      Exemplo: Café da manhã às 7h - em casa, Lanche da manhã às
                      10h - na escola, Almoço às 13h - no restaurante self
                      service, Lanche da tarde - não faço, Jantar às 19h - peço
                      delivery em casa, Ceia às 23h - em casa, etc.
                    </p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.refeicoesHorarios"
                      label="..."
                      :rules="mandatory"
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>Você gosta de cozinhar?</p>
                      <v-radio-group
                        v-model="pacienteInfos.gostaCozinhar"
                        column
                        :rules="mandatory"
                      >
                        <v-radio
                          label="Sim, gosto"
                          value="Sim"
                          color="#c5d13f"
                        ></v-radio>
                        <v-radio
                          label="Não, não gosto"
                          value="Não"
                          color="#614021"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>Tem alguém que cozinha para você?</p>
                      <v-radio-group
                        v-model="pacienteInfos.outroCozinhar"
                        column
                        :rules="mandatory"
                      >
                        <v-radio
                          label="Sim, outra pessoa cozinha para mim"
                          value="Sim"
                          color="#c5d13f"
                        ></v-radio>
                        <v-radio
                          label="Não, eu mesmo cozinho"
                          value="Não"
                          color="#614021"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStep8">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions></v-card
          >
        </v-stepper-step-content>

        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 9"
          step="9"
          class="d-flex justify-center"
          >Rotína de atividade física</v-stepper-step
        >
        <v-stepper-step-content
          step="9"
          v-if="openStep == 9"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form9" v-model="valid9">
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="3">
                    <v-container fluid>
                      <p>Faz atividade física?</p>
                      <v-radio-group
                        v-model="pacienteInfos.fazAtividade"
                        column
                      >
                        <v-radio
                          label="Sim, faço"
                          value="Sim"
                          color="#c5d13f"
                        ></v-radio>
                        <v-radio
                          label="Não, não faço"
                          value="Não"
                          color="#614021"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-container fluid>
                      <p>
                        Quais <strong>dias e horários</strong> você faz ou
                        planeja fazer <strong>atividade física</strong>?
                        <br />Escreva em detalhes para que possamos ajustar o
                        cardápio de acordo com o tipo e horário do treino.
                      </p>
                      <v-textarea
                        solo
                        v-model="pacienteInfos.atividadeDetalhe"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-textarea>
                    </v-container>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStep9">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions></v-card
          >
        </v-stepper-step-content>
        <v-stepper-step
          color="#c5d13f"
          :complete="openStep > 10"
          step="10"
          class="d-flex justify-center"
          >Preferências alimentares</v-stepper-step
        >
        <v-stepper-step-content
          step="10"
          v-if="openStep == 10"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="form10" v-model="valid10">
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Sobre o <strong>café da manhã</strong>: Gosta apenas de
                      café, café com leite ou tolera sucos e vitaminas?
                    </p>
                    <v-text-field
                      solo
                      v-model="pacienteInfos.cafe"
                      label="..."
                      :rules="mandatory"
                    >
                    </v-text-field>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Gosta de comer frutas no café da manhã? Quais?</p>
                    <v-text-field
                      solo
                      v-model="pacienteInfos.cafeFrutas"
                      label="..."
                      :rules="mandatory"
                    >
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Quais destes alimentos que você tolera no café da manhã ?
                      Marque as opções abaixo:
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Pão Integral"
                      label="Pão Integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Tapioquinha com ovo; queijo ou coco"
                      label="Tapioquinha com ovo; queijo ou coco"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Torrada integral"
                      label="Torrada integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Preparações como panquecas proteicas"
                      label="Preparações como panquecas proteicas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Wrap integral"
                      label="Wrap integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Batata doce"
                      label="Batata doce"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Macaxeira cozidas"
                      label="Macaxeira cozidas"
                      color="#c5d13f"
                    ></v-checkbox>
                  </v-container>
                </v-row>

                <v-divider></v-divider>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Nos <strong>lanches</strong>, quais das opções abaixo
                      podem ser incluídas no seu cardápio?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Frutas"
                      label="Frutas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Castanhas"
                      label="Castanhas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Pasta de amendoim"
                      label="Pasta de amendoim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Iogurte natural"
                      label="Iogurte natural"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Sementes como chia / linhaça"
                      label="Sementes como chia / linhaça"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Pão integral"
                      label="Pão integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Patê natural de frango ou atum"
                      label="Patê natural de frango ou atum"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Bolos ou bolachas integrais"
                      label="Bolos ou bolachas integrais"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Torrada integral"
                      label="Torrada integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Ovos mexidos ou cozidos"
                      label="Ovos mexidos ou cozidos"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Tapioquinha"
                      label="Tapioquinha"
                      color="#c5d13f"
                    ></v-checkbox>
                  </v-container>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>Que <strong>frutas</strong> você mais gosta?</p>
                      <v-text-field
                        v-model="pacienteInfos.frutasGostar"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Que <strong>frutas</strong> você
                        <strong>não</strong> tolera?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.frutasNaoTolerar"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-col cols="12">
                    <v-row class="d-flex justify-center">
                      <p>Sobre o <strong>almoço</strong>:</p>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Gosta do tradicional
                        <strong>arroz/ farinha/ farofa/ feijão</strong>?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.almocoFeijao"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Gosta de <strong>saladas</strong> cruas, refogadas e
                        cozidas?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.almocoSalada"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Tem <strong>aversão</strong> por algum
                        <strong>vegetal</strong>? Quais?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.aversaoVegetal"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Gosta de outras leguminosas como grão de bico, lentilha
                        e/ou ervilha?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.leguminosas"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Costuma comer
                        <strong>purês, quinoa e/ou raízes</strong> como batata
                        doce e macaxeira?
                      </p>
                      <v-text-field
                        solo
                        v-model="pacienteInfos.pures"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Gosta de <strong>massas</strong> tipo macarronada,
                        lasanha etc?
                      </p>
                      <v-text-field
                        solo
                        v-model="pacienteInfos.massas"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row>
                  <v-container fluid>
                    <p>Gosta de frango, carne vermelha e peixe?</p>
                    <v-text-field
                      v-model="pacienteInfos.frangoCarnePeixe"
                      label="..."
                      :rules="mandatory"
                    >
                    </v-text-field>
                  </v-container>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-col cols="12">
                    <v-row class="d-flex justify-center">
                      <p>Sobre o <strong>jantar</strong>:</p>
                    </v-row>
                  </v-col>
                  <v-container fluid>
                    <p>
                      Gosta de comer
                      <strong>o mesmo do almoço ou lanches</strong>? Ou os dois?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.jantar"
                      value="O mesmo do almoço"
                      label="O mesmo do almoço"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.jantar"
                      value="Somente lanche"
                      label="Somente lanche"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.jantar"
                      value="Gosto de sopas"
                      label="Gosto de sopas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.jantar"
                      value="Não como nada na hora do jantar"
                      label="Não como nada na hora do jantar"
                      color="#c5d13f"
                    ></v-checkbox>
                  </v-container>
                </v-row>
                <v-row>
                  <v-container fluid>
                    <p>
                      Sobre a <strong>ceia</strong>: o que normalmente você come
                      antes de dormir?
                    </p>
                    <v-text-field
                      v-model="pacienteInfos.ceia"
                      label="..."
                      :rules="mandatory"
                    >
                    </v-text-field>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-alert type="error" v-if="checkStep10false"
                    >Um campo obrigatório não preenchido!</v-alert
                  >
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn large color="#c5d13f" class="my-6" @click="checkStep10">
                Concluir
              </v-btn>
            </v-card-actions></v-card
          >
        </v-stepper-step-content>

        <!-- <v-row class="d-flex justify-center">
          <v-btn large color="#c5d13f" class="my-6" @click="submit">
            Concluir
          </v-btn>
        </v-row> -->
      </v-stepper>
    </v-card>

    <v-card
      outlined
      class="my-2"
      width="90%"
      v-if="userInfo.estetToFill"
      :style="{ border: '2px solid grey' }"
    >
      <v-card-title class="d-flex justify-center">
        Questionário Estético</v-card-title
      >
      <v-card-subtitle class="d-flex justify-center">
        Preencha todas as perguntas abaixo, com o máximo de detalhes.
      </v-card-subtitle>
      <v-stepper vertical v-model="openStep">
        <v-stepper-step
          color="#c5d13f"
          :complete="openStepE > 1"
          step="1"
          class="d-flex justify-center"
          >Dados gerais</v-stepper-step
        >
        <v-stepper-step-content
          step="1"
          v-if="openStepE == 1"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="formE1" v-model="validE1">
                <!-- <v-row class="d-flex justify-center">
                  <v-badge content="!" color="red">
                    <p class="red--text">Campos obrigatórios!</p>
                  </v-badge>
                </v-row> -->
                <!-- <v-divider></v-divider> -->
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="2"> </v-col>
                  <v-col cols="12" md="6">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiMapMarker"
                      v-model="pacienteInfos.endereco"
                      label="Endereço"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiHomeCityOutline"
                      v-model="pacienteInfos.bairro"
                      label="Bairro"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="2"> </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiMailboxOpenOutline"
                      v-model="pacienteInfos.cep"
                      label="CEP"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiCity"
                      v-model="pacienteInfos.cidade"
                      label="Cidade"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiFlagVariantOutline"
                      v-model="pacienteInfos.estado"
                      label="Estado / País"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="2"> </v-col>
                  <v-col cols="12" md="4">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiAccountDetailsOutline"
                      v-model="pacienteInfos.cpf"
                      label="CPF"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                  <v-col cols="12" md="2"> </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-select
                      :prepend-icon="icons.mdiHandHeart"
                      v-model="pacienteInfos.estadoCivil"
                      :items="estadoCivilItems"
                      label="Estado civil"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiBabyCarriage"
                      v-model="pacienteInfos.filhos"
                      label="Filhos"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiHumanMaleChild"
                      v-model="pacienteInfos.idadeFilhos"
                      label="Idade dos filhos"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.profissao"
                >
                  <v-col cols="12" md="6">
                    <!-- <v-badge content="!" color="red" overlap> -->
                    <v-text-field
                      :prepend-icon="icons.mdiAccountHardHat"
                      v-model="pacienteInfos.profissao"
                      label="Profissão"
                      :rules="mandatory"
                    ></v-text-field>
                    <!-- </v-badge> -->
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-if="pacIndText"
                      label="Como você ficou sabendo dos nossos serviços?"
                      v-model="pacienteInfos.indicacao"
                      @change="pacIndTextCheck"
                      :rules="indRules"
                    ></v-text-field>
                    <v-select
                      v-else
                      :prepend-icon="icons.mdiAccountVoice"
                      v-model="pacienteInfos.indicacao"
                      label="Como você ficou sabendo dos nossos serviços?"
                      :rules="mandatory"
                      :items="channels"
                      @change="pacIndTextCheck"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="#c5d13f" @click="checkStepE1">
                <v-icon left color="#614021">mdi-chevron-right</v-icon
                >Próximo</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-step-content>
        <v-stepper-step
          color="#c5d13f"
          :complete="openStepE > 2"
          step="10"
          class="d-flex justify-center"
          >Contra-indicações</v-stepper-step
        >
        <v-stepper-step-content
          step="10"
          v-if="openStepE == 2"
          class="d-flex justify-center"
        >
          <v-card outlined width="90%">
            <v-card-text class="d-flex justify-center">
              <v-form ref="formE2" v-model="validE2">
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Os nossos tratamentos estéticos são indolores, não
                      invasivos e com resultados visíveis logo nas primeiras
                      sessões. Porém, não podem ser feitos em certas condições.
                      Responda abaixo, se você sofra de algumas das condições
                      listadas:
                    </p>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Implante metálico no corpo</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet9"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Uma doença infecto-contagiosa</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet10"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Febre, inflamação, dermatites (eczema, herpes)</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet1"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Alterações da Tiróide</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet2"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Feridas abertas ou suturadas recentemente</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet3"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Doenças graves ou progressivas (cancro, sida, hepatite,
                      doenças sanguíneas, etc)
                    </p>
                    <v-radio-group
                      v-model="pacienteInfos.estet4"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Problemas de coagulação sanguínea</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet5"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Cirurgias vasculares nos últimos 2 meses</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet6"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Uso prolongado de medicamentos do tipo anti-inflamatórios,
                      anticoagulantes, antibióticos
                    </p>
                    <v-radio-group
                      v-model="pacienteInfos.estet7"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Gravidez</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet8"
                      column
                      :rules="mandatory"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#c5d13f"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#614021"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-alert type="error" v-if="checkStepE2false"
                    >Um campo obrigatório não preenchido!</v-alert
                  >
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn large color="#c5d13f" class="my-6" @click="checkStepE2">
                Concluir
              </v-btn>
            </v-card-actions></v-card
          >
        </v-stepper-step-content>
      </v-stepper>
    </v-card>

    <v-row class="d-flex justify-center" v-if="userInfo.imunidadeToFill"
      >Preencha a "Anamnese" e o "Teste de imunidade" sempre antes de cada
      consulta
    </v-row>
    <v-row class="d-flex justify-center my-3" v-if="userInfo.imunidadeToFill"
      ><small>Para terminar clique em "Concluir"</small></v-row
    >

    <div v-if="showImunidade">
      <v-expansion-panels v-if="userInfo.imunidadeToFill">
        <v-expansion-panel v-model="imunidadePanelOpen1">
          <v-expansion-panel-header color="grey lighten-3">
            <v-row class="d-flex justify-space-between text-h5 my-2"
              >Anamnese
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey lighten-3">
            <v-form
              class="grey lighten-3"
              ref="formAnamneseImunidade"
              v-model="validAnamneseImunidade"
            >
              <v-container>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="2"> </v-col>
                  <v-col cols="12" md="6">
                    <v-badge content="!" color="red" overlap>
                      <v-text-field
                        :prepend-icon="icons.mdiMapMarker"
                        v-model="imunidadeInfos.endereco"
                        label="Endereço"
                        :rules="mandatory"
                      ></v-text-field>
                    </v-badge>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-badge content="!" color="red" overlap>
                      <v-text-field
                        :prepend-icon="icons.mdiHomeCityOutline"
                        v-model="imunidadeInfos.bairro"
                        label="Bairro"
                        :rules="mandatory"
                      ></v-text-field>
                    </v-badge>
                  </v-col>
                  <v-col cols="12" md="2"> </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-badge content="!" color="red" overlap>
                      <v-text-field
                        :prepend-icon="icons.mdiMailboxOpenOutline"
                        v-model="imunidadeInfos.cep"
                        label="CEP"
                        :rules="mandatory"
                      ></v-text-field>
                    </v-badge>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-badge content="!" color="red" overlap>
                      <v-text-field
                        :prepend-icon="icons.mdiCity"
                        v-model="imunidadeInfos.cidade"
                        label="Cidade"
                        :rules="mandatory"
                      ></v-text-field>
                    </v-badge>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-badge content="!" color="red" overlap>
                      <v-text-field
                        :prepend-icon="icons.mdiFlagVariantOutline"
                        v-model="imunidadeInfos.estado"
                        label="Estado / País"
                        :rules="mandatory"
                      ></v-text-field>
                    </v-badge>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="2"> </v-col>
                  <v-col cols="12" md="4">
                    <v-badge content="!" color="red" overlap>
                      <v-text-field
                        :prepend-icon="icons.mdiAccountDetailsOutline"
                        v-model="imunidadeInfos.cpf"
                        label="CPF"
                        :rules="mandatory"
                      ></v-text-field>
                    </v-badge>
                  </v-col>
                  <v-col cols="12" md="2"> </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-select
                      :prepend-icon="icons.mdiHandHeart"
                      v-model="imunidadeInfos.estadoCivil"
                      :items="estadoCivilItems"
                      label="Estado civil"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiBabyCarriage"
                      v-model="imunidadeInfos.filhos"
                      label="Filhos"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiHumanMaleChild"
                      v-model="imunidadeInfos.idadeFilhos"
                      label="Idade dos filhos"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-badge content="!" color="red" overlap>
                      <v-text-field
                        :prepend-icon="icons.mdiAccountHardHat"
                        v-model="imunidadeInfos.profissao"
                        label="Profissão"
                        :rules="mandatory"
                      ></v-text-field>
                    </v-badge>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row v-if="userInfo.imunidadeToFill">
                  <v-col cols="12">
                    <v-container fluid>
                      <p>Opções de atendimento:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.opcoesAtendimento"
                        row
                      >
                        <v-radio
                          label="Profilaxia de Covid"
                          value="Profilaxia de Covid"
                          color="green"
                        ></v-radio>
                        <v-radio
                          label="Tratamento de Covid"
                          value="Tratamento de Covid"
                          color="red"
                        ></v-radio>
                        <v-radio
                          label="Tratamento Pós Covid"
                          value="Tratamento Pós Covid"
                          color="blue"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-row class="d-flex justify-center"> Anamnese Covid-19:</v-row>
                <v-row class="d-flex justify-center my-3">
                  <small>Preencha todos os campos seguidos</small></v-row
                >

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Assimtomático:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.assimtomatico"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Dor de cabeça:</p>
                      <v-radio-group v-model="imunidadeInfos.viroses" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Dispnéia (falta de ar):</p>
                      <v-radio-group v-model="imunidadeInfos.aftas" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center align-center">
                  <v-col cols="12" md="2" class="d-flex align-center">
                    <v-container fluid>
                      <p>Febre:</p>
                      <v-radio-group v-model="imunidadeInfos.febre" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex align-center">
                    <v-container fluid>
                      <p>Tosse:</p>
                      <v-radio-group v-model="imunidadeInfos.tosse" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex align-center">
                    <v-container fluid>
                      <p>Outros:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.outrosSintomas"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6" class="d-flex align-center">
                    <v-container fluid>
                      <v-text-field
                        :disabled="imunidadeInfos.outrosSintomas !== 'Sim'"
                        v-model="imunidadeInfos.outrosSintomasComment"
                      ></v-text-field>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center align-center">
                  <v-container fluid>
                    <p>Data do início de sintomas.</p>
                    <v-text-field
                      solo
                      v-model="imunidadeInfos.dataSintomas"
                      label="..."
                    >
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-divider></v-divider>
                <v-row class="d-flex justify-center my-3"
                  >Doenças e condições preexistentes</v-row
                >
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Doenças respiratórias crônicas descompensadas:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.doencasResp"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Doenças cardíacas crônicas:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.doencasCard"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Diabetes:</p>
                      <v-radio-group v-model="imunidadeInfos.diabetes1" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Doenças renais crônicas em estado avançado:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.doencasRenais"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Imunosupressão:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.imunosupressao"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Gestante de alto risco:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.gestanteRisco"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Portador de doenças cromossômicas ou estado de
                        fragilidade imunológica:
                      </p>
                      <v-radio-group v-model="imunidadeInfos.cromoImuno" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Teve contato com paciente confirmado ou suspeito de
                        Covid-19:
                      </p>
                      <v-radio-group v-model="imunidadeInfos.contato" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>É profissional da área de saúde:</p>
                      <v-radio-group v-model="imunidadeInfos.areaSaude" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Descreva suas doenças pré-existentes:</p>
                      <v-badge content="!" color="red" overlap>
                        <v-textarea
                          solo
                          v-model="imunidadeInfos.preexistentes"
                          label="..."
                          :rules="mandatory"
                        >
                        </v-textarea>
                      </v-badge>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Descreva seus sintomas atuais (se houver):</p>
                      <v-badge content="!" color="red" overlap>
                        <v-textarea
                          solo
                          v-model="imunidadeInfos.sintomas"
                          label="..."
                          :rules="mandatory"
                        >
                        </v-textarea>
                      </v-badge>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12">
                    Anexe seus exames de sangue ou tomografia:
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1">
                  <v-col cols="12">
                    <v-file-input
                      accept="document/*"
                      label="Exame 1"
                      @change="handleExam1"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1">
                  <v-col cols="12">
                    <v-file-input
                      accept="document/*"
                      label="Exame 2"
                      @change="handleExam2"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1">
                  <v-col cols="12">
                    <v-file-input
                      accept="document/*"
                      label="Exame 3"
                      @change="handleExam3"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Descreva sua lista de medicamentos atuais:</p>
                      <v-badge content="!" color="red" overlap>
                        <v-textarea
                          solo
                          v-model="imunidadeInfos.medicamentos"
                          label="..."
                          :rules="mandatory"
                        >
                        </v-textarea>
                      </v-badge>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Caso esteja tomando algum suplemento, descreva cada
                        nutriente e sua dosagem diária:
                      </p>
                      <v-badge content="!" color="red" overlap>
                        <v-textarea
                          solo
                          v-model="imunidadeInfos.suplemento"
                          label="..."
                          :rules="mandatory"
                        >
                        </v-textarea>
                      </v-badge>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Alergias ou intolerâncias alimentares? Descreva:</p>
                      <v-badge content="!" color="red" overlap>
                        <v-textarea
                          solo
                          v-model="imunidadeInfos.alergiaComment"
                          label="..."
                          :rules="mandatory"
                        >
                        </v-textarea>
                      </v-badge>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Descreva todas as substâncias que você é alérgico ou que
                        de alguma forma lhe faz mal:
                      </p>
                      <v-badge content="!" color="red" overlap>
                        <v-textarea
                          solo
                          v-model="imunidadeInfos.substanciasAlergico"
                          label="..."
                          :rules="mandatory"
                        >
                        </v-textarea>
                      </v-badge>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center align-center">
                  <v-icon color="blue" class="mx-2">mdi-arrow-down-bold</v-icon>
                  Continue agora com o Teste de Imunidade abaixo
                  <v-icon color="blue" class="mx-2">mdi-arrow-down-bold</v-icon>
                </v-row>
              </v-container>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels v-if="userInfo.imunidadeToFill">
        <v-expansion-panel v-model="imunidadePanelOpen">
          <v-expansion-panel-header color="grey lighten-3">
            <v-row class="text-h5 my-4">Teste sua imunidade</v-row>
            <v-row
              v-if="imunidadeTestesDoneLength"
              class="d-flex justify-center text-caption"
            >
              último teste: {{ userInfo.imunidadeTesteDate }} ({{
                userInfo.imunidadeTestScore
              }})
            </v-row>
            <v-btn
              small
              dense
              color="grey lighten-2"
              @click="allScoresDialog = true"
            >
              Ver resultados
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey lighten-3">
            <v-form
              ref="formImunidade"
              v-model="validImunidade"
              class="grey lighten-3"
            >
              <v-container>
                <v-row
                  v-if="userInfo.imunidadeToFill"
                  class="d-flex justify-center my-6 text-subtitle-1"
                >
                  Será que sua imunidade está boa? Responda as perguntas abaixo:
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-row>
                      <v-col cols="6" class="red--text">
                        <small
                          >Peso em kg. Por exemplo: "80", sem vírgula ou
                          ponto</small
                        >
                      </v-col>
                      <v-col cols="6">
                        <v-badge content="!" color="red" overlap>
                          <v-text-field
                            :prepend-icon="icons.mdiWeightKilogram"
                            type="number"
                            v-model="imunidadeInfos.pesoAtual"
                            label="peso atual (kg)?"
                            required
                            :rules="numbersOnly"
                          ></v-text-field>
                        </v-badge>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-row>
                      <v-col cols="6" class="red--text">
                        <small
                          >Altura em cm. Por exemplo: "177", sem vírgula ou
                          ponto</small
                        >
                      </v-col>
                      <v-col cols="6">
                        <v-badge content="!" color="red" overlap>
                          <v-text-field
                            :prepend-icon="icons.mdiHumanMaleHeightVariant"
                            v-model="imunidadeInfos.altura"
                            type="number"
                            label="altura (cm)"
                            required
                            :rules="numbersOnly"
                          ></v-text-field>
                        </v-badge>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex justify-center text-h6">
                    IMC: {{ bmi }}
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Gripes ou resfriados frequentes:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.gripesFrequentes"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Viroses constantes:</p>
                      <v-radio-group v-model="imunidadeInfos.viroses" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Aftas na boca:</p>
                      <v-radio-group v-model="imunidadeInfos.aftas" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Infecção urinária de repetição:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.infeccaoUrinaria"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Amigdalite ou herpes recorrente:</p>
                      <v-radio-group v-model="imunidadeInfos.amigdalite" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Doenças simples, mas que demoram a passar ou que agravam
                        facilmente:
                      </p>
                      <v-radio-group
                        v-model="imunidadeInfos.doencasDemoram"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Febre frequente e calafrios:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.febresCalafrios"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Olhos frequentemente secos:</p>
                      <v-radio-group v-model="imunidadeInfos.olhos" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Cansaço excessivo:</p>
                      <v-radio-group v-model="imunidadeInfos.cansaco" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Náuseas e vômitos:</p>
                      <v-radio-group v-model="imunidadeInfos.nauseas" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Diarreia:</p>
                      <v-radio-group v-model="imunidadeInfos.diarreia" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Intestino constipado:</p>
                      <v-radio-group v-model="imunidadeInfos.constipado" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Candidíase de repetição</p>
                      <v-radio-group v-model="imunidadeInfos.candidiase" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Manchas vermelhas ou brancas na pele:</p>
                      <v-radio-group v-model="imunidadeInfos.manchas" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Queda acentuada de cabelo ou cabelos quebradiços:</p>
                      <v-radio-group v-model="imunidadeInfos.cabelo" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Unhas fracas, fofas, com listras, amareladas ou
                        esbranquiçadas:
                      </p>
                      <v-radio-group v-model="imunidadeInfos.unhas" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Insônia ou sono ruim:</p>
                      <v-radio-group v-model="imunidadeInfos.insonia" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Acorda se sentindo cansado?</p>
                      <v-radio-group v-model="imunidadeInfos.acorda" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Alto nível de estresse no dia a dia:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.nivelEstresse"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Crises de ansiedade / pânico:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.criseAnsiedade"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Sofre de depressão?</p>
                      <v-radio-group v-model="imunidadeInfos.depressao" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Diabetes, pré-diabetes ou resistência a insulina:</p>
                      <v-radio-group v-model="imunidadeInfos.diabetes" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Toma remédio para pressão alta?</p>
                      <v-radio-group
                        v-model="imunidadeInfos.pressaoAlta"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Sofre de alguma doença auto imune?</p>
                      <v-radio-group v-model="imunidadeInfos.autoImune" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Alergias ou intolerâncias alimentares:</p>
                      <v-radio-group v-model="imunidadeInfos.alergias" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>

            <v-row class="d-flex justify-center">
              <v-btn large color="#c5d13f" class="my-6" @click="submit">
                Concluir
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import {
  mdiAccount,
  mdiWeb,
  mdiLock,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
  mdiStore,
  mdiAlert,
  mdiCalendar,
  mdiPhone,
  mdiWhatsapp,
  mdiMapMarker,
  mdiCity,
  mdiHomeCityOutline,
  mdiMailboxOpenOutline,
  mdiAccountDetailsOutline,
  mdiFlagVariantOutline,
  mdiPiggyBank,
  mdiHandHeart,
  mdiBabyCarriage,
  mdiHumanMaleChild,
  mdiHospitalBuilding,
  mdiAccountVoice,
  mdiWeightKilogram,
  mdiHumanMaleHeightVariant,
  mdiWeightLifter,
  mdiAccountHardHat,
} from "@mdi/js";

export default {
  data: function() {
    return {
      channels: [
        "Vi um anúncio no Facebook",
        "Vi um anúncio no Instagram",
        "Já seguia no Instagram",
        "Pesquisei no Google",
        "Recebi uma indicação",
        "Já conhecia",
        "Outro",
      ],
      pacIndText: false,
      openStep: 1,
      openStepE: 1,
      valid1: false,
      validE1: false,
      valid2: false,
      validE2: false,
      valid3: false,
      valid4: true,
      valid5: true,
      valid6: false,
      valid7: false,
      valid8: false,
      valid9: false,
      valid10: false,
      checkStep10false: false,
      checkStepE2false: false,
      progressDialog: false,
      savedQuest: false,
      mandatory: [(v) => !!v || "Campo obrigatório"],
      indRules: [
        (v) => !!v || "Campo obrigatório",
        (v) => v !== "Outro" || "Descreva brevemente, por favor!",
      ],
      numbersOnly: [
        (v) => !/[^0-9]+/.test(v) || "Apenas números",
        (v) => !!v || "Campo obrigatório",
        (v) => v !== "" || "Campo obrigatório",
      ],
      // numbersOnly: [(v) => !!v || "Apenas números"],
      dadosErradosDialog: false,
      validAnamneseImunidade: true,
      saved: false,
      showImunidade: true,
      imunidadePanelOpen: [],
      imunidadePanelOpen1: [],
      valid: true,
      validImunidade: true,
      scoreDialog: false,
      allScoresDialog: false,
      exam1: null,
      exam2: null,
      exam3: null,
      estadoCivilItems: [
        "-",
        "Solteira/Solteiro",
        "Casada/Casado",
        "Viúva/Viúvo",
        "Divorciada/Divorciado",
      ],
      icons: {
        mdiWeb,
        mdiLock,
        mdiEmail,
        mdiEye,
        mdiEyeOff,
        mdiStore,
        mdiAccount,
        mdiAlert,
        mdiCalendar,
        mdiPhone,
        mdiWhatsapp,
        mdiMapMarker,
        mdiCity,
        mdiHomeCityOutline,
        mdiMailboxOpenOutline,
        mdiAccountDetailsOutline,
        mdiFlagVariantOutline,
        mdiPiggyBank,
        mdiHospitalBuilding,
        mdiHandHeart,
        mdiBabyCarriage,
        mdiHumanMaleChild,
        mdiAccountVoice,
        mdiWeightKilogram,
        mdiHumanMaleHeightVariant,
        mdiWeightLifter,
        mdiAccountHardHat,
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    imunidadeInfos() {
      return this.$store.state.firestore.imunidadeInfos;
    },
    pacienteInfos() {
      return this.$store.state.firestore.pacienteInfos;
    },
    preAtendimentoQuestShow() {
      return this.$store.state.firestore.preAtendimentoToFill;
    },
    imunidadeTestesDone() {
      return this.$store.state.firestore.imunidadeTestesDone;
    },
    imunidadeTestesDoneLength() {
      return this.imunidadeTestesDone.length > 0;
    },
    bmi() {
      var bmi =
        this.imunidadeInfos.pesoAtual /
        ((this.imunidadeInfos.altura * this.imunidadeInfos.altura) / 10000);
      return Math.round(bmi * 100) / 100;
    },
    score() {
      var scoreImundidade = 0;

      var alergias_points = this.imunidadeInfos.alergias == "Sim" ? 1 : 0;
      var autoImune_points = this.imunidadeInfos.autoImune == "Sim" ? 2 : 0;
      var pressaoAlta_points = this.imunidadeInfos.pressaoAlta == "Sim" ? 1 : 0;
      var diabetes_points = this.imunidadeInfos.diabetes == "Sim" ? 2 : 0;
      var depressao_points = this.imunidadeInfos.depressao == "Sim" ? 1 : 0;
      var criseAnsiedade_points =
        this.imunidadeInfos.criseAnsiedade == "Sim" ? 1 : 0;
      var nivelEstresse_points =
        this.imunidadeInfos.nivelEstresse == "Sim" ? 2 : 0;
      var acorda_points = this.imunidadeInfos.acorda == "Sim" ? 1 : 0;
      var insonia_points = this.imunidadeInfos.insonia == "Sim" ? 2 : 0;
      var unhas_points = this.imunidadeInfos.unhas == "Sim" ? 1 : 0;
      var cabelo_points = this.imunidadeInfos.cabelo == "Sim" ? 1 : 0;
      var manchas_points = this.imunidadeInfos.manchas == "Sim" ? 1 : 0;
      var candidiase_points = this.imunidadeInfos.candidiase == "Sim" ? 2 : 0;
      var constipado_points = this.imunidadeInfos.constipado == "Sim" ? 1 : 0;
      var diarreia_points = this.imunidadeInfos.diarreia == "Sim" ? 2 : 0;
      var nauseas_points = this.imunidadeInfos.nauseas == "Sim" ? 1 : 0;
      var cansaco_points = this.imunidadeInfos.cansaco == "Sim" ? 1 : 0;
      var olhos_points = this.imunidadeInfos.olhos == "Sim" ? 1 : 0;
      var febresCalafrios_points =
        this.imunidadeInfos.febresCalafrios == "Sim" ? 2 : 0;
      var doencasDemoram_points =
        this.imunidadeInfos.doencasDemoram == "Sim" ? 1 : 0;
      var amigdalite_points = this.imunidadeInfos.amigdalite == "Sim" ? 1 : 0;
      var infeccaoUrinaria_points =
        this.imunidadeInfos.infeccaoUrinaria == "Sim" ? 2 : 0;
      var aftas_points = this.imunidadeInfos.aftas == "Sim" ? 1 : 0;
      var viroses_points = this.imunidadeInfos.viroses == "Sim" ? 1 : 0;
      var gripesFrequentes_points =
        this.imunidadeInfos.gripesFrequentes == "Sim" ? 2 : 0;
      var bmi =
        this.imunidadeInfos.pesoAtual /
        ((this.imunidadeInfos.altura * this.imunidadeInfos.altura) / 10000);
      // ate 24.9 = 0, ate 29.9 = 1, +30 = 2, -18 = 1,
      var bmi_points;
      if (bmi < 18) {
        bmi_points = 1;
      } else if (bmi < 24.9) {
        bmi_points = 0;
      } else if (bmi < 29.9) {
        bmi_points = 1;
      } else if (bmi > 29.9) {
        bmi_points = 2;
      }

      var scoreList = [
        alergias_points,
        autoImune_points,
        pressaoAlta_points,
        diabetes_points,
        depressao_points,
        criseAnsiedade_points,
        nivelEstresse_points,
        acorda_points,
        insonia_points,
        unhas_points,
        cabelo_points,
        manchas_points,
        candidiase_points,
        constipado_points,
        diarreia_points,
        nauseas_points,
        cansaco_points,
        olhos_points,
        febresCalafrios_points,
        doencasDemoram_points,
        amigdalite_points,
        infeccaoUrinaria_points,
        aftas_points,
        viroses_points,
        gripesFrequentes_points,
        bmi_points,
      ];

      scoreList.forEach(function(item) {
        scoreImundidade += parseFloat(item);
      });

      return scoreImundidade;
    },
    scorePerc() {
      var maxPoints = 36;
      var scorePerc = Math.round((this.score / maxPoints) * 100);

      return scorePerc;
    },
  },
  methods: {
    pacIndTextCheck() {
      console.log(this.channels.slice(0, 6));
      if (
        this.pacienteInfos.indicacao == undefined ||
        this.pacienteInfos.indicacao == null ||
        this.pacienteInfos.indicacao == "" ||
        this.channels.slice(0, 6).includes(this.pacienteInfos.indicacao)
      ) {
        this.pacIndText = false;
      } else {
        this.pacIndText = true;
      }
    },
    checkStep1() {
      if (this.$refs.form1.validate()) {
        this.openStep = 2;
      }
    },
    checkStepE1() {
      if (this.$refs.formE1.validate()) {
        this.openStepE = 2;
      }
    },
    checkStep2() {
      if (this.$refs.form2.validate()) {
        this.openStep = 3;
      }
    },
    checkStep3() {
      if (this.$refs.form3.validate()) {
        this.openStep = 4;
      }
    },
    checkStep4() {
      // if (this.$refs.form4.validate()) {
      this.openStep = 5;
      // }
    },
    checkStep5() {
      // if (this.$refs.form5.validate()) {
      this.openStep = 6;
      // }
    },
    checkStep6() {
      if (this.$refs.form6.validate()) {
        this.openStep = 7;
      }
    },
    checkStep7() {
      if (this.$refs.form7.validate()) {
        this.openStep = 8;
      }
    },
    checkStep8() {
      if (this.$refs.form8.validate()) {
        this.openStep = 9;
      }
    },
    checkStep9() {
      if (this.$refs.form9.validate()) {
        this.openStep = 10;
      }
    },
    checkStep10() {
      if (this.$refs.form10.validate()) {
        this.checkStep10false = false;
        this.submit();
      } else {
        this.checkStep10false = true;
      }
      console.log("this.checkStep10false", this.checkStep10false);
    },
    checkStepE2() {
      if (this.$refs.formE2.validate()) {
        this.checkStepE2false = false;

        var estetResps = [
          this.pacienteInfos.estet1,
          this.pacienteInfos.estet2,
          this.pacienteInfos.estet3,
          this.pacienteInfos.estet4,
          this.pacienteInfos.estet5,
          this.pacienteInfos.estet6,
          this.pacienteInfos.estet7,
          this.pacienteInfos.estet8,
          this.pacienteInfos.estet9,
          this.pacienteInfos.estet10,
        ];

        var estetCondNok = estetResps.includes("Não");

        console.log("estetCondNok", estetCondNok);

        if (estetCondNok) {
          console.log("sending Text")
          this.sendText(
            "**Mensagem automática**: Foi informado através do questionário no sistema que tenho uma contra-indicação para fazer tratamentos estéticos. Por favor, entrar em contato!"
          );
        }

        this.submit();
      } else {
        this.checkStepE2false = true;
      }
      console.log("this.checkStepE2false", this.checkStepE2false);
    },
    sendText(text) {
      var nutri = this.userInfo.nutri;
      var now = new Date();
      var hours = now.getHours();
      var mins = now.getMinutes();
      var day = now.getDate();
      var month = now.getMonth() + 1;
      var year = now.getFullYear();

      var horaStr;
      if (hours < 10) {
        horaStr = "0" + hours.toString();
      } else {
        horaStr = hours.toString();
      }
      var minStr;
      if (mins < 10) {
        minStr = "0" + mins.toString();
      } else {
        minStr = mins.toString();
      }
      var hora = horaStr + ":" + minStr;

      var dayStr;
      if (day < 10) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var monthStr;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }
      var dateStr = dayStr + "/" + monthStr + "/" + year.toString();

      var id = now.getTime();

      this.$store.dispatch("firestore/sendTextP", {
        nutri: nutri,
        text: text,
        id: id.toString(),
        lastFalaHora: hora,
        lastFalaMillis: id,
        lastFalaDateStr: dateStr,
      });
      setTimeout(() => {
        this.conversaDialog = false;
        this.sentDialog = true;
        this.textToSend = "";
      }, 1000);
    },
    handleExam1(file) {
      this.exam1 = file;
    },
    handleExam2(file) {
      this.exam2 = file;
    },
    handleExam3(file) {
      this.exam3 = file;
    },
    async submit() {
      this.savedQuest = false;
      var date = new Date();

      var monthStr = date.getMonth() + 1;
      var simpleDateStr =
        date.getFullYear().toString() +
        "-" +
        monthStr.toString() +
        "-" +
        date.getDate().toString();

      if (this.userInfo.preAtendimentoToFill) {
        this.progressDialog = true;
        this.$store.dispatch("firestore/pacientePreAtendimentoSubmit", {
          pacienteInfos: this.pacienteInfos,
        });
        this.savedQuest = true;
      }

      if (this.userInfo.estetToFill) {
        this.progressDialog = true;
        this.$store.dispatch("firestore/pacienteEstetSubmit", {
          pacienteInfos: this.pacienteInfos,
        });
        this.savedQuest = true;
      }

      if (this.userInfo.imunidadeToFill) {
        this.scoreDialog = true;
        if (
          this.$refs.formImunidade.validate() &&
          this.$refs.formAnamneseImunidade.validate()
        ) {
          var infos = {};
          if (this.exam1 !== null) {
            console.log("Enviando exame 1!");
            await firebase
              .storage()
              .ref("examsImunidade")
              .child(
                sessionStorage.getItem("current-user") +
                  "_" +
                  simpleDateStr +
                  "_exam1"
              )
              .put(this.exam1)
              .then(async (snapshot) => {
                infos.exam1 = await snapshot.ref.getDownloadURL();
                console.log(infos.exam1);
              });
          } else {
            infos.exam1 = null;
          }

          if (this.exam2 !== null) {
            console.log("Enviando exame 1!");
            await firebase
              .storage()
              .ref("examsImunidade")
              .child(
                sessionStorage.getItem("current-user") +
                  "_" +
                  simpleDateStr +
                  "_exam2"
              )
              .put(this.exam2)
              .then(async (snapshot) => {
                infos.exam2 = await snapshot.ref.getDownloadURL();
                console.log(infos.exam2);
              });
          } else {
            infos.exam2 = null;
          }

          if (this.exam3 !== null) {
            console.log("Enviando exame 3!");
            await firebase
              .storage()
              .ref("examsImunidade")
              .child(
                sessionStorage.getItem("current-user") +
                  "_" +
                  simpleDateStr +
                  "_exam3"
              )
              .put(this.exam3)
              .then(async (snapshot) => {
                infos.exam3 = await snapshot.ref.getDownloadURL();
                console.log(infos.exam3);
              });
          } else {
            infos.exam3 = null;
          }

          this.imunidadeInfos.exam1 = infos.exam1 == null ? "" : infos.exam1;
          this.imunidadeInfos.exam2 = infos.exam2 == null ? "" : infos.exam2;
          this.imunidadeInfos.exam3 = infos.exam3 == null ? "" : infos.exam3;

          this.$store.dispatch("firestore/pacienteImunidadeSubmit", {
            score: this.score,
            imunidadeInfos: this.imunidadeInfos,
          });

          this.$store.dispatch("firestore/pacienteGetImunidadeTestesDone");
          this.saved = true;
          this.showImunidade = false;
        } else {
          this.dadosErradosDialog = true;
          this.scoreDialog = false;
        }
      }
    },
    // superimunidade 2, boa imunidade 5, reforçar imunidade 6-10, baixa imunidade 11-20, imunidade crítica <5
    getScoreColor(score) {
      var color;
      if (score <= 2) {
        color = "#1cbd00";
      } else if (score <= 5) {
        color = "#fcff00";
      } else if (score <= 10) {
        color = "#ff8a00";
      } else if (score <= 18) {
        color = "#ff1100";
      } else if (score > 18) {
        color = "#ad0000";
      }
      return color;
    },
    getScoreDef(score) {
      var def;
      if (score <= 2) {
        def = "Superimunidade";
      } else if (score <= 5) {
        def = "Boa imunidade";
      } else if (score <= 10) {
        def = "Reforçar imunidade";
      } else if (score <= 18) {
        def = "Baixa imunidade";
      } else if (score > 18) {
        def = "Imunidade crítica";
      }
      return def;
    },
    makePerc(score) {
      return Math.round((score / 36) * 100);
    },
  },
  mounted() {
    this.$store.dispatch("firestore/pacienteGetPacienteInfos");
    this.$store.dispatch("firestore/pacienteGetImunidadeInfos");
    this.$store.dispatch("firestore/getPreAtendimentoToFill");
    this.$store.dispatch("firestore/pacienteGetImunidadeTestesDone");
  },
};
</script>

<style></style>
