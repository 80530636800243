<template>
  <div>
    <v-dialog v-model="dialogVisualizar" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title class="d-flex justify-center text-h6">
          {{ formulaVis.titulo }}
        </v-card-title>
        <v-card-text>
          <v-row class="mx-4">
            <v-textarea
              v-model="formulaVis.prescricoes"
              solo
              height="200"
            ></v-textarea>
          </v-row>
          <v-row
            class="d-flex justify-center mx-4 text-subtitle-2 black--text mt-2"
          >
            Catergorias:
          </v-row>
          <v-row class="mx-4">
            <v-col
              cols="6"
              v-for="categoria in formulaVis.categorias"
              :key="categoria"
              class="d-flex justify-center"
            >
              {{ categoria }},
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text @click="dialogVisualizar = false" color="#614021">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title class="d-flex justify-center text-h6">
        Fórmulas
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4" class="d-flex justify-end">
            <v-checkbox
              label="categoria:"
              v-model="catBool"
              @change="updateFormulaItensAll"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="categorias"
              v-model="categoriaSelected"
              label="categoria"
              @change="updateFormulasItens(categoriaSelected)"
              :disabled="!catBool"
            ></v-select>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
        <v-card
          v-for="(formula, formulaIndex) in formulasItens"
          :key="formulaIndex"
          outlined
        >
          <v-row class="d-flex justify-center">
            <v-col cols="12" class="d-flex justify-space-between">
              <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                {{ formula.data.titulo }}
              </v-row>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#614021"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 mx-2 black--text"
                    icon
                    @click="visualizar(formula.data)"
                  >
                    <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Formula</span>
              </v-tooltip>

              <v-btn
                color="#614021"
                class="mr-2 mx-2 black--text"
                @click="editarFormula(formula.value)"
                text
              >
                <v-icon color="#c5d13f" left>{{
                  icons.mdiPlaylistEdit
                }}</v-icon>
                Editar
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 mx-2 black--text"
                    icon
                    @click="deleteFormula(formula.value)"
                  >
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>Deletar fórmula</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiPlaylistEdit, mdiEye, mdiTrashCanOutline } from "@mdi/js";
import router from "../router";

export default {
  data: function () {
    return {
      dialogVisualizar: false,
      icons: {
        mdiPlaylistEdit,
        mdiEye,
        mdiTrashCanOutline,
      },
      formulasList: [],
      formula: {
        id: "",
      },
      categorias: [
        "Fórmulas veganas",
        "Fórmulas na pediatria",
        "Fórmulas nutrição e estética",
        "Fórmulas nutrição esportiva",
        "Fórmulas modulação intestinal",
        "Fórmulas saúde do homem",
        "Fórmulas saúde da mulher",
        "Fórmulas para imunidade",
        "Fórmulas para obesidade e emagrecimento",
        "Fórmulas para metais pesados",
        "Fórmulas neuro e sono",
        "Fórmulas longevidade",
        "Fórmulas para diabéticos",
        "Fórmulas de vitaminas e minerais",
        "Fórmulas de alergia e intolerância alimentar",
        "Fórmulas de patologia",
      ],
      categoriaSelected: null,
      catBool: false,
      formulasItens: [],
    };
  },
  computed: {
    formulas() {
      var alphabet = function (objs) {
        function compare(a, b) {
          if (a.data.titulo < b.data.titulo) {
            return -1;
          }
          if (a.data.titulo > b.data.titulo) {
            return 1;
          }
          return 0;
        }

        return objs.sort(compare);
      };

      return alphabet(this.$store.state.firestore.formulas);
    },
    formulaVis() {
      return this.$store.state.firestore.formulaVis;
    },
  },
  methods: {
    visualizar(formu) {
      this.$store.dispatch("firestore/makeFormulaVis", { formu });
      this.dialogVisualizar = true;
    },
    updateFormulasItens(cat) {
      var formulasCat = this.formulas.filter(function (el) {
        if (el.data.categorias) {
          return el.data.categorias.includes(cat);
        } else {
          return false;
        }
      });
      this.formulasItens = formulasCat;
      console.log(this.formulasItens);
    },
    updateFormulaItensAll() {
      if (!this.catBool) {
        this.formulasItens = this.formulas;
      }
    },
    onFormulaSelected(index, id) {
      var formulaSelecionada = this.formulas.find((item) => item.value == id);
      this.prescricaoC.formulasList[index] = formulaSelecionada.data;
    },
    editarFormula(id) {
      console.log(id);
      var formula = this.formulas.find((item) => item.value == id);
      if (formula !== undefined) {
        this.$store.dispatch("firestore/formulaId", {
          id: id,
        });
        this.$store.dispatch("firestore/formulaUpdate", {
          formula: formula.data,
        });
        router.replace("/EditarFormula");
      }
    },
    deleteFormula(id) {
      console.log(id);
      this.$store.dispatch("firestore/formulaDelete", {
        formula: id,
      });
      this.$store.dispatch("firestore/getFormulas");
      if (this.categoriaSelected !== null) {
        this.updateFormulasItens(this.categoriaSelected)
      }
    },
  },
  mounted() {
    // if (this.$store.state.firestore.formulas.length === 0) {
    this.$store.dispatch("firestore/getFormulas");
    // }
    this.formulasItens = this.formulas;
  },
};
</script>