<template>
  <div>
    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
      class="pa-5 my-1"
      tile
      style="background-color: rgba(197, 209, 63, 0.6)"
    >
      <v-card-title class="d-flex justify-center text-heading-6">
        Liberar Acesso a Vídeo-Aulas/Ebooks
      </v-card-title>
      <v-card-text>
        <v-form ref="formGeneral" v-model="validGeneral">
          <v-row class="d-flex justify-center mx-4">
            <!-- <v-col cols="8"> -->
            <v-autocomplete
              label="Nome do Paciente"
              single-line
              solo
              dense
              clearable
              v-model="pacienteDocID"
              :items="pacientes"
              class="align-self-center mx-2"
              @change="pacienteSelected(pacienteDocID)"
              no-data-text="..."
            ></v-autocomplete>
            <!-- </v-col>
            <v-col cols="4" class="d-flex justify-center">
              <v-btn to="/PacienteNovo" color="grey-lighten-1">
                Cadastrar Paciente Novo
              </v-btn>
            </v-col> -->
          </v-row>
          <v-row class="mx-4">
            <v-select
              class="mx-4"
              v-model="temasToLiberate"
              :items="temas"
              attach
              chips
              label="Temas"
              multiple
            ></v-select>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="#614021" class="white--text" large @click="liberar">
          <v-icon color="#c5d13f" left> mdi-television-guide </v-icon>
          Liberar
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      validGeneral: true,
      pacienteDocID: "",
      pacienteChosen: { data: { nome: "" } },
      temasToLiberate: [],
      temas: [
        { text: "Veganismo", value: "veganismo" },
        { text: "Saúde da Mulher", value: "saudeDaMulher" },
        { text: "Rendimento Desportivo", value: "rendimento" },
        { text: "Reeducação Alimentar", value: "reeducacao" },
        { text: "Obesidade e Emagrecimento", value: "obesidadeEmag" },
        { text: "Intolerância Alimentar", value: "intoleranciaAlimentar" },
        { text: "Imunidade", value: "imunidade" },
        { text: "Geriatria e Longevidade", value: "geri" },
        { text: "Esteatose Hepática", value: "estea" },
        { text: "Emagrecimento e Estética", value: "emagEste" },
        { text: "Doenças Autoimunes", value: "doencasAuto" },
        { text: "Distúrbios Gastrointestinais", value: "distGastro" },
        { text: "Dislipidemia", value: "dislip" },
        { text: "Diabetes", value: "diabetes" },
        { text: "Cirurgia Bariátrica", value: "cirBar" },
        { text: "Ansiedade", value: "ansiedade" },
        { text: "Programa 12S", value: "programa12s" },
      ],
      objs: {
        programa12s: {
          id: "programa12s",
          nome: "Programa 12S",
          videos: [
            {
              nome: "O Clube da Nutricionista",
              url: "https://youtu.be/j08ORSLrexA",
              yt: "j08ORSLrexA",
            },
          ],
          ebooks: [
            {
              nome: "O programa 12S",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2F12-semanas-V2.pdf?alt=media&token=2c76116c-0edb-40bb-8158-e91002f8b6e7",
            },
          ],
        },
        veganismo: {
          id: "veganismo",
          nome: "Veganismo",
          videos: [
            {
              nome: "Veganismo e Vegetarianismo",
              url: "https://youtu.be/tRIWWzZnNTM",
              yt: "tRIWWzZnNTM",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Espaguete Cremoso De Abobrinha",
              url: "https://youtu.be/92jjRI6d6h4",
              yt: "92jjRI6d6h4",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
          ],
          ebooks: [
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
            {
              nome: "Veganismo",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fveganismo.pdf?alt=media&token=a0104b17-4172-4db8-a949-9ac4f6d8677b",
            },
          ],
        },
        saudeDaMulher: {
          id: "saudeDaMulher",
          nome: "Saúde da Mulher",
          videos: [
            {
              nome: "Saúde de Mulher",
              url: "https://youtu.be/e6fsCJrkIdU",
              yt: "e6fsCJrkIdU",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Espaguete Cremoso De Abobrinha",
              url: "https://youtu.be/92jjRI6d6h4",
              yt: "92jjRI6d6h4",
            },
            {
              nome: "Hambúrguer De Carne com Quinoa",
              url: "https://youtu.be/BxZcf9SWxdw",
              yt: "BxZcf9SWxdw",
            },
            {
              nome: "Pizza De Brócolis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Biscoito De Aveia Com Geleia De Morango",
              url: "https://youtu.be/okNUTRmqEl4",
              yt: "okNUTRmqEl4",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
          ],
          ebooks: [
            {
              nome: "Saúde da Mulher",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FsaudeDaMulher.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        rendimento: {
          id: "rendimento",
          nome: "Rendimento Desportivo",
          videos: [
            {
              nome: "Alimentação na Prática de Atividade Física",
              url: "https://youtu.be/KYLLZDYl32o",
              yt: "KYLLZDYl32o",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer De Carne com Quinoa",
              url: "https://youtu.be/BxZcf9SWxdw",
              yt: "BxZcf9SWxdw",
            },
            {
              nome: "Biscoito De Aveia Com Geleia De Morango",
              url: "https://youtu.be/okNUTRmqEl4",
              yt: "okNUTRmqEl4",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Bombom Saudável",
              url: "https://youtu.be/PBCpPOxdIZI",
              yt: "PBCpPOxdIZI",
            },
          ],
          ebooks: [
            {
              nome: "Rendimento Desportivo",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        reeducacao: {
          nome: "Reeducação Alimentar",
          id: "reeducacao",
          videos: [
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome:
                "Mudança do Estilo de Vida para Potencializar Resultados Estéticos",
              url: "https://youtu.be/n9KyrRSeKY4",
              yt: "n9KyrRSeKY4",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Farofa De Agridoce",
              url: "https://youtu.be/_duLx9sSgoY",
              yt: "_duLx9sSgoY",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
          ],
          ebooks: [
            {
              nome: "Reeducação Alimentar",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FreeducacaoAlimentar.pdf?alt=media&token=28bb6184-666d-403d-b8bc-dcd54baef9b4",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        obesidadeEmag: {
          id: "obesidadeEmag",
          nome: "Obesidade e Emagrecimento",
          videos: [
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Esteatose Hepática (gordura no fígado)",
              url: "https://youtu.be/V1bbDKS6Jd4",
              yt: "V1bbDKS6Jd4",
            },
            {
              nome:
                "Mudança do Estilo de Vida para Potencializar Resultados Estéticos",
              url: "https://youtu.be/n9KyrRSeKY4",
              yt: "n9KyrRSeKY4",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Biscoito De Aveia Com Geleia De Morango",
              url: "https://youtu.be/okNUTRmqEl4",
              yt: "okNUTRmqEl4",
            },
          ],
          ebooks: [
            {
              nome: "Obesidade e Emagrecimento",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FobesidadeEmagrecimento.pdf?alt=media&token=a27da780-ca27-4509-8b92-39712abc914d",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        intoleranciaAlimentar: {
          id: "intoleranciaAlimentar",
          nome: "Intolerância Alimentar",
          videos: [
            {
              nome: "Alergias Alimentares",
              url: "https://youtu.be/Xi-VpBsZzow",
              yt: "Xi-VpBsZzow",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Farofa De Agridoce",
              url: "https://youtu.be/_duLx9sSgoY",
              yt: "_duLx9sSgoY",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Bolo De Cenoura Trufado",
              url: "https://youtu.be/Fe2ae-JI_j8",
              yt: "Fe2ae-JI_j8",
            },
            {
              nome: "Brigadeiro Funcional",
              url: "https://youtu.be/2I9K3CyjIRQ",
              yt: "2I9K3CyjIRQ",
            },
          ],
          ebooks: [
            {
              nome: "Intolerância Alimentar",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FintoleranciaAlimentar.pdf?alt=media&token=d92f695b-1ff3-4617-a2ac-1d3ec03e2627",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        imunidade: {
          id: "imunidade",
          nome: "Imunidade",
          videos: [
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Caldo De Abóbora Com Espinafre e Açafrão",
              url: "https://youtu.be/IcOFYv_cuEY",
              yt: "IcOFYv_cuEY",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Cookies de Banana com Castanha",
              url: "https://youtu.be/CxGyhnP3Zro",
              yt: "CxGyhnP3Zro",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Bolo De Cenoura Trufado",
              url: "https://youtu.be/Fe2ae-JI_j8",
              yt: "Fe2ae-JI_j8",
            },
            {
              nome: "Pizza De Brocólis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Torta Proteica de Frango",
              url: "https://youtu.be/2ulYqe6Rm90",
              yt: "2ulYqe6Rm90",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
          ],
          ebooks: [
            {
              nome: "Imunidade",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fimunidade.pdf?alt=media&token=0f2fb9e0-48db-4b1b-8a88-628278eb0f7d",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        geri: {
          id: "geri",
          nome: "Geriatria e Longevidade",
          videos: [
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Caldo De Abóbora Com Espinafre e Açafrão",
              url: "https://youtu.be/IcOFYv_cuEY",
              yt: "IcOFYv_cuEY",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
          ],
          ebooks: [
            {
              nome: "Geriatria e Longevidade",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FgeriatriaLongevidade.pdf?alt=media&token=1e09603d-6aa4-48f1-8033-70cda48ae4db",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        estea: {
          id: "estea",
          nome: "Esteatose Hepática",
          videos: [
            {
              nome: "Esteatose Hepática (gordura no fígado)",
              url: "https://youtu.be/V1bbDKS6Jd4",
              yt: "V1bbDKS6Jd4",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Abobrinha Recheada",
              url: "https://youtu.be/jqx1TbZ5ahY",
              yt: "jqx1TbZ5ahY",
            },
            {
              nome: "Espaguete Cremoso De Abobrinha",
              url: "https://youtu.be/92jjRI6d6h4",
              yt: "92jjRI6d6h4",
            },
            {
              nome: "Pizza De Brócolis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
          ],
          ebooks: [
            {
              nome: "Esteatose Hepática",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FesteatoseHepatica.pdf?alt=media&token=251bd6ff-209d-406e-9f2b-345ca4ae73b9",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        emagEste: {
          nome: "Emagrecimento e Estética",
          id: "emagEste",
          videos: [
            {
              nome:
                "Mudança do Estilo de Vida para Potencializar Resultados Estéticos",
              url: "https://youtu.be/n9KyrRSeKY4",
              yt: "n9KyrRSeKY4",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Abobrinha Recheada",
              url: "https://youtu.be/jqx1TbZ5ahY",
              yt: "jqx1TbZ5ahY",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Bombom Saudável",
              url: "https://youtu.be/PBCpPOxdIZI",
              yt: "PBCpPOxdIZI",
            },
          ],
          ebooks: [
            {
              nome: "Emagrecimento e Estética",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FemagrecimentoEstetica.pdf?alt=media&token=675485f1-4f54-41dd-a507-65de3150b464",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        doencasAuto: {
          id: "doencasAuto",
          nome: "Doenças Autoimunes",
          videos: [
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Torta Proteica de Frango",
              url: "https://youtu.be/2ulYqe6Rm90",
              yt: "2ulYqe6Rm90",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
            {
              nome: "Smoothie de Abacaxi com Coco",
              url: "https://youtu.be/wIAORt6SJY0",
              yt: "wIAORt6SJY0",
            },
          ],
          ebooks: [
            {
              nome: "Doenças Autoimunes",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FdoencasAutoimunes.pdf?alt=media&token=bd6a8cd3-bd72-4a07-8d0d-9a3d15f613e5",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        distGastro: {
          id: "distGastro",
          nome: "Distúrbios Gastrointestinais",
          videos: [
            {
              nome: "Alterações Gastrointestinais",
              url: "https://youtu.be/ukJQrYSMijU",
              yt: "ukJQrYSMijU",
            },
            {
              nome: "Constipação e Diarreia",
              url: "https://youtu.be/l6tTXSp-9WE",
              yt: "l6tTXSp-9WE",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Bolo De Cenoura Trufado",
              url: "https://youtu.be/Fe2ae-JI_j8",
              yt: "Fe2ae-JI_j8",
            },
            {
              nome: "Brigadeiro Funcional",
              url: "https://youtu.be/2I9K3CyjIRQ",
              yt: "2I9K3CyjIRQ",
            },
            {
              nome: "Smoothie de Abacaxi com Coco",
              url: "https://youtu.be/wIAORt6SJY0",
              yt: "wIAORt6SJY0",
            },
          ],
          ebooks: [
            {
              nome: "Distúrbios Gastrointestinais",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FdisturbiosGastrointestinais.pdf?alt=media&token=41fe36d9-5bae-45bf-bb7e-d60beed4cb63",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        dislip: {
          id: "dislip",
          nome: "Dislipidemia",
          video: [
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Abobrinha Recheada",
              url: "https://youtu.be/jqx1TbZ5ahY",
              yt: "jqx1TbZ5ahY",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
            {
              nome: "Smoothie de Abacaxi com Coco",
              url: "https://youtu.be/wIAORt6SJY0",
              yt: "wIAORt6SJY0",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
          ],
          ebooks: [
            {
              nome: "Dislipidemia",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fdislipidemia.pdf?alt=media&token=ee605280-627d-49fe-abe4-2a833fffa729",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        diabetes: {
          nome: "Diabetes",
          id: "diabetes",
          videos: [
            {
              yt: "z1Xvm0R5ndk",
              url: "https://youtu.be/z1Xvm0R5ndk",
              nome: "Diabetes Mellitus",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Abobrinha Recheada",
              url: "https://youtu.be/jqx1TbZ5ahY",
              yt: "jqx1TbZ5ahY",
            },
            {
              nome: "Espaguete Cremoso De Abobrinha",
              url: "https://youtu.be/92jjRI6d6h4",
              yt: "92jjRI6d6h4",
            },
            {
              nome: "Pizza De Brócolis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Bombom Saudável",
              url: "https://youtu.be/PBCpPOxdIZI",
              yt: "PBCpPOxdIZI",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
          ],
          ebooks: [
            {
              nome: "Diabetes",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fdiabetes.pdf?alt=media&token=157a59d3-5362-4e5d-a9e0-4744d3781c04",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        cirBar: {
          id: "cirBar",
          nome: "Cirurgia Bariátrica",
          videos: [
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome:
                "Mudança do Estilo de Vida para Potencializar Resultados Estéticos",
              url: "https://youtu.be/n9KyrRSeKY4",
              yt: "n9KyrRSeKY4",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Caldo De Abóbora Com Espinafre e Açafrão",
              url: "https://youtu.be/IcOFYv_cuEY",
              yt: "IcOFYv_cuEY",
            },
            {
              nome: "Omelete de Forno",
              url: "https://youtu.be/mBGUu2EspwA",
              yt: "mBGUu2EspwA",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Cookies de Banana com Castanha",
              url: "https://youtu.be/CxGyhnP3Zro",
              yt: "CxGyhnP3Zro",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
          ],
          ebooks: [
            {
              nome: "Cirurgia Bariátrica",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FcirurgiaBariatrica.pdf?alt=media&token=9f649456-4b13-4707-8b35-f9c44d175fe8",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        ansiedade: {
          id: "ansiedade",
          nome: "Ansiedade",
          videos: [
            {
              nome: "Ansiedade e Nutrição",
              url: "https://youtu.be/PMSMjZaJ2QI",
              yt: "PMSMjZaJ2QI",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
            {
              nome: "Farofa De Agridoce",
              url: "https://youtu.be/_duLx9sSgoY",
              yt: "_duLx9sSgoY",
            },
            {
              nome: "Pizza De Brócolis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Biscoito De Aveia Com Geleia De Morango",
              url: "https://youtu.be/okNUTRmqEl4",
              yt: "okNUTRmqEl4",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
          ],
          ebooks: [
            {
              nome: "Ansiedade",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fansiedade.pdf?alt=media&token=99d9646f-9b83-471e-bf8c-be61b0bf2297",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
      },
      saved: false,
      progressDialog: false,
    };
  },
  computed: {
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
  },
  methods: {
    async pacienteSelected(pacienteId) {
      // if (pacienteId != undefined) {
      //   var paciente = this.pacientes.find((item) => item.value == pacienteId);
      //   this.pacienteChosen = paciente;
      // } else {
      //   this.pacienteChosen = { data: { nome: "" } };
      // }
      var videoAulasGivenDocs = await firebase
        .firestore()
        .collection("users")
        .doc(pacienteId)
        .collection("videoAulas")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );
      var videoAulasGivenIds = videoAulasGivenDocs.map(function(v) {
        return v.id;
      });

      var temasGiven = this.temas.filter(function(t) {
        return videoAulasGivenIds.includes(t.value);
      });

      this.temasToLiberate = temasGiven;
    },
    async liberar() {
      this.saved = false;
      this.progressDialog = true;
      var objs = this.objs;
      var pacienteDocID = this.pacienteDocID;
      if (pacienteDocID !== "") {
        this.temasToLiberate.forEach(async function(tema) {
          var obj = objs[tema];
          console.log(obj);
          await firebase
            .firestore()
            .collection("users")
            .doc(pacienteDocID)
            .collection("videoAulas")
            .doc(tema)
            .set(obj);
        });
        this.saved = true;
      } else {
        alert("Escolhe um paciente cadastrado!");
      }
    },
  },
  mounted() {
    if (this.$store.state.firestore.pacientes.length === 0) {
      this.$store.dispatch("firestore/getPacientes");
    }
  },
};
</script>
