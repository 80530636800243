<template>
  <div>
    <v-dialog v-model="conversaDialog" scrollable max-width="1100">
      <v-card elevation="5" class="my-4">
        <v-card-title class="d-flex justify-center text-subtitle-1 my-2">
          Conversa com a Nutricionista
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 1000px">
          <v-row
            class="mx-4"
            v-for="fala in currentDialog.elements"
            :key="fala.id"
          >
            <v-col cols="0" md="4" v-if="fala.fromN"> </v-col>
            <v-col cols="12" md="8">
              <v-card
                :color="getColor(fala.fromN)"
                class="d-flex align-end my-1"
              >
                <v-card-text>
                  <v-row class="d-flex justify-center">
                    <v-textarea
                      readonly
                      v-model="fala.text"
                      solo
                      class="mx-1 my-1"
                    ></v-textarea>
                  </v-row>
                  <v-row class="d-flex justify-end">
                    <span
                      ><small>{{ readableDate(fala.id) }}</small></span
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="0" md="4" v-if="fala.fromP"></v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              @click="readClose"
              class="white--text my-2"
              v-if="lastMsgIsN"
            >
              <v-icon color="green">mdi-check-circle-outline</v-icon>
              Marcar como lido + Fechar
            </v-btn>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="height: 200px">
          <v-textarea
            style="height: 80%"
            outlined
            class="mx-2"
            v-model="textToSend"
          >
          </v-textarea>
          <v-btn color="#c5d13f" @click="sendText(textToSend)" class="mx-2">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sentDialog" max-width="600">
      <v-card elevation="5">
        <v-card-title class="d-flex justify-center">
          Mensagem enviada!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-email-check-outline</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="d-flex justify-center text-h6 my-4"
      >Chat com a Nutricionista</v-row
    >
    <v-row class="d-flex justify-center mt-4 mb-16">
      <v-card width="95%" outlined>
        <v-row class="d-flex justify-center mx-4">
          <v-col
            cols="12"
            md="7"
            class="d-flex justify-space-between align-center"
          >
            <v-row>
              Conversa com a Nutricionista, {{ hiddenInfo.lastFalaDateStr }},
              {{ hiddenInfo.lastFalaHora }}
            </v-row>
          </v-col>
          <v-col cols="12" md="5" class="d-flex justify-end">
            <v-icon color="red" v-if="hiddenInfo.unansweredP" class="mx-4"
              >mdi-email-alert-outline</v-icon
            >
            <v-btn color="#c5d13f" @click="openConversaP">
              <v-icon color="#614021" left>mdi-chat-plus</v-icon>
              Ver Chat
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      conversaDialog: false,
      sentDialog: false,
      pacienteDocID: null,
      textToSend: "",
      conversasToShow: [],
      lastFalaDateStr: "",
      lastFalaHora: "",
      currentDialog: {
        nome: null,
        email: null,
        elements: [
          {
            fromN: null,
            fromP: null,
            id: null,
            text: null,
          },
        ],
      },
    };
  },
  computed: {
    lastMsgIsN() {
      if (this.currentDialog.elements.length > 0) {
        return this.currentDialog.elements[
          this.currentDialog.elements.length - 1
        ].fromN;
      } else {
        return false;
      }
    },
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    // currentDialog() {
    //   return this.$store.state.firestore.currentDialog;
    // },
    hiddenInfo() {
      return this.$store.state.firestore.hiddenInfo;
    },
  },
  methods: {
    readableDate(millis) {
      var millisNr = parseFloat(millis);
      var date = new Date(millisNr);
      var year = date.getFullYear().toString();
      var month = date.getMonth() + 1;
      var monthStr;
      if (month > 9) {
        monthStr = month.toString();
      } else {
        monthStr = "0" + month.toString();
      }
      var day = date.getDate();
      var dayStr;
      if (day > 9) {
        dayStr = day.toString();
      } else {
        dayStr = "0" + day.toString();
      }
      var hours = date.getHours();
      var mins = date.getMinutes();
      var minsStr;
      if (mins > 9) {
        minsStr = mins.toString();
      } else {
        minsStr = "0" + mins.toString();
      }

      var niceDate =
        hours +
        ":" +
        minsStr +
        " (" +
        dayStr +
        "/" +
        monthStr +
        "/" +
        year +
        ")";

      return niceDate;
    },
    async openConversaP() {
      // console.log(this.userInfo.nutri);
      // this.$store.dispatch("firestore/chatCurrentP", {
      //   nutri: this.userInfo.nutri,
      //   pacienteName: this.userInfo.nome,
      // });

      var falas = await firebase
        .firestore()
        .collection("users")
        .doc(this.userInfo.nutri)
        .collection("pacientes")
        .doc(sessionStorage.getItem("current-user"))
        .collection("falas")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );

      var dialogObj = {
        nome: this.userInfo.nome,
        email: sessionStorage.getItem("current-user"),
        elements: falas,
      };
      console.log(dialogObj);
      this.currentDialog = dialogObj;

      this.conversaDialog = true;
    },
    sendText(text) {
      var nutri = this.userInfo.nutri;
      var now = new Date();
      var hours = now.getHours();
      var mins = now.getMinutes();
      var day = now.getDate();
      var month = now.getMonth() + 1;
      var year = now.getFullYear();

      var horaStr;
      if (hours < 10) {
        horaStr = "0" + hours.toString();
      } else {
        horaStr = hours.toString();
      }
      var minStr;
      if (mins < 10) {
        minStr = "0" + mins.toString();
      } else {
        minStr = mins.toString();
      }
      var hora = horaStr + ":" + minStr;

      var dayStr;
      if (day < 10) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var monthStr;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }
      var dateStr = dayStr + "/" + monthStr + "/" + year.toString();

      var id = now.getTime();

      this.$store.dispatch("firestore/sendTextP", {
        nutri: nutri,
        text: text,
        id: id.toString(),
        lastFalaHora: hora,
        lastFalaMillis: id,
        lastFalaDateStr: dateStr,
      });
      setTimeout(() => {
        this.conversaDialog = false;
        this.sentDialog = true;
        this.textToSend = "";
      }, 1000);
    },
    getColor(fromNutri) {
      if (fromNutri) {
        return "rgba(97, 64, 33, 0.4)";
      } else {
        return "rgba(197, 209, 63, 0.4)";
      }
    },
    readClose() {
      this.$store.dispatch("firestore/markAsRead", {
        nutri: this.userInfo.nutri,
      });
      this.conversaDialog = false;
    },
    getHiddenInfo() {
      this.$store.dispatch("firestore/getHiddenInfo", {
        nutri: this.userInfo.nutri,
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getHiddenInfo();
    }, 1000);
  },
};
</script>