<template>
  <div>
    <v-dialog v-model="setNutriRespDialog" max-width="600">
      <v-card>
        <v-card-title>Definir nutricionista responsável</v-card-title>
        <v-card-text v-if="!settingNewNutriResp">
          <v-radio-group v-model="newNutriResponsavel" column>
            <v-radio
              v-for="funcionario in nutriFuncionarios"
              :key="funcionario.papel"
              :label="labelOption(funcionario)"
              :value="funcionario.papel"
              color="#614021"
            >
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-text v-else>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-center">
            Definir nova nutricionista responsável nos agendamentos futuros...
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="grey" @click="setNutriRespDialog = false">Fechar</v-btn>
          <v-btn
            v-if="newNutriResponsavel !== null"
            @click="setNewNutriResp"
            color="#c5d13f"
            :disabled="settingNewNutriResp"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProgramaInicioFim" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Datas do programa alteradas!</v-card-title
        >
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAnotacoesAdmin" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Alerações das anotações salvas!</v-card-title
        >
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="verEvolucaoDialog" max-width="80%" height="80%" multiple>
      <v-card>
        <v-stepper non-linear>
          <v-stepper-header>
            <v-stepper-step editable step="1">
              Peso e Composição corporal
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step editable step="2"> Antropometría </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step editable step="3"> Outros </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="small">
                <line-chart :chart-data="datacollectionCompos"></line-chart>
              </div>
              <v-row class="d-flex justify-center mx-4">
                <div v-for="element in checkCompos" :key="element.nome">
                  <v-checkbox
                    class="pr-4"
                    v-model="element.show"
                    :label="element.label"
                    :color="element.color"
                    @click="fillDataCompos"
                  ></v-checkbox>
                </div>
              </v-row>
              <v-row class="d-flex justify-center mx-4 mt-4">
                <v-card color="#D6DBDF" width="100%">
                  <v-row>
                    <v-col cols="2">Medida</v-col>
                    <v-col cols="2"
                      ><small>{{ datatableComposLabels[0] }}</small></v-col
                    >
                    <v-col cols="2"
                      ><small>{{ datatableComposLabels[1] }}</small></v-col
                    >
                    <v-col cols="2"
                      ><small>{{ datatableComposLabels[2] }}</small></v-col
                    >
                    <v-col cols="2"
                      ><small>{{ datatableComposLabels[3] }}</small></v-col
                    >
                    <v-col cols="2">Total</v-col>
                  </v-row>
                </v-card>
              </v-row>
              <v-row
                v-for="element in checkCompos"
                :key="element.nome"
                class="d-flex justify-center mx-4"
              >
                <v-card outlined flat width="100%">
                  <v-row>
                    <v-col cols="2" class="d-flex align-center">{{
                      element.label
                    }}</v-col>
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableComposData(element.label, 0) }}
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableComposData(element.label, 1) }}
                      <small
                        class="mx-1"
                        :style="
                          filter_datatableComposDataChangeStyle(
                            element.label,
                            1
                          )
                        "
                        >{{
                          filter_datatableComposDataChange(element.label, 1)
                        }}</small
                      ></v-col
                    >
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableComposData(element.label, 2) }}
                      <small
                        class="mx-1"
                        :style="
                          filter_datatableComposDataChangeStyle(
                            element.label,
                            2
                          )
                        "
                        >{{
                          filter_datatableComposDataChange(element.label, 2)
                        }}</small
                      ></v-col
                    >
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableComposData(element.label, 3) }}
                      <small
                        class="mx-1"
                        :style="
                          filter_datatableComposDataChangeStyle(
                            element.label,
                            3
                          )
                        "
                        >{{
                          filter_datatableComposDataChange(element.label, 3)
                        }}</small
                      ></v-col
                    >
                    <v-col
                      cols="2"
                      class="d-flex align-center"
                      :style="
                        filter_datatableComposDataTotalChangeStyle(
                          element.label
                        )
                      "
                      >{{
                        filter_datatableComposDataTotalChange(element.label)
                      }}</v-col
                    >
                  </v-row>
                </v-card>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-btn
                  color="#614021"
                  class="white--text my-2"
                  @click="pdfGraphsTables"
                >
                  <v-icon left color="#c5d13f">mdi-download</v-icon>
                  baixar tabelas
                </v-btn>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div class="small">
                <line-chart :chart-data="datacollectionMedidas"></line-chart>
              </div>
              <v-row class="d-flex justify-center mx-4">
                <div v-for="element in checkMedidas" :key="element.nome">
                  <v-checkbox
                    class="pr-4"
                    v-model="element.show"
                    :label="element.label"
                    :color="element.color"
                    @click="fillDataMedidas"
                  ></v-checkbox>
                </div>
              </v-row>
              <v-row class="d-flex justify-center mx-4 mt-4">
                <v-card color="#D6DBDF" width="100%">
                  <v-row>
                    <v-col cols="2">Medida</v-col>
                    <v-col cols="2"
                      ><small>{{ datatableMedidasLabels[0] }}</small></v-col
                    >
                    <v-col cols="2"
                      ><small>{{ datatableMedidasLabels[1] }}</small></v-col
                    >
                    <v-col cols="2"
                      ><small>{{ datatableMedidasLabels[2] }}</small></v-col
                    >
                    <v-col cols="2"
                      ><small>{{ datatableMedidasLabels[3] }}</small></v-col
                    >
                    <v-col cols="2">Total</v-col>
                  </v-row>
                </v-card>
              </v-row>
              <v-row
                v-for="element in checkMedidas"
                :key="element.nome"
                class="d-flex justify-center mx-4"
              >
                <v-card outlined flat width="100%">
                  <v-row>
                    <v-col cols="2" class="d-flex align-center">{{
                      element.label
                    }}</v-col>
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableMedidasData(element.label, 0) }}
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableMedidasData(element.label, 1) }}
                      <small
                        class="mx-1"
                        :style="
                          filter_datatableMedidasDataChangeStyle(
                            element.label,
                            1
                          )
                        "
                        >{{
                          filter_datatableMedidasDataChange(element.label, 1)
                        }}</small
                      ></v-col
                    >
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableMedidasData(element.label, 2) }}
                      <small
                        class="mx-1"
                        :style="
                          filter_datatableMedidasDataChangeStyle(
                            element.label,
                            2
                          )
                        "
                        >{{
                          filter_datatableMedidasDataChange(element.label, 2)
                        }}</small
                      ></v-col
                    >
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableMedidasData(element.label, 3) }}
                      <small
                        class="mx-1"
                        :style="
                          filter_datatableMedidasDataChangeStyle(
                            element.label,
                            3
                          )
                        "
                        >{{
                          filter_datatableMedidasDataChange(element.label, 3)
                        }}</small
                      ></v-col
                    >
                    <v-col
                      cols="2"
                      class="d-flex align-center"
                      :style="
                        filter_datatableMedidasDataTotalChangeStyle(
                          element.label
                        )
                      "
                      >{{
                        filter_datatableMedidasDataTotalChange(element.label)
                      }}</v-col
                    >
                  </v-row>
                </v-card>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-btn
                  color="#614021"
                  class="white--text my-2"
                  @click="pdfGraphsTables"
                >
                  <v-icon left color="#c5d13f">mdi-download</v-icon>
                  baixar tabelas
                </v-btn>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
              <div class="small">
                <line-chart :chart-data="datacollectionSangue"></line-chart>
              </div>
              <v-row class="d-flex justify-center mx-4">
                <div v-for="element in checkSangue" :key="element.nome">
                  <v-checkbox
                    class="pr-4"
                    v-model="element.show"
                    :label="element.label"
                    :color="element.color"
                    @click="fillDataSangue"
                  ></v-checkbox>
                </div>
              </v-row>
              <v-row class="d-flex justify-center mx-4 mt-4">
                <v-card color="#D6DBDF" width="100%">
                  <v-row>
                    <v-col cols="2">Medida</v-col>
                    <v-col cols="2"
                      ><small>{{ datatableSangueLabels[0] }}</small></v-col
                    >
                    <v-col cols="2"
                      ><small>{{ datatableSangueLabels[1] }}</small></v-col
                    >
                    <v-col cols="2"
                      ><small>{{ datatableSangueLabels[2] }}</small></v-col
                    >
                    <v-col cols="2"
                      ><small>{{ datatableSangueLabels[3] }}</small></v-col
                    >
                    <v-col cols="2">Total</v-col>
                  </v-row>
                </v-card>
              </v-row>
              <v-row
                v-for="element in checkSangue"
                :key="element.nome"
                class="d-flex justify-center mx-4"
              >
                <v-card outlined flat width="100%">
                  <v-row>
                    <v-col cols="2" class="d-flex align-center">{{
                      element.label
                    }}</v-col>
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableSangueData(element.label, 0) }}
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableSangueData(element.label, 1) }}
                      <small
                        class="mx-1"
                        :style="
                          filter_datatableSangueDataChangeStyle(
                            element.label,
                            1
                          )
                        "
                        >{{
                          filter_datatableSangueDataChange(element.label, 1)
                        }}</small
                      ></v-col
                    >
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableSangueData(element.label, 2) }}
                      <small
                        class="mx-1"
                        :style="
                          filter_datatableSangueDataChangeStyle(
                            element.label,
                            2
                          )
                        "
                        >{{
                          filter_datatableSangueDataChange(element.label, 2)
                        }}</small
                      ></v-col
                    >
                    <v-col cols="2" class="d-flex align-center justify-center"
                      >{{ filter_datatableSangueData(element.label, 3) }}
                      <small
                        class="mx-1"
                        :style="
                          filter_datatableSangueDataChangeStyle(
                            element.label,
                            3
                          )
                        "
                        >{{
                          filter_datatableSangueDataChange(element.label, 3)
                        }}</small
                      ></v-col
                    >
                    <v-col
                      cols="2"
                      class="d-flex align-center"
                      :style="
                        filter_datatableSangueDataTotalChangeStyle(
                          element.label
                        )
                      "
                      >{{
                        filter_datatableSangueDataTotalChange(element.label)
                      }}</v-col
                    >
                  </v-row>
                </v-card>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="routerDialog" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title>Deseja editar esta dieta agora?</v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" class="my-4" @click="confirmEditarDieta">
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDietasAll" max-width="80%" height="89%" multiple>
      <v-card elevation="5">
        <v-card-title class="text-h6"> Dietas prescritas </v-card-title>
        <v-card-text>
          <v-card v-for="dieta in dietaRefs" :key="dieta.titulo" outlined>
            <v-card-text>
              <v-row class="text-subtitle-1 d-flex align-center my-1">
                <div v-if="dieta.ativo">
                  {{ dieta.titulo }}, {{ dieta.kcal.toFixed() }} kcal (
                  {{ dieta.createdAt.toDate().getDate() }}/{{
                    dieta.createdAt.toDate().getMonth() + 1
                  }}/{{ dieta.createdAt.toDate().getYear() + 1900 }}
                  {{ dieta.createdAt.toDate().getHours() }}:{{
                    stringify(dieta.createdAt.toDate().getMinutes())
                  }}
                  )
                  <div v-if="dieta.isNew" class="red--text">
                    <strong> NOVO ! </strong>
                  </div>
                </div>
                <div v-else>
                  <strike>
                    {{ dieta.titulo }}, {{ dieta.kcal.toFixed() }} kcal (
                    {{ dieta.createdAt.toDate().getDate() }}/{{
                      dieta.createdAt.toDate().getMonth() + 1
                    }}/{{ dieta.createdAt.toDate().getYear() + 1900 }}
                    {{ dieta.createdAt.toDate().getHours() }}:{{
                      stringify(dieta.createdAt.toDate().getMinutes())
                    }}
                    )</strike
                  >
                </div>
                <v-spacer></v-spacer>
                <div>
                  <v-tooltip bottom v-if="isIcon">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="grey"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteDieta(dieta.dietaDocId, dieta.titulo)"
                        class="mr-2 mx-2 black--text"
                      >
                        <v-icon color="#ff8080">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar Dieta</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="isIcon">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="grey"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="setDietaEditar(dieta.dietaDocId)"
                        class="mr-2 mx-2 black--text"
                      >
                        <v-icon color="grey">{{ icons.mdiTableEdit }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Dieta</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="isIcon">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="desAtivarDieta(dieta)"
                        icon
                      >
                        <v-icon v-if="!dieta.ativo" color="red"
                          >mdi-food-apple-outline</v-icon
                        >
                        <v-icon v-if="dieta.ativo" color="green"
                          >mdi-food-apple</v-icon
                        >
                      </v-btn>
                    </template>
                    <span v-if="!dieta.ativo"
                      >Reativar "{{ dieta.titulo }}"</span
                    >
                    <span v-if="dieta.ativo"
                      >Desativar "{{ dieta.titulo }}"</span
                    >
                  </v-tooltip>

                  <v-tooltip bottom v-if="isIcon" color="#c5d13f">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="grey"
                        @click="turnToCheckbox"
                        icon
                        :disabled="!dieta.ativo"
                      >
                        <v-icon color="#c5d13f">mdi-cart-arrow-down</v-icon>
                      </v-btn>
                    </template>
                    <span>Criar lista de Compras</span>
                  </v-tooltip>

                  <v-checkbox
                    v-if="!isIcon"
                    color="#614021"
                    v-model="dieta.includedInGroceryList"
                    label="incluir na lista de compras"
                    :disabled="!dieta.ativo"
                  >
                  </v-checkbox>

                  <v-tooltip bottom v-if="isIcon">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#614021"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          dietaDownloadPdf(
                            dieta.dietaDocId,
                            dieta.titulo,
                            dieta.createdAt
                          )
                        "
                      >
                        <v-icon color="#c5d13f">{{ icons.mdiDownload }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Baixar Dieta</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="isIcon">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#c5d13f"
                        v-bind="attrs"
                        v-on="on"
                        @click="setDietaVisualizar(dieta.dietaDocId)"
                        class="mr-2 mx-2 black--text"
                      >
                        <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar Dieta</span>
                  </v-tooltip>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider v-if="!isIcon"></v-divider>
          <v-row v-if="!isIcon" class="d-flex justify-center">
            <v-btn
              color="#614021"
              dense
              @click="turnToIcons"
              class="my-1 mx-2 white--text"
            >
              <v-icon color="#c5d13f" left>mdi-arrow-left-circle</v-icon>
              Voltar
            </v-btn>
            <v-btn
              dense
              color="#c5d13f"
              @click="makeGroceryList"
              :disabled="calcListaDeCompras"
              class="my-1"
            >
              <v-progress-circular
                :size="20"
                :width="3"
                color="#614021"
                indeterminate
                v-if="calcListaDeCompras"
              ></v-progress-circular>
              <v-icon color="#614021" left v-else>mdi-download</v-icon>
              Baixar lista de compras
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFormulasAll" max-width="80%" height="80%" multiple>
      <v-card elevation="5">
        <v-card-title> Fórmulas prescritas </v-card-title>
        <v-card-text>
          <v-card v-for="formulaP in pacienteFormulasAll" :key="formulaP.nome">
            <v-row class="d-flex justify-center">
              <v-col cols="12" class="d-flex justify-space-between">
                <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                  {{ formulaP.nome }}, ({{ formulaP.dateStr }})
                </v-row>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteFormula(formulaP.id, formulaP.nome)"
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="#ff8080">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Deletar Fórmula</span>
                </v-tooltip>

                <v-tooltip bottom v-if="isIcon">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="setFormulaEditar(formulaP)"
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="grey">{{ icons.mdiTableEdit }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Fórmula</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#c5d13f"
                      v-bind="attrs"
                      v-on="on"
                      @click="setFormulaVisualizar(formulaP)"
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar Prescrição</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#614021"
                      v-bind="attrs"
                      v-on="on"
                      @click="formulaDownloadPdf(formulaP)"
                    >
                      <v-icon color="#c5d13f">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Baixar Prescrição</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAnamnesesAll" max-width="80%" height="80%" multiple>
      <v-card elevation="5">
        <v-card-title class="d-flex justify-center"> Anamneses </v-card-title>
        <v-card-text v-if="pacienteDados.anamneses > 0">
          <v-card v-for="anam in pacienteAnamnesesAll" :key="anam.id" outlined>
            <v-card-text class="d-flex justify-center">
              <v-textarea
                :label="anam.dateStr"
                auto-grow
                :value="anam.content"
                readonly
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text v-else>
          <v-row class="d-flex justify-center red--text">
            Nenhuma anamnese registrada!
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog
      v-model="showQuestionariosAll"
      max-width="80%"
      height="80%"
      multiple
    >
      <v-card elevation="5">
        <v-card-title class="text-h6"> Questionários </v-card-title>
        <v-card-text>
          <v-card
            v-for="quest in pacienteQuestionariosAll"
            :key="quest.id"
            width="95%"
            outlined
          >
            <v-row class="d-flex align-center mx-4">
              <v-col cols="8"> {{ quest.dateStr }}: {{ quest.titulo }} </v-col>
              <v-col cols="2" class="d-flex justify-center">
                <v-icon color="#008000" v-if="!quest.unread">mdi-check</v-icon>
              </v-col>
              <v-col cols="2" class="d-flex justify-center">
                <v-btn color="#614021" @click="setQuestVisualizar(quest)" icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- <v-dialog v-model="showSpecificQuest" max-width="80%" height="80%" multiple>
      <v-card class="my-2" outlined>
        <v-card-title class="d-flex justify-center text-h6">
          {{ questVis.titulo }}
        </v-card-title>
        <v-card-subtitle
          class="d-flex justify-center"
          v-if="questVis.autor !== null && questVis.autor !== 'externo'"
        >
          criado por: {{ questVis.autor }}
        </v-card-subtitle>
        <v-card-text>
          <v-row
            class="d-flex justify-center"
            v-for="(perg, pergIndex) in questVis.pergs"
            :key="pergIndex"
          >
            <v-card
              class="my-2"
              outlined
              style="background-color: rgba(37, 63, 75, 0.1)"
              width="90%"
            >
              <v-card-title>
                <v-row class="d-flex justify-center text-subtitle-2">
                  {{ perg.pergunta }}
                </v-row>
              </v-card-title>
              <v-card-text v-if="perg.tipo == 'number'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-row class="d-flex justify-center align-bottom">
                      <v-text-field
                        label="resposta"
                        dense
                        outlined
                        v-model="perg.textResp"
                        type="number"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="perg.tipo == 'text-field'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-row class="d-flex justify-center align-bottom">
                      <v-text-field
                        label="resposta"
                        dense
                        outlined
                        v-model="perg.textResp"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="perg.tipo == 'textarea'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-row class="d-flex justify-center align-bottom">
                      <v-textarea
                        label="resposta"
                        dense
                        outlined
                        v-model="perg.textResp"
                      ></v-textarea>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="perg.tipo == 'checkboxes'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-row
                      class="d-flex justify-center"
                      v-for="respCheckbox in perg.checkboxes"
                      :key="respCheckbox.label"
                    >
                      <v-checkbox
                        hide-details
                        :label="respCheckbox.label"
                        :input-value="
                          perg.arrayResp.includes(respCheckbox.label)
                        "
                        color="#c5d13f"
                      >
                      </v-checkbox>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="perg.tipo == 'radio'">
                <v-row>
                  <v-col cols="0" md="2"></v-col>
                  <v-col cols="12" md="8">
                    <v-radio-group column v-model="perg.arrayResp">
                      <v-radio
                        v-for="respRadio in perg.radios"
                        :key="respRadio.label"
                        :label="respRadio.label"
                        :input-value="perg.arrayResp.includes(respRadio.label)"
                        color="#c5d13f"
                      >
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="showVideoAulasAll" max-width="80%" height="80%" multiple>
      <v-card elevation="5">
        <v-card-title class="text-h6"> Vídeo-Aulas e Ebooks </v-card-title>
        <v-card-text>
          <v-card
            v-for="tema in pacienteVideoAulasAll"
            :key="tema.id"
            width="95%"
            outlined
          >
            <v-row class="d-flex align-center mx-4">
              <v-col cols="9">
                {{ tema.nome }}
              </v-col>
              <v-col cols="3" class="d-flex justify-center white--text">
                <v-btn
                  color="#614021"
                  @click="setTemaVisualizar(tema)"
                  class="white--text"
                >
                  <v-icon color="#c5d13f" left>mdi-eye</v-icon>
                  Ver as Aulas
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showQuestPreConsulta"
      max-width="80%"
      height="80%"
      multiple
    >
      <v-card elevation="5">
        <v-card-title class="text-h6"> Questionário Pré-Consulta </v-card-title>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSpecificVideoAula"
      max-width="80%"
      height="80%"
      multiple
    >
      <v-card>
        <v-card-title class="d-flex justify-center">
          {{ temaVis.nome }}</v-card-title
        >
        <v-card-text>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Vídeo-Aulas/Ebooks</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row
                  class="d-flex justify-center"
                  v-for="video in temaVis.videos"
                  :key="video.yt"
                >
                  <v-col cols="9">
                    {{ video.nome }}
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      color="#614021"
                      class="white--text"
                      @click="assistir(video)"
                    >
                      <v-icon color="#c5d13f" left>mdi-play</v-icon> Assistir
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Ebooks</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  class="d-flex justify-center"
                  v-for="ebook in temaVis.ebooks"
                  :key="ebook.url"
                >
                  <v-col cols="9">
                    {{ ebook.nome }}
                  </v-col>
                  <v-col cols="3">
                    <!-- <v-btn
                      color="#614021"
                      class="white--text"
                      :href="ebook.url"
                      target="_blank"
                    >
                      <v-icon color="#c5d13f" left
                        >mdi-book-open-variant</v-icon
                      >
                      Ler
                    </v-btn> -->
                    <v-btn
                      color="#614021"
                      class="white--text"
                      @click="ler(ebook)"
                    >
                      <v-icon color="#c5d13f" left
                        >mdi-book-open-variant</v-icon
                      >
                      Ler
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showExamesAll" max-width="80%" height="89%" multiple>
      <v-card elevation="5">
        <v-card-title> Exames prescritas </v-card-title>
        <v-card-text>
          <v-card v-for="examP in pacienteExamesAll" :key="examP.nome" outlined>
            <v-row class="d-flex justify-center">
              <v-col cols="12" class="d-flex justify-space-between">
                <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                  {{ examP.nome }} ({{ examP.dateStr }})
                </v-row>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteExame(examP.id, examP.nome)"
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="#ff8080">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Deletar Exame</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#614021"
                      v-bind="attrs"
                      v-on="on"
                      @click="exameDownloadPdf(examP)"
                    >
                      <v-icon color="#c5d13f">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Baixar Prescrição</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#c5d13f"
                      v-bind="attrs"
                      v-on="on"
                      @click="setExameVisualizar(examP.id)"
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="#614021">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar Prescrição</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showOrientacoesAll"
      max-width="80%"
      height="89%"
      multiple
    >
      <v-card elevation="5">
        <v-card-title> Orientações prescritas </v-card-title>
        <v-card-text>
          <v-card
            v-for="orientacaoP in pacienteOrientacoesAll"
            :key="orientacaoP.nome"
            outlined
          >
            <v-row class="d-flex justify-center">
              <v-col cols="12" class="d-flex justify-space-between">
                <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                  {{ orientacaoP.nome }} ({{ orientacaoP.dateStr }})
                </v-row>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        deleteOrientacao(orientacaoP.id, orientacaoP.nome)
                      "
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="#ff8080">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Deletar Orientação</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#614021"
                      v-bind="attrs"
                      v-on="on"
                      @click="orientacaoDownloadPdf(orientacaoP)"
                    >
                      <v-icon color="#c5d13f">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Baixar Prescrição</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#c5d13f"
                      v-bind="attrs"
                      v-on="on"
                      @click="setOrientacaoVisualizar(orientacaoP.id)"
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="#614021">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar Orientação</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAgendaAll" max-width="80%" height="80%" multiple>
      <v-card elevation="5">
        <v-card-title class="text-h6"> Agendamentos </v-card-title>
        <v-card-text>
          <v-card v-for="event in pacienteEvents" :key="event.id" outlined>
            <v-card>
              <v-row class="d-flex justify-center">
                <v-col cols="12" class="d-flex justify-space-between">
                  <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                    {{ event.name }}, {{ event.niceDate }}
                  </v-row>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#c5d13f"
                        v-bind="attrs"
                        v-on="on"
                        @click="visualizarEvent(event)"
                        class="mr-2 mx-2 black--text"
                      >
                        <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showComprasAll" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title class="text-h6"> Compras </v-card-title>
        <v-card-text>
          <v-card
            v-for="comp in pacienteComprasAll"
            :key="comp.millis"
            width="95%"
            outlined
          >
            <v-row class="d-flex align-center mx-4">
              <v-col cols="5">
                <v-row>{{ comp.tipo }}</v-row>
                <v-row
                  ><small> {{ comp.descr }}</small></v-row
                >
              </v-col>
              <v-col cols="3">
                {{ comp.dateStr }}
              </v-col>
              <v-col cols="1">
                <!-- <v-icon color="#614021">{{ formaIcon(comp.forma) }}</v-icon> -->

                <v-tooltip bottom color="#614021">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="precisouAgendamentoAutom(comp)"
                      @click="editarAgendamentoAutom(comp)"
                    >
                      <v-icon color="#614021">mdi-calendar</v-icon>
                    </v-btn> </template
                  ><span>Editar agendamentos automáticos</span></v-tooltip
                >
              </v-col>
              <v-col cols="2" class="green--text">
                {{ niceString(comp.valor) }}
              </v-col>
              <v-col cols="1">
                <v-btn icon @click="setCompraVisualizar(comp)">
                  <v-icon color="#614021">mdi-eye</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showPacienteDados" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title class="text-h6">
          Dados pessoais do paciente
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row class="d-flex justify-center align-center">
                <v-col cols="12" md="5">
                  <v-text-field
                    :prepend-icon="icons.mdiAccount"
                    v-model="pacienteDados.nome"
                    :rules="rules.requiredField"
                    label="Nome"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-radio-group v-model="pacienteDados.sexo" column>
                    <v-radio
                      label="feminino"
                      value="feminino"
                      color="#ff00ff"
                    ></v-radio>
                    <v-radio
                      label="masculino"
                      value="masculino"
                      color="#0080ff"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6" md="2">
                  <v-checkbox
                    v-model="pacienteDados.gestante"
                    label="gestante"
                    color="#c5d13f"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    ref="menu"
                    v-model="menuDataNascimento"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="displayDataNasc"
                        label="Data de Nascimento"
                        locale="pt-br"
                        :prepend-icon="icons.mdiCalendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      locale="pt-br"
                      v-model="pacienteDados.dataNascimento"
                      :max="new Date().toISOString().substring(0, 10)"
                      min="1920-01-01"
                      @change="save"
                      color="#614021"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="6">
                  <v-text-field
                    :prepend-icon="icons.mdiPhone"
                    v-model="pacienteDados.telefone"
                    label="Telefone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :prepend-icon="icons.mdiWhatsapp"
                    v-model="pacienteDados.whatsapp"
                    label="WhatsApp"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="d-flex justify-center">
                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    :prepend-icon="icons.mdiHospitalBuilding"
                    v-model="pacienteDados.convenio"
                    label="Convênio"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" md="6">
                  <v-autocomplete
                    :prepend-icon="icons.mdiBullseyeArrow"
                    v-model="pacienteDados.objetivo"
                    :items="objetivos"
                    label="Objetivo"
                    required
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :prepend-icon="icons.mdiEmail"
                    v-model="pacienteDados.email"
                    @change="emailFoiEditado = true"
                    :rules="rules.emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="2"> </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :prepend-icon="icons.mdiMapMarker"
                    v-model="pacienteDados.endereco"
                    label="Endereço"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :prepend-icon="icons.mdiHomeCityOutline"
                    v-model="pacienteDados.bairro"
                    label="Bairro"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2"> </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="4">
                  <v-text-field
                    :prepend-icon="icons.mdiMailboxOpenOutline"
                    v-model="pacienteDados.cep"
                    label="CEP"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :prepend-icon="icons.mdiCity"
                    v-model="pacienteDados.cidade"
                    label="Cidade"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :prepend-icon="icons.mdiFlagVariantOutline"
                    v-model="pacienteDados.estado"
                    label="Estado / País"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="2"> </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :prepend-icon="icons.mdiAccountDetailsOutline"
                    v-model="pacienteDados.cpf"
                    label="CPF"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2"> </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="4"> </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :prepend-icon="icons.mdiHospitalBuilding"
                    v-model="pacienteDados.convenio"
                    label="Convênio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4"> </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="updatePacienteDados"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSpecificCompra"
      max-width="80%"
      height="80%"
      multiple
    >
      <v-card>
        <v-row class="d-flex align-center mx-4">
          <v-col cols="8">
            <v-row>{{ compraVis.tipo }}</v-row
            ><v-row>
              <small>
                {{ compraVis.descr }}
              </small>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-chip color="#c5d13f" class="white--text">
                {{ niceString(compraVis.valor) }}
              </v-chip>
              <v-icon v-if="compraVis.descontoAct" color="orange" class="mx-1">
                mdi-sale
              </v-icon>
            </v-row>
            <v-row class="text-caption mt-1">
              <v-icon color="black" left small>{{
                formaIcon(compraVis.forma)
              }}</v-icon>
              {{ formaStr(compraVis.forma) }}
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="compraVis.pacienteName != ''" class="mx-4">
          {{ compraVis.pacienteName }}
        </v-row>
        <div
          v-if="
            compraVis.pacotes[0] !== undefined &&
              compraVis.pacotes[0].dataInicio !== undefined &&
              compraVis.pacotes[0].dataInicio !== null
          "
          class="mx-4"
        >
          <!-- <v-data-table
            :headers="pacoteHeaders"
            :items="compraVis.pacotes"
            class="elevation-1"
            hide-default-footer
          ></v-data-table> -->
          <v-row
            v-for="(pac, pacIndex) in compraVis.pacotes"
            :key="pacIndex"
            class="d-flex justify-center my-1"
          >
            <strong> {{ pac.def2 }} </strong>, (início:
            {{ niceDateMonth(pac.millisInicio) }})
          </v-row>
        </div>
        <!-- <v-row
          v-if="precisouAgendamentoAutom(compraVis.tipo)"
          class="d-flex justify-center mx-4"
        >
          <v-btn
            color="#c5d13f"
            dense
            @click="editarAgendamentoAutom(compraVis)"
          >
            <v-icon left color="#614021">mdi-calendar</v-icon>
            Editar agendamento automático</v-btn
          >
        </v-row> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSpecificEvent" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title>
          {{ eventVis.name }}
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center"
            >{{ readableStart(eventVis.start) }}-{{ readableEnd(eventVis.end) }}
          </v-row>
          <v-row class="d-flex justify-center"
            >Obs.: {{ eventVis.details }}
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSpecificDieta" max-width="80%" height="80%" multiple>
      <v-card>
        <v-expansion-panels v-model="panelDieta" multiple>
          <v-expansion-panel
            v-for="refeicao in dietaRefeicoes"
            :key="refeicao.nomeRefeicao"
          >
            <v-expansion-panel-header>
              <v-row class="text-h6">
                {{ refeicao.nomeRefeicao }},
                {{ refeicao.horarioRefeicao }}
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels
                v-model="panelSubs"
                multiple
                v-show="refeicao.alimentosList[0].gramas > 0"
              >
                <v-expansion-panel
                  v-for="alimento in refeicao.alimentosList"
                  :key="alimento.nome"
                >
                  <v-expansion-panel-header>
                    <p
                      v-if="alimento.quantidade < 2"
                      class="text-p black--text"
                    >
                      {{ alimento.quantidade }} {{ alimento.medida }} de
                      {{ alimento.nome }} ({{ alimento.gramas }}g total)
                    </p>
                    <p
                      v-else-if="alimento.quantidade > 1"
                      class="text-p black--text"
                    >
                      {{ alimento.quantidade }} ({{ alimento.medida }}) de
                      {{ alimento.nome }} ({{ alimento.gramas }}g total)
                    </p>
                    <p v-else class="text-p black--text">
                      {{ alimento.gramas }}g de {{ alimento.nome }}
                    </p>
                    <template v-slot:actions>
                      <v-icon
                        color="black"
                        v-if="alimento.toSubstitute != undefined"
                        @click="log(alimento.toSubstitute)"
                      >
                        {{ icons.mdiAutorenew }}
                      </v-icon>
                      <v-icon v-else> </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="ml-6">
                    <v-row
                      class="mt-1"
                      v-show="alimento.toSubstitute != undefined"
                    >
                      <strong> Possíveis substituições </strong>
                    </v-row>
                    <v-simple-table v-show="alimento.toSubstitute != undefined">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Alimento</th>
                            <th class="text-left">Quantidade</th>
                            <th class="text-left">Medida caseira</th>
                            <th class="text-left">Gramas totais</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(sub, subIndex) in alimento.toSubstitute"
                            :key="subIndex"
                          >
                            <td>{{ sub.nome }}</td>
                            <td>{{ sub.qtdSub }}</td>
                            <td>{{ sub.medida_caseira_padrao }}</td>
                            <td>{{ sub.gramas }}g</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-expansion-panels
                v-model="panelReceitas"
                multiple
                v-show="refeicao.receitasList[0].porcoes > 0"
              >
                <v-expansion-panel
                  v-for="receita in refeicao.receitasList"
                  :key="receita.titulo"
                >
                  <v-expansion-panel-header class="text-p black--text">
                    {{ receita.porcoes }} {{ porcao(receita.porcoes) }} de "{{
                      receita.titulo
                    }}"
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="ml-6">
                    <v-row>
                      <strong> Tempo de Preparo: </strong>
                      {{ receita.tempoPreparo }}
                    </v-row>
                    <v-row> <strong> Modo de Preparo: </strong> </v-row>
                    <v-row> {{ receita.modoPreparo }} </v-row>
                    <v-row>
                      <strong> Rendimento: </strong> {{ receita.rendimento }}
                    </v-row>
                    <v-row class="mt-1">
                      <strong> Ingredientes: </strong>
                    </v-row>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Alimento</th>
                            <th class="text-left">Quantidade</th>
                            <th class="text-left">Medida caseira</th>
                            <th class="text-left">Gramas totais</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="alimentoR in receita.alimentosList"
                            :key="alimentoR.nome"
                          >
                            <td>{{ alimentoR.nome }}</td>
                            <td>{{ alimentoR.quantidade }}</td>
                            <td>{{ alimentoR.medida }}</td>
                            <td>{{ alimentoR.gramas }}g</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-card class="pr-2" min-height="50px" elevation="1">
                <v-card-text
                  v-show="refeicao.observacao"
                  class="text-p black--text ml-2"
                >
                  <strong>Observações: </strong> {{ refeicao.observacao }}
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSpecificFormula"
      max-width="80%"
      height="80%"
      multiple
    >
      <v-card>
        <v-card-title>
          {{ formulaPVis.nome }}, ({{ formulaPVis.dateStr }})
        </v-card-title>
        <v-card-text>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel
              v-for="formulaD in formulaPVis.prescricaoC.formulasList"
              :key="formulaD.titulo"
            >
              <v-expansion-panel-header>
                {{ formulaD.titulo }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-textarea readonly v-model="formulaD.prescricoes">
                </v-textarea>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSpecificExame" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title>
          {{ examPVis.nome }}, ({{ examPVis.dateStr }})
        </v-card-title>
        <v-card-text>
          <v-textarea readonly v-model="examPVis.examText"> </v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSpecificOrientacao"
      max-width="80%"
      height="80%"
      multiple
    >
      <v-card>
        <v-card-title>
          {{ orientacaoPVis.nome }}, ({{ orientacaoPVis.dateStr }})
        </v-card-title>
        <v-card-text>
          <v-textarea readonly v-model="orientacaoPVis.orientacaoText">
          </v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="verPacienteTimeline" max-width="80%" multiple>
      <v-card class="pa-4">
        <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
            v-for="(item, i) in pacienteTimeline"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
          >
            <span slot="opposite">{{ item.date }}</span>
            <v-card :color="item.color" dark>
              <v-card-title class="title"> {{ item.nome }} </v-card-title>
              <v-card-text class="white text--primary">
                <p>{{ item.text }}</p>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="item.type === 'dieta'"
                      :color="item.color"
                      class="mr-2"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click="visualizarTimelineItem(item)"
                    >
                      <v-icon>{{ icons.mdiEye }}</v-icon>
                    </v-btn> </template
                  ><span>Visualizar</span></v-tooltip
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="item.type === 'dieta'"
                      :color="item.color"
                      class="mr-2"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click="baixarTimelineItem(item)"
                    >
                      <v-icon>{{ icons.mdiDownload }}</v-icon>
                    </v-btn> </template
                  ><span>Baixar</span></v-tooltip
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="item.type === 'atendimento'"
                      :color="item.color"
                      class="mr-2"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click="visualizarTimelineItem(item)"
                    >
                      <v-icon>{{ icons.mdiFinance }}</v-icon>
                    </v-btn> </template
                  ><span>Ver evolução</span></v-tooltip
                >
                <v-btn :color="item.color" :to="item.to" class="mx-0" outlined>
                  {{ item.toText }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showBioimpAll" max-width="800" multiple>
      <v-card elevation="5">
        <v-card-title class="text-h6"> Bioimpedâncias </v-card-title>
        <v-card-text>
          <div
            v-for="(atendimento, atendimentoIndex) in atendimentos"
            :key="atendimentoIndex"
          >
            <div v-if="atendimento.bioimp !== ''">
              <v-card outlined>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                      {{ labels[atendimentoIndex] }}
                    </v-row>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="deleteBioimp(atendimento)"
                        >
                          <v-icon color="red">mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Deletar bioimpedância</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#614021"
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2 mx-2 black--text"
                          :href="atendimento.bioimp"
                          target="_blank"
                        >
                          <v-icon color="#c5d13f">{{ icons.mdiEye }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Visualizar/Baixar Bioimpedância</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div v-else></div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteBioimpDialog" max-width="500">
      <v-card>
        <v-card-title v-if="deletedBioimp">
          Bioimpedância deletada!
        </v-card-title>
        <v-card-title v-else> Deletar bioimpedância? </v-card-title>
        <v-card-text v-if="deletingBioimp && !deletedBioimp">
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingBioimp && deletedBioimp">
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingBioimp && !deletedBioimp">
          <v-row class="d-flex justify-center">
            Deletar bioimpedância do dia
            {{ niceDataStr(deleteBioimpAtendId) }} de forma irrevogável?
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            class="white--text"
            :disabled="deletingBioimp"
            @click="deleteBioimpDialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            class="red--text"
            v-if="!deletedBioimp"
            :disabled="deletingBioimp"
            @click="doDeleteBioimp"
          >
            <v-icon left color="red"> mdi-trash-can-outline </v-icon>
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDietaDialog" max-width="500">
      <v-card>
        <v-card-title v-if="deletedDieta">
          Dieta deletada!
        </v-card-title>
        <v-card-title v-else> Deletar Dieta? </v-card-title>
        <v-card-text v-if="deletingDieta && !deletedDieta">
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingDieta && deletedDieta">
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingDieta && !deletedDieta">
          <v-row class="d-flex justify-center">
            Deletar dieta "{{ deleteDietaTitulo }}" de forma irrevogável?
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            class="white--text"
            :disabled="deletingDieta"
            @click="deleteDietaDialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            class="red--text"
            v-if="!deletedDieta"
            :disabled="deletingDieta"
            @click="doDeleteDieta"
          >
            <v-icon left color="red"> mdi-trash-can-outline </v-icon>
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteFormulaDialog" max-width="500">
      <v-card>
        <v-card-title v-if="deletedFormula">
          Formula deletada!
        </v-card-title>
        <v-card-title v-else> Deletar Formula? </v-card-title>
        <v-card-text v-if="deletingFormula && !deletedFormula">
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingFormula && deletedFormula">
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingFormula && !deletedFormula">
          <v-row class="d-flex justify-center">
            Deletar Formula "{{ deleteFormulaTitulo }}" de forma irrevogável?
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            class="white--text"
            :disabled="deletingFormula"
            @click="deleteFormulaDialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            class="red--text"
            v-if="!deletedFormula"
            :disabled="deletingFormula"
            @click="doDeleteFormula"
          >
            <v-icon left color="red"> mdi-trash-can-outline </v-icon>
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteExameDialog" max-width="500">
      <v-card>
        <v-card-title v-if="deletedExame">
          Exame deletada!
        </v-card-title>
        <v-card-title v-else> Deletar Exame? </v-card-title>
        <v-card-text v-if="deletingExame && !deletedExame">
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingExame && deletedExame">
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingExame && !deletedExame">
          <v-row class="d-flex justify-center">
            Deletar Exame "{{ deleteExameTitulo }}" de forma irrevogável?
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            class="white--text"
            :disabled="deletingExame"
            @click="deleteExameDialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            class="red--text"
            v-if="!deletedExame"
            :disabled="deletingExame"
            @click="doDeleteExame"
          >
            <v-icon left color="red"> mdi-trash-can-outline </v-icon>
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteOrientacaoDialog" max-width="500">
      <v-card>
        <v-card-title v-if="deletedOrientacao">
          Orientação deletada!
        </v-card-title>
        <v-card-title v-else> Deletar Orientação? </v-card-title>
        <v-card-text v-if="deletingOrientacao && !deletedOrientacao">
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingOrientacao && deletedOrientacao">
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingOrientacao && !deletedOrientacao">
          <v-row class="d-flex justify-center">
            Deletar Orientação "{{ deleteOrientacaoTitulo }}" de forma
            irrevogável?
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            class="white--text"
            :disabled="deletingOrientacao"
            @click="deleteOrientacaoDialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            class="red--text"
            v-if="!deletedOrientacao"
            :disabled="deletingOrientacao"
            @click="doDeleteOrientacao"
          >
            <v-icon left color="red"> mdi-trash-can-outline </v-icon>
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fotoCompareDialog" max-width="1000" multiple persistent>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="d-flex justify-center">
              <v-select
                :items="atendimentosFotos"
                v-model="antesFotosIndex"
                label='data "antes"'
                @change="changeFotoDates"
              ></v-select>
            </v-col>
            <v-col cols="6" class="d-flex justify-center">
              <v-select
                :items="atendimentosFotos"
                v-model="depoisFotosIndex"
                label='data "depois"'
                @change="changeFotoDates"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center">
            <v-btn
              class="mx-1 my-2"
              :color="compareSide == 'fre' ? '#c5d13f' : 'grey'"
              @click="compareSide = 'fre'"
            >
              <v-icon left color="#614021">mdi-account</v-icon>
              Frente
            </v-btn>
            <v-btn
              class="mx-1 my-2"
              :color="compareSide == 'tra' ? '#c5d13f' : 'grey'"
              @click="compareSide = 'tra'"
            >
              <v-icon left color="#614021">mdi-account-reactivate</v-icon>
              Trás
            </v-btn>
            <v-btn
              class="mx-1 my-2"
              :color="compareSide == 'esq' ? '#c5d13f' : 'grey'"
              @click="compareSide = 'esq'"
            >
              <v-icon left color="#614021">mdi-account-arrow-left</v-icon>
              Esquerda
            </v-btn>
            <v-btn
              class="mx-1 my-2"
              :color="compareSide == 'dir' ? '#c5d13f' : 'grey'"
              @click="compareSide = 'dir'"
            >
              <v-icon left color="#614021">mdi-account-arrow-right</v-icon>
              Direita
            </v-btn>
          </v-row>
          <v-row
            v-if="
              compareSide == 'fre' &&
                antesFotosIndex !== null &&
                depoisFotosIndex !== null
            "
          >
            <v-col cols="6">
              <v-row class="d-flex justify-center">
                <v-slider
                  v-model="zoomWidthAntesFre"
                  class="align-self-stretch"
                  min="200"
                  max="1000"
                  step="1"
                  color="#614021"
                ></v-slider>
              </v-row>
              <v-row class="d-flex flex-column justify-center align-center">
                <v-img
                  max-height="500"
                  :max-width="zoomWidthAntesFre"
                  :src="
                    atendimentos[antesFotosIndex].fotoFrenteUrl !== undefined
                      ? atendimentos[antesFotosIndex].fotoFrenteUrl
                      : ''
                  "
                ></v-img>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row class="d-flex justify-center">
                <v-slider
                  v-model="zoomWidthDepoisFre"
                  class="align-self-stretch"
                  min="200"
                  max="1000"
                  step="1"
                  color="#614021"
                ></v-slider>
              </v-row>
              <v-row class="d-flex flex-column justify-center align-center">
                <v-img
                  max-height="500"
                  :max-width="zoomWidthDepoisFre"
                  :src="
                    atendimentos[depoisFotosIndex].fotoFrenteUrl !== undefined
                      ? atendimentos[depoisFotosIndex].fotoFrenteUrl
                      : ''
                  "
                ></v-img>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-else-if="
              compareSide == 'tra' &&
                antesFotosIndex !== null &&
                depoisFotosIndex !== null
            "
          >
            <v-col cols="6">
              <v-row class="d-flex justify-center text-h6">
                {{ niceDate(atendimentos[antesFotosIndex].data) }}
              </v-row>
              <v-row class="d-flex justify-center">
                <v-slider
                  v-model="zoomWidthAntesTra"
                  class="align-self-stretch"
                  min="200"
                  max="1000"
                  step="1"
                  color="#614021"
                ></v-slider>
              </v-row>
              <v-row class="d-flex flex-column justify-center align-center">
                <v-img
                  max-height="500"
                  :max-width="zoomWidthAntesTra"
                  :src="
                    atendimentos[antesFotosIndex].fotoAtrasUrl !== undefined
                      ? atendimentos[antesFotosIndex].fotoAtrasUrl
                      : ''
                  "
                ></v-img>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row class="d-flex justify-center text-h6">
                {{ niceDate(atendimentos[depoisFotosIndex].data) }}
              </v-row>
              <v-row class="d-flex justify-center">
                <v-slider
                  v-model="zoomWidthDepoisTra"
                  class="align-self-stretch"
                  min="200"
                  max="1000"
                  step="1"
                  color="#614021"
                ></v-slider>
              </v-row>
              <v-row class="d-flex flex-column justify-center align-center">
                <v-img
                  max-height="500"
                  :max-width="zoomWidthDepoisTra"
                  :src="
                    atendimentos[depoisFotosIndex].fotoAtrasUrl !== undefined
                      ? atendimentos[depoisFotosIndex].fotoAtrasUrl
                      : ''
                  "
                ></v-img>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-else-if="
              compareSide == 'esq' &&
                antesFotosIndex !== null &&
                depoisFotosIndex !== null
            "
          >
            <v-col cols="6">
              <v-row class="d-flex justify-center text-h6">
                {{ niceDate(atendimentos[antesFotosIndex].data) }}
              </v-row>
              <v-row class="d-flex justify-center">
                <v-slider
                  v-model="zoomWidthAntesEsq"
                  class="align-self-stretch"
                  min="200"
                  max="1000"
                  step="1"
                  color="#614021"
                ></v-slider>
              </v-row>
              <v-row class="d-flex flex-column justify-center align-center">
                <v-img
                  max-height="500"
                  :max-width="zoomWidthAntesEsq"
                  :src="
                    atendimentos[antesFotosIndex].fotoEsquerdaUrl !== undefined
                      ? atendimentos[antesFotosIndex].fotoEsquerdaUrl
                      : ''
                  "
                ></v-img>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row class="d-flex justify-center text-h6">
                {{ niceDate(atendimentos[depoisFotosIndex].data) }}
              </v-row>
              <v-row class="d-flex justify-center">
                <v-slider
                  v-model="zoomWidthDepoisEsq"
                  class="align-self-stretch"
                  min="200"
                  max="1000"
                  step="1"
                  color="#614021"
                ></v-slider>
              </v-row>
              <v-row class="d-flex flex-column justify-center align-center">
                <v-img
                  max-height="500"
                  :max-width="zoomWidthDepoisEsq"
                  :src="
                    atendimentos[depoisFotosIndex].fotoEsquerdaUrl !== undefined
                      ? atendimentos[depoisFotosIndex].fotoEsquerdaUrl
                      : ''
                  "
                ></v-img>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-else-if="
              compareSide == 'dir' &&
                antesFotosIndex !== null &&
                depoisFotosIndex !== null
            "
          >
            <v-col cols="6">
              <v-row class="d-flex justify-center text-h6">
                {{ niceDate(atendimentos[antesFotosIndex].data) }}
              </v-row>
              <v-row class="d-flex justify-center">
                <v-slider
                  v-model="zoomWidthAntesDir"
                  class="align-self-stretch"
                  min="200"
                  max="1000"
                  step="1"
                  color="#614021"
                ></v-slider>
              </v-row>
              <v-row class="d-flex flex-column justify-center align-center">
                <v-img
                  max-height="500"
                  :max-width="zoomWidthAntesDir"
                  :src="
                    atendimentos[antesFotosIndex].fotoDireitaUrl !== undefined
                      ? atendimentos[antesFotosIndex].fotoDireitaUrl
                      : ''
                  "
                ></v-img>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row class="d-flex justify-center text-h6">
                {{ niceDate(atendimentos[depoisFotosIndex].data) }}
              </v-row>
              <v-row class="d-flex justify-center">
                <v-slider
                  v-model="zoomWidthDepoisDir"
                  class="align-self-stretch"
                  min="200"
                  max="1000"
                  step="1"
                  color="#614021"
                ></v-slider>
              </v-row>
              <v-row class="d-flex flex-column justify-center align-center">
                <v-img
                  max-height="500"
                  :max-width="zoomWidthDepoisDir"
                  :src="
                    atendimentos[depoisFotosIndex].fotoDireitaUrl !== undefined
                      ? atendimentos[depoisFotosIndex].fotoDireitaUrl
                      : ''
                  "
                ></v-img>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="closeCompareDialog" color="#c5d13f">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showFotosAll"
      max-width="800"
      multiple
      :persistent="compareMode"
    >
      <v-card elevation="5">
        <v-card-title class="text-h6"> Fotos </v-card-title>
        <v-card-text>
          <div
            v-for="(atendimento, atendimentoIndex) in atendimentos"
            :key="atendimentoIndex"
          >
            <div
              v-if="
                atendimento.fotoFrenteUrl !== '' ||
                  atendimento.fotoAtrasUrl !== '' ||
                  atendimento.fotoEsquerdaUrl !== '' ||
                  atendimento.fotoDireitaUrl !== ''
              "
            >
              <v-card outlined>
                <v-card-text>
                  <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                    <!-- <div>{{ labels[atendimentoIndex] }}</div> -->
                    <div>{{ niceDate(atendimento.data) }}</div>
                    <v-spacer></v-spacer>
                    <div>
                      <!-- <v-btn dense :color="isAntesColor(atendimentoIndex)" v-if="compareMode" @click="setAntes(atendimentoIndex)"> 
                        comparar (usar como "antes")
                      </v-btn>
                      <v-btn class="ml-1" dense :color="isDepoisColor(atendimentoIndex)" v-if="compareMode && antesFotosIndex !== null" @click="setDepois(atendimentoIndex)"> 
                        comparar (usar como "depois")
                      </v-btn> -->

                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="deleteFotos(atendimento)"
                          >
                            <v-icon color="red">mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Deletar fotos</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 mx-2 black--text"
                            :href="atendimento.fotoFrenteUrl"
                            target="_blank"
                            icon
                          >
                            <v-icon color="#614021">mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <span>Frente</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 mx-2 black--text"
                            :href="atendimento.fotoAtrasUrl"
                            target="_blank"
                            icon
                          >
                            <v-icon color="#614021"
                              >mdi-account-reactivate</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Trás</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 mx-2 black--text"
                            :href="atendimento.fotoEsquerdaUrl"
                            target="_blank"
                            icon
                          >
                            <v-icon color="#614021"
                              >mdi-account-arrow-left</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Esquerda</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 mx-2 black--text"
                            :href="atendimento.fotoDireitaUrl"
                            target="_blank"
                            icon
                          >
                            <v-icon color="#614021"
                              >mdi-account-arrow-right</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Direita</span>
                      </v-tooltip>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <div v-else></div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <!-- <v-btn v-if="compareMode" color="grey" @click="closeFotosDialog" class="my-2 mx-1">Fechar</v-btn> -->
          <!-- <v-btn color="#c5d13f" @click="compareMode = !compareMode" class="my-2 mx-1">
            <span v-if="!compareMode">Comparar fotos</span>
            <span v-else>Encerrar comparação</span>
          </v-btn> -->
          <!-- <v-btn v-if="compareMode" color="#c5d13f" :disabled="antesFotosIndex == null || depoisFotosIndex == null" class="my-2" @click="verAntesDepois">Mostrar comparação</v-btn> -->
          <v-btn color="#c5d13f" class="my-2" @click="makeAtendimentosFotos"
            >comparar fotos</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteFotosDialog" max-width="500">
      <v-card>
        <v-card-title v-if="deletedFotos"> Fotos deletadas! </v-card-title>
        <v-card-title v-else> Deletar fotos? </v-card-title>
        <v-card-text v-if="deletingFotos && !deletedFotos">
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingFotos && deletedFotos">
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="!deletingFotos && !deletedFotos">
          <v-row class="d-flex justify-center">
            Deletar fotos do dia
            {{ niceDataStr(deleteFotosAtendId) }} de forma irrevogável?
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            class="white--text"
            :disabled="deletingFotos"
            @click="deleteFotosDialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            class="red--text"
            v-if="!deletedFotos"
            :disabled="deletingFotos"
            @click="doDeleteFotos"
          >
            <v-icon left color="red"> mdi-trash-can-outline </v-icon>
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showExamResultsAll" max-width="800" multiple>
      <v-card elevation="5">
        <v-card-title class="text-h6"> Fotos </v-card-title>
        <v-card-text>
          <div
            v-for="(atendimento, atendimentoIndex) in atendimentos"
            :key="atendimentoIndex"
          >
            <div v-if="atendimento.exam1 !== ''">
              <v-card outlined>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                      {{ labels[atendimentoIndex] }}
                    </v-row>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="atendimento.exam1 !== ''"
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2 mx-2 black--text"
                          :href="atendimento.exam1"
                          target="_blank"
                          icon
                        >
                          <v-icon color="#614021"
                            >mdi-numeric-1-box-multiple</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ atendimento.exam1Nome }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="atendimento.exam2 !== ''"
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2 mx-2 black--text"
                          :href="atendimento.exam2"
                          target="_blank"
                          icon
                        >
                          <v-icon color="#614021"
                            >mdi-numeric-2-box-multiple</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ atendimento.exam2Nome }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="atendimento.exam3 !== ''"
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2 mx-2 black--text"
                          :href="atendimento.exam3"
                          target="_blank"
                          icon
                        >
                          <v-icon color="#614021"
                            >mdi-numeric-3-box-multiple</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ atendimento.exam3Nome }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div v-else></div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showPacientePratos" max-width="80%">
      <v-card v-if="loadingPratos">
        <v-card-title class="d-flex justify-center"
          >Fotos dos pratos (carregando...)</v-card-title
        >
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center"
          >Fotos dos pratos</v-card-title
        >
        <v-card-subtitle class="d-flex justify-center">
          <v-select
            outlined
            label="Mês"
            :items="mesesPratos"
            v-model="mesPratos"
            no-data-text="Nenhum mês com fotos"
            class="my-2"
            @change="setMesPratos"
          >
          </v-select>
        </v-card-subtitle>
        <v-card-text v-if="mesPratosX !== undefined && mesPratosX.length > 0">
          <v-row
            class="d-flex justify-center"
            v-for="(chunk, chunkIndex) in chunks"
            :key="chunkIndex"
          >
            <v-chip
              class="mx-1"
              v-for="(item, itemIndex) in chunk"
              :key="itemIndex"
              @click="showFotosRefeicoes(item)"
              :color="clickedShowPratosId(item)"
              >{{ niceDay(item) }}</v-chip
            >
          </v-row>
          <v-row class="d-flex justify-center" v-if="loadingRefeicoes">
            <v-progress-linear
              color="#c5d13f"
              indeterminate
              class="md-2"
            ></v-progress-linear>
          </v-row>
          <v-row
            class="d-flex justify-center"
            v-for="(refeicao, refIndex) in pRefeicoes"
            :key="refeicao.dayCardapio"
          >
            <v-card outlined class="my-1" width="90%">
              <v-card-subtitle
                >{{ refeicao.titulo }} ({{
                  refeicao.kcal
                }}
                KCAL)</v-card-subtitle
              >
              <v-card-text>
                <v-row class="d-flex justify-center"
                  ><small>Alimentos: {{ refeicao.alimentosStr }}</small></v-row
                >
                <v-row class="d-flex justify-center"
                  ><small>Receitas: {{ refeicao.receitasStr }}</small></v-row
                >
                <v-row
                  class="d-flex justify-center"
                  v-if="
                    refeicao.observacaoStr !== '' &&
                      refeicao.observacaoStr !== null &&
                      refeicao.observacaoStr !== undefined
                  "
                  ><small>Obs.:{{ refeicao.observacaoStr }}</small></v-row
                >
                <v-row class="d-flex justify-center">
                  <v-col cols="0" md="4"></v-col>
                  <v-col cols="12" md="4">
                    <v-img
                      :src="refeicao.fotoUrl"
                      class="my-1"
                      v-if="
                        refeicao.fotoUrl !== undefined ||
                          refeicao.fotoUrl !== null
                      "
                    ></v-img>
                  </v-col>
                  <v-col cols="0" md="4"></v-col>
                </v-row>
                <v-row
                  class="d-flex justify-center"
                  v-if="
                    refeicao.fotoUrl !== undefined && refeicao.fotoUrl !== null
                  "
                >
                  <v-col cols="12" md="2" class="d-flex justify-center">
                    <v-btn
                      outlined
                      color="#614021"
                      disabled
                      v-if="savingHeart == refIndex"
                    >
                      <v-progress-circular
                        :size="20"
                        :width="3"
                        color="#c5d13f"
                        indeterminate
                      ></v-progress-circular>
                    </v-btn>
                    <v-btn
                      outlined
                      color="#c5d13f"
                      @click="saveHeart(refIndex)"
                      v-else-if="savingHeart !== refIndex && !refeicao.heartN"
                    >
                      <v-icon color="#614021">mdi-heart-outline</v-icon>
                    </v-btn>
                    <v-btn
                      color="#c5d13f"
                      @click="removeHeart(refIndex)"
                      v-else-if="savingHeart !== refIndex && refeicao.heartN"
                    >
                      <v-icon color="#FF40B9">mdi-heart</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      label="comentário"
                      v-model="refeicao.commentN"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex justify-center">
                    <v-btn disabled v-if="savingComment == refIndex">
                      <v-progress-circular
                        :size="20"
                        :width="3"
                        color="#614021"
                        indeterminate
                      ></v-progress-circular>
                    </v-btn>
                    <v-btn
                      color="#c5d13f"
                      @click="saveComment(refIndex)"
                      v-else
                    >
                      <v-icon color="#00C5FF" v-if="savedComment == refIndex"
                        >mdi-check-all</v-icon
                      >
                      <span v-else>Salvar</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
        <v-card-text v-else class="d-flex justify-center"> ... </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#D6DBDF" @click="closeShowPacientePratos">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row
            class="d-flex justify-center my-2"
            v-if="progressText !== null"
          >
            {{ progressText }}
          </v-row>
          <v-row
            class="d-flex justify-center text-subtitle2"
            v-if="progressText !== null"
          >
            Email antigo:
          </v-row>
          <v-row
            class="d-flex justify-center mb-2 text-h6 red--text"
            v-if="progressText !== null"
          >
            {{ gotPacienteEmail }}
          </v-row>
          <v-row
            class="d-flex justify-center text-subtitle2"
            v-if="progressText !== null"
          >
            Email novo:
          </v-row>
          <v-row
            class="d-flex justify-center mb-1 text-h6 green--text"
            v-if="progressText !== null"
          >
            {{ pacienteDados.email }}
          </v-row>
          <v-row
            class="d-flex justify-center my-3 red--text"
            v-if="errorFauthMsg !== null"
          >
            Erro: {{ errorFauthMsg }}
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-row class="d-flex justify-center">
      Quicklink ao paciente:
    </v-row> -->
    <v-row class="d-flex justify-center">
      <v-autocomplete
        ref="pacienteFilter"
        label="Paciente"
        single-line
        outlined
        class="my-2 mx-3"
        v-model="pacienteDocID"
        :items="pacientes"
        no-data-text="..."
        @change="getPacienteDados"
      ></v-autocomplete>
    </v-row>
    <v-row class="d-flex justify-center">
      <p v-if="nutriResponsavel !== undefined && nutriResponsavel !== null">
        Nutricionista responsável: {{ nutriResponsavel }},
        {{ getNutirName(nutriResponsavel) }}
      </p>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-btn
        color="grey lighten-3"
        @click="setNutriRespDialog = true"
        v-if="nutriResponsavel !== undefined && nutriResponsavel !== null"
        small
        class="mb-3 ml-2"
        >Alterar</v-btn
      >
      <v-btn
        color="grey lighten-3"
        @click="setNutriRespDialog = true"
        v-else
        small
        class="mb-3"
        :disabled="pacienteDocID == null || pacienteDocID == ''"
        >Def. nutri. resp.</v-btn
      >
    </v-row>
    <v-row class="d-flex justify-center">
      <v-alert
        type="warning"
        dense
        color="#ff6666"
        class="white--text"
        v-if="pacienteDados.imagemNao"
      >
        Paciente não autoriza divulgação de imagem!
      </v-alert>
    </v-row>
    <!-- <v-row class="d-flex justify-center my-4">
        <v-btn
          color="grey"
          @click="setPacienteVerHistorico"
          :disabled="noPacienteDocID"
          >Ver dados e histórico</v-btn
        >
      </v-row> -->

    <v-expansion-panels>
      <v-expansion-panel v-model="panel0">
        <v-expansion-panel-header
          >Anotações administrativas</v-expansion-panel-header
        >
        <v-expansion-panel-content class="d-flex justify-center align-center">
          <v-textarea
            v-model="pacienteDados.anotacoesAdmin"
            class="mx-2"
            outlined
            :disabled="noPacienteDocID"
          >
          </v-textarea>
          <v-btn
            color="#c5d13f"
            @click="saveAnotacoesAdmin"
            :disabled="noPacienteDocID"
            >Salvar</v-btn
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel1">
        <v-expansion-panel-header>Prescrições</v-expansion-panel-header>
        <v-expansion-panel-content class="d-flex justify-center">
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visDietasAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-food-apple </v-icon>
              Dietas
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visFormulasAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-text-box </v-icon> Formulas
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visExamesAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-needle </v-icon> Exames
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visOrientacoesAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f">
                mdi-clipboard-alert-outline
              </v-icon>
              Orientações
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visVideoAulasAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-television-play </v-icon>
              Vídeo-Aulas
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visQuestionarioPreConsulta"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-file-question </v-icon>
              Quest. Pré-Consulta
            </v-btn>
          </v-row>
          <!-- <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visQuestionarios"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-timeline-question </v-icon>
              Questionários
            </v-btn>
          </v-row> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel2">
        <v-expansion-panel-header>Dados</v-expansion-panel-header>
        <v-expansion-panel-content class="d-flex justify-center">
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visAnamneses"
              :disabled="noPacienteDocID"
              v-if="
                funcionarioOnline.papel == 'Nutricionista' ||
                  funcionarioOnline.papel == 'Nutricionista 1' ||
                  funcionarioOnline.papel == 'Nutricionista 2' ||
                  funcionarioOnline.papel == 'Nutricionista 3' ||
                  funcionarioOnline.papel == 'Administradora' ||
                  funcionarioOnline.papel == 'Proprietária' ||
                  funcionarioOnline.papel == 'Proprietário'
              "
            >
              <v-icon left color="#c5d13f"> mdi-text-box-edit </v-icon>
              Anamneses
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visEvolucao"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-finance </v-icon> Evolução
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visBioimpAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-human </v-icon> Bioimp.
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visFotosAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-camera </v-icon> Fotos
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visExamResultsAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-image-album </v-icon> Exam.
              real.
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel3">
        <v-expansion-panel-header>Mais</v-expansion-panel-header>
        <v-expansion-panel-content class="d-flex justify-center">
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visTimeline"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-timeline-text </v-icon>
              Timeline
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visAgendaAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-calendar </v-icon> Agenda
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visComprasAll"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-cart-variant </v-icon> Compras
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visPacienteDados"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-account </v-icon> Dados pess.
            </v-btn>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              class="mr-1"
              text
              @click="visPacientePratos"
              :disabled="noPacienteDocID"
            >
              <v-icon left color="#c5d13f"> mdi-food-fork-drink </v-icon>
              Pratos
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-divider></v-divider>
    <v-row class="d-flex justify-center my-4">
      <v-btn
        dense
        color="#c5d13f"
        @click="pacienteParaAtendimento"
        :disabled="noPacienteDocID"
        >+ Antropometría</v-btn
      >
    </v-row>
    <v-row class="d-flex justify-center my-4">
      <v-btn
        dense
        color="#614021"
        class="white--text mx-2"
        @click="pacienteParaPreAtendimento"
        :disabled="noPacienteDocID"
        >quest. + anamnese</v-btn
      >
    </v-row>

    <v-dialog max-width="600" v-model="questLiberadoDialog">
      <v-card>
        <v-card-title> Questionário liberado </v-card-title>
        <v-card-text>
          <v-row class="mx-4" v-if="questPadrao">
            Questionário do atendimento padrão foi liberado
          </v-row>
          <v-row class="mx-4" v-if="questImunidade">
            Questionário do protocolo Kirchhoff foi liberado
          </v-row>
          <v-row class="mx-4" v-if="questEstet">
            Questionário para pacientes apenas da estética foi liberado
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text @click="questLiberadoDialog = false"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "firebase";
import {
  mdiAccountPlus,
  mdiDownload,
  mdiPrinter,
  mdiEye,
  mdiAutorenew,
  mdiFinance,
  mdiWeb,
  mdiLock,
  mdiEmail,
  mdiEyeOff,
  mdiStore,
  mdiAccount,
  mdiAlert,
  mdiCalendar,
  mdiPhone,
  mdiWhatsapp,
  mdiMapMarker,
  mdiCity,
  mdiFlagVariantOutline,
  mdiPiggyBank,
  mdiHospitalBuilding,
  mdiHandHeart,
  mdiBabyCarriage,
  mdiHumanMaleChild,
  mdiAccountVoice,
  mdiWeightKilogram,
  mdiHumanMaleHeightVariant,
  mdiWeightLifter,
  mdiCreditCardCheckOutline,
  mdiCash100,
  mdiCreditCardClockOutline,
  mdiBankTransfer,
  mdiInfinity,
  mdiHelpRhombusOutline,
  mdiWalletPlusOutline,
  mdiCheckbook,
  mdiTableEdit,
  mdiBullseyeArrow,
} from "@mdi/js";
import LineChart from "../components/areaDePaciente/LineChart.js";
import fontCode from "../components/Montserrat-Medium-normal";
import jspdf from "jspdf";
import "jspdf-autotable";
export default {
  components: { LineChart },
  data: function() {
    return {
      formulaPVis: {
        nome: "",
        dateStr: "",
        prescricaoC: {
          formulasList: [],
        },
      },
      emailFoiEditado: false,
      deleteBioimpAtendId: null,
      deleteBioimpDialog: false,
      deletingBioimp: false,
      deletedBioimp: false,
      deleteDietaId: null,
      deleteDietaTitulo: null,
      deleteDietaDialog: false,
      deletingDieta: false,
      deletedDieta: false,
      deleteFormulaId: null,
      deleteFormulaTitulo: null,
      deleteFormulaDialog: false,
      deletingFormula: false,
      deletedFormula: false,
      deleteExameId: null,
      deleteExameTitulo: null,
      deleteExameDialog: false,
      deletingExame: false,
      deletedExame: false,
      deleteOrientacaoId: null,
      deleteOrientacaoTitulo: null,
      deleteOrientacaoDialog: false,
      deletingOrientacao: false,
      deletedOrientacao: false,
      deleteFotosAtendId: null,
      deleteFotosDialog: false,
      deletingFotos: false,
      deletedFotos: false,
      nutriResponsavel: null,
      newNutriResponsavel: null,
      setNutriRespDialog: false,
      settingNewNutriResp: false,
      pacienteProgramaFim: null,
      pacienteProgramaInicio: null,
      dialogProgramaInicioFim: false,
      dialogAnotacoesAdmin: false,
      pacienteEvents: [],
      // anotacoesAdminNew: null,
      anotacoesAdmin: null,
      autorName: "",
      autorCrn: "",
      autorData: {
        nome: "",
        crn: "",
      },
      pacienteDados: {
        nome: "",
        sexo: "",
        gestante: false,
        dataNascimento: "1990-01-01",
        email: "",
        telefone: "",
        whatsapp: "",
        convenio: "",
        pacienteInfos: {
          altura: "",
        },
        objetivo: "",
        imagemNao: false,
        preAtendimentoToFill: false,
        imunidadeToFill: false,
      },
      displayDataNasc: "",
      questEstet: false,
      questPadrao: false,
      questImunidade: false,
      progressDialog: false,
      saved: false,
      // pacienteQuestionariosAll: [],
      // boolPadrao: false,
      // boolImunidade: false,
      pacienteDocID: "",
      alert: true,
      gotAtendimentos: 0,
      gotDieta: "0",
      gotFillData: 0,
      // gotFormula: "0",
      gotExame: "0",
      gotOrientacao: "0",
      pacienteNrMax: 1000,
      objetivos: [
        "Emagrecer",
        "Tratar um Problema de Saúde",
        "Ganhar Massa Magra",
        "Procedimento Estético",
        "Exames",
      ],
      icons: {
        mdiAccountPlus,
        mdiBullseyeArrow,
        mdiDownload,
        mdiPrinter,
        mdiEye,
        mdiAutorenew,
        mdiFinance,
        mdiWeb,
        mdiLock,
        mdiEmail,
        mdiEyeOff,
        mdiStore,
        mdiAccount,
        mdiAlert,
        mdiCalendar,
        mdiPhone,
        mdiWhatsapp,
        mdiMapMarker,
        mdiCity,
        mdiFlagVariantOutline,
        mdiPiggyBank,
        mdiHospitalBuilding,
        mdiHandHeart,
        mdiBabyCarriage,
        mdiHumanMaleChild,
        mdiAccountVoice,
        mdiWeightKilogram,
        mdiHumanMaleHeightVariant,
        mdiWeightLifter,
        mdiCreditCardCheckOutline,
        mdiCash100,
        mdiCreditCardClockOutline,
        mdiBankTransfer,
        mdiInfinity,
        mdiHelpRhombusOutline,
        mdiWalletPlusOutline,
        mdiCheckbook,
        mdiTableEdit,
      },
      showPratos: [],
      mesPratos: {
        text: null,
        value: null,
        data: [],
      },
      mesPratosX: [],
      loadingPratos: false,
      loadingRefeicoes: false,
      pRefeicoes: [],
      showPratosId: null,
      savingComment: null,
      savedComment: null,
      savingHeart: null,
      showPacientePratos: false,
      showDietasAll: false,
      showBioimpAll: false,
      showFotosAll: false,
      fotoCompareDialog: false,
      compareMode: false,
      compareSide: "fre",
      zoomWidthAntesFre: 500,
      zoomWidthAntesTra: 500,
      zoomWidthAntesDir: 500,
      zoomWidthAntesEsq: 500,
      zoomWidthDepoisFre: 500,
      zoomWidthDepoisTra: 500,
      zoomWidthDepoisDir: 500,
      zoomWidthDepoisEsq: 500,
      atendimentosFotos: [],
      showExamesAll: false,
      showOrientacoesAll: false,
      showExamResultsAll: false,
      showFormulasAll: false,
      // showQuestionario: false,
      showVideoAulasAll: false,
      // showQuestionariosAll: false,
      // showSpecificQuest: false,
      showQuestPreConsulta: false,
      questVis: {
        titulo: null,
        quests: [],
      },
      showAnamnesesAll: false,
      showAgendaAll: false,
      showComprasAll: false,
      showPacienteDados: false,
      gotPacienteDataNascimento: null,
      gotPacienteEmail: null,
      progressText: null,
      errorFauthMsg: null,
      menuDataNascimento: false,
      showSpecificEvent: false,
      showSpecificCompra: false,
      showSpecificVideoAula: false,
      routerDialog: false,
      valid: true,
      questLiberadoDialog: false,
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
        // passwordLength: [(v) => v.length >= 8 || "Senha com mais que 8 letras"],
        emailRules: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+/.test(v) || "E-mail invalido",
        ],
      },
      estadoCivilItems: [
        "-",
        "Solteira/Solteiro",
        "Casada/Casado",
        "Viúva/Viúvo",
        "Divorciada/Divorciado",
      ],
      showSpecificDieta: false,
      showSpecificFormula: false,
      showSpecificExame: false,
      showSpecificOrientacao: false,
      verHistoricoDialog: false,
      verEvolucaoDialog: false,
      verPacienteTimeline: false,
      datacollectionCompos: {
        labels: null,
        datasets: [
          {
            label: null,
            borderColor: null,
            backgroundColor: null,
            data: [0],
          },
        ],
      },
      datatableComposData: null,
      datatableComposLabels: [null, null, null, null],
      datacollectionPeso: null,
      datacollectionMedidas: {
        labels: null,
        datasets: [
          {
            label: null,
            borderColor: null,
            backgroundColor: null,
            data: [0],
          },
        ],
      },
      datatableMedidasData: null,
      datatableMedidasLabels: [null, null, null, null],
      datacollectionSangue: {
        labels: null,
        datasets: [
          {
            label: null,
            borderColor: null,
            backgroundColor: null,
            data: [0],
          },
        ],
      },
      datatableSangueData: null,
      datatableSangueLabels: [null, null, null, null],
      panel: [],
      panelDieta: [0],
      panel0: [0],
      panel1: [0],
      panel2: [0],
      panel3: [0],
      dietas: [],
      isIcon: true,
      calcListaDeCompras: false,
      panelReceitas: [],
      panelSubs: [],
      preloadLogo: new Image(),
      preloadSignature: new Image(),
      checkCompos: [
        {
          show: true,
          nome: "pesoIdeal",
          color: "#ff7f00",
          label: "peso ideal [kg]",
        },
        {
          show: true,
          nome: "pesoAtual",
          color: "#000000",
          label: "peso atual [kg]",
        },
        {
          show: true,
          nome: "percGorduraAbs",
          color: "#ff0000",
          label: "gordura [kg]",
        },
        {
          show: true,
          nome: "massaMagraAbs",
          color: "#008000",
          label: "massa magra [kg]",
        },
        { show: true, nome: "aguaAbs", color: "#0000ff", label: "água [kg]" },
      ],
      checkMedidas: [
        {
          show: true,
          nome: "abdomen",
          color: "#ff0000",
          label: "abdomen [cm]",
        },
        {
          show: false,
          nome: "bracoContraido",
          color: "#810f7c",
          label: "braço contraído [cm]",
        },
        {
          show: false,
          nome: "bracoRelaxado",
          color: "#8856a7",
          label: "braço relaxado [cm]",
        },
        {
          show: true,
          nome: "cintura",
          color: "#ff7f00",
          label: "cintura [cm]",
        },
        { show: false, nome: "coxa", color: "#fb9a99", label: "coxa [cm]" },
        {
          show: false,
          nome: "panturrilha",
          color: "#33a02c",
          label: "panturrilha [cm]",
        },
        { show: true, nome: "perna", color: "#1f78b4", label: "perna [cm]" },
        {
          show: false,
          nome: "quadril",
          color: "#a6cee3",
          label: "quadril [cm]",
        },
        { show: false, nome: "torax", color: "#cab2d6", label: "torax [cm]" },
        {
          show: false,
          nome: "adipo",
          color: "#cab2d6",
          label: "adipômetro [cm]",
        },
      ],
      pacoteHeaders: [
        { text: "Pacote", value: "nome" },
        { text: "Mês do programa", value: "dataInicio" },
        { text: "Area", value: "area" },
      ],
      antesFotosIndex: null,
      depoisFotosIndex: null,
    };
  },
  computed: {
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    funcionarios() {
      return this.$store.state.firestore.funcionarios;
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
    noPacienteDocID() {
      if (this.pacienteDocID === "") {
        return true;
      } else {
        return false;
      }
    },
    dietaAutorObj() {
      return this.$store.state.firestore.dietaAutorObj;
    },
    dietaRefeicoes() {
      return this.$store.state.firestore.dietaRefeicoes;
    },
    dietaStatus() {
      return this.$store.state.firestore.dietaStatus;
    },
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    persistPaciente() {
      return this.$store.state.firestore.persistPaciente;
    },
    // pacienteDados() {
    //   return this.$store.state.firestore.pacienteDados;
    // },
    pacienteTimeline() {
      return this.$store.state.firestore.pacienteTimeline;
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    pacienteVerHistorico() {
      return this.$store.state.firestore.pacienteVerHistorico;
    },
    pacienteNr: function() {
      return this.pacientes.length;
    },
    pacienteRatio: function() {
      return (this.pacienteNr / this.pacienteNrMax) * 100;
    },
    atendimentos() {
      return this.$store.state.firestore.pacienteAtendimentosList;
    },
    dietaRefs() {
      return this.$store.state.firestore.pacienteDietaRefs;
    },
    labels() {
      var labelList = this.atendimentos.map(function(atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });
      return labelList;
    },
    checkSangue() {
      // var concatList = [];
      // for (var i = 0; i < this.atendimentos.length; i++) {
      //   concatList = concatList.concat(this.atendimentos[i]);
      // }
      // function arrayUnique(array) {
      //   var a = array.concat();
      //   for (var i = 0; i < a.length; ++i) {
      //     for (var j = i + 1; j < a.length; ++j) {
      //       if (a[i].nome === a[j].nome) a.splice(j--, 1);
      //     }
      //   }
      //   return a;
      // }
      // var sangueList = arrayUnique(concatList);
      // console.log(sangueList);

      var sangueList = this.atendimentos.map(function(atendimento) {
        function hasValue(element) {
          if (element.value) {
            return true;
          } else {
            return false;
          }
        }
        var sangueData = atendimento.sangue.filter(hasValue);

        var objs = sangueData.map(function(data) {
          return {
            index: data.index,
            nome: data.nome,
          };
        });

        return objs;
      });

      var sangueListFlat = [].concat.apply([], sangueList);

      function getRandomColor() {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      var sangueObjsList = sangueListFlat.map(function(obj) {
        return {
          show: false,
          nome: obj.index,
          color: getRandomColor(),
          label: obj.nome,
        };
      });

      return sangueObjsList;
    },
    pacienteFormulasAll() {
      return this.$store.state.firestore.pacienteFormulasAll;
    },
    pacienteAnamnesesAll() {
      return this.$store.state.firestore.pacienteAnamnesesAll;
    },
    pacienteVideoAulasAll() {
      return this.$store.state.firestore.pacienteVideoAulasAll;
    },
    // pacienteQuestionariosAll() {
    //   return this.$store.state.firestore.pacienteQuestionariosAll;
    // },
    temaVis() {
      return this.$store.state.firestore.temaVis;
    },
    compraVis() {
      return this.$store.state.firestore.compraVis;
    },
    pacienteExamesAll() {
      return this.$store.state.firestore.pacienteExamesAll;
    },
    pacienteOrientacoesAll() {
      return this.$store.state.firestore.pacienteOrientacoesAll;
    },
    pacienteAgendaAll() {
      return this.$store.state.firestore.pacienteAgendaAll;
    },
    pacienteComprasAll() {
      return this.$store.state.firestore.pacienteComprasAll;
    },
    // formulaPVis() {
    //   return this.$store.state.firestore.formulaPVis;
    // },
    examPVis() {
      return this.$store.state.firestore.examPVis;
    },
    orientacaoPVis() {
      return this.$store.state.firestore.orientacaoPVis;
    },
    eventVis() {
      return this.$store.state.firestore.visEvent;
    },
    nutriFuncionarios() {
      return this.funcionarios.filter(function(func) {
        return (
          func.papel == "Nutricionista 1" ||
          func.papel == "Nutricionista 2" ||
          func.papel == "Nutricionista 3" ||
          func.papel == "Nutricionista"
        );
      });
    },
    mesesPratos() {
      if (this.showPratos.length > 0) {
        var meses = this.showPratos.map(function(p) {
          return p.id.toString().substring(0, 4);
        });
        var uniq = [...new Set(meses)];
        var uniqMeses = uniq.map(function(m) {
          var mesStr = m.substring(2, 4);
          var mesIndex = parseInt(mesStr, 10) - 1;
          var yearStr = m.substring(0, 2);
          var yearNr = parseInt(yearStr, 10);
          var year = 2000 + yearNr;
          var monthTexts = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Augosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Decembro",
          ];
          var mes = monthTexts[mesIndex];
          var text = mes + "/" + year;
          var value = m;
          return {
            text: text,
            value: value,
          };
        });
        return uniqMeses;
      } else {
        return null;
      }
    },
    chunks() {
      var perChunk = 5;

      var inputArray = this.mesPratosX;
      var result = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      return result;
    },
  },
  methods: {
    // setQuestVisualizar(quest) {
    //   this.questVis = quest;

    //   this.showSpecificQuest = true;
    // },
    pdfGraphsTables() {
      console.log("pdfGraphsTables()");
      var createdAt = new Date();
      var doc = new jspdf("p");
      var day = createdAt.getDate();
      var month = createdAt.getMonth() + 1;
      var year = createdAt.getFullYear();

      // Doc settings
      var fontSize = 10;
      doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
      doc.addFont(
        "Montserrat-Medium-normal.ttf",
        "Montserrat-Medium",
        "normal"
      );
      doc.setFontSize(fontSize);
      doc.setFont("Montserrat-Medium");

      var imgWidth = 40;
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function(
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc
      //         .setFont("Montserrat-Medium")
      //         .splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };

      var createTexts = function(
        textList,
        centered = false,
        textStart = textStartYPos
      ) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page

        for (var i = 0; i < textList.length; i++) {
          var lines = doc
            .setFont("Montserrat-Medium")
            .splitTextToSize(textList[i], 180);

          for (var j = 0; j < lines.length; j++) {
            // Check if the current line would go past the threshold
            if (textStart > pageHeightThreshold) {
              doc.addPage();
              textStart = newYStart; // Reset the text start position for the new page
            }

            // Whether to center the text or not
            if (centered) {
              centeredText(lines[j], textStart);
            } else {
              doc.text(startXPos, textStart, lines[j]);
            }

            textStart += fontSize / 2; // Increment the y position for each line of text
          }

          // Increment after each text block rather than each line
          if (!centered) {
            textStart += fontSize / 2; // This accounts for the space after each block of text
          }
        }

        return textStart; // Return the updated text start position
      };

      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      var texts = [
        `Evolução: ${this.pacienteDados.nome} (${day}/${month}/${year})`,
      ];

      var createTablesForCompos = function(dataCompos, startPosY) {
        var startPosYNew = startPosY;

        var texts;
        texts = ["Composição corporal"];

        startPosYNew = createTexts(texts, false, startPosYNew + 12); //lastPos
        var body = dataCompos.labels.map(function(l) {
          var sindex = dataCompos.labels.indexOf(l);
          var datasetList = dataCompos.datasets.map(function(dl) {
            return dl.data[sindex];
          });
          var list1 = [l];
          var list = [...list1, ...datasetList];
          // return datasetList.splice(0, 0, l);
          return list;
        });
        var headList2 = dataCompos.datasets.map(function(d) {
          return d.label;
        });
        var headList1 = ["data"];
        // var headList = headList2.splice(0, 0, "data");
        var headList = [...headList1, ...headList2];

        doc.autoTable({
          startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
          startx: startXPos,
          head: [headList],
          body: body,
          headStyles: {
            fillColor: [226, 232, 159],
            textColor: 40,
            fontStyle: "regular",
          },
          bodyStyles: {
            fillColor: [243, 245, 216],
          },
          alternateRowStyles: {
            fillColor: [249, 250, 235],
          },
        });
        startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
        if (dataCompos.labels.length > 1) {
          var totalLabel = [" Total        "];
          var totalLabel2 = [""];
          var totalValues = headList2.map(function(dl) {
            var dlindex = headList2.indexOf(dl);
            var endIndex = dataCompos.datasets[dlindex].data.length - 1;
            var dataset0 = dataCompos.datasets[dlindex].data[0];
            var datasetE = dataCompos.datasets[dlindex].data[endIndex];
            var diff = parseFloat(datasetE) - parseFloat(dataset0);
            var diff100 = diff * 100;
            var diffRound = Math.round(diff100) / 100;
            var diffStr;
            if (diffRound > 0) {
              diffStr = "+" + diffRound;
            } else {
              diffStr = diffRound;
            }
            return diffStr;
          });
          console.log("totalValues", totalValues);
          var bodyTotal = [...totalLabel2, ...totalValues];
          var headListTotal = [...totalLabel, ...headList2];
          doc.autoTable({
            startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
            startx: startXPos,
            head: [headListTotal],
            body: [bodyTotal],
            headStyles: {
              fillColor: [245, 203, 167],
              textColor: 40,
              fontStyle: "regular",
            },
            bodyStyles: {
              fillColor: [250, 229, 211],
            },
            alternateRowStyles: {
              fillColor: [253, 242, 223],
            },
          });
          startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
        }

        return startPosYNew; // doc.previousAutoTable.finalY;
      };

      var createTablesForMedidas = function(dataMedidas, startPosY) {
        var startPosYNew = startPosY;

        var texts;
        texts = ["Medidas"];

        startPosYNew = createTexts(texts, false, startPosYNew + 12); //lastPos
        var body = dataMedidas.labels.map(function(l) {
          var sindex = dataMedidas.labels.indexOf(l);
          var datasetList = dataMedidas.datasets.map(function(dl) {
            return dl.data[sindex];
          });
          var list1 = [l];
          var list = [...list1, ...datasetList];
          // return datasetList.splice(0, 0, l);
          return list;
        });
        var headList2 = dataMedidas.datasets.map(function(d) {
          return d.label;
        });
        var headList1 = ["data"];
        // var headList = headList2.splice(0, 0, "data");
        var headList = [...headList1, ...headList2];

        console.log("body", body);
        console.log("head", headList);

        doc.autoTable({
          startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
          startx: startXPos,
          head: [headList],
          body: body,
          headStyles: {
            fillColor: [226, 232, 159],
            textColor: 40,
            fontStyle: "regular",
          },
          bodyStyles: {
            fillColor: [243, 245, 216],
          },
          alternateRowStyles: {
            fillColor: [249, 250, 235],
          },
        });
        startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
        if (dataMedidas.labels.length > 1) {
          var totalLabel = [" Total        "];
          var totalLabel2 = [""];
          var totalValues = headList2.map(function(dl) {
            var dlindex = headList2.indexOf(dl);
            var endIndex = dataMedidas.datasets[dlindex].data.length - 1;
            var dataset0 = dataMedidas.datasets[dlindex].data[0];
            var datasetE = dataMedidas.datasets[dlindex].data[endIndex];
            var diff = parseFloat(datasetE) - parseFloat(dataset0);
            var diff100 = diff * 100;
            var diffRound = Math.round(diff100) / 100;
            var diffStr;
            if (diffRound > 0) {
              diffStr = "+" + diffRound;
            } else {
              diffStr = diffRound;
            }
            return diffStr;
          });
          console.log("totalValues", totalValues);
          var bodyTotal = [...totalLabel2, ...totalValues];
          var headListTotal = [...totalLabel, ...headList2];
          doc.autoTable({
            startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
            startx: startXPos,
            head: [headListTotal],
            body: [bodyTotal],
            headStyles: {
              fillColor: [245, 203, 167],
              textColor: 40,
              fontStyle: "regular",
            },
            bodyStyles: {
              fillColor: [250, 229, 211],
            },
            alternateRowStyles: {
              fillColor: [253, 242, 223],
            },
          });
          startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
        }

        return startPosYNew; // doc.previousAutoTable.finalY;
      };

      var endTablePosY;
      createTexts(texts, true, textStartYPos + 10);

      if (this.datacollectionCompos.labels.length > 0) {
        endTablePosY = createTablesForCompos(
          this.datacollectionCompos,
          textStartYPos + texts.length * fontSize + 10
        );
      }

      if (this.datacollectionMedidas.labels.length > 0) {
        if (this.datacollectionCompos.labels.length > 10) {
          doc.addPage();
          endTablePosY = createTablesForMedidas(
            this.datacollectionMedidas,
            // endTablePosY + texts.length * fontSize + 10
            // endTablePosY + 10
            24
          );
        } else {
          endTablePosY = createTablesForMedidas(
            this.datacollectionMedidas,
            // endTablePosY + texts.length * fontSize + 10
            endTablePosY + 10
          );
        }
      }

      console.log(endTablePosY);

      doc.save("evolucao.pdf");
    },
    makeAtendimentosFotos() {
      var ams = this.atendimentos;
      var atendimentosWith = ams.filter(function(a) {
        return a.fotoFrenteUrl !== "";
      });
      var niceDate = function(millis) {
        var date = new Date(millis);
        var dayNr = date.getDate();
        var monthNr = date.getMonth() + 1;
        var yearNr = date.getFullYear();
        var dayStr;
        if (dayNr > 9) {
          dayStr = dayNr.toString();
        } else {
          dayStr = "0" + dayNr.toString();
        }
        var monthStr;
        if (monthNr > 9) {
          monthStr = monthNr.toString();
        } else {
          monthStr = "0" + monthNr.toString();
        }
        var yearStr = yearNr - 2000;
        var dateStr = dayStr + "/" + monthStr + "/" + yearStr;
        return dateStr;
      };
      console.log(atendimentosWith);
      this.atendimentosFotos = atendimentosWith.map(function(a) {
        return {
          text: niceDate(a.data),
          value: ams.indexOf(a),
        };
      });
      this.fotoCompareDialog = true;
    },
    niceDate(millis) {
      var date = new Date(millis);
      var dayNr = date.getDate();
      var monthNr = date.getMonth() + 1;
      var yearNr = date.getFullYear();
      var dayStr;
      if (dayNr > 9) {
        dayStr = dayNr.toString();
      } else {
        dayStr = "0" + dayNr.toString();
      }
      var monthStr;
      if (monthNr > 9) {
        monthStr = monthNr.toString();
      } else {
        monthStr = "0" + monthNr.toString();
      }
      var yearStr = yearNr - 2000;
      var dateStr = dayStr + "/" + monthStr + "/" + yearStr;
      return dateStr;
    },
    niceDateMonth(millis) {
      var date = new Date(millis);
      var monthNr = date.getMonth();
      var yearNr = date.getFullYear();
      var months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      var monthStr = months[monthNr];
      var yearStr = yearNr - 2000;
      var dateStr = monthStr + "/" + yearStr;
      return dateStr;
    },
    closeCompareDialog() {
      this.fotoCompareDialog = false;
      this.compareSide = "fre";
      this.zoomWidthAntesFre = 500;
      this.zoomWidthAntesTra = 500;
      this.zoomWidthAntesDir = 500;
      this.zoomWidthAntesEsq = 500;
      this.zoomWidthDepoisFre = 500;
      this.zoomWidthDepoisTra = 500;
      this.zoomWidthDepoisDir = 500;
      this.zoomWidthDepoisEsq = 500;
      this.antesFotosIndex = null;
      this.depoisFotosIndex = null;
    },
    changeFotoDates() {
      // this.compareSide = "fre";
      this.zoomWidthAntesFre = 500;
      this.zoomWidthAntesTra = 500;
      this.zoomWidthAntesDir = 500;
      this.zoomWidthAntesEsq = 500;
      this.zoomWidthDepoisFre = 500;
      this.zoomWidthDepoisTra = 500;
      this.zoomWidthDepoisDir = 500;
      this.zoomWidthDepoisEsq = 500;
    },
    // verAntesDepois() {
    //   this.fotoCompareDialog = true;
    // },
    closeFotosDialog() {
      this.showFotosAll = false;
      this.antesFotosIndex = null;
      this.depoisFotosIndex = null;
    },
    isAntesColor(aIndex) {
      if (this.antesFotosIndex == aIndex) {
        return "#c5d13f";
      } else {
        return "grey";
      }
    },
    isDepoisColor(aIndex) {
      if (this.depoisFotosIndex == aIndex) {
        return "#c5d13f";
      } else {
        return "grey";
      }
    },
    setAntes(index) {
      this.antesFotosIndex = index;
    },
    setDepois(index) {
      this.depoisFotosIndex = index;
      this.fotoCompareDialog = true;
    },
    clearAntesDepois() {
      this.antesFotosIndex = null;
      this.depoisFotosIndex = null;
    },
    async desAtivarDieta(d) {
      await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .collection("dietasRefs")
        .doc(d.dietaDocId)
        .update({
          ativo: !d.ativo,
        });
      this.$store.dispatch("firestore/pacienteDietaRefs", {
        pacienteEmail: this.pacienteDocID,
      });
    },
    turnToCheckbox() {
      this.isIcon = false;
    },
    turnToIcons() {
      this.isIcon = true;
    },
    async makeGroceryList() {
      this.calcListaDeCompras = true;
      // if (this.gotDieta !== dietaDocId) {
      //   this.$store.dispatch("firestore/getDietaPaciente", {
      //     dietaDocId: dietaDocId,
      //   });
      //   this.gotDieta = dietaDocId;
      // }
      // console.log(this.dietaRefeicoes);
      var groceryList = [];
      // this.dietaRefeicoes
      var includedDietas = this.dietaRefs.filter(function(d) {
        return d.includedInGroceryList;
      });

      var getDietaPacienteById = async function(pacEmail, id) {
        var dieta = await firebase
          .firestore()
          .collection("users")
          .doc(pacEmail)
          .collection("dietas")
          .doc(id.toString())
          .get()
          .then(function(doc) {
            return doc.data();
          });
        return dieta;
      };
      // var pacId = this.pacienteDocID;
      var includedDietasData = [];
      for (var i = 0; i < includedDietas.length; i++) {
        includedDietasData.push(
          await getDietaPacienteById(
            this.pacienteDocID,
            includedDietas[i].dietaDocId
          )
        );
      }

      includedDietasData.forEach(function(d) {
        d.refeicoes.forEach(function(refeicao) {
          refeicao.alimentosList.forEach(function(alimentoIsolado) {
            let alimentoIsoladoReduced = {
              nome: alimentoIsolado.nome,
              gramas: alimentoIsolado.gramas,
              medida: alimentoIsolado.medida,
              quantidade: alimentoIsolado.quantidade,
            };
            groceryList.push(alimentoIsoladoReduced);
          });

          refeicao.receitasList.forEach(function(receita) {
            receita.alimentosList.forEach(function(alimentoInReceita) {
              let alimentoIinReceitaReduced = {
                nome: alimentoInReceita.nome,
                gramas: alimentoInReceita.gramas,
                medida: alimentoInReceita.medida,
                quantidade: alimentoInReceita.quantidade,
              };
              groceryList.push(alimentoIinReceitaReduced);
            });
          });
        });
      });

      // var flatGroceryList = groceryList.flat();
      // console.log(groceryList)
      const summedGroceryList = groceryList.reduce((acc, d) => {
        const found = acc.find((a) => a.nome === d.nome);

        if (!found) {
          acc.push({
            nome: d.nome,
            quantidade: Math.round(parseFloat(d.quantidade)),
            medida: d.medida,
            gramas: Math.round(parseFloat(d.gramas)),
          }); // not found
        } else {
          if (d.quantidade !== null && d.quantidade !== undefined) {
            found.quantidade += Math.round(parseFloat(d.quantidade));
          }
          if (d.gramas !== null && d.gramas !== undefined) {
            found.gramas += Math.round(parseFloat(d.gramas));
          } // if found
        }
        return acc;
      }, []);

      var alphabet = function(objs) {
        function compare(a, b) {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        }

        return objs.sort(compare);
      };
      this.groceryList = alphabet(summedGroceryList);
      // console.log(summedGroceryList)

      // this.groceryListStrings = summedGroceryList.map(function (obj) {
      //   if (obj.nome != undefined) {
      //     return obj.nome + "..........." + obj.gramas.toString() + "g";
      //   } else {
      //     return "";
      //   }
      // });
      this.downloadGroceryList();
      this.calcListaDeCompras = false;
      // console.log(this.groceryListStrings);
    },
    downloadGroceryList() {
      console.log(this.groceryListStrings);
      alert("Gerando PDF");
      var doc = new jspdf("p");
      // Doc settings
      var fontSize = 12;
      doc.setFontSize(fontSize);
      doc.setFont("Times");

      // The (900 / 262) division is used to keep the proportion of the original image
      // New Width * (900 / 262) will keep the height proportion
      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function(
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc.setFont("Roboto").splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(
        textList,
        centered = false,
        textStart = textStartYPos
      ) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page

        for (var i = 0; i < textList.length; i++) {
          var lines = doc
            .setFont("Montserrat-Medium")
            .splitTextToSize(textList[i], 180);

          for (var j = 0; j < lines.length; j++) {
            // Check if the current line would go past the threshold
            if (textStart > pageHeightThreshold) {
              doc.addPage();
              textStart = newYStart; // Reset the text start position for the new page
            }

            // Whether to center the text or not
            if (centered) {
              centeredText(lines[j], textStart);
            } else {
              doc.text(startXPos, textStart, lines[j]);
            }

            textStart += fontSize / 2; // Increment the y position for each line of text
          }

          // Increment after each text block rather than each line
          if (!centered) {
            textStart += fontSize / 2; // This accounts for the space after each block of text
          }
        }

        return textStart; // Return the updated text start position
      };

      console.log("op 1");
      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      var texts = [
        `Lista de Compras`, // nome da dieta
        // `Para: ${this.pacienteChosen.data.nome}`,
      ];

      var endTablePosY = createTexts(texts);

      // endTablePosY = createTexts(this.groceryListStrings, false, endTablePosY);
      var createTablesForGroceries = function(groceryList, startPosY) {
        var startPosYNew = startPosY;
        // #c5d13f <----- trocar a cor da tabela
        var body = groceryList.map(function(alimento) {
          if (alimento.nome != undefined) {
            return [
              alimento.nome,
              alimento.quantidade,
              alimento.medida,
              alimento.gramas,
            ];
          } else {
            return [];
          }
        });

        doc.autoTable({
          startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
          startx: startXPos,
          head: [["Alimento", "Qtd.", "Medida", "Total (g)"]],
          body: body,
        });
        startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);

        return startPosYNew; // doc.previousAutoTable.finalY;
      };

      createTablesForGroceries(this.groceryList, endTablePosY);

      doc.save("listaDeCompras.pdf");
    },
    precisouAgendamentoAutom(mov) {
      var precisam = [
        "Programa Clínico Funcional",
        "Programa Modulação Intestinal",
        "Programa Clínico Funcional com Estética",
        "Programa Modulação Intestinal com Estética",
        "Programa Clínico Funcional (plano família)",
        "Programa Modulação Intestinal (plano família)",
        "Programa 12S Online",
        "Programa 12S",
        "Power Shape 30",
        "Programa 12S com Estética",
        "Protocolo Harmonização Corporal 24H",
        "Protocolo Detox + Lipodraining 24H",
        "Protocolo Lipodraining 24H",
        "Protocolo Glúteo de Ouro",
        "Pacote 90 dias Protocolo Kirchhoff",
        "Pacote Estético",
        "Sessão Estética",
        "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)",
        "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)",
      ];
      return precisam.includes(mov.tipo);
    },
    editarAgendamentoAutom(mov) {
      this.$store.dispatch("firestore/agendaMovEdit", { mov: mov });
    },
    closeShowPacientePratos() {
      this.showPacientePratos = false;
      this.savingComment = null;
      this.savedComment = null;
      this.savingHeart = null;
      this.showPratosId = null;
    },
    clickedShowPratosId(id) {
      if (this.showPratosId == id) {
        return "#c5d13f";
      } else {
        return "grey";
      }
    },
    setMesPratos() {
      var m = this.mesPratos;
      var dayCardapio_s = this.showPratos.map(function(p) {
        var dayCardapio = p.dayCardapio;
        return dayCardapio;
      });
      var data = dayCardapio_s.filter(function(d) {
        return d.startsWith(m);
      });
      this.mesPratosX = data;
    },
    niceDay(str) {
      var yearStr = str.substring(0, 2);
      var mesStr = str.substring(2, 4);
      var dayStr = str.substring(4, 6);
      return dayStr + "/" + mesStr + "/" + yearStr;
    },
    showFotosRefeicoes(id) {
      this.loadingRefeicoes = true;
      this.showPratosId = id;
      this.savingComment = null;
      this.savingHeart = null;
      this.savedComment = null;
      var dietaData = this.showPratos.filter(function(d) {
        return d.dayCardapio == id;
      });
      this.loadingRefeicoes = false;
      this.pRefeicoes = dietaData[0].pRefeicoes;
    },
    async saveComment(index) {
      if (this.showPratosId !== null && this.showPratosId !== undefined) {
        this.savingComment = index;
        console.log(
          "saveComment: this.pRefeicoes[index].commentN",
          this.pRefeicoes[index].commentN
        );
        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .collection("dietaStories")
          .doc(this.showPratosId)
          .update({
            pRefeicoes: this.pRefeicoes,
            newCommentN: true,
          });
        this.savingComment = null;
        this.savedComment = index;
      }
    },
    async saveHeart(index) {
      if (this.showPratosId !== null && this.showPratosId !== undefined) {
        this.savingHeart = index;
        this.pRefeicoes[index].heartN = true;
        console.log(
          "saveHeart: this.pRefeicoes[index].heartN",
          this.pRefeicoes[index].heartN
        );
        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .collection("dietaStories")
          .doc(this.showPratosId)
          .update({
            pRefeicoes: this.pRefeicoes,
            newHeartN: true,
          });
        this.savingHeart = null;
      }
    },
    async removeHeart(index) {
      if (this.showPratosId !== null && this.showPratosId !== undefined) {
        this.savingHeart = index;
        this.pRefeicoes[index].heartN = false;
        var countHearts = 0;
        this.pRefeicoes.forEach(function(r) {
          var nr = 0;
          if (r.heartN) {
            nr = 1;
          }
          countHearts += nr;
        });
        console.log("countHearts", countHearts);
        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .collection("dietaStories")
          .doc(this.showPratosId)
          .update({
            pRefeicoes: this.pRefeicoes,
            newHeartN: countHearts > 0,
          });
        this.savingHeart = null;
      }
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    // getFuncionarios() {
    //   this.$store.dispatch("firestore/getFuncionarios", {
    //     uId: sessionStorage.getItem("current-user"),
    //   });
    // },
    deleteBioimp(atend) {
      this.deletedBioimp = false;
      this.deleteBioimpDialog = true;
      this.deleteBioimpAtendId = atend.data.toString();
      console.log(this.deleteBioimpAtendId);
    },
    deleteDieta(id, titulo) {
      this.deletedDieta = false;
      this.deleteDietaDialog = true;
      this.deleteDietaId = id;
      this.deleteDietaTitulo = titulo;
      console.log(this.deleteDietaId);
    },
    deleteFormula(id, titulo) {
      this.deletedFormula = false;
      this.deleteFormulaDialog = true;
      this.deleteFormulaId = id;
      this.deleteFormulaTitulo = titulo;
      console.log(this.deleteFormulaId);
    },
    deleteExame(id, titulo) {
      this.deletedExame = false;
      this.deleteExameDialog = true;
      this.deleteExameId = id;
      this.deleteExameTitulo = titulo;
      console.log(this.deleteExameId);
    },
    deleteOrientacao(id, titulo) {
      this.deletedOrientacao = false;
      this.deleteOrientacaoDialog = true;
      this.deleteOrientacaoId = id;
      this.deleteOrientacaoTitulo = titulo;
      console.log(this.deleteOrientacaoId);
    },
    deleteFotos(atend) {
      this.deletedFotos = false;
      this.deleteFotosDialog = true;
      this.deleteFotosAtendId = atend.data.toString();
      console.log(this.deleteFotosAtendId);
    },
    niceDataStr(millis) {
      var millisFloat = parseFloat(millis);
      var date = new Date(millisFloat);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      return day + "/" + month + "/" + year;
    },
    async doDeleteBioimp() {
      this.deletingBioimp = true;
      await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .collection("atendimentos")
        .doc(this.deleteBioimpAtendId)
        .update({
          bioimp: "",
        });

      this.deletingBioimp = false;
      this.deletedBioimp = true;

      this.$store.dispatch("firestore/pacienteAtendimentosListN", {
        pacienteEmail: this.pacienteDocID,
      });
    },
    async doDeleteDieta() {
      this.deletingDieta = true;
      await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .collection("dietasRefs")
        .doc(this.deleteDietaId)
        .delete();

      await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .collection("dietas")
        .doc(this.deleteDietaId)
        .delete();

      this.deletingDieta = false;
      this.deletedDieta = true;

      this.$store.dispatch("firestore/pacienteDietaRefs", {
        pacienteEmail: this.pacienteDocID,
      });
    },
    async doDeleteFormula() {
      this.deletingFormula = true;

      await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .collection("formulas")
        .doc(this.deleteFormulaId)
        .delete();

      this.deletingFormula = false;
      this.deletedFormula = true;

      this.$store.dispatch("firestore/getFormulasAll", {
        pacienteEmail: this.pacienteDocID,
      });
    },
    async doDeleteExame() {
      this.deletingExame = true;

      await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .collection("exames")
        .doc(this.deleteExameId)
        .delete();

      this.deletingExame = false;
      this.deletedExame = true;

      this.$store.dispatch("firestore/getExamesAll", {
        pacienteEmail: this.pacienteDocID,
      });
    },
    async doDeleteOrientacao() {
      this.deletingOrientacao = true;

      await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .collection("orientacoes")
        .doc(this.deleteOrientacaoId)
        .delete();

      this.deletingOrientacao = false;
      this.deletedOrientacao = true;

      this.$store.dispatch("firestore/getOrientacoesAll", {
        pacienteEmail: this.pacienteDocID,
      });
    },
    async doDeleteFotos() {
      this.deletingFotos = true;
      await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .collection("atendimentos")
        .doc(this.deleteFotosAtendId)
        .update({
          fotoFrenteUrl: "",
          fotoAtrasUrl: "",
          fotoDireitaUrl: "",
          fotoEsquerdaUrl: "",
        });

      this.deletingFotos = false;
      this.deletedFotos = true;

      this.$store.dispatch("firestore/pacienteAtendimentosListN", {
        pacienteEmail: this.pacienteDocID,
      });
    },
    getNutirName(papel) {
      var funcs = this.funcionarios.filter(function(f) {
        return f.papel == papel;
      });
      var func = funcs[0];
      return func.nome;
    },
    labelOption(funcionario) {
      var string = funcionario.papel + ", " + funcionario.nome;
      return string;
    },
    async setNewNutriResp() {
      if (this.newNutriResponsavel !== null) {
        this.settingNewNutriResp = true;
        this.nutriResponsavel = this.newNutriResponsavel;
        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("pacientes")
          .doc(this.pacienteDocID)
          .update({
            nutriResponsavel: this.newNutriResponsavel,
          });

        var events = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agenda")
          .get()
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return doc.data();
            })
          );

        var pacienteDocID = this.pacienteDocID;
        var now = new Date();
        var nowMillis = now.getTime();
        var eventsToChange = events.filter(function(e) {
          var start = new Date(e.start);
          var startTime = start.getTime();
          return e.patientId == pacienteDocID && startTime > nowMillis;
        });

        var newNutriResponsavel = this.newNutriResponsavel;
        eventsToChange.forEach(async function(e) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc(e.id)
            .update({
              nutriAtendendo: newNutriResponsavel,
            });

          await firebase
            .firestore()
            .collection("users")
            .doc(e.patientId)
            .collection("agenda")
            .doc(e.id)
            .update({
              nutriAtendendo: newNutriResponsavel,
            });
        });
        console.log(eventsToChange.length);
        this.settingNewNutriResp = false;
        this.setNutriRespDialog = false;
      }
    },
    saveAnotacoesAdmin() {
      // if (this.pacienteDados.anotacoesAdmin !== undefined) {
      this.$store.dispatch("firestore/updateAnotacoesAdmin", {
        anotacoes: this.pacienteDados.anotacoesAdmin,
        pacienteEmail: this.pacienteDocID,
      });
      this.dialogAnotacoesAdmin = true;
      // } else {
      //   this.$store.dispatch("firestore/updateAnotacoesAdmin", {
      //     anotacoes: this.anotacoesAdminNew,
      //     pacienteEmail: this.pacienteDocID,
      //   });
      //   this.dialogAnotacoesAdmin = true;
      // }
    },
    setProgramaFim() {
      if (
        this.$store.state.firestore.pacienteDados.programaFim !== undefined &&
        this.$store.state.firestore.pacienteDados.programaFim > 0
      ) {
        var millisFim = this.$store.state.firestore.pacienteDados.programaFim;
        var dateStr = new Date(millisFim).toISOString().substring(0, 10);
        this.pacienteProgramaFim = dateStr;
      } else {
        this.pacienteProgramaFim = "01-01-2021";
      }
    },
    setProgramaInicio() {
      var millisInicio;
      if (
        this.$store.state.firestore.pacienteDados.programaFim !== undefined &&
        this.$store.state.firestore.pacienteDados.programaFim > 0
      ) {
        if (
          this.$store.state.firestore.pacienteDados.programaInicio !==
            undefined &&
          this.$store.state.firestore.pacienteDados.programaInicio > 0
        ) {
          millisInicio = this.$store.state.firestore.pacienteDados
            .programaInicio;
          var dateStrI = new Date(millisInicio).toISOString().substring(0, 10);
          this.pacienteProgramaInicio = dateStrI;
        } else {
          var millisFim = this.$store.state.firestore.pacienteDados.programaFim;
          millisInicio = millisFim - 12 * 7 * 24 * 60 * 60 * 1000;
          var dateStr12s = new Date(millisInicio)
            .toISOString()
            .substring(0, 10);
          this.pacienteProgramaInicio = dateStr12s;
        }
      } else {
        this.pacienteProgramaInicio = "01-01-2021";
      }
    },
    saveProgramaInicioFim() {
      // console.log(this.pacienteProgramaInicio)
      var newInicioStr = this.pacienteProgramaInicio + "T12:00:00+0000";
      var newInicioDate = new Date(newInicioStr);
      var newInicioMillis = newInicioDate.getTime();

      // console.log(this.pacienteProgramaFim)
      var newFimStr = this.pacienteProgramaFim + "T12:00:00+0000";
      var newFimDate = new Date(newFimStr);
      var newFimMillis = newFimDate.getTime();

      this.$store.dispatch("firestore/setProgramaInicioFim", {
        pacienteEmail: this.pacienteDocID,
        millisInicio: newInicioMillis,
        millisFim: newFimMillis,
      });
      this.dialogProgramaInicioFim = true;
    },
    setQuestBoolsP(qP) {
      this.questPadrao = qP;
      this.questImunidade = false;
      this.questEstet = false;
    },
    setQuestBoolsE(qE) {
      this.questPadrao = false;
      this.questImunidade = false;
      this.questEstet = qE;
    },
    setQuestBoolsK(qK) {
      this.questPadrao = false;
      this.questImunidade = qK;
      this.questEstet = false;
    },
    async getPacienteDados() {
      var pacienteDadosGot = await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .get()
        .then(function(doc) {
          return doc.data();
        });

      var pacienteId = this.pacienteDocID;
      var pacienteDadosHas = this.pacientes.find(
        (item) => item.value == pacienteId
      );
      // console.log("anotacoesAdmin", pacienteDadosGot.anotacoesAdmin);
      this.pacienteDados = pacienteDadosGot;
      console.log("this.pacienteDados.nome", this.pacienteDados.nome);
      var date = pacienteDadosGot.dataNascimento;
      var dStr = date.substring(8, 10);
      var mStr = date.substring(5, 7);
      var aStr = date.substring(0, 4);
      var niceDate = dStr + "/" + mStr + "/" + aStr;
      this.displayDataNasc = niceDate;
      if (pacienteDadosGot.anotacoesAdmin == undefined) {
        this.pacienteDados.anotacoesAdmin = null;
      }
      this.questPadrao =
        pacienteDadosHas.data.preAtendimentoToFill !== undefined
          ? pacienteDadosHas.data.preAtendimentoToFill
          : false;
      this.questImunidade =
        pacienteDadosHas.data.imunidadeToFill !== undefined
          ? pacienteDadosHas.data.imunidadeToFill
          : false;
      this.questEstet =
        pacienteDadosHas.data.estetToFill !== undefined
          ? pacienteDadosHas.data.estetToFill
          : false;

      this.nutriResponsavel = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("pacientes")
        .doc(this.pacienteDocID)
        .get()
        .then(function(doc) {
          return doc.data().nutriResponsavel;
        });
      // this.boolImunidade = this.questImunidade;
      // this.boolPadrao = this.questPadrao;
      // this.anotacoesAdminNew = null;
      this.setPacienteVerHistorico();
    },
    updatePacienteQuest() {
      this.$store.dispatch("firestore/updateQuest", {
        pacienteEmail: this.pacienteDocID,
        nutri: sessionStorage.getItem("current-user"),
        preAtendimentoToFill: this.questPadrao,
        imunidadeToFill: this.questImunidade,
        estetToFill: this.questEstet,
      });
      // this.getPacienteDados();
      this.questLiberadoDialog = true;
    },
    fillDataMedidas() {
      function validMedidas(element) {
        return element.medidas.abdomen > 0;
      }
      var atendimentos = this.atendimentos.filter(validMedidas);

      var labelsMedidas = atendimentos.map(function(atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });

      function checkShow(element) {
        return element.show;
      }

      this.datacollectionMedidas = {
        labels: labelsMedidas,
        datasets: this.checkMedidas.filter(checkShow).map(function(element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function(atendimento) {
              return atendimento.medidas[element.nome];
            }),
          };
        }),
      };

      this.datatableMedidasLabels = labelsMedidas;
      var fourLabels = [0, 1, 2, 3];
      if (labelsMedidas.length <= 4) {
        this.datatableMedidasLabels = fourLabels.map(function(i) {
          if (labelsMedidas[i] !== undefined) {
            return labelsMedidas[i];
          } else {
            return "...";
          }
        });
      } else {
        this.datatableMedidasLabels = fourLabels.map(function(i) {
          if (i == 2) {
            return "...";
          } else if (i == 3) {
            return labelsMedidas[labelsMedidas.length - 1];
          } else {
            return labelsMedidas[i];
          }
        });
      }
    },
    filter_datatableMedidasData(elemLabel, index) {
      var data = this.datacollectionMedidas.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      // console.log("data", data)
      if (data.length > 0) {
        return data[0].data[index];
      } else {
        return "...";
      }
      // return elemLabel + index;
    },
    filter_datatableMedidasDataChange(elemLabel, index) {
      var data = this.datacollectionMedidas.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      // console.log("data", data)
      if (data.length > 0) {
        var change = data[0].data[index] - data[0].data[index - 1];
        if (!isNaN(change)) {
          var decimal = Math.round(change * 100) / 100;
          if (change >= 0) {
            return "+" + decimal;
          } else {
            return decimal;
          }
        } else {
          return "...";
        }
      } else {
        return "...";
      }
      // return elemLabel + index;
    },
    filter_datatableMedidasDataChangeStyle(elemLabel, index) {
      var data = this.datacollectionMedidas.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      if (data.length > 0) {
        var change = data[0].data[index] - data[0].data[index - 1];
        if (!isNaN(change)) {
          if (change >= 0) {
            return "color: red";
          } else {
            return "color: green";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    filter_datatableMedidasDataTotalChange(elemLabel) {
      var data = this.datacollectionMedidas.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      // console.log("data", data)
      if (data.length > 0) {
        var change = data[0].data[data[0].data.length - 1] - data[0].data[0];
        if (!isNaN(change)) {
          var decimal = Math.round(change * 100) / 100;
          if (change >= 0) {
            return "+" + decimal;
          } else {
            return decimal;
          }
        } else {
          return "...";
        }
      } else {
        return "...";
      }
    },
    filter_datatableMedidasDataTotalChangeStyle(elemLabel) {
      var data = this.datacollectionMedidas.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      if (data.length > 0) {
        var change = data[0].data[data[0].data.length - 1] - data[0].data[0];
        if (!isNaN(change)) {
          if (change >= 0) {
            return "color: red";
          } else {
            return "color: green";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    fillDataCompos() {
      function validCompos(element) {
        return element.compos.agua > 0;
      }
      var atendimentos = this.atendimentos.filter(validCompos);

      function checkShow(element) {
        return element.show;
      }

      var labelsCompos = atendimentos.map(function(atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });

      this.datacollectionCompos = {
        labels: labelsCompos,
        datasets: this.checkCompos.filter(checkShow).map(function(element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function(atendimento) {
              return atendimento.compos[element.nome];
            }),
          };
        }),
      };

      this.datatableComposLabels = labelsCompos;
      var fourLabels = [0, 1, 2, 3];
      if (labelsCompos.length <= 4) {
        this.datatableComposLabels = fourLabels.map(function(i) {
          if (labelsCompos[i] !== undefined) {
            return labelsCompos[i];
          } else {
            return "...";
          }
        });
      } else {
        this.datatableComposLabels = fourLabels.map(function(i) {
          if (i == 2) {
            return "...";
          } else if (i == 3) {
            return labelsCompos[labelsCompos.length - 1];
          } else {
            return labelsCompos[i];
          }
        });
      }
    },
    filter_datatableComposData(elemLabel, index) {
      var data = this.datacollectionCompos.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      // console.log("data", data)
      if (data.length > 0) {
        return data[0].data[index];
      } else {
        return "...";
      }
      // return elemLabel + index;
    },
    filter_datatableComposDataChange(elemLabel, index) {
      var data = this.datacollectionCompos.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      // console.log("data", data)
      if (data.length > 0) {
        var change = data[0].data[index] - data[0].data[index - 1];
        if (!isNaN(change)) {
          var decimal = Math.round(change * 100) / 100;
          if (change >= 0) {
            return "+" + decimal;
          } else {
            return decimal;
          }
        } else {
          return "...";
        }
      } else {
        return "...";
      }
      // return elemLabel + index;
    },
    filter_datatableComposDataChangeStyle(elemLabel, index) {
      var data = this.datacollectionCompos.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      if (data.length > 0) {
        var change = data[0].data[index] - data[0].data[index - 1];
        if (!isNaN(change)) {
          if (change >= 0) {
            return "color: red";
          } else {
            return "color: green";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    filter_datatableComposDataTotalChange(elemLabel) {
      var data = this.datacollectionCompos.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      // console.log("data", data)
      if (data.length > 0) {
        var change = data[0].data[data[0].data.length - 1] - data[0].data[0];
        if (!isNaN(change)) {
          var decimal = Math.round(change * 100) / 100;
          if (change >= 0) {
            return "+" + decimal;
          } else {
            return decimal;
          }
        } else {
          return "...";
        }
      } else {
        return "...";
      }
    },
    filter_datatableComposDataTotalChangeStyle(elemLabel) {
      var data = this.datacollectionCompos.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      if (data.length > 0) {
        var change = data[0].data[data[0].data.length - 1] - data[0].data[0];
        if (!isNaN(change)) {
          if (change >= 0) {
            return "color: red";
          } else {
            return "color: green";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    fillDataSangue() {
      function validSangue(element) {
        if (element.sangue[0].value) {
          return true;
        } else {
          return false;
        }
      }
      var atendimentos = this.atendimentos.filter(validSangue);

      var labelsSangue = atendimentos.map(function(atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });

      function checkShow(element) {
        return element.show;
      }

      this.datacollectionSangue = {
        labels: labelsSangue,
        datasets: this.checkSangue.filter(checkShow).map(function(element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function(atendimento) {
              if (atendimento.sangue[element.nome].value) {
                return parseInt(atendimento.sangue[element.nome].value);
              } else {
                return null;
              }
            }),
          };
        }),
      };

      this.datatableSangueLabels = labelsSangue;
      var fourLabels = [0, 1, 2, 3];
      if (labelsSangue.length <= 4) {
        this.datatableMedidasLabels = fourLabels.map(function(i) {
          if (labelsSangue[i] !== undefined) {
            return labelsSangue[i];
          } else {
            return "...";
          }
        });
      } else {
        this.datatableSangueLabels = fourLabels.map(function(i) {
          if (i == 2) {
            return "...";
          } else if (i == 3) {
            return labelsSangue[labelsSangue.length - 1];
          } else {
            return labelsSangue[i];
          }
        });
      }
    },
    filter_datatableSangueData(elemLabel, index) {
      var data = this.datacollectionSangue.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      // console.log("data", data)
      if (data.length > 0) {
        return data[0].data[index];
      } else {
        return "...";
      }
      // return elemLabel + index;
    },
    filter_datatableSangueDataChange(elemLabel, index) {
      var data = this.datacollectionSangue.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      // console.log("data", data)
      if (data.length > 0) {
        var change = data[0].data[index] - data[0].data[index - 1];
        if (!isNaN(change)) {
          var decimal = Math.round(change * 100) / 100;
          if (change >= 0) {
            return "+" + decimal;
          } else {
            return decimal;
          }
        } else {
          return "...";
        }
      } else {
        return "...";
      }
      // return elemLabel + index;
    },
    filter_datatableSangueDataChangeStyle(elemLabel, index) {
      var data = this.datacollectionSangue.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      if (data.length > 0) {
        var change = data[0].data[index] - data[0].data[index - 1];
        if (!isNaN(change)) {
          if (change >= 0) {
            return "color: red";
          } else {
            return "color: green";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    filter_datatableSangueDataTotalChange(elemLabel) {
      var data = this.datacollectionSangue.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      // console.log("data", data)
      if (data.length > 0) {
        var change = data[0].data[data[0].data.length - 1] - data[0].data[0];
        if (!isNaN(change)) {
          var decimal = Math.round(change * 100) / 100;
          if (change >= 0) {
            return "+" + decimal;
          } else {
            return decimal;
          }
        } else {
          return "...";
        }
      } else {
        return "...";
      }
    },
    filter_datatableSangueDataTotalChangeStyle(elemLabel) {
      var data = this.datacollectionSangue.datasets.filter(function(d) {
        return d.label == elemLabel;
      });
      if (data.length > 0) {
        var change = data[0].data[data[0].data.length - 1] - data[0].data[0];
        if (!isNaN(change)) {
          if (change >= 0) {
            return "color: red";
          } else {
            return "color: green";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    visAnamneses() {
      if (this.pacienteDados.anamneses > 0) {
        this.$store.dispatch("firestore/getAnamnesesAll", {
          pacienteEmail: this.pacienteDocID,
        });
      }
      this.showAnamnesesAll = true;
    },
    visBioimpAll() {
      // if (this.gotAtendimentos < 1) {
      this.$store.dispatch("firestore/pacienteAtendimentosListN", {
        pacienteEmail: this.pacienteDocID,
      });
      // }
      this.showBioimpAll = true;
      this.gotAtendimentos = 1;
    },
    visEvolucao() {
      console.log("show me", this.pacienteDocID);
      // if (this.gotAtendimentos < 1 && this.gotFillData < 1) {
      this.$store.dispatch("firestore/pacienteAtendimentosListN", {
        pacienteEmail: this.pacienteDocID,
      });
      setTimeout(() => {
        this.fillDataCompos();
        this.fillDataMedidas();
        this.fillDataSangue();
      }, 2000);
      // } else if (this.gotAtendimentos > 0 && this.gotFillData < 1) {
      //   setTimeout(() => {
      //     this.fillDataCompos();
      //     this.fillDataMedidas();
      //     this.fillDataSangue();
      //   }, 2000);
      // }
      this.verEvolucaoDialog = true;
      this.gotAtendimentos = 1;
      this.gotFillData = 1;
    },
    visTimeline() {
      this.$store.dispatch("firestore/getPacienteTimeline", {
        pacienteEmail: this.pacienteDocID,
      });
      this.verPacienteTimeline = true;
      console.log(this.pacienteTimeline);
    },
    visDietasAll() {
      this.$store.dispatch("firestore/pacienteDietaRefs", {
        pacienteEmail: this.pacienteDocID,
      });
      this.showDietasAll = true;
    },
    visFormulasAll() {
      this.$store.dispatch("firestore/getFormulasAll", {
        pacienteEmail: this.pacienteDocID,
      });
      this.showFormulasAll = true;
    },
    visExamesAll() {
      this.$store.dispatch("firestore/getExamesAll", {
        pacienteEmail: this.pacienteDocID,
      });
      this.showExamesAll = true;
    },
    visOrientacoesAll() {
      this.$store.dispatch("firestore/getOrientacoesAll", {
        pacienteEmail: this.pacienteDocID,
      });
      this.showOrientacoesAll = true;
    },
    visVideoAulasAll() {
      this.$store.dispatch("firestore/getVideoAulasAll", {
        pacienteEmail: this.pacienteDocID,
      });
      this.showVideoAulasAll = true;
    },
    // async visQuestionariosAll() {
    //   // this.$store.dispatch("firestore/getQuestionariosAll", {
    //   //   pacienteEmail: this.pacienteDocID,
    //   // });
    //   var questsGot = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc(this.pacienteDocID)
    //     .collection("questionarios")
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         return doc.data();
    //       })
    //     );
    //   this.pacienteQuestionariosAll = questsGot;
    //   this.showQuestionariosAll = true;
    // },
    visQuestionarioPreConsulta() {
      this.showQuestPreConsulta = true;
    },
    // visQuestionarios() {
    //   this.showQuests = true;
    // },
    setTemaVisualizar(tema) {
      this.$store.dispatch("firestore/setTemaVis", {
        tema,
      });

      this.showSpecificVideoAula = true;
    },
    visComprasAll() {
      this.$store.dispatch("firestore/getComprasAll", {
        pacienteEmail: this.pacienteDocID,
      });
      this.showComprasAll = true;
    },
    visPacienteDados() {
      if (
        this.pacienteDados.nome == "" ||
        this.pacienteDados.nome == undefined
      ) {
        this.getPacienteDados();
      }
      this.gotPacienteDataNascimento = this.pacienteDados.dataNascimento;
      this.gotPacienteEmail = this.pacienteDados.email;
      this.showPacienteDados = true;
    },
    async visPacientePratos() {
      this.loadingPratos = true;
      this.showPacientePratos = true;
      this.showPratos = await firebase
        .firestore()
        .collection("users")
        .doc(this.pacienteDocID)
        .collection("dietaStories")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      this.loadingPratos = false;
    },
    visAgendaAll() {
      this.pacienteEvents = [];
      this.$store.dispatch("firestore/getAgendaAll", {
        pacienteEmail: this.pacienteDocID,
      });
      setTimeout(() => {
        function compare(a, b) {
          if (a.start < b.start) {
            return -1;
          }
          if (a.start > b.start) {
            return 1;
          }
          return 0;
        }
        this.pacienteEvents = this.pacienteAgendaAll.sort(compare);
        this.showAgendaAll = true;
      }, 2000);
    },
    visualizarEvent(event) {
      this.$store.dispatch("firestore/visEvent", { event: event });

      this.showSpecificEvent = true;
    },
    visFotosAll() {
      // if (this.gotAtendimentos < 1) {
      this.$store.dispatch("firestore/pacienteAtendimentosListN", {
        pacienteEmail: this.pacienteDocID,
      });
      // }
      this.showFotosAll = true;
      this.gotAtendimentos = 1;
    },
    visExamResultsAll() {
      // if (this.gotAtendimentos < 1) {
      this.$store.dispatch("firestore/pacienteAtendimentosListN", {
        pacienteEmail: this.pacienteDocID,
      });
      // }
      this.showExamResultsAll = true;
      this.gotAtendimentos = 1;
    },
    save(date) {
      this.$refs.menu.save(date);
      var dStr = date.substring(8, 10);
      var mStr = date.substring(5, 7);
      var aStr = date.substring(0, 4);
      var niceDate = dStr + "/" + mStr + "/" + aStr;
      this.displayDataNasc = niceDate;
      console.log(
        "this.pacienteDados.dataNascimento",
        this.pacienteDados.dataNascimento
      );
    },
    // visQuestionario() {
    //   this.$store.dispatch("firestore/getQuestionarioInfos", {
    //     paciente: this.pacienteDocID,
    //   });
    //   this.showQuestionario = true;
    // },
    visualizarTimelineItem(item) {
      if (item.type === "dieta") {
        if (this.gotDieta !== item.docId) {
          this.$store.dispatch("firestore/getDietaPacienteN", {
            dietaDocId: item.docId,
            pacienteEmail: this.pacienteDocID,
          });
          this.showSpecificDieta = true;
          this.gotDieta = item.docId;
        }
      }

      if (item.type === "atendimento") {
        // if (this.gotAtendimentos < 1) {
        this.$store.dispatch("firestore/pacienteAtendimentosListN", {
          pacienteEmail: this.pacienteDocID,
        });
        setTimeout(() => {
          this.fillDataCompos();
          this.fillDataMedidas();
          this.fillDataSangue();
        }, 2000);

        this.verEvolucaoDialog = true;
        this.gotAtendimentos = 1;
        // }
      }
    },
    baixarTimelineItem(item) {
      console.log(item);
    },
    formaIcon(forma) {
      if (forma === 1) {
        return this.icons.mdiCash100;
      } else if (forma === 2) {
        return this.icons.mdiCreditCardCheckOutline;
      } else if (forma === 3) {
        return this.icons.mdiCreditCardClockOutline;
      } else if (forma === 4) {
        return this.icons.mdiBankTransfer;
      } else if (forma === 5) {
        return this.icons.mdiInfinity;
      } else if (forma === 6) {
        return this.icons.mdiCheckbook;
      } else if (forma === 7) {
        return this.icons.mdiWalletPlusOutline;
      } else if (forma === 8) {
        return this.icons.mdiPiggyBank;
      } else {
        return this.icons.mdiHelpRhombusOutline;
      }
    },
    niceString(valor) {
      var valorAbs = Math.abs(valor);
      var valorKomma;

      var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valorAbs);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valorAbs / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    formaStr(forma) {
      if (forma === 1) {
        return "Dinheiro";
      } else if (forma === 2) {
        return "Cartão de Débito";
      } else if (forma === 3) {
        return "Cartão de Crédito";
      } else if (forma === 4) {
        return "Transferência Bancária";
      } else if (forma === 5) {
        return "PIX";
      } else if (forma === 6) {
        return "Cheque";
      } else if (forma === 7) {
        return "multiplas formas";
      } else if (forma === 8) {
        return "Depósito Bancário";
      } else {
        return "forma de pagamento não especificada";
      }
    },
    // createListWithNames() {
    //   var values = Object.values(this.pacientes);
    //   this.pacientes = values.map((valueItem) => {
    //     return {
    //       value: valueItem.id,
    //       text: valueItem.nome,
    //     };
    //   });
    // },
    pacienteParaPreAtendimento() {
      console.log(this.pacienteDocID);
      this.$store.dispatch("firestore/pacientePreAtendimento", {
        id: this.pacienteDocID,
      });
    },
    pacienteParaAtendimento() {
      console.log(this.pacienteDocID);
      this.$store.dispatch("firestore/pacienteAtendimento", {
        id: this.pacienteDocID,
      });
    },
    pacienteParaAnamnese() {
      this.$store.dispatch("firestore/pacienteAnamnese", {
        id: this.pacienteDocID,
      });
    },
    setPacienteVerHistorico() {
      console.log(this.pacienteDocID);

      this.$store.dispatch("firestore/pacienteVerHistorico", {
        id: this.pacienteDocID,
      });

      console.log(this.pacienteDados);
      this.setProgramaInicio();
      this.setProgramaFim();
    },
    porcao(nr) {
      if (nr > 1) {
        return "porções";
      } else {
        return "porção";
      }
    },
    stringify(min) {
      if (min < 10) {
        return "0" + min.toString();
      } else {
        return min;
      }
    },
    readableStart(startStr) {
      var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
      var date = new Date(startStr);
      var dayName = diasPtBr[date.getDay()];
      var day = date.getDate();
      var dayStr;
      if (day < 10) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var month = date.getMonth() + 1;
      var monthStr;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }
      var year = date.getYear() - 100;
      var yearStr = year.toString();
      var hoursStr = date.getHours().toString();
      var min = date.getMinutes();
      var minStr;
      if (min < 10) {
        minStr = "0" + min.toString();
      } else {
        minStr = min.toString();
      }

      var str =
        dayName +
        ", " +
        dayStr +
        "/" +
        monthStr +
        "/" +
        yearStr +
        ", " +
        hoursStr +
        ":" +
        minStr;

      return str;
    },
    readableEnd(endStr) {
      var date = new Date(endStr);
      var hoursStr = date.getHours().toString();
      var min = date.getMinutes();
      var minStr;
      if (min < 10) {
        minStr = "0" + min.toString();
      } else {
        minStr = min.toString();
      }

      var str = hoursStr + ":" + minStr;

      return str;
    },
    async updatePacienteDados() {
      this.saved = false;
      this.progressDialog = true;
      this.$store.dispatch("firestore/updatePacienteDados", {
        nutri: sessionStorage.getItem("current-user"),
        pacienteEmail: this.pacienteDocID,
        nome: this.pacienteDados.nome,
        sexo: this.pacienteDados.sexo,
        gestante: this.pacienteDados.gestante,
        dataNascimento: this.pacienteDados.dataNascimento,
        email:
          this.pacienteDados.email !== undefined
            ? this.pacienteDados.email
            : "",
        telefone:
          this.pacienteDados.telefone !== undefined
            ? this.pacienteDados.telefone
            : "",
        whatsapp:
          this.pacienteDados.whatsapp !== undefined
            ? this.pacienteDados.whatsapp
            : "",
        convenio:
          this.pacienteDados.convenio !== undefined
            ? this.pacienteDados.convenio
            : "",
        estado:
          this.pacienteDados.estado !== undefined
            ? this.pacienteDados.estado
            : "",
        endereco:
          this.pacienteDados.endereco !== undefined
            ? this.pacienteDados.endereco
            : "",
        bairro:
          this.pacienteDados.bairro !== undefined
            ? this.pacienteDados.bairro
            : "",
        cep: this.pacienteDados.cep !== undefined ? this.pacienteDados.cep : "",
        cidade:
          this.pacienteDados.cidade !== undefined
            ? this.pacienteDados.cidade
            : "",
        cpf: this.pacienteDados.cpf !== undefined ? this.pacienteDados.cpf : "",
        objetivo:
          this.pacienteDados.objetivo !== undefined
            ? this.pacienteDados.objetivo
            : "",
      });

      if (
        this.pacienteDados.dataNascimento !== this.gotPacienteDataNascimento &&
        this.gotPacienteDataNascimento !== null &&
        this.gotPacienteDataNascimento !== undefined
      ) {
        var newDataNascimento = this.pacienteDados.dataNascimento;
        await this.pacienteDados.anivLembArray.forEach(async function(lembObj) {
          var yearStr = lembObj.year.toString();
          var newDataNascimentoDate = new Date(newDataNascimento + " 06:00:00");
          var month = newDataNascimentoDate.getMonth() + 1;
          var day = newDataNascimentoDate.getDate();
          var monthStr;
          if (month > 9) {
            monthStr = month.toString();
          } else {
            monthStr = "0" + month.toString();
          }
          var dayStr;
          if (day > 9) {
            dayStr = day.toString();
          } else {
            dayStr = "0" + day.toString();
          }

          var birthdayStr =
            yearStr + "-" + monthStr + "-" + dayStr + " 06:00:00";
          var newDueDateDate = new Date(birthdayStr);
          // var sub24 = 24*60*60*1000;
          var newDueDate = newDueDateDate.getTime(); // - sub24;

          var now = new Date();
          var nowMillis = now.getTime();
          var newDueDatePast = newDueDate < nowMillis;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(lembObj.id)
            .update({
              dueDate: newDueDate,
              done: newDueDatePast,
              dataNascimento: newDataNascimento,
            });
        });
      }

      if (
        this.emailFoiEditado &&
        this.pacienteDados.email !== this.gotPacienteEmail &&
        this.gotPacienteEmail !== null &&
        this.gotPacienteEmail !== undefined
      ) {
        this.progressText =
          "Alterando email de acesso do paciente! Este processo pode demorar uns minutos...";

        var contentDoc = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("pacientes")
          .doc(this.gotPacienteEmail)
          .get()
          .then(function(doc) {
            return doc.data();
          });

        contentDoc.email = this.pacienteDados.email;

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("pacientes")
          .doc(this.pacienteDados.email)
          .set(contentDoc)
          .then(function() {
            console.log("generated copy in pacientes", contentDoc);
          });

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("pacientes")
          .doc(this.gotPacienteEmail)
          .delete()
          .then(function() {
            console.log("deleted in pacientes");
          });

        var contentDocUser = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .get()
          .then(function(doc) {
            return doc.data();
          });

        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDados.email)
          .set(contentDocUser)
          .then(function() {
            console.log("generated copy in users", contentDocUser);
          });

        var newEmail = this.pacienteDados.email;

        var agendaExists = false;
        agendaExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("agenda")
          .get()
          .then(function(snapshot) {
            return snapshot.docs.length > 0;
          });

        console.log("agendaExists", agendaExists);
        if (agendaExists) {
          var agendaCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("agenda")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          agendaCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("agenda")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var comprasExists = false;
        comprasExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("compras")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("comprasExists", comprasExists);
        if (comprasExists) {
          var comprasCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("compras")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          comprasCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("compras")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var anamneseNotesExists = false;
        anamneseNotesExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("anamneseNotes")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("anamneseNotesExists", anamneseNotesExists);
        if (anamneseNotesExists) {
          var anamneseNotesCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("anamneseNotes")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          anamneseNotesCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("anamneseNotes")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var atendimentosExists = false;
        atendimentosExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("atendimentos")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("atendimentosExists", atendimentosExists);
        if (atendimentosExists) {
          var atendimentosCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("atendimentos")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          atendimentosCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("atendimentos")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var dietasRefsExists = false;
        dietasRefsExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("dietasRefs")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("dietasRefsExists", dietasRefsExists);
        if (dietasRefsExists) {
          var dietasRefsCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("dietasRefs")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          dietasRefsCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("dietasRefs")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var dietasExists = false;
        dietasExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("dietas")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("dietasExists", dietasExists);
        if (dietasExists) {
          var dietasCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("dietas")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          dietasCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("dietas")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var examesExists = false;
        examesExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("exames")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("examesExists", examesExists);
        if (examesExists) {
          var examesCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("exames")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          examesCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("exames")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var orientacoesExists = false;
        orientacoesExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("orientacoes")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("orientacoesExists", orientacoesExists);
        if (orientacoesExists) {
          var orientacoesCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("orientacoes")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          orientacoesCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("orientacoes")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var formulasExists = false;
        formulasExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("formulas")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("formulasExists", formulasExists);
        if (formulasExists) {
          var formulasCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("formulas")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          formulasCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("formulas")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var timelineExists = false;
        timelineExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("timeline")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("timelineExists", timelineExists);
        if (timelineExists) {
          var timelineCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("timeline")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          timelineCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("timeline")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var dietaStoriesExists = false;
        dietaStoriesExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("dietaStories")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("dietaStoriesExists", dietaStoriesExists);
        if (dietaStoriesExists) {
          var dietaStoriesCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("dietaStories")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          dietaStoriesCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("dietaStories")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var videoAulasExists = false;
        videoAulasExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("videoAulas")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("videoAulasExists", videoAulasExists);
        if (videoAulasExists) {
          var videoAulasCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("videoAulas")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          videoAulasCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("videoAulas")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        var testesImunidadeExists = false;
        testesImunidadeExists = await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .collection("testesImunidade")
          .get()
          .then(function(snapshot) {
            console.log("agenda snapshot", snapshot);
            return snapshot.docs.length > 0;
          });
        console.log("testesImunidadeExists", testesImunidadeExists);
        if (testesImunidadeExists) {
          var testesImunidadeCol = await firebase
            .firestore()
            .collection("users")
            .doc(this.gotPacienteEmail)
            .collection("testesImunidade")
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          testesImunidadeCol.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection("users")
              .doc(newEmail)
              .collection("testesImunidade")
              .doc(doc.id.toString())
              .set(doc);
          });
        }

        // only after successfully copying inner collections
        await firebase
          .firestore()
          .collection("users")
          .doc(this.gotPacienteEmail)
          .delete()
          .then(function() {
            console.log("deleted in users");
          });

        var errorFauth = false;
        var errorFauthMsg = null;

        await firebase
          .auth()
          .createUserWithEmailAndPassword(this.pacienteDados.email, "nutri123")
          .then((user) => {
            console.log(user);
          })
          .catch((error) => {
            console.log(error);
            console.log("error fauth");
            errorFauth = true;
            errorFauthMsg = error.message;
          });

        if (errorFauth) {
          this.errorFauthMsg = errorFauthMsg;
        }

        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var hour = now.getHours();
        var mins = now.getMinutes();
        await firebase
          .firestore()
          .collection("usersToDelete")
          .doc(this.gotPacienteEmail)
          .set({
            old: this.gotPacienteEmail,
            new: this.pacienteDados.email,
            deleted: day + "/" + month + "/" + year + " " + hour + ":" + mins,
          });
      }

      this.saved = true;
      this.showPacienteDados = false;
    },
    setDietaVisualizar(dietaDocId) {
      if (this.gotDieta != dietaDocId) {
        this.$store.dispatch("firestore/getDietaPacienteN", {
          dietaDocId: dietaDocId,
          pacienteEmail: this.pacienteDocID,
        });
      }
      this.showSpecificDieta = true;
      this.gotDieta = dietaDocId;
    },
    setDietaEditar(dietaDocId) {
      if (this.gotDieta != dietaDocId) {
        this.$store.dispatch("firestore/getDietaPacienteN", {
          dietaDocId: dietaDocId,
          pacienteEmail: this.pacienteDocID,
        });
      }
      this.$store.dispatch("firestore/persistPaciente", {
        pId: this.pacienteDocID,
      });
      this.routerDialog = true;
    },
    confirmEditarDieta() {
      setTimeout(() => {
        this.$router.push("DietasSalvas");
      }, 1000);
    },
    setFormulaVisualizar(formula) {
      // if (this.gotFormula !== docId) {
      //   this.$store.dispatch("firestore/getFormulaPacienteN", {
      //     docId: docId,
      //     pacienteEmail: this.pacienteDocID,
      //   });
      // }
      this.formulaPVis = formula;
      this.showSpecificFormula = true;
      // this.gotFormula = docId;
    },
    setFormulaEditar(f) {
      console.log("formula", f);
      this.$store.dispatch("firestore/persistPaciente", {
        pId: this.pacienteDocID,
      });
      this.$store.dispatch("firestore/formulaEditData", {
        formula: f,
      });
      this.$store.dispatch("firestore/prescricaoCUpdate", {
        prescricaoC: f.prescricaoC,
      });
      setTimeout(() => {
        this.$router.push("FormulaPrescrever");
        console.log(
          "1: this.$store.state.firestore.prescricaoC",
          this.$store.state.firestore.prescricaoC
        );
      }, 1000);
    },
    setExameVisualizar(docId) {
      if (this.gotExame !== docId) {
        this.$store.dispatch("firestore/getExamePacienteN", {
          docId: docId,
          pacienteEmail: this.pacienteDocID,
        });
      }
      this.showSpecificExame = true;
      this.gotExame = docId;
    },
    setOrientacaoVisualizar(docId) {
      if (this.gotOrientacao !== docId) {
        this.$store.dispatch("firestore/getOrientacaoPacienteN", {
          docId: docId,
          pacienteEmail: this.pacienteDocID,
        });
      }
      this.showSpecificOrientacao = true;
      this.gotOrientacao = docId;
    },
    setCompraVisualizar(compra) {
      this.$store.dispatch("firestore/setCompraVis", {
        compra,
      });

      this.showSpecificCompra = true;
    },
    async preloadImages() {
      if (this.isFranquia) {
        this.preloadLogo.src = require("@/assets/logoCompleto.png");
      } else if (
        this.userInfo.profilePicture !== undefined &&
        this.userInfo.profilePicture !== "" &&
        !this.isFranquia
      ) {
        this.toDataURL(this.userInfo.profilePicture, (data) => {
          this.preloadLogo.src = data;
        });
      } else {
        this.preloadLogo.src = require("@/assets/signature_none.jpg");
      }

      this.preloadLogo.onload = function() {
        console.log("Logo carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      this.preloadSignature.src = require("@/assets/signature_none.jpg");
      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };
    },
    async createDietaWithJSPDF(titulo, createdAt) {
      if (this.dietaRefeicoes.length !== 0) {
        alert("Gerando PDF");
        // links
        // https://mrrio.github.io/jsPDF/examples/basic.html
        // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
        // https://simonbengtsson.github.io/jsPDF-AutoTable/
        // https://www.npmjs.com/package/jspdf-autotable

        var doc = new jspdf("p");
        var day = createdAt.toDate().getDate();
        var month = createdAt.toDate().getMonth() + 1;
        var year = createdAt.toDate().getFullYear();

        // Doc settings
        var fontSize = 10;
        doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
        doc.addFont(
          "Montserrat-Medium-normal.ttf",
          "Montserrat-Medium",
          "normal"
        );
        doc.setFontSize(fontSize);
        doc.setFont("Montserrat-Medium");

        // The (900 / 262) division is used to keep the proportion of the original image
        // New Width * (900 / 262) will keep the height proportion

        var imgWidth = 40;
        // var imgHeight = imgWidth / (900 / 262);
        var imgHeight =
          imgWidth /
          (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
        var imgPos = (210 - imgWidth) / 2;
        var startXPos = 15;
        var textStartYPos = imgHeight * 1.1 + 12;

        var centeredText = function(text, y) {
          var textWidth =
            (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
          doc.text(textOffset, y, text);
        };

        // var createTexts = function(
        //   textList,
        //   centered = false,
        //   textStart = textStartYPos
        // ) {
        //   for (var i = 0; i < textList.length; i++) {
        //     if (centered) {
        //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
        //     } else {
        //       var lines = doc
        //         .setFont("Montserrat-Medium")
        //         .splitTextToSize(textList[i], 180);
        //       // console.log(lines)
        //       for (var j = 0; j < lines.length; j++) {
        //         doc.text(
        //           startXPos,
        //           textStart + (fontSize / 2) * (1 + j),
        //           lines[j]
        //         );
        //       }
        //       textStart += (fontSize / 2) * /*1 + */ lines.length;
        //     }
        //   }
        //   return (textStart += fontSize / 2);
        // };

        var createTexts = function(
          textList,
          centered = false,
          textStart = textStartYPos
        ) {
          var pageHeightThreshold = 256; // The max Y position for the current page
          var newYStart = 24; // The Y position to start at on a new page

          for (var i = 0; i < textList.length; i++) {
            var lines = doc
              .setFont("Montserrat-Medium")
              .splitTextToSize(textList[i], 180);

            for (var j = 0; j < lines.length; j++) {
              // Check if the current line would go past the threshold
              if (textStart > pageHeightThreshold) {
                doc.addPage();
                textStart = newYStart; // Reset the text start position for the new page
              }

              // Whether to center the text or not
              if (centered) {
                centeredText(lines[j], textStart);
              } else {
                doc.text(startXPos, textStart, lines[j]);
              }

              textStart += fontSize / 2; // Increment the y position for each line of text
            }

            // Increment after each text block rather than each line
            if (!centered) {
              textStart += fontSize / 2; // This accounts for the space after each block of text
            }
          }

          return textStart; // Return the updated text start position
        };

        var createTablesForRefeicoes = function(refeicoes, startPosY) {
          var startPosYNew = startPosY;
          // #c5d13f <----- trocar a cor da tabela
          for (var i = 0; i < refeicoes.length; i++) {
            var texts;
            // if (refeicoes[i].observacao !== "") {
            //   texts = [
            //     `${refeicoes[i].nomeRefeicao} - ${refeicoes[i].horarioRefeicao}`,
            //     `Observação: ${refeicoes[i].observacao}`,
            //   ];
            // } else {
            texts = [
              `${refeicoes[i].nomeRefeicao} - ${refeicoes[i].horarioRefeicao}`,
            ];
            // }
            startPosYNew = createTexts(texts, false, startPosYNew + 12); //lastPos
            var body = refeicoes[i].alimentosList.map(function(alimento) {
              if (alimento != undefined) {
                alimento.hasSubs = "";
                if (alimento.toSubstitute != undefined) {
                  alimento.hasSubs = "*";
                }
                return [
                  alimento.nome,
                  alimento.quantidade, //.toString(),
                  alimento.medida, //.toString(),
                  alimento.gramas, //.toString(),
                  // alimento.kcal//.toString(),
                  alimento.hasSubs,
                ];
              } else {
                return [];
              }
            });
            console.log("", refeicoes[i].alimentosList[0]);
            if (refeicoes[i].alimentosList[0].alimentoId !== "") {
              doc.autoTable({
                startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
                startx: startXPos,
                head: [
                  ["Alimento", "Quantidade", "Medida", "Total (g)", "Subst."],
                ],
                body: body,
                // tableLineColor: [97, 64, 33],
                // tableLineWidth: 0.5,
                // headStyles: {
                //   fillColor: [176, 159, 144],
                //   textColor: 40,
                //   fontStyle: "regular",
                // },
                // bodyStyles: {
                //   fillColor: [223, 216, 210],
                // },
                // alternateRowStyles: {
                //   fillColor: [239, 235, 232],
                // },
                headStyles: {
                  fillColor: [226, 232, 159],
                  textColor: 40,
                  fontStyle: "regular",
                },
                bodyStyles: {
                  fillColor: [243, 245, 216],
                },
                alternateRowStyles: {
                  fillColor: [249, 250, 235],
                },
              });
              startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
            }

            // doc.text "* substituir {alimento} com {alimento.toSubstitute[0], [1]...}

            var bodyReceitas = refeicoes[i].receitasList.map(function(receita) {
              if (receita != undefined) {
                return [receita.titulo, receita.porcoes];
              } else {
                return [];
              }
            });
            console.log("receitas[0]", refeicoes[i].receitasList[0]);
            if (refeicoes[i].receitasList[0].titulo !== "") {
              doc.autoTable({
                startY: startPosYNew, //startPosY + i * (lastPos - startPosY),
                startx: startXPos,
                head: [["Receita", "Porções"]],
                body: bodyReceitas,
                // tableLineColor: [197, 209, 63],
                // tableLineWidth: 0.5,
                headStyles: {
                  fillColor: [226, 232, 159],
                  textColor: 40,
                  fontStyle: "regular",
                },
                bodyStyles: {
                  fillColor: [243, 245, 216],
                },
                alternateRowStyles: {
                  fillColor: [249, 250, 235],
                },
              });
              startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
            }

            if (refeicoes[i].observacao !== "") {
              doc.autoTable({
                startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
                startx: startXPos,
                head: [["Observações"]],
                body: [[refeicoes[i].observacao]],
                // tableLineColor: [149,153,156],
                // tableLineWidth: 0.5,
                headStyles: {
                  fillColor: [214, 219, 223],
                  textColor: 40,
                  fontStyle: "regular",
                },
                bodyStyles: {
                  fillColor: [238, 240, 242],
                },
              });
              startPosYNew = doc.previousAutoTable.finalY;
            }

            for (var jn = 0; jn < refeicoes[i].alimentosList.length; jn++) {
              if (refeicoes[i].alimentosList[jn].toSubstitute != undefined) {
                // console.log(refeicoes[i].alimentosList[jn]);
                var textsSub;
                if (refeicoes[i].alimentosList[jn].medida != null) {
                  textsSub = [
                    `substituir ${refeicoes[i].alimentosList[jn].quantidade} ${refeicoes[i].alimentosList[jn].medida} de ${refeicoes[i].alimentosList[jn].nome} por...`,
                  ];
                } else {
                  textsSub = [
                    `substituir ${refeicoes[i].alimentosList[jn].gramas}g de ${refeicoes[i].alimentosList[jn].nome} por...`,
                  ];
                }

                // var lastPos = createTexts(textsSub, false, startPosYNew);
                var bodySub = refeicoes[i].alimentosList[jn].toSubstitute.map(
                  function(alimento) {
                    if (alimento != undefined) {
                      if (alimento.toSubstitute != undefined) {
                        alimento.hasSubs = "*";
                      }
                      if (alimento.medida_caseira_padrao == "freetext") {
                        alimento.qtdSub = "";
                        alimento.medida_caseira_padrao = "";
                        alimento.gramas = "";
                      }
                      return [
                        alimento.nome,
                        alimento.qtdSub, //.toString(),
                        alimento.medida_caseira_padrao, //.toString(),
                        alimento.gramas, //.toString(),
                      ];
                    } else {
                      return [];
                    }
                  }
                );

                doc.autoTable({
                  startY: startPosYNew, // lastPos, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [[textsSub, "Qtd.", "Medida", "Total (g)"]],
                  body: bodySub,
                  // tableLineColor: [97, 64, 33],
                  // tableLineWidth: 0.5,
                  // headStyles: {
                  //   fillColor: [176, 159, 144],
                  //   textColor: 40,
                  //   fontStyle: "regular",
                  // },
                  // bodyStyles: {
                  //   fillColor: [223, 216, 210],
                  // },
                  // alternateRowStyles: {
                  //   fillColor: [239, 235, 232],
                  // },
                  headStyles: {
                    fillColor: [245, 203, 167],
                    textColor: 40,
                    fontStyle: "regular",
                  },
                  bodyStyles: {
                    fillColor: [250, 229, 211],
                  },
                  alternateRowStyles: {
                    fillColor: [253, 242, 223],
                  },
                });
                startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
              }
            }
          }
          return startPosYNew; // doc.previousAutoTable.finalY;
        };

        // var createTablesForSubst = function(refeicoes, startPosY) {
        //   // #c5d13f <----- trocar a cor da tabela
        //   for (var i = 0; i < refeicoes.length; i++) {
        //     for (var j = 0; j < refeicoes[i].alimentosList.length; j++) {
        //       if (refeicoes[i].alimentosList[j].toSubstitute != undefined) {
        //         // console.log(refeicoes[i].alimentosList[j]);
        //         var textsSub;
        //         if (refeicoes[i].alimentosList[j].medida != null) {
        //           textsSub = [
        //             `${refeicoes[i].nomeRefeicao}: substituir ${refeicoes[i].alimentosList[j].quantidade} ${refeicoes[i].alimentosList[j].medida} de ${refeicoes[i].alimentosList[j].nome} por...`,
        //           ];
        //         } else {
        //           textsSub = [
        //             `${refeicoes[i].nomeRefeicao}: substituir ${refeicoes[i].alimentosList[j].gramas}g de ${refeicoes[i].alimentosList[j].nome} por...`,
        //           ];
        //         }

        //         var lastPos = createTexts(textsSub, false, startPosY);
        //         var bodySub = refeicoes[i].alimentosList[j].toSubstitute.map(
        //           function(alimento) {
        //             if (alimento != undefined) {
        //               if (alimento.toSubstitute != undefined) {
        //                 alimento.hasSubs = "*";
        //               }
        //               return [
        //                 alimento.nome,
        //                 alimento.qtdSub, //.toString(),
        //                 alimento.medida_caseira_padrao, //.toString(),
        //                 alimento.gramas, //.toString(),
        //               ];
        //             } else {
        //               return [];
        //             }
        //           }
        //         );

        //         doc.autoTable({
        //           startY: lastPos, //(lastPos / 2) * 0.7 + startPosY,
        //           startx: startXPos,
        //           head: [["Alimento", "Quantidade", "Medida", "Total (g)"]],
        //           body: bodySub,
        //           // tableLineColor: [97, 64, 33],
        //           // tableLineWidth: 0.5,
        //           // headStyles: {
        //           //   fillColor: [176, 159, 144],
        //           //   textColor: 40,
        //           //   fontStyle: "regular",
        //           // },
        //           // bodyStyles: {
        //           //   fillColor: [223, 216, 210],
        //           // },
        //           // alternateRowStyles: {
        //           //   fillColor: [239, 235, 232],
        //           // },
        //           headStyles: {
        //             fillColor: [226, 232, 159],
        //             textColor: 40,
        //             fontStyle: "regular",
        //           },
        //           bodyStyles: {
        //             fillColor: [243, 245, 216],
        //           },
        //           alternateRowStyles: {
        //             fillColor: [249, 250, 235],
        //           },
        //         });
        //         startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
        //       }
        //     }
        //   }
        //   return doc.previousAutoTable.finalY;
        // };

        var createTablesForReceitas = function(refeicoes, startPosY) {
          // #c5d13f <----- trocar a cor da tabela
          for (var i = 0; i < refeicoes.length; i++) {
            for (var j = 0; j < refeicoes[i].receitasList.length; j++) {
              if (refeicoes[i].receitasList[j].titulo !== "") {
                // console.log(refeicoes[i].receitasList[j]);
                var textsRec = [
                  `${refeicoes[i].nomeRefeicao}: ${refeicoes[i].receitasList[j].titulo}, rendimento: ${refeicoes[i].receitasList[j].rendimento} (porções)`,
                  // `${refeicoes[i].receitasList[j].modoPreparo}`,
                ];

                var lastPos = createTexts(textsRec, false, startPosY + 6);
                var bodyRec = refeicoes[i].receitasList[j].alimentosList.map(
                  function(alimento) {
                    if (alimento != undefined) {
                      return [
                        alimento.nome,
                        alimento.quantidade, //.toString(),
                        alimento.medida, //.toString(),
                        alimento.gramas, //.toString(),
                      ];
                    } else {
                      return [];
                    }
                  }
                );
                var bodyModoPreparo = [
                  [refeicoes[i].receitasList[j].modoPreparo],
                ];

                doc.autoTable({
                  startY: lastPos, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [["Ingrediente", "Quantidade", "Medida", "Total (g)"]],
                  body: bodyRec,
                  // tableLineColor: [197, 209, 63],
                  // tableLineWidth: 0.5,
                  headStyles: {
                    fillColor: [226, 232, 159],
                    textColor: 40,
                    fontStyle: "regular",
                  },
                  bodyStyles: {
                    fillColor: [243, 245, 216],
                  },
                  alternateRowStyles: {
                    fillColor: [249, 250, 235],
                  },
                });
                startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
                doc.autoTable({
                  startY: startPosY, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [["Modo de preparo"]],
                  body: bodyModoPreparo,
                  // tableLineColor: [197, 209, 63],
                  // tableLineWidth: 0.5,
                  // headStyles: {
                  //   fillColor: [226, 232, 159],
                  //   textColor: 40,
                  //   fontStyle: "regular",
                  // },
                  // bodyStyles: {
                  //   fillColor: [243, 245, 216],
                  // },
                  headStyles: {
                    fillColor: [214, 219, 223],
                    textColor: 40,
                    fontStyle: "regular",
                  },
                  bodyStyles: {
                    fillColor: [238, 240, 242],
                  },
                });
                startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
              }
            }
          }
          return doc.previousAutoTable.finalY;
        };

        // console.log(this.$store.state.firestore.userInfo);
        // // Logo Clubedonutricionista
        // if (this.$store.state.firestore.userInfo.useLogo === false) {
        //   console.log("op 1");
        doc.addImage({
          imageData: this.preloadLogo,
          y: startXPos,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });
        // } else {
        //   // LOGO CLUBE
        //   imgWidth = 30;
        //   imgHeight = imgWidth / (900 / 262);
        //   startXPos = 15;

        //   doc.addImage({
        //     imageData: require("@/assets/logoCompleto.png"),
        //     y: startXPos,
        //     x: 15,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });

        //   imgWidth = 50;
        //   var startYPos = 15 + imgHeight * 2;
        //   imgHeight =
        //     imgWidth /
        //     (this.preloadSignature.naturalWidth /
        //       this.preloadSignature.naturalHeight);
        //   imgPos = (210 - imgWidth) / 2;
        //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

        //   doc.addImage({
        //     imageData: this.preloadSignature,
        //     y: startYPos,
        //     x: imgPos,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });
        // }

        var texts = [
          `Plano alimentar: ${titulo} (${day}/${month}/${year})`,
          `Para: ${this.pacienteDados.nome}`,
        ];

        createTexts(texts, true, textStartYPos + 10);
        var endTablePosY = createTablesForRefeicoes(
          this.dietaRefeicoes,
          textStartYPos + texts.length * fontSize + 10
        );

        // doc.addPage();
        // createTexts(
        //   [
        //     // "_______________________________________________________",
        //     "Possíveis Substituções de Alimentos",
        //   ],
        //   true,
        //   24
        //   // endTablePosY
        // );
        // endTablePosY = createTablesForSubst(
        //   this.dietaRefeicoes,
        //   36
        //   // endTablePosY + 24
        // );

        var refNrRecList = this.dietaRefeicoes.map(function(ref) {
          if (
            ref.receitasList.length > 0 &&
            ref.receitasList[0].titulo !== "" &&
            ref.receitasList[0].titulo !== undefined
          ) {
            return 1;
          } else {
            return 0;
          }
        });
        var nrRecs = 0;
        refNrRecList.forEach(function(nr) {
          nrRecs += parseFloat(nr);
        });

        if (nrRecs > 0) {
          console.log("nrRecs", nrRecs);
          doc.addPage();
          createTexts(
            [
              // "_______________________________________________________",
              "Receitas",
            ],
            true,
            24
            // endTablePosY
          );
          endTablePosY = createTablesForReceitas(
            this.dietaRefeicoes,
            36
            // endTablePosY + 24
          );
        }

        // USE SIGNATURE
        // console.log(
        //   "signature",
        //   this.$store.state.firestore.userInfo.signature
        // );
        // if (this.$store.state.firestore.userInfo.signature != undefined) {
        //   imgWidth = 30;
        //   startYPos =
        //     fontSize +
        //     (endTablePosY !== undefined
        //       ? endTablePosY + 16
        //       : textStartYPos + texts.length * (fontSize * (3 / 4)));
        //   imgHeight =
        //     imgWidth /
        //     (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
        //   imgPos = (210 - imgWidth) / 2;
        //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

        //   doc.addImage({
        //     imageData: this.preloadSignature,
        //     y: startYPos,
        //     x: imgPos,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });
        // } else {
        //   imgHeight = 0;
        // }

        doc.addPage();
        imgWidth = 75;
        imgHeight =
          imgWidth /
          (this.preloadSignature.naturalWidth /
            this.preloadSignature.naturalHeight);
        imgPos = (210 - imgWidth) / 2;
        textStartYPos = imgHeight + 24;
        // textStartYPos = imgHeight + endTablePosY + 12;

        doc.addImage({
          imageData: this.preloadSignature,
          // y: endTablePosY + 12, // + imgHeight * 1.4,
          y: 24,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });

        createTexts(
          [
            "____________________________",
            // `Nutricionista ${this.$store.state.firestore.userInfo.name}`,
            // `CRN: ${this.$store.state.firestore.userInfo.crn}`,
            `Nutricionista: ${this.autorData.nome}`,
            `CRN: ${this.autorData.crn}`,
            `Data: ${day}/${month}/${year}`,
            "",
            `${this.userInfo.addressRoad} - ${this.userInfo.addressComplement}`,
            `${this.userInfo.addressCep} ${this.userInfo.addressCity} - ${this.userInfo.addressBairro}`,
            `tel.: ${this.userInfo.phoneClients}`,
          ],
          true,
          // endTablePosY !== undefined
          //   ? endTablePosY + 16 + imgHeight * 1.4
          //   :
          textStartYPos // + texts.length * (fontSize * (3 / 4))
        );
        console.log(endTablePosY);
        doc.save(`${titulo}.pdf`);
      } else {
        alert("Preencha todos os campos da dieta antes de continuar");
      }
    },
    async dietaDownloadPdf(dietaDocId, titulo, createdAt) {
      console.log(dietaDocId);
      if (this.gotDieta !== dietaDocId) {
        this.$store.dispatch("firestore/getDietaPacienteN", {
          dietaDocId: dietaDocId,
          pacienteEmail: this.pacienteDocID,
        });
        this.gotDieta = dietaDocId;
      }

      console.log(
        "this.dietaAutorObj.assinatura",
        this.dietaAutorObj.assinatura
      );

      if (
        this.dietaAutorObj.assinatura !== "" &&
        this.dietaAutorObj.assinatura !== undefined
      ) {
        this.toDataURL(this.dietaAutorObj.assinatura, (data) => {
          this.preloadSignature.src = data;
        });
        this.autorData.nome = this.dietaAutorObj.nome;
        this.autorData.crn = this.dietaAutorObj.crn;
        console.log(this.preloadSignature.src);
      } else {
        if (
          this.dietaAutorObj.email !== "" &&
          this.dietaAutorObj.email !== undefined
        ) {
          if (
            this.dietaAutorObj.email ==
            "umarizalbelem@clubedanutricionista.com.br"
          ) {
            if (this.dietaAutorObj.papel == "Nutricionista 1") {
              this.preloadSignature.src = require("@/assets/signature_danielle.png");
              this.autorData.nome = "Danielle Zahlouth";
              this.autorData.crn = "11517";
            } else if (this.dietaAutorObj.papel == "Nutricionista 2") {
              this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
              this.autorData.nome = "Thayana Albuquerque Kirchhoff";
              this.autorData.crn = "4571";
            } else if (this.dietaAutorObj.papel == "Nutricionista 3") {
              this.preloadSignature.src = require("@/assets/signature_henrique.png");
              this.autorName = "Jhonatas Henrique Marques Barros";
              this.autorCrn = "12324";
            }
          }

          if (this.dietaAutorObj.email == "bc@clubedanutricionista.com.br") {
            this.preloadSignature.src = require("@/assets/signature_pietra.png");
            this.autorData.nome = "Pietra Chiella";
            this.autorData.crn = "SC 8570";
          }

          if (
            this.dietaAutorObj.email ==
            "fortaleza.salinas@clubedanutricionista.com.br"
          ) {
            // if (this.dietaAutorObj.papel == "Nutricionista 1") {
            this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
            this.autorData.nome = "Thayana Albuquerque Kirchhoff";
            this.autorData.crn = "4571";
            // }
          }
        } else {
          if (
            this.userInfo.nutri == "umarizalbelem@clubedanutricionista.com.br"
          ) {
            this.preloadSignature.src = require("@/assets/signature_danielle.png");
            this.autorData.nome = "Danielle Zahlouth";
            this.autorData.crn = "11517";
          } else if (this.userInfo.nutri == "bc@clubedanutricionista.com.br") {
            this.preloadSignature.src = require("@/assets/signature_pietra.png");
            this.autorData.nome = "Pietra Chiella";
            this.autorData.crn = "SC 8570";
          } else if (
            this.userInfo.nutri ==
            "fortaleza.salinas@clubedanutricionista.com.br"
          ) {
            this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
            this.autorData.nome = "Thayana Albuquerque Kirchhoff";
            this.autorData.crn = "4571";
          } else {
            this.preloadSignature.src = require("@/assets/signature_none.jpg");
          }
        }
      }

      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );

      setTimeout(() => {
        this.createDietaWithJSPDF(titulo, createdAt);
      }, 1500);
    },
    exameDownloadPdf: async function(exame) {
      var pacienteId = this.pacienteDocID;
      var paciente = this.pacientes.find((item) => item.value == pacienteId);
      var pacienteName = paciente.text;

      alert("Gerando PDF");
      // links
      // https://mrrio.github.io/jsPDF/examples/basic.html
      // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
      // https://simonbengtsson.github.io/jsPDF-AutoTable/
      // https://www.npmjs.com/package/jspdf-autotable
      if (exame.autorAssinatura !== undefined && exame.autorAssinatura !== "") {
        this.toDataURL(exame.autorAssinatura, (data) => {
          this.preloadSignature.src = data;
        });
        this.autorData.nome = exame.autorNome;
        this.autorData.crn = exame.autorCrn;
      } else {
        if (
          this.pacienteDados.nutri ==
          "umarizalbelem@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_danielle.png");
          this.autorData.nome = "Danielle Zahlouth";
          this.autorData.crn = "11517";
        } else if (
          this.pacienteDados.nutri == "bc@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_pietra.png");
          this.autorData.nome = "Pietra Chiella";
          this.autorData.crn = "SC 8570";
        } else if (
          this.pacienteDados.nutri ==
          "fortaleza.salinas@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
          this.autorData.nome = "Thayana Albuquerque Kirchhoff";
          this.autorData.crn = "4571";
        } else {
          this.preloadSignature.src = require("@/assets/signature_none.jpg");
        }
      }

      var doc = new jspdf("p");

      // Doc settings
      var fontSize = 10;
      doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
      doc.addFont(
        "Montserrat-Medium-normal.ttf",
        "Montserrat-Medium",
        "normal"
      );
      doc.setFontSize(fontSize);
      doc.setFont("Montserrat-Medium");

      // The (900 / 262) division is used to keep the proportion of the original image
      // New Width * (900 / 262) will keep the height proportion

      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function(
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc
      //         .setFont("Montserrat-Medium")
      //         .splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(
        textList,
        centered = false,
        textStart = textStartYPos
      ) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page

        for (var i = 0; i < textList.length; i++) {
          var lines = doc
            .setFont("Montserrat-Medium")
            .splitTextToSize(textList[i], 180);

          for (var j = 0; j < lines.length; j++) {
            // Check if the current line would go past the threshold
            if (textStart > pageHeightThreshold) {
              doc.addPage();
              textStart = newYStart; // Reset the text start position for the new page
            }

            // Whether to center the text or not
            if (centered) {
              centeredText(lines[j], textStart);
            } else {
              doc.text(startXPos, textStart, lines[j]);
            }

            textStart += fontSize / 2; // Increment the y position for each line of text
          }

          // Increment after each text block rather than each line
          if (!centered) {
            textStart += fontSize / 2; // This accounts for the space after each block of text
          }
        }

        return textStart; // Return the updated text start position
      };

      // // Logo Clubedonutricionista
      //   if (this.$store.state.firestore.pacienteNutriUseLogo === false) {
      // console.log("op 1");
      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });
      //   } else {
      // LOGO CLUBE
      // imgWidth = 30;
      // imgHeight = imgWidth / (900 / 262);
      // startXPos = 15;

      // doc.addImage({
      //   imageData: require("@/assets/logoCompleto.png"),
      //   y: startXPos,
      //   x: 15,
      //   w: imgWidth,
      //   h: imgHeight,
      // });

      // imgWidth = 50;
      // var startYPos = 15 + imgHeight * 2;
      // imgHeight =
      //   imgWidth /
      //   (this.preloadLogo.naturalWidth /
      //     this.preloadLogo.naturalHeight);
      // imgPos = (210 - imgWidth) / 2;
      // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

      // doc.addImage({
      //   imageData: this.preloadLogo,
      //   y: startYPos,
      //   x: imgPos,
      //   w: imgWidth,
      //   h: imgHeight,
      // });
      //   }

      var texts = [
        `"${exame.nome}" (${exame.dateStr})`,
        `Para: ${pacienteName}`,
      ];

      var endTablePosY = createTexts(texts, false, textStartYPos + 6);

      // endTablePosY = createTexts([exame.examText], false, endTablePosY);

      // endTablePosY = createTexts([exame.observacoes], false, endTablePosY);

      var body = [[exame.examText], ["Observações: " + exame.observacoes]];

      doc.autoTable({
        startY: endTablePosY, //lastPos // (lastPos / 2) * 0.7 + startPosY,
        startx: startXPos + 24,
        head: [["Solicito a realização dos exames abaixo:"]],
        body: body,
      });
      endTablePosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;

      doc.addImage({
        imageData: this.preloadSignature,
        y: endTablePosY,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          // `Nutricionista ${this.$store.state.firestore.pacienteNutriName}`,
          // `CRN: ${this.$store.state.firestore.pacienteCrn}`,
          `Nutricionista ${this.autorData.nome}`,
          `CRN: ${this.autorData.crn}`,
          `Data: ${exame.dateStr}`,
          "",
          `${this.userInfo.addressRoad} - ${this.userInfo.addressComplement}`,
          `${this.userInfo.addressCep} ${this.userInfo.addressCity} - ${this.userInfo.addressBairro}`,
          `tel.: ${this.userInfo.phoneClients}`,
        ],
        true,
        // endTablePosY !== undefined
        //   ? endTablePosY +
        //       prescricaoC.formulasList.length * 48 +
        //       16 +
        //       imgHeight * 1.4
        //   :
        endTablePosY // + texts.length * (fontSize * (3 / 4))
      );

      doc.save("prescricao.pdf");
    },
    orientacaoDownloadPdf: async function(orientacao) {
      var pacienteId = this.pacienteDocID;
      var paciente = this.pacientes.find((item) => item.value == pacienteId);
      var pacienteName = paciente.text;

      alert("Gerando PDF");
      // links
      // https://mrrio.github.io/jsPDF/examples/basic.html
      // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
      // https://simonbengtsson.github.io/jsPDF-AutoTable/
      // https://www.npmjs.com/package/jspdf-autotable
      if (
        orientacao.autorAssinatura !== undefined &&
        orientacao.autorAssinatura !== ""
      ) {
        this.toDataURL(orientacao.autorAssinatura, (data) => {
          this.preloadSignature.src = data;
        });
        this.autorData.nome = orientacao.autorNome;
        this.autorData.crn = orientacao.autorCrn;
      } else {
        if (
          this.pacienteDados.nutri ==
          "umarizalbelem@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_danielle.png");
          this.autorData.nome = "Danielle Zahlouth";
          this.autorData.crn = "11517";
        } else if (
          this.pacienteDados.nutri == "bc@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_pietra.png");
          this.autorData.nome = "Pietra Chiella";
          this.autorData.crn = "SC 8570";
        } else if (
          this.pacienteDados.nutri ==
          "fortaleza.salinas@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
          this.autorData.nome = "Thayana Albuquerque Kirchhoff";
          this.autorData.crn = "4571";
        } else {
          this.preloadSignature.src = require("@/assets/signature_none.jpg");
        }
      }

      var doc = new jspdf("p");

      // Doc settings
      var fontSize = 10;
      doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
      doc.addFont(
        "Montserrat-Medium-normal.ttf",
        "Montserrat-Medium",
        "normal"
      );
      doc.setFontSize(fontSize);
      doc.setFont("Montserrat-Medium");

      // The (900 / 262) division is used to keep the proportion of the original image
      // New Width * (900 / 262) will keep the height proportion

      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function(
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc
      //         .setFont("Montserrat-Medium")
      //         .splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(
        textList,
        centered = false,
        textStart = textStartYPos
      ) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page

        for (var i = 0; i < textList.length; i++) {
          var lines = doc
            .setFont("Montserrat-Medium")
            .splitTextToSize(textList[i], 180);

          for (var j = 0; j < lines.length; j++) {
            // Check if the current line would go past the threshold
            if (textStart > pageHeightThreshold) {
              doc.addPage();
              textStart = newYStart; // Reset the text start position for the new page
            }

            // Whether to center the text or not
            if (centered) {
              centeredText(lines[j], textStart);
            } else {
              doc.text(startXPos, textStart, lines[j]);
            }

            textStart += fontSize / 2; // Increment the y position for each line of text
          }

          // Increment after each text block rather than each line
          if (!centered) {
            textStart += fontSize / 2; // This accounts for the space after each block of text
          }
        }

        return textStart; // Return the updated text start position
      };

      // // Logo Clubedonutricionista
      //   if (this.$store.state.firestore.pacienteNutriUseLogo === false) {
      // console.log("op 1");
      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });
      //   } else {
      // LOGO CLUBE
      // imgWidth = 30;
      // imgHeight = imgWidth / (900 / 262);
      // startXPos = 15;

      // doc.addImage({
      //   imageData: require("@/assets/logoCompleto.png"),
      //   y: startXPos,
      //   x: 15,
      //   w: imgWidth,
      //   h: imgHeight,
      // });

      // imgWidth = 50;
      // var startYPos = 15 + imgHeight * 2;
      // imgHeight =
      //   imgWidth /
      //   (this.preloadLogo.naturalWidth /
      //     this.preloadLogo.naturalHeight);
      // imgPos = (210 - imgWidth) / 2;
      // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

      // doc.addImage({
      //   imageData: this.preloadLogo,
      //   y: startYPos,
      //   x: imgPos,
      //   w: imgWidth,
      //   h: imgHeight,
      // });
      //   }

      var texts = [
        `"${orientacao.nome}" (${orientacao.dateStr})`,
        `Para: ${pacienteName}`,
      ];

      var endTablePosY = createTexts(texts, false, textStartYPos + 6);

      // endTablePosY = createTexts([exame.examText], false, endTablePosY);

      // endTablePosY = createTexts([exame.observacoes], false, endTablePosY);

      var body = [
        [orientacao.orientacaoText],
        ["Observações: " + orientacao.observacoes],
      ];

      doc.autoTable({
        startY: endTablePosY, //lastPos // (lastPos / 2) * 0.7 + startPosY,
        startx: startXPos + 24,
        head: [["Orientações:"]],
        body: body,
      });
      endTablePosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;

      doc.addImage({
        imageData: this.preloadSignature,
        y: endTablePosY,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          // `Nutricionista ${this.$store.state.firestore.pacienteNutriName}`,
          // `CRN: ${this.$store.state.firestore.pacienteCrn}`,
          `Nutricionista ${this.autorData.nome}`,
          `CRN: ${this.autorData.crn}`,
          `Data: ${orientacao.dateStr}`,
          "",
          `${this.userInfo.addressRoad} - ${this.userInfo.addressComplement}`,
          `${this.userInfo.addressCep} ${this.userInfo.addressCity} - ${this.userInfo.addressBairro}`,
          `tel.: ${this.userInfo.phoneClients}`,
        ],
        true,
        // endTablePosY !== undefined
        //   ? endTablePosY +
        //       prescricaoC.formulasList.length * 48 +
        //       16 +
        //       imgHeight * 1.4
        //   :
        endTablePosY // + texts.length * (fontSize * (3 / 4))
      );

      doc.save("orientacao.pdf");
    },
    formulaDownloadPdf: async function(formula) {
      alert("Gerando PDF");
      // links
      // https://mrrio.github.io/jsPDF/examples/basic.html
      // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
      // https://simonbengtsson.github.io/jsPDF-AutoTable/
      // https://www.npmjs.com/package/jspdf-autotable
      if (
        formula.autorAssinatura !== undefined &&
        formula.autorAssinatura !== ""
      ) {
        this.toDataURL(formula.autorAssinatura, (data) => {
          this.preloadSignature.src = data;
        });
        this.autorData.nome = formula.autorNome;
        this.autorData.crn = formula.autorCrn;
        console.log(this.preloadSignature.src);
      } else {
        if (
          this.pacienteDados.nutri ==
          "umarizalbelem@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_danielle.png");
          this.autorData.nome = "Danielle Zahlouth";
          this.autorData.crn = "11517";
        } else if (
          this.pacienteDados.nutri == "bc@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_pietra.png");
          this.autorData.nome = "Pietra Chiella";
          this.autorData.crn = "SC 8570";
        } else if (
          this.pacienteDados.nutri ==
          "fortaleza.salinas@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
          this.autorData.nome = "Thayana Albuquerque Kirchhoff";
          this.autorData.crn = "4571";
        } else {
          this.preloadSignature.src = require("@/assets/signature_none.jpg");
        }
      }

      var doc = new jspdf("p");

      // Doc settings
      var fontSize = 10;
      doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
      doc.addFont(
        "Montserrat-Medium-normal.ttf",
        "Montserrat-Medium",
        "normal"
      );
      doc.setFontSize(fontSize);
      doc.setFont("Montserrat-Medium");

      // The (900 / 262) division is used to keep the proportion of the original image
      // New Width * (900 / 262) will keep the height proportion

      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function(
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc
      //         .setFont("Montserrat-Medium")
      //         .splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };

      var createTexts = function(
        textList,
        centered = false,
        textStart = textStartYPos
      ) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page

        for (var i = 0; i < textList.length; i++) {
          var lines = doc
            .setFont("Montserrat-Medium")
            .splitTextToSize(textList[i], 180);

          for (var j = 0; j < lines.length; j++) {
            // Check if the current line would go past the threshold
            if (textStart > pageHeightThreshold) {
              doc.addPage();
              textStart = newYStart; // Reset the text start position for the new page
            }

            // Whether to center the text or not
            if (centered) {
              centeredText(lines[j], textStart);
            } else {
              doc.text(startXPos, textStart, lines[j]);
            }

            textStart += fontSize / 2; // Increment the y position for each line of text
          }

          // Increment after each text block rather than each line
          if (!centered) {
            textStart += fontSize / 2; // This accounts for the space after each block of text
          }
        }

        return textStart; // Return the updated text start position
      };

      // // Logo Clubedonutricionista
      //   if (this.$store.state.firestore.pacienteNutriUseLogo === false) {
      // console.log("op 1");
      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });
      //   } else {
      // LOGO CLUBE
      // imgWidth = 30;
      // imgHeight = imgWidth / (900 / 262);
      // startXPos = 15;

      // doc.addImage({
      //   imageData: require("@/assets/logoCompleto.png"),
      //   y: startXPos,
      //   x: 15,
      //   w: imgWidth,
      //   h: imgHeight,
      // });

      // imgWidth = 50;
      // var startYPos = 15 + imgHeight * 2;
      // imgHeight =
      //   imgWidth /
      //   (this.preloadLogo.naturalWidth /
      //     this.preloadLogo.naturalHeight);
      // imgPos = (210 - imgWidth) / 2;
      // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

      // doc.addImage({
      //   imageData: this.preloadLogo,
      //   y: startYPos,
      //   x: imgPos,
      //   w: imgWidth,
      //   h: imgHeight,
      // });
      //   }

      var texts = [
        `"${formula.nome}" (${formula.dateStr})`,
        `Para: ${formula.pacienteName}`,
      ];

      var endTablePosY = createTexts(texts, false, textStartYPos + 6);

      for (var i = 0; i < formula.prescricaoC.formulasList.length; i++) {
        endTablePosY = createTexts(
          [
            formula.prescricaoC.formulasList[i].titulo,
            formula.prescricaoC.formulasList[i].prescricoes,
          ],
          false,
          endTablePosY + i * 48
        );
      }

      // var body = formula.prescricaoC.formulasList.map(function (formulaP) {
      //   if (formulaP.prescricoes != undefined) {
      //     return [formulaP.titulo, formulaP.prescricoes];
      //   } else {
      //     return [];
      //   }
      // });

      // doc.autoTable({
      //   startY: endTablePosY, //lastPos // (lastPos / 2) * 0.7 + startPosY,
      //   startx: startXPos,
      //   head: [["Nome da fórmula", "fórmula"]],
      //   body: body,
      // });
      // endTablePosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);

      // USE SIGNATURE
      //   if (this.$store.state.firestore.pacienteNutriSign != "") {
      // imgWidth = 30;
      // startYPos =
      //   fontSize +
      //   (endTablePosY !== undefined
      //     ? endTablePosY + 16
      //     : textStartYPos + texts.length * (fontSize * (3 / 4)));
      // imgHeight =
      //   imgWidth /
      //   (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      // imgPos = (210 - imgWidth) / 2;
      // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

      // doc.addImage({
      // //   imageData: this.preloadSignature,
      //   imageData: require("@/assets/signature_none.jpg"),
      //   y: startYPos,
      //   x: imgPos,
      //   w: imgWidth,
      //   h: imgHeight,
      // });
      //   } else {
      //     imgHeight = 0;
      //   }

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;

      doc.addImage({
        imageData: this.preloadSignature,
        y: endTablePosY,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          // `Nutricionista ${this.$store.state.firestore.pacienteNutriName}`,
          // `CRN: ${this.$store.state.firestore.pacienteCrn}`,
          `Nutricionista ${this.autorData.nome}`,
          `CRN: ${this.autorData.crn}`,
          `Data: ${formula.dateStr}`,
          "",
          `${this.userInfo.addressRoad} - ${this.userInfo.addressComplement}`,
          `${this.userInfo.addressCep} ${this.userInfo.addressCity} - ${this.userInfo.addressBairro}`,
          `tel.: ${this.userInfo.phoneClients}`,
        ],
        true,
        // endTablePosY !== undefined
        //   ? endTablePosY +
        //       prescricaoC.formulasList.length * 48 +
        //       16 +
        //       imgHeight * 1.4
        //   :
        endTablePosY + 8 // + texts.length * (fontSize * (3 / 4))
      );

      doc.save("prescricao.pdf");
    },
  },
  //   firestore() {
  //     return {
  //       documentsList: firebase
  //         .firestore()
  //         .collection("users")
  //         .doc(sessionStorage.getItem("current-user"))
  //         // .doc(firebase.auth().currentUser.email)
  //         .collection("pacientes"),
  //     };
  //   },
  mounted() {
    // if (this.$store.state.firestore.pacientes.length === 0) {
    this.$store.dispatch("firestore/getPacientes");
    // }

    setTimeout(() => {
      if (this.persistPaciente !== "") {
        this.pacienteDocID = this.persistPaciente;
        this.getPacienteDados();
      }
    }, 1000);
    this.preloadImages();
  },
};
</script>

<style scoped></style>
