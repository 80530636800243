<template>
  <div>
    <v-dialog v-model="dialogVoucher" max-width="600">
      <v-card>
        <div v-if="dialogVoucherNenhum">
          <v-card-title class="d-flex justify-center">
            Nenhum voucher de desconto inserido
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            <v-icon large color="red">mdi-alert-circle-outline</v-icon>
          </v-card-text>
        </div>
        <div v-else-if="dialogVoucherNotFound">
          <v-card-title class="d-flex justify-center">
            Voucher de desconto não encontrado
          </v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-icon large color="red">mdi-folder-search</v-icon>
            </v-row>
            <v-row class="d-flex justify-center">
              Verifique se escreveu certo o código!
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAv">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Voucher: {{ chosenAv.id }}, {{ chosenAv.dateStr }}
        </v-card-title>
        <v-card-text>
          <v-card outlined>
            <v-card-title justify-center text-subtitle-1
              >Dados pessoais</v-card-title
            >
            <v-card-text>
              <v-row class="d-flex justify-center align-center">
                <v-col cols="12" md="5">
                  <v-text-field
                    :prepend-icon="icons.mdiAccount"
                    v-model="chosenAv.nome"
                    label="Nome"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-radio-group v-model="chosenAv.sexo" column readonly>
                    <v-radio
                      label="feminino"
                      value="feminino"
                      color="#ff00ff"
                    ></v-radio>
                    <v-radio
                      label="masculino"
                      value="masculino"
                      color="#0080ff"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6" md="2">
                  <v-checkbox
                    readonly
                    v-model="chosenAv.gestante"
                    label="gestante"
                    color="#c5d13f"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    readonly
                    v-model="chosenAv.dataNascimento"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="4">
                  <v-text-field
                    :prepend-icon="icons.mdiEmail"
                    v-model="chosenAv.email"
                    label="E-mail"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    readonly
                    :prepend-icon="icons.mdiPhone"
                    v-model="chosenAv.telefone"
                    label="Telefone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    readonly
                    :prepend-icon="icons.mdiWhatsapp"
                    v-model="chosenAv.whatsapp"
                    label="WhatsApp"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card outlined style="background-color: #e0e0e0">
            <v-card-title> IMC </v-card-title>
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="4" class="d-flex align-center">
                  <v-row>
                    <v-col cols="6">
                      <small
                        >Peso em kg. Por exemplo: "80", sem vírgula ou
                        ponto</small
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :prepend-icon="icons.mdiWeightKilogram"
                        type="number"
                        v-model="chosenAv.pesoAtual"
                        label="peso atual (kg)?"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-center">
                  <v-row>
                    <v-col cols="6">
                      <small
                        >Altura em cm. Por exemplo: "177", sem vírgula ou
                        ponto</small
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :prepend-icon="icons.mdiHumanMaleHeightVariant"
                        v-model="chosenAv.altura"
                        type="number"
                        label="altura (cm)"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="d-flex justify-center align-center text-h6"
                >
                  <v-row>
                    <v-col cols="6" class="d-flex justify-end">
                      IMC: {{ chosenAv.bmi }}
                    </v-col>
                    <v-col cols="6" class="d-flex justify-start">
                      <v-icon :color="bmiColor(chosenAv.bmi)"
                        >mdi-alert-circle</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- <v-card outlinded style="background-color: #ffffff"> -->
          <v-expansion-panels class="mb-4" style="background-color: #ffffff">
            <v-expansion-panel v-model="panel0">
              <v-expansion-panel-header>
                <v-card-title> Questionário de saúde </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center">
                    <v-icon color="grey" left class="mx-4">mdi-liquor</v-icon>
                    Faz uso de bebida alcóolica (cervejas, destilados, outros)?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.bebAlc"
                      column
                      class="mx-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#008000"
                      ></v-radio>
                    </v-radio-group>
                    <v-divider
                      vertical
                      v-if="chosenAv.questSaude.bebAlc == 'Sim'"
                    ></v-divider>
                    <div v-if="chosenAv.questSaude.bebAlc == 'Sim'">
                      <v-row class="d-flex justify-center align-center ml-8">
                        Se bebe, com quê frequência?
                        <v-radio-group
                          readonly
                          v-model="chosenAv.questSaude.bebAlcFreq"
                          column
                          class="mx-4"
                        >
                          <v-radio
                            label="Reuniões sociais"
                            value="Reuniões sociais"
                            color="#ffff1f"
                          ></v-radio>
                          <v-radio
                            label="Finais de semana"
                            value="Finais de semana"
                            color="#ff8000"
                          ></v-radio>
                          <v-radio
                            label="Diariamente"
                            value="Diariamente"
                            color="#660000"
                          ></v-radio>
                        </v-radio-group>
                      </v-row>
                    </div>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center">
                    <v-icon class="mx-4" color="grey" left>mdi-smoking</v-icon>
                    Fuma?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.fuma"
                      column
                      class="mx-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#008000"
                      ></v-radio>
                    </v-radio-group>
                    <v-divider
                      vertical
                      v-if="chosenAv.questSaude.fuma == 'Sim'"
                    ></v-divider>
                    <div v-if="chosenAv.questSaude.fuma == 'Sim'">
                      <v-row class="d-flex justify-center align-center ml-8">
                        Se fumante, tem vontade de parar de fumar?
                        <v-radio-group
                          readonly
                          v-model="chosenAv.questSaude.fumaParar"
                          column
                          class="mx-4"
                        >
                          <v-radio
                            label="Sim"
                            value="Sim"
                            color="#FFFF1F"
                          ></v-radio>
                          <v-radio
                            label="Não"
                            value="Não"
                            color="#660000"
                          ></v-radio>
                        </v-radio-group>
                      </v-row>
                    </div>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center">
                    <v-icon class="mx-4" color="grey" left
                      >mdi-weight-lifter</v-icon
                    >
                    Pratica alguma atividade física (Exceto às atividades
                    relacionadas à rotina diária de trabalho)?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.atiFis"
                      column
                      class="mx-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#008000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#ff0000"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <div v-if="chosenAv.questSaude.atiFis == 'Sim'">
                    <v-row class="d-flex justify-center align-center">
                      Se você pratica atividade física, com que frequência?
                      <v-radio-group
                        readonly
                        v-model="chosenAv.questSaude.atiFisFreq"
                        column
                        class="mx-4"
                      >
                        <v-radio
                          label="1 vez por semana ou menos"
                          value="1 vez por semana ou menos"
                          color="#ff8000"
                        ></v-radio>
                        <v-radio
                          label="2 vezes por semana"
                          value="2 vezes por semana"
                          color="#ffff1f"
                        ></v-radio>
                        <v-radio
                          label="3 ou mais vezes por semana"
                          value="3 ou mais vezes por semana"
                          color="#008000"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>
                  </div>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center">
                    <v-icon class="mx-4" color="grey" left
                      >mdi-blood-bag</v-icon
                    >
                    Tem pressão alta?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.press"
                      column
                      class="ml-4 mr-8"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#008000"
                      ></v-radio>
                      <v-radio
                        label="Não sei"
                        value="Não sei"
                        color="#660000"
                      ></v-radio>
                    </v-radio-group>
                    Faz controle de pressão?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.pressCtrl"
                      column
                      class="ml-4 mr-8"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#008000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#660000"
                      ></v-radio>
                    </v-radio-group>
                    <div v-if="chosenAv.questSaude.press == 'Sim'">
                      <v-row class="d-flex justify-center align-center ml-8">
                        Usa medicação para controle?
                        <v-radio-group
                          readonly
                          v-if="
                            chosenAv.questSaude.press == null ||
                            chosenAv.questSaude.press == 'Não'
                          "
                          v-model="chosenAv.questSaude.pressMed"
                          column
                          class="ml-4"
                        >
                          <v-radio
                            label="Sim"
                            value="Sim"
                            color="#ffff1f"
                          ></v-radio>
                          <v-radio
                            label="Não"
                            value="Não"
                            color="#008000"
                          ></v-radio>
                        </v-radio-group>
                        <v-radio-group
                          readonly
                          v-else
                          v-model="chosenAv.questSaude.pressMed"
                          column
                          class="ml-4"
                        >
                          <v-radio
                            label="Sim"
                            value="Sim"
                            color="#ffff1f"
                          ></v-radio>
                          <v-radio
                            label="Não"
                            value="Não"
                            color="#660000"
                          ></v-radio>
                        </v-radio-group>
                      </v-row>
                    </div>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center">
                    <v-icon class="mx-4" color="grey" left
                      >mdi-heart-flash</v-icon
                    >
                    Já sofreu de algum problema no coração como infarto, angina
                    ou doença das coronárias?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.coracao"
                      column
                      class="ml-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#008000"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center mt-4">
                    <v-icon class="mx-4" color="grey" left
                      >mdi-water-alert</v-icon
                    >
                    Sua taxa de colesterol e/ou triglicérides é alta?
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.trigl"
                      row
                      class="ml-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#008000"
                      ></v-radio>
                      <v-radio
                        label="No limite"
                        value="No limite"
                        color="#ffff1f"
                      ></v-radio>
                      <v-radio
                        label="Não sei"
                        value="Não sei"
                        color="#ffff1f"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center">
                    <v-icon class="mx-4" color="grey" left
                      >mdi-spoon-sugar</v-icon
                    >
                    É diabético?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.diabet"
                      column
                      class="ml-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#008000"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center mt-4">
                    <v-icon class="mx-4" color="grey" left>mdi-doctor</v-icon>
                    Você tem/teve alguma destas doenças, diagnosticada por um
                    médico?
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    Doença respiratória (DPOC, Asma, Bronquite):
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.diagResp"
                      column
                      class="ml-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#008000"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <div class="d-flex align-center">
                      Depressão:
                      <v-radio-group
                        readonly
                        v-model="chosenAv.questSaude.diagDepr"
                        column
                        class="ml-4"
                      >
                        <v-radio
                          label="Sim"
                          value="Sim"
                          color="#ff0000"
                        ></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="#008000"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div class="d-flex align-center ml-8">
                      Câncer:
                      <v-radio-group
                        readonly
                        v-model="chosenAv.questSaude.diagCanc"
                        column
                        class="ml-4"
                      >
                        <v-radio
                          label="Sim"
                          value="Sim"
                          color="#ff0000"
                        ></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="#008000"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center">
                    <v-icon class="mx-4" color="grey" left
                      >mdi-emoticon-happy-outline</v-icon
                    >
                    Está satisfeito com sua saúde?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.satisSaude"
                      column
                      class="ml-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#008000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#ff0000"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <v-icon class="mx-4" color="grey" left>mdi-leaf</v-icon>
                    Está satisfeito com sua qualidade de vida?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questSaude.satisVida"
                      column
                      class="ml-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#008000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#ff0000"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- </v-card> -->
            <!-- <v-card outlinded style="background-color: #e0e0e0"> -->
            <v-expansion-panel
              v-model="panel1"
              style="background-color: #e0e0e0"
            >
              <v-expansion-panel-header>
                <v-card-title>
                  Questionário de comportamento alimentar
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center mt-4">
                    <v-icon color="grey" left class="mx-4"
                      >mdi-account-eye</v-icon
                    >
                    Autoconsciência
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <v-radio-group
                      v-model="chosenAv.questCompo.one"
                      column
                      readonly
                    >
                      <v-radio
                        value="5"
                        color="#008000"
                        label="Eu não me sinto consciente do meu peso ou tamanho corporal quando estou com outros."
                      ></v-radio>
                      <v-radio
                        value="3"
                        color="#ffff1f"
                        label="Sinto-me preocupado com o meu aspecto para os outros, mas normalmente não me faz sentir desapontado comigo mesmo."
                      ></v-radio>
                      <v-radio
                        value="2"
                        color="#ff0000"
                        label="Fico consciente da minha aparência e do meu peso, o que me faz sentir desapontado comigo mesmo."
                      ></v-radio>
                      <v-radio
                        value="1"
                        color="#660000"
                        label="Sinto vergonha e repugnância intensa por mim mesmo. Tento evitar contatos sociais por causa da minha autoconsciência."
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center mt-4">
                    <v-icon color="grey" left class="mx-4"
                      >mdi-account-clock</v-icon
                    >
                    Velocidade
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <v-radio-group
                      v-model="chosenAv.questCompo.two"
                      readonly
                      column
                    >
                      <v-radio
                        value="5"
                        color="#008000"
                        label="Não tenho qualquer dificuldade em comer lentamente da maneira correcta."
                      ></v-radio>
                      <v-radio
                        value="3"
                        color="#ffff1f"
                        label='Embora eu pareça "devorar" alimentos, não acabo me sentindo recheado por causa de comer demais.'
                      ></v-radio>
                      <v-radio
                        value="2"
                        color="#ff0000"
                        label="Às vezes, tendo a comer rapidamente e, depois, sinto-me desconfortavelmente cheio."
                      ></v-radio>
                      <v-radio
                        value="1"
                        color="#660000"
                        label="Eu tenho o hábito de comer sem mastigar e normalmente sinto-me desconfortavelmente recheado porque já comi demais."
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center mt-4">
                    <v-icon color="grey" left class="mx-4"
                      >mdi-emoticon-angry</v-icon
                    >
                    Aborrecimento
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <v-radio-group
                      v-model="chosenAv.questCompo.tre"
                      readonly
                      column
                    >
                      <v-radio
                        value="5"
                        color="#008000"
                        label="Eu não tenho o hábito de comer quando estou aborrecido."
                      ></v-radio>
                      <v-radio
                        value="3"
                        color="#ffff1f"
                        label='Às vezes como quando estou entediado, mas muitas vezes consigo "ficar ocupado" e tirar a minha mente da comida.'
                      ></v-radio>
                      <v-radio
                        value="2"
                        color="#ff0000"
                        label="Tenho o hábito de comer quando estou entediado, mas ocasionalmente, posso me distrair de comer."
                      ></v-radio>
                      <v-radio
                        value="1"
                        color="#660000"
                        label="Tenho um forte hábito de comer quando estou aborrecido. Nada parece ajudar-me a quebrar o hábito."
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center mt-4">
                    <v-icon color="grey" left class="mx-4"
                      >mdi-account-cancel</v-icon
                    >
                    Auto-ódio
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <v-radio-group
                      v-model="chosenAv.questCompo.for"
                      readonly
                      column
                    >
                      <v-radio
                        value="5"
                        color="#008000"
                        label="Não sinto qualquer culpa ou ódio por mim próprio depois de comer demais."
                      ></v-radio>
                      <v-radio
                        value="2"
                        color="#ffff1f"
                        label="Depois de comer demais, às vezes sinto culpa ou auto-ódio."
                      ></v-radio>
                      <v-radio
                        value="1"
                        color="#660000"
                        label="Quase sempre sinto uma forte culpa ou auto-ódio depois de ter comido demais."
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center mt-4">
                    <v-icon color="grey" left class="mx-4"
                      >mdi-sine-wave</v-icon
                    >
                    Irregularidades
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <v-radio-group
                      v-model="chosenAv.questCompo.fiv"
                      readonly
                      column
                    >
                      <v-radio
                        value="5"
                        color="#008000"
                        label="O meu nível de consumo calórico não sobe muito alto ou desce muito baixo numa base regular."
                      ></v-radio>
                      <v-radio
                        value="3"
                        color="#ffff1f"
                        label="As vezes, depois de comer em excesso, vou tentar reduzir a minha ingestão calórica a quase nada para compensar."
                      ></v-radio>
                      <v-radio
                        value="2"
                        color="#ff0000"
                        label="Eu tenho o hábito regular de comer em excesso durante a noite. Parece que a minha rotina não é ter fome de manhã, mas comer em excesso à noite."
                      ></v-radio>
                      <v-radio
                        value="1"
                        color="#660000"
                        label='Tive períodos em que praticamente morri de fome e períodos em que comi em excesso. Parece que vivo uma vida de "festa ou de fome".'
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center mt-4">
                    <v-icon color="grey" left class="mx-4"
                      >mdi-account-tie-hat</v-icon
                    >
                    Vigiliança
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <v-radio-group
                      v-model="chosenAv.questCompo.six"
                      readonly
                      column
                    >
                      <v-radio
                        value="5"
                        color="#008000"
                        label="Não penso muito em tentar controlar os impulsos alimentares indesejados."
                      ></v-radio>
                      <v-radio
                        value="3"
                        color="#ffff1f"
                        label="Pelo menos em parte do tempo, sinto que os meus pensamentos estão preocupados em tentar controlar os meus impulsos alimentares."
                      ></v-radio>
                      <v-radio
                        value="2"
                        color="#ff0000"
                        label="Sinto que frequentemente passo muito tempo a pensar no quanto comi ou em tentar não comer mais."
                      ></v-radio>
                      <v-radio
                        value="1"
                        color="#660000"
                        label="Parece-me que a maior parte das minhas horas de vigília são pré- ocupadas por pensamentos sobre comer ou não comer. "
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center mt-4">
                    <v-icon color="grey" left class="mx-4"
                      >mdi-account-search</v-icon
                    >
                    Autopercepção
                  </v-row>
                  <v-row class="d-flex justify-center align-center">
                    <v-radio-group
                      v-model="chosenAv.questCompo.sev"
                      readonly
                      column
                    >
                      <v-radio
                        value="5"
                        color="#008000"
                        label="Eu normalmente sei se tenho fome ou não. Como a porção certa de comida para me satisfazer."
                      ></v-radio>
                      <v-radio
                        value="2"
                        color="#ffff1f"
                        label="Ocasionalmente, sinto-me insegura se tenho fome ou não. Acho difícil saber quanta comida devo tomar para me satisfazer."
                      ></v-radio>
                      <v-radio
                        value="1"
                        color="#660000"
                        label='Embora eu possa saber quantas calorias devo comer, não faço ideia do que é uma quantidade "normal" de comida para mim.'
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- </v-card>
        <v-card outlinded style="background-color: #ffffff"> -->
            <v-expansion-panel v-model="panel2">
              <v-expansion-panel-header>
                <v-card-title>
                  Questionário de satisfação estética
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center">
                    Sente-se inchada?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questEstet.enx"
                      column
                      class="ml-4"
                    >
                      <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                      <v-radio label="Não" value="1" color="#008000"></v-radio>
                    </v-radio-group>
                    <v-divider vertical class="mx-8"></v-divider>
                    Você tem dificuldade de fechar roupas que antigamente
                    fecharam ?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questEstet.fec"
                      column
                      class="ml-4"
                    >
                      <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                      <v-radio label="Não" value="1" color="#008000"></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-divider></v-divider>

                  <v-row class="d-flex justify-center align-center">
                    Você tem vergohna de usar roupas de praia?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questEstet.rou"
                      column
                      class="mx-4"
                    >
                      <v-radio
                        label="Sim, não me sinto comfortável em roupas de praia"
                        value="0"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não, estou feliz com a minha forma atual"
                        value="1"
                        color="#008000"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="d-flex justify-center align-center">
                    Você tem flacidez de pele em alguma área do corpo?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questEstet.fla"
                      column
                      class="mx-4"
                    >
                      <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                      <v-radio label="Não" value="1" color="#008000"></v-radio>
                    </v-radio-group>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="d-flex justify-center align-center">
                    Você tem um acúmulo de gordura localizada em alguma área do
                    corpo?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questEstet.gor"
                      column
                      class="mx-4"
                    >
                      <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                      <v-radio label="Não" value="1" color="#008000"></v-radio>
                    </v-radio-group>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="d-flex justify-center align-center">
                    Você tem celulite em alguma área do corpo?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questEstet.cel"
                      column
                      class="mx-4"
                    >
                      <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                      <v-radio label="Não" value="1" color="#008000"></v-radio>
                    </v-radio-group>
                  </v-row>

                  <v-divider></v-divider>
                  <v-row class="d-flex justify-center align-center">
                    Você precisa de definição corporal?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questEstet.def"
                      column
                      class="ml-4"
                    >
                      <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                      <v-radio label="Não" value="1" color="#008000"></v-radio>
                    </v-radio-group>
                    <v-divider vertical class="mx-8"></v-divider>
                    Você precisa melhorar o tônus muscular?
                    <v-radio-group
                      readonly
                      v-model="chosenAv.questEstet.ton"
                      column
                      class="ml-4"
                    >
                      <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                      <v-radio label="Não" value="1" color="#008000"></v-radio>
                    </v-radio-group>
                  </v-row>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- </v-card> -->
          <v-card outlinded style="background-color: #e0e0e0">
            <v-card-title> Avaliação </v-card-title>
            <v-card-text>
              <v-row class="d-flex justify-space-between align-center">
                <v-col cols="4" class="d-flex justify-end">IMC: </v-col>
                <v-col cols="4" class="d-flex justify-center">
                  <v-progress-linear
                    v-model="chosenAv.bmiPerc"
                    :color="bmiColor(chosenAv.bmi)"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="4" class="d-flex justify-start">{{
                  chosenAv.bmi
                }}</v-col>
              </v-row>
              <v-row class="d-flex justify-space-between align-center">
                <v-col cols="4" class="d-flex justify-end">Saúde: </v-col>
                <v-col cols="4" class="d-flex justify-center">
                  <v-progress-linear
                    v-model="chosenAv.avSaude"
                    :color="colorPerc(chosenAv.avSaude)"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="4" class="d-flex justify-start"
                  >{{ chosenAv.avSaude }}%</v-col
                >
              </v-row>
              <v-row class="d-flex justify-space-between align-center">
                <v-col cols="4" class="d-flex justify-end"
                  >Comportamento alimentar:
                </v-col>
                <v-col cols="4" class="d-flex justify-center">
                  <v-progress-linear
                    v-model="chosenAv.avCompo"
                    :color="colorPerc(chosenAv.avCompo)"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="4" class="d-flex justify-start"
                  >{{ chosenAv.avCompo }}%</v-col
                >
              </v-row>
              <v-row class="d-flex justify-space-between align-center">
                <v-col cols="4" class="d-flex justify-end">Estética: </v-col>
                <v-col cols="4" class="d-flex justify-center">
                  <v-progress-linear
                    v-model="chosenAv.avEstet"
                    :color="colorPerc(chosenAv.avEstet)"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="4" class="d-flex justify-start"
                  >{{ chosenAv.avEstet }}%</v-col
                >
              </v-row>
              <v-row class="d-flex justify-space-between align-center">
                <v-col cols="2" class="d-flex justify-end"></v-col>
                <v-col cols="8" class="d-flex justify-center">
                  <v-textarea solo v-model="chosenAv.avEscrita"></v-textarea>
                </v-col>
                <v-col cols="2"></v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card outlined style="background-color: rgba(197, 209, 63, 0.6)">
            <v-card-title> Sugestão de tratamento </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="0" md="1"></v-col>
                <v-col cols="12" md="10">
                  <v-text-field
                    label="Tipo de tratamento"
                    readonly
                    v-model="chosenAv.mov.tipo"
                    class="mt-5"
                    single-line
                    solo
                    style="width: 100%"
                  ></v-text-field> </v-col
                ><v-col cols="0" md="1"></v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col cols="12" class="d-flex justify-center text-h6">
                  {{ niceString(chosenAv.mov.valor) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="d-flex align-center justify-end">
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label='desconto em R$, ex.: "100"'
                    single-line
                    class="mt-3 mx-3"
                    style="width: 100%"
                    v-model="chosenAv.mov.desconto"
                    type="number"
                  ></v-text-field> </v-col
                ><v-col cols="12" md="3"></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-btn
                    color="#614021"
                    class="white--text"
                    large
                    @click="downloadAv"
                  >
                    <v-icon color="#c5d13f" left>mdi-content-save</v-icon>
                    Baixar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title class="d-flex justify-center"
        >Avaliações gratuitas</v-card-title
      >
      <v-card-text>
        <v-row class="d-flex justify-center align-center" v-if="!voucherFound">
          <v-col cols="0" md="3"></v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="voucherDesconto"></v-text-field>
          </v-col>
          <v-col cols="0" md="2">
            <v-btn
              color="#614021"
              class="white--text"
              small
              @click="getVoucher"
            >
              <v-icon small left color="#c5d13f"
                >mdi-ticket-percent-outline</v-icon
              >
              Procurar Voucher</v-btn
            ></v-col
          >
          <v-col cols="0" md="3"></v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center" v-if="!voucherFound">
          <v-col cols="0" md="3"></v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              label="Nome do paciente"
              :items="avPacs"
              v-model="chosenNome"
              single-line
              style="width: 100%"
              no-data-text="..."
            ></v-autocomplete>
          </v-col>
          <v-col cols="0" md="2">
            <v-btn
              color="#614021"
              class="white--text"
              small
              @click="getVoucherDoc(chosenNome)"
            >
              <v-icon small left color="#c5d13f"
                >mdi-ticket-percent-outline</v-icon
              >
              Procurar avaliação</v-btn
            ></v-col
          >
          <v-col cols="0" md="3"></v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center" v-if="voucherFound">
          <v-icon large left class="mx-4">mdi-check-circle-outline</v-icon>
          Voucher encontrado!
          <v-btn
            v-if="voucherFound"
            color="#614021"
            class="white--text mx-4"
            @click="dialogAv = true"
          >
            <v-icon left color="#c5d13f">mdi-magnify</v-icon>
            Ver detalhes</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  mdiEmail,
  mdiAccount,
  mdiPhone,
  mdiWhatsapp,
  mdiCalendar,
  mdiWeightKilogram,
  mdiHumanMaleHeightVariant,
} from "@mdi/js";
import firebase from "firebase";
import jspdf from "jspdf";
import "jspdf-autotable";
export default {
  data: function () {
    return {
      voucherDesconto: null,
      voucherFound: false,
      chosenNome: null,
      dialogVoucher: false,
      dialogVoucherNenhum: false,
      dialogVoucherNotFound: false,
      dialogAv: false,
      panel0: [0],
      panel1: [0],
      panel2: [0],
      avPacs: [],
      chosenAv: {
        nome: null,
        sexo: true,
        gestante: false,
        dataNascimento: null,
        email: null,
        telefone: null,
        whatsapp: null,
        pesoAtual: null,
        altura: null,
        avEscrita: "...",
        questSaude: {
          bebAlc: null,
          bebAlcFreq: null,
          fuma: null,
          fumaParar: null,
          atiFis: null,
          atiFisFreq: null,
          press: null,
          pressCtrl: null,
          pressMed: null,
          coracao: null,
          trigl: null,
          diabet: null,
          diagResp: null,
          diagCanc: null,
          diagDepr: null,
          satisSaude: null,
          satisVida: null,
        },
        questCompo: {
          one: null,
          two: null,
          tre: null,
          for: null,
          fiv: null,
          six: null,
          sev: null,
        },
        questEstet: {
          enx: null,
          fec: null,
          cel: null,
          gor: null,
          fla: null,
          rou: null,
          def: null,
          ton: null,
        },
        id: null,
        dateStr: null,
        mov: {
          tipo: null,
          descr: null,
          paciente: null,
          comprovante: null,
          valor: null,
          pacotes: [
            {
              def1: null,
              def2: null,
              area1: null,
              area2: null,
              area3: null,
              dataInicio: null,
              millisInicio: null,
              index: 0,
            },
          ],
          soldObjs: [],
          forma: null,
        },
      },
      icons: {
        mdiAccount,
        mdiEmail,
        mdiPhone,
        mdiWhatsapp,
        mdiCalendar,
        mdiWeightKilogram,
        mdiHumanMaleHeightVariant,
      },
      preloadSignature: new Image(),
      preloadLogo: new Image(),
    };
  },
  methods: {
    bmiColor(bmi) {
      if (bmi < 18.5) {
        return "#ff0000";
      } else if (bmi > 18.5 && this.bmi < 25) {
        return "#209c05";
      } else if (bmi > 25 && this.bmi < 30) {
        return "#ffff00";
      } else if (bmi > 30 && this.bmi < 35) {
        return "#ff8000";
      } else if (bmi > 35) {
        return "#ff0000";
      } else {
        return "#c0c0c0";
      }
    },
    downloadAv() {
      var doc = new jspdf("p");
      var fontSize = 12;
      doc.setFontSize(fontSize);
      doc.setFont("Times");
      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function (text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function (
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc.setFont("Roboto").splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(textList, centered = false, textStart = textStartYPos) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page
        
        for (var i = 0; i < textList.length; i++) {
            var lines = doc.setFont("Montserrat-Medium").splitTextToSize(textList[i], 180);
            
            for (var j = 0; j < lines.length; j++) {
                // Check if the current line would go past the threshold
                if (textStart > pageHeightThreshold) {
                    doc.addPage();
                    textStart = newYStart; // Reset the text start position for the new page
                }
                
                // Whether to center the text or not
                if (centered) {
                    centeredText(lines[j], textStart);
                } else {
                    doc.text(startXPos, textStart, lines[j]);
                }
                
                textStart += (fontSize / 2); // Increment the y position for each line of text
            }

            // Increment after each text block rather than each line
            if (!centered) {
                textStart += (fontSize / 2); // This accounts for the space after each block of text
            }
        }
        
        return textStart; // Return the updated text start position
    };

      var createTablesForAv = function (avText, startPosY) {
        var bodyAvText = [[avText]];

        doc.autoTable({
          startY: startPosY, //(lastPos / 2) * 0.7 + startPosY,
          startx: startXPos,
          head: [["Avaliação do nutricionista"]],
          body: bodyAvText,
        });
        startPosY = doc.previousAutoTable.finalY;
        return doc.previousAutoTable.finalY;
      };

      var descrTratamento = function (tipo) {
        if (tipo == "1 consulta nutricional patrocinada") {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (tipo == "1 consulta nutricional Crédito / Débito") {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (tipo == "1 consulta nutricional PIX / Transferência") {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (
          tipo == "1 consulta nutricional (plano família, mínimo 2 pessoas)"
        ) {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (
          tipo == "1 consulta nutricional SEM RETORNO"
        ) {
          return "Uma consulta nutricional + cardápios personalizados + prescrição de supplementos";
        } else if (tipo == "Programa Clínico Funcional") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa Modulação Intestinal") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa Clínico Funcional (plano família)") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa Modulação Intestinal (plano família)") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa 12S Online") {
          return "Durante 12 semanas: 3 consultas nutricionais + cardápios personalizados + prescrição de supplementos + video-aulas + livro/diário do programa";
        } else if (tipo == "Programa 12S Plano Básico") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + tratamentos estéticos semanais + video-aulas + livro/diário do programa";
        } else if (tipo == "Programa 12S Plano Premium") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + tratamentos estéticos semanais + video-aulas + livro/diário do programa";
        } else if (tipo == "Programa 12S Plano VIP") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + tratamentos estéticos semanais + video-aulas + livro/diário do programa";
        } else if (tipo == "Protocolo Harmonização Corporal 24H") {
          return "Durante 4 semanas: 1 consulta nutricional + massagem moduladora 2 vezes por semana";
        } else if (tipo == "Pacote Estético") {
          return "Tratamentos estéticos semanais";
        } else if (tipo == "1 sessão estética avulsa") {
          return "Tratamento estético";
        } else if (tipo == "1 exame de bioimpedância") {
          return "Exame que mede a quantidade de água, massa magra e gordura em cada área do corpo";
        } else if (
          tipo == "1 exame de intolerância e hipersensibilidade alimentar"
        ) {
          return "Exame de intolerância e hipersensibilidade alimentar de 216 alimentos";
        } else if (tipo == "Protocolo Kirchhoff") {
          return "Uma consulta nutricional + prescrição de cardápios personalizados + prescrição de suplementos, para fortalecer a imunidade";
        } else if (tipo == "Pacote 90 dias Protocolo Kirchhoff") {
          return "Durante 90 dias: 3 consultas nutricionais + prescrição de cardápios personalizados + prescrição de suplementos, para fortalecer a imunidade";
        } else if (tipo == "Outro") {
          return "";
        }
      };

      var localCode = this.localCode;
      var adding = function (accumulator, a) {
        return accumulator + a;
      };
      var valorArray = this.chosenAv.mov.soldObjs.map(function (obj) {
        return obj.valor[localCode];
      });

      var valorOriginal = valorArray.reduce(adding, 0);

      var niceString = function (valor) {
        var valorKomma;

        var decimals = Math.round((valor - Math.floor(valor)) * 100);
        var decimalsStr;
        if (decimals < 1) {
          decimalsStr = "00";
        } else if (decimals < 10) {
          decimalsStr = "0" + decimals.toString();
        } else {
          decimalsStr = decimals.toString();
        }

        var valorGanz = Math.floor(valor);

        if (valorGanz > 1000) {
          var tusiger = Math.floor(valor / 1000);
          var rest = valorGanz % 1000;
          var restStr;
          if (rest < 10) {
            restStr = "00" + rest.toString();
          } else if (rest < 100) {
            restStr = "0" + rest.toString();
          } else {
            restStr = rest.toString();
          }
          valorKomma = tusiger.toString() + "." + restStr;
        } else {
          valorKomma = valorGanz.toString();
        }
        return "R$ " + valorKomma + "," + decimalsStr;
      };

      var createTablesForSug = function (av, startPosY) {
        var bodyAvText = [
          [av.mov.tipo],
          [`${descrTratamento(av.mov.tipo)}`],
          [`Valor original: ${niceString(valorOriginal)}`],
          [`Desconto: ${niceString(av.mov.desconto)}`],
          [`Valor com desconto: ${niceString(av.mov.valor)}`],
          [`Voucher de desconto: ${av.id}`],
        ];

        doc.autoTable({
          startY: startPosY, //(lastPos / 2) * 0.7 + startPosY,
          startx: startXPos,
          head: [["Sugestão de tratamento"]],
          body: bodyAvText,
        });
        startPosY = doc.previousAutoTable.finalY;
        return doc.previousAutoTable.finalY;
      };

      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      var newEnd1 = createTexts(
        ["Avaliação gratuita", `${this.chosenAv.nome}`],
        true,
        textStartYPos // + 1 * (fontSize * (3 / 4))
      );

      //   var newEndY = createTexts(
      //     [`${this.avEscrita}`],
      //     false,
      //     textStartYPos + 5 * (fontSize * (3 / 4))
      //   );
      var newEnd2 = createTablesForAv(this.chosenAv.avEscrita, newEnd1 + 16);
      var newEnd3 = createTablesForSug(this.chosenAv, newEnd2 + 8);

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;
      textStartYPos = imgHeight + newEnd3 + 16;

      doc.addImage({
        imageData: this.preloadSignature,
        y: newEnd3 + 16,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          `Nutricionista ${this.funcionarioOnline.nome}`,
          `CRN: ${this.funcionarioOnline.crn}`,
          `Data: ${this.chosenAv.dateStr}`,
          "",
          `${this.userInfo.addressRoad} - ${this.userInfo.addressComplement}`,
          `${this.userInfo.addressCep} ${this.userInfo.addressCity} - ${this.userInfo.addressBairro}`,
          `tel.: ${this.userInfo.phoneClients}`,
        ],
        true,
        textStartYPos // + texts.length * (fontSize * (3 / 4))
      );

      alert("Gerando PDF");
      doc.save("avGratuita.pdf");
    },
    // async preloadImages() {
    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "umarizalbelem@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.preloadSignature.src = require("@/assets/signature_danielle.png");
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 2") {
    //       this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 3") {
    //       this.preloadSignature.src = require("@/assets/signature_henrique.png");
    //     }
    //   }

    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "bc@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.preloadSignature.src = require("@/assets/signature_none.jpg");
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 2") {
    //       this.preloadSignature.src = require("@/assets/signature_suellen.jpg");
    //     } else {
    //       this.preloadSignature.src = require("@/assets/signature_suellen.jpg");
    //     }
    //   }

    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "fortaleza.salinas@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.preloadSignature.src = require("@/assets/signature_leanna.png");
    //     } else {
    //       this.preloadSignature.src = require("@/assets/signature_leanna.png");
    //     }
    //   }

    //   this.preloadSignature.onload = function () {
    //     console.log("Signature carregada");
    //   };
    //   console.log(
    //     "natural:",
    //     this.preloadSignature.naturalWidth,
    //     this.preloadSignature.naturalHeight
    //   );
    // },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async preloadImages() {
      if (
        this.funcionarioOnline.assinatura !== undefined &&
        this.funcionarioOnline.assinatura !== ""
      ) {
        this.toDataURL(this.funcionarioOnline.assinatura, (data) => {
          this.preloadSignature.src = data;
        });
      } else {
        this.preloadSignature.src = require("@/assets/signature_none.jpg");
      }

      if (this.isFranquia) {
        this.preloadLogo.src = require("@/assets/logoCompleto.png");
      } else if (this.userInfo.profilePicture !== undefined && this.userInfo.profilePicture !== "" && !this.isFranquia) {
        this.toDataURL(this.userInfo.profilePicture, (data) => {
          this.preloadLogo.src = data;
        });
      } else {
        this.preloadLogo.src = require("@/assets/signature_none.jpg");
      }

      this.preloadLogo.onload = function () {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function () {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    colorPerc(perc) {
      if (perc <= 25) {
        return "#660000";
      } else if (perc > 25 && perc <= 50) {
        return "#ff0000";
      } else if (perc > 50 && perc <= 75) {
        return "#ff8000";
      } else if (perc > 75 && perc <= 88) {
        return "#ffff1f";
      } else if (perc > 88 && perc <= 95) {
        return "#cccc00";
      } else if (perc > 95) {
        return "#008000";
      } else {
        return "#c0c0c0";
      }
    },
    niceString(valor) {
      var valorKomma;

      var decimals = Math.round((valor - Math.floor(valor)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valor);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valor / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    async getAvPacs() {
      var avs = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("avGratuita")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return {
              value: doc.id,
              text: doc.data().nome,
            };
          })
        );

      this.avPacs = avs;
    },
    async getVoucher() {
      if (this.voucherDesconto !== null) {
        this.dialogVoucher = false;
        var av = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("avGratuita")
          .doc(this.voucherDesconto)
          .get()
          .then(function (doc) {
            return doc.data();
          });

        if (av !== undefined) {
          this.chosenAv = av;
          this.dialogAv = true;
          this.voucherFound = true;
        } else {
          this.dialogVoucherNotFound = true;
          this.dialogVoucher = true;
          this.voucherFound = false;
        }
      } else {
        this.dialogVoucherNenhum = true;
        this.dialogVoucher = true;
        this.voucherFound = false;
      }
    },
    async getVoucherDoc(docId) {
      if (docId !== null) {
        this.dialogVoucher = false;
        var av = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("avGratuita")
          .doc(docId)
          .get()
          .then(function (doc) {
            return doc.data();
          });

        if (av !== undefined) {
          this.chosenAv = av;
          this.dialogAv = true;
          this.voucherFound = true;
        } else {
          this.dialogVoucherNotFound = true;
          this.dialogVoucher = true;
          this.voucherFound = false;
        }
      } else {
        this.dialogVoucherNenhum = true;
        this.dialogVoucher = true;
        this.voucherFound = false;
      }
    },
  },
  computed: {
    localCode() {
      if (
        sessionStorage.getItem("current-user") ==
        "umarizalbelem@clubedanutricionista.com.br"
      ) {
        return "belemx";
      } else if (
        sessionStorage.getItem("current-user") ==
        "bc@clubedanutricionista.com.br"
      ) {
        return "balcam";
      } else if (
        sessionStorage.getItem("current-user") ==
        "fortaleza.salinas@clubedanutricionista.com.br"
      ) {
        return "fortal";
      } else if (
        sessionStorage.getItem("current-user") == "thayanalucy@gmail.com"
      ) {
        return "belemx";
      } else {
        return "balcam";
      }
    },
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
    bmiPerc() {
      var bmiI = this.bmi - 18.5;
      var bmiT;
      if (bmiI > 11.5) {
        bmiT = 11.5;
      } else {
        bmiT = bmiI;
      }
      var bmiP = (bmiT / 11.5) * 100;
      return Math.round(bmiP);
    },
  },
  mounted() {
    setTimeout(() => {
      this.preloadImages();
      this.getAvPacs();
    }, 500);
  },
};
</script>