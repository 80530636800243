<template>
  <div v-if="normal">
    <v-dialog v-model="wrongAutorDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Sem autorização!
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center"
            ><v-icon x-large color="red">mdi-close-circle</v-icon></v-row
          >
          <v-row class="d-flex justify-center"
            >Só o autor desta receita pode alterar ela. Para poder salvar,
            altere o título e use a opção "Salvar como novo".</v-row
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="d-flex justify-center my-5 text-h6"
      >Editar receita salva:</v-row
    >
    <v-row class="d-flex justify-center align-center">
      <v-autocomplete
        label="Receita"
        :items="receitas"
        v-model="receita.id"
        style="width: 100%; max-width: 250px"
        no-data-text="..."
      ></v-autocomplete>
      <v-btn
        @click="editarReceita(receita.id)"
        class="mx-4"
        color="grey lighten-2"
      >
        <v-icon left>
          {{ icons.mdiPlaylistEdit }}
        </v-icon>
        Editar Receita
      </v-btn>
    </v-row>
    <v-divider></v-divider>
    <v-row class="d-flex justify-center my-5 text-h6"
      >Criar receita nova:</v-row
    >
    <v-row class="d-flex justify-center">
      <v-btn
        color="#c5d13f"
        to="/CriarReceitaNova"
        large
        class="black--text my-2"
      >
        <v-icon color="#614021" left>{{ icons.mdiPlaylistPlus }}</v-icon>
        Criar Receita
      </v-btn>
    </v-row>
    <!-- <v-row>
      <v-btn @click="numberOfReceitas">show number</v-btn>
    </v-row> -->
  </div>
  <div v-else>
    <v-card elevation="5">
      <v-card-title class="text-h6"> Receitas </v-card-title>
      <v-card-text>
        <v-card
          v-for="(receita, receitaIndex) in receitas"
          :key="receitaIndex"
          outlined
        >
          <v-row class="d-flex justify-center">
            <v-col cols="12" class="d-flex justify-space-between">
              <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                {{ receita.data.titulo }},
                {{ receita.data.alimentosList.length }}
              </v-row>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 mx-2 black--text"
                    icon
                    @click="show(receita)"
                  >
                    <v-icon color="green">{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>mostra receita</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 mx-2 black--text"
                    icon
                    @click="deleteReceita(receita)"
                  >
                    <v-icon color="red">{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>Deletar receita</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import firebase from "firebase";
import router from "../router";
import { mdiPlaylistPlus, mdiTrashCanOutline, mdiEye } from "@mdi/js";

export default {
  data: function () {
    return {
      normal: true,
      icons: {
        mdiPlaylistPlus,
        mdiTrashCanOutline,
        mdiEye,
      },
      receitasList: [],
      receita: {
        id: "",
      },
      wrongAutorDialog: false,
    };
  },
  computed: {
    receitas() {
      var alphabet = function (objs) {
        function compare(a, b) {
          if (a.data.titulo < b.data.titulo) {
            return -1;
          }
          if (a.data.titulo > b.data.titulo) {
            return 1;
          }
          return 0;
        }

        return objs.sort(compare);
      };

      return alphabet(this.$store.state.firestore.receitas);
    },
  },
  methods: {
    deleteReceita(receita) {
      if (receita.data.autor) {
        if (receita.data.autor == sessionStorage.getItem("current-user")) {
          var id = receita.value;
          console.log(id);
          this.$store.dispatch("firestore/receitaDelete", {
            receita: id,
          });
        } else {
          this.wrongAutorDialog = true;
        }
      } else {
        this.wrongAutorDialog = true;
      }
    },
    show(receita) {
      console.log(receita);
    },
    editarReceita(id) {
      var receita = this.receitas.find((item) => item.value == id);
      if (receita !== undefined) {
        this.$store.dispatch("firestore/receitaId", {
          receitaId: id,
        });
        this.$store.dispatch("firestore/receitaUpdate", {
          receita: receita.data,
        });
        router.replace("/EditarReceita");
      }
    },
    // numberOfReceitas() {
    //   console.log(this.receitas.length);
    // },
  },
  mounted() {
    if (this.$store.state.firestore.receitas.length === 0) {
      this.$store.dispatch("firestore/getReceitas");
    }
    // setTimeout(() => {
    //   this.createListWithNames();
    // }, 1000);
  },
};
</script>

<style scoped>
</style>