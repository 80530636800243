<template>
  <v-app-bar>
    <v-img
      :src="logo"
      contain
      max-height="60"
      max-width="180"
    ></v-img>

    <v-spacer></v-spacer>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1"
          value="patients"
          v-bind="attrs"
          v-on="on"
          color="#c5d13f"
        >
          <v-icon color="#614021">{{ icons.mdiAccountHeartOutline }}</v-icon>
        </v-btn>
      </template>
      <span>Acessar rede social do Clube</span>
    </v-tooltip>

    <v-btn
      class="mx-1 white--text"
      color="#614021"
      value="logout"
      to="/"
      @click="logout"
    >
      <span>Logout</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {
  mdiCalendar,
  mdiMagnify,
  mdiAccountPlus,
  mdiAccountGroup,
  mdiAccountHeartOutline,
  mdiCardAccountDetailsOutline,
} from "@mdi/js";

import firebase from "firebase";
import router from "../../router";

export default {
  data: function () {
    return {
      logo: null,
      userInfos: {},
      showPlanosDialog: false,
      pacientesMouse: false,
      icons: {
        mdiCalendar,
        mdiAccountPlus,
        mdiMagnify,
        mdiAccountGroup,
        mdiAccountHeartOutline,
        mdiCardAccountDetailsOutline,
      },
    };
  },
  firestore() {
    return {
      userInfos: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user")),
    };
  },
  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(function () {
          router.replace("/login");
        })
        .catch(function (error) {
          console.log(error);
        });
      sessionStorage.setItem("current-user", null);
      sessionStorage.removeItem("isAuthenticated");
    },
  },
  mounted() {
    this.logo = require("@/assets/logoCompleto.png");
  }
};
</script>

<style scoped>
</style>


