<template>
  <div>
    <v-row class="d-flex justify-center my-6 text-h5">
      Atendimento
    </v-row>
    <!-- <v-row class="d-flex justify-center my-6 text-h6">
      {{ pacienteFirestore.nome }}
    </v-row>     -->
    <AtendimentoForm></AtendimentoForm>
  </div>
</template>

<script>
import firebase from "firebase";
import AtendimentoForm from "./home/atendimento/AtendimentoForm.vue";
import {
  mdiAccount,
} from "@mdi/js";

export default {
  components: {AtendimentoForm},
  data: function () {
    return {
      valid: true,
      estadoCivilItems: [
        "-",
        "Solteira/Solteiro",
        "Casada/Casado",
        "Viúva/Viúvo",
        "Divorciada/Divorciado",
      ],
      pacienteFirestore: {},
      atendimentoInfos: {
      },
      icons: {
        mdiAccount,
      },
    };
  },
  computed: {
    pacienteAtendimento: function () {
      return this.$store.state.firestore.pacienteAtendimento;
    },
  },
  // methods: {
  //   submit () {
  //     if (this.$refs.form.validate()) {

  //       // this.$store.dispatch("firestore/pacienteAtendimento", this.pacientePreAtendimento);


  //     } else {
  //       console.log("Invalid data")
  //     }
  //   },
  // },
  firestore: function () {
    return {
      pacienteFirestore: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("pacientes")
        .doc(this.pacienteAtendimento),
    };
  },
};
</script>

<style>
</style>