<template>
  <div align="center">
    <!-- <v-dialog v-model="cadastroDialog">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Criar cadastro novo
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center">
          Acessar a plataforma de atendimento e gestão mais completa para
          nutricionistas
        </v-card-subtitle>
        <v-card-text>
          <v-stepper non-linear>
            <v-stepper-header>
              <v-stepper-step editable step="1"> Dados </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step editable step="2">
                Pagamento
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1" class="d-flex justify-center">
                <v-card outlined>
                  <v-card-text>
                    <v-row class="d-flex justify-center">
                      <v-text-field
                        label="nome"
                        prepend-icon="mdi-account"
                        outlined
                        v-model="nome"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row class="d-flex justify-center my-2">
                      <v-text-field
                        label="sobrenome"
                        prepend-icon="mdi-account"
                        outlined
                        v-model="sobrenome"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row class="d-flex justify-center my-2">
                      <v-text-field
                        label="email"
                        prepend-icon="mdi-at"
                        outlined
                        v-model="emailNovo"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row class="d-flex justify-center my-2">
                      <v-text-field
                        label="definir senha"
                        prepend-icon="mdi-lock"
                        outlined
                        v-model="senhaNovo"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row class="d-flex justify-center my-2">
                      <v-text-field
                        label="CPF/CNPJ"
                        prepend-icon="mdi-card-account-details"
                        outlined
                        v-model="cpf"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row class="d-flex justify-center my-2">
                      <v-text-field
                        label="CRN"
                        prepend-icon="mdi-doctor"
                        outlined
                        v-model="crn"
                      >
                      </v-text-field>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- <v-row class="d-flex justify-center text-h6 my-6">
      Acessar a área do paciente
    </v-row>
    
    <v-row align="center">
      <v-col class="text-center" cols="12">
        <v-btn large color="#c5d13f" href="https://nutriapp.app/#/">Login</v-btn>
      </v-col>
    </v-row> -->
    <v-row class="d-flex justify-center my-2">
      <v-card
        outlined
        style="width: 300px; background-color: rgba(197, 209, 63, 0.3);"
      >
        <v-card-title class="d-flex justify-center">
          Sou paciente ...
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center">
          Acessar a área do paciente
        </v-card-subtitle>
        <v-card-text class="d-flex justify-center">
          <v-btn large color="#c5d13f" href="https://nutriapp.app/#/"
            >área do paciente</v-btn
          >
        </v-card-text>
      </v-card>
    </v-row>
    <!-- <v-row class="d-flex justify-center my-2">
      <v-card
        outlined
        style="width: 300px; background-color: rgba(97, 64, 33, 0.4);"
      >
        <v-card-title class="d-flex justify-center">
          Sou nutricionista ...
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center">
          Login da nutricionista
        </v-card-subtitle>
        <v-card-text>
          <v-row v-if="!nutriLogin" class="d-flex justify-center">
            <v-btn
              large
              color="#614021"
              class="white--text"
              @click="nutriLogin = true"
              >área da nutricionista</v-btn
            >
          </v-row>
          <v-row class="d-flex justify-center" v-if="nutriLogin">
            <v-text-field
              label="email"
              v-model="email"
              prepend-icon="mdi-at"
              outlined
              background-color="white"
            >
            </v-text-field>
          </v-row>
          <v-row class="d-flex justify-center mt-1" v-if="nutriLogin">
            <v-text-field
              background-color="white"
              label="senha"
              outlined
              prepend-icon="mdi-lock"
              v-model="senha"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              required
              @click:append="show = !show"
            >
            </v-text-field>
          </v-row>
          <div v-if="nutriLogin">
            <v-row class="d-flex justify-center">
              <v-btn color="#c5d13f">
                <v-icon color="#614021">mdi-login</v-icon>
                Entrar</v-btn
              >
            </v-row>
            <v-row class="d-flex justify-center mt-3">
              <v-btn
                color="#614021"
                class="white--text"
                @click="cadastroDialog = true"
              >
                <v-icon color="#c5d13f">mdi-account-plus</v-icon>
                Criar cadastro novo</v-btn
              >
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-row> -->
  </div>
</template>

<script>
import { mdiLock, mdiEmail, mdiEye, mdiEyeOff, mdiAlert } from "@mdi/js";

export default {
  data: function() {
    return {
      cadastroDialog: false,
      nutriLogin: false,
      valid: false,
      show: false,
      email: "",
      senha: "",
      senhaNovo: null,
      emailNovo: null,
      nome: null,
      sobrenome: null,
      mandatory: [
        (v) => !!v || "Campo obrigatório",
        // v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      emailRules: [
        (v) => !!v || "E-mail obrigatório",
        (v) => /.+@.+/.test(v) || "E-mail invalido",
        (v) => /^\S*$/.test(v) || "E-mail com espaço",
      ],
      icons: { mdiLock, mdiEmail, mdiEye, mdiEyeOff, mdiAlert },
    };
  },
  computed: {
    alerts() {
      return this.$store.state.firestore.alerts;
    },
  },
  methods: {
    submit() {
      this.$store
        .dispatch("firestore/userPacienteLogin", {
          email: this.email,
          password: this.senha,
        })
        .then(() => {
          console.log(this.$store.state.firestore.isAuthenticated);
        });
    },
    resetPassword() {
      this.$store.dispatch("firestore/userResetPassword", {
        email: this.email,
      });
    },
  },
  mounted() {
    if (
      this.$store.state.firestore.defaultUserPacienteObj.email !== null &&
      this.$store.state.firestore.defaultUserPacienteObj.email !== undefined
    ) {
      this.email = this.$store.state.firestore.defaultUserPacienteObj.email;
      this.senha = this.$store.state.firestore.defaultUserPacienteObj.senha;
    }
  },
};
</script>
