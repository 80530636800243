<template>
  <div class="page">
      <HomeHeaderParceiro></HomeHeaderParceiro>
      <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
        <!-- <v-col cols="4" md="2">
          <HomeMenuParceiro></HomeMenuParceiro>
        </v-col>
        <v-col cols="8" md="10"> -->
          <!-- <router-view></router-view> -->
          <FarmaciaFormulas></FarmaciaFormulas>
        <!-- </v-col> -->
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <WelcomeFooter></WelcomeFooter>
    </div>
</template>

<script> 
import HomeHeaderParceiro from "../components/home/HomeHeaderParceiro";
// import HomeMenuParceiro from "../components/home/HomeMenuParceiro";
import WelcomeFooter from "../components/welcome/WelcomeFooter";
import FarmaciaFormulas from "../components/FarmaciaFormulas";

export default {
  components: { WelcomeFooter, HomeHeaderParceiro, /*HomeMenuParceiro,*/ FarmaciaFormulas},
};
</script>
