<template>
  <div>
    <v-dialog v-model="alimentoToDeleteDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Deseja deletar alimento "{{ alimentoToDelete.nome }}"</v-card-title
        >
        <v-card-subtitle class="d-flex justify-center"
          >O alimento será removido de forma irrevogável</v-card-subtitle
        >
        <v-card-actions class="d-flex justify-center">
          <v-btn dense color="grey" @click="alimentoToDeleteDialog = false"
            >Fechar</v-btn
          >
          <v-btn
            dense
            color="red"
            class="white--text mx-1"
            @click="doDeleteAlimento"
            :disabled="deletingAlimento"
          >
            <v-progress-circular
              :size="20"
              :width="3"
              color="#614021"
              indeterminate
              v-if="deletingAlimento"
            ></v-progress-circular>
            <v-icon color="white" left v-else>mdi-trash-can-outline</v-icon>
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialog" max-width="800">
      <v-card v-if="!saved && !saving">
        <v-card-title>
          {{ alimentoEdit.text }}
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Dados Gerais
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Nome: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.nome" type="text">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Código: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.codigo" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fonte dos dados: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.fonte" type="text">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Medida caseira padrão: </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.medida_caseira_padrao"
                type="text"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Gramas por medida caseira: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.gr_padrao" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Grupo de substituição, 1: </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="alimentoEdit.data.grupo_1"
                :items="grupo_1_ops"
                type="text"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Grupo de substituição, 2: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.grupo_2" type="text">
              </v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Composição Centesimal
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Proteina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.proteina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Lipideos [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.lipideos" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Carboidrato [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.carboidrato"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Cinzas [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.cinzas" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Calorias [kcal] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.kcal" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Energia [kj] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.kj" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Umidade [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.umidade" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fibra alimentar [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.fibra_alimentar"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fibra insoluvel [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.fibra_insoluvel"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fibra soluvel [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.fibra_soluvel"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Colesterol [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.colesterol"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Saturados: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.saturados" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Gordura trans: </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.gordura_trans"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Monoinsaturados: </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.monoinsaturados"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Poli-insaturados: </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.poliinsaturados"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Minerais
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Cálcio, Ca [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.calcio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Ferro, Fe [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.ferro" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Magnésio, Mg [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.magnesio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fósforo, P [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.fosforo" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Potássio, K [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.potassio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Sódio, Na [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.sodio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Zinco, Zn [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.zinco" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Cobre, Cu [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.cobre" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Manganês, Mn [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.manganes" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Iodo, I [mg] (em 100g) : </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.iodo" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Selénio, Se [mg] (em 100g) : </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.selenio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Vitaminas
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Retinol [μg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.retinol" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">RE, equivalente ao retinol [μg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.re" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">ERA [μg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.era" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Vitamina B [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.vitamina_b"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Vitamina C [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.vitamina_c"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Vitamina D [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.vitamina_d"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Vitamina E [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.vitamina_e"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Tiamina [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.tiamina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6"> Acido fólico [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.acido_folico"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6"> Acido pantoténico [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.acido_pantotenico"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Piridoxina [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.piridoxina"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Riboflavina [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.riboflavina"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Niacina [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.niacina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Aminoácidos
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6"> Ácido aspártico [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.acido_aspartico"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Ácido glutamico [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.acido_glutamico"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Alanina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.alanina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Arginina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.arginina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Cistina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.cistina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fenilalanina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.fenilalanina"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Glicina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.glicina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Histidina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.histidina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Isoleucina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.isoleucina"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Leucina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.leucina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Lisina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.lisina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Metionina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.metionina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Prolina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.prolina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Serina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.serina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Tirosina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.tirosina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Valina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.valina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Triptofano [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoEdit.data.triptofano"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Treonina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoEdit.data.treonina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#614021" @click="showDialog = false" text class="mx-2">
            Fechar
          </v-btn>
          <v-btn
            color="#c5d13f"
            class="mx-2"
            @click="saveAlimentoEdit(alimentoEdit)"
          >
            <v-icon color="#614021" left>mdi-content-save-outline</v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="saving && !saved">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-center"> Salvando ... </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else-if="saved && !saving">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
          <v-row class="d-flex justify-center"> Salvo! </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="fecharRecarregar"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newAlimentoDialog" max-width="800">
      <v-card v-if="!saved && !saving">
        <v-card-title>
          Adicionar Alimento
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Dados Gerais
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Nome: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.nome" type="text">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Código: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.codigo" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fonte dos dados: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.fonte" type="text">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Medida caseira padrão: </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.medida_caseira_padrao"
                type="text"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Gramas por medida caseira: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.gr_padrao" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Grupo de substituição, 1: </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="alimentoNew.data.grupo_1"
                :items="grupo_1_ops"
                type="text"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Grupo de substituição, 2: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.grupo_2" type="text">
              </v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Composição Centesimal
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Proteina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.proteina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Lipideos [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.lipideos" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Carboidrato [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.carboidrato"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Cinzas [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.cinzas" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Calorias [kcal] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.kcal" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Energia [kj] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.kj" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Umidade [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.umidade" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fibra alimentar [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.fibra_alimentar"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fibra insoluvel [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.fibra_insoluvel"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fibra soluvel [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.fibra_soluvel"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Colesterol [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.colesterol" type="number">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Saturados: </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.saturados" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Gordura trans: </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.gordura_trans"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Monoinsaturados: </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.monoinsaturados"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Poli-insaturados: </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.poliinsaturados"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Minerais
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Cálcio, Ca [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.calcio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Ferro, Fe [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.ferro" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Magnésio, Mg [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.magnesio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fósforo, P [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.fosforo" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Potássio, K [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.potassio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Sódio, Na [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.sodio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Zinco, Zn [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.zinco" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Cobre, Cu [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.cobre" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Manganês, Mn [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.manganes" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Iodo, I [mg] (em 100g) : </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.iodo" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Selénio, Se [mg] (em 100g) : </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.selenio" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Vitaminas
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Retinol [μg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.retinol" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">RE, equivalente ao retinol [μg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.re" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">ERA [μg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.era" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Vitamina B [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.vitamina_b" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Vitamina C [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.vitamina_c" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Vitamina D [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.vitamina_d" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Vitamina E [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.vitamina_e" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Tiamina [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.tiamina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6"> Acido fólico [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.acido_folico"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6"> Acido pantoténico [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.acido_pantotenico"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Piridoxina [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.piridoxina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Riboflavina [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.riboflavina"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Niacina [mg] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.niacina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            Aminoácidos
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6"> Ácido aspártico [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.acido_aspartico"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Ácido glutamico [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.acido_glutamico"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Alanina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.alanina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Arginina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.arginina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Cistina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.cistina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Fenilalanina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="alimentoNew.data.fenilalanina"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Glicina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.glicina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Histidina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.histidina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Isoleucina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.isoleucina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Leucina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.leucina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Lisina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.lisina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Metionina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.metionina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Prolina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.prolina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Serina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.serina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Tirosina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.tirosina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Valina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.valina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Triptofano [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.triptofano" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="d-flex align-center mx-2">
            <v-col cols="6">Treonina [g] (em 100g): </v-col>
            <v-col cols="6">
              <v-text-field v-model="alimentoNew.data.treonina" type="number">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            @click="newAlimentoDialog = false"
            text
            class="mx-2"
          >
            Fechar
          </v-btn>
          <v-btn
            color="#c5d13f"
            class="mx-2"
            @click="saveAlimentoNew(alimentoNew)"
          >
            <v-icon color="#614021" left>mdi-content-save-outline</v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="saving && !saved">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-center"> Salvando ... </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else-if="saved && !saving">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
          <v-row class="d-flex justify-center"> Salvo! </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="fecharRecarregar"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title>
        <v-row class="text-h6">
          <v-col cols="9"> Alimentos </v-col>
          <v-col cols="3">
            <v-btn color="#c5d13f" @click="newAlimentoDialog = true">
              <v-icon color="#614021">mdi-plus-circle-outline</v-icon>
              Adicionar Alimento
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mx-4">
          <v-chip
            v-for="letter in letters"
            :key="letter"
            @click="filterLetter(letter)"
          >
            {{ letter }}
          </v-chip>
        </v-row>
      </v-card-title>
      <v-card-text v-if="alimentosLetter.length > 0"
        >
        <!-- <v-row class="d-flex justify-center">
          <v-btn
            class="mx-2"
            color="#c5d13f"
            @click="createJsonString"
            label="Exportar"
          >
            <v-icon>mdi-export</v-icon>
          </v-btn>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-textarea
            solo
            readonly
            :value="jsonString"
            label="Copy the content below"
          ></v-textarea>
        </v-row> -->
        <v-card
          v-for="(alimento, alimentoIndex) in alimentosLetter"
          :key="alimentoIndex"
          outlined
          ><v-card-text>
            <v-row class="d-flex justify-center align-center" dense>
              <div>
                <v-icon
                  left
                  color="yellow"
                  v-if="editedList.includes(alimento.value)"
                  >mdi-database-alert</v-icon
                >
                <v-icon
                  left
                  color="red"
                  v-if="deletedList.includes(alimento.value)"
                  >mdi-database-remove</v-icon
                >
                <v-icon
                  left
                  color="green"
                  v-if="addedList.includes(alimento.value)"
                  >mdi-database-plus</v-icon
                >
                <span
                  v-if="
                    !editedList.includes(alimento.value) &&
                      !deletedList.includes(alimento.value) &&
                      !addedList.includes(alimento.value)
                  "
                >
                  {{ alimento.text }}, {{ alimento.data.kcal }} kcal
                </span>
                <span v-else-if="editedList.includes(alimento.value)">
                  <strong
                    >{{ alimento.text }}, {{ alimento.data.kcal }} kcal</strong
                  >
                </span>
                <span
                  color="green"
                  v-else-if="addedList.includes(alimento.value)"
                >
                  <strong
                    >{{ alimento.text }}, {{ alimento.data.kcal }} kcal</strong
                  >
                </span>
                <span
                  color="red"
                  v-else-if="deletedList.includes(alimento.value)"
                >
                  <strike
                    ><strong
                      >{{ alimento.text }},
                      {{ alimento.data.kcal }} kcal</strong
                    ></strike
                  >
                </span>
              </div>
              <v-spacer></v-spacer>

              <div>
                <v-tooltip bottom color="blue">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 mx-2 black--text"
                      icon
                      :disabled="deletedList.includes(alimento.value)"
                      @click="show(alimento)"
                    >
                      <v-icon color="blue">mdi-database-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>mostrar/editar {{ alimento.data.nome }}</span>
                </v-tooltip>

                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 mx-2 black--text"
                      icon
                      :disabled="deletedList.includes(alimento.value)"
                      @click="deleteAlimento(alimento)"
                    >
                      <v-icon color="red">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Deletar {{ alimento.data.nome }}</span>
                </v-tooltip>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <v-progress-circular
          :size="50"
          :width="5"
          color="#614021"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";
// import router from "../router";
// import { mdiPlaylistPlus, mdiTrashCanOutline, mdiEye } from "@mdi/js";
export default {
  data: function() {
    return {
      normal: true,
      showDialog: false,
      saved: false,
      saving: false,
      newAlimentoDialog: false,
      // icons: {
      //   mdiPlaylistPlus,
      //   mdiTrashCanOutline,
      //   mdiEye,
      // },
      alimentosLetter: [],
      editedList: [],
      addedList: [],
      deletedList: [],
      grupo_1_ops: [
        "",
        "FRUTAS",
        "POLPAS / SUCOS",
        "OLEAGINOSAS",
        "SEMENTES",
        "MOLHOS",
        "CARNE / FRANGO / PEIXE",
        "SUPLEMENTOS",
        "AZEITES / ÓLEOS",
        "VERDURAS",
        "CEREAL",
        "PÃES / BISCOITOS / BOLOS",
        "LEGUMES",
        "BEBIDAS",
        "TEMPEROS",
        "MASSAS",
        "LEITES E DERIVADOS",
        "SOBREMESAS",
        "TUBERCULOS",
        "FARINHAS",
        "AÇÚCARES",
        "CALDOS",
        "LEGUMINOSAS",
        "FERMENTOS",
        "CARBOIDRATOS",
        "FOLHAS / ERVAS",
      ],
      alimentoNew: {
        text: "",
        value: "",
        data: {
          acido_aspartico: 0,
          acido_folico: 0,
          acido_glutamico: 0,
          acido_pantotenico: 0,
          alanina: 0,
          arginina: 0,
          calcio: 0,
          carboidrato: 0,
          cinzas: 0,
          cistina: 0,
          cobre: 0,
          codigo: 0,
          colesterol: 0,
          // created_at: "",
          era: 0,
          fenilalanina: 0,
          ferro: 0,
          fibra_alimentar: 0,
          fibra_insoluvel: 0,
          fibra_soluvel: 0,
          fonte: "",
          fosforo: 0,
          glicina: 0,
          gordura_trans: 0,
          gr_padrao: 0,
          grupo_1: "",
          grupo_2: "",
          histidina: 0,
          // idioma: "br",
          iodo: 0,
          isoleucina: 0,
          kcal: 0,
          kj: 0,
          leucina: 0,
          lipideos: 0,
          lisina: 0,
          magnesio: 0,
          manganes: 0,
          medida_caseira_padrao: "",
          metionina: 0,
          monoinsaturados: 0,
          niacina: 0,
          nome: "",
          nutricionista: "",
          piridoxina: 0,
          poliinsaturados: 0,
          potassio: 0,
          prolina: 0,
          proteina: 0,
          re: 0,
          retinol: 0,
          riboflavina: 0,
          saturados: 0,
          selenio: 0,
          serina: 0,
          sodio: 0,
          tiamina: 0,
          tirosina: 0,
          treonina: 0,
          triptofano: 0,
          umidade: 0,
          updated_at: "",
          valina: 0,
          vitamina_b: 0,
          vitamina_c: 0,
          vitamina_d: 0,
          vitamina_e: 0,
          zinco: 0,
        },
      },
      alimentos: [],
      alimentoToDelete: { value: null, nome: null },
      alimentoToDeleteDialog: false,
      deletingAlimento: false,
      chosenLetter: null,
      jsonString: "",
      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "outros",
      ],
    };
  },
  computed: {
    alimentoEdit() {
      return this.$store.state.firestore.alimentoEdit;
    },
    // alimentos() {
    //   // var alphabet = function (objs) {
    //   //   function compare(a, b) {
    //   //     if (a.data.titulo < b.data.titulo) {
    //   //       return -1;
    //   //     }
    //   //     if (a.data.titulo > b.data.titulo) {
    //   //       return 1;
    //   //     }
    //   //     return 0;
    //   //   }

    //   //   return objs.sort(compare);
    //   // };
    //   return this.$store.state.firestore.alimentos;
    //   // return alphabet(this.$store.state.firestore.alimentos);
    // },
  },
  methods: {
    createJsonString() {
      this.jsonString = JSON.stringify(this.alimentosLetter, null, 2);
    },
    filterLetter(letter) {
      this.chosenLetter = letter;
      this.alimentosLetter = [];
      if (letter !== "outros") {
        var alimentosLetter = this.alimentos.filter(function(el) {
          if (el.text.length > 0) {
            return el.text.toUpperCase().substring(0, 1) == letter;
          } else {
            return false;
          }
        });
        this.alimentosLetter = alimentosLetter;
      } else {
        var alimentosNoLetter = this.alimentos.filter(function(el) {
          return el.text.length < 1;
        });

        this.alimentosLetter = alimentosNoLetter;
      }
    },
    async saveAlimentoEdit(alimento) {
      // console.log(alimento.data)
      this.saving = true;
      if (
        sessionStorage.getItem("current-user") ==
        "contato@clubedanutricionista.com.br"
      ) {
        await firebase
          .firestore()
          .collection("alimentos")
          .doc(alimento.value.toString())
          .update(alimento.data);
      } else {
        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("alimentos")
          .doc(alimento.value.toString())
          .update(alimento.data);
      }

      this.editedList.push(alimento.value);
      var alimIndex = this.alimentos
        .map(function(a) {
          return a.value;
        })
        .indexOf(alimento.value);
      this.alimentos[alimIndex] = alimento;
      this.saving = false;
      this.saved = true;
      this.filterLetter(this.chosenLetter);
    },
    async saveAlimentoNew(alimento) {
      console.log(alimento.data);
      this.saving = true;
      var refId = firebase
        .firestore()
        .collection("alimentos")
        .doc();
      var id = refId.id;
      if (
        sessionStorage.getItem("current-user") ==
        "contato@clubedanutricionista.com.br"
      ) {
        await firebase
          .firestore()
          .collection("alimentos")
          .doc(id.toString())
          .set(alimento.data);
      } else {
        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("alimentos")
          .doc(id.toString())
          .set(alimento.data);
      }

      alimento.value = id;
      alimento.text = alimento.data.nome;
      console.log(alimento);
      this.alimentos.push(alimento);
      this.addedList.push(id);
      this.saving = false;
      this.saved = true;
      this.filterLetter(this.chosenLetter);
    },
    deleteAlimento(alimento) {
      this.alimentoToDelete.nome = alimento.data.nome;
      this.alimentoToDelete.value = alimento.value;
      this.alimentoToDeleteDialog = true;
    },
    doDeleteAlimento() {
      this.deletingAlimento = true;
      this.deletedList.push(this.alimentoToDelete.value);
      const id = this.alimentoToDelete.value;
      console.log(id);
      this.$store.dispatch("firestore/alimentoDelete", {
        alimentoId: id,
      });
      // this.$store.dispatch("firestore/getAlimentos");
      // if (this.chosenLetter !== null) {
      //   this.filterLetter(this.chosenLetter);
      // }
      this.alimentoToDeleteDialog = false;
      this.deletingAlimento = false;
    },
    show(alimento) {
      this.$store.dispatch("firestore/showAlimento", { alimento });
      this.showDialog = true;
    },
    save() {
      this.saving = false;
      this.saved = true;
    },
    fecharRecarregar() {
      // this.$store.dispatch("firestore/getAlimentos");
      this.showDialog = false;
      this.saved = false;
    },
    async getAlimentos() {
      var alimentosList;
      if (
        sessionStorage.getItem("current-user") ==
        "contato@clubedanutricionista.com.br"
      ) {
        alimentosList = await firebase
          .firestore()
          .collection("alimentos")
          .get()
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return {
                value: doc.id,
                data: doc.data(), //myObj,
                text: doc.data().nome,
              };
            })
          );
        this.alimentos = alimentosList;
      } else {
        alimentosList = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("alimentos")
          .get()
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return {
                value: doc.id,
                data: doc.data(), //myObj,
                text: doc.data().nome,
              };
            })
          );
        this.alimentos = alimentosList;
      }
    },
  },
  mounted() {
    // this.$store.dispatch("firestore/getAlimentos");
    this.getAlimentos();
    this.chosenLetter = "A";
    setTimeout(() => {
      this.filterLetter(this.chosenLetter);
    }, 10000);
  },
};
</script>
