<template>
  <v-container>
    <div class="d-flex flex-column">
      <v-card
        class="pa-5 my-1"
        tile
        style="background-color: rgba(197, 209, 63, 0.6)"
      >
        <div class="d-flex flex-column">
          <v-row class="mx-4">
            <v-text-field
              v-model="formula.titulo"
              solo
              label="Título"
              @change="onUpdate"
            ></v-text-field>
          </v-row>
          <v-row class="mx-4">
            <v-textarea
              v-model="formula.prescricoes"
              solo
              label="Prescrição e Posologia"
              height="250"
              @change="onUpdate"
            ></v-textarea>
          </v-row>
          <v-row class="d-flex justify-center mx-4">
            <v-select
              v-model="formula.categorias"
              :items="formulaCategorias"
              attach
              chips
              label="Catergorias"
              multiple
              @change="onUpdate"
            ></v-select>
          </v-row>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mdiClose, mdiPlus, mdiAutorenew } from "@mdi/js";

export default {
  data: function () {
    return {
      icons: { mdiClose, mdiPlus, mdiAutorenew },
      // formula: {
      //   titulo: "",
      //   prescricoes: "",
      //   categorias: null
      // },
      formulaCategorias: [
        "Fórmulas veganas",
        "Fórmulas na pediatria",
        "Fórmulas nutrição e estética",
        "Fórmulas nutrição esportiva",
        "Fórmulas modulação intestinal",
        "Fórmulas saúde do homem",
        "Fórmulas saúde da mulher",
        "Fórmulas para imunidade",
        "Fórmulas para obesidade e emagrecimento",
        "Fórmulas para metais pesados",
        "Fórmulas neuro e sono",
        "Fórmulas longevidade",
        "Fórmulas para diabéticos",
        "Fórmulas de vitaminas e minerais",
        "Fórmulas de alergia e intolerância alimentar",
        "Fórmulas de patologia",
      ],
      inputRules: [],
      inputAlimentoRules: [],
      inputQtdRules: [(v) => /^[0-9]+$/.test(v) || "Use apenas números"],
    };
  },
  computed: {
    formulas() {
      return this.$store.state.firestore.formulas;
    },
    formula() {
      return this.$store.state.firestore.formula;
    },
  },
  methods: {
    // onFormulaSelected(formulaId) {
    //   var formula = this.formulas.find((item) => item.value == formulaId);
    //   if (formula !== undefined) {
    //     this.formula.titulo = formula.data.titulo;
    //     this.formula.prescricoes = formula.data.prescricoes;
    //     this.formula.indicacoes = formula.data.indicacoes;
    //     this.formula.sugestoes = formula.data.sugestoes;
    //   }
    //   this.$store.dispatch("firestore/formulaUpdate", {
    //     formula: this.formula,
    //   });
    // },
    onUpdate() {
      this.$store.dispatch("firestore/formulaUpdate", {
        formula: this.formula,
      });
    },
  },
};
</script>