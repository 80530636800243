<template>
  <div>
    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center"> Salvando dados... </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center"> Dados salvos! </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="justify-center">
      <v-alert
        v-show="disabled"
        dense
        outlined
        type="error"
        @click="toMeusPacientes"
      >
        Nenhum paciente selecionado: Clique aqui para selecionar um paciente
        agora!
      </v-alert>
    </v-row>

    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <!-- <v-row>{{pacienteAtendimento}}</v-row> -->
          <v-row class="mb-4 text-subtitle-1">
            <v-col cols="2"> Taxa metabol.: </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.taxaMetabolica"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >kcal</v-col
            >
            <v-col cols="2"> Peso ideal: </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.pesoIdeal"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >kg</v-col
            >
            <v-col cols="2"> Peso atual: </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.pesoAtual"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="changePesoAtual(compos.pesoAtual)"
              >
              </v-text-field
              >kg</v-col
            >
          </v-row>
          <v-row class="d-flex justify-center">
            <v-icon :color="colorPeso">{{ icons.mdiAlertCircle }}</v-icon>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1">
            Gordura corporal: {{ compos.percGordura }}%,
            {{ compos.percGorduraAbs }} kg
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-slider
                v-model="compos.percGordura"
                :color="color"
                track-color="grey"
                always-dirty
                step="0.1"
                min="0"
                max="100"
                @change="changePercGordura(compos.percGordura, true)"
              ></v-slider>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.percGordura"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="changePercGordura(compos.percGordura, true)"
              >
              </v-text-field
              >%
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.percGorduraAbs"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="changeAbsGordura(compos.percGorduraAbs, false)"
              >
              </v-text-field
              >kg
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1">
            Gordura visceral: Nível {{ compos.gorduraVisc }}
          </v-row>

          <v-row>
            <v-col cols="8">
              <v-slider
                v-model="compos.gorduraVisc"
                :color="colorVisc"
                track-color="grey"
                always-dirty
                step="0.1"
                min="0"
                max="25"
              ></v-slider>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.gorduraVisc"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1">
            Massa muscular esquelética: {{ compos.massaMagra }}%,
            {{ compos.massaMagraAbs }}kg
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-slider
                v-model="compos.massaMagra"
                :color="colorMassa"
                track-color="grey"
                always-dirty
                step="0.1"
                min="0"
                max="100"
                @change="changeMassaMagra(compos.massaMagra, true)"
              ></v-slider>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.massaMagra"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="changeMassaMagra(compos.massaMagra, true)"
              ></v-text-field
              >%
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.massaMagraAbs"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="changeMassaMagraAbs(compos.massaMagraAbs, false)"
              >
              </v-text-field
              >kg
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1">
            Água corporal: {{ compos.agua }}%, {{ compos.aguaAbs }}litros
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-slider
                v-model="compos.agua"
                :color="colorAgua"
                track-color="grey"
                always-dirty
                step="0.1"
                min="0"
                max="100"
                @change="changeAgua(compos.agua, true)"
              ></v-slider>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.agua"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="changeAgua(compos.agua, true)"
              >
              </v-text-field
              >%
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="compos.aguaAbs"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="changeAguaAbs(compos.aguaAbs, false)"
              >
              </v-text-field
              >litros
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1">
            <v-col cols="12"> Circunferências: </v-col>
          </v-row>
          <v-row class="mb-4 text-subtitle-1">
            <v-col cols="4">
              <v-text-field
                v-model="medidas.cintura"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Cintura
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="medidas.abdomen"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Abdômen
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="medidas.quadril"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Quadril
            </v-col>
          </v-row>
          <v-row class="mb-4 text-subtitle-1">
            <v-col cols="4">
              <v-text-field
                v-model="medidas.bracoRelaxado"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Braço relaxado
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="medidas.bracoContraido"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Braço contraído
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="medidas.panturrilha"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Panturrilha
            </v-col>
          </v-row>
          <v-row class="mb-4 text-subtitle-1">
            <v-col cols="4">
              <v-text-field
                v-model="medidas.perna"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Perna
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="medidas.coxa"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Coxa
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="medidas.torax"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Toráx
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="medidas.adipo"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              >
              </v-text-field
              >Adipômetro
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1"
            ><v-col cols="12"> Fotos: </v-col></v-row
          >
          <v-row class="text-subtitle-1">
            <!-- <v-col cols="2"> Frente: </v-col> -->
            <v-col cols="12">
              <v-file-input
                accept="image/*"
                label="Foto da frente"
                :prepend-icon="icons.mdiImage"
                @change="handleFotoFrente"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="text-subtitle-1">
            <!-- <v-col cols="2"> Atrás: </v-col> -->
            <v-col cols="12">
              <v-file-input
                accept="image/*"
                label="Foto de trás"
                :prepend-icon="icons.mdiImage"
                @change="handleFotoAtras"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="text-subtitle-1">
            <!-- <v-col cols="2"> Esquerda: </v-col> -->
            <v-col cols="12">
              <v-file-input
                accept="image/*"
                label="Foto da esquerda"
                :prepend-icon="icons.mdiImage"
                @change="handleFotoEsquerda"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="text-subtitle-1">
            <!-- <v-col cols="2"> Direita: </v-col> -->
            <v-col cols="12">
              <v-file-input
                accept="image/*"
                label="Foto da direita"
                :prepend-icon="icons.mdiImage"
                @change="handleFotoDireita"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1">
            <v-col cols="12"> Bioimpedância: </v-col></v-row
          >
          <v-row class="text-subtitle-1">
            <!-- <v-col cols="2"> Frente: </v-col> -->
            <v-col cols="12">
              <v-file-input
                accept="document/*"
                label="Bioimpedância"
                @change="handleBioimp"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1">
            <v-col cols="12"> Outros valores: </v-col>
          </v-row>

          <!-- <v-virtual-scroll
            :items="sangueObjs"
            :item-height="50"
            height="500"
          > 
            <template v-slot:default="{ sangueObj }">
              <v-list-item>-->
          <v-row
            class="text-body-2"
            v-for="(sangueObj, objIndex) in sangueObjs"
            :key="objIndex"
          >
            <v-col cols="5">
              <v-text-field
                v-model="sangueObj.nome"
                class="mt-0 pt-0"
                hide-details
                single-line
                label="nome"
                type="text"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="sangueObj.value"
                class="mt-0 pt-0"
                hide-details
                single-line
                label="valor"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="2"
                    rounded
                    icon
                    outlined
                    raised
                    x-small
                    color="red"
                    class="pa-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="removeObj(objIndex)"
                    ><v-icon>{{ icons.mdiMinus }}</v-icon>
                  </v-btn>
                </template>
                <span>remover item</span>
              </v-tooltip>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom color="green">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="objIndex === sangueObjs.length - 1"
                    elevation="2"
                    rounded
                    icon
                    outlined
                    raised
                    x-small
                    color="green"
                    class="pa-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="addObj"
                    ><v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>adicionar item</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <!-- </v-list-item>
            </template>
          </v-virtual-scroll>-->
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1">
            <v-col cols="12"> Outros Exames </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="exam1Nome"
                class="mt-0 pt-0"
                hide-details
                single-line
                label="nome"
                type="text"
              >
              </v-text-field>
            </v-col>
            <v-col cols="7">
              <v-file-input
                accept="document/*"
                label="Exame"
                @change="handleExam1"
              ></v-file-input>
            </v-col>
            <!-- <v-col cols="1">
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="2"
                    rounded
                    icon
                    outlined
                    raised
                    x-small
                    color="red"
                    class="pa-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="removeExam(examIndex)"
                    ><v-icon>{{ icons.mdiMinus }}</v-icon>
                  </v-btn>
                </template>
                <span>remover item</span>
              </v-tooltip>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom color="green">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="examIndex === exams.length - 1"
                    elevation="2"
                    rounded
                    icon
                    outlined
                    raised
                    x-small
                    color="green"
                    class="pa-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="addExam"
                    ><v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>adicionar item</span>
              </v-tooltip>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="exam2Nome"
                class="mt-0 pt-0"
                hide-details
                single-line
                label="nome"
                type="text"
              >
              </v-text-field>
            </v-col>
            <v-col cols="7">
              <v-file-input
                accept="document/*"
                label="Exame"
                @change="handleExam2"
              ></v-file-input></v-col
          ></v-row>
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="exam3Nome"
                class="mt-0 pt-0"
                hide-details
                single-line
                label="nome"
                type="text"
              >
              </v-text-field>
            </v-col>
            <v-col cols="7">
              <v-file-input
                accept="document/*"
                label="Exame"
                @change="handleExam3"
              ></v-file-input
            ></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="justify-center">
      <v-card class="my-2" min-width="600" outlined>
        <v-card-text>
          <v-row class="mb-4 text-subtitle-1">
            <v-col cols="12"> Anotações: </v-col></v-row
          >
          <v-row class="text-subtitle-1">
            <!-- <v-col cols="2"> Frente: </v-col> -->
            <v-col cols="12">
              <v-textarea
                v-model="anotacoes"
                solo
                label="Comente aqui..."
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="justify-center">
      <v-btn
        class="my-6"
        large
        color="#c5d13f"
        @click="submit"
        :disabled="disabled"
      >
        Salvar
      </v-btn>
    </v-row>

    <v-row class="justify-center">
      <v-alert
        v-show="disabled"
        dense
        outlined
        type="error"
        @click="toMeusPacientes"
      >
        Nenhum paciente selecionado: Clique aqui para selecionar um paciente
        agora!
      </v-alert>
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase";
import { mdiAlertCircle, mdiImage, mdiMinus, mdiPlus } from "@mdi/js";

export default {
  data: () => ({
    progressDialog: false,
    icons: {
      mdiAlertCircle,
      mdiImage,
      mdiMinus,
      mdiPlus,
    },
    fotoFrente: null,
    fotoAtras: null,
    fotoEsquerda: null,
    fotoDireita: null,
    bioimp: null,
    exam1: null,
    exam2: null,
    exam3: null,
    exam1Nome: null,
    exam2Nome: null,
    exam3Nome: null,
    saved: false,
    compos: {
      taxaMetabolica: 0,
      pesoIdeal: 0,
      pesoAtual: 0,
      percGorduraAbs: 0,
      massaMagraAbs: 0,
      massaMagra_asg: false,
      aguaAbs: 0,
      agua: 0,
      agua_ags: false,
      massaMagra: 0,
      percGordura: 0,
      percGordura_asg: false,
      gorduraVisc: 0,
    },
    medidas: {
      adipo: 0,
      torax: 0,
      coxa: 0,
      perna: 0,
      panturrilha: 0,
      bracoContraido: 0,
      bracoRelaxado: 0,
      cintura: 0,
      abdomen: 0,
      quadril: 0,
    },
    sangueObjs: [
      //v-for="(sangueObj, objIndex) in sangueObjs" :key="objIndex"
      { nome: "Glicemia de jejum", valor: 0, index: 0 },
      // { nome: "Glicemia de jejum", valor: 0, index: 0 },
      // { nome: "Colesterol total", valor: 0, index: 1 },
      // { nome: "HDL", valor: 0, index: 2 },
      // { nome: "LDL", valor: 0, index: 3 },
      // { nome: "Triglicerídios", valor: 0, index: 4 },
      // { nome: "TSH", valor: 0, index: 5 },
      // { nome: "TGP", valor: 0, index: 6 },
      // { nome: "TGO", valor: 0, index: 7 },
      // { nome: "Ureia", valor: 0, index: 8 },
      // { nome: "Creatina", valor: 0, index: 9 },
      // { nome: "Acido úrico", valor: 0, index: 10 },
      // { nome: "Insulina de jejum", valor: 0, index: 11 },
      // { nome: "Vitamína D", valor: 0, index: 12 },
      // { nome: "Plaquetes", valor: 0, index: 13 },
      // { nome: "Segmentados", valor: 0, index: 14 },
      // { nome: "Linfócitos", valor: 0, index: 15 },
      // { nome: "Leucócitos", valor: 0, index: 16 },
      // { nome: "Hemoglobina", valor: 0, index: 17 },
      // { nome: "Hemácias", valor: 0, index: 18 },
      // { nome: "Ferritina", valor: 0, index: 19 },
      // { nome: "Homocisteina", valor: 0, index: 20 },
      // { nome: "PCR", valor: 0, index: 21 },
    ],
    anotacoes: "",
  }),

  computed: {
    pacienteAtendimento: function () {
      return this.$store.state.firestore.pacienteAtendimento;
    },
    disabled: function () {
      if (this.pacienteAtendimento == "") {
        return true;
      } else {
        return false;
      }
    },
    pesoRatio() {
      var ratio = this.compos.pesoAtual / this.compos.pesoIdeal;
      return ratio;
    },
    color() {
      if (this.compos.percGordura < 8) return "#d73027";
      if (this.compos.percGordura < 13) return "#fdae61";
      if (this.compos.percGordura < 18) return "#fee08b";
      if (this.compos.percGordura < 23) return "#006837";
      if (this.compos.percGordura < 28) return "#1a9850";
      if (this.compos.percGordura < 33) return "#fee08b";
      if (this.compos.percGordura < 38) return "#f46d43";
      if (this.compos.percGordura < 33) return "#d73027";
      return "#a50026";
    },
    colorVisc() {
      if (this.compos.gorduraVisc < 3) return "#006837";
      if (this.compos.gorduraVisc < 6) return "#1a9850";
      if (this.compos.gorduraVisc < 9) return "#66bd63";
      if (this.compos.gorduraVisc < 10) return "#fee08b";
      if (this.compos.gorduraVisc < 11) return "#f46d43";
      if (this.compos.gorduraVisc < 12) return "#d73027";
      return "#a50026";
    },
    colorMassa() {
      if (this.compos.massaMagra < 25) return "#a50026";
      if (this.compos.massaMagra < 30) return "#d73027";
      if (this.compos.massaMagra < 35) return "#fdae61";
      if (this.compos.massaMagra < 40) return "#1a9850";
      if (this.compos.massaMagra < 45) return "#006837";
      if (this.compos.massaMagra < 50) return "#1a9850";
      if (this.compos.massaMagra < 55) return "#fee08b";
      if (this.compos.massaMagra < 60) return "#f46d43";
      if (this.compos.massaMagra < 65) return "#d73027";
      return "#a50026";
    },
    colorPeso() {
      if (this.pesoRatio < 0.75) return "#a50026";
      if (this.pesoRatio < 0.85) return "#d73027";
      if (this.pesoRatio < 0.95) return "#fdae61";
      if (this.pesoRatio < 1.05) return "#006837";
      if (this.pesoRatio < 1.15) return "#1a9850";
      if (this.pesoRatio < 1.25) return "#fee08b";
      if (this.pesoRatio < 1.35) return "#f46d43";
      if (this.pesoRatio < 1.45) return "#d73027";
      return "#a50026";
    },
    colorAgua() {
      if (this.compos.agua < 40) return "#a50026";
      if (this.compos.agua < 45) return "#f46d43";
      if (this.compos.agua < 50) return "#fee08b";
      if (this.compos.agua < 55) return "#1a9850";
      if (this.compos.agua < 60) return "#006837";
      if (this.compos.agua < 65) return "#d9ef8b";
      if (this.compos.agua < 70) return "#fee08b";
      if (this.compos.agua < 75) return "#d73027";
      return "#a50026";
    },
  },

  methods: {
    async submit() {
      var date = new Date();
      var milliseconds = date.getTime();

      var monthStr = date.getMonth() + 1;
      var simpleDateStr =
        date.getFullYear().toString() +
        "-" +
        monthStr.toString() +
        "-" +
        date.getDate().toString();

      this.progressDialog = true;

      var infos = {};
      if (this.fotoFrente !== null) {
        console.log("Enviando imagem da frente!");
        await firebase
          .storage()
          .ref("pacienteImgs")
          .child(
            sessionStorage.getItem("current-user") +
              "_" +
              this.pacienteAtendimento +
              "_" +
              milliseconds.toString() +
              "_frente"
          )
          // .ref("pacienteImgs")
          // .child(this.pacienteAtendimento)
          .put(this.fotoFrente)
          .then(async (snapshot) => {
            infos.fotoFrente = await snapshot.ref.getDownloadURL();
            console.log(infos.fotoFrente);
          });
      }

      if (this.fotoAtras !== null) {
        console.log("Enviando imagem de trás!");
        await firebase
          .storage()
          .ref("pacienteImgs")
          .child(
            sessionStorage.getItem("current-user") +
              "_" +
              this.pacienteAtendimento +
              "_" +
              milliseconds.toString() +
              "_atras"
          )
          // .ref("pacienteImgs")
          // .child(this.pacienteAtendimento)
          .put(this.fotoAtras)
          .then(async (snapshot) => {
            infos.fotoAtras = await snapshot.ref.getDownloadURL();
            console.log(infos.fotoAtras);
          });
      }

      if (this.fotoEsquerda !== null) {
        console.log("Enviando imagem da esquerda!");
        await firebase
          .storage()
          .ref("pacienteImgs")
          .child(
            sessionStorage.getItem("current-user") +
              "_" +
              this.pacienteAtendimento +
              "_" +
              milliseconds.toString() +
              "_esquerda"
          )
          // .ref("pacienteImgs")
          // .child(this.pacienteAtendimento)
          .put(this.fotoEsquerda)
          .then(async (snapshot) => {
            infos.fotoEsquerda = await snapshot.ref.getDownloadURL();
            console.log(infos.fotoEsquerda);
          });
      }

      if (this.fotoDireita !== null) {
        console.log("Enviando imagem da direita!");
        await firebase
          .storage()
          .ref("pacienteImgs")
          .child(
            sessionStorage.getItem("current-user") +
              "_" +
              this.pacienteAtendimento +
              "_" +
              milliseconds.toString() +
              "_direita"
          )
          // .ref("pacienteImgs")
          // .child(this.pacienteAtendimento)
          .put(this.fotoDireita)
          .then(async (snapshot) => {
            infos.fotoDireita = await snapshot.ref.getDownloadURL();
            console.log(infos.fotoDireita);
          });
      }

      if (this.bioimp !== null) {
        console.log("Enviando bioimpedancia!");
        await firebase
          .storage()
          .ref("bioimpedancias")
          .child(
            sessionStorage.getItem("current-user") +
              "_" +
              this.pacienteAtendimento +
              "_" +
              milliseconds.toString() +
              "_bioimp"
          )
          // .ref("pacienteImgs")
          // .child(this.pacienteAtendimento)
          .put(this.bioimp)
          .then(async (snapshot) => {
            infos.bioimp = await snapshot.ref.getDownloadURL();
            console.log(infos.bioimp);
          });
      }

      if (this.exam1 !== null) {
        console.log("Enviando exame 1!");
        await firebase
          .storage()
          .ref("exams")
          .child(
            sessionStorage.getItem("current-user") +
              "_" +
              this.pacienteAtendimento +
              "_" +
              simpleDateStr +
              "_exam1"
          )
          // .ref("pacienteImgs")
          // .child(this.pacienteAtendimento)
          .put(this.exam1)
          .then(async (snapshot) => {
            infos.exam1 = await snapshot.ref.getDownloadURL();
            console.log(infos.exam1);
          });
      } else {
        infos.exam1 = null;
      }

      if (this.exam2 !== null) {
        console.log("Enviando exame 1!");
        await firebase
          .storage()
          .ref("exams")
          .child(
            sessionStorage.getItem("current-user") +
              "_" +
              this.pacienteAtendimento +
              "_" +
              simpleDateStr +
              "_exam2"
          )
          // .ref("pacienteImgs")
          // .child(this.pacienteAtendimento)
          .put(this.exam2)
          .then(async (snapshot) => {
            infos.exam2 = await snapshot.ref.getDownloadURL();
            console.log(infos.exam2);
          });
      } else {
        infos.exam2 = null;
      }

      if (this.exam3 !== null) {
        console.log("Enviando exame 3!");
        await firebase
          .storage()
          .ref("exams")
          .child(
            sessionStorage.getItem("current-user") +
              "_" +
              this.pacienteAtendimento +
              "_" +
              simpleDateStr +
              "_exam3"
          )
          // .ref("pacienteImgs")
          // .child(this.pacienteAtendimento)
          .put(this.exam3)
          .then(async (snapshot) => {
            infos.exam3 = await snapshot.ref.getDownloadURL();
            console.log(infos.exam3);
          });
      } else {
        infos.exam3 = null;
      }

      this.$store.dispatch("firestore/salvarAtendimento", {
        paciente: this.pacienteAtendimento,
        compos: this.compos,
        medidas: this.medidas,
        fotoFrenteUrl: infos.fotoFrente == null ? "" : infos.fotoFrente,
        fotoAtrasUrl: infos.fotoAtras == null ? "" : infos.fotoAtras,
        fotoEsquerdaUrl: infos.fotoEsquerda == null ? "" : infos.fotoEsquerda,
        fotoDireitaUrl: infos.fotoDireita == null ? "" : infos.fotoDireita,
        bioimp: infos.bioimp == null ? "" : infos.bioimp,
        exam1: infos.exam1 == null ? "" : infos.exam1,
        exam2: infos.exam2 == null ? "" : infos.exam1,
        exam3: infos.exam3 == null ? "" : infos.exam1,
        exam1Nome: this.exam1Nome,
        exam2Nome: this.exam2Nome,
        exam3Nome: this.exam3Nome,
        sangue: this.sangueObjs,
        anotacoes: this.anotacoes,
      });

      this.$store.dispatch("firestore/persistPaciente", {
        pId: this.pacienteAtendimento,
      });

      this.saved = true;
             
      setTimeout(() => {
        this.progressDialog = false;
        this.$router.push("MeusPacientes");
      }, 1500);
    },
    removeObj(index) {
      this.sangueObjs.splice(this.sangueObjs.indexOf(index), 1);
    },
    addObj() {
      this.sangueObjs.push({
        nome: "",
        value: 0,
        index: this.sangueObjs.length - 1,
      });
    },
    removeExam(index) {
      this.exams.splice(this.exams.indexOf(index), 1);
    },
    addExam() {
      this.exams.push({ file: null });
    },
    changePercGordura(percGordura, asg) {
      this.compos.percGorduraAbs = (this.compos.pesoAtual / 100) * percGordura;
      this.percGordura_asg = asg;
    },
    changeAbsGordura(percGorduraAbs, asg) {
      this.compos.percGordura = (percGorduraAbs / this.compos.pesoAtual) * 100;
      this.percGordura_asg = asg;
    },
    changeMassaMagra(massaMagra, asg) {
      this.compos.massaMagraAbs = (this.compos.pesoAtual / 100) * massaMagra;
      this.massaMagra_asg = asg;
    },
    changeMassaMagraAbs(massaMagraAbs, asg) {
      this.compos.massaMagra = (massaMagraAbs / this.compos.pesoAtual) * 100;
      this.massaMagra_asg = asg;
    },
    changeAgua(agua, asg) {
      this.compos.aguaAbs = (this.compos.pesoAtual / 100) * agua;
      this.agua_asg = asg;
    },
    changeAguaAbs(aguaAbs, asg) {
      this.compos.agua = (aguaAbs / this.compos.pesoAtual) * 100;
      this.agua_asg = asg;
    },
    changePesoAtual(pesoAtual) {
      if (this.percGordura_asg) {
        this.compos.percGorduraAbs =
          (pesoAtual / 100) * this.compos.percGordura;
      } else {
        this.compos.percGordura =
          (this.compos.percGorduraAbs / pesoAtual) * 100;
      }

      if (this.massaMagra_asg) {
        this.compos.massaMagraAbs = (pesoAtual / 100) * this.compos.massaMagra;
      } else {
        this.compos.massaMagra = (this.compos.massaMagraAbs / pesoAtual) * 100;
      }

      if (this.agua_asg) {
        this.compos.aguaAbs = (pesoAtual / 100) * this.compos.agua;
      } else {
        this.compos.agua = (this.compos.aguaAbs / pesoAtual) * 100;
      }
    },
    handleFotoFrente(file) {
      this.fotoFrente = file;
    },
    handleFotoAtras(file) {
      this.fotoAtras = file;
    },
    handleFotoEsquerda(file) {
      this.fotoEsquerda = file;
    },
    handleFotoDireita(file) {
      this.fotoDireita = file;
    },
    handleBioimp(file) {
      this.bioimp = file;
    },
    handleExam1(file) {
      this.exam1 = file;
    },
    handleExam2(file) {
      this.exam2 = file;
    },
    handleExam3(file) {
      this.exam3 = file;
    },
    toMeusPacientes() {
      this.$router.push("MeusPacientes");
    },
  },
};
</script>
