<template>
  <div class="page">
    <CadastrarHeader></CadastrarHeader>
    <CadastrarForm></CadastrarForm>
    <WelcomeFooter></WelcomeFooter>
  </div>
</template>

<script>
import WelcomeFooter from "../components/welcome/WelcomeFooter";
import CadastrarHeader from "../components/cadastro/CadastrarHeader";
import CadastrarForm from "../components/cadastro/CadastrarForm";

export default {
  components: { WelcomeFooter, CadastrarHeader, CadastrarForm }
};
</script>

<style>
</style>