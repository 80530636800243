<template>
  <div class="d-flex flex-column justify-center">
    <v-dialog v-model="questPaciente" max-width="75%">
      <v-card color="grey lighten-3">
        <v-card-title>Questionário Pré-Consulta</v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="4">
              <v-select
                :prepend-icon="icons.mdiHandHeart"
                v-model="pacienteInfos.estadoCivil"
                :items="estadoCivilItems"
                label="Estado civil"
                readonly
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :prepend-icon="icons.mdiBabyCarriage"
                v-model="pacienteInfos.filhos"
                label="Filhos"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :prepend-icon="icons.mdiHumanMaleChild"
                v-model="pacienteInfos.idadeFilhos"
                label="Idade dos filhos"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center" v-if="pacienteInfos.profissao">
            <v-col cols="12" md="6">
              <v-badge content="!" color="red" overlap>
                <v-text-field
                  :prepend-icon="icons.mdiAccountHardHat"
                  v-model="pacienteInfos.profissao"
                  label="Profissão"
                  readonly
                ></v-text-field>
              </v-badge>
            </v-col>
          </v-row>
          <!-- <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-badge content="!" color="red" overlap>
              <v-text-field
                :prepend-icon="icons.mdiAccountVoice"
                v-model="pacienteInfos.indicacao"
                label="Indicação"
                :rules="mandatory"
              ></v-text-field>
            </v-badge>
          </v-col>
        </v-row> -->

          <v-divider></v-divider>

          <v-row class="d-flex justify-center">
            <v-col cols="12" md="4" class="d-flex align-center">
              <v-row>
                <v-col cols="6">
                  <small class="red--text"
                    >Peso em kg. Por exemplo: "80", sem vírgula ou ponto</small
                  >
                </v-col>
                <v-col cols="6">
                  <v-badge content="!" color="red" overlap>
                    <v-text-field
                      :prepend-icon="icons.mdiWeightKilogram"
                      v-model="pacienteInfos.pesoAtual"
                      label="peso atual (kg)"
                      readonly
                    ></v-text-field>
                  </v-badge>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" class="d-flex align-center">
              <v-row>
                <v-col cols="6">
                  <small class="red--text"
                    >Altura em cm. Por exemplo: "177", sem vírgula ou
                    ponto</small
                  >
                </v-col>
                <v-col cols="6">
                  <v-badge content="!" color="red" overlap>
                    <v-text-field
                      :prepend-icon="icons.mdiHumanMaleHeightVariant"
                      v-model="pacienteInfos.altura"
                      label="altura (cm)"
                      type="number"
                      readonly
                    ></v-text-field>
                  </v-badge>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-container fluid>
                <p>Em relação ao seu peso, você se considera...</p>
                <v-radio-group
                  v-model="pacienteInfos.pesoConsidera"
                  column
                  readonly
                >
                  <v-radio
                    label="Baixo peso"
                    value="Baixo peso"
                    color="#d7191c"
                  ></v-radio>
                  <v-radio
                    label="Peso normal"
                    value="Peso normal"
                    color="#1a9641"
                  ></v-radio>
                  <v-radio
                    label="Um pouco acima do peso"
                    value="Um pouco acima do peso"
                    color="#fdae61"
                  ></v-radio>
                  <v-radio
                    label="Muito acima do peso"
                    value="Muito acima do peso"
                    color="#d7191c"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Descreva detalhadamente todos os seus
                <strong>
                  problemas de saúde / sinais / sintomas para os quais você
                  deseja tratamento nutricional</strong
                >.
              </p>
              <v-textarea
                solo
                v-model="pacienteInfos.problemasSaude"
                label="..."
                readonly
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-row
            class="d-flex justify-center"
            v-if="pacienteInfos.gastrointestinal"
          >
            <v-container fluid>
              <p>
                Você percebe algum
                <strong>problema gastrointestinal</strong>?
              </p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.gastrointestinal"
                value="Sim"
                label="Sim"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.gastrointestinal"
                value="Não"
                label="Não"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <p class="mt-2">Comente:</p>
              <v-textarea
                solo
                v-model="pacienteInfos.gastrointestinalComment"
                label="..."
                readonly
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center" v-if="pacienteInfos.unhasCabelo">
            <v-container fluid>
              <p>
                Você percebe alguma
                <strong>alteração nas unhas, cabelo ou pele</strong>?
              </p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.unhasCabelo"
                value="Sim"
                label="Sim"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.unhasCabelo"
                value="Não"
                label="Não"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <p class="mt-2">Comente:</p>
              <v-textarea
                solo
                v-model="pacienteInfos.unhasCabeloComment"
                label="..."
                readonly
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center" v-if="pacienteInfos.ansiedade">
            <v-container fluid>
              <p>
                Você sofre de
                <strong>ansiedade, depressão, sindrome do pânico</strong> ou
                qualquer transtorno psiquiátrico?
              </p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.ansiedade"
                value="Sim"
                label="Sim"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.ansiedade"
                value="Não"
                label="Não"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <p class="mt-2">Comente:</p>
              <v-textarea
                solo
                v-model="pacienteInfos.ansiedadeComment"
                label="..."
                readonly
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center" v-if="pacienteInfos.imunidade">
            <v-container fluid>
              <p>Você percebe que sua <strong>imunidade é baixa</strong>?</p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.imunidade"
                value="Sim"
                label="Sim"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.imunidade"
                value="Não"
                label="Não"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <p class="mt-2">Comente:</p>
              <v-textarea
                solo
                v-model="pacienteInfos.imunidadeComment"
                label="..."
                readonly
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center" v-if="pacienteInfos.sono">
            <v-container fluid>
              <p>Você percebe alguma <strong>alteração no sono</strong>?</p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.sono"
                value="Sim"
                label="Sim"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.sono"
                value="Não"
                label="Não"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <p class="mt-2">Comente:</p>
              <v-textarea
                solo
                v-model="pacienteInfos.sonoComment"
                label="..."
                readonly
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center" v-if="pacienteInfos.estressada">
            <v-container fluid>
              <p>
                Você se acha uma <strong>pessoa muita estressada</strong> ou tem
                uma rotina muito estressante?
              </p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.estressada"
                value="Sim"
                label="Sim"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.estressada"
                value="Não"
                label="Não"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <p class="mt-2">Comente:</p>
              <v-textarea
                solo
                v-model="pacienteInfos.estressadaComment"
                label="..."
                readonly
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center" v-if="pacienteInfos.outraDieta">
            <v-container fluid>
              <p>
                Você já tentou fazer <strong>outras dietas</strong> com alguma
                nutricionista antes?
              </p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.outraDieta"
                value="Sim"
                label="Sim"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.outraDieta"
                value="Não"
                label="Não"
                readonly
                color="#c5d13f"
              ></v-checkbox>
              <p class="mt-2">Comente:</p>
              <v-textarea
                solo
                v-model="pacienteInfos.outraDietaComment"
                label="..."
                readonly
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-divider></v-divider>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Você faz uso de algum
                <strong>
                  suplemento, proteico, polivitamínico ou remédio
                  natural</strong
                >? Se sim, quais?
              </p>
              <v-text-field solo v-model="pacienteInfos.suplemento" label="...">
              </v-text-field>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Você toma algum <strong>medicamento</strong>? Descreva-o nome e
                as doses.
              </p>
              <v-text-field
                solo
                v-model="pacienteInfos.medicamento"
                label="..."
                readonly
              >
              </v-text-field>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Você fez <strong>exames de sangue</strong> recentemente ou tem
                <strong>histórico de alguma alteração / deficiência</strong>?
                Descreva o que for relevante para seu tratamento nutricional.
              </p>
              <v-text-field
                solo
                v-model="pacienteInfos.sangue"
                label="..."
                readonly
              >
              </v-text-field>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Você fez algum <strong>exame de imagem recentemente</strong>?
                Foi encontrado algo fora da normalidade no laudo? Comente.
              </p>
              <v-text-field
                solo
                v-model="pacienteInfos.imagem"
                label="..."
                readonly
              >
              </v-text-field>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Você percebe ou já foi diagnosticada com alguma
                <strong>alergia ou intolerância alimentar</strong>?
              </p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.alergia"
                value="Glúten"
                label="Glúten"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.alergia"
                value="Leite de vaca"
                label="Leite de vaca"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.alergia"
                value="Lactose"
                label="Lactose"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.alergia"
                value="Frutos do mar"
                label="Frutos do mar"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.alergia"
                value="Ovos"
                label="Ovos"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.alergia"
                value="Abacaxi"
                label="Abacaxi"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.alergia"
                value="Castanhas"
                label="Castanhas"
                color="#c5d13f"
                readonly
              ></v-checkbox>
              <v-text-field
                v-model="pacienteInfos.alergiaOutro"
                label="Outros?"
                readonly
              ></v-text-field>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                <strong>Que resultado no seu corpo</strong> você deseja obter
                <strong>após seguir</strong> nossas orientações nutricionais?
                Liste todas as suas expectativas quanto ao tratamento.
              </p>
              <v-textarea
                solo
                v-model="pacienteInfos.resultado"
                label="..."
                readonly
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Descreva os <strong>horários e os locais</strong> em que você
                normalmente faz <strong>suas refeições</strong>.
              </p>
              <p class="grey--text darken-3">
                Exemplo: Café da manhã às 7h - em casa, Lanche da manhã às 10h -
                na escola, Almoço às 13h - no restaurante self service, Lanche
                da tarde - não faço, Jantar às 19h - peço delivery em casa, Ceia
                às 23h - em casa, etc.
              </p>
              <v-textarea
                solo
                v-model="pacienteInfos.refeicoesHorarios"
                readonly
                label="..."
              >
              </v-textarea>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-col cols="6" md="6">
              <v-container fluid>
                <p>Você gosta de cozinhar?</p>
                <v-radio-group
                  v-model="pacienteInfos.gostaCozinhar"
                  column
                  readonly
                >
                  <v-radio
                    label="Sim, gosto"
                    value="Sim"
                    color="#c5d13f"
                  ></v-radio>
                  <v-radio
                    label="Não, não gosto"
                    value="Não"
                    color="#614021"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </v-col>
            <v-col cols="6" md="6">
              <v-container fluid>
                <p>Tem alguém que cozinha para você?</p>
                <v-radio-group
                  v-model="pacienteInfos.outroCozinhar"
                  column
                  readonly
                >
                  <v-radio
                    label="Sim, outra pessoa cozinha para mim"
                    value="Sim"
                    color="#c5d13f"
                  ></v-radio>
                  <v-radio
                    label="Não, eu mesmo cozinho"
                    value="Não"
                    color="#614021"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="3">
              <v-container fluid>
                <p>Faz atividade física?</p>
                <v-radio-group
                  v-model="pacienteInfos.fazAtividade"
                  column
                  readonly
                >
                  <v-radio
                    label="Sim, faço"
                    value="Sim"
                    color="#c5d13f"
                  ></v-radio>
                  <v-radio
                    label="Não, não faço"
                    value="Não"
                    color="#614021"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </v-col>
            <v-col cols="12" md="9">
              <v-container fluid>
                <p>
                  Quais <strong>dias e horários</strong> você faz ou planeja
                  fazer <strong>atividade física</strong>? <br />Escreva em
                  detalhes para que possamos ajustar o cardápio de acordo com o
                  tipo e horário do treino.
                </p>
                <v-textarea
                  solo
                  v-model="pacienteInfos.atividadeDetalhe"
                  readonly
                  label="..."
                >
                </v-textarea>
              </v-container>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Sobre o <strong>café da manhã</strong>: Gosta apenas de café,
                café com leite ou tolera sucos e vitaminas?
              </p>
              <v-text-field
                solo
                v-model="pacienteInfos.cafe"
                label="..."
                readonly
              >
              </v-text-field>
            </v-container>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>Gosta de comer frutas no café da manhã? Quais?</p>
              <v-text-field
                solo
                v-model="pacienteInfos.cafeFrutas"
                label="..."
                readonly
              >
              </v-text-field>
            </v-container>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Quais destes alimentos que você tolera no café da manhã ? Marque
                as opções abaixo:
              </p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.cafeTolerar"
                readonly
                value="Pão Integral"
                label="Pão Integral"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.cafeTolerar"
                readonly
                value="Tapioquinha com ovo; queijo ou coco"
                label="Tapioquinha com ovo; queijo ou coco"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.cafeTolerar"
                readonly
                value="Torrada integral"
                label="Torrada integral"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.cafeTolerar"
                readonly
                value="Preparações como panquecas proteicas"
                label="Preparações como panquecas proteicas"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.cafeTolerar"
                readonly
                value="Wrap integral"
                label="Wrap integral"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.cafeTolerar"
                readonly
                value="Batata doce"
                label="Batata doce"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.cafeTolerar"
                readonly
                value="Macaxeira cozidas"
                label="Macaxeira cozidas"
                color="#c5d13f"
              ></v-checkbox>
            </v-container>
          </v-row>

          <v-divider></v-divider>

          <v-row class="d-flex justify-center">
            <v-container fluid>
              <p>
                Nos <strong>lanches</strong>, quais das opções abaixo podem ser
                incluídas no seu cardápio?
              </p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Frutas"
                label="Frutas"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Castanhas"
                label="Castanhas"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Pasta de amendoim"
                label="Pasta de amendoim"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Iogurte natural"
                label="Iogurte natural"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Sementes como chia / linhaça"
                label="Sementes como chia / linhaça"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Pão integral"
                label="Pão integral"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Patê natural de frango ou atum"
                label="Patê natural de frango ou atum"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Bolos ou bolachas integrais"
                label="Bolos ou bolachas integrais"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Torrada integral"
                label="Torrada integral"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                readonly
                v-model="pacienteInfos.lanches"
                value="Ovos mexidos ou cozidos"
                label="Ovos mexidos ou cozidos"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.lanches"
                readonly
                value="Tapioquinha"
                label="Tapioquinha"
                color="#c5d13f"
              ></v-checkbox>
            </v-container>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="6">
              <v-container fluid>
                <p>Que <strong>frutas</strong> você mais gosta?</p>
                <v-text-field
                  v-model="pacienteInfos.frutasGostar"
                  label="..."
                  readonly
                >
                </v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" md="6">
              <v-container fluid>
                <p>
                  Que <strong>frutas</strong> você <strong>não</strong> tolera?
                </p>
                <v-text-field
                  v-model="pacienteInfos.frutasNaoTolerar"
                  readonly
                  label="..."
                >
                </v-text-field>
              </v-container>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            <v-col cols="12">
              <v-row class="d-flex justify-center">
                <p>Sobre o <strong>almoço</strong>:</p>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-container fluid>
                <p>
                  Gosta do tradicional
                  <strong>arroz/ farinha/ farofa/ feijão</strong>?
                </p>
                <v-text-field
                  v-model="pacienteInfos.almocoFeijao"
                  label="..."
                  readonly
                >
                </v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" md="6">
              <v-container fluid>
                <p>
                  Gosta de <strong>saladas</strong> cruas, refogadas e cozidas?
                </p>
                <v-text-field
                  v-model="pacienteInfos.almocoSalada"
                  label="..."
                  readonly
                >
                </v-text-field>
              </v-container>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="6">
              <v-container fluid>
                <p>
                  Tem <strong>aversão</strong> por algum
                  <strong>vegetal</strong>? Quais?
                </p>
                <v-text-field
                  v-model="pacienteInfos.aversaoVegetal"
                  readonly
                  label="..."
                >
                </v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" md="6">
              <v-container fluid>
                <p>
                  Gosta de outras leguminosas como grão de bico, lentilha e/ou
                  ervilha?
                </p>
                <v-text-field
                  v-model="pacienteInfos.leguminosas"
                  label="..."
                  readonly
                >
                </v-text-field>
              </v-container>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="6">
              <v-container fluid>
                <p>
                  Costuma comer <strong>purês, quinoa e/ou raízes</strong> como
                  batata doce e macaxeira?
                </p>
                <v-text-field
                  solo
                  v-model="pacienteInfos.pures"
                  label="..."
                  readonly
                >
                </v-text-field>
              </v-container>
            </v-col>
            <v-col cols="12" md="6">
              <v-container fluid>
                <p>
                  Gosta de <strong>massas</strong> tipo macarronada, lasanha
                  etc?
                </p>
                <v-text-field
                  solo
                  v-model="pacienteInfos.massas"
                  label="..."
                  readonly
                >
                </v-text-field>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-container fluid>
              <p>Gosta de frango, carne vermelha e peixe?</p>
              <v-text-field
                v-model="pacienteInfos.frangoCarnePeixe"
                readonly
                label="..."
              >
              </v-text-field>
            </v-container>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            <v-col cols="12">
              <v-row class="d-flex justify-center">
                <p>Sobre o <strong>jantar</strong>:</p>
              </v-row>
            </v-col>
            <v-container fluid>
              <p>
                Gosta de comer <strong>o mesmo do almoço ou lanches</strong>? Ou
                os dois?
              </p>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.jantar"
                value="O mesmo do almoço"
                readonly
                label="O mesmo do almoço"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.jantar"
                readonly
                value="Somente lanche"
                label="Somente lanche"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.jantar"
                readonly
                value="Gosto de sopas"
                label="Gosto de sopas"
                color="#c5d13f"
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="pacienteInfos.jantar"
                readonly
                value="Não como nada na hora do jantar"
                label="Não como nada na hora do jantar"
                color="#c5d13f"
              ></v-checkbox>
            </v-container>
          </v-row>
          <v-row>
            <v-container fluid>
              <p>
                Sobre a <strong>ceia</strong>: o que normalmente você come antes
                de dormir?
              </p>
              <v-text-field v-model="pacienteInfos.ceia" label="..." readonly>
              </v-text-field>
            </v-container>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="questPaciente = false"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSalvar" max-width="640" multiple>
      <v-card color="white">
        <v-card-title>Salvar Dieta</v-card-title>
        <v-card-text>
          <!-- <v-row v-if="pacienteChosen.data.nome != ''" class="d-flex align-center">
            <v-checkbox
              :label="`No histórico do paciente ${pacienteChosen.data.nome}`"
              v-model="salvarDietaEmPaciente"
              color="#614021"
            ></v-checkbox>
          </v-row> -->
          <v-row>
            <v-checkbox
              label="No histórico do paciente:"
              color="#614021"
              v-model="salvarDietaEmPaciente"
            ></v-checkbox>
            <v-text-field
              dense
              solo
              v-model="nomeDietaPaciente"
              label="Titulo da dieta (visível pelo paciente)"
              class="align-self-center mx-2"
              required
              :rules="mandatory"
            ></v-text-field>
            <v-autocomplete
              label="Nome do Paciente"
              single-line
              solo
              dense
              clearable
              v-model="pacienteDocID"
              :items="pacientes"
              class="align-self-center mx-2"
              @change="pacienteSelected(pacienteDocID)"
              no-data-text="..."
            ></v-autocomplete>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-checkbox
              color="#614021"
              v-model="salvarDietaEmSalvas"
              label='Nas minhas "Dietas salvas"'
            ></v-checkbox>
            <v-text-field
              dense
              solo
              v-model="nomeDieta"
              label="Titulo da dieta (nas dietas salvas)"
              class="align-self-center mx-2 mt-4"
              required
              :rules="mandatory"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#c5d13f"
                v-bind="attrs"
                v-on="on"
                class="mx-4"
                @click="salvarDietaAsNew"
              >
                <v-icon color="#614021" left>{{
                  icons.mdiContentSaveAll
                }}</v-icon>
                Salvar como Novo
              </v-btn>
            </template>
            <span>Salvar como Dieta Nova</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#c5d13f"
                v-bind="attrs"
                v-on="on"
                @click="salvarDietaOverride"
              >
                <v-icon color="#614021" left>{{
                  icons.mdiContentSaveEdit
                }}</v-icon>
                Sobre-escrever
              </v-btn>
            </template>
            <span>Salvar alterações na mesma dieta</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-btn @click="dialogSalvar = false" text color="grey">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn v-if="pacienteDocID !== ''" @click="gerarLembrete">
            <v-icon color="#00ff00">mdi-whatsapp</v-icon>
            Gerar lembrete de aviso por WhatsApp</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="gerarLembreteDialog" max-width="600">
      <v-card v-if="!lembreteGerado">
        <v-card-title class="d-flex justify-center">
          Gerando lembrete...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Lembrete de aviso gerado!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="gerarLembreteDialog = false" color="#c5d13f"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="d-flex justify-center mb-4" v-if="loadingDietasSalvas">
      <v-card style="width: 100%">
        <v-card-title class="d-flex justify-center">
          carregando dietas salvas...
        </v-card-title>
        <v-progress-linear
          indeterminate
          color="#c5d13f"
          style="width: 100%"
        ></v-progress-linear>
      </v-card>
    </v-row>
    <v-expansion-panels class="mb-4" v-else>
      <v-expansion-panel v-model="panel0">
        <v-expansion-panel-header class="text-h6">
          Dietas salvas
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-virtual-scroll
              height="500"
              item-height="42"
              :items="dietasSalvas"
            >
              <template v-slot:default="{ item }">
                <v-list-item>
                  <v-btn
                    size="small"
                    dense
                    :color="
                      editandoId == item.dietaDocId
                        ? '#c5d13f'
                        : 'grey lighten-2'
                    "
                    @click="setDietaEditar(item.dietaDocId)"
                    class="mr-2 black--text"
                  >
                    Editar
                  </v-btn>

                  <v-list-item-title
                    >{{ item.titulo }}, {{ roundNr(item.kcal) }} kcal (
                    {{ item.createdAt.toDate().getDate() }}/{{
                      item.createdAt.toDate().getMonth() + 1
                    }}/{{ item.createdAt.toDate().getYear() + 1900 }}
                    {{ item.createdAt.toDate().getHours() }}:{{
                      stringify(item.createdAt.toDate().getMinutes())
                    }}
                    )</v-list-item-title
                  >

                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        @click="setDietaDeletar(item.dietaDocId)"
                      >
                        <v-icon color="red">{{
                          icons.mdiTrashCanOutline
                        }}</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Remover "{{ item.titulo }}" da lista das dietas
                      salvas</span
                    >
                  </v-tooltip>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card>

          <v-card v-if="btnAll" flat>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                @click="getDietasSalvasAll"
                color="#c5d13f"
                class="black--text"
              >
                Carregar todas as dietas salvas
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card
      class="d-inline-block mr-3"
      width="100%"
      elevation="3"
      color="grey lighten-2"
    >
      <v-container>
        <v-card-actions>
          <v-dialog
            v-model="dialogVisualizar"
            max-width="80%"
            height="80%"
            multiple
          >
            <v-card>
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel
                  v-for="refeicao in dietaRefeicoes"
                  :key="refeicao.nomeRefeicao"
                >
                  <v-expansion-panel-header>
                    <v-row class="text-h6">
                      {{ refeicao.nomeRefeicao }},
                      {{ refeicao.horarioRefeicao }}
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels
                      v-model="panelSubs"
                      multiple
                      v-show="refeicao.alimentosList[0].gramas > 0"
                    >
                      <v-expansion-panel
                        v-for="alimento in refeicao.alimentosList"
                        :key="alimento.nome"
                      >
                        <v-expansion-panel-header>
                          <p
                            v-if="alimento.quantidade < 2"
                            class="text-p black--text"
                          >
                            {{ alimento.quantidade }} {{ alimento.medida }} de
                            {{ alimento.nome }} ({{ alimento.gramas }}g total)
                          </p>
                          <p
                            v-else-if="alimento.quantidade > 1"
                            class="text-p black--text"
                          >
                            {{ alimento.quantidade }} ({{ alimento.medida }}) de
                            {{ alimento.nome }} ({{ alimento.gramas }}g total)
                          </p>
                          <p v-else class="text-p black--text">
                            {{ alimento.gramas }}g de {{ alimento.nome }}
                          </p>
                          <template v-slot:actions>
                            <v-icon
                              color="black"
                              v-if="alimento.toSubstitute != undefined"
                              @click="log(alimento.toSubstitute)"
                            >
                              {{ icons.mdiAutorenew }}
                            </v-icon>
                            <v-icon v-else> </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="ml-6">
                          <v-row
                            class="mt-1"
                            v-show="alimento.toSubstitute != undefined"
                          >
                            <strong> Possíveis substituições </strong>
                          </v-row>
                          <v-simple-table
                            v-show="alimento.toSubstitute != undefined"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Alimento</th>
                                  <th class="text-left">Quantidade</th>
                                  <th class="text-left">Medida caseira</th>
                                  <th class="text-left">Gramas totais</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(sub,
                                  subIndex) in alimento.toSubstitute"
                                  :key="subIndex"
                                >
                                  <td>{{ sub.nome }}</td>
                                  <td>{{ sub.qtdSub }}</td>
                                  <td>{{ sub.medida_caseira_padrao }}</td>
                                  <td>{{ sub.gramas }}g</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels
                      v-model="panelReceitas"
                      multiple
                      v-show="refeicao.receitasList[0].porcoes > 0"
                    >
                      <v-expansion-panel
                        v-for="receita in refeicao.receitasList"
                        :key="receita.titulo"
                      >
                        <v-expansion-panel-header class="text-p black--text">
                          {{ receita.porcoes }} {{ porcao(receita.porcoes) }} de
                          "{{ receita.titulo }}"
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="ml-6">
                          <v-row> <strong> Modo de Preparo: </strong> </v-row>
                          <v-row> {{ receita.modoPreparo }} </v-row>
                          <v-row class="mt-1">
                            <strong> Ingredientes: </strong>
                          </v-row>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Alimento</th>
                                  <th class="text-left">Quantidade</th>
                                  <th class="text-left">Medida caseira</th>
                                  <th class="text-left">Gramas totais</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="alimentoR in receita.alimentosList"
                                  :key="alimentoR.nome"
                                >
                                  <td>{{ alimentoR.nome }}</td>
                                  <td>{{ alimentoR.quantidade }}</td>
                                  <td>{{ alimentoR.medida }}</td>
                                  <td>{{ alimentoR.gramas }}g</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-card class="pr-2" min-height="50px" elevation="1">
                      <v-card-text
                        v-show="refeicao.observacao"
                        class="text-p black--text ml-2"
                      >
                        <strong>Observações: </strong> {{ refeicao.observacao }}
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDeletar" max-width="640" multiple>
            <v-card>
              <v-card-title>
                Deseja deletar a dieta "{{ dietaTitulo }}" ?</v-card-title
              >
              <v-card-text>
                Ao concordar sairá da lista das suas dietas salvas de forma
                irrevogável
              </v-card-text>
              <v-card-actions>
                <v-btn @click="dialogDeletar = false" text color="#614021"
                  >Fechar</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn @click="deletarDieta" color="red" class="white--text">
                  Deletar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                @click="dialogVisualizar = true"
              >
                <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
              </v-btn>
            </template>
            <span>Visualizar Dieta</span>
          </v-tooltip>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2">
                <v-icon color="#614021">{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir Dieta</span>
          </v-tooltip> -->

          <v-tooltip bottom color="red">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                @click="dialogDeletar = true"
              >
                <v-icon color="red">{{ icons.mdiTrashCanOutline }}</v-icon>
              </v-btn>
            </template>
            <span>Deletar Dieta</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#c5d13f"
                v-bind="attrs"
                v-on="on"
                @click="createDocWithJSPDF"
              >
                <v-icon color="#614021">{{ icons.mdiDownload }}</v-icon>
              </v-btn>
            </template>
            <span>Baixar Dieta</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#c5d13f"
                v-bind="attrs"
                v-on="on"
                class="mx-4"
                @click="dialogSalvar = true"
              >
                <v-icon color="#614021">{{ icons.mdiContentSave }}</v-icon>
              </v-btn>
            </template>
            <span>Salvar Dieta</span>
          </v-tooltip>
        </v-card-actions>

        <v-divider></v-divider>

        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  elevation="0"
                  color="grey lighten-2"
                >
                  <v-autocomplete
                    label="Nome do Paciente"
                    single-line
                    solo
                    clearable
                    class="mt-2 mx-3"
                    v-model="pacienteDocID"
                    :items="pacientes"
                    @change="pacienteSelected(pacienteDocID)"
                    no-data-text="..."
                  ></v-autocomplete>
                </v-card>
              </template>
              <span
                >Selecione o nome de um paciente cadastrado para anexar a dieta
              </span>
            </v-tooltip>
          </v-col>
          <v-col
            class="d-flex align-center green--text"
            cols="4"
            md="2"
            v-if="
              taxaMetabolicaPaciente !== '' &&
                taxaMetabolicaPaciente !== undefined &&
                taxaMetabolicaPaciente !== null &&
                taxaMetabolicaPaciente !== 0
            "
          >
            TMB: {{ taxaMetabolicaPaciente }} kcal
            <!-- <v-btn class="mt-3">Ver histórico de atendimento</v-btn> -->
          </v-col>
          <v-col class="d-flex align-center red--text" cols="4" md="2" v-else>
            Escolher paciente!
            <!-- <v-btn class="mt-3">Ver histórico de atendimento</v-btn> -->
          </v-col>
          <v-col
            class="d-flex align-center green--text"
            cols="4"
            md="2"
            v-if="
              pesoAtualPaciente !== '' &&
                pesoAtualPaciente !== undefined &&
                pesoAtualPaciente !== null &&
                pesoAtualPaciente !== 0
            "
          >
            Peso: {{ pesoAtualPaciente }} kg
            <!-- <v-btn class="mt-3">Ver histórico de atendimento</v-btn> -->
          </v-col>
          <v-col class="d-flex align-center red--text" cols="4" md="2" v-else>
            Escolher paciente!
            <!-- <v-btn class="mt-3">Ver histórico de atendimento</v-btn> -->
          </v-col>
          <v-col class="d-flex align-center red--text" cols="4" md="2">
            <v-btn
              @click="openQuestPaciente(pacienteDocID)"
              small
              :disabled="
                pacienteDocID == '' ||
                  pacienteDocID == undefined ||
                  pacienteDocID == null
              "
              >Ver Questionário</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center black--text text-h6 mb-2">
          {{ dietaTitulo }}
        </v-row>
        <v-row class="d-flex justify-center">
          <v-chip color="#c5d13f" class="d-flex align-center">
            <v-row class="px-2 black--text">
              <p class="px-2 mt-4">
                KCAL: <strong>{{ roundNr(dietaStatus.KCAL) }}</strong>
              </p>
              <p class="px-2 mt-4">
                CHO: <strong>{{ roundNr(dietaStatus.CHO) }}</strong>
              </p>
              <p style="color: red" class="mt-4">
                {{
                  toPerc(dietaStatus.CHO, dietaStatus.PTN, dietaStatus.LIP).cho
                }}%
              </p>
              <p style="color: red"></p>
              <p class="px-2 mt-4">
                PTN: <strong>{{ roundNr(dietaStatus.PTN) }}</strong>
              </p>
              <p style="color: red" class="mt-4">
                {{
                  toPerc(dietaStatus.CHO, dietaStatus.PTN, dietaStatus.LIP).ptn
                }}%
              </p>
              <p style="color: red"></p>
              <p class="px-2 mt-4">
                LIP: <strong>{{ roundNr(dietaStatus.LIP) }}</strong>
              </p>
              <p style="color: red" class="mt-4">
                {{
                  toPerc(dietaStatus.CHO, dietaStatus.PTN, dietaStatus.LIP).lip
                }}%
              </p>
              <p style="color: red"></p>
              <p class="px-2 mt-4">
                COLEST: <strong>{{ roundNr(dietaStatus.COL) }}</strong>
              </p>
              <p class="px-2 mt-4">
                Na: <strong>{{ roundNr(dietaStatus.SOD) }}</strong>
              </p>
              <p class="px-2 mt-4">
                FIB: <strong>{{ roundNr(dietaStatus.FIB) }}</strong>
              </p>
            </v-row>
          </v-chip>
          <v-dialog v-model="dialog" width="90%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="#c5d13f"
                class="mx-2"
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-icon color="#614021" v-bind="attrs" v-on="on">{{
                      icons.mdiDotsHorizontal
                    }}</v-icon>
                  </template>
                  <span>Mais informações nutricionais</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="h6"
                  >Informações nutricionais detalhadas da dieta</span
                >
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="nutrient in allNutrients"
                          :key="nutrient.codigo"
                        >
                          <td>{{ nutrient.nome }}:</td>
                          <td>
                            <strong>{{
                              roundNr(dietaStatus[nutrient.codigo])
                            }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>
              </v-card-text>
              <v-card-actions class="d-flex justify-center">
                <v-btn color="#c5d13f" text @click="dialog = false">
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-card>

    <DietaFormEdit></DietaFormEdit>

    <template>
      <v-snackbar
        timeout="-1"
        elevation="0"
        bottom
        right
        multi-line
        v-model="snack"
        color="rgba(0, 0, 0, 0)"
        max-width="90%"
      >
        <div>
          <v-row class="d-flex justify-center align-center">
            <v-card color="rgba(0, 0, 0, 0)" elevation="0">
              <v-row class="d-flex justify-center">
                <v-chip color="#c5d13f" class="d-flex align-center">
                  <v-row class="px-2 black--text">
                    <p class="px-2 mt-4">
                      KCAL: <strong>{{ roundNr(dietaStatus.KCAL) }}</strong>
                    </p>
                    <p class="px-2 mt-4">
                      CHO: <strong>{{ roundNr(dietaStatus.CHO) }}</strong>
                    </p>
                    <p style="color: red" class="mt-4">
                      {{
                        toPerc(
                          dietaStatus.CHO,
                          dietaStatus.PTN,
                          dietaStatus.LIP
                        ).cho
                      }}%
                    </p>
                    <p style="color: red"></p>
                    <p class="px-2 mt-4">
                      PTN: <strong>{{ roundNr(dietaStatus.PTN) }}</strong>
                    </p>
                    <p style="color: red" class="mt-4">
                      {{
                        toPerc(
                          dietaStatus.CHO,
                          dietaStatus.PTN,
                          dietaStatus.LIP
                        ).ptn
                      }}%
                    </p>
                    <p style="color: red"></p>
                    <p class="px-2 mt-4">
                      LIP: <strong>{{ roundNr(dietaStatus.LIP) }}</strong>
                    </p>
                    <p style="color: red" class="mt-4">
                      {{
                        toPerc(
                          dietaStatus.CHO,
                          dietaStatus.PTN,
                          dietaStatus.LIP
                        ).lip
                      }}%
                    </p>
                    <p style="color: red"></p>
                    <p class="px-2 mt-4">
                      COLEST: <strong>{{ roundNr(dietaStatus.COL) }}</strong>
                    </p>
                    <p class="px-2 mt-4">
                      Na: <strong>{{ roundNr(dietaStatus.SOD) }}</strong>
                    </p>
                    <p class="px-2 mt-4">
                      FIB: <strong>{{ roundNr(dietaStatus.FIB) }}</strong>
                    </p>
                  </v-row>
                </v-chip>
              </v-row>
            </v-card>
            <v-btn
              fab
              color="#c5d13f"
              class="mx-2 my-1"
              x-small
              @click="dialog = true"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }"
                  ><v-icon color="#614021" v-bind="attrs" v-on="on">{{
                    icons.mdiDotsHorizontal
                  }}</v-icon>
                </template>
                <span>Mais informações nutricionais</span>
              </v-tooltip>
            </v-btn>
          </v-row>
        </div>
      </v-snackbar>
    </template>
  </div>
</template>

<script>
import firebase from "firebase";
import {
  mdiCalendar,
  mdiContentSave,
  mdiPrinter,
  mdiEye,
  mdiEmailSendOutline,
  mdiDownload,
  mdiMagnify,
  mdiDotsHorizontal,
  mdiInformationOutline,
  mdiClose,
  mdiAutorenew,
  mdiTrashCanOutline,
  mdiContentSaveAll,
  mdiContentSaveEdit,
} from "@mdi/js";

// const docx = require("docx");
// import { saveAs } from "file-saver";

import jspdf from "jspdf";
import fontCode from "../components/Montserrat-Medium-normal";
import "jspdf-autotable";
// import imagesloaded from 'imagesloaded'

// components imports
import DietaFormEdit from "../components/home/dieta/DietaFormEdit";

export default {
  components: { DietaFormEdit },
  data: function() {
    return {
      taxaMetabolicaPaciente: "",
      pesoAtualPaciente: "",
      // dietasSalvas: [
      //   {
      //     autorAssinatura: "",
      //     autorCrn: "",
      //     autorEmail: "",
      //     autorNome: "",
      //     autorPapel: "",
      //     createdAt: "",
      //     dietaDocId: "",
      //     kcal: 0,
      //     titulo: "",
      //   },
      // ],
      editandoId: null,
      panel0: [0],
      snack: true,
      table: false,
      dialog: false,
      nomeDieta: "",
      mandatory: [
        (v) => !!v || "Campo obrigatório",
        // v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      dialogVisualizar: false,
      dialogDeletar: false,
      dialogSalvar: false,
      salvarDietaEmPaciente: false,
      salvarDietaEmSalvas: true,
      progressDialog: false,
      saved: false,
      nomeDietaPaciente: "",
      panel: [],
      panelReceitas: [],
      panelSubs: [],
      snackbar: true,
      allNutrients: [
        { codigo: "KCAL", nome: "Calorias" }, //NaN
        { codigo: "PTN", nome: "Proteina" },
        { codigo: "LIP", nome: "Lipideos" },
        { codigo: "COL", nome: "Colesterol" },
        { codigo: "CHO", nome: "Carboidrato" },
        { codigo: "FIB", nome: "Fibra alimentar" },
        { codigo: "fibra_soluvel", nome: "Fibra solúvel" },
        { codigo: "fibra_insoluvel", nome: "Fibra insolúvel" },
        { codigo: "cinzas", nome: "Cinzas" },
        { codigo: "calcio", nome: "Cálcio" },
        { codigo: "magnesio", nome: "Magnésio" },
        { codigo: "manganes", nome: "Manganés" },
        { codigo: "fosforo", nome: "Fósforo" },
        { codigo: "ferro", nome: "Ferro" },
        { codigo: "SOD", nome: "Sódio" },
        { codigo: "potassio", nome: "Potassio" },
        { codigo: "cobre", nome: "Cobre" },
        { codigo: "zinco", nome: "Zinco" },
        { codigo: "retinol", nome: "Retinol" },
        { codigo: "re", nome: "RE" },
        { codigo: "era", nome: "ERA" },
        { codigo: "iodo", nome: "Iodo" },
        { codigo: "selenio", nome: "Selénio" },
        { codigo: "acido_folico", nome: "Ácido fólico" },
        { codigo: "acido_pantotenico", nome: "Ácido pantoténico" },
        { codigo: "tiamina", nome: "Tiamina" },
        { codigo: "riboflavina", nome: "Riboflavina" },
        { codigo: "piridoxina", nome: "Piridoxina" },
        { codigo: "niacina", nome: "Niacina" },
        { codigo: "vitamina_c", nome: "Vitamina C" },
        { codigo: "vitamina_d", nome: "Vitamina D" },
        { codigo: "vitamina_e", nome: "Vitamina E" },
        { codigo: "vitamina_b12", nome: "Vitamina B12" },
        { codigo: "saturados", nome: "Saturados" },
        { codigo: "monoinsaturados", nome: "Mono-insaturados" },
        { codigo: "poliinsaturados", nome: "Poli-insaturados" },
        { codigo: "gordura_trans", nome: "Gordura trans" },
        { codigo: "triptofano", nome: "Triptofano" },
        { codigo: "treonina", nome: "Treonina" },
        { codigo: "isoleucina", nome: "Isoleucina" },
        { codigo: "leucina", nome: "Leucina" },
        { codigo: "lisina", nome: "Lisina" },
        { codigo: "metionina", nome: "Metionina" },
        { codigo: "cistina", nome: "Cistina" },
        { codigo: "fenilalanina", nome: "Fenilalanina" },
        { codigo: "tirosina", nome: "Tirosina" },
        { codigo: "valina", nome: "Valina" },
        { codigo: "arginina", nome: "Arginina" },
        { codigo: "histidina", nome: "Histidina" },
        { codigo: "alanina", nome: "Alanina" },
        { codigo: "acido_aspartico", nome: "Ácido aspartico" },
        { codigo: "acido_glutamico", nome: "Ácido glutámico" },
        { codigo: "glicina", nome: "Glícina" },
        { codigo: "prolina", nome: "Prolina" },
        { codigo: "serina", nome: "Serina" },
        { codigo: "umidade", nome: "Umidade" },
      ],
      pacienteDocID: "",
      questPaciente: false,
      estadoCivilItems: [
        "-",
        "Solteira/Solteiro",
        "Casada/Casado",
        "Viúva/Viúvo",
        "Divorciada/Divorciado",
      ],
      pacienteInfos: {
        endereco: "",
        cidade: "",
        estado: "",
        profissao: "",
        estadoCivil: null,
        filhos: "",
        idadeFilhos: "",
        indicacao: "",
        pesoAtual: "",
        altura: "",
        pesoConsidera: "",
        problemasSaude: "",
        suplemento: "",
        medicamento: "",
        sangue: "",
        imagem: "",
        alergia: [],
        alergiaOutro: "",
        resultado: "",
        refeicoesHorarios: "",
        gostaCozinhar: "Sim",
        outroCozinhar: "Sim",
        fazAtividade: "Sim",
        atividadeDetalhe: "",
        cafe: "",
        cafeFrutas: "",
        cafeTolerar: [],
        lanches: [],
        frutasGostar: "",
        frutasNaoTolerar: "",
        almocoFeijao: "",
        almocoSalada: "",
        aversaoVegetal: "",
        leguminosas: "",
        pures: "",
        massas: "",
        frangoCarnePeixe: "",
        jantar: [],
        ceia: "",
        gastrointestinal: "Sim",
        unhasCabelo: "Sim",
        ansiedade: "Sim",
        imunidade: "Sim",
        sono: "Sim",
        estrassada: "Sim",
        gastrointestinalComment: "",
        unhasCabeloComment: "",
        ansiedadeComment: "",
        imunidadeComment: "",
        sonoComment: "",
        estrassadaComment: "",
      },
      whatsappLink: "",
      lembreteGerado: false,
      gerarLembreteDialog: false,
      pacienteChosen: { data: { nome: "" } },
      icons: {
        mdiCalendar,
        mdiContentSave,
        mdiPrinter,
        mdiEye,
        mdiEmailSendOutline,
        mdiDownload,
        mdiMagnify,
        mdiDotsHorizontal,
        mdiInformationOutline,
        mdiClose,
        mdiAutorenew,
        mdiTrashCanOutline,
        mdiContentSaveAll,
        mdiContentSaveEdit,
      },
      preloadLogo: new Image(),
      preloadSignature: new Image(),
      loadingDietasSalvas: false,
      btnAll: true,
    };
  },
  computed: {
    persistPaciente() {
      return this.$store.state.firestore.persistPaciente;
    },
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    dietaStatus() {
      return this.$store.state.firestore.dietaStatus;
    },
    dietaRefeicoes() {
      return this.$store.state.firestore.dietaRefeicoes;
    },
    dietaDocId() {
      return this.$store.state.firestore.dietaDocId;
    },
    dietaTitulo() {
      return this.$store.state.firestore.dietaTitulo;
    },
    alimentos() {
      return this.$store.state.firestore.alimentos;
    },
    dietasSalvas() {
      return this.$store.state.firestore.dietasSalvas;
    },
  },
  methods: {
    async getDietasSalvas() {
      this.loadingDietasSalvas = true;
      console.log("this.dietasSalvas.length", this.dietasSalvas.length);
      if (this.dietasSalvas.length == 0) {
        this.$store.dispatch("firestore/getDietasSalvas");
        this.loadingDietasSalvas = false;
      } else {
        this.loadingDietasSalvas = false;
      }
    },
    async forceGetDietasSalvas() {
      this.loadingDietasSalvas = true;
        this.$store.dispatch("firestore/getDietasSalvas");
        this.loadingDietasSalvas = false;
      },
    async getDietasSalvasAll() {
      this.loadingDietasSalvas = true;
      this.$store.dispatch("firestore/getDietasSalvasAll");
      this.loadingDietasSalvas = false;
      this.btnAll = false;
    },
    roundNr(nr) {
      if (nr) {
        var nrO = parseFloat(nr);
        var roundedNr = Math.round(nrO);
        return roundedNr;
      } else {
        return 0;
      }
    },
    async gerarLembrete() {
      this.gerarLembreteDialog = true;
      this.lembreteGerado = false;
      var d = new Date();

      var millis_1 = d.getTime();

      // var millis_1 = Math.ceil(millis / 86400000) * 86400000 + 21600000;

      var ref = firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc();

      var id = ref.id;

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(id)
        .set({
          tipo: "aviso-whatsapp",
          done: false,
          status: "notDueYet",
          dueDate: millis_1,
          patient: this.pacienteName,
          patientId: this.pacienteDocID,
          tipoPrescr: "Um novo cardápio foi prescrito!",
          link: this.whatsappLink,
          id: id,
        });

      this.lembreteGerado = true;
      this.progressDialog = false;
    },
    async openQuestPaciente(pId) {
      if (pId !== "" && pId !== null && pId !== undefined) {
        var pQuest = await firebase
          .firestore()
          .collection("users")
          .doc(pId)
          .get()
          .then(function(doc) {
            return doc.data().pacienteInfos;
          });
        this.pacienteInfos = pQuest;
        this.questPaciente = true;
      }
    },
    async getTaxaMetabolicaPaciente(pId) {
      if (pId !== "" && pId !== null && pId !== undefined) {
        var pacienteDoc = await firebase
          .firestore()
          .collection("users")
          .doc(pId)
          .get()
          .then(function(doc) {
            return doc.data();
          });

        var whatsapp = pacienteDoc.whatsapp;
        this.pacienteName = pacienteDoc.nome;

        if (whatsapp !== "" && whatsapp !== undefined) {
          var removedStr = whatsapp.replace(/[^a-zA-Z0-9]/g, "");
          // console.log(removedStr)
          var cleanWhatsapp = "55" + removedStr;
          var splitList = pId.split("@");
          var email1 = splitList[0];
          var email2 = splitList[1];
          this.whatsappLink = `https://wa.me/${cleanWhatsapp}?text=Ol%C3%A1%2C+cliente+do+Clube+da+Nutricionista%21%0D%0AUm+novo+card%C3%A1pio+foi+prescrito+para+voc%C3%AA.+Para+visualiz%C3%A1-lo+ou+baix%C3%A1-lo%2C+acesse+a+%22%C3%A1rea+do+paciente%22+pelo+link+nutriapp.app+%28email%3A+${email1}%40${email2}%2C+senha%3A+nutri123%29+e+v%C3%A1+na+aba+de+%22Prescri%C3%A7%C3%B5es%22.+%0D%0AQualquer+d%C3%BAvida+sobre+o+card%C3%A1pio%2C+envie+uma+mensagem+pelo+sistema+na+%C3%A1rea+%22Fale+com+a+Nutri%22.%0D%0A-----+Mensagem+autom%C3%A1tica+-----`;
          // console.log(this.whatsappLink);
        } else {
          this.whatsappLink =
            "https://wa.me/?text=Ol%C3%A1%2C+cliente+do+Clube+da+Nutricionista%21%0D%0AUm+novo+card%C3%A1pio+foi+prescrito+para+voc%C3%AA.+Para+visualiz%C3%A1-lo+ou+baix%C3%A1-lo%2C+acesse+a+%22%C3%A1rea+do+paciente%22+pelo+link+nutriapp.app+e+v%C3%A1+na+aba+de+%22Prescri%C3%A7%C3%B5es%22.+%0D%0AQualquer+d%C3%BAvida+sobre+o+card%C3%A1pio%2C+envie+uma+mensagem+pelo+sistema+na+%C3%A1rea+%22Fale+com+a+Nutri%22.%0D%0A-----+Mensagem+autom%C3%A1tica+-----";
        }

        var atendimentos = await firebase
          .firestore()
          .collection("users")
          .doc(pId)
          .collection("atendimentos")
          .get()
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return doc.data();
            })
          );

        var atendObjs = atendimentos.map(function(atend) {
          return {
            tmb: atend.compos.taxaMetabolica,
            peso: atend.compos.pesoAtual,
          };
        });
        var taxas = atendObjs.map(function(obj) {
          return obj.tmb;
        });
        var pesos = atendObjs.map(function(obj) {
          return obj.peso;
        });
        taxas = taxas.filter(function(nr) {
          return nr !== 0 && nr !== null && nr !== 1000;
        });
        pesos = pesos.filter(function(nr) {
          return nr !== 0 && nr !== null && nr !== 1000;
        });
        var lastTaxa = taxas[taxas.length - 1];
        var lastPeso = pesos[pesos.length - 1];

        this.taxaMetabolicaPaciente = lastTaxa;
        this.pesoAtualPaciente = lastPeso;
        // console.log(this.taxaMetabolicaPaciente);
      } else {
        this.taxaMetabolicaPaciente = "Nenhuma TMB registrada!";
        this.pesoAtualPaciente = "Peso atual não registrado!";
      }
    },
    toPerc(cho, ptn, lip) {
      var choFator = cho * 4;
      var ptnFator = ptn * 4;
      var lipFator = lip * 9;
      var cemPorcento = choFator + ptnFator + lipFator;
      var choPerc = (choFator / cemPorcento) * 100;
      var ptnPerc = (ptnFator / cemPorcento) * 100;
      var lipPerc = (lipFator / cemPorcento) * 100;

      var roundNr = function(nr) {
        if (nr !== undefined) {
          var nrO = parseFloat(nr);
          var roundedNr = Math.round(nrO);
          return roundedNr;
        } else {
          return 0;
        }
      };
      if (cemPorcento > 0) {
        return {
          cho: roundNr(choPerc),
          ptn: roundNr(ptnPerc),
          lip: roundNr(lipPerc),
        };
      } else {
        return {
          cho: 0,
          ptn: 0,
          lip: 0,
        };
      }
    },
    porcao(nr) {
      if (nr > 1) {
        return "porções";
      } else {
        return "porção";
      }
    },
    log(arg) {
      console.log(arg);
    },
    stringify(min) {
      if (min < 10) {
        return "0" + min.toString();
      } else {
        return min;
      }
    },
    // getAlimentosWithSameGroup(
    //   refeicoes,
    //   refeicaoIndex,
    //   alimentoIndex,
    //   alimentoInfo
    // ) {
    //   console.log(alimentoInfo.nome);
    //   var filterNome = function(alimento) {
    //     if (alimento !== undefined) {
    //       if (alimento.data.nome == alimentoInfo.nome) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     } else {
    //       return false;
    //     }
    //   };

    //   var alimentosNome = this.alimentos.filter(filterNome);
    //   console.log(alimentosNome[0]);
    //   var alimentoOriginal = alimentosNome[0];
    //   var filter = function(alimento) {
    //     if (alimento !== undefined && alimentoOriginal !== undefined) {
    //       if (
    //         alimento.data.grupo_1 == alimentoOriginal.data.grupo_1 &&
    //         // && calculateDiference(alimento.data.kcal, alimentoInfo.data.kcal)
    //         alimento.data.nome != alimentoOriginal.data.nome
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     } else {
    //       return false;
    //     }
    //   };
    //   var roundNr = function(nr) {
    //     if (nr !== undefined) {
    //       var nrO = parseFloat(nr);
    //       var roundedNr = Math.round(nrO);
    //       return roundedNr;
    //     } else {
    //       return 0;
    //     }
    //   };
    //   var alimentosWithSameGroup = this.alimentos.filter(filter);
    //   // console.log(alimentoInfo.toSubstitute);
    //   alimentosWithSameGroup = alimentosWithSameGroup.map(function(alimento) {
    //     let factorX = alimentoOriginal.data.kcal / alimento.data.kcal;
    //     alimento.gramas = alimentoOriginal.data.gr_padrao * factorX;
    //     alimento.gramas = roundNr(alimento.gramas);
    //     alimento.qtdSub = alimento.gramas / alimento.data.gr_padrao;
    //     alimento.qtdSub = roundNr(alimento.qtdSub);
    //     if (alimentoInfo.toSubstitute != undefined) {
    //       alimento.chosen = alimentoInfo.toSubstitute
    //         .map(function(l) {
    //           return l.nome;
    //         })
    //         .includes(alimento.data.nome);
    //     }
    //     return {
    //       qtdSub: alimento.qtdSub,
    //       gramas: alimento.gramas,
    //       chosen: alimento.chosen,
    //       nome: alimento.data.nome,
    //       medida_caseira_padrao: alimento.data.medida_caseira_padrao,
    //       // data: {
    //       //   nome: alimento.data.nome,
    //       //   medida_caseira_padrao: alimento.data.medida_caseira_padrao,
    //       //   alimentoId: 0,
    //       // },
    //     };
    //   });
    //   refeicoes[refeicaoIndex].alimentosList[
    //     alimentoIndex
    //   ].substitution = alimentosWithSameGroup;
    //   // console.log("substitions1", refeicoes[refeicaoIndex].alimentosList[alimentoIndex].substitution)
    // },
    // getAlimentosWithSameGroup(refeicaoIndex, alimentoIndex, alimentoInfo) {

    //   var filter = function (alimento) {
    //     if (
    //       alimento.data.grupo_1 == alimentoInfo.data.grupo_1 &&
    //       // && calculateDiference(alimento.data.kcal, alimentoInfo.data.kcal)
    //       alimento.data.nome != alimentoInfo.data.nome
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   };
    //   var alimentosWithSameGroup = this.alimentos.filter(filter);
    //   alimentosWithSameGroup = alimentosWithSameGroup.map(function (alimento) {
    //     let factorX = alimentoInfo.data.kcal / alimento.data.kcal;
    //     alimento.gramas = alimentoInfo.data.gr_padrao * factorX;
    //     alimento.gramas = alimento.gramas.toFixed();
    //     alimento.qtdSub = alimento.gramas / alimento.data.gr_padrao;
    //     alimento.qtdSub = alimento.qtdSub.toFixed(1);
    //     alimento.chosen = false;
    //     // return alimento;
    //     return {
    //       qtdSub: alimento.qtdSub,
    //       gramas: alimento.gramas,
    //       chosen: alimento.chosen,
    //       data: {
    //         nome: alimento.data.nome,
    //         medida_caseira_padrao: alimento.data.medida_caseira_padrao,
    //         alimentoId: alimento.data.alimentoId
    //       }
    //     };
    //   });
    //   this.refeicoes[refeicaoIndex].alimentosList[
    //     alimentoIndex
    //   ].substitution = alimentosWithSameGroup;
    // },

    // setDietaEditar(dieta) {
    //   // console.log(dieta.refeicoes);
    //   // console.log("length ref", dieta.refeicoes.length)

    //   for (
    //     var refIndex = 0;
    //     refIndex < dieta.refeicoes.length - 1;
    //     refIndex++
    //   ) {
    //     // console.log("length alimentos",dieta.refeicoes[refIndex].alimentosList.length)
    //     for (
    //       var alimentoIndex = 0;
    //       alimentoIndex < dieta.refeicoes[refIndex].alimentosList.length; //REMOVE THAT -1
    //       alimentoIndex++
    //     ) {
    //       if (
    //         dieta.refeicoes[refIndex].alimentosList[alimentoIndex].alimentoId !=
    //         ""
    //       ) {
    //         this.getAlimentosWithSameGroup(
    //           dieta.refeicoes,
    //           refIndex,
    //           alimentoIndex,
    //           dieta.refeicoes[refIndex].alimentosList[alimentoIndex]
    //         );
    //       }
    //     }
    //   }

    //   // console.log("refeicoes", dieta.refeicoes);
    //   this.$store.dispatch("firestore/dietaDocId", {
    //     dietaDocId: dieta.dietaDocId,
    //   });
    //   this.$store.dispatch("firestore/dietaTitulo", {
    //     dietaTitulo: dieta.titulo,
    //   });
    //   this.$store.dispatch("firestore/dietaStatus", {
    //     refeicoes: dieta.refeicoes,
    //   });
    // },

    setDietaDeletar(dietaDocId) {
      this.setDietaEditar(dietaDocId);
      this.dialogDeletar = true;
    },

    setDietaEditar(dietaDocId) {
      this.editandoId = dietaDocId;
      this.$store.dispatch("firestore/getDietaSalva", {
        dietaDocId: dietaDocId,
        alimentos: this.alimentos,
      });
    },

    deletarDieta() {
      console.log("a ser deletado", this.dietaDocId);
      this.$store.dispatch("firestore/deletarDietaSalva", {
        dietaDocId: this.dietaDocId,
      });

      setTimeout(() => {
        this.forceGetDietasSalvas()
        this.dialogDeletar = false;
      }, 3000);
    },
    pacienteSelected(pacienteId) {
      if (pacienteId != undefined) {
        var paciente = this.pacientes.find((item) => item.value == pacienteId);
        this.pacienteChosen = paciente;
        this.getTaxaMetabolicaPaciente(pacienteId);
        this.salvarDietaEmPaciente = true;
        this.salvarDietaEmSalvas = false;
      } else {
        this.pacienteChosen = { data: { nome: "" } };
        this.salvarDietaEmPaciente = false;
        this.salvarDietaEmSalvas = true;
      }
    },
    salvarDietaOverride() {
      this.dialogSalvar = false;
      this.progressDialog = true;
      if (this.salvarDietaEmPaciente) {
        this.$store.dispatch("firestore/salvarDietaPacienteEdit", {
          // titulo: this.nomeDietaPaciente,
          dietaDocId: this.dietaDocId,
          pacienteEmail: this.pacienteChosen.data.email,
          autorEmail: sessionStorage.getItem("current-user"),
          autorPapel: this.funcionarioOnline.papel,
          autorCrn: this.funcionarioOnline.crn,
          autorNome: this.funcionarioOnline.nome,
          autorAssinatura: this.funcionarioOnline.assinatura,
          status: this.dietaStatus,
          dietaRefeicoes: this.dietaRefeicoes.map(function(refeicao) {
            refeicao.alimentosList.map(function(alimento) {
              alimento.substitution = null;
              if (alimento.toSubstitute) {
                alimento.toSubstitute.map(function(sub) {
                  // sub.nome = sub.data.nome;
                  sub.data = null;
                  return sub;
                });
              }
              return alimento;
            });
            if (refeicao.receitasList != undefined) {
              refeicao.receitasList.map(function(receita) {
                receita.alimentosList.map(function(alimento) {
                  var alimentoReduced = {
                    nome: alimento.nome,
                    quantidade: alimento.quantidade,
                    gramas: alimento.gramas,
                    medida: alimento.medida,
                  };
                  return alimentoReduced;
                });
                return receita;
              });
            }
            return refeicao;
          }),
        });
        this.saved = true;
      }
      if (this.salvarDietaEmSalvas) {
        console.log(this.nomeDieta);
        this.$store.dispatch("firestore/salvarDietaSalvasEdit", {
          dietaDocId: this.dietaDocId,
          titulo: this.nomeDieta,
          status: this.dietaStatus,
          autorEmail: sessionStorage.getItem("current-user"),
          autorPapel: this.funcionarioOnline.papel,
          autorCrn: this.funcionarioOnline.crn,
          autorNome: this.funcionarioOnline.nome,
          autorAssinatura: this.funcionarioOnline.assinatura,
          dietaRefeicoes: this.dietaRefeicoes.map(function(refeicao) {
            refeicao.alimentosList.map(function(alimento) {
              alimento.substitution = null;
              if (alimento.toSubstitute) {
                alimento.toSubstitute.map(function(sub) {
                  // sub.nome = sub.data.nome;
                  sub.data = null;
                  return sub;
                });
              }
              return alimento;
            });
            if (refeicao.receitasList != undefined) {
              refeicao.receitasList.map(function(receita) {
                receita.alimentosList.map(function(alimento) {
                  var alimentoReduced = {
                    nome: alimento.nome,
                    quantidade: alimento.quantidade,
                    gramas: alimento.gramas,
                    medida: alimento.medida,
                  };
                  return alimentoReduced;
                });
                return receita;
              });
            }
            return refeicao;
          }),
        });
        this.saved = true;
      }
      setTimeout(() => {
        this.forceGetDietasSalvas()
      }, 2500);
    },
    salvarDietaAsNew() {
      this.dialogSalvar = false;
      this.progressDialog = true;
      if (this.salvarDietaEmPaciente) {
        this.$store.dispatch("firestore/salvarDietaPaciente", {
          // pacienteId: this.pacienteDocID,
          titulo: this.nomeDietaPaciente,
          pacienteEmail: this.pacienteChosen.data.email,
          status: this.dietaStatus,
          autorEmail: sessionStorage.getItem("current-user"),
          autorPapel: this.funcionarioOnline.papel,
          autorCrn: this.funcionarioOnline.crn,
          autorNome: this.funcionarioOnline.nome,
          autorAssinatura: this.funcionarioOnline.assinatura,
          dietaRefeicoes: this.dietaRefeicoes.map(function(refeicao) {
            refeicao.alimentosList.map(function(alimento) {
              alimento.substitution = null;
              if (alimento.toSubstitute) {
                alimento.toSubstitute.map(function(sub) {
                  // sub.nome = sub.data.nome;
                  sub.data = null;
                  return sub;
                });
              }
              return alimento;
            });
            if (refeicao.receitasList != undefined) {
              refeicao.receitasList.map(function(receita) {
                receita.alimentosList.map(function(alimento) {
                  var alimentoReduced = {
                    nome: alimento.nome,
                    quantidade: alimento.quantidade,
                    gramas: alimento.gramas,
                    medida: alimento.medida,
                  };
                  return alimentoReduced;
                });
                return receita;
              });
            }
            return refeicao;
          }),
        });

        this.$store.dispatch("firestore/persistPaciente", {
          pId: this.pacienteChosen.data.email,
        });
        this.saved = true;

        // setTimeout(() => {
        //   this.$router.push("MeusPacientes");
        // }, 2000);
      }
      if (this.salvarDietaEmSalvas) {
        console.log(this.nomeDieta);
        this.$store.dispatch("firestore/salvarDietaSalvas", {
          titulo: this.nomeDieta,
          status: this.dietaStatus,
          autorEmail: sessionStorage.getItem("current-user"),
          autorPapel: this.funcionarioOnline.papel,
          autorCrn: this.funcionarioOnline.crn,
          autorNome: this.funcionarioOnline.nome,
          autorAssinatura: this.funcionarioOnline.assinatura,
          dietaRefeicoes: this.dietaRefeicoes.map(function(refeicao) {
            refeicao.alimentosList.map(function(alimento) {
              alimento.substitution = null;
              if (alimento.toSubstitute) {
                alimento.toSubstitute.map(function(sub) {
                  // sub.nome = sub.data.nome;
                  sub.data = null;
                  return sub;
                });
              }
              return alimento;
            });
            if (refeicao.receitasList != undefined) {
              refeicao.receitasList.map(function(receita) {
                receita.alimentosList.map(function(alimento) {
                  var alimentoReduced = {
                    nome: alimento.nome,
                    quantidade: alimento.quantidade,
                    gramas: alimento.gramas,
                    medida: alimento.medida,
                  };
                  return alimentoReduced;
                });
                return receita;
              });
            }
            return refeicao;
          }),
        });
        this.saved = true;
      }
      setTimeout(() => {
        this.forceGetDietasSalvas()
      }, 2500);
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async preloadImages() {
      if (
        this.funcionarioOnline.assinatura !== undefined &&
        this.funcionarioOnline.assinatura !== ""
      ) {
        this.toDataURL(this.funcionarioOnline.assinatura, (data) => {
          this.preloadSignature.src = data;
        });
      } else {
        this.preloadSignature.src = require("@/assets/signature_none.jpg");
      }

      if (this.isFranquia) {
        this.preloadLogo.src = require("@/assets/logoCompleto.png");
      } else if (
        this.userInfo.profilePicture !== undefined &&
        this.userInfo.profilePicture !== "" &&
        !this.isFranquia
      ) {
        this.toDataURL(this.userInfo.profilePicture, (data) => {
          this.preloadLogo.src = data;
        });
      } else {
        this.preloadLogo.src = require("@/assets/signature_none.jpg");
      }

      this.preloadLogo.onload = function() {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    async createDocWithJSPDF() {
      console.log(this.dietaRefeicoes);
      if (this.dietaRefeicoes.length !== 0) {
        alert("Gerando PDF");
        // links
        // https://mrrio.github.io/jsPDF/examples/basic.html
        // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
        // https://simonbengtsson.github.io/jsPDF-AutoTable/
        // https://www.npmjs.com/package/jspdf-autotable

        var doc = new jspdf("p");
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        // Doc settings
        var fontSize = 10;
        doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
        doc.addFont(
          "Montserrat-Medium-normal.ttf",
          "Montserrat-Medium",
          "normal"
        );
        doc.setFontSize(fontSize);
        doc.setFont("Montserrat-Medium");

        // The (900 / 262) division is used to keep the proportion of the original image
        // New Width * (900 / 262) will keep the height proportion

        var imgWidth = 40;
        // var imgHeight = imgWidth / (900 / 262);
        var imgHeight =
          imgWidth /
          (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
        var imgPos = (210 - imgWidth) / 2;
        var startXPos = 15;
        var textStartYPos = imgHeight * 1.1 + 12;

        var centeredText = function(text, y) {
          var textWidth =
            (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
          doc.text(textOffset, y, text);
        };

        // var createTexts = function(
        //   textList,
        //   centered = false,
        //   textStart = textStartYPos
        // ) {
        //   for (var i = 0; i < textList.length; i++) {
        //     if (centered) {
        //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
        //     } else {
        //       var lines = doc
        //         .setFont("Montserrat-Medium")
        //         .splitTextToSize(textList[i], 180);
        //       // console.log(lines)
        //       for (var j = 0; j < lines.length; j++) {
        //         doc.text(
        //           startXPos,
        //           textStart + (fontSize / 2) * (1 + j),
        //           lines[j]
        //         );
        //       }
        //       textStart += (fontSize / 2) * /*1 + */ lines.length;
        //     }
        //   }
        //   return (textStart += fontSize / 2);
        // };

        var createTexts = function(textList, centered = false, textStart = textStartYPos) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page
        
        for (var i = 0; i < textList.length; i++) {
            var lines = doc.setFont("Montserrat-Medium").splitTextToSize(textList[i], 180);
            
            for (var j = 0; j < lines.length; j++) {
                // Check if the current line would go past the threshold
                if (textStart > pageHeightThreshold) {
                    doc.addPage();
                    textStart = newYStart; // Reset the text start position for the new page
                }
                
                // Whether to center the text or not
                if (centered) {
                    centeredText(lines[j], textStart);
                } else {
                    doc.text(startXPos, textStart, lines[j]);
                }
                
                textStart += (fontSize / 2); // Increment the y position for each line of text
            }

            // Increment after each text block rather than each line
            if (!centered) {
                textStart += (fontSize / 2); // This accounts for the space after each block of text
            }
        }
        
        return textStart; // Return the updated text start position
    };

        var createTablesForRefeicoes = function(refeicoes, startPosY) {
          var startPosYNew = startPosY;
          // #c5d13f <----- trocar a cor da tabela
          for (var i = 0; i < refeicoes.length; i++) {
            var texts;
            // if (refeicoes[i].observacao !== "") {
            //   texts = [
            //     `${refeicoes[i].nomeRefeicao} - ${refeicoes[i].horarioRefeicao}`,
            //     `Observação: ${refeicoes[i].observacao}`,
            //   ];
            // } else {
            texts = [
              `${refeicoes[i].nomeRefeicao} - ${refeicoes[i].horarioRefeicao}`,
            ];
            // }
            startPosYNew = createTexts(texts, false, startPosYNew + 12); //lastPos
            var body = refeicoes[i].alimentosList.map(function(alimento) {
              if (alimento != undefined) {
                if (alimento.toSubstitute != undefined) {
                  alimento.hasSubs = "*";
                }
                return [
                  alimento.nome,
                  alimento.quantidade, //.toString(),
                  alimento.medida, //.toString(),
                  alimento.gramas, //.toString(),
                  // alimento.kcal//.toString(),
                  alimento.hasSubs,
                ];
              } else {
                return [];
              }
            });
            console.log("", refeicoes[i].alimentosList[0]);
            if (refeicoes[i].alimentosList[0].alimentoId !== "") {
              doc.autoTable({
                startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
                startx: startXPos,
                head: [
                  ["Alimento", "Quantidade", "Medida", "Total (g)", "Subst."],
                ],
                body: body,
                // tableLineColor: [97, 64, 33],
                // tableLineWidth: 0.5,
                // headStyles: {
                //   fillColor: [176, 159, 144],
                //   textColor: 40,
                //   fontStyle: "regular",
                // },
                // bodyStyles: {
                //   fillColor: [223, 216, 210],
                // },
                // alternateRowStyles: {
                //   fillColor: [239, 235, 232],
                // },
                headStyles: {
                  fillColor: [226, 232, 159],
                  textColor: 40,
                  fontStyle: "regular",
                },
                bodyStyles: {
                  fillColor: [243, 245, 216],
                },
                alternateRowStyles: {
                  fillColor: [249, 250, 235],
                },
              });
              startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
            }

            // doc.text "* substituir {alimento} com {alimento.toSubstitute[0], [1]...}

            var bodyReceitas = refeicoes[i].receitasList.map(function(receita) {
              if (receita != undefined) {
                return [receita.titulo, receita.porcoes];
              } else {
                return [];
              }
            });
            console.log("receitas[0]", refeicoes[i].receitasList[0]);
            if (refeicoes[i].receitasList[0].titulo !== "") {
              doc.autoTable({
                startY: startPosYNew, //startPosY + i * (lastPos - startPosY),
                startx: startXPos,
                head: [["Receita", "Porções"]],
                body: bodyReceitas,
                // tableLineColor: [197, 209, 63],
                // tableLineWidth: 0.5,
                headStyles: {
                  fillColor: [226, 232, 159],
                  textColor: 40,
                  fontStyle: "regular",
                },
                bodyStyles: {
                  fillColor: [243, 245, 216],
                },
                alternateRowStyles: {
                  fillColor: [249, 250, 235],
                },
              });
              startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
            }

            if (refeicoes[i].observacao !== "") {
              doc.autoTable({
                startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
                startx: startXPos,
                head: [["Observações"]],
                body: [[refeicoes[i].observacao]],
                // tableLineColor: [149,153,156],
                // tableLineWidth: 0.5,
                headStyles: {
                  fillColor: [214, 219, 223],
                  textColor: 40,
                  fontStyle: "regular",
                },
                bodyStyles: {
                  fillColor: [238, 240, 242],
                },
              });
              startPosYNew = doc.previousAutoTable.finalY;
            }

            for (var jn = 0; jn < refeicoes[i].alimentosList.length; jn++) {
              if (refeicoes[i].alimentosList[jn].toSubstitute != undefined) {
                // console.log(refeicoes[i].alimentosList[jn]);
                var textsSub;
                if (refeicoes[i].alimentosList[jn].medida != null) {
                  textsSub = [
                    `substituir ${refeicoes[i].alimentosList[jn].quantidade} ${refeicoes[i].alimentosList[jn].medida} de ${refeicoes[i].alimentosList[jn].nome} por...`,
                  ];
                } else {
                  textsSub = [
                    `substituir ${refeicoes[i].alimentosList[jn].gramas}g de ${refeicoes[i].alimentosList[jn].nome} por...`,
                  ];
                }

                // var lastPos = createTexts(textsSub, false, startPosYNew);
                var bodySub = refeicoes[i].alimentosList[jn].toSubstitute.map(
                  function(alimento) {
                    if (alimento != undefined) {
                      if (alimento.toSubstitute != undefined) {
                        alimento.hasSubs = "*";
                      }
                      if (alimento.medida_caseira_padrao == "freetext") {
                        alimento.qtdSub = "";
                        alimento.medida_caseira_padrao = "";
                        alimento.gramas = "";
                      }
                      return [
                        alimento.nome,
                        alimento.qtdSub, //.toString(),
                        alimento.medida_caseira_padrao, //.toString(),
                        alimento.gramas, //.toString(),
                      ];
                    } else {
                      return [];
                    }
                  }
                );

                doc.autoTable({
                  startY: startPosYNew, // lastPos, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [[textsSub, "Qtd.", "Medida", "Total (g)"]],
                  body: bodySub,
                  // tableLineColor: [97, 64, 33],
                  // tableLineWidth: 0.5,
                  // headStyles: {
                  //   fillColor: [176, 159, 144],
                  //   textColor: 40,
                  //   fontStyle: "regular",
                  // },
                  // bodyStyles: {
                  //   fillColor: [223, 216, 210],
                  // },
                  // alternateRowStyles: {
                  //   fillColor: [239, 235, 232],
                  // },
                  headStyles: {
                    fillColor: [245, 203, 167],
                    textColor: 40,
                    fontStyle: "regular",
                  },
                  bodyStyles: {
                    fillColor: [250, 229, 211],
                  },
                  alternateRowStyles: {
                    fillColor: [253, 242, 223],
                  },
                });
                startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
              }
            }
          }
          return startPosYNew; // doc.previousAutoTable.finalY;
        };

        // var createTablesForSubst = function(refeicoes, startPosY) {
        //   // #c5d13f <----- trocar a cor da tabela
        //   for (var i = 0; i < refeicoes.length; i++) {
        //     for (var j = 0; j < refeicoes[i].alimentosList.length; j++) {
        //       if (refeicoes[i].alimentosList[j].toSubstitute != undefined) {
        //         // console.log(refeicoes[i].alimentosList[j]);
        //         var textsSub;
        //         if (refeicoes[i].alimentosList[j].medida != null) {
        //           textsSub = [
        //             `${refeicoes[i].nomeRefeicao}: substituir ${refeicoes[i].alimentosList[j].quantidade} ${refeicoes[i].alimentosList[j].medida} de ${refeicoes[i].alimentosList[j].nome} por...`,
        //           ];
        //         } else {
        //           textsSub = [
        //             `${refeicoes[i].nomeRefeicao}: substituir ${refeicoes[i].alimentosList[j].gramas}g de ${refeicoes[i].alimentosList[j].nome} por...`,
        //           ];
        //         }

        //         var lastPos = createTexts(textsSub, false, startPosY);
        //         var bodySub = refeicoes[i].alimentosList[j].toSubstitute.map(
        //           function(alimento) {
        //             if (alimento != undefined) {
        //               if (alimento.toSubstitute != undefined) {
        //                 alimento.hasSubs = "*";
        //               }
        //               return [
        //                 alimento.nome,
        //                 alimento.qtdSub, //.toString(),
        //                 alimento.medida_caseira_padrao, //.toString(),
        //                 alimento.gramas, //.toString(),
        //               ];
        //             } else {
        //               return [];
        //             }
        //           }
        //         );

        //         doc.autoTable({
        //           startY: lastPos, //(lastPos / 2) * 0.7 + startPosY,
        //           startx: startXPos,
        //           head: [["Alimento", "Quantidade", "Medida", "Total (g)"]],
        //           body: bodySub,
        //           // tableLineColor: [97, 64, 33],
        //           // tableLineWidth: 0.5,
        //           // headStyles: {
        //           //   fillColor: [176, 159, 144],
        //           //   textColor: 40,
        //           //   fontStyle: "regular",
        //           // },
        //           // bodyStyles: {
        //           //   fillColor: [223, 216, 210],
        //           // },
        //           // alternateRowStyles: {
        //           //   fillColor: [239, 235, 232],
        //           // },
        //           headStyles: {
        //             fillColor: [226, 232, 159],
        //             textColor: 40,
        //             fontStyle: "regular",
        //           },
        //           bodyStyles: {
        //             fillColor: [243, 245, 216],
        //           },
        //           alternateRowStyles: {
        //             fillColor: [249, 250, 235],
        //           },
        //         });
        //         startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
        //       }
        //     }
        //   }
        //   return doc.previousAutoTable.finalY;
        // };

        var createTablesForReceitas = function(refeicoes, startPosY) {
          // #c5d13f <----- trocar a cor da tabela
          for (var i = 0; i < refeicoes.length; i++) {
            for (var j = 0; j < refeicoes[i].receitasList.length; j++) {
              if (refeicoes[i].receitasList[j].titulo !== "") {
                // console.log(refeicoes[i].receitasList[j]);
                var textsRec = [
                  `${refeicoes[i].nomeRefeicao}: ${refeicoes[i].receitasList[j].titulo}, rendimento: ${refeicoes[i].receitasList[j].rendimento} (porções)`,
                  // `${refeicoes[i].receitasList[j].modoPreparo}`,
                ];

                var lastPos = createTexts(textsRec, false, startPosY + 6);
                var bodyRec = refeicoes[i].receitasList[j].alimentosList.map(
                  function(alimento) {
                    if (alimento != undefined) {
                      return [
                        alimento.nome,
                        alimento.quantidade, //.toString(),
                        alimento.medida, //.toString(),
                        alimento.gramas, //.toString(),
                      ];
                    } else {
                      return [];
                    }
                  }
                );
                var bodyModoPreparo = [
                  [refeicoes[i].receitasList[j].modoPreparo],
                ];

                doc.autoTable({
                  startY: lastPos, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [["Ingrediente", "Quantidade", "Medida", "Total (g)"]],
                  body: bodyRec,
                  // tableLineColor: [197, 209, 63],
                  // tableLineWidth: 0.5,
                  headStyles: {
                    fillColor: [226, 232, 159],
                    textColor: 40,
                    fontStyle: "regular",
                  },
                  bodyStyles: {
                    fillColor: [243, 245, 216],
                  },
                  alternateRowStyles: {
                    fillColor: [249, 250, 235],
                  },
                });
                startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
                doc.autoTable({
                  startY: startPosY, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [["Modo de preparo"]],
                  body: bodyModoPreparo,
                  // tableLineColor: [197, 209, 63],
                  // tableLineWidth: 0.5,
                  // headStyles: {
                  //   fillColor: [226, 232, 159],
                  //   textColor: 40,
                  //   fontStyle: "regular",
                  // },
                  // bodyStyles: {
                  //   fillColor: [243, 245, 216],
                  // },
                  headStyles: {
                    fillColor: [214, 219, 223],
                    textColor: 40,
                    fontStyle: "regular",
                  },
                  bodyStyles: {
                    fillColor: [238, 240, 242],
                  },
                });
                startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
              }
            }
          }
          return doc.previousAutoTable.finalY;
        };

        // console.log(this.$store.state.firestore.userInfo);
        // // Logo Clubedonutricionista
        // if (this.$store.state.firestore.userInfo.useLogo === false) {
        //   console.log("op 1");
        doc.addImage({
          imageData: this.preloadLogo,
          y: startXPos,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });
        // } else {
        //   // LOGO CLUBE
        //   imgWidth = 30;
        //   imgHeight = imgWidth / (900 / 262);
        //   startXPos = 15;

        //   doc.addImage({
        //     imageData: require("@/assets/logoCompleto.png"),
        //     y: startXPos,
        //     x: 15,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });

        //   imgWidth = 50;
        //   var startYPos = 15 + imgHeight * 2;
        //   imgHeight =
        //     imgWidth /
        //     (this.preloadSignature.naturalWidth /
        //       this.preloadSignature.naturalHeight);
        //   imgPos = (210 - imgWidth) / 2;
        //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

        //   doc.addImage({
        //     imageData: this.preloadSignature,
        //     y: startYPos,
        //     x: imgPos,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });
        // }

        var roundNr = function(nr) {
          if (nr !== undefined) {
            var nrO = parseFloat(nr);
            var roundedNr = Math.round(nrO);
            return roundedNr;
          } else {
            return 0;
          }
        };

        var texts = [
          `Plano alimentar de ${roundNr(
            this.dietaStatus.KCAL
          )} kcal (${day}/${month}/${year})`,
        ];

        createTexts(texts, true, textStartYPos + 10);
        var endTablePosY = createTablesForRefeicoes(
          this.dietaRefeicoes,
          textStartYPos + texts.length * fontSize + 10
        );

        // doc.addPage();
        // createTexts(
        //   [
        //     // "_______________________________________________________",
        //     "Possíveis Substituções de Alimentos",
        //   ],
        //   true,
        //   24
        //   // endTablePosY
        // );
        // endTablePosY = createTablesForSubst(
        //   this.dietaRefeicoes,
        //   36
        //   // endTablePosY + 24
        // );

        var refNrRecList = this.dietaRefeicoes.map(function(ref) {
          if (
            ref.receitasList.length > 0 &&
            ref.receitasList[0].titulo !== "" &&
            ref.receitasList[0].titulo !== undefined
          ) {
            return 1;
          } else {
            return 0;
          }
        });
        var nrRecs = 0;
        refNrRecList.forEach(function(nr) {
          nrRecs += parseFloat(nr);
        });

        if (nrRecs > 0) {
          console.log("nrRecs", nrRecs);
          doc.addPage();
          createTexts(
            [
              // "_______________________________________________________",
              "Receitas",
            ],
            true,
            24
            // endTablePosY
          );
          endTablePosY = createTablesForReceitas(
            this.dietaRefeicoes,
            36
            // endTablePosY + 24
          );
        }

        // USE SIGNATURE
        // console.log(
        //   "signature",
        //   this.$store.state.firestore.userInfo.signature
        // );
        // if (this.$store.state.firestore.userInfo.signature != undefined) {
        //   imgWidth = 30;
        //   startYPos =
        //     fontSize +
        //     (endTablePosY !== undefined
        //       ? endTablePosY + 16
        //       : textStartYPos + texts.length * (fontSize * (3 / 4)));
        //   imgHeight =
        //     imgWidth /
        //     (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
        //   imgPos = (210 - imgWidth) / 2;
        //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

        //   doc.addImage({
        //     imageData: this.preloadSignature,
        //     y: startYPos,
        //     x: imgPos,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });
        // } else {
        //   imgHeight = 0;
        // }

        doc.addPage();
        imgWidth = 75;
        imgHeight =
          imgWidth /
          (this.preloadSignature.naturalWidth /
            this.preloadSignature.naturalHeight);
        imgPos = (210 - imgWidth) / 2;
        textStartYPos = imgHeight + 24;
        // textStartYPos = imgHeight + endTablePosY + 12;

        doc.addImage({
          imageData: this.preloadSignature,
          // y: endTablePosY + 12, // + imgHeight * 1.4,
          y: 24,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });

        createTexts(
          [
            "____________________________",
            // `Nutricionista ${this.$store.state.firestore.userInfo.name}`,
            // `CRN: ${this.$store.state.firestore.userInfo.crn}`,
            `Nutricionista ${this.funcionarioOnline.nome}`,
            `CRN: ${this.funcionarioOnline.crn}`,
            `Data: ${day}/${month}/${year}`,
            "",
            `${this.userInfo.addressRoad} - ${this.userInfo.addressComplement}`,
            `${this.userInfo.addressCep} ${this.userInfo.addressCity} - ${this.userInfo.addressBairro}`,
            `tel.: ${this.userInfo.phoneClients}`,
          ],
          true,
          // endTablePosY !== undefined
          //   ? endTablePosY + 16 + imgHeight * 1.4
          //   :
          textStartYPos // + texts.length * (fontSize * (3 / 4))
        );
        console.log(endTablePosY);

        doc.save("cardapio.pdf");
      } else {
        alert("Preencha todos os campos da dieta antes de continuar");
      }
    },
  },
  // firestore() {
  //   return {
  //     dietasSalvas: firebase
  //       .firestore()
  //       .collection("users")
  //       .doc(sessionStorage.getItem("current-user"))
  //       // .doc(firebase.auth().currentUser.email)
  //       .collection("dietasSalvasRefs"),
  //   };
  // },
  mounted() {
    this.getDietasSalvas();
    if (this.$store.state.firestore.alimentos.length === 0) {
      this.$store.dispatch("firestore/getAlimentos");
    }
    // if (this.$store.state.firestore.dietasSalvas.length === 0) {
    //   this.$store.dispatch("firestore/getDietasSalvas");
    // }
    // if (this.$store.state.firestore.pacientes.length === 0) {
    this.$store.dispatch("firestore/getPacientes");
    // }
    // if (this.$store.state.firestore.receitas.length === 0) {
    this.$store.dispatch("firestore/getReceitas");
    // }
    setTimeout(() => {
      if (this.persistPaciente !== "") {
        this.pacienteDocID = this.persistPaciente;
        this.pacienteSelected(this.persistPaciente);
        console.log("this.persistPaciente", this.persistPaciente);
      }
    }, 500);
    setTimeout(() => {
      this.preloadImages();
    }, 500);
  },
};
</script>

<style></style>
