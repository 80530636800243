<template>
  <div>
    <v-dialog v-model="showDialog" max-width="800">
      <v-card>
        <v-card-title>
          <v-card :color="dirColor(showMov.dir)" width="100%">
            <v-card-title class="d-flex align-center white--text">
              <v-icon class="mx-2" v-if="showMov.dir" color="white"
                >mdi-plus-circle</v-icon
              >
              <v-icon class="mx-2" v-else color="white"
                >mdi-minus-circle</v-icon
              >
              {{ getNiceDate(showMov.id) }}: {{ showMov.descr }}</v-card-title
            >
          </v-card></v-card-title
        >
        <v-card-text>
          <v-card>
            <v-card-text class="mx-1">
              <v-row>
                <v-text-field
                  class="mx-2"
                  outlined
                  v-model="showMov.valor"
                  label="valor em R$"
                  type="number"
                  readonly
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  outlined
                  class="mx-2"
                  v-model="showMov.descr"
                  label="descrição curta"
                  readonly
                ></v-text-field>
              </v-row>
              <v-row>
                <v-textarea
                  outlined
                  class="mx-2"
                  v-model="showMov.details"
                  label="detalhes"
                  readonly
                ></v-textarea>
              </v-row>
              <v-row v-if="showMov.comprovante !== null">
                <v-img :src="showMov.comprovante" width="80%"></v-img
              ></v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="closeShowDialog" color="#D6DBDF">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="d-flex justify-center text-h6 my-4">
      Fundo Publicidade: {{ niceString(fundoSum) }}
    </v-row>
    <v-row class="d-flex justify-center mt-4">
      <v-card outlined color="#AEB6BF" width="95%">
        <v-row class="d-flex align-center mx-1">
          <v-col cols="2">Data</v-col>
          <v-col cols="5">Descrição</v-col>
          <v-col cols="2">
            <v-row class="d-flex justify-center"
              ><v-icon color="#5D6D7E">mdi-plus-circle-outline</v-icon></v-row
            >
            <v-row class="d-flex justify-center"
              ><small>Pagamento ao fundo</small></v-row
            >
          </v-col>
          <v-col cols="2">
            <v-row class="d-flex justify-center"
              ><v-icon color="#5D6D7E">mdi-minus-circle-outline</v-icon></v-row
            >
            <v-row class="d-flex justify-center"
              ><small>Investimento do fundo</small></v-row
            >
          </v-col>
          <v-col cols="1"></v-col>
        </v-row> </v-card
    ></v-row>
    <v-row
      v-for="fundoMov in fundoList"
      :key="fundoMov.id"
      class="d-flex justify-center"
    >
      <v-card outlined width="95%">
        <v-row class="d-flex align-center mx-1">
          <v-col cols="2">{{ getNiceDate(fundoMov.id) }}</v-col>
          <v-col cols="5">{{ fundoMov.descr }}</v-col>
          <v-col cols="2">
            <v-content v-if="fundoMov.dir">
              {{ niceString(fundoMov.valor) }}</v-content
            ></v-col
          >
          <v-col cols="2">
            <v-content v-if="!fundoMov.dir">
              {{ niceString(fundoMov.valor) }}</v-content
            ></v-col
          >
          <v-col cols="1">
            <v-tooltip bottom color="#5D6D7E" class="white--text">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#5D6D7E"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="seeDetails(fundoMov)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver detalhes</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  data() {
    return {
      showDialog: false,
      showMov: {
        id: null,
        valor: null,
        descr: null,
        details: null,
        comprovante: null,
      },
      saving: false,
      saved: false,
      dir: true,
      fundoList: [],
      mov: {
        valor: null,
        descr: null,
        details: null,
        comprovante: null,
      },
      comprovante: null,
    };
  },
  methods: {
    seeDetails(mov) {
      this.showMov = mov;
      this.showDialog = true;
    },
    getNiceDate(millis) {
      var millisNr = parseFloat(millis);
      var date = new Date(millisNr);
      var year = date.getFullYear().toString();
      var month = date.getMonth() + 1;
      var monthStr;
      if (month > 9) {
        monthStr = month.toString();
      } else {
        monthStr = "0" + month.toString();
      }
      var day = date.getDate();
      var dayStr;
      if (day > 9) {
        dayStr = day.toString();
      } else {
        dayStr = "0" + day.toString();
      }

      var niceDate = dayStr + "/" + monthStr + "/" + year;

      return niceDate;
    },
    handleComprovante(file) {
      this.comprovante = file;
    },
    setNull() {
      this.mov.valor = null;
      this.mov.descr = null;
      this.mov.details = null;
      this.mov.comprovante = null;
      this.comprovante = null;
    },
    niceString(valor) {
      var valorKomma;

      var decimals = Math.round((valor - Math.floor(valor)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valor);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valor / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    closeShowDialog() {
      this.showDialog = false;
      this.showMov = {
        id: null,
        valor: null,
        descr: null,
        details: null,
        comprovante: null,
      };
    },
    async getFundoPubl() {
      var fundoList = await firebase
        .firestore()
        .collection("fundoPubl")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );
      this.fundoList = fundoList;
    },
    dirColor(dir) {
      if (dir) {
        return "#4c9900";
      } else {
        return "#F1C40F";
      }
    },
  },
  computed: {
    labelTitle() {
      if (this.dir) {
        return "Registrar pagemento ao fundo";
      } else {
        return "Registrar investimento do fundo";
      }
    },
    fundoSum() {
      if (this.fundoList.length < 1) {
        return 0;
      } else {
        var sumList = 0;
        this.fundoList.forEach(function (mov) {
          sumList += parseInt(mov.valor);
        });
        return sumList;
      }
    },
  },
  mounted() {
    this.getFundoPubl();
  },
};
</script>