<template>
  <!-- app fixed -->
  <v-footer color="#614021" width="100%" :style="{ top: '100' }" app fixed>
    <v-card
      flat
      tile
      class="white--text text-center"
      color="#614021"
      width="100%"
    >
      <v-card-text class="white--text">
        <v-row class="d-flex justify-center">
          <v-btn
            elevation="5"
            class="white--text"
            color="#614021"
            value="logout"
            to="/"
            @click="logout"
          >
            <v-icon left color="#c5d13f">mdi-logout</v-icon>
            Sair
          </v-btn>
        </v-row><v-row class="d-flex justify-center">
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">{{ icon }}</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { mdiFacebook, mdiInstagram, mdiYoutube } from "@mdi/js";
import firebase from "firebase";
import router from "../../router";

export default {
  data: function () {
    return {
      icons: [mdiInstagram, mdiFacebook, mdiYoutube],
    };
  },
  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(function () {
          router.replace("/");
        })
        .catch(function (error) {
          console.log(error);
        });
      sessionStorage.setItem("current-user", null);
      sessionStorage.removeItem("isAuthenticated");
    },
  },
};
</script>