<template>
  <div class="page">
    <LoginDePacienteHeader></LoginDePacienteHeader>
    <LoginParceiroForm></LoginParceiroForm>
    <WelcomeFooter></WelcomeFooter>
  </div>
</template>

<script>
import WelcomeFooter from "../components/welcome/WelcomeFooter";
import LoginDePacienteHeader from "../components/login/LoginDePacienteHeader";
import LoginParceiroForm from "../components/login/LoginParceiroForm";

export default {
  components: { WelcomeFooter, LoginDePacienteHeader, LoginParceiroForm },

};
</script>

<style>
</style>