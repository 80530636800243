export default function() {
  return {
    dummy: "",
    user: "some email",
    daysIn: 0,
    anyUnansweredN: false,
    preAtendimentoToFill: false,
    pacienteNutriName: "",
    // pacienteCrn: "",
    clubeDeVantagens: [
      {
        logo: "",
        id: "",
        name: "",
        descr: "",
        vantagemResumo: "",
        vantagem: "",
        cidade: "",
        atividade: "",
      },
    ],
    dietaAutorObj: {
      email: "",
      papel: "",
      assinatura: "",
      nome: "",
      crn: "",
    },
    funcionarioOnline: {
      papel: "",
      assinatura: "",
      nome: "",
      crn: "",
    },
    // pacienteNutriUseLogo: "",
    // pacienteNutriLogo: "",
    // pacienteNutriSign: "",
    planoToAcquire: "",
    isAuthenticated: false,
    currentDialog: {
      nome: "",
      pId: "",
      falas: [],
    },
    hiddenInfo: {
      unansweredP: false,
      unansweredN: false,
    },
    alerts: {
      resetEmailsent: false,
      emailOk: false,
      loginOk: false,
      nomeClinicaExists: false,
    },
    funcionarios: [
      {
        papel: "aguarde...",
        assinatura: "",
        nome: "",
        crn: "",
      },
    ],
    visEvent: {},
    alimentos: [],
    nutrientes: [],
    pacientes: [],
    dietasSalvas: [],
    dietasPaciente: [],
    pacientePreAtendimento: "0",
    pacienteAtendimento: "",
    pacienteAtendimentosList: [],
    pacienteDietaRefs: [],
    pacienteTimeline: [],
    pacienteVerHistorico: "",
    defaultUserPacienteObj: {
      email: null,
      senha: null,
    },
    defaultUserObj: {
      email: null,
      senha: null,
    },
    agendaPacienteObj: {
      nome: null,
      sexo: null,
      telefone: null,
      whatsapp: null,
      email: null,
      objetivo: null,
      motivoRecusao: null,
      motivoRecusaoOutro: null,
      meioContato: null,
      detalhesImportantes: null,
      horarioLigacao: null,
      millis: null,
      dia: null,
      mes: null,
      ano: null,
    },
    agendaPacienteEmail: "",
    dietaStatus: {
      KCAL: 0,
      CHO: 0,
      PTN: 0,
      LIP: 0,
      FIB: 0,
      SOD: 0,
      COL: 0,
      fibra_soluvel: 0,
      fibra_insoluvel: 0,
      cinzas: 0,
      calcio: 0,
      magnesio: 0,
      manganes: 0,
      fosforo: 0,
      ferro: 0,
      potassio: 0,
      cobre: 0,
      zinco: 0,
      retinol: 0,
      re: 0,
      era: 0,
      iodo: 0,
      selenio: 0,
      acido_folico: 0,
      acido_pantotenico: 0,
      tiamina: 0,
      riboflavina: 0,
      piridoxina: 0,
      niacina: 0,
      vitamina_c: 0,
      vitamina_d: 0,
      vitamina_e: 0,
      vitamina_b12: 0,
      saturados: 0,
      monoinsaturados: 0,
      poliinsaturados: 0,
      gordura_trans: 0,
      triptofano: 0,
      treonina: 0,
      isoleucina: 0,
      leucina: 0,
      lisina: 0,
      metionina: 0,
      cistina: 0,
      fenilalanina: 0,
      tirosina: 0,
      valina: 0,
      arginina: 0,
      histidina: 0,
      alanina: 0,
      acido_aspartico: 0,
      acido_glutamico: 0,
      glicina: 0,
      prolina: 0,
      serina: 0,
      umidade: 0,
    },
    caixa: [],
    dietaDocId: "",
    dietaTitulo: "",
    dietaRefeicoes: [
      {
        nomeRefeicao: "",
        horarioRefeicao: "",
        receitasList: [
          {
            porcoes: 0,
            titulo: "",
            modoPreparo: "",
            alimentosList: [{ nome: "", medida: "", quantidade: 0, gramas: 0 }],
          },
        ],
        alimentosList: [
          {
            gramas: 0,
            quantidade: 0,
            medida: "",
            toSubstitute: [
              {
                nome: "",
                qtdSub: 0,
                medida_caseira_padrao: "",
                gramas: 0,
              },
            ],
            substitution: [
              {
                nome: "",
                qtdSub: 0,
                medida_caseira_padrao: "",
                gramas: 0,
              },
            ],
          },
        ],
      },
    ],
    alimentoSubstitutions: {
      nome: "",
      gramas: 0,
      list: [
        {
          chosen: false,
          qtdSub: 0,
          gramas: 0,
          data: {
            medida_caseira_padrao: "",
            nome: "",
            alimentoId: 0,
          },
        },
      ],
    },
    receitaId: "",
    receitaVis: {},
    formulaVis: {},
    receita: {
      titulo: "",
      tempoPreparo: "",
      rendimento: 0,
      modoPreparo: "",
      alimentosList: [
        {
          alimentoId: "",
          medida: "",
          quantidade: "",
          gramas: "",
          KCAL: 0,
          CHO: 0,
          PTN: 0,
          LIP: 0,
          FIB: 0,
          SOD: 0,
          COL: 0,
          fibra_soluvel: 0,
          fibra_insoluvel: 0,
          cinzas: 0,
          calcio: 0,
          magnesio: 0,
          manganes: 0,
          fosforo: 0,
          ferro: 0,
          potassio: 0,
          cobre: 0,
          zinco: 0,
          retinol: 0,
          re: 0,
          era: 0,
          iodo: 0,
          selenio: 0,
          acido_folico: 0,
          acido_pantotenico: 0,
          tiamina: 0,
          riboflavina: 0,
          piridoxina: 0,
          niacina: 0,
          vitamina_c: 0,
          vitamina_d: 0,
          vitamina_e: 0,
          vitamina_b12: 0,
          saturados: 0,
          monoinsaturados: 0,
          poliinsaturados: 0,
          gordura_trans: 0,
          triptofano: 0,
          treonina: 0,
          isoleucina: 0,
          leucina: 0,
          lisina: 0,
          metionina: 0,
          cistina: 0,
          fenilalanina: 0,
          tirosina: 0,
          valina: 0,
          arginina: 0,
          histidina: 0,
          alanina: 0,
          acido_aspartico: 0,
          acido_glutamico: 0,
          glicina: 0,
          prolina: 0,
          serina: 0,
          umidade: 0,
        },
      ],
      observacao: "",
      KCAL: 0,
      CHO: 0,
      PTN: 0,
      LIP: 0,
      FIB: 0,
      SOD: 0,
      COL: 0,
      fibra_soluvel: 0,
      fibra_insoluvel: 0,
      cinzas: 0,
      calcio: 0,
      magnesio: 0,
      manganes: 0,
      fosforo: 0,
      ferro: 0,
      potassio: 0,
      cobre: 0,
      zinco: 0,
      retinol: 0,
      re: 0,
      era: 0,
      iodo: 0,
      selenio: 0,
      acido_folico: 0,
      acido_pantotenico: 0,
      tiamina: 0,
      riboflavina: 0,
      piridoxina: 0,
      niacina: 0,
      vitamina_c: 0,
      vitamina_d: 0,
      vitamina_e: 0,
      vitamina_b12: 0,
      saturados: 0,
      monoinsaturados: 0,
      poliinsaturados: 0,
      gordura_trans: 0,
      triptofano: 0,
      treonina: 0,
      isoleucina: 0,
      leucina: 0,
      lisina: 0,
      metionina: 0,
      cistina: 0,
      fenilalanina: 0,
      tirosina: 0,
      valina: 0,
      arginina: 0,
      histidina: 0,
      alanina: 0,
      acido_aspartico: 0,
      acido_glutamico: 0,
      glicina: 0,
      prolina: 0,
      serina: 0,
      umidade: 0,
    },
    receitas: [],
    formulas: [],
    examList: [],
    orientacaoList: [],
    prescricoes: [],
    formulaId: "",
    formula: {
      titulo: "",
      prescricoes: "",
      categorias: null,
    },
    formulaPVis: {
      nome: "",
      dateStr: "",
      prescricaoC: {
        formulasList: [],
      },
    },
    formulaEditData: {
      dateStr: "",
      nome: "",
      id: ""
    },
    pacienteFormulasAll: [
      {
        nome: "",
        dateStr: "",
        prescricaoC: {
          formulasList: [],
        },
      },
    ],
    pacienteAnamnesesAll: [
      {
        content: "",
        dateStr: "",
        id: 0,
        index: 0
      },
    ],
    pacienteExamesAll: [{ nome: null, examText: "", observacoes: "" }],
    pacienteOrientacoesAll: [{ nome: null, orientacaoText: "", observacoes: "" }],
    pacienteOrcamentosAll: [{id: null, mov: {valor: null, desconto: null}, nome: null, dateStr: null}],
    pacienteVideoAulasAll: [
      {
        id: "",
        nome: "",
        videos: [{ url: "", nome: "", yt: "" }],
        ebooks: [{ url: "", nome: "" }],
      },
    ],
    temaVis: {
      id: "",
      nome: "",
      videos: [{ url: "", nome: "", yt: "" }],
      ebooks: [{ url: "", nome: "" }],
    },
    pacienteComprasAll: [
      {
        millis: 0,
        valor: 0,
        tipo: "",
        descr: "",
        pacotes: [{ nome: "", dataInicio: 1, area: "" }],
        forma: 0,
        descontoAct: false,
      },
    ],
    compraVis: {
      valor: 0,
      tipo: "",
      descr: "",
      pacotes: [{ nome: "", dataInicio: 1, area: "" }],
      forma: 0,
      descontoAct: false,
    },
    pacienteAgendaAll: [{ name: "", niceDate: "" }],
    prescricaoC: {
      nome: "",
      formulasList: [],
      observacoes: "",
    },
    mov: {
      valor: 0,
      tipo: "",
      descr: "",
      pacotes: [{ nome: "", dataInicio: 1, area: "" }],
      forma: 0,
      descontoAct: false,
    },
    userInfo: {},
    examP: { nome: null, examText: "", observacoes: "" },
    examPVis: { nome: "", examText: "", observacoes: "" },
    orientacaoP: { nome: null, orientacaoText: "", observacoes: "" },
    orientacaoPVis: { nome: "", orientacaoText: "", observacoes: "" },
    pacienteInfos: {
      endereco: "",
      cidade: "",
      bairro: "",
      estado: "",
      cep: "",
      cpf: "",
      profissao: "",
      estadoCivil: null,
      filhos: "",
      idadeFilhos: "",
      indicacao: "",
      pesoAtual: "",
      altura: "",
      pesoConsidera: "",
      problemasSaude: "",
      suplemento: "",
      medicamento: "",
      sangue: "",
      imagem: "",
      alergia: [],
      alergiaOutro: "",
      resultado: "",
      refeicoesHorarios: "",
      gostaCozinhar: "Sim",
      outroCozinhar: "Sim",
      fazAtividade: "Sim",
      atividadeDetalhe: "",
      cafe: "",
      cafeFrutas: "",
      cafeTolerar: [],
      lanches: [],
      frutasGostar: "",
      frutasNaoTolerar: "",
      almocoFeijao: "",
      almocoSalada: "",
      aversaoVegetal: "",
      leguminosas: "",
      pures: "",
      massas: "",
      jantar: [],
      frangoCarnePeixe: "",
      ceia: "",
      gastrointestinal: "Sim",
      unhasCabelo: "Sim",
      ansiedade: "Sim",
      imunidade: "Sim",
      sono: "Sim",
      estrassada: "Sim",
      gastrointestinalComment: "",
      unhasCabeloComment: "",
      ansiedadeComment: "",
      imunidadeComment: "",
      sonoComment: "",
      estrassadaComment: "",
      estet1: null,
      estet2: null,
      estet3: null,
      estet4: null,
      estet5: null,
      estet6: null,
      estet7: null,
      estet8: null,
      estet9: null,
      estet10: null,
    },
    imunidadeInfos: {
      opcoesAtendimento: "",
      exam1: null,
      exam2: null,
      exam3: null,
      alergiaComment: "",
      medicamentos: "",
      suplemento: "",
      preexistentes: "",
      sintomas: "",
      substanciasAlergico: "",
      alergias: "Não",
      autoImune: "Não",
      pressaoAlta: "Não",
      diabetes: "Não",
      depressao: "Não",
      criseAnsiedade: "Não",
      nivelEstresse: "Não",
      acorda: "Não",
      insonia: "Não",
      unhas: "Não",
      cabelo: "Não",
      manchas: "Não",
      candidiase: "Não",
      constipado: "Não",
      diarreia: "Não",
      nauseas: "Não",
      cansaco: "Não",
      olhos: "Não",
      febresCalafrios: "Não",
      doencasDemoram: "Não",
      amigdalite: "Não",
      infeccaoUrinaria: "Não",
      aftas: "Não",
      viroses: "Não",
      gripesFrequentes: "Não",
      endereco: "",
      cidade: "",
      bairro: "",
      estado: "",
      cep: "",
      cpf: "",
      profissao: "",
      estadoCivil: null,
      filhos: "",
      idadeFilhos: "",
      indicacao: "",
      pesoAtual: "",
      altura: "",
      dispneia: "",
      outrosSintomas: "",
      tosse: "",
      febre: "",
      dorCabeca: "",
      assimtomatico: "",
      dataSintomas: "",
      doencasResp: "",
      doencasCard: "",
      diabetes1: "",
      doencasRenais: "",
      imunosupressao: "",
      gestanteRisco: "",
      cromoImuno: "",
      contato: "",
      areaSaude: "",
    },
    imunidadeTestesDone: [],
    pacienteDados: {
      nome: "",
      sexo: "",
      gestante: false,
      dataNascimento: "1990-01-01",
      email: "",
      telefone: "",
      whatsapp: "",
      convenio: "",
      pacienteInfos: {
        altura: "",
      },
      objetivo: "",
      imagemNao: false,
      preAtendimentoToFill: false,
      imunidadeToFill: false,
    },
    orcamentoEditar: {
      mov: {
        valor: null,
        pacotes: []
      },
      id: null,
      orcamentoFim: null,
      nome: null
    },
    alimentoEdit: {
      text: "",
      value: "",
      data: {
        acido_aspartico: 0,
        acido_folico: 0,
        acido_glutamico: 0,
        acido_pantotenico: 0,
        alanina: 0,
        arginina: 0,
        calcio: 0,
        carboidrato: 0,
        cinzas: 0,
        cistina: 0,
        cobre: 0,
        codigo: 0,
        colesterol: 0,
        // created_at: "",
        era: 0,
        fenilalanina: 0,
        ferro: 0,
        fibra_alimentar: 0,
        fibra_insoluvel: 0,
        fibra_soluvel: 0,
        fonte: "",
        fosforo: 0,
        glicina: 0,
        gordura_trans: 0,
        gr_padrao: 0,
        grupo_1: "",
        grupo_2: "",
        histidina: 0,
        // idioma: "br",
        iodo: 0,
        isoleucina: 0,
        kcal: 0,
        kj: 0,
        leucina: 0,
        lipideos: 0,
        lisina: 0,
        magnesio: 0,
        manganes: 0,
        medida_caseira_padrao: "",
        metionina: 0,
        monoinsaturados: 0,
        niacina: 0,
        nome: "",
        nutricionista: "",
        piridoxina: 0,
        poliinsaturados: 0,
        potassio: 0,
        prolina: 0,
        proteina: 0,
        re: 0,
        retinol: 0,
        riboflavina: 0,
        saturados: 0,
        selenio: 0,
        serina: 0,
        sodio: 0,
        tiamina: 0,
        tirosina: 0,
        treonina: 0,
        triptofano: 0,
        umidade: 0,
        updated_at: "",
        valina: 0,
        vitamina_b: 0,
        vitamina_c: 0,
        vitamina_d: 0,
        vitamina_e: 0,
        zinco: 0,
      },
    },
    pacientePreAtendimentoData: {
      nome: "",
      dataNascimento: "",
    },
    persistPaciente: "",
    anamnesesNr: 0,
    allAnamneseNotes: [{ index: 0, dateStr: "", content: "" }],
  };
}
