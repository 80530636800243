<template>
  <div>
    <v-dialog max-width="50%" v-model="editDialog">
      <v-card>
        <v-card-title>Editar parceiro</v-card-title>
        <v-card-text>
          <v-row class="mx-4">
            <v-text-field
              readonly
              label="Email"
              v-model="editParc.email"
              disabled
            ></v-text-field>
          </v-row>
          <v-row class="mx-4">
            <v-text-field
              label="Senha"
              v-model="editParc.senha"
              readonly
              disabled
            ></v-text-field>
          </v-row>
          <v-row class="mx-4">
            <v-text-field label="Nome" v-model="editParc.nome"></v-text-field>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="2"></v-col>
            <v-col cols="3" class="d-flex justify-center">
              <v-icon large v-if="editParc.seesFormulas" color="green"
                >mdi-text-box-check-outline</v-icon
              >
              <v-icon large v-else color="red"
                >mdi-text-box-remove-outline</v-icon
              >
            </v-col>
            <v-col cols="5" class="d-flex justify-center">
              <v-checkbox
                color="#C5D13F"
                v-model="editParc.seesFormulas"
                :label="labelAcessoF(editParc.seesFormulas)"
              ></v-checkbox
            ></v-col>
            <v-col cols="2"></v-col>
          </v-row>
          <v-row class="mx-4">
            <v-col cols="2"></v-col>
            <v-col cols="3" class="d-flex justify-center">
              <v-icon large v-if="editParc.seesExames" color="green"
                >mdi-needle</v-icon
              >
              <v-icon large v-else color="red">mdi-needle</v-icon>
            </v-col>
            <v-col cols="5" class="d-flex justify-center">
              <v-checkbox
                color="#C5D13F"
                v-model="editParc.seesExames"
                :label="labelAcessoE(editParc.seesExames)"
              ></v-checkbox
            ></v-col>
            <v-col cols="2"></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#C5D13F" @click="updateParc"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="50%" v-model="addParcDialog">
      <v-card>
        <v-card-title>Adicionar parceiro</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row class="mx-4">
              <v-text-field
                label="Email"
                v-model="addParc.email"
                :rules="rules.emailRules"
              ></v-text-field>
            </v-row>
            <v-row class="mx-4">
              <v-text-field
                label="Senha"
                v-model="addParc.senha"
                :rules="rules.requiredField"
              ></v-text-field>
            </v-row>
            <v-row class="mx-4">
              <v-text-field
                :rules="rules.requiredField"
                label="Nome"
                v-model="addParc.nome"
              ></v-text-field>
            </v-row>
            <v-row class="mx-4">
              <v-col cols="2"></v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-icon large v-if="addParc.seesFormulas" color="green"
                  >mdi-text-box-check-outline</v-icon
                >
                <v-icon large v-else color="red"
                  >mdi-text-box-remove-outline</v-icon
                >
              </v-col>
              <v-col cols="5" class="d-flex align-center">
                <v-checkbox
                  color="#C5D13F"
                  v-model="addParc.seesFormulas"
                  :label="labelAcessoF(addParc.seesFormulas)"
                ></v-checkbox
              ></v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <v-row class="mx-4">
              <v-col cols="2"></v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-icon large v-if="addParc.seesExames" color="green"
                  >mdi-needle</v-icon
                >
                <v-icon large v-else color="red">mdi-needle</v-icon>
              </v-col>
              <v-col cols="5" class="d-flex align-center">
                <v-checkbox
                  color="#C5D13F"
                  v-model="addParc.seesExames"
                  :label="labelAcessoE(addParc.seesExames)"
                ></v-checkbox
              ></v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#C5D13F" @click="adicionarParc"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="processUpdate">
      <v-card v-if="savingUpdate && !savedUpdate">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-center"> Salvando ... </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else-if="savedUpdate && !savingUpdate">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
          <v-row class="d-flex justify-center"> Salvo! </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="processUpdateClose"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="40%" v-model="erroUpdate">
      <v-card>
        <v-row class="d-flex justify-center">
          <v-icon large color="red">mdi-close-circle</v-icon>
        </v-row>
        <v-card-title class="d-flex justify-center">Erro!</v-card-title>
        <v-card-text class="d-flex justify-center">
          O nome do parceiro não pode estar vazio!
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="processAdd">
      <v-card v-if="savingAdd && !savedAdd">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-center"> Salvando ... </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else-if="savedAdd && !savingAdd">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-icon x-large color="green">mdi-check-circle</v-icon>
          </v-row>
          <v-row class="d-flex justify-center"> Salvo! </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="processAddClose"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="40%" v-model="erroAdd">
      <v-card>
        <v-row class="d-flex justify-center">
          <v-icon large color="red">mdi-close-circle</v-icon>
        </v-row>
        <v-card-title class="d-flex justify-center">Erro!</v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-row class="d-flex justify-center">
            Preencher os campos da forma indicada!
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-sheet color="rgba(197, 209, 63, 0.2)"> -->
    <v-row>
      <v-card class="my-4 mr-4" width="100%" outlined>
        <v-card-title class="d-flex justify-center">
          Parceiros cadastrados</v-card-title
        >
        <v-card-text>
          <div v-if="hasParceiros && searchedParc">
            <v-row class="d-flex justify-center">
              <v-card width="95%" class="mx-2 mt-2" outlined color="#AEB6BF">
                <v-row class="d-flex align-center">
                  <v-col cols="5"> Nome: </v-col>
                  <v-col cols="2"> Adicionado no: </v-col>
                  <v-col cols="1"> Fórm. </v-col>
                  <v-col cols="1"> Exam. </v-col>
                  <v-col cols="3"> </v-col>
                </v-row>
              </v-card>
            </v-row>
            <v-row
              v-for="parceiro in parceirosList"
              :key="parceiro.email"
              class="d-flex justify-center"
            >
              <v-card width="95%" class="mx-2 mb-2" outlined>
                <v-row class="d-flex align-center">
                  <v-col cols="5">
                    {{ parceiro.nome }}
                  </v-col>
                  <v-col cols="2">
                    {{ parceiro.dataAdicionado }}
                  </v-col>
                  <v-col cols="1" v-if="parceiro.seesFormulas">
                    <v-tooltip bottom color="green">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="green" v-bind="attrs" v-on="on" icon>
                          <v-icon>mdi-text-box-check-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Acesso às fórmulas</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="1" v-else>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="red" v-bind="attrs" v-on="on" icon>
                          <v-icon>mdi-text-box-remove-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Sem acesso às fórmulas</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="1" v-if="parceiro.seesExames">
                    <v-tooltip bottom color="green">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="green" v-bind="attrs" v-on="on" icon>
                          <v-icon>mdi-needle</v-icon>
                        </v-btn>
                      </template>
                      <span>Acesso aos exames</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="1" v-else>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="red" v-bind="attrs" v-on="on" icon>
                          <v-icon>mdi-needle</v-icon>
                        </v-btn>
                      </template>
                      <span>Sem acesso aos exames</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="3" class="d-flex justify-center">
                    <v-btn
                      color="#AEB6BF"
                      @click="editParceiro(parceiro)"
                      class="brown--text"
                    >
                      <v-icon left color="#614021"> mdi-pencil </v-icon>
                      editar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </div>
          <div v-else-if="!hasParceiros && searchedParc">
            <v-row class="d-flex justify-center">
              Nenhum parceiro cadastrado
            </v-row>
          </div>
          <div v-else-if="!hasParceiros && !searchedParc">
            <v-row class="d-flex justify-center">
              <v-progress-circular
                :size="50"
                :width="5"
                color="#614021"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#C5D13F" large @click="addParcDialog = true">
            <v-icon color="#614021" left>mdi-plus-circle-outline</v-icon>
            Adicionar parceiro novo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
    <!-- </v-sheet> -->
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  data() {
    return {
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
        emailRules: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+/.test(v) || "E-mail invalido",
          (v) => /^\S*$/.test(v) || "E-mail com espaço",
        ],
      },
      searchedParc: false,
      parceirosList: [],
      editDialog: false,
      addParcDialog: false,
      editParc: {
        nome: null,
        email: null,
        senha: null,
        seesExames: false,
        seesFormulas: false,
      },
      savingUpdate: false,
      savedUpdate: false,
      erroUpdate: false,
      addParc: {
        nome: null,
        email: null,
        senha: null,
        dataAdicionado: null,
        seesExames: false,
        seesFormulas: false,
      },
      savingAdd: false,
      savedAdd: false,
      erroAdd: false,
    };
  },
  computed: {
    hasParceiros() {
      return this.parceirosList.length > 0;
    },
    processAdd() {
      return this.savingAdd || this.savedAdd;
    },
    processUpdate() {
      return this.savingUpdate || this.savedUpdate;
    },
  },
  methods: {
    labelAcessoF(bool) {
      if (bool) {
        return "Acesso às fórmulas";
      } else {
        return "Sem acesso às fórmulas";
      }
    },
    labelAcessoE(bool) {
      if (bool) {
        return "Acesso aos exames";
      } else {
        return "Sem acesso aos exames";
      }
    },
    processAddClose() {
      this.savingAdd = false;
      this.savedAdd = false;
      this.getParceiros();
    },
    processUpdateClose() {
      this.savingUpdate = false;
      this.savedUpdate = false;
    },
    async adicionarParc() {
      if (this.$refs.form.validate()) {
        this.savingAdd = true;

        await firebase
          .auth()
          .createUserWithEmailAndPassword(
            this.addParc.email.toLowerCase(),
            this.addParc.senha
          )
          .then((user) => {
            console.log(user);
          })
          .catch((error) => {
            console.log(error);
            console.log("error fauth");
          });

        console.log("cadastro completo");

        const d = new Date();
        var yearStr = d.getFullYear().toString();
        var monthStr;
        var dayStr;
        const month = d.getMonth() + 1;
        const day = d.getDate();
        if (month > 9) {
          monthStr = month.toString();
        } else {
          monthStr = "0" + month.toString();
        }
        if (day > 9) {
          dayStr = day.toString();
        } else {
          dayStr = "0" + day.toString();
        }
        const dataAdicionado = dayStr + "/" + monthStr + "/" + yearStr;

        await firebase
          .firestore()
          .collection("users")
          .doc(this.addParc.email.toLowerCase())
          .set({
            isParceiro: true,
            introducedBy: sessionStorage.getItem("current-user"),
            email: this.addParc.email,
            nome: this.addParc.nome,
            senha: this.addParc.senha,
            dataAdicionado: dataAdicionado,
            seesExames: this.addParc.seesExames,
            seesFormulas: this.addParc.seesFormulas,
          });

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("parceiros")
          .doc(this.addParc.email)
          .set({
            email: this.addParc.email,
            senha: this.addParc.senha,
            nome: this.addParc.nome,
            dataAdicionado: dataAdicionado,
            seesExames: this.addParc.seesExames,
            seesFormulas: this.addParc.seesFormulas,
          });
        this.savingAdd = false;
        this.savedAdd = true;
      } else {
        this.erroAdd = true;
      }
    },
    async updateParc() {
      if (this.editParc.nome !== null && this.editParc.nome !== "") {
        this.savingUpdate = true;
        await firebase
          .firestore()
          .collection("users")
          .doc(this.editParc.email)
          .update(this.editParc);

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("parceiros")
          .doc(this.editParc.email)
          .update(this.editParc);
        this.savingUpdate = false;
        this.savedUpdate = true;
      } else {
        this.erroUpdate = true;
      }
    },
    editParceiro(parc) {
      this.editParc = parc;
      this.editDialog = true;
    },
    async getParceiros() {
      const parceirosGot = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("parceiros")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      this.searchedParc = true;
      this.parceirosList = parceirosGot;
    },
  },
  mounted() {
    this.getParceiros();
  },
};
</script>
