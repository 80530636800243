<template>
  <div class="d-flex flex-column justify-center">
    <v-dialog v-model="receitaVisDialog">
      <v-card>
        <v-card-title class="text-p black--text">
          "{{ receita.titulo }}"
        </v-card-title>
        <v-card-text class="ml-6">
          <v-row>
            <strong> Tempo de Preparo: </strong> {{ receita.tempoPreparo }}
          </v-row>
          <v-row> <strong> Modo de Preparo: </strong> </v-row>
          <v-row> {{ receita.modoPreparo }} </v-row>
          <v-row>
            <strong> Rendimento: </strong> {{ receita.rendimento }}
          </v-row>
          <v-row class="mt-1">
            <strong> Ingredientes: </strong>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Alimento</th>
                  <th class="text-left">Quantidade</th>
                  <th class="text-left">Medida caseira</th>
                  <th class="text-left">Gramas totais</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="alimentoR in receita.alimentosList"
                  :key="alimentoR.nome"
                >
                  <td>{{ alimentoR.nome }}</td>
                  <td>{{ alimentoR.quantidade }}</td>
                  <td>{{ alimentoR.medida }}</td>
                  <td>{{ alimentoR.gramas }}g</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
      class="d-inline-block mr-3"
      width="100%"
      elevation="3"
      color="grey lighten-2"
    >
      <v-container>
        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                @click="receitaVisDialog = true"
              >
                <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
              </v-btn>
            </template>
            <span>Visualizar Receita</span>
          </v-tooltip>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2">
                <v-icon color="#614021">{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir Dieta</span>
          </v-tooltip> -->

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#c5d13f" v-bind="attrs" v-on="on">
                <v-icon color="#614021">{{ icons.mdiDownload }}</v-icon>
              </v-btn>
            </template>
            <span>Baixar Receita</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#c5d13f"
                v-bind="attrs"
                v-on="on"
                class="mx-4"
                @click="saveAsNew"
              >
                <v-icon color="#614021">{{ icons.mdiContentSaveAll }}</v-icon>
              </v-btn>
            </template>
            <span>Salvar como Receita Nova</span>
          </v-tooltip>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#c5d13f" v-bind="attrs" v-on="on">
                <v-icon color="#614021">{{ icons.mdiEmailSendOutline }}</v-icon>
              </v-btn>
            </template>
            <span>Enviar Receita ao Paciente</span>
          </v-tooltip> -->
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#c5d13f"
                v-bind="attrs"
                v-on="on"
                @click="saveOverride"
              >
                <v-icon color="#614021">{{ icons.mdiContentSaveEdit }}</v-icon>
              </v-btn>
            </template>
            <span>Salvar alterações na mesma receita</span>
          </v-tooltip> -->
        </v-card-actions>

        <v-divider></v-divider>

        <v-row class="d-flex justify-center">
          <v-chip color="#c5d13f" class="d-flex align-center mt-4">
            <v-row class="px-2 black--text">
              <p class="px-2 mt-4">
                KCAL: <strong>{{ receita.KCAL.toFixed(1) }}</strong>
              </p>
              <p class="px-2 mt-4">
                CHO: <strong>{{ receita.CHO.toFixed(1) }}</strong>
              </p>
              <p class="px-2 mt-4">
                PTN: <strong>{{ receita.PTN.toFixed(1) }}</strong>
              </p>
              <p class="px-2 mt-4">
                LIP: <strong>{{ receita.LIP.toFixed(1) }}</strong>
              </p>
              <p class="px-2 mt-4">
                COLEST: <strong>{{ receita.COL.toFixed(1) }}</strong>
              </p>
              <p class="px-2 mt-4">
                Na: <strong>{{ receita.SOD.toFixed(1) }}</strong>
              </p>
              <p class="px-2 mt-4">
                FIB: <strong>{{ receita.FIB.toFixed(1) }}</strong>
              </p>
            </v-row>
          </v-chip>
          <v-dialog v-model="dialog" width="90%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="#c5d13f"
                class="mx-2 mt-4"
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-icon color="#614021" v-bind="attrs" v-on="on">{{
                      icons.mdiDotsHorizontal
                    }}</v-icon>
                  </template>
                  <span>Mais informações nutricionais</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="h6"
                  >Informações nutricionais detalhadas da dieta</span
                >
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="nutrient in allNutrients"
                          :key="nutrient.codigo"
                        >
                          <td>{{ nutrient.nome }}:</td>
                          <td>
                            <strong>{{
                              receita[nutrient.codigo].toFixed(2)
                            }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>
              </v-card-text>
              <v-card-actions class="d-flex justify-center">
                <v-btn color="#c5d13f" text @click="dialog = false">
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-card>

    <ReceitaForm></ReceitaForm>
    <!-- <template>
      <v-snackbar
        timeout="-1"
        elevation="0"
        bottom
        right
        multi-line
        v-model="snack"
        color="rgba(0, 0, 0, 0)"
      >
        <div>
          <v-row class="d-flex justify-center align-center">
            <v-chip color="#c5d13f" class="d-flex align-center">
              <v-row class="px-2 black--text">
                <p class="px-2 mt-4">
                  KCAL: <strong>{{ receita.KCAL.toFixed(2) }}</strong>
                </p>
                <p class="px-2 mt-4">
                  CHO: <strong>{{ receita.CHO.toFixed(2) }}</strong>
                </p>
                <p class="px-2 mt-4">
                  LIP: <strong>{{ receita.LIP.toFixed(2) }}</strong>
                </p>
                <p class="px-2 mt-4">
                  COL: <strong>{{ receita.COL.toFixed(2) }}</strong>
                </p>
                <p class="px-2 mt-4">
                  SOD: <strong>{{ receita.SOD.toFixed(2) }}</strong>
                </p>
                <p class="px-2 mt-4">
                  FIB: <strong>{{ receita.FIB.toFixed(2) }}</strong>
                </p>
                <p class="px-2 mt-4">
                  PTN: <strong>{{ receita.PTN.toFixed(2) }}</strong>
                </p>
              </v-row>
            </v-chip>
            <v-btn
              fab
              color="#c5d13f"
              class="mx-2 my-1"
              x-small
              @click="dialog = true"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }"
                  ><v-icon color="#614021" v-bind="attrs" v-on="on">{{
                    icons.mdiDotsHorizontal
                  }}</v-icon>
                </template>
                <span>Mais informações nutricionais</span>
              </v-tooltip>
            </v-btn>
          </v-row>
        </div>
      </v-snackbar>
    </template> -->
  </div>
</template>

<script>
import firebase from "firebase";
import {
  mdiCalendar,
  mdiContentSaveAll,
  mdiContentSaveEdit,
  mdiPrinter,
  mdiEye,
  mdiEmailSendOutline,
  mdiDownload,
  mdiMagnify,
  mdiDotsHorizontal,
} from "@mdi/js";

// import jspdf from "jspdf";
// import "jspdf-autotable";

// components imports
import ReceitaForm from "@/components/home/receitas/ReceitaForm";
// import router from "../router";

export default {
  components: { ReceitaForm },
  data: function () {
    return {
      receitaVisDialog: false,
      snack: true,
      table: false,
      dialog: false,
      snackbar: true,
      // documentsList: [],
      icons: {
        mdiCalendar,
        mdiContentSaveAll,
        mdiContentSaveEdit,
        mdiPrinter,
        mdiEye,
        mdiEmailSendOutline,
        mdiDownload,
        mdiMagnify,
        mdiDotsHorizontal,
      },
      progressDialog: false,
      saved: false,
      allNutrients: [
        { codigo: "KCAL", nome: "Calorias" }, //NaN
        { codigo: "PTN", nome: "Proteina" },
        { codigo: "LIP", nome: "Lipideos" },
        { codigo: "COL", nome: "Colesterol" },
        { codigo: "CHO", nome: "Carboidrato" },
        { codigo: "FIB", nome: "Fibra alimentar" },
        { codigo: "fibra_soluvel", nome: "Fibra solúvel" },
        { codigo: "fibra_insoluvel", nome: "Fibra insolúvel" },
        { codigo: "cinzas", nome: "Cinzas" },
        { codigo: "calcio", nome: "Cálcio" },
        { codigo: "magnesio", nome: "Magnésio" },
        { codigo: "manganes", nome: "Manganés" },
        { codigo: "fosforo", nome: "Fósforo" },
        { codigo: "ferro", nome: "Ferro" },
        { codigo: "SOD", nome: "Sódio" },
        { codigo: "potassio", nome: "Potassio" },
        { codigo: "cobre", nome: "Cobre" },
        { codigo: "zinco", nome: "Zinco" },
        { codigo: "retinol", nome: "Retinol" },
        { codigo: "re", nome: "RE" },
        { codigo: "era", nome: "ERA" },
        { codigo: "iodo", nome: "Iodo" },
        { codigo: "selenio", nome: "Selénio" },
        { codigo: "acido_folico", nome: "Ácido fólico" },
        { codigo: "acido_pantotenico", nome: "Ácido pantoténico" },
        { codigo: "tiamina", nome: "Tiamina" },
        { codigo: "riboflavina", nome: "Riboflavina" },
        { codigo: "piridoxina", nome: "Piridoxina" },
        { codigo: "niacina", nome: "Niacina" },
        { codigo: "vitamina_c", nome: "Vitamina C" },
        { codigo: "vitamina_d", nome: "Vitamina D" },
        { codigo: "vitamina_e", nome: "Vitamina E" },
        { codigo: "vitamina_b12", nome: "Vitamina B12" },
        { codigo: "saturados", nome: "Saturados" },
        { codigo: "monoinsaturados", nome: "Mono-insaturados" },
        { codigo: "poliinsaturados", nome: "Poli-insaturados" },
        { codigo: "gordura_trans", nome: "Gordura trans" },
        { codigo: "triptofano", nome: "Triptofano" },
        { codigo: "treonina", nome: "Treonina" },
        { codigo: "isoleucina", nome: "Isoleucina" },
        { codigo: "leucina", nome: "Leucina" },
        { codigo: "lisina", nome: "Lisina" },
        { codigo: "metionina", nome: "Metionina" },
        { codigo: "cistina", nome: "Cistina" },
        { codigo: "fenilalanina", nome: "Fenilalanina" },
        { codigo: "tirosina", nome: "Tirosina" },
        { codigo: "valina", nome: "Valina" },
        { codigo: "arginina", nome: "Arginina" },
        { codigo: "histidina", nome: "Histidina" },
        { codigo: "alanina", nome: "Alanina" },
        { codigo: "acido_aspartico", nome: "Ácido aspartico" },
        { codigo: "acido_glutamico", nome: "Ácido glutámico" },
        { codigo: "glicina", nome: "Glícina" },
        { codigo: "prolina", nome: "Prolina" },
        { codigo: "serina", nome: "Serina" },
        { codigo: "umidade", nome: "Umidade" },
      ],
    };
  },
  computed: {
    receita() {
      return this.$store.state.firestore.receita;
    },
    alimentos() {
      return this.$store.state.firestore.alimentos;
    },
  },
  methods: {
    saveAsNew: function () {
      this.progressDialog = true;
      this.receita.autor = "admin";
      firebase
        .firestore()
        // .collection("users")
        // .doc(sessionStorage.getItem("current-user"))
        .collection("receitas")
        .add(this.receita);
      this.saved = true;

      // setTimeout(() => {
      //   router.replace("/ReceitasSalvas");
      // }, 2000);
    },
  },
  mounted() {
    if (this.$store.state.firestore.alimentos.length === 0) {
      this.$store.dispatch("firestore/getAlimentos");
    }
    // setTimeout(() => {
    //   this.createListWithNames();
    // }, 2000);
  },
};
</script>

<style>
</style>