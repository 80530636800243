export function setUser(state, payload) {
    state.user = payload;
}

export function setDefaultUserPaciente(state, payload) {
    state.defaultUserPacienteObj = payload;
}

export function setDefaultUser(state, payload) {
    state.defaultUserObj = payload;
}

export function setUserInfo(state, payload) {
    state.userInfo = payload;
}

// export function setPacienteCrn(state, payload) {
//     state.pacienteCrn = payload;
// }

export function setFuncionarioOnline(state, payload) {
    state.funcionarioOnline = payload;
}

export function setDietaAutorObj(state, payload) {
    state.dietaAutorObj = payload;
}

// export function setPacienteNutriUseLogo(state, payload) {
//     state.pacienteNutriUseLogo = payload;
// }

// export function setPacienteNutriLogo(state, payload) {
//     state.pacienteNutriLogo = payload;
// }

// export function setPacienteNutriSign(state, payload) {
//     state.pacienteNutriSign = payload;
// }

export function setPacienteNutriName(state, payload) {
    state.pacienteNutriName = payload;
}


export function setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
}

export function setAlertsEmailOk(state, payload) {
    state.alerts.emailOk = payload;
}

export function setAlertsLoginOk(state, payload) {
    state.alerts.loginOk = payload;
}

export function setResetEmailsent(state, payload) {
    state.alerts.resetEmailsent = payload;
}

export function setNomeClinicaExists(state, payload) {
    state.alerts.nomeClinicaExists = payload;
}

export function setAlimentos(state, payload) {
    state.alimentos = payload;
}

export function setClubeDeVantagens(state, payload) {
    state.clubeDeVantagens = payload;
}

export function setDietasSalvas(state, payload) {
    state.dietasSalvas = payload;
}

export function setDietasPaciente(state, payload) {
    state.dietasPaciente = payload;
}

export function setAnyUnansweredN(state, payload) {
    state.anyUnansweredN = payload;
}

export function setFuncionarios(state, payload) {
    state.funcionarios = payload;
}

// export function setPacienteNutri(state, payload) {
//     state.pacienteNutri = payload;
// }

// export function setPacienteNutriLogo(state, payload) {
//     state.pacienteNutriLogo = payload;
// }

// export function setPacienteNutriSign(state, payload) {
//     state.pacienteNutriSign = payload;
// }

export function setFormulas(state, payload) {
    state.formulas = payload;
}

export function setExamList(state, payload) {
    state.examList = payload;
}

export function setOrientacaoList(state, payload) {
    state.orientacaoList = payload;
}

export function setReceitas(state, payload) {
    state.receitas = payload;
}

export function setReceitaVis(state, payload) {
    state.receitaVis = payload;
}

export function setPacientes(state, payload) {
    state.pacientes = payload;
}

export function setPacientePreAtendimento(state, payload) {
    state.pacientePreAtendimento = payload;
}

export function setOrcamentoEditar(state, payload) {
    state.orcamentoEditar = payload;
}

export function setPacientePreAtendimentoData(state, payload) {
    state.pacientePreAtendimentoData = payload;
}

export function setPacienteAtendimento(state, payload) {
    state.pacienteAtendimento = payload;
}

export function setAlimentoEdit(state, payload) {
    state.alimentoEdit = payload;
}

export function setPacienteInfos(state, payload) {
    state.pacienteInfos = payload;
}

export function setImunidadeInfos(state, payload) {
    state.imunidadeInfos = payload;
}

export function setImunidadeTestesDone(state, payload) {
    state.imunidadeTestesDone = payload;
}

export function setPacienteDados(state, payload) {
    state.pacienteDados = payload;
}

export function setAnamnesesNr(state, payload) {
    state.anamnesesNr = payload;
}

export function setAllAnamneseNotes(state, payload) {
    state.allAnamneseNotes = payload;
}

export function pacienteAtendimentosList(state, payload) {
    state.pacienteAtendimentosList = payload;
}

export function setPacienteTimeline(state, payload) {
    state.pacienteTimeline = payload;
}

export function setPacienteVerHistorico(state, payload) {
    state.pacienteVerHistorico = payload;
}

export function setPacienteDietaRefs(state, payload) {
    state.pacienteDietaRefs = payload
}

// DIETA
export function setDietaStatus(state, payload) {
    state.dietaStatus = payload;
}

export function setDietaRefeicoes(state, payload) {
    state.dietaRefeicoes = payload;
}

export function setReceita(state, payload) {
    state.receita = payload;
}

export function setPrescricaoC(state, payload) {
    state.prescricaoC = payload;
}

export function setFormula(state, payload) {
    state.formula = payload;
}

export function setFormulaVis(state, payload) {
    state.formulaVis = payload;
}

export function setPacienteFormulasAll(state, payload) {
    state.pacienteFormulasAll = payload;
}

export function setPacienteAnamnesesAll(state, payload) {
    state.pacienteAnamnesesAll = payload;
}

export function setPacienteVideoAulasAll(state, payload) {
    state.pacienteVideoAulasAll = payload;
}

export function setPacienteAgendaAll(state, payload) {
    state.pacienteAgendaAll = payload;
}

export function setPacienteComprasAll(state, payload) {
    state.pacienteComprasAll = payload;
}

export function setCompraVis(state, payload) {
    state.compraVis = payload;
}

export function setTemaVis(state, payload) {
    state.temaVis = payload;
}

export function setPacienteExamesAll(state, payload) {
    state.pacienteExamesAll = payload;
}

export function setExamP(state, payload) {
    state.examP = payload;
}

export function setExamPVis(state, payload) {
    state.examPVis = payload;
}

export function setPacienteOrientacoesAll(state, payload) {
    state.pacienteOrientacoesAll = payload;
}

export function setPacienteOrcamentosAll(state, payload) {
    state.pacienteOrcamentosAll = payload;
}

export function setOrientacaoP(state, payload) {
    state.orientacaoP = payload;
}

export function setOrientacaoPVis(state, payload) {
    state.orientacaoPVis = payload;
}

export function setReceitaId(state, payload) {
    state.receitaId = payload;
}

export function setFormulaId(state, payload) {
    state.formulaId = payload;
}

export function setFormulaPVis(state, payload) {
    state.formulaPVis = payload;
}

export function setFormulaEditData(state, payload) {
    state.formulaEditData = payload;
}

// export function setPlanoToAcquire (state, payload) {
//     state.planoToAcquire = payload;
// }

export function setCurrentDialog(state, payload) {
    state.currentDialog = payload;
}

export function setHiddenInfo(state, payload) {
    state.hiddenInfo = payload;
}

export function setDaysIn(state, payload) {
    state.daysIn = payload;
}

export function setAlimentoSubstitutions(state, payload) {
    state.alimentoSubstitutions.list = payload.list;
    state.alimentoSubstitutions.nome = payload.nome;
    state.alimentoSubstitutions.gramas = payload.gramas;
}

export function setDietaDocId(state, payload) {
    state.dietaDocId = payload;
}

export function setPrescricoes(state, payload) {
    state.prescricoes = payload;
}

export function setDietaTitulo(state, payload) {
    state.dietaTitulo = payload;
}

export function setAgendaPacienteObj(state, payload) {
    state.agendaPacienteObj = payload;
}

export function setAgendaPacienteEmail(state, payload) {
    state.agendaPacienteEmail = payload;
}

export function setPreAtendimentoToFill(state, payload) {
    state.preAtendimentoToFill = payload;
}

export function setVisEvent(state, payload) {
    state.visEvent = payload;
}

export function setMov(state, payload) {
    state.mov = payload;
}

export function setCaixa(state, payload) {
    state.caixa = payload;
}

export function setPersistPaciente(state, payload) {
    state.persistPaciente = payload;
}

export function dummySetter(state, payload) {
    state.dummy = payload;
}