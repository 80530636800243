<template>
  <v-container>
    <div class="d-flex flex-column">
      <v-card
        class="pa-5 my-1"
        v-for="(refeicao, refIndex) in refeicoes"
        :key="refIndex"
        tile
        style="background-color: rgba(197, 209, 63, 0.6)"
      >
        <div class="d-flex flex-column">
          <v-row
            class="d-flex justify-center pa-1"
            style="background-color: #00"
          >
            <v-btn
              rounded
              outlined
              @click="removeRefeicao(refeicao)"
              x-small
              v-show="refeicoes.length !== 1"
              color="red"
              class="mx-1"
            >
              <v-icon left small color="red">mdi-trash-can-outline</v-icon>
              Remover Refeição
            </v-btn>
            <v-btn
              rounded
              outlined
              @click="insertRefeicao(refIndex)"
              x-small
              color="green"
              v-show="refIndex <= refeicoes.length - 1"
              class="mx-1"
            >
              <v-icon left small color="green">mdi-arrow-collapse-up</v-icon>
              Inserir Refeição
            </v-btn>
            <!-- <v-btn
              rounded
              outlined
              @click="duplicateRefeicao(refeicao, refIndex)"
              x-small
              color="blue"
              class="mx-1"
            >
              <v-icon left small color="blue">mdi-plus-circle-multiple</v-icon>
              Duplicar Refeição
            </v-btn> -->
          </v-row>

          <v-row class="d-flex pa-2" style="background-color: #00">
            <v-card width="100%" class="d-flex justify-center mt-2">
              <v-row class="d-flex justify-center mx-4">
                <v-text-field
                  dense
                  v-model="refeicoes[refIndex].nomeRefeicao"
                  label="Nome da Refeição"
                  :rules="inputRules"
                ></v-text-field>
                <v-divider vertical class="mx-2"></v-divider>
                <v-text-field
                  dense
                  v-model="refeicoes[refIndex].horarioRefeicao"
                  label="Horário"
                  :rules="inputRules"
                ></v-text-field>
              </v-row>
            </v-card>
          </v-row>

          <!-- lista de alimentos  -->

          <div
            class="d-flex align-center justify-space-around"
            v-for="(alimento, alimentoIndex) in refeicoes[refIndex]
              .alimentosList"
            :key="alimentoIndex"
          >
            <v-autocomplete
              label="Alimento"
              no-data-text="..."
              :items="alimentos"
              v-model="alimento.alimentoId"
              style="width: 100%; max-width: 250px"
              @change="
                onAlimentoSelected(refIndex, alimentoIndex, alimento.alimentoId)
              "
            ></v-autocomplete>

            <v-text-field
              label="Medida caseira"
              v-model="alimento.medida"
              :rules="inputAlimentoRules"
            ></v-text-field>
            <v-text-field
              label="Qtd."
              v-model="alimento.quantidade"
              :rules="inputQtdRules"
              @change="
                onQtdChange(
                  refIndex,
                  alimentoIndex,
                  alimento.alimentoId,
                  alimento.quantidade
                )
              "
            ></v-text-field>
            <v-text-field
              label="Gramas total"
              v-model="alimento.gramas"
              :rules="inputAlimentoRules"
              @change="
                onGrChange(
                  refIndex,
                  alimentoIndex,
                  alimento.alimentoId,
                  alimento.gramas
                )
              "
            ></v-text-field>

            <!-- <v-dialog
              v-model="dialog"
              scrollable
              max-width="500px"
            > -->
            <!-- v-if="alimento.substitution.length !== 0" -->
            <!-- <template v-slot:activator="{ on, attrs }"> -->
            <v-btn
              elevation="2"
              rounded
              icon
              outlined
              raised
              x-small
              color="black"
              class="pa-1"
              @click="substDialog(refIndex, alimentoIndex)"
            >
              <!-- v-bind="attrs"
                  v-on="on" -->
              <v-icon>{{ icons.mdiAutorenew }}</v-icon>
            </v-btn>
            <!-- </template> -->
            <!-- <div v-else></div> -->
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="refeicao.alimentosList.length > 1"
                  elevation="2"
                  rounded
                  icon
                  outlined
                  raised
                  x-small
                  color="red"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="removeAlimento(refIndex, alimento)"
                >
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </template>
              <span>remover alimento</span>
            </v-tooltip>

            <v-tooltip bottom color="green">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="
                    alimentoIndex ===
                      refeicoes[refIndex].alimentosList.length - 1
                  "
                  elevation="2"
                  rounded
                  icon
                  outlined
                  raised
                  x-small
                  color="green"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="addAlimento(refIndex)"
                >
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </template>
              <span>adicionar novo alimento</span>
            </v-tooltip>
          </div>

          <div
            class="d-flex align-center justify-space-around"
            v-for="(receita, receitaIndex) in refeicoes[refIndex].receitasList"
            :key="receitaIndex"
          >
            <v-autocomplete
              label="Receita"
              no-data-text="..."
              :items="receitas"
              v-model="receita.id"
              style="width: 100%; max-width: 500px"
              @change="onReceitaSelected(refIndex, receitaIndex, receita.id)"
            ></v-autocomplete>

            <v-text-field
              label="Porções"
              v-model="receita.porcoes"
              :rules="inputQtdRules"
              @change="
                onPorcoesChange(
                  refIndex,
                  receitaIndex,
                  receita.id,
                  receita.porcoes
                )
              "
            ></v-text-field>

            <v-text-field
              label="Rendimento"
              readonly
              v-model="receita.rendimento"
            ></v-text-field>

            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="refeicoes[refIndex].receitasList.length > 1"
                  elevation="2"
                  rounded
                  icon
                  outlined
                  raised
                  x-small
                  color="red"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="removeReceita(refIndex, receita)"
                >
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </template>
              <span>remover receita</span>
            </v-tooltip>

            <v-tooltip bottom color="green">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="
                    receitaIndex === refeicoes[refIndex].receitasList.length - 1
                  "
                  elevation="2"
                  rounded
                  icon
                  outlined
                  raised
                  x-small
                  color="green"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="addReceita(refIndex)"
                >
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </template>
              <span>adicionar nova receita</span>
            </v-tooltip>
          </div>

          <v-textarea
            v-model="refeicoes[refIndex].observacao"
            solo
            label="Observações"
          ></v-textarea>

          <v-row class="d-flex justify-center px-2 black--text">
            <p class="px-2">
              KCAL: <strong>{{ refeicoes[refIndex].KCAL.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              CHO: <strong>{{ refeicoes[refIndex].CHO.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              PTN: <strong>{{ refeicoes[refIndex].PTN.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              LIP: <strong>{{ refeicoes[refIndex].LIP.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              COLEST: <strong>{{ refeicoes[refIndex].COL.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              Na: <strong>{{ refeicoes[refIndex].SOD.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              FIB: <strong>{{ refeicoes[refIndex].FIB.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              Percentual de calorias na refeição:
              <strong>{{ calculatePercent(refeicoes[refIndex].KCAL) }}%</strong>
            </p>
          </v-row>
        </div>
      </v-card>

      <v-dialog v-model="dialog" scrollable max-width="500px">
        <v-card>
          <v-card-title
            >Substituições para {{ alimentoSubstitutions.gramas }}g de
            {{ alimentoSubstitutions.nome }}</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text style="height: 300px">
            <v-checkbox
              v-model="freetextAct"
              label="outra sugestão..."
              color="#614021"
              dense
            >
            </v-checkbox>
            <v-text-field
              v-if="freetextAct"
              label="outra sugestão..."
              v-model="freetext"
            ></v-text-field>
            <v-checkbox
              v-for="(sub, subIndex) in alimentoSubstitutions.list"
              v-model="sub.chosen"
              :key="subIndex"
              :label="
                `${sub.gramas}g de ${sub.nome} (${sub.qtdSub} ${sub.medida_caseira_padrao})`
              "
              :value="sub.chosen"
              dense
              color="#614021"
            ></v-checkbox>
            <!-- </v-radio-group> -->
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="#614021" text @click="dialog = false"> Fechar </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="#c5d13f"
              dense
              @click="
                addSubstitutions(substRefIndex, substAlimentoIndex, freetext)
              "
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-btn
      color="green"
      class="mx-2"
      rounded
      outlined
      x-small
      @click="addRefeicao()"
    >
      <v-icon left small color="green">mdi-arrow-collapse-down</v-icon>
      Acrescentar refeição
    </v-btn>
  </v-container>
</template>

<script>
import { mdiClose, mdiPlus, mdiAutorenew } from "@mdi/js";

export default {
  data: function() {
    return {
      substRefIndex: null,
      substAlimentoIndex: null,
      freetext: "",
      freetextAct: false,
      icons: { mdiClose, mdiPlus, mdiAutorenew },
      alimentoSubstitutions: {
        nome: "",
        gramas: 0,
        list: [
          {
            chosen: false,
            qtdSub: 0,
            gramas: 0,
            medida_caseira_padrao: "",
            nome: "",
            alimentoId: null,
            data: {
              medida_caseira_padrao: "",
              nome: "",
              // alimentoId: 0,
            },
          },
        ],
      },
      refeicoes: [],
      inputRules: [],
      inputAlimentoRules: [],
      inputQtdRules: [(v) => /^[0-9]+$/.test(v) || "Use apenas números"],
      demaisCodigos: [
        "fibra_soluvel",
        "fibra_insoluvel",
        "cinzas",
        "calcio",
        "magnesio",
        "manganes",
        "fosforo",
        "ferro",
        "sodio",
        "potassio",
        "cobre",
        "zinco",
        "retinol",
        "re",
        "era",
        "iodo",
        "selenio",
        "acido_folico",
        "acido_pantotenico",
        "tiamina",
        "riboflavina",
        "piridoxina",
        "niacina",
        "vitamina_c",
        "vitamina_d",
        "vitamina_e",
        "vitamina_b12",
        "saturados",
        "monoinsaturados",
        "poliinsaturados",
        "gordura_trans",
        "triptofano",
        "treonina",
        "isoleucina",
        "leucina",
        "lisina",
        "metionina",
        "cistina",
        "fenilalanina",
        "tirosina",
        "valina",
        "arginina",
        "histidina",
        "alanina",
        "acido_aspartico",
        "acido_glutamico",
        "glicina",
        "prolina",
        "serina",
        "umidade",
      ],
      dialogm1: 0,
      dialog: false,
      alfa: 1021,
    };
  },
  computed: {
    alimentos() {
      return this.$store.state.firestore.alimentos;
    },
    receitas() {
      return this.$store.state.firestore.receitas;
    },
    dietaStatus() {
      return this.$store.state.firestore.dietaStatus;
    },
    // alimentoSubstitutions() {
    //   return this.$store.state.firestore.alimentoSubstitutions;
    // },
  },
  methods: {
    calculatePercent: function(currentValue) {
      return ((currentValue * 100) / this.dietaStatus.KCAL).toFixed(2);
    },
    getAlimentosWithSameGroup(
      // refeicoes,
      refeicaoIndex,
      alimentoIndex,
      alimentoInfo
    ) {
      var theName;
      if (alimentoInfo.data != undefined) {
        theName = alimentoInfo.data.nome;
      } else {
        theName = alimentoInfo.nome;
      }
      console.log(theName);
      var filterNome = function(alimento) {
        if (alimento.data.nome == theName) {
          return true;
        } else {
          return false;
        }
      };

      var alimentosNome = this.alimentos.filter(filterNome);
      var alimentoOriginal = alimentosNome[0];
      var filter = function(alimento) {
        if (
          alimento.data.grupo_1 == alimentoOriginal.data.grupo_1 &&
          // && calculateDiference(alimento.data.kcal, alimentoInfo.data.kcal)
          alimento.data.nome != alimentoOriginal.data.nome
        ) {
          return true;
        } else {
          return false;
        }
      };
      var alimentosWithSameGroupU = this.alimentos.filter(filter);
      console.log("alimentoInfo", alimentoInfo);
      var alimentosWithSameGroup = alimentosWithSameGroupU.map(function(
        alimento
      ) {
        var chosenBool = false;
        if (alimentoInfo.toSubstitute != undefined) {
          chosenBool = alimentoInfo.toSubstitute
            .map(function(l) {
              return l.nome;
            })
            .includes(alimento.data.nome);
        }
        let factorX = alimentoOriginal.data.kcal / alimento.data.kcal;
        alimento.gramas = alimentoOriginal.data.gr_padrao * factorX;
        alimento.gramas = alimento.gramas.toFixed();
        alimento.qtdSub = alimento.gramas / alimento.data.gr_padrao;
        alimento.qtdSub = alimento.qtdSub.toFixed(1);
        alimento.chosen = chosenBool;
        // alimentoInfo.toSubstitute
        //   .map(function (l) {
        //     return l.nome;
        //   })
        //   .includes(alimento.data.nome);

        return {
          qtdSub: alimento.qtdSub,
          gramas: alimento.gramas,
          chosen: alimento.chosen,
          nome: alimento.data.nome,
          medida_caseira_padrao: alimento.data.medida_caseira_padrao,
          // alimentoId: alimento.data.alimentoId
          // data: {
          //   nome: alimento.data.nome,
          //   medida_caseira_padrao: alimento.data.medida_caseira_padrao,
          //   // alimentoId: alimento.data.alimentoId,
          // },
        };
      });

      this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].substitution = alimentosWithSameGroup;
    },
    substDialog(refeicaoIndex, alimentoIndex) {
      this.substAlimentoIndex = alimentoIndex;
      this.substRefIndex = refeicaoIndex;
      console.log(
        "doing it",
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex]
      );
      if (
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex]
          .substitution == undefined
      ) {
        this.getAlimentosWithSameGroup(
          // this.refeicoes,
          refeicaoIndex,
          alimentoIndex,
          this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex]
        );
      }

      if (
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex]
          .toSubstitute !== undefined
      ) {
        var freetextObjList = this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex]
          .toSubstitute.filter(function (a) {
            return a.medida_caseira_padrao == "freetext"
          });
        if (freetextObjList.length > 0) {
          this.freetextAct = true;
          this.freetext = freetextObjList[0].nome;
        }
      }

      this.alimentoSubstitutions = {
        nome: this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].nome,
        gramas: this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex]
          .gramas,
        list: this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex]
          .substitution, //substitionList,
      };

      this.dialog = true;
    },
    addSubstitutions(refeicaoIndex, alimentoIndex, freetext) {
      var chosenFilter = function(alimento) {
        if (alimento.chosen) {
          return true;
        } else {
          return false;
        }
      };

      var freetextObj = {
        nome: "",
        gramas: 0,
        qtdSub: 0,
        medida_caseira_padrao: "freetext",
        chosen: false,
      };
      var textArr = [];
      if (
        this.freetextAct &&
        freetext !== "" &&
        freetext !== null &&
        freetext !== undefined
      ) {
        freetextObj.nome = freetext;
        freetextObj.chosen = true;
        textArr.push(freetextObj);
      }
      var listArr = this.alimentoSubstitutions.list.filter(chosenFilter);

      this.refeicoes[refeicaoIndex].alimentosList[
        alimentoIndex
      ].toSubstitute = [...textArr, ...listArr];

      if (
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].toSubstitute
          .length > 0
      ) {
        setTimeout(() => {
          // this.$store.dispatch("firestore/dietaStatus", {
          //   refeicoes: this.refeicoes,
          // });
          console.log("addedSubs",
            this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex]
              .toSubstitute
          );
          this.dialog = false;
          this.freetext = "";
          this.freetextAct = false;
        }, 1000);
      }
    },
    onAlimentoSelected(refeicaoIndex, alimentoIndex, alimentoId) {
      console.log([refeicaoIndex, alimentoIndex, alimentoId]);

      var alimento = this.alimentos.find((item) => item.value == alimentoId);
      // this.getAlimentosWithSameGroup(
      //   this.refeicoes,
      //   refeicaoIndex,
      //   alimentoIndex,
      //   alimento
      // );

      if (alimento !== undefined) {
        console.log(alimento);
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].alimentoId =
          alimento.value;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].nome =
          alimento.data.nome;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].medida =
          alimento.data.medida_caseira_padrao;
        this.refeicoes[refeicaoIndex].alimentosList[
          alimentoIndex
        ].quantidade = 1;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].gramas =
          alimento.data.gr_padrao;
        let gramas = alimento.data.gr_padrao;

        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].CHO =
          (alimento.data.carboidrato / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].PTN =
          (alimento.data.proteina / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].LIP =
          (alimento.data.lipideos / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].KCAL =
          (alimento.data.kcal / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].FIB =
          (alimento.data.fibra_alimentar / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].COL =
          (alimento.data.colesterol / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].SOD =
          (alimento.data.sodio / 100) * gramas;

        for (var a = 0; a < this.demaisCodigos.length; a++) {
          this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex][
            this.demaisCodigos[a]
          ] = (alimento.data[this.demaisCodigos[a]] / 100) * gramas;
        }

        // to keep the values always clean even if the user change only one of the items
        this.refeicoes[refeicaoIndex].CHO = 0;
        this.refeicoes[refeicaoIndex].PTN = 0;
        this.refeicoes[refeicaoIndex].LIP = 0;
        this.refeicoes[refeicaoIndex].KCAL = 0;
        this.refeicoes[refeicaoIndex].SOD = 0;
        this.refeicoes[refeicaoIndex].FIB = 0;
        this.refeicoes[refeicaoIndex].COL = 0;

        for (var b = 0; b < this.demaisCodigos.length; b++) {
          this.refeicoes[refeicaoIndex][this.demaisCodigos[b]] = 0;
        }

        let concatList = this.refeicoes[refeicaoIndex].alimentosList.concat(
          this.refeicoes[refeicaoIndex].receitasList
        );

        concatList.forEach((item) => {
          this.refeicoes[refeicaoIndex].CHO += item.CHO;
          this.refeicoes[refeicaoIndex].LIP += item.LIP;
          this.refeicoes[refeicaoIndex].PTN += item.PTN;
          this.refeicoes[refeicaoIndex].KCAL += item.KCAL;
          this.refeicoes[refeicaoIndex].SOD += item.SOD;
          this.refeicoes[refeicaoIndex].FIB += item.FIB;
          this.refeicoes[refeicaoIndex].COL += item.COL;

          for (var c = 0; c < this.demaisCodigos.length; c++) {
            this.refeicoes[refeicaoIndex][this.demaisCodigos[c]] +=
              item[this.demaisCodigos[c]];
          }
        });
      }

      this.$store.dispatch("firestore/dietaStatus", {
        refeicoes: this.refeicoes,
      });
    },
    onReceitaSelected: function(refeicaoIndex, receitaIndex, receitaId) {
      var receita = this.receitas.find((item) => item.value == receitaId);

      if (receita !== undefined) {
        // rendimento !!
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].porcoes = 1;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].rendimento =
          receita.data.rendimento;
        let rendimento = receita.data.rendimento;

        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].CHO =
          receita.data.CHO / rendimento;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].PTN =
          receita.data.PTN / rendimento;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].LIP =
          receita.data.LIP / rendimento;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].KCAL =
          receita.data.KCAL / rendimento;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].FIB =
          receita.data.FIB / rendimento;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].COL =
          receita.data.COL / rendimento;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].SOD =
          receita.data.SOD / rendimento;

        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].titulo =
          receita.data.titulo;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].modoPreparo =
          receita.data.modoPreparo;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].alimentosList =
          receita.data.alimentosList;

        for (var ar = 0; ar < this.demaisCodigos.length; ar++) {
          this.refeicoes[refeicaoIndex].receitasList[receitaIndex][
            this.demaisCodigos[ar]
          ] = receita.data[this.demaisCodigos[ar]] / rendimento;
        }

        // to keep the values always clean even if the user change only one of the items
        this.refeicoes[refeicaoIndex].CHO = 0;
        this.refeicoes[refeicaoIndex].PTN = 0;
        this.refeicoes[refeicaoIndex].LIP = 0;
        this.refeicoes[refeicaoIndex].KCAL = 0;
        this.refeicoes[refeicaoIndex].SOD = 0;
        this.refeicoes[refeicaoIndex].FIB = 0;
        this.refeicoes[refeicaoIndex].COL = 0;

        for (var br = 0; br < this.demaisCodigos.length; br++) {
          this.refeicoes[refeicaoIndex][this.demaisCodigos[br]] = 0;
        }

        let concatList = this.refeicoes[refeicaoIndex].alimentosList.concat(
          this.refeicoes[refeicaoIndex].receitasList
        );

        concatList.forEach((item) => {
          this.refeicoes[refeicaoIndex].CHO += item.CHO;
          this.refeicoes[refeicaoIndex].LIP += item.LIP;
          this.refeicoes[refeicaoIndex].PTN += item.PTN;
          this.refeicoes[refeicaoIndex].KCAL += item.KCAL;
          this.refeicoes[refeicaoIndex].SOD += item.SOD;
          this.refeicoes[refeicaoIndex].FIB += item.FIB;
          this.refeicoes[refeicaoIndex].COL += item.COL;

          for (var cr = 0; cr < this.demaisCodigos.length; cr++) {
            this.refeicoes[refeicaoIndex][this.demaisCodigos[cr]] +=
              item[this.demaisCodigos[cr]];
          }
        });
      }

      this.$store.dispatch("firestore/dietaStatus", {
        refeicoes: this.refeicoes,
      });
    },
    onQtdChange: function(refeicaoIndex, alimentoIndex, alimentoId, qtd) {
      var alimento = this.alimentos.find((item) => item.value == alimentoId);
      if (alimento !== undefined) {
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].nome =
          alimento.data.nome;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].medida =
          alimento.data.medida_caseira_padrao;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].gramas =
          alimento.data.gr_padrao * qtd;
        let gramas = alimento.data.gr_padrao * qtd;

        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].CHO =
          (alimento.data.carboidrato / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].PTN =
          (alimento.data.proteina / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].LIP =
          (alimento.data.lipideos / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].KCAL =
          (alimento.data.kcal / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].FIB =
          (alimento.data.fibra_alimentar / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].COL =
          (alimento.data.colesterol / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].SOD =
          (alimento.data.sodio / 100) * gramas;

        for (var d = 0; d < this.demaisCodigos.length; d++) {
          this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex][
            this.demaisCodigos[d]
          ] = (alimento.data[this.demaisCodigos[d]] / 100) * gramas;
        }

        // to keep the values always clean even if the user change only one of the items
        this.refeicoes[refeicaoIndex].CHO = 0;
        this.refeicoes[refeicaoIndex].PTN = 0;
        this.refeicoes[refeicaoIndex].LIP = 0;
        this.refeicoes[refeicaoIndex].KCAL = 0;
        this.refeicoes[refeicaoIndex].FIB = 0;
        this.refeicoes[refeicaoIndex].COL = 0;
        this.refeicoes[refeicaoIndex].SOD = 0;

        for (var e = 0; e < this.demaisCodigos.length; e++) {
          this.refeicoes[refeicaoIndex][this.demaisCodigos[e]] = 0;
        }

        let concatList = this.refeicoes[refeicaoIndex].alimentosList.concat(
          this.refeicoes[refeicaoIndex].receitasList
        );

        concatList.forEach((item) => {
          this.refeicoes[refeicaoIndex].CHO += item.CHO;
          this.refeicoes[refeicaoIndex].LIP += item.LIP;
          this.refeicoes[refeicaoIndex].PTN += item.PTN;
          this.refeicoes[refeicaoIndex].KCAL += item.KCAL;
          this.refeicoes[refeicaoIndex].FIB += item.FIB;
          this.refeicoes[refeicaoIndex].COL += item.COL;
          this.refeicoes[refeicaoIndex].SOD += item.SOD;

          for (var f = 0; f < this.demaisCodigos.length; f++) {
            this.refeicoes[refeicaoIndex][this.demaisCodigos[f]] +=
              item[this.demaisCodigos[f]];
          }
        });
      }

      this.$store.dispatch("firestore/dietaStatus", {
        refeicoes: this.refeicoes,
      });
    },
    onPorcoesChange: function(
      refeicaoIndex,
      receitaIndex,
      receitaId,
      receitaPorcoes
    ) {
      var receita = this.receitas.find((item) => item.value == receitaId);
      if (receita !== undefined) {
        this.refeicoes[refeicaoIndex].receitasList[
          receitaIndex
        ].porcoes = receitaPorcoes;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].titulo =
          receita.data.titulo;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].rendimento =
          receita.data.rendimento;
        let rendimento = receita.data.rendimento;

        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].CHO =
          (receita.data.CHO / rendimento) * receitaPorcoes;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].PTN =
          (receita.data.PTN / rendimento) * receitaPorcoes;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].LIP =
          (receita.data.LIP / rendimento) * receitaPorcoes;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].KCAL =
          (receita.data.KCAL / rendimento) * receitaPorcoes;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].FIB =
          (receita.data.FIB / rendimento) * receitaPorcoes;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].COL =
          (receita.data.COL / rendimento) * receitaPorcoes;
        this.refeicoes[refeicaoIndex].receitasList[receitaIndex].SOD =
          (receita.data.SOD / rendimento) * receitaPorcoes;

        for (var dr = 0; dr < this.demaisCodigos.length; dr++) {
          this.refeicoes[refeicaoIndex].receitasList[receitaIndex][
            this.demaisCodigos[dr]
          ] =
            (receita.data[this.demaisCodigos[dr]] / rendimento) *
            receitaPorcoes;
        }

        // to keep the values always clean even if the user change only one of the items
        this.refeicoes[refeicaoIndex].CHO = 0;
        this.refeicoes[refeicaoIndex].PTN = 0;
        this.refeicoes[refeicaoIndex].LIP = 0;
        this.refeicoes[refeicaoIndex].KCAL = 0;
        this.refeicoes[refeicaoIndex].FIB = 0;
        this.refeicoes[refeicaoIndex].COL = 0;
        this.refeicoes[refeicaoIndex].SOD = 0;

        for (var er = 0; er < this.demaisCodigos.length; er++) {
          this.refeicoes[refeicaoIndex][this.demaisCodigos[er]] = 0;
        }

        let concatList = this.refeicoes[refeicaoIndex].alimentosList.concat(
          this.refeicoes[refeicaoIndex].receitasList
        );

        concatList.forEach((item) => {
          this.refeicoes[refeicaoIndex].CHO += item.CHO;
          this.refeicoes[refeicaoIndex].LIP += item.LIP;
          this.refeicoes[refeicaoIndex].PTN += item.PTN;
          this.refeicoes[refeicaoIndex].KCAL += item.KCAL;
          this.refeicoes[refeicaoIndex].FIB += item.FIB;
          this.refeicoes[refeicaoIndex].COL += item.COL;
          this.refeicoes[refeicaoIndex].SOD += item.SOD;

          for (var fr = 0; fr < this.demaisCodigos.length; fr++) {
            this.refeicoes[refeicaoIndex][this.demaisCodigos[fr]] +=
              item[this.demaisCodigos[fr]];
          }
        });
      }

      this.$store.dispatch("firestore/dietaStatus", {
        refeicoes: this.refeicoes,
      });
    },
    onGrChange: function(refeicaoIndex, alimentoIndex, alimentoId, gramas) {
      var alimento = this.alimentos.find((item) => item.value == alimentoId);
      if (alimento !== undefined) {
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].nome =
          alimento.data.nome;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].medida = "";
        this.refeicoes[refeicaoIndex].alimentosList[
          alimentoIndex
        ].quantidade = null;

        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].CHO =
          (alimento.data.carboidrato / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].PTN =
          (alimento.data.proteina / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].LIP =
          (alimento.data.lipideos / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].KCAL =
          (alimento.data.kcal / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].COL =
          (alimento.data.colesterol / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].FIB =
          (alimento.data.fibra_alimentar / 100) * gramas;
        this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].SOD =
          (alimento.data.sodio / 100) * gramas;

        for (var g = 0; g < this.demaisCodigos.length; g++) {
          this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex][
            this.demaisCodigos[g]
          ] = (alimento.data[this.demaisCodigos[g]] / 100) * gramas;
        }

        // to keep the values always clean even if the user change only one of the items
        this.refeicoes[refeicaoIndex].CHO = 0;
        this.refeicoes[refeicaoIndex].PTN = 0;
        this.refeicoes[refeicaoIndex].LIP = 0;
        this.refeicoes[refeicaoIndex].KCAL = 0;
        this.refeicoes[refeicaoIndex].FIB = 0;
        this.refeicoes[refeicaoIndex].COL = 0;
        this.refeicoes[refeicaoIndex].SOD = 0;

        for (var i = 0; i < this.demaisCodigos.length; i++) {
          this.refeicoes[refeicaoIndex][this.demaisCodigos[i]] = 0;
        }

        let concatList = this.refeicoes[refeicaoIndex].alimentosList.concat(
          this.refeicoes[refeicaoIndex].receitasList
        );

        concatList.forEach((item) => {
          this.refeicoes[refeicaoIndex].CHO += item.CHO;
          this.refeicoes[refeicaoIndex].LIP += item.LIP;
          this.refeicoes[refeicaoIndex].PTN += item.PTN;
          this.refeicoes[refeicaoIndex].KCAL += item.KCAL;
          this.refeicoes[refeicaoIndex].COL += item.COL;
          this.refeicoes[refeicaoIndex].FIB += item.FIB;
          this.refeicoes[refeicaoIndex].SOD += item.SOD;

          for (var j = 0; j < this.demaisCodigos.length; j++) {
            this.refeicoes[refeicaoIndex][this.demaisCodigos[j]] +=
              item[this.demaisCodigos[j]];
          }
        });
      }

      this.$store.dispatch("firestore/dietaStatus", {
        refeicoes: this.refeicoes,
      });
    },
    addRefeicao: function() {
      this.refeicoes.push({
        nomeRefeicao: "",
        horarioRefeicao: "",
        alimentosList: [
          {
            alimentoId: "",
            medida: "",
            quantidade: "",
            gramas: "",
            KCAL: 0,
            CHO: 0,
            PTN: 0,
            LIP: 0,
            FIB: 0,
            SOD: 0,
            COL: 0,
            fibra_soluvel: 0,
            fibra_insoluvel: 0,
            cinzas: 0,
            calcio: 0,
            magnesio: 0,
            manganes: 0,
            fosforo: 0,
            ferro: 0,
            potassio: 0,
            cobre: 0,
            zinco: 0,
            retinol: 0,
            re: 0,
            era: 0,
            iodo: 0,
            selenio: 0,
            acido_folico: 0,
            acido_pantotenico: 0,
            tiamina: 0,
            riboflavina: 0,
            piridoxina: 0,
            niacina: 0,
            vitamina_c: 0,
            vitamina_d: 0,
            vitamina_e: 0,
            vitamina_b12: 0,
            saturados: 0,
            monoinsaturados: 0,
            poliinsaturados: 0,
            gordura_trans: 0,
            triptofano: 0,
            treonina: 0,
            isoleucina: 0,
            leucina: 0,
            lisina: 0,
            metionina: 0,
            cistina: 0,
            fenilalanina: 0,
            tirosina: 0,
            valina: 0,
            arginina: 0,
            histidina: 0,
            alanina: 0,
            acido_aspartico: 0,
            acido_glutamico: 0,
            glicina: 0,
            prolina: 0,
            serina: 0,
            umidade: 0,
            // substitution: [],
          },
        ],
        receitasList: [
          {
            titulo: "",
            tempoPreparo: "",
            rendimento: "",
            modoPreparo: "",
            alimentosList: [
              {},
              // {
              //   alimentoId: "",
              //   medida: "",
              //   quantidade: "",
              //   gramas: "",
              //   KCAL: 0,
              //   CHO: 0,
              //   PTN: 0,
              //   LIP: 0,
              //   FIB: 0,
              //   SOD: 0,
              //   COL: 0,
              //   fibra_soluvel: 0,
              //   fibra_insoluvel: 0,
              //   cinzas: 0,
              //   calcio: 0,
              //   magnesio: 0,
              //   manganes: 0,
              //   fosforo: 0,
              //   ferro: 0,
              //   potassio: 0,
              //   cobre: 0,
              //   zinco: 0,
              //   retinol: 0,
              //   re: 0,
              //   era: 0,
              //   iodo: 0,
              //   selenio: 0,
              //   acido_folico: 0,
              //   acido_pantotenico: 0,
              //   tiamina: 0,
              //   riboflavina: 0,
              //   piridoxina: 0,
              //   niacina: 0,
              //   vitamina_c: 0,
              //   vitamina_d: 0,
              //   vitamina_e: 0,
              //   vitamina_b12: 0,
              //   saturados: 0,
              //   monoinsaturados: 0,
              //   poliinsaturados: 0,
              //   gordura_trans: 0,
              //   triptofano: 0,
              //   treonina: 0,
              //   isoleucina: 0,
              //   leucina: 0,
              //   lisina: 0,
              //   metionina: 0,
              //   cistina: 0,
              //   fenilalanina: 0,
              //   tirosina: 0,
              //   valina: 0,
              //   arginina: 0,
              //   histidina: 0,
              //   alanina: 0,
              //   acido_aspartico: 0,
              //   acido_glutamico: 0,
              //   glicina: 0,
              //   prolina: 0,
              //   serina: 0,
              //   umidade: 0,
              // },
            ],
            observacao: "",
            KCAL: 0,
            CHO: 0,
            PTN: 0,
            LIP: 0,
            FIB: 0,
            SOD: 0,
            COL: 0,
            fibra_soluvel: 0,
            fibra_insoluvel: 0,
            cinzas: 0,
            calcio: 0,
            magnesio: 0,
            manganes: 0,
            fosforo: 0,
            ferro: 0,
            potassio: 0,
            cobre: 0,
            zinco: 0,
            retinol: 0,
            re: 0,
            era: 0,
            iodo: 0,
            selenio: 0,
            acido_folico: 0,
            acido_pantotenico: 0,
            tiamina: 0,
            riboflavina: 0,
            piridoxina: 0,
            niacina: 0,
            vitamina_c: 0,
            vitamina_d: 0,
            vitamina_e: 0,
            vitamina_b12: 0,
            saturados: 0,
            monoinsaturados: 0,
            poliinsaturados: 0,
            gordura_trans: 0,
            triptofano: 0,
            treonina: 0,
            isoleucina: 0,
            leucina: 0,
            lisina: 0,
            metionina: 0,
            cistina: 0,
            fenilalanina: 0,
            tirosina: 0,
            valina: 0,
            arginina: 0,
            histidina: 0,
            alanina: 0,
            acido_aspartico: 0,
            acido_glutamico: 0,
            glicina: 0,
            prolina: 0,
            serina: 0,
            umidade: 0,
            // substitution: [],
          },
        ],
        observacao: "",
        KCAL: 0,
        CHO: 0,
        PTN: 0,
        LIP: 0,
        FIB: 0,
        SOD: 0,
        COL: 0,
        fibra_soluvel: 0,
        fibra_insoluvel: 0,
        cinzas: 0,
        calcio: 0,
        magnesio: 0,
        manganes: 0,
        fosforo: 0,
        ferro: 0,
        potassio: 0,
        cobre: 0,
        zinco: 0,
        retinol: 0,
        re: 0,
        era: 0,
        iodo: 0,
        selenio: 0,
        acido_folico: 0,
        acido_pantotenico: 0,
        tiamina: 0,
        riboflavina: 0,
        piridoxina: 0,
        niacina: 0,
        vitamina_c: 0,
        vitamina_d: 0,
        vitamina_e: 0,
        vitamina_b12: 0,
        saturados: 0,
        monoinsaturados: 0,
        poliinsaturados: 0,
        gordura_trans: 0,
        triptofano: 0,
        treonina: 0,
        isoleucina: 0,
        leucina: 0,
        lisina: 0,
        metionina: 0,
        cistina: 0,
        fenilalanina: 0,
        tirosina: 0,
        valina: 0,
        arginina: 0,
        histidina: 0,
        alanina: 0,
        acido_aspartico: 0,
        acido_glutamico: 0,
        glicina: 0,
        prolina: 0,
        serina: 0,
        umidade: 0,
        percentualCaloria: 0,
      });
    },
    insertRefeicao: function(index) {
      this.refeicoes.splice(index, 0, {
        nomeRefeicao: "",
        horarioRefeicao: "",
        alimentosList: [
          {
            alimentoId: "",
            medida: "",
            quantidade: "",
            gramas: "",
            KCAL: 0,
            CHO: 0,
            PTN: 0,
            LIP: 0,
            FIB: 0,
            SOD: 0,
            COL: 0,
            fibra_soluvel: 0,
            fibra_insoluvel: 0,
            cinzas: 0,
            calcio: 0,
            magnesio: 0,
            manganes: 0,
            fosforo: 0,
            ferro: 0,
            potassio: 0,
            cobre: 0,
            zinco: 0,
            retinol: 0,
            re: 0,
            era: 0,
            iodo: 0,
            selenio: 0,
            acido_folico: 0,
            acido_pantotenico: 0,
            tiamina: 0,
            riboflavina: 0,
            piridoxina: 0,
            niacina: 0,
            vitamina_c: 0,
            vitamina_d: 0,
            vitamina_e: 0,
            vitamina_b12: 0,
            saturados: 0,
            monoinsaturados: 0,
            poliinsaturados: 0,
            gordura_trans: 0,
            triptofano: 0,
            treonina: 0,
            isoleucina: 0,
            leucina: 0,
            lisina: 0,
            metionina: 0,
            cistina: 0,
            fenilalanina: 0,
            tirosina: 0,
            valina: 0,
            arginina: 0,
            histidina: 0,
            alanina: 0,
            acido_aspartico: 0,
            acido_glutamico: 0,
            glicina: 0,
            prolina: 0,
            serina: 0,
            umidade: 0,
            // substitution: [],
          },
        ],
        receitasList: [
          {
            titulo: "",
            tempoPreparo: "",
            rendimento: "",
            modoPreparo: "",
            alimentosList: [
              {},
              // {
              //   alimentoId: "",
              //   medida: "",
              //   quantidade: "",
              //   gramas: "",
              //   KCAL: 0,
              //   CHO: 0,
              //   PTN: 0,
              //   LIP: 0,
              //   FIB: 0,
              //   SOD: 0,
              //   COL: 0,
              //   fibra_soluvel: 0,
              //   fibra_insoluvel: 0,
              //   cinzas: 0,
              //   calcio: 0,
              //   magnesio: 0,
              //   manganes: 0,
              //   fosforo: 0,
              //   ferro: 0,
              //   potassio: 0,
              //   cobre: 0,
              //   zinco: 0,
              //   retinol: 0,
              //   re: 0,
              //   era: 0,
              //   iodo: 0,
              //   selenio: 0,
              //   acido_folico: 0,
              //   acido_pantotenico: 0,
              //   tiamina: 0,
              //   riboflavina: 0,
              //   piridoxina: 0,
              //   niacina: 0,
              //   vitamina_c: 0,
              //   vitamina_d: 0,
              //   vitamina_e: 0,
              //   vitamina_b12: 0,
              //   saturados: 0,
              //   monoinsaturados: 0,
              //   poliinsaturados: 0,
              //   gordura_trans: 0,
              //   triptofano: 0,
              //   treonina: 0,
              //   isoleucina: 0,
              //   leucina: 0,
              //   lisina: 0,
              //   metionina: 0,
              //   cistina: 0,
              //   fenilalanina: 0,
              //   tirosina: 0,
              //   valina: 0,
              //   arginina: 0,
              //   histidina: 0,
              //   alanina: 0,
              //   acido_aspartico: 0,
              //   acido_glutamico: 0,
              //   glicina: 0,
              //   prolina: 0,
              //   serina: 0,
              //   umidade: 0,
              // },
            ],
            observacao: "",
            KCAL: 0,
            CHO: 0,
            PTN: 0,
            LIP: 0,
            FIB: 0,
            SOD: 0,
            COL: 0,
            fibra_soluvel: 0,
            fibra_insoluvel: 0,
            cinzas: 0,
            calcio: 0,
            magnesio: 0,
            manganes: 0,
            fosforo: 0,
            ferro: 0,
            potassio: 0,
            cobre: 0,
            zinco: 0,
            retinol: 0,
            re: 0,
            era: 0,
            iodo: 0,
            selenio: 0,
            acido_folico: 0,
            acido_pantotenico: 0,
            tiamina: 0,
            riboflavina: 0,
            piridoxina: 0,
            niacina: 0,
            vitamina_c: 0,
            vitamina_d: 0,
            vitamina_e: 0,
            vitamina_b12: 0,
            saturados: 0,
            monoinsaturados: 0,
            poliinsaturados: 0,
            gordura_trans: 0,
            triptofano: 0,
            treonina: 0,
            isoleucina: 0,
            leucina: 0,
            lisina: 0,
            metionina: 0,
            cistina: 0,
            fenilalanina: 0,
            tirosina: 0,
            valina: 0,
            arginina: 0,
            histidina: 0,
            alanina: 0,
            acido_aspartico: 0,
            acido_glutamico: 0,
            glicina: 0,
            prolina: 0,
            serina: 0,
            umidade: 0,
            // substitution: [],
          },
        ],
        observacao: "",
        KCAL: 0,
        CHO: 0,
        PTN: 0,
        LIP: 0,
        FIB: 0,
        SOD: 0,
        COL: 0,
        fibra_soluvel: 0,
        fibra_insoluvel: 0,
        cinzas: 0,
        calcio: 0,
        magnesio: 0,
        manganes: 0,
        fosforo: 0,
        ferro: 0,
        potassio: 0,
        cobre: 0,
        zinco: 0,
        retinol: 0,
        re: 0,
        era: 0,
        iodo: 0,
        selenio: 0,
        acido_folico: 0,
        acido_pantotenico: 0,
        tiamina: 0,
        riboflavina: 0,
        piridoxina: 0,
        niacina: 0,
        vitamina_c: 0,
        vitamina_d: 0,
        vitamina_e: 0,
        vitamina_b12: 0,
        saturados: 0,
        monoinsaturados: 0,
        poliinsaturados: 0,
        gordura_trans: 0,
        triptofano: 0,
        treonina: 0,
        isoleucina: 0,
        leucina: 0,
        lisina: 0,
        metionina: 0,
        cistina: 0,
        fenilalanina: 0,
        tirosina: 0,
        valina: 0,
        arginina: 0,
        histidina: 0,
        alanina: 0,
        acido_aspartico: 0,
        acido_glutamico: 0,
        glicina: 0,
        prolina: 0,
        serina: 0,
        umidade: 0,
        percentualCaloria: 0,
      });
    },
    // duplicateRefeicao: function(ref, index) {
    //   this.refeicoes.splice(index + 1, 0, ref);
    // },
    log(logarg) {
      console.log(logarg);
    },
    addAlimento: function(index) {
      this.refeicoes[index].alimentosList.push({
        alimentoId: "",
        medida: "",
        quantidade: "",
        gramas: "",
        KCAL: 0,
        CHO: 0,
        PTN: 0,
        LIP: 0,
        FIB: 0,
        SOD: 0,
        COL: 0,
        fibra_soluvel: 0,
        fibra_insoluvel: 0,
        cinzas: 0,
        calcio: 0,
        magnesio: 0,
        manganes: 0,
        fosforo: 0,
        ferro: 0,
        potassio: 0,
        cobre: 0,
        zinco: 0,
        retinol: 0,
        re: 0,
        era: 0,
        iodo: 0,
        selenio: 0,
        acido_folico: 0,
        acido_pantotenico: 0,
        tiamina: 0,
        riboflavina: 0,
        piridoxina: 0,
        niacina: 0,
        vitamina_c: 0,
        vitamina_d: 0,
        vitamina_e: 0,
        vitamina_b12: 0,
        saturados: 0,
        monoinsaturados: 0,
        poliinsaturados: 0,
        gordura_trans: 0,
        triptofano: 0,
        treonina: 0,
        isoleucina: 0,
        leucina: 0,
        lisina: 0,
        metionina: 0,
        cistina: 0,
        fenilalanina: 0,
        tirosina: 0,
        valina: 0,
        arginina: 0,
        histidina: 0,
        alanina: 0,
        acido_aspartico: 0,
        acido_glutamico: 0,
        glicina: 0,
        prolina: 0,
        serina: 0,
        umidade: 0,
        // substitution: [],
      });
    },
    addReceita: function(index) {
      this.refeicoes[index].receitasList.push({
        titulo: "",
        tempoPreparo: "",
        rendimento: "",
        modoPreparo: "",
        alimentosList: [
          {
            alimentoId: "",
            medida: "",
            quantidade: "",
            gramas: "",
            KCAL: 0,
            CHO: 0,
            PTN: 0,
            LIP: 0,
            FIB: 0,
            SOD: 0,
            COL: 0,
            fibra_soluvel: 0,
            fibra_insoluvel: 0,
            cinzas: 0,
            calcio: 0,
            magnesio: 0,
            manganes: 0,
            fosforo: 0,
            ferro: 0,
            potassio: 0,
            cobre: 0,
            zinco: 0,
            retinol: 0,
            re: 0,
            era: 0,
            iodo: 0,
            selenio: 0,
            acido_folico: 0,
            acido_pantotenico: 0,
            tiamina: 0,
            riboflavina: 0,
            piridoxina: 0,
            niacina: 0,
            vitamina_c: 0,
            vitamina_d: 0,
            vitamina_e: 0,
            vitamina_b12: 0,
            saturados: 0,
            monoinsaturados: 0,
            poliinsaturados: 0,
            gordura_trans: 0,
            triptofano: 0,
            treonina: 0,
            isoleucina: 0,
            leucina: 0,
            lisina: 0,
            metionina: 0,
            cistina: 0,
            fenilalanina: 0,
            tirosina: 0,
            valina: 0,
            arginina: 0,
            histidina: 0,
            alanina: 0,
            acido_aspartico: 0,
            acido_glutamico: 0,
            glicina: 0,
            prolina: 0,
            serina: 0,
            umidade: 0,
            // substitution: [],
          },
        ],
        observacao: "",
        KCAL: 0,
        CHO: 0,
        PTN: 0,
        LIP: 0,
        FIB: 0,
        SOD: 0,
        COL: 0,
        fibra_soluvel: 0,
        fibra_insoluvel: 0,
        cinzas: 0,
        calcio: 0,
        magnesio: 0,
        manganes: 0,
        fosforo: 0,
        ferro: 0,
        potassio: 0,
        cobre: 0,
        zinco: 0,
        retinol: 0,
        re: 0,
        era: 0,
        iodo: 0,
        selenio: 0,
        acido_folico: 0,
        acido_pantotenico: 0,
        tiamina: 0,
        riboflavina: 0,
        piridoxina: 0,
        niacina: 0,
        vitamina_c: 0,
        vitamina_d: 0,
        vitamina_e: 0,
        vitamina_b12: 0,
        saturados: 0,
        monoinsaturados: 0,
        poliinsaturados: 0,
        gordura_trans: 0,
        triptofano: 0,
        treonina: 0,
        isoleucina: 0,
        leucina: 0,
        lisina: 0,
        metionina: 0,
        cistina: 0,
        fenilalanina: 0,
        tirosina: 0,
        valina: 0,
        arginina: 0,
        histidina: 0,
        alanina: 0,
        acido_aspartico: 0,
        acido_glutamico: 0,
        glicina: 0,
        prolina: 0,
        serina: 0,
        umidade: 0,
      });
    },
    removeRefeicao: function(refeicaoToRemove) {
      this.refeicoes.splice(this.refeicoes.indexOf(refeicaoToRemove), 1);
      this.$store.dispatch("firestore/dietaStatus", {
        refeicoes: this.refeicoes,
      });
    },
    removeAlimento: function(index, alimentoToRemove) {
      // var alimento = this.alimentos.find(
      //   (item) => item.value === alimentoToRemove.alimentoId
      // );
      // if (alimento !== undefined) {
      //   // this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].medida
      //   this.refeicoes[index].KCAL -= alimento.data.kcal;
      //   this.refeicoes[index].CHO -= alimento.data.carboidrato;
      //   this.refeicoes[index].PTN -= alimento.data.proteina;
      //   this.refeicoes[index].LIP -= alimento.data.lipideos;
      //   this.refeicoes[index].SOD -= alimento.data.sodio;
      //   this.refeicoes[index].COL -= alimento.data.colesterol;
      //   this.refeicoes[index].FIB -= alimento.data.fibra_alimentar;
      //   for (var k = 0; k < this.demaisCodigos.length; k++) {
      //     this.refeicoes[index][this.demaisCodigos[k]] -=
      //       alimento.data[this.demaisCodigos[k]];
      //   }
      // }

      this.refeicoes[index].alimentosList.splice(
        this.refeicoes[index].alimentosList.indexOf(alimentoToRemove),
        1
      );

      // to keep the values always clean even if the user change only one of the items
      this.refeicoes[index].CHO = 0;
      this.refeicoes[index].PTN = 0;
      this.refeicoes[index].LIP = 0;
      this.refeicoes[index].SOD = 0;
      this.refeicoes[index].FIB = 0;
      this.refeicoes[index].COL = 0;
      this.refeicoes[index].KCAL = 0;
      for (var l = 0; l < this.demaisCodigos.length; l++) {
        this.refeicoes[index][this.demaisCodigos[l]] = 0;
      }

      let concatList = this.refeicoes[index].alimentosList.concat(
        this.refeicoes[index].receitasList
      );

      concatList.forEach((item) => {
        this.refeicoes[index].CHO += item.CHO;
        this.refeicoes[index].LIP += item.LIP;
        this.refeicoes[index].PTN += item.PTN;
        this.refeicoes[index].COL += item.COL;
        this.refeicoes[index].SOD += item.SOD;
        this.refeicoes[index].FIB += item.FIB;
        this.refeicoes[index].KCAL += item.KCAL;

        for (var m = 0; m < this.demaisCodigos.length; m++) {
          this.refeicoes[index][this.demaisCodigos[m]] +=
            item[this.demaisCodigos[m]];
        }
      });

      this.$store.dispatch("firestore/dietaStatus", {
        refeicoes: this.refeicoes,
      });
    },
    removeReceita: function(index, receitaToRemove) {
      // var receita = this.receitas.find(
      //   (item) => item.value === receitaToRemove.id
      // );
      // if (receita !== undefined) {
      //   // this.refeicoes[refeicaoIndex].alimentosList[alimentoIndex].medida
      //   this.refeicoes[index].KCAL -= (receita.data.kcal * receita.porcoes);
      //   this.refeicoes[index].CHO -= (receita.data.carboidrato * receita.porcoes);
      //   this.refeicoes[index].PTN -= (receita.data.proteina * receita.porcoes);
      //   this.refeicoes[index].LIP -= (receita.data.lipideos * receita.porcoes);
      //   this.refeicoes[index].SOD -= (receita.data.sodio * receita.porcoes);
      //   this.refeicoes[index].COL -= (receita.data.colesterol * receita.porcoes);
      //   this.refeicoes[index].FIB -= (receita.data.fibra_alimentar * receita.porcoes);
      //   for (var kr = 0; kr < this.demaisCodigos.length; kr++) {
      //     this.refeicoes[index][this.demaisCodigos[kr]] -=
      //       (receita.data[this.demaisCodigos[kr]] * receita.porcoes);
      //   }
      // }

      this.refeicoes[index].receitasList.splice(
        this.refeicoes[index].receitasList.indexOf(receitaToRemove),
        1
      );

      // to keep the values always clean even if the user change only one of the items
      this.refeicoes[index].CHO = 0;
      this.refeicoes[index].PTN = 0;
      this.refeicoes[index].LIP = 0;
      this.refeicoes[index].SOD = 0;
      this.refeicoes[index].FIB = 0;
      this.refeicoes[index].COL = 0;
      this.refeicoes[index].KCAL = 0;
      for (var lr = 0; lr < this.demaisCodigos.length; lr++) {
        this.refeicoes[index][this.demaisCodigos[lr]] = 0;
      }

      let concatList = this.refeicoes[index].alimentosList.concat(
        this.refeicoes[index].receitasList
      );

      concatList.forEach((item) => {
        this.refeicoes[index].CHO += item.CHO;
        this.refeicoes[index].LIP += item.LIP;
        this.refeicoes[index].PTN += item.PTN;
        this.refeicoes[index].COL += item.COL;
        this.refeicoes[index].SOD += item.SOD;
        this.refeicoes[index].FIB += item.FIB;
        this.refeicoes[index].KCAL += item.KCAL;

        for (var mr = 0; mr < this.demaisCodigos.length; mr++) {
          this.refeicoes[index][this.demaisCodigos[mr]] +=
            item[this.demaisCodigos[mr]];
        }
      });

      this.$store.dispatch("firestore/dietaStatus", {
        refeicoes: this.refeicoes,
      });
    },
    substituteAlimento: function(alimento) {
      console.log(alimento);
    },
  },
  mounted() {
    this.addRefeicao();
  },
};
</script>
