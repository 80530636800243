<template>
  <div class="page">
    <!-- <div v-if="blockedTeste">
      <BlockedTeste></BlockedTeste>
    </div>
    <div v-else> -->

    <!-- <v-dialog v-model="metaDialog" width="80%">
      <v-card v-if="notReady">
        <v-card-title class="d-flex justify-center">
          Calculando contribuições...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>Meta mínima do nosso Clube</v-card-title>
        <v-card-text>
          <v-row class="d-flex align-center mx-1">
            <v-col cols="12" md="4">
              <v-row class="d-flex justify-center text-h6">Faturamento: </v-row
              ><v-row class="d-flex justify-center text-h6">{{
                niceString(faturamento)
              }}</v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-row class="d-flex justify-center text-h6">Meta mínima:</v-row
              ><v-row class="d-flex justify-center text-h6">
                {{ niceString(30000) }}</v-row
              >
            </v-col>
            <v-col cols="12" md="4" v-if="diferenca < 0">
              <v-row class="d-flex justify-center subtitle-1"
                >Faturamos quantas vezes a meta mínimo:</v-row
              >
              <v-row class="d-flex justify-center green--text text-h6">{{
                vezes
              }}</v-row>
            </v-col>
            <v-col cols="12" md="4" v-else class="red--text">
              <v-row class="d-flex justify-center subtitle-1"
                >A faturar até final do mês:</v-row
              >
              <v-row class="d-flex justify-center red--text text-h6">{{
                niceString(diferenca)
              }}</v-row>
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Software/App: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingSoftw"
                    color="#c5d13f"
                    background-color="#614021"
                    dense
                    readonly
                    large
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Cardápios: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingCarda"
                    color="#c5d13f"
                    background-color="#614021"
                    dense
                    readonly
                    large
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Resultados do tratamento: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingTrata"
                    color="#c5d13f"
                    background-color="#614021"
                    readonly
                    large
                    dense
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Experiência como um todo: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingTotal"
                    color="#c5d13f"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            class="d-flex justify-center mx-1"
            v-if="funcInclNutricionista1"
          >
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Nutricionista 1 </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoNutricionista1) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>de todos os nutris</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingNutri"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row
            class="d-flex justify-center mx-1"
            v-if="funcInclNutricionista2"
          >
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Nutricionista 2 </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoNutricionista2) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>de todos os nutris</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingNutri"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row
            class="d-flex justify-center mx-1"
            v-if="funcInclNutricionista3"
          >
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Nutricionista 3 </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoNutricionista3) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>de todos os nutris</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingNutri"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row
            class="d-flex justify-center mx-1"
            v-if="funcInclRecepcionista"
          >
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Recepcionista </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoRecepcionista) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>da recepcionista</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingRecip"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="d-flex justify-center mx-1" v-if="funcInclEsteticista">
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Esteticista </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoEsteticista) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>da esteticista</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingEstet"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="d-flex justify-center mx-1" v-if="funcInclEstagiaria">
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Estagiária </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoEstagiaria) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                </v-col>
                <v-col cols="4">
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="closeMetaDialog">{{ btnText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <HomeHeader></HomeHeader>
    <v-row>
      <v-col cols="4" md="2">
        <HomeMenu></HomeMenu>
      </v-col>
      <v-col cols="8" md="8">
        <router-view></router-view>
      </v-col>
      <v-col cols="0" md="2">
        <v-navigation-drawer permanent class="grey lighten-5">
          <v-card outlined color="grey lighten-5">
            <v-card-title>
              <v-btn text color="grey" @click="show = !show">
                <v-icon v-if="show" left>mdi-eye</v-icon>
                <v-icon v-else left>mdi-eye-off</v-icon>
                Meu paciente...
              </v-btn>
            </v-card-title>
            <v-card-text>
              <MeusPacientesHead v-if="show"></MeusPacientesHead>
            </v-card-text>
          </v-card>
        </v-navigation-drawer>
      </v-col>
    </v-row>
    <WelcomeFooterNotFixed></WelcomeFooterNotFixed>
  </div>
  <!-- <div
    class="page"
    style="height: 100%; width:100%"
  >
    <HomeHeader></HomeHeader>
    <v-row style="height: 82.6%">
      <div class="ml-4"></div>
      <HomeMenu></HomeMenu>
      <v-col
        cols="3"
        md="10"
      >
        <router-view></router-view>
      </v-col>
    </v-row>
    <WelcomeFooter></WelcomeFooter>
  </div> -->
</template>

<script>
import HomeHeader from "../components/home/HomeHeader";
import HomeMenu from "../components/home/HomeMenu";
import MeusPacientesHead from "../components/MeusPacientesHead";
import WelcomeFooterNotFixed from "../components/welcome/WelcomeFooterNotFixed";
// import BlockedTeste from "..components/home/BlockedTeste";

export default {
  components: {
    WelcomeFooterNotFixed,
    HomeHeader,
    MeusPacientesHead,
    HomeMenu /*, BlockedTeste */,
  },
  data() {
    return {
      show: false,
      vendedores: [
        // "Nutricionista 1",
        // "Nutricionista 2",
        // "Recepcionista",
        // "Esteticista",
      ],
      metaDialog: false,
      btnText: "",
      faturamento: 0,
      diferenca: 0,
      vezes: 0,
      funcInclNutricionista1: true,
      funcInclNutricionista2: true,
      funcInclNutricionista3: false,
      funcInclRecepcionista: true,
      funcInclEsteticista: true,
      funcInclEstagiaria: false,
      contribuicaoPropretario: 0,
      contribuicaoNutricionista1: 0,
      contribuicaoNutricionista2: 0,
      contribuicaoNutricionista3: 0,
      contribuicaoRecepcionista: 0,
      contribuicaoEsteticista: 0,
      contribuicaoEstagiaria: 0,
      ratingNutri: 0,
      ratingEstet: 0,
      ratingRecip: 0,
      ratingTotal: 0,
      ratingTrata: 0,
      ratingSoftw: 0,
      ratingCarda: 0,
      notReady: true,
    };
  },
  computed: {
    // caixa() {
    //   return this.$store.state.firestore.caixa;
    // },
    // pacientes() {
    //   return this.$store.state.firestore.pacientes;
    // },
    funcionarios() {
      return this.$store.state.firestore.funcionarios;
    },
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    funcInclProprietario() {
      if (
        this.vendedores.includes("Proprietário") ||
        this.vendedores.includes("Proprietária")
      ) {
        return true;
      } else {
        return false;
      }
    },
    // funcInclNutricionista1() {
    //   if (
    //     this.vendedores.includes("Nutricionista 1") ||
    //     this.vendedores.includes("Nutricionista")
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // funcInclNutricionista2() {
    //   return this.vendedores.includes("Nutricionista 2");
    // },
    // funcInclNutricionista3() {
    //   return this.vendedores.includes("Nutricionista 3");
    // },
    // funcInclRecepcionista() {
    //   return this.vendedores.includes("Recepcionista");
    // },
    // funcInclEsteticista() {
    //   return this.vendedores.includes("Esteticista");
    // },
    // funcInclEstagiaria() {
    //   return this.vendedores.includes("Estagiária");
    // },
  },
  methods: {
    // closeMetaDialog() {
    //   this.metaDialog = false;
    //   setTimeout(() => {
    //     this.$router.push("Lembretes");
    //   }, 500);
    // },
    // getVendedores() {
    //   this.vendedores = this.funcionarios.map(function (funcionario) {
    //     return funcionario.papel;
    //   });
    //   console.log(this.vendedores);
    //   var funcInclNutricionista1;
    //   if (
    //     this.vendedores.includes("Nutricionista 1") ||
    //     this.vendedores.includes("Nutricionista")
    //   ) {
    //     funcInclNutricionista1 = true;
    //   } else {
    //     funcInclNutricionista1 = false;
    //   }
    //   var funcInclNutricionista2 = this.vendedores.includes("Nutricionista 2");
    //   var funcInclNutricionista3 = this.vendedores.includes("Nutricionista 3");
    //   var funcInclRecepcionista = this.vendedores.includes("Recepcionista");
    //   var funcInclEsteticista = this.vendedores.includes("Esteticista");
    //   var funcInclEstagiaria = this.vendedores.includes("Estagiária");
    //   this.funcInclNutricionista1 = funcInclNutricionista1;
    //   this.funcInclNutricionista2 = funcInclNutricionista2;
    //   this.funcInclNutricionista3 = funcInclNutricionista3;
    //   this.funcInclRecepcionista = funcInclRecepcionista;
    //   this.funcInclEsteticista = funcInclEsteticista;
    //   this.funcInclEstagiaria = funcInclEstagiaria;
    // },
    // getComissoesAndRating() {
    //   var dateNow = new Date();
    //   var monthNow = dateNow.getMonth() + 1;
    //   var yearNow = dateNow.getFullYear();
    //   var filterM = function (list, c1, c2) {
    //     return list.filter(function (el) {
    //       return el.data.m === c1 && el.data.y === c2;
    //     });
    //   };
    //   var filterComissao = function (list, cond) {
    //     return list.filter(function (el) {
    //       return el.data.comissao == cond && el.data.direction;
    //     });
    //   };
    //   var filterComissao1 = function (list, cond1, cond2) {
    //     return list.filter(function (el) {
    //       if (el.data.direction) {
    //         return el.data.comissao == cond1 || el.data.comissao == cond2;
    //       } else {
    //         return false;
    //       }
    //     });
    //   };
    //   var filterVendas = function (list) {
    //     return list.filter(function (el) {
    //       return el.data.direction;
    //     });
    //   };
    //   var elementsM = filterM(this.caixa, monthNow, yearNow);
    //   var elementsPropretario = filterComissao(elementsM, "Propretário");
    //   var elementsNutricionista1 = filterComissao1(
    //     elementsM,
    //     "Nutricionista",
    //     "Nutricionista 1"
    //   );
    //   var elementsNutricionista2 = filterComissao(elementsM, "Nutricionista 2");
    //   var elementsNutricionista3 = filterComissao(elementsM, "Nutricionista 3");
    //   var elementsRecepcionista = filterComissao(elementsM, "Recepcionista");
    //   var elementsEsteticista = filterComissao(elementsM, "Esteticista");
    //   var elementsEstagiaria = filterComissao(elementsM, "Estagiária");
    //   var elementsVenda = filterVendas(elementsM);
    //   var faturamento = 0;
    //   if (elementsVenda.length > 0) {
    //     elementsVenda.forEach(function (item) {
    //       faturamento += parseFloat(item.data.valor);
    //     });
    //   }
    //   var comissaoPropretario = 0;
    //   if (elementsPropretario.length > 0) {
    //     elementsPropretario.forEach(function (item) {
    //       comissaoPropretario += parseFloat(item.data.valor);
    //     });
    //   }
    //   var comissaoNutricionista1 = 0;
    //   if (elementsNutricionista1.length > 0) {
    //     elementsNutricionista1.forEach(function (item) {
    //       comissaoNutricionista1 += parseFloat(item.data.valor);
    //     });
    //   }
    //   var comissaoNutricionista2 = 0;
    //   if (elementsNutricionista2.length > 0) {
    //     elementsNutricionista2.forEach(function (item) {
    //       comissaoNutricionista2 += parseFloat(item.data.valor);
    //     });
    //   }
    //   var comissaoNutricionista3 = 0;
    //   if (elementsNutricionista3.length > 0) {
    //     elementsNutricionista3.forEach(function (item) {
    //       comissaoNutricionista3 += parseFloat(item.data.valor);
    //     });
    //   }
    //   var comissaoRecepcionista = 0;
    //   if (elementsRecepcionista.length > 0) {
    //     elementsRecepcionista.forEach(function (item) {
    //       comissaoRecepcionista += parseFloat(item.data.valor);
    //     });
    //   }
    //   var comissaoEsteticista = 0;
    //   if (elementsEsteticista.length > 0) {
    //     elementsEsteticista.forEach(function (item) {
    //       comissaoEsteticista += parseFloat(item.data.valor);
    //     });
    //   }
    //   var comissaoEstagiaria = 0;
    //   if (elementsEstagiaria.length > 0) {
    //     elementsEstagiaria.forEach(function (item) {
    //       comissaoEstagiaria += parseFloat(item.data.valor);
    //     });
    //   }
    //   this.contribuicaoPropretario = comissaoPropretario;
    //   this.contribuicaoNutricionista1 = comissaoNutricionista1;
    //   this.contribuicaoNutricionista2 = comissaoNutricionista2;
    //   this.contribuicaoNutricionista3 = comissaoNutricionista3;
    //   this.contribuicaoRecepcionista = comissaoRecepcionista;
    //   this.contribuicaoEsteticista = comissaoEsteticista;
    //   this.contribuicaoEstagiaria = comissaoEstagiaria;
    //   this.faturamento = faturamento;
    //   if (faturamento < 30000) {
    //     this.btnText = "OK, vamos bater a meta !!!";
    //   } else {
    //     this.btnText = "Muito bom, vamos além da meta !!!";
    //   }
    //   this.diferenca = 30000 - faturamento;
    //   this.vezes = Math.round((faturamento / 30000) * 100) / 100;
    //   // ratings
    //   var hasRatingNutri = function (el) {
    //     return (
    //       el.data.ratingNutri !== undefined && el.data.ratingNutri !== null
    //     );
    //   };
    //   var hasRatingEstet = function (el) {
    //     return (
    //       el.data.ratingEstet !== undefined && el.data.ratingEstet !== null
    //     );
    //   };
    //   var hasRatingRecip = function (el) {
    //     return (
    //       el.data.ratingRecip !== undefined && el.data.ratingRecip !== null
    //     );
    //   };
    //   var hasRatingTrata = function (el) {
    //     return (
    //       el.data.ratingTrata !== undefined && el.data.ratingTrata !== null
    //     );
    //   };
    //   var hasRatingCarda = function (el) {
    //     return (
    //       el.data.ratingCarda !== undefined && el.data.ratingCarda !== null
    //     );
    //   };
    //   var hasRatingTotal = function (el) {
    //     return (
    //       el.data.ratingTotal !== undefined && el.data.ratingTotal !== null
    //     );
    //   };
    //   var hasRatingSoftw = function (el) {
    //     return (
    //       el.data.ratingSoftw !== undefined && el.data.ratingSoftw !== null
    //     );
    //   };
    //   var ratingsNutriArray = this.pacientes
    //     .filter(hasRatingNutri)
    //     .map(function (paciente) {
    //       return parseInt(paciente.data.ratingNutri);
    //     });
    //   var ratingsEstetArray = this.pacientes
    //     .filter(hasRatingEstet)
    //     .map(function (paciente) {
    //       return parseInt(paciente.data.ratingEstet);
    //     });
    //   var ratingsRecipArray = this.pacientes
    //     .filter(hasRatingRecip)
    //     .map(function (paciente) {
    //       return parseInt(paciente.data.ratingRecip);
    //     });
    //   var ratingsTrataArray = this.pacientes
    //     .filter(hasRatingTrata)
    //     .map(function (paciente) {
    //       return parseInt(paciente.data.ratingTrata);
    //     });
    //   var ratingsCardaArray = this.pacientes
    //     .filter(hasRatingCarda)
    //     .map(function (paciente) {
    //       return parseInt(paciente.data.ratingCarda);
    //     });
    //   var ratingsTotalArray = this.pacientes
    //     .filter(hasRatingTotal)
    //     .map(function (paciente) {
    //       return parseInt(paciente.data.ratingTotal);
    //     });
    //   var ratingsSoftwArray = this.pacientes
    //     .filter(hasRatingSoftw)
    //     .map(function (paciente) {
    //       return parseInt(paciente.data.ratingSoftw);
    //     });
    //   var ratingsNutriSum = ratingsNutriArray.reduce((a, b) => a + b, 0);
    //   var ratingsEstetSum = ratingsEstetArray.reduce((a, b) => a + b, 0);
    //   var ratingsRecipSum = ratingsRecipArray.reduce((a, b) => a + b, 0);
    //   var ratingsTrataSum = ratingsTrataArray.reduce((a, b) => a + b, 0);
    //   var ratingsCardaSum = ratingsCardaArray.reduce((a, b) => a + b, 0);
    //   var ratingsTotalSum = ratingsTotalArray.reduce((a, b) => a + b, 0);
    //   var ratingsSoftwSum = ratingsSoftwArray.reduce((a, b) => a + b, 0);
    //   var ratingsNutriAvg = ratingsNutriSum / ratingsNutriArray.length;
    //   var ratingsEstetAvg = ratingsEstetSum / ratingsEstetArray.length;
    //   var ratingsRecipAvg = ratingsRecipSum / ratingsRecipArray.length;
    //   var ratingsTrataAvg = ratingsTrataSum / ratingsTrataArray.length;
    //   var ratingsCardaAvg = ratingsCardaSum / ratingsCardaArray.length;
    //   var ratingsTotalAvg = ratingsTotalSum / ratingsTotalArray.length;
    //   var ratingsSoftwAvg = ratingsSoftwSum / ratingsSoftwArray.length;
    //   this.ratingNutri = Math.round(ratingsNutriAvg);
    //   this.ratingEstet = Math.round(ratingsEstetAvg);
    //   this.ratingRecip = Math.round(ratingsRecipAvg);
    //   this.ratingTrata = Math.round(ratingsTrataAvg);
    //   this.ratingCarda = Math.round(ratingsCardaAvg);
    //   this.ratingTotal = Math.round(ratingsTotalAvg);
    //   this.ratingSoftw = Math.round(ratingsSoftwAvg);
    //   this.metaDialog = true;
    //   console.log(ratingsTotalArray.length);
    //   this.notReady = false;
    // },
    // niceString(valor) {
    //   var minus = valor < 0;
    //   var valorAbs = Math.abs(valor);
    //   var valorKomma;
    //   var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
    //   var decimalsStr;
    //   if (decimals < 1) {
    //     decimalsStr = "00";
    //   } else if (decimals < 10) {
    //     decimalsStr = "0" + decimals.toString();
    //   } else {
    //     decimalsStr = decimals.toString();
    //   }
    //   var valorGanz = Math.floor(valorAbs);
    //   if (valorGanz > 1000) {
    //     var tusiger = Math.floor(valorAbs / 1000);
    //     var rest = valorGanz % 1000;
    //     var restStr;
    //     if (rest < 10) {
    //       restStr = "00" + rest.toString();
    //     } else if (rest < 100) {
    //       restStr = "0" + rest.toString();
    //     } else {
    //       restStr = rest.toString();
    //     }
    //     valorKomma = tusiger.toString() + "." + restStr;
    //   } else {
    //     valorKomma = valorGanz.toString();
    //   }
    //   if (minus) {
    //     return "- R$ " + valorKomma + "," + decimalsStr;
    //   } else {
    //     return "R$ " + valorKomma + "," + decimalsStr;
    //   }
    // },
  },
  // mounted() {
  //   if (this.isFranquia) {
  //     this.$store.dispatch("firestore/getCaixa");
  //     this.$store.dispatch("firestore/getPacientes");
  //     this.$store.dispatch("firestore/getFuncionarios");

  //     setTimeout(() => {
  //       this.getVendedores();
  //     }, 5000);

  //     // setTimeout(() => {
  //     //   this.getComissoesAndRating();
  //     // }, 7500);
  //   }
  // },
};
</script>
