<template>
  <v-page name="cartao">
    <div class="d-flex justify-center">
      <v-card
        class="pa-3"
        elevation="2"
        style="width: 600px"
      >
        <h2>Dados do cartão</h2>
        <v-stripe-card
          v-model="source"
          api-key="pk_test_51HbFJ0GkUXTqQoyNB2YiZTZLbrwIg71Xs2sob0JWG2jmrSE8NPIKBW6IL9ENGXx442QDrTtQiuAqzpYUTbaNHnI100glM3gd5Y"
          :customStyle="{currency: 'BRL', adress_country: 'BR'}"
        ></v-stripe-card>
        <v-btn text>Cancelar</v-btn>
        <v-btn
          color="green"
          :disabled="source === null"
          @click="sendPaymentMethod()"
        >Pagar</v-btn>
      </v-card>
    </div>
    <v-dialog
      v-model="successDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Pagamento realizado
        </v-card-title>

        <v-card-text>
          Seu pagamento foi realizado, obrigado.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="successDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="errorDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Pagamento realizado
        </v-card-title>

        <v-card-text class='pt-4'>
          Ocorreu um erro durante o pagamento, seu pagamento não foi realizado, verifique com o .
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="errorDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-page>
</template> 
<script>
import axios from 'axios';


export default {
  data () {
    return {
      source: null,
      successDialog: false,
      errorDialog: false,
      paymentClicked: 0,
    }
  },
  methods: {
    typingStarted () {
      console.log('Selecionado')
      this.typing = true
    },
    pagar () {
      console.log(this.source)
    },
    cancelar () {
      this.source = null
    },
    sendPaymentMethod () {
      if (this.source !== null && this.paymentClicked === 0) {
        this.paymentClicked = 1
        axios({
          method: 'POST',
          url: 'http://localhost:34939/pay',
          data: {
            token: this.source.id,
            amount: 300,
            client_email: sessionStorage.getItem('current-user'),
            plan: 'inicial'
          }
        }).then((response) => {
          console.log(response)
          switch (response.status) {
            case 202: {
              // alert('Pagamento realizado com sucesso')
              this.successDialog = true
              break;
            }
            case 500: {
              // alert('Server side error, desenvolvedores alertados, sua compra não foi realizada.')
              this.errorDialog = true
              break
            }
            case 400: {
              alert('400')
              break
            }


            // default:
            //   break;
          }
        })
      } else {
        alert("Insira os dados do cartão primeiro ")
      }
    }
  }
}
</script>