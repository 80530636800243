<template>
  <div class="page">
      <HomeHeaderAdmin></HomeHeaderAdmin>
      <v-row>
        <v-col cols="4" md="2">
          <HomeMenuAdmin></HomeMenuAdmin>
        </v-col>
        <v-col cols="8" md="10">
          <router-view></router-view>
        </v-col>
      </v-row>
      <WelcomeFooterNotFixed></WelcomeFooterNotFixed>
    </div>
</template>

<script>
import HomeHeaderAdmin from "../components/home/HomeHeaderAdmin";
import HomeMenuAdmin from "../components/home/HomeMenuAdmin";
import WelcomeFooterNotFixed from "../components/welcome/WelcomeFooterNotFixed";
export default {
  components: { WelcomeFooterNotFixed, HomeHeaderAdmin, HomeMenuAdmin},
  mounted() {
    // setTimeout(() => {
    // this.$router.push("AdminFinanceiroLivro");
    // }, 1500);
  }
};
</script>
