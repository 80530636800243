<template>
  <div>
    <v-row class="d-flex justify-center my-5 text-h6">Editar formula salva:</v-row>
    <v-row class="d-flex justify-center align-center">
      <v-autocomplete
        label="Formula"
        :items="formulas"
        v-model="formula.id"
        style="width: 100%; max-width: 250px"
        no-data-text="..."
      ></v-autocomplete>
      <v-btn @click="editarFormula(formula.id)" class="mx-4" color="grey lighten-2"> 
        <v-icon left>
          {{ icons.mdiTextBoxSearchOutline }}
        </v-icon>
        Editar Formula </v-btn>
    </v-row>
    <v-divider></v-divider>
    <v-row class="d-flex justify-center my-5 text-h6">Criar formula nova:</v-row>
    <v-row class="d-flex justify-center">
      <v-btn color="#c5d13f" to="/FormulaCriar" large class="black--text my-2">
      <v-icon color="#614021" left>{{ icons.mdiTextBoxPlusOutline }}</v-icon>
        Criar Formula
      </v-btn>
    </v-row>
  </div>
</template>

<script>
// import firebase from "firebase";
import router from "../router";
import {
  mdiTextBoxPlusOutline, mdiTextBoxSearchOutline 
} from "@mdi/js";

export default {
  data: function () {
    return {
      icons: {
        mdiTextBoxPlusOutline, mdiTextBoxSearchOutline 
      },
      formula: {
        id: "",
      },
    };
  },
  computed: {
    formulas() {
      return this.$store.state.firestore.formulas;
    },
  },
  methods: {
    editarFormula(id) {
      var formula = this.formulas.find((item) => item.value == id);
      
      if (formula !== undefined) {
        this.$store.dispatch("firestore/formulaId", {
            id: id,
        });
        this.$store.dispatch("firestore/formulaUpdate", {
            formula: formula.data,
        });
        router.replace("/EditarFormula");
      }
    },
  },
  mounted() {
    // if (this.$store.state.firestore.formulas.length === 0) {
      this.$store.dispatch("firestore/getFormulas");
    // }
    // setTimeout(() => {
    //   this.createListWithNames();
    // }, 1000);
  },
};
</script>

<style scoped>
</style>