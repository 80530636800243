 <template>
  <v-navigation-drawer
    v-model="drawer"
    class="grey lighten-5"
    :expand-on-hover="expandOnHover"
    :mini-variant="miniVariant"
    :right="right"
    :permanent="permanent"
  >

    <v-list-item two-line :class="miniVariant && 'px-0'">
      <v-list-item-content>
        <v-list-item-title>Admin</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list dense nav class="py-0">
      <v-divider></v-divider>

      <v-list-item v-for="item in items" :key="item.title" link>
        <v-menu offset-x open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-row v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </template>
          <v-list>
            <v-banner color="grey lighten-3" single-line>
              <v-avatar slot="icon" color="#c5d13f" size="35">
                <v-icon size="80%">{{ item.icon }}</v-icon>
              </v-avatar>
              {{ item.title }}
              <template v-slot:actions>
                <v-btn icon>
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </template>
            </v-banner>
            <v-divider></v-divider>
            <v-list-item v-for="(subitem, index) in item.subitems" :key="index">
              <router-link
                :to="{ name: subitem.route }"
                style="text-decoration: none; color: inherit"
                v-slot="{ href, navigate, isActive, isExactActive }"
                replace
              >
                <v-list-item
                  :class="[
                    isActive && 'router-link-active',
                    isExactActive && 'router-link-exact-active',
                  ]"
                  :href="href"
                  @click="navigate"
                >
                  <v-list-item-icon>
                    <v-icon>{{ subitem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// import firebase from "firebase";

import {
  mdiFoodApple,
  mdiFoodAppleOutline,
//   mdiDraw,
//   mdiTelevisionPlay,
  mdiCurrencyUsd,
  mdiBullhorn,
  mdiBullhornOutline,
//   mdiHelpCircleOutline,
  mdiAccountDetailsOutline,
  mdiClose,
//   mdiCalculatorVariantOutline,
  mdiTextBoxPlusOutline,
  mdiTextBoxCheckOutline,
//   mdiNeedle,
  mdiBookOpenVariant,
//   mdiTableEdit,
  mdiPlaylistPlus,
  mdiPlaylistEdit,
  mdiTextSearch,
//   mdiCash100,
  mdiHammerWrench,
  mdiTextBoxSearchOutline,
  mdiTextBoxOutline,
//   mdiTelevisionGuide, 
//   mdiChat,
//   mdiChatProcessing
  mdiBookshelf,
  mdiBookEducationOutline,
  mdiBookCogOutline
} from "@mdi/js";

export default {
  data() {
    return {
      userInfos: null,
      drawer: null,
      items: [
        {
          title: "Alimentos",
          icon: mdiFoodApple,
          subitems: [
            {
              title: "Admin",
              icon: mdiHammerWrench,
              route: "AdminAlimentos",
            },
          ],
        },
        {
          title: "Receitas",
          icon: mdiFoodAppleOutline,
          subitems: [
            {
              title: "Criar Receita Nova",
              icon: mdiPlaylistPlus,
              route: "AdminCriarReceitaNova",
            },
            {
              title: "Lista de Receitas",
              icon: mdiPlaylistEdit,
              route: "AdminReceitasTodas",
            },
            {
              title: "Pesquisar Receitas",
              icon: mdiTextSearch,
              route: "AdminReceitasSalvas",
            },
          ],
        },
        {
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          subitems: [
            {
              title: "Armazenar Fórmula",
              icon: mdiTextBoxPlusOutline,
              route: "AdminFormulaCriar",
            },
            {
              title: "Lista de Formulas",
              icon: mdiTextBoxCheckOutline,
              route: "AdminFormulasTodas",
            },
            {
              title: "Pesquisar Formulas",
              icon: mdiTextBoxSearchOutline,
              route: "AdminFormulasSalvas",
            },
          ],
        },
        {
          title: "Meu Financeiro",
          icon: mdiCurrencyUsd,
          subitems: [
            {
              title: "Livro Caixa",
              icon: mdiBookOpenVariant,
              route: "AdminFinanceiroLivro",
            },
          ],
        },
        {
          title: "Publicidade",
          icon: mdiBullhorn,
          subitems: [
            {
              title: "Fundo Publicidade",
              icon: mdiBullhornOutline,
              route: "AdminGestaoPublicidade",
            },
          ],
        },
        {
          title: "Treinamentos",
          icon: mdiBookshelf,
          subitems: [
            {
              title: "Treinamentos Software",
              icon: mdiBookCogOutline,
              route: "AdminTreinamentosSoftware",
            },
            {
              title: "Treinamentos Clínica",
              icon: mdiBookEducationOutline,
              route: "AdminTreinamentosClube",
            },
          ],
        },
      ],
      icons: { mdiAccountDetailsOutline, mdiClose },
      color: "primary",
      colors: ["primary", "blue", "success", "red", "teal"],
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: false,
      background: false,
    };
  },
  // firestore() {
  //   return {
  //     userInfos: firebase
  //       .firestore()
  //       .collection("users")
  //       .doc(sessionStorage.getItem("current-user")),
  //   };
  // },
};
</script>