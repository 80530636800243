<template>
  <div>
    <v-dialog v-model="visualizarDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center text-h6">
          {{ formula.titulo }}
        </v-card-title>
        <v-card-text>
          <v-row class="mx-4">
            <v-textarea
              v-model="formula.prescricoes"
              solo
              height="200"
            ></v-textarea>
          </v-row>
          <v-row
            class="d-flex justify-center mx-4 text-subtitle-2 black--text mt-2"
          >
            Catergorias:
          </v-row>
          <v-row class="mx-4">
            <v-col
              cols="6"
              v-for="categoria in formula.categorias"
              :key="categoria"
              class="d-flex justify-center"
            >
              {{ categoria }},
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text @click="visualizarDialog = false" color="#614021">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="d-flex flex-column justify-center">
      <v-card
        class="d-inline-block mr-3"
        width="100%"
        elevation="3"
        color="grey lighten-2"
      >
        <v-container>
          <v-card-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  @click="visualizarDialog = true"
                >
                  <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                </v-btn>
              </template>
              <span>Visualizar Formula</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <h3>Editar Fórmula</h3>
            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#c5d13f"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-4"
                  @click="saveToFirestore"
                >
                  <v-icon color="#614021">{{ icons.mdiContentSave }}</v-icon>
                </v-btn>
              </template>
              <span>Salvar Formula</span>
            </v-tooltip>
          </v-card-actions>
        </v-container>
      </v-card>

      <FormulaFormEdit></FormulaFormEdit>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import {
  mdiCalendar,
  mdiContentSave,
  mdiPrinter,
  mdiEye,
  mdiEmailSendOutline,
  mdiDownload,
  mdiMagnify,
} from "@mdi/js";

// import jspdf from "jspdf";
// import "jspdf-autotable";
// components imports
import FormulaFormEdit from "@/components/home/formulas/FormulaFormEdit";
import router from "@/router";

export default {
  components: { FormulaFormEdit },
  data: function () {
    return {
      pacienteDocID: "",
      // documentsList: [],
      visualizarDialog: false,
      progressDialog: false,
      wrongAutor: false,
      saved: false,
      icons: {
        mdiCalendar,
        mdiContentSave,
        mdiPrinter,
        mdiEye,
        mdiEmailSendOutline,
        mdiDownload,
        mdiMagnify,
      },
    };
  },
  computed: {
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    formulas() {
      return this.$store.state.firestore.formulas;
    },
    formula() {
      return this.$store.state.firestore.formula;
    },
    formulaId() {
      return this.$store.state.firestore.formulaId;
    },
  },
  methods: {
    saveToFirestore: function () {
      this.progressDialog = true;

      this.formula.autor = "admin";
      firebase
        .firestore()
        // .collection("users")
        // .doc(sessionStorage.getItem("current-user"))
        .collection("formulas")
        .doc(this.formulaId)
        .set(this.formula);

      this.saved = true;

      setTimeout(() => {
        router.replace("/AdminFormulasSalvas");
      }, 2000);
    },
  },
};
</script>

<style>
</style>