<template>
  <div>
    <v-dialog v-model="visualizarDialog" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title>
          {{ orientacaoPVis.nome }}, ({{ orientacaoPVis.dateStr }})
        </v-card-title>
        <v-card-text>
          <v-textarea readonly v-model="orientacaoPVis.orientacaoText">
          </v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title class="text-h6"> Orientações prescritas </v-card-title>
      <v-card-text>
        <v-card
          v-for="orientacaoP in orientacoes"
          :key="orientacaoP.nome"
          outlined
        >
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="6" class="d-flex justify-space-between">
              <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                {{ orientacaoP.nome }} ({{ orientacaoP.dateStr }})
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#614021"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadPdf(orientacaoP)"
                  >
                    <v-icon color="#c5d13f">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Baixar Prescrição</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#c5d13f"
                    v-bind="attrs"
                    v-on="on"
                    @click="setPrescricaoVisualizar(orientacaoP.id)"
                    class="mr-2 mx-2 black--text"
                  >
                    <v-icon color="#614021">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Prescrição</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";
import jspdf from "jspdf";
import "jspdf-autotable";
import fontCode from "@/components/Montserrat-Medium-normal";

export default {
  data() {
    return {
      // orientacoes: [],
      autorData: {
        nome: "",
        crn: "",
        assinatura: "",
        papel: "",
      },
      nutriProfilePicture: "",
      visualizarDialog: false,
      panel: [],
      preloadLogo: new Image(),
      preloadSignature: new Image(),
    };
  },
  // firestore() {
  //   return {
  //     orientacoes: firebase
  //       .firestore()
  //       .collection("users")
  //       .doc(sessionStorage.getItem("current-user"))
  //       .collection("orientacoes"),
  //   };
  // },
  computed: {
    orientacaoPVis() {
      return this.$store.state.firestore.orientacaoPVis;
    },
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    orientacoes() {
      return this.$store.state.firestore.pacienteOrientacoesAll;
    },
  },
  methods: {
    async getNutriData() {
      const nutri = this.userInfo.nutri;
      const nutriData = await firebase
        .firestore()
        .collection("users")
        .doc(nutri)
        .get()
        .then(function(doc) {
          return doc.data();
        });

      if (
        nutriData.profilePicture !== undefined &&
        nutriData.profilePicture !== ""
      ) {
        this.nutriProfilePicture = nutriData.profilePicture;
      }
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async preloadImages(orientacao) {
      if (this.userInfo.isNF) {
        if (
          this.nutriProfilePicture !== undefined &&
          this.nutriProfilePicture !== ""
        ) {
          this.toDataURL(this.nutriProfilePicture, (data) => {
            this.preloadLogo.src = data;
          });
        } else {
          this.preloadLogo.src = require("@/assets/signature_none.jpg");
        }
      } else {
        this.preloadLogo.src = require("@/assets/logoCompleto.png");
      }

      if (
        orientacao.autorAssinatura !== undefined &&
        orientacao.autorAssinatura !== ""
      ) {
        this.toDataURL(orientacao.autorAssinatura, (data) => {
          this.preloadSignature.src = data;
        });
        this.autorData.nome = orientacao.autorNome;
        this.autorData.crn = orientacao.autorCrn;
      } else {
        if (
          this.pacienteDados.nutri ==
          "umarizalbelem@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_danielle.png");
          this.autorData.nome = "Danielle Zahlouth";
          this.autorData.crn = "11517";
        } else if (
          this.pacienteDados.nutri == "bc@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_pietra.png");
          this.autorData.nome = "Pietra Chiella";
          this.autorData.crn = "SC 8570";
        } else if (
          this.pacienteDados.nutri ==
          "fortaleza.salinas@clubedanutricionista.com.br"
        ) {
          this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
          this.autorData.nome = "Thayana Albuquerque Kirchhoff";
          this.autorData.crn = "4571";
        } else {
          this.preloadSignature.src = require("@/assets/signature_none.jpg");
        }
      }

      this.preloadLogo.onload = function() {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    async downloadPdf(orientacao) {
      this.preloadImages(orientacao);

      setTimeout(() => {
        this.createDocWithJSPDF(orientacao);
      }, 2500);

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("orientacoes")
        .doc(orientacao.id.toString())
        .update({
          unread: false,
        });
    },
    createDocWithJSPDF: async function(orientacao) {
      var pacienteName = this.userInfo.nome;

      alert("Gerando PDF");
      // links
      // https://mrrio.github.io/jsPDF/examples/basic.html
      // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
      // https://simonbengtsson.github.io/jsPDF-AutoTable/
      // https://www.npmjs.com/package/jspdf-autotable

      var doc = new jspdf("p");

      // Doc settings
      var fontSize = 10;
      doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
      doc.addFont(
        "Montserrat-Medium-normal.ttf",
        "Montserrat-Medium",
        "normal"
      );
      doc.setFontSize(fontSize);
      doc.setFont("Montserrat-Medium");

      // The (900 / 262) division is used to keep the proportion of the original image
      // New Width * (900 / 262) will keep the height proportion

      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function(
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc
      //         .setFont("Montserrat-Medium")
      //         .splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(textList, centered = false, textStart = textStartYPos) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page
        
        for (var i = 0; i < textList.length; i++) {
            var lines = doc.setFont("Montserrat-Medium").splitTextToSize(textList[i], 180);
            
            for (var j = 0; j < lines.length; j++) {
                // Check if the current line would go past the threshold
                if (textStart > pageHeightThreshold) {
                    doc.addPage();
                    textStart = newYStart; // Reset the text start position for the new page
                }
                
                // Whether to center the text or not
                if (centered) {
                    centeredText(lines[j], textStart);
                } else {
                    doc.text(startXPos, textStart, lines[j]);
                }
                
                textStart += (fontSize / 2); // Increment the y position for each line of text
            }

            // Increment after each text block rather than each line
            if (!centered) {
                textStart += (fontSize / 2); // This accounts for the space after each block of text
            }
        }
        
        return textStart; // Return the updated text start position
    };

      // // Logo Clubedonutricionista
      //   if (this.$store.state.firestore.pacienteNutriUseLogo === false) {
      // console.log("op 1");
      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });
      //   } else {
      // LOGO CLUBE
      // imgWidth = 30;
      // imgHeight = imgWidth / (900 / 262);
      // startXPos = 15;

      // doc.addImage({
      //   imageData: require("@/assets/logoCompleto.png"),
      //   y: startXPos,
      //   x: 15,
      //   w: imgWidth,
      //   h: imgHeight,
      // });

      // imgWidth = 50;
      // var startYPos = 15 + imgHeight * 2;
      // imgHeight =
      //   imgWidth /
      //   (this.preloadLogo.naturalWidth /
      //     this.preloadLogo.naturalHeight);
      // imgPos = (210 - imgWidth) / 2;
      // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

      // doc.addImage({
      //   imageData: this.preloadLogo,
      //   y: startYPos,
      //   x: imgPos,
      //   w: imgWidth,
      //   h: imgHeight,
      // });
      //   }

      var texts = [
        `"${orientacao.nome}" (${orientacao.dateStr})`,
        `Para: ${pacienteName}`,
      ];

      var endTablePosY = createTexts(texts, false, textStartYPos + 6);

      // endTablePosY = createTexts([orientacao.orientacaoText], false, endTablePosY);

      // endTablePosY = createTexts([orientacao.observacoes], false, endTablePosY);

      var body = [
        [orientacao.orientacaoText],
        ["Observações: " + orientacao.observacoes],
      ];

      doc.autoTable({
        startY: endTablePosY, //lastPos // (lastPos / 2) * 0.7 + startPosY,
        startx: startXPos + 24,
        head: [["Orientações:"]],
        body: body,
        headStyles: {
          fillColor: [226, 232, 159],
          textColor: 40,
          fontStyle: "regular",
        },
        bodyStyles: {
          fillColor: [243, 245, 216],
        },
        alternateRowStyles: {
          fillColor: [249, 250, 235],
        },
      });
      endTablePosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;

      doc.addImage({
        imageData: this.preloadSignature,
        y: endTablePosY,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          // `Nutricionista ${this.$store.state.firestore.pacienteNutriName}`,
          // `CRN: ${this.$store.state.firestore.pacienteCrn}`,
          `Nutricionista ${this.autorData.nome}`,
          `CRN: ${this.autorData.crn}`,
          `Data: ${orientacao.dateStr}`,
        ],
        true,
        // endTablePosY !== undefined
        //   ? endTablePosY +
        //       prescricaoC.formulasList.length * 48 +
        //       16 +
        //       imgHeight * 1.4
        //   :
        endTablePosY + imgHeight // + texts.length * (fontSize * (3 / 4))
      );

      doc.save("orientacao.pdf");
    },
    async setPrescricaoVisualizar(docId) {
      this.$store.dispatch("firestore/getOrientacaoPaciente", {
        docId: docId,
      });

      this.visualizarDialog = true;
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("orientacoes")
        .doc(docId.toString())
        .update({
          unread: false,
        });
    },
  },
  mounted() {
    this.getNutriData();

    this.$store.dispatch("firestore/getOrientacoesAll", {
      pacienteEmail: sessionStorage.getItem("current-user"),
    });
  },
};
</script>
