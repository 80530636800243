<template>
  <v-app-bar app>
    <v-img :src="logo" contain max-height="60" max-width="180"></v-img>
    <v-spacer></v-spacer>

    <v-btn class="hidden-sm-and-down mx-1" color="#c5d13f" to="/LoginDePaciente">
      <span>Área do paciente</span>
    </v-btn>
    <v-btn class="hidden-sm-and-down mx-1" color="#614021" to="/LoginParceiro">
      <span class="white--text">Parceiro</span>
    </v-btn>

    <v-spacer></v-spacer>

    <v-btn class="hidden-sm-and-down mx-1" color="#614021" value="franquia" href="https://www.clubedanutricionista.com.br/" target="_blank">
      <span class="white--text">Franquia</span>
    </v-btn>

    <!-- <v-btn class="mx-1" color="#c5d13f" value="cadastrar" to="/cadastrar">
      <span>Cadastrar</span>
    </v-btn> -->

    <v-btn class="mx-1" color="#c5d13f" value="login" to="/login">
      <span>Funcionário</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      logo: null
    }
  },
  mounted() {
    this.logo = require("@/assets/logoCompleto.png");
  }
};
</script>

<style scoped>
</style>


