<template>
  <div>
    <v-dialog v-model="filterProspectosDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Filtrar lista de prospectos
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center">
          filtrado: {{ nrFiltrados }}, de {{ nrProspectos }}
        </v-card-subtitle>
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-autocomplete
              v-model="sexosChosen"
              multiple
              outlined
              :items="sexos"
              label="Sexo"
              class="mx-1"
              @change="filterProspectos"
            ></v-autocomplete>
            <v-autocomplete
              v-model="objetivosChosen"
              outlined
              :items="objetivos"
              label="Objetivo"
              class="mx-1"
              multiple
              @change="filterProspectos"
            ></v-autocomplete>
            <v-autocomplete
              v-model="motivosChosen"
              outlined
              :items="motivos"
              multiple
              label="Motivo pela recusão"
              class="mx-1"
              @change="filterProspectos"
            ></v-autocomplete>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-autocomplete
              v-model="meiosChosen"
              outlined
              :items="meios"
              label="Meio de contato"
              class="mx-1"
              multiple
              @change="filterProspectos"
            ></v-autocomplete>
            <v-menu
              ref="menuStart"
              v-model="menuStart"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="200px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startChosen"
                  @change="filterProspectos"
                  label="Data da chamada (à partir de...)"
                  prepend-icon="mdi-calendar"
                  :rules="rules.dataNasc"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                locale="pt-br"
                v-model="startChosen"
                :max="new Date().toISOString().substring(0, 10)"
                min="1920-01-01"
                @change="saveStart"
                color="#614021"
              ></v-date-picker>
            </v-menu>

            <v-menu
              ref="menuEnd"
              v-model="menuEnd"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="200px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endChosen"
                  @change="filterProspectos"
                  label="Data da chamada (até...)"
                  prepend-icon="mdi-calendar"
                  :rules="rules.dataNasc"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                locale="pt-br"
                v-model="endChosen"
                :max="new Date().toISOString().substring(0, 10)"
                min="1920-01-01"
                @change="saveEnd"
                color="#614021"
              ></v-date-picker>
            </v-menu>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            class="white--text"
            @click="downloadProspectos"
          >
            <v-icon left color="#c5d13f" @click="downloadProspectos"
              >mdi-download</v-icon
            >
            baixar lista ({{ nrFiltrados }}/{{ nrProspectos }})</v-btn
          >
          <v-btn color="#c5d13f" @click="filterProspectosDialog = false">
            <v-icon left color="#614021">mdi-eye</v-icon>
            Visualizar lista</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="inspectProspectDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center">
          {{ inspectProspect.nome }}, {{ inspectProspect.dia }}/{{
            inspectProspect.mes
          }}/{{ inspectProspect.ano }} {{ inspectProspect.horarioLigacao }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-text-field
              color="#614021"
              outlined
              prepend-icon="mdi-bullseye-arrow"
              v-model="inspectProspect.objetivo"
              readonly
              label="Objetivo"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-textarea
              color="#614021"
              prepend-icon="mdi-ear-hearing"
              outlined
              v-model="inspectProspect.detalhesImportantes"
              readonly
              label="Situação atual / Detalhes importantes"
            ></v-textarea>
          </v-row>
          <v-row class="d-flex justify-center align-center">
            <v-icon
              class="mx-2"
              color="#ff00ff"
              left
              v-if="inspectProspect.sexo == 'feminino'"
              >mdi-gender-female</v-icon
            >
            <v-icon
              class="mx-2"
              color="#0080ff"
              left
              v-if="inspectProspect.sexo == 'masculino'"
              >mdi-gender-male</v-icon
            >
            Sexo
          </v-row>
          <v-row class="d-flex justify-center">
            <v-radio-group v-model="inspectProspect.sexo" column readonly>
              <v-radio
                label="feminino"
                value="feminino"
                color="#ff00ff"
              ></v-radio>
              <v-radio
                label="masculino"
                value="masculino"
                color="#0080ff"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              outlined
              color="#c5d13f"
              prepend-icon="mdi-phone"
              v-model="inspectProspect.telefone"
              readonly
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              outlined
              color="#c5d13f"
              prepend-icon="mdi-whatsapp"
              v-model="inspectProspect.whatsapp"
              readonly
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              label="email"
              outlined
              color="#c5d13f"
              prepend-icon="mdi-email"
              v-model="inspectProspect.email"
              readonly
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              outlined
              color="#c5d13f"
              label="Meio de contato"
              prepend-icon="mdi-account-voice"
              v-model="inspectProspect.meioContato"
              readonly
            >
            </v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              label="Horário da ligação"
              type="time"
              outlined
              prepend-icon="mdi-clock-outline"
              readonly
              v-model="inspectProspect.horarioLigacao"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-text-field
              prepend-icon="mdi-account-clock"
              v-model="inspectProspect.motivoRecusao"
              readonly
              label="Motivo pela recusão"
              outlined
            ></v-text-field>
          </v-row>
          <v-row
            class="d-flex justify-center"
            v-if="inspectProspect.motivoRecusao == 'Outros'"
          >
            <v-text-field
              outlined
              prepend-icon="mdi-account-clock"
              v-model="inspectProspect.motivoRecusaoOutro"
              label="Motivo pela recusão"
              readonly
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="inspectProspectDialog = false" color="#D6DBDF"
            >Fechar</v-btn
          >
          <v-btn @click="convertToPatient(inspectProspect)" color="#c5d13f"
            >Converter</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title>Prospectos </v-card-title>
      <v-card-text v-if="filteredProspectos.length > 0">
        <v-row class="d-flex justify-center">
          <v-btn color="#614021" @click="filterProspectosDialog = true" class="my-1 white--text"
            ><v-progress-circular
              v-if="chargeProspectos"
              :size="20"
              :width="3"
              color="#c5d13f"
              indeterminate
            ></v-progress-circular>
            <v-icon v-else left color="#c5d13f">mdi-account-question</v-icon
            >Filtrar prospectos
          </v-btn>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="0" md="4"></v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              outlined
              label="buscar pelo nome..."
              v-model="searchProspecto"
              prepend-inner-icon="mdi-magnify"
              :items="searchFilteredProspectos"
              @change="filterProspectsFromSearch"
            ></v-autocomplete>
          </v-col>
          <v-col cols="0" md="4"></v-col>
        </v-row>
        <v-card color="grey">
          <v-row class="d-flex justify-center">
            <v-col cols="12" class="d-flex justify-space-between">
              <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                <v-col cols="3"> Nome do prospectps</v-col>
                <v-col cols="3"> Meio de contato:</v-col>
                <v-col cols="3"> Motivo da recusão:</v-col>
                <v-col cols="2">
                  <v-row>Data:</v-row>
                  <v-row><small>(primeiro contato) </small></v-row>
                </v-col>
                <v-col cols="1"> </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <div v-for="(pObj, pIndex) in filteredProspectos" :key="pIndex">
          <v-card
            :color="cardColor(pObj)"
            outlined
            :style="{ border: '1px solid grey' }"
          >
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" class="d-flex justify-space-between">
                <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                  <v-col cols="3"> {{ pObj.nome }}</v-col>
                  <v-col cols="3">
                    <small>{{ pObj.meioContato }}</small></v-col
                  >
                  <v-col cols="3">
                    <small>{{ pObj.motivoRecusao }}</small></v-col
                  >
                  <v-col cols="2" class="d-flex justify-center">
                    {{ readableDate(pObj.millis) }}</v-col
                  >
                  <v-col cols="1" class="d-flex justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="verDetalhes(pObj)"
                        >
                          <v-icon color="#614021">mdi-account-star</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalhes / converter</span>
                    </v-tooltip></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <v-progress-circular
          :size="50"
          :width="5"
          color="#614021"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import jspdf from "jspdf";
import "jspdf-autotable";
import firebase from "firebase";

export default {
  data: function () {
    return {
      menuStart: false,
      menuEnd: false,
      chargeProspectos: false,
      inspectProspectDialog: false,
      inspectProspect: {
        nome: null,
        sexo: null,
        telefone: null,
        whatsapp: null,
        email: null,
        objetivo: null,
        motivoRecusao: null,
        motivoRecusaoOutro: null,
        meioContato: null,
        detalhesImportantes: null,
        horarioLigacao: null,
        millis: null,
        dia: null,
        mes: null,
        ano: null,
      },
      prospectos: [
        {
          nome: null,
          sexo: null,
          telefone: null,
          whatsapp: null,
          email: null,
          objetivo: null,
          motivoRecusao: null,
          motivoRecusaoOutro: null,
          meioContato: null,
          detalhesImportantes: null,
          horarioLigacao: null,
          millis: null,
        },
      ],
      filteredProspectos: [
        {
          nome: null,
          sexo: null,
          telefone: null,
          whatsapp: null,
          email: null,
          objetivo: null,
          motivoRecusao: null,
          motivoRecusaoOutro: null,
          meioContato: null,
          detalhesImportantes: null,
          horarioLigacao: null,
          millis: null,
          dia: null,
          mes: null,
          ano: null,
        },
      ],
      searchFilteredProspectos: [
        {
          value: null,
          text: null,
          data: {
            nome: null,
            sexo: null,
            telefone: null,
            whatsapp: null,
            email: null,
            objetivo: null,
            motivoRecusao: null,
            motivoRecusaoOutro: null,
            meioContato: null,
            detalhesImportantes: null,
            horarioLigacao: null,
            millis: null,
            dia: null,
            mes: null,
            ano: null,
          },
        },
      ],
      searchProspecto: null,
      filteredProspectosBeforeSearch: [],
      endChosen: null,
      startChosen: null,
      sexos: ["feminino", "masculino"],
      sexosChosen: ["feminino", "masculino"],
      objetivos: [
        "Emagrecer",
        "Tratar um Problema de Saúde",
        "Ganhar Massa Magra",
        "Procedimento Estético",
        "Exames",
      ],
      objetivosChosen: [
        "Emagrecer",
        "Tratar um Problema de Saúde",
        "Ganhar Massa Magra",
        "Procedimento Estético",
        "Exames",
      ],
      meios: [
        // meio de contato para agendamento
        "Ligou para telefone fixo",
        "Ligou para celular",
        "Ligou para WhatsApp",
        "Mandou mensagem no WhatsApp",
        "Mandou mensagem no Instagram",
        "Agendou direto na recepção",
      ],
      meiosChosen: [
        // meio de contato para agendamento
        "Ligou para telefone fixo",
        "Ligou para celular",
        "Ligou para WhatsApp",
        "Mandou mensagem no WhatsApp",
        "Mandou mensagem no Instagram",
        "Agendou direto na recepção",
      ],
      motivos: [
        "Buscava plano de saúde",
        '"Vou pensar..."',
        "Outros",
        "Avaliação Gratuita",
        "Pré-Reserva 1ª Consulta",
      ],
      motivosChosen: ["Buscava plano de saúde", '"Vou pensar..."', "Outros"],
      filterProspectosDialog: false,
      visualizarProspectosDialog: false,
      nrFiltrados: null,
      nrProspectos: null,
      rules: {
        dataNasc: [
          function (v) {
            if (v.includes("-")) {
              if (v.split("-").length == 3) {
                if (
                  v.split("-")[0].length == 4 &&
                  v.split("-")[1].length == 2 &&
                  v.split("-")[1] <= 12 &&
                  v.split("-")[2].length == 2 &&
                  v.split("-")[2] <= 31
                ) {
                  return true;
                }
              } else {
                return "Usar formato automático! (por exemplo: 2022-12-23)";
              }
            } else {
              return "Usar formato automático! (por exemplo: 2022-12-23)";
            }
          },
        ],
      },
      pacienteDocID: null,
      pacienteEvents: [],
      pacoteHeaders: [
        { text: "Pacote", value: "nome" },
        { text: "Mês do programa", value: "dataInicio" },
        { text: "Area", value: "area" },
      ],
    };
  },
  methods: {
    saveStart(date) {
      this.$refs.menuStart.save(date);
      this.filterProspectos();
    },
    saveEnd(date) {
      this.$refs.menuEnd.save(date);
      this.filterProspectos();
    },
    async convertToPatient(obj) {
      this.$store.dispatch("firestore/agendaPacienteNome", {
        obj: obj,
      });
      // await firebase
      //   .firestore()
      //   .collection("users")
      //   .doc(sessionStorage.getItem("current-user"))
      //   .collection("ligacoes")
      //   .doc(obj.millis.toString())
      //   .update({
      //     converion: true
      //   });
    },
    async getProspectos() {
      this.chargeProspectos = true;

      const allLigacoes = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("ligacoes")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      var prospectos = allLigacoes.filter(function (e) {
        return !e.conversion;
      });

      function compare(a, b) {
        if (a.millis < b.millis) {
          return 1;
        }
        if (a.millis > b.millis) {
          return -1;
        }
      }

      prospectos = prospectos.sort(compare);

      this.prospectos = prospectos;
      this.filteredProspectos = prospectos;
      this.searchFilteredProspectos = prospectos.map(function (p) {
        return {
          text: p.nome,
          value: p.millis.toString(),
          data: p,
        };
      });
      this.nrProspectos = prospectos.length;

      // this.filterProspectosDialog = true;
      this.chargeProspectos = false;
    },
    filterProspectos() {
      console.log("not filtered", this.prospectos);
      var objetivosChosen = this.objetivosChosen;
      var sexosChosen = this.sexosChosen;
      var meiosChosen = this.meiosChosen;
      var motivosChosen = this.motivosChosen;
      var startChosen = new Date(this.startChosen + " 03:00:00");
      var startMillis = startChosen.getTime();
      var endChosen = new Date(this.endChosen + " 23:00:00");
      var endMillis = endChosen.getTime();

      var filteredProspectos = this.prospectos.filter(function (e) {
        return (
          sexosChosen.includes(e.sexo) &&
          objetivosChosen.includes(e.objetivo) &&
          meiosChosen.includes(e.meioContato) &&
          motivosChosen.includes(e.motivoRecusao) &&
          startMillis < e.millis &&
          endMillis > e.millis
        );
      });

      this.filteredProspectos = filteredProspectos;
      this.searchFilteredProspectos = filteredProspectos.map(function (p) {
        return {
          text: p.nome,
          value: p.millis.toString(),
          data: p,
        };
      });
      this.nrFiltrados = filteredProspectos.length;
      console.log("filtered", this.prospectos);
    },
    filterProspectsFromSearch() {
      console.log(this.searchProspecto);
      var searchProspecto = this.searchProspecto;
      if (
        this.searchProspecto !== null &&
        this.searchProspecto !== undefined &&
        this.searchProspecto !== ""
      ) {
        this.filteredProspectosBeforeSearch = this.filteredProspectos;
        this.filteredProspectos = this.filteredProspectos.filter(function (
          element
        ) {
          return element.millis.toString() == searchProspecto;
        });
      } else {
        this.filteredProspectos = this.filteredProspectosBeforeSearch;
      }
      console.log(this.filteredProspectos);
    },
    downloadProspectos() {
      setTimeout(() => {
        // console.log(this.body);
        alert("Gerando PDF");
        var doc = new jspdf("l");
        var fontSize = 12;
        doc.setFontSize(fontSize);
        doc.setFont("Times");

        console.log(this.filteredProspectos);
        var body = this.filteredProspectos.map(function (p) {
          return [
            p.dia + "/" + p.mes + "/" + p.ano + ", " + p.horarioLigacao,
            p.meioContato,
            p.objetivo,
            p.nome,
            p.email,
            p.telefone,
            p.whatsapp,
          ];
        });
        // console.log(body);

        doc.autoTable({
          startY: 0,
          startx: 0,
          head: [
            [
              "Data/Hora",
              "Meio de contato",
              "Objetivo",
              "Nome",
              "Email",
              "Telefone",
              "WhatsApp",
            ],
          ],
          body: body,
        });

        doc.save("prospectos.pdf");
      }, 500);
    },
    // async copyContent() {
    //   this.copying = true;
    //   // var contentDoc = await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   // .doc("fortaleza.salinas@clubedanutricionista.com.br")
    //   //   // .collection("pacientes")
    //   //   .doc("luizafranca03@htmail.com")
    //   //   .get()
    //   //   .then(function (doc) {
    //   //     return doc.data();
    //   //   });

    //   //   console.log(contentDoc)

    //   var targetCol = "compras";
    //   var contentCollection = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc("luizafranca03@htmail.com")
    //     .collection(targetCol)
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         return doc.data();
    //       })
    //     );

    //   // await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   // .doc("fortaleza.salinas@clubedanutricionista.com.br")
    //   //   // .collection("pacientes")
    //   //   .doc("luizafranca03@hotmail.com")
    //   //   .set(contentDoc);
    //   // // var pacienteListaAcopiar = [
    //   // //   "ronaldobatista12@hotmail.com",
    //   // // ];
    //   // // contentCollection = contentCollection.filter(function (doc) {
    //   // //   return pacienteListaAcopiar.includes(doc.email);
    //   // // });
    //   // // contentCollection = contentCollection.filter(function (doc) {
    //   // //   var d = new Date(doc.start);
    //   // //   var dMillis = d.getTime();
    //   // //   var is2022 = dMillis > 1641020400000;
    //   // //   return is2022;
    //   // // });

    //   contentCollection.forEach(async function (doc) {
    //     await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc("luizafranca03@hotmail.com")
    //       .collection(targetCol)
    //       .doc(doc.millis.toString())
    //       .set(doc);
    //   });
    //   // console.log(contentCollection);
    //   console.log("done copying!");

    //   this.copying = false;
    // },
    verDetalhes(obj) {
      this.inspectProspect = obj;
      this.inspectProspectDialog = true;
    },
    readableDate(millis) {
      var millisNr = parseFloat(millis);
      var date = new Date(millisNr);
      var year = date.getFullYear().toString();
      var month = date.getMonth() + 1;
      var monthStr;
      if (month > 9) {
        monthStr = month.toString();
      } else {
        monthStr = "0" + month.toString();
      }
      var day = date.getDate();
      var dayStr;
      if (day > 9) {
        dayStr = day.toString();
      } else {
        dayStr = "0" + day.toString();
      }

      var niceDate = dayStr + "/" + monthStr + "/" + year;

      return niceDate;
    },
    cardColor(obj) {
      if (
        obj.motivoRecusao == "Avaliação Gratuita" ||
        obj.motivoRecusao == "Pré-Reserva 1ª Consulta" ||
        obj.motivoRecusao.startsWith("BONUS")
      ) {
        return "#ffb8e4";
      } else if (obj.motivoRecusao == '"Vou pensar..."') {
        return "#ffffa3";
      } else {
        return "#b8eeff";
      }
    },
    fillBody() {
      console.log(this.pacientes);
      this.body = this.pacientes.map(function (paciente) {
        var strDate = function (stamp) {
          var d = new Date(stamp);
          var dia = d.getDate();
          var month = d.getMonth() + 1;
          var year = d.getFullYear();
          var dateStr = dia + "/" + month + "/" + year;
          return dateStr;
        };
        var strDateN = function (zStr) {
          var zDate = new Date(zStr + " 06:00:00");
          var year = zDate.getFullYear();
          var month = zDate.getMonth() + 1;
          var day = zDate.getDate();
          var monthStr;
          var dayStr;
          if (month > 9) {
            monthStr = month.toString();
          } else {
            monthStr = "0" + month.toString();
          }
          if (day > 9) {
            dayStr = day.toString();
          } else {
            dayStr = "0" + day.toString();
          }
          var dateNStr = dayStr + "/" + monthStr + "/" + year.toString();
          return dateNStr;
        };
        var nome = paciente.text;
        var dataEntrada = strDate(paciente.data.joinedAt.seconds * 1000);
        var dataN = strDateN(paciente.data.dataNascimento);
        var email = paciente.value;
        var telefone = paciente.data.telefone;
        var zap = paciente.data.whatsapp;

        return [nome, dataN, dataEntrada, email, telefone, zap];
      });
    },
  },
  computed: {
    today() {
      var now = new Date();
      var time = now.getTime();
      return this.readableDate(time);
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
  },
  mounted() {
    this.getProspectos();
  },
};
</script>