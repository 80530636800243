<template>
  <v-container>
    <div class="d-flex flex-column">
      <v-card
        class="pa-5 my-1"
        tile
        style="background-color: rgba(197, 209, 63, 0.6)"
      >
        <div
          class="d-flex flex-column"
          v-for="(formula, formulaIndex) in prescricaoC.formulasList"
          :key="formulaIndex"
        >
          <v-row class="d-flex align-center">
            <v-col cols="11">
              <v-row class="mx-4">
                <v-col cols="5">
                  <v-autocomplete
                    label="Fórmula"
                    no-data-text="..."
                    :items="formulasItens"
                    v-model="formula.id"
                    style="width: 100%; max-width: 250px"
                    @change="onFormulaSelected(formulaIndex, formula.id)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    label="categoria:"
                    v-model="catBool"
                    @change="updateFormulaItensAll"
                    color="#614021"
                  ></v-checkbox>
                </v-col>
                <v-col cols="5">
                  <v-select
                    :items="categorias"
                    v-model="categoriaSelected"
                    label="categoria"
                    @change="updateFormulasItens(categoriaSelected)"
                    :disabled="!catBool"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row class="mx-4">
                <v-text-field
                  v-model="formula.titulo"
                  solo
                  label="Título"
                  @change="onUpdate"
                  clearable
                ></v-text-field>
              </v-row>
              <v-row class="mx-4">
                <v-textarea
                  v-model="formula.prescricoes"
                  solo
                  label="Prescrição e Posologia"
                  height="250"
                  @change="onUpdate"
                  clearable
                ></v-textarea>
              </v-row>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="prescricaoC.formulasList.length > 1"
                    elevation="2"
                    rounded
                    icon
                    outlined
                    raised
                    x-small
                    color="red"
                    class="pa-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="removeFormula(formulaIndex)"
                  >
                    <v-icon>{{ icons.mdiClose }}</v-icon>
                  </v-btn>
                </template>
                <span>remover formula</span>
              </v-tooltip>

              <v-tooltip bottom color="green">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="
                      formulaIndex === prescricaoC.formulasList.length - 1
                    "
                    elevation="2"
                    rounded
                    icon
                    outlined
                    raised
                    x-small
                    color="green"
                    class="pa-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="addFormula"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>adicionar nova formula</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mdiClose, mdiPlus, mdiAutorenew } from "@mdi/js";

export default {
  data: function() {
    return {
      icons: { mdiClose, mdiPlus, mdiAutorenew },
      prescricaoC: {
        nome: "",
        formulasList: [],
        observacoes: "",
      },
      categorias: [
        "Fórmulas veganas",
        "Fórmulas na pediatria",
        "Fórmulas nutrição e estética",
        "Fórmulas nutrição esportiva",
        "Fórmulas modulação intestinal",
        "Fórmulas saúde do homem",
        "Fórmulas saúde da mulher",
        "Fórmulas para imunidade",
        "Fórmulas para obesidade e emagrecimento",
        "Fórmulas para metais pesados",
        "Fórmulas neuro e sono",
        "Fórmulas longevidade",
        "Fórmulas para diabéticos",
        "Fórmulas de vitaminas e minerais",
        "Fórmulas de alergia e intolerância alimentar",
        "Fórmulas de patologia",
      ],
      categoriaSelected: null,
      catBool: false,
      formulasItens: [],
    };
  },
  computed: {
    formulas() {
      return this.$store.state.firestore.formulas;
    },
    // formula() {
    //   return this.$store.state.firestore.formula;
    // },
  },
  methods: {
    addFormula() {
      console.log("adding default formula")
      this.prescricaoC.formulasList.push({
        titulo: "",
        prescricao: "",
      });
      this.$store.dispatch("firestore/prescricaoCUpdate", {
        prescricaoC: this.prescricaoC,
      });
    },
    removeFormula: function(formulaToRemove) {
      this.prescricaoC.formulasList.splice(
        this.prescricaoC.formulasList.indexOf(formulaToRemove),
        1
      );
      this.$store.dispatch("firestore/prescricaoCUpdate", {
        prescricaoC: this.prescricaoC,
      });
    },
    onUpdate() {
      this.$store.dispatch("firestore/prescricaoCUpdate", {
        prescricaoC: this.prescricaoC,
      });
    },
    updateFormulasItens(cat) {
      var formulasCat = this.formulas.filter(function(el) {
        if (el.data.categorias) {
          return el.data.categorias.includes(cat);
        } else {
          return false;
        }
      });
      this.formulasItens = formulasCat;
      console.log(this.formulasItens);
    },
    updateFormulaItensAll() {
      if (!this.catBool) {
        this.formulasItens = this.formulas;
      }
    },
    onFormulaSelected(index, id) {
      var formulaSelecionada = this.formulas.find((item) => item.value == id);
      this.prescricaoC.formulasList[index] = formulaSelecionada.data;
    },
  },
  mounted() {
    if (this.$store.state.firestore.prescricaoC.formulasList[0] == undefined) {
      this.addFormula();
    }
    this.$store.dispatch("firestore/getFormulas");
    this.formulasItens = this.formulas;
    setTimeout(() => {
      console.log("2: this.$store.state.firestore.prescricaoC", this.$store.state.firestore.prescricaoC)
      this.prescricaoC = this.$store.state.firestore.prescricaoC;
    }, 1000);
  },
};
</script>
