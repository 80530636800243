import firebase from "firebase";
import router from "../../router";

export async function userJoin(
  { commit },
  { email, password, /*nomeClinica, nomeSite, plano,*/ nome }
) {
  // await firebase
  //   .firestore()
  //   .collection("users")
  //   .where("nomeSite", "==", nomeSite)
  //   .get()
  //   .then(function(querySnapshot) {
  //     if (querySnapshot.docs.length === 0) {
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((user) => {
      commit("setUser", user.email);
      commit("setIsAuthenticated", true);
      router.replace("/personalData");
    })
    .catch((error) => {
      commit("setUser", "");
      commit("setIsAuthenticated", false);
      if (error.code === "FIRAuthErrorCodeEmailAlreadyInUse") {
        commit("setAlertsEmailOk", true);
      }
    });

  firebase
    .firestore()
    .collection("users")
    .doc(email)
    .set({
      isPatient: false,
      joinedAt: firebase.firestore.FieldValue.serverTimestamp(),
      daysJoined: 0,
      // nomeClinica: nomeClinica,
      // nomeSite: nomeSite,
      nome: nome,
      // plano: plano,
      plano: "TESTE",
      cnpj: "CNPJ",
      crn: "CRN",
      phone: "Seu Telefone",
      phoneClients: "Telefone para seus Clientes",
      addressRoad: 'Endereço: "Avenida Paulista, 1150"',
      addressComplement: 'Complemento: "Edifício Don Pedro II, Sala 1302"',
      addressCep: "CEP (código postal)",
      addressBairro: "Bairro",
      addressCity: "Cidade",
      addressCountry: "País",
      imagem:
        "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/defaultImgs%2FdefaultProfilePic.png?alt=media&token=ecefb364-1fa1-4775-89e6-d1db88abd021",
      pagamentoEmOrdem: false,
    });
  // commit("setNomeClinicaExists", false);
  //   } else {
  //     commit("setNomeClinicaExists", true);
  //   }
  // })
  // .catch(function(error) {
  //   console.log(error.message);
  // });
}

export async function updateQuest(
  { commit },
  { pacienteEmail, nutri, preAtendimentoToFill, imunidadeToFill, estetToFill }
) {
  if (estetToFill) {
    await firebase
      .firestore()
      .collection("users")
      .doc(pacienteEmail)
      .update({
        preAtendimentoToFill: preAtendimentoToFill,
        imunidadeToFill: imunidadeToFill,
        estetToFill: estetToFill,
        contratoVersion: 100,
        contractDialog: true,
        clausulaDialog: true,
      });
  } else if (preAtendimentoToFill) {
    await firebase
      .firestore()
      .collection("users")
      .doc(pacienteEmail)
      .update({
        preAtendimentoToFill: preAtendimentoToFill,
        imunidadeToFill: imunidadeToFill,
        estetToFill: estetToFill,
        contratoVersion: 4,
        contractDialog: true,
        clausulaDialog: true,
      });
  } else if (imunidadeToFill) {
    await firebase
      .firestore()
      .collection("users")
      .doc(pacienteEmail)
      .update({
        preAtendimentoToFill: preAtendimentoToFill,
        imunidadeToFill: imunidadeToFill,
        estetToFill: estetToFill,
        contratoVersion: 4,
        contractDialog: true,
        clausulaDialog: true,
      });
  }

  await firebase
    .firestore()
    .collection("users")
    .doc(nutri)
    .collection("pacientes")
    .doc(pacienteEmail)
    .update({
      preAtendimentoToFill: preAtendimentoToFill,
      imunidadeToFill: imunidadeToFill,
      estetToFill: estetToFill,
    });

  commit("dummySetter", "dummySet");
}

export async function pacienteJoin(
  { commit },
  { pacienteInfos, preAtendimentoToFill, imunidadeToFill }
) {
  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteInfos.email)
    .set({
      nutri: sessionStorage.getItem("current-user"),
      isPatient: true,
      joinedAt: firebase.firestore.FieldValue.serverTimestamp(),
      daysJoined: 0,
      // pacienteNr: pacienteNr,
      gestante: pacienteInfos.gestante,
      sexo: pacienteInfos.sexo,
      dataNascimento: pacienteInfos.dataNascimento,
      telefone: pacienteInfos.telefone,
      whatsapp: pacienteInfos.whatsapp,
      nome: pacienteInfos.nome,
      email: pacienteInfos.email,
      preAtendimentoToFill: preAtendimentoToFill,
      imunidadeToFill: imunidadeToFill,
      cpf: pacienteInfos.cpf,
      objetivo: pacienteInfos.objetivo,
      anamneses: 0,
      programaFim: 0,
      programaTipo: "",
      contratoVersion: 4,
      contractDialog: true,
      clausulaDialog: true,
      pacienteInfos: {
        endereco: "",
        cidade: "",
        estado: "",
        profissao: "",
        estadoCivil: null,
        filhos: "",
        idadeFilhos: "",
        indicacao: "",
        pesoAtual: "",
        altura: "",
        pesoConsidera: "",
        problemasSaude: "",
        suplemento: "",
        medicamento: "",
        sangue: "",
        imagem: "",
        alergia: [],
        alergiaOutro: "",
        resultado: "",
        refeicoesHorarios: "",
        gostaCozinhar: "Sim",
        outroCozinhar: "Sim",
        fazAtividade: "Sim",
        atividadeDetalhe: "",
        cafe: "",
        cafeFrutas: "",
        cafeTolerar: [],
        lanches: [],
        frutasGostar: "",
        frutasNaoTolerar: "",
        almocoFeijao: "",
        almocoSalada: "",
        aversaoVegetal: "",
        leguminosas: "",
        pures: "",
        massas: "",
        frangoCarnePeixe: "",
        jantar: [],
        ceia: "",
        gastrointestinal: "Sim",
        unhasCabelo: "Sim",
        ansiedade: "Sim",
        imunidade: "Sim",
        sono: "Sim",
        estrassada: "Sim",
        gastrointestinalComment: "",
        unhasCabeloComment: "",
        ansiedadeComment: "",
        imunidadeComment: "",
        sonoComment: "",
        estrassadaComment: "",
      },
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("pacientes")
    .doc(pacienteInfos.email)
    .set({
      isPatient: true,
      joinedAt: firebase.firestore.FieldValue.serverTimestamp(),
      daysJoined: 0,
      // pacienteNr: pacienteNr,
      gestante: pacienteInfos.gestante,
      sexo: pacienteInfos.sexo,
      dataNascimento: pacienteInfos.dataNascimento,
      telefone: pacienteInfos.telefone,
      whatsapp: pacienteInfos.whatsapp,
      nome: pacienteInfos.nome,
      email: pacienteInfos.email,
      objetivo: pacienteInfos.objetivo,
      preAtendimentoToFill: preAtendimentoToFill,
      imunidadeToFill: imunidadeToFill,
      cpf: pacienteInfos.cpf,
      anamneses: 0,
      programaTipo: "",
      programaFim: 0,
    });

  var d = new Date();
  var millis = d.getTime();
  var timeId = millis.toString();
  var ano = d.getFullYear();
  var mes0 = d.getMonth();
  var mes = mes0 + 1;
  var dia = d.getDate();

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("ligacoes")
    .doc(timeId)
    .set({
      telefone: pacienteInfos.telefone,
      whatsapp: pacienteInfos.whatsapp,
      nome: pacienteInfos.nome,
      email: pacienteInfos.email,
      sexo: pacienteInfos.sexo,
      objetivo: pacienteInfos.objetivo,
      horarioLigacao: pacienteInfos.horarioLigacao,
      meioContato: pacienteInfos.meioContato,
      conversion: true,
      ano: ano,
      mes: mes,
      dia: dia,
      millis: millis,
    });

  await firebase
    .auth()
    .createUserWithEmailAndPassword(pacienteInfos.email, "nutri123")
    .then((user) => {
      // commit("setUser", user.email);
      // commit("setIsAuthenticated", true);
      // router.replace("/personalData");
      console.log(user);
      commit("dummySetter", "dummySet");
    })
    .catch((error) => {
      // commit("setUser", "");
      // commit("setIsAuthenticated", false);
      // if (error.code === "FIRAuthErrorCodeEmailAlreadyInUse") {
      //   commit("setAlertsEmailOk", true);
      // }
      console.log(error);
    });
  console.log("cadastro completo");
}

export async function pacienteNonConverted({ commit }, { pacienteInfos }) {
  var d = new Date();
  var millis = d.getTime();
  var millis7 = 7 * 24 * 60 * 60 * 1000 + millis;
  var timeId = millis.toString();
  var timeId7 = millis7.toString();
  var ano = d.getFullYear();
  var mes0 = d.getMonth();
  var mes = mes0 + 1;
  var dia = d.getDate();

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("ligacoes")
    .doc(timeId)
    .set({
      telefone: pacienteInfos.telefone,
      whatsapp: pacienteInfos.whatsapp,
      nome: pacienteInfos.nome,
      email: pacienteInfos.email,
      sexo: pacienteInfos.sexo,
      objetivo: pacienteInfos.objetivo,
      motivoRecusao: pacienteInfos.motivoRecusao,
      motivoRecusaoOutro: pacienteInfos.motivoRecusaoOutro,
      horarioLigacao: pacienteInfos.horarioLigacao,
      meioContato: pacienteInfos.meioContato,
      detalhesImportantes: pacienteInfos.detalhesImportantes,
      conversion: false,
      ano: ano,
      mes: mes,
      dia: dia,
      millis: millis,
    });

  if (pacienteInfos.motivoRecusao == '"Vai pensar..."') {
    await firebase
      .firestore()
      .collection("users")
      .doc(sessionStorage.getItem("current-user"))
      .collection("lembretes")
      .doc(timeId7)
      .set({
        tipo: "follow-up-1-prospecto-vaiPensar",
        done: false,
        status: "notDueYet",
        dueDate: millis7,
        telefone: pacienteInfos.telefone,
        whatsapp: pacienteInfos.whatsapp,
        nome: pacienteInfos.nome,
        email: pacienteInfos.email,
        sexo: pacienteInfos.sexo,
        objetivo: pacienteInfos.objetivo,
        motivoRecusao: pacienteInfos.motivoRecusao,
        motivoRecusaoOutro: pacienteInfos.motivoRecusaoOutro,
        horarioLigacao: pacienteInfos.horarioLigacao,
        meioContato: pacienteInfos.meioContato,
        conversion: false,
        detalhesImportantes: pacienteInfos.detalhesImportantes,
        ano: ano,
        mes: mes,
        dia: dia,
        millis: millis,
      });
  }

  commit("dummySetter", "dummySet");
}

export function setFuncionarioOnline({ commit }, { funcionario }) {
  commit("setFuncionarioOnline", funcionario);
}

export async function getPreAtendimentoToFill({ commit }) {
  firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .get()
    .then(async (snapshot) => {
      commit(
        "setPreAtendimentoToFill",
        await snapshot.data().preAtendimentoToFill
      );
    });
}

export async function userLogin({ commit }, { email, password }) {
  await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async (user) => {
      console.log(user.user.email.toString());
      commit("setDefaultUser", {
        email: user.user.email.toString(),
        senha: password,
      });
      commit("setUser", user.user.email.toString());
      commit("setIsAuthenticated", true);
      commit("setAlertsLoginOk", false);

      // async function getJoinDate() {
      //   return await firebase
      //     .firestore()
      //     .collection("users")
      //     .doc(user.user.email)
      //     .get()
      //     .then((snapshot) => {
      //       return snapshot.data().joinedAt.toMillis();
      //     });
      // }

      // let joinedAt = await getJoinDate();
      // let daysIn = (new Date().getTime() - joinedAt) / 86400000;
      // let daysInFull = Math.floor(daysIn);
      // console.log(daysInFull);
      // commit("setDaysIn", daysInFull);

      await firebase
        .firestore()
        .collection("users")
        .doc(user.user.email.toString())
        .get()
        .then(async (snapshot) => {
          commit("setUserInfo", await snapshot.data());
        });
      sessionStorage.setItem("isAuthenticated", true);
      sessionStorage.setItem("current-user", user.user.email.toString());
      router.replace("/home");
    })
    .catch((error) => {
      console.log(error.message);
      sessionStorage.removeItem("isAuthenticated");
      commit("setUser", null);
      commit("setIsAuthenticated", false);
      commit("setAlertsLoginOk", true);
    });
}

export async function getUserInfoUpdates({ commit }) {
  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .get()
    .then(async (snapshot) => {
      commit("setUserInfo", await snapshot.data());
    });
  console.log("got userInfo updates");
}

export function userAdminLogin({ commit }, { email, password }) {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async (user) => {
      commit("setUser", user.user.email.toString());
      sessionStorage.setItem("current-user", user.user.email.toString());
      sessionStorage.setItem("isAuthenticated", true);
      commit("setIsAuthenticated", true);
      commit("setAlertsLoginOk", false);

      router.replace("/adminHome");
    })
    .catch((error) => {
      console.log(error.message);
      sessionStorage.removeItem("isAuthenticated");
      commit("setUser", null);
      commit("setIsAuthenticated", false);
      commit("setAlertsLoginOk", true);
    });
}

export async function sendClausulaConcordo({ commit }) {
  // await firebase
  //   .firestore()
  //   .collection("users")
  //   .doc(sessionStorage.getItem("current-user"))
  //   .update({
  //     clausulaDialog: false,
  //     confirmIp: ip
  //   })

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .get()
    .then(async (snapshot) => {
      commit("setUserInfo", await snapshot.data());
    });
}

export async function sendContractConcordo({ commit }) {
  // await firebase
  //   .firestore()
  //   .collection("users")
  //   .doc(sessionStorage.getItem("current-user"))
  //   .update({
  //     contractDialog: false,
  //     imagemNao: imagemNao,
  //     confirmIp: ip
  //   })

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .get()
    .then(async (snapshot) => {
      commit("setUserInfo", await snapshot.data());
    });
}

export function userPacienteLogin({ commit }, { email, password }) {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async (user) => {
      commit("setUser", user.user.email.toString());
      commit("setDefaultUserPaciente", {
        email: user.user.email.toString(),
        senha: password,
      });
      commit("setIsAuthenticated", true);
      commit("setAlertsLoginOk", false);

      var now = new Date();
      var millis = now.getTime();

      await firebase
        .firestore()
        .collection("users")
        .doc(user.user.email.toString())
        .collection("accesses")
        .doc(millis.toString())
        .set({
          millis: millis,
        });
      //   .get()
      //   .then(async (snapshot) => {
      //     commit("setPacienteNutri", await snapshot.data().nutri);
      //     commit("setPacienteNutriLogo", await snapshot.data().logo);
      //     commit("setPacienteNutriSign", await snapshot.data().signature);
      //   });

      // async function getJoinDate() {
      //   return await firebase
      //     .firestore()
      //     .collection("users")
      //     .doc(user.user.email)
      //     .get()
      //     .then((snapshot) => {
      //       return snapshot.data().joinedAt.toMillis();
      //     });
      // }

      // let joinedAt = await getJoinDate();
      // let daysIn = (new Date().getTime() - joinedAt) / 86400000;
      // let daysInFull = Math.floor(daysIn);
      // console.log(daysInFull);
      // commit("setDaysIn", daysInFull);

      firebase
        .firestore()
        .collection("users")
        .doc(user.user.email.toString())
        .get()
        .then(async (snapshot) => {
          commit("setUserInfo", await snapshot.data());
        });

      const nutriId = await firebase
        .firestore()
        .collection("users")
        .doc(user.user.email.toString())
        .get()
        .then(function(doc) {
          return doc.data().nutri;
        });

      const nutriData = await firebase
        .firestore()
        .collection("users")
        .doc(nutriId)
        .get()
        .then(function(doc) {
          return {
            // nutriUseLogo: doc.data().useLogo,
            // nutriCrn: doc.data().crn,
            // nutriSign: doc.data().signature,
            // nutriLogo: doc.data().logo,
            nutriName: doc.data().name,
          };
        });

      // commit("setPacienteCrn", nutriData.nutriCrn);
      // commit("setPacienteNutriUseLogo", nutriData.nutriUseLogo);
      // commit("setPacienteNutriSign", nutriData.nutriSign);
      // commit("setPacienteNutriLogo", nutriData.nutriLogo);
      commit("setPacienteNutriName", nutriData.nutriName);

      sessionStorage.setItem("isAuthenticated", true);
      sessionStorage.setItem("current-user", user.user.email.toString());
      // if (user.user.email.toString() !== "aaa.zzz@test.com") {
      //   router.replace("/AreaDoPaciente");
      // } else {
      router.replace("/AreaDoPacienteWork");
      // }
    })
    .catch((error) => {
      console.log(error.message);
      sessionStorage.removeItem("isAuthenticated");
      commit("setUser", null);
      commit("setIsAuthenticated", false);
      commit("setAlertsLoginOk", true);
    });
}

export function userParceiroLogin({ commit }, { email, password }) {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async (user) => {
      commit("setUser", user.user.email.toString());
      commit("setIsAuthenticated", true);
      commit("setAlertsLoginOk", false);

      firebase
        .firestore()
        .collection("users")
        .doc(user.user.email.toString())
        .get()
        .then(async (snapshot) => {
          commit("setUserInfo", await snapshot.data());
        });

      // const nutriId = await firebase.firestore()
      //   .collection("users")
      //   .doc(user.user.email)
      //   .get().then(
      //     function (doc) { return doc.data().nutri }
      //   );

      // const nutriData = await firebase.firestore()
      //   .collection("users")
      //   .doc(nutriId)
      //   .get().then(
      //     function (doc) {
      //       return {
      //         nutriUseLogo: doc.data().useLogo,
      //         nutriCrn: doc.data().crn,
      //         nutriSign: doc.data().signature,
      //         nutriLogo: doc.data().logo,
      //         nutriName: doc.data().name
      //       }
      //     }
      //   );

      // commit("setPacienteCrn", nutriData.nutriCrn);
      // commit("setPacienteNutriUseLogo", nutriData.nutriUseLogo);
      // commit("setPacienteNutriSign", nutriData.nutriSign);
      // commit("setPacienteNutriLogo", nutriData.nutriLogo);
      // commit("setPacienteNutriName", nutriData.nutriName);

      sessionStorage.setItem("isAuthenticated", true);
      sessionStorage.setItem("current-user", user.user.email.toString());
      router.replace("/AreaDoParceiro");
    })
    .catch((error) => {
      console.log(error.message);
      sessionStorage.removeItem("isAuthenticated");
      commit("setUser", null);
      commit("setIsAuthenticated", false);
      commit("setAlertsLoginOk", true);
    });
}

export function userResetPassword({ commit }, { email }) {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(function() {
      commit("setResetEmailsent", true);
      commit("setAlertsLoginOk", false);
    })
    .catch(function(error) {
      console.log(error.message);
    });
}

export async function setUserPersonalData(
  { commit },
  {
    userInfos,
    userEmail,
    userPictureUrl,
    // userSignatureUrl,
    // userLogoUrl,
    // useLogo,
  }
) {
  await firebase
    .firestore()
    .collection("users")
    .doc(userEmail)
    .update({
      name: userInfos.name,
      cnpj: userInfos.cnpj,
      // crn: userInfos.crn,
      phone: userInfos.phone,
      phoneClients: userInfos.phoneClients,
      addressRoad: userInfos.addressRoad,
      addressComplement: userInfos.addressComplement,
      addressCep: userInfos.addressCep,
      addressCity: userInfos.addressCity,
      addressCountry: userInfos.addressCountry,
      addressBairro: userInfos.addressBairro,
      profilePicture: userPictureUrl,
      // signature:
      //   userSignatureUrl != ""
      //     ? userSignatureUrl
      //     : response.data().signature,
      // logo: userLogoUrl != "" ? userLogoUrl : response.data().logo,
      // useLogo: useLogo,
    });

  var userInfoUpdate = {
    name: userInfos.name,
    cnpj: userInfos.cnpj,
    // crn: userInfos.crn,
    phone: userInfos.phone,
    phoneClients: userInfos.phoneClients,
    addressRoad: userInfos.addressRoad,
    addressComplement: userInfos.addressComplement,
    addressCep: userInfos.addressCep,
    addressCity: userInfos.addressCity,
    addressCountry: userInfos.addressCountry,
    addressBairro: userInfos.addressBairro,
    profilePicture: userPictureUrl,
  };

  commit("setUserInfo", userInfoUpdate);
}

export async function setUserPersonalData_noPic(
  { commit },
  {
    userInfos,
    userEmail,
    // userPictureUrl,
    // userSignatureUrl,
    // userLogoUrl,
    // useLogo,
  }
) {
  await firebase
    .firestore()
    .collection("users")
    .doc(userEmail)
    .update({
      name: userInfos.name,
      cnpj: userInfos.cnpj,
      // crn: userInfos.crn,
      phone: userInfos.phone,
      phoneClients: userInfos.phoneClients,
      addressRoad: userInfos.addressRoad,
      addressComplement: userInfos.addressComplement,
      addressCep: userInfos.addressCep,
      addressCity: userInfos.addressCity,
      addressCountry: userInfos.addressCountry,
      addressBairro: userInfos.addressBairro,
      // profilePicture:
      //   userPictureUrl != ""
      //     ? userPictureUrl
      //     : response.data().profilePicture,
      // signature:
      //   userSignatureUrl != ""
      //     ? userSignatureUrl
      //     : response.data().signature,
      // logo: userLogoUrl != "" ? userLogoUrl : response.data().logo,
      // useLogo: useLogo,
    });

  var userInfoUpdate = {
    name: userInfos.name,
    cnpj: userInfos.cnpj,
    // crn: userInfos.crn,
    phone: userInfos.phone,
    phoneClients: userInfos.phoneClients,
    addressRoad: userInfos.addressRoad,
    addressComplement: userInfos.addressComplement,
    addressCep: userInfos.addressCep,
    addressCity: userInfos.addressCity,
    addressCountry: userInfos.addressCountry,
    addressBairro: userInfos.addressBairro,
  };

  commit("setUserInfo", userInfoUpdate);
}

export async function getHiddenInfo({ commit }, { nutri }) {
  var data = await firebase
    .firestore()
    .collection("users")
    .doc(nutri)
    .collection("pacientes")
    .doc(sessionStorage.getItem("current-user"))
    .get()
    .then(function(doc) {
      return doc.data();
    });

  var infos = {
    lastFalaDateStr: data.lastFalaDateStr,
    lastFalaHora: data.lastFalaHora,
    unansweredP: data.unansweredP,
    unansweredN: data.unansweredN,
  };

  commit("setHiddenInfo", infos);
}

export async function chatCurrent({ commit }, { pId }) {
  var pacienteName = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("pacientes")
    .doc(pId)
    .get()
    .then(function(doc) {
      return doc.data().nome;
    });

  var falas = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("pacientes")
    .doc(pId)
    .collection("falas")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  var dialogObj = {
    nome: pacienteName,
    email: pId,
    elements: falas,
  };
  console.log(dialogObj);

  commit("setCurrentDialog", dialogObj);
}

export async function chatCurrentP({ commit }, { nutri, pacienteName }) {
  var falas = await firebase
    .firestore()
    .collection("users")
    .doc(nutri)
    .collection("pacientes")
    .doc(sessionStorage.getItem("current-user"))
    .collection("falas")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  var dialogObj = {
    nome: pacienteName,
    email: sessionStorage.getItem("current-user"),
    elements: falas,
  };
  console.log(dialogObj);

  commit("setCurrentDialog", dialogObj);
}

export async function sendTextN(
  { commit },
  { pId, text, id, lastFalaHora, lastFalaMillis, lastFalaDateStr }
) {
  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("pacientes")
    .doc(pId)
    .collection("falas")
    .doc(id)
    .set({
      text: text,
      fromP: false,
      fromN: true,
      id: id,
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("pacientes")
    .doc(pId)
    .update({
      lastFalaHora: lastFalaHora,
      lastFalaMillis: lastFalaMillis,
      lastFalaDateStr: lastFalaDateStr,
      unansweredP: true,
      unansweredN: false,
    });

  var unansweredArrayGot = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .get()
    .then(function(doc) {
      return doc.data().unansweredArray;
    });

  var unansweredArrayNew = [];

  if (unansweredArrayGot !== undefined) {
    unansweredArrayNew = unansweredArrayGot.filter(function(e) {
      return e !== pId;
    });
  }

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .update({
      unansweredArray: unansweredArrayNew,
    });

  commit("dummySetter", "dummySet");
}

export async function markAsRead({ commit }, { nutri }) {
  await firebase
    .firestore()
    .collection("users")
    .doc(nutri)
    .collection("pacientes")
    .doc(sessionStorage.getItem("current-user"))
    .update({
      unansweredP: false,
      // unansweredN: false
    });

  commit("dummySetter", "dummySet");
}

export async function markAsReadN({ commit }, { pId }) {
  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("pacientes")
    .doc(pId)
    .update({
      // unansweredP: false,
      unansweredN: false,
    });

  var unansweredArrayGot = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .get()
    .then(function(doc) {
      return doc.data().unansweredArray;
    });

  var unansweredArrayNew = [];

  if (unansweredArrayGot !== undefined) {
    unansweredArrayNew = unansweredArrayGot.filter(function(e) {
      return e !== pId;
    });
  }

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .update({
      unansweredArray: unansweredArrayNew,
    });

  commit("dummySetter", "dummySet");
}

export async function getAnyUnansweredN({ commit }) {
  var pacienteUnansweredList = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    // .collection("pacientes")
    .get()
    .then(function(doc) {
      return doc.data().unansweredArray;
    });

  var countList = [];

  if (
    pacienteUnansweredList !== undefined &&
    pacienteUnansweredList.length > 0
  ) {
    countList = pacienteUnansweredList.map(function(e) {
      if (e !== undefined && e) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  var sum = 0;
  countList.forEach(function(item) {
    sum += item;
  });

  var bool;
  if (sum > 0) {
    bool = true;
  } else {
    bool = false;
  }

  commit("setAnyUnansweredN", bool);
}

export async function sendTextP(
  { commit },
  { nutri, text, id, lastFalaHora, lastFalaMillis, lastFalaDateStr }
) {
  await firebase
    .firestore()
    .collection("users")
    .doc(nutri)
    .collection("pacientes")
    .doc(sessionStorage.getItem("current-user"))
    .collection("falas")
    .doc(id)
    .set({
      text: text,
      fromP: true,
      fromN: false,
      id: id,
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(nutri)
    .collection("pacientes")
    .doc(sessionStorage.getItem("current-user"))
    .update({
      lastFalaHora: lastFalaHora,
      lastFalaMillis: lastFalaMillis,
      lastFalaDateStr: lastFalaDateStr,
      unansweredP: false,
      unansweredN: true,
    });

  var newUnansweredArray = [sessionStorage.getItem("current-user")];
  var unansweredArrayGot = await firebase
    .firestore()
    .collection("users")
    .doc(nutri)
    .get()
    .then(function(doc) {
      return doc.data().unansweredArray;
    });

  if (unansweredArrayGot !== undefined) {
    if (unansweredArrayGot.length > 0) {
      var filteredArray = unansweredArrayGot.filter(function(a) {
        return a !== sessionStorage.getItem("current-user");
      });
      newUnansweredArray = filteredArray.push(
        sessionStorage.getItem("current-user")
      );
    }
  }

  await firebase
    .firestore()
    .collection("users")
    .doc(nutri)
    .update({
      unansweredArray: newUnansweredArray,
    });

  commit("dummySetter", "dummySet");
}

export function putOrcamentoEditar({ commit }, { orc }) {
  commit("setOrcamentoEditar", orc);
}

export async function nutriGetPacienteInfos({ commit }, { pacienteEmail }) {
  var pacienteInfosGot = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .get()
    .then(function(doc) {
      return doc.data().pacienteInfos;
    });

  if (pacienteInfosGot != undefined) {
    commit("setPacienteInfos", pacienteInfosGot);
  }
}

export async function getPacienteDados({ commit }, { pacienteEmail }) {
  var pacienteDadosGot = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .get()
    .then(function(doc) {
      return doc.data();
    });

  // var pacienteDadosObj = {
  //   nome: pacienteDadosGot.nome,
  //   sexo: pacienteDadosGot.sexo,
  //   gestante: pacienteDadosGot.gestante,
  //   dataNascimento: pacienteDadosGot.dataNascimento,
  //   email: pacienteDadosGot.email,
  //   telefone: pacienteDadosGot.telefone,
  //   whatsapp: pacienteDadosGot.whatsapp,
  //   convenio: pacienteDadosGot.convenio,
  //   endereco: pacienteDadosGot.endereco,
  //   bairro: pacienteDadosGot.bairro,
  //   cep: pacienteDadosGot.cep,
  //   cidade: pacienteDadosGot.cidade,
  //   objetivo: pacienteDadosGot.objetivo,
  //   cpf: pacienteDadosGot.cpf,
  //   imunidadeToFill: pacienteDadosGot.imunidadeToFill,
  //   preAtendimentoToFill: pacienteDadosGot.preAtendimentoToFill,
  //   imagemNao: pacienteDadosGot.imagemNao,
  //   pacienteInfos: {
  //     altura: pacienteDadosGot.pacienteInfos.altura
  //   }
  // }

  if (pacienteDadosGot != undefined) {
    commit("setPacienteDados", pacienteDadosGot);
  }
}

export function setProgramaInicioFim(
  { commit },
  { pacienteEmail, millisInicio, millisFim }
) {
  firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .update({
      programaFim: millisFim,
      programaInicio: millisInicio,
    });

  commit("dummySetter", "dummySet");
}

export async function updateAnotacoesAdmin(
  { commit },
  { anotacoes, pacienteEmail }
) {
  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .update({
      anotacoesAdmin: anotacoes,
    });

  commit("dummySetter", "dummySet");
}

export async function updatePacienteDados(
  { commit },
  {
    pacienteEmail,
    nutri,
    nome,
    sexo,
    gestante,
    dataNascimento,
    email,
    telefone,
    whatsapp,
    convenio,
    estado,
    endereco,
    bairro,
    cep,
    cidade,
    cpf,
    objetivo,
  }
) {
  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .update({
      nome: nome,
      sexo: sexo,
      gestante: gestante,
      dataNascimento: dataNascimento,
      email: email,
      telefone: telefone,
      whatsapp: whatsapp,
      convenio: convenio,
      estado: estado,
      endereco: endereco,
      bairro: bairro,
      cep: cep,
      cidade: cidade,
      cpf: cpf,
      objetivo: objetivo,
    });
  await firebase
    .firestore()
    .collection("users")
    .doc(nutri)
    .collection("pacientes")
    .doc(pacienteEmail)
    .update({
      nome: nome,
      sexo: sexo,
      gestante: gestante,
      dataNascimento: dataNascimento,
      email: email,
      telefone: telefone,
      whatsapp: whatsapp,
      convenio: convenio,
      estado: estado,
      endereco: endereco,
      bairro: bairro,
      cep: cep,
      cidade: cidade,
      cpf: cpf,
      objetivo: objetivo,
    });

  commit("dummySetter", "dummySet");
}

export async function nutriGetAnamnesesNr({ commit }, { pacienteEmail }) {
  var anamnesesNr = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .get()
    .then(function(doc) {
      return doc.data().anamneses;
    });

  if (anamnesesNr != undefined) {
    commit("setAnamnesesNr", anamnesesNr);
  }
}

export function nutriAnamneseSubmit(
  { commit },
  { pacienteEmail, content, index, dateStr, millis }
) {
  firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("anamneseNotes")
    .doc(millis.toString())
    .set({
      content: content,
      index: index,
      dateStr: dateStr,
      id: millis,
    });

  firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .update({
      anamneses: index,
    });

  commit("dummySetter", "dummySet");
}

export async function getAllAnamneseNotes({ commit }, { pacienteEmail }) {
  var notesGot = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("anamneseNotes")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  if (notesGot != undefined) {
    commit("setAllAnamneseNotes", notesGot);
  }
}

export async function pacienteGetPacienteInfos({ commit }) {
  var pacienteInfosGot = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .get()
    .then(function(doc) {
      return doc.data().pacienteInfos;
    });

  if (pacienteInfosGot != undefined) {
    commit("setPacienteInfos", pacienteInfosGot);
  }
}

export async function pacienteGetImunidadeInfos({ commit }) {
  var imunidadeInfosGot = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .get()
    .then(function(doc) {
      return doc.data().imunidadeInfos;
    });

  if (imunidadeInfosGot != undefined) {
    commit("setImunidadeInfos", imunidadeInfosGot);
  }
}

export async function nutriGetImunidadeInfos({ commit }, { pacienteEmail }) {
  var imunidadeInfosGot = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .get()
    .then(function(doc) {
      return doc.data().imunidadeInfos;
    });

  if (imunidadeInfosGot != undefined) {
    commit("setImunidadeInfos", imunidadeInfosGot);
  }
}

// export async function setImunidadeInfosVis({ commit }, {pacienteEmail, id}) {
//   var imunidadeInfosGot = await firebase.firestore()
//     .collection("users")
//     .doc(pacienteEmail)
//     .collection("testesImunidade")
//     .doc(id)
//     .get()
//     .then(function (doc) { return doc.data().imunidadeInfos });

//   if (imunidadeInfosGot != undefined) {
//     commit("setImunidadeInfos", imunidadeInfosGot);
//   }
// }

export async function pacienteGetImunidadeTestesDone({ commit }) {
  const imunidadeTestesDone = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("testesImunidade")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  commit("setImunidadeTestesDone", imunidadeTestesDone);
}

export async function nutriGetImunidadeTestesDone(
  { commit },
  { pacienteEmail }
) {
  const imunidadeTestesDone = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("testesImunidade")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );
  commit("setImunidadeTestesDone", imunidadeTestesDone);
}

export async function pacientePreAtendimento({ commit }, { id }) {
  commit("setPacientePreAtendimento", id);
  var nutriPdata = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("pacientes")
    .doc(id)
    .get()
    .then(function(doc) {
      return doc.data();
    });

  var data = await firebase
    .firestore()
    .collection("users")
    .doc(id)
    .get()
    .then(function(doc) {
      return doc.data();
    });

  var pacienteData = {
    nome: nutriPdata.nome,
    dataNascimento: nutriPdata.dataNascimento,
    data: data,
  };

  commit("setPacientePreAtendimentoData", pacienteData);
  if (id != null) {
    router.replace("/PreAtendimento");
  }
}

export function pacienteAtendimento({ commit }, { id }) {
  commit("setPacienteAtendimento", id);
  if (id !== "") {
    router.replace("/NovoAtendimento");
  }
}

export function pacienteVerHistorico({ commit }, { id }) {
  commit("setPacienteVerHistorico", id);
}

export async function pacientePreAtendimentoSubmit(
  { commit },
  { pacienteInfos }
) {
  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .update({
      preAtendimentoToFill: false,
      pacienteInfos: pacienteInfos,
    });

  commit("setPreAtendimentoToFill", false);
}

export async function pacienteEstetSubmit({ commit }, { pacienteInfos }) {
  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .update({
      estetToFill: false,
      pacienteInfos: pacienteInfos,
    });

  commit("setPreAtendimentoToFill", false);
}

export function pacienteImunidadeSubmit({ commit }, { imunidadeInfos, score }) {
  var d = new Date();
  var millis = d.getTime();
  var day = d.getDate();
  var month = d.getMonth() + 1;
  var year = d.getFullYear();

  var dayStr;
  if (day < 10) {
    dayStr = "0" + day.toString();
  } else {
    dayStr = day.toString();
  }

  var monthStr;
  if (month < 10) {
    monthStr = "0" + month.toString();
  } else {
    monthStr = month.toString();
  }

  var dateStr = dayStr + "/" + monthStr + "/" + year.toString();

  firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .update({
      imunidadeInfos: imunidadeInfos,
      imunidadeTesteDate: dateStr,
      imunidadeTestScore: score,
    });

  firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("testesImunidade")
    .doc(millis.toString())
    .set({
      id: millis.toString(),
      dateStr: dateStr,
      score: score,
      imunidadeInfos: imunidadeInfos,
    });

  commit("dummySetter", "dummySet");
}

export function nutriImunidadeSubmit(
  { commit },
  { pacienteEmail, imunidadeInfos, score }
) {
  var d = new Date();
  var millis = d.getTime();
  var day = d.getDate();
  var month = d.getMonth() + 1;
  var year = d.getFullYear();

  var dayStr;
  if (day < 10) {
    dayStr = "0" + day.toString();
  } else {
    dayStr = day.toString();
  }

  var monthStr;
  if (month < 10) {
    monthStr = "0" + month.toString();
  } else {
    monthStr = month.toString();
  }

  var dateStr = dayStr + "/" + monthStr + "/" + year.toString();

  firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .update({
      imunidadeInfos: imunidadeInfos,
      imunidadeTesteDate: dateStr,
      imunidadeTestScore: score,
    });

  firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("testesImunidade")
    .doc(millis.toString())
    .set({
      id: millis.toString(),
      dateStr: dateStr,
      score: score,
      imunidadeInfos: imunidadeInfos,
    });

  commit("dummySetter", "dummySet");
}

export function nutriImunidadeUpdate(
  { commit },
  { id, pacienteEmail, imunidadeInfos, score }
) {
  var d = new Date();
  var millis = d.getTime();
  var day = d.getDate();
  var month = d.getMonth() + 1;
  var year = d.getFullYear();

  var dayStr;
  if (day < 10) {
    dayStr = "0" + day.toString();
  } else {
    dayStr = day.toString();
  }

  var monthStr;
  if (month < 10) {
    monthStr = "0" + month.toString();
  } else {
    monthStr = month.toString();
  }

  var dateStr = dayStr + "/" + monthStr + "/" + year.toString();

  firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("testesImunidade")
    .doc(id)
    .update({
      id: millis.toString(),
      dateStr: "ediçao: " + dateStr,
      score: score,
      imunidadeInfos: imunidadeInfos,
    });

  commit("dummySetter", "dummySet");
}

export function persistPaciente({ commit }, { pId }) {
  commit("setPersistPaciente", pId);
}

export async function nutriPreAtendimentoSubmit(
  { commit },
  { pacienteInfos, pacienteEmail }
) {
  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("pacientes")
    .doc(pacienteEmail)
    .update({
      pacienteInfos: pacienteInfos,
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .update({
      preAtendimentoToFill: pacienteInfos.altura == "",
      pacienteInfos: pacienteInfos,
    });

  commit("setPreAtendimentoToFill", false);
}

export async function getAlimentos({ commit }) {
  console.log("getAlimentos");
  var alimentosList;
  if (
    sessionStorage.getItem("current-user") ==
    "admin@clubedanutricionista.com.br"
  ) {
    alimentosList = await firebase
      .firestore()
      .collection("alimentos")
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => {
          return {
            value: doc.id,
            data: doc.data(), //myObj,
            text: doc.data().nome,
          };
        })
      );
  } else {
    alimentosList = await firebase
      .firestore()
      .collection("users")
      .doc(sessionStorage.getItem("current-user"))
      .collection("alimentos")
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => {
          return {
            value: doc.id,
            data: doc.data(), //myObj,
            text: doc.data().nome,
          };
        })
      );
  }

  alimentosList = alimentosList.map(function(a) {
    var cleanData = {
      acido_aspartico: isNaN(parseFloat(a.data.acido_aspartico))
        ? 0
        : a.data.acido_aspartico,
      acido_folico: isNaN(parseFloat(a.data.acido_folico))
        ? 0
        : a.data.acido_folico,
      acido_glutamico: isNaN(parseFloat(a.data.acido_glutamico))
        ? 0
        : a.data.acido_glutamico,
      acido_pantotenico: isNaN(parseFloat(a.data.acido_pantotenico))
        ? 0
        : a.data.acido_pantotenico,
      alanina: isNaN(parseFloat(a.data.alanina)) ? 0 : a.data.alanina,
      arginina: isNaN(parseFloat(a.data.arginina)) ? 0 : a.data.arginina,
      calcio: isNaN(parseFloat(a.data.calcio)) ? 0 : a.data.calcio,
      carboidrato: isNaN(parseFloat(a.data.carboidrato))
        ? 0
        : a.data.carboidrato,
      cinzas: isNaN(parseFloat(a.data.cinzas)) ? 0 : a.data.cinzas,
      cistina: isNaN(parseFloat(a.data.cistina)) ? 0 : a.data.cistina,
      cobre: isNaN(parseFloat(a.data.cobre)) ? 0 : a.data.cobre,
      codigo: a.data.codigo !== undefined ? a.data.codigo : "",
      colesterol: isNaN(parseFloat(a.data.colesterol)) ? 0 : a.data.colesterol,
      created_at: a.data.created_at !== undefined ? a.data.created_at : "",
      era: isNaN(parseFloat(a.data.era)) ? 0 : a.data.era,
      fenilalanina: isNaN(parseFloat(a.data.fenilalanina))
        ? 0
        : a.data.fenilalanina,
      ferro: isNaN(parseFloat(a.data.ferro)) ? 0 : a.data.ferro,
      fibra_alimentar: isNaN(parseFloat(a.data.fibra_alimentar))
        ? 0
        : a.data.fibra_alimentar,
      fibra_insoluvel: isNaN(parseFloat(a.data.fibra_insoluvel))
        ? 0
        : a.data.fibra_insoluvel,
      fibra_soluvel: isNaN(parseFloat(a.data.fibra_soluvel))
        ? 0
        : a.data.fibra_soluvel,
      fonte: a.data.fonte !== undefined ? a.data.fonte : "",
      fosforo: isNaN(parseFloat(a.data.fosforo)) ? 0 : a.data.fosforo,
      glicina: isNaN(parseFloat(a.data.glicina)) ? 0 : a.data.glicina,
      gordura_trans: isNaN(parseFloat(a.data.gordura_trans))
        ? 0
        : a.data.gordura_trans,
      gr_padrao: isNaN(parseFloat(a.data.gr_padrao)) ? 0 : a.data.gr_padrao,
      grupo_1: a.data.grupo_1 !== undefined ? a.data.grupo_1 : "",
      grupo_2: a.data.grupo_2 !== undefined ? a.data.grupo_2 : "",
      histidina: isNaN(parseFloat(a.data.histidina)) ? 0 : a.data.histidina,
      id: a.data.id !== undefined ? a.data.id : "",
      idioma: a.data.idioma !== undefined ? a.data.idioma : "",
      iodo: isNaN(parseFloat(a.data.iodo)) ? 0 : a.data.iodo,
      isoleucina: isNaN(parseFloat(a.data.isoleucina)) ? 0 : a.data.isoleucina,
      kcal: isNaN(parseFloat(a.data.kcal)) ? 0 : a.data.kcal,
      kj: isNaN(parseFloat(a.data.kj)) ? 0 : a.data.kj,
      leucina: isNaN(parseFloat(a.data.leucina)) ? 0 : a.data.leucina,
      lipideos: isNaN(parseFloat(a.data.lipideos)) ? 0 : a.data.lipideos,
      lisina: isNaN(parseFloat(a.data.lisina)) ? 0 : a.data.lisina,
      magnesio: isNaN(parseFloat(a.data.magnesio)) ? 0 : a.data.magnesio,
      manganes: isNaN(parseFloat(a.data.manganes)) ? 0 : a.data.manganes,
      medida_caseira_padrao:
        a.data.medida_caseira_padrao !== undefined
          ? a.data.medida_caseira_padrao
          : "",
      metionina: isNaN(parseFloat(a.data.metionina)) ? 0 : a.data.metionina,
      monoinsaturados: isNaN(parseFloat(a.data.monoinsaturados))
        ? 0
        : a.data.monoinsaturados,
      niacina: isNaN(parseFloat(a.data.niacina)) ? 0 : a.data.niacina,
      nome: a.data.nome !== undefined ? a.data.nome : "",
      nutricionista:
        a.data.nutricionista !== undefined ? a.data.nutricionista : "",
      piridoxina: isNaN(parseFloat(a.data.piridoxina)) ? 0 : a.data.piridoxina,
      poliinsaturados: isNaN(parseFloat(a.data.poliinsaturados))
        ? 0
        : a.data.poliinsaturados,
      potassio: isNaN(parseFloat(a.data.potassio)) ? 0 : a.data.potassio,
      prolina: isNaN(parseFloat(a.data.prolina)) ? 0 : a.data.prolina,
      proteina: isNaN(parseFloat(a.data.proteina)) ? 0 : a.data.proteina,
      re: isNaN(parseFloat(a.data.re)) ? 0 : a.data.re,
      retinol: isNaN(parseFloat(a.data.retinol)) ? 0 : a.data.retinol,
      riboflavina: isNaN(parseFloat(a.data.riboflavina))
        ? 0
        : a.data.riboflavina,
      saturados: isNaN(parseFloat(a.data.saturados)) ? 0 : a.data.saturados,
      selenio: isNaN(parseFloat(a.data.selenio)) ? 0 : a.data.selenio,
      serina: isNaN(parseFloat(a.data.serina)) ? 0 : a.data.serina,
      sodio: isNaN(parseFloat(a.data.sodio)) ? 0 : a.data.sodio,
      tiamina: isNaN(parseFloat(a.data.tiamina)) ? 0 : a.data.tiamina,
      tirosina: isNaN(parseFloat(a.data.tirosina)) ? 0 : a.data.tirosina,
      treonina: isNaN(parseFloat(a.data.treonina)) ? 0 : a.data.treonina,
      triptofano: isNaN(parseFloat(a.data.triptofano)) ? 0 : a.data.triptofano,
      umidade: isNaN(parseFloat(a.data.umidade)) ? 0 : a.data.umidade,
      updated_at: a.data.updated_at !== undefined ? a.data.updated_at : "",
      valina: isNaN(parseFloat(a.data.valina)) ? 0 : a.data.valina,
      vitamina_b12: isNaN(parseFloat(a.data.vitamina_b12))
        ? 0
        : a.data.vitamina_b12,
      vitamina_c: isNaN(parseFloat(a.data.vitamina_c)) ? 0 : a.data.vitamina_c,
      vitamina_d: isNaN(parseFloat(a.data.vitamina_d)) ? 0 : a.data.vitamina_d,
      vitamina_e: isNaN(parseFloat(a.data.vitamina_e)) ? 0 : a.data.vitamina_e,
      zinco: isNaN(parseFloat(a.data.zinco)) ? 0 : a.data.zinco,
    };
    a.data = cleanData;
    return a;
  });

  commit("setAlimentos", alimentosList);
}

export async function getClubeDeVantagens({ commit }) {
  const clubeDeVantagensList = await firebase
    .firestore()
    // .collection("users")
    // .doc(nutri)
    .collection("clubeDeVantagens")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );
  commit("setClubeDeVantagens", clubeDeVantagensList);
}

export async function getFormulas({ commit }) {
  const formulasList = await firebase
    .firestore()
    .collection("formulas")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return {
          value: doc.id,
          data: doc.data(),
          text: doc.data().titulo,
        };
      })
    );

  // const pessoalFormulasList = await firebase
  //   .firestore()
  //   .collection("users")
  //   .doc(sessionStorage.getItem("current-user"))
  //   .collection("formulas")
  //   .get()
  //   .then((snapshot) =>
  //     snapshot.docs.map((doc) => {
  //       return {
  //         value: doc.id,
  //         data: doc.data(),
  //         text: doc.data().titulo,
  //       };
  //     })
  //   );

  commit("setFormulas", formulasList /*.concat(pessoalFormulasList)*/);
}

export async function getExamList({ commit }) {
  var examList;
  var splitName = sessionStorage.getItem("current-user").split("@");
  if (splitName[1] == "clubedanutricionista.com.br") {
    examList = await firebase
      .firestore()
      .collection("examList")
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => {
          return {
            value: doc.id,
            data: doc.data(),
            text: doc.data().nome,
          };
        })
      );
  } else {
    examList = await firebase
      .firestore()
      .collection("users")
      .doc(sessionStorage.getItem("current-user"))
      .collection("examList")
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => {
          return {
            value: doc.id,
            data: doc.data(),
            text: doc.data().nome,
          };
        })
      );
  }

  commit("setExamList", examList);
}

export async function getOrientacaoList({ commit }) {
  var orientacaoList;
  var splitName = sessionStorage.getItem("current-user").split("@");
  if (splitName[1] == "clubedanutricionista.com.br") {
    orientacaoList = await firebase
      .firestore()
      .collection("orientacaoList")
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => {
          return {
            value: doc.id,
            data: doc.data(),
            text: doc.data().nome,
          };
        })
      );
  } else {
    orientacaoList = await firebase
      .firestore()
      .collection("users")
      .doc(sessionStorage.getItem("current-user"))
      .collection("orientacaoList")
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => {
          return {
            value: doc.id,
            data: doc.data(),
            text: doc.data().nome,
          };
        })
      );
  }

  commit("setOrientacaoList", orientacaoList);
}

export async function getReceitas({ commit }) {
  var receitasList = await firebase
    .firestore()
    .collection("receitas")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return {
          value: doc.id,
          data: doc.data(),
          text: doc.data().titulo,
        };
      })
    );

  receitasList = receitasList.map(function(a) {
    var cleanData = {
      CHO: isNaN(parseFloat(a.data.CHO)) ? 0 : a.data.CHO,
      COL: isNaN(parseFloat(a.data.COL)) ? 0 : a.data.COL,
      FIB: isNaN(parseFloat(a.data.FIB)) ? 0 : a.data.FIB,
      KCAL: isNaN(parseFloat(a.data.KCAL)) ? 0 : a.data.KCAL,
      LIP: isNaN(parseFloat(a.data.LIP)) ? 0 : a.data.LIP,
      PTN: isNaN(parseFloat(a.data.PTN)) ? 0 : a.data.PTN,
      SOD: isNaN(parseFloat(a.data.SOD)) ? 0 : a.data.SOD,
      acido_aspartico: isNaN(parseFloat(a.data.acido_aspartico))
        ? 0
        : a.data.acido_aspartico,
      acido_folico: isNaN(parseFloat(a.data.acido_folico))
        ? 0
        : a.data.acido_folico,
      acido_glutamico: isNaN(parseFloat(a.data.acido_glutamico))
        ? 0
        : a.data.acido_glutamico,
      acido_pantotenico: isNaN(parseFloat(a.data.acido_pantotenico))
        ? 0
        : a.data.acido_pantotenico,
      alimentosList: a.data.alimentosList,
      alanina: isNaN(parseFloat(a.data.alanina)) ? 0 : a.data.alanina,
      arginina: isNaN(parseFloat(a.data.arginina)) ? 0 : a.data.arginina,
      autor: a.data.autor !== undefined ? a.data.autor : "",
      calcio: isNaN(parseFloat(a.data.calcio)) ? 0 : a.data.calcio,
      cinzas: isNaN(parseFloat(a.data.cinzas)) ? 0 : a.data.cinzas,
      cistina: isNaN(parseFloat(a.data.cistina)) ? 0 : a.data.cistina,
      cobre: isNaN(parseFloat(a.data.cobre)) ? 0 : a.data.cobre,
      era: isNaN(parseFloat(a.data.era)) ? 0 : a.data.era,
      fenilalanina: isNaN(parseFloat(a.data.fenilalanina))
        ? 0
        : a.data.fenilalanina,
      ferro: isNaN(parseFloat(a.data.ferro)) ? 0 : a.data.ferro,
      fibra_insoluvel: isNaN(parseFloat(a.data.fibra_insoluvel))
        ? 0
        : a.data.fibra_insoluvel,
      fibra_soluvel: isNaN(parseFloat(a.data.fibra_soluvel))
        ? 0
        : a.data.fibra_soluvel,
      fosforo: isNaN(parseFloat(a.data.fosforo)) ? 0 : a.data.fosforo,
      glicina: isNaN(parseFloat(a.data.glicina)) ? 0 : a.data.glicina,
      gordura_trans: isNaN(parseFloat(a.data.gordura_trans))
        ? 0
        : a.data.gordura_trans,
      histidina: isNaN(parseFloat(a.data.histidina)) ? 0 : a.data.histidina,
      iodo: isNaN(parseFloat(a.data.iodo)) ? 0 : a.data.iodo,
      isoleucina: isNaN(parseFloat(a.data.isoleucina)) ? 0 : a.data.isoleucina,
      leucina: isNaN(parseFloat(a.data.leucina)) ? 0 : a.data.leucina,
      lisina: isNaN(parseFloat(a.data.lisina)) ? 0 : a.data.lisina,
      magnesio: isNaN(parseFloat(a.data.magnesio)) ? 0 : a.data.magnesio,
      manganes: isNaN(parseFloat(a.data.manganes)) ? 0 : a.data.manganes,
      metionina: isNaN(parseFloat(a.data.metionina)) ? 0 : a.data.metionina,
      modoPreparo: a.data.modoPreparo !== undefined ? a.data.modoPreparo : "",
      monoinsaturados: isNaN(parseFloat(a.data.monoinsaturados))
        ? 0
        : a.data.monoinsaturados,
      niacina: isNaN(parseFloat(a.data.niacina)) ? 0 : a.data.niacina,
      observacao: a.data.observacao !== undefined ? a.data.observacao : "",
      piridoxina: isNaN(parseFloat(a.data.piridoxina)) ? 0 : a.data.piridoxina,
      poliinsaturados: isNaN(parseFloat(a.data.poliinsaturados))
        ? 0
        : a.data.poliinsaturados,
      potassio: isNaN(parseFloat(a.data.potassio)) ? 0 : a.data.potassio,
      prolina: isNaN(parseFloat(a.data.prolina)) ? 0 : a.data.prolina,
      re: isNaN(parseFloat(a.data.re)) ? 0 : a.data.re,
      rendimento: isNaN(parseFloat(a.data.rendimento)) ? 0 : a.data.rendimento,
      retinol: isNaN(parseFloat(a.data.retinol)) ? 0 : a.data.retinol,
      riboflavina: isNaN(parseFloat(a.data.riboflavina))
        ? 0
        : a.data.riboflavina,
      saturados: isNaN(parseFloat(a.data.saturados)) ? 0 : a.data.saturados,
      selenio: isNaN(parseFloat(a.data.selenio)) ? 0 : a.data.selenio,
      serina: isNaN(parseFloat(a.data.serina)) ? 0 : a.data.serina,
      sodio: isNaN(parseFloat(a.data.sodio)) ? 0 : a.data.sodio,
      tempoPreparo:
        a.data.tempoPreparo !== undefined ? a.data.tempoPreparo : "",
      tiamina: isNaN(parseFloat(a.data.tiamina)) ? 0 : a.data.tiamina,
      tirosina: isNaN(parseFloat(a.data.tirosina)) ? 0 : a.data.tirosina,
      titulo: a.data.titulo !== undefined ? a.data.titulo : "",
      treonina: isNaN(parseFloat(a.data.treonina)) ? 0 : a.data.treonina,
      triptofano: isNaN(parseFloat(a.data.triptofano)) ? 0 : a.data.triptofano,
      umidade: isNaN(parseFloat(a.data.umidade)) ? 0 : a.data.umidade,
      valina: isNaN(parseFloat(a.data.valina)) ? 0 : a.data.valina,
      vitamina_b12: isNaN(parseFloat(a.data.vitamina_b12))
        ? 0
        : a.data.vitamina_b12,
      vitamina_c: isNaN(parseFloat(a.data.vitamina_c)) ? 0 : a.data.vitamina_c,
      vitamina_d: isNaN(parseFloat(a.data.vitamina_d)) ? 0 : a.data.vitamina_d,
      vitamina_e: isNaN(parseFloat(a.data.vitamina_e)) ? 0 : a.data.vitamina_e,
      zinco: isNaN(parseFloat(a.data.zinco)) ? 0 : a.data.zinco,
    };
    a.data = cleanData;
    return a;
  });

  var pessoalReceitasList = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("receitas")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return {
          value: doc.id,
          data: doc.data(),
          text: doc.data().titulo,
        };
      })
    );

  if (pessoalReceitasList !== undefined) {
    pessoalReceitasList = pessoalReceitasList.map(function(a) {
      var cleanData = {
        CHO: isNaN(parseFloat(a.data.CHO)) ? 0 : a.data.CHO,
        COL: isNaN(parseFloat(a.data.COL)) ? 0 : a.data.COL,
        FIB: isNaN(parseFloat(a.data.FIB)) ? 0 : a.data.FIB,
        KCAL: isNaN(parseFloat(a.data.KCAL)) ? 0 : a.data.KCAL,
        LIP: isNaN(parseFloat(a.data.LIP)) ? 0 : a.data.LIP,
        PTN: isNaN(parseFloat(a.data.PTN)) ? 0 : a.data.PTN,
        SOD: isNaN(parseFloat(a.data.SOD)) ? 0 : a.data.SOD,
        acido_aspartico: isNaN(parseFloat(a.data.acido_aspartico))
          ? 0
          : a.data.acido_aspartico,
        acido_folico: isNaN(parseFloat(a.data.acido_folico))
          ? 0
          : a.data.acido_folico,
        acido_glutamico: isNaN(parseFloat(a.data.acido_glutamico))
          ? 0
          : a.data.acido_glutamico,
        acido_pantotenico: isNaN(parseFloat(a.data.acido_pantotenico))
          ? 0
          : a.data.acido_pantotenico,
        alanina: isNaN(parseFloat(a.data.alanina)) ? 0 : a.data.alanina,
        arginina: isNaN(parseFloat(a.data.arginina)) ? 0 : a.data.arginina,
        alimentosList: a.data.alimentosList,
        autor: a.data.autor !== undefined ? a.data.autor : "",
        calcio: isNaN(parseFloat(a.data.calcio)) ? 0 : a.data.calcio,
        cinzas: isNaN(parseFloat(a.data.cinzas)) ? 0 : a.data.cinzas,
        cistina: isNaN(parseFloat(a.data.cistina)) ? 0 : a.data.cistina,
        cobre: isNaN(parseFloat(a.data.cobre)) ? 0 : a.data.cobre,
        era: isNaN(parseFloat(a.data.era)) ? 0 : a.data.era,
        fenilalanina: isNaN(parseFloat(a.data.fenilalanina))
          ? 0
          : a.data.fenilalanina,
        ferro: isNaN(parseFloat(a.data.ferro)) ? 0 : a.data.ferro,
        fibra_insoluvel: isNaN(parseFloat(a.data.fibra_insoluvel))
          ? 0
          : a.data.fibra_insoluvel,
        fibra_soluvel: isNaN(parseFloat(a.data.fibra_soluvel))
          ? 0
          : a.data.fibra_soluvel,
        fosforo: isNaN(parseFloat(a.data.fosforo)) ? 0 : a.data.fosforo,
        glicina: isNaN(parseFloat(a.data.glicina)) ? 0 : a.data.glicina,
        gordura_trans: isNaN(parseFloat(a.data.gordura_trans))
          ? 0
          : a.data.gordura_trans,
        histidina: isNaN(parseFloat(a.data.histidina)) ? 0 : a.data.histidina,
        iodo: isNaN(parseFloat(a.data.iodo)) ? 0 : a.data.iodo,
        isoleucina: isNaN(parseFloat(a.data.isoleucina))
          ? 0
          : a.data.isoleucina,
        leucina: isNaN(parseFloat(a.data.leucina)) ? 0 : a.data.leucina,
        lisina: isNaN(parseFloat(a.data.lisina)) ? 0 : a.data.lisina,
        magnesio: isNaN(parseFloat(a.data.magnesio)) ? 0 : a.data.magnesio,
        manganes: isNaN(parseFloat(a.data.manganes)) ? 0 : a.data.manganes,
        metionina: isNaN(parseFloat(a.data.metionina)) ? 0 : a.data.metionina,
        modoPreparo: a.data.modoPreparo !== undefined ? a.data.modoPreparo : "",
        monoinsaturados: isNaN(parseFloat(a.data.monoinsaturados))
          ? 0
          : a.data.monoinsaturados,
        niacina: isNaN(parseFloat(a.data.niacina)) ? 0 : a.data.niacina,
        observacao: a.data.observacao !== undefined ? a.data.observacao : "",
        piridoxina: isNaN(parseFloat(a.data.piridoxina))
          ? 0
          : a.data.piridoxina,
        poliinsaturados: isNaN(parseFloat(a.data.poliinsaturados))
          ? 0
          : a.data.poliinsaturados,
        potassio: isNaN(parseFloat(a.data.potassio)) ? 0 : a.data.potassio,
        prolina: isNaN(parseFloat(a.data.prolina)) ? 0 : a.data.prolina,
        re: isNaN(parseFloat(a.data.re)) ? 0 : a.data.re,
        rendimento: isNaN(parseFloat(a.data.rendimento))
          ? 0
          : a.data.rendimento,
        retinol: isNaN(parseFloat(a.data.retinol)) ? 0 : a.data.retinol,
        riboflavina: isNaN(parseFloat(a.data.riboflavina))
          ? 0
          : a.data.riboflavina,
        saturados: isNaN(parseFloat(a.data.saturados)) ? 0 : a.data.saturados,
        selenio: isNaN(parseFloat(a.data.selenio)) ? 0 : a.data.selenio,
        serina: isNaN(parseFloat(a.data.serina)) ? 0 : a.data.serina,
        sodio: isNaN(parseFloat(a.data.sodio)) ? 0 : a.data.sodio,
        tempoPreparo:
          a.data.tempoPreparo !== undefined ? a.data.tempoPreparo : "",
        tiamina: isNaN(parseFloat(a.data.tiamina)) ? 0 : a.data.tiamina,
        tirosina: isNaN(parseFloat(a.data.tirosina)) ? 0 : a.data.tirosina,
        titulo: a.data.titulo !== undefined ? a.data.titulo : "",
        treonina: isNaN(parseFloat(a.data.treonina)) ? 0 : a.data.treonina,
        triptofano: isNaN(parseFloat(a.data.triptofano))
          ? 0
          : a.data.triptofano,
        umidade: isNaN(parseFloat(a.data.umidade)) ? 0 : a.data.umidade,
        valina: isNaN(parseFloat(a.data.valina)) ? 0 : a.data.valina,
        vitamina_b12: isNaN(parseFloat(a.data.vitamina_b12))
          ? 0
          : a.data.vitamina_b12,
        vitamina_c: isNaN(parseFloat(a.data.vitamina_c))
          ? 0
          : a.data.vitamina_c,
        vitamina_d: isNaN(parseFloat(a.data.vitamina_d))
          ? 0
          : a.data.vitamina_d,
        vitamina_e: isNaN(parseFloat(a.data.vitamina_e))
          ? 0
          : a.data.vitamina_e,
        zinco: isNaN(parseFloat(a.data.zinco)) ? 0 : a.data.zinco,
      };
      a.data = cleanData;
      return a;
    });
  }

  commit("setReceitas", receitasList.concat(pessoalReceitasList));
}

export function makeReceitaVis({ commit }, { rec }) {
  commit("setReceitaVis", rec);
}

export function makeFormulaVis({ commit }, { formu }) {
  commit("setFormulaVis", formu);
}

export function examPUpdate({ commit }, { examP }) {
  commit("setExamP", examP);
}

export function orientacaoPUpdate({ commit }, { orientacaoP }) {
  commit("setOrientacaoP", orientacaoP);
}

export async function getDietasSalvas({ commit }) {
  const dietasSalvas = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietasSalvasRefs")
    .limit(50)
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
        // {
        //   value: doc.id,
        //   data: doc.data(),
        //   text: doc.data().titulo,
        // };
      })
    );
  commit("setDietasSalvas", dietasSalvas);
}

export async function getDietasSalvasAll({ commit }) {
  const dietasSalvas = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietasSalvasRefs")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
        // {
        //   value: doc.id,
        //   data: doc.data(),
        //   text: doc.data().titulo,
        // };
      })
    );
  commit("setDietasSalvas", dietasSalvas);
}

// export async function getDietasPaciente({ commit }) {
//   const dietas = await firebase
//     .firestore()
//     .collection("users")
//     .doc(sessionStorage.getItem("current-user"))
//     .collection("dietas")
//     .get()
//     .then((snapshot) =>
//       snapshot.docs.map((doc) => {
//         return doc.data()
//         // {
//         //   value: doc.id,
//         //   data: doc.data(),
//         //   text: doc.data().titulo,
//         // };
//       })
//     );
//   commit("setDietasPaciente", dietas);
// }

export async function getPacientes({ commit }) {
  const pacientesList = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    // .doc(firebase.auth().currentUser.email)
    .collection("pacientes")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return {
          value: doc.id,
          data: doc.data(),
          text: doc.data().nome,
        };
      })
    );
  commit("setPacientes", pacientesList);
}

// dietas

export function dietaStatus({ commit }, { refeicoes }) {
  var demaisCodigos = [
    "fibra_soluvel",
    "fibra_insoluvel",
    "cinzas",
    "calcio",
    "magnesio",
    "manganes",
    "fosforo",
    "ferro",
    "sodio",
    "potassio",
    "cobre",
    "zinco",
    "retinol",
    "re",
    "era",
    "iodo",
    "selenio",
    "acido_folico",
    "acido_pantotenico",
    "tiamina",
    "riboflavina",
    "piridoxina",
    "niacina",
    "vitamina_c",
    "vitamina_d",
    "vitamina_e",
    "vitamina_b12",
    "saturados",
    "monoinsaturados",
    "poliinsaturados",
    "gordura_trans",
    "triptofano",
    "treonina",
    "isoleucina",
    "leucina",
    "lisina",
    "metionina",
    "cistina",
    "fenilalanina",
    "tirosina",
    "valina",
    "arginina",
    "histidina",
    "alanina",
    "acido_aspartico",
    "acido_glutamico",
    "glicina",
    "prolina",
    "serina",
    "umidade",
  ];
  var status = {
    KCAL: 0,
    CHO: 0,
    PTN: 0,
    LIP: 0,
    COL: 0,
    SOD: 0,
    FIB: 0,
    fibra_soluvel: 0,
    fibra_insoluvel: 0,
    cinzas: 0,
    calcio: 0,
    magnesio: 0,
    manganes: 0,
    fosforo: 0,
    ferro: 0,
    potassio: 0,
    cobre: 0,
    zinco: 0,
    retinol: 0,
    re: 0,
    era: 0,
    iodo: 0,
    selenio: 0,
    acido_folico: 0,
    acido_pantotenico: 0,
    tiamina: 0,
    riboflavina: 0,
    piridoxina: 0,
    niacina: 0,
    vitamina_c: 0,
    vitamina_d: 0,
    vitamina_e: 0,
    vitamina_b12: 0,
    saturados: 0,
    monoinsaturados: 0,
    poliinsaturados: 0,
    gordura_trans: 0,
    triptofano: 0,
    treonina: 0,
    isoleucina: 0,
    leucina: 0,
    lisina: 0,
    metionina: 0,
    cistina: 0,
    fenilalanina: 0,
    tirosina: 0,
    valina: 0,
    arginina: 0,
    histidina: 0,
    alanina: 0,
    acido_aspartico: 0,
    acido_glutamico: 0,
    glicina: 0,
    prolina: 0,
    serina: 0,
    umidade: 0,
  };
  refeicoes.forEach((item) => {
    status.KCAL += item.KCAL;
    status.CHO += item.CHO;
    status.PTN += item.PTN;
    status.LIP += item.LIP;
    status.FIB += item.FIB;
    status.COL += item.COL;
    status.SOD += item.SOD;
    for (var n = 0; n < demaisCodigos.length; n++) {
      status[demaisCodigos[n]] += item[demaisCodigos[n]];
    }
  });
  commit("setDietaStatus", status);
  commit("setDietaRefeicoes", refeicoes);
}

export function receitaUpdate({ commit }, { receita }) {
  commit("setReceita", receita);
}

export function prescricaoCUpdate({ commit }, { prescricaoC }) {
  commit("setPrescricaoC", prescricaoC);
}

export function receitaDelete({ commit }, { receita }) {
  firebase
    .firestore()
    // .collection("users")
    // .doc(sessionStorage.getItem("current-user"))
    .collection("receitas")
    .doc(receita.toString())
    .delete()
    .then(function() {
      console.log("Document " + receita + "successfully deleted!");
    })
    .catch(function(error) {
      console.error("Error removing document: " + receita, error);
    });
  commit("dummySetter", "dummySet");
}

export function formulaDelete({ commit }, { formula }) {
  firebase
    .firestore()
    // .collection("users")
    // .doc(sessionStorage.getItem("current-user"))
    .collection("formulas")
    .doc(formula.toString())
    .delete()
    .then(function() {
      console.log("Document " + formula + "successfully deleted!");
    })
    .catch(function(error) {
      console.error("Error removing document: " + formula, error);
    });
  commit("dummySetter", "dummySet");
}

export async function alimentoDelete({ commit }, { alimentoId }) {
  if (
    sessionStorage.getItem("current-user") ==
    "admin@clubedanutricionista.com.br"
  ) {
    await firebase
      .firestore()
      // .collection("users")
      // .doc(sessionStorage.getItem("current-user"))
      .collection("alimentos")
      .doc(alimentoId.toString())
      .delete()
      .then(function() {
        console.log("Document " + alimentoId + "successfully deleted!");
      })
      .catch(function(error) {
        console.error("Error removing document: " + alimentoId, error);
      });
  } else {
    await firebase
      .firestore()
      .collection("users")
      .doc(sessionStorage.getItem("current-user"))
      .collection("alimentos")
      .doc(alimentoId.toString())
      .delete()
      .then(function() {
        console.log("Document " + alimentoId + "successfully deleted!");
      })
      .catch(function(error) {
        console.error("Error removing document: " + alimentoId, error);
      });
  }
  commit("dummySetter", "dummySet");
}

export function formulaUpdate({ commit }, { formula }) {
  commit("setFormula", formula);
}

export function visEvent({ commit }, { event }) {
  commit("setVisEvent", event);
}

export function receitaId({ commit }, { receitaId }) {
  commit("setReceitaId", receitaId);
}

export function formulaId({ commit }, { id }) {
  commit("setFormulaId", id);
}

export async function salvarAtendimento(
  { commit },
  {
    paciente,
    compos,
    medidas,
    fotoFrenteUrl,
    fotoAtrasUrl,
    fotoEsquerdaUrl,
    fotoDireitaUrl,
    bioimp,
    exam1,
    exam2,
    exam3,
    exam1Nome,
    exam2Nome,
    exam3Nome,
    sangue,
    anotacoes,
  }
) {
  var date = new Date();

  var day;
  var month;
  var mes = date.getMonth() + 1;
  var dia = date.getDate();
  if (dia < 10) {
    day = "0" + dia.toString();
  } else {
    day = dia.toString();
  }
  if (mes < 10) {
    month = "0" + mes.toString();
  } else {
    month = mes.toString();
  }
  // var data = `${day}/${month}/${date.getFullYear().toString()}`
  var data = day + "/" + month + "/" + date.getFullYear().toString();

  var milliseconds = /*2524608000000 - */ date.getTime();

  console.log(milliseconds);

  await firebase
    .firestore()
    .collection("users")
    // .doc(sessionStorage.getItem("current-user"))
    // .collection("pacientes")
    .doc(paciente.toString())
    .collection("atendimentos")
    .doc(milliseconds.toString())
    .set({
      data: milliseconds,
      compos: compos,
      medidas: medidas,
      fotoFrenteUrl: fotoFrenteUrl,
      fotoAtrasUrl: fotoAtrasUrl,
      fotoEsquerdaUrl: fotoEsquerdaUrl,
      fotoDireitaUrl: fotoDireitaUrl,
      bioimp: bioimp,
      exam1: exam1,
      exam2: exam2,
      exam3: exam3,
      exam1Nome: exam1Nome,
      exam2Nome: exam2Nome,
      exam3Nome: exam3Nome,
      sangue: sangue,
      anotacoes: anotacoes,
    });

  await firebase
    .firestore()
    .collection("users")
    // .doc(sessionStorage.getItem("current-user"))
    // .collection("pacientes")
    .doc(paciente)
    .collection("timeline")
    .doc(milliseconds.toString())
    .set({
      docId: milliseconds.toString(),
      colId: "atendimentos",
      nome: "avaliação",
      type: "atendimento",
      icon: "mdi-finance",
      date: data,
      to: "/PacienteEvolucao",
      text: "Uma nova avaliação foi feita!",
      toText: "Todas as avaliações",
      color: "#614021",
      download: false,
    });

  commit("dummySetter", "dummySet");
}

export function showAlimento({ commit }, { alimento }) {
  console.log(alimento);
  commit("setAlimentoEdit", alimento);
}

export async function salvarDietaSalvas(
  { commit },
  {
    titulo,
    status,
    autorEmail,
    autorPapel,
    autorCrn,
    autorNome,
    autorAssinatura,
    dietaRefeicoes /*copy1Refeicoes, copy2Refeicoes, copy3Refeicoes, copy4Refeicoes*/,
  }
) {
  // var reduceObj = function (alimento) {
  //   return {
  //           nome: alimento.nome,
  //           quantidade: alimento.quantidade,
  //           gramas: alimento.gramas,
  //           medida: alimento.medida,
  //         };
  // }
  // var setNull = function (obj, element) {
  //   var newObj = obj;
  //   delete newObj[element];
  //   return newObj;
  // }
  // var dietaDocId = 0;
  // firebase.firestore()
  //   .collection("users")
  //   .doc(sessionStorage.getItem("current-user"))
  //   .collection("dietasSalvas")
  //   .get()
  //   .then(async function (snapshot) {
  //     if (await snapshot.exists) {
  //       console.log(snapshot.size);
  //       dietaDocId = snapshot.size;
  //     } else {
  //       dietaDocId = 0;
  //     }
  //   });

  // console.log("is zero:", dietaDocId)
  var date = new Date();
  var milliseconds = 2524608000000 - date.getTime();

  console.log(sessionStorage.getItem("current-user"));

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietasSalvasRefs")
    .doc(milliseconds.toString())
    .set({
      // .add({
      dietaDocId: milliseconds,
      titulo: titulo,
      kcal: status.KCAL,
      autorEmail: autorEmail,
      autorPapel: autorPapel,
      autorCrn: autorCrn,
      autorNome: autorNome,
      autorAssinatura: autorAssinatura,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      // refeicoes: dietaRefeicoes
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietasSalvas")
    .doc(milliseconds.toString())
    .set({
      // .add({
      dietaDocId: milliseconds,
      titulo: titulo,
      status: status,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      refeicoes: dietaRefeicoes,
      autorEmail: autorEmail,
      autorPapel: autorPapel,
      autorCrn: autorCrn,
      autorNome: autorNome,
      autorAssinatura: autorAssinatura,
    });

  // for (var refIndex=0; refIndex<dietaRefeicoes.length; refIndex++) {
  //   firebase.firestore()
  //     .collection("users")
  //     .doc(sessionStorage.getItem("current-user"))
  //     .collection("dietasSalvas")
  //     .doc(dietaDocId.toString())
  //     .collection("refeicoes")
  //     .doc(refIndex.toString())
  //     .set(/*setNull(*/dietaRefeicoes[refIndex]/*, "alimentosList")*/);

  //   // if (dietaRefeicoes[refIndex].alimentosList.length > 0) {
  //   //   for (var alimentoIndex=0; alimentoIndex<dietaRefeicoes[refIndex].alimentosList.length; alimentoIndex++) {
  //   //     firebase.firestore()
  //   //       .collection("users")
  //   //       .doc(sessionStorage.getItem("current-user"))
  //   //       .collection("dietasSalvas")
  //   //       .doc(dietaDocId.toString())
  //   //       .collection("refeicoes")
  //   //       .doc(refIndex.toString())
  //   //       .collection("alimentosList")
  //   //       .doc(alimentoIndex.toString())
  //   //       .set(setNull(copy2Refeicoes[refIndex].alimentosList[alimentoIndex], "substitution"));
  //   //   }
  //   // }

  //   // if (dietaRefeicoes[refIndex].receitasList.length > 0) {
  //   //   for (var receitaIndex=0; receitaIndex<dietaRefeicoes[refIndex].receitasList.length; receitaIndex++) {
  //   //     firebase.firestore()
  //   //       .collection("users")
  //   //       .doc(sessionStorage.getItem("current-user"))
  //   //       .collection("dietasSalvas")
  //   //       .doc(dietaDocId.toString())
  //   //       .collection("refeicoes")
  //   //       .doc(refIndex.toString())
  //   //       .collection("receitasList")
  //   //       .doc(receitaIndex.toString())
  //   //       .set(setNull(copy3Refeicoes[refIndex].receitasList[receitaIndex], "alimentosList"));
  //   //   }

  //   //   for (var alimentoIndexR=0; alimentoIndexR<dietaRefeicoes[refIndex].receitasList[receitaIndex].alimentosList.length; alimentoIndexR++) {
  //   //     firebase.firestore()
  //   //       .collection("users")
  //   //       .doc(sessionStorage.getItem("current-user"))
  //   //       .collection("dietasSalvas")
  //   //       .doc(dietaDocId.toString())
  //   //       .collection("refeicoes")
  //   //       .doc(refIndex.toString())
  //   //       .collection("receitasList")
  //   //       .doc(receitaIndex.toString())
  //   //       .collection("alimentosList")
  //   //       .doc(alimentoIndexR.toString())
  //   //       .set(reduceObj(copy4Refeicoes[refIndex].receitasList[receitaIndex].alimentosList[alimentoIndexR]));
  //   //   }
  //   // }

  // }

  commit("dummySetter", "dummySet");
  // console.log(dieta)
}

export async function salvarDietaPaciente(
  { commit },
  {
    titulo,
    pacienteEmail,
    status,
    autorEmail,
    autorPapel,
    autorCrn,
    autorNome,
    autorAssinatura,
    dietaRefeicoes,
  }
) {
  var date = new Date();

  var day;
  var month;
  var dia = date.getDate();
  var mes = date.getMonth() + 1;
  if (dia < 10) {
    day = "0" + dia.toString();
  } else {
    day = dia.toString();
  }
  if (mes < 10) {
    month = "0" + mes.toString();
  } else {
    month = mes.toString();
  }
  // var data = `${day}/${month}/${date.getFullYear().toString()}`
  var data = day + "/" + month + "/" + date.getFullYear().toString();

  var millis = date.getTime();
  var milliseconds = 2524608000000 - date.getTime();

  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail.toString())
    .collection("dietasRefs")
    .doc(milliseconds.toString())
    .set({
      // .add({
      dietaDocId: milliseconds.toString(),
      titulo: titulo,
      kcal: status.KCAL,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      autorEmail: autorEmail,
      autorPapel: autorPapel,
      autorCrn: autorCrn,
      autorNome: autorNome,
      autorAssinatura: autorAssinatura,
      unread: true,
      // refeicoes: dietaRefeicoes
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail.toString())
    .collection("dietas")
    .doc(milliseconds.toString())
    .set({
      // .add({
      isNew: true,
      dietaDocId: milliseconds.toString(),
      titulo: titulo,
      status: status,
      autorEmail: autorEmail,
      autorPapel: autorPapel,
      autorCrn: autorCrn,
      autorNome: autorNome,
      autorAssinatura: autorAssinatura,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      refeicoes: dietaRefeicoes,
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail.toString())
    .update({
      lastDietaMillis: millis,
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail.toString())
    .collection("timeline")
    .doc(millis.toString())
    .set({
      docId: milliseconds.toString(),
      colId: "dietas",
      nome: titulo,
      type: "dieta",
      icon: "mdi-apple",
      date: data,
      to: "/PacienteDietas",
      text: "Uma nova dieta foi prescrita!",
      toText: "Todas as dietas",
      color: "#c5d13f",
      download: true,
    });

  commit("dummySetter", "dummySet");
}

export async function salvarDietaSalvasEdit(
  { commit },
  {
    dietaDocId,
    titulo,
    status,
    autorEmail,
    autorPapel,
    autorCrn,
    autorNome,
    autorAssinatura,
    dietaRefeicoes,
  }
) {
  console.log(sessionStorage.getItem("current-user"));

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietasSalvasRefs")
    .doc(dietaDocId.toString())
    .update({
      // .add({
      // dietaDocId: milliseconds,
      titulo: titulo,
      kcal: status.KCAL,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      autorEmail: autorEmail,
      autorPapel: autorPapel,
      autorCrn: autorCrn,
      autorNome: autorNome,
      autorAssinatura: autorAssinatura,
      // refeicoes: dietaRefeicoes
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietasSalvas")
    .doc(dietaDocId.toString())
    .update({
      // .add({
      dietaDocId: dietaDocId,
      titulo: titulo,
      status: status,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      refeicoes: dietaRefeicoes,
      autorEmail: autorEmail,
      autorPapel: autorPapel,
      autorCrn: autorCrn,
      autorNome: autorNome,
      autorAssinatura: autorAssinatura,
    });

  commit("dummySetter", "dummySet");
  // console.log(dieta)
}

export async function salvarDietaPacienteEdit(
  { commit },
  {
    dietaDocId,
    pacienteEmail,
    autorEmail,
    autorPapel,
    autorCrn,
    autorNome,
    autorAssinatura,
    status,
    dietaRefeicoes,
  }
) {
  var date = new Date();

  var day;
  var month;
  var dia = date.getDate();
  var mes = date.getMonth() + 1;
  if (dia < 10) {
    day = "0" + dia.toString();
  } else {
    day = dia.toString();
  }
  if (mes < 10) {
    month = "0" + mes.toString();
  } else {
    month = mes.toString();
  }
  // var data = `${day}/${month}/${date.getFullYear().toString()}`
  var data = day + "/" + month + "/" + date.getFullYear().toString();

  var millis = date.getTime();

  // async function getTitulo() {
  //   return await firebase
  //     .firestore()
  //     .collection("users")
  //     .doc(pacienteEmail.toString())
  //     .collection("dietas")
  //     .doc(dietaDocId.toString())
  //     .get()
  //     .then((snapshot) => {
  //       return snapshot.data().titulo;
  //     });
  // }

  // let titulo = await getTitulo();
  // console.log("titulo", titulo)

  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail.toString())
    .collection("dietas")
    .doc(dietaDocId.toString())
    .update({
      // .add({
      dietaDocId: dietaDocId.toString(),
      // titulo: titulo,
      status: status,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      refeicoes: dietaRefeicoes,
      autorEmail: autorEmail,
      autorPapel: autorPapel,
      autorCrn: autorCrn,
      autorNome: autorNome,
      autorAssinatura: autorAssinatura,
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail.toString())
    .collection("dietasRefs")
    .doc(dietaDocId.toString())
    .update({
      // .add({
      dietaDocId: dietaDocId,
      // titulo: titulo,
      kcal: status.KCAL,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      autorEmail: autorEmail,
      autorPapel: autorPapel,
      autorCrn: autorCrn,
      autorNome: autorNome,
      autorAssinatura: autorAssinatura,
      isNew: true,
      unread: true,
      // refeicoes: dietaRefeicoes
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail.toString())
    .update({
      lastDietaMillis: millis,
    });

  await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail.toString())
    .collection("timeline")
    .doc(millis.toString())
    .set({
      docId: dietaDocId,
      colId: "dietas",
      nome: "Cardápio ajustado",
      type: "dieta",
      icon: "mdi-apple",
      date: data,
      to: "/PacienteDietas",
      text: "Uma dieta foi editada!",
      toText: "Todas as dietas",
      color: "#c5d13f",
      download: true,
    });

  commit("dummySetter", "dummySet");
}

export function alimentoSubstitutions({ commit }, { nome, gramas, list }) {
  commit("setAlimentoSubstitutions", {
    nome: nome,
    gramas: gramas,
    list: list,
  });
}

export async function getDietaSalva({ commit }, { dietaDocId, alimentos }) {
  console.log(sessionStorage.getItem("current-user"));
  var dieta = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietasSalvas")
    .doc(dietaDocId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  var getAlimentosWithSameGroup = function(
    refeicoes,
    refeicaoIndex,
    alimentoIndex,
    alimentoInfo
  ) {
    var filterNome = function(alimento) {
      if (alimento !== undefined) {
        if (alimento.data.nome == alimentoInfo.nome) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    var alimentosNome = alimentos.filter(filterNome);
    console.log(alimentosNome[0]);
    var alimentoOriginal = alimentosNome[0];
    var filter = function(alimento) {
      if (alimento !== undefined && alimentoOriginal !== undefined) {
        if (
          alimento.data.grupo_1 == alimentoOriginal.data.grupo_1 &&
          // && calculateDiference(alimento.data.kcal, alimentoInfo.data.kcal)
          alimento.data.nome != alimentoOriginal.data.nome
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };
    var alimentosWithSameGroup = alimentos.filter(filter);
    // console.log(alimentoInfo.toSubstitute);
    alimentosWithSameGroup = alimentosWithSameGroup.map(function(alimento) {
      let factorX = alimentoOriginal.data.kcal / alimento.data.kcal;
      var chosenBool = false;
      if (alimentoInfo.toSubstitute != undefined) {
        chosenBool = alimentoInfo.toSubstitute
          .map(function(l) {
            return l.nome;
          })
          .includes(alimento.data.nome);
      }
      alimento.gramas = alimentoOriginal.data.gr_padrao * factorX;
      alimento.gramas = alimento.gramas.toFixed();
      alimento.qtdSub = alimento.gramas / alimento.data.gr_padrao;
      alimento.qtdSub = alimento.qtdSub.toFixed(1);
      alimento.chosen = chosenBool;
      return {
        qtdSub: alimento.qtdSub,
        gramas: alimento.gramas,
        chosen: alimento.chosen,
        nome: alimento.data.nome,
        medida_caseira_padrao: alimento.data.medida_caseira_padrao,
        alimentoId: 0,
        data: {
          nome: alimento.data.nome,
          medida_caseira_padrao: alimento.data.medida_caseira_padrao,
          alimentoId: 0,
        },
      };
    });
    refeicoes[refeicaoIndex].alimentosList[
      alimentoIndex
    ].substitution = alimentosWithSameGroup;
    // console.log("substitions1", refeicoes[refeicaoIndex].alimentosList[alimentoIndex].substitution)
  };

  for (var refIndex = 0; refIndex < dieta.refeicoes.length - 1; refIndex++) {
    // console.log("length alimentos",dieta.refeicoes[refIndex].alimentosList.length)
    for (
      var alimentoIndex = 0;
      alimentoIndex < dieta.refeicoes[refIndex].alimentosList.length; //REMOVE THAT -1
      alimentoIndex++
    ) {
      if (
        dieta.refeicoes[refIndex].alimentosList[alimentoIndex].alimentoId != ""
      ) {
        getAlimentosWithSameGroup(
          dieta.refeicoes,
          refIndex,
          alimentoIndex,
          dieta.refeicoes[refIndex].alimentosList[alimentoIndex]
        );
      }
    }
  }

  commit("setDietaTitulo", dieta.titulo);

  commit("setDietaStatus", dieta.status);
  commit("setDietaRefeicoes", dieta.refeicoes);

  commit("setDietaDocId", dietaDocId);
}

export async function getDietaPaciente({ commit }, { dietaDocId }) {
  var dieta = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietas")
    .doc(dietaDocId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  var dietaAutorObj = {
    email: "",
    papel: "",
  };
  if (dieta.autorEmail !== undefined) {
    dietaAutorObj.email = dieta.autorEmail;
    dietaAutorObj.papel = dieta.autorPapel;
  }
  if (dieta.autorAssinatura !== undefined) {
    dietaAutorObj.assinatura = dieta.autorAssinatura;
    dietaAutorObj.crn = dieta.autorCrn;
    dietaAutorObj.nome = dieta.autorNome;
  }
  commit("setDietaAutorObj", dietaAutorObj);

  commit("setDietaTitulo", dieta.titulo);

  commit("setDietaStatus", dieta.status);
  commit("setDietaRefeicoes", dieta.refeicoes);

  commit("setDietaDocId", dietaDocId);
}

export async function getDietaPacienteN(
  { commit },
  { dietaDocId, pacienteEmail }
) {
  console.log("the id", dietaDocId);
  var dieta = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("dietas")
    .doc(dietaDocId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  commit("setDietaTitulo", dieta.titulo);

  commit("setDietaStatus", dieta.status);
  commit("setDietaRefeicoes", dieta.refeicoes);

  var dietaAutorObj = {};
  if (dieta.autorEmail !== undefined) {
    dietaAutorObj.email = dieta.autorEmail;
    dietaAutorObj.papel = dieta.autorPapel;
  }
  if (dieta.autorAssinatura !== undefined) {
    dietaAutorObj.assinatura = dieta.autorAssinatura;
    dietaAutorObj.crn = dieta.autorCrn;
    dietaAutorObj.nome = dieta.autorNome;
  }
  commit("setDietaAutorObj", dietaAutorObj);

  commit("setDietaDocId", dietaDocId);
}

export async function getFormulaPaciente({ commit }, { docId }) {
  console.log(docId);
  var formulaP = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("formulas")
    .doc(docId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  commit("setFormulaPVis", formulaP);
}

export async function getFormulaPacientePh({ commit }, { docId }) {
  console.log(docId);
  var formulaPh = await firebase
    .firestore()
    .collection("pharmapele")
    .doc(docId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  commit("setFormulaPVis", formulaPh);
}

export async function getPrescricoes({ commit }) {
  var prescricoes = await firebase
    .firestore()
    .collection("pharmapele")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  commit("setPrescricoes", prescricoes);
}
export async function getFormulaPacienteN(
  { commit },
  { docId, pacienteEmail }
) {
  console.log(docId);
  var formulaP = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("formulas")
    .doc(docId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  commit("setFormulaPVis", formulaP);
}

export async function formulaEditData({ commit }, { formula }) {
  commit("setFormulaEditData", formula);
}

export async function getFormulasAll({ commit }, { pacienteEmail }) {
  var formulasAll = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("formulas")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );
  var formulasAllMillis = formulasAll.map(function(e) {
    var splitArray = e.dateStr.split("/");
    var dayStr = splitArray[0];
    var monthStr = splitArray[1];
    var yearSplit = splitArray[2];
    var yearNr = 2000 + parseInt(yearSplit);
    var yearStr = yearNr.toString();
    var dateStr = yearStr + "-" + monthStr + "-" + dayStr + " 08:00:00";
    var date = new Date(dateStr);
    e.millis = date.getTime();
    return e;
  });

  function compare(a, b) {
    if (a.millis < b.millis) {
      return 1;
    }
    if (a.millis > b.millis) {
      return -1;
    }
    return 0;
  }
  const formulasAllSorted = formulasAllMillis.sort(compare);
  commit("setPacienteFormulasAll", formulasAllSorted);
}
export async function getAnamnesesAll({ commit }, { pacienteEmail }) {
  var anemnesesAll = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("anamneseNotes")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );
  var anemnesesAllMillis = anemnesesAll.map(function(e) {
    e.millis = parseFloat(e.id);
    return e;
  });

  function compare(a, b) {
    if (a.millis < b.millis) {
      return 1;
    }
    if (a.millis > b.millis) {
      return -1;
    }
    return 0;
  }
  const anemnesesAllSorted = anemnesesAllMillis.sort(compare);
  commit("setPacienteAnamnesesAll", anemnesesAllSorted);
}

export async function getExamesAll({ commit }, { pacienteEmail }) {
  var examesAll = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("exames")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );
  var examesAllMillis = examesAll.map(function(e) {
    var splitArray = e.dateStr.split("/");
    var dayStr = splitArray[0];
    var monthStr = splitArray[1];
    var yearSplit = splitArray[2];
    var yearNr = 2000 + parseInt(yearSplit);
    var yearStr = yearNr.toString();
    var dateStr = yearStr + "-" + monthStr + "-" + dayStr + " 08:00:00";
    var date = new Date(dateStr);
    e.millis = date.getTime();
    return e;
  });

  function compare(a, b) {
    if (a.millis < b.millis) {
      return 1;
    }
    if (a.millis > b.millis) {
      return -1;
    }
    return 0;
  }
  const examesAllSorted = examesAllMillis.sort(compare);

  commit("setPacienteExamesAll", examesAllSorted);
}

export async function getOrientacoesAll({ commit }, { pacienteEmail }) {
  var orientacoesAll = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("orientacoes")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );
  var orientacoesAllMillis = orientacoesAll.map(function(e) {
    var splitArray = e.dateStr.split("/");
    var dayStr = splitArray[0];
    var monthStr = splitArray[1];
    var yearSplit = splitArray[2];
    var yearNr = 2000 + parseInt(yearSplit);
    var yearStr = yearNr.toString();
    var dateStr = yearStr + "-" + monthStr + "-" + dayStr + " 08:00:00";
    var date = new Date(dateStr);
    e.millis = date.getTime();
    return e;
  });

  function compare(a, b) {
    if (a.millis < b.millis) {
      return 1;
    }
    if (a.millis > b.millis) {
      return -1;
    }
    return 0;
  }
  const orientacoesAllSorted = orientacoesAllMillis.sort(compare);

  commit("setPacienteOrientacoesAll", orientacoesAllSorted);
}
export async function getOrcamentosAll({ commit }, { pacienteEmail }) {
  var orcamentosAll = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("orcamentos")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  function compare(a, b) {
    if (a.orcamentoFim < b.orcamentoFim) {
      return 1;
    }
    if (a.orcamentoFim > b.orcamentoFim) {
      return -1;
    }
    return 0;
  }
  const orcamentosAllSorted = orcamentosAll.sort(compare);

  commit("setPacienteOrcamentosAll", orcamentosAllSorted);
}

export async function getVideoAulasAll({ commit }, { pacienteEmail }) {
  var videoAulasAll = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("videoAulas")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  commit("setPacienteVideoAulasAll", videoAulasAll);
}

export function setTemaVis({ commit }, { tema }) {
  commit("setTemaVis", tema);
}

export async function getComprasAll({ commit }, { pacienteEmail }) {
  var comprasAll = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("compras")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  commit("setPacienteComprasAll", comprasAll);
}

export function setCompraVis({ commit }, { compra }) {
  commit("setCompraVis", compra);
}

export async function getAgendaAll({ commit }, { pacienteEmail }) {
  var agendaAll = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("agenda")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  commit("setPacienteAgendaAll", agendaAll);
}

export async function getFuncionarios({ commit }, { uId }) {
  var funcionarios = await firebase
    .firestore()
    .collection("users")
    .doc(uId)
    .collection("funcionarios")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  commit("setFuncionarios", funcionarios);
}

export async function getExamePaciente({ commit }, { docId }) {
  console.log(docId);
  var examP = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("exames")
    .doc(docId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  commit("setExamPVis", examP);
}

export async function getOrientacaoPaciente({ commit }, { docId }) {
  console.log(docId);
  var orientacaoP = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("orientacoes")
    .doc(docId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  commit("setOrientacaoPVis", orientacaoP);
}

export async function getExamePacienteN({ commit }, { docId, pacienteEmail }) {
  console.log(docId);
  var examP = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("exames")
    .doc(docId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  commit("setExamPVis", examP);
}

export async function getOrientacaoPacienteN(
  { commit },
  { docId, pacienteEmail }
) {
  console.log(docId);
  var orientacaoP = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("orientacoes")
    .doc(docId.toString())
    .get()
    .then(function(doc) {
      return doc.data();
    });

  commit("setOrientacaoPVis", orientacaoP);
}

export async function getPacienteTimeline({ commit }, { pacienteEmail }) {
  var pacienteTimelineList = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("timeline")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );
  commit("setPacienteTimeline", pacienteTimelineList);
}

export async function pacienteAtendimentosList({ commit }) {
  var pacienteAtendimentosList = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("atendimentos")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        let data = doc.data();
        data.oldId = doc.id;
        return data;
      })
    );

  function compare(a, b) {
    if (a.data < b.data) {
      return -1;
    }
    if (a.data > b.data) {
      return 1;
    }
    return 0;
  }

  pacienteAtendimentosList = pacienteAtendimentosList.sort(compare);

  commit("pacienteAtendimentosList", pacienteAtendimentosList);
  console.log(pacienteAtendimentosList);
}

export async function pacienteAtendimentosListN({ commit }, { pacienteEmail }) {
  console.log(pacienteEmail);
  var pacienteAtendimentosListI = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("atendimentos")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        let data = doc.data();
        data.oldId = doc.id;
        return data;
      })
    );

  function compare(a, b) {
    if (a.data < b.data) {
      return -1;
    }
    if (a.data > b.data) {
      return 1;
    }
    return 0;
  }

  pacienteAtendimentosListI = pacienteAtendimentosListI.sort(compare);
  commit("pacienteAtendimentosList", pacienteAtendimentosListI);
  console.log(pacienteAtendimentosListI);
}

export async function pacienteDietaRefs({ commit }, { pacienteEmail }) {
  var pacienteDietaRefsListI = await firebase
    .firestore()
    .collection("users")
    .doc(pacienteEmail)
    .collection("dietasRefs")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return doc.data();
      })
    );

  var pacienteDietaRefsListII = pacienteDietaRefsListI.map(function(d) {
    if (d.ativo == undefined) {
      d.ativo = true;
    }
    d.includedInGroceryList = false;
    return d;
  });
  console.log("pacienteDietaRefsListII", pacienteDietaRefsListII);
  commit("setPacienteDietaRefs", pacienteDietaRefsListII);
}

export function dietaDocId({ commit }, { dietaDocId }) {
  commit("setDietaDocId", dietaDocId);
}

export function dietaTitulo({ commit }, { dietaTitulo }) {
  commit("setDietaTitulo", dietaTitulo);
}

export function deletarDietaSalva({ commit }, { dietaDocId }) {
  commit("dummySetter", "dummySet");

  firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietasSalvas")
    .doc(dietaDocId.toString())
    .delete()
    .then(function() {
      console.log("Document " + dietaDocId + "successfully deleted!");
    })
    .catch(function(error) {
      console.error("Error removing document: " + dietaDocId, error);
    });

  firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("dietasSalvasRefs")
    .doc(dietaDocId.toString())
    .delete()
    .then(function() {
      console.log("Document " + dietaDocId + "successfully deleted!");
    })
    .catch(function(error) {
      console.error("Error removing reference: " + dietaDocId, error);
    });
}

export function agendaMov({ commit }, { mov }) {
  commit("setMov", mov);
  router.replace("/AgendarPosCompra");
}

export function agendaMovEdit({ commit }, { mov }) {
  commit("setMov", mov);
  router.replace("/AgendarPosCompraEdit");
}

export function agendaPacienteNome({ commit }, { obj }) {
  commit("setAgendaPacienteObj", obj);
  console.log(obj);
  router.replace("/PacienteNovo");
}

export function agendaPacienteNome_noRedirect({ commit }, { obj }) {
  commit("setAgendaPacienteObj", obj);
}

export async function getCaixa({ commit }) {
  var caixaList = await firebase
    .firestore()
    .collection("users")
    .doc(sessionStorage.getItem("current-user"))
    .collection("caixa")
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        return {
          value: doc.id,
          data: doc.data(),
          text: doc.data().tipo,
        };
      })
    );

  console.log(caixaList.length);
  if (
    sessionStorage.getItem("current-user") ==
    "umarizalbelem@clubedanutricionista.com.br"
  ) {
    var limitMillis = 1640930400000;
    caixaList = caixaList.filter(function(item) {
      return item.data.millis > limitMillis;
      // return item.data.direction
    });
  }
  if (
    sessionStorage.getItem("current-user") == "bc@clubedanutricionista.com.br"
  ) {
    var limitMillisBC = 1647388823000;
    caixaList = caixaList.filter(function(item) {
      return item.data.millis > limitMillisBC;
      // return item.data.direction
    });
  }
  console.log(caixaList.length);

  commit("setCaixa", caixaList);
}

// export function sendEmail() {
//   firebase.functions().httpsCallable('sendEmail')
// }
