<template>
  <div class="page">
    <v-dialog v-model="clausulaDialog" persistent scrollable width="90%">
      <v-card>
        <v-card-title>Cláusula 16ª</v-card-title>
        <v-card-text>
          <v-row>
            <p class="mx-4">
              Pactuação facultativa de compromisso arbitral nos termos da lei
              9.307/96 aceita por iniciativa do <strong>CONTRATANTE</strong>:
              fica logo estabelecido, em caráter definitivo, que qualquer
              problema que venha resultar em conflito entre as partes, que seja
              originado ou relacionado com o presente contrato, será
              definitivamente resolvido por intermédio de arbitragem, conforme o
              regulamento de arbitragem de qualquer tribunal arbitral, com sede
              no municípop de Belém, capital do estado do Pará, por intermédio
              de um ou mais árbitros nomeados e a sentença por ele(s) prolatada
              poderá ser executada em qualquer juízo que sobre ela tenha
              jurisdição. Cujo intuito dessa cláusula é a celeridade e a solução
              de todos os problemas oriundos do presente contrato.
            </p>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <!-- <v-checkbox
            v-model="clausulaChecked"
            label="Confirmo que li e concordo com esta clausula"
          ></v-checkbox> -->
          Ao prosseguir, confirmo que li e concordo com esta clausula
          <v-btn disabled v-if="sendingClausulaDialog" class="mx-4">
            <v-progress-circular
              :size="20"
              :width="3"
              color="#c5d13f"
              indeterminate
            ></v-progress-circular>
          </v-btn>
          <v-btn
            color="#c5d13f"
            @click="sendClausulaConcordo"
            class="mx-4"
            v-else
          >
            Prosseguir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="contractDialog"
      persistent
      scrollable
      width="90%"
      style="height: 90%"
    >
      <v-card>
        <v-card-title class="d-flex justify-center">
          <!-- <v-row class="d-flex justify-center"> -->
          Contrato de prestação de serviços
          <!-- </v-row> -->
        </v-card-title>
        <v-card-text v-if="userInfo.contratoVersion !== undefined">
          <div v-if="userInfo.contratoVersion == 2">
            <v-row>
              <p>
                De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
                informações pessoais e dados cadastrais necessários dentro do
                sistema (software). Do outro lado a
                <strong>CONTRATADA</strong> também com todas as informações
                necessárias de informação dentro do sistema (software). Ambos
                totalmente disponibilizados de maneira online em sítio
                eletrônico (website).<br />
                Pelo presente instrumento particular de prestação de serviços
                têm entre si justos e acordados quanto segue:
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO OBJETO DO CONTRATO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como
                objeto, a prestação de serviços de nutrição e estética
                personalizados. <br /><strong>CLÁUSULA 2ª:</strong> A
                <strong>CONTRATADA</strong> executará os serviços diretamente
                a(o) <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço
                ser executado em menores de 18 (dezoito) anos ou em pessoas
                maiores de 18 (dezoito) anos incapazes, a luz do direito civil
                brasileiro, deverá ter uma pessoa responsável, a qual apenas
                precisará ser informada no sistema.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da
                cláusula 2ª, não se aplica aos menores de 18 (dezoito) anos
                emancipados, o qual fica condicionado a prestação do serviço
                pela
                <strong>
                  <u
                    >apresentação de sentença judicial transitada em julgado
                    informando a sua emancipação ou certidão de casamento
                    (original ou cópia autenticada)</u
                  ></strong
                >, a qual deverá ser encaminhado digitalizada e encaminhada para
                o setor jurídico da <strong>CONTRATANTE</strong> para autorizar
                a execução dos serviços.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DOS SERVIÇOS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>CLÁUSULA 3ª:</strong> A
                <strong>CONTRATADA</strong> prestará um ou mais dos serviços
                previstos conforme o que estiver disponibilizado pelo “Anexo 1”
                e a <strong>CONTRATANTE</strong> tiver escolhido.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
                prestados pela <strong>CONTRATADA</strong> a(o)
                <strong>CONTRATANTE</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – <strong>Pacote:</strong> entende-se como pacote conjunto de
                sessões e/ou consultas disponibilizados pelo sistema;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – <strong>Pacote estético avulso:</strong> entende-se como
                pacote de sessões exclusivamente estéticas disponibilizado pelo
                sistema;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III –
                <strong
                  >Consultas nutricionais e sessões estéticas avulsas:</strong
                >
                entende-se como não dentro de qualquer pacote, contratando para
                serviço específico e pontual.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
                prestados no estabelecimento da <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> As consultas e o
                acompanhamento nutricional poderão ser realizados, intercalado
                ou exclusivamente, por vídeo conferência quando solicitado
                pelo(a) <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> Todas as sessões de
                tratamento de estética serão realizadas exclusivamente no
                estabelecimento da <strong>CONTRATADA</strong>, não existe
                exceção.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Todos as sessões e
                consultas deverão ser previamente agendadas dentro do sistema
                disponibilizado online, o qual é um software, possível de ser
                acessado via computador ou aplicativo de telefone celular ambos
                conectados com a rede mundial de computadores (internet), pela
                <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo
                para que o(a) <strong>CONTRATANTE</strong> possa usufruir dos
                serviços contratados, sendo proibido a
                <strong>CONTRATADA</strong> prestar serviços para o(a)
                <strong>CONTRATANTE</strong> fora desse prazo:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – <strong>Programa:</strong> prazo máximo de 12 (doze) semanas
                corridos a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – <strong>Pacote estético avulso:</strong> prazo máximo de 30
                (trinta) dias corridos a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – <strong>Consulta e/ou sessão avulsa:</strong> prazo máximo
                de 15 (quinze) dias corridos a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser
                prorrogado nas seguintes hipóteses:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Doença (CID) grave que impossibilita a continuidade da
                prestação do serviço;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">II – Doença infecto contagiosa;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Quando decretado lockdown pelo chefe do poder executivo
                municipal (prefeito), estadual (governador) ou federal/central
                (presidente, primeiro-ministro, monarca, imperador) onde as
                sessões/consultas presenciais de estéticas/nutricionais estão
                sendo realizadas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
                resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
                e/ou da consulta, salvo nas hipóteses de doença previstas nos
                incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
                contados da contratação o(a) <strong>CONTRATANTE</strong> não
                poderá mais usufruir dos serviços, salvo em uma das hipóteses
                previstas no parágrafo 1º, cláusula 5ª, e nas condições
                estabelecidas no presente contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
                incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
                <strong>CONTRATANTE</strong> utilizar dos serviços da
                <strong>CONTRATADA</strong> nem a concessão de créditos pelo não
                uso dentro do prazo, também não haverá a devolução de qualquer
                quantia.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
                tenha terminado de pagar, não impedirá a
                <strong>CONTRATADA</strong> de realizar a cobrança dos valores
                não pagos, por todas as medidas legais cabíveis.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no
                inciso primeiro, do parágrafo 1º, da cláusula 5ª, haverá a
                suspensão do tratamento até ter condições aptas para continuar a
                receber os serviços, devendo apresentar laudo médico com
                matrícula no CRM (Conselho Regional de Medicina) válida, sob
                pena de perder os dias em que esteve impossibilitada de
                continuar a usufruir dos serviços.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no
                inciso segundo do parágrafo 1º o(a)
                <strong>CONTRATANTE</strong> precisará reagendar o dia em que
                faltar por motivo de doença, devendo apresentar atestado médico
                com matrícula no CRM e assinado, nas mesmas condições do
                parágrafo 6º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no
                inciso quarto do parágrafo 1º o(a)
                <strong>CONTRATANTE</strong> terá a prorrogação automática pelo
                tempo em que o lockdown tiver sido decretado, nas seguintes
                condições:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – O serviço contratado não for considerado como serviço
                essencial previsto pelo decreto, caso seja considerado não
                ocorrerá a suspensão do serviço.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – A suspensão dos serviços pela decretação do lockdown
                somente ocorre se houver impedimento para o local onde o serviço
                é prestado, em situação em decretação em região diferente da
                qual o serviço é prestado não haverá prorrogação, devendo o
                <strong>CONTRATANTE</strong> usufruir conforme o que estiver
                estabelecido pelo presente Contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações
                serão no mesmo número de dias em que ocorreram as situações
                previstas nos incisos, do parágrafo 1º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as
                seguintes obrigações do(a) <strong>CONTRATANTE</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
                presente contrato;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                b) Realizar todos os exames solicitados pela
                <strong>CONTRATADA</strong> para a efetiva prestação do serviço
                com qualidade, caso não seja realizado será total
                responsabilidade do(a) <strong>CONTRATANTE</strong>;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
                abatimentos, descontos ou dilações de prazo para o pagamento e
                execução dos serviços, sem o prévio conhecimento e autorização
                da <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as
                seguintes obrigações da <strong>CONTRATADA</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                a) Atividades de seus funcionários serem exercidos com
                profissionalismo, respeito e zelo;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                b) Respeitar o Código de Ética dos Nutricionistas;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                c) Não revelar detalhes de suas atividades a terceiros, bem
                como, informações sobre seus clientes;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
                (software) com acesso enquanto durar o
                programa/pacote/sessão/consulta, após o término do presente
                contrato será excluído a qualquer momento sem qualquer aviso
                prévio;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
                (software) com acesso enquanto durar o pacote/sessão/consulta,
                após o término do presente contrato será excluído a qualquer
                momento sem qualquer aviso prévio;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                e) Iniciar a consulta, tratamento ou sessão conforme data
                agendada após o pagamento da primeira parcela ou do pagamento
                integral;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                f) Manter o sigilo das informações da
                <strong>CONTRATANTE</strong> conforme o que estiver disposto na
                lei de nº 13.709/2018 (Lei Geral de Proteção de Dados).
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO DIREITO DE IMAGEM</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4" v-if="imagemNao">
                <strike>
                  <strong><u>Cláusula 8ª:</u></strong> O(A)
                  <strong>CONTRATANTE</strong> ao assinar esse contrato
                  autorizará a
                  <strong>CONTRATADA</strong>
                  a realizar a divulgação de sua imagem de forma gratuita em
                  suas redes sociais e em demais mídias, respeitando o Código de
                  Ética dos Nutricionistas.
                </strike>
              </p>
              <p class="mx-4" v-else>
                <strong><u>Cláusula 8ª:</u></strong> O(A)
                <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará
                a
                <strong>CONTRATADA</strong>
                a realizar a divulgação de sua imagem de forma gratuita em suas
                redes sociais e em demais mídias, respeitando o Código de Ética
                dos Nutricionistas.
              </p>
            </v-row>
            <v-row class="d-flex justify-center mx-4">
              <v-checkbox
                v-model="imagemNao"
                label="Não autorizo a divulgação da minha imagem."
              ></v-checkbox>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
                parte do(a) <strong>CONTRATANTE</strong>, após iniciado o
                programa ou pago, todos os valores pagos e a taxa administrativa
                (corresponde a 10% sobre o valor total do serviço) até a data de
                desistência não serão restituídos. Com relação aos valores
                pagos, apenas serão concedidos créditos correspondentes aos
                valores não usufruídas dentro do prazo para uso do serviço para
                uso posterior em sessões e/ou consultas
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela
                desistência do(a) <strong>CONTRATANTE</strong> poderá ser
                usufruído no prazo de 182 (cento e oitenta e dois) dias,
                contados da concessão desses créditos.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese
                haverá devolução de dinheiro após o início do tratamento,
                procedimento, consulta ou sessão, o que vier primeiro.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Caso o(a)
                <strong>CONTRATANTE</strong> desista antes do início da
                prestação do serviço, apenas ficará retido o valor da taxa
                administrativa de 10% sobre o valor do serviço total, devendo
                todo o resto ser restituído.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do
                serviço não existe mais a possibilidade de desistência e/ou
                restituição dos valores não gozados, pois a validade estará
                vencida, devendo o(a) <strong>CONTRATANTE</strong> pagar por
                todos os valores pendentes conforme o estipulado, caso ainda
                esteja em débito de pagamento, não sendo proibido a aplicação de
                juros nas condições previstas no presente contrato na clausula
                11ª, parágrafo 4º.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 10ª:</u></strong> A
                <strong>CONTRATADA</strong> poderá se recusar a prestar o
                serviço e/ou de rescindir o contrato quando:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Não preenchimento do questionário de saúde pré-consulta
                online;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">II – Não pagamento total ou parcial;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Realizar o pagamento por cheque sem fundos;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                IV – Não entrega dos exames solicitados antes do início do
                tratamento, quando esses forem pertinentes para a prestação do
                serviço;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Não prestar as informações solicitadas pela nutricionista
                responsável pelo acompanhamento nutricional;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                VI – Solicitar atividade não compreendida como serviço
                contratado ou disponibilizado pela <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
                previstas nos incisos da cláusula 10ª a
                <strong>CONTRATADA</strong> devolverá os valores das sessões e
                consultas pagas e não usufruídas pelo(a)
                <strong>CONTRATANTE</strong>, devendo aplicar o que couber no
                presente contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
                serviço não impossibilita de a CONTRATADA realizar a cobrança
                dos valores remanescentes ainda não pagos, em caso de aplicação
                de uma das hipóteses da cláusula 10ª.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO PAGAMENTO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 11:</u></strong> A
                <strong>CONTRATADA</strong> receberá o pagamento conforme está
                disposto no sistema (software).
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento
                são:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">I – Transferência bancária;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – PIX (forma de transferência bancária instantânea
                disponibilizado pelo Banco Central do Brasil);
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">III – Cartão de crédito;</p>
            </v-row>
            <v-row>
              <p class="mx-4">IV – Débito automático;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Cheque, desde que pago integralmente no ato da contratação
                ou antes da primeira consulta ou sessão, o que vier primeiro;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista
                integral ou da primeira parcela no cartão de crédito
                (independente de quantas vezes seja parcelado no cartão)
                autoriza o início do tratamento, consulta, sessão ou
                procedimento.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
                cheque apenas será confirmada quando sacado o cheque antes da
                primeira sessão ou consulta.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em
                que for sacado esteja sem fundos ou qualquer outro resultado que
                não seja a entrega do dinheiro correspondente pela instituição
                financeira a favor da CONTRATADA, não impede dessa tomar as
                medidas legais cabíveis.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento
                são proibidas de serem feitas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de
                pagamento incidirá juros mensais de 1% (um por cento) ao mês de
                atraso, mais multa na importância de 2% (dois por cento)
                corrigidos pelo IGP-M (Índice Geral de Preços Mercado), o qual é
                disponibilizado pela Fundação Getúlio Vargas. Na impossibilidade
                de aplicação do índice por qualquer motivo aplicar-se-á o índice
                inflacionário oficial do governo federal.
              </p></v-row
            >
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA VIGÊNCIA</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
                vigência por prazo indeterminado até o cumprimento de todas as
                suas obrigações.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá
                ter prazo determinado a depender do tratamento ou do pacote ou
                do programa aderido conforme estiver no sistema (software).
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
                instrumento de contrato, não extingue os direitos e obrigações
                que as partes tenham entre si e para com terceiros.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA CLAUSULA DE ARBITRAGEM</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  ><u
                    >CLÁUSULA 13ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL
                    NOS TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO
                    CONTRATANTE: FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO,
                    QUE QUALQUER PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE
                    AS PARTES, QUE SEJA ORIGINADO OU RELACIONADO COM O PRESENTE
                    CONTRATO, SERÁ DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE
                    ARBITRAGEM, CONFORME O REGULAMENTO DE ARBITRAGEM DE QUALQUER
                    TRIBUNAL ARBITRAL, COM SEDE NO MUNICÍPIO DE BELÉM, CAPITAL
                    DO ESTADO DO PARÁ, POR INTERMÉDIO DE UM OU MAIS ÁRBITROS
                    NOMEADOS E A SENTENÇA POR ELE(S) PROLATADA PODERÁ SER
                    EXECUTADA EM QUALQUER JUÍZO QUE SOBRE ELA TENHA JURISDIÇÃO.
                    CUJO INTUITO DESSA CLAUSULA É A CELERIDADE E A SOLUÇÃO DE
                    TODOS OS PROBLEMAS ORIUNDOS DO PRESENTE CONTRATO.
                  </u></strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong
                  >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
                  CONTRATAÇÃO</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 14ª:</u></strong> O
                <strong>CONTRATANTE</strong> terá como vantagens durante o
                período da contratação:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Suporte nutricional até a data de término em horário
                comercial;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – Caso algum dos programas, previstos no anexo 1, seja
                contratado terá cumulativamente, além do previsto no inciso I,
                acesso a e-books (livros eletrônicos/digitais), acesso ao
                sistema (software) e vídeos aulas educativos.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 15ª:</u></strong> Condições para participar:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Efetuar o pagamento total do programa na 1ª consulta ou
                antes;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >II – Agendar dias fixos na semana para realização dos
                  tratamentos estéticos;</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Realizar todos os procedimentos e consultas até a data de
                término.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 16ª:</u></strong> Regras e observações
                importantes:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >I – Cada cliente deverá ter seu dia fixo para realizar os
                  tratamentos, o não comparecimento na hora marcada (falta)
                  implica em perda da sessão, pois o horário ficará bloqueado na
                  agenda sem a possibilidade de disponibilizá-lo para outro
                  cliente</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – Não será possível desmarcar e remarcar sessões toda semana
                e nem desmarcar somente no dia da sessão, o cliente deverá
                alterar os dias fixos com o máximo de antecedência para não
                prejudicar a agenda, os demais clientes e seu próprio
                tratamento.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Em caso de pausa do tratamento até a data de término, não
                existe garantia posterior de os mesmos dias fixos estejam
                disponíveis quando retornar.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                IV – As demais consultas, podem ser feitas por meio de
                videoconferência, de acordo com o código de ética do
                nutricionista. Desta forma, o não comparecimento físico ou
                on-line na data marcada prejudica a agenda de consultas, atrasa
                o tratamento e pode implicar em perda da consulta.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Cada consulta deve ser agendada com intervalo médio de 30
                (trinta) dias entre uma e outra.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                VI – Os cardápios e prescrições serão disponibilizados no
                software em até 3 dias úteis após a consulta e com intervalo
                mínimo de 30 dias entre um e outro.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DISPOSIÇÕES FINAIS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 17ª:</u></strong> Em observância do disposto
                no artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor),
                declara expressamente o <strong>CONTRATANTE</strong> que, em
                virtude de ter lido todas as cláusulas constantes deste
                contrato, está ciente de todas elas, aceitando-as expressamente
                e de poder acessar no sistema o presente contrato quando quiser,
                antes, durante e depois de finalizado o presente contrato.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>Anexo 1</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>1. Programa Clínico Funcional - R$900,00</strong><br />3
                consultas mais uma avaliação final
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>2. Programa de Definição Corporal - R$900,00</strong
                ><br />3 consultas mais uma avaliação final
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>3. Programa de Modulação Intestinal - R$990,00</strong
                ><br />3 consultas mais uma avaliação final
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4. Programa de Emagrecimento 12 (doze) semanas</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.1. Plano básico</strong><br />3 (três) consultas + 10
                (dez) semanas de pacote estético em 1 (uma) área: R$ 1.860,00
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.2. Plano Premium</strong><br />3 (três) consultas + 10
                (dez) semanas de pacote estético em 2 (duas) áreas: R$ 2.020,00
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.3. Plano VIP</strong><br />3 (três) consultas + 10
                (dez) semanas de pacote estético em 3 (três) áreas: R$
                2.380,00<br />
                *No plano VIP ainda possui a vantagem exclusiva de 1(uma) pausa
                no programa de até 4 semanas consecutivas em caso de férias,
                doença ou viagem.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>5. Pacotes estéticos adicionais</strong><br />
                5.1. 1 (um) pacote mensal para 1(uma) área: R$ 480,00 (R$
                120,00/área)<br />
                5.2. 1 (um) pacote mensal para 2(duas) áreas: R$ 720,00 (R$
                90,00/área)<br />
                5.3. 1 (um) pacote mensal para 3(três) áreas: R$ 960,00 (R$
                80,00/área)<br />
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>Anexo 2 - Tipos de tratamentos (não invasivos)</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                - Para redução de celulite, gordura localizada, flacidez,
                retenção hídrica, ou estímulo muscular para definição
                corporal.<br />
                - Utilização de aparelhos e/ou procedimentos manuais (massagem e
                drenagem)<br />
                - Utilização de ativos durante sessões e home care (opcional)<br />
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4">
                <strong
                  >DEFINIÇÃO DE ÁREA (1 área é equivalente a ½ folha a
                  A4)</strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left">Quantidade de áreas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="areaItem in areaItems" :key="areaItem.nome">
                      <td>{{ areaItem.nome }}</td>
                      <td>{{ areaItem.qtd }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </div>
          <div v-else-if="userInfo.contratoVersion == 3">
            <v-row>
              <p>
                De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
                informações pessoais e dados cadastrais necessários dentro do
                sistema (software). Do outro lado a
                <strong>CONTRATADA</strong> também com todas as informações
                necessárias de informação dentro do sistema (software). Ambos
                totalmente disponibilizados de maneira online em sítio
                eletrônico (website).<br />
                Pelo presente instrumento particular de prestação de serviços
                têm entre si justos e acordados quanto segue:
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO OBJETO DO CONTRATO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como
                objeto, a prestação de serviços de nutrição e estética
                personalizados. <br /><strong>CLÁUSULA 2ª:</strong> A
                <strong>CONTRATADA</strong> executará os serviços diretamente
                a(o) <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço
                ser executado em menores de 18 (dezoito) anos ou em pessoas
                maiores de 18 (dezoito) anos incapazes, a luz do direito civil
                brasileiro, deverá ter uma pessoa responsável, a qual apenas
                precisará ser informada no sistema.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da
                cláusula 2ª, não se aplica aos menores de 18 (dezoito) anos
                emancipados, o qual fica condicionado a prestação do serviço
                pela
                <strong>
                  <u
                    >apresentação de sentença judicial transitada em julgado
                    informando a sua emancipação ou certidão de casamento
                    (original ou cópia autenticada)</u
                  ></strong
                >, a qual deverá ser encaminhado digitalizada e encaminhada para
                o setor jurídico da <strong>CONTRATANTE</strong> para autorizar
                a execução dos serviços.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DOS SERVIÇOS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>CLÁUSULA 3ª:</strong> A
                <strong>CONTRATADA</strong> prestará um ou mais dos serviços
                previstos conforme o que estiver disponibilizado pelo “Anexo 1”
                e a <strong>CONTRATANTE</strong> tiver escolhido.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
                prestados pela <strong>CONTRATADA</strong> a(o)
                <strong>CONTRATANTE</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – <strong>Pacote:</strong> entende-se como pacote conjunto de
                sessões e/ou consultas disponibilizados pelo sistema;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – <strong>Pacote estético avulso:</strong> entende-se como
                pacote de sessões exclusivamente estéticas disponibilizado pelo
                sistema;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III –
                <strong
                  >Consultas nutricionais e sessões estéticas avulsas:</strong
                >
                entende-se como não dentro de qualquer pacote, contratando para
                serviço específico e pontual.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
                prestados no estabelecimento da <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> As consultas e o
                acompanhamento nutricional poderão ser realizados, intercalado
                ou exclusivamente, por vídeo conferência quando solicitado
                pelo(a) <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> Todas as sessões de
                tratamento de estética serão realizadas exclusivamente no
                estabelecimento da <strong>CONTRATADA</strong>, não existe
                exceção.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Todos as sessões e
                consultas deverão ser previamente agendadas dentro do sistema
                disponibilizado online, o qual é um software, possível de ser
                acessado via computador ou aplicativo de telefone celular ambos
                conectados com a rede mundial de computadores (internet), pela
                <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo
                para que o(a) <strong>CONTRATANTE</strong> possa usufruir dos
                serviços contratados, sendo proibido a
                <strong>CONTRATADA</strong> prestar serviços para o(a)
                <strong>CONTRATANTE</strong> fora desse prazo:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – <strong>Programa:</strong> prazo máximo de 12 (doze) semanas
                corridos a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – <strong>Pacote estético avulso:</strong> prazo máximo de 30
                (trinta) dias corridos a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – <strong>Consulta e/ou sessão avulsa:</strong> prazo máximo
                de 15 (quinze) dias corridos a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser
                prorrogado nas seguintes hipóteses:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Doença (CID) grave que impossibilita a continuidade da
                prestação do serviço;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">II – Doença infecto contagiosa;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Quando decretado lockdown pelo chefe do poder executivo
                municipal (prefeito), estadual (governador) ou federal/central
                (presidente, primeiro-ministro, monarca, imperador) onde as
                sessões/consultas presenciais de estéticas/nutricionais estão
                sendo realizadas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
                resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
                e/ou da consulta, salvo nas hipóteses de doença previstas nos
                incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
                contados da contratação o(a) <strong>CONTRATANTE</strong> não
                poderá mais usufruir dos serviços, salvo em uma das hipóteses
                previstas no parágrafo 1º, cláusula 5ª, e nas condições
                estabelecidas no presente contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
                incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
                <strong>CONTRATANTE</strong> utilizar dos serviços da
                <strong>CONTRATADA</strong> nem a concessão de créditos pelo não
                uso dentro do prazo, também não haverá a devolução de qualquer
                quantia.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
                tenha terminado de pagar, não impedirá a
                <strong>CONTRATADA</strong> de realizar a cobrança dos valores
                não pagos, por todas as medidas legais cabíveis.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no
                inciso primeiro, do parágrafo 1º, da cláusula 5ª, haverá a
                suspensão do tratamento até ter condições aptas para continuar a
                receber os serviços, devendo apresentar laudo médico com
                matrícula no CRM (Conselho Regional de Medicina) válida, sob
                pena de perder os dias em que esteve impossibilitada de
                continuar a usufruir dos serviços.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no
                inciso segundo do parágrafo 1º o(a)
                <strong>CONTRATANTE</strong> precisará reagendar o dia em que
                faltar por motivo de doença, devendo apresentar atestado médico
                com matrícula no CRM e assinado, nas mesmas condições do
                parágrafo 6º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no
                inciso quarto do parágrafo 1º o(a)
                <strong>CONTRATANTE</strong> terá a prorrogação automática pelo
                tempo em que o lockdown tiver sido decretado, nas seguintes
                condições:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – O serviço contratado não for considerado como serviço
                essencial previsto pelo decreto, caso seja considerado não
                ocorrerá a suspensão do serviço.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – A suspensão dos serviços pela decretação do lockdown
                somente ocorre se houver impedimento para o local onde o serviço
                é prestado, em situação em decretação em região diferente da
                qual o serviço é prestado não haverá prorrogação, devendo o
                <strong>CONTRATANTE</strong> usufruir conforme o que estiver
                estabelecido pelo presente Contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações
                serão no mesmo número de dias em que ocorreram as situações
                previstas nos incisos, do parágrafo 1º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as
                seguintes obrigações do(a) <strong>CONTRATANTE</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
                presente contrato;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                b) Realizar todos os exames solicitados pela
                <strong>CONTRATADA</strong> para a efetiva prestação do serviço
                com qualidade, caso não seja realizado será total
                responsabilidade do(a) <strong>CONTRATANTE</strong>;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
                abatimentos, descontos ou dilações de prazo para o pagamento e
                execução dos serviços, sem o prévio conhecimento e autorização
                da <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as
                seguintes obrigações da <strong>CONTRATADA</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                a) Atividades de seus funcionários serem exercidos com
                profissionalismo, respeito e zelo;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                b) Respeitar o Código de Ética dos Nutricionistas;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                c) Não revelar detalhes de suas atividades a terceiros, bem
                como, informações sobre seus clientes;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
                (software) com acesso enquanto durar o
                programa/pacote/sessão/consulta, após o término do presente
                contrato será excluído a qualquer momento sem qualquer aviso
                prévio;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
                (software) com acesso enquanto durar o pacote/sessão/consulta,
                após o término do presente contrato será excluído a qualquer
                momento sem qualquer aviso prévio;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                e) Iniciar a consulta, tratamento ou sessão conforme data
                agendada após o pagamento da primeira parcela ou do pagamento
                integral;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                f) Manter o sigilo das informações da
                <strong>CONTRATANTE</strong> conforme o que estiver disposto na
                lei de nº 13.709/2018 (Lei Geral de Proteção de Dados).
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO DIREITO DE IMAGEM</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4" v-if="imagemNao">
                <strike>
                  <strong><u>Cláusula 8ª:</u></strong> O(A)
                  <strong>CONTRATANTE</strong> ao assinar esse contrato
                  autorizará a <strong>CONTRATADA</strong> a realizar a
                  divulgação de sua imagem, isoladamente ou do lado dos demais
                  profissionais da <strong>CONTRATADA</strong>, de forma
                  gratuita em suas redes sociais e em demais mídias, respeitando
                  o Código de Ética dos Nutricionistas que não autoriza
                  divulgação de fotos íntimas de antes e depois de serviços
                  nutricionais. Para fins de demonstração de comparação de
                  resultados de procedimentos estéticos, nenhuma foto íntima
                  poderá ser publicada pela <strong>CONTRATADA</strong> sem
                  prévia autorização por escrito do(a)
                  <strong>CONTRATANTE</strong>.
                </strike>
              </p>
              <p class="mx-4" v-else>
                <strong><u>Cláusula 8ª:</u></strong> O(A)
                <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará
                a <strong>CONTRATADA</strong> a realizar a divulgação de sua
                imagem, isoladamente ou do lado dos demais profissionais da
                <strong>CONTRATADA</strong>, de forma gratuita em suas redes
                sociais e em demais mídias, respeitando o Código de Ética dos
                Nutricionistas que não autoriza divulgação de fotos íntimas de
                antes e depois de serviços nutricionais. Para fins de
                demonstração de comparação de resultados de procedimentos
                estéticos, nenhuma foto íntima poderá ser publicada pela
                <strong>CONTRATADA</strong> sem prévia autorização por escrito
                do(a) <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center mx-4">
              <v-radio-group row v-model="imagemNao">
                <v-radio label="Concordo" :value="false"></v-radio>
                <v-radio label="Não concordo" :value="true"></v-radio>
              </v-radio-group>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
                parte do(a) <strong>CONTRATANTE</strong>, após iniciado o
                programa ou pago, todos os valores pagos e a taxa administrativa
                (corresponde a 10% sobre o valor total do serviço) até a data de
                desistência não serão restituídos. Com relação aos valores
                pagos, apenas serão concedidos créditos correspondentes aos
                valores não usufruídas dentro do prazo para uso do serviço para
                uso posterior em sessões e/ou consultas
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela
                desistência do(a) <strong>CONTRATANTE</strong> poderá ser
                usufruído no prazo de 182 (cento e oitenta e dois) dias,
                contados da concessão desses créditos.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese
                haverá devolução de dinheiro após o início do tratamento,
                procedimento, consulta ou sessão, o que vier primeiro.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Caso o(a)
                <strong>CONTRATANTE</strong> desista antes do início da
                prestação do serviço, apenas ficará retido o valor da taxa
                administrativa de 10% sobre o valor do serviço total, devendo
                todo o resto ser restituído.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do
                serviço não existe mais a possibilidade de desistência e/ou
                restituição dos valores não gozados, pois a validade estará
                vencida, devendo o(a) <strong>CONTRATANTE</strong> pagar por
                todos os valores pendentes conforme o estipulado, caso ainda
                esteja em débito de pagamento, não sendo proibido a aplicação de
                juros nas condições previstas no presente contrato na clausula
                11ª, parágrafo 4º.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 10ª:</u></strong> A
                <strong>CONTRATADA</strong> poderá se recusar a prestar o
                serviço e/ou de rescindir o contrato quando:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Não preenchimento do questionário de saúde pré-consulta
                online;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">II – Não pagamento total ou parcial;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Realizar o pagamento por cheque sem fundos;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                IV – Não entrega dos exames solicitados antes do início do
                tratamento, quando esses forem pertinentes para a prestação do
                serviço;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Não prestar as informações solicitadas pela nutricionista
                responsável pelo acompanhamento nutricional;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                VI – Solicitar atividade não compreendida como serviço
                contratado ou disponibilizado pela <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
                previstas nos incisos da cláusula 10ª a
                <strong>CONTRATADA</strong> devolverá os valores das sessões e
                consultas pagas e não usufruídas pelo(a)
                <strong>CONTRATANTE</strong>, devendo aplicar o que couber no
                presente contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
                serviço não impossibilita de a CONTRATADA realizar a cobrança
                dos valores remanescentes ainda não pagos, em caso de aplicação
                de uma das hipóteses da cláusula 10ª.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO PAGAMENTO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 11:</u></strong> A
                <strong>CONTRATADA</strong> receberá o pagamento conforme está
                disposto no sistema (software).
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento
                são:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">I – Transferência bancária;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – PIX (forma de transferência bancária instantânea
                disponibilizado pelo Banco Central do Brasil);
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">III – Cartão de crédito;</p>
            </v-row>
            <v-row>
              <p class="mx-4">IV – Débito automático;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Cheque, desde que pago integralmente no ato da contratação
                ou antes da primeira consulta ou sessão, o que vier primeiro;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista
                integral ou da primeira parcela no cartão de crédito
                (independente de quantas vezes seja parcelado no cartão)
                autoriza o início do tratamento, consulta, sessão ou
                procedimento.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
                cheque apenas será confirmada quando sacado o cheque antes da
                primeira sessão ou consulta.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em
                que for sacado esteja sem fundos ou qualquer outro resultado que
                não seja a entrega do dinheiro correspondente pela instituição
                financeira a favor da CONTRATADA, não impede dessa tomar as
                medidas legais cabíveis.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento
                são proibidas de serem feitas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de
                pagamento incidirá juros mensais de 1% (um por cento) ao mês de
                atraso, mais multa na importância de 2% (dois por cento)
                corrigidos pelo IGP-M (Índice Geral de Preços Mercado), o qual é
                disponibilizado pela Fundação Getúlio Vargas. Na impossibilidade
                de aplicação do índice por qualquer motivo aplicar-se-á o índice
                inflacionário oficial do governo federal.
              </p></v-row
            >
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA VIGÊNCIA</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
                vigência por prazo indeterminado até o cumprimento de todas as
                suas obrigações.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá
                ter prazo determinado a depender do tratamento ou do pacote ou
                do programa aderido conforme estiver no sistema (software).
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
                instrumento de contrato, não extingue os direitos e obrigações
                que as partes tenham entre si e para com terceiros.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA PROTEÇÃO DE DADOS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 13ª:</u></strong>
                <strong>CONTRATANTE</strong> ao assinar o presente instrumento
                contratual autoriza de imediato que todos os seus dados que
                vierem a ser coletados antes e durante a prestação dos serviços
                pela <strong>CONTRATADA</strong>, sejam compartilhados com a
                empresa franqueadora (responsável pelo software que armazena
                todos os dados dos clientes), enquanto durar a prestação do
                serviço e após, cujo objetivo de garantir a qualidade do serviço
                prestado ao <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4 mt-8">
                <strong><u>CLÁUSULA 14ª:</u></strong>
                A <strong>CONTRATADA</strong>, por si e por seus colaboradores,
                obriga-se a atuar no presente Contrato em conformidade com a
                Legislação vigente sobre Proteção de Dados Pessoais e as
                determinações de órgãos reguladores/fiscalizadores sobre a
                matéria, em especial a Lei 13.709/2018, além das demais normas e
                políticas de proteção de dados de cada país onde houver qualquer
                tipo de tratamento dos dados dos clientes, o que inclui os dados
                dos clientes desta. No manuseio dos dados a
                <strong>CONTRATADA</strong> deverá:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (i) Tratar os dados pessoais a que tiver acesso em conformidade
                com estas cláusulas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (ii) Manter e utilizar medidas de segurança administrativas,
                técnicas e físicas apropriadas e suficientes para proteger a
                confidencialidade e integridade de todos os dados pessoais
                mantidos ou consultados/transmitidos eletronicamente, para
                garantir a proteção desses dados contra acesso não autorizado,
                destruição, uso, modificação, divulgação ou perda acidental ou
                indevida.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (iii) Acessar os dados dentro de seu escopo e na medida
                abrangida por sua permissão de acesso (autorização) e que os
                dados pessoais não podem ser lidos, copiados, modificados ou
                removidos sem autorização expressa e por escrito da
                <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (iv) Garantir, por si própria ou quaisquer de seus empregados,
                prepostos, sócios, diretores, representantes ou terceiros
                contratados, a confidencialidade dos dados processados,
                assegurando que todos os seus colaboradores prepostos, sócios,
                diretores, representantes ou terceiros contratados que lidam com
                os dados pessoais sob responsabilidade da
                <strong>CONTRATANTE</strong> assinaram Acordo de
                Confidencialidade e/ou possuem em seus contratos clausulas de
                confidencialidade com a <strong>CONTRATADA</strong>, bem como a
                manter quaisquer Dados Pessoais estritamente confidenciais e de
                não os utilizar para outros fins, com exceção da prestação de
                serviços à <strong>CONTRATANTE</strong>. Ainda, treinará e
                orientará a sua equipe sobre as disposições legais aplicáveis em
                relação à proteção de dados.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 15ª:</u></strong> Os dados pessoais não
                poderão ser revelados a terceiros, com exceção da prévia
                autorização por escrito da <strong>CONTRATANTE</strong>, quer
                direta ou indiretamente, seja mediante a distribuição de cópias,
                resumos, compilações, extratos, análises, estudos ou outros
                meios que contenham ou de outra forma reflitam referidas
                Informações, já ficando desde já autorizado o compartilhamento
                dos dados com a empresa franqueadora (responsável pelo software
                que armazena todos os dados dos clientes).
                <strong><u>Parágrafo único:</u></strong> Caso a
                <strong>CONTRATADA</strong> seja obrigada por determinação legal
                a fornecer dados pessoais a uma autoridade pública, deverá
                informar previamente a <strong>CONTRATANTE</strong> para que
                esta tome as medidas que julgar cabíveis.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA CLAUSULA DE ARBITRAGEM</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  ><u
                    >CLÁUSULA 16ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL
                    NOS TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO
                    CONTRATANTE: FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO,
                    QUE QUALQUER PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE
                    AS PARTES, QUE SEJA ORIGINADO OU RELACIONADO COM O PRESENTE
                    CONTRATO, SERÁ DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE
                    ARBITRAGEM, CONFORME O REGULAMENTO DE ARBITRAGEM DE QUALQUER
                    TRIBUNAL ARBITRAL, COM SEDE NO MUNICÍPIO DE BELÉM, CAPITAL
                    DO ESTADO DO PARÁ, POR INTERMÉDIO DE UM OU MAIS ÁRBITROS
                    NOMEADOS E A SENTENÇA POR ELE(S) PROLATADA PODERÁ SER
                    EXECUTADA EM QUALQUER JUÍZO QUE SOBRE ELA TENHA JURISDIÇÃO.
                    CUJO INTUITO DESSA CLAUSULA É A CELERIDADE E A SOLUÇÃO DE
                    TODOS OS PROBLEMAS ORIUNDOS DO PRESENTE CONTRATO.
                  </u></strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong
                  >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
                  CONTRATAÇÃO</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 17ª:</u></strong> O
                <strong>CONTRATANTE</strong> terá como vantagens durante o
                período da contratação:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Suporte nutricional até a data de término em horário
                comercial;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – Caso algum dos programas, previstos no anexo 1, seja
                contratado terá cumulativamente, além do previsto no inciso I,
                acesso a e-books (livros eletrônicos/digitais), acesso ao
                sistema (software) e vídeos aulas educativos.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 18ª:</u></strong> Condições para participar:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Efetuar o pagamento total do programa na 1ª consulta ou
                antes;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >II – Agendar dias fixos na semana para realização dos
                  tratamentos estéticos;</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Realizar todos os procedimentos e consultas até a data de
                término.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 19ª:</u></strong> Regras e observações
                importantes:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >I – Cada cliente deverá ter seu dia fixo para realizar os
                  tratamentos, o não comparecimento na hora marcada (falta)
                  implica em perda da sessão, pois o horário ficará bloqueado na
                  agenda sem a possibilidade de disponibilizá-lo para outro
                  cliente</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – Não será possível desmarcar e remarcar sessões toda semana
                e nem desmarcar somente no dia da sessão, o cliente deverá
                alterar os dias fixos com o máximo de antecedência para não
                prejudicar a agenda, os demais clientes e seu próprio
                tratamento.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Em caso de pausa do tratamento até a data de término, não
                existe garantia posterior de os mesmos dias fixos estejam
                disponíveis quando retornar.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                IV – As demais consultas, podem ser feitas por meio de
                videoconferência, de acordo com o código de ética do
                nutricionista. Desta forma, o não comparecimento físico ou
                on-line na data marcada prejudica a agenda de consultas, atrasa
                o tratamento e pode implicar em perda da consulta.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Cada consulta deve ser agendada com intervalo médio de 30
                (trinta) dias entre uma e outra.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                VI – Os cardápios e prescrições serão disponibilizados no
                software em até 3 dias úteis após a consulta e com intervalo
                mínimo de 30 dias entre um e outro.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DISPOSIÇÕES FINAIS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 20ª:</u></strong> Em observância do disposto
                no artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor),
                declara expressamente o <strong>CONTRATANTE</strong> que, em
                virtude de ter lido todas as cláusulas constantes deste
                contrato, está ciente de todas elas, aceitando-as expressamente
                e de poder acessar no sistema o presente contrato quando quiser,
                antes, durante e depois de finalizado o presente contrato.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>Anexo 1</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>1. Programa Clínico Funcional - R$900,00</strong><br />3
                consultas mais uma avaliação final
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>2. Programa de Definição Corporal - R$900,00</strong
                ><br />3 consultas mais uma avaliação final
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>3. Programa de Modulação Intestinal - R$990,00</strong
                ><br />3 consultas mais uma avaliação final
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4. Programa de Emagrecimento 12 (doze) semanas</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.1. Plano básico</strong><br />3 (três) consultas + 10
                (dez) semanas de pacote estético em 1 (uma) área: R$ 1.860,00
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.2. Plano Premium</strong><br />3 (três) consultas + 10
                (dez) semanas de pacote estético em 2 (duas) áreas: R$ 2.020,00
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.3. Plano VIP</strong><br />3 (três) consultas + 10
                (dez) semanas de pacote estético em 3 (três) áreas: R$
                2.380,00<br />
                *No plano VIP ainda possui a vantagem exclusiva de 1(uma) pausa
                no programa de até 4 semanas consecutivas em caso de férias,
                doença ou viagem.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>5. Pacotes estéticos adicionais</strong><br />
                5.1. 1 (um) pacote mensal para 1(uma) área: R$ 480,00 (R$
                120,00/área)<br />
                5.2. 1 (um) pacote mensal para 2(duas) áreas: R$ 720,00 (R$
                90,00/área)<br />
                5.3. 1 (um) pacote mensal para 3(três) áreas: R$ 960,00 (R$
                80,00/área)<br />
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>Anexo 2 - Tipos de tratamentos (não invasivos)</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                - Para redução de celulite, gordura localizada, flacidez,
                retenção hídrica, ou estímulo muscular para definição
                corporal.<br />
                - Utilização de aparelhos e/ou procedimentos manuais (massagem e
                drenagem)<br />
                - Utilização de ativos durante sessões e home care (opcional)<br />
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4">
                <strong
                  >DEFINIÇÃO DE ÁREA (1 área é equivalente a ½ folha a
                  A4)</strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left">Quantidade de áreas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="areaItem in areaItems" :key="areaItem.nome">
                      <td>{{ areaItem.nome }}</td>
                      <td>{{ areaItem.qtd }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </div>
          <div v-else-if="userInfo.contratoVersion == 4">
            <v-row>
              <p>
                De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
                informações pessoais e dados cadastrais necessários dentro do
                sistema (software). Do outro lado a
                <strong>CONTRATADA</strong> também com todas as informações
                necessárias de informação dentro do sistema (software). Ambos
                totalmente disponibilizados de maneira online em sítio
                eletrônico (website).<br />
                Pelo presente instrumento particular de prestação de serviços
                têm entre si justos e acordados quanto segue:
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO OBJETO DO CONTRATO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como
                objeto, a prestação de serviços personalizados de nutrição,
                estética e terapias complementares. <br /><strong
                  >CLÁUSULA 2ª:</strong
                >
                A <strong>CONTRATADA</strong> executará os serviços diretamente
                a(o) <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço
                ser executado em menores de 18 (dezoito) anos ou em pessoas
                maiores de 18 (dezoito) anos incapazes, a luz do direito civil
                brasileiro, deverá ter uma pessoa responsável, a qual apenas
                precisará ser informada no sistema.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da
                cláusula 2ª, não se aplica aos menores de 18 (dezoito) anos
                emancipados, o qual fica condicionado a prestação do serviço
                pela
                <strong>
                  <u
                    >apresentação de sentença judicial transitada em julgado
                    informando a sua emancipação ou certidão de casamento
                    (original ou cópia autenticada)</u
                  ></strong
                >, a qual deverá ser encaminhado digitalizada e encaminhada para
                o setor jurídico da <strong>CONTRATANTE</strong> para autorizar
                a execução dos serviços.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DOS SERVIÇOS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>CLÁUSULA 3ª:</strong> A
                <strong>CONTRATADA</strong> prestará um ou mais dos serviços
                previstos conforme o que estiver disponibilizado pelo “Anexo 1”
                e a <strong>CONTRATANTE</strong> tiver escolhido.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
                prestados pela <strong>CONTRATADA</strong> a(o)
                <strong>CONTRATANTE</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – <strong>Programa:</strong> entende-se como programa o
                conjunto de consultas com ou sem sessões estéticas,
                disponibilizados pelo sistema;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II –
                <strong>Pacote de sessões estéticas avulsas:</strong> entende-se
                como pacote de sessões exclusivamente estéticas de 4 (quatro)
                semanas disponibilizado pelo sistema;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III –
                <strong>Pacote de terapias complementares avulso:</strong>
                entende-se como pacote de sessões exclusivamente de terapias
                complementares disponibilizado pelo sistema;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                IV –
                <strong
                  >Consultas nutricionais, sessões estéticas avulsas e sessões
                  de terapias complementares avulsas:</strong
                >
                entende-se como uma única consulta ou única sessão, não dentro
                de qualquer programa ou pacote, contratando para serviço
                específico e pontual.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V –
                <strong
                  >Coleta para realização de exames nutri genéticos, de
                  hipersensibilidade alimentar e de perfil intestinal:</strong
                >
                na clínica será realizado apenas a coleta para encaminhar a
                amostra para laboratório especializado para a realização do
                exame.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
                prestados no estabelecimento da <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> As consultas e o
                acompanhamento nutricional poderão ser realizados, intercalado
                ou exclusivamente, por vídeo conferência quando solicitado
                pelo(a) <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> Todas as sessões de
                tratamento de estética serão realizadas exclusivamente no
                estabelecimento da <strong>CONTRATADA</strong>, não existe
                exceção.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Todos as sessões e
                consultas deverão ser previamente agendadas dentro do sistema
                disponibilizado online, o qual é um software, possível de ser
                acessado via computador ou aplicativo de telefone celular ambos
                conectados com a rede mundial de computadores (internet), pela
                <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo
                para que o(a) <strong>CONTRATANTE</strong> possa usufruir dos
                serviços contratados, sendo proibido a
                <strong>CONTRATADA</strong> prestar serviços para o(a)
                <strong>CONTRATANTE</strong> fora desse prazo:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – <strong>Programa:</strong> prazo máximo de 90 (noventa) dias
                corridas a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II –
                <strong
                  >Pacote estético e de terapia complementar avulso:</strong
                >
                prazo máximo de 30 (trinta) dias corridos a contar da data da
                contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – <strong>Consulta e/ou sessão avulsa:</strong> prazo máximo
                de 15 (quinze) dias corridos a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser
                prorrogado nas seguintes hipóteses:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Doença (CID) grave que impossibilita a continuidade da
                prestação do serviço;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">II – Doença infecto contagiosa;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Quando decretado lockdown pelo chefe do poder executivo
                municipal (prefeito), estadual (governador) ou federal/central
                (presidente, primeiro-ministro, monarca, imperador) onde as
                sessões/consultas presenciais de estéticas/nutricionais estão
                sendo realizadas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
                resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
                e/ou da consulta, salvo nas hipóteses de doença previstas nos
                incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
                contados da contratação o(a) <strong>CONTRATANTE</strong> não
                poderá mais usufruir dos serviços, salvo em uma das hipóteses
                previstas no parágrafo 1º, cláusula 5ª, e nas condições
                estabelecidas no presente contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
                incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
                <strong>CONTRATANTE</strong> utilizar dos serviços da
                <strong>CONTRATADA</strong> nem a concessão de créditos pelo não
                uso dentro do prazo, também não haverá a devolução de qualquer
                quantia.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
                tenha terminado de pagar, não impedirá a
                <strong>CONTRATADA</strong> de realizar a cobrança dos valores
                não pagos, por todas as medidas legais cabíveis.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no
                inciso primeiro, do parágrafo 1º, da cláusula 5ª, haverá a
                suspensão do tratamento até ter condições aptas para continuar a
                receber os serviços, devendo apresentar laudo médico com
                matrícula no CRM (Conselho Regional de Medicina) válida, sob
                pena de perder os dias em que esteve impossibilitada de
                continuar a usufruir dos serviços.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no
                inciso segundo do parágrafo 1º o(a)
                <strong>CONTRATANTE</strong> precisará reagendar o dia em que
                faltar por motivo de doença, devendo apresentar atestado médico
                com matrícula no CRM e assinado, nas mesmas condições do
                parágrafo 6º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no
                inciso quarto do parágrafo 1º o(a)
                <strong>CONTRATANTE</strong> terá a prorrogação automática pelo
                tempo em que o lockdown tiver sido decretado, nas seguintes
                condições:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – O serviço contratado não for considerado como serviço
                essencial previsto pelo decreto, caso seja considerado não
                ocorrerá a suspensão do serviço.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – A suspensão dos serviços pela decretação do lockdown
                somente ocorre se houver impedimento para o local onde o serviço
                é prestado, em situação em decretação em região diferente da
                qual o serviço é prestado não haverá prorrogação, devendo o
                <strong>CONTRATANTE</strong> usufruir conforme o que estiver
                estabelecido pelo presente Contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações
                serão no mesmo número de dias em que ocorreram as situações
                previstas nos incisos, do parágrafo 1º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as
                seguintes obrigações do(a) <strong>CONTRATANTE</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
                presente contrato;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                b) Realizar todos os exames solicitados pela
                <strong>CONTRATADA</strong> para a efetiva prestação do serviço
                com qualidade, caso não seja realizado será total
                responsabilidade do(a) <strong>CONTRATANTE</strong>;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
                abatimentos, descontos ou dilações de prazo para o pagamento e
                execução dos serviços, sem o prévio conhecimento e autorização
                da <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as
                seguintes obrigações da <strong>CONTRATADA</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                a) Atividades de seus funcionários serem exercidos com
                profissionalismo, respeito e zelo;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                b) Respeitar o Código de Ética dos Nutricionistas;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                c) Não revelar detalhes de suas atividades a terceiros, bem
                como, informações sobre seus clientes;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
                (software) com acesso enquanto durar o
                programa/pacote/sessão/consulta, após o término do presente
                contrato será excluído a qualquer momento sem qualquer aviso
                prévio;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
                (software) com acesso enquanto durar o pacote/sessão/consulta,
                após o término do presente contrato será excluído a qualquer
                momento sem qualquer aviso prévio;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                e) Iniciar a consulta, tratamento ou sessão conforme data
                agendada após o pagamento da primeira parcela ou do pagamento
                integral;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                f) Manter o sigilo das informações da
                <strong>CONTRATANTE</strong> conforme o que estiver disposto na
                lei de nº 13.709/2018 (Lei Geral de Proteção de Dados).
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO DIREITO DE IMAGEM</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4" v-if="imagemNao">
                <strike>
                  <strong><u>Cláusula 8ª:</u></strong> O(A)
                  <strong>CONTRATANTE</strong> ao assinar esse contrato
                  autorizará a <strong>CONTRATADA</strong> a realizar a
                  divulgação de sua imagem, isoladamente ou do lado dos demais
                  profissionais da <strong>CONTRATADA</strong>, de forma
                  gratuita em suas redes sociais e em demais mídias, respeitando
                  o Código de Ética dos Nutricionistas que não autoriza
                  divulgação de fotos íntimas de antes e depois de serviços
                  nutricionais. Para fins de demonstração de comparação de
                  resultados de procedimentos estéticos, nenhuma foto íntima
                  poderá ser publicada pela <strong>CONTRATADA</strong> sem
                  prévia autorização por escrito do(a)
                  <strong>CONTRATANTE</strong>.
                </strike>
              </p>
              <p class="mx-4" v-else>
                <strong><u>Cláusula 8ª:</u></strong> O(A)
                <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará
                a <strong>CONTRATADA</strong> a realizar a divulgação de sua
                imagem, isoladamente ou do lado dos demais profissionais da
                <strong>CONTRATADA</strong>, de forma gratuita em suas redes
                sociais e em demais mídias, respeitando o Código de Ética dos
                Nutricionistas que não autoriza divulgação de fotos íntimas de
                antes e depois de serviços nutricionais. Para fins de
                demonstração de comparação de resultados de procedimentos
                estéticos, nenhuma foto íntima poderá ser publicada pela
                <strong>CONTRATADA</strong> sem prévia autorização por escrito
                do(a) <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center mx-4">
              <v-radio-group row v-model="imagemNao">
                <v-radio label="Concordo" :value="false"></v-radio>
                <v-radio label="Não concordo" :value="true"></v-radio>
              </v-radio-group>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
                parte do(a) <strong>CONTRATANTE</strong>, após iniciado o
                programa ou pago, todos os valores pagos e a taxa administrativa
                (corresponde a 10% sobre o valor total do serviço) até a data de
                desistência não serão restituídos. Com relação aos valores
                pagos, apenas serão concedidos créditos correspondentes aos
                valores não usufruídas dentro do prazo para uso do serviço para
                uso posterior em sessões e/ou consultas
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela
                desistência do(a) <strong>CONTRATANTE</strong> poderá ser
                usufruído no prazo de 182 (cento e oitenta e dois) dias,
                contados da concessão desses créditos.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese
                haverá devolução de dinheiro após o início do tratamento,
                procedimento, consulta ou sessão, o que vier primeiro.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Caso o(a)
                <strong>CONTRATANTE</strong> desista antes do início da
                prestação do serviço, apenas ficará retido o valor da taxa
                administrativa de 10% sobre o valor do serviço total, devendo
                todo o resto ser restituído.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do
                serviço não existe mais a possibilidade de desistência e/ou
                restituição dos valores não gozados, pois a validade estará
                vencida, devendo o(a) <strong>CONTRATANTE</strong> pagar por
                todos os valores pendentes conforme o estipulado, caso ainda
                esteja em débito de pagamento, não sendo proibido a aplicação de
                juros nas condições previstas no presente contrato na clausula
                11ª, parágrafo 4º.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 10ª:</u></strong> A
                <strong>CONTRATADA</strong> poderá se recusar a prestar o
                serviço e/ou de rescindir o contrato quando:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Não preenchimento do questionário de saúde pré-consulta
                online;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">II – Não pagamento total ou parcial;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Realizar o pagamento por cheque sem fundos;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                IV – Não entrega dos exames solicitados antes do início do
                tratamento, quando esses forem pertinentes para a prestação do
                serviço;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Não prestar as informações solicitadas pela nutricionista
                responsável pelo acompanhamento nutricional;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                VI – Solicitar atividade não compreendida como serviço
                contratado ou disponibilizado pela <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
                previstas nos incisos da cláusula 10ª a
                <strong>CONTRATADA</strong> devolverá os valores das sessões e
                consultas pagas e não usufruídas pelo(a)
                <strong>CONTRATANTE</strong>, devendo aplicar o que couber no
                presente contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
                serviço não impossibilita de a CONTRATADA realizar a cobrança
                dos valores remanescentes ainda não pagos, em caso de aplicação
                de uma das hipóteses da cláusula 10ª.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO PAGAMENTO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 11:</u></strong> A
                <strong>CONTRATADA</strong> receberá o pagamento conforme está
                disposto no sistema (software).
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento
                são:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">I – Transferência bancária;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – PIX (forma de transferência bancária instantânea
                disponibilizado pelo Banco Central do Brasil);
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">III – Cartão de crédito;</p>
            </v-row>
            <v-row>
              <p class="mx-4">IV – Débito automático;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Cheque, desde que pago integralmente no ato da contratação
                ou antes da primeira consulta ou sessão, o que vier primeiro;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista
                integral ou da primeira parcela no cartão de crédito
                (independente de quantas vezes seja parcelado no cartão)
                autoriza o início do tratamento, consulta, sessão ou
                procedimento.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
                cheque apenas será confirmada quando sacado o cheque antes da
                primeira sessão ou consulta.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em
                que for sacado esteja sem fundos ou qualquer outro resultado que
                não seja a entrega do dinheiro correspondente pela instituição
                financeira a favor da CONTRATADA, não impede dessa tomar as
                medidas legais cabíveis.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento
                são proibidas de serem feitas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de
                pagamento incidirá juros mensais de 1% (um por cento) ao mês de
                atraso, mais multa na importância de 2% (dois por cento)
                corrigidos pelo IGP-M (Índice Geral de Preços Mercado), o qual é
                disponibilizado pela Fundação Getúlio Vargas. Na impossibilidade
                de aplicação do índice por qualquer motivo aplicar-se-á o índice
                inflacionário oficial do governo federal.
              </p></v-row
            >
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA VIGÊNCIA</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
                vigência por prazo indeterminado até o cumprimento de todas as
                suas obrigações.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá
                ter prazo determinado a depender do tratamento ou do pacote ou
                do programa aderido conforme estiver no sistema (software).
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
                instrumento de contrato, não extingue os direitos e obrigações
                que as partes tenham entre si e para com terceiros.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA PROTEÇÃO DE DADOS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 13ª:</u></strong>
                <strong>CONTRATANTE</strong> ao assinar o presente instrumento
                contratual autoriza de imediato que todos os seus dados que
                vierem a ser coletados antes e durante a prestação dos serviços
                pela <strong>CONTRATADA</strong>, sejam compartilhados com a
                empresa franqueadora (responsável pelo software que armazena
                todos os dados dos clientes), enquanto durar a prestação do
                serviço e após, cujo objetivo de garantir a qualidade do serviço
                prestado ao <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4 mt-8">
                <strong><u>CLÁUSULA 14ª:</u></strong>
                A <strong>CONTRATADA</strong>, por si e por seus colaboradores,
                obriga-se a atuar no presente Contrato em conformidade com a
                Legislação vigente sobre Proteção de Dados Pessoais e as
                determinações de órgãos reguladores/fiscalizadores sobre a
                matéria, em especial a Lei 13.709/2018, além das demais normas e
                políticas de proteção de dados de cada país onde houver qualquer
                tipo de tratamento dos dados dos clientes, o que inclui os dados
                dos clientes desta. No manuseio dos dados a
                <strong>CONTRATADA</strong> deverá:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (i) Tratar os dados pessoais a que tiver acesso em conformidade
                com estas cláusulas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (ii) Manter e utilizar medidas de segurança administrativas,
                técnicas e físicas apropriadas e suficientes para proteger a
                confidencialidade e integridade de todos os dados pessoais
                mantidos ou consultados/transmitidos eletronicamente, para
                garantir a proteção desses dados contra acesso não autorizado,
                destruição, uso, modificação, divulgação ou perda acidental ou
                indevida.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (iii) Acessar os dados dentro de seu escopo e na medida
                abrangida por sua permissão de acesso (autorização) e que os
                dados pessoais não podem ser lidos, copiados, modificados ou
                removidos sem autorização expressa e por escrito da
                <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (iv) Garantir, por si própria ou quaisquer de seus empregados,
                prepostos, sócios, diretores, representantes ou terceiros
                contratados, a confidencialidade dos dados processados,
                assegurando que todos os seus colaboradores prepostos, sócios,
                diretores, representantes ou terceiros contratados que lidam com
                os dados pessoais sob responsabilidade da
                <strong>CONTRATANTE</strong> assinaram Acordo de
                Confidencialidade e/ou possuem em seus contratos clausulas de
                confidencialidade com a <strong>CONTRATADA</strong>, bem como a
                manter quaisquer Dados Pessoais estritamente confidenciais e de
                não os utilizar para outros fins, com exceção da prestação de
                serviços à <strong>CONTRATANTE</strong>. Ainda, treinará e
                orientará a sua equipe sobre as disposições legais aplicáveis em
                relação à proteção de dados.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 15ª:</u></strong> Os dados pessoais não
                poderão ser revelados a terceiros, com exceção da prévia
                autorização por escrito da <strong>CONTRATANTE</strong>, quer
                direta ou indiretamente, seja mediante a distribuição de cópias,
                resumos, compilações, extratos, análises, estudos ou outros
                meios que contenham ou de outra forma reflitam referidas
                Informações, já ficando desde já autorizado o compartilhamento
                dos dados com a empresa franqueadora (responsável pelo software
                que armazena todos os dados dos clientes).
                <strong><u>Parágrafo único:</u></strong> Caso a
                <strong>CONTRATADA</strong> seja obrigada por determinação legal
                a fornecer dados pessoais a uma autoridade pública, deverá
                informar previamente a <strong>CONTRATANTE</strong> para que
                esta tome as medidas que julgar cabíveis.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA CLAUSULA DE ARBITRAGEM</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  ><u
                    >CLÁUSULA 16ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL
                    NOS TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO
                    CONTRATANTE: FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO,
                    QUE QUALQUER PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE
                    AS PARTES, QUE SEJA ORIGINADO OU RELACIONADO COM O PRESENTE
                    CONTRATO, SERÁ DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE
                    ARBITRAGEM, CONFORME O REGULAMENTO DE ARBITRAGEM DE QUALQUER
                    TRIBUNAL ARBITRAL, COM SEDE NO MUNICÍPIO DE BELÉM, CAPITAL
                    DO ESTADO DO PARÁ, POR INTERMÉDIO DE UM OU MAIS ÁRBITROS
                    NOMEADOS E A SENTENÇA POR ELE(S) PROLATADA PODERÁ SER
                    EXECUTADA EM QUALQUER JUÍZO QUE SOBRE ELA TENHA JURISDIÇÃO.
                    CUJO INTUITO DESSA CLAUSULA É A CELERIDADE E A SOLUÇÃO DE
                    TODOS OS PROBLEMAS ORIUNDOS DO PRESENTE CONTRATO.
                  </u></strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong
                  >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
                  CONTRATAÇÃO</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 17ª:</u></strong> O
                <strong>CONTRATANTE</strong> terá como vantagens durante o
                período da contratação:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Suporte nutricional até a data de término em horário
                comercial;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – Caso algum dos programas, previstos no anexo 1, seja
                contratado terá cumulativamente, além do previsto no inciso I,
                acesso a e-books (livros eletrônicos/digitais), acesso ao
                sistema (software) e vídeos aulas educativos.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 18ª:</u></strong> Condições para participar:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Efetuar o pagamento total do programa na 1ª consulta ou
                antes;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >II – Agendar dias fixos na semana para realização dos
                  tratamentos estéticos;</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Realizar todos os procedimentos e consultas até a data de
                término.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 19ª:</u></strong> Regras e observações
                importantes:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >I – Cada cliente deverá ter seu dia fixo para realizar os
                  tratamentos estéticos, o não comparecimento na hora marcada
                  (falta) implica em perda da sessão, pois o horário ficará
                  bloqueado na agenda sem a possibilidade de disponibilizá-lo
                  para outro cliente</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – Não será possível desmarcar e remarcar sessões toda semana
                e nem desmarcar somente no dia da sessão, o cliente deverá
                alterar os dias fixos com o máximo de antecedência para não
                prejudicar a agenda, os demais clientes e seu próprio
                tratamento.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Em caso de pausa do tratamento até a data de término, não
                existe garantia posterior de que os mesmos dias fixos estejam
                disponíveis quando retornar.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                IV – As demais consultas, podem ser feitas por meio de
                videoconferência, de acordo com o código de ética do
                nutricionista. Desta forma, o não comparecimento físico ou
                on-line na data marcada prejudica a agenda de consultas, atrasa
                o tratamento e pode implicar em perda da consulta.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Cada consulta deve ser agendada com intervalo médio de 30
                (trinta) dias entre uma e outra.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                VI – Os cardápios e prescrições serão disponibilizados no
                software em até 3 dias úteis após a consulta e com intervalo
                mínimo de 30 dias entre um e outro.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DISPOSIÇÕES FINAIS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 20ª:</u></strong> Em observância do disposto
                no artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor),
                declara expressamente o <strong>CONTRATANTE</strong> que, em
                virtude de ter lido todas as cláusulas constantes deste
                contrato, está ciente de todas elas, aceitando-as expressamente
                e de poder acessar no sistema o presente contrato quando quiser,
                antes, durante e depois de finalizado o presente contrato.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>Anexo 1</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>1. Programa Clínico Funcional - R$900,00</strong><br />3
                consultas mais uma avaliação final
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>2. Programa de Definição Corporal - R$900,00</strong
                ><br />3 consultas mais uma avaliação final
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>3. Programa de Modulação Intestinal - R$990,00</strong
                ><br />3 consultas mais uma avaliação final
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                Obs.1: Na escolha de qualquer um dos programas acima será
                possível adicionar pacotes estéticos ou de terapia
                complementares nas seguintes condições, desde que contratados
                até a primeira consulta do programa:
                <br />a) Inclusão de um pacote adicional por R$ 500,00; <br />b)
                Inclusão do segundo pacote por R$ 400,00; <br />c) Inclusão do
                terceiro pacote em diante cada um com o valor de R$ 300,00.
                <br />
                A partir do quarto pacote adicionado será cobrado a importância
                de R$ 300,00 reais por pacote adicionado, desde que contratados
                até a primeira consulta do programa.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4. Programa de Emagrecimento 12S (doze semanas)</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.1. Opção 1: </strong><br />3 (três) consultas + 1
                avaliação final + 1 livro: R$ 990,00
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.2. Opção 2: </strong><br />3 (três) consultas + 1
                avaliação final + 1 livro + 1 pacote estético de 4 (quatro)
                semanas em 1 (uma) área: R$ 1.490,00
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.3. Opção 3: </strong><br />3 (três) consultas + 1
                avaliação final + 1 livro + 2 (dois) pacotes de quatro semanas
                cada: R$ 1.890,00
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>4.4. Opção 4: </strong><br />3 (três) consultas + 1
                avaliação final + 1 livro + 3 (três) pacotes de quatro semanas
                cada: R$ 2.190,00 <br />
                Obs.2: A partir do quarto pacote adicionado será cobrado a
                importância de R$ 300,00 reais por pacote adicionado, desde que
                contratados até a primeira consulta do programa.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >5. Pacotes estéticos e de terapia complementares
                  avulsos</strong
                ><br />
                5.1. - 1 (um) pacote mensal para 1 (uma) área isolada (duas
                vezes por semana): R$ 720,00<br />
                5.2. - 1 (um) pacote mensal para 2 (duas) áreas pares (uma
                vez por semana): R$ 720,00<br />
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>6. Terapias complementares:</strong><br />
                a) Acupuntura<br />
                b) Auriculoterapia<br />
                c) Ventosaterapia<br />
                d) Massoterapia<br />
                e) Aromaterapia <br />
                f) Reflexologia podal<br />
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>Anexo 2 - Tipos de tratamentos (não invasivos)</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                - Para redução de celulite, gordura localizada, flacidez,
                retenção hídrica, ou estímulo muscular para definição
                corporal.<br />
                - Utilização de aparelhos e/ou procedimentos manuais (massagem e
                drenagem)<br />
                - Utilização de ativos durante sessões e home care (opcional)<br />
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4">
                <strong
                  >DEFINIÇÃO DE ÁREA ISOLADA: é equivalente a no máximo ½ folha
                  a A4</strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left">Quantidade de áreas</th>
                      <th class="text-left">Frequência</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="areaItem in areaItems1" :key="areaItem.nome">
                      <td>{{ areaItem.nome }}</td>
                      <td>{{ areaItem.qtd }}</td>
                      <td>{{ areaItem.freq }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4">
                <strong
                  >DEFINIÇÃO DE ÁREAS PARES: é equivalente a no máximo duas ½
                  folhas A4</strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left">Quantidade de áreas</th>
                      <th class="text-left">Frequência</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="areaItem in areaItems2" :key="areaItem.nome">
                      <td>{{ areaItem.nome }}</td>
                      <td>{{ areaItem.qtd }}</td>
                      <td>{{ areaItem.freq }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </div>
          <div v-else-if="userInfo.contratoVersion == 100">
            <v-row>
              <p>
                De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
                informações pessoais e dados cadastrais necessários dentro do
                sistema (software). Do outro lado a
                <strong>CONTRATADA</strong> também com todas as informações
                necessárias de informação dentro do sistema (software). Ambos
                totalmente disponibilizados de maneira online em sítio
                eletrônico (website).<br />
                Pelo presente instrumento particular de prestação de serviços
                têm entre si justos e acordados quanto segue:
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO OBJETO DO CONTRATO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como objeto, a prestação de serviços personalizados de estética e terapias complementares. <br /><strong
                  >CLÁUSULA 2ª:</strong
                >
                A <strong>CONTRATADA</strong> executará os serviços diretamente
                a(o) <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço
                ser executado em menores de 18 (dezoito) anos ou em pessoas
                maiores de 18 (dezoito) anos incapazes, a luz do direito civil
                brasileiro, deverá ter uma pessoa responsável, a qual apenas
                precisará ser informada no sistema.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da
                cláusula 2ª, não se aplica aos menores de 18 (dezoito) anos
                emancipados, o qual fica condicionado a prestação do serviço
                pela
                <strong>
                  <u
                    >apresentação de sentença judicial transitada em julgado
                    informando a sua emancipação ou certidão de casamento
                    (original ou cópia autenticada)</u
                  ></strong
                >, a qual deverá ser encaminhado digitalizada e encaminhada para
                o setor jurídico da <strong>CONTRATANTE</strong> para autorizar
                a execução dos serviços.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DOS SERVIÇOS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>CLÁUSULA 3ª:</strong> A
                <strong>CONTRATADA</strong> prestará um ou mais dos serviços
                previstos conforme o que estiver disponibilizado pelo “Anexo 1”
                e a <strong>CONTRATANTE</strong> tiver escolhido.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
                prestados pela <strong>CONTRATADA</strong> a(o)
                <strong>CONTRATANTE</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – <strong>Programa power shape 30 (trinta):</strong> programa estético promocional realizado em 30 (trinta) dias;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II –
                <strong>Pacote de sessões estéticas avulsas:</strong> entende-se
                como pacote de sessões exclusivamente estéticas de 4 (quatro)
                semanas disponibilizado pelo sistema;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III –
                <strong>Pacote de terapias complementares avulso:</strong>
                entende-se como pacote de sessões exclusivamente de terapias
                complementares disponibilizado pelo sistema;
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
                prestados no estabelecimento da <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> Todas as sessões de
                tratamento de estética serão realizadas exclusivamente no
                estabelecimento da <strong>CONTRATADA</strong>, não existe
                exceção.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> Todos as sessões deverão ser previamente agendadas dentro do sistema disponibilizado online, o qual é um software, possível de ser acessado via computador ou aplicativo de telefone celular ambos conectados com a rede mundial de computadores (internet), pela <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo
                para que o(a) <strong>CONTRATANTE</strong> possa usufruir dos
                serviços contratados, sendo proibido a
                <strong>CONTRATADA</strong> prestar serviços para o(a)
                <strong>CONTRATANTE</strong> fora desse prazo:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – <strong>Programa power shape 30 (trinta):</strong> prazo máximo de 30 (trinta) dias corridas a contar da data da primeira avaliação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II –
                <strong
                  >Pacote estético e de terapia complementar avulso:</strong
                >
                prazo máximo de 60 (sessenta) dias corridos a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – <strong>Sessão avulsa:</strong> prazo máximo
                de 15 (quinze) dias corridos a contar da data da contratação;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser
                prorrogado nas seguintes hipóteses:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Doença (CID) grave que impossibilita a continuidade da
                prestação do serviço;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">II – Doença infecto contagiosa;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Quando decretado lockdown pelo chefe do poder executivo
                municipal (prefeito), estadual (governador) ou federal/central
                (presidente, primeiro-ministro, monarca, imperador) onde as
                sessões/consultas presenciais de estéticas/nutricionais estão
                sendo realizadas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
                resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
                e/ou da consulta, salvo nas hipóteses de doença previstas nos
                incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
                contados da contratação o(a) <strong>CONTRATANTE</strong> não
                poderá mais usufruir dos serviços, salvo em uma das hipóteses
                previstas no parágrafo 1º, cláusula 5ª, e nas condições
                estabelecidas no presente contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
                incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
                <strong>CONTRATANTE</strong> utilizar dos serviços da
                <strong>CONTRATADA</strong> nem a concessão de créditos pelo não
                uso dentro do prazo, também não haverá a devolução de qualquer
                quantia.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
                tenha terminado de pagar, não impedirá a
                <strong>CONTRATADA</strong> de realizar a cobrança dos valores
                não pagos, por todas as medidas legais cabíveis.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no
                inciso primeiro, do parágrafo 1º, da cláusula 5ª, haverá a
                suspensão do tratamento até ter condições aptas para continuar a
                receber os serviços, devendo apresentar laudo médico com
                matrícula no CRM (Conselho Regional de Medicina) válida, sob
                pena de perder os dias em que esteve impossibilitada de
                continuar a usufruir dos serviços.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no
                inciso segundo do parágrafo 1º o(a)
                <strong>CONTRATANTE</strong> precisará reagendar o dia em que
                faltar por motivo de doença, devendo apresentar atestado médico
                com matrícula no CRM e assinado, nas mesmas condições do
                parágrafo 6º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no
                inciso quarto do parágrafo 1º o(a)
                <strong>CONTRATANTE</strong> terá a prorrogação automática pelo
                tempo em que o lockdown tiver sido decretado, nas seguintes
                condições:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – O serviço contratado não for considerado como serviço
                essencial previsto pelo decreto, caso seja considerado não
                ocorrerá a suspensão do serviço.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – A suspensão dos serviços pela decretação do lockdown
                somente ocorre se houver impedimento para o local onde o serviço
                é prestado, em situação em decretação em região diferente da
                qual o serviço é prestado não haverá prorrogação, devendo o
                <strong>CONTRATANTE</strong> usufruir conforme o que estiver
                estabelecido pelo presente Contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações
                serão no mesmo número de dias em que ocorreram as situações
                previstas nos incisos, do parágrafo 1º, da cláusula 5ª.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as
                seguintes obrigações do(a) <strong>CONTRATANTE</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
                presente contrato;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                b) Realizar todos os exames solicitados pela
                <strong>CONTRATADA</strong> para a efetiva prestação do serviço
                com qualidade, caso não seja realizado será total
                responsabilidade do(a) <strong>CONTRATANTE</strong>;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
                abatimentos, descontos ou dilações de prazo para o pagamento e
                execução dos serviços, sem o prévio conhecimento e autorização
                da <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as
                seguintes obrigações da <strong>CONTRATADA</strong>:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                a) Atividades de seus funcionários serem exercidos com
                profissionalismo, respeito e zelo;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                b) Não revelar detalhes de suas atividades a terceiros, bem
                como, informações sobre seus clientes;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                c) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
                (software) com acesso enquanto durar o
                pacote/sessão, após o término do presente
                contrato será excluído a qualquer momento sem qualquer aviso
                prévio;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                d) Iniciar tratamento ou sessão conforme data
                agendada após o pagamento da primeira parcela ou do pagamento
                integral;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                e) Manter o sigilo das informações da
                <strong>CONTRATANTE</strong> conforme o que estiver disposto na
                lei de nº 13.709/2018 (Lei Geral de Proteção de Dados).
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO DIREITO DE IMAGEM</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4" v-if="imagemNao">
                <strike>
                  <strong><u>Cláusula 8ª:</u></strong> O(A)
                  <strong>CONTRATANTE</strong> ao assinar esse contrato
                  autorizará a <strong>CONTRATADA</strong> a realizar a
                  divulgação de sua imagem, isoladamente ou do lado dos demais
                  profissionais da <strong>CONTRATADA</strong>, de forma
                  gratuita em suas redes sociais e em demais mídias. Para fins de demonstração de comparação de
                  resultados de procedimentos estéticos, nenhuma foto íntima
                  poderá ser publicada pela <strong>CONTRATADA</strong> sem
                  prévia autorização por escrito do(a)
                  <strong>CONTRATANTE</strong>.
                </strike>
              </p>
              <p class="mx-4" v-else>
                <strong><u>Cláusula 8ª:</u></strong> O(A)
                <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará
                a <strong>CONTRATADA</strong> a realizar a divulgação de sua
                imagem, isoladamente ou do lado dos demais profissionais da
                <strong>CONTRATADA</strong>, de forma gratuita em suas redes
                sociais e em demais mídias. Para fins de
                demonstração de comparação de resultados de procedimentos
                estéticos, nenhuma foto íntima poderá ser publicada pela
                <strong>CONTRATADA</strong> sem prévia autorização por escrito
                do(a) <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row class="d-flex justify-center mx-4">
              <v-radio-group row v-model="imagemNao">
                <v-radio label="Concordo" :value="false"></v-radio>
                <v-radio label="Não concordo" :value="true"></v-radio>
              </v-radio-group>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
                parte do(a) <strong>CONTRATANTE</strong>, após iniciado o programa ou pago, todos os valores pagos até a data de desistência não serão restituídos. Com relação aos valores pagos, apenas serão concedidos créditos correspondentes aos valores não usufruídas dentro do prazo para uso do serviço para uso posterior em sessões.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela desistência do(a) <strong>CONTRATANTE</strong> poderá ser usufruído no prazo de 60 (sessenta) dias, contados da concessão desses créditos.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese haverá devolução de dinheiro após o início do tratamento, procedimento ou sessão, o que vier primeiro.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Caso o(a) <strong>CONTRATANTE</strong> desista antes do início da prestação do serviço, apenas ficará retido o valor da taxa administrativa de 10% (dez por cento) sobre o valor do serviço total, devendo todo o resto ser restituído.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do serviço não existe mais a possibilidade de desistência e/ou restituição dos valores não gozados, pois a validade estará vencida, devendo o(a) <strong>CONTRATANTE</strong> pagar por todos os valores pendentes conforme o estipulado, caso ainda esteja em débito de pagamento, não sendo proibido a aplicação de juros nas condições previstas no presente contrato na cláusula 11ª, parágrafo 4º.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 10ª:</u></strong> A
                <strong>CONTRATADA</strong> poderá se recusar a prestar o
                serviço e/ou de rescindir o contrato quando:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Não preenchimento do questionário estético online;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">II – Não pagamento total ou parcial;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Realizar o pagamento por cheque sem fundos;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                IV – Não entrega dos exames solicitados antes do início do tratamento, quando esses forem pertinentes para a prestação do serviço;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Solicitar atividade não compreendida como serviço contratado ou disponibilizado pela <strong>CONTRATADA</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
                previstas nos incisos da cláusula 10ª a
                <strong>CONTRATADA</strong> devolverá os valores das sessões pagas e não usufruídas pelo(a)
                <strong>CONTRATANTE</strong>, devendo aplicar o que couber no
                presente contrato.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
                serviço não impossibilita de a CONTRATADA realizar a cobrança
                dos valores remanescentes ainda não pagos, em caso de aplicação
                de uma das hipóteses da cláusula 10ª.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DO PAGAMENTO</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 11:</u></strong> A
                <strong>CONTRATADA</strong> receberá o pagamento conforme está
                disposto no sistema (software).
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento
                são:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">I – Transferência bancária;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – PIX (forma de transferência bancária instantânea
                disponibilizado pelo Banco Central do Brasil);
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">III – Cartão de crédito;</p>
            </v-row>
            <v-row>
              <p class="mx-4">IV – Débito automático;</p>
            </v-row>
            <v-row>
              <p class="mx-4">
                V – Cheque, desde que pago integralmente no ato da contratação
                ou antes da primeira consulta ou sessão, o que vier primeiro;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista
                integral ou da primeira parcela no cartão de crédito
                (independente de quantas vezes seja parcelado no cartão)
                autoriza o início do tratamento, sessão ou
                procedimento.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
                cheque apenas será confirmada quando sacado o cheque antes da
                primeira sessão.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em
                que for sacado esteja sem fundos ou qualquer outro resultado que
                não seja a entrega do dinheiro correspondente pela instituição
                financeira a favor da CONTRATADA, não impede dessa tomar as
                medidas legais cabíveis.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento
                são proibidas de serem feitas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de
                pagamento incidirá juros mensais de 1% (um por cento) ao mês de
                atraso, mais multa na importância de 2% (dois por cento)
                corrigidos pelo IGP-M (Índice Geral de Preços Mercado), o qual é
                disponibilizado pela Fundação Getúlio Vargas. Na impossibilidade
                de aplicação do índice por qualquer motivo aplicar-se-á o índice
                inflacionário oficial do governo federal.
              </p></v-row
            >
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA VIGÊNCIA</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
                vigência por prazo indeterminado até o cumprimento de todas as
                suas obrigações.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá
                ter prazo determinado a depender do tratamento ou do pacote ou
                do programa aderido conforme estiver no sistema (software).
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
                instrumento de contrato, não extingue os direitos e obrigações
                que as partes tenham entre si e para com terceiros.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA PROTEÇÃO DE DADOS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 13ª:</u></strong>
                <strong>CONTRATANTE</strong> ao assinar o presente instrumento
                contratual autoriza de imediato que todos os seus dados que
                vierem a ser coletados antes e durante a prestação dos serviços
                pela <strong>CONTRATADA</strong>, sejam compartilhados com a
                empresa franqueadora (responsável pelo software que armazena
                todos os dados dos clientes), enquanto durar a prestação do
                serviço e após, cujo objetivo de garantir a qualidade do serviço
                prestado ao <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4 mt-8">
                <strong><u>CLÁUSULA 14ª:</u></strong>
                A <strong>CONTRATADA</strong>, por si e por seus colaboradores,
                obriga-se a atuar no presente Contrato em conformidade com a
                Legislação vigente sobre Proteção de Dados Pessoais e as
                determinações de órgãos reguladores/fiscalizadores sobre a
                matéria, em especial a Lei 13.709/2018, além das demais normas e
                políticas de proteção de dados de cada país onde houver qualquer
                tipo de tratamento dos dados dos clientes, o que inclui os dados
                dos clientes desta. No manuseio dos dados a
                <strong>CONTRATADA</strong> deverá:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (i) Tratar os dados pessoais a que tiver acesso em conformidade
                com estas cláusulas.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (ii) Manter e utilizar medidas de segurança administrativas,
                técnicas e físicas apropriadas e suficientes para proteger a
                confidencialidade e integridade de todos os dados pessoais
                mantidos ou consultados/transmitidos eletronicamente, para
                garantir a proteção desses dados contra acesso não autorizado,
                destruição, uso, modificação, divulgação ou perda acidental ou
                indevida.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (iii) Acessar os dados dentro de seu escopo e na medida
                abrangida por sua permissão de acesso (autorização) e que os
                dados pessoais não podem ser lidos, copiados, modificados ou
                removidos sem autorização expressa e por escrito da
                <strong>CONTRATANTE</strong>.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                (iv) Garantir, por si própria ou quaisquer de seus empregados,
                prepostos, sócios, diretores, representantes ou terceiros
                contratados, a confidencialidade dos dados processados,
                assegurando que todos os seus colaboradores prepostos, sócios,
                diretores, representantes ou terceiros contratados que lidam com
                os dados pessoais sob responsabilidade da
                <strong>CONTRATANTE</strong> assinaram Acordo de
                Confidencialidade e/ou possuem em seus contratos clausulas de
                confidencialidade com a <strong>CONTRATADA</strong>, bem como a
                manter quaisquer Dados Pessoais estritamente confidenciais e de
                não os utilizar para outros fins, com exceção da prestação de
                serviços à <strong>CONTRATANTE</strong>. Ainda, treinará e
                orientará a sua equipe sobre as disposições legais aplicáveis em
                relação à proteção de dados.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 15ª:</u></strong> Os dados pessoais não
                poderão ser revelados a terceiros, com exceção da prévia
                autorização por escrito da <strong>CONTRATANTE</strong>, quer
                direta ou indiretamente, seja mediante a distribuição de cópias,
                resumos, compilações, extratos, análises, estudos ou outros
                meios que contenham ou de outra forma reflitam referidas
                Informações, já ficando desde já autorizado o compartilhamento
                dos dados com a empresa franqueadora (responsável pelo software
                que armazena todos os dados dos clientes).
                <strong><u>Parágrafo único:</u></strong> Caso a
                <strong>CONTRATADA</strong> seja obrigada por determinação legal
                a fornecer dados pessoais a uma autoridade pública, deverá
                informar previamente a <strong>CONTRATANTE</strong> para que
                esta tome as medidas que julgar cabíveis.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DA CLAUSULA DE ARBITRAGEM</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  ><u
                    >CLÁUSULA 16ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL
                    NOS TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO
                    CONTRATANTE: FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO,
                    QUE QUALQUER PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE
                    AS PARTES, QUE SEJA ORIGINADO OU RELACIONADO COM O PRESENTE
                    CONTRATO, SERÁ DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE
                    ARBITRAGEM, CONFORME O REGULAMENTO DE ARBITRAGEM DE QUALQUER
                    TRIBUNAL ARBITRAL, COM SEDE NO MUNICÍPIO DE BELÉM, CAPITAL
                    DO ESTADO DO PARÁ, POR INTERMÉDIO DE UM OU MAIS ÁRBITROS
                    NOMEADOS E A SENTENÇA POR ELE(S) PROLATADA PODERÁ SER
                    EXECUTADA EM QUALQUER JUÍZO QUE SOBRE ELA TENHA JURISDIÇÃO.
                    CUJO INTUITO DESSA CLAUSULA É A CELERIDADE E A SOLUÇÃO DE
                    TODOS OS PROBLEMAS ORIUNDOS DO PRESENTE CONTRATO.
                  </u></strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong
                  >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
                  CONTRATAÇÃO</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 17ª:</u></strong> O
                <strong>CONTRATANTE</strong> terá acesso ao clube de vantagens durante o
                período da contratação:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 18ª:</u></strong> Condições para participar:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                I – Efetuar o pagamento total do programa na 1ª sessão ou
                antes;
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >II – Agendar dias fixos na semana para realização dos
                  tratamentos estéticos;</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Realizar todos os procedimentos e sessões até a data de
                término.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 19ª:</u></strong> Regras e observações
                importantes:
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >I – Cada cliente deverá ter seu dia fixo para realizar os
                  tratamentos estéticos, o não comparecimento na hora marcada
                  (falta) implica em perda da sessão, pois o horário ficará
                  bloqueado na agenda sem a possibilidade de disponibilizá-lo
                  para outro cliente</strong
                >
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                II – Não será possível desmarcar e remarcar sessões toda semana
                e nem desmarcar somente no dia da sessão, o cliente deverá
                alterar os dias fixos com o máximo de antecedência para não
                prejudicar a agenda, os demais clientes e seu próprio
                tratamento.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                III – Em caso de pausa do tratamento até a data de término, não
                existe garantia posterior de que os mesmos dias fixos estejam
                disponíveis quando retornar.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>DISPOSIÇÕES FINAIS</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong><u>CLÁUSULA 20ª:</u></strong> Em observância do disposto
                no artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor),
                declara expressamente o <strong>CONTRATANTE</strong> que, em
                virtude de ter lido todas as cláusulas constantes deste
                contrato, está ciente de todas elas, aceitando-as expressamente
                e de poder acessar no sistema o presente contrato quando quiser,
                antes, durante e depois de finalizado o presente contrato.
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>Anexo 1</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>1. Programa power shape 30 (trinta) – R$ 550,00</strong><br />	1 (uma) avaliação inicial com bioimpedância mais 4 (quatro) semanas de 1 (um) pacote ou mais de tratamentos estéticos e uma avaliação final com bioimpedância até o último dia do programa. 
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                Obs.1: O programa power shape 30 (trinta) deve ser todo dentro do prazo de 30 (trinta) dias contados da data da primeira avaliação.
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong
                  >5. Pacotes estéticos e de terapia complementares
                  avulsos</strong
                ><br />
                2.1. - 1 (um) pacote mensal para 1 (uma) área isolada (duas vezes por semana): R$ 720,00 <br />
                2.2. - 1 (um) pacote mensal para duas 2 (duas) áreas pares (uma vez por semana): R$ 720,00<br />
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                <strong>3. Terapias complementares:</strong><br />
                a) Acupuntura<br />
                b) Auriculoterapia<br />
                c) Ventosaterapia<br />
                d) Massoterapia<br />
                e) Aromaterapia <br />
                f) Reflexologia podal<br />
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4 mt-8">
                <strong>Anexo 2 - Tipos de tratamentos (não invasivos)</strong>
              </p>
            </v-row>
            <v-row>
              <p class="mx-4">
                - Para redução de celulite, gordura localizada, flacidez,
                retenção hídrica, ou estímulo muscular para definição
                corporal.<br />
                - Utilização de aparelhos e/ou procedimentos manuais (massagem e
                drenagem)<br />
                - Utilização de ativos durante sessões e home care (opcional)<br />
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4">
                <strong
                  >DEFINIÇÃO DE ÁREA ISOLADA: é equivalente a no máximo ½ folha
                  a A4</strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left">Quantidade de áreas</th>
                      <th class="text-left">Frequência</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="areaItem in areaItems1" :key="areaItem.nome">
                      <td>{{ areaItem.nome }}</td>
                      <td>{{ areaItem.qtd }}</td>
                      <td>{{ areaItem.freq }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="mx-4">
                <strong
                  >DEFINIÇÃO DE ÁREAS PARES: é equivalente a no máximo duas ½
                  folhas A4</strong
                >
              </p>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left">Quantidade de áreas</th>
                      <th class="text-left">Frequência</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="areaItem in areaItems2" :key="areaItem.nome">
                      <td>{{ areaItem.nome }}</td>
                      <td>{{ areaItem.qtd }}</td>
                      <td>{{ areaItem.freq }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <p class="mx-4">
              De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
              informações pessoais e dados cadastrais necessários dentro do
              sistema (software). Do outro lado a
              <strong>CONTRATADA</strong> também com todas as informações
              necessárias de informação dentro do sistema (software). Ambos
              totalmente disponibilizados de maneira online em sítio eletrônico
              (website).<br />
              Pelo presente instrumento particular de prestação de serviços têm
              entre si justos e acordados quanto segue:
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO OBJETO DO CONTRATO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como
              objeto, a prestação de serviços de nutrição e estética
              personalizados. <br /><strong>CLÁUSULA 2ª:</strong> A
              <strong>CONTRATADA</strong> executará os serviços diretamente a(o)
              <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço ser
              executado em menores de 18 (dezoito) anos ou em pessoas maiores de
              18 (dezoito) anos incapazes, a luz do direito civil brasileiro,
              deverá ter uma pessoa responsável, a qual apenas precisará ser
              informada no sistema.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da clausula
              2ª, não se aplica aos menores de 18 (dezoito) anos emancipados, o
              qual fica condicionado a prestação do serviço pela
              <strong>
                <u
                  >apresentação de sentença judicial transitada em julgado
                  informando a sua emancipação ou certidão de casamento
                  (original ou cópia autenticada)</u
                ></strong
              >, a qual deverá ser encaminhado digitalizada e encaminhada para o
              setor jurídico da <strong>CONTRATANTE</strong> para autorizar a
              execução dos serviços.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DOS SERVIÇOS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 3ª:</strong> A
              <strong>CONTRATADA</strong> prestará um ou mais dos serviços
              previstos conforme o que estiver disponibilizado pelo “Anexo 1” e
              a <strong>CONTRATANTE</strong> tiver escolhido.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
              prestados pela <strong>CONTRATADA</strong> a(o)
              <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Pacote:</strong> entende-se como pacote conjunto de
              sessões e/ou consultas disponibilizados pelo sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – <strong>Pacote estético avulso:</strong> entende-se como
              pacote de sessões exclusivamente estéticas disponibilizado pelo
              sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III –
              <strong
                >Consultas nutricionais e sessões estéticas avulsas:</strong
              >
              entende-se como não dentro de qualquer pacote, contratando para
              serviço específico e pontual.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
              prestados no estabelecimento da <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> As consultas e o
              acompanhamento nutricional poderão ser realizados, intercalado ou
              exclusivamente, por vídeo conferência quando solicitado pelo(a)
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Todas as sessões de
              tratamento de estética serão realizadas exclusivamente no
              estabelecimento da <strong>CONTRATADA</strong>, não existe
              exceção.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Todos as sessões e consultas
              deverão ser previamente agendadas dentro do sistema
              disponibilizado online, o qual é um software, possível de ser
              acessado via computador ou aplicativo de telefone celular ambos
              conectados com a rede mundial de computadores (internet), pela
              <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo para
              que o(a) <strong>CONTRATANTE</strong> possa usufruir dos serviços
              contratados, sendo proibido a <strong>CONTRATADA</strong> prestar
              serviços para o(a) <strong>CONTRATANTE</strong> fora desse prazo:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Pacote:</strong> prazo máximo de 90 (noventa) dias
              corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – <strong>Pacote estético avulso:</strong> prazo máximo de 30
              (trinta) dias corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – <strong>Consulta e/ou sessão avulsa:</strong> prazo máximo
              de 15 (quinze) dias corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser
              prorrogado nas seguintes hipóteses:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Doença (CID) grave que impossibilita a continuidade da
              prestação do serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Doença infecto contagiosa;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Quando decretado lockdown pelo chefe do poder executivo
              municipal (prefeito), estadual (governador) ou federal/central
              (presidente, primeiro-ministro, monarca, imperador) onde as
              sessões/consultas presenciais de estéticas/nutricionais estão
              sendo realizadas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
              resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
              e/ou da consulta, salvo nas hipóteses de doença previstas nos
              incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
              contados da contratação o(a) <strong>CONTRATANTE</strong> não
              poderá mais usufruir dos serviços, salvo em uma das hipóteses
              previstas no parágrafo 1º, cláusula 5ª, e nas condições
              estabelecidas no presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
              incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
              <strong>CONTRATANTE</strong> utilizar dos serviços da
              <strong>CONTRATADA</strong> nem a concessão de créditos pelo não
              uso dentro do prazo, também não haverá a devolução de qualquer
              quantia.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
              tenha terminado de pagar, não impedirá a
              <strong>CONTRATADA</strong> de realizar a cobrança dos valores não
              pagos, por todas as medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no
              inciso primeiro, do parágrafo 1º, da cláusula 5ª, haverá a
              suspensão do tratamento até ter condições aptas para continuar a
              receber os serviços, devendo apresentar laudo médico com matrícula
              no CRM (Conselho Regional de Medicina) válida, sob pena de perder
              os dias em que esteve impossibilitada de continuar a usufruir dos
              serviços.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no
              inciso segundo do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> precisará reagendar o dia em que
              faltar por motivo de doença, devendo apresentar atestado médico
              com matrícula no CRM e assinado, nas mesmas condições do parágrafo
              6º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no
              inciso quarto do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> terá a prorrogação automática pelo
              tempo em que o lockdown tiver sido decretado, nas seguintes
              condições:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – O serviço contratado não for considerado como serviço
              essencial previsto pelo decreto, caso seja considerado não
              ocorrerá a suspensão do serviço.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – A suspensão dos serviços pela decretação do lockdown somente
              ocorre se houver impedimento para o local onde o serviço é
              prestado, em situação em decretação em região diferente da qual o
              serviço é prestado não haverá prorrogação, devendo o
              <strong>CONTRATANTE</strong> usufruir conforme o que estiver
              estabelecido pelo presente Contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações serão
              no mesmo número de dias em que ocorreram as situações previstas
              nos incisos, do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as
              seguintes obrigações do(a) <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
              presente contrato;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Realizar todos os exames solicitados pela
              <strong>CONTRATADA</strong> para a efetiva prestação do serviço
              com qualidade, caso não seja realizado será total responsabilidade
              do(a) <strong>CONTRATANTE</strong>;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
              abatimentos, descontos ou dilações de prazo para o pagamento e
              execução dos serviços, sem o prévio conhecimento e autorização da
              <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as
              seguintes obrigações da <strong>CONTRATADA</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Atividades de seus funcionários serem exercidos com
              profissionalismo, respeito e zelo;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Respeitar o Código de Ética dos Nutricionistas;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Não revelar detalhes de suas atividades a terceiros, bem como,
              informações sobre seus clientes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
              (software) com acesso enquanto durar o pacote/sessão/consulta,
              após o término do presente contrato será excluído a qualquer
              momento sem qualquer aviso prévio;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              e) Iniciar a consulta, tratamento ou sessão conforme data agendada
              após o pagamento da primeira parcela ou do pagamento integral;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              f) Manter o sigilo das informações da
              <strong>CONTRATANTE</strong> conforme o que estiver disposto na
              lei de nº 13.709/2018 (Lei Geral de Proteção de Dados).
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO DIREITO DE IMAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4" v-if="imagemNao">
              <strike>
                <strong><u>Cláusula 8ª:</u></strong> O(A)
                <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará
                a
                <strong>CONTRATADA</strong>
                a realizar a divulgação de sua imagem de forma gratuita em suas
                redes sociais e em demais mídias, respeitando o Código de Ética
                dos Nutricionistas.
              </strike>
            </p>
            <p class="mx-4" v-else>
              <strong><u>Cláusula 8ª:</u></strong> O(A)
              <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará a
              <strong>CONTRATADA</strong>
              a realizar a divulgação de sua imagem de forma gratuita em suas
              redes sociais e em demais mídias, respeitando o Código de Ética
              dos Nutricionistas.
            </p>
          </v-row>
          <v-row class="d-flex justify-center mx-4">
            <v-checkbox
              v-model="imagemNao"
              label="Não autorizo a divulgação da minha imagem."
            ></v-checkbox>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
              parte do(a) <strong>CONTRATANTE</strong>, após iniciado o programa
              ou pago, todos os valores pagos e a taxa administrativa
              (corresponde a 10% sobre o valor total do serviço) até a data de
              desistência não serão restituídos. Com relação aos valores pagos,
              apenas serão concedidos créditos correspondentes aos valores não
              usufruídas dentro do prazo para uso do serviço para uso posterior
              em sessões e/ou consultas
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela
              desistência do(a) <strong>CONTRATANTE</strong> poderá ser
              usufruído no prazo de 182 (cento e oitenta e dois) dias, contados
              da concessão desses créditos.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese
              haverá devolução de dinheiro após o início do tratamento,
              procedimento, consulta ou sessão, o que vier primeiro.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o(a)
              <strong>CONTRATANTE</strong> desista antes do início da prestação
              do serviço, apenas ficará retido o valor da taxa administrativa de
              10% sobre o valor do serviço total, devendo todo o resto ser
              restituído.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do
              serviço não existe mais a possibilidade de desistência e/ou
              restituição dos valores não gozados, pois a validade estará
              vencida, devendo o(a) <strong>CONTRATANTE</strong> pagar por todos
              os valores pendentes conforme o estipulado, caso ainda esteja em
              débito de pagamento, não sendo proibido a aplicação de juros nas
              condições previstas no presente contrato na cláusula 11ª,
              parágrafo 4º.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 10ª:</u></strong> A
              <strong>CONTRATADA</strong> poderá se recusar a prestar o serviço
              e/ou de rescindir o contrato quando:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Não preenchimento do questionário de saúde pré-consulta
              online;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Não pagamento total ou parcial;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar o pagamento por cheque sem fundos;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV – Não entrega dos exames solicitados antes do início do
              tratamento, quando esses forem pertinentes para a prestação do
              serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Não prestar as informações solicitadas pela nutricionista
              responsável pelo acompanhamento nutricional;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              VI – Solicitar atividade não compreendida como serviço contratado
              ou disponibilizado pela <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
              previstas nos incisos da cláusula 10ª a
              <strong>CONTRATADA</strong> devolverá os valores das sessões e
              consultas pagas e não usufruídas pelo(a)
              <strong>CONTRATANTE</strong>, devendo aplicar o que couber no
              presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
              serviço não impossibilita de a CONTRATADA realizar a cobrança dos
              valores remanescentes ainda não pagos, em caso de aplicação de uma
              das hipóteses da cláusula 10ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO PAGAMENTO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 11:</u></strong> A
              <strong>CONTRATADA</strong> receberá o pagamento conforme está
              disposto no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento são:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">I – Transferência bancária;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – PIX (forma de transferência bancária instantânea
              disponibilizado pelo Banco Central do Brasil);
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">III – Cartão de crédito;</p>
          </v-row>
          <v-row>
            <p class="mx-4">IV – Débito automático;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Cheque, desde que pago integralmente no ato da contratação ou
              antes da primeira consulta ou sessão, o que vier primeiro;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista integral
              ou da primeira parcela no cartão de crédito (independente de
              quantas vezes seja parcelado no cartão) autoriza o início do
              tratamento, consulta, sessão ou procedimento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
              cheque apenas será confirmada quando sacado o cheque antes da
              primeira sessão ou consulta.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em
              que for sacado esteja sem fundos ou qualquer outro resultado que
              não seja a entrega do dinheiro correspondente pela instituição
              financeira a favor da CONTRATADA, não impede dessa tomar as
              medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento
              são proibidas de serem feitas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de
              pagamento incidirá juros mensais de 1% (um por cento) ao mês de
              atraso, mais multa na importância de 2% (dois por cento)
              corrigidos pelo IGP-M (Índice Geral de Preços Mercado), o qual é
              disponibilizado pela Fundação Getúlio Vargas. Na impossibilidade
              de aplicação do índice por qualquer motivo aplicar-se-á o índice
              inflacionário oficial do governo federal.
            </p></v-row
          >

          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA VIGÊNCIA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
              vigência por prazo indeterminado até o cumprimento de todas as
              suas obrigações.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá
              ter prazo determinado a depender do tratamento ou do pacote ou do
              programa aderido conforme estiver no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
              instrumento de contrato, não extingue os direitos e obrigações que
              as partes tenham entre si e para com terceiros.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA CLAUSULA DE ARBITRAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                ><u
                  >CLÁUSULA 13ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL
                  NOS TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO
                  CONTRATANTE: FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO,
                  QUE QUALQUER PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE AS
                  PARTES, QUE SEJA ORIGINADO OU RELACIONADO COM O PRESENTE
                  CONTRATO, SERÁ DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE
                  ARBITRAGEM, CONFORME O REGULAMENTO DE ARBITRAGEM DE QUALQUER
                  TRIBUNAL ARBITRAL, COM SEDE NO MUNICÍPIO DE BELÉM, CAPITAL DO
                  ESTADO DO PARÁ, POR INTERMÉDIO DE UM OU MAIS ÁRBITROS NOMEADOS
                  E A SENTENÇA POR ELE(S) PROLATADA PODERÁ SER EXECUTADA EM
                  QUALQUER JUÍZO QUE SOBRE ELA TENHA JURISDIÇÃO. CUJO INTUITO
                  DESSA CLAUSULA É A CELERIDADE E A SOLUÇÃO DE TODOS OS
                  PROBLEMAS ORIUNDOS DO PRESENTE CONTRATO.
                </u></strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong
                >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
                CONTRATAÇÃO</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 14ª:</u></strong> O
              <strong>CONTRATANTE</strong> terá como vantagens durante o período
              da contratação:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Suporte nutricional até a data de término em horário
              comercial;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – Acesso a e-books (livros eletrônicos/digitais), acesso ao
              sistema (software) e vídeos aulas educativos.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 15ª:</u></strong> Condições para participar:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Efetuar o pagamento total do programa na 1ª consulta ou antes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >II – Agendar dias fixos na semana para realização dos
                tratamentos estéticos;</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar todos os procedimentos e consultas até a data de
              término.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 16ª:</u></strong> Regras e observações
              importantes:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >I – Cada cliente deverá ter seu dia fixo para realizar os
                tratamentos, o não comparecimento na hora marcada (falta)
                implica em perda da sessão, pois o horário ficará bloqueado na
                agenda sem a possibilidade de disponibilizá-lo para outro
                cliente</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – Não será possível desmarcar e remarcar sessões toda semana e
              nem desmarcar somente no dia da sessão, o cliente deverá alterar
              os dias fixos com o máximo de antecedência para não prejudicar a
              agenda, os demais clientes e seu próprio tratamento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Em caso de pausa do tratamento até a data de término, não
              existe garantia posterior de os mesmos dias fixos estejam
              disponíveis quando retornar.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV – As demais consultas, podem ser feitas por meio de
              videoconferência, de acordo com o código de ética do
              nutricionista. Desta forma, o não comparecimento físico ou on-line
              na data marcada prejudica a agenda de consultas, atrasa o
              tratamento e pode implicar em perda da consulta.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Cada consulta deve ser agendada com intervalo médio de 30
              (trinta) dias entre uma e outra.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              VI – Os cardápios e prescrições serão disponibilizados no software
              em até 3 dias úteis após a consulta e com intervalo mínimo de 30
              dias entre um e outro.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 17ª:</u></strong> Em observância do disposto
              no artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor),
              declara expressamente o <strong>CONTRATANTE</strong> que, em
              virtude de ter lido todas as cláusulas constantes deste contrato,
              está ciente de todas elas, aceitando-as expressamente e de poder
              acessar no sistema o presente contrato quando quiser, antes,
              durante e depois de finalizado o presente contrato.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>Anexo 1</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>OPÇÃO 1:</strong> 3 consultas nutricionais + videoaulas +
              90 dias de suporte nutricional = 3X de R$330,00, total R$990,00 à
              vista.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>OPÇÃO 2:</strong> 3 consultas + videoaulas + 90 dias de
              suporte nutricional + 1 pacote estético = 4X de R$395,00 ou
              R$1.580,00 à vista.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>OPÇÃO 3:</strong> 3 consultas + videoaulas + 90 dias de
              suporte nutricional + 2 pacotes estéticos = 5X de R$392,00 ou
              R$1.960,00 à vista.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>OPÇÃO 4:</strong> 3 consultas + videoaulas + 90 dias de
              suporte nutricional + 3 pacotes estéticos = 6X de R$390,00 ou
              R$2340,00 à vista.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >De acordo com a avaliação clínica, podem ser escolhidos o(s)
                tratamento(s) estético(s) abaixo:</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >• Pacote Triplo (HYBRIUS) Gordura/Celulite/ Flacidez =
              </strong>
              R$780,00/ 1x por semana por 4 semanas
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >• Pacote de Tonificação Abdominal (Corrente russa) =
              </strong>
              R$780,00/ 2x por semana por 4 semanas
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >• Pacote de Gordura Visceral / Esteatose Hepática (NARL) =
              </strong>
              R$780,00/ 2x por semana por 4 semanas
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>• Pacotes Manuais: </strong>
              Drenagem Linfática ou Massagem Modeladora ou Massagem Antiestresse
              ou Tratamento de Terapias Naturais (ventosas/ auriculoterapia/
              reflexologia podal/ aromaterapia) = R$780,00/ 2x por semana por 4
              semanas
            </p>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <!-- <v-checkbox
            v-model="contratoChecked"
            label="Confirmo que li e concordo com os termos"
          ></v-checkbox> -->
          <v-row>
            Ao prosseguir, confirmo que li e concordo com os termos
          </v-row>
          <!-- <v-btn
            color="#c5d13f"
            :disabled="!contratoChecked"
            @click="sendContractConcordo"
            class="mx-4"
          >
            Prosseguir
          </v-btn> -->
          <v-row class="d-flex justify-center">
            <v-btn disabled v-if="sendingContractDialog" class="mx-4">
              <v-progress-circular
                :size="20"
                :width="3"
                color="#c5d13f"
                indeterminate
              ></v-progress-circular>
            </v-btn>
            <v-btn
              color="#c5d13f"
              @click="sendContractConcordo"
              class="mx-4"
              v-else
            >
              Prosseguir
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="evalDialog"
      persistent
      scrollable
      max-width="600"
      width="90%"
    >
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Avalia a sua experiência no Clube da Nutricionista</v-card-title
        >
        <v-card-text>
          <v-row class="d-flex justify-center black--text">
            Avalie os profissionais que te acompanharam
          </v-row>
          <v-row class="d-flex justify-center" v-if="warnRatingNutri">
            <v-alert type="error" dense>
              Avalie o atendimento da sua nutricionista!
            </v-alert>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex align-center justify-end black--text"
            >
              Atendimento da nutricionista:
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <v-rating
                v-model="ratingNutri"
                color="yellow darken-3"
                background-color="#614021"
                hover
                large
              ></v-rating>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center" v-if="warnRatingRecip">
            <v-alert type="error" dense>
              Avalie o atendimento da sua recepcionista!
            </v-alert>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex align-center justify-end black--text"
            >
              Atendimento da recepcionista:
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <v-rating
                v-model="ratingRecip"
                color="yellow darken-3"
                background-color="#614021"
                hover
                large
              ></v-rating>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center" v-if="warnRatingEstet">
            <v-alert type="error" dense>
              Avalie o atendimento da sua esteticista!
            </v-alert>
          </v-row>
          <v-row v-if="knowsEstet">
            <v-col
              cols="12"
              md="6"
              class="d-md-flex align-center justify-end black--text"
            >
              Atendimento da esteticista:
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <v-rating
                v-model="ratingEstet"
                color="yellow darken-3"
                background-color="#614021"
                hover
                large
              ></v-rating>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center black--text mt-3">
            Qual é o nível de satisfação com...
          </v-row>
          <v-row class="d-flex justify-center" v-if="warnRatingCarda">
            <v-alert type="error" dense> Avalie os cardápios! </v-alert>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex align-center justify-end black--text"
            >
              ...os cardápios prescritos:
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <v-rating
                v-model="ratingCarda"
                color="#c5d13f"
                background-color="#614021"
                hover
                large
              ></v-rating>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center" v-if="warnRatingTrata">
            <v-alert type="error" dense> Avalie o resultado! </v-alert>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex align-center justify-end black--text"
            >
              ...os resultados do tratamento:
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <v-rating
                v-model="ratingTrata"
                color="#c5d13f"
                background-color="#614021"
                hover
                large
              ></v-rating>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center" v-if="warnRatingSoftw">
            <v-alert type="error" dense> Avalie o software/app! </v-alert>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex align-center justify-end black--text"
            >
              ...o suporte do software / app:
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <v-rating
                v-model="ratingSoftw"
                color="#c5d13f"
                background-color="#614021"
                hover
                large
              ></v-rating>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center" v-if="warnRatingTotal">
            <v-alert type="error" dense> Avalie a sua experiência! </v-alert>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex align-center justify-end black--text"
            >
              ...a experiência como um todo:
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <v-rating
                v-model="ratingTotal"
                color="#c5d13f"
                background-color="#614021"
                hover
                large
              ></v-rating>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col
              cols="12"
              class="d-md-flex align-center justify-center black--text"
            >
              Qual é a probabilidade de você recomendar os nossos serviços a um
              amigo?
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-slider
                v-model="probRec"
                :tick-labels="ticksLabels"
                track-color="grey"
                :max="10"
                step="1"
                ticks="always"
                tick-size="5"
                :color="colorProbRec(probRec)"
              ></v-slider>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-top justify-center red--text"
              v-if="probRec <= 6"
            >
              {{ labelProbRec(probRec) }}
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-top justify-center black--text"
              v-if="probRec > 6 && probRec <= 8"
            >
              {{ labelProbRec(probRec) }}
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-top justify-center green--text"
              v-if="probRec > 8"
            >
              {{ labelProbRec(probRec) }}
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row>
            <v-col
              cols="12"
              class="d-md-flex align-top justify-center black--text"
            >
              Comentários e sugestões <strong class="ml-1">(anónimo)</strong>:
            </v-col>
            <v-col cols="12" class="d-flex align-center">
              <v-textarea outlined v-model="comentSugest"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="d-md-flex align-top justify-center black--text"
            >
              Comunicar um problema para
              <u class="ml-1">central da franquia</u>
              <strong class="ml-1">(anónimo)</strong>:
            </v-col>
            <v-col cols="12" class="d-flex align-center">
              <v-textarea outlined v-model="comentCentral"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn class="mx-1" text color="#614021" @click="evalDialog = false">Fechar</v-btn>
          <v-btn color="#c5d13f" @click="submitEval">Submeter avaliação</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alertNovaMensagem" max-width="600" width="90%">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Uma mensagem nova!</v-card-title
        >
        <v-card-subtitle class="d-flex justify-center"
          >A sua nutricionista enviou uma nova mensagem</v-card-subtitle
        >
        <v-card-text class="d-flex justify-center">
          <v-icon large color="red">mdi-email-alert-outline</v-icon>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            to="/PacienteChat"
            @click="alertNovaMensagem = false"
            color="#c5d13f"
            >Visualizar</v-btn
          >
          <v-btn @click="alertNovaMensagem = false" color="#D6DBDF"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="menorDialog" max-width="600" persistent>
      <v-card>
        <v-card-subtitle class="d-flex justify-center">
          <p class="mt-2">
            Por favor, define um responsável pel@ paciente de menor de idade
          </p>
        </v-card-subtitle>
        <v-card-text class="d-flex justify-center">
          <v-text-field
            v-model="nomeResponsavel"
            label="Nome do responsável"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <!-- <v-btn @click="menorDialog = false" color="#D6DBDF">Fechar</v-btn> -->
          <v-btn v-if="savingNomeResponsavel">
            <v-progress-circular
              :size="20"
              :width="3"
              color="#c5d13f"
              indeterminate
            ></v-progress-circular
          ></v-btn>
          <v-btn
            v-else
            @click="saveNomeResponsavel"
            :disabled="nomeResponsavel == '' && nomeResponsavel == null"
            color="#c5d13f"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="expiredSession" persistent max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Sessão expirada!</v-card-title
        >
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="#c5d13f">mdi-clock-alert-outline</v-icon>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            @click="restartSession"
            :disabled="restarting"
            class="white--text"
          >
            <v-progress-circular
              v-if="restarting"
              :size="20"
              :width="3"
              color="#c5d13f"
              indeterminate
            ></v-progress-circular>
            <v-icon color="#c5d13f" v-else left>mdi-home-export-outline</v-icon>
            <span v-if="!restarting">continuar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <HomeHeaderPaciente></HomeHeaderPaciente> -->

    <v-app-bar app class="d-flex justify-center">
      <v-img
        :src="logo"
        contain
        max-height="60"
        max-width="180"
        class="my-2"
      ></v-img>
    </v-app-bar>
    <v-row class="d-flex justify-center mb-4" v-if="!userInfo.isNF">
      <v-expansion-panels>
        <v-expansion-panel v-model="menuItemClicked" @click="closeAgain">
          <v-expansion-panel-header>
            <v-row class="d-flex align-center justify-center">
              <v-icon left>mdi-menu</v-icon>
              MENU
              <v-chip
                class="ml-2"
                color="red"
                text-color="white"
                v-if="hasNovidade"
              >
                Novidades!
              </v-chip>
            </v-row></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list-item v-for="(subitem, index) in menuItems" :key="index">
              <router-link
                :to="{ name: subitem.route }"
                style="text-decoration: none; color: inherit"
                v-slot="{ href, navigate, isActive, isExactActive }"
                replace
              >
                <v-list-item
                  :class="[
                    isActive && 'router-link-active',
                    isExactActive && 'router-link-exact-active',
                  ]"
                  :href="href"
                  @click="navigate"
                >
                  <v-list-item-icon>
                    <v-icon v-if="hasNovidadeItem(subitem)" color="red">{{
                      subitem.icon
                    }}</v-icon>
                    <v-icon v-else color="#c5d13f">{{ subitem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row class="d-flex justify-center mb-4" v-else>
      <v-expansion-panels>
        <v-expansion-panel v-model="menuItemClicked" @click="closeAgain">
          <v-expansion-panel-header>
            <v-row class="d-flex align-center justify-center">
              <v-icon left>mdi-menu</v-icon>
              MENU
              <v-chip
                class="ml-2"
                color="red"
                text-color="white"
                v-if="hasNovidade"
              >
                Novidades!
              </v-chip>
            </v-row></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list-item v-for="(subitem, index) in menuItems_nF" :key="index">
              <router-link
                :to="{ name: subitem.route }"
                style="text-decoration: none; color: inherit"
                v-slot="{ href, navigate, isActive, isExactActive }"
                replace
              >
                <v-list-item
                  :class="[
                    isActive && 'router-link-active',
                    isExactActive && 'router-link-exact-active',
                  ]"
                  :href="href"
                  @click="navigate"
                >
                  <v-list-item-icon>
                    <v-icon v-if="hasNovidadeItem(subitem)" color="red">{{
                      subitem.icon
                    }}</v-icon>
                    <v-icon v-else color="#c5d13f">{{ subitem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title color="#614021">{{
                    subitem.title
                  }}</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <router-view></router-view>
    <v-row
      class="d-flex justify-center"
      v-if="consultasOrigLength > 0 || sessoesOrigLength > 0"
    >
      <v-card
        outlined
        width="90%"
        class="my-1"
        :style="{ border: '2px solid grey' }"
      >
        <v-card-subtitle class="d-flex justify-center">
          <v-icon left color="#c5d13f">mdi-calendar</v-icon>
          Meus próximos encontros</v-card-subtitle
        >
        <div v-if="consultasOrigLength > 0">
          <v-card-subtitle class="d-flex justify-center"
            >Consultas</v-card-subtitle
          >
          <v-card-text>
            <v-row
              v-for="(consulta, consultaIndex) in consultas"
              :key="consultaIndex"
              class="d-flex justify-center"
            >
              <p style="color: #ff0000" v-if="isSoon(consulta)">
                {{ consulta.name }}:
              </p>
              <p style="color: #614021" v-else>{{ consulta.name }}:</p>
              <p style="color: #ff0000" v-if="isSoon(consulta)">
                {{ consulta.niceDate }}
              </p>
              <p style="color: #c5d13f" v-else>{{ consulta.niceDate }}</p>
            </v-row>
          </v-card-text>
        </div>
        <div v-if="sessoesOrigLength > 0">
          <v-card-subtitle class="d-flex justify-center"
            >Estética</v-card-subtitle
          >
          <v-card-text>
            <v-row
              v-for="(sessao, sessaoIndex) in sessoes"
              :key="sessaoIndex"
              class="d-flex justify-center"
            >
              <p style="color: #ff0000" v-if="isSoon(sessao)">
                {{ sessao.name }}:
              </p>
              <p style="color: #614021" v-else>{{ sessao.name }}:</p>
              <p style="color: #ff0000" v-if="isSoon(sessao)">
                {{ sessao.niceDate }}
              </p>
              <p style="color: #c5d13f" v-else>{{ sessao.niceDate }}</p>
            </v-row>
          </v-card-text>
        </div>
        <v-card-actions
          v-if="consultasOrigLength > 3 || sessoesOrigLength > 3"
          class="d-flex justify-center"
        >
          <v-btn text to="/AgendaPaciente" color="#614021" small>
            <v-icon left small color="#614021">mdi-calendar</v-icon>
            mais
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-row class="d-flex justify-center" v-if="dietasRefs.length > 0">
      <v-card
        outlined
        width="90%"
        class="my-3"
        :style="{ border: '2px solid grey' }"
      >
        <v-card-subtitle class="d-flex justify-center">
          <v-icon left color="#c5d13f">mdi-food-fork-drink</v-icon>
          Deixe a nutri ver os seus pratos!</v-card-subtitle
        >
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-select
              outlined
              prepend-inner-icon="mdi-calendar"
              label="1. Escolha um dia"
              v-model="dayCardapio"
              :items="daysCardapio"
              @change="updateFileInputs"
            ></v-select>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-select
              outlined
              prepend-inner-icon="mdi-food-apple"
              label="2. Escolha um cardápio"
              v-model="pCardapio"
              :items="pCardapios"
              @change="updateFileInputs"
            ></v-select>
          </v-row>
          <v-row class="d-flex justify-center">
            <p>3. Anexe as fotos das refeições</p>
          </v-row>
          <v-row class="d-flex justify-center" v-if="loadingRefeicoes">
            Carregando refeições prescritas...
          </v-row>
          <v-row class="d-flex justify-center" v-if="loadingRefeicoes">
            <v-progress-linear
              color="#c5d13f"
              indeterminate
              class="md-2"
            ></v-progress-linear>
          </v-row>
          <v-row
            class="d-flex justify-center"
            v-for="(refeicao, refIndex) in pRefeicoes"
            :key="refIndex"
          >
            <v-card outlined class="my-1" width="90%">
              <v-card-subtitle
                >{{ refeicao.titulo }} ({{
                  refeicao.kcal
                }}
                KCAL)</v-card-subtitle
              >
              <v-card-text>
                <v-row class="d-flex justify-center"
                  ><small>Alimentos: {{ refeicao.alimentosStr }}</small></v-row
                >
                <v-row class="d-flex justify-center"
                  ><small>Receitas: {{ refeicao.receitasStr }}</small></v-row
                >
                <v-row
                  class="d-flex justify-center"
                  v-if="
                    refeicao.observacaoStr !== '' &&
                    refeicao.observacaoStr !== null &&
                    refeicao.observacaoStr !== undefined
                  "
                  ><small>Obs.:{{ refeicao.observacaoStr }}</small></v-row
                >
                <v-row class="d-flex justify-center">
                  <v-img
                    width="80%"
                    :src="refeicao.fotoUrl"
                    class="my-1"
                    v-if="
                      refeicao.fotoUrl !== undefined ||
                      refeicao.fotoUrl !== null
                    "
                  ></v-img>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-progress-linear
                    color="#c5d13f"
                    indeterminate
                    class="my-2"
                    v-if="confirmingFotoIndex == refIndex"
                  ></v-progress-linear>
                </v-row>
                <v-row
                  class="d-flex justify-center red--text"
                  v-if="refeicao.errorUpload"
                >
                  <p>Erro ao subir a imagem, tente novamente!</p>
                </v-row>
                <v-row class="d-flex justify-center my-2">
                  <input
                    type="file"
                    accept="image/*"
                    @change="captureFoto(refIndex)"
                    :id="makeId(refIndex)"
                  />
                </v-row>
                <v-row
                  class="d-flex justify-center align-center mt-1"
                  v-if="
                    refeicao.fotoUrl !== undefined &&
                    refeicao.fotoUrl !== null &&
                    (refeicao.commentN !== null || refeicao.heartN !== null)
                  "
                >
                  <v-col cols="3" class="d-flex justify-center">
                    <v-btn small color="#c5d13f" v-if="refeicao.heartN">
                      <v-icon color="#FF40B9">mdi-heart</v-icon>
                    </v-btn>
                    <v-btn small outlined color="#c5d13f" v-else>
                      <v-icon color="#614021">mdi-heart-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="9">
                    <v-textarea
                      v-if="refeicao.commentN !== null"
                      outlined
                      color="red"
                      label="comentário"
                      v-model="refeicao.commentN"
                      style="color: red !important"
                      auto-grow
                    ></v-textarea>
                    <v-text-field
                      v-else
                      outlined
                      label="comentário"
                      readonly
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
          <v-divider></v-divider>
          <v-row
            class="d-flex justify-center red--text"
            v-if="newCommentHeartItems.length > 0"
          >
            Novas reações da Nutricionista!
          </v-row>
          <v-row
            class="d-flex justify-center"
            v-for="(item, itemIndex) in newCommentHeartItems"
            :key="itemIndex"
          >
            <v-chip class="my-1" @click="visComment(item)">
              {{ niceDay(item.dayCardapio) }}
              <v-icon right color="#c5d13f" v-if="item.newCommentN"
                >mdi-chat-processing</v-icon
              >
              <v-icon
                right
                color="#FF40B9"
                v-else-if="!item.newCommentN && item.newHeartN"
                >mdi-heart</v-icon
              >
            </v-chip>
          </v-row>
          <v-row
            class="d-flex justify-center"
            v-if="visPRef.dayCardapio !== null"
          >
            <v-card outlined width="90%">
              <v-card-subtitle>{{
                niceDay(visPRef.dayCardapio)
              }}</v-card-subtitle>
              <v-card-text>
                <v-row class="d-flex justify-center" v-if="loadingVisPRef">
                  Carregando...
                </v-row>
                <v-row class="d-flex justify-center" v-if="loadingVisPRef">
                  <v-progress-linear
                    color="#c5d13f"
                    indeterminate
                    class="md-2"
                  ></v-progress-linear>
                </v-row>
                <v-row
                  class="d-flex justify-center"
                  v-for="(refeicao, refIndex) in filteredVisPRef"
                  :key="refIndex"
                >
                  <v-card outlined class="my-1" width="90%">
                    <v-card-subtitle
                      >{{ refeicao.titulo }} ({{
                        refeicao.kcal
                      }}
                      KCAL)</v-card-subtitle
                    >
                    <v-card-text>
                      <v-row class="d-flex justify-center"
                        ><small
                          >Alimentos: {{ refeicao.alimentosStr }}</small
                        ></v-row
                      >
                      <v-row class="d-flex justify-center"
                        ><small
                          >Receitas: {{ refeicao.receitasStr }}</small
                        ></v-row
                      >
                      <v-row
                        class="d-flex justify-center"
                        v-if="
                          refeicao.observacaoStr !== '' &&
                          refeicao.observacaoStr !== null &&
                          refeicao.observacaoStr !== undefined
                        "
                        ><small>Obs.:{{ refeicao.observacaoStr }}</small></v-row
                      >
                      <v-row class="d-flex justify-center">
                        <v-img
                          width="80%"
                          :src="refeicao.fotoUrl"
                          class="my-1"
                          v-if="
                            refeicao.fotoUrl !== undefined ||
                            refeicao.fotoUrl !== null
                          "
                        ></v-img>
                      </v-row>
                      <v-row
                        class="d-flex justify-center align-center mt-1"
                        v-if="
                          refeicao.fotoUrl !== undefined &&
                          refeicao.fotoUrl !== null &&
                          (refeicao.commentN !== null ||
                            refeicao.heartN !== null)
                        "
                      >
                        <v-col cols="3" class="d-flex justify-center">
                          <v-btn small color="#c5d13f" v-if="refeicao.heartN">
                            <v-icon color="#FF40B9">mdi-heart</v-icon>
                          </v-btn>
                          <v-btn small outlined color="#c5d13f" v-else>
                            <v-icon color="#614021">mdi-heart-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="9">
                          <v-textarea
                            v-if="refeicao.commentN !== null"
                            outlined
                            color="red"
                            label="comentário"
                            v-model="refeicao.commentN"
                            style="color: red !important"
                            auto-grow
                          ></v-textarea>
                          <v-text-field
                            v-else
                            outlined
                            label="comentário"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="d-flex justify-center" v-if="pesosLength > 0">
      <v-card outlined width="90%" class="my-1">
        <v-card-subtitle class="d-flex justify-center">
          <v-icon left color="#c5d13f">mdi-finance</v-icon>
          Minha evolução</v-card-subtitle
        >
        <v-card-text>
          <v-row
            class="d-flex justify-center text-h4 mb-2"
            v-if="userInfo.objetivo == 'Emagrecer' && diffPeso < 0"
          >
            {{ diffPeso }} kg
          </v-row>
          <v-row
            class="d-flex justify-center text-h5 mb-2"
            v-if="userInfo.objetivo == 'Ganhar Massa Magra' && diffPeso < 0"
          >
            Ganho de Massa Magra: {{ diffPeso }} kg
          </v-row>
          <v-row
            class="d-flex justify-center text-h5 mb-2"
            v-if="userInfo.objetivo == 'Procedimento Estético' && diffPeso < 0"
          >
            {{ diffPeso }} kg de gordura
          </v-row>
          <v-row class="d-flex justify-center">
            <div class="small">
              <chart :chart-data="datacollectionCompos"></chart>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <PacienteTimeline></PacienteTimeline>
    <!-- </v-col>
    </v-row> -->
    <!-- <v-row><v-btn @click="sendClausulaConcordo">Test</v-btn></v-row> -->
    <WelcomeFooter></WelcomeFooter>
  </div>
</template>

<script>
// import HomeHeaderPaciente from "../components/home/HomeHeaderPaciente";
// import HomeMenuPaciente from "../components/home/HomeMenuPaciente";
import WelcomeFooter from "../components/welcome/WelcomeFooterPaciente";
import PacienteTimeline from "../components/PacienteTimeline";
import firebase from "firebase";
import router from "../router";

import Chart from "../components/areaDePaciente/LineChart.js";
import {
  mdiCalendar,
  mdiMagnify,
  mdiAccountPlus,
  mdiAccountGroup,
  mdiAccountHeartOutline,
  mdiCardAccountDetailsOutline,
  mdiFoodApple,
  mdiHome,
  mdiTextBoxOutline,
  mdiNeedle,
  mdiClipboardAlertOutline,
  mdiHandshakeOutline,
  mdiFinance,
  // mdiWeightKilogram,
  mdiHuman,
  // mdiTextBox,
  mdiTelevision,
  // mdiTelevisionPlay,
  // mdiCalendarStar,
  // mdiGift,
  mdiCartVariant,
  // mdiClose,
  // mdiAccountDetailsOutline,
  mdiCamera,
  // mdiChat,
  mdiChatProcessing,
  mdiFileDocumentEditOutline,
  mdiChatAlert,
  // mdiFileQuestionOutline
} from "@mdi/js";

export default {
  components: {
    WelcomeFooter,
    // HomeHeaderPaciente,
    // HomeMenuPaciente,
    PacienteTimeline,
    Chart,
  },
  data() {
    return {
      confirmingFotoIndex: null,
      savingNomeResponsavel: false,
      nomeResponsavel: null,
      dayCardapio: null,
      pCardapio: null,
      pCardapios: [],
      pRefeicoes: [],
      newCommentHeartItems: [],
      visPRef: {
        dayCardapio: null,
      },
      filteredVisPRef: [
        {
          titulo: null,
          alimentosStr: null,
          receitasStr: null,
          observacaoStr: null,
          kcal: null,
          fotoUrl: null,
          commentN: null,
          heartN: null,
        },
      ],
      loadingRefeicoes: false,
      useCamera: false,
      useUpload: false,
      uploadFile: null,
      // capturingFoto: false,
      // cameraInputFile: null,
      hiddenInfo: {
        lastFalaDateStr: null,
        lastFalaHora: null,
        unansweredP: null,
        unansweredN: null,
      },
      alertNovaMensagem: false,
      imagemNao: false,
      menuItemClicked: [],
      datacollectionCompos: null,
      checkCompos: [
        {
          show: true,
          nome: "pesoIdeal",
          color: "#ff7f00",
          label: "peso ideal [kg]",
        },
        {
          show: true,
          nome: "pesoAtual",
          color: "#000000",
          label: "peso atual [kg]",
        },
        {
          show: true,
          nome: "percGorduraAbs",
          color: "#ff0000",
          label: "gordura [kg]",
        },
        {
          show: true,
          nome: "massaMagraAbs",
          color: "#008000",
          label: "massa magra [kg]",
        },
        { show: true, nome: "aguaAbs", color: "#0000ff", label: "água [kg]" },
      ],
      diffPeso: 0,
      pesosLength: 0,
      hasNovidade: false,
      nrDietasUnread: 0,
      dietasRefs: [],
      formulas: [],
      exames: [],
      orientacoes: [],
      atendimentos: [],
      videoAulas: [],
      // fotos: [],
      // agenda: [],
      consultas: [
        {
          start: null,
          name: null,
          niceDate: null,
        },
      ],
      sessoes: [
        {
          start: null,
          name: null,
          niceDate: null,
        },
      ],
      consultasOrigLength: 0,
      sessoesOrigLength: 0,
      openMenu: false,
      // userInfos: {},
      menuItems: [
        {
          key: 11,
          title: "Início",
          icon: mdiHome,
          route: "AreaDoPacienteWork",
        },
        {
          key: 0,
          title: "Dietas",
          icon: mdiFoodApple,
          route: "PacienteDietas",
        },
        {
          key: 1,
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          route: "PacienteFormulas",
        },
        { key: 2, title: "Exames", icon: mdiNeedle, route: "PacienteExames" },
        { key: 12, title: "Orientações nutricionais", icon: mdiClipboardAlertOutline, route: "PacienteOrientacoes" },
        {
          key: 3,
          title: "Minha evolução",
          icon: mdiFinance,
          route: "PacienteEvolucao",
        },
        {
          key: 4,
          title: "Bioimpedâncias",
          icon: mdiHuman,
          route: "PacienteBioimpedancias",
        },
        { key: 5, title: "Fotos", icon: mdiCamera, route: "PacienteFotos" },
        {
          key: 6,
          title: "Vídeo-Cursos / Ebooks",
          icon: mdiTelevision,
          route: "PacienteVideoAulas",
        },
        {
          key: 7,
          title: "Clube de Vantagens",
          icon: mdiHandshakeOutline, //shake hands
          route: "PacienteClubeDeVantagens",
        },
        {
          key: 8,
          title: "Minhas compras",
          icon: mdiCartVariant,
          route: "PacienteCompras",
        },
        {
          key: 9,
          title: "Contrato",
          icon: mdiFileDocumentEditOutline,
          route: "PacienteContrato",
        },
        {
          key: 10,
          title: "Fale com a Nutri",
          icon: mdiChatProcessing,
          route: "PacienteChat",
        },
      ],
      menuItems_nF: [
        {
          key: 11,
          title: "Início",
          icon: mdiHome,
          route: "AreaDoPacienteWork",
        },
        {
          key: 0,
          title: "Dietas",
          icon: mdiFoodApple,
          route: "PacienteDietas",
        },
        {
          key: 1,
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          route: "PacienteFormulas",
        },
        { key: 2, title: "Exames", icon: mdiNeedle, route: "PacienteExames" },
        { key: 12, title: "Orientações nutricionais", icon: mdiClipboardAlertOutline, route: "PacienteOrientacoes" },
        {
          key: 3,
          title: "Minha evolução",
          icon: mdiFinance,
          route: "PacienteEvolucao",
        },
        {
          key: 4,
          title: "Bioimpedâncias",
          icon: mdiHuman,
          route: "PacienteBioimpedancias",
        },
        { key: 5, title: "Fotos", icon: mdiCamera, route: "PacienteFotos" },
        // {
        //   key: 6,
        //   title: "Vídeo-Cursos",
        //   icon: mdiTelevision,
        //   route: "PacienteVideoAulas",
        // },
        // {
        //   key: 7,
        //   title: "Clube de Vantagens",
        //   icon: mdiHandshakeOutline, //shake hands
        //   route: "PacienteClubeDeVantagens",
        // },
        {
          key: 8,
          title: "Minhas compras",
          icon: mdiCartVariant,
          route: "PacienteCompras",
        },
        // {
        //   key: 9,
        //   title: "Contrato",
        //   icon: mdiFileDocumentEditOutline,
        //   route: "PacienteContrato",
        // },
        {
          key: 10,
          title: "Fale com a Nutri",
          icon: mdiChatProcessing,
          route: "PacienteChat",
        },
      ],
      logo: null,
      showPlanosDialog: false,
      pacientesMouse: false,
      icons: {
        mdiChatAlert,
        mdiCalendar,
        mdiAccountPlus,
        mdiMagnify,
        mdiAccountGroup,
        mdiAccountHeartOutline,
        mdiCardAccountDetailsOutline,
      },

      probRec: null,
      ticksLabels: [
        "0%",
        "10%",
        "20%",
        "30%",
        "40%",
        "50%",
        "60%",
        "70%",
        "80%",
        "90%",
        "100%",
      ],
      areaItems: [
        { nome: "Abdômen inferior", qtd: "1 área" },
        { nome: "Abdômen superior", qtd: "1 área" },
        { nome: "Glúteo direito", qtd: "1 área" },
        { nome: "Glúteo esquerdo", qtd: "1 área" },
        { nome: "Posterior de coxa esquerdo", qtd: "1 área" },
        { nome: "Posterior de coxa direito", qtd: "1 área" },
        { nome: "Flanco esquerdo", qtd: "1 área" },
        { nome: "Flanco direito", qtd: "1 área" },
        { nome: "Interno de coxas esquerdo", qtd: "1 área" },
        { nome: "Interno de coxas direito", qtd: "1 área" },
        { nome: "Lateral de coxas esquerdo", qtd: "1 área" },
        { nome: "Lateral de coxas direito", qtd: "1 área" },
        { nome: "Bíceps esquerdo", qtd: "1 área" },
        { nome: "Bíceps direito", qtd: "1 área" },
        { nome: "Costas lado direito", qtd: "1 área" },
        { nome: "Costas lado esquerdo", qtd: "1 área" },
        { nome: "Tríceps direito", qtd: "1 área" },
        { nome: "Tríceps esquerdo", qtd: "1 área" },
        { nome: "Papada", qtd: "1 área" },
      ],
      areaItems1: [
        {
          nome: "Face (drenagem)",
          qtd: "1 área isolada",
          freq: "2 (duas) vezes por semana",
        },
        {
          nome: "Pés (reflexologia podal)",
          qtd: "1 área isolada",
          freq: "2 (duas) vezes por semana",
        },
      ],
      areaItems2: [
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Abdômen superior e inferior",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Glúteo direito e esquerdo",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Posterior de coxa esquerdo e direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Flanco esquerdo e direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Interno de coxas esquerdo e direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Lateral de coxas esquerdo direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Posterior de coxa direito e esquerdo",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Bíceps esquerdo e direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Costas lado direito e esquerdo",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Tríceps direito e esquerdo",
        },
      ],
      // contratoChecked: false,
      // clausulaChecked: false,
      // ipClient: null,
      ratingTotal: null,
      ratingTrata: null,
      ratingEstet: null,
      ratingRecip: null,
      ratingNutri: null,
      ratingSoftw: null,
      ratingCarda: null,
      warnRatingEstet: false,
      warnRatingRecip: false,
      warnRatingTotal: false,
      warnRatingTrata: false,
      warnRatingNutri: false,
      warnRatingSoftw: false,
      warnRatingCarda: false,
      comentCentral: null,
      comentSugest: null,
      evalDialog: false,
      contractDialog: false,
      clausulaDialog: false,
      sendingContractDialog: false,
      sendingClausulaDialog: false,
      expiredSession: false,
      restarting: false,
    };
  },
  computed: {
    // hiddenInfo() {
    //   return this.$store.state.firestore.hiddenInfo;
    // },
    menorDialog() {
      var noNomeResponsavel = true;
      var gotNomeResponsavel = this.userInfo.nomeResponsavel;
      if (gotNomeResponsavel !== undefined) {
        noNomeResponsavel =
          gotNomeResponsavel == "" && gotNomeResponsavel == null;
      }
      var underage = false;
      var gotDataNasc = this.userInfo.dataNascimento;
      var now = new Date();
      if (gotDataNasc !== undefined) {
        var dob = new Date(gotDataNasc + " 12:00:00");
        var diff = now.getTime() - dob.getTime();
        var diffDate = new Date(diff);
        var age = diffDate.getUTCFullYear() - 1970;
        underage = age < 18;
      }
      return noNomeResponsavel && underage;
    },
    daysCardapio() {
      const range = [0, 1, 2, 3];
      var now = new Date();
      var items = range.map(function (index) {
        var subst = index * 24 * 60 * 60 * 1000;
        var x_millis = now.getTime() - subst;
        var x_now = new Date(x_millis);
        var x_shortYearNr = x_now.getFullYear() - 2000;
        var x_shortYearStr = x_shortYearNr.toString();
        var x_monthNr = x_now.getMonth() + 1;
        var x_monthStr;
        if (x_monthNr > 9) {
          x_monthStr = x_monthNr.toString();
        } else {
          x_monthStr = "0" + x_monthNr.toString();
        }
        var x_dateNr = x_now.getDate();
        var x_dateStr;
        if (x_dateNr > 9) {
          x_dateStr = x_dateNr.toString();
        } else {
          x_dateStr = "0" + x_dateNr.toString();
        }
        // var x_middleDay = new Date(
        //   x_yearStr + "-" + x_monthStr + "-" + x_dateStr + " 12:00:00"
        // );
        // var x_dayMillis = x_middleDay.getTime();
        var x_dateCode = x_shortYearStr + x_monthStr + x_dateStr;

        const dayNames = [
          "Domingo",
          "Segunda-feira",
          "Terça-feira",
          "Quarta-feira",
          "Quinta-feira",
          "Sexta-feira",
          "Sábado",
        ];

        var x_dayName = dayNames[x_now.getDay()];

        var niceDateStr =
          x_dayName +
          ", " +
          x_dateStr +
          "/" +
          x_monthStr +
          "/" +
          x_shortYearStr;

        var obj = {
          text: niceDateStr,
          value: x_dateCode,
        };
        return obj;
      });
      return items;
    },
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    knowsEstet() {
      return this.$store.state.firestore.userInfo.knowsEstet;
    },
    nutri() {
      return this.$store.state.firestore.userInfo.nutri;
    },
    needsEval() {
      var now = new Date();
      var joinedMillis = now.getTime();
      if (
        this.$store.state.firestore.userInfo.firstConsultaMillis == undefined
      ) {
        joinedMillis =
          this.$store.state.firestore.userInfo.joinedAt.seconds * 1000;
      } else {
        joinedMillis = this.$store.state.firestore.userInfo.firstConsultaMillis;
      }
      var nowMillis = now.getTime();
      var dias15 = 15 * 24 * 60 * 60 * 1000;
      var dias30 = 30 * 24 * 60 * 60 * 1000;
      var dias60 = 60 * 24 * 60 * 60 * 1000;
      var dias75 = 75 * 24 * 60 * 60 * 1000;
      var millis15 = nowMillis - dias15;
      var millis30 = nowMillis - dias30;
      var millis60 = nowMillis - dias60;
      var millis75 = nowMillis - dias75;
      var cond15 = millis15 > joinedMillis;
      var cond30 = millis30 > joinedMillis;
      var cond60 = millis60 > joinedMillis;
      var cond75 = millis75 > joinedMillis;
      var evalExists = this.$store.state.firestore.userInfo.evalExists;
      var evalMillis = this.$store.state.firestore.userInfo.evalMillis;
      var lastEval15 = millis15 > evalMillis;
      var lastEval30 = millis30 > evalMillis;
      var lastEval60 = millis60 > evalMillis;

      var case1 = cond15 && !evalExists; // 15 days after joining && not yet evaluated
      var case2 = cond30 && evalExists && lastEval15; // 30 days after joining && once evaluated && last evaluation at least 14 days ago
      var case3 = cond60 && evalExists && lastEval30; // 60 days after joining &&
      var case4 = cond75 && evalExists && lastEval60; // 90 days after joining &&
      console.log([case1, case2, case3, case4]);

      return case1 || case2 || case3 || case4;
    },
    // expiredDialog() {
    //   console.log(this.$store.state.firestore.userInfo.programaFim);
    //   var now = new Date();
    //   var nowMillis = now.getTime();
    //   var millis90 = 100 * 24 * 60 * 60 * 1000;
    //   var minus90millis = nowMillis - millis90;
    //   var joinedDate = new Date(this.$store.state.firestore.userInfo.joinedAt);
    //   var joinedMillis = joinedDate.getTime();
    //   console.log(joinedMillis);
    //   if (
    //     this.$store.state.firestore.userInfo.programaFim !== undefined &&
    //     this.$store.state.firestore.userInfo.programaFim !== 0
    //   ) {
    //     var bool =
    //       parseFloat(this.$store.state.firestore.userInfo.programaFim) >
    //       nowMillis;
    //     return bool;
    //   } else if (this.$store.state.firestore.userInfo.programaFim == 0) {
    //     return false;
    //   } else {
    //     if (joinedMillis < minus90millis) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },
  },
  methods: {
    async visComment(item) {
      this.loadingVisPRef = true;
      this.visPRef = item;
      this.filteredVisPRef = item.pRefeicoes.filter(function (r) {
        return r.commentN !== null || r.heartN !== null;
      });
      this.loadingVisPRef = false;
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("dietaStories")
        .doc(item.dayCardapio)
        .update({
          newCommentN: false,
          newHeartN: false,
        });
    },
    niceDay(str) {
      var yearStr = str.substring(0, 2);
      var mesStr = str.substring(2, 4);
      var dayStr = str.substring(4, 6);
      return dayStr + "/" + mesStr + "/" + yearStr;
    },
    makeId(index) {
      if (this.dayCardapio !== undefined && this.dayCardapio !== null) {
        return "cameraInput" + this.dayCardapio + this.pCardapio + index;
      }
    },
    async saveNomeResponsavel() {
      if (this.nomeResponsavel !== undefined) {
        this.savingNomeResponsavel = true;
        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .update({
            nomeResponsavel: this.nomeResponsavel,
          });
        // this.menorDialog = false;
        this.savingNomeResponsavel = false;

        this.$store.dispatch("firestore/getUserInfoUpdates");
      }
    },
    async captureFoto(refeIndex) {
      console.log("captureFoto", refeIndex);
      const fileInput = document.getElementById(
        "cameraInput" + this.dayCardapio + this.pCardapio + refeIndex
      );
      var inputFile = null;
      if (fileInput.files[0] !== undefined) {
        inputFile = fileInput.files[0];
      }
      if (inputFile !== undefined && inputFile !== null) {
        this.confirmingFotoIndex = refeIndex;
        var refeFotoUrl = null;
        var d = new Date();
        var millis = d.getTime();
        await firebase
          .storage()
          .ref("dietaStories")
          .child(
            sessionStorage.getItem("current-user") + "_" + millis.toString()
          )
          .put(inputFile)
          .then(async (snapshot) => {
            refeFotoUrl = await snapshot.ref.getDownloadURL();
            console.log("refeFotoUrl", refeFotoUrl);
          });

        this.pRefeicoes[refeIndex].fotoUrl = refeFotoUrl;
        this.confirmingFotoIndex = null;

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("dietaStories")
          .doc(this.dayCardapio)
          .set({
            pRefeicoes: this.pRefeicoes,
            updated: millis,
            pCardapio: this.pCardapio,
            dayCardapio: this.dayCardapio,
          });

        var nutriResponsavel = "any";
        if (this.userInfo.nutriResponsavel !== undefined) {
          nutriResponsavel = this.userInfo.nutriResponsavel;
        }
        var boardId =
          sessionStorage.getItem("current-user") + "_" + this.dayCardapio;
        var qtdFotos = 0;
        this.pRefeicoes.forEach(function (r) {
          if (r.fotoUrl !== null && r.fotoUrl !== undefined) {
            qtdFotos += 1;
          }
        });

        await firebase
          .firestore()
          .collection("users")
          .doc(this.userInfo.nutri)
          .collection("dietaStoriesBoard")
          .doc(boardId)
          .set({
            fotoUrl: refeFotoUrl,
            nutriResponsavel: nutriResponsavel,
            updated: millis,
            nomePaciente: this.userInfo.nome,
            pacienteId: sessionStorage.getItem("current-user"),
            seenN: false,
            dayCardapio: this.dayCardapio,
            objetivo: this.userInfo.objetivo,
            qtdFotos: qtdFotos,
          });
      } else {
        this.confirmingFotoIndex = null;
        this.pRefeicoes[refeIndex].errorUpload = true;
      }
    },
    async updateFileInputs() {
      this.loadingRefeicoes = true;
      var dayCardapio = this.dayCardapio;
      var existingCardapioObj_list = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("dietaStories")
        .where("dayCardapio", "==", dayCardapio)
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      console.log(
        "existingCardapioObj_list.length",
        existingCardapioObj_list.length
      );

      if (existingCardapioObj_list.length > 0) {
        var dietaData = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("dietaStories")
          .doc(dayCardapio)
          .get()
          .then(function (doc) {
            return doc.data();
          });
        this.pRefeicoes = dietaData.pRefeicoes;
        this.pCardapio = dietaData.pCardapio;
      } else {
        this.pRefeicoes = [];
        if (this.pCardapio !== null && this.pCardapio !== undefined) {
          var dieta = await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("dietas")
            .doc(this.pCardapio)
            .get()
            .then(function (doc) {
              return doc.data();
            });

          this.pRefeicoes = dieta.refeicoes.map(function (refe) {
            var alimentoStr = "";
            refe.alimentosList.forEach(function (alim) {
              alimentoStr +=
                alim.nome +
                "(" +
                alim.quantidade +
                " " +
                alim.medida +
                ", " +
                alim.gramas +
                " g" +
                ") - ";
            });
            var receitaStr = "";
            refe.receitasList.forEach(function (rece) {
              var porcaoV = "";
              if (rece.porcoes !== undefined && rece.porcoes !== null) {
                if (rece.porcoes < 2) {
                  porcaoV = "porção";
                } else {
                  porcaoV = "porções";
                }
              }
              var receTitulo = "";
              if (rece.titulo !== undefined && rece.titulo !== null) {
                receTitulo = rece.titulo;
              }

              receitaStr +=
                receTitulo + "(" + rece.porcoes + " " + porcaoV + ") - ";
            });
            var observacaoStr = "";
            if (refe.observacao.length > 25) {
              observacaoStr = refe.observacao.substring(0, 25) + "...";
            }
            return {
              titulo: refe.nomeRefeicao,
              alimentosStr: alimentoStr,
              receitasStr: receitaStr,
              observacaoStr: observacaoStr,
              kcal: Math.round(refe.KCAL),
              fotoUrl: null,
              commentN: null,
              heartN: null,
            };
          });
        }
      }
      this.loadingRefeicoes = false;
    },
    async restartSession() {
      this.restarting = true;
      await firebase
        .auth()
        .signOut()
        .then(function () {
          router.replace("/");
        })
        .catch(function (error) {
          console.log(error);
        });
      this.restarting = false;
      sessionStorage.setItem("current-user", null);
      sessionStorage.removeItem("isAuthenticated");
    },
    closeAgain() {
      setTimeout(() => {
        this.menuItemClicked = [];
      }, 4500);
    },
    fillDataCompos() {
      function validCompos(element) {
        return element.compos.agua > 0;
      }
      var atendimentos = this.atendimentos.filter(validCompos);

      var labelsCompos = atendimentos.map(function (atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });

      function checkShow(element) {
        return element.show;
      }

      this.datacollectionCompos = {
        labels: labelsCompos,
        datasets: this.checkCompos.filter(checkShow).map(function (element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function (atendimento) {
              return atendimento.compos[element.nome];
            }),
          };
        }),
      };
    },
    hasNovidadeItem(item) {
      if (
        item.title == "Fale com a Nutri" ||
        item.title == "Chat com a Nutricionista"
      ) {
        return this.hiddenInfo.unansweredP;
      } else {
        return item.nrUnread !== undefined && item.nrUnread > 0;
      }
    },
    isSoon(el) {
      if (el !== undefined) {
        const d = new Date();
        const next12hours = d.getTime() + 48 * 60 * 60 * 1000;
        return el.millis < next12hours;
      } else {
        return false;
      }
    },
    async getUserCollections() {
      const dietasRefs = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("dietasRefs")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      const formulas = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("formulas")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      const exames = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("exames")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      const orientacoes = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("orientacoes")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      const atendimentos = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("atendimentos")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      const videoAulas = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("videoAulas")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      const dietaStories = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("dietaStories")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      this.newCommentHeartItems = dietaStories.filter(function (d) {
        return d.newCommentN || d.newHeartN;
      });

      // const fotos = atendimentos.filter(function (a) {
      //   return a.fotoFrenteUrl !== undefined && a.fotoFrenteUrl !== ""
      // })

      var agenda = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      function compare(a, b) {
        if (a.millis < b.millis) {
          return -1;
        }
        if (a.millis > b.millis) {
          return 1;
        }
        return 0;
      }

      var agendaSorted = agenda
        .map(function (el) {
          var startDate = new Date(el.start);
          var startMillis = startDate.getTime();
          el.millis = startMillis;
          return el;
        })
        .filter(function (el) {
          var nowDate = new Date();
          const sub12 = 12 * 60 * 60 * 1000;
          var nowMillis = nowDate.getTime() - sub12;
          return el.millis > nowMillis;
        })
        .sort(compare);

      // if (agendaSorted.length == 0) {
      //   agendaSorted = [{
      //     name: "null", millis: null, start: null, niceDate: null,
      //   }];
      // }

      const atendimentoTipos = [
        "1ª Consulta",
        "Retorno de Consulta Avulsa",
        "2ª Consulta",
        "3ª Consulta",
        "Avaliação Final",
        "Bioimpedância",
        "Coleta de Exames",
        "Leitura de Exames",
        "1ª Avaliação Protocolo Kirchhoff",
        "2ª Avaliação Pacote Protocolo Kirchhoff",
        "3ª Avaliação Pacote Protocolo Kirchhoff",
        "Avaliação Gratuita",
        "Treinamento",
        "Reunião",
      ];

      var consultas = agendaSorted.filter(function (el) {
        return atendimentoTipos.includes(el.name);
      });
      var sessoes = agendaSorted.filter(function (el) {
        return !atendimentoTipos.includes(el.name);
      });

      this.consultasOrigLength = consultas.length;
      this.sessoesOrigLength = sessoes.length;
      if (consultas.length > 3) {
        consultas.length = 3;
      }
      if (sessoes.length > 3) {
        sessoes.length = 3;
      }

      this.pCardapios = dietasRefs.map(function (dRef) {
        return {
          text: dRef.titulo,
          value: dRef.dietaDocId,
          data: dRef,
        };
      });

      this.dietasRefs = dietasRefs;
      this.nrDietasUnread = nrDietasUnread;
      this.formulas = formulas;
      this.exames = exames;
      this.orientacoes = orientacoes;
      this.atendimentos = atendimentos;
      this.videoAulas = videoAulas;
      // this.fotos = fotos;
      // this.agenda = agendaSorted;
      this.consultas = consultas;
      this.sessoes = sessoes;

      var nrDietasUnread = 0;
      dietasRefs.forEach(function (dieta) {
        if (dieta.unread !== undefined && dieta.unread == true) {
          nrDietasUnread += 1;
        }
      });
      var nrDietasUnreadStr = "";
      if (nrDietasUnread > 0) {
        nrDietasUnreadStr = " (" + nrDietasUnread.toString() + ")";
      }

      var nrFormulasUnread = 0;
      formulas.forEach(function (f) {
        if (f.unread !== undefined && f.unread == true) {
          nrFormulasUnread += 1;
        }
      });
      var nrFormulasUnreadStr = "";
      if (nrFormulasUnread > 0) {
        nrFormulasUnreadStr = " (" + nrFormulasUnread.toString() + ")";
      }

      var nrExamesUnread = 0;
      exames.forEach(function (e) {
        if (e.unread !== undefined && e.unread == true) {
          nrExamesUnread += 1;
        }
      });
      var nrExamesUnreadStr = "";
      if (nrExamesUnread > 0) {
        nrExamesUnreadStr = " (" + nrExamesUnread.toString() + ")";
      }

      var nrOrientacoesUnread = 0;
      orientacoes.forEach(function (e) {
        if (e.unread !== undefined && e.unread == true) {
          nrOrientacoesUnread += 1;
        }
      });
      var nrOrientacoesUnreadStr = "";
      if (nrOrientacoesUnread > 0) {
        nrOrientacoesUnreadStr = " (" + nrOrientacoesUnread.toString() + ")";
      }

      // var nrAtendimentoUnread = 0;
      // atendimentos.forEach(function (a) {
      //   if (a.unread !== undefined && a.unread == true) {
      //     nrAtendimentoUnread += 1;
      //   }
      // });
      // var nrAtendimentoUnreadStr = "";
      // if (nrAtendimentoUnread > 0) {
      //   nrAtendimentoUnreadStr = " (" + nrAtendimentoUnread.toString() + ")";
      // }
      var pesos = atendimentos
        .filter(function (a) {
          return a.compos.pesoAtual !== undefined && a.compos.pesoAtual > 0;
        })
        .map(function (a) {
          return parseFloat(a.compos.pesoAtual);
        });
      var pesosG = atendimentos
        .filter(function (a) {
          return a.compos.massaMagra !== undefined && a.compos.massaMagra > 0;
        })
        .map(function (a) {
          return parseFloat(a.compos.massaMagra);
        });
      var pesosE = atendimentos
        .filter(function (a) {
          return a.compos.percGorduraAbs !== undefined && a.compos.percGorduraAbs > 0;
        })
        .map(function (a) {
          return parseFloat(a.compos.percGorduraAbs);
        });

      this.pesosLength = pesos.length;

      if (pesos.length > 1) {
        if (this.userInfo.objetivo == "Emagrecer") {
          this.diffPeso = Math.round((pesos[pesos.length - 1] - pesos[0])*10)/10;
        }
        if (this.userInfo.objetivo == "Procedimento Estético") {
          this.diffPeso = Math.round((pesosE[pesosE.length - 1] - pesosE[0])*10)/10;
        }
        if (this.userInfo.objetivo == "Ganhar Massa Magra") {
          this.diffPeso = Math.round((pesosG[pesosG.length - 1] - pesosG[0])*10)/10;
        }

      }

      // var nrFotosUnread = 0;
      // fotos.forEach(function (fo) {
      //   if (fo.unread !== undefined && fo.unread == true) {
      //     nrFotosUnread += 1;
      //   }
      // });
      // var nrFotosUnreadStr = "";
      // if (nrFotosUnread > 0) {
      //   nrFotosUnreadStr = " (" + nrFotosUnread.toString() + ")";
      // }

      var nrVideoAulasUnread = 0;
      var nowDate = new Date();
      const sub7d = 7 * 24 * 60 * 60 * 1000;
      var recentMillisLimit = nowDate.getTime() - sub7d;
      videoAulas.forEach(function (v) {
        if (v.addedMillis !== undefined && v.addedMillis > recentMillisLimit) {
          nrVideoAulasUnread += 1;
        }
      });
      var nrVideoAulasUnreadStr = "";
      if (nrVideoAulasUnread > 0) {
        nrVideoAulasUnreadStr = " (" + nrVideoAulasUnread.toString() + ")";
      }

      if (
        nrVideoAulasUnread > 0 ||
        // nrFotosUnread > 0 ||
        // nrAtendimentoUnread > 0 ||
        nrExamesUnread > 0 ||
        nrOrientacoesUnread > 0 ||
        nrFormulasUnread > 0 ||
        nrDietasUnread > 0
      ) {
        this.hasNovidade = true;
      }

      this.menuItems = [
        {
          key: 11,
          title: "Início",
          icon: mdiHome,
          route: "AreaDoPacienteWork",
        },
        {
          key: 0,
          title: "Dietas" + nrDietasUnreadStr,
          nrUnread: nrDietasUnread,
          icon: mdiFoodApple,
          route: "PacienteDietas",
        },
        {
          key: 1,
          title: "Fórmulas" + nrFormulasUnreadStr,
          nrUnread: nrFormulasUnread,
          icon: mdiTextBoxOutline,
          route: "PacienteFormulas",
        },
        {
          key: 2,
          title: "Exames" + nrExamesUnreadStr,
          nrUnread: nrExamesUnread,
          icon: mdiNeedle,
          route: "PacienteExames",
        },
        {
          key: 12,
          title: "Orientações nutricionais" + nrOrientacoesUnreadStr,
          nrUnread: nrOrientacoesUnread,
          icon: mdiClipboardAlertOutline,
          route: "PacienteOrientacoes",
        },
        {
          key: 3,
          title: "Minha evolução", // + nrAtendimentoUnreadStr,
          // nrUnread: nrAtendimentoUnread,
          icon: mdiFinance,
          route: "PacienteEvolucao",
        },
        {
          key: 4,
          title: "Bioimpedâncias",
          icon: mdiHuman,
          route: "PacienteBioimpedancias",
        },
        {
          key: 5,
          title: "Fotos", // + nrFotosUnreadStr,
          // nrUnread: nrFotosUnread,
          icon: mdiCamera,
          route: "PacienteFotos",
        },
        {
          key: 7,
          title: "Clube de Vantagens",
          icon: mdiHandshakeOutline, //shake hands
          route: "PacienteClubeDeVantagens",
        },
        {
          key: 8,
          title: "Minhas compras",
          icon: mdiCartVariant,
          route: "PacienteCompras",
        },
        {
          key: 9,
          title: "Contrato",
          icon: mdiFileDocumentEditOutline,
          route: "PacienteContrato",
        },
        {
          key: 10,
          title: "Fale com a Nutri",
          icon: mdiChatProcessing,
          route: "PacienteChat",
        },
      ];
      if (videoAulas.length > 0) {
        this.menuItems.splice(7, 0, {
          key: 6,
          title: "Vídeo-Cursos/Ebooks " + nrVideoAulasUnreadStr,
          icon: mdiTelevision,
          nrUnread: nrVideoAulasUnread,
          route: "PacienteVideoAulas",
        });
      }
      this.menuItems_nF = [
        {
          key: 11,
          title: "Início",
          icon: mdiHome,
          route: "AreaDoPacienteWork",
        },
        {
          key: 0,
          title: "Dietas",
          icon: mdiFoodApple,
          route: "PacienteDietas",
        },
        {
          key: 1,
          title: "Fórmulas",
          icon: mdiTextBoxOutline,
          route: "PacienteFormulas",
        },
        { key: 2, title: "Exames", icon: mdiNeedle, route: "PacienteExames" },
        { key: 2, title: "Orientações nutricionais", icon: mdiClipboardAlertOutline, route: "PacienteOrientacoes" },
        {
          key: 3,
          title: "Minha evolução",
          icon: mdiFinance,
          route: "PacienteEvolucao",
        },
        {
          key: 4,
          title: "Bioimpedâncias",
          icon: mdiHuman,
          route: "PacienteBioimpedancias",
        },
        { key: 5, title: "Fotos", icon: mdiCamera, route: "PacienteFotos" },
        // {
        //   key: 6,
        //   title: "Vídeo-Cursos",
        //   icon: mdiTelevision,
        //   route: "PacienteVideoAulas",
        // },
        // {
        //   key: 7,
        //   title: "Clube de Vantagens",
        //   icon: mdiHandshakeOutline, //shake hands
        //   route: "PacienteClubeDeVantagens",
        // },
        {
          key: 8,
          title: "Minhas compras",
          icon: mdiCartVariant,
          route: "PacienteCompras",
        },
        // {
        //   key: 9,
        //   title: "Contrato",
        //   icon: mdiFileDocumentEditOutline,
        //   route: "PacienteContrato",
        // },
        {
          key: 10,
          title: "Fale com a Nutri",
          icon: mdiChatProcessing,
          route: "PacienteChat",
        },
      ];

      setTimeout(() => {
        this.fillDataCompos();
        // this.fillDataMedidas();
        // this.fillDataSangue();
      }, 2000);
    },
    async getHiddenInfo() {
      console.log("getHiddenInfo");
      // this.$store.dispatch("firestore/getHiddenInfo", {
      //   nutri: this.userInfo.nutri,
      // });
      var data = await firebase
        .firestore()
        .collection("users")
        .doc(this.userInfo.nutri)
        .collection("pacientes")
        .doc(sessionStorage.getItem("current-user"))
        .get()
        .then(function (doc) {
          return doc.data();
        });

      var infos = {
        lastFalaDateStr: data.lastFalaDateStr,
        lastFalaHora: data.lastFalaHora,
        unansweredP: data.unansweredP,
        unansweredN: data.unansweredN,
      };
      this.hiddenInfo = infos;
      if (infos.unansweredP) {
        this.alertNovaMensagem = true;
      } else {
        this.alertNovaMensagem = false;
      }
    },
    // chatUnansweredP(title) {
    //   if (title == "Fale com a Nutri" || title == "Chat com a Nutricionista") {
    //     return this.hiddenInfo.unansweredN;
    //   } else {
    //     return false;
    //   }
    // },
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(function () {
          router.replace("/");
        })
        .catch(function (error) {
          console.log(error);
        });
      sessionStorage.setItem("current-user", null);
      sessionStorage.removeItem("isAuthenticated");
    },
    colorProbRec(nr) {
      if (nr <= 6) {
        return "#ff6666";
      } else if (nr > 6 && nr <= 8) {
        return "#ffff66";
      } else if (nr > 8) {
        return "#00994c";
      }
    },
    labelProbRec(nr) {
      if (nr <= 6) {
        return "Baixa probabilidade!";
      } else if (nr > 6 && nr <= 8) {
        return "Provável!";
      } else if (nr > 8) {
        return "Muito provável!";
      }
    },
    async sendContractConcordo() {
      this.sendingContractDialog = true;
      var now = new Date();
      var millis = now.getTime();
      var ipClient = "";
      const url = "https://api.ipify.org/?format=json";
      await fetch(url)
        .then((response) => response.json())
        .then(function (data) {
          ipClient = data.ip.toString();
        });
      console.log(ipClient, this.imagemNao);

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .update({
          contractDialog: false,
          imagemNao: this.imagemNao,
          confirmIp: ipClient,
          confirmIpMillis: millis,
        });

      this.sendingContractDialog = false;
      this.contractDialog = false;
      this.$store.dispatch("firestore/sendContractConcordo");
    },
    async sendClausulaConcordo() {
      this.sendingClausulaDialog = true;

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .update({
          clausulaDialog: false,
          // confirmIp: ip,
        });

      this.sendingClausulaDialog = false;
      this.clausulaDialog = false;
      this.$store.dispatch("firestore/sendClausulaConcordo");
    },
    async submitEval() {
      if (this.ratingEstet == null && this.knowsEstet) {
        this.warnRatingEstet = true;
      } else {
        this.warnRatingEstet = false;
      }

      if (this.ratingRecip == null) {
        this.warnRatingRecip = true;
      } else {
        this.warnRatingRecip = false;
      }

      if (this.ratingTotal == null) {
        this.warnRatingTotal = true;
      } else {
        this.warnRatingTotal = false;
      }

      if (this.ratingTrata == null) {
        this.warnRatingTrata = true;
      } else {
        this.warnRatingTrata = false;
      }

      if (this.ratingCarda == null) {
        this.warnRatingCarda = true;
      } else {
        this.warnRatingCarda = false;
      }

      if (this.ratingNutri == null) {
        this.warnRatingNutri = true;
      } else {
        this.warnRatingNutri = false;
      }

      if (this.ratingSoftw == null) {
        this.warnRatingSoftw = true;
      } else {
        this.warnRatingSoftw = false;
      }

      if (
        !this.warnRatingNutri &&
        !this.warnRatingTotal &&
        !this.warnRatingTrata &&
        !this.warnRatingCarda &&
        !this.warnRatingEstet &&
        !this.warnRatingSoftw &&
        !this.warnRatingRecip
      ) {
        var now = new Date();
        var nowMillis = now.getTime();
        await firebase
          .firestore()
          .collection("users")
          .doc(this.nutri)
          .collection("pacientes")
          .doc(sessionStorage.getItem("current-user"))
          .update({
            ratingTotal: this.ratingTotal,
            ratingTrata: this.ratingTrata,
            ratingEstet: this.ratingEstet,
            ratingRecip: this.ratingRecip,
            ratingNutri: this.ratingNutri,
            ratingSoftw: this.ratingSoftw,
            ratingCarda: this.ratingCarda,
            evalMillis: nowMillis,
            probRec: this.probRec,
          });

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .update({
            evalExists: true,
          });

        if (this.comentSugest !== null && this.comentSugest !== "") {
          await firebase
            .firestore()
            .collection("users")
            .doc(this.nutri)
            .collection("coment")
            .add({
              coment: this.comentSugest,
              evalMillis: nowMillis,
            });
        }

        if (this.comentCentral !== null && this.comentCentral !== "") {
          await firebase.firestore().collection("comentCentral").add({
            coment: this.comentCentral,
            evalMillis: nowMillis,
            franq: this.nutri,
          });
        }

        this.evalDialog = false;
      }
    },
  },
  mounted() {
    this.logo = require("@/assets/logoCompleto.png");
    this.evalDialog = this.needsEval;
    this.contractDialog = this.userInfo.contractDialog;
    this.clausulaDialog = this.userInfo.clausulaDialog;
    this.getHiddenInfo();
    this.getUserCollections();
    // setInterval(() => {
    //   this.getUserCollections();
    // }, 15 * 60 * 1000);
    setInterval(() => {
      this.getHiddenInfo();
    }, 60000);
    setTimeout(() => {
      this.expiredSession = true;
    }, 1 * 60 * 60 * 1000);
  },
};
</script>
