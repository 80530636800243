<template>
  <div>
    <v-dialog v-model="visualizarDialog" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title>
          {{ formulaPVis.nome }}, ({{ formulaPVis.dateStr }})
        </v-card-title>
        <v-card-text>
          <v-card class="mx-2 my-2">
            <v-row class="mx-2"> Nome: {{ formulaPVis.pacienteName }} </v-row>
            <v-row class="mx-2">
              Telefone: {{ formulaPVis.pacienteTelefone }}
            </v-row>
            <v-row class="mx-2">
              WhatsApp: {{ formulaPVis.pacienteWhatsapp }}
            </v-row>
          </v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel
              v-for="formulaD in formulaPVis.prescricaoC.formulasList"
              :key="formulaD.titulo"
            >
              <v-expansion-panel-header>
                {{ formulaD.titulo }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-textarea readonly v-model="formulaD.prescricoes">
                </v-textarea>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visualizarDialogE" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title>
          {{ examPVis.nome }}, ({{ examPVis.dateStr }})
        </v-card-title>
        <v-card-text>
          <v-card class="mx-2 my-2" outlined>
            <v-row class="mx-2"> Nome: {{ examPVis.pacienteName }} </v-row>
            <v-row class="mx-2">
              Telefone: {{ examPVis.pacienteTelefone }}
            </v-row>
            <v-row class="mx-2">
              WhatsApp: {{ examPVis.pacienteWhatsapp }}
            </v-row>
          </v-card>
          <v-card outlined>
            <v-card-text>
              {{ examPVis.examText }}
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="d-flex justify-center">
      <v-card width="95%" outlined>
        <v-card-title class="d-flex justify-center"
          >Área do parceiro do Clube Da Nutricionista</v-card-title
        >
        <v-card-subtitle class="d-flex justify-center">{{
          userInfo.nome
        }}</v-card-subtitle>
        <v-card-text class="d-flex justify-center">
          <v-btn
            :disabled="!userInfo.seesFormulas"
            :color="btnColorF.btn"
            :class="btnColorF.class"
            @click="getFormulas"
          >
            <v-icon left color="#614021">mdi-text-box-outline</v-icon>
            Ver Fórmulas</v-btn
          >
          <v-btn
            :disabled="!userInfo.seesExames"
            :color="btnColorE.btn"
            :class="btnColorE.class"
            @click="getExames"
          >
            <v-icon left color="#614021">mdi-needle</v-icon>
            Ver Exames</v-btn
          >
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="d-flex justify-center" v-if="loadedF">
      <v-card outlined width="95%">
        <v-card-title class="text-h6">
          <v-row class="mx-4">
            <v-col cols="8"> Fórmulas prescritas </v-col>
            <v-col cols="4">
              <!-- <v-row> Faturamento: {{ faturamento }} </v-row> -->
              <!-- <v-row class="text-subtitle-1">
              Comissão (20%): {{ comissao }}
            </v-row> -->
              <v-row class="d-flex justify-center">
                <v-btn
                  class="mx-1"
                  color="#c5d13f"
                  @click="show2023"
                  v-if="showList2023"
                  >2023</v-btn
                >
                <v-btn class="mx-1" color="grey" @click="show2023" v-else
                  >2023</v-btn
                >

                <v-btn
                  class="mx-1"
                  color="#c5d13f"
                  @click="show2022"
                  v-if="showList2022"
                  >2022</v-btn
                >
                <v-btn class="mx-1" color="grey" @click="show2022" v-else
                  >2022</v-btn
                >

                <v-btn
                  class="mx-1"
                  color="#c5d13f"
                  @click="show2021"
                  v-if="showList2021"
                  >2021</v-btn
                >
                <v-btn class="mx-1" color="grey" @click="show2021" v-else
                  >2021</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card
            v-for="formula in formulas"
            :key="formula.titulo"
            outlined
            :color="getColor(formula)"
          >
            <v-row class="mx-4 d-flex align-center">
              <v-col cols="5" class="text-subtitle-1">
                <v-row>
                  {{ formula.pacienteName }} ({{ formula.dateStr }})
                </v-row>
                <v-row>
                  <small>
                    <v-icon color="black" x-small>mdi-phone</v-icon
                    >{{ formula.pacienteTelefone }},
                    <v-icon color="black" x-small>mdi-whatsapp</v-icon>
                    {{ formula.pacienteWhatsapp }}
                  </small>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#614021"
                      v-bind="attrs"
                      v-on="on"
                      @click="downloadPdf(formula)"
                    >
                      <v-icon color="#c5d13f">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Baixar Prescrição</span>
                </v-tooltip>
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#c5d13f"
                      v-bind="attrs"
                      v-on="on"
                      @click="setPrescricaoVisualizar(formula)"
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="#614021">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar Prescrição</span>
                </v-tooltip></v-col
              >
              <v-col cols="4">
                <v-switch
                  inset
                  :value="valueEnc(formula)"
                  color="#614021"
                  :label="labelEnc(formula)"
                  :disabled="valueEnc(formula)"
                  @change="setPrescricaoEnc(formula)"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="d-flex justify-center" v-else-if="!loadedF && loadingF">
      <v-card outlined width="95%">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-center"> Carregando fórmulas ... </v-row>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="d-flex justify-center" v-if="loadedE">
      <v-card outlined width="95%">
        <v-card-title class="text-h6">
          <v-row class="mx-4">
            <v-col cols="8"> Exames prescritas </v-col>
            <v-col cols="4">
              <v-row class="d-flex justify-center">
                <v-btn
                  class="mx-1"
                  color="#c5d13f"
                  @click="show2023"
                  v-if="showList2023"
                  >2023</v-btn
                >
                <v-btn class="mx-1" color="grey" @click="show2023" v-else
                  >2023</v-btn
                >

                <v-btn
                  class="mx-1"
                  color="#c5d13f"
                  @click="show2022"
                  v-if="showList2022"
                  >2022</v-btn
                >
                <v-btn class="mx-1" color="grey" @click="show2022" v-else
                  >2022</v-btn
                >

                <v-btn
                  class="mx-1"
                  color="#c5d13f"
                  @click="show2021"
                  v-if="showList2021"
                  >2021</v-btn
                >
                <v-btn class="mx-1" color="grey" @click="show2021" v-else
                  >2021</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card
            v-for="exam in exames"
            :key="exam.id"
            outlined
            :color="getColor(exam)"
          >
            <v-row class="mx-4 d-flex align-center">
              <v-col cols="5" class="text-subtitle-1">
                <v-row> {{ exam.pacienteName }} ({{ exam.dateStr }}) </v-row>
                <v-row>
                  <small>
                    <v-icon color="black" x-small>mdi-phone</v-icon
                    >{{ exam.pacienteTelefone }},
                    <v-icon color="black" x-small>mdi-whatsapp</v-icon>
                    {{ exam.pacienteWhatsapp }}
                  </small>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#614021"
                      v-bind="attrs"
                      v-on="on"
                      @click="downloadPdfE(exam)"
                    >
                      <v-icon color="#c5d13f">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Baixar Exame</span>
                </v-tooltip>
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#c5d13f"
                      v-bind="attrs"
                      v-on="on"
                      @click="setExameVisualizar(exam)"
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="#614021">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar Exame</span>
                </v-tooltip></v-col
              >
              <v-col cols="4">
                <v-switch
                  inset
                  :value="valueEnc(exam)"
                  color="#614021"
                  :label="labelEnc(exam)"
                  :disabled="valueEnc(exam)"
                  @change="setExamEnc(exam)"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="d-flex justify-center" v-else-if="!loadedE && loadingE">
      <v-card outlined width="95%">
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-center"> Carregando fórmulas ... </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase";
import jspdf from "jspdf";
import "jspdf-autotable";
import fontCode from "@/components/Montserrat-Medium-normal";

export default {
  data() {
    return {
      //   formulas: [],
      showList2023: true,
      showList2022: false,
      showList2021: false,
      visualizarDialog: false,
      visualizarDialogE: false,
      panel: [],
      faturamento: "",
      comissao: "",
      preloadLogo: new Image(),
      preloadSignature: new Image(),
      seeE: false,
      seeF: false,
      formulas: [],
      exames: [],
      loadingF: false,
      loadedF: false,
      loadingE: false,
      loadedE: false,
      formulaPVis: {
        examText: null,
        pacienteName: null,
        pacienteTelefone: null,
        pacienteWhatsapp: null,
        dateStr: null,
        nome: null,
        prescricaoC: {
          formulasList: [
            {
              titulo: null,
              prescricoes: null,
            },
          ],
        },
      },
      examPVis: {
        examText: null,
        pacienteName: null,
        pacienteTelefone: null,
        pacienteWhatsapp: null,
        dateStr: null,
        nome: null,
      },
    };
  },
  computed: {
    // formulaPVis() {
    //   return this.$store.state.firestore.formulaPVis;
    // },
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    btnColorE() {
      if (this.loadedE) {
        return {
          btn: "#c5d13f",
          class: "mx-4",
        };
      } else {
        return {
          btn: "#AEB6BF",
          class: "mx-4 white--text",
        };
      }
    },
    btnColorF() {
      if (this.loadedF) {
        return {
          btn: "#c5d13f",
          class: "mx-4",
        };
      } else {
        return {
          btn: "#AEB6BF",
          class: "mx-4 white--text",
        };
      }
    },
    // seesOnlyF() {
    //   var seesF = this.userInfo.seesFormulas;
    //   var seesE = this.userInfo.seesExames;
    //   return seesF && !seesE;
    // },
    // seesOnlyE() {
    //   var seesF = this.userInfo.seesFormulas;
    //   var seesE = this.userInfo.seesExames;
    //   return !seesF && seesE;
    // },
    // seesBoth() {
    //   var seesF = this.userInfo.seesFormulas;
    //   var seesE = this.userInfo.seesExames;
    //   return seesF && seesE;
    // },
    // formulas() {
    //   var lista = this.$store.state.firestore.prescricoes;
    //   var datedLista = lista.map(function (doc) {
    //     var day = doc.dateStr.substring(0, 2);
    //     var month = doc.dateStr.substring(3, 5);
    //     var year = doc.dateStr.substring(6, 10);
    //     var d = new Date(year + "-" + month + "-" + day + " 08:00:00");
    //     var millis = d.getTime();
    //     doc.millis = millis; //year + "-" + month + "-" + day + " 08:00:00";
    //     return doc;
    //   });
  },
  methods: {
    show2023() {
      this.showList2021 = false;
      this.showList2022 = false;
      this.showList2023 = true;
    },
    show2022() {
      this.showList2021 = false;
      this.showList2022 = true;
      this.showList2023 = false;
    },
    show2021() {
      this.showList2021 = true;
      this.showList2022 = false;
      this.showList2023 = false;
    },
    labelEnc(form) {
      if (sessionStorage.getItem("current-user") == "eemcphee@gmail.com") {
        if (form.enc) {
          return "contactado";
        } else {
          return "não contactado";
        }
      } else {
        if (form.contacts.includes(sessionStorage.getItem("current-user"))) {
          return "contactado";
        } else {
          return "não contactado";
        }
      }
    },
    getColor(form) {
      if (sessionStorage.getItem("current-user") == "eemcphee@gmail.com") {
        if (form.enc) {
          return "rgba(0, 250, 0, 0.3)";
        } else {
          return "rgba(250, 0, 0, 0.3)";
        }
      } else {
        if (form.contacts.includes(sessionStorage.getItem("current-user"))) {
          return "rgba(0, 250, 0, 0.3)";
        } else {
          return "rgba(250, 0, 0, 0.3)";
        }
      }
    },
    valueEnc(form) {
      if (sessionStorage.getItem("current-user") == "eemcphee@gmail.com") {
        return form.enc;
      } else {
        return form.contacts.includes(sessionStorage.getItem("current-user"));
      }
    },
    niceString(valor) {
      var valorAbs = Math.abs(valor);
      var valorKomma;

      var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valorAbs);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valorAbs / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    async setPrescricaoEnc(form) {
      if (sessionStorage.getItem("current-user") == "eemcphee@gmail.com") {
        await firebase
          .firestore()
          .collection("pharmapele")
          .doc(form.id)
          .update({
            enc: true,
          });
      } else {
        var contactsArray = form.contacts;
        console.log("contactsArray", contactsArray);
        // await firebase
        //   .firestore()
        //   .collection("users")
        //   .doc(this.userInfo.introducedBy)
        //   .collection("formulasPublic")
        //   .doc(id)
        //   .get()
        //   .then(function (doc) {
        //     return doc.data().contacts;
        //   });
        contactsArray.push(sessionStorage.getItem("current-user"));
        console.log("pushedArray", contactsArray);
        await firebase
          .firestore()
          .collection("users")
          .doc(this.userInfo.introducedBy)
          .collection("formulasPublic")
          .doc(form.id)
          .update({
            contacts: contactsArray,
          });
      }

      this.getFormulas();
    },
    async setExamEnc(exam) {
      var contactsArray = exam.contacts;
      contactsArray.push(sessionStorage.getItem("current-user"));
      await firebase
        .firestore()
        .collection("users")
        .doc(this.userInfo.introducedBy)
        .collection("examesPublic")
        .doc(exam.id)
        .update({
          contacts: contactsArray,
        });
      this.getExames();
    },
    // getFaturamento() {
    //   var faturamento = 0;
    //   this.formulas.forEach(function (item) {
    //     if (item.enc) {
    //       faturamento += parseFloat(item.orcamento);
    //     } else {
    //       faturamento += 0;
    //     }
    //   });
    //   faturamento = Math.round(faturamento * 100) / 100;
    //   var comissao = Math.round(faturamento * 0.2 * 100) / 100;

    //   this.faturamento = this.niceString(faturamento);
    //   this.comissao = this.niceString(comissao);
    // },
    async getFormulas() {
      this.loadedE = false;
      this.loadedF = false;
      this.loadingF = true;

      function compare(a, b) {
        if (a.millis < b.millis) {
          return 1;
        }
        if (a.millis > b.millis) {
          return -1;
        }
        return 0;
      }

      if (sessionStorage.getItem("current-user") == "eemcphee@gmail.com") {
        var prescricoes = await firebase
          .firestore()
          .collection("pharmapele")
          .get()
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return doc.data();
            })
          );

        var lista = prescricoes;
        var datedLista = lista.map(function(doc) {
          var day = doc.dateStr.substring(0, 2);
          var month = doc.dateStr.substring(3, 5);
          var year = doc.dateStr.substring(6, 10);
          var d = new Date(year + "-" + month + "-" + day + " 08:00:00");
          var millis = d.getTime();
          doc.millis = millis; //year + "-" + month + "-" + day + " 08:00:00";
          return doc;
        });

        var filteredList;
        if (this.showList2021) {
          filteredList = datedLista.filter(function(doc) {
            return doc.millis < 1641024000000;
          });
        } else if (this.showList2022) {
          filteredList = datedLista.filter(function(doc) {
            return doc.millis > 1641024000000;
          });
        } else if (this.showList2023) {
          filteredList = datedLista.filter(function(doc) {
            return doc.millis > 1672531200000;
          });
        }

        var sortedList = filteredList.sort(compare);
        // console.log(sortedList[0]);

        this.formulas = sortedList;
        this.loadingF = false;
        this.loadedF = true;
      } else {
        var prescricoesR = await firebase
          .firestore()
          .collection("users")
          .doc(this.userInfo.introducedBy)
          .collection("formulasPublic")
          .get()
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return doc.data();
            })
          );

        var listaR = prescricoesR;
        var datedListaR = listaR.map(function(doc) {
          var day = doc.dateStr.substring(0, 2);
          var month = doc.dateStr.substring(3, 5);
          var year = doc.dateStr.substring(6, 10);
          var d = new Date(year + "-" + month + "-" + day + " 08:00:00");
          var millis = d.getTime();
          doc.millis = millis; //year + "-" + month + "-" + day + " 08:00:00";
          return doc;
        });

        var filteredListR;
        if (this.showList2021) {
          filteredListR = datedListaR.filter(function(doc) {
            return doc.millis < 1641024000000;
          });
        } else if (this.showList2022) {
          filteredListR = datedListaR.filter(function(doc) {
            return doc.millis > 1641024000000;
          });
        } else if (this.showList2023) {
          filteredListR = datedListaR.filter(function(doc) {
            return doc.millis > 1672531200000;
          });
        }

        var sortedListR = filteredListR.sort(compare);
        // console.log(sortedList[0]);

        this.formulas = sortedListR;
        this.loadingF = false;
        this.loadedF = true;
      }
    },
    async getExames() {
      this.loadedF = false;
      this.loadedE = false;
      this.loadingE = true;
      var dbexames = await firebase
        .firestore()
        .collection("users")
        .doc(this.userInfo.introducedBy)
        .collection("examesPublic")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );

      function compare(a, b) {
        if (a.millis < b.millis) {
          return 1;
        }
        if (a.millis > b.millis) {
          return -1;
        }
        return 0;
      }

      var datedListaE = dbexames.map(function(doc) {
        var day = doc.dateStr.substring(0, 2);
        var month = doc.dateStr.substring(3, 5);
        var year = doc.dateStr.substring(6, 10);
        var d = new Date(year + "-" + month + "-" + day + " 08:00:00");
        var millis = d.getTime();
        doc.millis = millis; //year + "-" + month + "-" + day + " 08:00:00";
        return doc;
      });

      var filteredListE;
      if (this.showList2021) {
        filteredListE = datedListaE.filter(function(doc) {
          return doc.millis < 1641024000000;
        });
      } else if (this.showList2022) {
        filteredListE = datedListaE.filter(function(doc) {
          return doc.millis > 1641024000000;
        });
      } else if (this.showList2023) {
        filteredListE = datedListaE.filter(function(doc) {
          return doc.millis > 1672531200000;
        });
      }

      var sortedListE = filteredListE.sort(compare);
      // console.log(sortedList[0]);

      this.exames = sortedListE;
      this.loadingE = false;
      this.loadedE = true;
    },
    async preloadImages() {
      //   console.log(this.$store.state.firestore.pacienteNutriLogo);
      //   this.preloadLogo.crossOrigin = "anonymous";
      //   this.preloadSignature.crossOrigin = "anonymous";
      //   this.preloadLogo.src = `${this.$store.state.firestore.pacienteNutriLogo}`;
      //   this.preloadSignature.src = `${this.$store.state.firestore.pacienteNutriSign}`;

      this.preloadSignature.src = require("@/assets/signature_none.jpg");
      this.preloadLogo.src = require("@/assets/logoCompleto.png");

      this.preloadLogo.onload = function() {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    downloadPdfE: async function(exame) {
      var pacienteName = exame.pacienteName;

      alert("Gerando PDF");
      // links
      // https://mrrio.github.io/jsPDF/examples/basic.html
      // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
      // https://simonbengtsson.github.io/jsPDF-AutoTable/
      // https://www.npmjs.com/package/jspdf-autotable

      var doc = new jspdf("p");

      // Doc settings
      var fontSize = 10;
      doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
      doc.addFont(
        "Montserrat-Medium-normal.ttf",
        "Montserrat-Medium",
        "normal"
      );
      doc.setFontSize(fontSize);
      doc.setFont("Montserrat-Medium");

      // The (900 / 262) division is used to keep the proportion of the original image
      // New Width * (900 / 262) will keep the height proportion

      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function (
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc
      //         .setFont("Montserrat-Medium")
      //         .splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(
        textList,
        centered = false,
        textStart = textStartYPos
      ) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page

        for (var i = 0; i < textList.length; i++) {
          var lines = doc
            .setFont("Montserrat-Medium")
            .splitTextToSize(textList[i], 180);

          for (var j = 0; j < lines.length; j++) {
            // Check if the current line would go past the threshold
            if (textStart > pageHeightThreshold) {
              doc.addPage();
              textStart = newYStart; // Reset the text start position for the new page
            }

            // Whether to center the text or not
            if (centered) {
              centeredText(lines[j], textStart);
            } else {
              doc.text(startXPos, textStart, lines[j]);
            }

            textStart += fontSize / 2; // Increment the y position for each line of text
          }

          // Increment after each text block rather than each line
          if (!centered) {
            textStart += fontSize / 2; // This accounts for the space after each block of text
          }
        }

        return textStart; // Return the updated text start position
      };

      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      var texts = [
        `"${exame.nome}" (${exame.dateStr})`,
        `Para: ${pacienteName}`,
      ];

      var endTablePosY = createTexts(texts);

      endTablePosY = createTexts([exame.examText], false, endTablePosY + 12);

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;
      textStartYPos = imgHeight + endTablePosY + 48 + 16 + imgHeight * 1.4;

      doc.addImage({
        imageData: this.preloadSignature,
        y: endTablePosY,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          `Nutricionista ${exame.autorNome}`,
          `CRN: ${exame.autorCrn}`,
          `Data: ${exame.dateStr}`,
        ],
        true,
        endTablePosY + imgHeight // + texts.length * (fontSize * (3 / 4))
      );

      doc.save("prescricao.pdf");
    },
    downloadPdf: async function(formula) {
      // var pacienteName = formula.pacienteName;
      // var pacienteTelefone = formula.pacienteTelefone;
      // var pacienteWhatsapp = formula.pacienteWhatsapp;

      alert("Gerando PDF");
      // links
      // https://mrrio.github.io/jsPDF/examples/basic.html
      // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
      // https://simonbengtsson.github.io/jsPDF-AutoTable/
      // https://www.npmjs.com/package/jspdf-autotable

      var doc = new jspdf("p");

      // Doc settings
      var fontSize = 10;
      doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
      doc.addFont(
        "Montserrat-Medium-normal.ttf",
        "Montserrat-Medium",
        "normal"
      );
      doc.setFontSize(fontSize);
      doc.setFont("Montserrat-Medium");

      // The (900 / 262) division is used to keep the proportion of the original image
      // New Width * (900 / 262) will keep the height proportion

      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function (
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc
      //         .setFont("Montserrat-Medium")
      //         .splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(
        textList,
        centered = false,
        textStart = textStartYPos
      ) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page

        for (var i = 0; i < textList.length; i++) {
          var lines = doc
            .setFont("Montserrat-Medium")
            .splitTextToSize(textList[i], 180);

          for (var j = 0; j < lines.length; j++) {
            // Check if the current line would go past the threshold
            if (textStart > pageHeightThreshold) {
              doc.addPage();
              textStart = newYStart; // Reset the text start position for the new page
            }

            // Whether to center the text or not
            if (centered) {
              centeredText(lines[j], textStart);
            } else {
              doc.text(startXPos, textStart, lines[j]);
            }

            textStart += fontSize / 2; // Increment the y position for each line of text
          }

          // Increment after each text block rather than each line
          if (!centered) {
            textStart += fontSize / 2; // This accounts for the space after each block of text
          }
        }

        return textStart; // Return the updated text start position
      };

      // // Logo Clubedonutricionista
      //   if (this.$store.state.firestore.pacienteNutriUseLogo === false) {
      // console.log("op 1");
      doc.addImage({
        imageData: require("@/assets/logoCompleto.png"),
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });
      //   } else {
      // LOGO CLUBE
      // imgWidth = 30;
      // imgHeight = imgWidth / (900 / 262);
      // startXPos = 15;

      // doc.addImage({
      //   imageData: require("@/assets/logoCompleto.png"),
      //   y: startXPos,
      //   x: 15,
      //   w: imgWidth,
      //   h: imgHeight,
      // });

      // imgWidth = 50;
      // var startYPos = 15 + imgHeight * 2;
      // imgHeight =
      //   imgWidth /
      //   (this.preloadLogo.naturalWidth /
      //     this.preloadLogo.naturalHeight);
      // imgPos = (210 - imgWidth) / 2;
      // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

      // doc.addImage({
      //   imageData: this.preloadLogo,
      //   y: startYPos,
      //   x: imgPos,
      //   w: imgWidth,
      //   h: imgHeight,
      // });
      //   }

      var texts = [
        `"${formula.nome}" (${formula.dateStr})`,
        `Para: ${formula.pacienteName} (Tel.: ${formula.pacienteTelefone}, WhatsApp: ${formula.pacienteWhatsapp})`,
      ];

      var endTablePosY = createTexts(texts);

      // for (var i = 0; i < formula.prescricaoC.formulasList.length; i++) {
      //   endTablePosY = createTexts(
      //     [
      //       formula.prescricaoC.formulasList[i].titulo,
      //       formula.prescricaoC.formulasList[i].prescricoes,
      //     ],
      //     false,
      //     endTablePosY + i * 48
      //   );
      // }

      var body = formula.prescricaoC.formulasList.map(function(formulaP) {
        if (formulaP.prescricoes != undefined) {
          return [formulaP.titulo, formulaP.prescricoes];
        } else {
          return [];
        }
      });

      doc.autoTable({
        startY: endTablePosY, //lastPos // (lastPos / 2) * 0.7 + startPosY,
        startx: startXPos,
        head: [["Nome da fórmula", "fórmula"]],
        body: body,
        headStyles: {
          fillColor: [226, 232, 159],
          textColor: 40,
          fontStyle: "regular",
        },
        bodyStyles: {
          fillColor: [243, 245, 216],
        },
        alternateRowStyles: {
          fillColor: [249, 250, 235],
        },
      });
      endTablePosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
      // USE SIGNATURE
      //   if (this.$store.state.firestore.pacienteNutriSign != "") {
      // imgWidth = 30;
      // startYPos =
      //   fontSize +
      //   (endTablePosY !== undefined
      //     ? endTablePosY + 16
      //     : textStartYPos + texts.length * (fontSize * (3 / 4)));
      // imgHeight =
      //   imgWidth /
      //   (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      // imgPos = (210 - imgWidth) / 2;
      // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

      // doc.addImage({
      // //   imageData: this.preloadSignature,
      //   imageData: require("@/assets/signature_none.jpg"),
      //   y: startYPos,
      //   x: imgPos,
      //   w: imgWidth,
      //   h: imgHeight,
      // });
      //   } else {
      //     imgHeight = 0;
      //   }

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;

      doc.addImage({
        imageData: this.preloadSignature,
        y: endTablePosY,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          // `Nutricionista ${this.$store.state.firestore.pacienteNutriName}`,
          // `CRN: ${this.$store.state.firestore.pacienteCrn}`,
          `Nutricionista ${formula.autorNome}`,
          `CRN: ${formula.autorCrn}`,
          `Data: ${formula.dateStr}`,
        ],
        true,
        // endTablePosY !== undefined
        //   ? endTablePosY +
        //       prescricaoC.formulasList.length * 48 +
        //       16 +
        //       imgHeight * 1.4
        //   :
        endTablePosY // + texts.length * (fontSize * (3 / 4))
      );

      doc.save("prescricao.pdf");
    },
    // downloadPdf2: async function (formula) {
    //   var pacienteName = this.userInfo.nome;

    //   alert("Gerando PDF");
    //   // links
    //   // https://mrrio.github.io/jsPDF/examples/basic.html
    //   // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
    //   // https://simonbengtsson.github.io/jsPDF-AutoTable/
    //   // https://www.npmjs.com/package/jspdf-autotable

    //   var doc = new jspdf("p");

    //   // Doc settings
    //   var fontSize = 12;
    //   doc.setFontSize(fontSize);
    //   doc.setFont("Times");

    //   // The (900 / 262) division is used to keep the proportion of the original image
    //   // New Width * (900 / 262) will keep the height proportion

    //   var imgWidth = 40;
    //   // var imgHeight = imgWidth / (900 / 262);
    //   var imgHeight =
    //     imgWidth /
    //     (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
    //   var imgPos = (210 - imgWidth) / 2;
    //   var startXPos = 15;
    //   var textStartYPos = imgHeight * 1.1 + 12;

    //   var centeredText = function (text, y) {
    //     var textWidth =
    //       (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
    //       doc.internal.scaleFactor;
    //     var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    //     doc.text(textOffset, y, text);
    //   };

    //   var createTexts = function (
    //     textList,
    //     centered = false,
    //     textStart = textStartYPos
    //   ) {
    //     for (var i = 0; i < textList.length; i++) {
    //       if (centered) {
    //         centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
    //       } else {
    //         var lines = doc.setFont("Roboto").splitTextToSize(textList[i], 180);
    //         // console.log(lines)
    //         for (var j = 0; j < lines.length; j++) {
    //           doc.text(
    //             startXPos,
    //             textStart + (fontSize / 2) * (1 + j),
    //             lines[j]
    //           );
    //         }
    //         textStart += (fontSize / 2) * /*1 + */ lines.length;
    //       }
    //     }
    //     return (textStart += fontSize / 2);
    //   };

    //   // // Logo Clubedonutricionista
    //   //   if (this.$store.state.firestore.pacienteNutriUseLogo === false) {
    //   // console.log("op 1");
    //   doc.addImage({
    //     imageData: require("@/assets/logoCompleto.png"),
    //     y: startXPos,
    //     x: imgPos,
    //     w: imgWidth,
    //     h: imgHeight,
    //   });
    //   //   } else {
    //   // LOGO CLUBE
    //   // imgWidth = 30;
    //   // imgHeight = imgWidth / (900 / 262);
    //   // startXPos = 15;

    //   // doc.addImage({
    //   //   imageData: require("@/assets/logoCompleto.png"),
    //   //   y: startXPos,
    //   //   x: 15,
    //   //   w: imgWidth,
    //   //   h: imgHeight,
    //   // });

    //   // imgWidth = 50;
    //   // var startYPos = 15 + imgHeight * 2;
    //   // imgHeight =
    //   //   imgWidth /
    //   //   (this.preloadLogo.naturalWidth /
    //   //     this.preloadLogo.naturalHeight);
    //   // imgPos = (210 - imgWidth) / 2;
    //   // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

    //   // doc.addImage({
    //   //   imageData: this.preloadLogo,
    //   //   y: startYPos,
    //   //   x: imgPos,
    //   //   w: imgWidth,
    //   //   h: imgHeight,
    //   // });
    //   //   }

    //   var texts = [
    //     `"${formula.nome}" (${formula.dateStr})`,
    //     `Para: ${pacienteName}`,
    //   ];

    //   var endTablePosY = createTexts(texts);

    //   // for (var i = 0; i < formula.prescricaoC.formulasList.length; i++) {
    //   // endTablePosY = createTexts(
    //   //     [
    //   //       formula.prescricaoC.formulasList[i].titulo,
    //   //       formula.prescricaoC.formulasList[i].prescricoes,
    //   //     ],
    //   //     false,
    //   //     endTablePosY + i * 48
    //   //   );
    //   // }

    //   var body = formula.prescricaoC.formulasList.map(function (formulaP) {
    //     if (formulaP.prescricoes != undefined) {
    //       return [formulaP.titulo, formulaP.prescricoes];
    //     } else {
    //       return [];
    //     }
    //   });

    //   doc.autoTable({
    //     startY: endTablePosY, //lastPos // (lastPos / 2) * 0.7 + startPosY,
    //     startx: startXPos,
    //     head: [["Nome da fórmula", "fórmula"]],
    //     body: body,
    //   });
    //   endTablePosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);

    //   // USE SIGNATURE
    //   //   if (this.$store.state.firestore.pacienteNutriSign != "") {
    //   // imgWidth = 30;
    //   // startYPos =
    //   //   fontSize +
    //   //   (endTablePosY !== undefined
    //   //     ? endTablePosY + 16
    //   //     : textStartYPos + texts.length * (fontSize * (3 / 4)));
    //   // imgHeight =
    //   //   imgWidth /
    //   //   (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
    //   // imgPos = (210 - imgWidth) / 2;
    //   // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

    //   // doc.addImage({
    //   // //   imageData: this.preloadSignature,
    //   //   imageData: require("@/assets/signature_none.jpg"),
    //   //   y: startYPos,
    //   //   x: imgPos,
    //   //   w: imgWidth,
    //   //   h: imgHeight,
    //   // });
    //   //   } else {
    //   //     imgHeight = 0;
    //   //   }

    //   imgWidth = 75;
    //   imgHeight =
    //     imgWidth /
    //     (this.preloadSignature.naturalWidth /
    //       this.preloadSignature.naturalHeight);
    //   imgPos = (210 - imgWidth) / 2;
    //   textStartYPos =
    //     imgHeight +
    //     // 4 * (imgHeight / fontSize) +
    //     endTablePosY +
    //     formula.prescricaoC.formulasList.length * 48 +
    //     16 +
    //     imgHeight * 1.4;

    //   doc.addImage({
    //     imageData: this.preloadSignature,
    //     y: endTablePosY,
    //     x: imgPos,
    //     w: imgWidth,
    //     h: imgHeight,
    //   });

    //   createTexts(
    //     [
    //       "____________________________",
    //       // `Nutricionista ${this.$store.state.firestore.pacienteNutriName}`,
    //       // `CRN: ${this.$store.state.firestore.pacienteCrn}`,
    //       `Nutricionista Thayana Albuquerque`,
    //       `CRN: 4571`,
    //       `Data: ${formula.dateStr}`,
    //     ],
    //     true,
    //     // endTablePosY !== undefined
    //     //   ? endTablePosY +
    //     //       prescricaoC.formulasList.length * 48 +
    //     //       16 +
    //     //       imgHeight * 1.4
    //     //   :
    //     endTablePosY // + texts.length * (fontSize * (3 / 4))
    //   );

    //   doc.save("prescricao.pdf");
    // },
    setPrescricaoVisualizar(form) {
      // this.$store.dispatch("firestore/getFormulaPacientePh", {
      //   docId: docId,
      // });
      this.formulaPVis = form;
      this.visualizarDialog = true;
    },
    setExameVisualizar(exam) {
      this.examPVis = exam;
      this.visualizarDialogE = true;
    },
  },
  mounted() {
    // this.getFaturamento();
    this.preloadImages();
  },
};
</script>
