<template>
  <div align="center">
    <v-dialog v-model="adminDialog" max-width="600">
      <v-card>
        <v-card-title> Entrar como Admin </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center mx-6">
            <v-text-field
              label="usuário"
              v-model="senhaAdmin1"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center mx-6">
            <v-text-field
              label="senha 2"
              v-model="senhaAdmin2"
              :append-icon="showAdmin2 ? icons.mdiEye : icons.mdiEyeOff"
              :type="showAdmin2 ? 'text' : 'password'"
              @click:append="showAdmin2 = !showAdmin2"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn large color="#c5d13f" @click="entrarAdmin">Entrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form v-model="valid">
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <!-- <v-text-field
              solo
              :prepend-icon="icons.mdiEmail"
              v-model="email"
              label="Email"
              :rules="mandatory"
              required
            ></v-text-field> -->
            <v-autocomplete
              solo
              :prepend-icon="icons.mdiEmail"
              v-model="email"
              label="Email"
              :rules="mandatory"
              :items="franqEmails"
              required
              no-data-text="..."
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-text-field
              solo
              :prepend-icon="icons.mdiLock"
              v-model="senha"
              :append-icon="show ? icons.mdiEye : icons.mdiEyeOff"
              :type="show ? 'text' : 'password'"
              :rules="mandatory"
              label="Senha"
              required
              @click:append="show = !show"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-alert
              v-show="alerts.loginOk"
              dense
              type="error"
              :icon="icons.mdiAlert"
              >Email ou senha errada</v-alert
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-row align="center">
      <v-col class="text-center" cols="12">
        <v-btn large color="#c5d13f" @click="submit">Login</v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="text-center" cols="12">
        <v-btn
          large
          class="mx-4 white--text"
          color="#614021"
          value="cadastrar"
          @click="adminDialog = true"
        ><v-icon left color="#c5d13f">mdi-hammer-wrench</v-icon>
          <span>Admin</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="text-center" cols="12">
        <v-btn
          v-show="alerts.loginOk"
          class="mx-4 white--text"
          large
          color="#614021"
          @click="resetPassword"
          >Esqueci minha senha</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog v-model="alerts.resetEmailsent" width="50%">
      <v-card>
        <v-card-title class="red white--text"
          >Verifique seu Email!</v-card-title
        >

        <v-card-text class="px-4"
          >Uma mensagem enviado para o seu Email (possívelmente na pasta
          "Notificações"). Abre a mensagem para poder criar uma nova
          senha.</v-card-text
        >

        <!-- <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="!dialoge">OK, vou verificar</v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiLock, mdiEmail, mdiEye, mdiEyeOff, mdiAlert } from "@mdi/js";

export default {
  data: function () {
    return {
      franqEmails: [
        {text: "Belém - PA", value: "umarizalbelem@clubedanutricionista.com.br"},
        {text: "Balneário Camboriú - SC", value: "bc@clubedanutricionista.com.br"},
        {text: "Fortaleza - CE", value: "fortaleza.salinas@clubedanutricionista.com.br"},
        {text: "Admin", value: "bc2@clubedanutricionista.com.br"},
        // {text: "Niterói - RJ", value: "niteroi@clubedanutricionista.com.br"},
        // {text: "Teresina - PI", value: "teresina@clubedanutricionista.com.br"},
        {text: "Conta de teste", value: "test@clubedanutricionista.com.br"},
        // {text: "Admin", value: "bc2@clubedanutricionista.com.br"},
      ],
      adminDialog: false,
      valid: false,
      show: false,
      showAdmin2: false,
      email: "",
      senha: "",
      senhaAdmin1: "",
      senhaAdmin2: "",
      mandatory: [
        (v) => !!v || "Campo obrigatório",
        // v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      icons: { mdiLock, mdiEmail, mdiEye, mdiEyeOff, mdiAlert },
    };
  },
  computed: {
    alerts() {
      return this.$store.state.firestore.alerts;
    },
  },
  methods: {
    submit() {
      this.$store
        .dispatch("firestore/userLogin", {
          email: this.email,
          password: this.senha,
        })
        .then(() => {
          console.log(this.$store.state.firestore.isAuthenticated);
        });
    },
    entrarAdmin() {
      if (this.senhaAdmin1 == "contato@clubedanutricionista.com.br") {
        this.$store.dispatch("firestore/userAdminLogin", {
          email: this.senhaAdmin1,
          password: this.senhaAdmin2,
        });
      }
    },
    resetPassword() {
      this.$store.dispatch("firestore/userResetPassword", {
        email: this.email,
      });
    },
  },
  mounted() {
    if (this.$store.state.firestore.defaultUserObj.email !== null && this.$store.state.firestore.defaultUserObj.email !== undefined) {
      this.email = this.$store.state.firestore.defaultUserObj.email;
      this.senha = this.$store.state.firestore.defaultUserObj.senha;
    }
  }
};
</script>