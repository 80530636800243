<template>
  <v-app-bar>
    <v-img contain max-height="60" max-width="180" :src="logo"></v-img>

    <v-dialog v-model="choosePapelDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center">
          Quem está online ?
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="papelOnlineChosen" column>
            <v-radio
              v-for="funcionario in funcionarios"
              :key="funcionario.papel"
              :label="labelOption(funcionario)"
              :value="funcionario.papel"
            >
            </v-radio>
          </v-radio-group>
          <!-- <v-card
            v-for="funcionario in funcionarios"
            :key="funcionario.papel"
            outlined
            class="my-2 mx-2"
            :color="chosenOneColor(funcionario)"
            @click="chooseFuncionario(funcionario)"
          >
            <v-row class="d-flex justify-center">
              <v-col cols="12" class="d-flex justify-space-between">
                <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                  {{ funcionario.papel }}, {{ funcionario.nome }}
                </v-row>
              </v-col>
            </v-row>
          </v-card> -->
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#c5d13f"
            :disabled="papelOnlineChosen == ''"
            @click="senhaDialog = true"
          >
            Entrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="senhaDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center"> Senha </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center mx-4 red--text" v-if="senhaErrada">
            Tente novamente!
          </v-row>
          <v-row class="d-flex justify-center mx-4">
            <v-text-field
              v-model="digitSenha"
              label="Senha"
              :prepend-icon="icons.mdiLock"
              :append-icon="show ? icons.mdiEye : icons.mdiEyeOff"
              :type="show ? 'text' : 'password'"
              :rules="rules.correctSenha"
              @click:append="show = !show"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#c5d13f"
            :disabled="digitSenha == ''"
            @click="setFuncionarioOnline(papelOnlineChosen)"
          >
            Entrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="roteiroDialog" max-width="400">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Primeiro contato</v-card-title
        >
        <v-card-text>
          <v-form ref="formLigacao" v-model="validLigacao">
            <v-card outlined class="my-2">
              <v-card-text>
                <v-row class="d-flex justify-center">
                  <p>Qual é seu <strong>nome</strong>?</p>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-text-field
                    color="#c5d13f"
                    outlined
                    v-model="pacienteInfos.nome"
                    :rules="rules.mandatory"
                  ></v-text-field>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card outlined class="my-2">
              <v-card-text>
                <v-row class="d-flex justify-center">
                  <p>Qual é seu <strong>objetivo</strong>?</p>
                </v-row>
                <v-row>
                  <v-autocomplete
                    color="#614021"
                    outlined
                    :prepend-icon="icons.mdiBullseyeArrow"
                    v-model="pacienteInfos.objetivo"
                    :rules="rules.mandatory"
                    :items="objetivos"
                  ></v-autocomplete>
                </v-row>
                <v-row class="d-flex justify-center">
                  <p>Situação atual / Detalhes importantes</p>
                </v-row>
                <v-row>
                  <v-textarea
                    color="#614021"
                    :prepend-icon="icons.mdiEarHearing"
                    outlined
                    v-model="pacienteInfos.detalhesImportantes"
                  ></v-textarea>
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-icon
                    class="mx-2"
                    color="#ff00ff"
                    left
                    v-if="pacienteInfos.sexo == 'feminino'"
                    >mdi-gender-female</v-icon
                  >
                  <v-icon
                    class="mx-2"
                    color="#0080ff"
                    left
                    v-if="pacienteInfos.sexo == 'masculino'"
                    >mdi-gender-male</v-icon
                  >
                  Sexo
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-radio-group v-model="pacienteInfos.sexo" column>
                    <v-radio
                      label="feminino"
                      value="feminino"
                      color="#ff00ff"
                    ></v-radio>
                    <v-radio
                      label="masculino"
                      value="masculino"
                      color="#0080ff"
                    ></v-radio>
                  </v-radio-group>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card outlined class="my-2">
              <v-card-text>
                <v-row class="d-flex justify-center">
                  <p>As <strong>vantagens</strong> do Clube são:</p>
                </v-row>
                <v-row class="d-flex justify-center"
                  >Tratamento personalizado</v-row
                >
                <v-row class="d-flex justify-center">Nutrição funcional</v-row>
                <v-row class="d-flex justify-center"
                  >Consolidar o resultado com estética</v-row
                >
                <v-row class="d-flex justify-center">... </v-row>
              </v-card-text>
            </v-card>
            <v-card outlined class="my-2">
              <v-card-text>
                <v-row class="d-flex justify-center">
                  <p>O <strong>valor</strong> da Consulta/Tratamento é:</p>
                </v-row>
                <v-row class="d-flex justify-center">
                  <p>...</p>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card outlined class="my-2">
              <v-card-text>
                <v-row class="d-flex justify-center">
                  <p><strong>Manter contato</strong></p>
                </v-row>
                <v-row class="d-flex justify-center">
                  <p>Por <strong>telefone</strong>:</p>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-text-field
                    outlined
                    color="#c5d13f"
                    :prepend-icon="icons.mdiPhone"
                    v-model="pacienteInfos.telefone"
                  ></v-text-field>
                </v-row>
                <v-row class="d-flex justify-center">
                  <p>Por <strong>whatsapp</strong></p>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-text-field
                    outlined
                    color="#c5d13f"
                    :prepend-icon="icons.mdiWhatsapp"
                    v-model="pacienteInfos.whatsapp"
                  ></v-text-field>
                </v-row>
                <v-row class="d-flex justify-center">
                  <p>Por <strong>email</strong></p>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-text-field
                    outlined
                    color="#c5d13f"
                    :prepend-icon="icons.mdiEmail"
                    v-model="pacienteInfos.email"
                  ></v-text-field>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-autocomplete
                    outlined
                    color="#c5d13f"
                    :items="meios"
                    label="Meio de contato"
                    :prepend-icon="icons.mdiAccountVoice"
                    v-model="pacienteInfos.meioContato"
                    :rules="rules.mandatory"
                  >
                  </v-autocomplete>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-text-field
                    label="Horário da ligação"
                    type="time"
                    outlined
                    :rules="rules.mandatory"
                    v-model="pacienteInfos.horarioLigacao"
                  ></v-text-field>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-autocomplete
                    class="mt-4"
                    :prepend-icon="icons.mdiAccountClock"
                    v-model="pacienteInfos.motivoRecusao"
                    :rules="rules.mandatory"
                    :items="motivos"
                    label="Motivo pela recusão"
                    outlined
                  ></v-autocomplete>
                </v-row>
                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.motivoRecusao == 'Outros'"
                >
                  <v-text-field
                    outlined
                    :prepend-icon="icons.mdiAccountClock"
                    v-model="pacienteInfos.motivoRecusaoOutro"
                    :items="motivos"
                    label="Motivo pela recusão"
                  ></v-text-field>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="roteiroDialog = false" color="#D6DBDF">Fechar</v-btn>
          <v-btn color="#c5d13f" @click="submitNonConverted"
            >Salvar prospecto</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="expiredSession" persistent max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Sessão expirada!</v-card-title
        >
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="#c5d13f">mdi-clock-alert-outline</v-icon>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            @click="restartSession"
            :disabled="restarting"
            class="white--text"
          >
            <v-progress-circular
              v-if="restarting"
              :size="20"
              :width="3"
              color="#c5d13f"
              indeterminate
            ></v-progress-circular>
            <v-icon color="#c5d13f" v-else left>mdi-home-export-outline</v-icon>
            <span v-if="!restarting">continuar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="metaDialog" width="80%">
      <v-card v-if="notReady">
        <v-card-title class="d-flex justify-center">
          Calculando contribuições...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>Meta mínima do nosso Clube</v-card-title>
        <v-card-text>
          <v-row class="d-flex align-center mx-1">
            <v-col cols="12" md="4">
              <v-row class="d-flex justify-center text-h6">Faturamento: </v-row
              ><v-row class="d-flex justify-center text-h6">{{
                niceString(faturamento)
              }}</v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-row class="d-flex justify-center text-h6">Meta mínima:</v-row
              ><v-row class="d-flex justify-center text-h6">
                {{ niceString(30000) }}</v-row
              >
            </v-col>
            <v-col cols="12" md="4" v-if="diferenca < 0">
              <v-row class="d-flex justify-center subtitle-1"
                >Faturamos quantas vezes a meta mínimo:</v-row
              >
              <v-row class="d-flex justify-center green--text text-h6">{{
                vezes
              }}</v-row>
            </v-col>
            <v-col cols="12" md="4" v-else class="red--text">
              <v-row class="d-flex justify-center subtitle-1"
                >A faturar até final do mês:</v-row
              >
              <v-row class="d-flex justify-center red--text text-h6">{{
                niceString(diferenca)
              }}</v-row>
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Software/App: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingSoftw"
                    color="#c5d13f"
                    background-color="#614021"
                    dense
                    readonly
                    large
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Cardápios: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingCarda"
                    color="#c5d13f"
                    background-color="#614021"
                    dense
                    readonly
                    large
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Resultados do tratamento: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingTrata"
                    color="#c5d13f"
                    background-color="#614021"
                    readonly
                    large
                    dense
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined>
                <v-row class="d-flex justify-center black--text"
                  >Experiência como um todo: </v-row
                ><v-row class="d-flex justify-center">
                  <v-rating
                    v-model="ratingTotal"
                    color="#c5d13f"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-row class="mx-4">
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="8"> Contribuição Propretário </v-col>
                <v-col cols="4">
                  {{ niceString(contribuicaoPropretario) }}
                </v-col>
              </v-row>
            </v-card>
          </v-row> -->
          <v-row
            class="d-flex justify-center mx-1"
            v-if="funcInclNutricionista1"
          >
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Nutricionista 1 </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoNutricionista1) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>de todos os nutris</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingNutri"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row
            class="d-flex justify-center mx-1"
            v-if="funcInclNutricionista2"
          >
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Nutricionista 2 </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoNutricionista2) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>de todos os nutris</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingNutri"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row
            class="d-flex justify-center mx-1"
            v-if="funcInclNutricionista3"
          >
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Nutricionista 3 </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoNutricionista3) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>de todos os nutris</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingNutri"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row
            class="d-flex justify-center mx-1"
            v-if="funcInclRecepcionista"
          >
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Recepcionista </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoRecepcionista) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>da recepcionista</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingRecip"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="d-flex justify-center mx-1" v-if="funcInclEsteticista">
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Esteticista </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoEsteticista) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <v-row>Avaliação</v-row
                  ><v-row><small>da esteticista</small></v-row>
                </v-col>
                <v-col cols="4">
                  <v-rating
                    v-model="ratingEstet"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="d-flex justify-center mx-1" v-if="funcInclEstagiaria">
            <v-card outlined class="my-2 mx-4" width="90%">
              <v-row class="d-flex align-center">
                <v-col cols="4"> Contribuição Estagiária </v-col>
                <v-col cols="2">
                  {{ niceString(contribuicaoEstagiaria) }}
                </v-col>
                <v-col cols="2" class="text-caption">
                  <!-- <v-row>Avaliação</v-row
                  ><v-row><small>da esteticista</small></v-row> -->
                </v-col>
                <v-col cols="4">
                  <!-- <v-rating
                    v-model="ratingEstet"
                    color="yellow darken-3"
                    background-color="#614021"
                    readonly
                    dense
                    large
                  ></v-rating> -->
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="closeMetaDialog">{{ btnText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          class="mx-2 blue white--text"
          v-bind="attrs"
          v-on="on"
          @click="showPlanosDialog = true"
          >{{ userInfos.plano }}</v-chip
        >
      </template>
      <span>Ver todos os planos deste Software!</span>
    </v-tooltip> -->

    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          class="mx-2 white--text"
          color="#c5d13f"
          v-bind="attrs"
          v-on="on"
          href="https://www.clubedanutricionista.com.br/franquia"
          >FRANQUIA</v-chip
        >
      </template>
      <span>Seja Franqueado!</span>
    </v-tooltip> -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          class="mx-2 white--text"
          color="#c5d13f"
          v-bind="attrs"
          v-on="on"
          @click="roteiroDialog = true"
          v-if="isFranquia"
        >
          <v-icon color="white" left small>mdi-phone-classic</v-icon>
          Chamada</v-chip
        >
      </template>
      <span>Roteiro da primeira conversa</span>
    </v-tooltip>

    <v-spacer></v-spacer>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1"
          value="patients"
          @mouseover="pacientesMouse = true"
          @mouseleave="pacientesMouse = false"
          v-bind="attrs"
          v-on="on"
          color="#c5d13f"
        >
          <v-tooltip bottom v-model="pacientesMouse">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="#614021" left v-bind="attrs" v-on="on">{{
                icons.mdiAccountGroup
              }}</v-icon>
            </template>
            <span>Ver Pacientes / Iniciar atendimento</span>
          </v-tooltip>
          Pacientes
        </v-btn>
      </template>
      <v-list>
        <v-list-item to="/PacienteNovo">
          <v-list-item-icon>
            <v-icon>{{ icons.mdiAccountPlus }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cadastrar novo</v-list-item-title>
        </v-list-item>
        <v-list-item to="MeusPacientes">
          <v-list-item-icon>
            <v-icon>{{ icons.mdiMagnify }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            >Meus pacientes + Iniciar atendimento</v-list-item-title
          >
        </v-list-item>
        <v-list-item to="PacientesLista">
          <v-list-item-icon>
            <v-icon>mdi-folder-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Lista de Pacientes</v-list-item-title>
        </v-list-item>
        <v-list-item to="Prospectos">
          <v-list-item-icon>
            <v-icon>mdi-account-question</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Prospectos</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1"
          value="agenda"
          v-bind="attrs"
          v-on="on"
          color="#c5d13f"
        >
          <v-icon color="#614021" left>{{ icons.mdiCalendar }}</v-icon
          >Agenda
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <v-btn to="/Agenda" text>
              <v-icon color="#614021" left>{{ icons.mdiCalendar }}</v-icon
              >Consultas</v-btn
            ></v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            ><v-btn to="/AgendaEstetica" text>
              <v-icon color="#614021" left>{{ icons.mdiCalendar }}</v-icon
              >Estética</v-btn
            ></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer></v-spacer>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1 text-caption"
          value="patients"
          v-bind="attrs"
          v-on="on"
          color="#c5d13f"
          max-width="250"
          @click="choosePapelDialog = true"
        >
          <v-icon color="#614021" left>{{
            icons.mdiAccountHeartOutline
          }}</v-icon>
          {{ funcionarioOnline.papel }}
        </v-btn>
      </template>
      <span>Quem está ativo?</span>
    </v-tooltip>

    <v-btn
      class="mx-1 white--text"
      color="#614021"
      value="logout"
      to="/"
      @click="logout"
    >
      <span>Logout</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {
  mdiCalendar,
  mdiMagnify,
  mdiAccountPlus,
  mdiAccountGroup,
  mdiAccountHeartOutline,
  mdiCardAccountDetailsOutline,
  mdiLock,
  mdiEye,
  mdiEyeOff,
  mdiAccountVoice,
  mdiWhatsapp,
  mdiPhone,
  mdiBullseyeArrow,
  mdiEarHearing,
} from "@mdi/js";

import firebase from "firebase";
import router from "../../router";

export default {
  data: function () {
    return {
      vendedores: [
        // "Nutricionista 1",
        // "Nutricionista 2",
        // "Recepcionista",
        // "Esteticista",
      ],
      metaDialog: false,
      btnText: "",
      faturamento: 0,
      diferenca: 0,
      vezes: 0,
      funcInclNutricionista1: true,
      funcInclNutricionista2: true,
      funcInclNutricionista3: false,
      funcInclRecepcionista: true,
      funcInclEsteticista: true,
      funcInclEstagiaria: false,
      contribuicaoPropretario: 0,
      contribuicaoNutricionista1: 0,
      contribuicaoNutricionista2: 0,
      contribuicaoNutricionista3: 0,
      contribuicaoRecepcionista: 0,
      contribuicaoEsteticista: 0,
      contribuicaoEstagiaria: 0,
      ratingNutri: 0,
      ratingEstet: 0,
      ratingRecip: 0,
      ratingTotal: 0,
      ratingTrata: 0,
      ratingSoftw: 0,
      ratingCarda: 0,
      notReady: true,
      validLigacao: false,
      progressDialog: false,
      saved: false,
      rules: {
        mandatory: [(v) => !!v || "Campo obrigatório"],
        // passwordLength: [(v) => v.length >= 8 || "Senha com mais que 8 letras"],
        emailRules: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+/.test(v) || "E-mail invalido",
          (v) => /^\S*$/.test(v) || "E-mail com espaço",
        ],
        correctSenha: [(v) => !!v || "Senha errada!"],
      },
      objetivos: [
        "Emagrecer",
        "Tratar um Problema de Saúde",
        "Ganhar Massa Magra",
        "Procedimento Estético",
        "Exames",
      ],
      meios: [
        // meio de contato para agendamento
        "Ligou para telefone fixo",
        "Ligou para celular",
        "Ligou para WhatsApp",
        "Mandou mensagem no WhatsApp",
        "Mandou mensagem no Instagram",
        "Agendou direto na recepção",
      ],
      motivos: ["Buscava plano de saúde", '"Vou pensar..."', "Outros"],
      pacienteInfos: {
        gestante: false,
        sexo: "feminino",
        telefone: "",
        whatsapp: "",
        nome: null,
        email: null,
        objetivo: null,
        motivoRecusao: null,
        motivoRecusaoOutro: null,
        meioContato: null,
        detalhesImportantes: null,
        horarioLigacao: null,
      },
      roteiroDialog: false,
      logo: null,
      userInfos: {},
      show: false,
      digitSenha: "",
      senhaErrada: false,
      senhaDialog: false,
      choosePapelDialog: true,
      papelOnlineChosen: "",
      showPlanosDialog: false,
      pacientesMouse: false,
      expiredSession: false,
      restarting: false,
      icons: {
        mdiCalendar,
        mdiAccountPlus,
        mdiMagnify,
        mdiAccountGroup,
        mdiAccountHeartOutline,
        mdiCardAccountDetailsOutline,
        mdiLock,
        mdiEye,
        mdiEyeOff,
        mdiAccountVoice,
        mdiWhatsapp,
        mdiPhone,
        mdiBullseyeArrow,
        mdiEarHearing,
      },
    };
  },
  firestore() {
    return {
      userInfos: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user")),
    };
  },
  computed: {
    caixa() {
      return this.$store.state.firestore.caixa;
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    funcionarios() {
      return this.$store.state.firestore.funcionarios;
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
  },
  methods: {
    async restartSession() {
      this.restarting = true;
      await firebase
        .auth()
        .signOut()
        .then(function () {
          router.replace("/login");
        })
        .catch(function (error) {
          console.log(error);
        });
      this.restarting = false;
      sessionStorage.setItem("current-user", null);
      sessionStorage.removeItem("isAuthenticated");
    },
    submitNonConverted: function () {
      if (this.$refs.formLigacao.validate()) {
        this.saved = false;
        this.progressDialog = true;
        console.log(this.pacienteInfos);
        // if (this.$refs.form.validate()) {

        this.$store.dispatch("firestore/pacienteNonConverted", {
          pacienteInfos: this.pacienteInfos,
        });

        this.saved = true;
        this.roteiroDialog = false;

        this.pacienteInfos = {
          gestante: false,
          sexo: "feminino",
          telefone: null,
          whatsapp: null,
          nome: null,
          email: null,
          objetivo: null,
          motivoRecusao: null,
          motivoRecusaoOutro: null,
          meioContato: null,
          detalhesImportantes: null,
          horarioLigacao: null,
        };

        // this.$router.push("Conversoes");
        // }
      }
    },
    async setFuncionarioOnline(chosen) {
      var funcionarioChosen = this.funcionarios.filter(function (el) {
        return el.papel == chosen;
      });

      var element = funcionarioChosen[0];
      var date = new Date();

      var millis = date.getTime().toString();
      var month = date.getMonth() + 1;
      var readableDate =
        date.getFullYear().toString() +
        "-" +
        month.toString() +
        "-" +
        date.getDate().toString() +
        " " +
        date.getHours().toString() +
        ":" +
        date.getMinutes().toString();

      if (this.digitSenha == element.senha) {
        
        this.$store.dispatch("firestore/setFuncionarioOnline", {
          funcionario: element,
        });

        // var ipAccess;
        // const url = "https://api.ipify.org/?format=json";
        // await fetch(url)
        //   .then((response) => response.json())
        //   .then(function (data) {
        //     ipAccess = data.ip.toString();
        //   });

        // console.log(ipAccess);
        this.getComissoesAndRating();

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("funcionarios")
          .doc(element.id)
          .collection("accesses")
          .doc(millis)
          .set({
            accessMillis: millis,
            accessReadable: readableDate,
            // ip: ipAccess,
          });
          
        this.choosePapelDialog = false;
        this.senhaDialog = false;
      } else {
        this.senhaErrada = true;
        this.digitSenha = null;
      }

    },
    // getFuncionarios() {
    //   this.$store.dispatch("firestore/getFuncionarios", {
    //     uId: sessionStorage.getItem("current-user"),
    //   });
    // },
    labelOption(funcionario) {
      var string = funcionario.papel + ", " + funcionario.nome;
      return string;
    },
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(function () {
          router.replace("/login");
        })
        .catch(function (error) {
          console.log(error);
        });
      sessionStorage.setItem("current-user", null);
      sessionStorage.removeItem("isAuthenticated");
    },
    closeMetaDialog() {
      this.metaDialog = false;
      setTimeout(() => {
        this.$router.push("Lembretes");
      }, 500);
    },
    getVendedores() {
      this.vendedores = this.funcionarios.map(function (funcionario) {
        return funcionario.papel;
      });
      console.log(this.vendedores);
      var funcInclNutricionista1;
      if (
        this.vendedores.includes("Nutricionista 1") ||
        this.vendedores.includes("Nutricionista")
      ) {
        funcInclNutricionista1 = true;
      } else {
        funcInclNutricionista1 = false;
      }
      var funcInclNutricionista2 = this.vendedores.includes("Nutricionista 2");
      var funcInclNutricionista3 = this.vendedores.includes("Nutricionista 3");
      var funcInclRecepcionista = this.vendedores.includes("Recepcionista");
      var funcInclEsteticista = this.vendedores.includes("Esteticista");
      var funcInclEstagiaria = this.vendedores.includes("Estagiária");

      this.funcInclNutricionista1 = funcInclNutricionista1;
      this.funcInclNutricionista2 = funcInclNutricionista2;
      this.funcInclNutricionista3 = funcInclNutricionista3;
      this.funcInclRecepcionista = funcInclRecepcionista;
      this.funcInclEsteticista = funcInclEsteticista;
      this.funcInclEstagiaria = funcInclEstagiaria;
    },
    getComissoesAndRating() {
      var dateNow = new Date();
      var monthNow = dateNow.getMonth() + 1;
      var yearNow = dateNow.getFullYear();

      var filterM = function (list, c1, c2) {
        return list.filter(function (el) {
          return el.data.m === c1 && el.data.y === c2;
        });
      };
      var filterComissao = function (list, cond) {
        return list.filter(function (el) {
          return el.data.comissao == cond && el.data.direction;
        });
      };
      var filterComissao1 = function (list, cond1, cond2) {
        return list.filter(function (el) {
          if (el.data.direction) {
            return el.data.comissao == cond1 || el.data.comissao == cond2;
          } else {
            return false;
          }
        });
      };
      var filterVendas = function (list) {
        return list.filter(function (el) {
          return el.data.direction;
        });
      };

      var elementsM = filterM(this.caixa, monthNow, yearNow);

      var elementsPropretario = filterComissao(elementsM, "Propretário");
      var elementsNutricionista1 = filterComissao1(
        elementsM,
        "Nutricionista",
        "Nutricionista 1"
      );
      var elementsNutricionista2 = filterComissao(elementsM, "Nutricionista 2");
      var elementsNutricionista3 = filterComissao(elementsM, "Nutricionista 3");
      var elementsRecepcionista = filterComissao(elementsM, "Recepcionista");
      var elementsEsteticista = filterComissao(elementsM, "Esteticista");
      var elementsEstagiaria = filterComissao(elementsM, "Estagiária");
      var elementsVenda = filterVendas(elementsM);

      var faturamento = 0;
      if (elementsVenda.length > 0) {
        elementsVenda.forEach(function (item) {
          faturamento += parseFloat(item.data.valor);
        });
      }

      var comissaoPropretario = 0;
      if (elementsPropretario.length > 0) {
        elementsPropretario.forEach(function (item) {
          comissaoPropretario += parseFloat(item.data.valor);
        });
      }

      var comissaoNutricionista1 = 0;
      if (elementsNutricionista1.length > 0) {
        elementsNutricionista1.forEach(function (item) {
          comissaoNutricionista1 += parseFloat(item.data.valor);
        });
      }

      var comissaoNutricionista2 = 0;
      if (elementsNutricionista2.length > 0) {
        elementsNutricionista2.forEach(function (item) {
          comissaoNutricionista2 += parseFloat(item.data.valor);
        });
      }

      var comissaoNutricionista3 = 0;
      if (elementsNutricionista3.length > 0) {
        elementsNutricionista3.forEach(function (item) {
          comissaoNutricionista3 += parseFloat(item.data.valor);
        });
      }

      var comissaoRecepcionista = 0;
      if (elementsRecepcionista.length > 0) {
        elementsRecepcionista.forEach(function (item) {
          comissaoRecepcionista += parseFloat(item.data.valor);
        });
      }

      var comissaoEsteticista = 0;
      if (elementsEsteticista.length > 0) {
        elementsEsteticista.forEach(function (item) {
          comissaoEsteticista += parseFloat(item.data.valor);
        });
      }

      var comissaoEstagiaria = 0;
      if (elementsEstagiaria.length > 0) {
        elementsEstagiaria.forEach(function (item) {
          comissaoEstagiaria += parseFloat(item.data.valor);
        });
      }

      this.contribuicaoPropretario = comissaoPropretario;
      this.contribuicaoNutricionista1 = comissaoNutricionista1;
      this.contribuicaoNutricionista2 = comissaoNutricionista2;
      this.contribuicaoNutricionista3 = comissaoNutricionista3;
      this.contribuicaoRecepcionista = comissaoRecepcionista;
      this.contribuicaoEsteticista = comissaoEsteticista;
      this.contribuicaoEstagiaria = comissaoEstagiaria;
      this.faturamento = faturamento;
      if (faturamento < 30000) {
        this.btnText = "OK, vamos bater a meta !!!";
      } else {
        this.btnText = "Muito bom, vamos além da meta !!!";
      }
      this.diferenca = 30000 - faturamento;
      this.vezes = Math.round((faturamento / 30000) * 100) / 100;

      // ratings
      var hasRatingNutri = function (el) {
        return (
          el.data.ratingNutri !== undefined && el.data.ratingNutri !== null
        );
      };
      var hasRatingEstet = function (el) {
        return (
          el.data.ratingEstet !== undefined && el.data.ratingEstet !== null
        );
      };
      var hasRatingRecip = function (el) {
        return (
          el.data.ratingRecip !== undefined && el.data.ratingRecip !== null
        );
      };
      var hasRatingTrata = function (el) {
        return (
          el.data.ratingTrata !== undefined && el.data.ratingTrata !== null
        );
      };
      var hasRatingCarda = function (el) {
        return (
          el.data.ratingCarda !== undefined && el.data.ratingCarda !== null
        );
      };
      var hasRatingTotal = function (el) {
        return (
          el.data.ratingTotal !== undefined && el.data.ratingTotal !== null
        );
      };
      var hasRatingSoftw = function (el) {
        return (
          el.data.ratingSoftw !== undefined && el.data.ratingSoftw !== null
        );
      };

      var ratingsNutriArray = this.pacientes
        .filter(hasRatingNutri)
        .map(function (paciente) {
          return parseInt(paciente.data.ratingNutri);
        });
      var ratingsEstetArray = this.pacientes
        .filter(hasRatingEstet)
        .map(function (paciente) {
          return parseInt(paciente.data.ratingEstet);
        });
      var ratingsRecipArray = this.pacientes
        .filter(hasRatingRecip)
        .map(function (paciente) {
          return parseInt(paciente.data.ratingRecip);
        });
      var ratingsTrataArray = this.pacientes
        .filter(hasRatingTrata)
        .map(function (paciente) {
          return parseInt(paciente.data.ratingTrata);
        });
      var ratingsCardaArray = this.pacientes
        .filter(hasRatingCarda)
        .map(function (paciente) {
          return parseInt(paciente.data.ratingCarda);
        });
      var ratingsTotalArray = this.pacientes
        .filter(hasRatingTotal)
        .map(function (paciente) {
          return parseInt(paciente.data.ratingTotal);
        });
      var ratingsSoftwArray = this.pacientes
        .filter(hasRatingSoftw)
        .map(function (paciente) {
          return parseInt(paciente.data.ratingSoftw);
        });

      var ratingsNutriSum = ratingsNutriArray.reduce((a, b) => a + b, 0);
      var ratingsEstetSum = ratingsEstetArray.reduce((a, b) => a + b, 0);
      var ratingsRecipSum = ratingsRecipArray.reduce((a, b) => a + b, 0);
      var ratingsTrataSum = ratingsTrataArray.reduce((a, b) => a + b, 0);
      var ratingsCardaSum = ratingsCardaArray.reduce((a, b) => a + b, 0);
      var ratingsTotalSum = ratingsTotalArray.reduce((a, b) => a + b, 0);
      var ratingsSoftwSum = ratingsSoftwArray.reduce((a, b) => a + b, 0);

      var ratingsNutriAvg = ratingsNutriSum / ratingsNutriArray.length;
      var ratingsEstetAvg = ratingsEstetSum / ratingsEstetArray.length;
      var ratingsRecipAvg = ratingsRecipSum / ratingsRecipArray.length;
      var ratingsTrataAvg = ratingsTrataSum / ratingsTrataArray.length;
      var ratingsCardaAvg = ratingsCardaSum / ratingsCardaArray.length;
      var ratingsTotalAvg = ratingsTotalSum / ratingsTotalArray.length;
      var ratingsSoftwAvg = ratingsSoftwSum / ratingsSoftwArray.length;

      this.ratingNutri = Math.round(ratingsNutriAvg);
      this.ratingEstet = Math.round(ratingsEstetAvg);
      this.ratingRecip = Math.round(ratingsRecipAvg);
      this.ratingTrata = Math.round(ratingsTrataAvg);
      this.ratingCarda = Math.round(ratingsCardaAvg);
      this.ratingTotal = Math.round(ratingsTotalAvg);
      this.ratingSoftw = Math.round(ratingsSoftwAvg);

      this.metaDialog = true;
      console.log(ratingsTotalArray.length);
      this.notReady = false;
    },
    niceString(valor) {
      var minus = valor < 0;
      var valorAbs = Math.abs(valor);
      var valorKomma;

      var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valorAbs);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valorAbs / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }

      if (minus) {
        return "- R$ " + valorKomma + "," + decimalsStr;
      } else {
        return "R$ " + valorKomma + "," + decimalsStr;
      }
    },
  },
  mounted() {
    this.logo = require("@/assets/logoCompleto.png");
    if (this.isFranquia) {
      this.$store.dispatch("firestore/getCaixa");
      this.$store.dispatch("firestore/getPacientes");
      this.$store.dispatch("firestore/getFuncionarios",{
        uId: sessionStorage.getItem("current-user"),
      });

      setTimeout(() => {
        this.getVendedores();
      }, 5000);

      // setTimeout(() => {
      //   this.getComissoesAndRating();
      // }, 7500);
    }

    // if (this.funcionarioOnline.papel == "") {
    // this.choosePapelDialog = true;
    // }
    
    // this.getFuncionarios();
    // setTimeout(() => {
    //   this.getFuncionarios();
    // }, 1000);

    // const messaging = firebase.messaging();
    // messaging.getToken();
    setTimeout(() => {
      this.expiredSession = true;
    }, 15 * 60 * 60 * 1000);
  },
};
</script>

<style scoped>
</style>


