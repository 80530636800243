<template>
  <div>
    <!-- <v-row>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-end align-center black--text text-h5"
      >
        Cadastro de Paciente
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-start align-center grey--text text-h5"
      >
        Paciente número {{ pacienteNr }} (de {{ pacienteNrMax }})
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="8">
        <v-alert
          v-show="pacienteRatio > 75"
          border="bottom"
          close-text="Fechar"
          color="#c5d13f"
          dismissible
        >
          Você já vai atingir o número máximo de pacientes que você pode
          cadastrar com o seu plano. Com plano VIP você poderá cadastrar tantos
          pacientes como você quiser.
          <v-card-actions class="d-flex justify-center">
            <v-btn text class="brown--text darken-3"> Ver plano VIP </v-btn>
          </v-card-actions>
        </v-alert>
      </v-col>
    </v-row> -->

    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="salvoDialog" max-width="600">
      <v-card v-if="!saved && !warnDialog">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else-if="saved">
        <v-card-title class="d-flex justify-center">
          Movimento salvo no Livro Caixa
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
      <v-card v-else-if="warnDialog">
        <v-card-title class="orange--texts">Alerta!</v-card-title>
        <v-card-text>
          <v-row
            class="d-flex justify-center red--text"
            v-for="(warnMessage, warnIndex) in warnMessages"
            :key="warnIndex"
          >
            {{ warnMessage }}
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            dense
            color="#614021"
            class="white--text"
            @click="salvoDialog = false"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVoucher" max-width="600">
      <v-card>
        <div v-if="dialogVoucherNenhum">
          <v-card-title class="d-flex justify-center">
            Nenhum voucher de desconto inserido
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            <v-icon large color="red">mdi-alert-circle-outline</v-icon>
          </v-card-text>
        </div>
        <div v-else-if="dialogVoucherNotFound">
          <v-card-title class="d-flex justify-center">
            Voucher de desconto não encontrado
          </v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-icon large color="red">mdi-folder-search</v-icon>
            </v-row>
            <v-row class="d-flex justify-center">
              Verifique se escreveu certo o código!
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAv">
      <v-row>
        <v-card outlined>
          <v-card-title justify-center text-subtitle-1
            >Dados pessoais</v-card-title
          >
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="5">
                <v-text-field
                  :prepend-icon="icons.mdiAccount"
                  v-model="chosenAv.nome"
                  label="Nome"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="2">
                <v-radio-group v-model="chosenAv.sexo" column readonly>
                  <v-radio
                    label="feminino"
                    value="feminino"
                    color="#ff00ff"
                  ></v-radio>
                  <v-radio
                    label="masculino"
                    value="masculino"
                    color="#0080ff"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6" md="2">
                <v-checkbox
                  readonly
                  v-model="chosenAv.gestante"
                  label="gestante"
                  color="#c5d13f"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  readonly
                  v-model="chosenAv.dataNascimento"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="4">
                <v-text-field
                  :prepend-icon="icons.mdiEmail"
                  v-model="chosenAv.email"
                  label="E-mail"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  readonly
                  :prepend-icon="icons.mdiPhone"
                  v-model="chosenAv.telefone"
                  label="Telefone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  readonly
                  :prepend-icon="icons.mdiWhatsapp"
                  v-model="chosenAv.whatsapp"
                  label="WhatsApp"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row>
        <v-card outlinded style="background-color: #e0e0e0">
          <v-card-title> Avaliação </v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-space-between align-center">
              <v-col cols="2" class="d-flex justify-end"></v-col>
              <v-col cols="8" class="d-flex justify-center">
                <v-textarea solo v-model="chosenAv.avEscrita"></v-textarea>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-dialog>

    <v-card
      :color="colorConversion(conversion)"
      class="d-flex justify-center white--text text-h6"
    >
      <v-row class="d-flex pa-2 white--text" style="background-color: #00">
        <v-col cols="0" md="3">
          <v-btn text class="white--text mt-4" @click="setNull(true)">
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols="3" md="1">
          <v-switch
            inset
            v-model="conversion"
            color="#614021"
            @change="setNull(conversion)"
          >
          </v-switch>
        </v-col>
        <v-col cols="9" md="7">
          <v-row class="d-flex justify-start mt-4">{{
            labelConversion(conversion)
          }}</v-row>
        </v-col>
        <!-- <v-col cols="6" md="2">
              <v-row class="d-flex mt-4">{{ niceString(mov.valor) }}</v-row>
            </v-col> -->
        <v-col cols="0" md="1">
          <v-icon large color="white" class="mt-4">{{
            conversionIcon(conversion)
          }}</v-icon>
        </v-col>
      </v-row>
    </v-card>
    <v-form ref="form" v-model="valid" v-if="conversion">
      <v-container>
        <v-row>
          <v-col cols="0" md="3"></v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              class="mt-4"
              :prepend-icon="icons.mdiBullseyeArrow"
              v-model="pacienteInfos.objetivo"
              :rules="rules.requiredField"
              :items="objetivos"
              label="Objetivo do Paciente"
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="0" md="3"></v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="5">
            <v-text-field
              :prepend-icon="icons.mdiAccount"
              v-model="pacienteInfos.nome"
              :rules="rules.requiredField"
              label="Nome"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-radio-group v-model="pacienteInfos.sexo" column>
              <v-radio
                label="feminino"
                value="feminino"
                color="#ff00ff"
              ></v-radio>
              <v-radio
                label="masculino"
                value="masculino"
                color="#0080ff"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" md="2">
            <v-checkbox
              v-model="pacienteInfos.gestante"
              label="gestante"
              color="#c5d13f"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="menu"
              v-model="menuDataNascimento"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="displayDataNasc"
                  label="Data de Nascimento"
                  :prepend-icon="icons.mdiCalendar"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                locale="pt-br"
                v-model="pacienteInfos.dataNascimento"
                :max="new Date().toISOString().substring(0, 10)"
                min="1920-01-01"
                @change="save"
                color="#614021"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- <v-col cols="12" md="3">
            <v-row class="d-flex justify-center text-caption">
              Data de nascimento
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field label="dia" :rules="numbersOnly2" v-model="dataNascDia" @change="changeDataNasc"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="mês" :rules="numbersOnly2" v-model="dataNascMes" @change="changeDataNasc"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="ano" :rules="numbersOnly4" v-model="dataNascAno" @change="changeDataNasc"></v-text-field>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-divider></v-divider>
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="4">
            <v-text-field
              :prepend-icon="icons.mdiEmail"
              v-model="pacienteInfos.email"
              :rules="rules.emailRules"
              @change="setMovPaciente"
              label="E-mail"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :prepend-icon="icons.mdiPhone"
              v-model="pacienteInfos.telefone"
              label="Telefone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :prepend-icon="icons.mdiWhatsapp"
              v-model="pacienteInfos.whatsapp"
              label="WhatsApp"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="d-flex justify-center">
          <v-col cols="0" md="3"></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiCardAccountDetails"
              v-model="pacienteInfos.cpf"
              :rules="rules.requiredField"
              label="CPF"
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="12" md="6">
            <v-autocomplete
              :prepend-icon="icons.mdiBullseyeArrow"
              v-model="pacienteInfos.objetivo"
              :rules="rules.requiredField"
              :items="objetivos"
              label="Objetivo"
              required
            ></v-autocomplete>
          </v-col> -->
          <v-col cols="0" md="3"></v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="0" md="2"></v-col>
          <v-col cols="12" md="5">
            <v-autocomplete
              :items="meios"
              label="Meio de contato"
              :prepend-icon="icons.mdiAccountVoice"
              v-model="pacienteInfos.meioContato"
              :rules="rules.requiredField"
              required
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              label="Horário da ligação"
              type="time"
              v-model="pacienteInfos.horarioLigacao"
            ></v-text-field>
          </v-col>
          <v-col cols="0" md="2"></v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-form ref="form2" v-model="valid" v-else>
      <v-container>
        <v-row>
          <!-- <v-col cols="0" md="3"></v-col> -->
          <v-col cols="12" md="6">
            <v-autocomplete
              class="mt-4"
              :prepend-icon="icons.mdiBullseyeArrow"
              v-model="pacienteInfos.objetivo"
              :rules="rules.requiredField"
              :items="objetivos"
              label="Objetivo do Paciente"
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              class="mt-4"
              :prepend-icon="icons.mdiAccountClock"
              v-model="pacienteInfos.motivoRecusao"
              :rules="rules.requiredField"
              :items="motivos"
              label="Motivo pela recusão"
              required
            ></v-autocomplete>
          </v-col>
          <!-- <v-col cols="0" md="3"></v-col> -->
        </v-row>
        <v-row v-show="pacienteInfos.motivoRecusao == 'Outros'">
          <v-col cols="0" md="3"></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiAccountClock"
              v-model="pacienteInfos.motivoRecusaoOutro"
              :items="motivos"
              label="Motivo pela recusão"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="0" md="3"></v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
          <v-col cols="0" md="3"></v-col>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="pacienteInfos.detalhesImportantes"
              label="Detalhes importantes da conversa"
              required
            ></v-textarea>
          </v-col>
          <v-col cols="0" md="3"></v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiAccount"
              v-model="pacienteInfos.nome"
              :rules="rules.requiredField"
              label="Nome"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-radio-group v-model="pacienteInfos.sexo" column>
              <v-radio
                label="feminino"
                value="feminino"
                color="#ff00ff"
              ></v-radio>
              <v-radio
                label="masculino"
                value="masculino"
                color="#0080ff"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiPhone"
              v-model="pacienteInfos.telefone"
              label="Telefone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="icons.mdiWhatsapp"
              v-model="pacienteInfos.whatsapp"
              label="WhatsApp"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="0" md="2"></v-col>
          <v-col cols="12" md="5">
            <v-autocomplete
              :items="meios"
              label="Meio de contato"
              :prepend-icon="icons.mdiAccountVoice"
              v-model="pacienteInfos.meioContato"
              :rules="rules.requiredField"
              required
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              label="Horário da ligação"
              type="time"
              v-model="pacienteInfos.horarioLigacao"
            ></v-text-field>
          </v-col>
          <v-col cols="0" md="2"></v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-divider v-if="conversion"></v-divider>
    <v-row v-if="conversion && isFranquia">
      <v-col cols="0" md="3"></v-col>
      <v-col cols="4" md="2">
        <v-checkbox
          v-model="questPadrao"
          label="questionário atendimento padrão"
          color="#614021"
          @change="setQuestBoolP(questPadrao)"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="4" md="2">
        <v-checkbox
          v-model="questEstet"
          label="apenas estética"
          color="#614021"
          @change="setQuestBoolE(questEstet)"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="4" md="2">
        <v-checkbox
          v-model="questImunidade"
          label="protocolo Kirchhoff"
          color="#614021"
          @change="setQuestBoolK(questImunidade)"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="0" md="3"></v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- <v-dialog v-model="warnDialog" max-width="600">
      <v-card>
        <v-card-title class="orange--texts">Alerta!</v-card-title>
        <v-card-text>
          <v-row
            class="d-flex justify-center red--text"
            v-for="(warnMessage, warnIndex) in warnMessages"
            :key="warnIndex"
          >
            {{ warnMessage }}
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            dense
            color="#614021"
            class="white--text"
            @click="warnDialog = false"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-card
      class="pa-5 my-1"
      tile
      style="background-color: rgba(197, 209, 63, 0.6)"
      v-if="conversion && isFranquia"
    >
      <v-card
        :color="colorDirection(mov.direction)"
        class="d-flex justify-center white--text text-h6"
      >
        <v-row class="d-flex pa-2 white--text" style="background-color: #00">
          <v-col cols="0" md="3">
            <v-btn text class="white--text mt-4" @click="setNullMov(true)">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="3" md="1">
            <v-switch
              inset
              v-model="mov.direction"
              color="#614021"
              @change="setNullMov(mov.direction)"
            >
            </v-switch>
          </v-col>
          <v-col cols="9" md="5">
            <v-row class="d-flex justify-start mt-4">{{
              labelDirection(mov.direction)
            }}</v-row>
          </v-col>
          <v-col cols="6" md="2" v-if="mov.direction">
            <v-row class="d-flex mt-4">{{ niceString(mov.valor) }}</v-row>
          </v-col>
          <v-col cols="6" md="2" v-else> </v-col>
          <v-col cols="6" md="1">
            <v-icon large color="white" class="mt-4">{{ formaIcon }}</v-icon>
          </v-col>
        </v-row>
      </v-card>

      <v-row>
        <v-col cols="0" md="1"></v-col>
        <v-col cols="12" md="10">
          <v-autocomplete
            label="Tipo de Venda"
            :items="getItems"
            v-model="mov.tipo"
            class="mt-5"
            single-line
            solo
            style="width: 100%"
            @change="changeTipo(mov.tipo)"
            :rules="mandatory"
            no-data-text="..."
          ></v-autocomplete> </v-col
        ><v-col cols="0" md="1"></v-col>
      </v-row>

      <div v-if="mov.direction">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="0" md="3"></v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="voucherDesconto"></v-text-field>
          </v-col>
          <v-col cols="0" md="2">
            <v-btn
              v-if="voucherFound"
              color="#614021"
              class="white--text"
              small
              @click="dialogAv = true"
            >
              <v-icon small left color="#c5d13f">mdi-magnify</v-icon>
              Ver detalhes</v-btn
            >
            <v-btn
              v-else
              color="#614021"
              class="white--text"
              small
              @click="getVoucher"
            >
              <v-icon small left color="#c5d13f"
                >mdi-ticket-percent-outline</v-icon
              >
              Procurar Voucher</v-btn
            ></v-col
          >
          <v-col cols="0" md="3"></v-col>
        </v-row>
      </div>
      <v-divider></v-divider>

      <div
        v-if="
          mov.tipo == 'Programa 12S com Estética' ||
            mov.tipo == 'Programa Modulação Intestinal com Estética' ||
            mov.tipo == 'Programa Clínico Funcional com Estética'
        "
      >
        <v-row class="d-flex justify-center mt-5">
          Define a quantidade de pacotes estéticos
        </v-row>
        <v-row>
          <v-col cols="0" md="4"></v-col>
          <v-col cols="12" md="4">
            <v-select
              label="número de pacotes"
              v-model="numberPacote"
              @change="setNrPacotes(numberPacote, mov.tipo)"
              :items="numbers"
            ></v-select>
          </v-col>
          <v-col cols="0" md="4"></v-col>
        </v-row>
      </div>

      <v-row v-if="mov.tipo == 'Protocolo Harmonização Corporal 24H'">
        <v-col cols="0" md="2"></v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="pacH1area"
            :items="areas"
            label="área 1"
            @change="setPacIncl"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="pacH2area"
            :items="areas"
            label="área 2"
            @change="setPacIncl"
          ></v-select>
        </v-col>
        <v-col cols="0" md="2"></v-col>
      </v-row>

      <v-row
        v-if="
          mov.tipo == 'Protocolo Lipodraining 24H' ||
            mov.tipo == 'Protocolo Detox + Lipodraining 24H'
        "
      >
        <v-col cols="0" md="4" class="d-flex justify-center">
          <v-checkbox
            v-model="pacDldef1"
            color="#614021"
            label="Corpo inteiro"
            @change="setPacIncl"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            :disabled="pacDldef1"
            v-model="pacDl1area"
            :items="areas"
            label="área 1"
            @change="setPacIncl"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            :disabled="pacDldef1"
            v-model="pacDl2area"
            :items="areas"
            label="área 2"
            @change="setPacIncl"
          ></v-select>
        </v-col>
      </v-row>
      <!-- <div v-if="isPrograma">
        <v-row class="d-flex justify-center">
          <v-checkbox
            label="Pacote estético adicional?"
            v-model="pacAdiPrograma"
          ></v-checkbox>
        </v-row>
      </div> -->

      <!-- <div v-if="pacAdiPrograma || mov.tipo == 'Pacote Estético'"> -->
      <div v-if="mov.tipo == 'Pacote Estético'">
        <v-row class="d-flex justify-center">
          <v-col cols="0" md="2"></v-col>
          <v-col cols="12" md="8">
            <v-card
              v-for="pac in mov.pacotes"
              :key="pac.index"
              outlined
              class="my-1"
              style="background-color: rgba(197, 209, 63, 0.2)"
            >
              <v-row class="mx-1">
                <v-col cols="12" md="4"
                  ><v-select
                    label="tipo de pacote"
                    v-model="pac.def1"
                    :items="getNames(pacotesEsteticos1)"
                    @change="setValor(mov.tipo, mov.descr)"
                    :rules="mandatory"
                    :readonly="
                      pac.def2 == 'REFLEXOLOGIA PODAL (10 sessões)' ||
                        pac.def2 == 'TERAPIA COM VENTOSAS (10 sessões)' ||
                        pac.def2 == 'AURICULOTERAPIA (10 sessões)' ||
                        pac.def2 == 'DRY NEEDLING (10 sessões)' ||
                        pac.def2 ==
                          'REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)'
                    "
                  ></v-select
                ></v-col>
                <v-col cols="12" md="5"
                  ><v-select
                    label="procedimento"
                    v-model="pac.def2"
                    :items="pacotesEsteticos2"
                    @change="checkReflexPodal(pac)"
                    :rules="mandatory"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    label="mês do procedimento"
                    v-model="pac.dataInicio"
                    @change="setMillisPacote(pac.index)"
                    :items="meses"
                    :rules="mandatory"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="mx-1">
                <v-col cols="12" md="4">
                  <v-select
                    label="área 1"
                    v-model="pac.area1"
                    :items="getCorrectAreas(pac.def1)"
                    :rules="mandatory"
                    v-if="
                      pac.def2 !== 'REFLEXOLOGIA PODAL (10 sessões)' &&
                        pac.def2 !==
                          'REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)' &&
                        pac.def2 !== 'TERAPIA COM VENTOSAS (10 sessões)' &&
                        pac.def2 !== 'AURICULOTERAPIA (10 sessões)' &&
                        pac.def2 !== 'DRY NEEDLING (10 sessões)'
                    "
                  >
                  </v-select>
                  <v-text-field
                    label="área 1"
                    v-model="pac.area1"
                    readonly
                    v-else
                  >
                  </v-text-field
                ></v-col>
                <v-col cols="12" md="4">
                  <v-select
                    label="área 2"
                    v-model="pac.area2"
                    :items="areas"
                    :disabled="disableArea2(pac.def1, pac.def2)"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    label="área 3"
                    v-model="pac.area3"
                    :items="areas"
                    :disabled="disableArea3(pac.def1, pac.def2)"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="0" md="2" class="d-flex align-center">
            <v-tooltip bottom color="#614021">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="#614021"
                  v-bind="attrs"
                  v-on="on"
                  @click="pushPacote"
                  ><v-icon color="#614021"
                    >mdi-plus-circle-outline</v-icon
                  ></v-btn
                >
              </template>
              <span>Adicionar Pacote</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>

      <div v-if="mov.tipo == 'Power Shape 30'">
        <v-row class="d-flex justify-center">
          <v-col cols="0" md="2"></v-col>
          <v-col cols="12" md="8">
            <v-card
              v-for="pac in mov.pacotes"
              :key="pac.index"
              outlined
              class="my-1"
              style="background-color: rgba(197, 209, 63, 0.2)"
            >
              <v-row class="mx-1">
                <v-col cols="12" md="4"
                  ><v-select
                    label="tipo de pacote"
                    v-model="pac.def1"
                    :items="getNames(pacotesEsteticos1)"
                    readonly
                    hint="Somente DUO (áreas pares)"
                  ></v-select
                ></v-col>
                <v-col cols="12" md="5"
                  ><v-select
                    label="procedimento"
                    v-model="pac.def2"
                    :items="pacotesEsteticos2"
                    @change="checkReflexPodal(pac)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    label="mês do procedimento"
                    v-model="pac.dataInicio"
                    @change="setMillisPacote(pac.index)"
                    :items="meses"
                    :rules="mandatory"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center mx-4">
                <v-select
                  label="área"
                  v-model="pac.area1"
                  :items="getCorrectAreas(pac.def1)"
                  :rules="mandatory"
                  v-if="
                    pac.def2 !== 'REFLEXOLOGIA PODAL (10 sessões)' &&
                      pac.def2 !==
                        'REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)' &&
                      pac.def2 !== 'TERAPIA COM VENTOSAS (10 sessões)' &&
                      pac.def2 !== 'AURICULOTERAPIA (10 sessões)' &&
                      pac.def2 !== 'DRY NEEDLING (10 sessões)'
                  "
                >
                </v-select>
                <v-text-field
                  label="área 1"
                  v-model="pac.area1"
                  readonly
                  v-else
                >
                </v-text-field>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="0" md="2" class="d-flex align-center"> </v-col>
        </v-row>
      </div>

      <div
        v-if="
          mov.tipo == 'Programa 12S com Estética' ||
            mov.tipo == 'Programa Modulação Intestinal com Estética' ||
            mov.tipo == 'Programa Clínico Funcional com Estética'
        "
      >
        <v-row class="d-flex justify-center">
          <v-col cols="0" md="2"></v-col>
          <v-col cols="12" md="8">
            <v-card
              v-for="pac in mov.pacotes"
              :key="pac.index"
              outlined
              class="my-1"
              style="background-color: rgba(197, 209, 63, 0.2)"
            >
              <v-row class="mx-1">
                <v-col cols="12" md="5"
                  ><v-select
                    label="procedimento"
                    v-model="pac.def2"
                    :items="pacotesEsteticos2"
                    @change="checkReflexPodal(pac)"
                    :rules="mandatory"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    label="mês do procedimento"
                    v-model="pac.dataInicio"
                    @change="setMillisPacote(pac.index)"
                    :items="meses12s"
                    :rules="mandatory"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    label="área"
                    v-model="pac.area1"
                    :items="areasPares"
                    :rules="mandatory"
                    v-if="
                      pac.def2 !== 'REFLEXOLOGIA PODAL (10 sessões)' &&
                        pac.def2 !==
                          'REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)' &&
                        pac.def2 !== 'TERAPIA COM VENTOSAS (10 sessões)' &&
                        pac.def2 !== 'AURICULOTERAPIA (10 sessões)' &&
                        pac.def2 !== 'DRY NEEDLING (10 sessões)'
                    "
                  >
                  </v-select>
                  <v-text-field
                    label="área"
                    v-model="pac.area1"
                    readonly
                    v-else
                  >
                  </v-text-field
                ></v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="0" md="2" class="d-flex align-center">
            <!-- <v-tooltip bottom color="#614021">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="#614021"
                  v-bind="attrs"
                  v-on="on"
                  @click="pushPacote"
                  ><v-icon color="#614021"
                    >mdi-plus-circle-outline</v-icon
                  ></v-btn
                >
              </template>
              <span>Adicionar Pacote</span>
            </v-tooltip> -->
          </v-col>
        </v-row>
      </div>
      <div v-if="mov.tipo == '1 sessão estética avulsa'">
        <v-form ref="formSessao" v-model="validSessao">
          <v-row>
            <v-col cols="0" md="3"></v-col>
            <v-col cols="12" md="6">
              <v-select
                :value="mov.descr"
                v-model="mov.descr"
                :items="getNames(sessoesEsteticos)"
                solo
                label="Sessão"
                style="width: 100%"
                @input="setValor(mov.tipo, mov.descr)"
                :rules="mandatory"
              >
              </v-select> </v-col
            ><v-col cols="0" md="3"></v-col>
          </v-row>
          <!-- <v-row class="d-flex justify-center">
            {{ mov.descr }}
          </v-row> -->
        </v-form>
      </div>

      <v-divider v-if="descrNesc"></v-divider>
      <div v-if="descrNesc">
        <v-row>
          <v-col cols="3" md="2" class="d-flex align-center justify-end">
            Descrição:
          </v-col>
          <v-col cols="9" md="4">
            <v-text-field
              label="mais detalhes importantes..."
              single-line
              solo
              class="mt-3 mx-3"
              style="width: 100%"
              v-model="mov.descr"
              :rules="mandatory"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="2" class="d-flex align-center justify-end">
            Valor:
          </v-col>
          <v-col cols="9" md="4">
            <v-text-field
              label='ex.: "167.50"'
              single-line
              solo
              class="mt-3 mx-3"
              style="width: 100%"
              v-model="mov.valor"
              :rules="hasDots"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <div v-if="mov.direction">
          <v-row>
            <v-col cols="12" md="3" class="d-flex align-center justify-end">
              <v-checkbox
                v-model="descontoAct"
                label="Desconto"
                color="#614021"
                @change="descontoImpact"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label='desconto em R$, ex.: "100"'
                single-line
                :solo="descontoAct"
                class="mt-3 mx-3"
                style="width: 100%"
                v-model="mov.desconto"
                @change="adjustValorDesc"
                :disabled="!descontoAct"
                type="number"
              ></v-text-field> </v-col
            ><v-col cols="12" md="3"></v-col>
          </v-row>
        </div>
      </div>
      <v-divider v-if="mov.direction"></v-divider>

      <v-row v-if="mov.direction">
        <v-col cols="12" md="3" class="d-flex align-center justify-end">
          Forma de Pagamento:
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            label="Forma de Pagamento"
            :items="pagamentoFormas"
            v-model="mov.forma"
            class="mt-5"
            single-line
            solo
            style="width: 100%"
            :rules="mandatory"
          ></v-select> </v-col
        ><v-col cols="0" md="3"></v-col>
      </v-row>
      <v-divider v-if="mov.forma == 7"></v-divider>
      <div v-if="mov.forma == 7">
        <v-row class="d-flex justify-center" v-if="formasSumIncorrect">
          <v-alert type="error" dense class="my-1"
            >Soma não igual ao valor da venda!</v-alert
          >
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouCartaoDeDebito)">
              {{ icons.mdiCreditCardCheckOutline }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouCartaoDeDebito"
              label="Cartão de Débito"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago no cartão de débito, ex.: "100"'
              single-line
              :solo="usouCartaoDeDebito"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.cartaoDeDebito"
              :disabled="!usouCartaoDeDebito"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouCartaoDeCredito)">
              {{ icons.mdiCreditCardClockOutline }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouCartaoDeCredito"
              label="Cartão de Crédito"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago no cartão de crédito, ex.: "100"'
              single-line
              :solo="usouCartaoDeCredito"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.cartaoDeCredito"
              :disabled="!usouCartaoDeCredito"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouTransferenciaBancaria)">
              {{ icons.mdiBankTransfer }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouTransferenciaBancaria"
              label="Transferência Bancária"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago no cartão de crédito, ex.: "100"'
              single-line
              :solo="usouTransferenciaBancaria"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.transferenciaBancaria"
              :disabled="!usouTransferenciaBancaria"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouPIX)">
              {{ icons.mdiInfinity }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouPIX"
              label="PIX"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago no PIX, ex.: "100"'
              single-line
              :solo="usouPIX"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.pix"
              :disabled="!usouPIX"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <!-- <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouCheque)">
              {{ icons.mdiCheckbook }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouCheque"
              label="Cheque"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago em Cheque, ex.: "100"'
              single-line
              :solo="usouCheque"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.cheque"
              :disabled="!usouCheque"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row> -->
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouDeposito)">
              {{ icons.mdiPiggyBank }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouDeposito"
              label="Depósito Bancário"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago como depósito, ex.: "100"'
              single-line
              :solo="usouDeposito"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.deposito"
              :disabled="!usouDeposito"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouDinheiro)">
              {{ icons.mdiCash100 }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouDinheiro"
              label="Dinheiro"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago em dinheiro, ex.: "100"'
              single-line
              :solo="usouDinheiro"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.dinheiro"
              :disabled="!usouDinheiro"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
      </div>
      <v-divider v-if="mov.direction"></v-divider>
      <div v-if="mov.direction">
        <v-row>
          <v-col cols="12" md="3" class="d-flex align-center justify-end">
            Vendedor:
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              label="Quem está vendendo (comissão)"
              single-line
              solo
              class="mt-3 mx-3"
              style="width: 100%"
              v-model="mov.comissao"
              :items="vendedores"
              :rules="mandatory"
            ></v-select> </v-col
          ><v-col cols="12" md="3"></v-col>
        </v-row>
      </div>
      <v-divider v-if="mov.direction"></v-divider>
      <div v-if="mov.direction">
        <v-row class="text-subtitle-1"
          ><v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="6">
            <v-file-input
              accept="document/*"
              label="Comprovante de pagamento"
              @change="handleComprovante"
              :rules="mandatory"
            ></v-file-input> </v-col
          ><v-col cols="12" md="3"></v-col>
        </v-row>
      </div>
      <v-divider v-if="isPrograma"></v-divider>
      <div v-if="isPrograma">
        <v-row>
          <v-col cols="12" md="3" class="d-flex align-center justify-end">
            Vídeo-Cursos/Ebooks:
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              class="mx-4"
              v-model="temasToLiberate"
              :items="temas"
              attach
              chips
              label="Temas"
              multiple
            ></v-select>
          </v-col>
          <v-col cols="0" md="3"></v-col>
        </v-row>
      </div>
      <!-- <v-card-actions class="d-flex justify-center mt-2">
        <v-btn
          color="#614021"
          large
          class="white--text"
          @click="salvarMov"
          :disabled="mov.tipo == 'null'"
          ><v-icon left color="#c5d13f">{{ formaIcon }}</v-icon>
          Salvar
        </v-btn>
      </v-card-actions> -->
    </v-card>

    <v-card
      class="pa-5 my-1"
      tile
      style="background-color: rgba(197, 209, 63, 0.6)"
      v-else-if="conversion && !isFranquia"
    >
      <v-card
        :color="colorDirection(mov.direction)"
        class="d-flex justify-center white--text text-h6"
      >
        <v-row class="d-flex pa-2 white--text" style="background-color: #00">
          <v-col cols="0" md="3">
            <v-btn text class="white--text mt-4" @click="setNull(true)">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="3" md="1">
            <v-switch
              inset
              v-model="mov.direction"
              color="#614021"
              @change="setNull(mov.direction)"
            >
            </v-switch>
          </v-col>
          <v-col cols="9" md="5">
            <v-row class="d-flex justify-start mt-4">{{
              labelDirection(mov.direction)
            }}</v-row>
          </v-col>
          <v-col cols="6" md="2">
            <v-row class="d-flex mt-4">{{ niceString(mov.valor) }}</v-row>
          </v-col>
          <v-col cols="6" md="1">
            <v-icon large color="white" class="mt-4">{{ formaIcon }}</v-icon>
          </v-col>
        </v-row>
      </v-card>

      <v-row>
        <v-col cols="0" md="1"></v-col>
        <v-col cols="12" md="10">
          <v-text-field
            :label="labelTipo(mov.direction)"
            v-model="mov.tipo"
            solo
            class="mt-5"
            single-line
            style="width: 100%"
            :rules="mandatory"
          ></v-text-field> </v-col
        ><v-col cols="0" md="1"></v-col>
      </v-row>

      <div v-if="mov.direction">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="0" md="3"></v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="voucherDesconto"></v-text-field>
          </v-col>
          <v-col cols="0" md="2">
            <v-btn
              v-if="voucherFound"
              color="#614021"
              class="white--text"
              small
              @click="dialogAv = true"
            >
              <v-icon small left color="#c5d13f">mdi-magnify</v-icon>
              Ver detalhes</v-btn
            >
            <v-btn
              v-else
              color="#614021"
              class="white--text"
              small
              @click="getVoucher"
            >
              <v-icon small left color="#c5d13f"
                >mdi-ticket-percent-outline</v-icon
              >
              Procurar Voucher</v-btn
            ></v-col
          >
          <v-col cols="0" md="3"></v-col>
        </v-row>
      </div>
      <v-divider></v-divider>

      <v-divider></v-divider>
      <div>
        <v-row>
          <v-col cols="3" md="2" class="d-flex align-center justify-end">
            Descrição:
          </v-col>
          <v-col cols="9" md="4">
            <v-text-field
              label="mais detalhes importantes..."
              single-line
              solo
              class="mt-3 mx-3"
              style="width: 100%"
              v-model="mov.descr"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="2" class="d-flex align-center justify-end">
            Valor:
          </v-col>
          <v-col cols="9" md="4">
            <v-text-field
              label='ex.: "167.50"'
              single-line
              solo
              class="mt-3 mx-3"
              style="width: 100%"
              v-model="mov.valor"
              :rules="hasDots"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>

      <v-row>
        <v-col cols="12" md="3" class="d-flex align-center justify-end">
          Forma de Pagamento:
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            label="Forma de Pagamento"
            :items="pagamentoFormas"
            v-model="mov.forma"
            class="mt-5"
            single-line
            solo
            style="width: 100%"
            :rules="mandatory"
          ></v-select> </v-col
        ><v-col cols="0" md="3"></v-col>
      </v-row>
      <v-divider></v-divider>

      <div v-if="mov.forma == 7">
        <v-row class="d-flex justify-center" v-if="formasSumIncorrect">
          <v-alert type="error" dense class="my-1"
            >Soma não igual ao valor da venda!</v-alert
          >
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouCartaoDeDebito)">
              {{ icons.mdiCreditCardCheckOutline }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouCartaoDeDebito"
              label="Cartão de Débito"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago no cartão de débito, ex.: "100"'
              single-line
              :solo="usouCartaoDeDebito"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.cartaoDeDebito"
              :disabled="!usouCartaoDeDebito"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouCartaoDeCredito)">
              {{ icons.mdiCreditCardClockOutline }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouCartaoDeCredito"
              label="Cartão de Crédito"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago no cartão de crédito, ex.: "100"'
              single-line
              :solo="usouCartaoDeCredito"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.cartaoDeCredito"
              :disabled="!usouCartaoDeCredito"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouTransferenciaBancaria)">
              {{ icons.mdiBankTransfer }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouTransferenciaBancaria"
              label="Transferência Bancária"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago no cartão de crédito, ex.: "100"'
              single-line
              :solo="usouTransferenciaBancaria"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.transferenciaBancaria"
              :disabled="!usouTransferenciaBancaria"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouPIX)">
              {{ icons.mdiInfinity }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouPIX"
              label="PIX"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago no PIX, ex.: "100"'
              single-line
              :solo="usouPIX"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.pix"
              :disabled="!usouPIX"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <!-- <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouCheque)">
              {{ icons.mdiCheckbook }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouCheque"
              label="Cheque"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago em Cheque, ex.: "100"'
              single-line
              :solo="usouCheque"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.cheque"
              :disabled="!usouCheque"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row> -->
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouDeposito)">
              {{ icons.mdiPiggyBank }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouDeposito"
              label="Depósito Bancário"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago como depósito, ex.: "100"'
              single-line
              :solo="usouDeposito"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.deposito"
              :disabled="!usouDeposito"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center">
            <v-icon :color="greyOrBrown(usouDinheiro)">
              {{ icons.mdiCash100 }}
            </v-icon>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-start">
            <v-checkbox
              v-model="usouDinheiro"
              label="Dinheiro"
              color="#614021"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label='valor pago em dinheiro, ex.: "100"'
              single-line
              :solo="usouDinheiro"
              class="mx-3"
              style="width: 100%"
              v-model="mov.formas.dinheiro"
              :disabled="!usouDinheiro"
              @change="checkFormasSum"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <div v-if="mov.direction">
        <v-row>
          <v-col cols="12" md="3" class="d-flex align-center justify-end">
            Vendedor:
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              label="Quem está vendendo (comissão)"
              single-line
              solo
              class="mt-3 mx-3"
              style="width: 100%"
              v-model="mov.comissao"
              :items="vendedores"
              :rules="mandatory"
            ></v-select> </v-col
          ><v-col cols="12" md="3"></v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <!-- <div v-if="mov.direction">
        <v-row class="text-subtitle-1"
          ><v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="6">
            <v-file-input
              accept="document/*"
              label="Comprovante de pagamento"
              @change="handleComprovante"
            ></v-file-input> </v-col
          ><v-col cols="12" md="3"></v-col>
        </v-row>
      </div> -->
      <!-- <v-divider></v-divider> -->
      <!-- <v-card-actions class="d-flex justify-center mt-2">
        <v-btn
          color="#614021"
          large
          class="white--text"
          @click="salvarMov"
          :disabled="mov.tipo == 'null'"
          ><v-icon left color="#c5d13f">{{ formaIcon }}</v-icon>
          Salvar
        </v-btn>
      </v-card-actions> -->
    </v-card>

    <v-divider v-if="conversion"></v-divider>
    <v-row align="center" v-if="conversion">
      <v-col class="text-center" cols="12">
        <!-- <v-btn large color="grey" class="mx-2" @click="submitIniciar"
          >Salvar -> questionário</v-btn
        > -->
        <v-btn
          large
          color="#c5d13f"
          class="mx-2"
          @click="submitOnly"
          :disabled="mov.tipo == 'null'"
          ><v-icon left color="#614021">{{ formaIcon }}</v-icon
          >Salvar</v-btn
        >
      </v-col>
    </v-row>
    <v-row align="center" class="d-flex justify-center" v-else>
      <v-btn
        large
        color="#614021"
        class="my-2 white--text"
        @click="submitNonConverted"
      >
        <v-icon color="#c5d13f" left> {{ icons.mdiAccountClock }} </v-icon
        >Salvar</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase";
import {
  mdiGift,
  mdiAccount,
  mdiAccountClock,
  mdiLock,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
  mdiStore,
  mdiAlert,
  mdiCalendar,
  mdiPhone,
  mdiWhatsapp,
  mdiMapMarker,
  mdiCity,
  mdiFlagVariantOutline,
  mdiPiggyBank,
  mdiCreditCardClockOutline,
  mdiHandHeart,
  mdiBabyCarriage,
  mdiHumanMaleChild,
  mdiHospitalBuilding,
  mdiAccountVoice,
  mdiCardAccountDetails,
  mdiBullseyeArrow,
  mdiCreditCardCheckOutline,
  mdiCash100,
  mdiBankTransfer,
  mdiInfinity,
  mdiHelpRhombusOutline,
  mdiWalletPlusOutline,
  mdiCheckbook,
  mdiWeightKilogram,
  mdiHumanMaleHeightVariant,
} from "@mdi/js";

export default {
  name: "cadastro",
  data: function() {
    return {
      usouDinheiro: false,
      usouCartaoDeDebito: false,
      usouCartaoDeCredito: false,
      usouTransferenciaBancaria: false,
      usouPIX: false,
      // usouCheque: false,
      usouDeposito: false,
      pagamentoFormas: [
        { text: "Cartão de Débito", value: 2 },
        { text: "Cartão de Crédito", value: 3 },
        { text: "Transferência Bancária", value: 4 },
        { text: "PIX", value: 5 },
        // { text: "Cheque", value: 6 },
        { text: "Depósito Bancário", value: 8 },
        { text: "Dinheiro", value: 1 },
        { text: "multiplas formas", value: 7 },
      ],
      pacDldef1: false,
      pacDl1area: null,
      pacDl2area: null,
      progressDialog: false,
      saved1: false,
      saved: false,
      menuDataNascimento: false,
      conversion: true,
      pacienteInfos: {
        gestante: false,
        sexo: "feminino",
        dataNascimento: null,
        telefone: "",
        whatsapp: "",
        nome: null,
        email: null,
        objetivo: null,
        motivoRecusao: null,
        horarioLigacao: null,
        motivoRecusaoOutro: null,
        meioContato: null,
        detalhesImportantes: null,
        cpf: null,
      },
      estadoCivilItems: [
        "-",
        "Solteira/Solteiro",
        "Casada/Casado",
        "Viúva/Viúvo",
        "Divorciada/Divorciado",
      ],
      objetivos: [
        "Emagrecer",
        "Tratar um Problema de Saúde",
        "Ganhar Massa Magra",
        "Procedimento Estético",
        "Exames",
      ],
      meios: [
        // meio de contato para agendamento
        "Ligou para telefone fixo",
        "Ligou para celular",
        "Ligou para WhatsApp",
        "Mandou mensagem no WhatsApp",
        "Mandou mensagem no Instagram",
        "Agendou direto na recepção",
      ],
      motivos: ["Buscava plano de saúde", '"Vou pensar..."', "Outros"],
      icons: {
        mdiGift,
        mdiLock,
        mdiEmail,
        mdiEye,
        mdiEyeOff,
        mdiStore,
        mdiAccount,
        mdiAccountClock,
        mdiAlert,
        mdiCalendar,
        mdiPhone,
        mdiWhatsapp,
        mdiMapMarker,
        mdiCity,
        mdiFlagVariantOutline,
        mdiPiggyBank,
        mdiCreditCardClockOutline,
        mdiHospitalBuilding,
        mdiHandHeart,
        mdiBabyCarriage,
        mdiHumanMaleChild,
        mdiAccountVoice,
        mdiCardAccountDetails,
        mdiBullseyeArrow,
        mdiCreditCardCheckOutline,
        mdiCash100,
        mdiBankTransfer,
        mdiInfinity,
        mdiHelpRhombusOutline,
        mdiWalletPlusOutline,
        mdiCheckbook,
        mdiWeightKilogram,
        mdiHumanMaleHeightVariant,
      },
      show: false,
      valid: true,
      questPadrao: true,
      questEstet: false,
      questImunidade: false,
      // questKirchhoff: false,
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
        // passwordLength: [(v) => v.length >= 8 || "Senha com mais que 8 letras"],
        emailRules: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+/.test(v) || "E-mail invalido",
          (v) => /^\S*$/.test(v) || "E-mail com espaço",
        ],
        // dataNasc: [
        //   function(v) {
        //     if (v.includes("-")) {
        //       if (v.split("-").length == 3) {
        //         if (
        //           v.split("-")[0].length == 4 &&
        //           v.split("-")[1].length == 2 &&
        //           v.split("-")[1] <= 12 &&
        //           v.split("-")[2].length == 2 &&
        //           v.split("-")[2] <= 31
        //         ) {
        //           return true;
        //         }
        //       } else {
        //         return "Usar formato automático! (por exemplo: 1999-12-23)";
        //       }
        //     } else {
        //       return "Usar formato automático! (por exemplo: 1999-12-23)";
        //     }
        //   },
        // ],
      },

      voucherDesconto: null,
      voucherFound: false,
      dialogVoucher: false,
      dialogVoucherNenhum: false,
      dialogVoucherNotFound: false,
      dialogAv: false,
      chosenAv: {
        nome: null,
        sexo: null,
        email: null,
        whatsapp: null,
        telefone: null,
        dataNascimento: null,
      },
      // saved: false,
      warnMessages: [],
      warnDialog: false,
      salvoDialog: false,
      mov: {
        direction: true,
        tipo: null,
        descr: null,
        paciente: null,
        pacienteName: null,
        y: null,
        m: null,
        d: null,
        comprovante: null,
        valor: null,
        desconto: 0,
        soldObjs: [],
        pacotes: [
          {
            def1: null,
            def2: null,
            area1: null,
            area2: null,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            // index: ix,
          },
        ],
        forma: null,
        formas: {
          cartaoDeCredito: 0,
          cartaoDeDebito: 0,
          dinheiro: 0,
          deposito: 0,
          pix: 0,
          transferenciaBancaria: 0,
        },
      },
      formasSumIncorrect: false,
      validNrPacs: true,
      validSessao: true,
      vendedores: [],
      numberPacote: 0,
      numbers: [1, 2, 3, 4, 5, 6],
      //   pacAdiPrograma: false,
      displayDataNasc: "",
      pacH1area: null,
      pacH2area: null,
      pac12S1area: null,
      pac12S2area: null,
      pac12S3area: null,
      compradorDesconhecido: false,
      descontoAct: false,
      temasToLiberate: [],
      comprovante: null,
      areas: [
        "ABDOMEN INFERIOR",
        "ABDOMEN SUPERIOR",
        "GLUTEO DIREITO",
        "GLÚTEO ESQUERDO",
        "POSTERIOR DE COXA ESQUERDO",
        "POSTERIOR DE COXA DIREITO",
        "FLANCO ESQUERDO",
        "FLANCO DIREITO",
        "BRAÇO DIREITO",
        "BRAÇO ESQUERDO",
        "INTERNO DE COXA DIREITO",
        "INTERNO DE COXA ESQUERDO",
        "LATERAL DE COXA DIREITO",
        "LATERAL DE COXA ESQUERDO",
        "PAPADA",
        "PEITORAL",
      ],
      areasPares: [
        "ABDOMEN INFERIOR + SUPERIOR",
        "GLUTEO DIREITO + GLÚTEO ESQUERDO",
        "POSTERIOR DE COXA ESQUERDO + DIREITO",
        "FLANCO ESQUERDO + DIREITO",
        "BRAÇO DIREITO + BRAÇO ESQUERDO",
        "INTERNO DE COXA DIREITO + INTERNO DE COXA ESQUERDO",
        "LATERAL DE COXA DIREITO + LATERAL DE COXA ESQUERDO",
        "PAPADA",
        "PEITORAL",
      ],
      vendaItems: [
        // {
        //   nome: "1 consulta nutricional patrocinada",
        //   valor: { balcam: 0, fortal: 0, belemx: 0 },
        //   transactModus: ["none"],
        //   duracao: 30, //acessoTipo acessoFim
        // },
        {
          nome: "1 consulta nutricional Crédito / Débito",
          valor: { balcam: 330, fortal: 300, belemx: 350 },
          transactModus: ["Cartão de Crédito", "Cartão de Débito"],
          duracao: 30,
        },
        {
          nome: "1 consulta nutricional PIX / Transferência",
          valor: { balcam: 330, fortal: 300, belemx: 350 },
          transactModus: ["PIX", "Transferência Bancária"],
          duracao: 30,
        },
        // !!!!
        {
          nome: "1 consulta nutricional (plano família, mínimo 2 pessoas)",
          valor: { balcam: 250, fortal: 250, belemx: 300 },
          transactModus: ["any"],
          duracao: 30,
        },
        // !!!!
        {
          nome: "1 consulta nutricional SEM RETORNO",
          valor: { balcam: 180, fortal: 180, belemx: 200 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Programa Clínico Funcional", //"1 programa BÁSICO NUTRICIONAL (90 dias)",
          valor: { balcam: 900, fortal: 900, belemx: 990 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Clínico Funcional (plano família)", // "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)",
          valor: { balcam: 810, fortal: 810, belemx: 810 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Clínico Funcional com Estética", // "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)",
          valor: { balcam: 1400, fortal: 1400, belemx: 1400 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Modulação Intestinal", //"1 programa MODULAÇÃO INTESTINAL (90 dias)",
          valor: { balcam: 990, fortal: 990, belemx: 1100 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Modulação Intestinal (plano família)", //"1 programa de MODULAÇÃO INTESTINAL plano família (90 dias)",
          valor: { balcam: 900, fortal: 900, belemx: 900 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Modulação Intestinal com Estética", // "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)",
          valor: { balcam: 1490, fortal: 1490, belemx: 1490 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S Online", //"1 programa de 3 consultas + 3 meses de Estética (1 área)",
          valor: { balcam: 990, fortal: 990, belemx: 990 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S", //"1 programa de 3 consultas + 3 meses de Estética (1 área)",
          valor: { balcam: 990, fortal: 990, belemx: 990 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S com Estética", //"1 programa de 3 consultas + 3 meses de Estética (1 área)",
          valor: { balcam: 1490, fortal: 1490, belemx: 1490 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)",
          valor: { balcam: 1990, fortal: 1990, belemx: 1990 },
          transactModus: ["any"],
          duracao: 12 * 7,
          incl:
            "Durante 12 semanas: 3 consultas nutricionais + dietas personalizadas + prescrição de supplementos + video-aulas + livro/diário do programa + exame nutrigenético",
        },
        {
          nome: "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)",
          valor: { balcam: 2190, fortal: 2190, belemx: 2190 },
          transactModus: ["any"],
          duracao: 12 * 7,
          incl:
            "Durante 12 semanas: 3 consultas nutricionais + dietas personalizadas + prescrição de supplementos + video-aulas + livro/diário do programa + exame nutrigenético",
        },
        {
          nome: "Power Shape 30",
          valor: { balcam: 560, fortal: 560, belemx: 560 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Glúteo de Ouro",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Lipodraining 24H",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Detox + Lipodraining 24H",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Harmonização Corporal 24H",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Lipo Vegana",
          valor: { balcam: 540, fortal: 540, belemx: 540 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Mini Lipo Vegana",
          valor: { balcam: 297, fortal: 297, belemx: 297 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Pacote Estético",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "1 sessão estética avulsa",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "1 exame de bioimpedância",
          valor: { balcam: 100, fortal: 100, belemx: 100 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame de Intolerância e Hipersensibilidade alimentar",
          valor: { balcam: 1910, fortal: 1910, belemx: 1910 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame de Microbioma Intestinal",
          valor: { balcam: 1970, fortal: 1970, belemx: 1970 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame Nutrigenético (DNA equilibrio) (a vista)",
          valor: { balcam: 1350, fortal: 1350, belemx: 1350 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame Nutrigenético (DNA equilibrio) (parcelado)",
          valor: { balcam: 1500, fortal: 1500, belemx: 1500 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame Genético Esportivo",
          valor: { balcam: 2760, fortal: 2760, belemx: 2760 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "Combo exames de Microbioma Intestinal + Hipersensibilidade alimentar + Nutrigenético",
          valor: { balcam: 5976, fortal: 5976, belemx: 5976 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "Protocolo Kirchhoff",
          valor: { balcam: 180, fortal: 180, belemx: 180 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Pacote 90 dias Protocolo Kirchhoff",
          valor: { balcam: 590, fortal: 590, belemx: 590 },
          transactModus: ["any"],
          duracao: 90,
        },
        // {
        //   nome: "Giftcard",
        //   valor: { balcam: 0, fortal: 0, belemx: 0 },
        //   transactModus: ["any"],
        //   duracao: 90,
        // },
        {
          nome: "HydraGloss",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Protocolo de Clareamento",
          valor: { balcam: 180, fortal: 180, belemx: 180 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Protocolo de Clareamento (Pacote de 4 sessões)",
          valor: { balcam: 600, fortal: 600, belemx: 600 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Entrada 1ª Consulta",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "Outro",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 0,
        },
      ],
      pacotesEsteticos1: [
        {
          nome: "UNO",
          valor: { balcam: 480, fortal: 480, belemx: 480 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "DUO",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "TRIPLO",
          valor: { balcam: 960, fortal: 960, belemx: 960 },
          transactModus: ["any"],
          duracao: 30,
        },
      ],
      pacotesEsteticos2: [
        "HIBRIUS",
        "DUALSOON",
        "ENDERMOTERAPIA",
        "CORRENTE RUSSA", //2x
        "MASSAGEM REDUTORA", // 2x
        "DRENAGEM LINFATICA", // 1x
        "MASSAGEM RELAXANTE", // 1x
        "REFLEXOLOGIA PODAL (10 sessões)", // 2x
        "REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)", // 2x
        "TERAPIA COM VENTOSAS (10 sessões)", // 2x
        "AURICULOTERAPIA (10 sessões)", // 2x
        "DRY NEEDLING (10 sessões)", // 2x
      ],
      pacotesEsteticos2_12S: [
        "HIBRIUS",
        "DUALSOON",
        "ENDERMOTERAPIA",
        "CORRENTE RUSSA", //2x
        "MASSAGEM REDUTORA", // 2x
        "DRENAGEM LINFATICA", // 1x
        "MASSAGEM RELAXANTE", // 1x
        // "REFLEXOLOGIA PODAL", // 2x
      ],
      sessoesEsteticos: [
        {
          nome: "Sessão de HIBRIUS",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de DUALSOON",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de LIPOSOON",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de MASSAGEM REDUTORA ou DRENAGEM CORPO TODO",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de MASSAGEM REDUTORA ou DRENAGEM LOCAL",
          valor: { balcam: 65, fortal: 65, belemx: 65 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de MASSAGEM RELAXANTE",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de CORRENTE RUSSA",
          valor: { balcam: 65, fortal: 65, belemx: 65 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de REFLEXOLOGIA PODAL",
          valor: { balcam: 80, fortal: 80, belemx: 80 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de REFLEXOLOGIA PODAL + ESCALDA PES",
          valor: { balcam: 100, fortal: 100, belemx: 100 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de TERAPIA COM VENTOSAS",
          valor: { balcam: 80, fortal: 80, belemx: 80 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de LIMPEZA DE PELE",
          valor: { balcam: 180, fortal: 180, belemx: 180 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de MICROAGULHAMENTO",
          valor: { balcam: 250, fortal: 250, belemx: 250 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de AURICULOTERAPIA",
          valor: { balcam: 80, fortal: 80, belemx: 80 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de DRY NEEDLING",
          valor: { balcam: 80, fortal: 80, belemx: 80 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Sessão de PEELING",
          valor: { balcam: 220, fortal: 220, belemx: 220 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        //   sessoesEsteticosValores: [/*65, */120, 120, 65, 120, 65, 65],
      ],
      gastoItems: [
        "Salário",
        "Aluguel e Condomínio",
        "Luz",
        "Água",
        "Internet/Telefone",
        "Outro",
      ],
      gastoValores: [1500, 2500, 500, 100, 150, 0],
      willKnowEstet: [
        "1 sessão estética avulsa",
        "Pacote Estético",
        "Programa 12S com Estética",
        "Programa Clínico Funcional com Estética",
        "Programa Modulação Intestinal com Estética",
      ],
      // dataNascDia: null,
      // dataNascMes: null,
      // dataNascAno: null,
      cpfOk: false,
      mandatory: [(v) => !!v || v == 0 || "Campo obrigatório"],
      // numbersOnly2: [(v) => !/[^0-9]+/.test(v) || "!!", (v) => !!v || "!!", (v) => v.length <= 2 || "!!"],
      // numbersOnly4: [(v) => !/[^0-9]+/.test(v) || "!!", (v) => !!v || "!!", (v) => v.length <= 4 || "!!"],
      hasDots: [
        (v) => /^\d+(\.\d+)*$/.test(v) || "Use ponto, não vírgula!",
        (v) => /^\S*$/.test(v) || "Não pode incluir espaços",
      ],
      temas: [
        { text: "Veganismo", value: "veganismo" },
        { text: "Saúde da Mulher", value: "saudeDaMulher" },
        { text: "Rendimento Desportivo", value: "rendimento" },
        { text: "Reeducação Alimentar", value: "reeducacao" },
        { text: "Obesidade e Emagrecimento", value: "obesidadeEmag" },
        { text: "Intolerância Alimentar", value: "intoleranciaAlimentar" },
        { text: "Imunidade", value: "imunidade" },
        { text: "Geriatria e Longevidade", value: "geri" },
        { text: "Esteatose Hepática", value: "estea" },
        { text: "Emagrecimento e Estética", value: "emagEste" },
        { text: "Doenças Autoimunes", value: "doencasAuto" },
        { text: "Distúrbios Gastrointestinais", value: "distGastro" },
        { text: "Dislipidemia", value: "dislip" },
        { text: "Diabetes", value: "diabetes" },
        { text: "Cirurgia Bariátrica", value: "cirBar" },
        { text: "Ansiedade", value: "ansiedade" },
        { text: "Programa 12S", value: "programa12s" },
      ],
      objs: {
        programa12s: {
          id: "programa12s",
          nome: "Programa 12S",
          videos: [
            {
              nome: "O Clube da Nutricionista",
              url: "https://youtu.be/j08ORSLrexA",
              yt: "j08ORSLrexA",
            },
          ],
          ebooks: [
            {
              nome: "O programa 12S",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2F12-semanas-V2.pdf?alt=media&token=2c76116c-0edb-40bb-8158-e91002f8b6e7",
            },
          ],
        },
        veganismo: {
          id: "veganismo",
          nome: "Veganismo",
          videos: [
            {
              nome: "Veganismo e Vegetarianismo",
              url: "https://youtu.be/tRIWWzZnNTM",
              yt: "tRIWWzZnNTM",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Espaguete Cremoso De Abobrinha",
              url: "https://youtu.be/92jjRI6d6h4",
              yt: "92jjRI6d6h4",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
          ],
          ebooks: [
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
            {
              nome: "Veganismo",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fveganismo.pdf?alt=media&token=a0104b17-4172-4db8-a949-9ac4f6d8677b",
            },
          ],
        },
        saudeDaMulher: {
          id: "saudeDaMulher",
          nome: "Saúde da Mulher",
          videos: [
            {
              nome: "Saúde de Mulher",
              url: "https://youtu.be/e6fsCJrkIdU",
              yt: "e6fsCJrkIdU",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Espaguete Cremoso De Abobrinha",
              url: "https://youtu.be/92jjRI6d6h4",
              yt: "92jjRI6d6h4",
            },
            {
              nome: "Hambúrguer De Carne com Quinoa",
              url: "https://youtu.be/BxZcf9SWxdw",
              yt: "BxZcf9SWxdw",
            },
            {
              nome: "Pizza De Brócolis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Biscoito De Aveia Com Geleia De Morango",
              url: "https://youtu.be/okNUTRmqEl4",
              yt: "okNUTRmqEl4",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
          ],
          ebooks: [
            {
              nome: "Saúde da Mulher",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FsaudeDaMulher.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        rendimento: {
          id: "rendimento",
          nome: "Rendimento Desportivo",
          videos: [
            {
              nome: "Alimentação na Prática de Atividade Física",
              url: "https://youtu.be/KYLLZDYl32o",
              yt: "KYLLZDYl32o",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer De Carne com Quinoa",
              url: "https://youtu.be/BxZcf9SWxdw",
              yt: "BxZcf9SWxdw",
            },
            {
              nome: "Biscoito De Aveia Com Geleia De Morango",
              url: "https://youtu.be/okNUTRmqEl4",
              yt: "okNUTRmqEl4",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
          ],
          ebooks: [
            {
              nome: "Rendimento Desportivo",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        reeducacao: {
          nome: "Reeducação Alimentar",
          id: "reeducacao",
          videos: [
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome:
                "Mudança do Estilo de Vida para Potencializar Resultados Estéticos",
              url: "https://youtu.be/n9KyrRSeKY4",
              yt: "n9KyrRSeKY4",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Farofa De Agridoce",
              url: "https://youtu.be/_duLx9sSgoY",
              yt: "_duLx9sSgoY",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
          ],
          ebooks: [
            {
              nome: "Reeducação Alimentar",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FreeducacaoAlimentar.pdf?alt=media&token=28bb6184-666d-403d-b8bc-dcd54baef9b4",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        obesidadeEmag: {
          id: "obesidadeEmag",
          nome: "Obesidade e Emagrecimento",
          videos: [
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Esteatose Hepática (gordura no fígado)",
              url: "https://youtu.be/V1bbDKS6Jd4",
              yt: "V1bbDKS6Jd4",
            },
            {
              nome:
                "Mudança do Estilo de Vida para Potencializar Resultados Estéticos",
              url: "https://youtu.be/n9KyrRSeKY4",
              yt: "n9KyrRSeKY4",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Biscoito De Aveia Com Geleia De Morango",
              url: "https://youtu.be/okNUTRmqEl4",
              yt: "okNUTRmqEl4",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
          ],
          ebooks: [
            {
              nome: "Obesidade e Emagrecimento",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FobesidadeEmagrecimento.pdf?alt=media&token=a27da780-ca27-4509-8b92-39712abc914d",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        intoleranciaAlimentar: {
          id: "intoleranciaAlimentar",
          nome: "Intolerância Alimentar",
          videos: [
            {
              nome: "Alergias Alimentares",
              url: "https://youtu.be/Xi-VpBsZzow",
              yt: "Xi-VpBsZzow",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Farofa De Agridoce",
              url: "https://youtu.be/_duLx9sSgoY",
              yt: "_duLx9sSgoY",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Bolo De Cenoura Trufado",
              url: "https://youtu.be/Fe2ae-JI_j8",
              yt: "Fe2ae-JI_j8",
            },
            {
              nome: "Brigadeiro Funcional",
              url: "https://youtu.be/2I9K3CyjIRQ",
              yt: "2I9K3CyjIRQ",
            },
          ],
          ebooks: [
            {
              nome: "Intolerância Alimentar",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FintoleranciaAlimentar.pdf?alt=media&token=d92f695b-1ff3-4617-a2ac-1d3ec03e2627",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        imunidade: {
          id: "imunidade",
          nome: "Imunidade",
          videos: [
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Caldo De Abóbora Com Espinafre e Açafrão",
              url: "https://youtu.be/IcOFYv_cuEY",
              yt: "IcOFYv_cuEY",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Cookies de Banana com Castanha",
              url: "https://youtu.be/CxGyhnP3Zro",
              yt: "CxGyhnP3Zro",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Bolo De Cenoura Trufado",
              url: "https://youtu.be/Fe2ae-JI_j8",
              yt: "Fe2ae-JI_j8",
            },
            {
              nome: "Pizza De Brocólis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Torta Proteica de Frango",
              url: "https://youtu.be/2ulYqe6Rm90",
              yt: "2ulYqe6Rm90",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
          ],
          ebooks: [
            {
              nome: "Imunidade",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fimunidade.pdf?alt=media&token=0f2fb9e0-48db-4b1b-8a88-628278eb0f7d",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        geri: {
          id: "geri",
          nome: "Geriatria e Longevidade",
          videos: [
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Caldo De Abóbora Com Espinafre e Açafrão",
              url: "https://youtu.be/IcOFYv_cuEY",
              yt: "IcOFYv_cuEY",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
          ],
          ebooks: [
            {
              nome: "Geriatria e Longevidade",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FgeriatriaLongevidade.pdf?alt=media&token=1e09603d-6aa4-48f1-8033-70cda48ae4db",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        estea: {
          id: "estea",
          nome: "Esteatose Hepática",
          videos: [
            {
              nome: "Esteatose Hepática (gordura no fígado)",
              url: "https://youtu.be/V1bbDKS6Jd4",
              yt: "V1bbDKS6Jd4",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Abobrinha Recheada",
              url: "https://youtu.be/jqx1TbZ5ahY",
              yt: "jqx1TbZ5ahY",
            },
            {
              nome: "Espaguete Cremoso De Abobrinha",
              url: "https://youtu.be/92jjRI6d6h4",
              yt: "92jjRI6d6h4",
            },
            {
              nome: "Pizza De Brócolis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
          ],
          ebooks: [
            {
              nome: "Esteatose Hepática",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FesteatoseHepatica.pdf?alt=media&token=251bd6ff-209d-406e-9f2b-345ca4ae73b9",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        emagEste: {
          nome: "Emagrecimento e Estética",
          id: "emagEste",
          videos: [
            {
              nome:
                "Mudança do Estilo de Vida para Potencializar Resultados Estéticos",
              url: "https://youtu.be/n9KyrRSeKY4",
              yt: "n9KyrRSeKY4",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Abobrinha Recheada",
              url: "https://youtu.be/jqx1TbZ5ahY",
              yt: "jqx1TbZ5ahY",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
          ],
          ebooks: [
            {
              nome: "Emagrecimento e Estética",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FemagrecimentoEstetica.pdf?alt=media&token=675485f1-4f54-41dd-a507-65de3150b464",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        doencasAuto: {
          id: "doencasAuto",
          nome: "Doenças Autoimunes",
          videos: [
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Torta Proteica de Frango",
              url: "https://youtu.be/2ulYqe6Rm90",
              yt: "2ulYqe6Rm90",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
            {
              nome: "Smoothie de Abacaxi com Coco",
              url: "https://youtu.be/wIAORt6SJY0",
              yt: "wIAORt6SJY0",
            },
          ],
          ebooks: [
            {
              nome: "Doenças Autoimunes",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FdoencasAutoimunes.pdf?alt=media&token=bd6a8cd3-bd72-4a07-8d0d-9a3d15f613e5",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        distGastro: {
          id: "distGastro",
          nome: "Distúrbios Gastrointestinais",
          videos: [
            {
              nome: "Alterações Gastrointestinais",
              url: "https://youtu.be/ukJQrYSMijU",
              yt: "ukJQrYSMijU",
            },
            {
              nome: "Constipação e Diarreia",
              url: "https://youtu.be/l6tTXSp-9WE",
              yt: "l6tTXSp-9WE",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Bolo De Cenoura Trufado",
              url: "https://youtu.be/Fe2ae-JI_j8",
              yt: "Fe2ae-JI_j8",
            },
            {
              nome: "Brigadeiro Funcional",
              url: "https://youtu.be/2I9K3CyjIRQ",
              yt: "2I9K3CyjIRQ",
            },
            {
              nome: "Smoothie de Abacaxi com Coco",
              url: "https://youtu.be/wIAORt6SJY0",
              yt: "wIAORt6SJY0",
            },
          ],
          ebooks: [
            {
              nome: "Distúrbios Gastrointestinais",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FdisturbiosGastrointestinais.pdf?alt=media&token=41fe36d9-5bae-45bf-bb7e-d60beed4cb63",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        dislip: {
          id: "dislip",
          nome: "Dislipidemia",
          video: [
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Abobrinha Recheada",
              url: "https://youtu.be/jqx1TbZ5ahY",
              yt: "jqx1TbZ5ahY",
            },
            {
              nome: "Estrogonofe Funcional",
              url: "https://youtu.be/S_Z_NpuBvbs",
              yt: "S_Z_NpuBvbs",
            },
            {
              nome: "Hambúrguer Vegano De Quinoa Com Ervas",
              url: "https://youtu.be/VF4UO_wKlUc",
              yt: "VF4UO_wKlUc",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
            {
              nome: "Smoothie de Abacaxi com Coco",
              url: "https://youtu.be/wIAORt6SJY0",
              yt: "wIAORt6SJY0",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
          ],
          ebooks: [
            {
              nome: "Dislipidemia",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fdislipidemia.pdf?alt=media&token=ee605280-627d-49fe-abe4-2a833fffa729",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        diabetes: {
          nome: "Diabetes",
          id: "diabetes",
          videos: [
            {
              yt: "z1Xvm0R5ndk",
              url: "https://youtu.be/z1Xvm0R5ndk",
              nome: "Diabetes Mellitus",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Abobrinha Recheada",
              url: "https://youtu.be/jqx1TbZ5ahY",
              yt: "jqx1TbZ5ahY",
            },
            {
              nome: "Espaguete Cremoso De Abobrinha",
              url: "https://youtu.be/92jjRI6d6h4",
              yt: "92jjRI6d6h4",
            },
            {
              nome: "Pizza De Brócolis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Biscoito de Amêndoas com Canela",
              url: "https://youtu.be/hL5uNkRUVN0",
              yt: "hL5uNkRUVN0",
            },
            {
              nome: "Bombom Saudável",
              url: "https://youtu.be/PBCpPOxdIZI",
              yt: "PBCpPOxdIZI",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
          ],
          ebooks: [
            {
              nome: "Diabetes",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fdiabetes.pdf?alt=media&token=157a59d3-5362-4e5d-a9e0-4744d3781c04",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        cirBar: {
          id: "cirBar",
          nome: "Cirurgia Bariátrica",
          videos: [
            {
              nome: "Síndrome Metabólica",
              url: "https://youtu.be/1ZuxRuMtivA",
              yt: "1ZuxRuMtivA",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Obesidade e Emagrecimento",
              url: "https://youtu.be/JeBZRypsXm0",
              yt: "JeBZRypsXm0",
            },
            {
              nome:
                "Mudança do Estilo de Vida para Potencializar Resultados Estéticos",
              url: "https://youtu.be/n9KyrRSeKY4",
              yt: "n9KyrRSeKY4",
            },
            {
              nome: "Alimentação Low Carb",
              url: "https://youtu.be/WDOrdyNGZ5Y",
              yt: "WDOrdyNGZ5Y",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Caldo De Abóbora Com Espinafre e Açafrão",
              url: "https://youtu.be/IcOFYv_cuEY",
              yt: "IcOFYv_cuEY",
            },
            {
              nome: "Omelete de Forno",
              url: "https://youtu.be/mBGUu2EspwA",
              yt: "mBGUu2EspwA",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Cookies de Banana com Castanha",
              url: "https://youtu.be/CxGyhnP3Zro",
              yt: "CxGyhnP3Zro",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
          ],
          ebooks: [
            {
              nome: "Cirurgia Bariátrica",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FcirurgiaBariatrica.pdf?alt=media&token=9f649456-4b13-4707-8b35-f9c44d175fe8",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
        ansiedade: {
          id: "ansiedade",
          nome: "Ansiedade",
          videos: [
            {
              nome: "Ansiedade e Nutrição",
              url: "https://youtu.be/PMSMjZaJ2QI",
              yt: "PMSMjZaJ2QI",
            },
            {
              nome: "Leitura de rótulos",
              url: "https://youtu.be/mRP8yct7ASo",
              yt: "mRP8yct7ASo",
            },
            {
              nome: "Estresse e Saúde",
              url: "https://youtu.be/veiEcx3QbL0",
              yt: "veiEcx3QbL0",
            },
            {
              nome: "Reeducação Alimentar",
              url: "https://youtu.be/cAS4re4jNPY",
              yt: "cAS4re4jNPY",
            },
            {
              nome: "Biomassa de Banana",
              url: "https://youtu.be/uBer1qKm8t0",
              yt: "uBer1qKm8t0",
            },
            {
              nome: "Bebida Vegetal de Coco",
              url: "https://youtu.be/FXQQP2lMBm0",
              yt: "FXQQP2lMBm0",
            },
            {
              nome: "Molho de Tomate Caseiro",
              url: "https://youtu.be/vy8dPyACbdg",
              yt: "vy8dPyACbdg",
            },
            {
              nome: "Sal de Ervas",
              url: "https://youtu.be/UyVl6wxDJM8",
              yt: "UyVl6wxDJM8",
            },
            {
              nome: "Empada Saudável",
              url: "https://youtu.be/L4kfaneiy8A",
              yt: "L4kfaneiy8A",
            },
            {
              nome: "Farofa De Agridoce",
              url: "https://youtu.be/_duLx9sSgoY",
              yt: "_duLx9sSgoY",
            },
            {
              nome: "Pizza De Brócolis",
              url: "https://youtu.be/rrjJwAUKgZg",
              yt: "rrjJwAUKgZg",
            },
            {
              nome: "Risoto De Açafrão e Alho Poró",
              url: "https://youtu.be/JmBv69NWCeQ",
              yt: "JmBv69NWCeQ",
            },
            {
              nome: "Salada De Verão",
              url: "https://youtu.be/MmV7JZ7cccA",
              yt: "MmV7JZ7cccA",
            },
            {
              nome: "Biscoito De Aveia Com Geleia De Morango",
              url: "https://youtu.be/okNUTRmqEl4",
              yt: "okNUTRmqEl4",
            },
            {
              nome: "Bolo De Cacau",
              url: "https://youtu.be/AJ1J27JcFQM",
              yt: "AJ1J27JcFQM",
            },
            {
              nome: "Bolo De Maracujá",
              url: "https://youtu.be/LYEVp-to2Zw",
              yt: "LYEVp-to2Zw",
            },
            {
              nome: "Mousse De Abacate",
              url: "https://youtu.be/RF93JkAHejc",
              yt: "RF93JkAHejc",
            },
          ],
          ebooks: [
            {
              nome: "Ansiedade",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2Fansiedade.pdf?alt=media&token=99d9646f-9b83-471e-bf8c-be61b0bf2297",
            },
            {
              nome: "Leitura de Rótulos",
              url:
                "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2FleituraDeRotulos.pdf?alt=media&token=17113cb6-aeeb-4347-93b5-c740f0216989",
            },
          ],
        },
      },
    };
  },
  computed: {
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    getItems() {
      return this.vendaItems.map(function(item) {
        return item.nome;
      });
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    // pacienteNr: function () {
    //   return this.pacientes.length;
    // },
    // pacienteRatio: function () {
    //   return (this.pacienteNr / this.pacienteNrMax) * 100;
    // },
    agendaPacienteObj() {
      return this.$store.state.firestore.agendaPacienteObj;
    },
    agendaPacienteEmail() {
      return this.$store.state.firestore.agendaPacienteEmail;
    },
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    funcionarios() {
      return this.$store.state.firestore.funcionarios;
    },
    meses() {
      var monthTexts = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Augosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Decembro",
      ];
      var indexList = [0, 1, 2, 3, 4, 5];
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth();
      var monthIndexList = indexList.map(function(i) {
        return i + month;
      });

      var makeMonthStr = function(index) {
        var monthYear = year;
        var getIndex = index;
        if (index > 11) {
          monthYear = year + 1;
          getIndex = index - 12;
        }
        var monthName = monthTexts[getIndex];
        var monthStr = monthName + "/" + monthYear;
        return monthStr;
      };

      var strList = monthIndexList.map(function(index) {
        return { text: makeMonthStr(index), value: index - month };
      });

      return strList;
    },
    meses12s() {
      var monthTexts = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Augosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Decembro",
      ];
      var indexList = [0, 1, 2];
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth();
      var monthIndexList = indexList.map(function(i) {
        return i + month;
      });

      var makeMonthStr = function(index) {
        var monthYear = year;
        var getIndex = index;
        if (index > 11) {
          monthYear = year + 1;
          getIndex = index - 12;
        }
        var monthName = monthTexts[getIndex];
        var index1 = index - month + 1;
        var monthStr = index1 + "° mês (" + monthName + "/" + monthYear + ")";
        return monthStr;
      };

      var strList = monthIndexList.map(function(index) {
        return { text: makeMonthStr(index), value: index - month };
      });

      return strList;
    },
    localCode() {
      if (
        sessionStorage.getItem("current-user") ==
        "umarizalbelem@clubedanutricionista.com.br"
      ) {
        return "belemx";
      } else if (
        sessionStorage.getItem("current-user") ==
        "bc@clubedanutricionista.com.br"
      ) {
        return "balcam";
      } else if (
        sessionStorage.getItem("current-user") ==
        "fortaleza.salinas@clubedanutricionista.com.br"
      ) {
        return "fortal";
      } else if (
        sessionStorage.getItem("current-user") == "thayanalucy@gmail.com"
      ) {
        return "belemx";
      } else {
        return "balcam";
      }
    },
    isPrograma() {
      var programasList = [
        "Programa Clínico Funcional",
        "Programa Modulação Intestinal",
        "Programa Clínico Funcional com Estética",
        "Programa Modulação Intestinal com Estética",
        "Programa Clínico Funcional (plano família)",
        "Programa Modulação Intestinal (plano família)",
        "Power Shape 30",
        "Programa 12S Online",
        "Programa 12S",
        "Programa 12S com Estética",
        "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)",
        "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)",
      ];
      return programasList.includes(this.mov.tipo);
    },
    descrNesc() {
      if (
        this.mov.tipo === "Outro" ||
        this.mov.tipo === "Entrada 1ª Consulta"
      ) {
        return true;
      } else {
        return false;
      }
    },
    formaIcon() {
      if (this.mov.direction) {
        var forma = this.mov.forma;
        if (forma === 1) {
          return this.icons.mdiCash100;
        } else if (forma === 2) {
          return this.icons.mdiCreditCardCheckOutline;
        } else if (forma === 3) {
          return this.icons.mdiCreditCardClockOutline;
        } else if (forma === 4) {
          return this.icons.mdiBankTransfer;
        } else if (forma === 5) {
          return this.icons.mdiInfinity;
        } else if (forma === 6) {
          return this.icons.mdiCheckbook;
        } else if (forma === 7) {
          return this.icons.mdiWalletPlusOutline;
        } else if (forma === 8) {
          return this.icons.mdiPiggyBank;
        } else {
          return this.icons.mdiHelpRhombusOutline;
        }
      } else {
        return this.icons.mdiGift;
      }
    },
  },
  methods: {
    // changeDataNasc() {
    //   const d = this.dataNascDia;
    //   const m = this.dataNascMes;
    //   const a = this.dataNascAno;
    //   var date = new Date();
    //   var nowYear = date.getFullYear() - 2000;
    //   if (d == null || d == undefined || m == null || m == undefined || a == null || a == undefined) {
    //    this.pacienteInfos.dataNascimento = null;
    //   } else {
    //     if (a !== null && a !== undefined && a > 0) {
    //       if (a < nowYear) {
    //         this.dataNascAno = 2000 + a;
    //       } else if (a > nowYear && a < 100) {
    //         this.dataNascAno = 1900 + a;
    //       } else if (a >= 100 && a < 1000) {
    //         this.dataNascAno = null;
    //       }
    //     }
    //   }
    // },
    setQuestBoolP(qP) {
      this.questPadrao = qP;
      this.questImunidade = false;
      this.questEstet = false;
    },
    setQuestBoolE(qE) {
      this.questPadrao = false;
      this.questImunidade = false;
      this.questEstet = qE;
    },
    setQuestBoolK(qK) {
      this.questPadrao = false;
      this.questImunidade = qK;
      this.questEstet = false;
    },
    greyOrBrown(bool) {
      if (bool) {
        return "#614021";
      } else {
        return "grey-lighten-2";
      }
    },
    checkFormasSum() {
      var sum =
        parseFloat(this.mov.formas.dinheiro) +
        parseFloat(this.mov.formas.deposito) +
        parseFloat(this.mov.formas.transferenciaBancaria) +
        parseFloat(this.mov.formas.pix) +
        parseFloat(this.mov.formas.cartaoDeCredito) +
        parseFloat(this.mov.formas.cartaoDeDebito);

      if (sum !== this.mov.valor) {
        this.formasSumIncorrect = true;
      } else {
        this.formasSumIncorrect = false;
      }
    },
    labelTipo(dirBool) {
      if (dirBool) {
        return "Tipo de Venda";
      } else {
        return "Tipo de Oferta";
      }
    },
    setMovPaciente() {
      this.pacienteInfos.email = this.pacienteInfos.email
        .toLowerCase()
        .replace(/\s/g, "");
      console.log(this.pacienteInfos.email);
      this.mov.paciente = this.pacienteInfos.email
        .toLowerCase()
        .replace(/\s/g, "");
    },
    labelConversion(bool) {
      if (bool) {
        return "Cadastrar Cliente Novo";
      } else {
        return "Cadastrar Prospecto";
      }
    },
    colorConversion(bool) {
      if (bool) {
        return "green";
      } else {
        return "#ff6666";
      }
    },
    conversionIcon(bool) {
      if (bool) {
        return this.icons.mdiAccount;
      } else {
        return this.icons.mdiAccountClock;
      }
    },
    setNull(bool) {
      this.conversion = bool;
      this.pacienteInfos = {
        gestante: false,
        sexo: "feminino",
        dataNascimento: null,
        telefone: "",
        whatsapp: "",
        nome: null,
        email: "",
        objetivo: null,
        motivoRecusao: null,
        motivoRecusaoOutro: null,
        meioContato: null,
        detalhesImportantes: null,
        // senha: "",
        // endereco: "",
        // cidade: "",
        // estado: "",
        // profissao: "",
        cpf: null,
        // estadoCivil: null,
        // filhos: "",
        // idadeFilhos: "",
        // indicacao: "",
      };
    },
    async getVoucher() {
      if (this.voucherDesconto !== null) {
        this.dialogVoucher = false;
        var av = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("avGratuita")
          .doc(this.voucherDesconto)
          .get()
          .then(function(doc) {
            return doc.data();
          });

        if (av !== undefined) {
          if (av.mov.tipo == "Programa 12S com Estética (1 pacote)") {
            av.mov.tipo = "Programa 12S com Estética";
            av.mov.pacotes = [
              {
                def1: null,
                def2: null,
                area1: null,
                area2: null,
                area3: null,
                dataInicio: null,
                millisInicio: null,
              },
            ];
          } else if (av.mov.tipo == "Programa 12S com Estética (2 pacotes)") {
            av.mov.tipo = "Programa 12S com Estética";
            av.mov.pacotes = [
              {
                def1: null,
                def2: null,
                area1: null,
                area2: null,
                area3: null,
                dataInicio: null,
                millisInicio: null,
              },
              {
                def1: null,
                def2: null,
                area1: null,
                area2: null,
                area3: null,
                dataInicio: null,
                millisInicio: null,
              },
            ];
          } else if (av.mov.tipo == "Programa 12S com Estética (3 pacotes)") {
            av.mov.tipo = "Programa 12S com Estética";
            av.mov.pacotes = [];
            for (var i3 = 0; i3 < 3; i3++) {
              av.mov.pacotes.push({
                def1: null,
                def2: null,
                area1: null,
                area2: null,
                area3: null,
                dataInicio: null,
                millisInicio: null,
              });
            }
          } else if (av.mov.tipo == "Programa 12S com Estética (4 pacotes)") {
            av.mov.tipo = "Programa 12S com Estética";
            av.mov.pacotes = [];
            for (var i4 = 0; i4 < 4; i4++) {
              av.mov.pacotes.push({
                def1: null,
                def2: null,
                area1: null,
                area2: null,
                area3: null,
                dataInicio: null,
                millisInicio: null,
              });
            }
          } else if (av.mov.tipo == "Programa 12S com Estética (5 pacotes)") {
            av.mov.tipo = "Programa 12S com Estética";
            av.mov.pacotes = [];
            for (var i5 = 0; i5 < 5; i5++) {
              av.mov.pacotes.push({
                def1: null,
                def2: null,
                area1: null,
                area2: null,
                area3: null,
                dataInicio: null,
                millisInicio: null,
              });
            }
          } else if (av.mov.tipo == "Programa 12S com Estética (6 pacotes)") {
            av.mov.tipo = "Programa 12S com Estética";
            av.mov.pacotes = [];
            for (var i6 = 0; i6 < 6; i6++) {
              av.mov.pacotes.push({
                def1: null,
                def2: null,
                area1: null,
                area2: null,
                area3: null,
                dataInicio: null,
                millisInicio: null,
              });
            }
          }
          this.mov = av.mov;
          this.chosenAv = av;
          this.voucherFound = true;
        } else {
          this.dialogVoucherNotFound = true;
          this.dialogVoucher = true;
          this.voucherFound = false;
        }
      } else {
        this.dialogVoucherNenhum = true;
        this.dialogVoucher = true;
        this.voucherFound = false;
      }
    },
    getVendedores() {
      this.vendedores = this.funcionarios.map(function(funcionario) {
        return funcionario.papel;
      });
    },
    handleComprovante(file) {
      this.comprovante = file;
    },
    setPacIncl() {
      if (this.mov.tipo == "Programa 12S Plano Básico") {
        this.mov.pacotes[0] = {
          def1: "1 área",
          def2: "Protocolo HIBRIUS 12S",
          area1: this.pac12S1area,
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (this.mov.tipo == "Programa 12S Plano Premium") {
        this.mov.pacotes[0] = {
          def1: "2 áreas",
          def2: "Protocolo HIBRIUS 12S",
          area1: this.pac12S1area,
          area2: this.pac12S2area,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (this.mov.tipo == "Programa 12S Plano VIP") {
        this.mov.pacotes[0] = {
          def1: "3 áreas",
          def2: "Protocolo HIBRIUS 12S",
          area1: this.pac12S1area,
          area2: this.pac12S2area,
          area3: this.pac12S3area,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (this.mov.tipo == "Protocolo Glúteo de Ouro") {
        this.mov.pacotes[0] = {
          def1: "Glúteo",
          def2: "8 passos",
          area1: "GLÚTEO",
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (this.mov.tipo == "Protocolo Harmonização Corporal 24H") {
        this.mov.pacotes[0] = {
          def1: "2 áreas",
          def2: "Massagem Moduladora",
          area1: this.pacH1area,
          area2: this.pacH2area,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      } else if (
        this.mov.tipo == "Protocolo Lipodraining 24H" ||
        this.mov.tipo == "Protocolo Detox + Lipodraining 24H"
      ) {
        if (this.pacDldef1) {
          this.pacDl1area = null;
          this.pacDl2area = null;
          this.mov.pacotes[0] = {
            def1: "Corpo inteiro",
            def2: "Drenagem Linfática",
            area1: null,
            area2: null,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            index: 0,
          };
        } else {
          this.mov.pacotes[0] = {
            def1: "2 áreas",
            def2: "Drenagem Linfática",
            area1: this.pacDl1area,
            area2: this.pacDl2area,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            index: 0,
          };
        }
      }
    },
    async salvarMov() {
      this.warnMessages = [];
      this.salvoDialog = true;
      var d = new Date();
      var milliseconds = d.getTime();
      this.mov.pacienteCpf = this.pacienteInfos.cpf;
      this.mov.paciente = this.pacienteInfos.email
        .toLowerCase()
        .replace(/\s/g, "");
      this.mov.millis = milliseconds;
      this.mov.y = d.getFullYear();
      this.mov.m = d.getMonth() + 1;
      this.mov.d = d.getDate();

      var dayStr;
      if (d.getDate() < 10) {
        dayStr = "0" + d.getDate().toString();
      } else {
        dayStr = d.getDate().toString();
      }

      var monthStr;
      var month = d.getMonth() + 1;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }

      var yearStr = d.getFullYear().toString();

      var dateStr = dayStr + "/" + monthStr + "/" + yearStr;
      var simpleDateStr = dayStr + monthStr + yearStr;

      this.mov.dateStr = dateStr;
      // var pacientes = this.pacientes;
      if (!this.compradorDesconhecido) {
        if (this.mov.paciente !== null) {
          // var getName = function (id) {
          //   var el = pacientes.find((item) => item.value == id);
          //   console.log(el)
          //   return el.text;
          // };
          this.mov.pacienteName = this.pacienteInfos.nome;
        } else {
          this.mov.pacienteName = "";
        }
      }
      this.mov.descontoAct = this.descontoAct;

      var checkedForms = true;
      if (this.mov.direction) {
        if (this.mov.forma == null) {
          this.warnMessages.push("Definir forma de pagamento!");
          checkedForms = false;
        }
        if (this.mov.paciente == null) {
          this.warnMessages.push("Definir comprador!");
          checkedForms = false;
        }
        if (this.mov.comissao == null && this.isFranquia) {
          this.warnMessages.push("Definir vendedor!");
          checkedForms = false;
        }
        if (this.mov.pacienteCpf == null && this.isFranquia) {
          this.warnMessages.push("Inserir CPF do comprador!");
          checkedForms = false;
        }
        // if (this.comprovante == null) {
        //   this.warnMessages.push("Subir comprovante de pagamento!");
        //   checkedForms = false;
        // }
      }
      if (
        /*this.pacAdiPrograma ||*/ this.mov.tipo == "Pacote Estético" &&
        this.isFranquia
      ) {
        // if (this.$refs.formNrPacs.validate()) {
        console.log("Nr of Pacs ok");
        var pacsFormOkList = this.mov.pacotes.map(function(pac) {
          var bool = 0;
          if (pac.def1 == null) {
            bool = 1;
          } else {
            if (pac.def1 == "UNO") {
              if (pac.area1 == null) {
                bool = 1;
              }
            } else if (pac.def1 == "DUO") {
              if (pac.area1 == null /* || pac.area2 == null*/) {
                bool = 1;
              }
            } else if (pac.def1 == "TRIPLO") {
              if (pac.area1 == null || pac.area2 == null || pac.area3 == null) {
                bool = 1;
              }
            } else {
              bool = 1;
            }
          }
          return bool;
        });

        var adding = function(accumulator, a) {
          return accumulator + a;
        };
        var pacFormOkSum = pacsFormOkList.reduce(adding, 0);
        if (pacFormOkSum > 0) {
          this.warnMessages.push("Detalhe de um pacote estético faltando!");
          checkedForms = false;
        } else {
          checkedForms = true;
        }
        // } else {
        //   this.warnMessages.push("Definir número de pacotes!");
        //   checkedForms = false;
        // }
      } else if (
        this.mov.tipo == "Protocolo Harmonização Corporal 24H" &&
        this.isFranquia
      ) {
        if (this.pacH1area == null) {
          this.warnMessages.push("Definir área 1 da Massagem Moduladora!");
          checkedForms = false;
        }

        if (this.pacH2area == null) {
          this.warnMessages.push("Definir área 2 da Massagem Moduladora!");
          checkedForms = false;
        }
      } else if (
        (this.mov.tipo == "Protocolo Lipodraining 24H" ||
          this.mov.tipo == "Protocolo Detox + Lipodraining 24H") &&
        this.isFranquia
      ) {
        if (!this.pacDldef1) {
          if (this.pacDl2area == null) {
            this.warnMessages.push("Definir área 2 da Drenagem Linfática!");
            checkedForms = false;
          }
          if (this.pacDl1area == null) {
            this.warnMessages.push("Definir área 1 da Drenagem Linfática!");
            checkedForms = false;
          }
        }
      }

      if (checkedForms) {
        var infos = {};
        if (this.comprovante !== null) {
          console.log("Enviando comprovante!");
          var nutri = sessionStorage.getItem("current-user");
          var paciente;
          if (this.mov.paciente !== null) {
            paciente = this.mov.paciente;
          } else {
            paciente = "desconhecido";
          }
          await firebase
            .storage()
            .ref("comprovantesDePag")
            .child(
              nutri.toString() +
                "_" +
                paciente +
                "_" +
                simpleDateStr +
                "_comprovante"
            )
            .put(this.comprovante)
            .then(async (snapshot) => {
              infos.comprovante = await snapshot.ref.getDownloadURL();
              console.log(infos.comprovante);
            });
        } else {
          infos.comprovante = null;
        }

        this.mov.comprovante = infos.comprovante;

        console.log(this.mov);
        if (this.mov.direction) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("caixa")
            .doc(milliseconds.toString())
            .set(this.mov);

          if (this.mov.paciente !== null) {
            await firebase
              .firestore()
              .collection("users")
              .doc(this.mov.paciente)
              .collection("compras")
              .doc(milliseconds.toString())
              .set(this.mov);

            var ltvInit = Math.round(parseFloat(this.mov.valor));

            await firebase
              .firestore()
              .collection("users")
              .doc(this.mov.paciente)
              .update({
                ltv: ltvInit,
              });

            var willKnowEstetList = this.willKnowEstet;
            var knowsEstet = function(tipo) {
              return willKnowEstetList.includes(tipo);
            };

            if (this.isPrograma && this.isFranquia) {
              var now = new Date();
              var nowMillis = now.getTime();
              var endMillis = 12 * 7 * 24 * 60 * 60 * 1000 + nowMillis;

              await firebase
                .firestore()
                .collection("users")
                .doc(this.mov.paciente)
                .update({
                  programaFim: endMillis,
                  programaTipo: this.mov.tipo,
                });

              await firebase
                .firestore()
                .collection("users")
                .doc(sessionStorage.getItem("current-user"))
                .collection("pacientes")
                .doc(this.mov.paciente)
                .update({
                  programaFim: endMillis,
                  programaTipo: this.mov.tipo,
                });
            }

            if (knowsEstet(this.mov.tipo)) {
              await firebase
                .firestore()
                .collection("users")
                .doc(this.mov.paciente)
                .update({
                  knowsEstet: true,
                });
            }

            var objs = this.objs;
            var pacienteDocID = this.mov.paciente;

            // this.temasToLiberate.forEach(function (tema) {
            //   var obj = objs[tema];
            //   firebase
            //     .firestore()
            //     .collection("users")
            //     .doc(pacienteDocID)
            //     .collection("videoAulas")
            //     .doc(tema)
            //     .set(obj);
            // });

            for (var i = 0; i < this.temasToLiberate.length; i++) {
              var tema = this.temasToLiberate[i];
              var obj = objs[tema];
              firebase
                .firestore()
                .collection("users")
                .doc(pacienteDocID)
                .collection("videoAulas")
                .doc(tema)
                .set(obj);
            }

            this.precisaAgendamento();
            this.setNullMov();

            this.$store.dispatch("firestore/agendaPacienteNome_noRedirect", {
              obj: {
                nome: null,
                sexo: null,
                telefone: null,
                whatsapp: null,
                email: null,
                objetivo: null,
                motivoRecusao: null,
                motivoRecusaoOutro: null,
                meioContato: null,
                detalhesImportantes: null,
                horarioLigacao: null,
                millis: null,
                dia: null,
                mes: null,
                ano: null,
              },
            });
            this.saved = true;
          } else {
            this.warnMessages.push("Nenhuma paciente escolhido!");
          }
        } else {
          this.mov.valor = -1 * this.mov.valor;
          firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("caixa")
            .doc(milliseconds.toString())
            .set(this.mov);

          this.setNullMov();
          this.saved = true;
        }
      } else {
        this.warnDialog = true;
      }
      console.log("Mov terminated!");
    },
    precisaAgendamento: function() {
      if (this.isFranquia) {
        var precisam = [
          "Programa Clínico Funcional",
          "Programa Modulação Intestinal",
          "Programa Clínico Funcional com Estética",
          "Programa Modulação Intestinal com Estética",
          "Programa Clínico Funcional (plano família)",
          "Programa Modulação Intestinal (plano família)",
          "Programa 12S Online",
          "Programa 12S",
          "Power Shape 30",
          "Programa 12S com Estética",
          "Protocolo Harmonização Corporal 24H",
          "Protocolo Detox + Lipodraining 24H",
          "Protocolo Lipodraining 24H",
          "Protocolo Glúteo de Ouro",
          "Pacote 90 dias Protocolo Kirchhoff",
          "Pacote Estético",
          "Sessão Estética",
          "Protocolo de Clareamento (Pacote de 4 sessões)",
          "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)",
          "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)",
          "Lipo Vegana",
          "Mini Lipo Vegana"
        ];
        if (precisam.includes(this.mov.tipo) && this.isFranquia) {
          this.$store.dispatch("firestore/agendaMov", { mov: this.mov });
        }
      } else {
        console.log("no redirecting at current plan");
      }
    },
    // labelPacNrSetter(tipo) {
    //   if (tipo == "Pacote Estético") {
    //     return "Quantos pacotes estéticos?";
    //   } else {
    //     return "Quantos pacotes estéticos adicionais?";
    //   }
    // },
    checkReflexPodal(pac) {
      if (pac.def2 == "REFLEXOLOGIA PODAL (10 sessões)") {
        pac.area1 = "PÉ";
        pac.def1 = "DUO";
        this.setValor(this.mov.tipo, this.mov.descr);
      } else if (pac.def2 == "REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)") {
        pac.def1 = "DUO";
        pac.area1 = "PÉ";
        this.setValor(this.mov.tipo, this.mov.descr);
      } else if (pac.def2 == "TERAPIA COM VENTOSAS (10 sessões)") {
        pac.area1 = "---";
        pac.def1 = "DUO";
        this.setValor(this.mov.tipo, this.mov.descr);
      } else if (pac.def2 == "AURICULOTERAPIA (10 sessões)") {
        pac.area1 = "---";
        pac.def1 = "DUO";
        this.setValor(this.mov.tipo, this.mov.descr);
      } else if (pac.def2 == "DRY NEEDLING (10 sessões)") {
        pac.area1 = "---";
        pac.def1 = "DUO";
        this.setValor(this.mov.tipo, this.mov.descr);
      }
      pac.dataInicio = 0;
      this.setMillisPacote(pac.index);
      console.log(this.mov.pacotes);
    },
    disableArea2(def1, def2) {
      return (
        def2 == "TERAPIA COM VENTOSAS (10 sessões)" ||
        def2 == "AURICULOTERAPIA (10 sessões)" ||
        def2 == "DRY NEEDLING (10 sessões)" ||
        def2 == "REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)" ||
        def2 == "REFLEXOLOGIA PODAL (10 sessões)" ||
        def1 == "UNO" ||
        def1 == "DUO" ||
        def1 == null
      );
    },
    getCorrectAreas(def1) {
      if (def1 == "DUO") {
        return this.areasPares;
      } else {
        return this.areas;
      }
    },
    disableArea3(def1, def2) {
      return (
        def2 == "TERAPIA COM VENTOSAS (10 sessões)" ||
        def2 == "AURICULOTERAPIA (10 sessões)" ||
        def2 == "DRY NEEDLING (10 sessões)" ||
        def2 == "REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)" ||
        def2 == "REFLEXOLOGIA PODAL (10 sessões)" ||
        def1 !== "TRIPLO"
      );
    },
    niceString(valor) {
      var valorKomma;

      var decimals = Math.round((valor - Math.floor(valor)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valor);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valor / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    setNullMov(dir) {
      this.mov = {
        direction: dir,
        tipo: null,
        descr: null,
        paciente: null,
        pacienteName: null,
        pacienteCpf: null,
        y: null,
        m: null,
        d: null,
        comprovante: null,
        valor: null,
        pacotes: [
          {
            def1: null,
            def2: null,
            area1: null,
            area2: null,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            index: 0,
          },
        ],
        forma: null,
      };
      this.temasToLiberate = [];
    },
    setNrPacotes(nr, tipo) {
      if (tipo == "Programa Clínico Funcional com Estética") {
        if (nr == 1) {
          this.mov.valor = 900 + 500;
        } else if (nr == 2) {
          this.mov.valor = 900 + 500 + 400;
        } else if (nr >= 3) {
          this.mov.valor = (nr - 2) * 300 + 900 + 500 + 400;
        }
      } else {
        if (nr == 1) {
          this.mov.valor = 990 + 500;
        } else if (nr == 2) {
          this.mov.valor = 990 + 500 + 400;
        } else if (nr >= 3) {
          this.mov.valor = (nr - 2) * 300 + 990 + 500 + 400;
        }
      }
      this.mov.pacotes = [];
      for (var ix = 0; ix < nr; ix++) {
        this.mov.pacotes.push({
          def1: null,
          def2: null,
          area1: null,
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: ix,
        });
      }
    },
    setMillisPacote(index) {
      var date = new Date();
      var dateI =
        this.mov.pacotes[index].dataInicio * 28 * 24 * 60 * 60 * 1000 +
        date.getTime();
      var dateII = new Date(dateI);
      var millis = dateII.getTime();
      this.mov.pacotes[index].millisInicio = millis;
    },
    getNames(array) {
      return array.map(function(el) {
        return el.nome;
      });
    },
    // adjustValorCheck(bool, name) {
    //   var nameFilter = function (el) {
    //     return el.nome == name;
    //   };
    //   var vendaItemList = this.vendaItems.filter(nameFilter);
    //   var vendaItem = vendaItemList[0];

    //   var adding = function (accumulator, a) {
    //     return accumulator + a;
    //   };
    //   var localCode = this.localCode;
    //   if (bool) {
    //     this.mov.soldObjs.push(vendaItem);

    //     var valorArray = this.mov.soldObjs.map(function (obj) {
    //       return obj.valor[localCode];
    //     });

    //     console.log(valorArray);

    //     this.mov.valor = valorArray.reduce(adding, 0);
    //   } else {
    //     var exclFilter = function (el) {
    //       return el.nome !== name;
    //     };
    //     this.mov.soldObjs.filter(exclFilter);

    //     var valorArray2 = this.mov.soldObjs.map(function (obj) {
    //       return obj.valor[localCode];
    //     });

    //     console.log(valorArray2);

    //     this.mov.valor = valorArray2.reduce(adding, 0);
    //   }
    // },
    adjustValorDesc() {
      this.mov.valor -= this.mov.desconto;
    },
    adjust() {
      console.log(this.mov);
    },
    descontoImpact() {
      if (!this.descontoAct) {
        var localCode = this.localCode;
        var adding = function(accumulator, a) {
          return accumulator + a;
        };
        var tipo = this.mov.tipo;
        var valorArray = this.mov.soldObjs.map(function(obj) {
          if (obj.nome == "DUO" && tipo == "Power Shape 30") {
            return Math.round(0);
          } else {
            return obj.valor[localCode];
          }
        });

        this.mov.valor = valorArray.reduce(adding, 0);
      } else {
        this.mov.valor -= this.mov.desconto;
      }
    },
    changeTipo(tipoNew) {
      this.numberPacote = 0;
      this.mov.pacotes = [];
      this.mov.soldObjs = [];
      this.mov.descr = null;
      this.mov.comprovante = null;
      this.mov.desconto = 0;
      this.mov.pacotes = [
        {
          def1: null,
          def2: null,
          area1: null,
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        },
      ];
      this.mov.forma = null;
      if (this.mov.tipo == "Protocolo Glúteo de Ouro") {
        this.mov.pacotes[0] = {
          def1: "Glúteo",
          def2: "8 passos",
          area1: "GLÚTEO",
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
      }
      if (this.mov.tipo == "Power Shape 30") {
        this.mov.pacotes[0] = {
          def1: "DUO",
          def2: null,
          area1: null,
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        };
        this.temasToLiberate = ["emagEste"];
      }
      // var planos = [
      //   "Programa 12S Online",
      //   "Programa 12S",
      //   "Programa 12S com Estética",
      // ];
      if (tipoNew.includes("Programa 12S")) {
        this.temasToLiberate = ["programa12s"];
      }
      this.warnMessages = [];
      this.setValor(tipoNew, this.mov.descr);
    },
    setValor(tipo, descr) {
      this.mov.soldObjs = [];

      var tipoFilter = function(el) {
        return el.nome == tipo;
      };
      var descrFilter = function(el) {
        return el.nome == descr;
      };
      // if (this.mov.direction) {
      // push soldObjs
      var vendaItemList = this.vendaItems.filter(tipoFilter);
      var vendaItem = vendaItemList[0];
      this.mov.soldObjs.push(vendaItem);

      if (this.mov.descr !== null) {
        var aVendaItem;
        var sessaoEstetList = this.sessoesEsteticos.filter(descrFilter);
        if (sessaoEstetList.length > 0) {
          aVendaItem = sessaoEstetList[0];
          this.mov.soldObjs.push(aVendaItem);
        }
      }

      console.log(this.mov.pacotes.length);
      if (this.mov.pacotes.length > 0) {
        for (var j = 0; j < this.mov.pacotes.length; j++) {
          if (this.mov.pacotes[j].def1 !== null) {
            var pacDef1 = this.mov.pacotes[j].def1;
            var pacItemList = this.pacotesEsteticos1.filter(function(el) {
              return el.nome == pacDef1;
            });
            var pacItem = pacItemList[0];
            this.mov.soldObjs.push(pacItem);
          }
        }
      }

      console.log(this.mov.soldObjs);

      // localCode to map through soldObjs

      var localCode = this.localCode;
      var tipoX = this.mov.tipo;
      var valorArray = this.mov.soldObjs.map(function(obj) {
        if (obj.nome == "DUO" && tipoX == "Power Shape 30") {
          return Math.round(0);
        } else {
          return obj.valor[localCode];
        }
      });

      var adding = function(accumulator, a) {
        return accumulator + a;
      };

      console.log(valorArray);
      if (this.mov.direction) {
        this.mov.valor = valorArray.reduce(adding, 0);
      } else {
        this.mov.valor = 0;
      }
      // } else {
      //   var index2 = this.gastoItems.indexOf(tipo);
      //   this.mov.valor = this.gastoValores[index2];
      // }
    },
    labelDirection(dirBool) {
      if (dirBool) {
        return "Venda Nova";
      } else {
        return "Tratamento patrocinado";
      }
    },
    colorDirection(dirBool) {
      if (dirBool) {
        return "green";
      } else {
        return "blue";
      }
    },
    pushPacote() {
      this.mov.pacotes.push({
        def1: null,
        def2: null,
        area1: null,
        area2: null,
        area3: null,
        dataInicio: null,
        millisInicio: null,
        index: this.mov.pacotes.length,
      });
    },
    async submitOnly() {
      // this.saved1 = false;
      // var saved1 = false;
      this.saved = false;
      this.progressDialog = true;
      console.log("this.$refs.form.validate()", this.$refs.form.validate());
      if (this.$refs.form.validate()) {
        console.log("this.questPadrao", this.questPadrao);
        var pacienteInfos = this.pacienteInfos;
        console.log("gestante", pacienteInfos);

        var dn = new Date();
        var firstConsultaMillisExisting = dn.getTime();
        var existingUserData = await firebase
          .firestore()
          .collection("users")
          .doc(pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""))
          .get()
          .then(function(doc) {
            if (doc.data() == undefined) {
              return null;
            } else {
              return doc.data();
            }
          });

        if (existingUserData !== null) {
          firstConsultaMillisExisting = existingUserData.firstConsultaMillis;
        }
        console.log("firstConsultaMillisExisting", firstConsultaMillisExisting);

        if (existingUserData == null) {
          await firebase
            .firestore()
            .collection("users")
            .doc(pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""))
            .set({
              nutri: sessionStorage.getItem("current-user"),
              isPatient: true,
              joinedAt: firebase.firestore.FieldValue.serverTimestamp(),
              daysJoined: 0,
              gestante: pacienteInfos.gestante,
              sexo: pacienteInfos.sexo,
              dataNascimento: pacienteInfos.dataNascimento,
              telefone: pacienteInfos.telefone,
              whatsapp: pacienteInfos.whatsapp,
              nome: pacienteInfos.nome,
              email: pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""),
              preAtendimentoToFill: this.questPadrao,
              estetToFill: this.questEstet,
              imunidadeToFill: this.questImunidade,
              cpf: pacienteInfos.cpf,
              objetivo: pacienteInfos.objetivo,
              anamneses: 0,
              programaFim: 0,
              programaTipo: "",
              contratoVersion: this.questEstet ? 100 : 4,
              pacienteInfos: {
                endereco: "",
                cidade: "",
                estado: "",
                profissao: "",
                estadoCivil: null,
                filhos: "",
                idadeFilhos: "",
                indicacao: "",
                pesoAtual: "",
                altura: "",
                pesoConsidera: "",
                problemasSaude: "",
                suplemento: "",
                medicamento: "",
                sangue: "",
                imagem: "",
                alergia: [],
                alergiaOutro: "",
                resultado: "",
                refeicoesHorarios: "",
                gostaCozinhar: "Sim",
                outroCozinhar: "Sim",
                fazAtividade: "Sim",
                atividadeDetalhe: "",
                cafe: "",
                cafeFrutas: "",
                cafeTolerar: [],
                lanches: [],
                frutasGostar: "",
                frutasNaoTolerar: "",
                almocoFeijao: "",
                almocoSalada: "",
                aversaoVegetal: "",
                leguminosas: "",
                pures: "",
                massas: "",
                frangoCarnePeixe: "",
                jantar: [],
                ceia: "",
                gastrointestinal: "Sim",
                unhasCabelo: "Sim",
                ansiedade: "Sim",
                imunidade: "Sim",
                sono: "Sim",
                estrassada: "Sim",
                gastrointestinalComment: "",
                unhasCabeloComment: "",
                ansiedadeComment: "",
                imunidadeComment: "",
                sonoComment: "",
                estrassadaComment: "",
              },
              isNF: !this.isFranquia,
              contractDialog: this.isFranquia,
              clausulaDialog: this.isFranquia,
            });
        } else if (
          existingUserData !== null &&
          existingUserData.email !== undefined
        ) {
          this.warnMessages.push("Email já pertence à um outro paciente!");
          this.warnDialog = true;
          this.salvoDialog = true;
          this.progressDialog = false;
        } else {
          await firebase
            .firestore()
            .collection("users")
            .doc(pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""))
            .set({
              ltv: 0,
              nutri: sessionStorage.getItem("current-user"),
              isPatient: true,
              joinedAt: firebase.firestore.FieldValue.serverTimestamp(),
              daysJoined: 0,
              gestante: pacienteInfos.gestante,
              sexo: pacienteInfos.sexo,
              dataNascimento: pacienteInfos.dataNascimento,
              telefone: pacienteInfos.telefone,
              whatsapp: pacienteInfos.whatsapp,
              nome: pacienteInfos.nome,
              email: pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""),
              preAtendimentoToFill: this.questPadrao,
              imunidadeToFill: this.questImunidade,
              estetToFill: this.questEstet,
              cpf: pacienteInfos.cpf,
              objetivo: pacienteInfos.objetivo,
              anamneses: 0,
              programaFim: 0,
              programaTipo: "",
              contratoVersion: this.questEstet ? 100 : 4,
              pacienteInfos: {
                endereco: "",
                cidade: "",
                estado: "",
                profissao: "",
                estadoCivil: null,
                filhos: "",
                idadeFilhos: "",
                indicacao: "",
                pesoAtual: "",
                altura: "",
                pesoConsidera: "",
                problemasSaude: "",
                suplemento: "",
                medicamento: "",
                sangue: "",
                imagem: "",
                alergia: [],
                alergiaOutro: "",
                resultado: "",
                refeicoesHorarios: "",
                gostaCozinhar: "Sim",
                outroCozinhar: "Sim",
                fazAtividade: "Sim",
                atividadeDetalhe: "",
                cafe: "",
                cafeFrutas: "",
                cafeTolerar: [],
                lanches: [],
                frutasGostar: "",
                frutasNaoTolerar: "",
                almocoFeijao: "",
                almocoSalada: "",
                aversaoVegetal: "",
                leguminosas: "",
                pures: "",
                massas: "",
                frangoCarnePeixe: "",
                jantar: [],
                ceia: "",
                gastrointestinal: "Sim",
                unhasCabelo: "Sim",
                ansiedade: "Sim",
                imunidade: "Sim",
                sono: "Sim",
                estrassada: "Sim",
                gastrointestinalComment: "",
                unhasCabeloComment: "",
                ansiedadeComment: "",
                imunidadeComment: "",
                sonoComment: "",
                estrassadaComment: "",
              },
              isNF: !this.isFranquia,
              contractDialog: this.isFranquia,
              clausulaDialog: this.isFranquia,
              firstConsultaMillis: firstConsultaMillisExisting,
            });
        }

        if (existingUserData == null) {
          var lembObjs = [];
          for (var ix = 0; ix < 10; ix++) {
            var ref = firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("lembretes")
              .doc();
            var now = new Date();
            var nowYear = now.getFullYear();
            var anoX = nowYear + ix;
            var lembObj = {
              year: anoX,
              id: ref.id,
            };
            lembObjs.push(lembObj);
          }

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("pacientes")
            .doc(pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""))
            .set({
              isPatient: true,
              joinedAt: firebase.firestore.FieldValue.serverTimestamp(),
              daysJoined: 0,
              gestante: pacienteInfos.gestante,
              sexo: pacienteInfos.sexo,
              dataNascimento: pacienteInfos.dataNascimento,
              telefone: pacienteInfos.telefone,
              whatsapp: pacienteInfos.whatsapp,
              nome: pacienteInfos.nome,
              email: pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""),
              objetivo: pacienteInfos.objetivo,
              preAtendimentoToFill: this.questPadrao,
              imunidadeToFill: this.questImunidade,
              estetToFill: this.questEstet,
              cpf: pacienteInfos.cpf,
              anamneses: 0,
              programaTipo: "",
              programaFim: 0,
              anivLembArray: lembObjs,
            });

          lembObjs.forEach(async function(lembObj) {
            var bornDate = new Date(pacienteInfos.dataNascimento + " 06:00:00");
            var month = bornDate.getMonth() + 1;
            var day = bornDate.getDate();
            var monthStr;
            if (month > 9) {
              monthStr = month.toString();
            } else {
              monthStr = "0" + month.toString();
            }
            var dayStr;
            if (day > 9) {
              dayStr = day.toString();
            } else {
              dayStr = "0" + day.toString();
            }
            var birthdayStr =
              lembObj.year.toString() +
              "-" +
              monthStr +
              "-" +
              dayStr +
              " 06:00:00";
            var birthday = new Date(birthdayStr);
            // var sub24 = 24 * 60 * 60 * 1000;
            var dueDate = birthday.getTime(); // - sub24;

            var link = "";

            if (
              pacienteInfos.whatsapp !== "" &&
              pacienteInfos.whatsapp !== undefined
            ) {
              var removedStr = pacienteInfos.whatsapp.replace(
                /[^a-zA-Z0-9]/g,
                ""
              );
              // console.log(removedStr)
              var cleanWhatsapp = "55" + removedStr;
              // var splitList = pacienteInfos.email.split("@");
              // var email1 = splitList[0];
              // var email2 = splitList[1];
              link = `https://wa.me/${cleanWhatsapp}?text=N%C3%A3o+h%C3%A1+presente+de+anivers%C3%A1rio+que+possa+expressar+o+quanto+voc%C3%AA+%C3%A9+importante+para+n%C3%B3s.+Parab%C3%A9ns+e+muitas+felicidades%21+Clube+da+Nutricionista`;
              // console.log(this.whatsappLink);
            } else {
              link =
                "https://wa.me/?text=N%C3%A3o+h%C3%A1+presente+de+anivers%C3%A1rio+que+possa+expressar+o+quanto+voc%C3%AA+%C3%A9+importante+para+n%C3%B3s.+Parab%C3%A9ns+e+muitas+felicidades%21+Clube+da+Nutricionista";
            }

            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("lembretes")
              .doc(lembObj.id)
              .set({
                id: lembObj.id,
                year: lembObj.year,
                done: false,
                dataNascimento: pacienteInfos.dataNascimento,
                patient: pacienteInfos.nome,
                patientId: pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""),
                whatsapp: pacienteInfos.whatsapp,
                dueDate: dueDate,
                status: "notDueYet",
                tipo: "aniversario",
                link: link,
              });
          });

          var d = new Date();
          var millis = d.getTime();
          var timeId = millis.toString();
          var ano = d.getFullYear();
          var mes0 = d.getMonth();
          var mes = mes0 + 1;
          var dia = d.getDate();

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("ligacoes")
            .doc(timeId)
            .set({
              telefone: pacienteInfos.telefone,
              whatsapp: pacienteInfos.whatsapp,
              nome: pacienteInfos.nome,
              email: pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""),
              sexo: pacienteInfos.sexo,
              objetivo: pacienteInfos.objetivo,
              horarioLigacao: pacienteInfos.horarioLigacao,
              meioContato: pacienteInfos.meioContato,
              conversion: true,
              ano: ano,
              mes: mes,
              dia: dia,
              millis: millis,
            });

          var errorFauth = false;
          var errorFauthMsg = "";

          await firebase
            .auth()
            .createUserWithEmailAndPassword(
              pacienteInfos.email.toLowerCase().replace(/\s/g, "").replace(" ", ""),
              "nutri123"
            )
            .then((user) => {
              console.log(user);
            })
            .catch((error) => {
              console.log(error);
              console.log("error fauth");
              errorFauth = true;
              errorFauthMsg = error.message;
            });

          console.log("cadastro completo");

          if (
            this.agendaPacienteObj.millis !== undefined &&
            this.agendaPacienteObj.millis !== null &&
            !errorFauth
          ) {
            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("ligacoes")
              .doc(this.agendaPacienteObj.millis.toString())
              .update({
                conversion: true,
              });
          }

          if (!errorFauth) {
            this.salvarMov();
            this.setNull(true);
          } else {
            this.warnMessages.push(errorFauthMsg);
            this.warnDialog = true;
            this.salvoDialog = true;
            this.progressDialog = false;
          }
        }
      } else {
        this.warnMessages.push("Formulário de cadastrado com erro!");
        this.warnDialog = true;
        this.salvoDialog = true;
        this.progressDialog = false;
      }
    },
    submitNonConverted: function() {
      this.saved = false;
      this.progressDialog = true;
      console.log(this.pacienteInfos);
      // if (this.$refs.form.validate()) {
      this.$store.dispatch("firestore/pacienteNonConverted", {
        pacienteInfos: this.pacienteInfos,
      });

      this.saved = true;
      this.setNull(false);

      // this.$router.push("Conversoes");
      // }
    },
    save(date) {
      this.$refs.menu.save(date);
      var dStr = date.substring(8, 10);
      var mStr = date.substring(5, 7);
      var aStr = date.substring(0, 4);
      var niceDate = dStr + "/" + mStr + "/" + aStr;
      this.displayDataNasc = niceDate;
      console.log(
        "this.pacienteInfos.dataNascimento",
        this.pacienteInfos.dataNascimento
      );
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  mounted() {
    this.pacienteInfos = { ...this.pacienteInfos, ...this.agendaPacienteObj };

    // if (this.$store.state.firestore.pacientes.length === 0) {
    this.$store.dispatch("firestore/getPacientes");
    // this.$store.dispatch("firestore/getFuncionarios");
    this.getVendedores();
    // }
  },
};
</script>
