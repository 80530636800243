<template>
  <div>
    <v-dialog v-model="dialogComprovante" max-width="600">
      <v-card>
        <v-card-text class="d-flex justify-center">
          <v-img
            :src="comprovanteLink"
            width="90%"
            height="90%"
            class="my-3 mx-3"
          ></v-img>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn dense @click="dialogComprovante = false" color="#c5d13f"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDshow" max-width="750">
      <v-card>
        <v-row class="d-flex align-center mx-4">
          <v-col cols="8">
            <v-row>{{ dialogDelement.tipo }}</v-row
            ><v-row>
              <small>
                {{ dialogDelement.descr }}
              </small>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-row>
              <v-chip
                :color="colorDirection(dialogDelement.direction)"
                class="white--text"
              >
                {{ niceString(dialogDelement.valor) }}
              </v-chip>
              <v-icon
                v-if="dialogDelement.descontoAct"
                color="orange"
                class="mx-1"
              >
                mdi-sale
              </v-icon>
            </v-row>
            <v-row class="text-caption mt-1">
              <v-icon color="black" left small>{{
                formaIcon(dialogDelement.forma)
              }}</v-icon>
              {{ formaStr(dialogDelement.forma) }}
            </v-row>
            <v-row>
              <small> {{ dialogDelement.comissao }} </small>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom v-if="dialogDelement.comprovante">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  @click="openComprovanteDialog(dialogDelement.comprovante)"
                >
                  <v-icon color="#614021">mdi-note-text-outline</v-icon>
                </v-btn>
              </template>
              <span>Comprovante de pagamento</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="dialogDelement.pacienteName != ''" class="mx-4">
          {{ dialogDelement.pacienteName }}
        </v-row>
        <v-divider v-if="dialogDelement.forma == 7"></v-divider>
        <v-row v-if="dialogDelement.forma == 7" class="mx-4">
          <v-data-table
            :headers="formaHeaders"
            :items="[dialogDelement.formas]"
            class="elevation-1"
            hide-default-footer
          ></v-data-table>
        </v-row>
        <v-divider v-if="dialogDelement.forma == 7"></v-divider>
        <v-row
          v-if="
            dialogDelement.pacotes[0] !== undefined &&
              dialogDelement.pacotes[0].dataInicio !== undefined &&
              dialogDelement.pacotes[0].dataInicio !== null
          "
          class="mx-4"
        >
          <v-data-table
            :headers="pacoteHeaders"
            :items="orgPac(dialogDelement.pacotes)"
            class="elevation-1"
            hide-default-footer
          ></v-data-table>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogComissoes" max-width="750">
      <v-card>
        <v-card outlined class="mx-4" v-if="funcInclProprietario">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Propretário </v-col>
            <v-col cols="4">
              {{ niceString(comissaoPropretario) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclNutricionista1">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Nutricionista 1</v-col>
            <v-col cols="4">
              {{ niceString(comissaoNutricionista1) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclNutricionista2">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Nutricionista 2</v-col>
            <v-col cols="4">
              {{ niceString(comissaoNutricionista2) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclNutricionista3">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Nutricionista 3</v-col>
            <v-col cols="4">
              {{ niceString(comissaoNutricionista3) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclEsteticista">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Esteticista </v-col>
            <v-col cols="4">
              {{ niceString(comissaoEsteticista) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4" v-if="funcInclRecepcionista">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Recepcionista </v-col>
            <v-col cols="4">
              {{ niceString(comissaoRecepcionista) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8">
              <strong>Total de comissões a pagar</strong>
            </v-col>
            <v-col cols="4">
              <strong>{{ niceString(comissaoFranquia) }}</strong>
            </v-col>
          </v-row>
        </v-card>
        <v-divider class="my-4"></v-divider>
        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Taxa do Software </v-col>
            <v-col cols="4">
              {{ niceString(taxaSoftware) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Taxa de Publicidade </v-col>
            <v-col cols="4">
              {{ niceString(taxaPublicidade) }}
            </v-col>
          </v-row>
        </v-card>
        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8"> Royalts </v-col>
            <v-col cols="4">
              {{ niceString(taxaRoyalts) }}
            </v-col>
          </v-row>
        </v-card>

        <v-card outlined class="mx-4">
          <v-row class="d-flex align-center">
            <v-col cols="8"> <strong>Total de taxas a pagar</strong> </v-col>
            <v-col cols="4">
              <strong>{{ niceString(taxaSoma) }}</strong>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMshow" max-width="750">
      <v-card>
        <v-row class="d-flex align-center mx-4">
          <v-col cols="8">
            <v-row>{{ dialogMelement.dia }}</v-row>
          </v-col>
          <v-col cols="4">
            <v-chip
              :color="colorDirection(dialogMelement.somaNr > 0)"
              class="white--text"
            >
              {{ niceString(dialogMelement.somaNr) }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-data-table
            :headers="dayHeaders"
            :items="dialogMelement.elements"
            class="elevation-1"
          ></v-data-table>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTshow" max-width="750">
      <v-card>
        <v-row class="d-flex align-center mx-4">
          <v-col cols="8">
            <v-row>{{ dialogTelement.ano }}</v-row>
          </v-col>
          <v-col cols="4">
            <v-chip
              :color="colorDirection(dialogTelement.somaNr > 0)"
              class="white--text"
            >
              {{ niceString(dialogTelement.somaNr) }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-data-table
            :headers="monthHeaders"
            :items="dialogTelement.monthList"
            class="elevation-1"
          ></v-data-table>
        </v-row>
      </v-card>
    </v-dialog>

    <v-row
      class="d-flex justify-center mx-2"
      v-if="
        funcionarioOnline.papel == 'Administradora' ||
          funcionarioOnline.papel == 'Nutricionista 1' ||
          funcionarioOnline.papel == 'Proprietária' ||
          funcionarioOnline.papel == 'Proprietário'
      "
    >
      <v-card color="grey lighten-3" width="95%" class="mb-3">
        <v-row class="d-flex align-center text-h6">
          <v-col cols="5">
            <v-row>
              <v-select
                v-model="vision"
                :items="periods"
                label="Período"
                class="mx-4"
                @change="getFaturamento"
              ></v-select>
            </v-row>
            <v-row v-if="vision === 'Dia'" class="text-subtitle-2">
              <v-col cols="12" md="4">
                <v-select
                  v-model="visionD"
                  :items="periodsD"
                  label="Dia"
                  class="mx-2"
                  @change="getFaturamento"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="visionM"
                  :items="periodsM"
                  label="Mês"
                  class="mx-2"
                  @change="getFaturamento"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="visionA"
                  :items="periodsA"
                  label="Ano"
                  class="mx-2"
                  @change="getFaturamento"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-else-if="vision === 'Mês'">
              <v-col cols="12" md="6">
                <v-select
                  v-model="visionM"
                  :items="periodsM"
                  label="Mês"
                  class="mx-2"
                  @change="getFaturamento"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="visionA"
                  :items="periodsA"
                  label="Ano"
                  class="mx-2"
                  @change="getFaturamento"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-else-if="vision === 'Total'">
              <!-- <v-col cols="12" md="6">
                <v-select
                  v-model="visionM"
                  :items="periodsM"
                  label="Mês"
                  class="mx-2"
                  @change="getFaturamento"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="visionA"
                  :items="periodsA"
                  label="Ano"
                  class="mx-2"
                  @change="getFaturamento"
                ></v-select>
              </v-col> -->
            </v-row>
          </v-col>
          <v-col cols="4" class="d-flex justify-center"> {{ titulo }} </v-col>
          <v-col cols="3">
            <v-row class="text-subtitle-2 green--text my-1"
              ><v-icon color="green" small class="mr-1"
                >mdi-plus-circle-outline</v-icon
              >
              {{ entrouSoma }}</v-row
            ><v-row class="text-subtitle-2 red--text my-1"
              ><v-icon color="red" small class="mr-1"
                >mdi-minus-circle-outline</v-icon
              >
              {{ gastosSoma }}</v-row
            >
            <v-row>{{ faturamento }}</v-row>
            <v-row v-if="vision === 'Dia'" class="text-subtitle-2"
              >Dinheiro: {{ faturamentoDdin }}</v-row
            >
            <v-row
              v-else-if="vision === 'Mês'"
              class="d-flex justify-start text-subtitle-2 my-1"
            >
              <v-btn dense small @click="getComissoes">
                Comissões e Taxas
              </v-btn>
            </v-row>
            <v-row
              v-else-if="vision === 'Total'"
              class="d-flex justify-start text-heading-6 my-4"
            >
              Payback: {{ payback }}%
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-row
      class="d-flex justify-center mx-2"
      v-if="
        funcionarioOnline.papel == 'Administradora' ||
          funcionarioOnline.papel == 'Nutricionista 1' ||
          funcionarioOnline.papel == 'Proprietária' ||
          funcionarioOnline.papel == 'Proprietário'
      "
    >
      <v-stepper non-linear>
        <v-stepper-header>
          <v-stepper-step editable step="1"> Livro Caixa </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step editable step="2">
            Estatísticas
          </v-stepper-step></v-stepper-header
        >
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row v-if="vision === 'Dia'" class="d-flex justify-center mx-2">
              <v-card v-for="el in elementsD" :key="el.id" width="95%" outlined>
                <v-row class="d-flex align-center mx-4">
                  <v-col cols="4">
                    <v-row>{{ el.data.tipo }}</v-row>
                    <v-row
                      ><small> {{ el.data.descr }}</small></v-row
                    >
                  </v-col>
                  <v-col cols="3">
                    {{ el.data.pacienteName }}
                  </v-col>
                  <v-col cols="1">
                    <v-row class="d-flex justify-center">
                      <v-icon color="black">{{
                        formaIcon(el.data.forma)
                      }}</v-icon>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <small> {{ el.data.comissao }} </small>
                    </v-row>
                  </v-col>
                  <v-col cols="2" v-if="el.data.direction" class="green--text">
                    + {{ niceString(el.data.valor) }}
                  </v-col>
                  <v-col cols="2" v-else class="red--text">
                    {{ niceString(el.data.valor) }}
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon @click="dialogD(el.data)">
                      <v-icon color="black">mdi-eye</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon @click="makeRecibo(el.data)">
                      <v-icon color="black">mdi-file-download-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
            <v-row
              v-else-if="vision === 'Mês'"
              class="d-flex justify-center mx-2"
            >
              <v-card width="95%" outlined style="background-color: #c0c0c0">
                <v-row class="d-flex align-center mx-4">
                  <v-col cols="4"> Dia </v-col>
                  <v-col cols="3"> Faturamento </v-col>
                  <v-col cols="3"> Gasto </v-col>
                  <v-col cols="2"> Detalhes </v-col>
                </v-row>
              </v-card>
              <v-card v-for="del in daysM" :key="del.dia" width="95%" outlined>
                <v-row class="d-flex align-center mx-4">
                  <v-col cols="4">
                    {{ del.dia }}
                  </v-col>
                  <v-col cols="3" v-if="del.inSomaNr > 0" class="green--text">
                    + {{ niceString(del.inSomaNr) }}
                  </v-col>
                  <v-col cols="3" v-else class="black--text">
                    {{ niceString(del.inSomaNr) }}
                  </v-col>
                  <v-col cols="3" v-if="del.outSomaNr < 0" class="red--text">
                    {{ niceString(del.outSomaNr) }}
                  </v-col>
                  <v-col cols="3" v-else class="black--text">
                    {{ niceString(del.outSomaNr) }}
                  </v-col>
                  <v-col cols="2">
                    <v-btn icon @click="dialogM(del)">
                      <v-icon color="black">mdi-eye</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
            <v-row
              v-else-if="vision === 'Total'"
              class="d-flex justify-center mx-2"
            >
              <v-card v-for="ano in anosObjs" :key="ano" width="95%" outlined>
                <v-row class="d-flex align-center mx-4">
                  <v-col cols="5">
                    {{ ano.ano }}
                  </v-col>
                  <v-col cols="5" v-if="ano.somaNr > 0" class="green--text">
                    + {{ niceString(ano.somaNr) }}
                  </v-col>
                  <v-col cols="5" v-else class="red--text">
                    {{ niceString(ano.somaNr) }}
                  </v-col>
                  <v-col cols="2">
                    <v-btn icon @click="dialogT(ano)">
                      <v-icon color="black">mdi-eye</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <!-- sort & count vendas por tipo -->
            <v-row class="d-flex justify-center mx-2">
              <v-card outlined width="1000">
                <v-card-title class="d-flex justify-center">
                  Analise de vendas
                </v-card-title>
                <v-card-text
                  v-if="
                    loadedTiposVendidos &&
                      !zeroTiposVendidos &&
                      !loadingTiposVendidos
                  "
                >
                  <v-card color="grey">
                    <!-- <v-row class="d-flex justify-center">
                      <v-col cols="12" class="d-flex justify-space-between"> -->
                    <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                      <v-col cols="5"> Tipo de venda: </v-col>
                      <v-col cols="3"> Quantidade vendido: </v-col>
                      <v-col cols="4"> Valor somado: </v-col>
                    </v-row>
                    <!-- </v-col>
                    </v-row> -->
                  </v-card>
                  <div
                    v-for="tipoVendidoEl in tiposVendidos"
                    :key="tipoVendidoEl.tipo"
                  >
                    <v-card outlined>
                      <!-- <v-row class="d-flex justify-center">
                        <v-col cols="12" class="d-flex justify-space-between"> -->
                      <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                        <v-col cols="5"> {{ tipoVendidoEl.tipo }} </v-col>
                        <v-col cols="3"> {{ tipoVendidoEl.qtd }} </v-col>
                        <v-col cols="4"> {{ tipoVendidoEl.valor }} </v-col>
                      </v-row>
                      <!-- </v-col>
                      </v-row> -->
                    </v-card>
                  </div>
                </v-card-text>
                <v-card-text
                  v-else-if="
                    !loadedTiposVendidos &&
                      !zeroTiposVendidos &&
                      loadingTiposVendidos
                  "
                >
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="#614021"
                    indeterminate
                  ></v-progress-circular>
                </v-card-text>
                <v-card-text
                  v-else-if="
                    loadedTiposVendidos &&
                      zeroTiposVendidos &&
                      !loadingTiposVendidos
                  "
                >
                  <v-alert type="error">
                    Nenhuma venda no periodo escolhido!
                  </v-alert>
                </v-card-text>
                <v-card-text
                  v-else-if="
                    !loadedTiposVendidos &&
                      !zeroTiposVendidos &&
                      !loadingTiposVendidos
                  "
                >
                  <v-alert type="error">
                    Escolhe um periodo para analisar!
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row class="d-flex justify-center mx-2" v-if="vision == 'Mês'">
              <v-card outlined width="1000">
                <v-card-title class="d-flex justify-center"
                  >Contribuições dos funcionários</v-card-title
                >
                <v-card-text v-if="contribuicoesNotCalc"> </v-card-text>
                <v-card-text v-else>
                  <v-row
                    class="d-flex justify-center mx-1"
                    v-if="funcInclNutricionista1"
                  >
                    <v-card outlined class="my-2 mx-4" width="90%">
                      <v-row class="d-flex align-center">
                        <v-col cols="6"> Contribuição Nutricionista 1 </v-col>
                        <v-col cols="6">
                          {{ niceString(contribuicaoNutricionista1) }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-row>
                  <v-row
                    class="d-flex justify-center mx-1"
                    v-if="funcInclNutricionista2"
                  >
                    <v-card outlined class="my-2 mx-4" width="90%">
                      <v-row class="d-flex align-center">
                        <v-col cols="6"> Contribuição Nutricionista 2 </v-col>
                        <v-col cols="6">
                          {{ niceString(contribuicaoNutricionista2) }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-row>
                  <v-row
                    class="d-flex justify-center mx-1"
                    v-if="funcInclNutricionista3"
                  >
                    <v-card outlined class="my-2 mx-4" width="90%">
                      <v-row class="d-flex align-center">
                        <v-col cols="6"> Contribuição Nutricionista 3 </v-col>
                        <v-col cols="6">
                          {{ niceString(contribuicaoNutricionista3) }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-row>
                  <v-row
                    class="d-flex justify-center mx-1"
                    v-if="funcInclRecepcionista"
                  >
                    <v-card outlined class="my-2 mx-4" width="90%">
                      <v-row class="d-flex align-center">
                        <v-col cols="6"> Contribuição Recepcionista </v-col>
                        <v-col cols="6">
                          {{ niceString(contribuicaoRecepcionista) }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-row>
                  <v-row
                    class="d-flex justify-center mx-1"
                    v-if="funcInclEsteticista"
                  >
                    <v-card outlined class="my-2 mx-4" width="90%">
                      <v-row class="d-flex align-center">
                        <v-col cols="6"> Contribuição Esteticista </v-col>
                        <v-col cols="6">
                          {{ niceString(contribuicaoEsteticista) }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-row>
                  <v-row
                    class="d-flex justify-center mx-1"
                    v-if="funcInclEstagiaria"
                  >
                    <v-card outlined class="my-2 mx-4" width="90%">
                      <v-row class="d-flex align-center">
                        <v-col cols="6"> Contribuição Estagiária </v-col>
                        <v-col cols="6">
                          {{ niceString(contribuicaoEstagiaria) }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    color="#614021"
                    class="white--text"
                    @click="calculateContribuicoes"
                    v-if="contribuicoesNotCalc"
                  >
                    <v-icon left color="#c5d13f">mdi-cash-multiple</v-icon>
                    Ver contribuições
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-row>

            <!-- por nutri: nr de atendimentos + nr de programas + faturamento do periodo -->
            <v-row class="d-flex justify-center mx-2">
              <v-card outlined width="1000">
                <v-card-title class="d-flex justify-center">
                  Performance de conversões das nutricionistas
                </v-card-title>
                <v-card-text v-if="eventsLoaded && !loadingNutriStats">
                  <v-row class="d-flex justify-center">
                    <v-card outlined v-if="funcInclNutricionista1">
                      <div class="small">
                        <pie-chart :chart-data="dataNutri1"></pie-chart>
                      </div>
                    </v-card>
                    <v-card outlined v-if="funcInclNutricionista2">
                      <div class="small">
                        <pie-chart :chart-data="dataNutri2"></pie-chart>
                      </div>
                    </v-card>
                    <v-card outlined v-if="funcInclNutricionista3">
                      <div class="small">
                        <pie-chart :chart-data="dataNutri3"></pie-chart>
                      </div>
                    </v-card>
                  </v-row>
                </v-card-text>
                <v-card-text v-else-if="!eventsLoaded && !loadingNutriStats">
                  <v-row class="d-flex justify-center">
                    <v-btn
                      dense
                      color="#614021"
                      class="white--text"
                      @click="loadNutriStats"
                    >
                      <v-icon color="#c5d13f" left>mdi-chart-pie</v-icon>
                      Ver estatísticas de conversões
                    </v-btn>
                  </v-row>
                </v-card-text>
                <v-card-text v-else-if="!eventsLoaded && loadingNutriStats">
                  <v-row class="d-flex justify-center">
                    <v-progress-circular
                      :size="50"
                      :width="5"
                      color="#614021"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-row>
  </div>
</template>

<script>
// import jspdf from "jspdf";

import {
  mdiCreditCardCheckOutline,
  mdiCash100,
  mdiCreditCardClockOutline,
  mdiBankTransfer,
  mdiHelpRhombusOutline,
  mdiInfinity,
  mdiWalletPlusOutline,
  mdiCheckbook,
  mdiPiggyBank,
} from "@mdi/js";
import PieChart from "../components/PieChart.js";
import firebase from "firebase";
export default {
  components: { PieChart },
  data: function() {
    return {
      contribuicoesNotCalc: true,
      events: [],
      eventsLoaded: false,
      loadingNutriStats: false,
      vendedores: [],
      tiposVendidos: [{ tipo: null, qtd: null, valor: null }],
      loadingTiposVendidos: false,
      loadedTiposVendidos: false,
      zeroTiposVendidos: false,
      dataNutri1: null,
      dataNutri2: null,
      dataNutri3: null,
      comprovanteLink: "",
      dialogComprovante: false,
      vision: "Dia",
      periods: ["Dia", "Mês", "Total"],
      periodsM: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      periodsD: [],
      anos: [],
      anosObjs: [],
      nameMonths: [
        "None",
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthList: [
        {
          mes: "Janeiro",
          m: 1,
          somaNr: 0,
        },
        {
          mes: "Fevereiro",
          m: 2,
          somaNr: 0,
        },
        {
          mes: "Março",
          m: 3,
          somaNr: 0,
        },
        {
          mes: "Abril",
          m: 4,
          somaNr: 0,
        },
        {
          mes: "Maio",
          m: 5,
          somaNr: 0,
        },
        {
          mes: "Junho",
          m: 6,
          somaNr: 0,
        },
        {
          mes: "Julho",
          m: 7,
          somaNr: 0,
        },
        {
          mes: "Agosto",
          m: 8,
          somaNr: 0,
        },
        {
          mes: "Setembro",
          m: 9,
          somaNr: 0,
        },
        {
          mes: "Outubro",
          m: 10,
          somaNr: 0,
        },
        {
          mes: "Novembro",
          m: 11,
          somaNr: 0,
        },
        {
          mes: "Dezembro",
          m: 12,
          somaNr: 0,
        },
      ],
      icons: {
        mdiCreditCardCheckOutline,
        mdiCash100,
        mdiCreditCardClockOutline,
        mdiBankTransfer,
        mdiHelpRhombusOutline,
        mdiInfinity,
        mdiWalletPlusOutline,
        mdiCheckbook,
        mdiPiggyBank,
      },
      pacoteHeaders: [
        { text: "Pacote", value: "nome" },
        { text: "Mês do programa", value: "dataInicio" },
        { text: "Area", value: "area" },
      ],
      formaHeaders: [
        { text: "Cartão de Crédito", value: "cartaoDeCredito" },
        { text: "Cartão de Débito", value: "cartaoDeDebito" },
        // { text: "Cheque", value: "cheque" },
        { text: "Dinheiro", value: "dinheiro" },
        { text: "PIX", value: "pix" },
        { text: "Transferência Bancária", value: "transferenciaBancaria" },
        { text: "Depósito Bancário", value: "deposito" },
      ],
      dayHeaders: [
        { text: "Tipo de venda/gasto", value: "tipo" },
        { text: "Descrição", value: "descr" },
        { text: "Paciente", value: "pacienteName" },
        { text: "Valor", value: "niceValor" },
      ],
      monthHeaders: [
        { text: "Mês", value: "mes" },
        { text: "Lucro", value: "somaNr" },
        { text: "Faturamento", value: "faturamento" },
        { text: "Gastos", value: "gastos" },
      ],
      dialogDshow: false,
      dialogMshow: false,
      dialogTshow: false,
      dialogComissoes: false,
      comissaoPropretario: 0,
      comissaoNutricionista1: 0,
      comissaoNutricionista2: 0,
      comissaoNutricionista3: 0,
      comissaoEsteticista: 0,
      comissaoRecepcionista: 0,
      comissaoFranquia: 0,
      contribuicaoPropretario: 0,
      contribuicaoNutricionista1: 0,
      contribuicaoNutricionista2: 0,
      contribuicaoNutricionista3: 0,
      contribuicaoRecepcionista: 0,
      contribuicaoEsteticista: 0,
      contribuicaoEstagiaria: 0,
      taxaPublicidade: 0,
      taxaRoyalts: 0,
      taxaSoftware: 0,
      taxaSoma: 0,
      entrouSoma: 0,
      gastosSoma: 0,
      dialogDelement: {
        descr: "",
        pacienteName: "",
        valor: 0,
        tipo: "",
        pacotes: [{ dataInicio: null }],
      },
      dialogMelement: {
        y: 0,
        m: 0,
        d: 0,
        dia: "",
        somaNr: 0,
        elements: [
          {
            pacienteName: "",
            niceValor: "",
            descr: "",
            tipo: "",
          },
        ],
      },
      dialogTelement: {
        ano: 0,
        somaNr: 0,
        monthList: [],
      },
      titulo: "",
      faturamento: "",
      faturamentoT: 0,
      faturamentoDdin: "",
      visionD: null,
      visionM: null,
      visionA: null,
      elementsM: [],
      daysM: [],
      elementsD: [],
      preloadLogo: new Image(),
      preloadSignature: new Image(),
    };
  },
  computed: {
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
    funcionarios() {
      return this.$store.state.firestore.funcionarios;
    },
    funcInclProprietario() {
      if (
        this.vendedores.includes("Proprietário") ||
        this.vendedores.includes("Proprietária")
      ) {
        return true;
      } else {
        return false;
      }
    },
    funcInclNutricionista1() {
      if (
        this.vendedores.includes("Nutricionista 1") ||
        this.vendedores.includes("Nutricionista")
      ) {
        return true;
      } else {
        return false;
      }
    },
    funcInclNutricionista2() {
      return this.vendedores.includes("Nutricionista 2");
    },
    funcInclNutricionista3() {
      return this.vendedores.includes("Nutricionista 3");
    },
    funcInclRecepcionista() {
      return this.vendedores.includes("Recepcionista");
    },
    funcInclEsteticista() {
      return this.vendedores.includes("Esteticista");
    },
    funcInclEstagiaria() {
      return this.vendedores.includes("Estagiária");
    },
    payback() {
      var investimento = 160000;
      return Math.round((this.faturamentoT / investimento) * 100);
    },
    // setPeriodsD() {
    //   function daysInMonth(year, month) {
    //     return new Date(year, month, 0).getDate();
    //   }
    //   var d = new Date();
    //   var monthNow = d.getMonth() + 1;
    //   var listOfDays = [];
    //   for (var j = 1; j <= daysInMonth(d.getFullYear(), monthNow); j++) {
    //     listOfDays.push(j);
    //   }
    //   return listOfDays;
    // },
    periodsA() {
      var d = new Date();
      var y = d.getFullYear();
      var indexes = [-2, -1, 0, 1, 2];
      return indexes.map(function(yr) {
        return y + yr;
      });
    },
    caixa() {
      return this.$store.state.firestore.caixa;
    },
  },
  methods: {
    orgPac(pacs) {
      if (pacs !== undefined) {
        return pacs.map(function(p) {
          var areaStr = "";
          if (p.area !== undefined) {
            areaStr += p.area;
          } else {
            if (p.area1 !== undefined && p.area1 !== null) {
              areaStr += p.area1;
            }
            if (p.area2 !== undefined && p.area2 !== null) {
              var area2str = " + " + p.area2;
              areaStr += area2str;
            }
            if (p.area3 !== undefined && p.area3 !== null) {
              var area3str = " + " + p.area3;
              areaStr += area3str;
            }
          }
          var monthStr = "";
          var nameMonths = [
            "None",
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ];
          if (p.millisInicio !== undefined) {
            var newDate = new Date(p.millisInicio);
            var monthIndex = newDate.getMonth();
            var dateName = newDate.getDate();
            var monthName = nameMonths[monthIndex];
            var year = newDate.getFullYear() - 2000;
            monthStr += dateName + "/" + monthName + "/" + year;
          }
          var nomeStr = "";
          if (p.def1 !== undefined && p.def1 !== null) {
            nomeStr += p.def1;
          }
          if (p.def1 !== undefined && p.def2 !== null) {
            var def2str = ", " + p.def2;
            nomeStr += def2str;
          }
          return {
            area: areaStr,
            dataInicio: monthStr,
            nome: nomeStr,
          };
        });
      } else {
        return "";
      }
    },
    calculateContribuicoes() {
      var elementsM = [];
      if (this.vision == "Mês") {
        var filterM = function(list, c1, c2) {
          return list.filter(function(el) {
            return el.data.m === c1 && el.data.y === c2;
          });
        };
        elementsM = filterM(this.caixa, this.visionM, this.visionA);

        elementsM = elementsM.filter(function(el) {
          return el.data.direction;
        });
      }
      // else {
      //   var filterA = function (list, c1) {
      //     return list.filter(function (el) {
      //       return el.data.y === c1;
      //     });
      //   };
      //   elementsM = filterA(this.caixa, this.visionA)
      // }

      var filterComissao = function(list, cond) {
        return list.filter(function(el) {
          return el.data.comissao == cond && el.data.direction;
        });
      };
      var filterComissao1 = function(list, cond1, cond2) {
        return list.filter(function(el) {
          if (el.data.direction) {
            return el.data.comissao == cond1 || el.data.comissao == cond2;
          } else {
            return false;
          }
        });
      };

      var elementsPropretario = filterComissao(elementsM, "Propretário");
      var elementsNutricionista1 = filterComissao1(
        elementsM,
        "Nutricionista",
        "Nutricionista 1"
      );
      var elementsNutricionista2 = filterComissao(elementsM, "Nutricionista 2");
      var elementsNutricionista3 = filterComissao(elementsM, "Nutricionista 3");
      var elementsRecepcionista = filterComissao(elementsM, "Recepcionista");
      var elementsEsteticista = filterComissao(elementsM, "Esteticista");
      var elementsEstagiaria = filterComissao(elementsM, "Estagiária");

      var comissaoPropretario = 0;
      if (elementsPropretario.length > 0) {
        elementsPropretario.forEach(function(item) {
          comissaoPropretario += parseFloat(item.data.valor);
        });
      }

      var comissaoNutricionista1 = 0;
      if (elementsNutricionista1.length > 0) {
        elementsNutricionista1.forEach(function(item) {
          comissaoNutricionista1 += parseFloat(item.data.valor);
        });
      }

      var comissaoNutricionista2 = 0;
      if (elementsNutricionista2.length > 0) {
        elementsNutricionista2.forEach(function(item) {
          comissaoNutricionista2 += parseFloat(item.data.valor);
        });
      }

      var comissaoNutricionista3 = 0;
      if (elementsNutricionista3.length > 0) {
        elementsNutricionista3.forEach(function(item) {
          comissaoNutricionista3 += parseFloat(item.data.valor);
        });
      }

      var comissaoRecepcionista = 0;
      if (elementsRecepcionista.length > 0) {
        elementsRecepcionista.forEach(function(item) {
          comissaoRecepcionista += parseFloat(item.data.valor);
        });
      }

      var comissaoEsteticista = 0;
      if (elementsEsteticista.length > 0) {
        elementsEsteticista.forEach(function(item) {
          comissaoEsteticista += parseFloat(item.data.valor);
        });
      }

      var comissaoEstagiaria = 0;
      if (elementsEstagiaria.length > 0) {
        elementsEstagiaria.forEach(function(item) {
          comissaoEstagiaria += parseFloat(item.data.valor);
        });
      }

      this.contribuicaoPropretario = comissaoPropretario;
      this.contribuicaoNutricionista1 = comissaoNutricionista1;
      this.contribuicaoNutricionista2 = comissaoNutricionista2;
      this.contribuicaoNutricionista3 = comissaoNutricionista3;
      this.contribuicaoRecepcionista = comissaoRecepcionista;
      this.contribuicaoEsteticista = comissaoEsteticista;
      this.contribuicaoEstagiaria = comissaoEstagiaria;
      this.contribuicoesNotCalc = false;
    },
    loadNutriStats() {
      this.loadingNutriStats = true;
      this.getEvents();
    },
    async getEvents() {
      var events = [];
      var eventList = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      eventList.forEach(function(event) {
        if (event.nutriAtendendo != undefined) {
          events.push({
            name: event.name,
            nutriAtendendo: event.nutriAtendendo,
            patient: event.patient,
            patientId: event.patientId,
            newPatient: event.newPatient,
            start: new Date(event.start),
            end: new Date(event.end),
            details: event.details,
            color: event.color,
            timed: event.timed,
            id: event.id,
            startTime: event.startTime,
            endTime: event.endTime,
            niceDateShort: event.niceDateShort,
          });
        } else {
          events.push({
            name: event.name,
            patient: event.patient,
            patientId: event.patientId,
            newPatient: event.newPatient,
            start: new Date(event.start),
            end: new Date(event.end),
            details: event.details,
            color: event.color,
            timed: event.timed,
            id: event.id,
            startTime: event.startTime,
            endTime: event.endTime,
            niceDateShort: event.niceDateShort,
          });
        }
      });
      this.events = events;

      var elementsFramePos = [];

      if (this.vision == "Mês") {
        var filterM = function(list, c1, c2) {
          return list.filter(function(el) {
            return el.data.m === c1 && el.data.y === c2;
          });
        };
        var elementsFrameM = filterM(this.caixa, this.visionM, this.visionA);

        elementsFramePos = elementsFrameM.filter(function(el) {
          return el.data.direction;
        });
      }

      var pacSemEstet = [
        "Programa 12S",
        "Programa 12S Online",
        "Programa Clínico Funcional",
        "Programa Modulação Intestinal",
        "Programa Clínico Funcional (plano família)",
        "Programa Modulação Intestinal (plano família)",
      ];

      var pacComEstet = [
        "Programa 12S com Estética",
        "Protocolo Harmonização Corporal 24H",
        "Protocolo Detox + Lipodraining 24H",
        "Power Shape 30",
        "Protocolo Lipodraining 24H",
        "Protocolo Glúteo de Ouro",
        "Pacote Estético",
      ];

      var totalCons1 = this.events.filter(function(event) {
        return (
          event.nutriAtendendo == "Nutricionista 1" ||
          event.nutriAtendendo == "Nutricionista"
        );
      }).length;

      var nutri1Mpacs = elementsFramePos.filter(function(el) {
        var comissaoCond1 =
          el.data.comissao == "Nutricionista 1" ||
          el.data.comissao == "Nutricionista";
        var tipoCondS1 = pacSemEstet.includes(el.data.tipo);
        return comissaoCond1 && tipoCondS1;
      }).length;

      var nutri1Mpacc = elementsFramePos.filter(function(el) {
        var comissaoCond1 =
          el.data.comissao == "Nutricionista 1" ||
          el.data.comissao == "Nutricionista";
        var tipoCondC1 = pacComEstet.includes(el.data.tipo);
        return comissaoCond1 && tipoCondC1;
      }).length;

      var nutri1Mcons = totalCons1 - nutri1Mpacc - nutri1Mpacs;
      if (nutri1Mcons < 0) {
        nutri1Mcons = 0;
      }

      var nutri1Mdata = [nutri1Mcons, nutri1Mpacs, nutri1Mpacc];

      this.dataNutri1 = {
        labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
        datasets: [
          {
            backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
            data: nutri1Mdata, // filter events by m and by category
          },
        ],
      };

      var totalCons2 = this.events.filter(function(event) {
        return event.nutriAtendendo == "Nutricionista 2";
      }).length;

      var nutri2Mpacs = elementsFramePos.filter(function(el) {
        var comissaoCond2 = el.data.comissao == "Nutricionista 2";
        var tipoCondS2 = pacSemEstet.includes(el.data.tipo);
        return comissaoCond2 && tipoCondS2;
      }).length;

      var nutri2Mpacc = elementsFramePos.filter(function(el) {
        var comissaoCond2 = el.data.comissao == "Nutricionista 2";
        var tipoCondC2 = pacComEstet.includes(el.data.tipo);
        return comissaoCond2 && tipoCondC2;
      }).length;

      var nutri2Mcons = totalCons2 - nutri2Mpacc - nutri2Mpacs;
      if (nutri2Mcons < 0) {
        nutri2Mcons = 0;
      }

      var nutri2Mdata = [nutri2Mcons, nutri2Mpacs, nutri2Mpacc];

      this.dataNutri2 = {
        labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
        datasets: [
          {
            backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
            data: nutri2Mdata,
          },
        ],
      };

      var totalCons3 = this.events.filter(function(event) {
        return event.nutriAtendendo == "Nutricionista 3";
      }).length;

      var nutri3Mpacs = elementsFramePos.filter(function(el) {
        var comissaoCond3 = el.data.comissao == "Nutricionista 3";
        var tipoCondS3 = pacSemEstet.includes(el.data.tipo);
        return comissaoCond3 && tipoCondS3;
      }).length;

      var nutri3Mpacc = elementsFramePos.filter(function(el) {
        var comissaoCond3 = el.data.comissao == "Nutricionista 3";
        var tipoCondC3 = pacComEstet.includes(el.data.tipo);
        return comissaoCond3 && tipoCondC3;
      }).length;

      var nutri3Mcons = totalCons3 - nutri3Mpacc - nutri3Mpacs;
      if (nutri3Mcons < 0) {
        nutri3Mcons = 0;
      }

      var nutri3Mdata = [nutri3Mcons, nutri3Mpacs, nutri3Mpacc];
      console.log(nutri3Mdata);

      this.dataNutri3 = {
        labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
        datasets: [
          {
            backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
            data: nutri3Mdata,
          },
        ],
      };

      this.loadingNutriStats = false;
      this.eventsLoaded = true;
    },
    getVendedores() {
      this.vendedores = this.funcionarios.map(function(funcionario) {
        return funcionario.papel;
      });
    },
    setPeriodsD(year, month) {
      function daysInMonth(y, m) {
        return new Date(y, m, 0).getDate();
      }
      var listOfDays = [];
      for (var j = 1; j <= daysInMonth(year, month); j++) {
        listOfDays.push(j);
      }
      this.periodsD = listOfDays;
    },
    async preloadImages() {
      //   console.log(this.$store.state.firestore.pacienteNutriLogo);
      //   this.preloadLogo.crossOrigin = "anonymous";
      //   this.preloadSignature.crossOrigin = "anonymous";
      //   this.preloadLogo.src = `${this.$store.state.firestore.pacienteNutriLogo}`;
      //   this.preloadSignature.src = `${this.$store.state.firestore.pacienteNutriSign}`;

      if (
        sessionStorage.getItem("current-user") ==
        "umarizalbelem@clubedanutricionista.com.br"
      ) {
        if (this.funcionarioOnline.papel == "Nutricionista 1") {
          this.preloadSignature.src = require("@/assets/signature_danielle.png");
        } else if (this.funcionarioOnline.papel == "Nutricionista 2") {
          this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
        } else if (this.funcionarioOnline.papel == "Nutricionista 3") {
          this.preloadSignature.src = require("@/assets/signature_henrique.png");
        }
      }

      if (
        sessionStorage.getItem("current-user") ==
        "bc@clubedanutricionista.com.br"
      ) {
        this.preloadSignature.src = require("@/assets/signature_pietra.png");
      }

      if (
        sessionStorage.getItem("current-user") ==
        "fortaleza.salinas@clubedanutricionista.com.br"
      ) {
        if (this.funcionarioOnline.papel == "Nutricionista 1") {
          this.preloadSignature.src = require("@/assets/signature_leanna.png");
        } else {
          this.preloadSignature.src = require("@/assets/signature_none.jpg");
        }
      }

      this.preloadLogo.src = require("@/assets/logoCompleto.png");

      this.preloadLogo.onload = function() {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    // async makeRecibo(elData) {
    //   alert("indisponível");
    //   // links
    //   // https://mrrio.github.io/jsPDF/examples/basic.html
    //   // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
    //   // https://simonbengtsson.github.io/jsPDF-AutoTable/
    //   // https://www.npmjs.com/package/jspdf-autotable

    //   var doc = new jspdf("p");
    //   var date = new Date();
    //   var day = date.getDate();
    //   var month = date.getMonth() + 1;
    //   var year = date.getFullYear();

    //   // Doc settings
    //   var fontSize = 12;
    //   doc.setFontSize(fontSize);
    //   doc.setFont("Times");

    //   // The (900 / 262) division is used to keep the proportion of the original image
    //   // New Width * (900 / 262) will keep the height proportion

    //   var imgWidth = 40;
    //   var imgHeight = imgWidth / (900 / 262);
    //   var imgPos = (210 - imgWidth) / 2;
    //   var startXPos = 15;
    //   var textStartYPos = imgHeight * 4;

    //   var centeredText = function (text, y) {
    //     var textWidth =
    //       (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
    //       doc.internal.scaleFactor;
    //     var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    //     doc.text(textOffset, y, text);
    //   };

    //   var createTexts = function (
    //     textList,
    //     centered = false,
    //     textStart = textStartYPos
    //   ) {
    //     for (var i = 0; i < textList.length; i++) {
    //       if (centered) {
    //         centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
    //       } else {
    //         var lines = doc.setFont("Roboto").splitTextToSize(textList[i], 180);
    //         // console.log(lines)
    //         for (var j = 0; j < lines.length; j++) {
    //           doc.text(
    //             startXPos,
    //             textStart + (fontSize / 2) * (1 + j),
    //             lines[j]
    //           );
    //         }
    //         textStart += (fontSize / 2) * /*1 + */ lines.length;
    //       }
    //     }
    //     return (textStart += fontSize / 2);
    //   };

    //   // console.log(this.$store.state.firestore.userInfo);
    //   // // Logo Clubedonutricionista
    //   // if (this.$store.state.firestore.userInfo.useLogo === false) {
    //   //   console.log("op 1");
    //   doc.addImage({
    //     imageData: require("@/assets/logoCompleto.png"),
    //     y: startXPos,
    //     x: imgPos,
    //     w: imgWidth,
    //     h: imgHeight,
    //   });
    //   // } else {
    //   //   // LOGO CLUBE
    //   //   imgWidth = 30;
    //   //   imgHeight = imgWidth / (900 / 262);
    //   //   startXPos = 15;

    //   //   doc.addImage({
    //   //     imageData: require("@/assets/logoCompleto.png"),
    //   //     y: startXPos,
    //   //     x: 15,
    //   //     w: imgWidth,
    //   //     h: imgHeight,
    //   //   });

    //   //   imgWidth = 50;
    //   //   var startYPos = 15 + imgHeight * 2;
    //   //   imgHeight =
    //   //     imgWidth /
    //   //     (this.preloadSignature.naturalWidth /
    //   //       this.preloadSignature.naturalHeight);
    //   //   imgPos = (210 - imgWidth) / 2;
    //   //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

    //   //   doc.addImage({
    //   //     imageData: this.preloadSignature,
    //   //     y: startYPos,
    //   //     x: imgPos,
    //   //     w: imgWidth,
    //   //     h: imgHeight,
    //   //   });
    //   // }

    //   var endTablePosY = centeredText("Recibo", textStartYPos);

    //   var texts = [
    //     `Recebemos de ${elData.pacienteName} no dia ${elData.dateStr}`,
    //     `a quantia de ${this.niceString(elData.valor)} referente a "${elData.tipo}".`,
    //     `Forma de pagamento: ${this.formaStr(elData.forma)}`,
    //   ];

    //   endTablePosY = createTexts(texts);

    //   // USE SIGNATURE
    //   // console.log(
    //   //   "signature",
    //   //   this.$store.state.firestore.userInfo.signature
    //   // );
    //   // if (this.$store.state.firestore.userInfo.signature != undefined) {
    //   //   imgWidth = 30;
    //   //   startYPos =
    //   //     fontSize +
    //   //     (endTablePosY !== undefined
    //   //       ? endTablePosY + 16
    //   //       : textStartYPos + texts.length * (fontSize * (3 / 4)));
    //   //   imgHeight =
    //   //     imgWidth /
    //   //     (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
    //   //   imgPos = (210 - imgWidth) / 2;
    //   //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

    //   //   doc.addImage({
    //   //     imageData: this.preloadSignature,
    //   //     y: startYPos,
    //   //     x: imgPos,
    //   //     w: imgWidth,
    //   //     h: imgHeight,
    //   //   });
    //   // } else {
    //   //   imgHeight = 0;
    //   // }

    //   imgWidth = 75;
    //   imgHeight =
    //     imgWidth /
    //     (this.preloadSignature.naturalWidth /
    //       this.preloadSignature.naturalHeight);
    //   imgPos = (210 - imgWidth) / 2;
    //   textStartYPos =
    //     imgHeight +
    //     // 4 * (imgHeight / fontSize) +
    //     endTablePosY +
    //     imgHeight * 1.4;

    //   doc.addImage({
    //     imageData: this.preloadSignature,
    //     y: endTablePosY + imgHeight * 1.4,
    //     x: imgPos,
    //     w: imgWidth,
    //     h: imgHeight,
    //   });

    //   createTexts(
    //     [
    //       "____________________________",
    //       `Clube da Nutricionista - ${this.$store.state.firestore.userInfo.name}`,
    //       `CNPJ: ${this.$store.state.firestore.userInfo.cnpj}`,
    //       `Data: ${day}/${month}/${year}`,
    //     ],
    //     true,
    //     endTablePosY !== undefined
    //       ? endTablePosY + imgHeight * 1.4
    //       : textStartYPos + texts.length * (fontSize * (3 / 4))
    //   );

    //   doc.save("recibo.pdf");
    // },
    makeRecibo(elData) {
      alert("indisponível");
      console.log(elData);
    },
    getTitulo() {
      var tituloStr;
      if (this.vision == "Mês") {
        tituloStr = this.nameMonths[this.visionM];
      } else if (this.vision === "Dia") {
        var monthStr;
        if (this.visionM < 10) {
          monthStr = "0" + this.visionM.toString();
        } else {
          monthStr = this.visionM.toString();
        }

        var dayStr;
        if (this.visionD < 10) {
          dayStr = "0" + this.visionD.toString();
        } else {
          dayStr = this.visionD.toString();
        }

        tituloStr = dayStr + "/" + monthStr + "/" + this.visionA.toString();
      }
      this.titulo = tituloStr;
    },
    getFaturamento() {
      var sum;
      var entrouSum = 0;
      var gastosSum = 0;
      if (this.vision == "Mês") {
        var filterM = function(list, c1, c2) {
          return list.filter(function(el) {
            return el.data.m === c1 && el.data.y === c2;
          });
        };
        var elementsM = filterM(this.caixa, this.visionM, this.visionA);
        var elementsMpos = elementsM.filter(function(el) {
          return el.data.direction;
        });
        var elementsMneg = elementsM.filter(function(el) {
          return !el.data.direction;
        });
        var daysDef = new Date(this.visionA, this.visionM, 0);
        var daysInMonth = daysDef.getDate();
        var mDays = [];
        for (var dr = 1; dr <= daysInMonth; dr++) {
          mDays.push(dr);
        }

        var y = this.visionA;
        var m = this.visionM;

        this.daysM = mDays.map(function(day) {
          var somaNr = 0;
          var inSomaNr = 0;
          var outSomaNr = 0;

          function filterDm(list, c1, c2, c3) {
            return list.filter(function(el) {
              return el.data.d === c1 && el.data.m === c2 && el.data.y === c3;
            });
          }
          var somaNrEls = filterDm(elementsM, day, m, y);
          somaNrEls.forEach(function(item) {
            somaNr += parseFloat(item.data.valor);
          });

          somaNrEls.forEach(function(item) {
            if (parseFloat(item.data.valor) > 0) {
              inSomaNr += parseFloat(item.data.valor);
            } else {
              inSomaNr += 0;
            }
          });

          somaNrEls.forEach(function(item) {
            if (parseFloat(item.data.valor) < 0) {
              outSomaNr += parseFloat(item.data.valor);
            } else {
              outSomaNr += 0;
            }
          });

          var monthStr;
          if (m < 10) {
            monthStr = "0" + m.toString();
          } else {
            monthStr = m.toString();
          }

          var dayStr;
          if (day < 10) {
            dayStr = "0" + day.toString();
          } else {
            dayStr = day.toString();
          }

          var dataStr = dayStr + "/" + monthStr + "/" + y.toString();

          return {
            dia: dataStr,
            somaNr: somaNr,
            inSomaNr: inSomaNr,
            outSomaNr: outSomaNr,
            y: y,
            m: m,
            d: day,
          };
        });

        this.elementsM = elementsM;

        var sumM = 0;
        elementsM.forEach(function(item) {
          sumM += parseFloat(item.data.valor);
        });
        sum = sumM;

        elementsMpos.forEach(function(item) {
          entrouSum += parseFloat(item.data.valor);
        });
        elementsMneg.forEach(function(item) {
          gastosSum += parseFloat(item.data.valor);
        });

        var arrangedListM = [];
        var arrangedListMtipos = [];
        var doneTiposM = [];

        for (var iM = 0; iM < elementsMpos.length; iM++) {
          var iMtipo = elementsMpos[iM].data.tipo;
          var iMvalor = parseFloat(elementsMpos[iM].data.valor);
          if (doneTiposM.includes(iMtipo)) {
            var index = arrangedListMtipos.indexOf(iMtipo);
            arrangedListM[index].qtd = arrangedListM[index].qtd + 1;
            arrangedListM[index].valor = arrangedListM[index].valor + iMvalor;
          } else {
            doneTiposM.push(iMtipo);
            arrangedListMtipos.push(iMtipo);
            arrangedListM.push({ tipo: iMtipo, qtd: 1, valor: iMvalor });
          }
        }

        this.tiposVendidos = arrangedListM;

        this.loadingTiposVendidos = false;
        this.loadedTiposVendidos = true;
        this.zeroTiposVendidos = arrangedListM.length == 0;

        if (this.eventsLoaded) {
          var pacSemEstet = [
            "Programa 12S",
            "Programa 12S Online",
            "Programa Clínico Funcional",
            "Programa Modulação Intestinal",
            "Programa Clínico Funcional (plano família)",
            "Programa Modulação Intestinal (plano família)",
            "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)",
            "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)",
          ];

          var pacComEstet = [
            "Programa 12S com Estética",
            "Protocolo Harmonização Corporal 24H",
            "Protocolo Detox + Lipodraining 24H",
            "Power Shape 30",
            "Protocolo Lipodraining 24H",
            "Protocolo Glúteo de Ouro",
            "Pacote Estético",
          ];

          var totalCons1 = this.events.filter(function(event) {
            return (
              event.nutriAtendendo == "Nutricionista 1" ||
              event.nutriAtendendo == "Nutricionista"
            );
          }).length;

          var nutri1Mpacs = elementsMpos.filter(function(el) {
            var comissaoCond1 =
              el.data.comissao == "Nutricionista 1" ||
              el.data.comissao == "Nutricionista";
            var tipoCondS1 = pacSemEstet.includes(el.data.tipo);
            return comissaoCond1 && tipoCondS1;
          }).length;

          var nutri1Mpacc = elementsMpos.filter(function(el) {
            var comissaoCond1 =
              el.data.comissao == "Nutricionista 1" ||
              el.data.comissao == "Nutricionista";
            var tipoCondC1 = pacComEstet.includes(el.data.tipo);
            return comissaoCond1 && tipoCondC1;
          }).length;

          var nutri1Mcons = totalCons1 - nutri1Mpacc - nutri1Mpacs;
          if (nutri1Mcons < 0) {
            nutri1Mcons = 0;
          }

          var nutri1Mdata = [nutri1Mcons, nutri1Mpacs, nutri1Mpacc];

          this.dataNutri1 = {
            labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
            datasets: [
              {
                backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
                data: nutri1Mdata, // filter events by m and by category
              },
            ],
          };

          var totalCons2 = this.events.filter(function(event) {
            return event.nutriAtendendo == "Nutricionista 2";
          }).length;

          var nutri2Mpacs = elementsMpos.filter(function(el) {
            var comissaoCond2 = el.data.comissao == "Nutricionista 2";
            var tipoCondS2 = pacSemEstet.includes(el.data.tipo);
            return comissaoCond2 && tipoCondS2;
          }).length;

          var nutri2Mpacc = elementsMpos.filter(function(el) {
            var comissaoCond2 = el.data.comissao == "Nutricionista 2";
            var tipoCondC2 = pacComEstet.includes(el.data.tipo);
            return comissaoCond2 && tipoCondC2;
          }).length;

          var nutri2Mcons = totalCons2 - nutri2Mpacc - nutri2Mpacs;
          if (nutri2Mcons < 0) {
            nutri2Mcons = 0;
          }

          var nutri2Mdata = [nutri2Mcons, nutri2Mpacs, nutri2Mpacc];

          this.dataNutri2 = {
            labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
            datasets: [
              {
                backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
                data: nutri2Mdata,
              },
            ],
          };

          var totalCons3 = this.events.filter(function(event) {
            return event.nutriAtendendo == "Nutricionista 3";
          }).length;

          var nutri3Mpacs = elementsMpos.filter(function(el) {
            var comissaoCond3 = el.data.comissao == "Nutricionista 3";
            var tipoCondS3 = pacSemEstet.includes(el.data.tipo);
            return comissaoCond3 && tipoCondS3;
          }).length;

          var nutri3Mpacc = elementsMpos.filter(function(el) {
            var comissaoCond3 = el.data.comissao == "Nutricionista 3";
            var tipoCondC3 = pacComEstet.includes(el.data.tipo);
            return comissaoCond3 && tipoCondC3;
          }).length;

          var nutri3Mcons = totalCons3 - nutri3Mpacc - nutri3Mpacs;
          if (nutri3Mcons < 0) {
            nutri3Mcons = 0;
          }

          var nutri3Mdata = [nutri3Mcons, nutri3Mpacs, nutri3Mpacc];
          console.log(nutri3Mdata);

          this.dataNutri3 = {
            labels: ["Só consulta", "Prog. SEM estética", "Prog. COM estética"],
            datasets: [
              {
                backgroundColor: ["#ff2000", "#ffff33", "#1E9600"],
                data: nutri3Mdata,
              },
            ],
          };
        }
      } else if (this.vision === "Dia") {
        this.setPeriodsD(this.visionA, this.visionM);
        var filterD = function(list, c1, c2, c3) {
          return list.filter(function(el) {
            return el.data.d === c1 && el.data.m === c2 && el.data.y === c3;
          });
        };
        var filterDdin = function(list) {
          return list.filter(function(el) {
            return el.data.forma === 1;
          });
        };
        var filterDmul = function(list) {
          return list.filter(function(el) {
            return el.data.forma === 7;
          });
        };
        var elementsD = filterD(
          this.caixa,
          this.visionD,
          this.visionM,
          this.visionA
        );
        var elementsDpos = elementsD.filter(function(el) {
          return el.data.direction;
        });
        var elementsDneg = elementsD.filter(function(el) {
          return !el.data.direction;
        });
        var elementsDdin = filterDdin(elementsD);
        var elementsDmul = filterDmul(elementsD);

        this.elementsD = elementsD;

        var sumD = 0;
        elementsD.forEach(function(item) {
          sumD += parseFloat(item.data.valor);
        });
        sum = sumD;

        var sumDdin = 0;
        elementsDdin.forEach(function(item) {
          sumDdin += parseFloat(item.data.valor);
        });
        var sumDmul = 0;
        elementsDmul.forEach(function(item) {
          if (item.data.formas) {
            sumDmul += parseFloat(item.data.formas.dinheiro);
          } else {
            sumDmul += 0;
          }
        });

        elementsDpos.forEach(function(item) {
          entrouSum += parseFloat(item.data.valor);
        });
        elementsDneg.forEach(function(item) {
          gastosSum += parseFloat(item.data.valor);
        });

        var sumDdimmul = sumDmul + sumDdin;

        this.faturamentoDdin = this.niceString(sumDdimmul);
      } else if (this.vision === "Total") {
        var filterA = function(list, c1) {
          return list.filter(function(el) {
            return el.data.y === c1;
          });
        };

        var caixa = this.caixa;
        this.anosObjs = this.anos.map(function(ano) {
          var sumA = 0;
          var elementsA = filterA(caixa, ano);
          elementsA.forEach(function(item) {
            sumA += parseFloat(item.data.valor);
          });

          return {
            ano: ano,
            somaNr: sumA,
          };
        });

        var sumTotal = 0;
        caixa.forEach(function(item) {
          sumTotal += parseFloat(item.data.valor);
        });

        var caixaPos = caixa.filter(function(el) {
          return el.data.direction;
        });
        // =================================================================
        // var sumLivia = 0;
        // var caixaLivia = caixaPos.filter(function (el) {
        //   return el.data.comissao == "Nutricionista 1" || el.data.comissao == "Nutricionista";
        // });
        // var livia300 = caixaLivia.filter(function (el) {
        //   return el.data.valor <= 300;
        // });
        // var livia690sum = 0;
        // var livia690 = caixaLivia.filter(function (el) {
        //   return el.data.valor == 690 || el.data.valor == 990;
        // });
        // var livia990sum = 0;
        // var livia990 = caixaLivia.filter(function (el) {
        //   return el.data.valor > 690 && el.data.valor !== 990;
        // });

        // caixaLivia.forEach(function (item) {
        //   sumLivia += parseFloat(item.data.valor);
        // });
        // livia990.forEach(function (item) {
        //   livia990sum += parseFloat(item.data.valor);
        // });
        // livia690.forEach(function (item) {
        //   livia690sum += parseFloat(item.data.valor);
        // });

        // console.log("sumLivia", sumLivia);
        // console.log("livia300", livia300.length)
        // console.log("livia690", livia690.length)
        // console.log("livia990", livia990.length)
        // console.log(livia990sum)
        // console.log(livia690sum)

        // =================================================================
        var sumEntrou = 0;
        caixaPos.forEach(function(item) {
          sumEntrou += parseFloat(item.data.valor);
        });

        var caixaNeg = caixa.filter(function(el) {
          return !el.data.direction;
        });
        var sumGastos = 0;
        caixaNeg.forEach(function(item) {
          sumGastos += parseFloat(item.data.valor);
        });

        this.faturamentoT = sumTotal;
        sum = sumTotal;
        entrouSum = sumEntrou;
        gastosSum = sumGastos;
      }

      this.getTitulo();
      this.faturamento = this.niceString(sum);
      this.entrouSoma = this.niceString(entrouSum);
      this.gastosSoma = this.niceString(gastosSum);

      var arrangedListY = [];
      var arrangedListYtipos = [];
      var doneTiposY = [];

      for (var iY = 0; iY < caixaPos.length; iY++) {
        var iYtipo = caixaPos[iY].data.tipo;
        var iYvalor = parseFloat(caixaPos[iY].data.valor);
        if (doneTiposY.includes(iYtipo)) {
          var indexY = arrangedListYtipos.indexOf(iYtipo);
          arrangedListY[indexY].qtd = arrangedListY[indexY].qtd + 1;
          arrangedListY[indexY].valor = arrangedListY[indexY].valor + iYvalor;
        } else {
          doneTiposY.push(iYtipo);
          arrangedListYtipos.push(iYtipo);
          arrangedListY.push({ tipo: iYtipo, qtd: 1, valor: iYvalor });
        }
      }

      this.tiposVendidos = arrangedListY;

      this.loadingTiposVendidos = false;
      this.loadedTiposVendidos = true;
      this.zeroTiposVendidos = arrangedListY.length == 0;
    },
    getComissoes() {
      var filterM = function(list, c1, c2) {
        return list.filter(function(el) {
          return el.data.m === c1 && el.data.y === c2;
        });
      };
      var filterComissao = function(list, cond) {
        return list.filter(function(el) {
          return el.data.comissao == cond && el.data.direction;
        });
      };
      var filterComissao1 = function(list, cond1, cond2) {
        return list.filter(function(el) {
          if (el.data.direction) {
            return el.data.comissao == cond1 || el.data.comissao == cond2;
          } else {
            return false;
          }
        });
      };
      var filterVendas = function(list) {
        return list.filter(function(el) {
          return el.data.direction;
        });
      };

      var elementsM = filterM(this.caixa, this.visionM, this.visionA);

      var elementsPropretario = filterComissao(elementsM, "Propretário");
      var elementsNutricionista1 = filterComissao1(
        elementsM,
        "Nutricionista",
        "Nutricionista 1"
      );
      var elementsNutricionista2 = filterComissao(elementsM, "Nutricionista 2");
      var elementsNutricionista3 = filterComissao(elementsM, "Nutricionista 3");
      var elementsRecepcionista = filterComissao(elementsM, "Recepcionista");
      var elementsEsteticista = filterComissao(elementsM, "Esteticista");
      var elementsVenda = filterVendas(elementsM);

      var comissaoFranquia = 0;
      elementsVenda.forEach(function(item) {
        comissaoFranquia += parseFloat(item.data.valor);
      });

      var taxaPublicidade = 0;
      elementsVenda.forEach(function(item) {
        taxaPublicidade += parseFloat(item.data.valor);
      });

      var taxaRoyalts = 0;
      elementsVenda.forEach(function(item) {
        taxaRoyalts += parseFloat(item.data.valor);
      });

      var comissaoPropretario = 0;
      if (elementsPropretario.length > 0) {
        elementsPropretario.forEach(function(item) {
          comissaoPropretario += parseFloat(item.data.valor);
        });
      }

      var comissaoNutricionista1 = 0;
      if (elementsNutricionista1.length > 0) {
        elementsNutricionista1.forEach(function(item) {
          comissaoNutricionista1 += parseFloat(item.data.valor);
        });
      }

      var comissaoNutricionista2 = 0;
      if (elementsNutricionista2.length > 0) {
        elementsNutricionista2.forEach(function(item) {
          comissaoNutricionista2 += parseFloat(item.data.valor);
        });
      }

      var comissaoNutricionista3 = 0;
      if (elementsNutricionista3.length > 0) {
        elementsNutricionista3.forEach(function(item) {
          comissaoNutricionista3 += parseFloat(item.data.valor);
        });
      }

      var comissaoRecepcionista = 0;
      if (elementsRecepcionista.length > 0) {
        elementsRecepcionista.forEach(function(item) {
          comissaoRecepcionista += parseFloat(item.data.valor);
        });
      }

      var comissaoEsteticista = 0;
      if (elementsEsteticista.length > 0) {
        elementsEsteticista.forEach(function(item) {
          comissaoEsteticista += parseFloat(item.data.valor);
        });
      }

      comissaoPropretario = (comissaoPropretario / 100) * 2;
      comissaoNutricionista1 = (comissaoNutricionista1 / 100) * 2;
      comissaoNutricionista2 = (comissaoNutricionista2 / 100) * 2;
      comissaoNutricionista3 = (comissaoNutricionista3 / 100) * 2;
      comissaoRecepcionista = (comissaoRecepcionista / 100) * 2;
      comissaoEsteticista = (comissaoEsteticista / 100) * 2;
      comissaoFranquia = (comissaoFranquia / 100) * 2;
      taxaPublicidade = (taxaPublicidade / 100) * 2;
      taxaRoyalts = (taxaRoyalts / 100) * 8;

      comissaoPropretario = Math.round(comissaoPropretario * 100) / 100;
      comissaoNutricionista1 = Math.round(comissaoNutricionista1 * 100) / 100;
      comissaoNutricionista2 = Math.round(comissaoNutricionista2 * 100) / 100;
      comissaoNutricionista3 = Math.round(comissaoNutricionista3 * 100) / 100;
      comissaoRecepcionista = Math.round(comissaoRecepcionista * 100) / 100;
      comissaoEsteticista = Math.round(comissaoEsteticista * 100) / 100;
      comissaoFranquia = Math.round(comissaoFranquia * 100) / 100;
      taxaPublicidade = Math.round(taxaPublicidade * 100) / 100;
      taxaRoyalts = Math.round(taxaRoyalts * 100) / 100;

      this.comissaoPropretario = comissaoPropretario;
      this.comissaoNutricionista1 = comissaoNutricionista1;
      this.comissaoNutricionista2 = comissaoNutricionista2;
      this.comissaoNutricionista3 = comissaoNutricionista3;
      this.comissaoRecepcionista = comissaoRecepcionista;
      this.comissaoEsteticista = comissaoEsteticista;
      this.comissaoFranquia = comissaoFranquia;

      this.taxaSoftware = 160; // 160
      this.taxaPublicidade = Math.max(taxaPublicidade, 600); // 2% elementsVenda, min 600
      this.taxaRoyalts = Math.max(taxaRoyalts, 1200); // 8% elementsVenda, min 1200
      this.taxaSoma = 160 + this.taxaPublicidade + this.taxaRoyalts;

      this.dialogComissoes = true;
    },
    niceString(valor) {
      var minus = valor < 0;
      var valorAbs = Math.abs(valor);
      var valorKomma;

      var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valorAbs);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valorAbs / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }

      if (minus) {
        return "- R$ " + valorKomma + "," + decimalsStr;
      } else {
        return "R$ " + valorKomma + "," + decimalsStr;
      }
    },
    dialogD(element) {
      console.log("element", element);
      this.dialogDelement = element;

      this.dialogDshow = true;
    },
    dialogT(element) {
      var caixa = this.caixa;
      var y = element.ano;
      var func = this.niceString;

      var filterM = function(list, c1, c2) {
        return list.filter(function(el) {
          return el.data.m === c1 && el.data.y === c2;
        });
      };

      var filledMonthList = this.monthList.map(function(mo) {
        var elementsM = filterM(caixa, mo.m, y);

        var somaNr = 0;
        var faturamento = 0;
        var gastos = 0;
        elementsM.forEach(function(item) {
          somaNr += parseFloat(item.data.valor);
        });
        elementsM.forEach(function(item) {
          if (item.data.direction) {
            faturamento += parseFloat(item.data.valor);
          }
        });
        elementsM.forEach(function(item) {
          if (!item.data.direction) {
            gastos += parseFloat(item.data.valor);
          }
        });

        return {
          mes: mo.mes,
          somaNr: func(somaNr),
          faturamento: func(faturamento),
          gastos: func(gastos),
        };
      });

      var elementComplete = {
        ano: element.ano,
        somaNr: element.somaNr,
        monthList: filledMonthList,
      };
      this.dialogTelement = elementComplete;
      this.dialogTshow = true;
    },
    dialogM(element) {
      function filterDm(list, c1, c2, c3) {
        return list.filter(function(el) {
          return el.data.d === c1 && el.data.m === c2 && el.data.y === c3;
        });
      }
      element.elements = filterDm(
        this.caixa,
        element.d,
        element.m,
        element.y
      ).map(function(e) {
        function niceString(valor) {
          var minus = valor < 0;
          var valorAbs = Math.abs(valor);
          var valorKomma;

          var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
          var decimalsStr;
          if (decimals < 1) {
            decimalsStr = "00";
          } else if (decimals < 10) {
            decimalsStr = "0" + decimals.toString();
          } else {
            decimalsStr = decimals.toString();
          }

          var valorGanz = Math.floor(valorAbs);

          if (valorGanz > 1000) {
            var tusiger = Math.floor(valorAbs / 1000);
            var rest = valorGanz % 1000;
            var restStr;
            if (rest < 10) {
              restStr = "00" + rest.toString();
            } else if (rest < 100) {
              restStr = "0" + rest.toString();
            } else {
              restStr = rest.toString();
            }
            valorKomma = tusiger.toString() + "." + restStr;
          } else {
            valorKomma = valorGanz.toString();
          }

          if (minus) {
            return "- R$ " + valorKomma + "," + decimalsStr;
          } else {
            return "R$ " + valorKomma + "," + decimalsStr;
          }
        }

        e.data.niceValor = niceString(e.data.valor);
        return e.data;
      });
      this.dialogMelement = element;
      this.dialogMshow = true;
    },
    colorDirection(dirBool) {
      if (dirBool) {
        return "green";
      } else {
        return "red";
      }
    },
    formaIcon(forma) {
      if (forma === 1) {
        return this.icons.mdiCash100;
      } else if (forma === 2) {
        return this.icons.mdiCreditCardCheckOutline;
      } else if (forma === 3) {
        return this.icons.mdiCreditCardClockOutline;
      } else if (forma === 4) {
        return this.icons.mdiBankTransfer;
      } else if (forma === 5) {
        return this.icons.mdiInfinity;
      } else if (forma === 6) {
        return this.icons.mdiCheckbook;
      } else if (forma === 7) {
        return this.icons.mdiWalletPlusOutline;
      } else if (forma === 8) {
        return this.icons.mdiPiggyBank;
      } else {
        return this.icons.mdiHelpRhombusOutline;
      }
    },
    formaStr(forma) {
      if (forma === 1) {
        return "Dinheiro";
      } else if (forma === 2) {
        return "Cartão de Débito";
      } else if (forma === 3) {
        return "Cartão de Crédito";
      } else if (forma === 4) {
        return "Transferência Bancária";
      } else if (forma === 5) {
        return "PIX";
      } else if (forma === 6) {
        return "Cheque";
      } else if (forma === 7) {
        return "multiplas formas";
      } else if (forma === 8) {
        return "Depósito Bancário";
      } else {
        return "forma de pagamento não especificada";
      }
    },
    openComprovanteDialog(link) {
      this.dialogComprovante = true;
      this.comprovanteLink = link;
    },
  },
  mounted() {
    // if (this.$store.state.firestore.caixa.length === 0) {
    this.$store.dispatch("firestore/getCaixa");
    // }
    this.getVendedores();

    var d = new Date();
    var y = d.getFullYear();
    var anosList = [];
    for (var ano = 2021; ano <= y; ano++) {
      anosList.push(ano);
    }
    this.visionA = y;
    var monthNow = d.getMonth() + 1;
    this.visionM = monthNow;
    this.visionD = d.getDate();
    setTimeout(() => {
      this.getFaturamento();
    }, 1000);
    this.preloadImages();
    this.anos = anosList;
    this.setPeriodsD(d.getFullYear(), monthNow);
  },
};
</script>
