<template>
  <div>
    <v-dialog v-model="visualizarDialog" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title class="d-flex justify-center">
          {{ temaVis.nome }}</v-card-title
        >
        <v-card-text>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-h6">
                Vídeo-Cursos / Ebooks</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row
                  class="d-flex justify-center"
                  v-for="video in temaVis.videos"
                  :key="video.yt"
                >
                  <v-col cols="12" md="9">
                    {{ video.nome }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn
                      color="#614021"
                      class="white--text"
                      @click="assistir(video)"
                    >
                      <v-icon color="#c5d13f" left>mdi-play</v-icon> Assistir
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-h6">
                Ebooks</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row
                  class="d-flex justify-center"
                  v-for="ebook in temaVis.ebooks"
                  :key="ebook.url"
                >
                  <v-col cols="9">
                    {{ ebook.nome }}
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      color="#614021"
                      class="white--text"
                      @click="ler(ebook)"
                    >
                      <v-icon color="#c5d13f" left
                        >mdi-book-open-variant</v-icon
                      >
                      Ler
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="lerDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="#614021">
          <v-btn icon dark @click="lerDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ lerObj.nome }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="lerDialog = false"> Fechar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="d-flex justify-center">
          <v-card width="90%">
            <iframe
              :src="`${lerObj.url}#toolbar=0`"
              width="100%"
              height="1000px"
            ></iframe>
          </v-card>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="assistirDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="assistirObj.nome !== ''">
        <v-toolbar dark color="#614021">
          <v-btn icon dark @click="closeAssistirDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ assistirObj.nome }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="closeAssistirDialog"> Fechar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="d-flex justify-center">
          <v-card width="90%" elevation="4">

            <vue-plyr :emit="['ready', 'ended', 'pause']">
              <div class="plyr__video-embed">
                <iframe
                  :src="assistirObj.link"
                  allowfullscreen
                  allowtransparency
                  allow="autoplay"
                ></iframe>
              </div>
            </vue-plyr>

          </v-card>

          <!-- <v-card
            width="90%"
            elevation="4"
            v-if="assistirObj.yt === 'tRIWWzZnNTM'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="tRIWWzZnNTM"
              ></div>
            </vue-plyr>
          </v-card> -->
          <!-- <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'mRP8yct7ASo'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="mRP8yct7ASo"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'uBer1qKm8t0'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="uBer1qKm8t0"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'FXQQP2lMBm0'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="FXQQP2lMBm0"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'vy8dPyACbdg'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="vy8dPyACbdg"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'UyVl6wxDJM8'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="UyVl6wxDJM8"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === '92jjRI6d6h4'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="92jjRI6d6h4"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'VF4UO_wKlUc'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="VF4UO_wKlUc"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'MmV7JZ7cccA'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="MmV7JZ7cccA"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'e6fsCJrkIdU'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="e6fsCJrkIdU"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'veiEcx3QbL0'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="veiEcx3QbL0"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'cAS4re4jNPY'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="cAS4re4jNPY"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === '1ZuxRuMtivA'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="1ZuxRuMtivA"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'BxZcf9SWxdw'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="BxZcf9SWxdw"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'rrjJwAUKgZg'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="rrjJwAUKgZg"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'okNUTRmqEl4'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="okNUTRmqEl4"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'AJ1J27JcFQM'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="AJ1J27JcFQM"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'RF93JkAHejc'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="RF93JkAHejc"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'L4kfaneiy8A'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="L4kfaneiy8A"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'KYLLZDYl32o'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="KYLLZDYl32o"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'S_Z_NpuBvbs'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="S_Z_NpuBvbs"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'PBCpPOxdIZI'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="PBCpPOxdIZI"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'JeBZRypsXm0'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="JeBZRypsXm0"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'n9KyrRSeKY4'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="n9KyrRSeKY4"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === '_duLx9sSgoY'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="_duLx9sSgoY"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'V1bbDKS6Jd4'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="V1bbDKS6Jd4"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'WDOrdyNGZ5Y'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="WDOrdyNGZ5Y"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'Xi-VpBsZzow'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="Xi-VpBsZzow"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'Fe2ae-JI_j8'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="Fe2ae-JI_j8"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === '2I9K3CyjIRQ'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="2I9K3CyjIRQ"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'IcOFYv_cuEY'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="IcOFYv_cuEY"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'JmBv69NWCeQ'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="JmBv69NWCeQ"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'CxGyhnP3Zro'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="CxGyhnP3Zro"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === '2ulYqe6Rm90'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="2ulYqe6Rm90"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'LYEVp-to2Zw'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="LYEVp-to2Zw"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'jqx1TbZ5ahY'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="jqx1TbZ5ahY"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'hL5uNkRUVN0'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="hL5uNkRUVN0"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'wIAORt6SJY0'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="wIAORt6SJY0"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'ukJQrYSMijU'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="ukJQrYSMijU"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'l6tTXSp-9WE'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="l6tTXSp-9WE"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'z1Xvm0R5ndk'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="z1Xvm0R5ndk"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'mBGUu2EspwA'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="mBGUu2EspwA"
              ></div>
            </vue-plyr>
          </v-card>
          <v-card
            width="90%"
            elevation="4"
            v-else-if="assistirObj.yt === 'PMSMjZaJ2QI'"
          >
            <vue-plyr :emit="['ready', 'ended', 'pause']"
              ><div
                data-plyr-provider="youtube"
                data-plyr-embed-id="PMSMjZaJ2QI"
              ></div>
            </vue-plyr>
          </v-card> -->
        </v-row>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title class="text-h6"> Vídeo-Aulas e Ebooks </v-card-title>
      <v-card-text>
        <v-card v-for="tema in temas" :key="tema.nome" width="95%" outlined>
          <v-row class="d-flex align-center mx-4">
            <v-col cols="12" md="9">
              {{ tema.nome }}
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-center white--text">
              <v-btn
                color="#614021"
                @click="setTemaVisualizar(tema)"
                class="white--text"
              >
                <v-icon color="#c5d13f" left>mdi-eye</v-icon>
                Ver as Aulas
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      panel: [],
      temas: [],
      visualizarDialog: false,
      assistirDialog: false,
      lerDialog: false,
      lerObj: { nome: "", url: "" },
      assistirObj: { nome: "", url: "", yt: "" },
    };
  },
  firestore() {
    return {
      temas: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("videoAulas"),
    };
  },
  computed: {
    temaVis() {
      return this.$store.state.firestore.temaVis;
    },
  },
  methods: {
    setTemaVisualizar(tema) {
      this.$store.dispatch("firestore/setTemaVis", {
        tema,
      });

      this.visualizarDialog = true;
    },
    assistir(obj) {
      var raw = `https://www.youtube.com/embed/${obj.yt}?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`
      this.assistirObj = obj;
      this.assistirObj.link = raw;
      console.log(this.assistirObj.link)

      this.assistirDialog = true;
    },
    closeAssistirDialog() {
      this.assistirObj = { nome: "", url: "", yt: "" }
      this.assistirDialog = false;
    },
    ler(obj) {
      this.lerObj = obj;

      this.lerDialog = true;
    },
  },
};
</script>