<template>
  <div>
    <v-dialog v-model="visualizarDialog" width="600">
      <v-card>
        <v-card-title class="d-flex justify-center text-h6">
          {{ nomeFormulaPaciente }}, {{ pacienteChosen.nome }}
        </v-card-title>
        <v-card-text>
          <div
            v-for="formula in prescricaoC.formulasList"
            :key="formula.titulo"
          >
            <v-row class="mx-4"> {{ formula.titulo }} </v-row>
            <v-row class="mx-4">
              <v-textarea
                v-model="formula.prescricoes"
                solo
                height="200"
              ></v-textarea>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text @click="visualizarDialog = false" color="#614021">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSalvar" width="600">
      <v-card>
        <v-card-title class="d-flex justify-center text-h6">
          Salvar "{{ nomeFormulaPaciente }}" para {{ pacienteChosen.nome }}
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          Deseja sobre-escrever a fórmula já prescrita ou deseja salvar esta
          fórmula como nova?
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text @click="dialogSalvar = false" color="#614021">
            Fechar
          </v-btn>
          <v-btn
            class="mx-1 white--text"
            dense
            @click="salvarPrescricaoSobreEscrever"
            color="#c5d13f"
          >
            Sobre-escrever
          </v-btn>
          <v-btn @click="salvarPrescricaoComoNovo" dense color="#c5d13f">
            Salvar como novo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Enviando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Fórmula prescrita!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn v-if="pacienteDocID !== ''" @click="gerarLembrete">
            <v-icon color="#00ff00">mdi-whatsapp</v-icon>
            Gerar lembrete de aviso por WhatsApp</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="gerarLembreteDialog" max-width="600">
      <v-card v-if="!lembreteGerado">
        <v-card-title class="d-flex justify-center">
          Gerando lembrete...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          Lembrete de aviso gerado!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="gerarLembreteDialog = false" color="#c5d13f"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex flex-column justify-center">
      <v-card
        class="d-inline-block mr-3"
        width="100%"
        elevation="3"
        color="grey lighten-2"
      >
        <v-container>
          <v-row class="mx-4 mb-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  @click="visualizarDialog = true"
                >
                  <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                </v-btn>
              </template>
              <span>Visualizar Prescrição</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <h3>Prescrever Fórmulas</h3>
            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#c5d13f"
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadPdf(prescricaoC)"
                >
                  <v-icon color="#614021">{{ icons.mdiDownload }}</v-icon>
                </v-btn>
              </template>
              <span>Baixar Prescrição</span>
            </v-tooltip>

            <v-tooltip
              bottom
              v-if="persistPaciente !== '' && formulaEditData.nome !== ''"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#c5d13f"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-4"
                  @click="dialogSalvar = true"
                >
                  <v-icon color="#614021">{{ icons.mdiContentSave }}</v-icon>
                </v-btn>
              </template>
              <span>Salvar Prescrição</span>
            </v-tooltip>

            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#c5d13f"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-4"
                  @click="salvarPrescricaoComoNovo"
                >
                  <v-icon color="#614021">{{ icons.mdiContentSave }}</v-icon>
                </v-btn>
              </template>
              <span>Salvar Prescrição</span>
            </v-tooltip>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center mx-4 mt-2">
            <v-text-field
              dense
              solo
              v-model="nomeFormulaPaciente"
              label="Titulo da Prescrição (visível pelo paciente)"
              class="align-self-center mx-2"
              required
            ></v-text-field>
            <v-autocomplete
              label="Nome do Paciente"
              single-line
              solo
              dense
              clearable
              v-model="pacienteDocID"
              :items="pacientes"
              class="align-self-center mx-2"
              @change="pacienteSelected(pacienteDocID)"
              no-data-text="..."
            ></v-autocomplete>
          </v-row>
        </v-container>
      </v-card>

      <PrescreverForm></PrescreverForm>
    </div>
  </div>
</template>

<script>
import {
  mdiCalendar,
  mdiContentSave,
  mdiPrinter,
  mdiEye,
  mdiEmailSendOutline,
  mdiDownload,
  mdiMagnify,
} from "@mdi/js";

import jspdf from "jspdf";
import "jspdf-autotable";

// components imports
import PrescreverForm from "../components/home/formulas/PrescreverForm";
import firebase from "firebase";
// import router from "../router";
import fontCode from "../components/Montserrat-Medium-normal";

export default {
  components: { PrescreverForm },
  data: function() {
    return {
      // comoNovo: true,
      dialogSalvar: false,
      pacienteDocID: "",
      whatsappLink: "",
      lembreteGerado: false,
      gerarLembreteDialog: false,
      pacienteName: "",
      pacienteChosen: { nome: "", acceptsParceiroF: false },
      visualizarDialog: false,
      progressDialog: false,
      saved: false,
      icons: {
        mdiCalendar,
        mdiContentSave,
        mdiPrinter,
        mdiEye,
        mdiEmailSendOutline,
        mdiDownload,
        mdiMagnify,
      },
      nomeFormulaPaciente: "",
      preloadLogo: new Image(),
      preloadSignature: new Image(),
    };
  },
  computed: {
    persistPaciente() {
      return this.$store.state.firestore.persistPaciente;
    },
    formulaEditData() {
      return this.$store.state.firestore.formulaEditData;
    },
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    formulas() {
      return this.$store.state.firestore.formulas;
    },
    prescricaoC() {
      return this.$store.state.firestore.prescricaoC;
    },
  },
  methods: {
    // async preloadImages() {
    //   // this.preloadSignature.src = require(this.signatureAdress); // require("@/assets/signature_none.jpg");
    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "umarizalbelem@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.preloadSignature.src = require("@/assets/signature_danielle.png");
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 2") {
    //       this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 3") {
    //       this.preloadSignature.src = require("@/assets/signature_henrique.png");
    //     }
    //   }

    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "bc@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.preloadSignature.src = require("@/assets/signature_none.jpg");
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 2") {
    //       this.preloadSignature.src = require("@/assets/signature_suellen.jpg");
    //     } else {
    //       this.preloadSignature.src = require("@/assets/signature_suellen.jpg");
    //     }
    //   }

    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "fortaleza.salinas@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.preloadSignature.src = require("@/assets/signature_leanna.png");
    //     } else {
    //       this.preloadSignature.src = require("@/assets/signature_none.jpg");
    //     }
    //   }

    //   this.preloadLogo.src = require("@/assets/logoCompleto.png");

    //   this.preloadLogo.onload = function () {
    //     console.log("Logo carregada");
    //   };
    //   this.preloadSignature.onload = function () {
    //     console.log("Signature carregada");
    //   };

    //   console.log(
    //     "natural:",
    //     this.preloadLogo.naturalWidth,
    //     this.preloadLogo.naturalHeight
    //   );
    //   console.log(
    //     "natural:",
    //     this.preloadSignature.naturalWidth,
    //     this.preloadSignature.naturalHeight
    //   );
    // },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async preloadImages() {
      console.log(
        "this.funcionarioOnline.assinatura",
        this.funcionarioOnline.assinatura
      );
      if (
        this.funcionarioOnline.assinatura !== undefined &&
        this.funcionarioOnline.assinatura !== ""
      ) {
        this.toDataURL(this.funcionarioOnline.assinatura, (data) => {
          this.preloadSignature.src = data;
        });
      } else {
        this.preloadSignature.src = require("@/assets/signature_none.jpg");
      }

      if (this.isFranquia) {
        this.preloadLogo.src = require("@/assets/logoCompleto.png");
      } else if (
        this.userInfo.profilePicture !== undefined &&
        this.userInfo.profilePicture !== "" &&
        !this.isFranquia
      ) {
        this.toDataURL(this.userInfo.profilePicture, (data) => {
          this.preloadLogo.src = data;
        });
      } else {
        this.preloadLogo.src = require("@/assets/signature_none.jpg");
      }

      this.preloadLogo.onload = function() {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };

      console.log(
        "natural logo:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural sign:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    downloadPdf: async function(prescricaoC) {
      if (this.prescricaoC.formulasList.length !== 0) {
        var doc = new jspdf("p");
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        alert("Gerando PDF");
        // links
        // https://mrrio.github.io/jsPDF/examples/basic.html
        // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
        // https://simonbengtsson.github.io/jsPDF-AutoTable/
        // https://www.npmjs.com/package/jspdf-autotable

        // Doc settings
        var fontSize = 10;
        doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
        doc.addFont(
          "Montserrat-Medium-normal.ttf",
          "Montserrat-Medium",
          "normal"
        );
        doc.setFontSize(fontSize);
        doc.setFont("Montserrat-Medium");

        // The (900 / 262) division is used to keep the proportion of the original image
        // New Width * (900 / 262) will keep the height proportion

        var imgWidth = 40;
        // var imgHeight = imgWidth / (900 / 262);
        var imgHeight =
          imgWidth /
          (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
        var imgPos = (210 - imgWidth) / 2;
        var startXPos = 15;
        var textStartYPos = imgHeight * 1.1 + 12;

        var centeredText = function(text, y) {
          var textWidth =
            (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
          doc.text(textOffset, y, text);
        };

        // var createTexts = function(
        //   textList,
        //   centered = false,
        //   textStart = textStartYPos
        // ) {
        //   for (var i = 0; i < textList.length; i++) {
        //     if (centered) {
        //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
        //     } else {
        //       var lines = doc
        //         .setFont("Montserrat-Medium")
        //         .splitTextToSize(textList[i], 180);
        //       // console.log(lines)
        //       for (var j = 0; j < lines.length; j++) {
        //         doc.text(
        //           startXPos,
        //           textStart + (fontSize / 2) * (1 + j),
        //           lines[j]
        //         );
        //       }
        //       textStart += (fontSize / 2) * /*1 + */ lines.length;
        //     }
        //   }
        //   return (textStart += fontSize / 2);
        // };
        var createTexts = function(
          textList,
          centered = false,
          textStart = textStartYPos
        ) {
          var pageHeightThreshold = 256; // The max Y position for the current page
          var newYStart = 24; // The Y position to start at on a new page

          for (var i = 0; i < textList.length; i++) {
            var lines = doc
              .setFont("Montserrat-Medium")
              .splitTextToSize(textList[i], 180);

            for (var j = 0; j < lines.length; j++) {
              // Check if the current line would go past the threshold
              if (textStart > pageHeightThreshold) {
                doc.addPage();
                textStart = newYStart; // Reset the text start position for the new page
              }

              // Whether to center the text or not
              if (centered) {
                centeredText(lines[j], textStart);
              } else {
                doc.text(startXPos, textStart, lines[j]);
              }

              textStart += fontSize / 2; // Increment the y position for each line of text
            }

            // Increment after each text block rather than each line
            if (!centered) {
              textStart += fontSize / 2; // This accounts for the space after each block of text
            }
          }

          return textStart; // Return the updated text start position
        };

        // // Logo Clubedonutricionista
        //   if (this.$store.state.firestore.pacienteNutriUseLogo === false) {
        // console.log("op 1");
        doc.addImage({
          imageData: this.preloadLogo,
          y: startXPos,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });
        //   } else {
        // LOGO CLUBE
        // imgWidth = 30;
        // imgHeight = imgWidth / (900 / 262);
        // startXPos = 15;

        // doc.addImage({
        //   imageData: require("@/assets/logoCompleto.png"),
        //   y: startXPos,
        //   x: 15,
        //   w: imgWidth,
        //   h: imgHeight,
        // });

        // imgWidth = 50;
        // var startYPos = 15 + imgHeight * 2;
        // imgHeight =
        //   imgWidth /
        //   (this.preloadLogo.naturalWidth /
        //     this.preloadLogo.naturalHeight);
        // imgPos = (210 - imgWidth) / 2;
        // textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

        // doc.addImage({
        //   imageData: this.preloadLogo,
        //   y: startYPos,
        //   x: imgPos,
        //   w: imgWidth,
        //   h: imgHeight,
        // });
        //   }

        var texts = [
          `"${this.nomeFormulaPaciente}" (${day}/${month}/${year})`,
          `Para: ${this.pacienteChosen.nome}`,
        ];

        var endTablePosY = createTexts(texts);

        // for (var i = 0; i < formula.prescricaoC.formulasList.length; i++) {
        // endTablePosY = createTexts(
        //     [
        //       formula.prescricaoC.formulasList[i].titulo,
        //       formula.prescricaoC.formulasList[i].prescricoes,
        //     ],
        //     false,
        //     endTablePosY + i * 48
        //   );
        // }

        var body = prescricaoC.formulasList.map(function(formulaP) {
          if (formulaP.prescricoes != undefined) {
            return [formulaP.titulo, formulaP.prescricoes];
          } else {
            return [];
          }
        });

        doc.autoTable({
          startY: endTablePosY, //lastPos // (lastPos / 2) * 0.7 + startPosY,
          startx: startXPos + 24,
          head: [["Nome da fórmula", "fórmula"]],
          body: body,
          headStyles: {
            fillColor: [226, 232, 159],
            textColor: 40,
            fontStyle: "regular",
          },
          bodyStyles: {
            fillColor: [243, 245, 216],
          },
          alternateRowStyles: {
            fillColor: [249, 250, 235],
          },
        });
        endTablePosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);

        imgWidth = 75;
        imgHeight =
          imgWidth /
          (this.preloadSignature.naturalWidth /
            this.preloadSignature.naturalHeight);
        imgPos = (210 - imgWidth) / 2;
        textStartYPos = endTablePosY + 16;

        doc.addImage({
          imageData: this.preloadSignature,
          y: endTablePosY + 16,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });

        createTexts(
          [
            "____________________________",
            // `Nutricionista ${this.$store.state.firestore.userInfo.name}`,
            // `CRN: ${this.$store.state.firestore.userInfo.crn}`,
            `Nutricionista ${this.funcionarioOnline.nome}`,
            `CRN: ${this.funcionarioOnline.crn}`,
            `Data: ${day}/${month}/${year}`,
            "",
            `${this.userInfo.addressRoad} - ${this.userInfo.addressComplement}`,
            `${this.userInfo.addressCep} ${this.userInfo.addressCity} - ${this.userInfo.addressBairro}`,
            `telefono: ${this.userInfo.phoneClients}`,
          ],
          true,
          endTablePosY !== undefined
            ? endTablePosY + imgHeight
            : textStartYPos + texts.length * (fontSize * (3 / 4))
        );

        doc.save("prescricao.pdf");
      } else {
        alert("Preencha todos os campos da dieta antes de continuar");
      }
    },
    async gerarLembrete() {
      this.gerarLembreteDialog = true;
      this.lembreteGerado = false;
      var d = new Date();

      var millis_1 = d.getTime();

      // var millis_1 = Math.ceil(millis / 86400000) * 86400000 + 21600000;

      var ref = firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc();

      var id = ref.id;

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(id)
        .set({
          tipo: "aviso-whatsapp",
          done: false,
          status: "notDueYet",
          dueDate: millis_1,
          patient: this.pacienteName,
          patientId: this.pacienteDocID,
          tipoPrescr: "Uma nova suplementação foi prescrito!",
          link: this.whatsappLink,
          id: id,
        });

      this.lembreteGerado = true;
      this.progressDialog = false;
    },
    async getWhatsappLink(pId) {
      if (pId !== "" && pId !== null && pId !== undefined) {
        var pacienteDoc = await firebase
          .firestore()
          .collection("users")
          .doc(pId)
          .get()
          .then(function(doc) {
            return doc.data();
          });

        var whatsapp = pacienteDoc.whatsapp;
        this.pacienteName = pacienteDoc.nome;

        if (whatsapp !== "" && whatsapp !== undefined) {
          var removedStr = whatsapp.replace(/[^a-zA-Z0-9]/g, "");
          // console.log(removedStr)
          var cleanWhatsapp = "55" + removedStr;
          var splitList = pId.split("@");
          var email1 = splitList[0];
          var email2 = splitList[1];
          this.whatsappLink = `https://wa.me/${cleanWhatsapp}?text=Ol%C3%A1%2C+cliente+do+Clube+da+Nutricionista%21%0D%0AUma+nova+suplementa%C3%A7%C3%A3o+foi+prescrito+para+voc%C3%AA.+Para+visualiz%C3%A1-lo+ou+baix%C3%A1-lo%2C+acesse+a+%22%C3%A1rea+do+paciente%22+pelo+link+nutriapp.app+%28email%3A+${email1}%40${email2}%2C+senha%3A+nutri123%29+e+v%C3%A1+na+aba+de+%22Prescri%C3%A7%C3%B5es%22.+%0D%0AQualquer+d%C3%BAvida+sobre+a+suplementa%C3%A7%C3%A3o+envie+uma+mensagem+pelo+sistema+na+%C3%A1rea+%22Fale+com+a+Nutri%22.%0D%0A-----+Mensagem+autom%C3%A1tica+-----`;
          // console.log(this.whatsappLink);
        } else {
          this.whatsappLink =
            "https://wa.me/?text=Ol%C3%A1%2C+cliente+do+Clube+da+Nutricionista%21%0D%0AUma+nova+suplementa%C3%A7%C3%A3o+foi+prescrito+para+voc%C3%AA.+Para+visualiz%C3%A1-lo+ou+baix%C3%A1-lo%2C+acesse+a+%22%C3%A1rea+do+paciente%22+pelo+link+nutriapp.app+e+v%C3%A1+na+aba+de+%22Prescri%C3%A7%C3%B5es%22.+%0D%0AQualquer+d%C3%BAvida+sobre+a+suplementa%C3%A7%C3%A3o+envie+uma+mensagem+pelo+sistema+na+%C3%A1rea+%22Fale+com+a+Nutri%22.%0D%0A-----+Mensagem+autom%C3%A1tica+-----";
        }
      }
    },
    async pacienteSelected(pacienteId) {
      if (pacienteId != undefined) {
        this.getWhatsappLink(pacienteId);
        // var paciente = this.pacientes.find((item) => item.value == pacienteId);
        const pacienteData = await firebase
          .firestore()
          .collection("users")
          .doc(pacienteId)
          .get()
          .then(function(doc) {
            return doc.data();
          });
        this.pacienteChosen = pacienteData;
        console.log(this.pacienteChosen);
      } else {
        this.pacienteChosen = { nome: "", acceptsParceiroF: false };
      }
    },
    async salvarPrescricaoSobreEscrever() {
      if (this.pacienteDocID !== "") {
        this.progressDialog = true;
        console.log(this.pacienteDocID);
        var d = new Date();
        var milliseconds = d.getTime();
        var year = d.getFullYear();
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var yearStr = year.toString();
        var monthStr;
        if (month < 10) {
          monthStr = "0" + month.toString();
        } else {
          monthStr = month.toString();
        }
        var dayStr;
        if (day < 10) {
          dayStr = "0" + day.toString();
        } else {
          dayStr = day.toString();
        }
        var dateStr = dayStr + "/" + monthStr + "/" + yearStr;

        var id = "";

        id = this.formulaEditData.id;
        dateStr = this.formulaEditData.dateStr;

        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .collection("formulas")
          .doc(id)
          .set({
            id: id,
            nome: this.nomeFormulaPaciente,
            prescricaoC: this.prescricaoC,
            dateStr: dateStr,
            pacienteName: this.pacienteChosen.nome,
            autorEmail: sessionStorage.getItem("current-user"),
            autorPapel: this.funcionarioOnline.papel,
            autorCrn: this.funcionarioOnline.crn,
            autorNome: this.funcionarioOnline.nome,
            autorAssinatura: this.funcionarioOnline.assinatura,
            unread: true,
          });

        if (
          sessionStorage.getItem("current-user") ==
            "admin@clubedanutricionista.com.br" ||
          sessionStorage.getItem("current-user") ==
            "bc@clubedanutricionista.com.br"
        ) {
          await firebase
            .firestore()
            .collection("masterfarma")
            .doc(id)
            .set({
              autorPapel: this.funcionarioOnline.papel,
              autorCrn: this.funcionarioOnline.crn,
              autorNome: this.funcionarioOnline.nome,
              autorAssinatura: this.funcionarioOnline.assinatura,
              unread: true,
              id: id,
              millis: milliseconds,
              franq: sessionStorage.getItem("current-user"),
              enc: false,
              orcamento: 0,
              nome: this.nomeFormulaPaciente,
              prescricaoC: this.prescricaoC,
              dateStr: dateStr,
              pacId: this.pacienteDocID,
              pacienteName: this.pacienteChosen.nome,
              pacienteTelefone: this.pacienteChosen.telefone,
              pacienteWhatsapp: this.pacienteChosen.whatsapp,
              bairro: this.pacienteChosen.bairro
                ? this.pacienteChosen.bairro
                : this.pacienteChosen.pacienteInfos.bairro
                ? this.pacienteChosen.pacienteInfos.bairro
                : null,
              endereco: this.pacienteChosen.endereco
                ? this.pacienteChosen.endereco
                : this.pacienteChosen.pacienteInfos.endereco
                ? this.pacienteChosen.pacienteInfos.endereco
                : null,
              cep: this.pacienteChosen.cep
                ? this.pacienteChosen.cep
                : this.pacienteChosen.pacienteInfos.cep
                ? this.pacienteChosen.pacienteInfos.cep
                : null,
              cidade: this.pacienteChosen.cidade
                ? this.pacienteChosen.cidade
                : this.pacienteChosen.pacienteInfos.cidade
                ? this.pacienteChosen.pacienteInfos.cidade
                : null,
              estado: this.pacienteChosen.estado
                ? this.pacienteChosen.estado
                : this.pacienteChosen.pacienteInfos.estado
                ? this.pacienteChosen.pacienteInfos.estado
                : null,
              ncont: true,
              conta: false,
              orcad: false,
              aceit: false,
              rejei: false,
            });
        }

        if (
          sessionStorage.getItem("current-user") ==
            "umarizalbelem@clubedanutricionista.com.br" &&
          this.pacienteChosen.acceptsParceiroF
        ) {
          await firebase
            .firestore()
            .collection("pharmapele")
            .doc(id)
            .set({
              id: id,
              enc: false,
              orcamento: 0,
              nome: this.nomeFormulaPaciente,
              prescricaoC: this.prescricaoC,
              dateStr: dateStr,
              pacienteName: this.pacienteChosen.nome,
              pacienteTelefone: this.pacienteChosen.telefone,
              pacienteWhatsapp: this.pacienteChosen.whatsapp,
            });
        } else if (
          sessionStorage.getItem("current-user") !==
            "umarizalbelem@clubedanutricionista.com.br" &&
          this.pacienteChosen.acceptsParceiroF
        ) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("formulasPublic")
            .doc(id)
            .set({
              id: id,
              nome: this.nomeFormulaPaciente,
              prescricaoC: this.prescricaoC,
              dateStr: dateStr,
              autorEmail: sessionStorage.getItem("current-user"),
              autorPapel: this.funcionarioOnline.papel,
              autorCrn: this.funcionarioOnline.crn,
              autorNome: this.funcionarioOnline.nome,
              autorAssinatura: this.funcionarioOnline.assinatura,
              pacienteName: this.pacienteChosen.nome,
              pacienteTelefone: this.pacienteChosen.telefone,
              pacienteWhatsapp: this.pacienteChosen.whatsapp,
              contacts: [],
            });
        }

        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .update({
            lastFormulaMillis: milliseconds,
          });

        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .collection("timeline")
          .doc(milliseconds.toString())
          .set({
            docId: id,
            colId: "formulas",
            nome: this.nomeFormulaPaciente,
            type: "formula",
            icon: "mdi-text-box-outline",
            date: dateStr,
            to: "/PacienteFormulas",
            text: "Uma nova prescrição foi feita!",
            toText: "Todas as prescrições (fórmulas)",
            color: "#c5d13f",
            download: true,
          });

        var defaultPrescricaoC = {
          nome: "",
          formulasList: [],
          observacoes: "",
        };
        this.saved = true;
        this.dialogSalvar = false;
        this.$store.dispatch("firestore/prescricaoCUpdate", {
          prescricaoC: defaultPrescricaoC,
        });

        // this.$router.push("MeusPacientes");
      } else {
        alert("Escolhe um paciente antes de salvar!");
      }
    },
    async salvarPrescricaoComoNovo() {
      if (this.pacienteDocID !== "") {
        this.progressDialog = true;
        console.log(this.pacienteDocID);
        var d = new Date();
        var milliseconds = d.getTime();
        var year = d.getFullYear();
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var yearStr = year.toString();
        var monthStr;
        if (month < 10) {
          monthStr = "0" + month.toString();
        } else {
          monthStr = month.toString();
        }
        var dayStr;
        if (day < 10) {
          dayStr = "0" + day.toString();
        } else {
          dayStr = day.toString();
        }
        var dateStr = dayStr + "/" + monthStr + "/" + yearStr;

        var id = "";

        var ref = firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .collection("formulas")
          .doc();

        id = ref.id;

        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .collection("formulas")
          .doc(id)
          .set({
            id: id,
            nome: this.nomeFormulaPaciente,
            prescricaoC: this.prescricaoC,
            dateStr: dateStr,
            pacienteName: this.pacienteChosen.nome,
            autorEmail: sessionStorage.getItem("current-user"),
            autorPapel: this.funcionarioOnline.papel,
            autorCrn: this.funcionarioOnline.crn,
            autorNome: this.funcionarioOnline.nome,
            autorAssinatura: this.funcionarioOnline.assinatura,
            unread: true,
          });

        if (
          sessionStorage.getItem("current-user") ==
            "admin@clubedanutricionista.com.br" ||
          sessionStorage.getItem("current-user") ==
            "bc@clubedanutricionista.com.br"
        ) {
          await firebase
            .firestore()
            .collection("masterfarma")
            .doc(id)
            .set({
              autorPapel: this.funcionarioOnline.papel,
              autorCrn: this.funcionarioOnline.crn,
              autorNome: this.funcionarioOnline.nome,
              autorAssinatura: this.funcionarioOnline.assinatura,
              unread: true,
              id: id,
              millis: milliseconds,
              franq: sessionStorage.getItem("current-user"),
              enc: false,
              orcamento: 0,
              nome: this.nomeFormulaPaciente,
              prescricaoC: this.prescricaoC,
              dateStr: dateStr,
              pacId: this.pacienteDocID,
              pacienteName: this.pacienteChosen.nome,
              pacienteTelefone: this.pacienteChosen.telefone,
              pacienteWhatsapp: this.pacienteChosen.whatsapp,
              bairro: this.pacienteChosen.bairro
                ? this.pacienteChosen.bairro
                : this.pacienteChosen.pacienteInfos.bairro
                ? this.pacienteChosen.pacienteInfos.bairro
                : null,
              endereco: this.pacienteChosen.endereco
                ? this.pacienteChosen.endereco
                : this.pacienteChosen.pacienteInfos.endereco
                ? this.pacienteChosen.pacienteInfos.endereco
                : null,
              cep: this.pacienteChosen.cep
                ? this.pacienteChosen.cep
                : this.pacienteChosen.pacienteInfos.cep
                ? this.pacienteChosen.pacienteInfos.cep
                : null,
              cidade: this.pacienteChosen.cidade
                ? this.pacienteChosen.cidade
                : this.pacienteChosen.pacienteInfos.cidade
                ? this.pacienteChosen.pacienteInfos.cidade
                : null,
              estado: this.pacienteChosen.estado
                ? this.pacienteChosen.estado
                : this.pacienteChosen.pacienteInfos.estado
                ? this.pacienteChosen.pacienteInfos.estado
                : null,
              ncont: true,
              conta: false,
              orcad: false,
              aceit: false,
              rejei: false,
            });
        }

        if (
          sessionStorage.getItem("current-user") ==
            "umarizalbelem@clubedanutricionista.com.br" &&
          this.pacienteChosen.acceptsParceiroF
        ) {
          await firebase
            .firestore()
            .collection("pharmapele")
            .doc(id)
            .set({
              id: id,
              enc: false,
              orcamento: 0,
              nome: this.nomeFormulaPaciente,
              prescricaoC: this.prescricaoC,
              dateStr: dateStr,
              pacienteName: this.pacienteChosen.nome,
              pacienteTelefone: this.pacienteChosen.telefone,
              pacienteWhatsapp: this.pacienteChosen.whatsapp,
            });
        } else if (
          sessionStorage.getItem("current-user") !==
            "umarizalbelem@clubedanutricionista.com.br" &&
          this.pacienteChosen.acceptsParceiroF
        ) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("formulasPublic")
            .doc(id)
            .set({
              id: id,
              nome: this.nomeFormulaPaciente,
              prescricaoC: this.prescricaoC,
              dateStr: dateStr,
              autorEmail: sessionStorage.getItem("current-user"),
              autorPapel: this.funcionarioOnline.papel,
              autorCrn: this.funcionarioOnline.crn,
              autorNome: this.funcionarioOnline.nome,
              autorAssinatura: this.funcionarioOnline.assinatura,
              pacienteName: this.pacienteChosen.nome,
              pacienteTelefone: this.pacienteChosen.telefone,
              pacienteWhatsapp: this.pacienteChosen.whatsapp,
              contacts: [],
            });
        }

        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .update({
            lastFormulaMillis: milliseconds,
          });

        await firebase
          .firestore()
          .collection("users")
          .doc(this.pacienteDocID)
          .collection("timeline")
          .doc(milliseconds.toString())
          .set({
            docId: id,
            colId: "formulas",
            nome: this.nomeFormulaPaciente,
            type: "formula",
            icon: "mdi-text-box-outline",
            date: dateStr,
            to: "/PacienteFormulas",
            text: "Uma nova prescrição foi feita!",
            toText: "Todas as prescrições (fórmulas)",
            color: "#c5d13f",
            download: true,
          });

        var defaultPrescricaoC = {
          nome: "",
          formulasList: [],
          observacoes: "",
        };
        this.saved = true;
        this.dialogSalvar = false;
        this.$store.dispatch("firestore/prescricaoCUpdate", {
          prescricaoC: defaultPrescricaoC,
        });

        // this.$router.push("MeusPacientes");
      } else {
        alert("Escolhe um paciente antes de salvar!");
      }
    },
  },
  mounted() {
    // if (this.$store.state.firestore.pacientes.length === 0) {
    this.$store.dispatch("firestore/getPacientes");
    // }
    this.preloadImages();
    setTimeout(() => {
      if (this.persistPaciente !== "" && this.formulaEditData.nome !== "") {
        this.pacienteDocID = this.persistPaciente;
        this.pacienteSelected(this.pacienteDocID);
        this.nomeFormulaPaciente = this.formulaEditData.nome;
      }
    }, 1000);
  },
};
</script>

<style></style>
