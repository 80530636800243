<template>
  <div>plano to acquire: {{ plano }}</div>
</template>

<script>
export default {
  computed: {
    plano: function () {
      return this.$store.state.firestore.planoToAcquire;
    },
  },
};
</script>