<template>
  <div>
    <v-dialog v-model="dialogVisualizar" max-width="80%" height="80%" multiple>
      <v-card>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel
            v-for="refeicao in dietaRefeicoes"
            :key="refeicao.nomeRefeicao"
          >
            <v-expansion-panel-header>
              <v-row class="text-h6">
                {{ refeicao.nomeRefeicao }},
                {{ refeicao.horarioRefeicao }}
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels
                v-model="panelSubs"
                multiple
                v-show="refeicao.alimentosList[0].gramas > 0"
              >
                <v-expansion-panel
                  v-for="alimento in refeicao.alimentosList"
                  :key="alimento.nome"
                >
                  <v-expansion-panel-header>
                    <p
                      v-if="alimento.quantidade < 2"
                      class="text-p black--text"
                    >
                      {{ alimento.quantidade }} {{ alimento.medida }} de
                      {{ alimento.nome }} ({{ alimento.gramas }}g total)
                    </p>
                    <p
                      v-else-if="alimento.quantidade > 1"
                      class="text-p black--text"
                    >
                      {{ alimento.quantidade }} ({{ alimento.medida }}) de
                      {{ alimento.nome }} ({{ alimento.gramas }}g total)
                    </p>
                    <p v-else class="text-p black--text">
                      {{ alimento.gramas }}g de {{ alimento.nome }}
                    </p>
                    <template v-slot:actions>
                      <v-icon
                        color="black"
                        v-if="alimento.toSubstitute != undefined"
                        @click="log(alimento.toSubstitute)"
                      >
                        {{ icons.mdiAutorenew }}
                      </v-icon>
                      <v-icon v-else> </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="ml-6">
                    <v-row
                      class="mt-1"
                      v-show="alimento.toSubstitute != undefined"
                    >
                      <strong> Possíveis substituições </strong>
                    </v-row>
                    <v-simple-table v-show="alimento.toSubstitute != undefined">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Alimento</th>
                            <th class="text-left">Quantidade</th>
                            <th class="text-left">Medida caseira</th>
                            <th class="text-left">Gramas totais</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(sub, subIndex) in alimento.toSubstitute"
                            :key="subIndex"
                          >
                            <td>{{ sub.nome }}</td>
                            <td>{{ sub.qtdSub }}</td>
                            <td>{{ sub.medida_caseira_padrao }}</td>
                            <td>{{ sub.gramas }}g</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-expansion-panels
                v-model="panelReceitas"
                multiple
                v-show="refeicao.receitasList[0].porcoes > 0"
              >
                <v-expansion-panel
                  v-for="receita in refeicao.receitasList"
                  :key="receita.titulo"
                >
                  <v-expansion-panel-header class="text-p black--text">
                    {{ receita.porcoes }} {{ porcao(receita.porcoes) }} de "{{
                      receita.titulo
                    }}"
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="ml-6">
                    <v-row> <strong> Modo de Preparo: </strong> </v-row>
                    <v-row> {{ receita.modoPreparo }} </v-row>
                    <v-row>
                      <strong> Rendimento: </strong> {{ receita.rendimento }}
                    </v-row>
                    <v-row class="mt-1">
                      <strong> Ingredientes: </strong>
                    </v-row>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Alimento</th>
                            <th class="text-left">Quantidade</th>
                            <th class="text-left">Medida caseira</th>
                            <th class="text-left">Gramas totais</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="alimentoR in receita.alimentosList"
                            :key="alimentoR.nome"
                          >
                            <td>{{ alimentoR.nome }}</td>
                            <td>{{ alimentoR.quantidade }}</td>
                            <td>{{ alimentoR.medida }}</td>
                            <td>{{ alimentoR.gramas }}g</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-card class="pr-2" min-height="50px" elevation="1">
                <v-card-text
                  v-show="refeicao.observacao"
                  class="text-p black--text ml-2"
                >
                  <strong>Observações: </strong> {{ refeicao.observacao }}
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title class="text-h6"> Dietas prescritas </v-card-title>
      <v-card-text>
        <v-card v-for="dieta in dietas" :key="dieta.titulo" outlined>
          <v-row class="text-subtitle-1 d-flex align-center my-1 ml-2">
            <div v-if="dieta.ativo">
              {{ dieta.titulo }}, ({{ dieta.createdAt.toDate().getDate() }}/{{
                dieta.createdAt.toDate().getMonth() + 1
              }}/{{ dieta.createdAt.toDate().getYear() + 1900 }}
              {{ dieta.createdAt.toDate().getHours() }}:{{
                stringify(dieta.createdAt.toDate().getMinutes())
              }}
              )
              <div v-if="dieta.isNew" class="red--text">
                <strong> NOVO ! </strong>
              </div>
            </div>
            <div v-else>
              <strike>
                {{ dieta.titulo }}, (
                {{ dieta.createdAt.toDate().getDate() }}/{{
                  dieta.createdAt.toDate().getMonth() + 1
                }}/{{ dieta.createdAt.toDate().getYear() + 1900 }}
                {{ dieta.createdAt.toDate().getHours() }}:{{
                  stringify(dieta.createdAt.toDate().getMinutes())
                }}
                )</strike
              >
            </div>
            <v-spacer></v-spacer>
            <div>
              <v-tooltip
                bottom
                v-if="isIcon"
                :color="desAtivarColor(dieta.ativo)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon v-if="!dieta.ativo" color="red"
                      >mdi-food-apple-outline</v-icon
                    >
                    <v-icon v-if="dieta.ativo" color="green"
                      >mdi-food-apple</v-icon
                    >
                  </v-btn>
                </template>
                <span v-if="!dieta.ativo">Dieta desativada</span>
                <span v-if="dieta.ativo">Dieta ativa</span>
              </v-tooltip>
              <v-tooltip bottom v-if="isIcon" color="#c5d13f">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="grey"
                    @click="turnToCheckbox"
                    icon
                    :disabled="!dieta.ativo"
                  >
                    <v-icon color="#c5d13f">mdi-cart-arrow-down</v-icon>
                  </v-btn>
                </template>
                <span>Criar lista de Compras</span>
              </v-tooltip>

              <v-checkbox
                v-if="!isIcon"
                color="#614021"
                v-model="dieta.includedInGroceryList"
                label="incluir na lista de compras"
                :disabled="!dieta.ativo"
              >
              </v-checkbox>

              <v-tooltip bottom v-if="isIcon">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#614021"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      downloadPdf(
                        dieta.dietaDocId,
                        dieta.titulo,
                        dieta.createdAt
                      )
                    "
                    :disabled="!dieta.ativo"
                  >
                    <v-icon color="#c5d13f">{{ icons.mdiDownload }}</v-icon>
                  </v-btn>
                </template>
                <span>Baixar Dieta</span>
              </v-tooltip>

              <v-tooltip bottom v-if="isIcon">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#c5d13f"
                    v-bind="attrs"
                    v-on="on"
                    @click="setDietaVisualizar(dieta.dietaDocId)"
                    class="mr-2 mx-2 black--text"
                    :disabled="!dieta.ativo"
                  >
                    <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Dieta</span>
              </v-tooltip>
            </div>
          </v-row>
        </v-card>
        <v-divider v-if="!isIcon"></v-divider>
        <v-row v-if="!isIcon" class="d-flex justify-center">
          <v-btn
            color="#614021"
            dense
            @click="turnToIcons"
            class="my-1 mx-2 white--text"
          >
            <v-icon color="#c5d13f" left>mdi-arrow-left-circle</v-icon>
            Voltar
          </v-btn>
          <v-btn
            dense
            color="#c5d13f"
            @click="makeGroceryList"
            :disabled="calcListaDeCompras"
            class="my-1"
          >
            <v-progress-circular
              :size="20"
              :width="3"
              color="#614021"
              indeterminate
              v-if="calcListaDeCompras"
            ></v-progress-circular>
            <v-icon color="#614021" left v-else>mdi-download</v-icon>
            Baixar lista de compras
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";
import jspdf from "jspdf";
import "jspdf-autotable";
import {
  // mdiCalendar,
  // mdiContentSave,
  mdiCartArrowDown,
  mdiEye,
  // mdiEmailSendOutline,
  mdiDownload,
  // mdiMagnify,
  // mdiDotsHorizontal,
  // mdiInformationOutline,
  // mdiClose,
  mdiAutorenew,
  // mdiTrashCanOutline,
} from "@mdi/js";
import fontCode from "@/components/Montserrat-Medium-normal";

export default {
  data: () => ({
    isIcon: true,
    calcListaDeCompras: false,
    gotDieta: "0",
    dialogVisualizar: false,
    dietas: [],
    panel: [],
    panel0: [0],
    panelReceitas: [],
    panelSubs: [],
    groceryList: [],
    groceryListStrings: [],
    autorData: {
      nome: "",
      crn: "",
      assinatura: "",
      papel: "",
    },
    nutriProfilePicture: "",
    icons: {
      mdiCartArrowDown,
      mdiEye,
      mdiDownload,
      mdiAutorenew,
    },
    preloadLogo: new Image(),
    preloadSignature: new Image(),
  }),
  computed: {
    dietaRefeicoes() {
      return this.$store.state.firestore.dietaRefeicoes;
    },
    dietaAutorObj() {
      return this.$store.state.firestore.dietaAutorObj;
    },
    dietaTitulo() {
      return this.$store.state.firestore.dietaTitulo;
    },
    dietaStatus() {
      return this.$store.state.firestore.dietaStatus;
    },
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
  },
  // firestore() {
  //   return {
  //     dietas: firebase
  //       .firestore()
  //       .collection("users")
  //       .doc(sessionStorage.getItem("current-user"))
  //       // .doc(firebase.auth().currentUser.email)
  //       .collection("dietasRefs"),
  //   };
  // },
  methods: {
    desAtivarColor(b) {
      if (b) {
        return "green";
      } else {
        return "red";
      }
    },
    async getDietasRefs() {
      const dietasRefsGot = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("dietasRefs")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );

      this.dietas = dietasRefsGot.map(function(d) {
        if (d.ativo == undefined) {
          d.ativo = true;
        }
        d.includedInGroceryList = false;
        return d;
      });
    },
    async getNutriData() {
      const nutri = this.userInfo.nutri;
      const nutriData = await firebase
        .firestore()
        .collection("users")
        .doc(nutri)
        .get()
        .then(function(doc) {
          return doc.data();
        });

      if (
        nutriData.profilePicture !== undefined &&
        nutriData.profilePicture !== ""
      ) {
        this.nutriProfilePicture = nutriData.profilePicture;
      }
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    turnToCheckbox() {
      this.isIcon = false;
    },
    turnToIcons() {
      this.isIcon = true;
    },
    async makeGroceryList() {
      this.calcListaDeCompras = true;
      // if (this.gotDieta !== dietaDocId) {
      //   this.$store.dispatch("firestore/getDietaPaciente", {
      //     dietaDocId: dietaDocId,
      //   });
      //   this.gotDieta = dietaDocId;
      // }
      // console.log(this.dietaRefeicoes);
      var groceryList = [];
      // this.dietaRefeicoes
      var includedDietas = this.dietas.filter(function(d) {
        return d.includedInGroceryList;
      });

      var getDietaPacienteById = async function(pacEmail, id) {
        var dieta = await firebase
          .firestore()
          .collection("users")
          .doc(pacEmail)
          .collection("dietas")
          .doc(id.toString())
          .get()
          .then(function(doc) {
            return doc.data();
          });
        return dieta;
      };
      // var pacId = this.pacienteDocID;
      var includedDietasData = [];
      for (var i = 0; i < includedDietas.length; i++) {
        includedDietasData.push(
          await getDietaPacienteById(
            sessionStorage.getItem("current-user"),
            includedDietas[i].dietaDocId
          )
        );
      }

      includedDietasData.forEach(function(d) {
        d.refeicoes.forEach(function(refeicao) {
          refeicao.alimentosList.forEach(function(alimentoIsolado) {
            let alimentoIsoladoReduced = {
              nome: alimentoIsolado.nome,
              gramas: alimentoIsolado.gramas,
              medida: alimentoIsolado.medida,
              quantidade: alimentoIsolado.quantidade,
            };
            groceryList.push(alimentoIsoladoReduced);
          });

          refeicao.receitasList.forEach(function(receita) {
            receita.alimentosList.forEach(function(alimentoInReceita) {
              let alimentoIinReceitaReduced = {
                nome: alimentoInReceita.nome,
                gramas: alimentoInReceita.gramas,
                medida: alimentoInReceita.medida,
                quantidade: alimentoInReceita.quantidade,
              };
              groceryList.push(alimentoIinReceitaReduced);
            });
          });
        });
      });

      // var flatGroceryList = groceryList.flat();
      // console.log(groceryList)
      const summedGroceryList = groceryList.reduce((acc, d) => {
        const found = acc.find((a) => a.nome === d.nome);

        if (!found) {
          acc.push({
            nome: d.nome,
            quantidade: Math.round(parseFloat(d.quantidade)),
            medida: d.medida,
            gramas: Math.round(parseFloat(d.gramas)),
          }); // not found
        } else {
          if (d.quantidade !== null && d.quantidade !== undefined) {
            found.quantidade += Math.round(parseFloat(d.quantidade));
          }
          if (d.gramas !== null && d.gramas !== undefined) {
            found.gramas += Math.round(parseFloat(d.gramas));
          } // if found
        }
        return acc;
      }, []);

      var alphabet = function(objs) {
        function compare(a, b) {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        }

        return objs.sort(compare);
      };
      this.groceryList = alphabet(summedGroceryList);
      // console.log(summedGroceryList)

      // this.groceryListStrings = summedGroceryList.map(function (obj) {
      //   if (obj.nome != undefined) {
      //     return obj.nome + "..........." + obj.gramas.toString() + "g";
      //   } else {
      //     return "";
      //   }
      // });
      this.downloadGroceryList();
      this.calcListaDeCompras = false;
      // console.log(this.groceryListStrings);
    },
    downloadGroceryList() {
      this.preloadImages();
      console.log(this.groceryListStrings);
      alert("Gerando PDF");
      var doc = new jspdf("p");
      // Doc settings
      var fontSize = 12;
      doc.setFontSize(fontSize);
      doc.setFont("Times");

      // The (900 / 262) division is used to keep the proportion of the original image
      // New Width * (900 / 262) will keep the height proportion
      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function(
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc.setFont("Roboto").splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(
        textList,
        centered = false,
        textStart = textStartYPos
      ) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page

        for (var i = 0; i < textList.length; i++) {
          var lines = doc
            .setFont("Montserrat-Medium")
            .splitTextToSize(textList[i], 180);

          for (var j = 0; j < lines.length; j++) {
            // Check if the current line would go past the threshold
            if (textStart > pageHeightThreshold) {
              doc.addPage();
              textStart = newYStart; // Reset the text start position for the new page
            }

            // Whether to center the text or not
            if (centered) {
              centeredText(lines[j], textStart);
            } else {
              doc.text(startXPos, textStart, lines[j]);
            }

            textStart += fontSize / 2; // Increment the y position for each line of text
          }

          // Increment after each text block rather than each line
          if (!centered) {
            textStart += fontSize / 2; // This accounts for the space after each block of text
          }
        }

        return textStart; // Return the updated text start position
      };

      console.log("op 1");
      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      var texts = [
        `Lista de Compras`, // nome da dieta
        // `Para: ${this.pacienteChosen.data.nome}`,
      ];

      var endTablePosY = createTexts(texts);

      // endTablePosY = createTexts(this.groceryListStrings, false, endTablePosY);
      var createTablesForGroceries = function(groceryList, startPosY) {
        var startPosYNew = startPosY;
        // #c5d13f <----- trocar a cor da tabela
        var body = groceryList.map(function(alimento) {
          if (alimento.nome != undefined) {
            return [
              alimento.nome,
              alimento.quantidade,
              alimento.medida,
              alimento.gramas,
            ];
          } else {
            return [];
          }
        });

        doc.autoTable({
          startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
          startx: startXPos,
          head: [["Alimento", "Qtd.", "Medida", "Total (g)"]],
          body: body,
        });
        startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);

        return startPosYNew; // doc.previousAutoTable.finalY;
      };

      createTablesForGroceries(this.groceryList, endTablePosY);

      doc.save("listaDeCompras.pdf");
    },
    porcao(nr) {
      if (nr > 1) {
        return "porções";
      } else {
        return "porção";
      }
    },
    stringify(min) {
      if (min < 10) {
        return "0" + min.toString();
      } else {
        return min;
      }
    },
    async setDietaVisualizar(dietaDocId) {
      console.log(dietaDocId);
      if (this.gotDieta !== dietaDocId) {
        this.$store.dispatch("firestore/getDietaPaciente", {
          dietaDocId: dietaDocId,
        });
        this.gotDieta = dietaDocId;
      }
      this.dialogVisualizar = true;

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("dietasRefs")
        .doc(dietaDocId.toString())
        .update({
          unread: false,
        });
      // dieta.isNew = false;
    },
    async preloadImages() {
      if (this.userInfo.isNF) {
        if (
          this.nutriProfilePicture !== undefined &&
          this.nutriProfilePicture !== ""
        ) {
          this.toDataURL(this.nutriProfilePicture, (data) => {
            this.preloadLogo.src = data;
          });
        } else {
          this.preloadLogo.src = require("@/assets/signature_none.jpg");
        }
      } else {
        this.preloadLogo.src = require("@/assets/logoCompleto.png");
      }

      this.preloadLogo.onload = function() {
        console.log("Logo carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
    },
    async createDocWithJSPDF(titulo, createdAt) {
      if (this.dietaRefeicoes.length !== 0) {
        alert("Gerando PDF");
        // links
        // https://mrrio.github.io/jsPDF/examples/basic.html
        // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
        // https://simonbengtsson.github.io/jsPDF-AutoTable/
        // https://www.npmjs.com/package/jspdf-autotable

        var doc = new jspdf("p");
        // var date = new Date();
        // var day = date.getDate();
        // var month = date.getMonth() + 1;
        // var year = date.getFullYear();(
        var day = createdAt.toDate().getDate();
        var month = createdAt.toDate().getMonth() + 1;
        var year = createdAt.toDate().getFullYear();

        // Doc settings
        var fontSize = 10;
        doc.addFileToVFS("Montserrat-Medium-normal.ttf", fontCode);
        doc.addFont(
          "Montserrat-Medium-normal.ttf",
          "Montserrat-Medium",
          "normal"
        );
        doc.setFontSize(fontSize);
        doc.setFont("Montserrat-Medium");

        // The (900 / 262) division is used to keep the proportion of the original image
        // New Width * (900 / 262) will keep the height proportion

        var imgWidth = 40;
        // var imgHeight = imgWidth / (900 / 262);
        var imgHeight =
          imgWidth /
          (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
        var imgPos = (210 - imgWidth) / 2;
        var startXPos = 15;
        var textStartYPos = imgHeight * 1.1 + 12;

        var centeredText = function(text, y) {
          var textWidth =
            (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
          doc.text(textOffset, y, text);
        };

        // var createTexts = function(
        //   textList,
        //   centered = false,
        //   textStart = textStartYPos
        // ) {
        //   for (var i = 0; i < textList.length; i++) {
        //     if (centered) {
        //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
        //     } else {
        //       var lines = doc
        //         .setFont("Montserrat-Medium")
        //         .splitTextToSize(textList[i], 180);
        //       // console.log(lines)
        //       for (var j = 0; j < lines.length; j++) {
        //         doc.text(
        //           startXPos,
        //           textStart + (fontSize / 2) * (1 + j),
        //           lines[j]
        //         );
        //       }
        //       textStart += (fontSize / 2) * /*1 + */ lines.length;
        //     }
        //   }
        //   return (textStart += fontSize / 2);
        // };
        var createTexts = function(
          textList,
          centered = false,
          textStart = textStartYPos
        ) {
          var pageHeightThreshold = 256; // The max Y position for the current page
          var newYStart = 24; // The Y position to start at on a new page

          for (var i = 0; i < textList.length; i++) {
            var lines = doc
              .setFont("Montserrat-Medium")
              .splitTextToSize(textList[i], 180);

            for (var j = 0; j < lines.length; j++) {
              // Check if the current line would go past the threshold
              if (textStart > pageHeightThreshold) {
                doc.addPage();
                textStart = newYStart; // Reset the text start position for the new page
              }

              // Whether to center the text or not
              if (centered) {
                centeredText(lines[j], textStart);
              } else {
                doc.text(startXPos, textStart, lines[j]);
              }

              textStart += fontSize / 2; // Increment the y position for each line of text
            }

            // Increment after each text block rather than each line
            if (!centered) {
              textStart += fontSize / 2; // This accounts for the space after each block of text
            }
          }

          return textStart; // Return the updated text start position
        };
        var createTablesForRefeicoes = function(refeicoes, startPosY) {
          var startPosYNew = startPosY;
          // #c5d13f <----- trocar a cor da tabela
          for (var i = 0; i < refeicoes.length; i++) {
            var texts;
            // if (refeicoes[i].observacao !== "") {
            //   texts = [
            //     `${refeicoes[i].nomeRefeicao} - ${refeicoes[i].horarioRefeicao}`,
            //     `Observação: ${refeicoes[i].observacao}`,
            //   ];
            // } else {
            texts = [
              `${refeicoes[i].nomeRefeicao} - ${refeicoes[i].horarioRefeicao}`,
            ];
            // }
            startPosYNew = createTexts(texts, false, startPosYNew + 12); //lastPos
            var body = refeicoes[i].alimentosList.map(function(alimento) {
              if (alimento != undefined) {
                if (alimento.toSubstitute != undefined) {
                  alimento.hasSubs = "*";
                }
                return [
                  alimento.nome,
                  alimento.quantidade, //.toString(),
                  alimento.medida, //.toString(),
                  alimento.gramas, //.toString(),
                  // alimento.kcal//.toString(),
                  alimento.hasSubs,
                ];
              } else {
                return [];
              }
            });
            console.log("", refeicoes[i].alimentosList[0]);
            if (refeicoes[i].alimentosList[0].alimentoId !== "") {
              doc.autoTable({
                startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
                startx: startXPos,
                head: [
                  ["Alimento", "Quantidade", "Medida", "Total (g)", "Subst."],
                ],
                body: body,
                // tableLineColor: [97, 64, 33],
                // tableLineWidth: 0.5,
                // headStyles: {
                //   fillColor: [176, 159, 144],
                //   textColor: 40,
                //   fontStyle: "regular",
                // },
                // bodyStyles: {
                //   fillColor: [223, 216, 210],
                // },
                // alternateRowStyles: {
                //   fillColor: [239, 235, 232],
                // },
                headStyles: {
                  fillColor: [226, 232, 159],
                  textColor: 40,
                  fontStyle: "regular",
                },
                bodyStyles: {
                  fillColor: [243, 245, 216],
                },
                alternateRowStyles: {
                  fillColor: [249, 250, 235],
                },
              });
              startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
            }

            // doc.text "* substituir {alimento} com {alimento.toSubstitute[0], [1]...}

            var bodyReceitas = refeicoes[i].receitasList.map(function(receita) {
              if (receita != undefined) {
                return [receita.titulo, receita.porcoes];
              } else {
                return [];
              }
            });
            console.log("receitas[0]", refeicoes[i].receitasList[0]);
            if (refeicoes[i].receitasList[0].titulo !== "") {
              doc.autoTable({
                startY: startPosYNew, //startPosY + i * (lastPos - startPosY),
                startx: startXPos,
                head: [["Receita", "Porções"]],
                body: bodyReceitas,
                // tableLineColor: [197, 209, 63],
                // tableLineWidth: 0.5,
                headStyles: {
                  fillColor: [226, 232, 159],
                  textColor: 40,
                  fontStyle: "regular",
                },
                bodyStyles: {
                  fillColor: [243, 245, 216],
                },
                alternateRowStyles: {
                  fillColor: [249, 250, 235],
                },
              });
              startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
            }

            if (refeicoes[i].observacao !== "") {
              doc.autoTable({
                startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
                startx: startXPos,
                head: [["Observações"]],
                body: [[refeicoes[i].observacao]],
                // tableLineColor: [149,153,156],
                // tableLineWidth: 0.5,
                headStyles: {
                  fillColor: [214, 219, 223],
                  textColor: 40,
                  fontStyle: "regular",
                },
                bodyStyles: {
                  fillColor: [238, 240, 242],
                },
              });
              startPosYNew = doc.previousAutoTable.finalY;
            }

            for (var jn = 0; jn < refeicoes[i].alimentosList.length; jn++) {
              if (refeicoes[i].alimentosList[jn].toSubstitute != undefined) {
                // console.log(refeicoes[i].alimentosList[jn]);
                var textsSub;
                if (refeicoes[i].alimentosList[jn].medida != null) {
                  textsSub = [
                    `substituir ${refeicoes[i].alimentosList[jn].quantidade} ${refeicoes[i].alimentosList[jn].medida} de ${refeicoes[i].alimentosList[jn].nome} por...`,
                  ];
                } else {
                  textsSub = [
                    `substituir ${refeicoes[i].alimentosList[jn].gramas}g de ${refeicoes[i].alimentosList[jn].nome} por...`,
                  ];
                }

                // var lastPos = createTexts(textsSub, false, startPosYNew);
                var bodySub = refeicoes[i].alimentosList[jn].toSubstitute.map(
                  function(alimento) {
                    if (alimento != undefined) {
                      if (alimento.toSubstitute != undefined) {
                        alimento.hasSubs = "*";
                      }
                      if (alimento.medida_caseira_padrao == "freetext") {
                        alimento.qtdSub = "";
                        alimento.medida_caseira_padrao = "";
                        alimento.gramas = "";
                      }
                      return [
                        alimento.nome,
                        alimento.qtdSub, //.toString(),
                        alimento.medida_caseira_padrao, //.toString(),
                        alimento.gramas, //.toString(),
                      ];
                    } else {
                      return [];
                    }
                  }
                );

                doc.autoTable({
                  startY: startPosYNew, // lastPos, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [[textsSub, "Qtd.", "Medida", "Total (g)"]],
                  body: bodySub,
                  // tableLineColor: [97, 64, 33],
                  // tableLineWidth: 0.5,
                  // headStyles: {
                  //   fillColor: [176, 159, 144],
                  //   textColor: 40,
                  //   fontStyle: "regular",
                  // },
                  // bodyStyles: {
                  //   fillColor: [223, 216, 210],
                  // },
                  // alternateRowStyles: {
                  //   fillColor: [239, 235, 232],
                  // },
                  headStyles: {
                    fillColor: [245, 203, 167],
                    textColor: 40,
                    fontStyle: "regular",
                  },
                  bodyStyles: {
                    fillColor: [250, 229, 211],
                  },
                  alternateRowStyles: {
                    fillColor: [253, 242, 223],
                  },
                });
                startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
              }
            }
          }
          return startPosYNew; // doc.previousAutoTable.finalY;
        };

        // var createTablesForSubst = function(refeicoes, startPosY) {
        //   // #c5d13f <----- trocar a cor da tabela
        //   for (var i = 0; i < refeicoes.length; i++) {
        //     for (var j = 0; j < refeicoes[i].alimentosList.length; j++) {
        //       if (refeicoes[i].alimentosList[j].toSubstitute != undefined) {
        //         // console.log(refeicoes[i].alimentosList[j]);
        //         var textsSub;
        //         if (refeicoes[i].alimentosList[j].medida != null) {
        //           textsSub = [
        //             `${refeicoes[i].nomeRefeicao}: substituir ${refeicoes[i].alimentosList[j].quantidade} ${refeicoes[i].alimentosList[j].medida} de ${refeicoes[i].alimentosList[j].nome} por...`,
        //           ];
        //         } else {
        //           textsSub = [
        //             `${refeicoes[i].nomeRefeicao}: substituir ${refeicoes[i].alimentosList[j].gramas}g de ${refeicoes[i].alimentosList[j].nome} por...`,
        //           ];
        //         }

        //         var lastPos = createTexts(textsSub, false, startPosY);
        //         var bodySub = refeicoes[i].alimentosList[j].toSubstitute.map(
        //           function(alimento) {
        //             if (alimento != undefined) {
        //               if (alimento.toSubstitute != undefined) {
        //                 alimento.hasSubs = "*";
        //               }
        //               return [
        //                 alimento.nome,
        //                 alimento.qtdSub, //.toString(),
        //                 alimento.medida_caseira_padrao, //.toString(),
        //                 alimento.gramas, //.toString(),
        //               ];
        //             } else {
        //               return [];
        //             }
        //           }
        //         );

        //         doc.autoTable({
        //           startY: lastPos, //(lastPos / 2) * 0.7 + startPosY,
        //           startx: startXPos,
        //           head: [["Alimento", "Quantidade", "Medida", "Total (g)"]],
        //           body: bodySub,
        //           // tableLineColor: [97, 64, 33],
        //           // tableLineWidth: 0.5,
        //           // headStyles: {
        //           //   fillColor: [176, 159, 144],
        //           //   textColor: 40,
        //           //   fontStyle: "regular",
        //           // },
        //           // bodyStyles: {
        //           //   fillColor: [223, 216, 210],
        //           // },
        //           // alternateRowStyles: {
        //           //   fillColor: [239, 235, 232],
        //           // },
        //           headStyles: {
        //             fillColor: [226, 232, 159],
        //             textColor: 40,
        //             fontStyle: "regular",
        //           },
        //           bodyStyles: {
        //             fillColor: [243, 245, 216],
        //           },
        //           alternateRowStyles: {
        //             fillColor: [249, 250, 235],
        //           },
        //         });
        //         startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
        //       }
        //     }
        //   }
        //   return doc.previousAutoTable.finalY;
        // };

        var createTablesForReceitas = function(refeicoes, startPosY) {
          // #c5d13f <----- trocar a cor da tabela
          for (var i = 0; i < refeicoes.length; i++) {
            for (var j = 0; j < refeicoes[i].receitasList.length; j++) {
              if (refeicoes[i].receitasList[j].titulo !== "") {
                // console.log(refeicoes[i].receitasList[j]);
                var textsRec = [
                  `${refeicoes[i].nomeRefeicao}: ${refeicoes[i].receitasList[j].titulo}, rendimento: ${refeicoes[i].receitasList[j].rendimento} (porções)`,
                  // `${refeicoes[i].receitasList[j].modoPreparo}`,
                ];

                var lastPos = createTexts(textsRec, false, startPosY + 6);
                var bodyRec = refeicoes[i].receitasList[j].alimentosList.map(
                  function(alimento) {
                    if (alimento != undefined) {
                      return [
                        alimento.nome,
                        alimento.quantidade, //.toString(),
                        alimento.medida, //.toString(),
                        alimento.gramas, //.toString(),
                      ];
                    } else {
                      return [];
                    }
                  }
                );
                var bodyModoPreparo = [
                  [refeicoes[i].receitasList[j].modoPreparo],
                ];

                doc.autoTable({
                  startY: lastPos, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [["Ingrediente", "Quantidade", "Medida", "Total (g)"]],
                  body: bodyRec,
                  // tableLineColor: [197, 209, 63],
                  // tableLineWidth: 0.5,
                  headStyles: {
                    fillColor: [226, 232, 159],
                    textColor: 40,
                    fontStyle: "regular",
                  },
                  bodyStyles: {
                    fillColor: [243, 245, 216],
                  },
                  alternateRowStyles: {
                    fillColor: [249, 250, 235],
                  },
                });
                startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
                doc.autoTable({
                  startY: startPosY, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [["Modo de preparo"]],
                  body: bodyModoPreparo,
                  // tableLineColor: [197, 209, 63],
                  // tableLineWidth: 0.5,
                  // headStyles: {
                  //   fillColor: [226, 232, 159],
                  //   textColor: 40,
                  //   fontStyle: "regular",
                  // },
                  // bodyStyles: {
                  //   fillColor: [243, 245, 216],
                  // },
                  headStyles: {
                    fillColor: [214, 219, 223],
                    textColor: 40,
                    fontStyle: "regular",
                  },
                  bodyStyles: {
                    fillColor: [238, 240, 242],
                  },
                });
                startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
              }
            }
          }
          return doc.previousAutoTable.finalY;
        };

        // console.log(this.$store.state.firestore.userInfo);
        // // Logo Clubedonutricionista
        // if (this.$store.state.firestore.userInfo.useLogo === false) {
        //   console.log("op 1");
        doc.addImage({
          imageData: this.preloadLogo,
          y: startXPos,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });
        // } else {
        //   // LOGO CLUBE
        //   imgWidth = 30;
        //   imgHeight = imgWidth / (900 / 262);
        //   startXPos = 15;

        //   doc.addImage({
        //     imageData: require("@/assets/logoCompleto.png"),
        //     y: startXPos,
        //     x: 15,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });

        //   imgWidth = 50;
        //   var startYPos = 15 + imgHeight * 2;
        //   imgHeight =
        //     imgWidth /
        //     (this.preloadSignature.naturalWidth /
        //       this.preloadSignature.naturalHeight);
        //   imgPos = (210 - imgWidth) / 2;
        //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

        //   doc.addImage({
        //     imageData: this.preloadSignature,
        //     y: startYPos,
        //     x: imgPos,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });
        // }

        var texts = [
          `Plano alimentar: ${titulo} (${day}/${month}/${year})`,
          `Para: ${this.userInfo.nome}`,
        ];

        createTexts(texts, true, textStartYPos + 10);
        var endTablePosY = createTablesForRefeicoes(
          this.dietaRefeicoes,
          textStartYPos + texts.length * fontSize + 10
        );

        // doc.addPage();
        // createTexts(
        //   [
        //     // "_______________________________________________________",
        //     "Possíveis Substituções de Alimentos",
        //   ],
        //   true,
        //   24
        //   // endTablePosY
        // );
        // endTablePosY = createTablesForSubst(
        //   this.dietaRefeicoes,
        //   36
        //   // endTablePosY + 24
        // );

        var refNrRecList = this.dietaRefeicoes.map(function(ref) {
          if (
            ref.receitasList.length > 0 &&
            ref.receitasList[0].titulo !== "" &&
            ref.receitasList[0].titulo !== undefined
          ) {
            return 1;
          } else {
            return 0;
          }
        });
        var nrRecs = 0;
        refNrRecList.forEach(function(nr) {
          nrRecs += parseFloat(nr);
        });

        if (nrRecs > 0) {
          console.log("nrRecs", nrRecs);
          doc.addPage();
          createTexts(
            [
              // "_______________________________________________________",
              "Receitas",
            ],
            true,
            24
            // endTablePosY
          );
          endTablePosY = createTablesForReceitas(
            this.dietaRefeicoes,
            36
            // endTablePosY + 24
          );
        }

        // USE SIGNATURE
        // console.log(
        //   "signature",
        //   this.$store.state.firestore.userInfo.signature
        // );
        // if (this.$store.state.firestore.userInfo.signature != undefined) {
        //   imgWidth = 30;
        //   startYPos =
        //     fontSize +
        //     (endTablePosY !== undefined
        //       ? endTablePosY + 16
        //       : textStartYPos + texts.length * (fontSize * (3 / 4)));
        //   imgHeight =
        //     imgWidth /
        //     (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
        //   imgPos = (210 - imgWidth) / 2;
        //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

        //   doc.addImage({
        //     imageData: this.preloadSignature,
        //     y: startYPos,
        //     x: imgPos,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });
        // } else {
        //   imgHeight = 0;
        // }

        doc.addPage();
        imgWidth = 75;
        imgHeight =
          imgWidth /
          (this.preloadSignature.naturalWidth /
            this.preloadSignature.naturalHeight);
        imgPos = (210 - imgWidth) / 2;
        textStartYPos = imgHeight + 24;
        // textStartYPos = imgHeight + endTablePosY + 12;

        doc.addImage({
          imageData: this.preloadSignature,
          // y: endTablePosY + 12, // + imgHeight * 1.4,
          y: 24,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });

        createTexts(
          [
            "____________________________",
            // `Nutricionista ${this.$store.state.firestore.userInfo.name}`,
            // `CRN: ${this.$store.state.firestore.userInfo.crn}`,
            `Nutricionista: ${this.autorData.nome}`,
            `CRN: ${this.autorData.crn}`,
            `Data: ${day}/${month}/${year}`,
          ],
          true,
          // endTablePosY !== undefined
          //   ? endTablePosY + 16 + imgHeight * 1.4
          //   :
          textStartYPos // + texts.length * (fontSize * (3 / 4))
        );
        console.log(endTablePosY);
        doc.save(`${titulo}.pdf`);
      } else {
        alert("Preencha todos os campos da dieta antes de continuar");
      }
    },
    async downloadPdf(dietaDocId, titulo, createdAt) {
      if (this.gotDieta !== dietaDocId) {
        this.$store.dispatch("firestore/getDietaPaciente", {
          dietaDocId: dietaDocId,
        });
        this.gotDieta = dietaDocId;
      }

      this.preloadImages();
      if (
        this.dietaAutorObj.assinatura !== "" &&
        this.dietaAutorObj.assinatura !== undefined
      ) {
        this.toDataURL(this.dietaAutorObj.assinatura, (data) => {
          this.preloadSignature.src = data;
        });
        this.autorData.nome = this.dietaAutorObj.nome;
        this.autorData.crn = this.dietaAutorObj.crn;
      } else {
        if (
          this.dietaAutorObj.email !== "" &&
          this.dietaAutorObj.email !== undefined
        ) {
          if (
            this.dietaAutorObj.email ==
            "umarizalbelem@clubedanutricionista.com.br"
          ) {
            if (this.dietaAutorObj.papel == "Nutricionista 1") {
              this.preloadSignature.src = require("@/assets/signature_danielle.png");
              this.autorData.nome = "Danielle Zahlouth";
              this.autorData.crn = "11517";
            } else if (this.dietaAutorObj.papel == "Nutricionista 2") {
              this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
              this.autorData.nome = "Thayana Albuquerque Kirchhoff";
              this.autorData.crn = "4571";
            } else if (this.dietaAutorObj.papel == "Nutricionista 3") {
              this.preloadSignature.src = require("@/assets/signature_henrique.png");
              this.autorName = "Jhonatas Henrique Marques Barros";
              this.autorCrn = "12324";
            }
          }

          if (this.dietaAutorObj.email == "bc@clubedanutricionista.com.br") {
            this.preloadSignature.src = require("@/assets/signature_pietra.png");
            this.autorData.nome = "Pietra Chiella";
            this.autorData.crn = "SC 8570";
          }

          if (
            this.dietaAutorObj.email ==
            "fortaleza.salinas@clubedanutricionista.com.br"
          ) {
            // if (this.dietaAutorObj.papel == "Nutricionista 1") {
            this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
            this.autorData.nome = "Thayana Albuquerque Kirchhoff";
            this.autorData.crn = "4571";
            // }
          }
        } else {
          if (
            this.userInfo.nutri == "umarizalbelem@clubedanutricionista.com.br"
          ) {
            this.preloadSignature.src = require("@/assets/signature_danielle.png");
            this.autorData.nome = "Danielle Zahlouth";
            this.autorData.crn = "11517";
          } else if (this.userInfo.nutri == "bc@clubedanutricionista.com.br") {
            this.preloadSignature.src = require("@/assets/signature_pietra.png");
            this.autorData.nome = "Pietra Chiella";
            this.autorData.crn = "SC 8570";
          } else if (
            this.userInfo.nutri ==
            "fortaleza.salinas@clubedanutricionista.com.br"
          ) {
            this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
            this.autorData.nome = "Thayana Albuquerque Kirchhoff";
            this.autorData.crn = "4571";
          } else {
            this.preloadSignature.src = require("@/assets/signature_none.jpg");
          }
        }
      }

      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );

      setTimeout(() => {
        this.createDocWithJSPDF(titulo, createdAt);
      }, 2500);

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("dietasRefs")
        .doc(dietaDocId.toString())
        .update({
          unread: false,
        });
    },
  },
  mounted() {
    //   if (this.$store.state.firestore.dietasPaciente.length === 0) {
    //     this.$store.dispatch("firestore/getDietasPaciente");
    //   }
    // this.preloadImages();
    this.getDietasRefs();
    this.getNutriData();
  },
};
</script>

<style scoped></style>
