<template>
  <v-container>
    <div class="d-flex flex-column">
      <v-card
        class="pa-5 my-1"
        tile
        style="background-color: rgba(197, 209, 63, 0.6)"
      >
        <div class="d-flex flex-column">
          <v-card>
            <div class="d-flex pa-2" style="background-color: #00">
              <v-text-field
                dense
                v-model="receita.titulo"
                label="Título da Receita"
                :rules="inputRules"
                @change="onSomeChange"
              ></v-text-field>
              <v-divider vertical class="mx-2"></v-divider>
              <v-text-field
                dense
                v-model="receita.tempoPreparo"
                label="Tempo de Preparo"
                :rules="inputRules"
                @change="onSomeChange"
              ></v-text-field>
              <v-divider vertical class="mx-2"></v-divider>
              <v-text-field
                dense
                v-model="receita.rendimento"
                label="Rendimento"
                :rules="inputQtdRules"
                @change="onSomeChange"
              ></v-text-field>
            </div>
          </v-card>

          <div
            class="d-flex align-center justify-space-around"
            v-for="(alimento, alimentoIndex) in receita.alimentosList"
            :key="alimentoIndex"
          >
            <v-autocomplete
              label="Alimento"
              :items="alimentos"
              v-model="alimento.alimentoId"
              style="width: 100%; max-width: 250px"
              no-data-text="..."
              @change="onAlimentoSelected(alimentoIndex, alimento.alimentoId)"
            ></v-autocomplete>

            <v-text-field
              label="Medida caseira"
              v-model="alimento.medida"
              :rules="inputAlimentoRules"
              readonly
            ></v-text-field>
            <v-text-field
              label="Qtd."
              v-model="alimento.quantidade"
              :rules="inputQtdRules"
              @change="
                onQtdChange(
                  alimentoIndex,
                  alimento.alimentoId,
                  alimento.quantidade
                )
              "
            ></v-text-field>
            <v-text-field
              label="Gramas total"
              v-model="alimento.gramas"
              :rules="inputAlimentoRules"
              @change="
                onGrChange(alimentoIndex, alimento.alimentoId, alimento.gramas)
              "
            ></v-text-field>

            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="2"
                  rounded
                  icon
                  outlined
                  raised
                  x-small
                  color="black"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="substituteAlimento(alimento)"
                >
                  <v-icon>{{ icons.mdiAutorenew }}</v-icon>
                </v-btn>
              </template>
              <span>opções de substituição</span>
            </v-tooltip>

            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="receita.alimentosList.length > 1"
                  elevation="2"
                  rounded
                  icon
                  outlined
                  raised
                  x-small
                  color="red"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="removeAlimento(alimento)"
                >
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </template>
              <span>remover alimento</span>
            </v-tooltip>

            <v-tooltip bottom color="green">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="alimentoIndex === receita.alimentosList.length - 1"
                  elevation="2"
                  rounded
                  icon
                  outlined
                  raised
                  x-small
                  color="green"
                  class="pa-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="addAlimento"
                >
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </template>
              <span>adicionar novo alimento</span>
            </v-tooltip>
          </div>

          <v-textarea
            v-model="receita.modoPreparo"
            solo
            label="Modo de Preparo"
          ></v-textarea>

          <v-row class="d-flex justify-center px-2 black--text">
            <p class="px-2">
              KCAL: <strong>{{ receita.KCAL.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              CHO: <strong>{{ receita.CHO.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              LIP: <strong>{{ receita.LIP.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              COL: <strong>{{ receita.COL.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              SOD: <strong>{{ receita.SOD.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              FIB: <strong>{{ receita.FIB.toFixed(2) }}</strong>
            </p>
            <p class="px-2">
              PTN: <strong>{{ receita.PTN.toFixed(2) }}</strong>
            </p>
          </v-row>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mdiClose, mdiPlus, mdiAutorenew } from "@mdi/js";

export default {
  data: function () {
    return {
      icons: { mdiClose, mdiPlus, mdiAutorenew },
      inputRules: [],
      inputAlimentoRules: [],
      inputQtdRules: [(v) => /^[0-9]+$/.test(v) || "Use apenas números"],
      demaisCodigos: [
        "fibra_soluvel",
        "fibra_insoluvel",
        "cinzas",
        "calcio",
        "magnesio",
        "manganes",
        "fosforo",
        "ferro",
        "sodio",
        "potassio",
        "cobre",
        "zinco",
        "retinol",
        "re",
        "era",
        "iodo",
        "selenio",
        "acido_folico",
        "acido_pantotenico",
        "tiamina",
        "riboflavina",
        "piridoxina",
        "niacina",
        "vitamina_c",
        "vitamina_d",
        "vitamina_e",
        "vitamina_b12",
        "saturados",
        "monoinsaturados",
        "poliinsaturados",
        "gordura_trans",
        "triptofano",
        "treonina",
        "isoleucina",
        "leucina",
        "lisina",
        "metionina",
        "cistina",
        "fenilalanina",
        "tirosina",
        "valina",
        "arginina",
        "histidina",
        "alanina",
        "acido_aspartico",
        "acido_glutamico",
        "glicina",
        "prolina",
        "serina",
        "umidade",
      ],
    };
  },
  computed: {
    receita() {
      return this.$store.state.firestore.receita;
    },
    alimentos() {
      return this.$store.state.firestore.alimentos;
    },
    dietaStatus() {
      return this.$store.state.firestore.dietaStatus;
    },
  },
  methods: {
    onSomeChange() {
      this.$store.dispatch("firestore/receitaUpdate", {
        receita: this.receita,
      });
    },
    onAlimentoSelected(alimentoIndex, alimentoId) {
      var alimento = this.alimentos.find((item) => item.value == alimentoId);
      if (alimento !== undefined) {
        this.receita.alimentosList[alimentoIndex].nome = alimento.data.nome;
        this.receita.alimentosList[alimentoIndex].medida =
          alimento.data.medida_caseira_padrao;
        this.receita.alimentosList[alimentoIndex].quantidade = 1;
        this.receita.alimentosList[alimentoIndex].gramas =
          alimento.data.gr_padrao;
        let gramas = alimento.data.gr_padrao;

        this.receita.alimentosList[alimentoIndex].CHO =
          (alimento.data.carboidrato / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].PTN =
          (alimento.data.proteina / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].LIP =
          (alimento.data.lipideos / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].KCAL =
          (alimento.data.kcal / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].SOD =
          (alimento.data.sodio / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].COL =
          (alimento.data.colesterol / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].FIB =
          (alimento.data.fibra_alimentar / 100) * gramas;
        for (var a = 0; a < this.demaisCodigos.length; a++) {
          this.receita.alimentosList[alimentoIndex][this.demaisCodigos[a]] =
            (alimento.data[this.demaisCodigos[a]] / 100) * gramas;
        }

        // to keep the values always clean even if the user change only one of the items
        this.receita.CHO = 0;
        this.receita.PTN = 0;
        this.receita.LIP = 0;
        this.receita.KCAL = 0;
        this.receita.SOD = 0;
        this.receita.COL = 0;
        this.receita.FIB = 0;
        for (var b = 0; b < this.demaisCodigos.length; b++) {
          this.receita[this.demaisCodigos[b]] = 0;
        }

        this.receita.alimentosList.forEach((item) => {
          this.receita.CHO += item.CHO;
          this.receita.LIP += item.LIP;
          this.receita.PTN += item.PTN;
          this.receita.KCAL += item.KCAL;
          this.receita.SOD += item.SOD;
          this.receita.COL += item.COL;
          this.receita.FIB += item.FIB;
          for (var c = 0; c < this.demaisCodigos.length; c++) {
            this.receita[this.demaisCodigos[c]] += item[this.demaisCodigos[c]];
          }
        });

        this.$store.dispatch("firestore/receitaUpdate", {
          receita: this.receita,
        });
      }
    },
    onQtdChange(alimentoIndex, alimentoId, qtd) {
      var alimento = this.alimentos.find((item) => item.value == alimentoId);
      if (alimento !== undefined) {
        this.receita.alimentosList[alimentoIndex].nome = alimento.data.nome;
        this.receita.alimentosList[alimentoIndex].medida =
          alimento.data.medida_caseira_padrao;
        this.receita.alimentosList[alimentoIndex].gramas =
          alimento.data.gr_padrao * qtd;
        let gramas = alimento.data.gr_padrao * qtd;

        this.receita.alimentosList[alimentoIndex].CHO =
          (alimento.data.carboidrato / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].PTN =
          (alimento.data.proteina / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].LIP =
          (alimento.data.lipideos / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].KCAL =
          (alimento.data.kcal / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].SOD =
          (alimento.data.sodio / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].FIB =
          (alimento.data.fibra_alimentar / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].COL =
          (alimento.data.colesterol / 100) * gramas;

        for (var d = 0; d < this.demaisCodigos.length; d++) {
          this.receita.alimentosList[alimentoIndex][this.demaisCodigos[d]] =
            (alimento.data[this.demaisCodigos[d]] / 100) * gramas;
        }

        // to keep the values always clean even if the user change only one of the items
        this.receita.CHO = 0;
        this.receita.PTN = 0;
        this.receita.LIP = 0;
        this.receita.KCAL = 0;
        this.receita.SOD = 0;
        this.receita.FIB = 0;
        this.receita.COL = 0;
        for (var e = 0; e < this.demaisCodigos.length; e++) {
          this.receita[this.demaisCodigos[e]] = 0;
        }

        this.receita.alimentosList.forEach((item) => {
          this.receita.CHO += item.CHO;
          this.receita.LIP += item.LIP;
          this.receita.PTN += item.PTN;
          this.receita.KCAL += item.KCAL;
          this.receita.SOD += item.SOD;
          this.receita.FIB += item.FIB;
          this.receita.COL += item.COL;
          for (var f = 0; f < this.demaisCodigos.length; f++) {
            this.receita[this.demaisCodigos[f]] += item[this.demaisCodigos[f]];
          }
        });
      }

      this.$store.dispatch("firestore/receitaUpdate", {
        receita: this.receita,
      });
    },
    onGrChange(alimentoIndex, alimentoId, gramas) {
      var alimento = this.alimentos.find((item) => item.value == alimentoId);
      if (alimento !== undefined) {
        this.receita.alimentosList[alimentoIndex].nome = alimento.data.nome;
        this.receita.alimentosList[alimentoIndex].medida = "";
        this.receita.alimentosList[alimentoIndex].quantidade = null;

        this.receita.alimentosList[alimentoIndex].CHO =
          (alimento.data.carboidrato / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].PTN =
          (alimento.data.proteina / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].LIP =
          (alimento.data.lipideos / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].KCAL =
          (alimento.data.kcal / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].SOD =
          (alimento.data.sodio / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].COL =
          (alimento.data.colesterol / 100) * gramas;
        this.receita.alimentosList[alimentoIndex].FIB =
          (alimento.data.fibra_alimentar / 100) * gramas;
        for (var g = 0; g < this.demaisCodigos.length; g++) {
          this.receita.alimentosList[alimentoIndex][this.demaisCodigos[g]] =
            (alimento.data[this.demaisCodigos[g]] / 100) * gramas;
        }

        // to keep the values always clean even if the user change only one of the items
        this.receita.CHO = 0;
        this.receita.PTN = 0;
        this.receita.LIP = 0;
        this.receita.KCAL = 0;
        this.receita.SOD = 0;
        this.receita.FIB = 0;
        this.receita.COL = 0;
        for (var i = 0; i < this.demaisCodigos.length; i++) {
          this.receita[this.demaisCodigos[i]] = 0;
        }

        this.receita.alimentosList.forEach((item) => {
          this.receita.CHO += item.CHO;
          this.receita.LIP += item.LIP;
          this.receita.PTN += item.PTN;
          this.receita.KCAL += item.KCAL;
          this.receita.SOD += item.SOD;
          this.receita.FIB += item.FIB;
          this.receita.COL += item.COL;
          for (var j = 0; j < this.demaisCodigos.length; j++) {
            this.receita[this.demaisCodigos[j]] += item[this.demaisCodigos[j]];
          }
        });
      }

      this.$store.dispatch("firestore/receitaUpdate", {
        receita: this.receita,
      });
    },
    addAlimento() {
      this.receita.alimentosList.push({
        alimentoId: "",
        medida: "",
        quantidade: 0,
        gramas: 0,
        KCAL: 0,
        CHO: 0,
        PTN: 0,
        LIP: 0,
        FIB: 0,
        SOD: 0,
        COL: 0,
        fibra_soluvel: 0,
        fibra_insoluvel: 0,
        cinzas: 0,
        calcio: 0,
        magnesio: 0,
        manganes: 0,
        fosforo: 0,
        ferro: 0,
        potassio: 0,
        cobre: 0,
        zinco: 0,
        retinol: 0,
        re: 0,
        era: 0,
        iodo: 0,
        selenio: 0,
        acido_folico: 0,
        acido_pantotenico: 0,
        tiamina: 0,
        riboflavina: 0,
        piridoxina: 0,
        niacina: 0,
        vitamina_c: 0,
        vitamina_d: 0,
        vitamina_e: 0,
        vitamina_b12: 0,
        saturados: 0,
        monoinsaturados: 0,
        poliinsaturados: 0,
        gordura_trans: 0,
        triptofano: 0,
        treonina: 0,
        isoleucina: 0,
        leucina: 0,
        lisina: 0,
        metionina: 0,
        cistina: 0,
        fenilalanina: 0,
        tirosina: 0,
        valina: 0,
        arginina: 0,
        histidina: 0,
        alanina: 0,
        acido_aspartico: 0,
        acido_glutamico: 0,
        glicina: 0,
        prolina: 0,
        serina: 0,
        umidade: 0,
      });
    },
    removeAlimento(alimentoToRemove) {
      var alimento = this.alimentos.find(
        (item) => item.value === alimentoToRemove.alimentoId
      );
      if (alimento !== undefined) {
        this.receita.KCAL -= alimento.data.kcal;
        this.receita.CHO -= alimento.data.carboidrato;
        this.receita.PTN -= alimento.data.proteina;
        this.receita.COL -= alimento.data.colesterol;
        this.receita.SOD -= alimento.data.sodio;
        this.receita.FIB -= alimento.data.fibra_alimentar;
        this.receita.LIP -= alimento.data.lipideos;
        for (var k = 0; k < this.demaisCodigos.length; k++) {
          this.receita[this.demaisCodigos[k]] -=
            alimento.data[this.demaisCodigos[k]];
        }
      }

      this.receita.alimentosList.splice(
        this.receita.alimentosList.indexOf(alimentoToRemove),
        1
      );

      // to keep the values always clean even if the user change only one of the items
      this.receita.CHO = 0;
      this.receita.PTN = 0;
      this.receita.LIP = 0;
      this.receita.KCAL = 0;
      this.receita.SOD = 0;
      this.receita.FIB = 0;
      this.receita.COL = 0;
      for (var l = 0; l < this.demaisCodigos.length; l++) {
        this.receita[this.demaisCodigos[l]] = 0;
      }

      this.receita.alimentosList.forEach((item) => {
        this.receita.CHO += item.CHO;
        this.receita.LIP += item.LIP;
        this.receita.PTN += item.PTN;
        this.receita.KCAL += item.KCAL;
        this.receita.SOD += item.SOD;
        this.receita.FIB += item.FIB;
        this.receita.COL += item.COL;
        for (var m = 0; m < this.demaisCodigos.length; m++) {
          this.receita[this.demaisCodigos[m]] += item[this.demaisCodigos[m]];
        }
      });

      this.$store.dispatch("firestore/receitaUpdate", {
        receita: this.receita,
      });
    },
    substituteAlimento(alimento) {
      console.log(alimento);
    },
  },
};
</script>