<template>
  <div>
    <v-card outlined style="background-color: rgba(97, 64, 33, 0.2)">
      <v-card-title class="d-flex justify-center">
        Avaliação gratuita
      </v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-card-title justify-center text-subtitle-1
            >Dados pessoais</v-card-title
          >
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="5">
                <v-text-field
                  :prepend-icon="icons.mdiAccount"
                  v-model="nome"
                  :rules="rules.requiredField"
                  label="Nome"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="2">
                <v-radio-group v-model="sexo" column>
                  <v-radio
                    label="feminino"
                    value="feminino"
                    color="#ff00ff"
                  ></v-radio>
                  <v-radio
                    label="masculino"
                    value="masculino"
                    color="#0080ff"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6" md="2">
                <v-checkbox
                  v-model="gestante"
                  label="gestante"
                  color="#c5d13f"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  ref="menu"
                  v-model="menuDataNascimento"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="displayDataNasc"
                      label="Data de Nascimento"
                      :prepend-icon="icons.mdiCalendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    locale="pt-br"
                    v-model="dataNascimento"
                    :max="new Date().toISOString().substring(0, 10)"
                    min="1920-01-01"
                    @change="save"
                    color="#614021"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="4">
                <v-text-field
                  :prepend-icon="icons.mdiEmail"
                  v-model="email"
                  :rules="rules.emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :prepend-icon="icons.mdiPhone"
                  v-model="telefone"
                  label="Telefone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :prepend-icon="icons.mdiWhatsapp"
                  v-model="whatsapp"
                  label="WhatsApp"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined style="background-color: #e0e0e0">
          <v-card-title> IMC </v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="4" class="d-flex align-center">
                <v-row>
                  <v-col cols="6">
                    <small
                      >Peso em kg. Por exemplo: "80", sem vírgula ou
                      ponto</small
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :prepend-icon="icons.mdiWeightKilogram"
                      type="number"
                      v-model="pesoAtual"
                      label="peso atual (kg)?"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4" class="d-flex align-center">
                <v-row>
                  <v-col cols="6">
                    <small
                      >Altura em cm. Por exemplo: "177", sem vírgula ou
                      ponto</small
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :prepend-icon="icons.mdiHumanMaleHeightVariant"
                      v-model="altura"
                      type="number"
                      label="altura (cm)"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="d-flex justify-center align-center text-h6"
              >
                <v-row>
                  <v-col cols="6" class="d-flex justify-end">
                    IMC: {{ bmi }}
                  </v-col>
                  <v-col cols="6" class="d-flex justify-start">
                    <v-icon :color="bmiColor">mdi-alert-circle</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- <v-card outlinded style="background-color: #ffffff"> -->
        <v-expansion-panels class="mb-4" style="background-color: #ffffff">
          <v-expansion-panel v-model="panel0">
            <v-expansion-panel-header>
              <v-card-title> Questionário de saúde </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center">
                  <v-icon color="grey" left class="mx-4">mdi-liquor</v-icon>
                  Faz uso de bebida alcóolica (cervejas, destilados, outros)?
                  <v-radio-group
                    v-model="questSaude.bebAlc"
                    column
                    class="mx-4"
                  >
                    <v-radio label="Sim" value="Sim" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="Não" color="#008000"></v-radio>
                  </v-radio-group>
                  <v-divider
                    vertical
                    v-if="questSaude.bebAlc == 'Sim'"
                  ></v-divider>
                  <div v-if="questSaude.bebAlc == 'Sim'">
                    <v-row class="d-flex justify-center align-center ml-8">
                      Se bebe, com quê frequência?
                      <v-radio-group
                        v-model="questSaude.bebAlcFreq"
                        column
                        class="mx-4"
                      >
                        <v-radio
                          label="Reuniões sociais"
                          value="Reuniões sociais"
                          color="#ffff1f"
                        ></v-radio>
                        <v-radio
                          label="Finais de semana"
                          value="Finais de semana"
                          color="#ff8000"
                        ></v-radio>
                        <v-radio
                          label="Diariamente"
                          value="Diariamente"
                          color="#660000"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>
                  </div>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center">
                  <v-icon class="mx-4" color="grey" left>mdi-smoking</v-icon>
                  Fuma?
                  <v-radio-group v-model="questSaude.fuma" column class="mx-4">
                    <v-radio label="Sim" value="Sim" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="Não" color="#008000"></v-radio>
                  </v-radio-group>
                  <v-divider
                    vertical
                    v-if="questSaude.fuma == 'Sim'"
                  ></v-divider>
                  <div v-if="questSaude.fuma == 'Sim'">
                    <v-row class="d-flex justify-center align-center ml-8">
                      Se fumante, tem vontade de parar de fumar?
                      <v-radio-group
                        v-model="questSaude.fumaParar"
                        column
                        class="mx-4"
                      >
                        <v-radio
                          label="Sim"
                          value="Sim"
                          color="#FFFF1F"
                        ></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="#660000"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>
                  </div>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center">
                  <v-icon class="mx-4" color="grey" left
                    >mdi-weight-lifter</v-icon
                  >
                  Pratica alguma atividade física (Exceto às atividades
                  relacionadas à rotina diária de trabalho)?
                  <v-radio-group
                    v-model="questSaude.atiFis"
                    column
                    class="mx-4"
                  >
                    <v-radio label="Sim" value="Sim" color="#008000"></v-radio>
                    <v-radio label="Não" value="Não" color="#ff0000"></v-radio>
                  </v-radio-group>
                </v-row>
                <div v-if="questSaude.atiFis == 'Sim'">
                  <v-row class="d-flex justify-center align-center">
                    Se você pratica atividade física, com que frequência?
                    <v-radio-group
                      v-model="questSaude.atiFisFreq"
                      column
                      class="mx-4"
                    >
                      <v-radio
                        label="1 vez por semana ou menos"
                        value="1 vez por semana ou menos"
                        color="#ff8000"
                      ></v-radio>
                      <v-radio
                        label="2 vezes por semana"
                        value="2 vezes por semana"
                        color="#ffff1f"
                      ></v-radio>
                      <v-radio
                        label="3 ou mais vezes por semana"
                        value="3 ou mais vezes por semana"
                        color="#008000"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                </div>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center">
                  <v-icon class="mx-4" color="grey" left>mdi-blood-bag</v-icon>
                  Tem pressão alta?
                  <v-radio-group
                    v-model="questSaude.press"
                    column
                    class="ml-4 mr-8"
                  >
                    <v-radio label="Sim" value="Sim" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="Não" color="#008000"></v-radio>
                    <v-radio
                      label="Não sei"
                      value="Não sei"
                      color="#660000"
                    ></v-radio>
                  </v-radio-group>
                  Faz controle de pressão?
                  <v-radio-group
                    v-model="questSaude.pressCtrl"
                    column
                    class="ml-4 mr-8"
                  >
                    <v-radio label="Sim" value="Sim" color="#008000"></v-radio>
                    <v-radio label="Não" value="Não" color="#660000"></v-radio>
                  </v-radio-group>
                  <div v-if="questSaude.press == 'Sim'">
                    <v-row class="d-flex justify-center align-center ml-8">
                      Usa medicação para controle?
                      <v-radio-group
                        v-if="
                          questSaude.press == null || questSaude.press == 'Não'
                        "
                        v-model="questSaude.pressMed"
                        column
                        class="ml-4"
                      >
                        <v-radio
                          label="Sim"
                          value="Sim"
                          color="#ffff1f"
                        ></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="#008000"
                        ></v-radio>
                      </v-radio-group>
                      <v-radio-group
                        v-else
                        v-model="questSaude.pressMed"
                        column
                        class="ml-4"
                      >
                        <v-radio
                          label="Sim"
                          value="Sim"
                          color="#ffff1f"
                        ></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="#660000"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>
                  </div>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center">
                  <v-icon class="mx-4" color="grey" left
                    >mdi-heart-flash</v-icon
                  >
                  Já sofreu de algum problema no coração como infarto, angina ou
                  doença das coronárias?
                  <v-radio-group
                    v-model="questSaude.coracao"
                    column
                    class="ml-4"
                  >
                    <v-radio label="Sim" value="Sim" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="Não" color="#008000"></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center mt-4">
                  <v-icon class="mx-4" color="grey" left
                    >mdi-water-alert</v-icon
                  >
                  Sua taxa de colesterol e/ou triglicérides é alta?
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-radio-group v-model="questSaude.trigl" row class="ml-4">
                    <v-radio label="Sim" value="Sim" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="Não" color="#008000"></v-radio>
                    <v-radio
                      label="No limite"
                      value="No limite"
                      color="#ffff1f"
                    ></v-radio>
                    <v-radio
                      label="Não sei"
                      value="Não sei"
                      color="#ffff1f"
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center">
                  <v-icon class="mx-4" color="grey" left
                    >mdi-spoon-sugar</v-icon
                  >
                  É diabético?
                  <v-radio-group
                    v-model="questSaude.diabet"
                    column
                    class="ml-4"
                  >
                    <v-radio label="Sim" value="Sim" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="Não" color="#008000"></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center mt-4">
                  <v-icon class="mx-4" color="grey" left>mdi-doctor</v-icon>
                  Você tem/teve alguma destas doenças, diagnosticada por um
                  médico?
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  Doença respiratória (DPOC, Asma, Bronquite):
                  <v-radio-group
                    v-model="questSaude.diagResp"
                    column
                    class="ml-4"
                  >
                    <v-radio label="Sim" value="Sim" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="Não" color="#008000"></v-radio>
                  </v-radio-group>
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <div class="d-flex align-center">
                    Depressão:
                    <v-radio-group
                      v-model="questSaude.diagDepr"
                      column
                      class="ml-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#008000"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="d-flex align-center ml-8">
                    Câncer:
                    <v-radio-group
                      v-model="questSaude.diagCanc"
                      column
                      class="ml-4"
                    >
                      <v-radio
                        label="Sim"
                        value="Sim"
                        color="#ff0000"
                      ></v-radio>
                      <v-radio
                        label="Não"
                        value="Não"
                        color="#008000"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center">
                  <v-icon class="mx-4" color="grey" left
                    >mdi-emoticon-happy-outline</v-icon
                  >
                  Está satisfeito com sua saúde?
                  <v-radio-group
                    v-model="questSaude.satisSaude"
                    column
                    class="ml-4"
                  >
                    <v-radio label="Sim" value="Sim" color="#008000"></v-radio>
                    <v-radio label="Não" value="Não" color="#ff0000"></v-radio>
                  </v-radio-group>
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-icon class="mx-4" color="grey" left>mdi-leaf</v-icon>
                  Está satisfeito com sua qualidade de vida?
                  <v-radio-group
                    v-model="questSaude.satisVida"
                    column
                    class="ml-4"
                  >
                    <v-radio label="Sim" value="Sim" color="#008000"></v-radio>
                    <v-radio label="Não" value="Não" color="#ff0000"></v-radio>
                  </v-radio-group>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- </v-card> -->
          <!-- <v-card outlinded style="background-color: #e0e0e0"> -->
          <v-expansion-panel v-model="panel1" style="background-color: #e0e0e0">
            <v-expansion-panel-header>
              <v-card-title>
                Questionário de comportamento alimentar
              </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center mt-4">
                  <v-icon color="grey" left class="mx-4"
                    >mdi-account-eye</v-icon
                  >
                  Autoconsciência
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-radio-group v-model="questCompo.one" column>
                    <v-radio
                      value="5"
                      color="#008000"
                      label="Eu não me sinto consciente do meu peso ou tamanho corporal quando estou com outros."
                    ></v-radio>
                    <v-radio
                      value="3"
                      color="#ffff1f"
                      label="Sinto-me preocupado com o meu aspecto para os outros, mas normalmente não me faz sentir desapontado comigo mesmo."
                    ></v-radio>
                    <v-radio
                      value="2"
                      color="#ff0000"
                      label="Fico consciente da minha aparência e do meu peso, o que me faz sentir desapontado comigo mesmo."
                    ></v-radio>
                    <v-radio
                      value="1"
                      color="#660000"
                      label="Sinto vergonha e repugnância intensa por mim mesmo. Tento evitar contatos sociais por causa da minha autoconsciência."
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center mt-4">
                  <v-icon color="grey" left class="mx-4"
                    >mdi-account-clock</v-icon
                  >
                  Velocidade
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-radio-group v-model="questCompo.two" column>
                    <v-radio
                      value="5"
                      color="#008000"
                      label="Não tenho qualquer dificuldade em comer lentamente da maneira correcta."
                    ></v-radio>
                    <v-radio
                      value="3"
                      color="#ffff1f"
                      label='Embora eu pareça "devorar" alimentos, não acabo me sentindo recheado por causa de comer demais.'
                    ></v-radio>
                    <v-radio
                      value="2"
                      color="#ff0000"
                      label="Às vezes, tendo a comer rapidamente e, depois, sinto-me desconfortavelmente cheio."
                    ></v-radio>
                    <v-radio
                      value="1"
                      color="#660000"
                      label="Eu tenho o hábito de comer sem mastigar e normalmente sinto-me desconfortavelmente recheado porque já comi demais."
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center mt-4">
                  <v-icon color="grey" left class="mx-4"
                    >mdi-emoticon-angry</v-icon
                  >
                  Aborrecimento
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-radio-group v-model="questCompo.tre" column>
                    <v-radio
                      value="5"
                      color="#008000"
                      label="Eu não tenho o hábito de comer quando estou aborrecido."
                    ></v-radio>
                    <v-radio
                      value="3"
                      color="#ffff1f"
                      label='Às vezes como quando estou entediado, mas muitas vezes consigo "ficar ocupado" e tirar a minha mente da comida.'
                    ></v-radio>
                    <v-radio
                      value="2"
                      color="#ff0000"
                      label="Tenho o hábito de comer quando estou entediado, mas ocasionalmente, posso me distrair de comer."
                    ></v-radio>
                    <v-radio
                      value="1"
                      color="#660000"
                      label="Tenho um forte hábito de comer quando estou aborrecido. Nada parece ajudar-me a quebrar o hábito."
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center mt-4">
                  <v-icon color="grey" left class="mx-4"
                    >mdi-account-cancel</v-icon
                  >
                  Auto-ódio
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-radio-group v-model="questCompo.for" column>
                    <v-radio
                      value="5"
                      color="#008000"
                      label="Não sinto qualquer culpa ou ódio por mim próprio depois de comer demais."
                    ></v-radio>
                    <v-radio
                      value="2"
                      color="#ffff1f"
                      label="Depois de comer demais, às vezes sinto culpa ou auto-ódio."
                    ></v-radio>
                    <v-radio
                      value="1"
                      color="#660000"
                      label="Quase sempre sinto uma forte culpa ou auto-ódio depois de ter comido demais."
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center mt-4">
                  <v-icon color="grey" left class="mx-4">mdi-sine-wave</v-icon>
                  Irregularidades
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-radio-group v-model="questCompo.fiv" column>
                    <v-radio
                      value="5"
                      color="#008000"
                      label="O meu nível de consumo calórico não sobe muito alto ou desce muito baixo numa base regular."
                    ></v-radio>
                    <v-radio
                      value="3"
                      color="#ffff1f"
                      label="As vezes, depois de comer em excesso, vou tentar reduzir a minha ingestão calórica a quase nada para compensar."
                    ></v-radio>
                    <v-radio
                      value="2"
                      color="#ff0000"
                      label="Eu tenho o hábito regular de comer em excesso durante a noite. Parece que a minha rotina não é ter fome de manhã, mas comer em excesso à noite."
                    ></v-radio>
                    <v-radio
                      value="1"
                      color="#660000"
                      label='Tive períodos em que praticamente morri de fome e períodos em que comi em excesso. Parece que vivo uma vida de "festa ou de fome".'
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center mt-4">
                  <v-icon color="grey" left class="mx-4"
                    >mdi-account-tie-hat</v-icon
                  >
                  Vigiliança
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-radio-group v-model="questCompo.six" column>
                    <v-radio
                      value="5"
                      color="#008000"
                      label="Não penso muito em tentar controlar os impulsos alimentares indesejados."
                    ></v-radio>
                    <v-radio
                      value="3"
                      color="#ffff1f"
                      label="Pelo menos em parte do tempo, sinto que os meus pensamentos estão preocupados em tentar controlar os meus impulsos alimentares."
                    ></v-radio>
                    <v-radio
                      value="2"
                      color="#ff0000"
                      label="Sinto que frequentemente passo muito tempo a pensar no quanto comi ou em tentar não comer mais."
                    ></v-radio>
                    <v-radio
                      value="1"
                      color="#660000"
                      label="Parece-me que a maior parte das minhas horas de vigília são pré- ocupadas por pensamentos sobre comer ou não comer. "
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center mt-4">
                  <v-icon color="grey" left class="mx-4"
                    >mdi-account-search</v-icon
                  >
                  Autopercepção
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-radio-group v-model="questCompo.sev" column>
                    <v-radio
                      value="5"
                      color="#008000"
                      label="Eu normalmente sei se tenho fome ou não. Como a porção certa de comida para me satisfazer."
                    ></v-radio>
                    <v-radio
                      value="2"
                      color="#ffff1f"
                      label="Ocasionalmente, sinto-me insegura se tenho fome ou não. Acho difícil saber quanta comida devo tomar para me satisfazer."
                    ></v-radio>
                    <v-radio
                      value="1"
                      color="#660000"
                      label='Embora eu possa saber quantas calorias devo comer, não faço ideia do que é uma quantidade "normal" de comida para mim.'
                    ></v-radio>
                  </v-radio-group>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- </v-card>
        <v-card outlinded style="background-color: #ffffff"> -->
          <v-expansion-panel v-model="panel2">
            <v-expansion-panel-header>
              <v-card-title> Questionário de satisfação estética </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center">
                  Sente-se inchada?
                  <v-radio-group v-model="questEstet.enx" column class="ml-4">
                    <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="1" color="#008000"></v-radio>
                  </v-radio-group>
                  <v-divider vertical class="mx-8"></v-divider>
                  Você tem dificuldade de fechar roupas que antigamente fecharam
                  ?
                  <v-radio-group v-model="questEstet.fec" column class="ml-4">
                    <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="1" color="#008000"></v-radio>
                  </v-radio-group>
                </v-row>
                <v-divider></v-divider>

                <v-row class="d-flex justify-center align-center">
                  Você tem vergohna de usar roupas de praia?
                  <v-radio-group v-model="questEstet.rou" column class="mx-4">
                    <v-radio
                      label="Sim, não me sinto comfortável em roupas de praia"
                      value="0"
                      color="#ff0000"
                    ></v-radio>
                    <v-radio
                      label="Não, estou feliz com a minha forma atual"
                      value="1"
                      color="#008000"
                    ></v-radio>
                  </v-radio-group>
                </v-row>

                <v-divider></v-divider>

                <v-row class="d-flex justify-center align-center">
                  Você tem flacidez de pele em alguma área do corpo?
                  <v-radio-group v-model="questEstet.fla" column class="mx-4">
                    <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="1" color="#008000"></v-radio>
                  </v-radio-group>
                </v-row>

                <v-divider></v-divider>

                <v-row class="d-flex justify-center align-center">
                  Você tem um acúmulo de gordura localizada em alguma área do
                  corpo?
                  <v-radio-group v-model="questEstet.gor" column class="mx-4">
                    <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="1" color="#008000"></v-radio>
                  </v-radio-group>
                </v-row>

                <v-divider></v-divider>

                <v-row class="d-flex justify-center align-center">
                  Você tem celulite em alguma área do corpo?
                  <v-radio-group v-model="questEstet.cel" column class="mx-4">
                    <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="1" color="#008000"></v-radio>
                  </v-radio-group>
                </v-row>

                <v-divider></v-divider>
                <v-row class="d-flex justify-center align-center">
                  Você precisa de definição corporal?
                  <v-radio-group v-model="questEstet.def" column class="ml-4">
                    <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="1" color="#008000"></v-radio>
                  </v-radio-group>
                  <v-divider vertical class="mx-8"></v-divider>
                  Você precisa melhorar o tônus muscular?
                  <v-radio-group v-model="questEstet.ton" column class="ml-4">
                    <v-radio label="Sim" value="0" color="#ff0000"></v-radio>
                    <v-radio label="Não" value="1" color="#008000"></v-radio>
                  </v-radio-group>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- </v-card> -->
        <v-card outlinded style="background-color: #e0e0e0">
          <v-card-title> Avaliação </v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-space-between align-center">
              <v-col cols="4" class="d-flex justify-end">IMC: </v-col>
              <v-col cols="4" class="d-flex justify-center">
                <v-progress-linear
                  v-model="bmiPerc"
                  :color="bmiColor"
                ></v-progress-linear>
              </v-col>
              <v-col cols="4" class="d-flex justify-start">{{ bmi }}</v-col>
            </v-row>
            <v-row class="d-flex justify-space-between align-center">
              <v-col cols="4" class="d-flex justify-end">Saúde: </v-col>
              <v-col cols="4" class="d-flex justify-center">
                <v-progress-linear
                  v-model="avSaude"
                  :color="colorPerc(avSaude)"
                ></v-progress-linear>
              </v-col>
              <v-col cols="4" class="d-flex justify-start"
                >{{ avSaude }}%</v-col
              >
            </v-row>
            <v-row class="d-flex justify-space-between align-center">
              <v-col cols="4" class="d-flex justify-end"
                >Comportamento alimentar:
              </v-col>
              <v-col cols="4" class="d-flex justify-center">
                <v-progress-linear
                  v-model="avCompo"
                  :color="colorPerc(avCompo)"
                ></v-progress-linear>
              </v-col>
              <v-col cols="4" class="d-flex justify-start"
                >{{ avCompo }}%</v-col
              >
            </v-row>
            <v-row class="d-flex justify-space-between align-center">
              <v-col cols="4" class="d-flex justify-end">Estética: </v-col>
              <v-col cols="4" class="d-flex justify-center">
                <v-progress-linear
                  v-model="avEstet"
                  :color="colorPerc(avEstet)"
                ></v-progress-linear>
              </v-col>
              <v-col cols="4" class="d-flex justify-start"
                >{{ avEstet }}%</v-col
              >
            </v-row>
            <v-row class="d-flex justify-space-between align-center">
              <v-col cols="2" class="d-flex justify-end"></v-col>
              <v-col cols="8" class="d-flex justify-center">
                <v-textarea solo v-model="avEscrita"></v-textarea>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined style="background-color: rgba(197, 209, 63, 0.6)">
          <v-card-title> Sugestão de tratamento </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="0" md="1"></v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  label="Tipo de tratamento"
                  :items="getItems"
                  v-model="mov.tipo"
                  class="mt-5"
                  single-line
                  solo
                  style="width: 100%"
                  @change="changeTratSugg(mov.tipo)"
                  no-data-text="..."
                ></v-autocomplete> </v-col
              ><v-col cols="0" md="1"></v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="12" class="d-flex justify-center text-h6">
                {{ niceString(mov.valor) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" class="d-flex align-center justify-end">
                <v-checkbox
                  v-model="descontoAct"
                  label="Desconto"
                  color="#614021"
                  @change="descontoImpact"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label='desconto em R$, ex.: "100"'
                  single-line
                  :solo="descontoAct"
                  class="mt-3 mx-3"
                  style="width: 100%"
                  v-model="mov.desconto"
                  @change="adjustValorDesc"
                  :disabled="!descontoAct"
                  type="number"
                ></v-text-field> </v-col
              ><v-col cols="12" md="3"></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  color="#614021"
                  class="white--text"
                  large
                  @click="downloadAv"
                >
                  <v-icon color="#c5d13f" left>mdi-content-save</v-icon>
                  Salvar e baixar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <!-- <v-card class="d-flex justify-center">
      <v-card-title>Em desenvolvimento</v-card-title>
    </v-card> -->
  </div>
</template>

<script>
import {
  mdiEmail,
  mdiAccount,
  mdiPhone,
  mdiWhatsapp,
  mdiCalendar,
  mdiWeightKilogram,
  mdiHumanMaleHeightVariant,
} from "@mdi/js";
import firebase from "firebase";
import jspdf from "jspdf";
import "jspdf-autotable";

export default {
  data: function () {
    return {
      menuDataNascimento: false,
      displayDataNasc: "",
      panel0: [0],
      panel1: [0],
      panel2: [0],
      nome: null,
      sexo: true,
      gestante: false,
      dataNascimento: null,
      email: null,
      telefone: null,
      whatsapp: null,
      pesoAtual: null,
      altura: null,
      valor: 0,
      avEscrita: "...",
      questSaude: {
        bebAlc: null,
        bebAlcFreq: null,
        fuma: null,
        fumaParar: null,
        atiFis: null,
        atiFisFreq: null,
        press: null,
        pressCtrl: null,
        pressMed: null,
        coracao: null,
        trigl: null,
        diabet: null,
        diagResp: null,
        diagCanc: null,
        diagDepr: null,
        satisSaude: null,
        satisVida: null,
      },
      questCompo: {
        one: null,
        two: null,
        tre: null,
        for: null,
        fiv: null,
        six: null,
        sev: null,
      },
      questEstet: {
        enx: null,
        fec: null,
        cel: null,
        gor: null,
        fla: null,
        rou: null,
        def: null,
        ton: null,
      },
      vendaItems: [
        // {
        //   nome: "1 consulta nutricional patrocinada",
        //   valor: { balcam: 0, fortal: 0, belemx: 0 },
        //   transactModus: ["none"],
        //   duracao: 30, //acessoTipo acessoFim
        // },
        {
          nome: "1 consulta nutricional Crédito / Débito",
          valor: { balcam: 330, fortal: 300, belemx: 350 },
          transactModus: ["Cartão de Crédito", "Cartão de Débito"],
          duracao: 30,
        },
        {
          nome: "1 consulta nutricional PIX / Transferência",
          valor: { balcam: 330, fortal: 300, belemx: 350 },
          transactModus: ["PIX", "Transferência Bancária"],
          duracao: 30,
        },
        // !!!!
        {
          nome: "1 consulta nutricional (plano família, mínimo 2 pessoas)",
          valor: { balcam: 250, fortal: 250, belemx: 300 },
          transactModus: ["any"],
          duracao: 30,
        },
        // !!!!
        {
          nome: "1 consulta nutricional SEM RETORNO",
          valor: { balcam: 180, fortal: 180, belemx: 200 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Programa Clínico Funcional", //"1 programa BÁSICO NUTRICIONAL (90 dias)",
          valor: { balcam: 900, fortal: 900, belemx: 990 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Clínico Funcional (plano família)", // "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)",
          valor: { balcam: 810, fortal: 810, belemx: 810 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Clínico Funcional com Estética", // "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)",
          valor: { balcam: 1400, fortal: 1400, belemx: 1400 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Modulação Intestinal", //"1 programa MODULAÇÃO INTESTINAL (90 dias)",
          valor: { balcam: 990, fortal: 990, belemx: 1100 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Modulação Intestinal (plano família)", //"1 programa de MODULAÇÃO INTESTINAL plano família (90 dias)",
          valor: { balcam: 900, fortal: 900, belemx: 900 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa Modulação Intestinal com Estética", // "1 programa de BÁSICO NUTRICIONAL plano família (90 dias)",
          valor: { balcam: 1490, fortal: 1490, belemx: 1490 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S Online", //"1 programa de 3 consultas + 3 meses de Estética (1 área)",
          valor: { balcam: 990, fortal: 990, belemx: 990 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S", //"1 programa de 3 consultas + 3 meses de Estética (1 área)",
          valor: { balcam: 990, fortal: 990, belemx: 990 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S com Estética", //"1 programa de 3 consultas + 3 meses de Estética (1 área)",
          valor: { balcam: 1490, fortal: 1490, belemx: 1490 },
          transactModus: ["any"],
          duracao: 12 * 7,
        },
        {
          nome: "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)",
          valor: { balcam: 1990, fortal: 1990, belemx: 1990 },
          transactModus: ["any"],
          duracao: 12 * 7,
          incl:
            "Durante 12 semanas: 3 consultas nutricionais + dietas personalizadas + prescrição de supplementos + video-aulas + livro/diário do programa + exame nutrigenético",
        },
        {
          nome: "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)",
          valor: { balcam: 2190, fortal: 2190, belemx: 2190 },
          transactModus: ["any"],
          duracao: 12 * 7,
          incl:
            "Durante 12 semanas: 3 consultas nutricionais + dietas personalizadas + prescrição de supplementos + video-aulas + livro/diário do programa + exame nutrigenético",
        },
        {
          nome: "Power Shape 30",
          valor: { balcam: 560, fortal: 560, belemx: 560 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Glúteo de Ouro",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Lipodraining 24H",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Detox + Lipodraining 24H",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Protocolo Harmonização Corporal 24H",
          valor: { balcam: 720, fortal: 720, belemx: 720 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Pacote Estético",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "1 sessão estética avulsa",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "1 exame de bioimpedância",
          valor: { balcam: 100, fortal: 100, belemx: 100 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame de Intolerância e Hipersensibilidade alimentar",
          valor: { balcam: 1910, fortal: 1910, belemx: 1910 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame de Microbioma Intestinal",
          valor: { balcam: 1970, fortal: 1970, belemx: 1970 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame Nutrigenético (DNA equilibrio) (a vista)",
          valor: { balcam: 1350, fortal: 1350, belemx: 1350 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame Nutrigenético (DNA equilibrio) (parcelado)",
          valor: { balcam: 1500, fortal: 1500, belemx: 1500 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "1 exame Genético Esportivo",
          valor: { balcam: 2760, fortal: 2760, belemx: 2760 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "Combo exames de Microbioma Intestinal + Hipersensibilidade alimentar + Nutrigenético",
          valor: { balcam: 5976, fortal: 5976, belemx: 5976 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "Protocolo Kirchhoff",
          valor: { balcam: 180, fortal: 180, belemx: 180 },
          transactModus: ["any"],
          duracao: 30,
        },
        {
          nome: "Pacote 90 dias Protocolo Kirchhoff",
          valor: { balcam: 590, fortal: 590, belemx: 590 },
          transactModus: ["any"],
          duracao: 90,
        },
        // {
        //   nome: "Giftcard",
        //   valor: { balcam: 0, fortal: 0, belemx: 0 },
        //   transactModus: ["any"],
        //   duracao: 90,
        // },
        {
          nome: "HydraGloss",
          valor: { balcam: 120, fortal: 120, belemx: 120 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Protocolo de Clareamento",
          valor: { balcam: 180, fortal: 180, belemx: 180 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Protocolo de Clareamento (Pacote de 4 sessões)",
          valor: { balcam: 600, fortal: 600, belemx: 600 },
          transactModus: ["any"],
          duracao: 1, //acessoTipo acessoFim
        },
        {
          nome: "Entrada 1ª Consulta",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 0,
        },
        {
          nome: "Outro",
          valor: { balcam: 0, fortal: 0, belemx: 0 },
          transactModus: ["any"],
          duracao: 0,
        },
      ],
      icons: {
        mdiAccount,
        mdiEmail,
        mdiPhone,
        mdiWhatsapp,
        mdiCalendar,
        mdiWeightKilogram,
        mdiHumanMaleHeightVariant,
      },
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
        emailRules: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+/.test(v) || "E-mail invalido",
          (v) => /^\S*$/.test(v) || "E-mail com espaço",
        ],
      },
      descontoAct: false,
      mov: {
        direction: true,
        tipo: null,
        descr: null,
        paciente: null,
        comprovante: null,
        valor: null,
        desconto: 0,
        soldObjs: [],
        pacotes: [
          {
            def1: null,
            def2: null,
            area1: null,
            area2: null,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            // index: ix,
          },
        ],
        forma: null,
      },
      preloadSignature: new Image(),
      preloadLogo: new Image(),
    };
  },
  computed: {
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
    bmiPerc() {
      var bmiI = this.bmi - 18.5;
      var bmiT;
      if (bmiI > 11.5) {
        bmiT = 11.5;
      } else {
        bmiT = bmiI;
      }
      var bmiP = (bmiT / 11.5) * 100;
      return Math.round(bmiP);
    },
    avSaude() {
      var NumbebAlc;
      var NumbebAlcFreq;
      var Numfuma;
      var NumfumaParar;
      var NumatiFis;
      var NumatiFisFreq;
      var Numpress;
      var NumpressCtrl;
      var NumpressMed;
      var Numcoracao;
      var Numtrigl;
      var Numdiabet;
      var NumdiagResp;
      var NumdiagCanc;
      var NumdiagDepr;
      var NumsatisSaude;
      var NumsatisVida;
      var substBebAlcFreq = 0;
      var substFumaParar = 0;
      var susbstAtiFisFreq = 0;
      var susbstPressMed = 0;

      if (this.questSaude.bebAlc == "Não") {
        NumbebAlc = 1;
        // this.questSaude.bebAlcFreq = null;
        substBebAlcFreq = 1;
        NumbebAlcFreq = 0;
      } else {
        NumbebAlc = 0;
        substBebAlcFreq = 0;
      }

      if (this.questSaude.bebAlcFreq == "Reuniões sociais") {
        NumbebAlcFreq = 1;
      } else {
        NumbebAlcFreq = 0;
      }

      if (this.questSaude.fuma == "Não") {
        Numfuma = 1;
        NumfumaParar = 0;
        // this.questSaude.fumaParar = null;
        substFumaParar = 1;
      } else {
        Numfuma = 0;
        substFumaParar = 0;
      }

      if (this.questSaude.fumaParar == "Sim") {
        NumfumaParar = 1;
      } else {
        NumfumaParar = 0;
      }

      if (this.questSaude.atiFis == "Sim") {
        NumatiFis = 2;
        susbstAtiFisFreq = 0;
      } else {
        NumatiFis = 0;
        // this.questSaude.atiFisFreq = null;
        susbstAtiFisFreq = 1;
        NumatiFisFreq = 0;
      }

      if (this.questSaude.atiFisFreq == "3 ou mais vezes por semana") {
        NumatiFisFreq = 1;
      } else if (this.questSaude.atiFisFreq == "2 vezes por semana") {
        NumatiFisFreq = 0.5;
      } else {
        NumatiFisFreq = 0;
      }

      if (this.questSaude.press == "Não") {
        Numpress = 1;
        // this.questSaude.pressMed = null;
        susbstPressMed = 1;
        NumpressMed = 0;
      } else if (this.questSaude.press == "Não sei") {
        Numpress = 0.5;
        // this.questSaude.pressMed = null;
        susbstPressMed = 1;
        NumpressMed = 0;
      } else {
        Numpress = 0;
        susbstPressMed = 0;
      }

      if (this.questSaude.pressCtrl == "Sim") {
        NumpressCtrl = 0.5;
      } else {
        NumpressCtrl = 0;
      }

      if (this.questSaude.press == "Sim" && this.questSaude.pressMed == "Sim") {
        NumpressMed = 0.5;
      } else {
        NumpressMed = 0;
      }

      if (this.questSaude.coracao == "Não") {
        Numcoracao = 1;
      } else {
        Numcoracao = 0;
      }

      if (this.questSaude.trigl == "Não") {
        Numtrigl = 1;
      } else {
        Numtrigl = 0;
      }

      if (this.questSaude.trigl == "Não") {
        Numdiabet = 1;
      } else {
        Numdiabet = 0;
      }

      if (this.questSaude.diagResp == "Não") {
        NumdiagResp = 1;
      } else {
        NumdiagResp = 0;
      }

      if (this.questSaude.diagCanc == "Não") {
        NumdiagCanc = 1;
      } else {
        NumdiagCanc = 0;
      }

      if (this.questSaude.diagDepr == "Não") {
        NumdiagDepr = 1;
      } else {
        NumdiagDepr = 0;
      }

      if (this.questSaude.satisSaude == "Sim") {
        NumsatisSaude = 0.5;
      } else {
        NumsatisSaude = 0;
      }

      if (this.questSaude.satisVida == "Sim") {
        NumsatisVida = 0.5;
      } else {
        NumsatisVida = 0;
      }

      var elementsSaudeNum = [
        NumbebAlc,
        NumbebAlcFreq,
        Numfuma,
        NumfumaParar,
        NumatiFis,
        NumatiFisFreq,
        Numpress,
        NumpressCtrl,
        NumpressMed,
        Numcoracao,
        Numtrigl,
        Numdiabet,
        NumdiagResp,
        NumdiagCanc,
        NumdiagDepr,
        NumsatisSaude,
        NumsatisVida,
      ];

      var sumM = 0;
      elementsSaudeNum.forEach(function (item) {
        sumM += item;
      });

      var substSum =
        substBebAlcFreq + susbstAtiFisFreq + substFumaParar + susbstPressMed;

      var pointsSaude = sumM;
      var totalSaude = 16.5 - substSum;
      var percSaudeExact = (pointsSaude / totalSaude) * 100;
      var percSaude = Math.round(percSaudeExact);

      return percSaude;
    },
    avCompo() {
      var oneNum = parseInt(this.questCompo.one);
      var twoNum = parseInt(this.questCompo.two);
      var treNum = parseInt(this.questCompo.tre);
      var forNum = parseInt(this.questCompo.for);
      var fivNum = parseInt(this.questCompo.fiv);
      var sixNum = parseInt(this.questCompo.six);
      var sevNum = parseInt(this.questCompo.sev);

      var compoNums = [oneNum, twoNum, treNum, forNum, fivNum, sixNum, sevNum];

      var compoSum = 0;
      compoNums.forEach(function (nr) {
        compoSum += parseFloat(nr);
      });

      var compoPoints = compoSum;
      var compoPerc = Math.round((compoPoints / 35) * 100);
      return compoPerc;
    },
    avEstet() {
      var enxNum = parseInt(this.questEstet.enx);
      var fecNum = parseInt(this.questEstet.fec);
      var celNum = parseInt(this.questEstet.cel);
      var gorNum = parseInt(this.questEstet.gor);
      var flaNum = parseInt(this.questEstet.fla);
      var rouNum = parseInt(this.questEstet.rou);
      var defNum = parseInt(this.questEstet.def);
      var tonNum = parseInt(this.questEstet.ton);

      var estetNums = [
        enxNum,
        fecNum,
        celNum,
        gorNum,
        flaNum,
        rouNum,
        defNum,
        tonNum,
      ];

      var estetSum = 0;
      estetNums.forEach(function (nr) {
        estetSum += parseFloat(nr);
      });

      var estetPoints = estetSum;
      var estetPerc = Math.round((estetPoints / 8) * 100);
      return estetPerc;
    },
    localCode() {
      if (
        sessionStorage.getItem("current-user") ==
        "umarizalbelem@clubedanutricionista.com.br"
      ) {
        return "belemx";
      } else if (
        sessionStorage.getItem("current-user") ==
        "bc@clubedanutricionista.com.br"
      ) {
        return "balcam";
      } else if (
        sessionStorage.getItem("current-user") ==
        "fortaleza.salinas@clubedanutricionista.com.br"
      ) {
        return "fortal";
      } else if (
        sessionStorage.getItem("current-user") == "thayanalucy@gmail.com"
      ) {
        return "belemx";
      } else {
        return "balcam";
      }
    },
    getItems() {
      return this.vendaItems.map(function (item) {
        return item.nome;
      });
    },
    bmi() {
      var bmi = this.pesoAtual / ((this.altura * this.altura) / 10000);

      return Math.round(bmi * 100) / 100;
    },
    bmiColor() {
      if (this.bmi < 18.5) {
        return "#ff0000";
      } else if (this.bmi > 18.5 && this.bmi < 25) {
        return "#209c05";
      } else if (this.bmi > 25 && this.bmi < 30) {
        return "#ffff00";
      } else if (this.bmi > 30 && this.bmi < 35) {
        return "#ff8000";
      } else if (this.bmi > 35) {
        return "#ff0000";
      } else {
        return "#c0c0c0";
      }
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
      var dStr = date.substring(8, 10);
      var mStr = date.substring(5, 7);
      var aStr = date.substring(0, 4);
      var niceDate = dStr + "/" + mStr + "/" + aStr;
      this.displayDataNasc = niceDate;
      console.log("this.dataNascimento", this.dataNascimento)
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async preloadImages() {
      console.log("this.funcionarioOnline.assinatura", this.funcionarioOnline.assinatura)
      if (
        this.funcionarioOnline.assinatura !== undefined &&
        this.funcionarioOnline.assinatura !== ""
      ) {
        this.toDataURL(this.funcionarioOnline.assinatura, (data) => {
          this.preloadSignature.src = data;
        });
      } else {
        this.preloadSignature.src = require("@/assets/signature_none.jpg");
      }

      if (this.isFranquia) {
        this.preloadLogo.src = require("@/assets/logoCompleto.png");
      } else if (this.userInfo.profilePicture !== undefined && this.userInfo.profilePicture !== "" && !this.isFranquia) {
        this.toDataURL(this.userInfo.profilePicture, (data) => {
          this.preloadLogo.src = data;
        });
      } else {
        this.preloadLogo.src = require("@/assets/signature_none.jpg");
      }

      this.preloadLogo.onload = function () {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function () {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    colorPerc(perc) {
      if (perc <= 25) {
        return "#660000";
      } else if (perc > 25 && perc <= 50) {
        return "#ff0000";
      } else if (perc > 50 && perc <= 75) {
        return "#ff8000";
      } else if (perc > 75 && perc <= 88) {
        return "#ffff1f";
      } else if (perc > 88 && perc <= 95) {
        return "#cccc00";
      } else if (perc > 95) {
        return "#008000";
      } else {
        return "#c0c0c0";
      }
    },
    async downloadAv() {
      var ref = firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("avGratuita")
        .doc();

      var id = ref.id;
      var d = new Date();
      var millis = d.getTime();
      var day = d.getDate();
      var month = d.getMonth() + 1;
      var year = d.getFullYear();
      var dayStr;
      if (day > 9) {
        dayStr = day.toString();
      } else {
        dayStr = "0" + day.toString();
      }

      var monthStr;
      if (month > 9) {
        monthStr = month.toString();
      } else {
        monthStr = "0" + month.toString();
      }

      var yearStr = year.toString();

      var dateStr = dayStr + "/" + monthStr + "/" + yearStr;

      var avDoc = {
        id: id,
        mov: this.mov,
        nome: this.nome,
        email: this.email,
        sexo: this.sexo,
        dataNascimento: this.dataNascimento,
        whatsapp: this.whatsapp,
        gestante: this.gestante,
        telefone: this.telefone,
        altura: this.altura,
        pesoAtual: this.pesoAtual,
        bmi: this.bmi,
        bmiPerc: this.bmiPerc,
        questSaude: this.questSaude,
        questCompo: this.questCompo,
        questEstet: this.questEstet,
        avSaude: this.avSaude,
        avCompo: this.avCompo,
        avEstet: this.avEstet,
        avEscrita: this.avEscrita,
        date: millis,
        dateStr: dateStr,
      };

      var avDocLigacoes = {
        nome: this.nome,
        email: this.email,
        sexo: this.sexo,
        dataNascimento: this.dataNascimento,
        whatsapp: this.whatsapp,
        gestante: this.gestante,
        telefone: this.telefone,
        objetivo: null,
        motivoRecusao: "Avaliação Gratuita",
        motivoRecusaoOutro: null,
        meioContato: null,
        detalhesImportantes: this.avEscrita,
        horarioLigacao: d.getHours().toString() + ":" + d.getMinutes().toString(),
        millis: millis,
        dia: day,
        mes: month,
        ano: year,
      }

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("avGratuita")
        .doc(id)
        .set(avDoc);

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("ligacoes")
        .doc(millis.toString())
        .set(avDocLigacoes);

      var doc = new jspdf("p");
      var fontSize = 12;
      doc.setFontSize(fontSize);
      doc.setFont("Times");
      var imgWidth = 40;
      // var imgHeight = imgWidth / (900 / 262);
      var imgHeight =
        imgWidth /
        (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 1.1 + 12;

      var centeredText = function (text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function (
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc.setFont("Roboto").splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };
      var createTexts = function(textList, centered = false, textStart = textStartYPos) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page
        
        for (var i = 0; i < textList.length; i++) {
            var lines = doc.setFont("Montserrat-Medium").splitTextToSize(textList[i], 180);
            
            for (var j = 0; j < lines.length; j++) {
                // Check if the current line would go past the threshold
                if (textStart > pageHeightThreshold) {
                    doc.addPage();
                    textStart = newYStart; // Reset the text start position for the new page
                }
                
                // Whether to center the text or not
                if (centered) {
                    centeredText(lines[j], textStart);
                } else {
                    doc.text(startXPos, textStart, lines[j]);
                }
                
                textStart += (fontSize / 2); // Increment the y position for each line of text
            }

            // Increment after each text block rather than each line
            if (!centered) {
                textStart += (fontSize / 2); // This accounts for the space after each block of text
            }
        }
        
        return textStart; // Return the updated text start position
    };

      var createTablesForAv = function (avText, startPosY) {
        var bodyAvText = [[avText]];

        doc.autoTable({
          startY: startPosY, //(lastPos / 2) * 0.7 + startPosY,
          startx: startXPos,
          head: [["Avaliação do nutricionista"]],
          body: bodyAvText,
        });
        startPosY = doc.previousAutoTable.finalY;
        return doc.previousAutoTable.finalY;
      };

      var descrTratamento = function (tipo) {
        if (tipo == "1 consulta nutricional patrocinada") {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (tipo == "1 consulta nutricional Crédito / Débito") {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (tipo == "1 consulta nutricional PIX / Transferência") {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (
          tipo == "1 consulta nutricional (plano família, mínimo 2 pessoas)"
        ) {
          return "Uma consulta nutricional + bioimpedância + cardápios personalizados + prescrição de supplementos + retorno após 15 dias";
        } else if (
          tipo == "1 consulta nutricional SEM RETORNO"
        ) {
          return "Uma consulta nutricional + cardápios personalizados + prescrição de supplementos";
        } else if (tipo == "Programa Clínico Funcional") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa Modulação Intestinal") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa Clínico Funcional com Estética") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas + tratamentos estéticos semanais";
        } else if (tipo == "Programa Modulação Intestinal com Estética") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas + tratamentos estéticos semanais";
        } else if (tipo == "Programa Clínico Funcional (plano família)") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa Modulação Intestinal (plano família)") {
          return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + video-aulas";
        } else if (tipo == "Programa 12S Online") {
          return "Durante 12 semanas: 3 consultas nutricionais + cardápios personalizados + prescrição de supplementos + video-aulas + livro/diário do programa";
          // } else if (tipo == "Programa 12S Plano Básico") {
          //   return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + tratamentos estéticos semanais + video-aulas + livro/diário do programa";
          // } else if (tipo == "Programa 12S Plano Premium") {
          //   return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + tratamentos estéticos semanais + video-aulas + livro/diário do programa";
          // } else if (tipo == "Programa 12S Plano VIP") {
          //   return "Durante 12 semanas: 3 consultas nutricionais + bioimpedância + cardápios personalizados + prescrição de supplementos + tratamentos estéticos semanais + video-aulas + livro/diário do programa";
        } else if (tipo == "Programa 12S") {
          return "Durante 12 semanas: 3 consultas nutricionais + cardápios personalizados + prescrição de supplementos + video-aulas + livro/diário do programa";
        } else if (tipo == "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)" || tipo == "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)") {
          return "Durante 12 semanas: 3 consultas nutricionais + cardápios personalizados + prescrição de supplementos + video-aulas + livro/diário do programa + exame nutrigenético";
        } else if (tipo == "Programa 12S com Estética") {
          return "Durante 12 semanas: 3 consultas nutricionais + cardápios personalizados + prescrição de supplementos + video-aulas + livro/diário do programa + tratamentos estéticos semanais";
        } else if (tipo == "Protocolo Harmonização Corporal 24H") {
          return "Durante 4 semanas: 1 consulta nutricional + massagem moduladora 2 vezes por semana";
        } else if (tipo == "Protocolo Glúteo de Ouro") {
          return "8 sessões: Peeling Diamante, Ultracavitação ou velox, Endermo corporal com bioativos, Vacuoterapia, Radiofrequência, Pump Up, Corrente Russa, Crio Home care";
        } else if (tipo == "Pacote Estético") {
          return "Tratamentos estéticos semanais";
        } else if (tipo == "1 sessão estética avulsa") {
          return "Tratamento estético";
        } else if (tipo == "1 exame de bioimpedância") {
          return "Exame que mede a quantidade de água, massa magra e gordura em cada área do corpo";
        } else if (tipo == "1 exame Nutrigenético (DNA equilibrio) (a vista)" || tipo == "1 exame Nutrigenético (DNA equilibrio) (parcelado)") {
          return "Exame Nutrigênico que avalia genes relacionados a nutrição e outros fatores de prevensão";
        }  else if (
          tipo == "1 exame de Intolerância e Hipersensibilidade alimentar"
        ) {
          return "Exame de intolerância e hipersensibilidade alimentar de 216 alimentos";
        } else if (tipo == "Protocolo Kirchhoff") {
          return "Uma consulta nutricional + prescrição de cardápios personalizados + prescrição de suplementos, para fortalecer a imunidade";
        } else if (tipo == "Pacote 90 dias Protocolo Kirchhoff") {
          return "Durante 90 dias: 3 consultas nutricionais + prescrição de cardápios personalizados + prescrição de suplementos, para fortalecer a imunidade";
        } else if (tipo == "Outro") {
          return "";
        } else {
          return "";
        }
      };

      var localCode = this.localCode;
      var adding = function (accumulator, a) {
        return accumulator + a;
      };
      var valorArray = this.mov.soldObjs.map(function (obj) {
        return obj.valor[localCode];
      });

      var valorOriginal = valorArray.reduce(adding, 0);

      var niceString = function (valor) {
        var valorKomma;

        var decimals = Math.round((valor - Math.floor(valor)) * 100);
        var decimalsStr;
        if (decimals < 1) {
          decimalsStr = "00";
        } else if (decimals < 10) {
          decimalsStr = "0" + decimals.toString();
        } else {
          decimalsStr = decimals.toString();
        }

        var valorGanz = Math.floor(valor);

        if (valorGanz > 1000) {
          var tusiger = Math.floor(valor / 1000);
          var rest = valorGanz % 1000;
          var restStr;
          if (rest < 10) {
            restStr = "00" + rest.toString();
          } else if (rest < 100) {
            restStr = "0" + rest.toString();
          } else {
            restStr = rest.toString();
          }
          valorKomma = tusiger.toString() + "." + restStr;
        } else {
          valorKomma = valorGanz.toString();
        }
        return "R$ " + valorKomma + "," + decimalsStr;
      };

      var createTablesForSug = function (mov, startPosY) {
        var bodyAvText = [
          [mov.tipo],
          [`${descrTratamento(mov.tipo)}`],
          [`Valor original: ${niceString(valorOriginal)}`],
          [`Desconto: ${niceString(mov.desconto)}`],
          [`Valor com desconto: ${niceString(mov.valor)}`],
          [`Voucher de desconto: ${id}`],
        ];

        doc.autoTable({
          startY: startPosY, //(lastPos / 2) * 0.7 + startPosY,
          startx: startXPos,
          head: [["Sugestão de tratamento"]],
          body: bodyAvText,
        });
        startPosY = doc.previousAutoTable.finalY;
        return doc.previousAutoTable.finalY;
      };

      doc.addImage({
        imageData: this.preloadLogo,
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      var newEnd1 = createTexts(
        ["Avaliação gratuita", `${this.nome}`],
        true,
        textStartYPos // + 1 * (fontSize * (3 / 4))
      );

      //   var newEndY = createTexts(
      //     [`${this.avEscrita}`],
      //     false,
      //     textStartYPos + 5 * (fontSize * (3 / 4))
      //   );
      var newEnd2 = createTablesForAv(this.avEscrita, newEnd1 + 16);
      var newEnd3 = createTablesForSug(this.mov, newEnd2 + 8);

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;
      textStartYPos = imgHeight + newEnd3 + 16;

      doc.addImage({
        imageData: this.preloadSignature,
        y: newEnd3 + 16,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          `Nutricionista ${this.funcionarioOnline.nome}`,
          `CRN: ${this.funcionarioOnline.crn}`,
          `Data: ${day}/${month}/${year}`,
          "",
          `${this.userInfo.addressRoad} - ${this.userInfo.addressComplement}`,
          `${this.userInfo.addressCep} ${this.userInfo.addressCity} - ${this.userInfo.addressBairro}`,
          `tel.: ${this.userInfo.phoneClients}`,
        ],
        true,
        textStartYPos // + texts.length * (fontSize * (3 / 4))
      );

      alert("Gerando PDF");
      doc.save("avGratuita.pdf");
    },
    adjustValorDesc() {
      this.mov.valor -= this.mov.desconto;
    },
    descontoImpact() {
      if (!this.descontoAct) {
        var localCode = this.localCode;
        var adding = function (accumulator, a) {
          return accumulator + a;
        };
        var valorArray = this.mov.soldObjs.map(function (obj) {
          return obj.valor[localCode];
        });

        this.mov.valor = valorArray.reduce(adding, 0);
      } else {
        this.mov.valor -= this.mov.desconto;
      }
    },
    niceString(valor) {
      var valorKomma;

      var decimals = Math.round((valor - Math.floor(valor)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valor);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valor / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    changeTratSugg(tipoNew) {
      this.mov.soldObjs = [];
      this.mov.descr = null;
      this.mov.comprovante = null;
      this.mov.desconto = 0;
      this.mov.pacotes = [
        {
          def1: null,
          def2: null,
          area1: null,
          area2: null,
          area3: null,
          dataInicio: null,
          millisInicio: null,
          index: 0,
        },
      ];
      this.mov.forma = null;
      this.warnMessages = [];
      this.setValor(tipoNew, this.mov.descr);
    },
    setValor(tipo, descr) {
      var tipoFilter = function (el) {
        return el.nome == tipo;
      };
      var descrFilter = function (el) {
        return el.nome == descr;
      };
      // push soldObjs
      var vendaItemList = this.vendaItems.filter(tipoFilter);
      var vendaItem = vendaItemList[0];
      this.mov.soldObjs.push(vendaItem);

      if (this.mov.descr !== null) {
        var aVendaItem;
        var sessaoEstetList = this.sessoesEsteticos.filter(descrFilter);
        if (sessaoEstetList.length > 0) {
          aVendaItem = sessaoEstetList[0];
          this.mov.soldObjs.push(aVendaItem);
        }
      }

      console.log(this.mov.pacotes.length);
      if (this.mov.pacotes.length > 0) {
        for (var j = 0; j < this.mov.pacotes.length; j++) {
          if (this.mov.pacotes[j].def1 !== null) {
            var pacDef1 = this.mov.pacotes[j].def1;
            var pacItemList = this.pacotesEsteticos1.filter(function (el) {
              return el.nome == pacDef1;
            });
            var pacItem = pacItemList[0];
            this.mov.soldObjs.push(pacItem);
          }
        }
      }

      console.log(this.mov.soldObjs);

      // localCode to map through soldObjs

      var localCode = this.localCode;
      var valorArray = this.mov.soldObjs.map(function (obj) {
        return obj.valor[localCode];
      });

      var adding = function (accumulator, a) {
        return accumulator + a;
      };

      console.log(valorArray);

      this.mov.valor = valorArray.reduce(adding, 0);
    },

    setNull(dir) {
      this.mov = {
        direction: dir,
        tipo: null,
        descr: null,
        paciente: null,
        comprovante: null,
        valor: null,
        pacotes: [
          {
            def1: null,
            def2: null,
            area1: null,
            area2: null,
            area3: null,
            dataInicio: null,
            millisInicio: null,
            index: 0,
          },
        ],
        forma: null,
      };
      this.temasToLiberate = [];
    },
  },
  mounted() {
    setTimeout(() => {
      this.preloadImages();
    }, 500);
  },
};
</script>
