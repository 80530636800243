<template>
  <div>
    <v-dialog v-model="dialogComprovante" max-width="600">
      <v-card>
        <v-card-text class="d-flex justify-center">
          <v-img
            :src="comprovanteLink"
            width="90%"
            height="90%"
            class="my-3 mx-3"
          ></v-img>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn dense @click="dialogComprovante = false" color="#c5d13f"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="visualizarDialog" max-width="80%" height="80%" multiple>
      <v-card>
        <v-row class="d-flex align-center mx-4">
          <v-col cols="8">
            <v-row>{{ compraVis.tipo }}</v-row
            ><v-row>
              <small>
                {{ compraVis.descr }}
              </small>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-row>
              <v-chip color="#c5d13f" class="white--text">
                {{ niceString(compraVis.valor) }}
              </v-chip>
              <v-icon v-if="compraVis.descontoAct" color="orange" class="mx-1">
                mdi-sale
              </v-icon>
            </v-row>
            <v-row class="text-caption mt-1">
              <v-icon color="black" left small>{{
                formaIcon(compraVis.forma)
              }}</v-icon>
              {{ formaStr(compraVis.forma) }}
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom v-if="compraVis.comprovante">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  @click="openComprovanteDialog(compraVis.comprovante)"
                >
                  <v-icon color="#614021">mdi-note-text-outline</v-icon>
                </v-btn>
              </template>
              <span>Comprovante de pagamento</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- <v-row v-if="compraVis.pacienteName != ''" class="mx-4">
          {{ compraVis.pacienteName }}
        </v-row> -->
        <v-row
          v-if="
            compraVis.pacotes[0].dataInicio !== undefined &&
              compraVis.pacotes[0].dataInicio !== null
          "
          class="mx-4"
        >
          <v-data-table
            :headers="pacoteHeaders"
            :items="formatPacotes(compraVis.pacotes)"
            class="elevation-1"
            hide-default-footer
          ></v-data-table>
        </v-row>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title class="text-h6"> Compras </v-card-title>
      <v-card-text>
        <v-card v-for="comp in compras" :key="comp.millis" width="95%" outlined>
          <v-row class="d-flex align-center mx-4">
            <v-col cols="12" md="3">
              <v-row>{{ comp.tipo }}</v-row>
              <v-row
                ><small> {{ comp.descr }}</small></v-row
              >
            </v-col>
            <v-col cols="5" md="2">
              {{ comp.dateStr }}
            </v-col>
            <v-col cols="1" md="1">
              <v-icon color="#614021">{{ formaIcon(comp.forma) }}</v-icon>
            </v-col>
            <v-col cols="4" mc="2" class="green--text">
              {{ niceString(comp.valor) }}
            </v-col>
            <v-col cols="1" md="1">
              <v-btn icon @click="setCompraVisualizar(comp)">
                <v-icon color="#614021">mdi-eye</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="1" md="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="makeRecibo(comp)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#614021">mdi-file-download-outline</v-icon>
                  </v-btn>
                </template>
                <span>Recibo</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiCreditCardCheckOutline,
  mdiCash100,
  mdiCreditCardClockOutline,
  mdiBankTransfer,
  mdiInfinity,
  mdiHelpRhombusOutline,
  mdiPiggyBank,
  mdiCheckbook,
  mdiWalletPlusOutline,
} from "@mdi/js";
import firebase from "firebase";
import jspdf from "jspdf";

export default {
  data() {
    return {
      compras: [],
      dialogComprovante: false,
      comprovanteLink: "",
      franquiaNomeString: "",
      franquiaCnpjString: "",
      icons: {
        mdiCreditCardCheckOutline,
        mdiCash100,
        mdiCreditCardClockOutline,
        mdiBankTransfer,
        mdiInfinity,
        mdiHelpRhombusOutline,
        mdiPiggyBank,
        mdiWalletPlusOutline,
        mdiCheckbook,
      },
      visualizarDialog: false,
      pacoteHeaders: [
        { text: "Pacote", value: "defs" },
        { text: "Mês do programa", value: "mesPrograma" },
        { text: "Area", value: "areas" },
      ],
      preloadLogo: new Image(),
      preloadSignature: new Image(),
    };
  },
  firestore() {
    return {
      compras: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("compras"),
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    compraVis() {
      return this.$store.state.firestore.compraVis;
    },
  },
  methods: {
    formatPacotes(pacs) {
      return pacs.map(function(pac) {
        var defs = "";
        if (pac.def1 !== null && pac.def2 == null) {
          defs = pac.def1;
        } else if (pac.def1 == null && pac.def2 !== null) {
          defs = pac.def2;
        } else if (pac.def1 !== null && pac.def2 !== null) {
          defs = pac.def1 + " - " + pac.def2;
        }

        var areas = pac.area1;
        if (pac.area2 !== null && pac.area3 == null) {
          areas = areas + " + " + pac.area2;
        } else if (pac.area2 !== null && pac.area3 !== null) {
          areas = areas + " + " + pac.area2 + " + " + pac.area3;
        }

        var mesPrograma = pac.dataInicio + 1;
        if (pac.millisInicio !== undefined && pac.millisInicio !== null) {
          var date = new Date(pac.millisInicio);
          const mesMillis = 7 * 4 * 24 * 60 * 60 * 1000;
          var date2 = new Date(pac.millisInicio + mesMillis);
          var mes = date.getMonth() + 1;
          var mes2 = date2.getMonth() + 1;
          var dataReal = date.getDate().toString();
          var dataReal2 = date2.getDate().toString();
          var ano = date.getFullYear() - 2000;
          var ano2 = date2.getFullYear() - 2000;
          mesPrograma =
            mesPrograma.toString() +
            " (" +
            dataReal +
            "/" +
            mes.toString() +
            "/" +
            ano.toString() +
            " - " +
            dataReal2 +
            "/" +
            mes2 +
            "/" +
            ano2 +
            ")";
        }

        return {
          defs: defs,
          areas: areas,
          mesPrograma: mesPrograma,
        };
      });
    },
    async preloadImages() {
      //   console.log(this.$store.state.firestore.pacienteNutriLogo);
      //   this.preloadLogo.crossOrigin = "anonymous";
      //   this.preloadSignature.crossOrigin = "anonymous";
      //   this.preloadLogo.src = `${this.$store.state.firestore.pacienteNutriLogo}`;
      //   this.preloadSignature.src = `${this.$store.state.firestore.pacienteNutriSign}`;

      if (this.userInfo.nutri == "umarizalbelem@clubedanutricionista.com.br") {
        this.preloadSignature.src = require("@/assets/signature_danielle.png");
      } else if (this.userInfo.nutri == "bc@clubedanutricionista.com.br") {
        this.preloadSignature.src = require("@/assets/signature_pietra.png");
      } else if (
        this.userInfo.nutri == "fortaleza.salinas@clubedanutricionista.com.br"
      ) {
        this.preloadSignature.src = require("@/assets/signature_leanna.png");
      } else {
        this.preloadSignature.src = require("@/assets/signature_none.jpg");
      }

      this.preloadLogo.src = require("@/assets/logoCompleto.png");

      this.preloadLogo.onload = function() {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function() {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    async getFranquiaCnpj() {
      var nutriData = await firebase
        .firestore()
        .collection("users")
        .doc(this.userInfo.nutri)
        .get()
        .then(function(doc) {
          return doc.data();
        });

      this.franquiaNomeString = nutriData.name;
      this.franquiaCnpjString = nutriData.cnpj;
    },
    async makeRecibo(elData) {
      alert("Gerando PDF");
      // links
      // https://mrrio.github.io/jsPDF/examples/basic.html
      // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
      // https://simonbengtsson.github.io/jsPDF-AutoTable/
      // https://www.npmjs.com/package/jspdf-autotable

      var pacienteCpfString;
      if (this.userInfo.cpf !== undefined) {
        pacienteCpfString = `(CPF: ${this.userInfo.cpf})`;
      } else {
        pacienteCpfString = "";
      }

      var doc = new jspdf("p");
      var date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();

      // Doc settings
      var fontSize = 12;
      doc.setFontSize(fontSize);
      doc.setFont("Times");

      // The (900 / 262) division is used to keep the proportion of the original image
      // New Width * (900 / 262) will keep the height proportion

      var imgWidth = 40;
      var imgHeight = imgWidth / (900 / 262);
      var imgPos = (210 - imgWidth) / 2;
      var startXPos = 15;
      var textStartYPos = imgHeight * 4;

      var centeredText = function(text, y) {
        var textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      };

      // var createTexts = function(
      //   textList,
      //   centered = false,
      //   textStart = textStartYPos
      // ) {
      //   for (var i = 0; i < textList.length; i++) {
      //     if (centered) {
      //       centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
      //     } else {
      //       var lines = doc.setFont("Roboto").splitTextToSize(textList[i], 180);
      //       // console.log(lines)
      //       for (var j = 0; j < lines.length; j++) {
      //         doc.text(
      //           startXPos,
      //           textStart + (fontSize / 2) * (1 + j),
      //           lines[j]
      //         );
      //       }
      //       textStart += (fontSize / 2) * /*1 + */ lines.length;
      //     }
      //   }
      //   return (textStart += fontSize / 2);
      // };

      var createTexts = function(
        textList,
        centered = false,
        textStart = textStartYPos
      ) {
        var pageHeightThreshold = 256; // The max Y position for the current page
        var newYStart = 24; // The Y position to start at on a new page

        for (var i = 0; i < textList.length; i++) {
          var lines = doc
            .setFont("Montserrat-Medium")
            .splitTextToSize(textList[i], 180);

          for (var j = 0; j < lines.length; j++) {
            // Check if the current line would go past the threshold
            if (textStart > pageHeightThreshold) {
              doc.addPage();
              textStart = newYStart; // Reset the text start position for the new page
            }

            // Whether to center the text or not
            if (centered) {
              centeredText(lines[j], textStart);
            } else {
              doc.text(startXPos, textStart, lines[j]);
            }

            textStart += fontSize / 2; // Increment the y position for each line of text
          }

          // Increment after each text block rather than each line
          if (!centered) {
            textStart += fontSize / 2; // This accounts for the space after each block of text
          }
        }

        return textStart; // Return the updated text start position
      };

      // console.log(this.$store.state.firestore.userInfo);
      // // Logo Clubedonutricionista
      // if (this.$store.state.firestore.userInfo.useLogo === false) {
      //   console.log("op 1");
      doc.addImage({
        imageData: require("@/assets/logoCompleto.png"),
        y: startXPos,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });
      // } else {
      //   // LOGO CLUBE
      //   imgWidth = 30;
      //   imgHeight = imgWidth / (900 / 262);
      //   startXPos = 15;

      //   doc.addImage({
      //     imageData: require("@/assets/logoCompleto.png"),
      //     y: startXPos,
      //     x: 15,
      //     w: imgWidth,
      //     h: imgHeight,
      //   });

      //   imgWidth = 50;
      //   var startYPos = 15 + imgHeight * 2;
      //   imgHeight =
      //     imgWidth /
      //     (this.preloadSignature.naturalWidth /
      //       this.preloadSignature.naturalHeight);
      //   imgPos = (210 - imgWidth) / 2;
      //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

      //   doc.addImage({
      //     imageData: this.preloadSignature,
      //     y: startYPos,
      //     x: imgPos,
      //     w: imgWidth,
      //     h: imgHeight,
      //   });
      // }

      var endTablePosY = centeredText("Recibo", textStartYPos);

      var texts = [
        `Recebemos de ${this.userInfo.nome} ${pacienteCpfString} no dia ${elData.dateStr}`,
        `a quantia de ${this.niceString(
          elData.valor
        )}, referente a ${this.niceTipo(elData.tipo)}.`,
        `Forma de pagamento: ${this.formaStr(elData.forma)}`,
      ];

      endTablePosY = createTexts(texts);

      // USE SIGNATURE
      // console.log(
      //   "signature",
      //   this.$store.state.firestore.userInfo.signature
      // );
      // if (this.$store.state.firestore.userInfo.signature != undefined) {
      //   imgWidth = 30;
      //   startYPos =
      //     fontSize +
      //     (endTablePosY !== undefined
      //       ? endTablePosY + 16
      //       : textStartYPos + texts.length * (fontSize * (3 / 4)));
      //   imgHeight =
      //     imgWidth /
      //     (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
      //   imgPos = (210 - imgWidth) / 2;
      //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

      //   doc.addImage({
      //     imageData: this.preloadSignature,
      //     y: startYPos,
      //     x: imgPos,
      //     w: imgWidth,
      //     h: imgHeight,
      //   });
      // } else {
      //   imgHeight = 0;
      // }

      imgWidth = 75;
      imgHeight =
        imgWidth /
        (this.preloadSignature.naturalWidth /
          this.preloadSignature.naturalHeight);
      imgPos = (210 - imgWidth) / 2;
      textStartYPos =
        imgHeight +
        // 4 * (imgHeight / fontSize) +
        endTablePosY +
        imgHeight * 1.4;

      doc.addImage({
        imageData: this.preloadSignature,
        y: endTablePosY + imgHeight * 1.4,
        x: imgPos,
        w: imgWidth,
        h: imgHeight,
      });

      createTexts(
        [
          "____________________________",
          `Clube da Nutricionista - ${this.franquiaNomeString}`,
          `CNPJ: ${this.franquiaCnpjString}`,
          `Data: ${day}/${month}/${year}`,
        ],
        true,
        endTablePosY !== undefined
          ? endTablePosY + imgHeight * 1.4
          : textStartYPos + texts.length * (fontSize * (3 / 4))
      );

      doc.save("recibo.pdf");
    },
    // makeRecibo(elData) {
    //   alert("indisponível");
    //   console.log(elData);
    // },
    openComprovanteDialog(link) {
      this.dialogComprovante = true;
      this.comprovanteLink = link;
    },
    niceString(valor) {
      var valorAbs = Math.abs(valor);
      var valorKomma;

      var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valorAbs);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valorAbs / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    niceTipo(item) {
      var niceItem = "";

      var vendaItems = [
        {
          nome: "1 consulta nutricional Crédito / Débito",
          niceNome: "uma consulta nutricional com bioimpedância",
        },
        {
          nome: "1 consulta nutricional PIX / Transferência",
          niceNome: "uma consulta nutricional com bioimpedância",
        },
        {
          nome: "1 consulta nutricional (plano família, mínimo 2 pessoas)",
          niceNome: "uma consulta nutricional com bioimpedância",
        },
        // !!!!
        {
          nome: "1 consulta nutricional SEM BIOIMPEDÂNCIA E RETORNO",
          niceNome: "uma consulta nutricional",
        },
        {
          nome: "1 consulta nutricional SEM RETORNO",
          niceNome: "uma consulta nutricional",
        },
        {
          nome: "Programa Clínico Funcional",
          niceNome:
            'acompanhamento nutricional de 90 dias ("Programa Clínico Funcional")',
        },
        {
          nome: "Programa Clínico Funcional (plano família)",
          niceNome:
            'acompanhamento nutricional de 90 dias ("Programa Clínico Funcional")',
        },
        {
          nome: "Programa Clínico Funcional com Estética",
          niceNome:
            'acompanhamento nutricional de 90 dias com tratamentos estéticos complementares ("Programa Clínico Funcional")',
        },
        {
          nome: "Programa Modulação Intestinal",
          niceNome:
            'acompanhamento nutricional de 90 dias ("Programa Modulação Intestinal")',
        },
        {
          nome: "Programa Modulação Intestinal (plano família)",
          niceNome:
            'acompanhamento nutricional de 90 dias ("Programa Modulação Intestinal")',
        },
        {
          nome: "Programa Modulação Intestinal com Estética",
          niceNome:
            'acompanhamento nutricional de 90 dias com tratamentos estéticos complementares ("Programa Modulação Intestinal")',
        },
        {
          nome: "Programa 12S Online",
          niceNome:
            'acompanhamento nutricional de 12 semanas ("Programa 12S Online")',
        },
        {
          nome: "Programa 12S",
          niceNome: 'acompanhamento nutricional de 12 semanas ("Programa 12S")',
        },
        {
          nome: "Programa 12S com Estética",
          niceNome:
            'acompanhamento nutricional de 12 semanas com tratamentos estéticos complementares ("Programa 12S com Estética")',
        },
        {
          nome: "Protocolo Glúteo de Ouro",
          niceNome: 'tratamento estético ("Protocolo Glúteo de Ouro")',
        },
        {
          nome: "Power Shape 30",
          niceNome: 'tratamento estético ("Power Shape 30")',
        },
        {
          nome: "Protocolo Lipodraining 24H",
          niceNome: 'tratamento estético ("Protocolo Lipodraining 24H")',
        },
        {
          nome: "Protocolo Detox + Lipodraining 24H",
          niceNome:
            'tratamento estético ("Protocolo Detox + Lipodraining 24H")',
        },
        {
          nome: "Protocolo Harmonização Corporal 24H",
          niceNome:
            'tratamento estético ("Protocolo Harmonização Corporal 24H")',
        },
        {
          nome: "Pacote Estético",
          niceNome: "tratamento estético",
        },
        {
          nome: "1 sessão estética avulsa",
          niceNome: "uma sessão estética avulsa",
        },
        {
          nome: "1 exame de bioimpedância",
          niceNome: "um exame de bioimpedância",
        },
        {
          nome: "1 exame de Intolerância e Hipersensibilidade alimentar",
          niceNome: "um exame de Intolerância e Hipersensibilidade alimentar",
        },
        {
          nome: "1 exame de Microbioma Intestinal",
          niceNome: "um exame de Microbioma Intestinal",
        },
        {
          nome: "1 exame Nutrigenético",
          niceNome: "um exame de Microbioma Intestinal",
        },
        {
          nome: "1 exame Genético Esportivo",
          niceNome: "um exame Genético Esportivo",
        },
        {
          nome: "Protocolo Kirchhoff",
          niceNome: 'uma consulta nutricional ("Protocolo Kirchhoff")',
        },
        {
          nome: "Pacote 90 dias Protocolo Kirchhoff",
          niceNome:
            'acompanhamento nutricional de 90 dias ("Protocolo Kirchhoff")',
        },
        {
          nome: "HydraGloss",
          niceNome: "HydraGloss",
        },
        {
          nome: "Protocolo de Clareamento",
          niceNome: "Protocolo de Clareamento",
        },
        {
          nome: "Protocolo de Clareamento (Pacote de 4 sessões)",
          niceNome: "Protocolo de Clareamento (Pacote de 4 sessões)",
        },
        {
          nome: "Giftcard",
          niceNome: "um giftcard",
        },
        {
          nome: "Outro",
          niceNome: "um serviço",
        },
      ];

      var gotItems = vendaItems.filter(function(v) {
        return v.nome == item;
      });

      if (gotItems.length > 0) {
        niceItem = gotItems[0].niceNome;
      } else {
        niceItem = item;
      }

      return niceItem;
    },
    formaIcon(forma) {
      if (forma === 1) {
        return this.icons.mdiCash100;
      } else if (forma === 2) {
        return this.icons.mdiCreditCardCheckOutline;
      } else if (forma === 3) {
        return this.icons.mdiCreditCardClockOutline;
      } else if (forma === 4) {
        return this.icons.mdiBankTransfer;
      } else if (forma === 5) {
        return this.icons.mdiInfinity;
      } else if (forma === 6) {
        return this.icons.mdiCheckbook;
      } else if (forma === 7) {
        return this.icons.mdiWalletPlusOutline;
      } else if (forma === 8) {
        return this.icons.mdiPiggyBank;
      } else {
        return this.icons.mdiHelpRhombusOutline;
      }
    },
    formaStr(forma) {
      if (forma === 1) {
        return "Dinheiro";
      } else if (forma === 2) {
        return "Cartão de Débito";
      } else if (forma === 3) {
        return "Cartão de Crédito";
      } else if (forma === 4) {
        return "Transferência Bancária";
      } else if (forma === 5) {
        return "PIX";
      } else if (forma === 6) {
        return "Cheque";
      } else if (forma === 7) {
        return "multiplas formas";
      } else if (forma === 8) {
        return "Depósito Bancário";
      } else {
        return "forma de pagamento não especificada";
      }
    },
    setCompraVisualizar(compra) {
      this.$store.dispatch("firestore/setCompraVis", {
        compra,
      });

      this.visualizarDialog = true;
    },
  },
  mounted() {
    this.preloadImages();

    setTimeout(() => {
      this.getFranquiaCnpj();
    }, 2000);
  },
};
</script>
