var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialogComprovante),callback:function ($$v) {_vm.dialogComprovante=$$v},expression:"dialogComprovante"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('v-img',{staticClass:"my-3 mx-3",attrs:{"src":_vm.comprovanteLink,"width":"90%","height":"90%"}})],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"dense":"","color":"#c5d13f"},on:{"click":function($event){_vm.dialogComprovante = false}}},[_vm._v("Fechar")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"80%","height":"80%","multiple":""},model:{value:(_vm.visualizarDialog),callback:function ($$v) {_vm.visualizarDialog=$$v},expression:"visualizarDialog"}},[_c('v-card',[_c('v-row',{staticClass:"d-flex align-center mx-4"},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',[_vm._v(_vm._s(_vm.compraVis.tipo))]),_c('v-row',[_c('small',[_vm._v(" "+_vm._s(_vm.compraVis.descr)+" ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-chip',{staticClass:"white--text",attrs:{"color":"#c5d13f"}},[_vm._v(" "+_vm._s(_vm.niceString(_vm.compraVis.valor))+" ")]),(_vm.compraVis.descontoAct)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"orange"}},[_vm._v(" mdi-sale ")]):_vm._e()],1),_c('v-row',{staticClass:"text-caption mt-1"},[_c('v-icon',{attrs:{"color":"black","left":"","small":""}},[_vm._v(_vm._s(_vm.formaIcon(_vm.compraVis.forma)))]),_vm._v(" "+_vm._s(_vm.formaStr(_vm.compraVis.forma))+" ")],1)],1),_c('v-col',{attrs:{"cols":"1"}},[(_vm.compraVis.comprovante)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.openComprovanteDialog(_vm.compraVis.comprovante)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#614021"}},[_vm._v("mdi-note-text-outline")])],1)]}}],null,false,3206277036)},[_c('span',[_vm._v("Comprovante de pagamento")])]):_vm._e()],1)],1),(
          _vm.compraVis.pacotes[0].dataInicio !== undefined &&
            _vm.compraVis.pacotes[0].dataInicio !== null
        )?_c('v-row',{staticClass:"mx-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.pacoteHeaders,"items":_vm.formatPacotes(_vm.compraVis.pacotes),"hide-default-footer":""}})],1):_vm._e()],1)],1),_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Compras ")]),_c('v-card-text',_vm._l((_vm.compras),function(comp){return _c('v-card',{key:comp.millis,attrs:{"width":"95%","outlined":""}},[_c('v-row',{staticClass:"d-flex align-center mx-4"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',[_vm._v(_vm._s(comp.tipo))]),_c('v-row',[_c('small',[_vm._v(" "+_vm._s(comp.descr))])])],1),_c('v-col',{attrs:{"cols":"5","md":"2"}},[_vm._v(" "+_vm._s(comp.dateStr)+" ")]),_c('v-col',{attrs:{"cols":"1","md":"1"}},[_c('v-icon',{attrs:{"color":"#614021"}},[_vm._v(_vm._s(_vm.formaIcon(comp.forma)))])],1),_c('v-col',{staticClass:"green--text",attrs:{"cols":"4","mc":"2"}},[_vm._v(" "+_vm._s(_vm.niceString(comp.valor))+" ")]),_c('v-col',{attrs:{"cols":"1","md":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setCompraVisualizar(comp)}}},[_c('v-icon',{attrs:{"color":"#614021"}},[_vm._v("mdi-eye")])],1)],1),_c('v-col',{attrs:{"cols":"1","md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.makeRecibo(comp)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#614021"}},[_vm._v("mdi-file-download-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Recibo")])])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }