<template>
  <div>
    <WelcomeHeader></WelcomeHeader>
    <LoginDePacienteForm></LoginDePacienteForm>
    <!-- <WelcomeCarousel></WelcomeCarousel>
    <WelcomeCarouselPlanos></WelcomeCarouselPlanos> -->
    <WelcomeFooter></WelcomeFooter>
  </div>
</template>

<script>
import WelcomeHeader from "../components/welcome/WelcomeHeader";
import WelcomeFooter from "../components/welcome/WelcomeFooter";
import LoginDePacienteForm from "../components/login/LoginDePacienteForm";

// import WelcomeCarousel from "../components/welcome/WelcomeCarousel";
// import WelcomeCarouselPlanos from "../components/welcome/WelcomeCarouselPlanos";

export default {
  components: { WelcomeHeader, WelcomeFooter, LoginDePacienteForm },
  data() {
    return {
      bottomNav: "recent",
      preloadSignature: new Image(),
      preloadLogo: new Image(),
      dieta: {},
    };
  },
  methods: {
    preloadImages() {
      this.preloadSignature.src = require("@/assets/signature_none.jpg");

      this.preloadLogo.src = require("@/assets/logoCompleto.png");

      this.preloadLogo.onload = function () {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function () {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },

  },
  mounted() {
    console.log(this.$route.query);
    console.log(this.$route.query.option);
    console.log(this.$route.query.email);
    console.log(this.$route.query.name);
  },
};
</script>

<style scoped>
</style>


