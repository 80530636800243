<template>
  <div class="d-flex flex-column justify-center">
    <v-dialog v-model="receitaVisDialog" max-width="50%">
      <v-card>
        <v-card-title class="text-p black--text">
          "{{ receita.titulo }}"
        </v-card-title>
        <v-card-text class="ml-6">
          <v-row>
            <strong> Tempo de Preparo: </strong> {{ receita.tempoPreparo }}
          </v-row>
          <v-row> <strong> Modo de Preparo: </strong> </v-row>
          <v-row> {{ receita.modoPreparo }} </v-row>
          <v-row>
            <strong> Rendimento: </strong> {{ receita.rendimento }}
          </v-row>
          <v-row class="mt-1">
            <strong> Ingredientes: </strong>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Alimento</th>
                  <th class="text-left">Quantidade</th>
                  <th class="text-left">Medida caseira</th>
                  <th class="text-left">Gramas totais</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="alimentoR in receita.alimentosList"
                  :key="alimentoR.nome"
                >
                  <td>{{ alimentoR.nome }}</td>
                  <td>{{ alimentoR.quantidade }}</td>
                  <td>{{ alimentoR.medida }}</td>
                  <td>{{ alimentoR.gramas }}g</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-if="saved">
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card
      class="d-inline-block mr-3"
      width="100%"
      elevation="3"
      color="grey lighten-2"
    >
      <v-container>
        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                @click="receitaVisDialog = true"
              >
                <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
              </v-btn>
            </template>
            <span>Visualizar Receita</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2">
                <v-icon color="#614021">{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir Dieta</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#c5d13f" v-bind="attrs" v-on="on">
                <v-icon color="#614021">{{ icons.mdiDownload }}</v-icon>
              </v-btn>
            </template>
            <span>Baixar Receita</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#c5d13f"
                v-bind="attrs"
                v-on="on"
                class="mx-4"
                @click="saveAsNew"
              >
                <v-icon color="#614021">{{ icons.mdiContentSaveAll }}</v-icon>
              </v-btn>
            </template>
            <span>Salvar como Receita Nova</span>
          </v-tooltip>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#c5d13f" v-bind="attrs" v-on="on">
                <v-icon color="#614021">{{ icons.mdiEmailSendOutline }}</v-icon>
              </v-btn>
            </template>
            <span>Enviar Receita ao Paciente</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#c5d13f"
                v-bind="attrs"
                v-on="on"
                @click="saveOverride"
              >
                <v-icon color="#614021">{{ icons.mdiContentSaveEdit }}</v-icon>
              </v-btn>
            </template>
            <span>Salvar alterações na mesma receita</span>
          </v-tooltip>
        </v-card-actions>

        <v-divider></v-divider>

        <v-row class="d-flex justify-center">
          <v-chip color="#c5d13f" draggable>
            <v-row class="px-2">
              <h4 class="pr-2">TOTAL KCAL: {{ receita.KCAL.toFixed(2) }}</h4>
              <h4 class="pr-2">CHO: {{ receita.CHO.toFixed(2) }}</h4>
              <h4 class="pr-2">PTN: {{ receita.PTN.toFixed(2) }}</h4>
              <h4 class="pr-2">LIP: {{ receita.LIP.toFixed(2) }}</h4>
              <h4 class="pr-2">COL: {{ receita.COL.toFixed(2) }}</h4>
              <h4 class="pr-2">FIB: {{ receita.FIB.toFixed(2) }}</h4>
              <h4 class="pr-2">SOD: {{ receita.SOD.toFixed(2) }}</h4>
            </v-row>
          </v-chip>
        </v-row>
      </v-container>
    </v-card>

    <ReceitaFormEdit></ReceitaFormEdit>
  </div>
</template>

<script>
import firebase from "firebase";
import {
  mdiCalendar,
  mdiContentSaveAll,
  mdiContentSaveEdit,
  mdiPrinter,
  mdiEye,
  mdiEmailSendOutline,
  mdiDownload,
  mdiMagnify,
} from "@mdi/js";

// import jspdf from "jspdf";
// import "jspdf-autotable";
import router from "@/router";
// components imports
import ReceitaFormEdit from "@/components/home/receitas/ReceitaFormEdit";

export default {
  components: { ReceitaFormEdit },
  data: function () {
    return {
      icons: {
        mdiCalendar,
        mdiContentSaveAll,
        mdiContentSaveEdit,
        mdiPrinter,
        mdiEye,
        mdiEmailSendOutline,
        mdiDownload,
        mdiMagnify,
      },
      receitaVisDialog: false,
      wrongAutor: false,
      progressDialog: false,
      saved: false,
      tituloInicial: "",
    };
  },
  computed: {
    receita() {
      return this.$store.state.firestore.receita;
    },
    receitaId() {
      return this.$store.state.firestore.receitaId;
    },
    alimentos() {
      return this.$store.state.firestore.alimentos;
    },
  },
  methods: {
    saveOverride: function () {
      console.log(this.receitaId);
      this.progressDialog = true;
      firebase
        .firestore()
        // .collection("users")
        // .doc(sessionStorage.getItem("current-user"))
        .collection("receitas")
        .doc(this.receitaId)
        .update(this.receita);
      this.saved = true;

      setTimeout(() => {
        router.replace("/AdminReceitasSalvas");
      }, 2000);
    },
    saveAsNew: function () {
      this.progressDialog = true;
      this.receita.autor = "admin";
      firebase
        .firestore()
        // .collection("users")
        // .doc(sessionStorage.getItem("current-user"))
        .collection("receitas")
        .add(this.receita);
      this.saved = true;

      setTimeout(() => {
        router.replace("/AdminReceitasSalvas");
      }, 2000);
    },
  },
  mounted() {
    if (this.$store.state.firestore.alimentos.length === 0) {
      this.$store.dispatch("firestore/getAlimentos");
    }
    setTimeout(() => {
      this.tituloInicial = this.receita.titulo;
    }, 1000);
  },
};
</script>

<style>
</style>