<template>
  <v-card elevation="5">
    <v-card-title class="text-h6"> Fotos </v-card-title>
    <v-card-text>
      <div
        v-for="(atendimento, atendimentoIndex) in atendimentos"
        :key="atendimentoIndex"
      >
        <div v-if="atendimento.fotoFrenteUrl !== ''">
          <v-card outlined>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="6" class="d-flex justify-space-between">
                <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                  {{ labels[atendimentoIndex] }}
                </v-row>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 mx-2 black--text"
                      :href="atendimento.fotoFrenteUrl"
                      target="_blank"
                      icon
                    >
                      <v-icon color="#614021">mdi-account</v-icon>
                    </v-btn>
                  </template>
                  <span>Frente</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 mx-2 black--text"
                      :href="atendimento.fotoAtrasUrl"
                      target="_blank"
                      icon
                    >
                      <v-icon color="#614021">mdi-account-reactivate</v-icon>
                    </v-btn>
                  </template>
                  <span>Trás</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 mx-2 black--text"
                      :href="atendimento.fotoEsquerdaUrl"
                      target="_blank"
                      icon
                    >
                      <v-icon color="#614021">mdi-account-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Esquerda</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 mx-2 black--text"
                      :href="atendimento.fotoDireitaUrl"
                      target="_blank"
                      icon
                    >
                      <v-icon color="#614021">mdi-account-arrow-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Direita</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <div v-else></div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      atendimentos: [],
    };
  },
  computed: {
    labels() {
      var labelList = this.atendimentos.map(function (atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });
      return labelList;
    },
  },
  firestore() {
    return {
      atendimentos: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("atendimentos"),
    };
  },
};
</script>