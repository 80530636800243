<template>
  <div class="page">
    <LoginHeader></LoginHeader>
    <LoginForm></LoginForm>
    <WelcomeFooter></WelcomeFooter>
  </div>
</template>

<script>
import WelcomeFooter from "../components/welcome/WelcomeFooter";
import LoginHeader from "../components/login/LoginHeader";
import LoginForm from "../components/login/LoginForm";

export default {
  components: { WelcomeFooter, LoginHeader, LoginForm },

};
</script>

<style>
</style>