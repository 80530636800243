<template>
  <div class="d-flex flex-column justify-center">
    <v-card>
      <v-card-title class="d-flex justify-center">Dados da dieta</v-card-title>
      <v-card-text>
        <v-row class="d-flex justify-center mx-2">
          <v-text-field
            type="number"
            label="calorias totais [kcal]"
            v-model="defTaxaMet"
            outlined
          >
          </v-text-field>
        </v-row>
        <v-row class="d-flex justify-center mt-2" v-if="defPercSoma">
          <v-alert type="error" dense
            >Soma dos percentuais não igual a 100! {{ defPercPtn }} +
            {{ defPercCho }} + {{ defPercLip }} = {{ defPercSomaNr }}</v-alert
          >
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="4">
            <v-row class="d-flex justify-center mx-1">
              <v-slider
                v-model="defPercPtn"
                color="red"
                track-color="grey"
              ></v-slider>
            </v-row>
            <v-row class="d-flex justify-center mx-1">
              <v-text-field
                type="number"
                label="percentual de proteínas [% PTN]"
                v-model="defPercPtn"
                outlined
              >
              </v-text-field></v-row
          ></v-col>
          <v-col cols="12" md="4">
            <v-row class="d-flex justify-center mx-1">
              <v-slider
                v-model="defPercCho"
                color="black"
                track-color="grey"
              ></v-slider>
            </v-row>
            <v-row class="d-flex justify-center mx-1">
              <v-text-field
                type="number"
                label="percentual de carboidratos [% CHO]"
                v-model="defPercCho"
                outlined
              >
              </v-text-field></v-row
          ></v-col>
          <v-col cols="12" md="4">
            <v-row class="d-flex justify-center mx-1">
              <v-slider
                v-model="defPercLip"
                color="yellow darken-2"
                track-color="grey"
              ></v-slider>
            </v-row>
            <v-row class="d-flex justify-center mx-1">
              <v-text-field
                type="number"
                label="percentual de lipideos [% LIP]"
                v-model="defPercLip"
                outlined
              >
              </v-text-field></v-row
          ></v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="d-flex justify-center mx-1 mt-1">
          Definir quantidade refeições
        </v-row>
        <v-row
          class="d-flex justify-center mx-1 mt-1"
          v-for="(defRefeicao, defRefIndex) in defRefeicoes"
          :key="defRefIndex"
        >
          <v-card class="d-flex align-center" outlined width="100%" style="background-color: #E5FFCC">
            <v-text-field
              label="nome da refeição"
              v-model="defRefeicao.nome"
              class="d-flex mx-1"
            ></v-text-field>
            <v-select
              class="d-flex mx-1"
              label="tipo de refeição"
              v-model="defRefeicao.refTipo"
              :items="refTipos"
            ></v-select>
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-if="defRefIndex > 0"
                  icon
                  color="red"
                  @click="excludeRef(defRefIndex)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
              <span>Excluir refeição</span>
            </v-tooltip>
            <v-tooltip bottom color="green">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="green"
                  @click="insertRef(defRefIndex)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span>Adicionar refeição</span>
            </v-tooltip>
          </v-card>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="criarDieta" color="#c5d13f">Criar dieta</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dietaDialog">
      <div v-if="created">
        <v-card
          class="d-inline-block mr-3"
          width="100%"
          elevation="3"
          color="grey lighten-2"
        >
          <v-container>
            <v-card-actions>
              <v-dialog
                v-model="dialogVisualizar"
                max-width="80%"
                height="80%"
                multiple
              >
                <v-card>
                  <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel
                      v-for="refeicao in dietaRefeicoes"
                      :key="refeicao.nomeRefeicao"
                    >
                      <v-expansion-panel-header>
                        <v-row class="text-h6">
                          {{ refeicao.nomeRefeicao }},
                          {{ refeicao.horarioRefeicao }}
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-expansion-panels
                          v-model="panelSubs"
                          multiple
                          v-show="refeicao.alimentosList[0].gramas > 0"
                        >
                          <v-expansion-panel
                            v-for="alimento in refeicao.alimentosList"
                            :key="alimento.nome"
                          >
                            <v-expansion-panel-header>
                              <p
                                v-if="alimento.quantidade < 2"
                                class="text-p black--text"
                              >
                                {{ alimento.quantidade }}
                                {{ alimento.medida }} de {{ alimento.nome }} ({{
                                  alimento.gramas
                                }}g total)
                              </p>
                              <p
                                v-else-if="alimento.quantidade > 1"
                                class="text-p black--text"
                              >
                                {{ alimento.quantidade }} ({{
                                  alimento.medida
                                }}) de {{ alimento.nome }} ({{
                                  alimento.gramas
                                }}g total)
                              </p>
                              <p v-else class="text-p black--text">
                                {{ alimento.gramas }}g de {{ alimento.nome }}
                              </p>
                              <template v-slot:actions>
                                <v-icon
                                  color="black"
                                  v-if="alimento.toSubstitute != undefined"
                                >
                                  {{ icons.mdiAutorenew }}
                                </v-icon>
                                <v-icon v-else> </v-icon>
                              </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="ml-6">
                              <v-row
                                class="mt-1"
                                v-show="alimento.toSubstitute != undefined"
                              >
                                <strong> Possíveis substituições </strong>
                              </v-row>
                              <v-simple-table
                                v-show="alimento.toSubstitute != undefined"
                              >
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">Alimento</th>
                                      <th class="text-left">Quantidade</th>
                                      <th class="text-left">Medida caseira</th>
                                      <th class="text-left">Gramas totais</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(
                                        sub, subIndex
                                      ) in alimento.toSubstitute"
                                      :key="subIndex"
                                    >
                                      <td>{{ sub.nome }}</td>
                                      <td>{{ sub.qtdSub }}</td>
                                      <td>{{ sub.medida_caseira_padrao }}</td>
                                      <td>{{ sub.gramas }}g</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>

                        <v-expansion-panels
                          v-model="panelReceitas"
                          multiple
                          v-show="refeicao.receitasList[0].porcoes > 0"
                        >
                          <v-expansion-panel
                            v-for="receita in refeicao.receitasList"
                            :key="receita.titulo"
                          >
                            <v-expansion-panel-header
                              class="text-p black--text"
                            >
                              {{ receita.porcoes }}
                              {{ porcao(receita.porcoes) }} de "{{
                                receita.titulo
                              }}"
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="ml-6">
                              <v-row>
                                <strong> Modo de Preparo: </strong>
                              </v-row>
                              <v-row> {{ receita.modoPreparo }} </v-row>
                              <v-row class="mt-1">
                                <strong> Ingredientes: </strong>
                              </v-row>
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">Alimento</th>
                                      <th class="text-left">Quantidade</th>
                                      <th class="text-left">Medida caseira</th>
                                      <th class="text-left">Gramas totais</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="alimentoR in receita.alimentosList"
                                      :key="alimentoR.nome"
                                    >
                                      <td>{{ alimentoR.nome }}</td>
                                      <td>{{ alimentoR.quantidade }}</td>
                                      <td>{{ alimentoR.medida }}</td>
                                      <td>{{ alimentoR.gramas }}g</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card class="pr-2" min-height="50px" elevation="1">
                          <v-card-text
                            v-show="refeicao.observacao"
                            class="text-p black--text ml-2"
                          >
                            <strong>Observações: </strong>
                            {{ refeicao.observacao }}
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-dialog>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    @click="dialogVisualizar = true"
                  >
                    <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Dieta</span>
              </v-tooltip>

              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#c5d13f"
                    v-bind="attrs"
                    v-on="on"
                    @click="createDocWithJSPDF"
                  >
                    <v-icon color="#614021">{{ icons.mdiDownload }}</v-icon>
                  </v-btn>
                </template>
                <span>Baixar Dieta</span>
              </v-tooltip>

              <v-dialog v-model="dialogSalvar" max-width="640" multiple>
                <v-card color="white">
                  <v-card-title>Salvar Dieta</v-card-title>
                  <v-card-text>
                    <!-- <v-row v-if="pacienteChosen.data.nome != ''" class="d-flex align-center">
                  <v-checkbox
                    :label="`No histórico do paciente ${pacienteChosen.data.nome}`"
                    v-model="salvarDietaEmPaciente"
                    color="#614021"
                  ></v-checkbox>
                </v-row> -->
                    <v-row>
                      <v-checkbox
                        label="No histórico do paciente:"
                        color="#614021"
                        v-model="salvarDietaEmPaciente"
                      ></v-checkbox>
                      <v-text-field
                        dense
                        solo
                        v-model="nomeDietaPaciente"
                        label="Titulo da dieta (visível pelo paciente)"
                        class="align-self-center mx-2"
                        required
                        :rules="mandatory"
                      ></v-text-field>
                      <v-autocomplete
                        label="Nome do Paciente"
                        single-line
                        solo
                        dense
                        clearable
                        v-model="pacienteDocID"
                        :items="pacientes"
                        class="align-self-center mx-2"
                        @change="pacienteSelected(pacienteDocID)"
                        no-data-text="..."
                      ></v-autocomplete>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                      <v-checkbox
                        color="#614021"
                        v-model="salvarDietaEmSalvas"
                        label='Nas minhas "Dietas salvas"'
                      ></v-checkbox>
                      <v-text-field
                        dense
                        solo
                        v-model="nomeDieta"
                        label="Titulo da dieta (nas dietas salvas)"
                        class="align-self-center mx-2 mt-4"
                        required
                        :rules="mandatory"
                      ></v-text-field>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn @click="salvarDieta" color="#c5d13f">
                      <v-icon color="#614021">{{
                        icons.mdiContentSave
                      }}</v-icon>
                      Salvar</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn @click="dialogSalvar = false" text color="grey"
                      >Fechar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="progressDialog" max-width="600">
                <v-card v-if="!saved">
                  <v-card-title class="d-flex justify-center">
                    Salvando dados...
                  </v-card-title>
                  <v-card-text class="d-flex justify-center">
                    <v-progress-circular
                      :size="50"
                      :width="5"
                      color="#614021"
                      indeterminate
                    ></v-progress-circular>
                  </v-card-text>
                </v-card>
                <v-card v-else>
                  <v-card-title class="d-flex justify-center">
                    Dados salvos!
                  </v-card-title>
                  <v-card-text class="d-flex justify-center">
                    <v-icon x-large color="green">mdi-check-circle</v-icon>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#c5d13f"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-4"
                    @click="dialogSalvar = true"
                  >
                    <v-icon color="#614021">{{ icons.mdiContentSave }}</v-icon>
                  </v-btn>
                </template>
                <span>Salvar Dieta</span>
              </v-tooltip>

              <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#c5d13f" v-bind="attrs" v-on="on">
                <v-icon color="#614021">{{ icons.mdiEmailSendOutline }}</v-icon>
              </v-btn>
            </template>
            <span>Enviar Dieta ao Paciente</span>
          </v-tooltip> -->
            </v-card-actions>

            <v-divider></v-divider>

            <v-row class="d-flex justify-center">
              <v-col cols="12" md="8">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-card
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      color="grey lighten-2"
                    >
                      <v-autocomplete
                        label="Nome do Paciente"
                        single-line
                        solo
                        clearable
                        class="mt-2 mx-3"
                        v-model="pacienteDocID"
                        :items="pacientes"
                        @change="pacienteSelected(pacienteDocID)"
                        no-data-text="..."
                      ></v-autocomplete>
                    </v-card>
                  </template>
                  <span
                    >Selecione o nome de um paciente cadastrado para anexar a
                    dieta
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="4">
                <!-- <v-btn class="mt-3">Ver histórico de atendimento</v-btn> -->
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-chip color="#c5d13f" class="d-flex align-center">
                <v-row class="px-2 black--text">
                  <p class="px-2 mt-4">
                    KCAL: <strong>{{ dietaStatus.KCAL.toFixed(1) }}</strong>
                  </p>
                  <p class="px-2 mt-4">
                    CHO: <strong>{{ dietaStatus.CHO.toFixed(1) }}</strong>
                  </p>
                  <p style="color: red" class="mt-4">
                    {{
                      toPerc(dietaStatus.CHO, dietaStatus.PTN, dietaStatus.LIP)
                        .cho
                    }}%
                  </p>
                  <p style="color: red"></p>
                  <p class="px-2 mt-4">
                    PTN: <strong>{{ dietaStatus.PTN.toFixed(1) }}</strong>
                  </p>
                  <p style="color: red" class="mt-4">
                    {{
                      toPerc(dietaStatus.CHO, dietaStatus.PTN, dietaStatus.LIP)
                        .ptn
                    }}%
                  </p>
                  <p style="color: red"></p>
                  <p class="px-2 mt-4">
                    LIP: <strong>{{ dietaStatus.LIP.toFixed(1) }}</strong>
                  </p>
                  <p style="color: red" class="mt-4">
                    {{
                      toPerc(dietaStatus.CHO, dietaStatus.PTN, dietaStatus.LIP)
                        .lip
                    }}%
                  </p>
                  <p style="color: red"></p>
                  <p class="px-2 mt-4">
                    COLEST: <strong>{{ dietaStatus.COL.toFixed(1) }}</strong>
                  </p>
                  <p class="px-2 mt-4">
                    Na: <strong>{{ dietaStatus.SOD.toFixed(1) }}</strong>
                  </p>
                  <p class="px-2 mt-4">
                    FIB: <strong>{{ dietaStatus.FIB.toFixed(1) }}</strong>
                  </p>
                </v-row>
              </v-chip>
              <v-dialog v-model="dialog" width="90%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    color="#c5d13f"
                    class="mx-2"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }"
                        ><v-icon color="#614021" v-bind="attrs" v-on="on">{{
                          icons.mdiDotsHorizontal
                        }}</v-icon>
                      </template>
                      <span>Mais informações nutricionais</span>
                    </v-tooltip>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="h6"
                      >Informações nutricionais detalhadas da dieta</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr
                              v-for="nutrient in allNutrients"
                              :key="nutrient.codigo"
                            >
                              <td>{{ nutrient.nome }}:</td>
                              <td>
                                <strong>{{
                                  dietaStatus[nutrient.codigo].toFixed(2)
                                }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn color="#c5d13f" text @click="dialog = false">
                      Fechar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-container>
        </v-card>

        <DietaForm></DietaForm>
      </div>
      <div v-else>
        <v-card>
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-progress-circular
                :size="50"
                :width="5"
                color="#614021"
                indeterminate
              ></v-progress-circular>
            </v-row>
            <v-row class="d-flex justify-center"> Criando dieta... </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// import firebase from "firebase";
import {
  mdiCalendar,
  mdiContentSave,
  mdiPrinter,
  mdiEye,
  mdiEmailSendOutline,
  mdiDownload,
  mdiMagnify,
  mdiDotsHorizontal,
  mdiInformationOutline,
  mdiClose,
  mdiAutorenew,
} from "@mdi/js";

// const docx = require("docx");
// import { saveAs } from "file-saver";

import jspdf from "jspdf";
import "jspdf-autotable";
// import imagesloaded from 'imagesloaded'

// components imports
import DietaForm from "../components/home/dieta/DietaForm";

export default {
  components: { DietaForm },
  data: function () {
    return {
      defTaxaMet: 1200,
      defPercPtn: 25,
      defPercLip: 5,
      defPercCho: 70,
      refTipos: ["lanche", "Refeição pequena", "Refeição maior"],
      defRefeicoes: [
        {
          nome: null,
          refTipo: null,
        },
      ],
      dietaDialog: false,
      created: false,
      snack: true,
      table: false,
      dialog: false,
      nomeDieta: "",
      nomeDietaPaciente: "",
      signatureAdress: "@/assets/signature_none.jpg",
      mandatory: [
        (v) => !!v || "Campo obrigatório",
        // v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      dialogVisualizar: false,
      dialogSalvar: false,
      salvarDietaEmPaciente: false,
      salvarDietaEmSalvas: true,
      progressDialog: false,
      saved: false,
      panel: [],
      panelReceitas: [],
      panelSubs: [],
      snackbar: true,
      allNutrients: [
        { codigo: "KCAL", nome: "Calorias [kcal]" }, //NaN
        { codigo: "PTN", nome: "Proteina [g]" },
        { codigo: "LIP", nome: "Lipideos [g]" },
        { codigo: "COL", nome: "Colesterol" },
        { codigo: "CHO", nome: "Carboidrato [g]" },
        { codigo: "FIB", nome: "Fibra alimentar [g]" },
        { codigo: "fibra_soluvel", nome: "Fibra solúvel [g]" },
        { codigo: "fibra_insoluvel", nome: "Fibra insolúvel [g]" },
        { codigo: "umidade", nome: "Umidade [g]" },
        { codigo: "cinzas", nome: "Cinzas [g]" },
        { codigo: "calcio", nome: "Cálcio [mg]" },
        { codigo: "ferro", nome: "Ferro [mg]" },
        { codigo: "magnesio", nome: "Magnésio [mg]" },
        { codigo: "manganes", nome: "Manganés [mg]" },
        { codigo: "fosforo", nome: "Fósforo [mg]" },
        { codigo: "SOD", nome: "Sódio [mg]" },
        { codigo: "potassio", nome: "Potassio [mg]" },
        { codigo: "cobre", nome: "Cobre [mg]" },
        { codigo: "zinco", nome: "Zinco [mg]" },
        { codigo: "iodo", nome: "Iodo [mg]" },
        { codigo: "selenio", nome: "Selénio [mg]" },
        { codigo: "retinol", nome: "Retinol [μg]" },
        { codigo: "re", nome: "RE [μg]" },
        { codigo: "era", nome: "ERA [μg]" },
        { codigo: "vitamina_b", nome: "Vitamina B [mg]" }, //
        { codigo: "vitamina_c", nome: "Vitamina C [mg]" },
        { codigo: "vitamina_d", nome: "Vitamina D [mg]" },
        { codigo: "vitamina_e", nome: "Vitamina E [mg]" },
        { codigo: "vitamina_b12", nome: "Vitamina B12 [mg]" },

        { codigo: "acido_folico", nome: "Ácido fólico" },
        { codigo: "acido_pantotenico", nome: "Ácido pantoténico" },
        { codigo: "tiamina", nome: "Tiamina" },
        { codigo: "riboflavina", nome: "Riboflavina" },
        { codigo: "piridoxina", nome: "Piridoxina" },
        { codigo: "niacina", nome: "Niacina" },

        { codigo: "saturados", nome: "Saturados" },
        { codigo: "monoinsaturados", nome: "Mono-insaturados" },
        { codigo: "poliinsaturados", nome: "Poli-insaturados" },
        { codigo: "gordura_trans", nome: "Gordura trans" },

        { codigo: "acido_aspartico", nome: "Ácido aspartico" },
        { codigo: "acido_glutamico", nome: "Ácido glutámico" },
        { codigo: "triptofano", nome: "Triptofano" },
        { codigo: "treonina", nome: "Treonina" },
        { codigo: "isoleucina", nome: "Isoleucina" },
        { codigo: "leucina", nome: "Leucina" },
        { codigo: "lisina", nome: "Lisina" },
        { codigo: "metionina", nome: "Metionina" },
        { codigo: "cistina", nome: "Cistina" },
        { codigo: "fenilalanina", nome: "Fenilalanina" },
        { codigo: "tirosina", nome: "Tirosina" },
        { codigo: "valina", nome: "Valina" },
        { codigo: "arginina", nome: "Arginina" },
        { codigo: "histidina", nome: "Histidina" },
        { codigo: "alanina", nome: "Alanina" },
        { codigo: "glicina", nome: "Glícina" },
        { codigo: "prolina", nome: "Prolina" },
        { codigo: "serina", nome: "Serina" },
      ],
      pacienteDocID: "",
      pacienteChosen: "", //{ data: { nome: "", email: "" } },
      icons: {
        mdiCalendar,
        mdiContentSave,
        mdiPrinter,
        mdiEye,
        mdiEmailSendOutline,
        mdiDownload,
        mdiMagnify,
        mdiDotsHorizontal,
        mdiInformationOutline,
        mdiClose,
        mdiAutorenew,
      },
      preloadLogo: new Image(),
      preloadSignature: new Image(),
    };
  },
  computed: {
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    defPercSoma() {
      var soma = this.defPercCho + this.defPercLip + this.defPercPtn;
      return soma !== 100;
    },
    defPercSomaNr() {
      var soma = this.defPercCho + this.defPercLip + this.defPercPtn;
      return soma;
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    dietaStatus() {
      return this.$store.state.firestore.dietaStatus;
    },
    dietaRefeicoes() {
      return this.$store.state.firestore.dietaRefeicoes;
    },
    alimentos() {
      return this.$store.state.firestore.alimentos;
    },
  },
  methods: {
    insertRef(index) {
      var refItem = {
        nome: null,
        refTipo: null,
      };
      var addIndex = index + 1;
      this.defRefeicoes.splice(addIndex, 0, refItem);
    },
    excludeRef(index) {
      this.defRefeicoes.splice(index, 1);
    },
    criarDieta() {
      console.log("criando dieta");
    },
    porcao(nr) {
      if (nr > 1) {
        return "porções";
      } else {
        return "porção";
      }
    },
    toPerc(cho, ptn, lip) {
      var choFator = cho * 4;
      var ptnFator = ptn * 4;
      var lipFator = lip * 9;
      var cemPorcento = choFator + ptnFator + lipFator;
      var choPerc = (choFator / cemPorcento) * 100;
      var ptnPerc = (ptnFator / cemPorcento) * 100;
      var lipPerc = (lipFator / cemPorcento) * 100;
      if (cemPorcento > 0) {
        return {
          cho: choPerc.toFixed(),
          ptn: ptnPerc.toFixed(),
          lip: lipPerc.toFixed(),
        };
      } else {
        return {
          cho: 0,
          ptn: 0,
          lip: 0,
        };
      }
    },
    pacienteSelected(pacienteId) {
      console.log(pacienteId);
      if (pacienteId != undefined) {
        // var paciente = this.pacientes.find((item) => item.value == pacienteId);
        // this.pacienteChosen = paciente;
        // console.log(this.pacienteChosen);
        this.pacienteChosen = pacienteId;
        this.salvarDietaEmPaciente = true;
        this.salvarDietaEmSalvas = false;
      } else {
        this.pacienteChosen = ""; //{ data: { nome: "", email: "" } };
        this.salvarDietaEmPaciente = false;
        this.salvarDietaEmSalvas = true;
      }
    },
    salvarDieta() {
      this.dialogSalvar = false;
      this.progressDialog = true;
      if (this.salvarDietaEmPaciente) {
        if (this.pacienteChosen !== "") {
          this.$store.dispatch("firestore/salvarDietaPaciente", {
            // pacienteId: this.pacienteDocID,
            titulo: this.nomeDietaPaciente,
            pacienteEmail: this.pacienteChosen, //.data.email,
            status: this.dietaStatus,
            autorEmail: sessionStorage.getItem("current-user"),
            autorPapel: this.funcionarioOnline.papel,
            autorNome: this.funcionarioOnline.nome,
            autorCrn: this.funcionarioOnline.crn,
            dietaRefeicoes: this.dietaRefeicoes.map(function (refeicao) {
              refeicao.alimentosList.map(function (alimento) {
                alimento.substitution = null;
                if (alimento.toSubstitute) {
                  alimento.toSubstitute.map(function (sub) {
                    // sub.nome = sub.data.nome;
                    sub.data = null;
                    return sub;
                  });
                }
                return alimento;
              });
              if (refeicao.receitasList != undefined) {
                refeicao.receitasList.map(function (receita) {
                  receita.alimentosList.map(function (alimento) {
                    var alimentoReduced = {
                      nome: alimento.nome,
                      quantidade: alimento.quantidade,
                      gramas: alimento.gramas,
                      medida: alimento.medida,
                    };
                    return alimentoReduced;
                  });
                  return receita;
                });
              }
              return refeicao;
            }),
          });

          this.$store.dispatch("firestore/persistPaciente", {
            pId: this.pacienteChosen, //.data.email,
          });
          this.saved = true;
        } else {
          alert("Escolher paciente");
        }
        // setTimeout(() => {
        //   this.$router.push("MeusPacientes");
        // }, 2000);
      }
      if (this.salvarDietaEmSalvas) {
        console.log(this.nomeDieta);
        this.$store.dispatch("firestore/salvarDietaSalvas", {
          titulo: this.nomeDieta,
          status: this.dietaStatus,
          autorEmail: sessionStorage.getItem("current-user"),
          autorPapel: this.funcionarioOnline.papel,
          autorCrn: this.funcionarioOnline.crn,
          autorNome: this.funcionarioOnline.nome,
          dietaRefeicoes: this.dietaRefeicoes.map(function (refeicao) {
            refeicao.alimentosList.map(function (alimento) {
              alimento.substitution = null;
              if (alimento.toSubstitute) {
                alimento.toSubstitute.map(function (sub) {
                  // sub.nome = sub.data.nome;
                  sub.data = null;
                  return sub;
                });
              }
              return alimento;
            });
            if (refeicao.receitasList != undefined) {
              refeicao.receitasList.map(function (receita) {
                receita.alimentosList.map(function (alimento) {
                  var alimentoReduced = {
                    nome: alimento.nome,
                    quantidade: alimento.quantidade,
                    gramas: alimento.gramas,
                    medida: alimento.medida,
                  };
                  return alimentoReduced;
                });
                return receita;
              });
            }
            return refeicao;
          }),
        });
        this.saved = true;

        setTimeout(() => {
          this.$router.push("DietasSalvas");
        }, 2000);
      }
    },
    // createListWithNames() {
    //   var values = Object.values(this.pacientes);
    //   this.pacientes = values.map((valueItem) => {
    //     return {
    //       value: valueItem.id,
    //       text: valueItem.nome,
    //     };
    //   });
    // },

    // logRecietaNumber() {
    //   firebase
    //     .firestore()
    //     .collection("receitas")
    //     .get()
    //     .then((snapshot) => console.log(snapshot.size));
    // },
    // setSignatureAdress() {
    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "umarizalbelem@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.signatureAdress = "@/assets/signature_none.jpg";
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 2") {
    //       this.signatureAdress = "@/assets/signature_livia.jpg";
    //     }
    //   }
    //   console.log(this.signatureAdress);
    // },

    // async preloadImages() {
    //   // this.preloadSignature.src = require(this.signatureAdress); // require("@/assets/signature_none.jpg");
    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "umarizalbelem@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.preloadSignature.src = require("@/assets/signature_none.jpg");
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 2") {
    //       this.preloadSignature.src = require("@/assets/signature_thayana.jpg");
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 3") {
    //       this.preloadSignature.src = require("@/assets/signature_henrique.png");
    //     }
    //   }

    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "bc@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.preloadSignature.src = require("@/assets/signature_none.jpg");
    //     } else if (this.funcionarioOnline.papel == "Nutricionista 2") {
    //       this.preloadSignature.src = require("@/assets/signature_suellen.jpg");
    //     } else {
    //       this.preloadSignature.src = require("@/assets/signature_suellen.jpg");
    //     }
    //   }

    //   if (
    //     sessionStorage.getItem("current-user") ==
    //     "fortaleza.salinas@clubedanutricionista.com.br"
    //   ) {
    //     if (this.funcionarioOnline.papel == "Nutricionista 1") {
    //       this.preloadSignature.src = require("@/assets/signature_none.jpg");
    //     } else {
    //       this.preloadSignature.src = require("@/assets/signature_none.jpg");
    //     }
    //   }

    //   this.preloadLogo.src = require("@/assets/logoCompleto.png");

    //   this.preloadLogo.onload = function () {
    //     console.log("Logo carregada");
    //   };
    //   this.preloadSignature.onload = function () {
    //     console.log("Signature carregada");
    //   };

    //   console.log(
    //     "natural:",
    //     this.preloadLogo.naturalWidth,
    //     this.preloadLogo.naturalHeight
    //   );
    //   console.log(
    //     "natural:",
    //     this.preloadSignature.naturalWidth,
    //     this.preloadSignature.naturalHeight
    //   );
    // },
    async preloadImages() {
      if (
        this.funcionarioOnline.assinatura !== undefined &&
        this.funcionarioOnline.assinatura !== ""
      ) {
        this.toDataURL(this.funcionarioOnline.assinatura, (data) => {
          this.preloadSignature.src = data;
        });
      } else {
        this.preloadSignature.src = require("@/assets/signature_none.jpg");
      }

      if (this.userInfo.profilePicture !== undefined) {
        this.toDataURL(this.userInfo.profilePicture, (data) => {
          this.preloadLogo.src = data;
        });
      } else {
        this.preloadLogo.src = require("@/assets/signature_none.jpg");
      }

      this.preloadLogo.onload = function () {
        console.log("Logo carregada");
      };
      this.preloadSignature.onload = function () {
        console.log("Signature carregada");
      };

      console.log(
        "natural:",
        this.preloadLogo.naturalWidth,
        this.preloadLogo.naturalHeight
      );
      console.log(
        "natural:",
        this.preloadSignature.naturalWidth,
        this.preloadSignature.naturalHeight
      );
    },
    async createDocWithJSPDF() {
      if (this.dietaRefeicoes.length !== 0) {
        alert("Gerando PDF");
        // links
        // https://mrrio.github.io/jsPDF/examples/basic.html
        // http://www.rotisedapsales.com/snr/cloud_staging/website/jsPDF-master/docs/global.html#text
        // https://simonbengtsson.github.io/jsPDF-AutoTable/
        // https://www.npmjs.com/package/jspdf-autotable

        var doc = new jspdf("p");
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        // Doc settings
        var fontSize = 12;
        doc.setFontSize(fontSize);
        doc.setFont("Times");

        // The (900 / 262) division is used to keep the proportion of the original image
        // New Width * (900 / 262) will keep the height proportion

        var imgWidth = 40;
        var imgHeight = imgWidth / (900 / 262);
        var imgPos = (210 - imgWidth) / 2;
        var startXPos = 15;
        var textStartYPos = imgHeight * 4;

        var centeredText = function (text, y) {
          var textWidth =
            (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
          doc.text(textOffset, y, text);
        };

        var createTexts = function (
          textList,
          centered = false,
          textStart = textStartYPos
        ) {
          for (var i = 0; i < textList.length; i++) {
            if (centered) {
              centeredText(textList[i], textStart + (fontSize / 2) * (1 + i));
            } else {
              var lines = doc
                .setFont("Roboto")
                .splitTextToSize(textList[i], 180);
              // console.log(lines)
              for (var j = 0; j < lines.length; j++) {
                doc.text(
                  startXPos,
                  textStart + (fontSize / 2) * (1 + j),
                  lines[j]
                );
              }
              textStart += (fontSize / 2) * /*1 + */ lines.length;
            }
          }
          return (textStart += fontSize / 2);
        };

        var createTablesForRefeicoes = function (refeicoes, startPosY) {
          var startPosYNew = startPosY;
          // #c5d13f <----- trocar a cor da tabela
          for (var i = 0; i < refeicoes.length; i++) {
            var texts;
            if (refeicoes[i].observacao !== "") {
              texts = [
                `${refeicoes[i].nomeRefeicao} - ${refeicoes[i].horarioRefeicao}`,
                `Observação: ${refeicoes[i].observacao}`,
              ];
            } else {
              texts = [
                `${refeicoes[i].nomeRefeicao} - ${refeicoes[i].horarioRefeicao}`,
              ];
            }
            startPosYNew = createTexts(texts, false, startPosYNew); //lastPos
            var body = refeicoes[i].alimentosList.map(function (alimento) {
              if (alimento != undefined) {
                if (alimento.toSubstitute != undefined) {
                  alimento.hasSubs = "*";
                }
                return [
                  alimento.nome,
                  alimento.quantidade, //.toString(),
                  alimento.medida, //.toString(),
                  alimento.gramas, //.toString(),
                  // alimento.kcal//.toString(),
                  alimento.hasSubs,
                ];
              } else {
                return [];
              }
            });
            console.log("", refeicoes[i].alimentosList[0]);
            if (refeicoes[i].alimentosList[0].alimentoId !== "") {
              doc.autoTable({
                startY: startPosYNew, //lastPos // (lastPos / 2) * 0.7 + startPosY,
                startx: startXPos,
                head: [
                  ["Alimento", "Quantidade", "Medida", "Total (g)", "Subst."],
                ],
                body: body,
              });
              startPosYNew = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
            }

            // doc.text "* substituir {alimento} com {alimento.toSubstitute[0], [1]...}

            var bodyReceitas = refeicoes[i].receitasList.map(function (
              receita
            ) {
              if (receita != undefined) {
                return [receita.titulo, receita.porcoes];
              } else {
                return [];
              }
            });
            console.log("receitas[0]", refeicoes[i].receitasList[0]);
            if (refeicoes[i].receitasList[0].titulo !== "") {
              doc.autoTable({
                startY: startPosYNew, //startPosY + i * (lastPos - startPosY),
                startx: startXPos,
                head: [["Receita", "Porções"]],
                body: bodyReceitas,
              });
              startPosYNew = doc.previousAutoTable.finalY + fontSize * (3 / 4);
            }
          }
          return startPosYNew; // doc.previousAutoTable.finalY;
        };

        var createTablesForSubst = function (refeicoes, startPosY) {
          // #c5d13f <----- trocar a cor da tabela
          for (var i = 0; i < refeicoes.length; i++) {
            for (var j = 0; j < refeicoes[i].alimentosList.length; j++) {
              if (refeicoes[i].alimentosList[j].toSubstitute != undefined) {
                // console.log(refeicoes[i].alimentosList[j]);
                var textsSub;
                if (refeicoes[i].alimentosList[j].medida != null) {
                  textsSub = [
                    `${refeicoes[i].nomeRefeicao}: substituir ${refeicoes[i].alimentosList[j].quantidade} ${refeicoes[i].alimentosList[j].medida} de ${refeicoes[i].alimentosList[j].nome} por...`,
                  ];
                } else {
                  textsSub = [
                    `${refeicoes[i].nomeRefeicao}: substituir ${refeicoes[i].alimentosList[j].gramas}g de ${refeicoes[i].alimentosList[j].nome} por...`,
                  ];
                }

                var lastPos = createTexts(textsSub, false, startPosY);
                var bodySub = refeicoes[i].alimentosList[j].toSubstitute.map(
                  function (alimento) {
                    if (alimento != undefined) {
                      if (alimento.toSubstitute != undefined) {
                        alimento.hasSubs = "*";
                      }
                      return [
                        alimento.nome,
                        alimento.qtdSub, //.toString(),
                        alimento.medida_caseira_padrao, //.toString(),
                        alimento.gramas, //.toString(),
                      ];
                    } else {
                      return [];
                    }
                  }
                );

                doc.autoTable({
                  startY: lastPos, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [["Alimento", "Quantidade", "Medida", "Total (g)"]],
                  body: bodySub,
                });
                startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
              }
            }
          }
          return doc.previousAutoTable.finalY;
        };

        var createTablesForReceitas = function (refeicoes, startPosY) {
          // #c5d13f <----- trocar a cor da tabela
          for (var i = 0; i < refeicoes.length; i++) {
            for (var j = 0; j < refeicoes[i].receitasList.length; j++) {
              if (refeicoes[i].receitasList[j].titulo !== "") {
                // console.log(refeicoes[i].receitasList[j]);
                var textsRec = [
                  `${refeicoes[i].nomeRefeicao}: ${refeicoes[i].receitasList[j].titulo}, rendimento: ${refeicoes[i].receitasList[j].rendimento} (porções)`,
                  // `${refeicoes[i].receitasList[j].modoPreparo}`,
                ];

                var lastPos = createTexts(textsRec, false, startPosY + 6);
                var bodyRec = refeicoes[i].receitasList[j].alimentosList.map(
                  function (alimento) {
                    if (alimento != undefined) {
                      return [
                        alimento.nome,
                        alimento.quantidade, //.toString(),
                        alimento.medida, //.toString(),
                        alimento.gramas, //.toString(),
                      ];
                    } else {
                      return [];
                    }
                  }
                );
                var bodyModoPreparo = [[refeicoes[i].receitasList[j].modoPreparo]];

                doc.autoTable({
                  startY: lastPos, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [["Ingrediente", "Quantidade", "Medida", "Total (g)"]],
                  body: bodyRec,
                });
                startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
                doc.autoTable({
                  startY: startPosY, //(lastPos / 2) * 0.7 + startPosY,
                  startx: startXPos,
                  head: [["Modo de preparo"]],
                  body: bodyModoPreparo,
                });
                startPosY = doc.previousAutoTable.finalY; // + fontSize * (3 / 4);
              }
            }
          }
          return doc.previousAutoTable.finalY;
        };

        // console.log(this.$store.state.firestore.userInfo);
        // // Logo Clubedonutricionista
        // if (this.$store.state.firestore.userInfo.useLogo === false) {
        //   console.log("op 1");
        doc.addImage({
          imageData: this.preloadLogo,
          y: startXPos,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });
        // } else {
        //   // LOGO CLUBE
        //   imgWidth = 30;
        //   imgHeight = imgWidth / (900 / 262);
        //   startXPos = 15;

        //   doc.addImage({
        //     imageData: require("@/assets/logoCompleto.png"),
        //     y: startXPos,
        //     x: 15,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });

        //   imgWidth = 50;
        //   var startYPos = 15 + imgHeight * 2;
        //   imgHeight =
        //     imgWidth /
        //     (this.preloadSignature.naturalWidth /
        //       this.preloadSignature.naturalHeight);
        //   imgPos = (210 - imgWidth) / 2;
        //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

        //   doc.addImage({
        //     imageData: this.preloadSignature,
        //     y: startYPos,
        //     x: imgPos,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });
        // }

        var texts = [
          `Plano alimentar de ${this.dietaStatus.KCAL.toFixed(
            2
          )} kcal (${day}/${month}/${year})`,
        ];

        createTexts(texts);
        var endTablePosY = createTablesForRefeicoes(
          this.dietaRefeicoes,
          textStartYPos + texts.length * (fontSize * (3 / 4))
        );

        createTexts(
          [
            "_______________________________________________________",
            "Possíveis Substituções de Alimentos",
          ],
          true,
          endTablePosY
        );
        endTablePosY = createTablesForSubst(
          this.dietaRefeicoes,
          endTablePosY + 24
        );

        createTexts(
          [
            "_______________________________________________________",
            "Receitas",
          ],
          true,
          endTablePosY
        );
        endTablePosY = createTablesForReceitas(
          this.dietaRefeicoes,
          endTablePosY + 24
        );

        // USE SIGNATURE
        // console.log(
        //   "signature",
        //   this.$store.state.firestore.userInfo.signature
        // );
        // if (this.$store.state.firestore.userInfo.signature != undefined) {
        //   imgWidth = 30;
        //   startYPos =
        //     fontSize +
        //     (endTablePosY !== undefined
        //       ? endTablePosY + 16
        //       : textStartYPos + texts.length * (fontSize * (3 / 4)));
        //   imgHeight =
        //     imgWidth /
        //     (this.preloadLogo.naturalWidth / this.preloadLogo.naturalHeight);
        //   imgPos = (210 - imgWidth) / 2;
        //   textStartYPos = imgHeight + 4 * (imgHeight / fontSize) + startYPos;

        //   doc.addImage({
        //     imageData: this.preloadSignature,
        //     y: startYPos,
        //     x: imgPos,
        //     w: imgWidth,
        //     h: imgHeight,
        //   });
        // } else {
        //   imgHeight = 0;
        // }

        imgWidth = 75;
        imgHeight =
          imgWidth /
          (this.preloadSignature.naturalWidth /
            this.preloadSignature.naturalHeight);
        imgPos = (210 - imgWidth) / 2;
        textStartYPos = imgHeight + endTablePosY + 12;

        doc.addImage({
          imageData: this.preloadSignature,
          y: endTablePosY + 12,// + imgHeight * 1.4,
          x: imgPos,
          w: imgWidth,
          h: imgHeight,
        });

        createTexts(
          [
            "____________________________",
            // `Nutricionista ${this.$store.state.firestore.userInfo.name}`,
            // `CRN: ${this.$store.state.firestore.userInfo.crn}`,
            `Nutricionista ${this.funcionarioOnline.nome}`,
            `CRN: ${this.funcionarioOnline.crn}`,
            `Data: ${day}/${month}/${year}`,
          ],
          true,
          // endTablePosY !== undefined
          //   ? endTablePosY + 16 + imgHeight * 1.4
          //   :
          textStartYPos // + texts.length * (fontSize * (3 / 4))
        );

        doc.save("test.pdf");
      } else {
        alert("Preencha todos os campos da dieta antes de continuar");
      }
    },
  },
  // firestore() {
  //   return {
  //     documentsList: firebase
  //       .firestore()
  //       .collection("users")
  //       .doc(sessionStorage.getItem("current-user"))
  //       // .doc(firebase.auth().currentUser.email)
  //       .collection("pacientes"),
  //   };
  // },
  mounted() {
    if (this.$store.state.firestore.alimentos.length === 0) {
      this.$store.dispatch("firestore/getAlimentos");
    }
    if (this.$store.state.firestore.pacientes.length === 0) {
      this.$store.dispatch("firestore/getPacientes");
    }
    if (this.$store.state.firestore.receitas.length === 0) {
      this.$store.dispatch("firestore/getReceitas");
    }
    // setTimeout(() => {
    //   this.createListWithNames();
    // }, 1000);
    // this.setSignatureAdress();

    setTimeout(() => {
      this.preloadImages();
    }, 500);
  },
};
</script>

<style>
</style>