<template>
  <div style="width: 100%">
    <v-row class="d-flex justify-center">
      <PreAtendimentoPaciente></PreAtendimentoPaciente>
    </v-row>
    <!-- </div>
  <div v-else> -->
    <v-dialog v-model="visualizarDialog" max-width="80%" height="80%" multiple>
      <v-card v-if="isDieta">
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel
            v-for="refeicao in dietaRefeicoes"
            :key="refeicao.nomeRefeicao"
          >
            <v-expansion-panel-header>
              <v-row class="text-h6">
                {{ refeicao.nomeRefeicao }},
                {{ refeicao.horarioRefeicao }}
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels
                v-model="panelSubs"
                multiple
                v-show="refeicao.alimentosList[0].gramas > 0"
              >
                <v-expansion-panel
                  v-for="alimento in refeicao.alimentosList"
                  :key="alimento.nome"
                >
                  <v-expansion-panel-header>
                    <p
                      v-if="alimento.quantidade < 2"
                      class="text-p black--text"
                    >
                      {{ alimento.quantidade }} {{ alimento.medida }} de
                      {{ alimento.nome }} ({{ alimento.gramas }}g total)
                    </p>
                    <p
                      v-else-if="alimento.quantidade > 1"
                      class="text-p black--text"
                    >
                      {{ alimento.quantidade }} ({{ alimento.medida }}) de
                      {{ alimento.nome }} ({{ alimento.gramas }}g total)
                    </p>
                    <p v-else class="text-p black--text">
                      {{ alimento.gramas }}g de {{ alimento.nome }}
                    </p>
                    <template v-slot:actions>
                      <v-icon
                        color="black"
                        v-if="alimento.toSubstitute != undefined"
                        @click="log(alimento.toSubstitute)"
                      >
                        {{ icons.mdiAutorenew }}
                      </v-icon>
                      <v-icon v-else> </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="ml-6">
                    <v-row
                      class="mt-1"
                      v-show="alimento.toSubstitute != undefined"
                    >
                      <strong> Possíveis substituições </strong>
                    </v-row>
                    <v-simple-table v-show="alimento.toSubstitute != undefined">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Alimento</th>
                            <th class="text-left">Quantidade</th>
                            <th class="text-left">Medida caseira</th>
                            <th class="text-left">Gramas totais</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(sub, subIndex) in alimento.toSubstitute"
                            :key="subIndex"
                          >
                            <td>{{ sub.nome }}</td>
                            <td>{{ sub.qtdSub }}</td>
                            <td>{{ sub.medida_caseira_padrao }}</td>
                            <td>{{ sub.gramas }}g</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-expansion-panels
                v-model="panelReceitas"
                multiple
                v-show="refeicao.receitasList[0].porcoes > 0"
              >
                <v-expansion-panel
                  v-for="receita in refeicao.receitasList"
                  :key="receita.titulo"
                >
                  <v-expansion-panel-header class="text-p black--text">
                    {{ receita.porcoes }} {{ porcao(receita.porcoes) }} de "{{
                      receita.titulo
                    }}"
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="ml-6">
                    <v-row> <strong> Modo de Preparo: </strong> </v-row>
                    <v-row> {{ receita.modoPreparo }} </v-row>
                    <v-row class="mt-1">
                      <strong> Ingredientes: </strong>
                    </v-row>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Alimento</th>
                            <th class="text-left">Quantidade</th>
                            <th class="text-left">Medida caseira</th>
                            <th class="text-left">Gramas totais</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="alimentoR in receita.alimentosList"
                            :key="alimentoR.nome"
                          >
                            <td>{{ alimentoR.nome }}</td>
                            <td>{{ alimentoR.quantidade }}</td>
                            <td>{{ alimentoR.medida }}</td>
                            <td>{{ alimentoR.gramas }}g</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-card class="pr-2" min-height="50px" elevation="1">
                <v-card-text
                  v-show="refeicao.observacao"
                  class="text-p black--text ml-2"
                >
                  <strong>Observações: </strong> {{ refeicao.observacao }}
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-card v-else-if="isAvaliacao">
        <v-stepper non-linear>
          <v-stepper-header>
            <v-stepper-step editable step="1">
              Peso e Composição corporal
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step editable step="2"> Antropometría </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step editable step="3"> Outros </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="small">
                <line-chart :chart-data="datacollectionCompos"></line-chart>
              </div>
              <v-row class="d-flex justify-center mx-4">
                <div v-for="element in checkCompos" :key="element.nome">
                  <v-checkbox
                    class="pr-4"
                    v-model="element.show"
                    :label="element.label"
                    :color="element.color"
                    @click="fillDataCompos"
                  ></v-checkbox>
                </div>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div class="small">
                <line-chart :chart-data="datacollectionMedidas"></line-chart>
              </div>
              <v-row class="d-flex justify-center mx-4">
                <div v-for="element in checkMedidas" :key="element.nome">
                  <v-checkbox
                    class="pr-4"
                    v-model="element.show"
                    :label="element.label"
                    :color="element.color"
                    @click="fillDataMedidas"
                  ></v-checkbox>
                </div>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
              <div class="small">
                <line-chart :chart-data="datacollectionSangue"></line-chart>
              </div>
              <v-row class="d-flex justify-center mx-4">
                <div v-for="element in checkSangue" :key="element.nome">
                  <v-checkbox
                    class="pr-4"
                    v-model="element.show"
                    :label="element.label"
                    :color="element.color"
                    @click="fillDataMedidas"
                  ></v-checkbox>
                </div>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
      <v-card v-else-if="isFormula">
        <v-card-title>
          {{ formulaPVis.nome }}, ({{ formulaPVis.dateStr }})
        </v-card-title>
        <v-card-text>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel
              v-for="formulaD in formulaPVis.prescricaoC.formulasList"
              :key="formulaD.titulo"
            >
              <v-expansion-panel-header>
                {{ formulaD.titulo }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-textarea readonly v-model="formulaD.prescricoes">
                </v-textarea>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="d-flex justify-center">
      <v-card outlined width="90%" class="d-flex justify-center">
        <v-timeline dense>
          <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
          >
            <template v-slot:opposite>
            <span>{{ item.date }}</span>
            </template>
            <v-card :color="item.color" max-width="300px">
              <v-card-text class="white text--primary">
                <p>{{ item.date }}:</p>
                <p>
                  <strong>{{ item.text }}</strong>
                </p>
                <p>"{{ item.nome }}"</p>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="item.type === 'dieta'"
                      :color="item.color"
                      class="mr-2"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click="visualizar(item)"
                    >
                      <v-icon>{{ icons.mdiEye }}</v-icon>
                    </v-btn> </template
                  ><span>Visualizar</span></v-tooltip
                >
                <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="item.type === 'dieta'"
                  :color="item.color"
                  class="mr-2"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="baixar(item)"
                >
                  <v-icon>{{ icons.mdiDownload }}</v-icon>
                </v-btn> </template
              ><span>Baixar</span></v-tooltip
            > -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="item.type === 'atendimento'"
                      :color="item.color"
                      class="mr-2"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click="visualizar(item)"
                    >
                      <v-icon>{{ icons.mdiEye }}</v-icon>
                    </v-btn> </template
                  ><span>Ver evolução</span></v-tooltip
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="item.type === 'formula'"
                      :color="item.color"
                      :to="item.to"
                      class="mr-2"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click="visualizar(item)"
                    >
                      <v-icon>{{ icons.mdiEye }}</v-icon>
                    </v-btn> </template
                  ><span>Ver fórmula</span></v-tooltip
                >
                <!-- <v-btn
                  :color="item.color"
                  :to="item.to"
                  outlined
                  max-width="10px"
                >
                  {{ item.toText }}
                </v-btn> -->
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase";
import LineChart from "../components/areaDePaciente/LineChart.js";
import PreAtendimentoPaciente from "../components/PreAtendimentoPaciente_170322.vue";
import {
  mdiEye,
  mdiDownload,
  mdiAutorenew,
  mdiFinance,
  mdiCamera,
} from "@mdi/js";

export default {
  components: { PreAtendimentoPaciente, LineChart },
  data: () => ({
    condition: true,
    visualizarDialog: false,
    panel: [],
    panel0: [0],
    dietas: [],
    panelReceitas: [],
    panelSubs: [],
    items: [],
    isDieta: false,
    isAvaliacao: false,
    isFormula: false,
    gotDieta: 0,
    gotAtendimentos: 0,
    gotFormula: 0,
    icons: {
      mdiEye,
      mdiDownload,
      mdiAutorenew,
      mdiFinance,
      mdiCamera,
    },
    datacollectionCompos: null,
    datacollectionPeso: null,
    datacollectionMedidas: null,
    datacollectionSangue: null,
    checkCompos: [
      {
        show: true,
        nome: "pesoIdeal",
        color: "#ff7f00",
        label: "peso ideal [kg]",
      },
      {
        show: true,
        nome: "pesoAtual",
        color: "#000000",
        label: "peso atual [kg]",
      },
      {
        show: true,
        nome: "percGorduraAbs",
        color: "#ff0000",
        label: "gordura [kg]",
      },
      {
        show: true,
        nome: "massaMagraAbs",
        color: "#008000",
        label: "massa magra [kg]",
      },
      { show: true, nome: "aguaAbs", color: "#0000ff", label: "água [kg]" },
    ],
    checkMedidas: [
      {
        show: true,
        nome: "abdomen",
        color: "#ff0000",
        label: "abdomen [cm]",
      },
      {
        show: false,
        nome: "bracoContraido",
        color: "#810f7c",
        label: "braço contraído [cm]",
      },
      {
        show: false,
        nome: "bracoRelaxado",
        color: "#8856a7",
        label: "braço relaxado [cm]",
      },
      {
        show: true,
        nome: "cintura",
        color: "#ff7f00",
        label: "cintura [cm]",
      },
      { show: false, nome: "coxa", color: "#fb9a99", label: "coxa [cm]" },
      {
        show: false,
        nome: "panturrilha",
        color: "#33a02c",
        label: "panturrilha [cm]",
      },
      { show: true, nome: "perna", color: "#1f78b4", label: "perna [cm]" },
      {
        show: false,
        nome: "quadril",
        color: "#a6cee3",
        label: "quadril [cm]",
      },
      { show: false, nome: "torax", color: "#cab2d6", label: "torax [cm]" },
    ],
  }),
  computed: {
    preAtendimentoToFill() {
      return this.$store.state.firestore.preAtendimentoToFill;
    },
    dietaRefeicoes() {
      return this.$store.state.firestore.dietaRefeicoes;
    },
    dietaStatus() {
      return this.$store.state.firestore.dietaStatus;
    },
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
    atendimentos() {
      return this.$store.state.firestore.pacienteAtendimentosList;
    },
    labels() {
      var labelList = this.atendimentos.map(function (atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });
      return labelList;
    },
    checkSangue() {
      var concatList = [];
      for (var i = 0; i < this.atendimentos.length; i++) {
        concatList = concatList.concat(this.atendimentos[i]);
      }
      function arrayUnique(array) {
        var a = array.concat();
        for (var i = 0; i < a.length; ++i) {
          for (var j = i + 1; j < a.length; ++j) {
            if (a[i].nome === a[j].nome) a.splice(j--, 1);
          }
        }
        return a;
      }
      var sangueList = arrayUnique(concatList);
      console.log(sangueList);

      function getRandomColor() {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      var sangueObjsList = sangueList.map(function (obj) {
        return {
          show: false,
          nome: obj.index,
          color: getRandomColor(),
          label: obj.nome,
        };
      });

      return sangueObjsList;
    },
    formulaPVis() {
      return this.$store.state.firestore.formulaPVis;
    },
  },
  methods: {
    porcao(nr) {
      if (nr > 1) {
        return "porções";
      } else {
        return "porção";
      }
    },
    visualizar(item) {
      if (item.type === "dieta") {
        this.isAvaliacao = false;
        this.isDieta = true;
        this.isFormula = false;
        if (this.gotDieta !== item.docId) {
          this.$store.dispatch("firestore/getDietaPaciente", {
            dietaDocId: item.docId,
          });
          this.gotDieta = item.docId;
        }
      }

      if (item.type === "atendimento") {
        this.isAvaliacao = true;
        this.isDieta = false;
        this.isFormula = false;
        if (this.gotAtendimentos < 1) {
          this.$store.dispatch("firestore/pacienteAtendimentosList");
          setTimeout(() => {
            this.fillDataCompos();
            this.fillDataMedidas();
            this.fillDataSangue();
          }, 2000);
          this.gotAtendimentos = 1;
        }
      }

      if (item.type === "formula") {
        this.isAvaliacao = false;
        this.isDieta = false;
        this.isFormula = true;

        if (this.gotFormula !== item.docId) {
          this.$store.dispatch("firestore/getFormulaPaciente", {
            docId: item.docId,
          });
          this.gotFormula = item.docId;
        }
      }

      this.visualizarDialog = true;
    },
    // baixar(item) {
    //   console.log(item);
    // },
    fillDataMedidas() {
      var atendimentos = this.atendimentos;

      function checkShow(element) {
        return element.show;
      }

      this.datacollectionMedidas = {
        labels: this.labels,
        datasets: this.checkMedidas.filter(checkShow).map(function (element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function (atendimento) {
              return atendimento.medidas[element.nome];
            }),
          };
        }),
      };
    },
    fillDataCompos() {
      var atendimentos = this.atendimentos;

      function checkShow(element) {
        return element.show;
      }

      this.datacollectionCompos = {
        labels: this.labels,
        datasets: this.checkCompos.filter(checkShow).map(function (element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function (atendimento) {
              return atendimento.compos[element.nome];
            }),
          };
        }),
      };
    },
    fillDataSangue() {
      var atendimentos = this.atendimentos;

      function checkShow(element) {
        return element.show;
      }

      this.datacollectionSangue = {
        labels: this.labels,
        datasets: this.checkSangue.filter(checkShow).map(function (element) {
          return {
            label: element.label,
            borderColor: element.color,
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: atendimentos.map(function (atendimento) {
              return atendimento.sangue[element.nome];
            }),
          };
        }),
      };
    },
  },
  firestore() {
    return {
      items: firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("timeline"),
    };
  },
  mounted() {
    this.$store.dispatch("firestore/getPreAtendimentoToFill");
  },
};
</script>

<style>
.small {
  max-width: 700px;
  margin: 10px 150px 10px 150px;
}
</style>
