var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"grey lighten-5",attrs:{"expand-on-hover":_vm.expandOnHover,"mini-variant":_vm.miniVariant,"right":_vm.right,"permanent":_vm.permanent},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{class:_vm.miniVariant && 'px-0',attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Admin")])],1)],1),_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":""}},[_c('v-divider'),_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                  isActive && 'router-link-active',
                  isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }