<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row class="d-flex justify-center mx-4 mt-8">
          CONTRATO DE PRESTAÇÃO DE SERVIÇOS
        </v-row></v-card-title
      >
      <v-card-text v-if="userInfo.contratoVersion !== undefined">
        <div v-if="userInfo.contratoVersion == 2">
          <v-row>
            <p class="mx-4">
              De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
              informações pessoais e dados cadastrais necessários dentro do
              sistema (software). Do outro lado a
              <strong>CONTRATADA</strong> também com todas as informações
              necessárias de informação dentro do sistema (software). Ambos
              totalmente disponibilizados de maneira online em sítio eletrônico
              (website).<br />
              Pelo presente instrumento particular de prestação de serviços têm
              entre si justos e acordados quanto segue:
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO OBJETO DO CONTRATO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como
              objeto, a prestação de serviços de nutrição e estética
              personalizados. <br /><strong>CLÁUSULA 2ª:</strong> A
              <strong>CONTRATADA</strong> executará os serviços diretamente a(o)
              <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço ser
              executado em menores de 18 (dezoito) anos ou em pessoas maiores de
              18 (dezoito) anos incapazes, a luz do direito civil brasileiro,
              deverá ter uma pessoa responsável, a qual apenas precisará ser
              informada no sistema.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da cláusula
              2ª, não se aplica aos menores de 18 (dezoito) anos emancipados, o
              qual fica condicionado a prestação do serviço pela
              <strong>
                <u
                  >apresentação de sentença judicial transitada em julgado
                  informando a sua emancipação ou certidão de casamento
                  (original ou cópia autenticada)</u
                ></strong
              >, a qual deverá ser encaminhado digitalizada e encaminhada para o
              setor jurídico da <strong>CONTRATANTE</strong> para autorizar a
              execução dos serviços.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DOS SERVIÇOS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 3ª:</strong> A
              <strong>CONTRATADA</strong> prestará um ou mais dos serviços
              previstos conforme o que estiver disponibilizado pelo “Anexo 1” e
              a <strong>CONTRATANTE</strong> tiver escolhido.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
              prestados pela <strong>CONTRATADA</strong> a(o)
              <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Pacote:</strong> entende-se como pacote conjunto de
              sessões e/ou consultas disponibilizados pelo sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – <strong>Pacote estético avulso:</strong> entende-se como
              pacote de sessões exclusivamente estéticas disponibilizado pelo
              sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III –
              <strong
                >Consultas nutricionais e sessões estéticas avulsas:</strong
              >
              entende-se como não dentro de qualquer pacote, contratando para
              serviço específico e pontual.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
              prestados no estabelecimento da <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> As consultas e o
              acompanhamento nutricional poderão ser realizados, intercalado ou
              exclusivamente, por vídeo conferência quando solicitado pelo(a)
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Todas as sessões de
              tratamento de estética serão realizadas exclusivamente no
              estabelecimento da <strong>CONTRATADA</strong>, não existe
              exceção.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Todos as sessões e consultas
              deverão ser previamente agendadas dentro do sistema
              disponibilizado online, o qual é um software, possível de ser
              acessado via computador ou aplicativo de telefone celular ambos
              conectados com a rede mundial de computadores (internet), pela
              <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo para
              que o(a) <strong>CONTRATANTE</strong> possa usufruir dos serviços
              contratados, sendo proibido a <strong>CONTRATADA</strong> prestar
              serviços para o(a) <strong>CONTRATANTE</strong> fora desse prazo:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Programa:</strong> prazo máximo de 12 (doze) semanas
              corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – <strong>Pacote estético avulso:</strong> prazo máximo de 30
              (trinta) dias corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – <strong>Consulta e/ou sessão avulsa:</strong> prazo máximo
              de 15 (quinze) dias corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser
              prorrogado nas seguintes hipóteses:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Doença (CID) grave que impossibilita a continuidade da
              prestação do serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Doença infecto contagiosa;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Quando decretado lockdown pelo chefe do poder executivo
              municipal (prefeito), estadual (governador) ou federal/central
              (presidente, primeiro-ministro, monarca, imperador) onde as
              sessões/consultas presenciais de estéticas/nutricionais estão
              sendo realizadas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
              resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
              e/ou da consulta, salvo nas hipóteses de doença previstas nos
              incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
              contados da contratação o(a) <strong>CONTRATANTE</strong> não
              poderá mais usufruir dos serviços, salvo em uma das hipóteses
              previstas no parágrafo 1º, cláusula 5ª, e nas condições
              estabelecidas no presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
              incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
              <strong>CONTRATANTE</strong> utilizar dos serviços da
              <strong>CONTRATADA</strong> nem a concessão de créditos pelo não
              uso dentro do prazo, também não haverá a devolução de qualquer
              quantia.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
              tenha terminado de pagar, não impedirá a
              <strong>CONTRATADA</strong> de realizar a cobrança dos valores não
              pagos, por todas as medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no
              inciso primeiro, do parágrafo 1º, da cláusula 5ª, haverá a
              suspensão do tratamento até ter condições aptas para continuar a
              receber os serviços, devendo apresentar laudo médico com matrícula
              no CRM (Conselho Regional de Medicina) válida, sob pena de perder
              os dias em que esteve impossibilitada de continuar a usufruir dos
              serviços.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no
              inciso segundo do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> precisará reagendar o dia em que
              faltar por motivo de doença, devendo apresentar atestado médico
              com matrícula no CRM e assinado, nas mesmas condições do parágrafo
              6º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no
              inciso quarto do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> terá a prorrogação automática pelo
              tempo em que o lockdown tiver sido decretado, nas seguintes
              condições:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – O serviço contratado não for considerado como serviço
              essencial previsto pelo decreto, caso seja considerado não
              ocorrerá a suspensão do serviço.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – A suspensão dos serviços pela decretação do lockdown somente
              ocorre se houver impedimento para o local onde o serviço é
              prestado, em situação em decretação em região diferente da qual o
              serviço é prestado não haverá prorrogação, devendo o
              <strong>CONTRATANTE</strong> usufruir conforme o que estiver
              estabelecido pelo presente Contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações serão
              no mesmo número de dias em que ocorreram as situações previstas
              nos incisos, do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as
              seguintes obrigações do(a) <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
              presente contrato;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Realizar todos os exames solicitados pela
              <strong>CONTRATADA</strong> para a efetiva prestação do serviço
              com qualidade, caso não seja realizado será total responsabilidade
              do(a) <strong>CONTRATANTE</strong>;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
              abatimentos, descontos ou dilações de prazo para o pagamento e
              execução dos serviços, sem o prévio conhecimento e autorização da
              <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as
              seguintes obrigações da <strong>CONTRATADA</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Atividades de seus funcionários serem exercidos com
              profissionalismo, respeito e zelo;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Respeitar o Código de Ética dos Nutricionistas;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Não revelar detalhes de suas atividades a terceiros, bem como,
              informações sobre seus clientes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
              (software) com acesso enquanto durar o
              programa/pacote/sessão/consulta, após o término do presente
              contrato será excluído a qualquer momento sem qualquer aviso
              prévio;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
              (software) com acesso enquanto durar o pacote/sessão/consulta,
              após o término do presente contrato será excluído a qualquer
              momento sem qualquer aviso prévio;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              e) Iniciar a consulta, tratamento ou sessão conforme data agendada
              após o pagamento da primeira parcela ou do pagamento integral;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              f) Manter o sigilo das informações da
              <strong>CONTRATANTE</strong> conforme o que estiver disposto na
              lei de nº 13.709/2018 (Lei Geral de Proteção de Dados).
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO DIREITO DE IMAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4" v-if="userInfo.imagemNao">
              <strike>
                <strong><u>Cláusula 8ª:</u></strong> O(A)
                <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará
                a
                <strong>CONTRATADA</strong>
                a realizar a divulgação de sua imagem de forma gratuita em suas
                redes sociais e em demais mídias, respeitando o Código de Ética
                dos Nutricionistas.
              </strike>
            </p>
            <p class="mx-4" v-else>
              <strong><u>Cláusula 8ª:</u></strong> O(A)
              <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará a
              <strong>CONTRATADA</strong>
              a realizar a divulgação de sua imagem de forma gratuita em suas
              redes sociais e em demais mídias, respeitando o Código de Ética
              dos Nutricionistas.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
              parte do(a) <strong>CONTRATANTE</strong>, após iniciado o programa
              ou pago, todos os valores pagos e a taxa administrativa
              (corresponde a 10% sobre o valor total do serviço) até a data de
              desistência não serão restituídos. Com relação aos valores pagos,
              apenas serão concedidos créditos correspondentes aos valores não
              usufruídas dentro do prazo para uso do serviço para uso posterior
              em sessões e/ou consultas
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela
              desistência do(a) <strong>CONTRATANTE</strong> poderá ser
              usufruído no prazo de 182 (cento e oitenta e dois) dias, contados
              da concessão desses créditos.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese
              haverá devolução de dinheiro após o início do tratamento,
              procedimento, consulta ou sessão, o que vier primeiro.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o(a)
              <strong>CONTRATANTE</strong> desista antes do início da prestação
              do serviço, apenas ficará retido o valor da taxa administrativa de
              10% sobre o valor do serviço total, devendo todo o resto ser
              restituído.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do
              serviço não existe mais a possibilidade de desistência e/ou
              restituição dos valores não gozados, pois a validade estará
              vencida, devendo o(a) <strong>CONTRATANTE</strong> pagar por todos
              os valores pendentes conforme o estipulado, caso ainda esteja em
              débito de pagamento, não sendo proibido a aplicação de juros nas
              condições previstas no presente contrato na cláusula 11ª,
              parágrafo 4º.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 10ª:</u></strong> A
              <strong>CONTRATADA</strong> poderá se recusar a prestar o serviço
              e/ou de rescindir o contrato quando:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Não preenchimento do questionário de saúde pré-consulta
              online;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Não pagamento total ou parcial;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar o pagamento por cheque sem fundos;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV – Não entrega dos exames solicitados antes do início do
              tratamento, quando esses forem pertinentes para a prestação do
              serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Não prestar as informações solicitadas pela nutricionista
              responsável pelo acompanhamento nutricional;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              VI – Solicitar atividade não compreendida como serviço contratado
              ou disponibilizado pela <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
              previstas nos incisos da cláusula 10ª a
              <strong>CONTRATADA</strong> devolverá os valores das sessões e
              consultas pagas e não usufruídas pelo(a)
              <strong>CONTRATANTE</strong>, devendo aplicar o que couber no
              presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
              serviço não impossibilita de a CONTRATADA realizar a cobrança dos
              valores remanescentes ainda não pagos, em caso de aplicação de uma
              das hipóteses da cláusula 10ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO PAGAMENTO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 11:</u></strong> A
              <strong>CONTRATADA</strong> receberá o pagamento conforme está
              disposto no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento são:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">I – Transferência bancária;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – PIX (forma de transferência bancária instantânea
              disponibilizado pelo Banco Central do Brasil);
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">III – Cartão de crédito;</p>
          </v-row>
          <v-row>
            <p class="mx-4">IV – Débito automático;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Cheque, desde que pago integralmente no ato da contratação ou
              antes da primeira consulta ou sessão, o que vier primeiro;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista integral
              ou da primeira parcela no cartão de crédito (independente de
              quantas vezes seja parcelado no cartão) autoriza o início do
              tratamento, consulta, sessão ou procedimento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
              cheque apenas será confirmada quando sacado o cheque antes da
              primeira sessão ou consulta.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em
              que for sacado esteja sem fundos ou qualquer outro resultado que
              não seja a entrega do dinheiro correspondente pela instituição
              financeira a favor da CONTRATADA, não impede dessa tomar as
              medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento
              são proibidas de serem feitas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de
              pagamento incidirá juros mensais de 1% (um por cento) ao mês de
              atraso, mais multa na importância de 2% (dois por cento)
              corrigidos pelo IGP-M (Índice Geral de Preços Mercado), o qual é
              disponibilizado pela Fundação Getúlio Vargas. Na impossibilidade
              de aplicação do índice por qualquer motivo aplicar-se-á o índice
              inflacionário oficial do governo federal.
            </p></v-row
          >
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA VIGÊNCIA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
              vigência por prazo indeterminado até o cumprimento de todas as
              suas obrigações.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá
              ter prazo determinado a depender do tratamento ou do pacote ou do
              programa aderido conforme estiver no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
              instrumento de contrato, não extingue os direitos e obrigações que
              as partes tenham entre si e para com terceiros.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA CLAUSULA DE ARBITRAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                ><u
                  >CLÁUSULA 13ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL
                  NOS TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO
                  CONTRATANTE: FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO,
                  QUE QUALQUER PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE AS
                  PARTES, QUE SEJA ORIGINADO OU RELACIONADO COM O PRESENTE
                  CONTRATO, SERÁ DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE
                  ARBITRAGEM, CONFORME O REGULAMENTO DE ARBITRAGEM DE QUALQUER
                  TRIBUNAL ARBITRAL, COM SEDE NO MUNICÍPIO DE BELÉM, CAPITAL DO
                  ESTADO DO PARÁ, POR INTERMÉDIO DE UM OU MAIS ÁRBITROS NOMEADOS
                  E A SENTENÇA POR ELE(S) PROLATADA PODERÁ SER EXECUTADA EM
                  QUALQUER JUÍZO QUE SOBRE ELA TENHA JURISDIÇÃO. CUJO INTUITO
                  DESSA CLAUSULA É A CELERIDADE E A SOLUÇÃO DE TODOS OS
                  PROBLEMAS ORIUNDOS DO PRESENTE CONTRATO.
                </u></strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong
                >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
                CONTRATAÇÃO</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 14ª:</u></strong> O
              <strong>CONTRATANTE</strong> terá como vantagens durante o período
              da contratação:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Suporte nutricional até a data de término em horário
              comercial;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – Caso algum dos programas, previstos no anexo 1, seja
              contratado terá cumulativamente, além do previsto no inciso I,
              acesso a e-books (livros eletrônicos/digitais), acesso ao sistema
              (software) e vídeos aulas educativos.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 15ª:</u></strong> Condições para participar:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Efetuar o pagamento total do programa na 1ª consulta ou antes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >II – Agendar dias fixos na semana para realização dos
                tratamentos estéticos;</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar todos os procedimentos e consultas até a data de
              término.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 16ª:</u></strong> Regras e observações
              importantes:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >I – Cada cliente deverá ter seu dia fixo para realizar os
                tratamentos, o não comparecimento na hora marcada (falta)
                implica em perda da sessão, pois o horário ficará bloqueado na
                agenda sem a possibilidade de disponibilizá-lo para outro
                cliente</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – Não será possível desmarcar e remarcar sessões toda semana e
              nem desmarcar somente no dia da sessão, o cliente deverá alterar
              os dias fixos com o máximo de antecedência para não prejudicar a
              agenda, os demais clientes e seu próprio tratamento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Em caso de pausa do tratamento até a data de término, não
              existe garantia posterior de os mesmos dias fixos estejam
              disponíveis quando retornar.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV – As demais consultas, podem ser feitas por meio de
              videoconferência, de acordo com o código de ética do
              nutricionista. Desta forma, o não comparecimento físico ou on-line
              na data marcada prejudica a agenda de consultas, atrasa o
              tratamento e pode implicar em perda da consulta.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Cada consulta deve ser agendada com intervalo médio de 30
              (trinta) dias entre uma e outra.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              VI – Os cardápios e prescrições serão disponibilizados no software
              em até 3 dias úteis após a consulta e com intervalo mínimo de 30
              dias entre um e outro.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DISPOSIÇÕES FINAIS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 17ª:</u></strong> Em observância do disposto
              no artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor),
              declara expressamente o <strong>CONTRATANTE</strong> que, em
              virtude de ter lido todas as cláusulas constantes deste contrato,
              está ciente de todas elas, aceitando-as expressamente e de poder
              acessar no sistema o presente contrato quando quiser, antes,
              durante e depois de finalizado o presente contrato.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>Anexo 1</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>1. Programa Clínico Funcional - R$900,00</strong><br />3
              consultas mais uma avaliação final
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>2. Programa de Definição Corporal - R$900,00</strong
              ><br />3 consultas mais uma avaliação final
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>3. Programa de Modulação Intestinal - R$990,00</strong
              ><br />3 consultas mais uma avaliação final
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4. Programa de Emagrecimento 12 (doze) semanas</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.1. Plano básico</strong><br />3 (três) consultas + 10
              (dez) semanas de pacote estético em 1 (uma) área: R$ 1.860,00
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.2. Plano Premium</strong><br />3 (três) consultas + 10
              (dez) semanas de pacote estético em 2 (duas) áreas: R$ 2.020,00
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.3. Plano VIP</strong><br />3 (três) consultas + 10 (dez)
              semanas de pacote estético em 3 (três) áreas: R$ 2.380,00<br />
              *No plano VIP ainda possui a vantagem exclusiva de 1(uma) pausa no
              programa de até 4 semanas consecutivas em caso de férias, doença
              ou viagem.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>5. Pacotes estéticos adicionais</strong><br />
              5.1. 1 (um) pacote mensal para 1(uma) área: R$ 480,00 (R$
              120,00/área)<br />
              5.2. 1 (um) pacote mensal para 2(duas) áreas: R$ 720,00 (R$
              90,00/área)<br />
              5.3. 1 (um) pacote mensal para 3(três) áreas: R$ 960,00 (R$
              80,00/área)<br />
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>Anexo 2 - Tipos de tratamentos (não invasivos)</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              - Para redução de celulite, gordura localizada, flacidez, retenção
              hídrica, ou estímulo muscular para definição corporal.<br />
              - Utilização de aparelhos e/ou procedimentos manuais (massagem e
              drenagem)<br />
              - Utilização de ativos durante sessões e home care (opcional)<br />
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4">
              <strong
                >DEFINIÇÃO DE ÁREA (1 área é equivalente a ½ folha a A4)</strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">Quantidade de áreas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="areaItem in areaItems" :key="areaItem.nome">
                    <td>{{ areaItem.nome }}</td>
                    <td>{{ areaItem.qtd }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </div>
        <div v-else-if="userInfo.contratoVersion == 3">
          <v-row>
            <p class="mx-4">
              De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
              informações pessoais e dados cadastrais necessários dentro do
              sistema (software). Do outro lado a
              <strong>CONTRATADA</strong> também com todas as informações
              necessárias de informação dentro do sistema (software). Ambos
              totalmente disponibilizados de maneira online em sítio eletrônico
              (website).<br />
              Pelo presente instrumento particular de prestação de serviços têm
              entre si justos e acordados quanto segue:
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO OBJETO DO CONTRATO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como
              objeto, a prestação de serviços de nutrição e estética
              personalizados. <br /><strong>CLÁUSULA 2ª:</strong> A
              <strong>CONTRATADA</strong> executará os serviços diretamente a(o)
              <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço ser
              executado em menores de 18 (dezoito) anos ou em pessoas maiores de
              18 (dezoito) anos incapazes, a luz do direito civil brasileiro,
              deverá ter uma pessoa responsável, a qual apenas precisará ser
              informada no sistema.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da cláusula
              2ª, não se aplica aos menores de 18 (dezoito) anos emancipados, o
              qual fica condicionado a prestação do serviço pela
              <strong>
                <u
                  >apresentação de sentença judicial transitada em julgado
                  informando a sua emancipação ou certidão de casamento
                  (original ou cópia autenticada)</u
                ></strong
              >, a qual deverá ser encaminhado digitalizada e encaminhada para o
              setor jurídico da <strong>CONTRATANTE</strong> para autorizar a
              execução dos serviços.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DOS SERVIÇOS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 3ª:</strong> A
              <strong>CONTRATADA</strong> prestará um ou mais dos serviços
              previstos conforme o que estiver disponibilizado pelo “Anexo 1” e
              a <strong>CONTRATANTE</strong> tiver escolhido.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
              prestados pela <strong>CONTRATADA</strong> a(o)
              <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Pacote:</strong> entende-se como pacote conjunto de
              sessões e/ou consultas disponibilizados pelo sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – <strong>Pacote estético avulso:</strong> entende-se como
              pacote de sessões exclusivamente estéticas disponibilizado pelo
              sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III –
              <strong
                >Consultas nutricionais e sessões estéticas avulsas:</strong
              >
              entende-se como não dentro de qualquer pacote, contratando para
              serviço específico e pontual.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
              prestados no estabelecimento da <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> As consultas e o
              acompanhamento nutricional poderão ser realizados, intercalado ou
              exclusivamente, por vídeo conferência quando solicitado pelo(a)
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Todas as sessões de
              tratamento de estética serão realizadas exclusivamente no
              estabelecimento da <strong>CONTRATADA</strong>, não existe
              exceção.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Todos as sessões e consultas
              deverão ser previamente agendadas dentro do sistema
              disponibilizado online, o qual é um software, possível de ser
              acessado via computador ou aplicativo de telefone celular ambos
              conectados com a rede mundial de computadores (internet), pela
              <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo para
              que o(a) <strong>CONTRATANTE</strong> possa usufruir dos serviços
              contratados, sendo proibido a <strong>CONTRATADA</strong> prestar
              serviços para o(a) <strong>CONTRATANTE</strong> fora desse prazo:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Programa:</strong> prazo máximo de 12 (doze) semanas
              corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – <strong>Pacote estético avulso:</strong> prazo máximo de 30
              (trinta) dias corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – <strong>Consulta e/ou sessão avulsa:</strong> prazo máximo
              de 15 (quinze) dias corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser
              prorrogado nas seguintes hipóteses:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Doença (CID) grave que impossibilita a continuidade da
              prestação do serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Doença infecto contagiosa;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Quando decretado lockdown pelo chefe do poder executivo
              municipal (prefeito), estadual (governador) ou federal/central
              (presidente, primeiro-ministro, monarca, imperador) onde as
              sessões/consultas presenciais de estéticas/nutricionais estão
              sendo realizadas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
              resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
              e/ou da consulta, salvo nas hipóteses de doença previstas nos
              incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
              contados da contratação o(a) <strong>CONTRATANTE</strong> não
              poderá mais usufruir dos serviços, salvo em uma das hipóteses
              previstas no parágrafo 1º, cláusula 5ª, e nas condições
              estabelecidas no presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
              incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
              <strong>CONTRATANTE</strong> utilizar dos serviços da
              <strong>CONTRATADA</strong> nem a concessão de créditos pelo não
              uso dentro do prazo, também não haverá a devolução de qualquer
              quantia.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
              tenha terminado de pagar, não impedirá a
              <strong>CONTRATADA</strong> de realizar a cobrança dos valores não
              pagos, por todas as medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no
              inciso primeiro, do parágrafo 1º, da cláusula 5ª, haverá a
              suspensão do tratamento até ter condições aptas para continuar a
              receber os serviços, devendo apresentar laudo médico com matrícula
              no CRM (Conselho Regional de Medicina) válida, sob pena de perder
              os dias em que esteve impossibilitada de continuar a usufruir dos
              serviços.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no
              inciso segundo do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> precisará reagendar o dia em que
              faltar por motivo de doença, devendo apresentar atestado médico
              com matrícula no CRM e assinado, nas mesmas condições do parágrafo
              6º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no
              inciso quarto do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> terá a prorrogação automática pelo
              tempo em que o lockdown tiver sido decretado, nas seguintes
              condições:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – O serviço contratado não for considerado como serviço
              essencial previsto pelo decreto, caso seja considerado não
              ocorrerá a suspensão do serviço.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – A suspensão dos serviços pela decretação do lockdown somente
              ocorre se houver impedimento para o local onde o serviço é
              prestado, em situação em decretação em região diferente da qual o
              serviço é prestado não haverá prorrogação, devendo o
              <strong>CONTRATANTE</strong> usufruir conforme o que estiver
              estabelecido pelo presente Contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações serão
              no mesmo número de dias em que ocorreram as situações previstas
              nos incisos, do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as
              seguintes obrigações do(a) <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
              presente contrato;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Realizar todos os exames solicitados pela
              <strong>CONTRATADA</strong> para a efetiva prestação do serviço
              com qualidade, caso não seja realizado será total responsabilidade
              do(a) <strong>CONTRATANTE</strong>;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
              abatimentos, descontos ou dilações de prazo para o pagamento e
              execução dos serviços, sem o prévio conhecimento e autorização da
              <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as
              seguintes obrigações da <strong>CONTRATADA</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Atividades de seus funcionários serem exercidos com
              profissionalismo, respeito e zelo;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Respeitar o Código de Ética dos Nutricionistas;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Não revelar detalhes de suas atividades a terceiros, bem como,
              informações sobre seus clientes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
              (software) com acesso enquanto durar o
              programa/pacote/sessão/consulta, após o término do presente
              contrato será excluído a qualquer momento sem qualquer aviso
              prévio;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
              (software) com acesso enquanto durar o pacote/sessão/consulta,
              após o término do presente contrato será excluído a qualquer
              momento sem qualquer aviso prévio;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              e) Iniciar a consulta, tratamento ou sessão conforme data agendada
              após o pagamento da primeira parcela ou do pagamento integral;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              f) Manter o sigilo das informações da
              <strong>CONTRATANTE</strong> conforme o que estiver disposto na
              lei de nº 13.709/2018 (Lei Geral de Proteção de Dados).
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO DIREITO DE IMAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4" v-if="userInfo.imagemNao">
              <strike>
                <strong><u>Cláusula 8ª:</u></strong> O(A)
                <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará
                a <strong>CONTRATADA</strong> a realizar a divulgação de sua
                imagem, isoladamente ou do lado dos demais profissionais da
                <strong>CONTRATADA</strong>, de forma gratuita em suas redes
                sociais e em demais mídias, respeitando o Código de Ética dos
                Nutricionistas que não autoriza divulgação de fotos íntimas de
                antes e depois de serviços nutricionais. Para fins de
                demonstração de comparação de resultados de procedimentos
                estéticos, nenhuma foto íntima poderá ser publicada pela
                <strong>CONTRATADA</strong> sem prévia autorização por escrito
                do(a) <strong>CONTRATANTE</strong>.
              </strike>
            </p>
            <p class="mx-4" v-else>
              <strong><u>Cláusula 8ª:</u></strong> O(A)
              <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará a
              <strong>CONTRATADA</strong> a realizar a divulgação de sua imagem,
              isoladamente ou do lado dos demais profissionais da
              <strong>CONTRATADA</strong>, de forma gratuita em suas redes
              sociais e em demais mídias, respeitando o Código de Ética dos
              Nutricionistas que não autoriza divulgação de fotos íntimas de
              antes e depois de serviços nutricionais. Para fins de demonstração
              de comparação de resultados de procedimentos estéticos, nenhuma
              foto íntima poderá ser publicada pela
              <strong>CONTRATADA</strong> sem prévia autorização por escrito
              do(a) <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
              parte do(a) <strong>CONTRATANTE</strong>, após iniciado o programa
              ou pago, todos os valores pagos e a taxa administrativa
              (corresponde a 10% sobre o valor total do serviço) até a data de
              desistência não serão restituídos. Com relação aos valores pagos,
              apenas serão concedidos créditos correspondentes aos valores não
              usufruídas dentro do prazo para uso do serviço para uso posterior
              em sessões e/ou consultas
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela
              desistência do(a) <strong>CONTRATANTE</strong> poderá ser
              usufruído no prazo de 182 (cento e oitenta e dois) dias, contados
              da concessão desses créditos.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese
              haverá devolução de dinheiro após o início do tratamento,
              procedimento, consulta ou sessão, o que vier primeiro.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o(a)
              <strong>CONTRATANTE</strong> desista antes do início da prestação
              do serviço, apenas ficará retido o valor da taxa administrativa de
              10% sobre o valor do serviço total, devendo todo o resto ser
              restituído.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do
              serviço não existe mais a possibilidade de desistência e/ou
              restituição dos valores não gozados, pois a validade estará
              vencida, devendo o(a) <strong>CONTRATANTE</strong> pagar por todos
              os valores pendentes conforme o estipulado, caso ainda esteja em
              débito de pagamento, não sendo proibido a aplicação de juros nas
              condições previstas no presente contrato na cláusula 11ª,
              parágrafo 4º.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 10ª:</u></strong> A
              <strong>CONTRATADA</strong> poderá se recusar a prestar o serviço
              e/ou de rescindir o contrato quando:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Não preenchimento do questionário de saúde pré-consulta
              online;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Não pagamento total ou parcial;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar o pagamento por cheque sem fundos;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV – Não entrega dos exames solicitados antes do início do
              tratamento, quando esses forem pertinentes para a prestação do
              serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Não prestar as informações solicitadas pela nutricionista
              responsável pelo acompanhamento nutricional;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              VI – Solicitar atividade não compreendida como serviço contratado
              ou disponibilizado pela <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
              previstas nos incisos da cláusula 10ª a
              <strong>CONTRATADA</strong> devolverá os valores das sessões e
              consultas pagas e não usufruídas pelo(a)
              <strong>CONTRATANTE</strong>, devendo aplicar o que couber no
              presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
              serviço não impossibilita de a CONTRATADA realizar a cobrança dos
              valores remanescentes ainda não pagos, em caso de aplicação de uma
              das hipóteses da cláusula 10ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO PAGAMENTO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 11:</u></strong> A
              <strong>CONTRATADA</strong> receberá o pagamento conforme está
              disposto no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento são:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">I – Transferência bancária;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – PIX (forma de transferência bancária instantânea
              disponibilizado pelo Banco Central do Brasil);
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">III – Cartão de crédito;</p>
          </v-row>
          <v-row>
            <p class="mx-4">IV – Débito automático;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Cheque, desde que pago integralmente no ato da contratação ou
              antes da primeira consulta ou sessão, o que vier primeiro;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista integral
              ou da primeira parcela no cartão de crédito (independente de
              quantas vezes seja parcelado no cartão) autoriza o início do
              tratamento, consulta, sessão ou procedimento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
              cheque apenas será confirmada quando sacado o cheque antes da
              primeira sessão ou consulta.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em
              que for sacado esteja sem fundos ou qualquer outro resultado que
              não seja a entrega do dinheiro correspondente pela instituição
              financeira a favor da <strong>CONTRATADA</strong>, não impede
              dessa tomar as medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento
              são proibidas de serem feitas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de
              pagamento incidirá juros mensais de 1% (um por cento) ao mês de
              atraso, mais multa na importância de 2% (dois por cento)
              corrigidos pelo IGP-M (Índice Geral de Preços Mercado), o qual é
              disponibilizado pela Fundação Getúlio Vargas. Na impossibilidade
              de aplicação do índice por qualquer motivo aplicar-se-á o índice
              inflacionário oficial do governo federal.
            </p></v-row
          >
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA VIGÊNCIA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
              vigência por prazo indeterminado até o cumprimento de todas as
              suas obrigações.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá
              ter prazo determinado a depender do tratamento ou do pacote ou do
              programa aderido conforme estiver no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
              instrumento de contrato, não extingue os direitos e obrigações que
              as partes tenham entre si e para com terceiros.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA PROTEÇÃO DE DADOS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 13ª:</u></strong>
              <strong>CONTRATANTE</strong> ao assinar o presente instrumento
              contratual autoriza de imediato que todos os seus dados que vierem
              a ser coletados antes e durante a prestação dos serviços pela
              <strong>CONTRATADA</strong>, sejam compartilhados com a empresa
              franqueadora (responsável pelo software que armazena todos os
              dados dos clientes), enquanto durar a prestação do serviço e após,
              cujo objetivo de garantir a qualidade do serviço prestado ao
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4 mt-8">
              <strong><u>CLÁUSULA 14ª:</u></strong>
              A <strong>CONTRATADA</strong>, por si e por seus colaboradores,
              obriga-se a atuar no presente Contrato em conformidade com a
              Legislação vigente sobre Proteção de Dados Pessoais e as
              determinações de órgãos reguladores/fiscalizadores sobre a
              matéria, em especial a Lei 13.709/2018, além das demais normas e
              políticas de proteção de dados de cada país onde houver qualquer
              tipo de tratamento dos dados dos clientes, o que inclui os dados
              dos clientes desta. No manuseio dos dados a
              <strong>CONTRATADA</strong> deverá:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (i) Tratar os dados pessoais a que tiver acesso em conformidade
              com estas cláusulas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (ii) Manter e utilizar medidas de segurança administrativas,
              técnicas e físicas apropriadas e suficientes para proteger a
              confidencialidade e integridade de todos os dados pessoais
              mantidos ou consultados/transmitidos eletronicamente, para
              garantir a proteção desses dados contra acesso não autorizado,
              destruição, uso, modificação, divulgação ou perda acidental ou
              indevida.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (iii) Acessar os dados dentro de seu escopo e na medida abrangida
              por sua permissão de acesso (autorização) e que os dados pessoais
              não podem ser lidos, copiados, modificados ou removidos sem
              autorização expressa e por escrito da
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (iv) Garantir, por si própria ou quaisquer de seus empregados,
              prepostos, sócios, diretores, representantes ou terceiros
              contratados, a confidencialidade dos dados processados,
              assegurando que todos os seus colaboradores prepostos, sócios,
              diretores, representantes ou terceiros contratados que lidam com
              os dados pessoais sob responsabilidade da
              <strong>CONTRATANTE</strong> assinaram Acordo de Confidencialidade
              e/ou possuem em seus contratos cláusulas de confidencialidade com
              a <strong>CONTRATADA</strong>, bem como a manter quaisquer Dados
              Pessoais estritamente confidenciais e de não os utilizar para
              outros fins, com exceção da prestação de serviços à
              <strong>CONTRATANTE</strong>. Ainda, treinará e orientará a sua
              equipe sobre as disposições legais aplicáveis em relação à
              proteção de dados.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 15ª:</u></strong> Os dados pessoais não
              poderão ser revelados a terceiros, com exceção da prévia
              autorização por escrito da <strong>CONTRATANTE</strong>, quer
              direta ou indiretamente, seja mediante a distribuição de cópias,
              resumos, compilações, extratos, análises, estudos ou outros meios
              que contenham ou de outra forma reflitam referidas Informações, já
              ficando desde já autorizado o compartilhamento dos dados com a
              empresa franqueadora (responsável pelo software que armazena todos
              os dados dos clientes).
              <strong><u>Parágrafo único:</u></strong> Caso a
              <strong>CONTRATADA</strong> seja obrigada por determinação legal a
              fornecer dados pessoais a uma autoridade pública, deverá informar
              previamente a <strong>CONTRATANTE</strong> para que esta tome as
              medidas que julgar cabíveis.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA CLAUSULA DE ARBITRAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                ><u
                  >CLÁUSULA 16ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL
                  NOS TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO
                  CONTRATANTE: FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO,
                  QUE QUALQUER PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE AS
                  PARTES, QUE SEJA ORIGINADO OU RELACIONADO COM O PRESENTE
                  CONTRATO, SERÁ DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE
                  ARBITRAGEM, CONFORME O REGULAMENTO DE ARBITRAGEM DE QUALQUER
                  TRIBUNAL ARBITRAL, COM SEDE NO MUNICÍPIO DE BELÉM, CAPITAL DO
                  ESTADO DO PARÁ, POR INTERMÉDIO DE UM OU MAIS ÁRBITROS NOMEADOS
                  E A SENTENÇA POR ELE(S) PROLATADA PODERÁ SER EXECUTADA EM
                  QUALQUER JUÍZO QUE SOBRE ELA TENHA JURISDIÇÃO. CUJO INTUITO
                  DESSA CLAUSULA É A CELERIDADE E A SOLUÇÃO DE TODOS OS
                  PROBLEMAS ORIUNDOS DO PRESENTE CONTRATO.
                </u></strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong
                >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
                CONTRATAÇÃO</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 17ª:</u></strong> O
              <strong>CONTRATANTE</strong> terá como vantagens durante o período
              da contratação:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Suporte nutricional até a data de término em horário
              comercial;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – Caso algum dos programas, previstos no anexo 1, seja
              contratado terá cumulativamente, além do previsto no inciso I,
              acesso a e-books (livros eletrônicos/digitais), acesso ao sistema
              (software) e vídeos aulas educativos.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 18ª:</u></strong> Condições para participar:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Efetuar o pagamento total do programa na 1ª consulta ou antes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >II – Agendar dias fixos na semana para realização dos
                tratamentos estéticos;</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar todos os procedimentos e consultas até a data de
              término.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 19ª:</u></strong> Regras e observações
              importantes:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >I – Cada cliente deverá ter seu dia fixo para realizar os
                tratamentos, o não comparecimento na hora marcada (falta)
                implica em perda da sessão, pois o horário ficará bloqueado na
                agenda sem a possibilidade de disponibilizá-lo para outro
                cliente</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – Não será possível desmarcar e remarcar sessões toda semana e
              nem desmarcar somente no dia da sessão, o cliente deverá alterar
              os dias fixos com o máximo de antecedência para não prejudicar a
              agenda, os demais clientes e seu próprio tratamento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Em caso de pausa do tratamento até a data de término, não
              existe garantia posterior de os mesmos dias fixos estejam
              disponíveis quando retornar.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV – As demais consultas, podem ser feitas por meio de
              videoconferência, de acordo com o código de ética do
              nutricionista. Desta forma, o não comparecimento físico ou on-line
              na data marcada prejudica a agenda de consultas, atrasa o
              tratamento e pode implicar em perda da consulta.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Cada consulta deve ser agendada com intervalo médio de 30
              (trinta) dias entre uma e outra.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              VI – Os cardápios e prescrições serão disponibilizados no software
              em até 3 dias úteis após a consulta e com intervalo mínimo de 30
              dias entre um e outro.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DISPOSIÇÕES FINAIS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 20ª:</u></strong> Em observância do disposto
              no artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor),
              declara expressamente o <strong>CONTRATANTE</strong> que, em
              virtude de ter lido todas as cláusulas constantes deste contrato,
              está ciente de todas elas, aceitando-as expressamente e de poder
              acessar no sistema o presente contrato quando quiser, antes,
              durante e depois de finalizado o presente contrato.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>Anexo 1</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>1. Programa Clínico Funcional - R$900,00</strong><br />3
              consultas mais uma avaliação final
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>2. Programa de Definição Corporal - R$900,00</strong
              ><br />3 consultas mais uma avaliação final
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>3. Programa de Modulação Intestinal - R$990,00</strong
              ><br />3 consultas mais uma avaliação final
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4. Programa de Emagrecimento 12 (doze) semanas</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.1. Plano básico</strong><br />3 (três) consultas + 10
              (dez) semanas de pacote estético em 1 (uma) área: R$ 1.860,00
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.2. Plano Premium</strong><br />3 (três) consultas + 10
              (dez) semanas de pacote estético em 2 (duas) áreas: R$ 2.020,00
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.3. Plano VIP</strong><br />3 (três) consultas + 10 (dez)
              semanas de pacote estético em 3 (três) áreas: R$ 2.380,00<br />
              *No plano VIP ainda possui a vantagem exclusiva de 1(uma) pausa no
              programa de até 4 semanas consecutivas em caso de férias, doença
              ou viagem.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>5. Pacotes estéticos adicionais</strong><br />
              5.1. 1 (um) pacote mensal para 1(uma) área: R$ 480,00 (R$
              120,00/área)<br />
              5.2. 1 (um) pacote mensal para 2(duas) áreas: R$ 720,00 (R$
              90,00/área)<br />
              5.3. 1 (um) pacote mensal para 3(três) áreas: R$ 960,00 (R$
              80,00/área)<br />
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>Anexo 2 - Tipos de tratamentos (não invasivos)</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              - Para redução de celulite, gordura localizada, flacidez, retenção
              hídrica, ou estímulo muscular para definição corporal.<br />
              - Utilização de aparelhos e/ou procedimentos manuais (massagem e
              drenagem)<br />
              - Utilização de ativos durante sessões e home care (opcional)<br />
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4">
              <strong
                >DEFINIÇÃO DE ÁREA (1 área é equivalente a ½ folha a A4)</strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">Quantidade de áreas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="areaItem in areaItems" :key="areaItem.nome">
                    <td>{{ areaItem.nome }}</td>
                    <td>{{ areaItem.qtd }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </div>
        <div v-else-if="userInfo.contratoVersion == 4">
          <v-row>
            <p>
              De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
              informações pessoais e dados cadastrais necessários dentro do
              sistema (software). Do outro lado a
              <strong>CONTRATADA</strong> também com todas as informações
              necessárias de informação dentro do sistema (software). Ambos
              totalmente disponibilizados de maneira online em sítio eletrônico
              (website).<br />
              Pelo presente instrumento particular de prestação de serviços têm
              entre si justos e acordados quanto segue:
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO OBJETO DO CONTRATO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como
              objeto, a prestação de serviços personalizados de nutrição,
              estética e terapias complementares. <br /><strong
                >CLÁUSULA 2ª:</strong
              >
              A <strong>CONTRATADA</strong> executará os serviços diretamente
              a(o) <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço ser
              executado em menores de 18 (dezoito) anos ou em pessoas maiores de
              18 (dezoito) anos incapazes, a luz do direito civil brasileiro,
              deverá ter uma pessoa responsável, a qual apenas precisará ser
              informada no sistema.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da cláusula
              2ª, não se aplica aos menores de 18 (dezoito) anos emancipados, o
              qual fica condicionado a prestação do serviço pela
              <strong>
                <u
                  >apresentação de sentença judicial transitada em julgado
                  informando a sua emancipação ou certidão de casamento
                  (original ou cópia autenticada)</u
                ></strong
              >, a qual deverá ser encaminhado digitalizada e encaminhada para o
              setor jurídico da <strong>CONTRATANTE</strong> para autorizar a
              execução dos serviços.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DOS SERVIÇOS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 3ª:</strong> A
              <strong>CONTRATADA</strong> prestará um ou mais dos serviços
              previstos conforme o que estiver disponibilizado pelo “Anexo 1” e
              a <strong>CONTRATANTE</strong> tiver escolhido.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
              prestados pela <strong>CONTRATADA</strong> a(o)
              <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Programa:</strong> entende-se como programa o conjunto
              de consultas com ou sem sessões estéticas, disponibilizados pelo
              sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II –
              <strong>Pacote de sessões estéticas avulsas:</strong> entende-se
              como pacote de sessões exclusivamente estéticas de 4 (quatro)
              semanas disponibilizado pelo sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III –
              <strong>Pacote de terapias complementares avulso:</strong>
              entende-se como pacote de sessões exclusivamente de terapias
              complementares disponibilizado pelo sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV –
              <strong
                >Consultas nutricionais, sessões estéticas avulsas e sessões de
                terapias complementares avulsas:</strong
              >
              entende-se como uma única consulta ou única sessão, não dentro de
              qualquer programa ou pacote, contratando para serviço específico e
              pontual.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V –
              <strong
                >Coleta para realização de exames nutri genéticos, de
                hipersensibilidade alimentar e de perfil intestinal:</strong
              >
              na clínica será realizado apenas a coleta para encaminhar a
              amostra para laboratório especializado para a realização do exame.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
              prestados no estabelecimento da <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> As consultas e o
              acompanhamento nutricional poderão ser realizados, intercalado ou
              exclusivamente, por vídeo conferência quando solicitado pelo(a)
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Todas as sessões de
              tratamento de estética serão realizadas exclusivamente no
              estabelecimento da <strong>CONTRATADA</strong>, não existe
              exceção.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Todos as sessões e consultas
              deverão ser previamente agendadas dentro do sistema
              disponibilizado online, o qual é um software, possível de ser
              acessado via computador ou aplicativo de telefone celular ambos
              conectados com a rede mundial de computadores (internet), pela
              <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo para
              que o(a) <strong>CONTRATANTE</strong> possa usufruir dos serviços
              contratados, sendo proibido a <strong>CONTRATADA</strong> prestar
              serviços para o(a) <strong>CONTRATANTE</strong> fora desse prazo:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Programa:</strong> prazo máximo de 90 (noventa) dias
              corridas a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II –
              <strong>Pacote estético e de terapia complementar avulso:</strong>
              prazo máximo de 30 (trinta) dias corridos a contar da data da
              contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – <strong>Consulta e/ou sessão avulsa:</strong> prazo máximo
              de 15 (quinze) dias corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser
              prorrogado nas seguintes hipóteses:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Doença (CID) grave que impossibilita a continuidade da
              prestação do serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Doença infecto contagiosa;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Quando decretado lockdown pelo chefe do poder executivo
              municipal (prefeito), estadual (governador) ou federal/central
              (presidente, primeiro-ministro, monarca, imperador) onde as
              sessões/consultas presenciais de estéticas/nutricionais estão
              sendo realizadas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
              resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
              e/ou da consulta, salvo nas hipóteses de doença previstas nos
              incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
              contados da contratação o(a) <strong>CONTRATANTE</strong> não
              poderá mais usufruir dos serviços, salvo em uma das hipóteses
              previstas no parágrafo 1º, cláusula 5ª, e nas condições
              estabelecidas no presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
              incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
              <strong>CONTRATANTE</strong> utilizar dos serviços da
              <strong>CONTRATADA</strong> nem a concessão de créditos pelo não
              uso dentro do prazo, também não haverá a devolução de qualquer
              quantia.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
              tenha terminado de pagar, não impedirá a
              <strong>CONTRATADA</strong> de realizar a cobrança dos valores não
              pagos, por todas as medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no
              inciso primeiro, do parágrafo 1º, da cláusula 5ª, haverá a
              suspensão do tratamento até ter condições aptas para continuar a
              receber os serviços, devendo apresentar laudo médico com matrícula
              no CRM (Conselho Regional de Medicina) válida, sob pena de perder
              os dias em que esteve impossibilitada de continuar a usufruir dos
              serviços.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no
              inciso segundo do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> precisará reagendar o dia em que
              faltar por motivo de doença, devendo apresentar atestado médico
              com matrícula no CRM e assinado, nas mesmas condições do parágrafo
              6º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no
              inciso quarto do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> terá a prorrogação automática pelo
              tempo em que o lockdown tiver sido decretado, nas seguintes
              condições:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – O serviço contratado não for considerado como serviço
              essencial previsto pelo decreto, caso seja considerado não
              ocorrerá a suspensão do serviço.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – A suspensão dos serviços pela decretação do lockdown somente
              ocorre se houver impedimento para o local onde o serviço é
              prestado, em situação em decretação em região diferente da qual o
              serviço é prestado não haverá prorrogação, devendo o
              <strong>CONTRATANTE</strong> usufruir conforme o que estiver
              estabelecido pelo presente Contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações serão
              no mesmo número de dias em que ocorreram as situações previstas
              nos incisos, do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as
              seguintes obrigações do(a) <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
              presente contrato;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Realizar todos os exames solicitados pela
              <strong>CONTRATADA</strong> para a efetiva prestação do serviço
              com qualidade, caso não seja realizado será total responsabilidade
              do(a) <strong>CONTRATANTE</strong>;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
              abatimentos, descontos ou dilações de prazo para o pagamento e
              execução dos serviços, sem o prévio conhecimento e autorização da
              <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as
              seguintes obrigações da <strong>CONTRATADA</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Atividades de seus funcionários serem exercidos com
              profissionalismo, respeito e zelo;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Respeitar o Código de Ética dos Nutricionistas;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Não revelar detalhes de suas atividades a terceiros, bem como,
              informações sobre seus clientes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
              (software) com acesso enquanto durar o
              programa/pacote/sessão/consulta, após o término do presente
              contrato será excluído a qualquer momento sem qualquer aviso
              prévio;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
              (software) com acesso enquanto durar o pacote/sessão/consulta,
              após o término do presente contrato será excluído a qualquer
              momento sem qualquer aviso prévio;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              e) Iniciar a consulta, tratamento ou sessão conforme data agendada
              após o pagamento da primeira parcela ou do pagamento integral;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              f) Manter o sigilo das informações da
              <strong>CONTRATANTE</strong> conforme o que estiver disposto na
              lei de nº 13.709/2018 (Lei Geral de Proteção de Dados).
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO DIREITO DE IMAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4" v-if="userInfo.imagemNao">
              <strike>
                <strong><u>Cláusula 8ª:</u></strong> O(A)
                <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará
                a <strong>CONTRATADA</strong> a realizar a divulgação de sua
                imagem, isoladamente ou do lado dos demais profissionais da
                <strong>CONTRATADA</strong>, de forma gratuita em suas redes
                sociais e em demais mídias, respeitando o Código de Ética dos
                Nutricionistas que não autoriza divulgação de fotos íntimas de
                antes e depois de serviços nutricionais. Para fins de
                demonstração de comparação de resultados de procedimentos
                estéticos, nenhuma foto íntima poderá ser publicada pela
                <strong>CONTRATADA</strong> sem prévia autorização por escrito
                do(a) <strong>CONTRATANTE</strong>.
              </strike>
            </p>
            <p class="mx-4" v-else>
              <strong><u>Cláusula 8ª:</u></strong> O(A)
              <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará a
              <strong>CONTRATADA</strong> a realizar a divulgação de sua imagem,
              isoladamente ou do lado dos demais profissionais da
              <strong>CONTRATADA</strong>, de forma gratuita em suas redes
              sociais e em demais mídias, respeitando o Código de Ética dos
              Nutricionistas que não autoriza divulgação de fotos íntimas de
              antes e depois de serviços nutricionais. Para fins de demonstração
              de comparação de resultados de procedimentos estéticos, nenhuma
              foto íntima poderá ser publicada pela
              <strong>CONTRATADA</strong> sem prévia autorização por escrito
              do(a) <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <!-- <v-row class="d-flex justify-center mx-4">
            <v-radio-group row v-model="userInfo.imagemNao">
              <v-radio label="Concordo" :value="false"></v-radio>
              <v-radio label="Não concordo" :value="true"></v-radio>
            </v-radio-group>
          </v-row> -->
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
              parte do(a) <strong>CONTRATANTE</strong>, após iniciado o programa
              ou pago, todos os valores pagos e a taxa administrativa
              (corresponde a 10% sobre o valor total do serviço) até a data de
              desistência não serão restituídos. Com relação aos valores pagos,
              apenas serão concedidos créditos correspondentes aos valores não
              usufruídas dentro do prazo para uso do serviço para uso posterior
              em sessões e/ou consultas
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela
              desistência do(a) <strong>CONTRATANTE</strong> poderá ser
              usufruído no prazo de 182 (cento e oitenta e dois) dias, contados
              da concessão desses créditos.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese
              haverá devolução de dinheiro após o início do tratamento,
              procedimento, consulta ou sessão, o que vier primeiro.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o(a)
              <strong>CONTRATANTE</strong> desista antes do início da prestação
              do serviço, apenas ficará retido o valor da taxa administrativa de
              10% sobre o valor do serviço total, devendo todo o resto ser
              restituído.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do
              serviço não existe mais a possibilidade de desistência e/ou
              restituição dos valores não gozados, pois a validade estará
              vencida, devendo o(a) <strong>CONTRATANTE</strong> pagar por todos
              os valores pendentes conforme o estipulado, caso ainda esteja em
              débito de pagamento, não sendo proibido a aplicação de juros nas
              condições previstas no presente contrato na cláusula 11ª,
              parágrafo 4º.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 10ª:</u></strong> A
              <strong>CONTRATADA</strong> poderá se recusar a prestar o serviço
              e/ou de rescindir o contrato quando:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Não preenchimento do questionário de saúde pré-consulta
              online;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Não pagamento total ou parcial;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar o pagamento por cheque sem fundos;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV – Não entrega dos exames solicitados antes do início do
              tratamento, quando esses forem pertinentes para a prestação do
              serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Não prestar as informações solicitadas pela nutricionista
              responsável pelo acompanhamento nutricional;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              VI – Solicitar atividade não compreendida como serviço contratado
              ou disponibilizado pela <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
              previstas nos incisos da cláusula 10ª a
              <strong>CONTRATADA</strong> devolverá os valores das sessões e
              consultas pagas e não usufruídas pelo(a)
              <strong>CONTRATANTE</strong>, devendo aplicar o que couber no
              presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
              serviço não impossibilita de a CONTRATADA realizar a cobrança dos
              valores remanescentes ainda não pagos, em caso de aplicação de uma
              das hipóteses da cláusula 10ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO PAGAMENTO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 11:</u></strong> A
              <strong>CONTRATADA</strong> receberá o pagamento conforme está
              disposto no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento são:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">I – Transferência bancária;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – PIX (forma de transferência bancária instantânea
              disponibilizado pelo Banco Central do Brasil);
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">III – Cartão de crédito;</p>
          </v-row>
          <v-row>
            <p class="mx-4">IV – Débito automático;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Cheque, desde que pago integralmente no ato da contratação ou
              antes da primeira consulta ou sessão, o que vier primeiro;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista integral
              ou da primeira parcela no cartão de crédito (independente de
              quantas vezes seja parcelado no cartão) autoriza o início do
              tratamento, consulta, sessão ou procedimento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
              cheque apenas será confirmada quando sacado o cheque antes da
              primeira sessão ou consulta.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em
              que for sacado esteja sem fundos ou qualquer outro resultado que
              não seja a entrega do dinheiro correspondente pela instituição
              financeira a favor da CONTRATADA, não impede dessa tomar as
              medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento
              são proibidas de serem feitas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de
              pagamento incidirá juros mensais de 1% (um por cento) ao mês de
              atraso, mais multa na importância de 2% (dois por cento)
              corrigidos pelo IGP-M (Índice Geral de Preços Mercado), o qual é
              disponibilizado pela Fundação Getúlio Vargas. Na impossibilidade
              de aplicação do índice por qualquer motivo aplicar-se-á o índice
              inflacionário oficial do governo federal.
            </p></v-row
          >
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA VIGÊNCIA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
              vigência por prazo indeterminado até o cumprimento de todas as
              suas obrigações.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá
              ter prazo determinado a depender do tratamento ou do pacote ou do
              programa aderido conforme estiver no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
              instrumento de contrato, não extingue os direitos e obrigações que
              as partes tenham entre si e para com terceiros.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA PROTEÇÃO DE DADOS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 13ª:</u></strong>
              <strong>CONTRATANTE</strong> ao assinar o presente instrumento
              contratual autoriza de imediato que todos os seus dados que vierem
              a ser coletados antes e durante a prestação dos serviços pela
              <strong>CONTRATADA</strong>, sejam compartilhados com a empresa
              franqueadora (responsável pelo software que armazena todos os
              dados dos clientes), enquanto durar a prestação do serviço e após,
              cujo objetivo de garantir a qualidade do serviço prestado ao
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4 mt-8">
              <strong><u>CLÁUSULA 14ª:</u></strong>
              A <strong>CONTRATADA</strong>, por si e por seus colaboradores,
              obriga-se a atuar no presente Contrato em conformidade com a
              Legislação vigente sobre Proteção de Dados Pessoais e as
              determinações de órgãos reguladores/fiscalizadores sobre a
              matéria, em especial a Lei 13.709/2018, além das demais normas e
              políticas de proteção de dados de cada país onde houver qualquer
              tipo de tratamento dos dados dos clientes, o que inclui os dados
              dos clientes desta. No manuseio dos dados a
              <strong>CONTRATADA</strong> deverá:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (i) Tratar os dados pessoais a que tiver acesso em conformidade
              com estas cláusulas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (ii) Manter e utilizar medidas de segurança administrativas,
              técnicas e físicas apropriadas e suficientes para proteger a
              confidencialidade e integridade de todos os dados pessoais
              mantidos ou consultados/transmitidos eletronicamente, para
              garantir a proteção desses dados contra acesso não autorizado,
              destruição, uso, modificação, divulgação ou perda acidental ou
              indevida.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (iii) Acessar os dados dentro de seu escopo e na medida abrangida
              por sua permissão de acesso (autorização) e que os dados pessoais
              não podem ser lidos, copiados, modificados ou removidos sem
              autorização expressa e por escrito da
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (iv) Garantir, por si própria ou quaisquer de seus empregados,
              prepostos, sócios, diretores, representantes ou terceiros
              contratados, a confidencialidade dos dados processados,
              assegurando que todos os seus colaboradores prepostos, sócios,
              diretores, representantes ou terceiros contratados que lidam com
              os dados pessoais sob responsabilidade da
              <strong>CONTRATANTE</strong> assinaram Acordo de Confidencialidade
              e/ou possuem em seus contratos cláusulas de confidencialidade com
              a <strong>CONTRATADA</strong>, bem como a manter quaisquer Dados
              Pessoais estritamente confidenciais e de não os utilizar para
              outros fins, com exceção da prestação de serviços à
              <strong>CONTRATANTE</strong>. Ainda, treinará e orientará a sua
              equipe sobre as disposições legais aplicáveis em relação à
              proteção de dados.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 15ª:</u></strong> Os dados pessoais não
              poderão ser revelados a terceiros, com exceção da prévia
              autorização por escrito da <strong>CONTRATANTE</strong>, quer
              direta ou indiretamente, seja mediante a distribuição de cópias,
              resumos, compilações, extratos, análises, estudos ou outros meios
              que contenham ou de outra forma reflitam referidas Informações, já
              ficando desde já autorizado o compartilhamento dos dados com a
              empresa franqueadora (responsável pelo software que armazena todos
              os dados dos clientes).
              <strong><u>Parágrafo único:</u></strong> Caso a
              <strong>CONTRATADA</strong> seja obrigada por determinação legal a
              fornecer dados pessoais a uma autoridade pública, deverá informar
              previamente a <strong>CONTRATANTE</strong> para que esta tome as
              medidas que julgar cabíveis.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA CLAUSULA DE ARBITRAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                ><u
                  >CLÁUSULA 16ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL
                  NOS TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO
                  CONTRATANTE: FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO,
                  QUE QUALQUER PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE AS
                  PARTES, QUE SEJA ORIGINADO OU RELACIONADO COM O PRESENTE
                  CONTRATO, SERÁ DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE
                  ARBITRAGEM, CONFORME O REGULAMENTO DE ARBITRAGEM DE QUALQUER
                  TRIBUNAL ARBITRAL, COM SEDE NO MUNICÍPIO DE BELÉM, CAPITAL DO
                  ESTADO DO PARÁ, POR INTERMÉDIO DE UM OU MAIS ÁRBITROS NOMEADOS
                  E A SENTENÇA POR ELE(S) PROLATADA PODERÁ SER EXECUTADA EM
                  QUALQUER JUÍZO QUE SOBRE ELA TENHA JURISDIÇÃO. CUJO INTUITO
                  DESSA CLAUSULA É A CELERIDADE E A SOLUÇÃO DE TODOS OS
                  PROBLEMAS ORIUNDOS DO PRESENTE CONTRATO.
                </u></strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong
                >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
                CONTRATAÇÃO</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 17ª:</u></strong> O
              <strong>CONTRATANTE</strong> terá como vantagens durante o período
              da contratação:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Suporte nutricional até a data de término em horário
              comercial;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – Caso algum dos programas, previstos no anexo 1, seja
              contratado terá cumulativamente, além do previsto no inciso I,
              acesso a e-books (livros eletrônicos/digitais), acesso ao sistema
              (software) e vídeos aulas educativos.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 18ª:</u></strong> Condições para participar:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Efetuar o pagamento total do programa na 1ª consulta ou antes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >II – Agendar dias fixos na semana para realização dos
                tratamentos estéticos;</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar todos os procedimentos e consultas até a data de
              término.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 19ª:</u></strong> Regras e observações
              importantes:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >I – Cada cliente deverá ter seu dia fixo para realizar os
                tratamentos estéticos, o não comparecimento na hora marcada
                (falta) implica em perda da sessão, pois o horário ficará
                bloqueado na agenda sem a possibilidade de disponibilizá-lo para
                outro cliente</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – Não será possível desmarcar e remarcar sessões toda semana e
              nem desmarcar somente no dia da sessão, o cliente deverá alterar
              os dias fixos com o máximo de antecedência para não prejudicar a
              agenda, os demais clientes e seu próprio tratamento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Em caso de pausa do tratamento até a data de término, não
              existe garantia posterior de que os mesmos dias fixos estejam
              disponíveis quando retornar.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV – As demais consultas, podem ser feitas por meio de
              videoconferência, de acordo com o código de ética do
              nutricionista. Desta forma, o não comparecimento físico ou on-line
              na data marcada prejudica a agenda de consultas, atrasa o
              tratamento e pode implicar em perda da consulta.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Cada consulta deve ser agendada com intervalo médio de 30
              (trinta) dias entre uma e outra.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              VI – Os cardápios e prescrições serão disponibilizados no software
              em até 3 dias úteis após a consulta e com intervalo mínimo de 30
              dias entre um e outro.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DISPOSIÇÕES FINAIS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 20ª:</u></strong> Em observância do disposto
              no artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor),
              declara expressamente o <strong>CONTRATANTE</strong> que, em
              virtude de ter lido todas as cláusulas constantes deste contrato,
              está ciente de todas elas, aceitando-as expressamente e de poder
              acessar no sistema o presente contrato quando quiser, antes,
              durante e depois de finalizado o presente contrato.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>Anexo 1</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>1. Programa Clínico Funcional - R$900,00</strong><br />3
              consultas mais uma avaliação final
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>2. Programa de Definição Corporal - R$900,00</strong
              ><br />3 consultas mais uma avaliação final
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>3. Programa de Modulação Intestinal - R$990,00</strong
              ><br />3 consultas mais uma avaliação final
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              Obs.1: Na escolha de qualquer um dos programas acima será possível
              adicionar pacotes estéticos ou de terapia complementares nas
              seguintes condições, desde que contratados até a primeira consulta
              do programa:
              <br />a) Inclusão de um pacote adicional por R$ 500,00; <br />b)
              Inclusão do segundo pacote por R$ 400,00; <br />c) Inclusão do
              terceiro pacote em diante cada um com o valor de R$ 300,00.
              <br />
              A partir do quarto pacote adicionado será cobrado a importância de
              R$ 300,00 reais por pacote adicionado, desde que contratados até a
              primeira consulta do programa.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4. Programa de Emagrecimento 12S (doze semanas)</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.1. Opção 1: </strong><br />3 (três) consultas + 1
              avaliação final + 1 livro: R$ 990,00
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.2. Opção 2: </strong><br />3 (três) consultas + 1
              avaliação final + 1 livro + 1 pacote estético de 4 (quatro)
              semanas em 1 (uma) área: R$ 1.490,00
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.3. Opção 3: </strong><br />3 (três) consultas + 1
              avaliação final + 1 livro + 2 (dois) pacotes de quatro semanas
              cada: R$ 1.890,00
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>4.4. Opção 4: </strong><br />3 (três) consultas + 1
              avaliação final + 1 livro + 3 (três) pacotes de quatro semanas
              cada: R$ 2.190,00 <br />
              Obs.2: A partir do quarto pacote adicionado será cobrado a
              importância de R$ 300,00 reais por pacote adicionado, desde que
              contratados até a primeira consulta do programa.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >5. Pacotes estéticos e de terapia complementares
                avulsos</strong
              ><br />
              5.1. - 1 (um) pacote mensal para 1 (uma) área isolada (duas vezes
              por semana): R$ 720,00<br />
              5.2. - 1 (um) pacote mensal para 2 (duas) áreas pares (uma vez por
              semana): R$ 720,00<br />
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>6. Terapias complementares:</strong><br />
              a) Acupuntura<br />
              b) Auriculoterapia<br />
              c) Ventosaterapia<br />
              d) Massoterapia<br />
              e) Aromaterapia <br />
              f) Reflexologia podal<br />
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>Anexo 2 - Tipos de tratamentos (não invasivos)</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              - Para redução de celulite, gordura localizada, flacidez, retenção
              hídrica, ou estímulo muscular para definição corporal.<br />
              - Utilização de aparelhos e/ou procedimentos manuais (massagem e
              drenagem)<br />
              - Utilização de ativos durante sessões e home care (opcional)<br />
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4">
              <strong
                >DEFINIÇÃO DE ÁREA ISOLADA: é equivalente a no máximo ½ folha a
                A4</strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">Quantidade de áreas</th>
                    <th class="text-left">Frequência</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="areaItem in areaItems1" :key="areaItem.nome">
                    <td>{{ areaItem.nome }}</td>
                    <td>{{ areaItem.qtd }}</td>
                    <td>{{ areaItem.freq }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4">
              <strong
                >DEFINIÇÃO DE ÁREAS PARES: é equivalente a no máximo duas ½
                folhas A4</strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">Quantidade de áreas</th>
                    <th class="text-left">Frequência</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="areaItem in areaItems2" :key="areaItem.nome">
                    <td>{{ areaItem.nome }}</td>
                    <td>{{ areaItem.qtd }}</td>
                    <td>{{ areaItem.freq }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </div>
        <div v-else-if="userInfo.contratoVersion == 100">
          <v-row>
            <p>
              De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
              informações pessoais e dados cadastrais necessários dentro do
              sistema (software). Do outro lado a
              <strong>CONTRATADA</strong> também com todas as informações
              necessárias de informação dentro do sistema (software). Ambos
              totalmente disponibilizados de maneira online em sítio eletrônico
              (website).<br />
              Pelo presente instrumento particular de prestação de serviços têm
              entre si justos e acordados quanto segue:
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO OBJETO DO CONTRATO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como
              objeto, a prestação de serviços personalizados de estética e
              terapias complementares. <br /><strong>CLÁUSULA 2ª:</strong> A
              <strong>CONTRATADA</strong> executará os serviços diretamente a(o)
              <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço ser
              executado em menores de 18 (dezoito) anos ou em pessoas maiores de
              18 (dezoito) anos incapazes, a luz do direito civil brasileiro,
              deverá ter uma pessoa responsável, a qual apenas precisará ser
              informada no sistema.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da cláusula
              2ª, não se aplica aos menores de 18 (dezoito) anos emancipados, o
              qual fica condicionado a prestação do serviço pela
              <strong>
                <u
                  >apresentação de sentença judicial transitada em julgado
                  informando a sua emancipação ou certidão de casamento
                  (original ou cópia autenticada)</u
                ></strong
              >, a qual deverá ser encaminhado digitalizada e encaminhada para o
              setor jurídico da <strong>CONTRATANTE</strong> para autorizar a
              execução dos serviços.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DOS SERVIÇOS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>CLÁUSULA 3ª:</strong> A
              <strong>CONTRATADA</strong> prestará um ou mais dos serviços
              previstos conforme o que estiver disponibilizado pelo “Anexo 1” e
              a <strong>CONTRATANTE</strong> tiver escolhido.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
              prestados pela <strong>CONTRATADA</strong> a(o)
              <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Programa power shape 30 (trinta):</strong> programa
              estético promocional realizado em 30 (trinta) dias;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II –
              <strong>Pacote de sessões estéticas avulsas:</strong> entende-se
              como pacote de sessões exclusivamente estéticas de 4 (quatro)
              semanas disponibilizado pelo sistema;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III –
              <strong>Pacote de terapias complementares avulso:</strong>
              entende-se como pacote de sessões exclusivamente de terapias
              complementares disponibilizado pelo sistema;
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
              prestados no estabelecimento da <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Todas as sessões de
              tratamento de estética serão realizadas exclusivamente no
              estabelecimento da <strong>CONTRATADA</strong>, não existe
              exceção.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Todos as sessões deverão ser
              previamente agendadas dentro do sistema disponibilizado online, o
              qual é um software, possível de ser acessado via computador ou
              aplicativo de telefone celular ambos conectados com a rede mundial
              de computadores (internet), pela <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo para
              que o(a) <strong>CONTRATANTE</strong> possa usufruir dos serviços
              contratados, sendo proibido a <strong>CONTRATADA</strong> prestar
              serviços para o(a) <strong>CONTRATANTE</strong> fora desse prazo:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – <strong>Programa power shape 30 (trinta):</strong> prazo
              máximo de 30 (trinta) dias corridas a contar da data da primeira
              avaliação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II –
              <strong>Pacote estético e de terapia complementar avulso:</strong>
              prazo máximo de 60 (sessenta) dias corridos a contar da data da
              contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – <strong>Sessão avulsa:</strong> prazo máximo de 15 (quinze)
              dias corridos a contar da data da contratação;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser
              prorrogado nas seguintes hipóteses:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Doença (CID) grave que impossibilita a continuidade da
              prestação do serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Doença infecto contagiosa;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Quando decretado lockdown pelo chefe do poder executivo
              municipal (prefeito), estadual (governador) ou federal/central
              (presidente, primeiro-ministro, monarca, imperador) onde as
              sessões/consultas presenciais de estéticas/nutricionais estão
              sendo realizadas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
              resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
              e/ou da consulta, salvo nas hipóteses de doença previstas nos
              incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
              contados da contratação o(a) <strong>CONTRATANTE</strong> não
              poderá mais usufruir dos serviços, salvo em uma das hipóteses
              previstas no parágrafo 1º, cláusula 5ª, e nas condições
              estabelecidas no presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
              incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
              <strong>CONTRATANTE</strong> utilizar dos serviços da
              <strong>CONTRATADA</strong> nem a concessão de créditos pelo não
              uso dentro do prazo, também não haverá a devolução de qualquer
              quantia.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
              tenha terminado de pagar, não impedirá a
              <strong>CONTRATADA</strong> de realizar a cobrança dos valores não
              pagos, por todas as medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no
              inciso primeiro, do parágrafo 1º, da cláusula 5ª, haverá a
              suspensão do tratamento até ter condições aptas para continuar a
              receber os serviços, devendo apresentar laudo médico com matrícula
              no CRM (Conselho Regional de Medicina) válida, sob pena de perder
              os dias em que esteve impossibilitada de continuar a usufruir dos
              serviços.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no
              inciso segundo do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> precisará reagendar o dia em que
              faltar por motivo de doença, devendo apresentar atestado médico
              com matrícula no CRM e assinado, nas mesmas condições do parágrafo
              6º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no
              inciso quarto do parágrafo 1º o(a)
              <strong>CONTRATANTE</strong> terá a prorrogação automática pelo
              tempo em que o lockdown tiver sido decretado, nas seguintes
              condições:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – O serviço contratado não for considerado como serviço
              essencial previsto pelo decreto, caso seja considerado não
              ocorrerá a suspensão do serviço.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – A suspensão dos serviços pela decretação do lockdown somente
              ocorre se houver impedimento para o local onde o serviço é
              prestado, em situação em decretação em região diferente da qual o
              serviço é prestado não haverá prorrogação, devendo o
              <strong>CONTRATANTE</strong> usufruir conforme o que estiver
              estabelecido pelo presente Contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações serão
              no mesmo número de dias em que ocorreram as situações previstas
              nos incisos, do parágrafo 1º, da cláusula 5ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as
              seguintes obrigações do(a) <strong>CONTRATANTE</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
              presente contrato;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Realizar todos os exames solicitados pela
              <strong>CONTRATADA</strong> para a efetiva prestação do serviço
              com qualidade, caso não seja realizado será total responsabilidade
              do(a) <strong>CONTRATANTE</strong>;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
              abatimentos, descontos ou dilações de prazo para o pagamento e
              execução dos serviços, sem o prévio conhecimento e autorização da
              <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as
              seguintes obrigações da <strong>CONTRATADA</strong>:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              a) Atividades de seus funcionários serem exercidos com
              profissionalismo, respeito e zelo;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              b) Não revelar detalhes de suas atividades a terceiros, bem como,
              informações sobre seus clientes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              c) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
              (software) com acesso enquanto durar o pacote/sessão, após o
              término do presente contrato será excluído a qualquer momento sem
              qualquer aviso prévio;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              d) Iniciar tratamento ou sessão conforme data agendada após o
              pagamento da primeira parcela ou do pagamento integral;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              e) Manter o sigilo das informações da
              <strong>CONTRATANTE</strong> conforme o que estiver disposto na
              lei de nº 13.709/2018 (Lei Geral de Proteção de Dados).
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO DIREITO DE IMAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4" v-if="userInfo.imagemNao">
              <strike>
                <strong><u>Cláusula 8ª:</u></strong> O(A)
                <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará
                a <strong>CONTRATADA</strong> a realizar a divulgação de sua
                imagem, isoladamente ou do lado dos demais profissionais da
                <strong>CONTRATADA</strong>, de forma gratuita em suas redes
                sociais e em demais mídias. Para fins de demonstração de
                comparação de resultados de procedimentos estéticos, nenhuma
                foto íntima poderá ser publicada pela
                <strong>CONTRATADA</strong> sem prévia autorização por escrito
                do(a) <strong>CONTRATANTE</strong>.
              </strike>
            </p>
            <p class="mx-4" v-else>
              <strong><u>Cláusula 8ª:</u></strong> O(A)
              <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará a
              <strong>CONTRATADA</strong> a realizar a divulgação de sua imagem,
              isoladamente ou do lado dos demais profissionais da
              <strong>CONTRATADA</strong>, de forma gratuita em suas redes
              sociais e em demais mídias. Para fins de demonstração de
              comparação de resultados de procedimentos estéticos, nenhuma foto
              íntima poderá ser publicada pela <strong>CONTRATADA</strong> sem
              prévia autorização por escrito do(a) <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <!-- <v-row class="d-flex justify-center mx-4">
            <v-radio-group row v-model="userInfo.imagemNao">
              <v-radio label="Concordo" :value="false"></v-radio>
              <v-radio label="Não concordo" :value="true"></v-radio>
            </v-radio-group>
          </v-row> -->
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
              parte do(a) <strong>CONTRATANTE</strong>, após iniciado o programa
              ou pago, todos os valores pagos até a data de desistência não
              serão restituídos. Com relação aos valores pagos, apenas serão
              concedidos créditos correspondentes aos valores não usufruídas
              dentro do prazo para uso do serviço para uso posterior em sessões.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela
              desistência do(a) <strong>CONTRATANTE</strong> poderá ser
              usufruído no prazo de 60 (sessenta) dias, contados da concessão
              desses créditos.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese
              haverá devolução de dinheiro após o início do tratamento,
              procedimento ou sessão, o que vier primeiro.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o(a)
              <strong>CONTRATANTE</strong> desista antes do início da prestação
              do serviço, apenas ficará retido o valor da taxa administrativa de
              10% (dez por cento) sobre o valor do serviço total, devendo todo o
              resto ser restituído.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do
              serviço não existe mais a possibilidade de desistência e/ou
              restituição dos valores não gozados, pois a validade estará
              vencida, devendo o(a) <strong>CONTRATANTE</strong> pagar por todos
              os valores pendentes conforme o estipulado, caso ainda esteja em
              débito de pagamento, não sendo proibido a aplicação de juros nas
              condições previstas no presente contrato na cláusula 11ª,
              parágrafo 4º.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 10ª:</u></strong> A
              <strong>CONTRATADA</strong> poderá se recusar a prestar o serviço
              e/ou de rescindir o contrato quando:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Não preenchimento do questionário estético online;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">II – Não pagamento total ou parcial;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar o pagamento por cheque sem fundos;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              IV – Não entrega dos exames solicitados antes do início do
              tratamento, quando esses forem pertinentes para a prestação do
              serviço;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Solicitar atividade não compreendida como serviço contratado
              ou disponibilizado pela <strong>CONTRATADA</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
              previstas nos incisos da cláusula 10ª a
              <strong>CONTRATADA</strong> devolverá os valores das sessões pagas
              e não usufruídas pelo(a) <strong>CONTRATANTE</strong>, devendo
              aplicar o que couber no presente contrato.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
              serviço não impossibilita de a CONTRATADA realizar a cobrança dos
              valores remanescentes ainda não pagos, em caso de aplicação de uma
              das hipóteses da cláusula 10ª.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DO PAGAMENTO</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 11:</u></strong> A
              <strong>CONTRATADA</strong> receberá o pagamento conforme está
              disposto no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento são:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">I – Transferência bancária;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – PIX (forma de transferência bancária instantânea
              disponibilizado pelo Banco Central do Brasil);
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">III – Cartão de crédito;</p>
          </v-row>
          <v-row>
            <p class="mx-4">IV – Débito automático;</p>
          </v-row>
          <v-row>
            <p class="mx-4">
              V – Cheque, desde que pago integralmente no ato da contratação ou
              antes da primeira consulta ou sessão, o que vier primeiro;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista integral
              ou da primeira parcela no cartão de crédito (independente de
              quantas vezes seja parcelado no cartão) autoriza o início do
              tratamento, sessão ou procedimento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
              cheque apenas será confirmada quando sacado o cheque antes da
              primeira sessão.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em
              que for sacado esteja sem fundos ou qualquer outro resultado que
              não seja a entrega do dinheiro correspondente pela instituição
              financeira a favor da CONTRATADA, não impede dessa tomar as
              medidas legais cabíveis.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento
              são proibidas de serem feitas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de
              pagamento incidirá juros mensais de 1% (um por cento) ao mês de
              atraso, mais multa na importância de 2% (dois por cento)
              corrigidos pelo IGP-M (Índice Geral de Preços Mercado), o qual é
              disponibilizado pela Fundação Getúlio Vargas. Na impossibilidade
              de aplicação do índice por qualquer motivo aplicar-se-á o índice
              inflacionário oficial do governo federal.
            </p></v-row
          >
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA VIGÊNCIA</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
              vigência por prazo indeterminado até o cumprimento de todas as
              suas obrigações.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá
              ter prazo determinado a depender do tratamento ou do pacote ou do
              programa aderido conforme estiver no sistema (software).
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
              instrumento de contrato, não extingue os direitos e obrigações que
              as partes tenham entre si e para com terceiros.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA PROTEÇÃO DE DADOS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 13ª:</u></strong>
              <strong>CONTRATANTE</strong> ao assinar o presente instrumento
              contratual autoriza de imediato que todos os seus dados que vierem
              a ser coletados antes e durante a prestação dos serviços pela
              <strong>CONTRATADA</strong>, sejam compartilhados com a empresa
              franqueadora (responsável pelo software que armazena todos os
              dados dos clientes), enquanto durar a prestação do serviço e após,
              cujo objetivo de garantir a qualidade do serviço prestado ao
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4 mt-8">
              <strong><u>CLÁUSULA 14ª:</u></strong>
              A <strong>CONTRATADA</strong>, por si e por seus colaboradores,
              obriga-se a atuar no presente Contrato em conformidade com a
              Legislação vigente sobre Proteção de Dados Pessoais e as
              determinações de órgãos reguladores/fiscalizadores sobre a
              matéria, em especial a Lei 13.709/2018, além das demais normas e
              políticas de proteção de dados de cada país onde houver qualquer
              tipo de tratamento dos dados dos clientes, o que inclui os dados
              dos clientes desta. No manuseio dos dados a
              <strong>CONTRATADA</strong> deverá:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (i) Tratar os dados pessoais a que tiver acesso em conformidade
              com estas cláusulas.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (ii) Manter e utilizar medidas de segurança administrativas,
              técnicas e físicas apropriadas e suficientes para proteger a
              confidencialidade e integridade de todos os dados pessoais
              mantidos ou consultados/transmitidos eletronicamente, para
              garantir a proteção desses dados contra acesso não autorizado,
              destruição, uso, modificação, divulgação ou perda acidental ou
              indevida.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (iii) Acessar os dados dentro de seu escopo e na medida abrangida
              por sua permissão de acesso (autorização) e que os dados pessoais
              não podem ser lidos, copiados, modificados ou removidos sem
              autorização expressa e por escrito da
              <strong>CONTRATANTE</strong>.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              (iv) Garantir, por si própria ou quaisquer de seus empregados,
              prepostos, sócios, diretores, representantes ou terceiros
              contratados, a confidencialidade dos dados processados,
              assegurando que todos os seus colaboradores prepostos, sócios,
              diretores, representantes ou terceiros contratados que lidam com
              os dados pessoais sob responsabilidade da
              <strong>CONTRATANTE</strong> assinaram Acordo de Confidencialidade
              e/ou possuem em seus contratos cláusulas de confidencialidade com
              a <strong>CONTRATADA</strong>, bem como a manter quaisquer Dados
              Pessoais estritamente confidenciais e de não os utilizar para
              outros fins, com exceção da prestação de serviços à
              <strong>CONTRATANTE</strong>. Ainda, treinará e orientará a sua
              equipe sobre as disposições legais aplicáveis em relação à
              proteção de dados.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 15ª:</u></strong> Os dados pessoais não
              poderão ser revelados a terceiros, com exceção da prévia
              autorização por escrito da <strong>CONTRATANTE</strong>, quer
              direta ou indiretamente, seja mediante a distribuição de cópias,
              resumos, compilações, extratos, análises, estudos ou outros meios
              que contenham ou de outra forma reflitam referidas Informações, já
              ficando desde já autorizado o compartilhamento dos dados com a
              empresa franqueadora (responsável pelo software que armazena todos
              os dados dos clientes).
              <strong><u>Parágrafo único:</u></strong> Caso a
              <strong>CONTRATADA</strong> seja obrigada por determinação legal a
              fornecer dados pessoais a uma autoridade pública, deverá informar
              previamente a <strong>CONTRATANTE</strong> para que esta tome as
              medidas que julgar cabíveis.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DA CLAUSULA DE ARBITRAGEM</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                ><u
                  >CLÁUSULA 16ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL
                  NOS TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO
                  CONTRATANTE: FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO,
                  QUE QUALQUER PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE AS
                  PARTES, QUE SEJA ORIGINADO OU RELACIONADO COM O PRESENTE
                  CONTRATO, SERÁ DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE
                  ARBITRAGEM, CONFORME O REGULAMENTO DE ARBITRAGEM DE QUALQUER
                  TRIBUNAL ARBITRAL, COM SEDE NO MUNICÍPIO DE BELÉM, CAPITAL DO
                  ESTADO DO PARÁ, POR INTERMÉDIO DE UM OU MAIS ÁRBITROS NOMEADOS
                  E A SENTENÇA POR ELE(S) PROLATADA PODERÁ SER EXECUTADA EM
                  QUALQUER JUÍZO QUE SOBRE ELA TENHA JURISDIÇÃO. CUJO INTUITO
                  DESSA CLAUSULA É A CELERIDADE E A SOLUÇÃO DE TODOS OS
                  PROBLEMAS ORIUNDOS DO PRESENTE CONTRATO.
                </u></strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong
                >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
                CONTRATAÇÃO</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 17ª:</u></strong> O
              <strong>CONTRATANTE</strong> terá acesso ao clube de vantagens
              durante o período da contratação:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 18ª:</u></strong> Condições para participar:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              I – Efetuar o pagamento total do programa na 1ª sessão ou antes;
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >II – Agendar dias fixos na semana para realização dos
                tratamentos estéticos;</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Realizar todos os procedimentos e sessões até a data de
              término.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 19ª:</u></strong> Regras e observações
              importantes:
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >I – Cada cliente deverá ter seu dia fixo para realizar os
                tratamentos estéticos, o não comparecimento na hora marcada
                (falta) implica em perda da sessão, pois o horário ficará
                bloqueado na agenda sem a possibilidade de disponibilizá-lo para
                outro cliente</strong
              >
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              II – Não será possível desmarcar e remarcar sessões toda semana e
              nem desmarcar somente no dia da sessão, o cliente deverá alterar
              os dias fixos com o máximo de antecedência para não prejudicar a
              agenda, os demais clientes e seu próprio tratamento.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              III – Em caso de pausa do tratamento até a data de término, não
              existe garantia posterior de que os mesmos dias fixos estejam
              disponíveis quando retornar.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>DISPOSIÇÕES FINAIS</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong><u>CLÁUSULA 20ª:</u></strong> Em observância do disposto
              no artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor),
              declara expressamente o <strong>CONTRATANTE</strong> que, em
              virtude de ter lido todas as cláusulas constantes deste contrato,
              está ciente de todas elas, aceitando-as expressamente e de poder
              acessar no sistema o presente contrato quando quiser, antes,
              durante e depois de finalizado o presente contrato.
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>Anexo 1</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>1. Programa power shape 30 (trinta) – R$ 550,00</strong
              ><br />
              1 (uma) avaliação inicial com bioimpedância mais 4 (quatro)
              semanas de 1 (um) pacote ou mais de tratamentos estéticos e uma
              avaliação final com bioimpedância até o último dia do programa.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              Obs.1: O programa power shape 30 (trinta) deve ser todo dentro do
              prazo de 30 (trinta) dias contados da data da primeira avaliação.
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong
                >5. Pacotes estéticos e de terapia complementares
                avulsos</strong
              ><br />
              2.1. - 1 (um) pacote mensal para 1 (uma) área isolada (duas vezes
              por semana): R$ 720,00 <br />
              2.2. - 1 (um) pacote mensal para duas 2 (duas) áreas pares (uma
              vez por semana): R$ 720,00<br />
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              <strong>3. Terapias complementares:</strong><br />
              a) Acupuntura<br />
              b) Auriculoterapia<br />
              c) Ventosaterapia<br />
              d) Massoterapia<br />
              e) Aromaterapia <br />
              f) Reflexologia podal<br />
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4 mt-8">
              <strong>Anexo 2 - Tipos de tratamentos (não invasivos)</strong>
            </p>
          </v-row>
          <v-row>
            <p class="mx-4">
              - Para redução de celulite, gordura localizada, flacidez, retenção
              hídrica, ou estímulo muscular para definição corporal.<br />
              - Utilização de aparelhos e/ou procedimentos manuais (massagem e
              drenagem)<br />
              - Utilização de ativos durante sessões e home care (opcional)<br />
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4">
              <strong
                >DEFINIÇÃO DE ÁREA ISOLADA: é equivalente a no máximo ½ folha a
                A4</strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">Quantidade de áreas</th>
                    <th class="text-left">Frequência</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="areaItem in areaItems1" :key="areaItem.nome">
                    <td>{{ areaItem.nome }}</td>
                    <td>{{ areaItem.qtd }}</td>
                    <td>{{ areaItem.freq }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="mx-4">
              <strong
                >DEFINIÇÃO DE ÁREAS PARES: é equivalente a no máximo duas ½
                folhas A4</strong
              >
            </p>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">Quantidade de áreas</th>
                    <th class="text-left">Frequência</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="areaItem in areaItems2" :key="areaItem.nome">
                    <td>{{ areaItem.nome }}</td>
                    <td>{{ areaItem.qtd }}</td>
                    <td>{{ areaItem.freq }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <p class="mx-4">
            De um lado o(a) <strong>CONTRATANTE</strong> já com todas as
            informações pessoais e dados cadastrais necessários dentro do
            sistema (software). Do outro lado a
            <strong>CONTRATADA</strong> também com todas as informações
            necessárias de informação dentro do sistema (software). Ambos
            totalmente disponibilizados de maneira online em sítio eletrônico
            (website).<br />
            Pelo presente instrumento particular de prestação de serviços têm
            entre si justos e acordados quanto segue:
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DO OBJETO DO CONTRATO</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong>CLÁUSULA 1ª:</strong> O presente instrumento, tem como
            objeto, a prestação de serviços de nutrição e estética
            personalizados. <br /><strong>CLÁUSULA 2ª:</strong> A
            <strong>CONTRATADA</strong> executará os serviços diretamente a(o)
            <strong>CONTRATANTE</strong> e/ou a quem essa indicar.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 1º:</u></strong> Na hipótese de o serviço ser
            executado em menores de 18 (dezoito) anos ou em pessoas maiores de
            18 (dezoito) anos incapazes, a luz do direito civil brasileiro,
            deverá ter uma pessoa responsável, a qual apenas precisará ser
            informada no sistema.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 2º:</u></strong> O parágrafo 1º, da cláusula
            2ª, não se aplica aos menores de 18 (dezoito) anos emancipados, o
            qual fica condicionado a prestação do serviço pela
            <strong>
              <u
                >apresentação de sentença judicial transitada em julgado
                informando a sua emancipação ou certidão de casamento (original
                ou cópia autenticada)</u
              ></strong
            >, a qual deverá ser encaminhado digitalizada e encaminhada para o
            setor jurídico da <strong>CONTRATANTE</strong> para autorizar a
            execução dos serviços.
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DOS SERVIÇOS</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong>CLÁUSULA 3ª:</strong> A <strong>CONTRATADA</strong> prestará
            um ou mais dos serviços previstos conforme o que estiver
            disponibilizado pelo “Anexo 1” e a
            <strong>CONTRATANTE</strong> tiver escolhido.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo único:</u></strong> Sejam esses serviços
            prestados pela <strong>CONTRATADA</strong> a(o)
            <strong>CONTRATANTE</strong>:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            I – <strong>Pacote:</strong> entende-se como pacote conjunto de
            sessões e/ou consultas disponibilizados pelo sistema;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            II – <strong>Pacote estético avulso:</strong> entende-se como pacote
            de sessões exclusivamente estéticas disponibilizado pelo sistema;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            III –
            <strong>Consultas nutricionais e sessões estéticas avulsas:</strong>
            entende-se como não dentro de qualquer pacote, contratando para
            serviço específico e pontual.
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DO LOCAL DA PRESTAÇÃO DO SERVIÇO</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>CLÁUSULA 4ª:</u></strong> Todos os serviços serão
            prestados no estabelecimento da <strong>CONTRATADA</strong>.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 1º:</u></strong> As consultas e o
            acompanhamento nutricional poderão ser realizados, intercalado ou
            exclusivamente, por vídeo conferência quando solicitado pelo(a)
            <strong>CONTRATANTE</strong>.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 2º:</u></strong> Todas as sessões de tratamento
            de estética serão realizadas exclusivamente no estabelecimento da
            <strong>CONTRATADA</strong>, não existe exceção.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 3º:</u></strong> Todos as sessões e consultas
            deverão ser previamente agendadas dentro do sistema disponibilizado
            online, o qual é um software, possível de ser acessado via
            computador ou aplicativo de telefone celular ambos conectados com a
            rede mundial de computadores (internet), pela
            <strong>CONTRATADA</strong>.
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DO TEMPO PARA USAR O SERVIÇO</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Cláusula 5ª:</u></strong> Fica determinado o tempo para
            que o(a) <strong>CONTRATANTE</strong> possa usufruir dos serviços
            contratados, sendo proibido a <strong>CONTRATADA</strong> prestar
            serviços para o(a) <strong>CONTRATANTE</strong> fora desse prazo:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            I – <strong>Pacote:</strong> prazo máximo de 90 (noventa) dias
            corridos a contar da data da contratação;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            II – <strong>Pacote estético avulso:</strong> prazo máximo de 30
            (trinta) dias corridos a contar da data da contratação;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            III – <strong>Consulta e/ou sessão avulsa:</strong> prazo máximo de
            15 (quinze) dias corridos a contar da data da contratação;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 1º:</u></strong> O prazo poderá ser prorrogado
            nas seguintes hipóteses:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            I – Doença (CID) grave que impossibilita a continuidade da prestação
            do serviço;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">II – Doença infecto contagiosa;</p>
        </v-row>
        <v-row>
          <p class="mx-4">
            III – Quando decretado lockdown pelo chefe do poder executivo
            municipal (prefeito), estadual (governador) ou federal/central
            (presidente, primeiro-ministro, monarca, imperador) onde as
            sessões/consultas presenciais de estéticas/nutricionais estão sendo
            realizadas.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 2º:</u></strong> A falta injustificada
            resultará em perda do(da) <strong>CONTRATANTE</strong> da sessão
            e/ou da consulta, salvo nas hipóteses de doença previstas nos
            incisos I, II e IV do parágrafo 1º, da cláusula 5ª.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 3º:</u></strong> Após o prazo estipulado
            contados da contratação o(a) <strong>CONTRATANTE</strong> não poderá
            mais usufruir dos serviços, salvo em uma das hipóteses previstas no
            parágrafo 1º, cláusula 5ª, e nas condições estabelecidas no presente
            contrato.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 4º:</u></strong> Após o prazo previsto nos
            incisos da cláusula 5ª, não haverá em nenhuma hipótese de o(a)
            <strong>CONTRATANTE</strong> utilizar dos serviços da
            <strong>CONTRATADA</strong> nem a concessão de créditos pelo não uso
            dentro do prazo, também não haverá a devolução de qualquer quantia.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 5º:</u></strong> Caso o prazo expire e não
            tenha terminado de pagar, não impedirá a
            <strong>CONTRATADA</strong> de realizar a cobrança dos valores não
            pagos, por todas as medidas legais cabíveis.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 6º:</u></strong> Na situação prevista no inciso
            primeiro, do parágrafo 1º, da cláusula 5ª, haverá a suspensão do
            tratamento até ter condições aptas para continuar a receber os
            serviços, devendo apresentar laudo médico com matrícula no CRM
            (Conselho Regional de Medicina) válida, sob pena de perder os dias
            em que esteve impossibilitada de continuar a usufruir dos serviços.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 7º:</u></strong> Na situação prevista no inciso
            segundo do parágrafo 1º o(a) <strong>CONTRATANTE</strong> precisará
            reagendar o dia em que faltar por motivo de doença, devendo
            apresentar atestado médico com matrícula no CRM e assinado, nas
            mesmas condições do parágrafo 6º, da cláusula 5ª.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 8º:</u></strong> Na situação prevista no inciso
            quarto do parágrafo 1º o(a) <strong>CONTRATANTE</strong> terá a
            prorrogação automática pelo tempo em que o lockdown tiver sido
            decretado, nas seguintes condições:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            I – O serviço contratado não for considerado como serviço essencial
            previsto pelo decreto, caso seja considerado não ocorrerá a
            suspensão do serviço.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            II – A suspensão dos serviços pela decretação do lockdown somente
            ocorre se houver impedimento para o local onde o serviço é prestado,
            em situação em decretação em região diferente da qual o serviço é
            prestado não haverá prorrogação, devendo o
            <strong>CONTRATANTE</strong> usufruir conforme o que estiver
            estabelecido pelo presente Contrato.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 9º:</u></strong> Todos as prorrogações serão no
            mesmo número de dias em que ocorreram as situações previstas nos
            incisos, do parágrafo 1º, da cláusula 5ª.
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DAS OBRIGAÇÕES DO(A) CONTRATANTE</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Cláusula 6ª:</u></strong> Fica estabelecido as seguintes
            obrigações do(a) <strong>CONTRATANTE</strong>:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            a) Efetuar o pagamento, conforme as cláusulas estabelecidas no
            presente contrato;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            b) Realizar todos os exames solicitados pela
            <strong>CONTRATADA</strong> para a efetiva prestação do serviço com
            qualidade, caso não seja realizado será total responsabilidade do(a)
            <strong>CONTRATANTE</strong>;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            c) Fica proibido ao <strong>CONTRATANTE</strong>, negociar
            abatimentos, descontos ou dilações de prazo para o pagamento e
            execução dos serviços, sem o prévio conhecimento e autorização da
            <strong>CONTRATADA</strong>.
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DAS OBRIGAÇÕES DA CONTRATADA</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Cláusula 7ª:</u></strong> Fica estabelecido as seguintes
            obrigações da <strong>CONTRATADA</strong>:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            a) Atividades de seus funcionários serem exercidos com
            profissionalismo, respeito e zelo;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">b) Respeitar o Código de Ética dos Nutricionistas;</p>
        </v-row>
        <v-row>
          <p class="mx-4">
            c) Não revelar detalhes de suas atividades a terceiros, bem como,
            informações sobre seus clientes;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            d) Manter o(a) <strong>CONTRATANTE</strong> no seu sistema
            (software) com acesso enquanto durar o pacote/sessão/consulta, após
            o término do presente contrato será excluído a qualquer momento sem
            qualquer aviso prévio;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            e) Iniciar a consulta, tratamento ou sessão conforme data agendada
            após o pagamento da primeira parcela ou do pagamento integral;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            f) Manter o sigilo das informações da
            <strong>CONTRATANTE</strong> conforme o que estiver disposto na lei
            de nº 13.709/2018 (Lei Geral de Proteção de Dados).
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DO DIREITO DE IMAGEM</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4" v-if="userInfo.imagemNao">
            <strike>
              <strong><u>Cláusula 8ª:</u></strong> O(A)
              <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará a
              <strong>CONTRATADA</strong>
              a realizar a divulgação de sua imagem de forma gratuita em suas
              redes sociais e em demais mídias, respeitando o Código de Ética
              dos Nutricionistas.
            </strike>
          </p>
          <p class="mx-4" v-else>
            <strong><u>Cláusula 8ª:</u></strong> O(A)
            <strong>CONTRATANTE</strong> ao assinar esse contrato autorizará a
            <strong>CONTRATADA</strong>
            a realizar a divulgação de sua imagem de forma gratuita em suas
            redes sociais e em demais mídias, respeitando o Código de Ética dos
            Nutricionistas.
          </p>
        </v-row>
        <!-- <v-row class="d-flex justify-center mx-4">
          <v-checkbox
            v-model="userInfo.imagemNao"
            label="Não autorizo a divulgação da minha imagem."
          ></v-checkbox>
        </v-row> -->
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DA DESISTÊNCIA DO SERVIÇO</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Cláusula 9ª:</u></strong> Em caso de desistência por
            parte do(a) <strong>CONTRATANTE</strong>, após iniciado o programa
            ou pago, todos os valores pagos e a taxa administrativa (corresponde
            a 10% sobre o valor total do serviço) até a data de desistência não
            serão restituídos. Com relação aos valores pagos, apenas serão
            concedidos créditos correspondentes aos valores não usufruídas
            dentro do prazo para uso do serviço para uso posterior em sessões
            e/ou consultas
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 1º:</u></strong> O crédito concedido pela
            desistência do(a) <strong>CONTRATANTE</strong> poderá ser usufruído
            no prazo de 182 (cento e oitenta e dois) dias, contados da concessão
            desses créditos.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 2º:</u></strong> Sobre nenhuma hipótese haverá
            devolução de dinheiro após o início do tratamento, procedimento,
            consulta ou sessão, o que vier primeiro.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 3º:</u></strong> Caso o(a)
            <strong>CONTRATANTE</strong> desista antes do início da prestação do
            serviço, apenas ficará retido o valor da taxa administrativa de 10%
            sobre o valor do serviço total, devendo todo o resto ser restituído.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 4º:</u></strong> Após o prazo para uso do
            serviço não existe mais a possibilidade de desistência e/ou
            restituição dos valores não gozados, pois a validade estará vencida,
            devendo o(a) <strong>CONTRATANTE</strong> pagar por todos os valores
            pendentes conforme o estipulado, caso ainda esteja em débito de
            pagamento, não sendo proibido a aplicação de juros nas condições
            previstas no presente contrato na cláusula 11ª, parágrafo 4º.
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DA RECUSA DA PRESTAÇÃO DO SERVIÇO</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Cláusula 10ª:</u></strong> A
            <strong>CONTRATADA</strong> poderá se recusar a prestar o serviço
            e/ou de rescindir o contrato quando:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            I – Não preenchimento do questionário de saúde pré-consulta online;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">II – Não pagamento total ou parcial;</p>
        </v-row>
        <v-row>
          <p class="mx-4">III – Realizar o pagamento por cheque sem fundos;</p>
        </v-row>
        <v-row>
          <p class="mx-4">
            IV – Não entrega dos exames solicitados antes do início do
            tratamento, quando esses forem pertinentes para a prestação do
            serviço;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            V – Não prestar as informações solicitadas pela nutricionista
            responsável pelo acompanhamento nutricional;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            VI – Solicitar atividade não compreendida como serviço contratado ou
            disponibilizado pela <strong>CONTRATADA</strong>.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 1º:</u></strong> Em nenhuma das hipóteses
            previstas nos incisos da cláusula 10ª a
            <strong>CONTRATADA</strong> devolverá os valores das sessões e
            consultas pagas e não usufruídas pelo(a)
            <strong>CONTRATANTE</strong>, devendo aplicar o que couber no
            presente contrato.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 2º:</u></strong> A recusa da prestação do
            serviço não impossibilita de a CONTRATADA realizar a cobrança dos
            valores remanescentes ainda não pagos, em caso de aplicação de uma
            das hipóteses da cláusula 10ª.
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DO PAGAMENTO</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Cláusula 11:</u></strong> A
            <strong>CONTRATADA</strong> receberá o pagamento conforme está
            disposto no sistema (software).
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 1º:</u></strong> As formas de pagamento são:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">I – Transferência bancária;</p>
        </v-row>
        <v-row>
          <p class="mx-4">
            II – PIX (forma de transferência bancária instantânea
            disponibilizado pelo Banco Central do Brasil);
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">III – Cartão de crédito;</p>
        </v-row>
        <v-row>
          <p class="mx-4">IV – Débito automático;</p>
        </v-row>
        <v-row>
          <p class="mx-4">
            V – Cheque, desde que pago integralmente no ato da contratação ou
            antes da primeira consulta ou sessão, o que vier primeiro;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 1º:</u></strong> O pagamento à vista integral
            ou da primeira parcela no cartão de crédito (independente de quantas
            vezes seja parcelado no cartão) autoriza o início do tratamento,
            consulta, sessão ou procedimento.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 2º:</u></strong> A forma de pagamento por
            cheque apenas será confirmada quando sacado o cheque antes da
            primeira sessão ou consulta.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 3º:</u></strong> Caso o cheque na altura em que
            for sacado esteja sem fundos ou qualquer outro resultado que não
            seja a entrega do dinheiro correspondente pela instituição
            financeira a favor da CONTRATADA, não impede dessa tomar as medidas
            legais cabíveis.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 4º:</u></strong> Outras formas de pagamento são
            proibidas de serem feitas.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 5º:</u></strong> No caso de atraso de pagamento
            incidirá juros mensais de 1% (um por cento) ao mês de atraso, mais
            multa na importância de 2% (dois por cento) corrigidos pelo IGP-M
            (Índice Geral de Preços Mercado), o qual é disponibilizado pela
            Fundação Getúlio Vargas. Na impossibilidade de aplicação do índice
            por qualquer motivo aplicar-se-á o índice inflacionário oficial do
            governo federal.
          </p></v-row
        >

        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DA VIGÊNCIA</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Cláusula 12ª:</u></strong> O presente contrato, terá
            vigência por prazo indeterminado até o cumprimento de todas as suas
            obrigações.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 1º:</u></strong> O presente contrato poderá ter
            prazo determinado a depender do tratamento ou do pacote ou do
            programa aderido conforme estiver no sistema (software).
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>Parágrafo 2º:</u></strong> A rescisão do presente
            instrumento de contrato, não extingue os direitos e obrigações que
            as partes tenham entre si e para com terceiros.
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>DA CLAUSULA DE ARBITRAGEM</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong
              ><u
                >CLÁUSULA 13ª: PACTUAÇÃO FACULTATIVA DE COMPROMISSO ARBITRAL NOS
                TERMOS DA LEI 9.307/96 ACEITA POR INICIATIVA DO CONTRATANTE:
                FICA LOGO ESTABELECIDO, EM CARÁTER DEFINITIVO, QUE QUALQUER
                PROBLEMA QUE VENHA RESULTAR EM CONFLITO ENTRE AS PARTES, QUE
                SEJA ORIGINADO OU RELACIONADO COM O PRESENTE CONTRATO, SERÁ
                DEFINITIVAMENTE RESOLVIDO POR INTERMÉDIO DE ARBITRAGEM, CONFORME
                O REGULAMENTO DE ARBITRAGEM DE QUALQUER TRIBUNAL ARBITRAL, COM
                SEDE NO MUNICÍPIO DE BELÉM, CAPITAL DO ESTADO DO PARÁ, POR
                INTERMÉDIO DE UM OU MAIS ÁRBITROS NOMEADOS E A SENTENÇA POR
                ELE(S) PROLATADA PODERÁ SER EXECUTADA EM QUALQUER JUÍZO QUE
                SOBRE ELA TENHA JURISDIÇÃO. CUJO INTUITO DESSA CLAUSULA É A
                CELERIDADE E A SOLUÇÃO DE TODOS OS PROBLEMAS ORIUNDOS DO
                PRESENTE CONTRATO.
              </u></strong
            >
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong
              >DAS CONDIÇÕES PARA USO DOS SERVIÇOS E VANTAGENS PELA
              CONTRATAÇÃO</strong
            >
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>CLÁUSULA 14ª:</u></strong> O
            <strong>CONTRATANTE</strong> terá como vantagens durante o período
            da contratação:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            I – Suporte nutricional até a data de término em horário comercial;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            II – Acesso a e-books (livros eletrônicos/digitais), acesso ao
            sistema (software) e vídeos aulas educativos.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>CLÁUSULA 15ª:</u></strong> Condições para participar:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            I – Efetuar o pagamento total do programa na 1ª consulta ou antes;
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong
              >II – Agendar dias fixos na semana para realização dos tratamentos
              estéticos;</strong
            >
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            III – Realizar todos os procedimentos e consultas até a data de
            término.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>CLÁUSULA 16ª:</u></strong> Regras e observações
            importantes:
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong
              >I – Cada cliente deverá ter seu dia fixo para realizar os
              tratamentos, o não comparecimento na hora marcada (falta) implica
              em perda da sessão, pois o horário ficará bloqueado na agenda sem
              a possibilidade de disponibilizá-lo para outro cliente</strong
            >
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            II – Não será possível desmarcar e remarcar sessões toda semana e
            nem desmarcar somente no dia da sessão, o cliente deverá alterar os
            dias fixos com o máximo de antecedência para não prejudicar a
            agenda, os demais clientes e seu próprio tratamento.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            III – Em caso de pausa do tratamento até a data de término, não
            existe garantia posterior de os mesmos dias fixos estejam
            disponíveis quando retornar.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            IV – As demais consultas, podem ser feitas por meio de
            videoconferência, de acordo com o código de ética do nutricionista.
            Desta forma, o não comparecimento físico ou on-line na data marcada
            prejudica a agenda de consultas, atrasa o tratamento e pode implicar
            em perda da consulta.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            V – Cada consulta deve ser agendada com intervalo médio de 30
            (trinta) dias entre uma e outra.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            VI – Os cardápios e prescrições serão disponibilizados no software
            em até 3 dias úteis após a consulta e com intervalo mínimo de 30
            dias entre um e outro.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong><u>CLÁUSULA 17ª:</u></strong> Em observância do disposto no
            artigo 46, da Lei 8.078/90 (Código de Defesa do Consumidor), declara
            expressamente o <strong>CONTRATANTE</strong> que, em virtude de ter
            lido todas as cláusulas constantes deste contrato, está ciente de
            todas elas, aceitando-as expressamente e de poder acessar no sistema
            o presente contrato quando quiser, antes, durante e depois de
            finalizado o presente contrato.
          </p>
        </v-row>
        <v-row class="d-flex justify-center">
          <p class="mx-4 mt-8">
            <strong>Anexo 1</strong>
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong>OPÇÃO 1:</strong> 3 consultas nutricionais + videoaulas + 90
            dias de suporte nutricional = 3X de R$330,00, total R$990,00 à
            vista.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong>OPÇÃO 2:</strong> 3 consultas + videoaulas + 90 dias de
            suporte nutricional + 1 pacote estético = 4X de R$395,00 ou
            R$1.580,00 à vista.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong>OPÇÃO 3:</strong> 3 consultas + videoaulas + 90 dias de
            suporte nutricional + 2 pacotes estéticos = 5X de R$392,00 ou
            R$1.960,00 à vista.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong>OPÇÃO 4:</strong> 3 consultas + videoaulas + 90 dias de
            suporte nutricional + 3 pacotes estéticos = 6X de R$390,00 ou
            R$2340,00 à vista.
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong
              >De acordo com a avaliação clínica, podem ser escolhidos o(s)
              tratamento(s) estético(s) abaixo:</strong
            >
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong
              >• Pacote Triplo (HYBRIUS) Gordura/Celulite/ Flacidez =
            </strong>
            R$780,00/ 1x por semana por 4 semanas
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong
              >• Pacote de Tonificação Abdominal (Corrente russa) =
            </strong>
            R$780,00/ 2x por semana por 4 semanas
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong
              >• Pacote de Gordura Visceral / Esteatose Hepática (NARL) =
            </strong>
            R$780,00/ 2x por semana por 4 semanas
          </p>
        </v-row>
        <v-row>
          <p class="mx-4">
            <strong>• Pacotes Manuais: </strong>
            Drenagem Linfática ou Massagem Modeladora ou Massagem Antiestresse
            ou Tratamento de Terapias Naturais (ventosas/ auriculoterapia/
            reflexologia podal/ aromaterapia) = R$780,00/ 2x por semana por 4
            semanas
          </p>
        </v-row>
      </v-card-text>
      <!-- <v-card-actions class="d-flex justify-center">
        <v-checkbox
          v-model="clausulaChecked"
          label="Confirmo que li e concordo com os termos"
        ></v-checkbox>
        <v-btn
          color="#c5d13f"
          v-if="clausulaChecked"
          @click="sendContractConcordo"
          class="mx-4"
        >
          Prosseguir
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      areaItems: [
        { nome: "Abdômen inferior", qtd: "1 área" },
        { nome: "Abdômen superior", qtd: "1 área" },
        { nome: "Glúteo direito", qtd: "1 área" },
        { nome: "Glúteo esquerdo", qtd: "1 área" },
        { nome: "Posterior de coxa esquerdo", qtd: "1 área" },
        { nome: "Posterior de coxa direito", qtd: "1 área" },
        { nome: "Flanco esquerdo", qtd: "1 área" },
        { nome: "Flanco direito", qtd: "1 área" },
        { nome: "Interno de coxas esquerdo", qtd: "1 área" },
        { nome: "Interno de coxas direito", qtd: "1 área" },
        { nome: "Lateral de coxas esquerdo", qtd: "1 área" },
        { nome: "Lateral de coxas direito", qtd: "1 área" },
        { nome: "Bíceps esquerdo", qtd: "1 área" },
        { nome: "Bíceps direito", qtd: "1 área" },
        { nome: "Costas lado direito", qtd: "1 área" },
        { nome: "Costas lado esquerdo", qtd: "1 área" },
        { nome: "Tríceps direito", qtd: "1 área" },
        { nome: "Tríceps esquerdo", qtd: "1 área" },
        { nome: "Papada", qtd: "1 área" },
      ],
      areaItems1: [
        {
          nome: "Face (drenagem)",
          qtd: "1 área isolada",
          freq: "2 (duas) vezes por semana",
        },
        {
          nome: "Pés (reflexologia podal)",
          qtd: "1 área isolada",
          freq: "2 (duas) vezes por semana",
        },
      ],
      areaItems2: [
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Abdômen superior e inferior",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Glúteo direito e esquerdo",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Posterior de coxa esquerdo e direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Flanco esquerdo e direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Interno de coxas esquerdo e direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Lateral de coxas esquerdo direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Posterior de coxa direito e esquerdo",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Bíceps esquerdo e direito",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Costas lado direito e esquerdo",
        },
        {
          freq: "1 (uma) vez por semana",
          qtd: "2 áreas pares",
          nome: "Tríceps direito e esquerdo",
        },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.firestore.userInfo;
    },
  },
};
</script>
