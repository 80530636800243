<template>
  <div>
    <v-dialog v-model="newCursoDialog" width="600">
      <v-card width="100%">
        <v-card-title class="d-flex justify-center"
          >Adicionar curso (em posição {{ newCursoOrder }}.)</v-card-title
        >
        <v-card-text>
          <v-row class="d-flex justify-center my-2">
            <v-text-field
              v-model="newCursoNome"
              outlined
              label="nome"
              :disabled="savingCurso"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#c5d13f"
            @click="addCurso(newCursoOrder, newCursoNome)"
            :disabled="savingCurso"
          >
            <v-progress-circular
              v-if="savingCurso"
              :size="20"
              :width="3"
              color="#614021"
              indeterminate
            ></v-progress-circular>
            Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteCursoDialog" width="600">
      <v-card width="100%">
        <v-card-title class="d-flex justify-center"
          >Gostaria de deletar o curso: "{{
            cursoToDelete.nome
          }}"?</v-card-title
        >
        <v-card-subtitle class="d-flex justify-center"
          >O curso será deletado de forma irrevogável</v-card-subtitle
        >
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="pink"
            @click="deleteCursoDef(cursoToDelete)"
            :disabled="deletingCurso"
          >
            <v-progress-circular
              v-if="deletingCurso"
              :size="20"
              :width="3"
              color="red"
              indeterminate
            ></v-progress-circular>
            Deletar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editarCursoDialog" width="600">
      <v-card width="100%">
        <v-card-title class="d-flex justify-center"
          >Editar curso "{{ cursoToEdit.order }}.
          {{ cursoToEdit.nome }} "</v-card-title
        >
        <v-card-text>
          <v-row class="d-flex justify-center my-2">
            <v-text-field
              v-model="cursoToEdit.nome"
              outlined
              label="nome"
              :disabled="updatingCurso"
            ></v-text-field>
          </v-row>
          <v-row
            class="d-flex justify-center align-center"
            v-for="aula in aulas"
            :key="aula.id"
          >
            <v-card width="95%" outlined>
              <v-row class="d-flex align-center" style="width: 100%">
                <v-col cols="6">
                  {{ aula.order }}. <strong>{{ aula.nome }}</strong>
                </v-col>
                <v-col cols="2">
                  {{ aula.link }}
                </v-col>
                <v-col cols="1">
                  <v-tooltip bottom color="pink">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="pink"
                        @click="deleteAula(aula)"
                      >
                        <v-icon small color="pink">
                          mdi-trash-can-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar "{{ aula.order }}. {{ aula.nome }}"</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="2" class="d-flex justify-center">
                  <v-tooltip bottom color="#c5d13f">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="#c5d13f"
                        @click="editarAula(aula)"
                      >
                        <v-icon color="#c5d13f">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar "{{ aula.order }}. {{ aula.nome }}"</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="1">
                  <v-tooltip bottom color="grey">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="grey"
                        @click="insertAula(aula.order)"
                      >
                        <v-icon color="grey">
                          mdi-plus-circle-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Inserir nova aula abaixo</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#c5d13f"
            @click="updateCurso(cursoToEdit)"
            :disabled="updatingCurso"
          >
            <v-progress-circular
              v-if="updatingCurso"
              :size="20"
              :width="3"
              color="#614021"
              indeterminate
            ></v-progress-circular>
            Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newAulaDialog" width="600">
      <v-card width="100%">
        <v-card-title class="d-flex justify-center"
          >Adicionar aula (em posição {{ newAulaOrder }}.)</v-card-title
        >
        <v-card-text>
          <v-row class="d-flex justify-center my-2">
            <v-text-field
              v-model="newAulaNome"
              outlined
              label="nome"
              :disabled="savingAula"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center my-2">
            <v-text-field
              v-model="newAulaLink"
              outlined
              label="link"
              :disabled="savingAula"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center my-2">
            <v-textarea
              v-model="newAulaText"
              outlined
              label="informações adicionais"
              :disabled="savingAula"
            ></v-textarea>
          </v-row>
          <v-row class="d-flex justify-center">
            Autorizar acesso para:
          </v-row>
          <v-list outlined>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Administradora e Proprietária"
                v-model="newAulaAuth_Admin"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Nutricionista 1"
                v-model="newAulaAuth_Nutri1"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Nutricionista 2 e 3"
                v-model="newAulaAuth_Nutri2"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Recepcionista"
                v-model="newAulaAuth_Recep"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Esteticista"
                v-model="newAulaAuth_Estet"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox dense label="Estagiária" color="#c5d13f" v-model="newAulaAuth_Estag"></v-checkbox
              >
            </v-list-item>
          </v-list>
          <v-row class="d-flex justify-center my-2">
            <v-btn
              icon
              large
              v-if="newAulaLink !== null"
              @click="viewVideo(newAulaLink)"
            >
              <v-icon large color="#c5d13f">mdi-play-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#c5d13f"
            @click="
              addAula(
                newAulaOrder,
                newAulaNome,
                newAulaLink,
                newAulaText,
                newAulaAuth_Admin,
                newAulaAuth_Nutri1,
                newAulaAuth_Nutri2,
                newAulaAuth_Recep,
                newAulaAuth_Estet,
                newAulaAuth_Estag
              )
            "
            :disabled="savingAula"
          >
            <v-progress-circular
              v-if="savingAula"
              :size="20"
              :width="3"
              color="#614021"
              indeterminate
            ></v-progress-circular>
            Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteAulaDialog" width="600">
      <v-card width="100%">
        <v-card-title class="d-flex justify-center"
          >Gostaria de deletar a aula: "{{ aulaToDelete.nome }}"?</v-card-title
        >
        <v-card-subtitle class="d-flex justify-center"
          >A aula será deletado de forma irrevogável</v-card-subtitle
        >
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="pink"
            @click="deleteAulaDef(aulaToDelete)"
            :disabled="deletingAula"
          >
            <v-progress-circular
              v-if="deletingAula"
              :size="20"
              :width="3"
              color="red"
              indeterminate
            ></v-progress-circular>
            Deletar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editarAulaDialog" width="600">
      <v-card width="100%">
        <v-card-title class="d-flex justify-center"
          >Editar aula "{{ aulaToEdit.order }}.
          {{ aulaToEdit.nome }} "</v-card-title
        >
        <v-card-text>
          <v-row class="d-flex justify-center my-2">
            <v-text-field
              v-model="aulaToEdit.nome"
              outlined
              label="nome"
              :disabled="updatingAula"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center my-2">
            <v-textarea
              v-model="aulaToEdit.text"
              outlined
              label="informações adicionais"
              :disabled="savingAula"
            ></v-textarea>
          </v-row>
          <v-row class="d-flex justify-center">
            Autorizar acesso para:
          </v-row>
          <v-list outlined>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Administradora e Proprietária"
                v-model="aulaToEdit.authAdmin"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Nutricionista 1"
                v-model="aulaToEdit.authNutri1"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Nutricionista 2 e 3"
                v-model="aulaToEdit.authNutri2"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Recepcionista"
                v-model="aulaToEdit.authRecep"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Esteticista"
                v-model="aulaToEdit.authEstet"
              ></v-checkbox>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-checkbox
                dense
                color="#c5d13f"
                label="Estagiária"
                v-model="aulaToEdit.authEstag"
              ></v-checkbox>
            </v-list-item>
          </v-list>
          <v-row class="d-flex justify-center my-2">
            <v-text-field
              v-model="aulaToEdit.link"
              outlined
              label="link"
              :disabled="updatingAula"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center my-2">
            <v-btn
              icon
              large
              v-if="aulaToEdit.link !== null"
              @click="viewVideo(aulaToEdit.link)"
            >
              <v-icon large color="#c5d13f">mdi-play-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#c5d13f"
            @click="updateAula(aulaToEdit)"
            :disabled="updatingAula"
          >
            <v-progress-circular
              v-if="updatingAula"
              :size="20"
              :width="3"
              color="#614021"
              indeterminate
            ></v-progress-circular>
            Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewVideoDialog" width="600">
      <v-card>
        <v-card-title>Assistir aula</v-card-title>
        <v-card-subtitle>{{ viewVideoLink }}</v-card-subtitle>
        <v-card-text class="d-flex justify-center">
          <v-row class="d-flex justify-center my-2">
            <v-card width="90%" elevation="4">
              <vue-plyr :emit="['ready', 'ended', 'pause']"
                ><div
                  data-plyr-provider="youtube"
                  :data-plyr-embed-id="viewVideoLink"
                ></div>
              </vue-plyr>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card width="100%">
      <v-card-title class="d-flex justify-center">
        Cursos do software
      </v-card-title>
      <v-card-text>
        <v-row
          v-for="curso in cursos"
          :key="curso.order"
          class="d-flex justify-center align-center"
        >
          <v-card outlined width="90%">
            <v-row class="d-flex align-center">
              <v-col cols="7" class="d-flex align-center">
                {{ curso.order }}. <strong>{{ curso.nome }}</strong>
              </v-col>
              <!-- <v-col cols="1" class="d-flex align-center">
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="grey"
                  @click="orderUpCurso(curso)"
                >
                  <v-icon color="grey">
                    mdi-arrow-expand-up
                  </v-icon>
                </v-btn>
              </template>
              <span>Mover para cima</span>
            </v-tooltip>
          </v-col>
          <v-col cols="1" class="d-flex align-center">
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="grey"
                  @click="orderDownCurso(curso)"
                >
                  <v-icon color="grey">
                    mdi-arrow-expand-down
                  </v-icon>
                </v-btn>
              </template>
              <span>Mover para baixo</span>
            </v-tooltip>
          </v-col> -->
              <v-col cols="2" class="d-flex justify-center align-center">
                <v-tooltip bottom color="pink">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="pink"
                      @click="deleteCurso(curso)"
                    >
                      <v-icon small color="pink">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Deletar "{{ curso.order }}. {{ curso.nome }}"</span>
                </v-tooltip>
              </v-col>
              <v-col cols="2" class="d-flex justify-center align-center">
                <v-tooltip bottom color="#c5d13f">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="#c5d13f"
                      @click="editarCurso(curso)"
                    >
                      <v-icon small color="#c5d13f">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar "{{ curso.order }}. {{ curso.nome }}"</span>
                </v-tooltip>
              </v-col>
              <v-col cols="1" class="d-flex justify-center align-center">
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      @click="insertCurso(curso.order)"
                    >
                      <v-icon color="grey">
                        mdi-plus-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Inserir curso abaixo</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  data() {
    return {
      papeis: [
        "Nutricionista 1",
        "Nutricionista 2",
        "Nutricionista 3",
        "Recepcionista",
        "Esteticista",
        "Administradora",
        "Proprietária",
        "Estagiária",
      ],
      cursos: [{ nome: null, order: null }],
      newCursoDialog: false,
      newCursoOrder: null,
      newCursoNome: null,
      newAulaDialog: false,
      newAulaOrder: null,
      newAulaNome: null,
      newAulaLink: null,
      newAulaText: null,
      viewVideoLink: "",
      viewVideoDialog: false,
      savingCurso: false,
      savingAula: false,
      cursoToDelete: { nome: null, id: null, order: null },
      aulaToDelete: { nome: null, id: null, order: null },
      deleteCursoDialog: false,
      deletingCurso: false,
      cursoToEdit: { nome: null, id: null, order: null },
      deleteAulaDialog: false,
      deletingAula: false,
      aulaToEdit: {
        nome: null,
        id: null,
        order: null,
        link: null,
        text: null,
        authAdmin: null,
        authNutri1: null,
        authNutri2: null,
        authRecep: null,
        authEstet: null,
        authEstag: null,
      },
      aulas: [
        {
          order: null,
          nome: null,
          link: null,
          text: null,
          authAdmin: null,
          authNutri1: null,
          authNutri2: null,
          authRecep: null,
          authEstet: null,
          authEstag: null,
        },
      ],
      editarCursoDialog: false,
      updatingCurso: false,
      editarAulaDialog: false,
      updatingAula: false,
      newAulaAuth_Admin: false,
      newAulaAuth_Nutri1: false,
      newAulaAuth_Nutri2: false,
      newAulaAuth_Recep: false,
      newAulaAuth_Estet: false,
      newAulaAuth_Estag: false,
    };
  },
  methods: {
    viewVideo(link) {
      this.viewVideoLink = link;
      this.viewVideoDialog = true;
    },
    async getCursos() {
      var cursosGot = await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var cursosSorted = cursosGot.sort(compare);

      this.cursos = cursosSorted;
      if (this.cursos.length == 0) {
        this.newCursoDialog = true;
      }
    },
    async addCurso(newCursoOrder, newCursoNome) {
      this.savingCurso = true;
      console.log(newCursoOrder);

      if (newCursoOrder < this.cursos.length) {
        var cursosAcima = this.cursos.filter(function(c) {
          return c.order >= newCursoOrder;
        });
        for (var i = 0; i < cursosAcima.length; i++) {
          var oldOrder = cursosAcima[i].order;
          await firebase
            .firestore()
            .collection("treinamentos")
            .doc("software")
            .collection("cursos")
            .doc(cursosAcima[i].id)
            .update({
              order: oldOrder + 1,
            });
        }
      }

      var ref = firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc();

      var id = ref.id;

      await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(id)
        .set({
          nome: newCursoNome,
          id: id,
          order: newCursoOrder,
        });

      this.newCursoOrder = null;
      this.newCursoNome = null;
      this.getCursos();
      this.newCursoDialog = false;
      this.savingCurso = false;
    },
    deleteCurso(curso) {
      this.cursoToDelete = curso;
      this.deleteCursoDialog = true;
    },
    async deleteCursoDef(curso) {
      this.deletingCurso = true;

      if (curso.order <= this.cursos.length) {
        var cursosAcima = this.cursos.filter(function(c) {
          return c.order > curso.order;
        });
        for (var i = 0; i < cursosAcima.length; i++) {
          var oldOrder = cursosAcima[i].order;
          await firebase
            .firestore()
            .collection("treinamentos")
            .doc("software")
            .collection("cursos")
            .doc(cursosAcima[i].id)
            .update({
              order: oldOrder - 1,
            });
        }
      }

      await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(curso.id)
        .delete();

      this.cursoToDelete = null;
      this.getCursos();
      this.deleteCursoDialog = false;
      this.deletingCurso = false;
    },
    async getAulas() {
      console.log(this.cursoToEdit.id);
      var aulasGot = await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(this.cursoToEdit.id)
        .collection("aulas")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var aulasSorted = aulasGot.sort(compare);

      this.aulas = aulasSorted;
      if (this.aulas.length == 0) {
        this.newAulaDialog = true;
      }
    },
    async editarCurso(curso) {
      this.cursoToEdit = curso;
      this.editarCursoDialog = true;
      this.getAulas();
    },
    insertCurso(tOrder) {
      this.newCursoOrder = tOrder + 1;
      this.newCursoDialog = true;
    },
    async addAula(
      newAulaOrder,
      newAulaNome,
      newAulaLink,
      newAulaText,
      newAulaAuth_Admin,
      newAulaAuth_Nutri1,
      newAulaAuth_Nutri2,
      newAulaAuth_Recep,
      newAulaAuth_Estet,
      newAulaAuth_Estag
    ) {
      this.savingAula = true;
      console.log(newAulaOrder);

      if (newAulaOrder < this.aulas.length) {
        var aulasAcima = this.aulas.filter(function(c) {
          return c.order >= newAulaOrder;
        });
        console.log(aulasAcima)
        for (var i = 0; i < aulasAcima.length; i++) {
          var oldOrder = aulasAcima[i].order;
          await firebase
            .firestore()
            .collection("treinamentos")
            .doc("software")
            .collection("cursos")
            .doc(this.cursoToEdit.id)
            .collection("aulas")
            .doc(aulasAcima[i].id)
            .update({
              order: oldOrder + 1,
            });
        }
      }

      var ref = firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(this.cursoToEdit.id)
        .collection("aulas")
        .doc();

      var id = ref.id;

      await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(this.cursoToEdit.id)
        .collection("aulas")
        .doc(id)
        .set({
          nome: newAulaNome,
          id: id,
          order: newAulaOrder,
          link: newAulaLink,
          text: newAulaText,
          authAdmin: newAulaAuth_Admin,
          authNutri1: newAulaAuth_Nutri1,
          authNutri2: newAulaAuth_Nutri2,
          authRecep: newAulaAuth_Recep,
          authEstet: newAulaAuth_Estet,
          authEstag: newAulaAuth_Estag,
        });

      this.newAulaOrder = null;
      this.newAulaNome = null;
      this.newAulaLink = null;
      this.newAulaText = null;
      this.getAulas();
      this.newAulaDialog = false;
      this.savingAula = false;
    },
    deleteAula(aula) {
      this.aulaToDelete = aula;
      this.deleteAulaDialog = true;
    },
    async deleteAulaDef(aula) {
      this.deletingAula = true;

      if (aula.order <= this.aulas.length) {
        var aulasAcima = this.aulas.filter(function(c) {
          return c.order > aula.order;
        });
        for (var i = 0; i < aulasAcima.length; i++) {
          var oldOrder = aulasAcima[i].order;
          await firebase
            .firestore()
            .collection("treinamentos")
            .doc("software")
            .collection("cursos")
            .doc(this.cursoToEdit.id)
            .collection("aulas")
            .doc(aulasAcima[i].id)
            .update({
              order: oldOrder - 1,
            });
        }
      }

      await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(this.cursoToEdit.id)
        .collection("aulas")
        .doc(aula.id)
        .delete();

      this.aulaToDelete = { nome: null, id: null, order: null };
      this.getAulas();
      this.deleteAulaDialog = false;
      this.deletingAula = false;
    },
    editarAula(aula) {
      this.aulaToEdit = aula;
      this.editarAulaDialog = true;
    },
    async updateAula(aula) {
      await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(this.cursoToEdit.id)
        .collection("aulas")
        .doc(aula.id)
        .update({
          nome: aula.nome,
          link: aula.link,
          text: aula.text,
          authAdmin: aula.authAdmin,
          authNutri1: aula.authNutri1,
          authNutri2: aula.authNutri2,
          authRecep: aula.authRecep,
          authEstet: aula.authEstet,
          authEstag: aula.authEstag,
        });
      this.getAulas();
      this.editarAulaDialog = false;
    },
    async updateCurso(curso) {
      await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(curso.id)
        .update({
          nome: curso.nome,
        });
      this.getCursos();
      this.editarCursoDialog = false;
    },
    insertAula(tOrder) {
      console.log("tOrder", tOrder);
      this.newAulaOrder = tOrder + 1;
      this.newAulaDialog = true;
    },
  },
  computed: {},
  mounted() {
    this.getCursos();
  },
};
</script>
