<template>
  <div>
    <v-dialog v-model="showDialog" max-width="800">
      <v-card>
        <v-row class="d-flex justify-center">
          <v-img :src="empresaToShow.logo" height="72"></v-img>
        </v-row>
        <v-card-title>
          <v-row class="d-flex justify-center">{{ empresaToShow.name }}</v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center">
            <strong>Descrição curta: </strong>{{ empresaToShow.descr }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Endereço: </strong>{{ empresaToShow.endereco }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Telefone: </strong>{{ empresaToShow.telefone }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Resumo da vantagem: </strong
            >{{ empresaToShow.vantagemResumo }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Vantagem: </strong>{{ empresaToShow.vantagem }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Cidade: </strong>{{ empresaToShow.cidade }}
          </v-row>
          <v-row class="d-flex justify-center">
            <strong>Ramo de atividade: </strong>{{ empresaToShow.atividade }}
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="#614021" @click="showDialog = false" text class="mx-1">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title>
        <v-row class="d-flex justify-space-between text-h6">
          <!-- <v-col cols="9">  -->
          Clube de Vantagens
          <!-- </v-col>
          <v-col cols="3"> -->
          <!-- <v-btn color="#c5d13f" @click="newEmpresaDialog = true">
            <v-icon color="#614021">mdi-plus-circle-outline</v-icon>
            Adicionar Empresa Parceira
          </v-btn> -->
          <!-- </v-col> -->
        </v-row>
        <!-- <v-row>
          <v-col cols="6" md="3">
            <v-checkbox
              label="Filtrar por Cidade"
              v-model="filtrarPorCidade"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" md="3">
            <v-autocomplete
              :items="cidades"
              :disabled="!filtrarPorCidade"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3">
            <v-checkbox
              label="Filtrar por Atividade"
              v-model="filtrarPorAtividade"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" md="3">
            <v-autocomplete
              :items="atividades"
              :disabled="!filtrarPorAtividade"
            ></v-autocomplete>
          </v-col>
        </v-row> -->
        <!-- <v-row class="d-flex justify-center mx-4">
          <v-chip
            v-for="atividade in atividades"
            :key="atividade"
            @click="filterAtividade(atividade)"
          >
            {{ atividade }}
          </v-chip>
        </v-row> -->
      </v-card-title>
      <v-card-text v-if="empresasPorCidadePorAtividade.length > 0">
        <v-card
          v-for="(cityList, cityListIndex) in empresasPorCidadePorAtividade"
          :key="cityListIndex"
          outlined
          color="#d8b18d"
          class="my-1"
        >
          <v-card-title class="font-weight-regular text-body-1">
            Cidade: <strong>{{ cityList[0][0].cidade }}</strong>
          </v-card-title>
          <v-card-text>
            <v-card
              v-for="(subList, subListIndex) in cityList"
              :key="subListIndex"
              outlined
              color="#d9e184"
              class="my-1"
            >
              <v-card-title class="font-weight-regular text-body-1">
                Ramo de atividade: <strong>{{ subList[0].atividade }}</strong>
              </v-card-title>
              <v-card
                v-for="empresa in subList"
                :key="empresa.id"
                outlined
                class="mx-1 my-1"
                color="white"
              >
                <v-card-text>
                  <v-row class="d-flex align-center">
                    <v-col cols="2" class="d-flex justify-center">
                      <v-img :src="empresa.logo" contain height="36"></v-img>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-start">
                      <strong>{{ empresa.name }} : </strong>
                      {{ empresa.vantagemResumo }}
                    </v-col>
                    <!-- </v-row>
                  <v-row class="d-flex justify-center"> -->
                    <!-- <v-col cols="10">
                      {{ empresa.vantagemResumo }}
                    </v-col> -->
                    <v-col cols="2">
                      <v-btn @click="show(empresa)" color="#c5d13f">
                        Ver mais
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- <v-card-actions>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2 mx-2 black--text"
                        icon
                        @click="editEmpresa(empresa)"
                      >
                        <v-icon color="black">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar empresa</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2 mx-2 black--text"
                        icon
                        @click="wantDeleteEmpresa(empresa)"
                      >
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar empresa</span>
                  </v-tooltip>
                </v-card-actions> -->
              </v-card>
            </v-card>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <v-progress-circular
          :size="50"
          :width="5"
          color="#614021"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import firebase from "firebase";
// import router from "../router";
import { mdiPlaylistPlus, mdiEye } from "@mdi/js";
export default {
  data: function () {
    return {
      logomarca: null,
      normal: true,
      showDialog: false,
      empresaToShow: {
        logo: "",
        id: "",
        name: "",
        descr: "",
        vantagemResumo: "",
        vantagem: "",
        cidade: "",
        atividade: "",
      },
      icons: {
        mdiPlaylistPlus,
        mdiEye,
      },
      empresasPorCidadePorAtividade: [],
      cidades: [],
      atividades: [],
      filtrarPorAtividade: false,
      filtrarPorCidade: false,
    };
  },
  computed: {
    empresas() {
      return this.$store.state.firestore.clubeDeVantagens;
    },
  },
  methods: {
    filterCidade(city) {
      if (city !== null) {
        var empresasCity = this.empresas.filter(function (el) {
          if (el.data.cidade.length > 0) {
            return el.data.cidade == city;
          } else {
            return false;
          }
        });
        return empresasCity;
      } else {
        return this.empresas;
      }
    },
    filterAtividade(activity) {
      if (activity !== null) {
        var empresasActivity = this.empresas.filter(function (el) {
          if (el.data.atividade.length > 0) {
            return el.data.atividade == activity;
          } else {
            return false;
          }
        });
        return empresasActivity;
      } else {
        return this.empresas;
      }
    },
    separatePorCity() {
      var cidades = this.empresas.map(function (empresa) {
        return empresa.cidade;
      });
      cidades = cidades.filter(function (city, pos) {
        return cidades.indexOf(city) == pos;
      })
      this.cidades = cidades;
      var atividades = this.empresas.map(function (empresa) {
        return empresa.atividade;
      });

      this.atividades = atividades;
      var empresas = this.empresas;

      var filterCidade = function (city, empresas) {
        if (city !== null) {
          var empresasCity = empresas.filter(function (el) {
            if (el.cidade.length > 0) {
              return el.cidade == city;
            } else {
              return false;
            }
          });
          return empresasCity;
        } else {
          return empresas;
        }
      };
      var filterAtividade = function (activity, empresasOfCity) {
        if (activity !== null) {
          var empresasActivity = empresasOfCity.filter(function (el) {
            if (el.atividade.length > 0) {
              return el.atividade == activity;
            } else {
              return false;
            }
          });
          return empresasActivity;
        } else {
          return empresasOfCity;
        }
      };
      var empresasPorCidadePorAtividade = cidades.map(function (city) {
        var allOfCity = filterCidade(city, empresas);
        var atividadesOfCity = allOfCity.map(function (empresa) {
          return empresa.atividade;
        });
        return atividadesOfCity.map(function (activity) {
          return filterAtividade(activity, allOfCity);
        });
      });
      this.empresasPorCidadePorAtividade = empresasPorCidadePorAtividade;
    },
    show(empresa) {
      this.empresaToShow = empresa;
      this.showDialog = true;
    },
  },
  mounted() {
    this.$store.dispatch("firestore/getClubeDeVantagens");
    setTimeout(() => {
      this.separatePorCity();
    }, 2000);
  },
};
</script>