<template>
  <div class="page">
    <LoginDePacienteHeader></LoginDePacienteHeader>
    <LoginDePacienteForm></LoginDePacienteForm>
    <WelcomeFooter></WelcomeFooter>
  </div>
</template>

<script>
import WelcomeFooter from "../components/welcome/WelcomeFooter";
import LoginDePacienteHeader from "../components/login/LoginDePacienteHeader";
import LoginDePacienteForm from "../components/login/LoginDePacienteForm";

export default {
  components: { WelcomeFooter, LoginDePacienteHeader, LoginDePacienteForm },

};
</script>

<style>
</style>