import firebase from "firebase";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAfq1C6yX9kdt3iJG5HuBAoppoZUQC8il8",
  authDomain: "nutri-app-129e7.firebaseapp.com",
  databaseURL: "https://nutri-app-129e7.firebaseio.com",
  projectId: "nutri-app-129e7",
  storageBucket: "nutri-app-129e7.appspot.com",
  messagingSenderId: "861235815482",
  appId: "1:861235815482:web:b152ca2c7aef3553cb8103",
  measurementId: "G-57QSER2GL2"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
firebase.getCurrentUser = function () {
  return new Promise(function (resolve, reject) {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};
firebase.auth().languageCode = "pt";
// export default firebase.messaging();