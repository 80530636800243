<template>
  <div v-if="ready">
    <v-dialog max-width="600" v-model="doneDialog">
      <v-card v-if="doneDialogTask.tipo == 'cardapioEntregar'">
        <v-card-title>Cardápios entregados</v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-start">
            <v-checkbox
              v-model="check1_cardapioEntregar"
              :label="label1_cardapioEntregar"
              color="#614021"
            ></v-checkbox>
          </v-row>
          <v-row class="d-flex justify-start">
            <v-checkbox
              v-model="check2_cardapioEntregar"
              :label="label2_cardapioEntregar"
              color="#614021"
            ></v-checkbox>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="grey" @click="setNullDoneDialog">Fechar</v-btn>
          <v-btn
            color="#c5d13f"
            :disabled="checked_cardapioEntregar"
            @click="mark_cardapioEntregar"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-if="doneDialogTask.tipo == 'follow-up-1-prospecto-vaiPensar'">
        <v-card-title>Tratamento sugerido</v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-start">
            <v-checkbox
              v-model="check1_followUp1"
              :label="label1_followUp1"
              color="#614021"
            ></v-checkbox>
          </v-row>
          <!-- <v-row class="d-flex justify-start">
            <v-checkbox
              v-model="check2_followUp1"
              :label="label2_followUp1"
              color="#614021"
            ></v-checkbox>
          </v-row> -->
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="grey" @click="setNullDoneDialog">Fechar</v-btn>
          <v-btn
            color="#c5d13f"
            :disabled="checked_followUp1"
            @click="mark_followUp1"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-if="doneDialogTask.tipo == 'confirm'">
        <v-card-title>Consulta/Sessão confirmada</v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-start">
            <v-checkbox
              v-model="check1_confirm"
              :label="label1_confirm"
              color="#614021"
            ></v-checkbox>
          </v-row>
          <v-row class="d-flex justify-start">
            <v-switch
              inset
              v-model="confirmComparecer"
              :label="confirmComparecerLabel"
              :color="confirmComparecerColor"
            >
            </v-switch>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="grey" @click="setNullDoneDialog">Fechar</v-btn>
          <v-btn
            v-if="generatesDietaLembrete"
            color="#c5d13f"
            :disabled="!check1_confirm"
            @click="mark_confirmDieta"
            >Salvar</v-btn
          >
          <v-btn
            v-else
            color="#c5d13f"
            :disabled="!check1_confirm"
            @click="mark_confirm"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-if="doneDialogTask.tipo == 'aviso-whatsapp'">
        <v-card-title>Paciente avisado por WhatsApp</v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-start">
            <v-checkbox
              v-model="check1_aviso"
              :label="label1_aviso"
              color="#614021"
            ></v-checkbox>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="grey" @click="setNullDoneDialog">Fechar</v-btn>
          <v-btn color="#c5d13f" :disabled="!check1_aviso" @click="mark_aviso"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-if="doneDialogTask.tipo == 'aniversario'">
        <v-card-title>Paciente parabenisado por WhatsApp</v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-start">
            <v-checkbox
              v-model="check1_aniv"
              :label="label1_aniv"
              color="#614021"
            ></v-checkbox>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="grey" @click="setNullDoneDialog">Fechar</v-btn>
          <v-btn color="#c5d13f" :disabled="!check1_aniv" @click="mark_aniv"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="followUpDetalhe">
      <v-card>
        <v-card-title>Detalhes da primeira conversa</v-card-title>
        <v-card-text>
          <v-row>
            <!-- <v-col cols="0" md="3"></v-col> -->
            <v-col cols="12" md="6">
              <v-autocomplete
                class="mt-4"
                :prepend-icon="icons.mdiBullseyeArrow"
                v-model="pacienteInfos.objetivo"
                :items="objetivos"
                label="Objetivo do Paciente"
                readonly
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                class="mt-4"
                :prepend-icon="icons.mdiAccountClock"
                :items="motivos"
                v-model="pacienteInfos.motivoRecusao"
                label="Motivo pela recusão"
                readonly
              ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="0" md="3"></v-col> -->
          </v-row>
          <v-row v-show="pacienteInfos.motivoRecusao == 'Outros'">
            <v-col cols="0" md="3"></v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :prepend-icon="icons.mdiAccountClock"
                v-model="pacienteInfos.motivoRecusaoOutro"
                label="Motivo pela recusão"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="0" md="3"></v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center">
            <v-col cols="0" md="3"></v-col>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="pacienteInfos.detalhesImportantes"
                label="Detalhes importantes da conversa"
                required
              ></v-textarea>
            </v-col>
            <v-col cols="0" md="3"></v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center align-center">
            <v-col cols="12" md="6">
              <v-text-field
                :prepend-icon="icons.mdiAccount"
                v-model="pacienteInfos.nome"
                label="Nome"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-radio-group v-model="pacienteInfos.sexo" column>
                <v-radio
                  label="feminino"
                  value="feminino"
                  color="#ff00ff"
                ></v-radio>
                <v-radio
                  label="masculino"
                  value="masculino"
                  color="#0080ff"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="6">
              <v-text-field
                :prepend-icon="icons.mdiPhone"
                v-model="pacienteInfos.telefone"
                label="Telefone"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :prepend-icon="icons.mdiWhatsapp"
                v-model="pacienteInfos.whatsapp"
                label="WhatsApp"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="0" md="2"></v-col>
            <v-col cols="12" md="5">
              <v-autocomplete
                label="Meio de contato"
                :prepend-icon="icons.mdiAccountVoice"
                v-model="pacienteInfos.meioContato"
                :items="meios"
                readonly
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Horário da ligação"
                type="time"
                v-model="pacienteInfos.horarioLigacao"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="0" md="2"></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text @click="followUpDetalhe = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showBoardItemDialog" max-width="800">
      <v-card>
        <v-card-title class="d-flex justify-center">
          {{ boardItemDialogPaciente }}, {{ niceDay(boardItemDialogDay) }}
        </v-card-title>
        <v-card-text>
          <v-row
            class="d-flex justify-center"
            v-for="(refeicao, refIndex) in pRefeicoes"
            :key="refeicao.dayCardapio"
          >
            <v-card outlined class="my-1" width="90%">
              <v-card-subtitle class="d-flex justify-center"
                >{{ refeicao.titulo }} ({{
                  refeicao.kcal
                }}
                KCAL)</v-card-subtitle
              >
              <v-card-text>
                <v-row class="d-flex justify-center"
                  ><small>Alimentos: {{ refeicao.alimentosStr }}</small></v-row
                >
                <v-row class="d-flex justify-center"
                  ><small>Receitas: {{ refeicao.receitasStr }}</small></v-row
                >
                <v-row
                  class="d-flex justify-center"
                  v-if="
                    refeicao.observacaoStr !== '' &&
                      refeicao.observacaoStr !== null &&
                      refeicao.observacaoStr !== undefined
                  "
                  ><small>Obs.:{{ refeicao.observacaoStr }}</small></v-row
                >
                <v-row class="d-flex justify-center">
                  <v-col cols="0" md="4"></v-col>
                  <v-col cols="12" md="4">
                    <v-img
                      :src="refeicao.fotoUrl"
                      class="my-1"
                      v-if="
                        refeicao.fotoUrl !== undefined ||
                          refeicao.fotoUrl !== null
                      "
                    ></v-img>
                  </v-col>
                  <v-col cols="0" md="4"></v-col>
                </v-row>
                <v-row
                  class="d-flex justify-center"
                  v-if="
                    refeicao.fotoUrl !== undefined && refeicao.fotoUrl !== null
                  "
                >
                  <v-col cols="12" md="2" class="d-flex justify-center">
                    <v-btn
                      outlined
                      color="#614021"
                      disabled
                      v-if="savingHeart == refIndex"
                    >
                      <v-progress-circular
                        :size="20"
                        :width="3"
                        color="#c5d13f"
                        indeterminate
                      ></v-progress-circular>
                    </v-btn>
                    <v-btn
                      outlined
                      color="#c5d13f"
                      @click="saveHeart(refIndex)"
                      v-else-if="savingHeart !== refIndex && !refeicao.heartN"
                    >
                      <v-icon color="#614021">mdi-heart-outline</v-icon>
                    </v-btn>
                    <v-btn
                      color="#c5d13f"
                      @click="removeHeart(refIndex)"
                      v-else-if="savingHeart !== refIndex && refeicao.heartN"
                    >
                      <v-icon color="#FF40B9">mdi-heart</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      label="comentário"
                      v-model="refeicao.commentN"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex justify-center">
                    <v-btn disabled v-if="savingComment == refIndex">
                      <v-progress-circular
                        :size="20"
                        :width="3"
                        color="#614021"
                        indeterminate
                      ></v-progress-circular>
                    </v-btn>
                    <v-btn
                      color="#c5d13f"
                      @click="saveComment(refIndex)"
                      v-else
                    >
                      <v-icon color="#00C5FF" v-if="savedComment == refIndex"
                        >mdi-check-all</v-icon
                      >
                      <span v-else>Salvar</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#D6DBDF" @click="closeShowBoardItemDialog"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="taskList.length > 0 || taskDietaList.length > 0">
      <v-card-title class="d-flex justify-center">
        Tarefas a concluir hoje: {{ readableDate(currentMillis) }}
      </v-card-title>
      <div
        v-if="
          funcionarioOnline.papel == 'Proprietário' ||
            funcionarioOnline.papel == 'Proprietária' ||
            funcionarioOnline.papel == 'Administradora' ||
            funcionarioOnline.papel == 'Nutricionista' ||
            funcionarioOnline.papel == 'Nutricionista 1' ||
            funcionarioOnline.papel == 'Nutricionista 2' ||
            funcionarioOnline.papel == 'Nutricionista 3' ||
            funcionarioOnline.papel == 'Estagiária'
        "
      >
        <v-card outlined class="my-1">
          <v-card-title> Nutricionista: </v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-center">
              Fotos dos pratos dos pacientes:</v-row
            >
            <v-row class="d-flex justify-center my-2">
              <v-chip
                class="mx-1"
                v-for="item in chunk"
                :key="item"
                @click="getDietaStoriesBoard(item)"
                :color="clickedShowPratosId(item)"
                >{{ niceDay(item) }}</v-chip
              >
            </v-row>
            <v-row class="d-flex justify-center">
              <v-progress-circular
                size="50"
                width="5"
                color="#614021"
                indeterminate
                class="mx-2"
                v-if="loadingDietaStoriesBoard"
              ></v-progress-circular>
              <v-carousel
                v-model="carousel"
                height="300"
                v-else
                class="d-flex justify-center"
                delimiter-icon="mdi-circle-medium"
              >
                <v-carousel-item
                  v-for="(boardItem, boardItemIndex) in dietaStoriesBoard"
                  :key="boardItem.fotoUrl"
                >
                  <v-card width="750" color="rgba(197, 209, 63, 0.4)">
                    <v-card-text class="d-flex justify-center align-center">
                      <v-card
                        outlined
                        height="80%"
                        style="background-color: #ffffff"
                        width="300"
                      >
                        <v-card-subtitle
                          class="d-flex justify-center black--text"
                        >
                          <v-badge
                            :content="boardItem.qtdFotos + ' fotos'"
                            color="#c5d13f"
                          >
                            <span>{{ boardItem.nomePaciente }}</span> <br />
                            <span v-if="boardItem.qtdFotos > 0"
                              >(objetivo: {{ boardItem.objetivo }})</span
                            >
                          </v-badge>
                        </v-card-subtitle>
                        <v-card-actions class="d-flex justify-center">
                          <v-btn
                            color="#614021"
                            class="white--text"
                            @click="showBoardItem(boardItem, boardItemIndex)"
                            dense
                            :disabled="
                              loadingBoardItem == boardItemIndex ||
                                dietaStoriesBoard[0].fotoUrl == null
                            "
                          >
                            <v-progress-circular
                              :size="20"
                              :width="3"
                              color="#c5d13f"
                              indeterminate
                              v-if="loadingBoardItem == boardItemIndex"
                            ></v-progress-circular>
                            <v-icon left color="#c5d13f" v-else>
                              mdi-food-fork-drink
                            </v-icon>
                            Ver fotos</v-btn
                          >
                        </v-card-actions>
                        <v-card-text class="d-flex align-top">
                          <v-img
                            :src="boardItem.fotoUrl"
                            max-height="120"
                          ></v-img>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </v-row>
            <v-row
              class="my-1"
              v-for="(taskDieta, taskDietaIndex) in taskDietaList"
              :key="taskDietaIndex"
            >
              <v-card outlined width="95%" :color="taskColor(taskDieta)">
                <v-row class="d-flex align-center">
                  <v-col cols="7">
                    <span class="ml-1">
                      <strong>{{ readableDate(taskDieta.dueDate) }}</strong>
                      Entregar cardápios: {{ taskDieta.patient }}</span
                    ></v-col
                  >
                  <v-col cols="5" class="d-flex justify-end align-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#c5d13f"
                          v-bind="attrs"
                          v-on="on"
                          class="mx-4"
                          to="/PlanoAlimentarCalcularNovo"
                        >
                          <v-icon color="#614021">mdi-food-apple</v-icon>
                        </v-btn>
                      </template>
                      <span>Novo plano alimentar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#c5d13f"
                          v-bind="attrs"
                          v-on="on"
                          class="mx-4"
                          @click="seeInMeusPacientes(taskDieta.patientId)"
                        >
                          <v-icon color="#614021">mdi-account</v-icon>
                        </v-btn>
                      </template>
                      <span>Detalhes do paciente</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#008000">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#008000"
                          v-bind="attrs"
                          v-on="on"
                          class="mx-4"
                          @click="markAsDoneTask(taskDieta)"
                        >
                          <v-icon color="white">mdi-check-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Salvar como feita</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <v-divider></v-divider>
      <div>
        <v-card outlined class="my-1">
          <v-card-title> Todos: </v-card-title>
          <v-card-text>
            <v-card>
              <v-virtual-scroll height="720" item-height="72" :items="taskList">
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-card
                      outlined
                      width="95%"
                      :color="taskColor(item)"
                      v-if="item.tipo == 'follow-up-1-prospecto-vaiPensar'"
                    >
                      <v-row class="d-flex align-center">
                        <v-col cols="9">
                          <span class="ml-1">
                            <strong>{{ readableDate(item.dueDate) }}</strong>
                            Sugerir tratamento para: {{ item.nome }}, <br />
                            <small
                              >Objetivo: {{ item.objetivo }},
                              {{ item.meioContato }},
                              <v-icon
                                x-small
                                v-if="
                                  item.telefone !== null && item.telefone !== ''
                                "
                                >mdi-phone</v-icon
                              >
                              {{ item.telefone }}
                              <v-icon
                                x-small
                                v-if="
                                  item.whatsapp !== null && item.whatsapp !== ''
                                "
                                >mdi-whatsapp</v-icon
                              >
                              {{ item.whatsapp }}
                            </small>
                          </span>
                        </v-col>
                        <v-col cols="3" class="d-flex justify-end align-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#c5d13f"
                                v-bind="attrs"
                                v-on="on"
                                class="mx-4"
                                @click="seeDetalhesConversa(item)"
                              >
                                <v-icon color="#614021"
                                  >mdi-account-details</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Detalhes da primeira conversa</span>
                          </v-tooltip>
                          <v-tooltip bottom color="#008000">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#008000"
                                v-bind="attrs"
                                v-on="on"
                                class="mx-4"
                                @click="markAsDoneTask(item)"
                              >
                                <v-icon color="white">mdi-check-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Salvar como feita</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card
                      outlined
                      width="95%"
                      :color="taskColor(item)"
                      v-if="item.tipo == 'confirm'"
                    >
                      <v-row class="d-flex align-center">
                        <v-col cols="9">
                          <span class="ml-1">
                            <strong>{{ readableDate(item.dueDate) }}</strong>
                            {{ item.patient }}: Confirmar {{ item.name }},
                            {{ item.niceDateShort }}
                          </span>
                        </v-col>
                        <v-col cols="3" class="d-flex justify-end align-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#c5d13f"
                                v-bind="attrs"
                                v-on="on"
                                class="mx-4"
                                @click="seeInMeusPacientes(item.patientId)"
                              >
                                <v-icon color="#614021">mdi-account</v-icon>
                              </v-btn>
                            </template>
                            <span>Detalhes do paciente</span>
                          </v-tooltip>
                          <v-tooltip bottom color="#008000">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#008000"
                                v-bind="attrs"
                                v-on="on"
                                class="mx-4"
                                @click="markAsDoneTask(item)"
                              >
                                <v-icon color="white">mdi-check-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Salvar como feita</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card
                      outlined
                      width="95%"
                      :color="taskColor(item)"
                      v-if="item.tipo == 'aviso-whatsapp'"
                    >
                      <v-row class="d-flex align-center">
                        <v-col cols="9">
                          <span class="ml-1">
                            <strong>{{ readableDate(item.dueDate) }}</strong>
                            {{ item.patient }}: {{ item.tipoPrescr }}
                          </span>
                        </v-col>
                        <v-col cols="3" class="d-flex justify-end align-center">
                          <v-tooltip bottom color="#00ff00">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#66cc00"
                                v-bind="attrs"
                                v-on="on"
                                class="mx-4"
                                :href="item.link"
                                target="_blank"
                              >
                                <v-icon color="#ffffff">mdi-whatsapp</v-icon>
                              </v-btn>
                            </template>
                            <span class="black--text"
                              >Avisar por WhatsApp com texto padrão</span
                            >
                          </v-tooltip>
                          <v-tooltip bottom color="#008000">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#008000"
                                v-bind="attrs"
                                v-on="on"
                                class="mx-4"
                                @click="markAsDoneTask(item)"
                              >
                                <v-icon color="white">mdi-check-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Salvar como feita</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card
                      outlined
                      width="95%"
                      :color="taskColor(item)"
                      v-if="item.tipo == 'aniversario'"
                    >
                      <v-row class="d-flex align-center">
                        <v-col cols="9">
                          <span class="ml-1">
                            <strong>{{ readableDate(item.dueDate) }}</strong>
                            {{ item.patient }}:
                            <v-icon small color="black"
                              >mdi-gift-outline</v-icon
                            >
                            Aniversário {{ getAge(item.dataNascimento) }} anos!
                          </span>
                        </v-col>
                        <v-col cols="3" class="d-flex justify-end align-center">
                          <v-tooltip bottom color="#00ff00">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#66cc00"
                                v-bind="attrs"
                                v-on="on"
                                class="mx-4"
                                :href="item.link"
                                target="_blank"
                              >
                                <v-icon color="#ffffff">mdi-whatsapp</v-icon>
                              </v-btn>
                            </template>
                            <span class="black--text"
                              >Parabenisar por WhatsApp com texto padrão</span
                            >
                          </v-tooltip>
                          <v-tooltip bottom color="#008000">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#008000"
                                v-bind="attrs"
                                v-on="on"
                                class="mx-4"
                                @click="markAsDoneTask(item)"
                              >
                                <v-icon color="white">mdi-check-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Salvar como feita</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card>
            <!-- <v-row
              class="my-1"
              v-for="(task, taskIndex) in taskList"
              :key="taskIndex"
            >
            </v-row> -->
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
  <div v-else>
    <v-row class="d-flex justify-center align-center">
      <v-progress-circular
        size="50"
        width="5"
        color="#614021"
        indeterminate
        class="mx-2"
      ></v-progress-circular>
      Carregando lista de pendências...
    </v-row>
  </div>
</template>
<script>
import firebase from "firebase";
import {
  mdiAccount,
  mdiAccountClock,
  mdiWeb,
  mdiLock,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
  mdiStore,
  mdiAlert,
  mdiCalendar,
  mdiPhone,
  mdiWhatsapp,
  mdiMapMarker,
  mdiCity,
  mdiFlagVariantOutline,
  mdiPiggyBank,
  mdiHandHeart,
  mdiBabyCarriage,
  mdiHumanMaleChild,
  mdiHospitalBuilding,
  mdiAccountVoice,
  mdiCardAccountDetails,
  mdiBullseyeArrow,
} from "@mdi/js";
export default {
  data: function() {
    return {
      ready: false,
      doneDialog: false,
      doneDialogTask: {
        tipo: null,
        patient: null,
        patientId: null,
        done: null,
        nome: null,
        telefone: null,
        whatsapp: null,
        meioContato: null,
        objetivo: null,
        motivoRecusao: null,
        detalhesImportantes: null,
        horarioLigacao: null,
        id: null,
        name: null,
        niceDateShort: null,
        start: null,
      },
      check1_cardapioEntregar: false,
      check1_followUp1: false,
      check1_confirm: false,
      check1_aviso: false,
      check1_aniv: false,
      check2_cardapioEntregar: false,
      taskDietaList: [
        {
          tipo: "cardapioEntregar",
          patient: "Ewan McGregor",
          patientId: "ewan@mcgregor.com",
          id: "XXXXyZ",
          dueDate: 1640549988000,
          status: "overdue",
          done: false,
        },
      ],
      taskList: [
        {
          tipo: "follow-up-1-prospecto-vaiPensar",
          id: "XXXXyZ",
          dueDate: 1640549988000,
          status: "overdue",
          done: false,
          nome: "Ewan McGregor",
          telefone: "",
          whatsapp: "998038116",
          meioContato: "Mandou mensagem no WhatsApp",
          objetivo: "Emagrecer",
          motivoRecusao: '"Vou pensar..."',
        },
      ],
      pacienteInfos: {
        tipo: "follow-up-1-prospecto-vaiPensar",
        id: null,
        dueDate: null,
        status: null,
        done: null,
        nome: null,
        telefone: null,
        whatsapp: null,
        meioContato: null,
        objetivo: null,
        motivoRecusao: null,
        detalhesImportantes: null,
        horarioLigacao: null,
      },
      followUpDetalhe: false,
      objetivos: [
        "Emagrecer",
        "Tratar um Problema de Saúde",
        "Ganhar Massa Magra",
        "Procedimento Estético",
        "Exames",
      ],
      meios: [
        // meio de contato para agendamento
        "Ligou para telefone fixo",
        "Ligou para celular",
        "Ligou para WhatsApp",
        "Mandou mensagem no WhatsApp",
        "Mandou mensagem no Instagram",
        "Agendou direto na recepção",
      ],
      motivos: ["Buscava plano de saúde", '"Vou pensar..."', "Outros"],
      atendimentoTiposDieta: [
        "1ª Consulta",
        "Retorno de Consulta Avulsa",
        "2ª Consulta",
        "3ª Consulta",
        // "1ª Avaliação Protocolo Kirchhoff",
        // "2ª Avaliação Pacote Protocolo Kirchhoff",
        // "3ª Avaliação Pacote Protocolo Kirchhoff",
      ],
      confirmComparecer: true,
      carousel: 0,
      dietaStoriesBoard: [
        {
          fotoUrl: null,
          nutriResponsavel: null,
          updated: null,
          nomePaciente: null,
          seenN: null,
          dayCardapio: null,
          objetivo: null,
          qtdFotos: null,
        },
      ],
      loadingDietaStoriesBoard: false,
      loadingBoardItem: null,
      showBoardItemDialog: false,
      pRefeicoes: [
        {
          titulo: null,
          alimentosStr: null,
          receitasStr: null,
          observacaoStr: null,
          kcal: null,
          fotoUrl: null,
          commentN: null,
          heartN: null,
        },
      ],
      savingHeart: null,
      savingComment: null,
      savedComment: null,
      boardItemDialogPaciente: null,
      boardItemDialogPacienteId: null,
      boardItemDialogDay: "220418",
      showPratosId: null,
      icons: {
        mdiWeb,
        mdiLock,
        mdiEmail,
        mdiEye,
        mdiEyeOff,
        mdiStore,
        mdiAccount,
        mdiAccountClock,
        mdiAlert,
        mdiCalendar,
        mdiPhone,
        mdiWhatsapp,
        mdiMapMarker,
        mdiCity,
        mdiFlagVariantOutline,
        mdiPiggyBank,
        mdiHospitalBuilding,
        mdiHandHeart,
        mdiBabyCarriage,
        mdiHumanMaleChild,
        mdiAccountVoice,
        mdiCardAccountDetails,
        mdiBullseyeArrow,
      },
    };
  },
  methods: {
    closeShowBoardItemDialog() {
      this.showBoardItemDialog = false;
      this.savingComment = null;
      this.savedComment = null;
      this.savingHeart = null;
    },
    async saveComment(index) {
      if (
        this.boardItemDialogPacienteId !== null &&
        this.boardItemDialogPacienteId !== undefined
      ) {
        this.savingComment = index;
        console.log(
          "saveComment: this.pRefeicoes[index].commentN",
          this.pRefeicoes[index].commentN
        );
        await firebase
          .firestore()
          .collection("users")
          .doc(this.boardItemDialogPacienteId)
          .collection("dietaStories")
          .doc(this.boardItemDialogDay)
          .update({
            pRefeicoes: this.pRefeicoes,
            newCommentN: true,
          });
        this.savingComment = null;
        this.savedComment = index;
      }
    },
    async saveHeart(index) {
      if (
        this.boardItemDialogPacienteId !== null &&
        this.boardItemDialogPacienteId !== undefined
      ) {
        this.savingHeart = index;
        this.pRefeicoes[index].heartN = true;
        console.log(
          "saveHeart: this.pRefeicoes[index].heartN",
          this.pRefeicoes[index].heartN
        );
        await firebase
          .firestore()
          .collection("users")
          .doc(this.boardItemDialogPacienteId)
          .collection("dietaStories")
          .doc(this.boardItemDialogDay)
          .update({
            pRefeicoes: this.pRefeicoes,
            newHeartN: true,
          });
        this.savingHeart = null;
      }
    },
    async removeHeart(index) {
      if (
        this.boardItemDialogPacienteId !== null &&
        this.boardItemDialogPacienteId !== undefined
      ) {
        this.savingHeart = index;
        this.pRefeicoes[index].heartN = false;
        var countHearts = 0;
        this.pRefeicoes.forEach(function(r) {
          var nr = 0;
          if (r.heartN) {
            nr = 1;
          }
          countHearts += nr;
        });
        console.log("countHearts", countHearts);
        await firebase
          .firestore()
          .collection("users")
          .doc(this.boardItemDialogPacienteId)
          .collection("dietaStories")
          .doc(this.boardItemDialogDay)
          .update({
            pRefeicoes: this.pRefeicoes,
            newHeartN: countHearts > 0,
          });
        this.savingHeart = null;
      }
    },
    async showBoardItem(infos, index) {
      this.loadingBoardItem = index;
      var pRef = await firebase
        .firestore()
        .collection("users")
        .doc(infos.pacienteId)
        .collection("dietaStories")
        .doc(infos.dayCardapio)
        .get()
        .then(function(doc) {
          return doc.data().pRefeicoes;
        });

      this.boardItemDialogPaciente = infos.nomePaciente;
      this.boardItemDialogPacienteId = infos.pacienteId;
      this.boardItemDialogDay = infos.dayCardapio;
      this.pRefeicoes = pRef;
      this.showBoardItemDialog = true;
      this.loadingBoardItem = null;

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("dietaStoriesBoard")
        .doc(infos.pacienteId + "_" + infos.dayCardapio)
        .update({
          seenN: true,
        });
    },
    niceDay(str) {
      var yearStr = str.substring(0, 2);
      var mesStr = str.substring(2, 4);
      var dayStr = str.substring(4, 6);
      return dayStr + "/" + mesStr + "/" + yearStr;
    },
    clickedShowPratosId(id) {
      if (this.showPratosId == id) {
        return "#c5d13f";
      } else {
        return "grey";
      }
    },
    async getDietaStoriesBoard(dayCardapio) {
      this.showPratosId = dayCardapio;
      this.loadingDietaStoriesBoard = true;

      var list = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("dietaStoriesBoard")
        .where("dayCardapio", "==", dayCardapio)
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      var papel = this.funcionarioOnline.papel;
      var filteredList = list.filter(function(i) {
        var cond1 = i.nutriResponsavel == papel || i.nutriResponsavel == "any";
        var cond2 = !i.seenN;
        return cond1 && cond2;
      });

      if (filteredList.length == 0) {
        filteredList = [
          {
            fotoUrl: null,
            nutriResponsavel: null,
            updated: null,
            nomePaciente: "Nenhuma foto nova",
            seenN: null,
            dayCardapio: null,
            objetivo: "...",
            qtdFotos: 0,
          },
        ];
      }

      this.dietaStoriesBoard = filteredList;
      this.loadingDietaStoriesBoard = false;
    },
    getAge(dataN) {
      var date = new Date(dataN + " 06:00:00");
      var jahrgang = date.getFullYear();
      var now = new Date();
      var jahr = now.getFullYear();
      var age = jahr - jahrgang;
      return age;
    },
    seeInMeusPacientes(pId) {
      this.$store.dispatch("firestore/persistPaciente", {
        pId: pId,
      });
      this.$router.push("MeusPacientes");
    },
    seeDetalhesConversa(task) {
      this.pacienteInfos = task;
      this.followUpDetalhe = true;
    },
    markAsDoneTask(task) {
      this.doneDialogTask = task;
      this.doneDialog = true;
    },
    mark_cardapioEntregar() {
      var d = new Date();
      var millis = d.getTime();
      firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(this.doneDialogTask.id)
        .update({
          done: true,
          doneBy: this.funcionarioOnline.papel,
          doneTime: millis,
        });

      this.check1_cardapioEntregar = false;
      this.check2_cardapioEntregar = false;
      this.setNullDoneDialog();
      this.ready = false;
      this.getTaskLists();
    },
    mark_followUp1() {
      var d = new Date();
      var millis = d.getTime();
      firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(this.doneDialogTask.id)
        .update({
          done: true,
          doneBy: this.funcionarioOnline.papel,
          doneTime: millis,
        });

      this.check1_followUp1 = false;
      this.setNullDoneDialog();
      this.ready = false;
      this.getTaskLists();
    },
    mark_confirmDieta() {
      var d = new Date();
      var millis = d.getTime();
      firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(this.doneDialogTask.id)
        .update({
          done: true,
          doneBy: this.funcionarioOnline.papel,
          doneTime: millis,
        });

      if (this.confirmComparecer) {
        var dateConsulta = new Date(this.doneDialogTask.start);
        var millisConsulta = dateConsulta.getTime();
        var dueDateDieta = 3 * 24 * 60 * 60 * 1000 + millisConsulta;

        firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("lembretes")
          .doc(this.doneDialogTask.id)
          .set({
            done: false,
            doneBy: null,
            doneTime: null,
            tipo: "cardapioEntregar",
            patient: this.doneDialogTask.patient,
            patientId: this.doneDialogTask.patientId,
            id: this.doneDialogTask.id,
            dueDate: dueDateDieta,
            status: "notDueYet",
          });
      }

      this.check1_confirm = false;
      this.confirmComparecer = true;
      this.setNullDoneDialog();
      this.ready = false;
      this.getTaskLists();
    },
    mark_confirm() {
      var d = new Date();
      var millis = d.getTime();
      firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(this.doneDialogTask.id)
        .update({
          done: true,
          doneBy: this.funcionarioOnline.papel,
          doneTime: millis,
        });

      this.check1_confirm = false;
      this.confirmComparecer = true;
      this.setNullDoneDialog();
      this.ready = false;
      this.getTaskLists();
    },
    mark_aviso() {
      var d = new Date();
      var millis = d.getTime();
      firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(this.doneDialogTask.id)
        .update({
          done: true,
          doneBy: this.funcionarioOnline.papel,
          doneTime: millis,
        });

      this.check1_avsio = false;
      this.setNullDoneDialog();
      this.ready = false;
      this.getTaskLists();
    },
    mark_aniv() {
      var d = new Date();
      var millis = d.getTime();
      firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(this.doneDialogTask.id)
        .update({
          done: true,
          doneBy: this.funcionarioOnline.papel,
          doneTime: millis,
        });

      this.check1_aniv = false;
      this.setNullDoneDialog();
      this.ready = false;
      this.getTaskLists();
    },
    setNullDoneDialog() {
      this.doneDialogTask = {
        tipo: null,
        patient: null,
        patientId: null,
        done: null,
        nome: null,
        telefone: null,
        whatsapp: null,
        meioContato: null,
        objetivo: null,
        motivoRecusao: null,
        detalhesImportantes: null,
        horarioLigacao: null,
        name: null,
        niceDateShort: null,
        start: null,
      };
      this.doneDialog = false;
    },
    toPersistPaciente() {},
    readableDate(millis) {
      var millisNr = parseFloat(millis);
      var date = new Date(millisNr);
      var year = date.getFullYear().toString();
      var month = date.getMonth() + 1;
      var monthStr;
      if (month > 9) {
        monthStr = month.toString();
      } else {
        monthStr = "0" + month.toString();
      }
      var day = date.getDate();
      var dayStr;
      if (day > 9) {
        dayStr = day.toString();
      } else {
        dayStr = "0" + day.toString();
      }
      var niceDate = dayStr + "/" + monthStr + "/" + year;
      return niceDate;
    },
    taskColor(task) {
      if (task.status == "overdue" && !task.done) {
        return "#ff3333";
      } else {
        return "#F1F7BB";
      }
    },
    updateStatusLembretes() {
      // var d = new Date();
      // var e = new Date(millis);
      // var diff = d.getTime() - millis;
      // var day = e.getDate();
      // var today = d.getDate();
      // if (diff > 12*60*60*1000 || day !== today) {
      // }
    },
    async getTaskLists() {
      let d = new Date();
      let sub14 = 14 * 24 * 60 * 60 * 1000;
      let twoWeeksAgo = d.getTime() - sub14;
      var allLembretes = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .where("dueDate", ">", twoWeeksAgo)
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );

      var allLembretes_ov = allLembretes.map(function(lembrete) {
        var d = new Date();
        var nowMillis = d.getTime();
        var dueMillis = lembrete.dueDate;

        var nowAbsdayR = nowMillis / 86400000;
        var dueAbsdayR = dueMillis / 86400000;

        var nowAbsday = Math.floor(nowAbsdayR);
        var dueAbsday = Math.floor(dueAbsdayR);

        var diffMillis = nowAbsday - dueAbsday;
        var overdue = diffMillis > 0;
        if (overdue) {
          lembrete.status = "overdue";
        }
        return lembrete;
      });

      var openLembretes = allLembretes_ov.filter(function(lembrete) {
        var d = new Date();
        var nowMillis = d.getTime();
        var dueMillis = lembrete.dueDate;

        var nowAbsdayR = nowMillis / 86400000;
        var dueAbsdayR = dueMillis / 86400000;

        var nowAbsday = Math.floor(nowAbsdayR);
        var dueAbsday = Math.floor(dueAbsdayR);

        var due = nowAbsday == dueAbsday;
        return due && lembrete.tipo !== "cardapioEntregar" && !lembrete.done;
      });

      var openDietaLembretes = allLembretes_ov.filter(function(lembrete) {
        var d = new Date();
        var nowMillis = d.getTime();
        var dueMillis = lembrete.dueDate;

        var nowAbsdayR = nowMillis / 86400000;
        var dueAbsdayR = dueMillis / 86400000;

        var nowAbsday = Math.floor(nowAbsdayR);
        var dueAbsday = Math.floor(dueAbsdayR);

        var diff = nowAbsday - dueAbsday;

        var comingUpNotDone = diff >= -2;
        return (
          lembrete.tipo == "cardapioEntregar" &&
          comingUpNotDone &&
          !lembrete.done
        );
      });

      var overdueLembretes = allLembretes_ov.filter(function(lembrete) {
        return (
          lembrete.status == "overdue" &&
          !lembrete.done &&
          lembrete.tipo !== "cardapioEntregar"
        );
      });
      var overdueDietaLembretes = allLembretes_ov.filter(function(lembrete) {
        return (
          lembrete.status == "overdue" &&
          !lembrete.done &&
          lembrete.tipo == "cardapioEntregar"
        );
      });
      this.taskDietaList = openDietaLembretes
        .concat(overdueDietaLembretes)
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );
      this.taskList = openLembretes.concat(overdueLembretes);
      this.ready = true;
    },
  },
  computed: {
    chunk() {
      var indexList = [0, 1, 2, 3];
      var nowDate = new Date();
      var now = nowDate.getTime();
      var dayCardapioList = indexList.map(function(i) {
        var sub = i * 24 * 60 * 60 * 1000;
        var date = new Date(now - sub);
        var y = date.getFullYear() - 2000;
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var yearStr = y.toString();
        var monthStr;
        if (m > 9) {
          monthStr = m.toString();
        } else {
          monthStr = "0" + m.toString();
        }
        var dayStr;
        if (d > 9) {
          dayStr = d.toString();
        } else {
          dayStr = "0" + d.toString();
        }

        var dayCardapio = yearStr + monthStr + dayStr;
        return dayCardapio;
      });
      return dayCardapioList;
    },
    generatesDietaLembrete() {
      return (
        this.atendimentoTiposDieta.includes(this.doneDialogTask.name) &&
        this.confirmComparecer
      );
    },
    label1_aniv() {
      if (this.doneDialogTask.tipo == "aniversario") {
        return `
              Confirmo que eu, ${this.funcionarioOnline.nome},
              ${this.funcionarioOnline.papel}, parabenisei o paciente ${this.doneDialogTask.patient} pelo WhatsApp da clínica usando o texto padrão sugerido.`;
      } else {
        return "";
      }
    },
    label1_aviso() {
      if (this.doneDialogTask.tipo == "cardapioEntregar") {
        return `
              Confirmo que eu, ${this.funcionarioOnline.nome},
              ${this.funcionarioOnline.papel}, avisei o paciente ${this.doneDialogTask.patient} pelo WhatsApp da clínica sobre a nova prescrição usando o texto padrão sugerido.`;
      } else {
        return "";
      }
    },
    label1_cardapioEntregar() {
      if (this.doneDialogTask.tipo == "cardapioEntregar") {
        return `
              Confirmo que eu, ${this.funcionarioOnline.nome},
              ${this.funcionarioOnline.papel}, entreguei todos os cardápios
              pendentes do paciente ${this.doneDialogTask.patient}`;
      } else {
        return "";
      }
    },
    label2_cardapioEntregar() {
      if (this.doneDialogTask.tipo == "cardapioEntregar") {
        return `
              Verifiquei em "Meus Pacientes" se todos cardápios estão salvos
              corretamente na área do paciente ${this.doneDialogTask.patient}
            `;
      } else {
        return "";
      }
    },
    label1_followUp1() {
      if (this.doneDialogTask.tipo == "follow-up-1-prospecto-vaiPensar") {
        return `
              Confirmo que eu, ${this.funcionarioOnline.nome},
              ${this.funcionarioOnline.papel}, entrei em contato com ${this.doneDialogTask.nome} 
              e sugeri um tratamento que corresponde ao objetivo do potencial cliente`;
      } else {
        return "";
      }
    },
    label1_confirm() {
      if (this.doneDialogTask.tipo == "confirm") {
        return `
              Confirmo que eu, ${this.funcionarioOnline.nome},
              ${this.funcionarioOnline.papel}, entrei em contato com ${this.doneDialogTask.patient} 
              para confirmar: ${this.doneDialogTask.name}, ${this.doneDialogTask.niceDateShort}`;
      } else {
        return "";
      }
    },
    confirmComparecerLabel() {
      if (this.confirmComparecer) {
        return "Paciente confirmou a presença";
      } else {
        return "Paciente pediu para remarcar";
      }
    },
    confirmComparecerColor() {
      if (this.confirmComparecer) {
        return "#008000";
      } else {
        return "#ff3333";
      }
    },
    checked_cardapioEntregar() {
      return !(this.check1_cardapioEntregar && this.check2_cardapioEntregar);
    },
    checked_followUp1() {
      return !this.check1_followUp1;
    },
    currentMillis() {
      var d = new Date();
      return d.getTime();
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
  },
  mounted() {
    var now = new Date();
    var y = now.getFullYear() - 2000;
    var m = now.getMonth() + 1;
    var d = now.getDate();
    var yearStr = y.toString();
    var monthStr;
    if (m > 9) {
      monthStr = m.toString();
    } else {
      monthStr = "0" + m.toString();
    }
    var dayStr;
    if (d > 9) {
      dayStr = d.toString();
    } else {
      dayStr = "0" + d.toString();
    }

    var initialDayCardapio = yearStr + monthStr + dayStr;
    this.showPratosId = initialDayCardapio;
    setTimeout(() => {
      this.getTaskLists();
      this.getDietaStoriesBoard(initialDayCardapio);
    }, 1500);
  },
};
</script>
