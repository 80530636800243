<template>
  <div>
    <v-dialog v-model="conversaDialog" scrollable max-width="1100">
      <v-card elevation="5" class="my-4">
        <v-card-title class="d-flex justify-center text-subtitle-1 my-2">
          Conversa com {{ currentDialog.nome }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 1000px">
          <v-row
            class="mx-4"
            v-for="fala in currentDialog.elements"
            :key="fala.id"
          >
            <v-col col="4" v-if="fala.fromP"></v-col>
            <v-col col="8">
              <v-card
                :color="getColor(fala.fromP)"
                class="d-flex align-end my-1"
              >
                <v-card-text class="d-flex justify-center">
                  <v-textarea
                    readonly
                    v-model="fala.text"
                    solo
                    class="mx-1 my-1"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                  <span
                    ><small>{{ readableDate(fala.id) }}</small></span
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col col="4" v-if="fala.fromN"></v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              color="#614021"
              @click="readClose"
              class="white--text my-2"
              v-if="lastMsgIsP"
            >
              <v-icon color="green">mdi-check-circle-outline</v-icon>
              Marcar como lido + Fechar
            </v-btn>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="height: 200px">
          <v-textarea
            style="height: 80%"
            outlined
            class="mx-2"
            v-model="textToSend"
          >
          </v-textarea>
          <v-btn color="#c5d13f" @click="sendText(textToSend)"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sentDialog" max-width="600">
      <v-card elevation="5">
        <v-card-title class="d-flex justify-center">
          Mensagem enviada!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-email-check-outline</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="d-flex justify-center text-h6 my-4">Chat com Paciente</v-row>
    <v-row class="d-flex justify-center text-subtitle-1 grey--text my-2"
      >Conversas recentes</v-row
    >
    <v-row class="d-flex justify-center my-4">
      <v-card
        v-for="conversa in conversasToShow"
        :key="conversa.value"
        width="95%"
        outlined
      >
        <v-row class="d-flex justify-center mx-4">
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <v-row>
              {{ conversa.data.nome }}, {{ conversa.data.lastFalaDateStr }},
              {{ conversa.data.lastFalaHora }}
            </v-row>
            <v-icon color="red" v-if="unansweredArrayGotIncludes(conversa.value)" class="mx-4"
              >mdi-email-alert-outline</v-icon
            >
            <v-btn color="#c5d13f" @click="openConversa(conversa.value)">
              <v-icon color="#614021" left>mdi-chat-plus</v-icon>
              Ver Chat
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-divider></v-divider>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6">
        <v-autocomplete
          ref="pacienteFilter"
          label="Nome do Paciente"
          single-line
          solo
          class="my-2 mx-3"
          v-model="pacienteDocID"
          :items="pacientes"
          no-data-text="..."
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-btn
        color="#614021"
        class="white--text"
        @click="openConversa(pacienteDocID)"
      >
        <v-icon color="#c5d13f" left>mdi-chat-plus</v-icon>
        Iniciar Chat
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      conversaDialog: false,
      sentDialog: false,
      pacienteDocID: null,
      textToSend: "",
      conversasToShow: [],
      unansweredArrayGot: [],
      pId: null,
      pacientes: [],
      currentDialog: {
        nome: null,
        email: null,
        elements: [
          {
            fromN: null,
            fromP: null,
            id: null,
            text: null,
          },
        ],
      },
    };
  },
  computed: {
    lastMsgIsP() {
      if (this.currentDialog.elements.length > 0) {
        return this.currentDialog.elements[
          this.currentDialog.elements.length - 1
        ].fromP;
      } else {
        return false;
      }
    },
    // pacientes() {
    //   return this.$store.state.firestore.pacientes;
    // },
    // currentDialog() {
    //   return this.$store.state.firestore.currentDialog;
    // },
  },
  methods: {
    unansweredArrayGotIncludes(v) {
      return this.unansweredArrayGot.includes(v);
    },
    readableDate(millis) {
      var millisNr = parseFloat(millis);
      var date = new Date(millisNr);
      var year = date.getFullYear().toString();
      var month = date.getMonth() + 1;
      var monthStr;
      if (month > 9) {
        monthStr = month.toString();
      } else {
        monthStr = "0" + month.toString();
      }
      var day = date.getDate();
      var dayStr;
      if (day > 9) {
        dayStr = day.toString();
      } else {
        dayStr = "0" + day.toString();
      }
      var hours = date.getHours();
      var mins = date.getMinutes();
      var minsStr;
      if (mins > 9) {
        minsStr = mins.toString();
      } else {
        minsStr = "0" + mins.toString();
      }

      var niceDate =
        hours +
        ":" +
        minsStr +
        " (" +
        dayStr +
        "/" +
        monthStr +
        "/" +
        year +
        ")";

      return niceDate;
    },
    async openConversa(paciente) {
      var pacienteName = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("pacientes")
        .doc(paciente)
        .get()
        .then(function (doc) {
          return doc.data().nome;
        });

      var falas = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("pacientes")
        .doc(paciente)
        .collection("falas")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );

      // if (falas.length < 1) {
      //   falas = [
      //     {
      //       fromN: null,
      //       fromP: null,
      //       id: null,
      //       text: null,
      //     },
      //   ];
      // }

      var dialogObj = {
        nome: pacienteName,
        email: paciente,
        elements: falas,
      };
      console.log(dialogObj);
      this.currentDialog = dialogObj;
      this.conversaDialog = true;
      this.pId = paciente;
    },
    sendText(text) {
      var pId = this.currentDialog.email;
      //   var ref = firebase
      //     .firestore()
      //     .collection("users")
      //     .doc(sessionStorage.getItem("current-user"))
      //     .collection("pacientes")
      //     .doc(pId)
      //     .collection("falas")
      //     .doc();

      //   var id = ref.id;
      var now = new Date();
      var hours = now.getHours();
      var mins = now.getMinutes();
      var day = now.getDate();
      var month = now.getMonth() + 1;
      var year = now.getFullYear();

      var horaStr;
      if (hours < 10) {
        horaStr = "0" + hours.toString();
      } else {
        horaStr = hours.toString();
      }
      var minStr;
      if (mins < 10) {
        minStr = "0" + mins.toString();
      } else {
        minStr = mins.toString();
      }
      var hora = horaStr + ":" + minStr;

      var dayStr;
      if (day < 10) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var monthStr;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }
      var dateStr = dayStr + "/" + monthStr + "/" + year.toString();

      var id = now.getTime();

      this.$store.dispatch("firestore/sendTextN", {
        pId: pId,
        text: text,
        id: id.toString(),
        lastFalaHora: hora,
        lastFalaMillis: id,
        lastFalaDateStr: dateStr,
      });
      setTimeout(() => {
        this.loadConversas();
        this.conversaDialog = false;
        this.sentDialog = true;
        this.textToSend = "";
      }, 1000);
    },
    getColor(fromPatient) {
      if (fromPatient) {
        return "rgba(97, 64, 33, 0.4)";
      } else {
        return "rgba(197, 209, 63, 0.4)";
      }
    },
    getUnansweredNs() {
      this.$store.dispatch("firestore/getAnyUnansweredN");
    },
    readClose() {
      this.$store.dispatch("firestore/markAsReadN", {
        pId: this.currentDialog.email,
      });
      this.getUnansweredNs();
      this.loadConversas();
      this.conversaDialog = false;
    },
    async loadConversas() {
      var pacientesList = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        // .doc(firebase.auth().currentUser.email)
        .collection("pacientes")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return {
              value: doc.id,
              data: doc.data(),
              text: doc.data().nome,
            };
          })
        );
      this.pacientes = pacientesList;

      var unansweredArray = [];

      var unansweredArrayGot = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .get()
        .then(function(doc) {
          return doc.data().unansweredArray;
        });

      if (unansweredArrayGot !== undefined) {
        this.unansweredArrayGot = unansweredArrayGot;
        unansweredArray = unansweredArrayGot;
      } else {
        this.unansweredArrayGot = [];
        unansweredArray = [];
      }

      // var now = new Date();
      // var weekMillis = 3 * 24 * 60 * 60 * 1000;
      // var thisWeek = now.getTime() - weekMillis;
      console.log("unansweredArray", unansweredArray)
      var now = new Date();
      var nowTime = now.getTime();
      var substractTwoMonths = 2 * 30 * 24 * 60 * 60 * 1000;
      const twoMonthsAgo = nowTime - substractTwoMonths;
      var list = pacientesList.filter(function (el) {
        if (el.data.lastFalaMillis) {
          var timeCond = /*thisWeek < */parseFloat(el.data.lastFalaMillis) > twoMonthsAgo;
          var unansweredCond = unansweredArray.includes(el.value); //unanswered BY nutri
          return timeCond || unansweredCond;
        } else {
          return unansweredArray.includes(el.value);
        }
      });
      
      function compare(a, b) {
        if (a.data.lastFalaMillis < b.data.lastFalaMillis) {
          return 1;
        }
        if (a.data.lastFalaMillis > b.data.lastFalaMillis) {
          return -1;
        }
        return 0;
      }
      var sortedList = list.sort(compare)

      // console.log(list);
      this.conversasToShow = sortedList;
    },
  },
  mounted() {
    // this.$store.dispatch("firestore/getPacientes");
    this.loadConversas();
    setInterval(() => {
      this.loadConversas();
    }, 60000);
  },
};
</script>