<template>
  <!-- v-if="readyToAtend(pacienteFirestore.data.joinedAt)" -->
  <!-- <div v-if="readyToAtend(pacienteFirestore.data.joinedAt)"> -->
  <div>
    <!-- <div> -->
    <v-dialog v-model="dadosErradosDialog" max-width="600">
      <v-card>
        <v-card-title> Erro! </v-card-title>
        <v-card-text class="d-flex justify-center">
          Preencha todos os campos obrigatórios no início do questionário!
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="dadosErradosDialog = false" dense>
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showBioimpAll" max-width="800" multiple>
      <v-card elevation="5">
        <v-card-title class="text-h6"> Bioimpedâncias </v-card-title>
        <v-card-text>
          <div
            v-for="(atendimento, atendimentoIndex) in atendimentos"
            :key="atendimentoIndex"
          >
            <div v-if="atendimento.bioimp !== ''">
              <v-card outlined>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                      {{ labels[atendimentoIndex] }}
                    </v-row>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#614021"
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2 mx-2 black--text"
                          :href="atendimento.bioimp"
                          target="_blank"
                        >
                          <v-icon color="#c5d13f">{{ icons.mdiEye }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Visualizar/Baixar Bioimpedância</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div v-else></div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="progressDialog" max-width="600">
      <v-card v-if="!saved && !noTaxa">
        <v-card-title class="d-flex justify-center">
          Salvando dados...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else-if="saved && !noTaxa">
        <v-card-title class="d-flex justify-center">
          Dados salvos!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="green">mdi-check-circle</v-icon>
        </v-card-text>
      </v-card>
      <v-card v-else-if="!saved && noTaxa">
        <v-card-title class="d-flex justify-center">
          Registrar composição corporal completa!
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon x-large color="red">mdi-close-circle-outline</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="d-flex justify-center my-6 text-h5">
      Anamnese Nutricional e Questionário Alimentar
    </v-row>

    <v-row class="d-flex justify-center mt-6 text-h6"
      >{{ pacienteFirestore.nome }},
      {{ getAge(pacienteFirestore.dataNascimento) }},
      {{ pacienteInfos.profissao }}
    </v-row>
    <v-row class="d-flex justify-center mb-4 text-subtitle-1">
      {{ nowDateStr }}
    </v-row>

    <v-row v-if="atendimentoPadrao">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Questionário pré-consulta
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="form" v-model="valid" class="grey lighten-3">
              <v-container>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiMapMarker"
                      v-model="pacienteInfos.endereco"
                      label="Endereço"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiCity"
                      v-model="pacienteInfos.cidade"
                      label="Cidade"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiFlagVariantOutline"
                      v-model="pacienteInfos.estado"
                      label="Estado / País"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-select
                      :prepend-icon="icons.mdiHandHeart"
                      v-model="pacienteInfos.estadoCivil"
                      :items="estadoCivilItems"
                      label="Estado civil"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiBabyCarriage"
                      v-model="pacienteInfos.filhos"
                      label="Filhos"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiHumanMaleChild"
                      v-model="pacienteInfos.idadeFilhos"
                      label="Idade dos filhos"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center" v-if="noBirthdayYet"
                  ><v-col cols="4" class="d-flex justify-end">
                    Data de nascimento</v-col
                  >
                  <v-col cols="1">
                    <v-text-field
                      v-model="birthdayDays"
                      label="dd"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-text-field
                      v-model="birthdayMonths"
                      label="mm"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="birthdayYear"
                      label="aaaa"
                      @change="setBirthday"
                    ></v-text-field> </v-col
                  ><v-col cols="4"></v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      :prepend-icon="icons.mdiAccountHardHat"
                      v-model="pacienteInfos.profissao"
                      label="Profissão"
                      :rules="mandatory"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-if="pacIndText"
                      label="Como você ficou sabendo dos nossos serviços?"
                      v-model="pacienteInfos.indicacao"
                      @change="pacIndTextCheck"
                      :rules="indRules"
                    ></v-text-field>
                    <v-select
                      v-else
                      :prepend-icon="icons.mdiAccountVoice"
                      v-model="pacienteInfos.indicacao"
                      label="Como você ficou sabendo dos nossos serviços?"
                      :rules="mandatory"
                      :items="channels"
                      @change="pacIndTextCheck"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-text-field
                      :prepend-icon="icons.mdiWeightKilogram"
                      v-model="pacienteInfos.pesoAtual"
                      label="Qual é seu peso atual?"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-row>
                      <v-col cols="6">
                        <small
                          >Altura em cm. Por exemplo: "177", sem vírgula ou
                          ponto</small
                        >
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          :prepend-icon="icons.mdiHumanMaleHeightVariant"
                          v-model="pacienteInfos.altura"
                          label="Qual é sua altura?"
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-container fluid>
                      <p>Em relação ao seu peso, você se considera...</p>
                      <v-radio-group
                        v-model="pacienteInfos.pesoConsidera"
                        column
                      >
                        <v-radio
                          label="Baixo peso"
                          value="Baixo peso"
                          color="#d7191c"
                        ></v-radio>
                        <v-radio
                          label="Peso normal"
                          value="Peso normal"
                          color="#1a9641"
                        ></v-radio>
                        <v-radio
                          label="Um pouco acima do peso"
                          value="Um pouco acima do peso"
                          color="#fdae61"
                        ></v-radio>
                        <v-radio
                          label="Muito acima do peso"
                          value="Muito acima do peso"
                          color="#d7191c"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Descreva detalhadamente todos os seus
                      <strong>
                        problemas de saúde / sinais / sintomas para os quais
                        você deseja tratamento nutricional</strong
                      >.
                    </p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.problemasSaude"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.gastrointestinal"
                >
                  <v-container fluid>
                    <p>
                      Você percebe algum
                      <strong>problema gastrointestinal</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.gastrointestinal"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.gastrointestinal"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.gastrointestinalComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.unhasCabelo"
                >
                  <v-container fluid>
                    <p>
                      Você percebe alguma
                      <strong>alteração nas unhas, cabelo ou pele</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.unhasCabelo"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.unhasCabelo"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.unhasCabeloComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.ansiedade"
                >
                  <v-container fluid>
                    <p>
                      Você sofre de
                      <strong>ansiedade, depressão, sindrome do pânico</strong>
                      ou qualquer transtorno psiquiátrico?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.ansiedade"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.ansiedade"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.ansiedadeComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.imunidade"
                >
                  <v-container fluid>
                    <p>
                      Você percebe que sua <strong>imunidade é baixa</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.imunidade"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.imunidade"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.imunidadeComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center" v-if="pacienteInfos.sono">
                  <v-container fluid>
                    <p>
                      Você percebe alguma <strong>alteração no sono</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.sono"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.sono"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.sonoComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.estressada"
                >
                  <v-container fluid>
                    <p>
                      Você se acha uma
                      <strong>pessoa muita estressada</strong> ou tem uma rotina
                      muito estressante?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.estressada"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.estressada"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.estressadaComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row
                  class="d-flex justify-center"
                  v-if="pacienteInfos.outraDieta"
                >
                  <v-container fluid>
                    <p>
                      Você já tentou fazer <strong>outras dietas</strong> com
                      alguma nutricionista antes?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.outraDieta"
                      value="Sim"
                      label="Sim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.outraDieta"
                      value="Não"
                      label="Não"
                      color="#c5d13f"
                    ></v-checkbox>
                    <p class="mt-2">Comente:</p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.outraDietaComment"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você faz uso de algum
                      <strong>
                        suplemento, proteico, polivitamínico ou remédio
                        natural</strong
                      >? Se sim, quais?
                    </p>
                    <v-text-field
                      v-model="pacienteInfos.suplemento"
                      label="..."
                    >
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você toma algum <strong>medicamento</strong>? Descreva-o
                      nome e as doses.
                    </p>
                    <v-text-field
                      v-model="pacienteInfos.medicamento"
                      label="..."
                    >
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você fez <strong>exames de sangue</strong> recentemente ou
                      tem
                      <strong
                        >histórico de alguma alteração / deficiência</strong
                      >? Descreva o que for relevante para seu tratamento
                      nutricional.
                    </p>
                    <v-text-field v-model="pacienteInfos.sangue" label="...">
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você fez algum
                      <strong>exame de imagem recentemente</strong>? Foi
                      encontrado algo fora da normalidade no laudo? Comente.
                    </p>
                    <v-text-field v-model="pacienteInfos.imagem" label="...">
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Você percebe ou já foi diagnosticada com alguma
                      <strong>alergia ou intolerância alimentar</strong>?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Glúten"
                      label="Glúten"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Leite de vaca"
                      label="Leite de vaca"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Lactose"
                      label="Lactose"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Frutos do mar"
                      label="Frutos do mar"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Ovos"
                      label="Ovos"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Abacaxi"
                      label="Abacaxi"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.alergia"
                      value="Castanhas"
                      label="Castanhas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-text-field
                      v-model="pacienteInfos.alergiaOutro"
                      label="Outros?"
                    ></v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      <strong>Que resultado no seu corpo</strong> você deseja
                      obter <strong>após seguir</strong> nossas orientações
                      nutricionais? Liste todas as suas expectativas quanto ao
                      tratamento.
                    </p>
                    <v-text-field v-model="pacienteInfos.resultado" label="...">
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Descreva os <strong>horários e os locais</strong> em que
                      você normalmente faz <strong>suas refeições</strong>.
                    </p>
                    <p class="grey--text darken-3">
                      Exemplo: Café da manhã às 7h - em casa, Lanche da manhã às
                      10h - na escola, Almoço às 13h - no restaurante self
                      service, Lanche da tarde - não faço, Jantar às 19h - peço
                      delivery em casa, Ceia às 23h - em casa, etc.
                    </p>
                    <v-textarea
                      solo
                      v-model="pacienteInfos.refeicoesHorarios"
                      label="..."
                    >
                    </v-textarea>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="6" md="6">
                    <v-container fluid>
                      <p>Você gosta de cozinhar?</p>
                      <v-radio-group
                        v-model="pacienteInfos.gostaCozinhar"
                        column
                      >
                        <v-radio
                          label="Sim, gosto"
                          value="Sim"
                          color="#c5d13f"
                        ></v-radio>
                        <v-radio
                          label="Não, não gosto"
                          value="Não"
                          color="#614021"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-container fluid>
                      <p>Tem alguém que cozinha para você?</p>
                      <v-radio-group
                        v-model="pacienteInfos.outroCozinhar"
                        column
                      >
                        <v-radio
                          label="Sim, outra pessoa cozinha para mim"
                          value="Sim"
                          color="#c5d13f"
                        ></v-radio>
                        <v-radio
                          label="Não, eu mesmo cozinho"
                          value="Não"
                          color="#614021"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="3">
                    <v-container fluid>
                      <p>Faz atividade física?</p>
                      <v-radio-group
                        v-model="pacienteInfos.fazAtividade"
                        column
                      >
                        <v-radio
                          label="Sim, faço"
                          value="Sim"
                          color="#c5d13f"
                        ></v-radio>
                        <v-radio
                          label="Não, não faço"
                          value="Não"
                          color="#614021"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-container fluid>
                      <p>
                        Quais <strong>dias e horários</strong> você faz ou
                        planeja fazer <strong>atividade física</strong>?
                        <br />Escreva em detalhes para que possamos ajustar o
                        cardápio de acordo com o tipo e horário do treino.
                      </p>
                      <v-textarea
                        v-model="pacienteInfos.atividadeDetalhe"
                        label="..."
                      >
                      </v-textarea>
                    </v-container>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Sobre o <strong>café da manhã</strong>: Gosta apenas de
                      café, café com leite ou tolera sucos e vitaminas?
                    </p>
                    <v-text-field v-model="pacienteInfos.cafe" label="...">
                    </v-text-field>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Gosta de comer frutas no café da manhã? Quais?</p>
                    <v-text-field
                      v-model="pacienteInfos.cafeFrutas"
                      label="..."
                    >
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Quais destes alimentos que você tolera no café da manhã ?
                      Marque as opções abaixo:
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Pão Integral"
                      label="Pão Integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Tapioquinha com ovo; queijo ou coco"
                      label="Tapioquinha com ovo; queijo ou coco"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Torrada integral"
                      label="Torrada integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Preparações como panquecas proteicas"
                      label="Preparações como panquecas proteicas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Wrap integral"
                      label="Wrap integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Batata doce"
                      label="Batata doce"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.cafeTolerar"
                      value="Macaxeira cozidas"
                      label="Macaxeira cozidas"
                      color="#c5d13f"
                    ></v-checkbox>
                  </v-container>
                </v-row>

                <v-divider></v-divider>

                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Nos <strong>lanches</strong>, quais das opções abaixo
                      podem ser incluídas no seu cardápio?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Frutas"
                      label="Frutas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Castanhas"
                      label="Castanhas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Pasta de amendoim"
                      label="Pasta de amendoim"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Iogurte natural"
                      label="Iogurte natural"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Sementes como chia / linhaça"
                      label="Sementes como chia / linhaça"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Pão integral"
                      label="Pão integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Patê natural de frango ou atum"
                      label="Patê natural de frango ou atum"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Bolos ou bolachas integrais"
                      label="Bolos ou bolachas integrais"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Torrada integral"
                      label="Torrada integral"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Ovos mexidos ou cozidos"
                      label="Ovos mexidos ou cozidos"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.lanches"
                      value="Tapioquinha"
                      label="Tapioquinha"
                      color="#c5d13f"
                    ></v-checkbox>
                  </v-container>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>Que <strong>frutas</strong> você mais gosta?</p>
                      <v-text-field
                        v-model="pacienteInfos.frutasGostar"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Que <strong>frutas</strong> você
                        <strong>não</strong> tolera?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.frutasNaoTolerar"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-col cols="12">
                    <v-row class="d-flex justify-center">
                      <p>Sobre o <strong>almoço</strong>:</p>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Gosta do tradicional
                        <strong>arroz/ farinha/ farofa/ feijão</strong>?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.almocoFeijao"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Gosta de <strong>saladas</strong> cruas, refogadas e
                        cozidas?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.almocoSalada"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Tem <strong>aversão</strong> por algum
                        <strong>vegetal</strong>? Quais?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.aversaoVegetal"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Gosta de outras leguminosas como grão de bico, lentilha
                        e/ou ervilha?
                      </p>
                      <v-text-field
                        v-model="pacienteInfos.leguminosas"
                        label="..."
                      >
                      </v-text-field>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Costuma comer
                        <strong>purês, quinoa e/ou raízes</strong> como batata
                        doce e macaxeira?
                      </p>
                      <v-text-field v-model="pacienteInfos.pures" label="...">
                      </v-text-field>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-container fluid>
                      <p>
                        Gosta de <strong>massas</strong> tipo macarronada,
                        lasanha etc?
                      </p>
                      <v-text-field v-model="pacienteInfos.massas" label="...">
                      </v-text-field>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row>
                  <v-container fluid>
                    <p>Gosta de frango, carne vermelha e peixe?</p>
                    <v-text-field
                      v-model="pacienteInfos.frangoCarnePeixe"
                      label="..."
                    >
                    </v-text-field>
                  </v-container>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-col cols="12">
                    <v-row class="d-flex justify-center">
                      <p>Sobre o <strong>jantar</strong>:</p>
                    </v-row>
                  </v-col>
                  <v-container fluid>
                    <p>
                      Gosta de comer
                      <strong>o mesmo do almoço ou lanches</strong>? Ou os dois?
                    </p>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.jantar"
                      value="O mesmo do almoço"
                      label="O mesmo do almoço"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.jantar"
                      value="Somente lanche"
                      label="Somente lanche"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.jantar"
                      value="Gosto de sopas"
                      label="Gosto de sopas"
                      color="#c5d13f"
                    ></v-checkbox>
                    <v-checkbox
                      hide-details
                      v-model="pacienteInfos.jantar"
                      value="Não como nada na hora do jantar"
                      label="Não como nada na hora do jantar"
                      color="#c5d13f"
                    ></v-checkbox>
                  </v-container>
                </v-row>
                <v-row>
                  <v-container fluid>
                    <p>
                      Sobre a <strong>ceia</strong>: o que normalmente você come
                      antes de dormir?
                    </p>
                    <v-text-field v-model="pacienteInfos.ceia" label="...">
                    </v-text-field>
                  </v-container>
                </v-row>
              </v-container>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Composição corporal
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-title class="d-flex justify-center">
                  <v-btn color="#c5d13f" @click="visBioimpAll">
                    Ver biomimpedâncias
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="d-flex justify-center">
                    <small
                      v-if="lastAtendimento.data !== null"
                      class="blue--text"
                    >
                      Data do último registro de dados de composição corporal:
                      {{ getDataStr(lastAtendimento.data) }}
                    </small>
                    <small v-else class="red--text">
                      Não foram registrados ainda os dados de composição
                      corporal deste paciente!
                    </small>
                  </v-row>
                  <v-row class="mb-4 text-subtitle-1">
                    <v-col cols="2">
                      Taxa metabol.:
                      <small
                        v-if="lastAtendimento.compos.taxaMetabolica !== null"
                        class="blue--text"
                      >
                        {{ lastAtendimento.compos.taxaMetabolica }} kcal
                      </small>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.taxaMetabolica"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field
                      >kcal</v-col
                    >
                    <v-col cols="2">
                      Peso ideal:
                      <small
                        v-if="lastAtendimento.compos.pesoIdeal !== null"
                        class="blue--text"
                      >
                        {{ lastAtendimento.compos.pesoIdeal }} kg
                      </small>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.pesoIdeal"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field
                      >kg</v-col
                    >
                    <v-col cols="2">
                      Peso atual:
                      <small
                        v-if="lastAtendimento.compos.pesoAtual !== null"
                        class="blue--text"
                      >
                        {{ lastAtendimento.compos.pesoAtual }} kg
                      </small>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.pesoAtual"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changePesoAtual(compos.pesoAtual)"
                      >
                      </v-text-field
                      >kg</v-col
                    >
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-icon :color="colorPeso">{{
                      icons.mdiAlertCircle
                    }}</v-icon>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>

            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-text>
                  <v-row class="mb-4 text-subtitle-1">
                    Gordura corporal: {{ compos.percGordura }}%,
                    {{ compos.percGorduraAbs }} kg
                    <small
                      v-if="lastAtendimento.compos.taxaMetabolica !== null"
                      class="blue--text mx-2"
                    >
                      {{ twoZeros(lastAtendimento.compos.percGordura) }} %,
                      {{ twoZeros(lastAtendimento.compos.percGorduraAbs) }} kg
                    </small>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      <v-slider
                        v-model="compos.percGordura"
                        :color="color"
                        track-color="grey"
                        always-dirty
                        step="0.1"
                        min="0"
                        max="100"
                        @change="changePercGordura(compos.percGordura, true)"
                      ></v-slider>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.percGordura"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changePercGordura(compos.percGordura, true)"
                      >
                      </v-text-field
                      >%
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.percGorduraAbs"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changeAbsGordura(compos.percGorduraAbs, false)"
                      >
                      </v-text-field
                      >kg
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-text>
                  <v-row class="mb-4 text-subtitle-1">
                    Gordura visceral: Nível {{ compos.gorduraVisc }}
                    <small
                      v-if="lastAtendimento.compos.taxaMetabolica !== null"
                      class="blue--text mx-2"
                    >
                      {{ lastAtendimento.compos.gorduraVisc }}
                    </small>
                  </v-row>

                  <v-row>
                    <v-col cols="8">
                      <v-slider
                        v-model="compos.gorduraVisc"
                        :color="colorVisc"
                        track-color="grey"
                        always-dirty
                        step="0.1"
                        min="0"
                        max="18"
                      ></v-slider>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.gorduraVisc"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-text>
                  <v-row class="mb-4 text-subtitle-1">
                    Massa muscular esquelética: {{ compos.massaMagra }}%,
                    {{ compos.massaMagraAbs }}kg
                    <small
                      v-if="lastAtendimento.compos.taxaMetabolica !== null"
                      class="blue--text mx-2"
                    >
                      {{ twoZeros(lastAtendimento.compos.massaMagra) }} %,
                      {{ twoZeros(lastAtendimento.compos.massaMagraAbs) }} kg
                    </small>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      <v-slider
                        v-model="compos.massaMagra"
                        :color="colorMassa"
                        track-color="grey"
                        always-dirty
                        step="0.1"
                        min="0"
                        max="100"
                        @change="changeMassaMagra(compos.massaMagra, true)"
                      ></v-slider>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.massaMagra"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changeMassaMagra(compos.massaMagra, true)"
                      ></v-text-field
                      >%
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.massaMagraAbs"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="
                          changeMassaMagraAbs(compos.massaMagraAbs, false)
                        "
                      >
                      </v-text-field
                      >kg
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-text>
                  <v-row class="mb-4 text-subtitle-1">
                    Água corporal: {{ compos.agua }}%,
                    {{ compos.aguaAbs }}litros
                    <small
                      v-if="lastAtendimento.compos.taxaMetabolica !== null"
                      class="blue--text mx-2"
                    >
                      {{ twoZeros(lastAtendimento.compos.agua) }} %,
                      {{ twoZeros(lastAtendimento.compos.aguaAbs) }} litros
                    </small>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      <v-slider
                        v-model="compos.agua"
                        :color="colorAgua"
                        track-color="grey"
                        always-dirty
                        step="0.1"
                        min="0"
                        max="100"
                        @change="changeAgua(compos.agua, true)"
                      ></v-slider>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.agua"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changeAgua(compos.agua, true)"
                      >
                      </v-text-field
                      >%
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.aguaAbs"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changeAguaAbs(compos.aguaAbs, false)"
                      >
                      </v-text-field
                      >litros
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header> Anamneses </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="anamnesesNr < 1">
              <v-card
                style="background-color: rgba(197, 209, 63, 0.6)"
                class="my-2"
              >
                <v-card-title v-if="pacienteInfos.profissao">
                  Primeira Anamnese, {{ nowDateStr }},
                  {{ pacienteFirestore.nome }},
                  {{ getAge(pacienteFirestore.dataNascimento) }},
                  {{ pacienteInfos.profissao }}
                </v-card-title>
                <v-card-title v-else>
                  Primeira Anamnese, {{ nowDateStr }},
                  {{ pacienteFirestore.nome }},
                  {{ getAge(pacienteFirestore.dataNascimento) }}
                </v-card-title>
                <v-card-text>
                  <v-textarea
                    solo
                    v-model="anamneseContent"
                    height="300"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </div>
            <div v-else>
              <v-card
                v-for="note in allAnamneseNotes"
                :key="note.index"
                style="background-color: rgba(197, 209, 63, 0.6)"
                class="my-2"
              >
                <v-card-title>
                  Anamnese número {{ note.index }}, {{ note.dateStr }},
                  {{ pacienteFirestore.nome }},
                  {{ getAge(pacienteFirestore.dataNascimento) }}
                </v-card-title>
                <v-card-text>
                  <v-textarea
                    solo
                    v-model="note.content"
                    height="300"
                  ></v-textarea>
                </v-card-text>
              </v-card>
              <v-card
                style="background-color: rgba(197, 209, 63, 0.6)"
                class="my-2"
              >
                <v-card-title>
                  Anamnese número {{ anamnesesNr + 1 }}, {{ nowDateStr }},
                  {{ pacienteFirestore.nome }},
                  {{ getAge(pacienteFirestore.dataNascimento) }}
                </v-card-title>
                <v-card-text>
                  <v-textarea
                    solo
                    v-model="anamneseContent"
                    height="300"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row v-if="apenasEstet">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Questionário estético
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="formE" v-model="validE" class="grey lighten-3">
              <v-container>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiMapMarker"
                      v-model="pacienteInfos.endereco"
                      label="Endereço"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiCity"
                      v-model="pacienteInfos.cidade"
                      label="Cidade"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiFlagVariantOutline"
                      v-model="pacienteInfos.estado"
                      label="Estado / País"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-select
                      :prepend-icon="icons.mdiHandHeart"
                      v-model="pacienteInfos.estadoCivil"
                      :items="estadoCivilItems"
                      label="Estado civil"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiBabyCarriage"
                      v-model="pacienteInfos.filhos"
                      label="Filhos"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiHumanMaleChild"
                      v-model="pacienteInfos.idadeFilhos"
                      label="Idade dos filhos"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center" v-if="noBirthdayYet"
                  ><v-col cols="4" class="d-flex justify-end">
                    Data de nascimento</v-col
                  >
                  <v-col cols="1">
                    <v-text-field
                      v-model="birthdayDays"
                      label="dd"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-text-field
                      v-model="birthdayMonths"
                      label="mm"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="birthdayYear"
                      label="aaaa"
                      @change="setBirthday"
                    ></v-text-field> </v-col
                  ><v-col cols="4"></v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      :prepend-icon="icons.mdiAccountHardHat"
                      v-model="pacienteInfos.profissao"
                      label="Profissão"
                      :rules="mandatory"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-if="pacIndText"
                      label="Como você ficou sabendo dos nossos serviços?"
                      v-model="pacienteInfos.indicacao"
                      @change="pacIndTextCheck"
                      :rules="indRules"
                    ></v-text-field>
                    <v-select
                      v-else
                      :prepend-icon="icons.mdiAccountVoice"
                      v-model="pacienteInfos.indicacao"
                      label="Como você ficou sabendo dos nossos serviços?"
                      :rules="mandatory"
                      :items="channels"
                      @change="pacIndTextCheck"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Implante metálico no corpo</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet9"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Uma doença infecto-contagiosa</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet10"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Febre, inflamação, dermatites (eczema, herpes)</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet1"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Alterações da Tiróide</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet2"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Feridas abertas ou suturadas recentemente</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet3"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Doenças graves ou progressivas (cancro, sida, hepatite,
                      doenças sanguíneas, etc)
                    </p>
                    <v-radio-group
                      v-model="pacienteInfos.estet4"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Problemas de coagulação sanguínea</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet5"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Cirurgias vasculares nos últimos 2 meses</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet6"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>
                      Uso prolongado de medicamentos do tipo anti-inflamatórios,
                      anticoagulantes, antibióticos
                    </p>
                    <v-radio-group
                      v-model="pacienteInfos.estet7"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-container fluid>
                    <p>Gravidez</p>
                    <v-radio-group
                      v-model="pacienteInfos.estet8"
                      column
                      :rules="mandatory"
                    >
                      <v-radio label="Sim" value="Sim" color="red"></v-radio>
                      <v-radio label="Não" value="Não" color="blue"></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>
              </v-container>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Composição corporal
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-title class="d-flex justify-center">
                  <v-btn color="#c5d13f" @click="visBioimpAll">
                    Ver biomimpedâncias
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="d-flex justify-center">
                    <small
                      v-if="lastAtendimento.data !== null"
                      class="blue--text"
                    >
                      Data do último registro de dados de composição corporal:
                      {{ getDataStr(lastAtendimento.data) }}
                    </small>
                    <small v-else class="red--text">
                      Não foram registrados ainda os dados de composição
                      corporal deste paciente!
                    </small>
                  </v-row>
                  <v-row class="mb-4 text-subtitle-1">
                    <v-col cols="2">
                      Taxa metabol.:
                      <small
                        v-if="lastAtendimento.compos.taxaMetabolica !== null"
                        class="blue--text"
                      >
                        {{ lastAtendimento.compos.taxaMetabolica }} kcal
                      </small>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.taxaMetabolica"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field
                      >kcal</v-col
                    >
                    <v-col cols="2">
                      Peso ideal:
                      <small
                        v-if="lastAtendimento.compos.pesoIdeal !== null"
                        class="blue--text"
                      >
                        {{ lastAtendimento.compos.pesoIdeal }} kg
                      </small>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.pesoIdeal"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field
                      >kg</v-col
                    >
                    <v-col cols="2">
                      Peso atual:
                      <small
                        v-if="lastAtendimento.compos.pesoAtual !== null"
                        class="blue--text"
                      >
                        {{ lastAtendimento.compos.pesoAtual }} kg
                      </small>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.pesoAtual"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changePesoAtual(compos.pesoAtual)"
                      >
                      </v-text-field
                      >kg</v-col
                    >
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-icon :color="colorPeso">{{
                      icons.mdiAlertCircle
                    }}</v-icon>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>

            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-text>
                  <v-row class="mb-4 text-subtitle-1">
                    Gordura corporal: {{ compos.percGordura }}%,
                    {{ compos.percGorduraAbs }} kg
                    <small
                      v-if="lastAtendimento.compos.taxaMetabolica !== null"
                      class="blue--text mx-2"
                    >
                      {{ twoZeros(lastAtendimento.compos.percGordura) }} %,
                      {{ twoZeros(lastAtendimento.compos.percGorduraAbs) }} kg
                    </small>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      <v-slider
                        v-model="compos.percGordura"
                        :color="color"
                        track-color="grey"
                        always-dirty
                        step="0.1"
                        min="0"
                        max="100"
                        @change="changePercGordura(compos.percGordura, true)"
                      ></v-slider>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.percGordura"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changePercGordura(compos.percGordura, true)"
                      >
                      </v-text-field
                      >%
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.percGorduraAbs"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changeAbsGordura(compos.percGorduraAbs, false)"
                      >
                      </v-text-field
                      >kg
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-text>
                  <v-row class="mb-4 text-subtitle-1">
                    Gordura visceral: Nível {{ compos.gorduraVisc }}
                    <small
                      v-if="lastAtendimento.compos.taxaMetabolica !== null"
                      class="blue--text mx-2"
                    >
                      {{ lastAtendimento.compos.gorduraVisc }}
                    </small>
                  </v-row>

                  <v-row>
                    <v-col cols="8">
                      <v-slider
                        v-model="compos.gorduraVisc"
                        :color="colorVisc"
                        track-color="grey"
                        always-dirty
                        step="0.1"
                        min="0"
                        max="18"
                      ></v-slider>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.gorduraVisc"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-text>
                  <v-row class="mb-4 text-subtitle-1">
                    Massa muscular esquelética: {{ compos.massaMagra }}%,
                    {{ compos.massaMagraAbs }}kg
                    <small
                      v-if="lastAtendimento.compos.taxaMetabolica !== null"
                      class="blue--text mx-2"
                    >
                      {{ twoZeros(lastAtendimento.compos.massaMagra) }} %,
                      {{ twoZeros(lastAtendimento.compos.massaMagraAbs) }} kg
                    </small>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      <v-slider
                        v-model="compos.massaMagra"
                        :color="colorMassa"
                        track-color="grey"
                        always-dirty
                        step="0.1"
                        min="0"
                        max="100"
                        @change="changeMassaMagra(compos.massaMagra, true)"
                      ></v-slider>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.massaMagra"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changeMassaMagra(compos.massaMagra, true)"
                      ></v-text-field
                      >%
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.massaMagraAbs"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="
                          changeMassaMagraAbs(compos.massaMagraAbs, false)
                        "
                      >
                      </v-text-field
                      >kg
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row class="justify-center">
              <v-card class="my-2" min-width="600" outlined>
                <v-card-text>
                  <v-row class="mb-4 text-subtitle-1">
                    Água corporal: {{ compos.agua }}%,
                    {{ compos.aguaAbs }}litros
                    <small
                      v-if="lastAtendimento.compos.taxaMetabolica !== null"
                      class="blue--text mx-2"
                    >
                      {{ twoZeros(lastAtendimento.compos.agua) }} %,
                      {{ twoZeros(lastAtendimento.compos.aguaAbs) }} litros
                    </small>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      <v-slider
                        v-model="compos.agua"
                        :color="colorAgua"
                        track-color="grey"
                        always-dirty
                        step="0.1"
                        min="0"
                        max="100"
                        @change="changeAgua(compos.agua, true)"
                      ></v-slider>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.agua"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changeAgua(compos.agua, true)"
                      >
                      </v-text-field
                      >%
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="compos.aguaAbs"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="changeAguaAbs(compos.aguaAbs, false)"
                      >
                      </v-text-field
                      >litros
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header> Anamneses </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="anamnesesNr < 1">
              <v-card
                style="background-color: rgba(197, 209, 63, 0.6)"
                class="my-2"
              >
                <v-card-title v-if="pacienteInfos.profissao">
                  Primeira Anamnese, {{ nowDateStr }},
                  {{ pacienteFirestore.nome }},
                  {{ getAge(pacienteFirestore.dataNascimento) }},
                  {{ pacienteInfos.profissao }}
                </v-card-title>
                <v-card-title v-else>
                  Primeira Anamnese, {{ nowDateStr }},
                  {{ pacienteFirestore.nome }},
                  {{ getAge(pacienteFirestore.dataNascimento) }}
                </v-card-title>
                <v-card-text>
                  <v-textarea
                    solo
                    v-model="anamneseContent"
                    height="300"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </div>
            <div v-else>
              <v-card
                v-for="note in allAnamneseNotes"
                :key="note.index"
                style="background-color: rgba(197, 209, 63, 0.6)"
                class="my-2"
              >
                <v-card-title>
                  Anamnese número {{ note.index }}, {{ note.dateStr }},
                  {{ pacienteFirestore.nome }},
                  {{ getAge(pacienteFirestore.dataNascimento) }}
                </v-card-title>
                <v-card-text>
                  <v-textarea
                    solo
                    v-model="note.content"
                    height="300"
                  ></v-textarea>
                </v-card-text>
              </v-card>
              <v-card
                style="background-color: rgba(197, 209, 63, 0.6)"
                class="my-2"
              >
                <v-card-title>
                  Anamnese número {{ anamnesesNr + 1 }}, {{ nowDateStr }},
                  {{ pacienteFirestore.nome }},
                  {{ getAge(pacienteFirestore.dataNascimento) }}
                </v-card-title>
                <v-card-text>
                  <v-textarea
                    solo
                    v-model="anamneseContent"
                    height="300"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row class="d-flex justify-center" v-if="isFranquia">
      <v-icon color="green" left v-if="acceptsParceiroF"
        >mdi-text-box-check-outline</v-icon
      >
      <v-icon color="red" left v-else>mdi-text-box-remove-outline</v-icon>
      <v-checkbox
        v-model="acceptsParceiroF"
        color="#c5d13f"
        :label="labelAcceptF(acceptsParceiroF)"
      ></v-checkbox>
    </v-row>
    <v-row class="d-flex justify-center" v-if="isFranquia">
      <v-icon color="green" left v-if="acceptsParceiroE">mdi-needle</v-icon>
      <v-icon color="red" left v-else>mdi-needle</v-icon>
      <v-checkbox
        v-model="acceptsParceiroE"
        color="#c5d13f"
        :label="labelAcceptE(acceptsParceiroE)"
      ></v-checkbox>
    </v-row>
    <v-row class="d-flex justify-center" v-if="apenasEstet">
      <v-btn large color="#c5d13f" class="my-6" @click="checkFormE">
        Concluir (apenas estética)
      </v-btn>
    </v-row>
    <v-row class="d-flex justify-center" v-if="atendimentoPadrao">
      <v-btn dense color="#c5d13f" class="my-4" @click="submit">
        Concluir (atendimento padrão)
      </v-btn>
    </v-row>

    <v-divider class="my-6"></v-divider>

    <div v-if="pacienteFirestore.data.imunidadeToFill">
      <div v-if="imunidadeTestesDoneLength">
        <v-row class="d-flex justify-center my-6"> Atendimento Covid </v-row>
        <v-card
          outlined
          width="90%"
          class="my-2"
          v-for="teste in imunidadeTestesDone"
          :key="teste.id"
        >
          <v-row class="d-flex align-center mx-4">
            <v-col cols="8"
              >Anamnese e Teste de imunidade: {{ teste.dateStr }} ({{
                teste.score
              }})</v-col
            >
            <v-col cols="4" class="d-flex justify-end">
              <v-btn icon color="#614021" @click="openTeste(teste)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <v-expansion-panels v-else>
        <v-expansion-panel v-model="imunidadePanelOpen1">
          <v-expansion-panel-header color="grey lighten-3"
            >Anamnese (imunidade)
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey lighten-3">
            <v-form
              class="grey lighten-3"
              ref="formAnamneseImunidade"
              v-model="validAnamneseImunidade"
            >
              <v-container>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="2"> </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      :prepend-icon="icons.mdiMapMarker"
                      v-model="imunidadeInfos.endereco"
                      label="Endereço"
                      :rules="mandatory"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiHomeCityOutline"
                      v-model="imunidadeInfos.bairro"
                      label="Bairro"
                      :rules="mandatory"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2"> </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiMailboxOpenOutline"
                      v-model="imunidadeInfos.cep"
                      label="CEP"
                      :rules="mandatory"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiCity"
                      v-model="imunidadeInfos.cidade"
                      label="Cidade"
                      :rules="mandatory"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiFlagVariantOutline"
                      v-model="imunidadeInfos.estado"
                      label="Estado / País"
                      :rules="mandatory"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="2"> </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiAccountDetailsOutline"
                      v-model="imunidadeInfos.cpf"
                      label="CPF"
                      :rules="mandatory"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2"> </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4">
                    <v-select
                      :prepend-icon="icons.mdiHandHeart"
                      v-model="imunidadeInfos.estadoCivil"
                      :items="estadoCivilItems"
                      label="Estado civil"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiBabyCarriage"
                      v-model="imunidadeInfos.filhos"
                      label="Filhos"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :prepend-icon="icons.mdiHumanMaleChild"
                      v-model="imunidadeInfos.idadeFilhos"
                      label="Idade dos filhos"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      :prepend-icon="icons.mdiAccountHardHat"
                      v-model="imunidadeInfos.profissao"
                      label="Profissão"
                      :rules="mandatory"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row v-if="pacienteFirestore.data.imunidadeToFill">
                  <v-col cols="12">
                    <v-container fluid>
                      <p>Opções de atendimento:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.opcoesAtendimento"
                        row
                      >
                        <v-radio
                          label="Profilaxia de Covid"
                          value="Profilaxia de Covid"
                          color="green"
                        ></v-radio>
                        <v-radio
                          label="Tratamento de Covid"
                          value="Tratamento de Covid"
                          color="red"
                        ></v-radio>
                        <v-radio
                          label="Tratamento Pós Covid"
                          value="Tratamento Pós Covid"
                          color="blue"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-row class="d-flex justify-center"> Anamnese Covid-19:</v-row>
                <v-row class="d-flex justify-center my-3">
                  <small>Preencha todos os campos seguidos</small></v-row
                >

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Assimtomático:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.assimtomatico"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Dor de cabeça:</p>
                      <v-radio-group v-model="imunidadeInfos.dorCabeca" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Dispnéia (falta de ar):</p>
                      <v-radio-group v-model="imunidadeInfos.dispneia" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center align-center">
                  <v-col cols="12" md="2" class="d-flex align-center">
                    <v-container fluid>
                      <p>Febre:</p>
                      <v-radio-group v-model="imunidadeInfos.febre" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex align-center">
                    <v-container fluid>
                      <p>Tosse:</p>
                      <v-radio-group v-model="imunidadeInfos.tosse" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex align-center">
                    <v-container fluid>
                      <p>Outros:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.outrosSintomas"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="6" class="d-flex align-center">
                    <v-container fluid>
                      <v-text-field
                        :disabled="imunidadeInfos.outrosSintomas !== 'Sim'"
                        v-model="imunidadeInfos.outrosSintomasComment"
                      ></v-text-field>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center align-center">
                  <v-container fluid>
                    <p>Data do início de sintomas.</p>
                    <v-text-field
                      solo
                      v-model="imunidadeInfos.dataSintomas"
                      label="..."
                    >
                    </v-text-field>
                  </v-container>
                </v-row>

                <v-divider></v-divider>
                <v-row class="d-flex justify-center my-3"
                  >Doenças e condições preexistentes</v-row
                >
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Doenças respiratórias crônicas descompensadas:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.doencasResp"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Doenças cardíacas crônicas:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.doencasCard"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Diabetes:</p>
                      <v-radio-group v-model="imunidadeInfos.diabetes1" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Doenças renais crônicas em estado avançado:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.doencasRenais"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Imunosupressão:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.imunosupressao"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Gestante de alto risco:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.gestanteRisco"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Portador de doenças cromossômicas ou estado de
                        fragilidade imunológica:
                      </p>
                      <v-radio-group v-model="imunidadeInfos.cromoImuno" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Teve contato com paciente confirmado ou suspeito de
                        Covid-19:
                      </p>
                      <v-radio-group v-model="imunidadeInfos.contato" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>É profissional da área de saúde:</p>
                      <v-radio-group v-model="imunidadeInfos.areaSaude" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Descreva suas doenças pré-existentes:</p>
                      <v-textarea
                        solo
                        v-model="imunidadeInfos.preexistentes"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-textarea>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Descreva seus sintomas atuais (se houver):</p>
                      <v-textarea
                        solo
                        v-model="imunidadeInfos.sintomas"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-textarea>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12">
                    Anexe seus exames de sangue ou tomografia:
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1">
                  <v-col cols="12">
                    <v-file-input
                      accept="document/*"
                      label="Exame 1"
                      @change="handleExam1"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1">
                  <v-col cols="12">
                    <v-file-input
                      accept="document/*"
                      label="Exame 2"
                      @change="handleExam2"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1">
                  <v-col cols="12">
                    <v-file-input
                      accept="document/*"
                      label="Exame 3"
                      @change="handleExam3"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Descreva sua lista de medicamentos atuais:</p>
                      <v-textarea
                        solo
                        v-model="imunidadeInfos.medicamentos"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-textarea>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Caso esteja tomando algum suplemento, descreva cada
                        nutriente e sua dosagem diária:
                      </p>
                      <v-textarea
                        solo
                        v-model="imunidadeInfos.suplemento"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-textarea>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Alergias ou intolerâncias alimentares? Descreva:</p>
                      <v-textarea
                        solo
                        v-model="imunidadeInfos.alergiaComment"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-textarea>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Descreva todas as substâncias que você é alérgico ou que
                        de alguma forma lhe faz mal:
                      </p>
                      <v-textarea
                        solo
                        v-model="imunidadeInfos.substanciasAlergico"
                        label="..."
                        :rules="mandatory"
                      >
                      </v-textarea>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center align-center">
                  <v-icon color="blue" class="mx-2">mdi-arrow-down-bold</v-icon>
                  Continue agora com o Teste de Imunidade abaixo
                  <v-icon color="blue" class="mx-2">mdi-arrow-down-bold</v-icon>
                </v-row>
              </v-container>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-model="imunidadePanelOpen">
          <v-expansion-panel-header color="grey lighten-3">
            <v-row class="mx-1">Teste de imunidade</v-row>
            <v-row
              v-if="imunidadeTestesDoneLength"
              class="d-flex justify-center text-caption"
            >
              último teste: {{ pacienteFirestore.data.imunidadeTesteDate }} ({{
                pacienteFirestore.data.imunidadeTestScore
              }})
            </v-row>
            <v-btn
              small
              dense
              color="grey lighten-2"
              @click="allScoresDialog = true"
            >
              Ver resultados
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey lighten-3">
            <v-form v-model="validImunidade" class="grey lighten-3">
              <v-container>
                <v-row
                  v-if="pacienteFirestore.data.imunidadeToFill"
                  class="d-flex justify-center my-6 text-subtitle-1"
                >
                  Será que sua imunidade está boa? Responda as perguntas abaixo:
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-row>
                      <v-col cols="6">
                        <small
                          >Peso em kg. Por exemplo: "80", sem vírgula ou
                          ponto</small
                        >
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          :prepend-icon="icons.mdiWeightKilogram"
                          type="number"
                          v-model="imunidadeInfos.pesoAtual"
                          label="peso atual (kg)?"
                          required
                          :rules="numbersOnly"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-row>
                      <v-col cols="6">
                        <small
                          >Altura em cm. Por exemplo: "177", sem vírgula ou
                          ponto</small
                        >
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          :prepend-icon="icons.mdiHumanMaleHeightVariant"
                          v-model="imunidadeInfos.altura"
                          type="number"
                          label="altura (cm)"
                          required
                          :rules="numbersOnly"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex justify-center text-h6">
                    IMC: {{ bmi }}
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Gripes ou resfriados frequentes:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.gripesFrequentes"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Viroses constantes:</p>
                      <v-radio-group v-model="imunidadeInfos.viroses" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Aftas na boca:</p>
                      <v-radio-group v-model="imunidadeInfos.aftas" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Infecção urinária de repetição:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.infeccaoUrinaria"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Amigdalite ou herpes recorrente:</p>
                      <v-radio-group v-model="imunidadeInfos.amigdalite" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Doenças simples, mas que demoram a passar ou que agravam
                        facilmente:
                      </p>
                      <v-radio-group
                        v-model="imunidadeInfos.doencasDemoram"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Febre frequente e calafrios:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.febresCalafrios"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Olhos frequentemente secos:</p>
                      <v-radio-group v-model="imunidadeInfos.olhos" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Cansaço excessivo:</p>
                      <v-radio-group v-model="imunidadeInfos.cansaco" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Náuseas e vômitos:</p>
                      <v-radio-group v-model="imunidadeInfos.nauseas" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Diarreia:</p>
                      <v-radio-group v-model="imunidadeInfos.diarreia" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Intestino constipado:</p>
                      <v-radio-group v-model="imunidadeInfos.constipado" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Candidíase de repetição</p>
                      <v-radio-group v-model="imunidadeInfos.candidiase" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Manchas vermelhas ou brancas na pele:</p>
                      <v-radio-group v-model="imunidadeInfos.manchas" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Queda acentuada de cabelo ou cabelos quebradiços:</p>
                      <v-radio-group v-model="imunidadeInfos.cabelo" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>
                        Unhas fracas, fofas, com listras, amareladas ou
                        esbranquiçadas:
                      </p>
                      <v-radio-group v-model="imunidadeInfos.unhas" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Insônia ou sono ruim:</p>
                      <v-radio-group v-model="imunidadeInfos.insonia" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Acorda se sentindo cansado?</p>
                      <v-radio-group v-model="imunidadeInfos.acorda" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Alto nível de estresse no dia a dia:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.nivelEstresse"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Crises de ansiedade / pânico:</p>
                      <v-radio-group
                        v-model="imunidadeInfos.criseAnsiedade"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Sofre de depressão?</p>
                      <v-radio-group v-model="imunidadeInfos.depressao" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Diabetes, pré-diabetes ou resistência a insulina:</p>
                      <v-radio-group v-model="imunidadeInfos.diabetes" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Toma remédio para pressão alta?</p>
                      <v-radio-group
                        v-model="imunidadeInfos.pressaoAlta"
                        column
                      >
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-container fluid>
                      <p>Sofre de alguma doença auto imune?</p>
                      <v-radio-group v-model="imunidadeInfos.autoImune" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex align-center">
                    <v-container fluid>
                      <p>Alergias ou intolerâncias alimentares:</p>
                      <v-radio-group v-model="imunidadeInfos.alergias" column>
                        <v-radio label="Sim" value="Sim" color="red"></v-radio>
                        <v-radio
                          label="Não"
                          value="Não"
                          color="black"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>

            <v-row class="d-flex justify-center">
              <v-btn large color="#c5d13f" class="my-6" @click="submit">
                Concluir
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-row
      class="d-flex justify-center"
      v-if="
        pacienteFirestore.data.imunidadeToFill && !imunidadeTestesDoneLength
      "
    >
      <v-btn large color="#c5d13f" class="my-6" @click="submitImunidade">
        Concluir
      </v-btn>
    </v-row>

    <v-dialog v-model="testeDialog" max-width="90%">
      <v-card>
        <v-card-title
          >Anamnese e Teste de imunidade: {{ testeDateStr }}
        </v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel v-model="imunidadePanelOpen1d">
              <v-expansion-panel-header color="grey lighten-3"
                >Anamnese (imunidade)
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey lighten-3">
                <v-form class="grey lighten-3" ref="anamneseImunidadeVis">
                  <v-container>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="2"> </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          :prepend-icon="icons.mdiMapMarker"
                          v-model="imunidadeInfosVis.endereco"
                          label="Endereço"
                          :rules="mandatory"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          :prepend-icon="icons.mdiHomeCityOutline"
                          v-model="imunidadeInfosVis.bairro"
                          label="Bairro"
                          :rules="mandatory"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2"> </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4">
                        <v-text-field
                          :prepend-icon="icons.mdiMailboxOpenOutline"
                          v-model="imunidadeInfosVis.cep"
                          label="CEP"
                          :rules="mandatory"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          :prepend-icon="icons.mdiCity"
                          v-model="imunidadeInfosVis.cidade"
                          label="Cidade"
                          :rules="mandatory"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          :prepend-icon="icons.mdiFlagVariantOutline"
                          v-model="imunidadeInfosVis.estado"
                          label="Estado / País"
                          :rules="mandatory"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="2"> </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          :prepend-icon="icons.mdiAccountDetailsOutline"
                          v-model="imunidadeInfosVis.cpf"
                          label="CPF"
                          :rules="mandatory"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2"> </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4">
                        <v-select
                          :prepend-icon="icons.mdiHandHeart"
                          v-model="imunidadeInfosVis.estadoCivil"
                          :items="estadoCivilItems"
                          label="Estado civil"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          :prepend-icon="icons.mdiBabyCarriage"
                          v-model="imunidadeInfosVis.filhos"
                          label="Filhos"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          :prepend-icon="icons.mdiHumanMaleChild"
                          v-model="imunidadeInfosVis.idadeFilhos"
                          label="Idade dos filhos"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="6">
                        <v-text-field
                          :prepend-icon="icons.mdiAccountHardHat"
                          v-model="imunidadeInfosVis.profissao"
                          label="Profissão"
                          :rules="mandatory"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row v-if="pacienteFirestore.data.imunidadeToFill">
                      <v-col cols="12">
                        <v-container fluid>
                          <p>Opções de atendimento:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.opcoesAtendimento"
                            row
                          >
                            <v-radio
                              label="Profilaxia de Covid"
                              value="Profilaxia de Covid"
                              color="green"
                            ></v-radio>
                            <v-radio
                              label="Tratamento de Covid"
                              value="Tratamento de Covid"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Tratamento Pós Covid"
                              value="Tratamento Pós Covid"
                              color="blue"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <v-row class="d-flex justify-center">
                      Anamnese Covid-19:</v-row
                    >
                    <v-row class="d-flex justify-center my-3">
                      <small>Preencha todos os campos seguidos</small></v-row
                    >

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Assimtomático:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.assimtomatico"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Dor de cabeça:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.dorCabeca"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Dispnéia (falta de ar):</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.dispneia"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center align-center">
                      <v-col cols="12" md="2" class="d-flex align-center">
                        <v-container fluid>
                          <p>Febre:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.febre"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="2" class="d-flex align-center">
                        <v-container fluid>
                          <p>Tosse:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.tosse"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="2" class="d-flex align-center">
                        <v-container fluid>
                          <p>Outros:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.outrosSintomas"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex align-center">
                        <v-container fluid>
                          <v-text-field
                            :disabled="
                              imunidadeInfosVis.outrosSintomas !== 'Sim'
                            "
                            v-model="imunidadeInfosVis.outrosSintomasComment"
                          ></v-text-field>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center align-center">
                      <v-container fluid>
                        <p>Data do início de sintomas.</p>
                        <v-text-field
                          solo
                          v-model="imunidadeInfosVis.dataSintomas"
                          label="..."
                        >
                        </v-text-field>
                      </v-container>
                    </v-row>

                    <v-divider></v-divider>
                    <v-row class="d-flex justify-center my-3"
                      >Doenças e condições preexistentes</v-row
                    >
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Doenças respiratórias crônicas descompensadas:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.doencasResp"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Doenças cardíacas crônicas:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.doencasCard"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Diabetes:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.diabetes1"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Doenças renais crônicas em estado avançado:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.doencasRenais"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Imunosupressão:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.imunosupressao"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Gestante de alto risco:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.gestanteRisco"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>
                            Portador de doenças cromossômicas ou estado de
                            fragilidade imunológica:
                          </p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.cromoImuno"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>
                            Teve contato com paciente confirmado ou suspeito de
                            Covid-19:
                          </p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.contato"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>É profissional da área de saúde:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.areaSaude"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" class="d-flex align-center">
                        <v-container fluid>
                          <p>Descreva suas doenças pré-existentes:</p>
                          <v-textarea
                            solo
                            v-model="imunidadeInfosVis.preexistentes"
                            label="..."
                            :rules="mandatory"
                          >
                          </v-textarea>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" class="d-flex align-center">
                        <v-container fluid>
                          <p>Descreva seus sintomas atuais (se houver):</p>
                          <v-textarea
                            solo
                            v-model="imunidadeInfosVis.sintomas"
                            label="..."
                            :rules="mandatory"
                          >
                          </v-textarea>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12">
                        Anexe seus exames de sangue ou tomografia:
                      </v-col>
                    </v-row>
                    <v-row class="text-subtitle-1">
                      <v-col cols="12">
                        <v-file-input
                          accept="document/*"
                          label="Exame 1"
                          @change="handleExam1"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row class="text-subtitle-1">
                      <v-col cols="12">
                        <v-file-input
                          accept="document/*"
                          label="Exame 2"
                          @change="handleExam2"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row class="text-subtitle-1">
                      <v-col cols="12">
                        <v-file-input
                          accept="document/*"
                          label="Exame 3"
                          @change="handleExam3"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" class="d-flex align-center">
                        <v-container fluid>
                          <p>Descreva sua lista de medicamentos atuais:</p>
                          <v-textarea
                            solo
                            v-model="imunidadeInfosVis.medicamentos"
                            label="..."
                            :rules="mandatory"
                          >
                          </v-textarea>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" class="d-flex align-center">
                        <v-container fluid>
                          <p>
                            Caso esteja tomando algum suplemento, descreva cada
                            nutriente e sua dosagem diária:
                          </p>
                          <v-textarea
                            solo
                            v-model="imunidadeInfosVis.suplemento"
                            label="..."
                            :rules="mandatory"
                          >
                          </v-textarea>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" class="d-flex align-center">
                        <v-container fluid>
                          <p>
                            Alergias ou intolerâncias alimentares? Descreva:
                          </p>
                          <v-textarea
                            solo
                            v-model="imunidadeInfosVis.alergiaComment"
                            label="..."
                            :rules="mandatory"
                          >
                          </v-textarea>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" class="d-flex align-center">
                        <v-container fluid>
                          <p>
                            Descreva todas as substâncias que você é alérgico ou
                            que de alguma forma lhe faz mal:
                          </p>
                          <v-textarea
                            solo
                            v-model="imunidadeInfosVis.substanciasAlergico"
                            label="..."
                            :rules="mandatory"
                          >
                          </v-textarea>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center align-center">
                      <v-icon color="blue" class="mx-2"
                        >mdi-arrow-down-bold</v-icon
                      >
                      Continue agora com o Teste de Imunidade abaixo
                      <v-icon color="blue" class="mx-2"
                        >mdi-arrow-down-bold</v-icon
                      >
                    </v-row>
                  </v-container>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-model="imunidadePanelOpend">
              <v-expansion-panel-header color="grey lighten-3">
                <v-row class="mx-1">Teste de imunidade</v-row>
                <v-row
                  v-if="imunidadeTestesDoneLength"
                  class="d-flex justify-center text-caption"
                >
                  último teste:
                  {{ pacienteFirestore.data.imunidadeTesteDate }} ({{
                    pacienteFirestore.data.imunidadeTestScore
                  }})
                </v-row>
                <v-btn
                  small
                  dense
                  color="grey lighten-2"
                  @click="allScoresDialog = true"
                >
                  Ver resultados
                </v-btn>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey lighten-3">
                <v-form class="grey lighten-3" ref="imunidadeVis">
                  <v-container>
                    <v-row
                      v-if="pacienteFirestore.data.imunidadeToFill"
                      class="d-flex justify-center my-6 text-subtitle-1"
                    >
                      Será que sua imunidade está boa? Responda as perguntas
                      abaixo:
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-row>
                          <v-col cols="6">
                            <small
                              >Peso em kg. Por exemplo: "80", sem vírgula ou
                              ponto</small
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :prepend-icon="icons.mdiWeightKilogram"
                              type="number"
                              v-model="imunidadeInfosVis.pesoAtual"
                              label="peso atual (kg)?"
                              required
                              :rules="numbersOnly"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-row>
                          <v-col cols="6">
                            <small
                              >Altura em cm. Por exemplo: "177", sem vírgula ou
                              ponto</small
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :prepend-icon="icons.mdiHumanMaleHeightVariant"
                              v-model="imunidadeInfosVis.altura"
                              type="number"
                              label="altura (cm)"
                              required
                              :rules="numbersOnly"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                        class="d-flex justify-center text-h6"
                      >
                        IMC: {{ bmi }}
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Gripes ou resfriados frequentes:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.gripesFrequentes"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Viroses constantes:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.viroses"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Aftas na boca:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.aftas"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Infecção urinária de repetição:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.infeccaoUrinaria"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Amigdalite ou herpes recorrente:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.amigdalite"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>
                            Doenças simples, mas que demoram a passar ou que
                            agravam facilmente:
                          </p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.doencasDemoram"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Febre frequente e calafrios:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.febresCalafrios"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Olhos frequentemente secos:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.olhos"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Cansaço excessivo:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.cansaco"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Náuseas e vômitos:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.nauseas"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Diarreia:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.diarreia"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Intestino constipado:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.constipado"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Candidíase de repetição</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.candidiase"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Manchas vermelhas ou brancas na pele:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.manchas"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>
                            Queda acentuada de cabelo ou cabelos quebradiços:
                          </p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.cabelo"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>
                            Unhas fracas, fofas, com listras, amareladas ou
                            esbranquiçadas:
                          </p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.unhas"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Insônia ou sono ruim:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.insonia"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Acorda se sentindo cansado?</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.acorda"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Alto nível de estresse no dia a dia:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.nivelEstresse"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Crises de ansiedade / pânico:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.criseAnsiedade"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Sofre de depressão?</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.depressao"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row class="d-flex justify-center">
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>
                            Diabetes, pré-diabetes ou resistência a insulina:
                          </p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.diabetes"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Toma remédio para pressão alta?</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.pressaoAlta"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col cols="12" md="4" class="d-flex align-center">
                        <v-container fluid>
                          <p>Sofre de alguma doença auto imune?</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.autoImune"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" class="d-flex align-center">
                        <v-container fluid>
                          <p>Alergias ou intolerâncias alimentares:</p>
                          <v-radio-group
                            v-model="imunidadeInfosVis.alergias"
                            column
                          >
                            <v-radio
                              label="Sim"
                              value="Sim"
                              color="red"
                            ></v-radio>
                            <v-radio
                              label="Não"
                              value="Não"
                              color="black"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>

                <v-row class="d-flex justify-center">
                  <v-btn
                    large
                    color="#614021"
                    class="my-3 mx-3 white--text"
                    @click="testeDialog = false"
                  >
                    Fechar
                  </v-btn>
                  <v-btn
                    large
                    color="#c5d13f"
                    class="my-3"
                    @click="updateTeste"
                  >
                    Concluir
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="scoreDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center text-h5">
          Teste de imunidade concluído!
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center text-h6">
            <v-col> Resultado: {{ getScoreDef(score) }} </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="8" class="d-flex align-center">
              <v-progress-linear
                :value="scorePerc"
                :color="getScoreColor(score)"
              ></v-progress-linear>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-start">
              {{ score }}
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="d-flex justify-center"> Testes anteriores</v-row>
          <v-row
            class="d-flex justify-center"
            v-for="teste in imunidadeTestesDone"
            :key="teste.id"
          >
            <v-col cols="2">
              {{ teste.dateStr }}
            </v-col>
            <v-col cols="5" class="d-flex align-center">
              <v-progress-linear
                :value="makePerc(teste.score)"
                :color="getScoreColor(teste.score)"
              ></v-progress-linear>
            </v-col>
            <v-col cols="1" class="d-flex align-center justify-start">
              {{ teste.score }}
            </v-col>
            <v-col cols="6" class="d-flex align-center justify-center">
              <strong>{{ getScoreDef(teste.score) }}</strong>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="allScoresDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center text-h5">
          Todos os testes
        </v-card-title>
        <v-card-text>
          <v-row v-for="teste in imunidadeTestesDone" :key="teste.id">
            <v-col cols="2" class="text-caption">
              {{ teste.dateStr }}
            </v-col>
            <v-col cols="4" class="d-flex align-center">
              <v-progress-linear
                :value="makePerc(teste.score)"
                :color="getScoreColor(teste.score)"
              ></v-progress-linear>
            </v-col>
            <v-col cols="1" class="d-flex align-center justify-center">
              {{ teste.score }}
            </v-col>
            <v-col cols="5" class="d-flex align-center justify-center">
              <strong>{{ getScoreDef(teste.score) }}</strong>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!-- <div v-else>
    <v-dialog v-model="showAlert2h" persistent width="600">
      <v-card>
        <v-card-title
          >Intervalo de 1h entre cadastro e atendimento</v-card-title
        >
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-icon color="red" x-large>mdi-clock-alert-outline</v-icon>
          </v-row>
          <v-row class="d-flex justify-center">
            O cadastro do paciente {{ pacienteFirestore.data.nome }} foi feito
            ás {{ getJoinDateStr(pacienteFirestore.data.joinedAt) }}, portanto o
            atendimento poderá somente ocorrer á partir dás
            {{ getAtendimentoDateStr(pacienteFirestore.data.joinedAt) }}
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="voltar">OK, voltar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div> -->
</template>

<script>
import firebase from "firebase";
import {
  mdiAccount,
  mdiWeb,
  mdiLock,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
  mdiStore,
  mdiAlert,
  mdiCalendar,
  mdiPhone,
  mdiWhatsapp,
  mdiMapMarker,
  mdiCity,
  mdiFlagVariantOutline,
  mdiPiggyBank,
  mdiHandHeart,
  mdiBabyCarriage,
  mdiHumanMaleChild,
  mdiHospitalBuilding,
  mdiAccountVoice,
  mdiWeightKilogram,
  mdiHumanMaleHeightVariant,
  mdiWeightLifter,
  mdiAccountHardHat,
} from "@mdi/js";

export default {
  data: function() {
    return {
      channels: [
        "Vi um anúncio no Facebook", // 0
        "Vi um anúncio no Instagram", // 1
        "Já seguia no Instagram", // 2
        "Pesquisei no Google", // 3
        "Recebi uma indicação", // 4
        "Já conhecia", // 5
        "Outro", // 6
      ],
      pacIndText: false,
      acceptsParceiroF: false,
      acceptsParceiroE: false,
      showAlert2h: true,
      // gotAtendimentos: 0,
      showBioimpAll: false,
      noTaxa: false,
      saved: false,
      compos: {
        taxaMetabolica: 0,
        pesoIdeal: 0,
        pesoAtual: 0,
        percGorduraAbs: 0,
        massaMagraAbs: 0,
        massaMagra_asg: false,
        aguaAbs: 0,
        agua: 0,
        agua_ags: false,
        massaMagra: 0,
        percGordura: 0,
        percGordura_asg: false,
        gorduraVisc: 0,
      },
      medidas: {
        torax: 0,
        coxa: 0,
        perna: 0,
        panturrilha: 0,
        bracoContraido: 0,
        bracoRelaxado: 0,
        cintura: 0,
        abdomen: 0,
        quadril: 0,
      },
      sangueObjs: [
        //v-for="(sangueObj, objIndex) in sangueObjs" :key="objIndex"
        { nome: "Glicemia de jejum", valor: 0, index: 0 },
        // { nome: "Glicemia de jejum", valor: 0, index: 0 },
        // { nome: "Colesterol total", valor: 0, index: 1 },
        // { nome: "HDL", valor: 0, index: 2 },
        // { nome: "LDL", valor: 0, index: 3 },
        // { nome: "Triglicerídios", valor: 0, index: 4 },
        // { nome: "TSH", valor: 0, index: 5 },
        // { nome: "TGP", valor: 0, index: 6 },
        // { nome: "TGO", valor: 0, index: 7 },
        // { nome: "Ureia", valor: 0, index: 8 },
        // { nome: "Creatina", valor: 0, index: 9 },
        // { nome: "Acido úrico", valor: 0, index: 10 },
        // { nome: "Insulina de jejum", valor: 0, index: 11 },
        // { nome: "Vitamína D", valor: 0, index: 12 },
        // { nome: "Plaquetes", valor: 0, index: 13 },
        // { nome: "Segmentados", valor: 0, index: 14 },
        // { nome: "Linfócitos", valor: 0, index: 15 },
        // { nome: "Leucócitos", valor: 0, index: 16 },
        // { nome: "Hemoglobina", valor: 0, index: 17 },
        // { nome: "Hemácias", valor: 0, index: 18 },
        // { nome: "Ferritina", valor: 0, index: 19 },
        // { nome: "Homocisteina", valor: 0, index: 20 },
        // { nome: "PCR", valor: 0, index: 21 },
      ],
      anotacoes: "",
      indRules: [
        (v) => !!v || "Campo obrigatório",
        (v) => v !== "Outro" || "Descreva brevemente, por favor!",
      ],
      mandatory: [(v) => !!v || "Campo obrigatório"],
      numbersOnly: [(v) => !/[^0-9]+/.test(v) || "Apenas números"],
      // numbersOnly: [(v) => !!v || "Apenas números"],
      dadosErradosDialog: false,
      validAnamneseImunidade: true,
      exam1: null,
      exam2: null,
      exam3: null,
      testeDateStr: "",
      testeDialog: false,
      valid: true,
      validE: true,
      validImunidade: true,
      scoreDialog: false,
      allScoresDialog: false,
      imunidadePanelOpen: [],
      imunidadePanelOpen1: [],
      imunidadePanelOpend: [],
      imunidadePanelOpen1d: [],
      estadoCivilItems: [
        "-",
        "Solteira/Solteiro",
        "Casada/Casado",
        "Viúva/Viúvo",
        "Divorciada/Divorciado",
      ],
      panel: [],
      anamneseContent: "",
      progressDialog: false,
      imunidadeInfosVis: {},
      testeVisId: "",
      // pacienteFirestore: {},
      icons: {
        mdiWeb,
        mdiLock,
        mdiEmail,
        mdiEye,
        mdiEyeOff,
        mdiStore,
        mdiAccount,
        mdiAlert,
        mdiCalendar,
        mdiPhone,
        mdiWhatsapp,
        mdiMapMarker,
        mdiCity,
        mdiFlagVariantOutline,
        mdiPiggyBank,
        mdiHospitalBuilding,
        mdiHandHeart,
        mdiBabyCarriage,
        mdiHumanMaleChild,
        mdiAccountVoice,
        mdiWeightKilogram,
        mdiHumanMaleHeightVariant,
        mdiWeightLifter,
        mdiAccountHardHat,
      },
      birthdayDays: null,
      birthdayMonths: null,
      birthdayYear: null,
    };
  },
  computed: {
    isFranquia() {
      var splitList = sessionStorage.getItem("current-user").split("@");
      return splitList[1] == "clubedanutricionista.com.br";
    },
    atendimentos() {
      return this.$store.state.firestore.pacienteAtendimentosList;
    },
    lastAtendimento() {
      var validAtendimentos = this.atendimentos.filter(function(atend) {
        return (
          atend.compos.taxaMetabolica !== undefined &&
          atend.compos.taxaMetabolica !== null &&
          atend.compos.taxaMetabolica !== 0
        );
      });
      if (validAtendimentos.length > 0) {
        return validAtendimentos[validAtendimentos.length - 1];
      } else {
        return {
          data: null,
          compos: {
            agua: null,
            aguaAbs: null,
            agua_ags: null,
            gorduraVisc: null,
            massaMagra: null,
            massaMagraAbs: null,
            massaMagra_asg: null,
            percGordura: null,
            percGorduraAbs: null,
            percGordura_asg: null,
            pesoAtual: null,
            pesoIdeal: null,
            taxaMetabolica: null,
          },
        };
      }
    },
    labels() {
      var labelList = this.atendimentos.map(function(atendimento) {
        var date = new Date(atendimento.data);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear().toString();
        var dayString;
        var monthString;
        if (day < 10) {
          dayString = "0" + day.toString();
        } else {
          dayString = day.toString();
        }
        if (month < 10) {
          monthString = "0" + month.toString();
        } else {
          monthString = month.toString();
        }
        var atendimentoDateString = dayString + "/" + monthString + "/" + year;
        return atendimentoDateString;
      });
      return labelList;
    },
    pesoRatio() {
      var ratio = this.compos.pesoAtual / this.compos.pesoIdeal;
      return ratio;
    },
    color() {
      if (this.compos.percGordura < 8) return "#d73027";
      if (this.compos.percGordura < 13) return "#fdae61";
      if (this.compos.percGordura < 18) return "#fee08b";
      if (this.compos.percGordura < 23) return "#006837";
      if (this.compos.percGordura < 28) return "#1a9850";
      if (this.compos.percGordura < 33) return "#fee08b";
      if (this.compos.percGordura < 38) return "#f46d43";
      if (this.compos.percGordura < 33) return "#d73027";
      return "#a50026";
    },
    colorVisc() {
      if (this.compos.gorduraVisc < 3) return "#006837";
      if (this.compos.gorduraVisc < 6) return "#1a9850";
      if (this.compos.gorduraVisc < 9) return "#66bd63";
      if (this.compos.gorduraVisc < 10) return "#fee08b";
      if (this.compos.gorduraVisc < 11) return "#f46d43";
      if (this.compos.gorduraVisc < 12) return "#d73027";
      return "#a50026";
    },
    colorMassa() {
      if (this.compos.massaMagra < 25) return "#a50026";
      if (this.compos.massaMagra < 30) return "#d73027";
      if (this.compos.massaMagra < 35) return "#fdae61";
      if (this.compos.massaMagra < 40) return "#1a9850";
      if (this.compos.massaMagra < 45) return "#006837";
      if (this.compos.massaMagra < 50) return "#1a9850";
      if (this.compos.massaMagra < 55) return "#fee08b";
      if (this.compos.massaMagra < 60) return "#f46d43";
      if (this.compos.massaMagra < 65) return "#d73027";
      return "#a50026";
    },
    colorPeso() {
      if (this.pesoRatio < 0.75) return "#a50026";
      if (this.pesoRatio < 0.85) return "#d73027";
      if (this.pesoRatio < 0.95) return "#fdae61";
      if (this.pesoRatio < 1.05) return "#006837";
      if (this.pesoRatio < 1.15) return "#1a9850";
      if (this.pesoRatio < 1.25) return "#fee08b";
      if (this.pesoRatio < 1.35) return "#f46d43";
      if (this.pesoRatio < 1.45) return "#d73027";
      return "#a50026";
    },
    colorAgua() {
      if (this.compos.agua < 40) return "#a50026";
      if (this.compos.agua < 45) return "#f46d43";
      if (this.compos.agua < 50) return "#fee08b";
      if (this.compos.agua < 55) return "#1a9850";
      if (this.compos.agua < 60) return "#006837";
      if (this.compos.agua < 65) return "#d9ef8b";
      if (this.compos.agua < 70) return "#fee08b";
      if (this.compos.agua < 75) return "#d73027";
      return "#a50026";
    },
    noBirthdayYet() {
      var bool;
      if (this.pacienteFirestore.dataNascimento !== null) {
        if (this.pacienteFirestore.dataNascimento !== "") {
          bool = false;
        } else {
          bool = true;
        }
      } else {
        bool = true;
      }
      return bool;
    },
    bmi() {
      var bmi =
        this.imunidadeInfos.pesoAtual /
        ((this.imunidadeInfos.altura * this.imunidadeInfos.altura) / 10000);

      return Math.round(bmi * 100) / 100;
    },
    score() {
      var scoreImundidade = 0;

      var alergias_points = this.imunidadeInfos.alergias == "Sim" ? 1 : 0;
      var autoImune_points = this.imunidadeInfos.autoImune == "Sim" ? 2 : 0;
      var pressaoAlta_points = this.imunidadeInfos.pressaoAlta == "Sim" ? 1 : 0;
      var diabetes_points = this.imunidadeInfos.diabetes == "Sim" ? 2 : 0;
      var depressao_points = this.imunidadeInfos.depressao == "Sim" ? 1 : 0;
      var criseAnsiedade_points =
        this.imunidadeInfos.criseAnsiedade == "Sim" ? 1 : 0;
      var nivelEstresse_points =
        this.imunidadeInfos.nivelEstresse == "Sim" ? 2 : 0;
      var acorda_points = this.imunidadeInfos.acorda == "Sim" ? 1 : 0;
      var insonia_points = this.imunidadeInfos.insonia == "Sim" ? 2 : 0;
      var unhas_points = this.imunidadeInfos.unhas == "Sim" ? 1 : 0;
      var cabelo_points = this.imunidadeInfos.cabelo == "Sim" ? 1 : 0;
      var manchas_points = this.imunidadeInfos.manchas == "Sim" ? 1 : 0;
      var candidiase_points = this.imunidadeInfos.candidiase == "Sim" ? 2 : 0;
      var constipado_points = this.imunidadeInfos.constipado == "Sim" ? 1 : 0;
      var diarreia_points = this.imunidadeInfos.diarreia == "Sim" ? 2 : 0;
      var nauseas_points = this.imunidadeInfos.nauseas == "Sim" ? 1 : 0;
      var cansaco_points = this.imunidadeInfos.cansaco == "Sim" ? 1 : 0;
      var olhos_points = this.imunidadeInfos.olhos == "Sim" ? 1 : 0;
      var febresCalafrios_points =
        this.imunidadeInfos.febresCalafrios == "Sim" ? 2 : 0;
      var doencasDemoram_points =
        this.imunidadeInfos.doencasDemoram == "Sim" ? 1 : 0;
      var amigdalite_points = this.imunidadeInfos.amigdalite == "Sim" ? 1 : 0;
      var infeccaoUrinaria_points =
        this.imunidadeInfos.infeccaoUrinaria == "Sim" ? 2 : 0;
      var aftas_points = this.imunidadeInfos.aftas == "Sim" ? 1 : 0;
      var viroses_points = this.imunidadeInfos.viroses == "Sim" ? 1 : 0;
      var gripesFrequentes_points =
        this.imunidadeInfos.gripesFrequentes == "Sim" ? 2 : 0;
      var bmi =
        this.imunidadeInfos.pesoAtual /
        ((this.imunidadeInfos.altura * this.imunidadeInfos.altura) / 10000);
      // ate 24.9 = 0, ate 29.9 = 1, +30 = 2, -18 = 1,
      var bmi_points;
      if (bmi < 18) {
        bmi_points = 1;
      } else if (bmi < 24.9) {
        bmi_points = 0;
      } else if (bmi < 29.9) {
        bmi_points = 1;
      } else if (bmi > 29.9) {
        bmi_points = 2;
      }

      var scoreList = [
        alergias_points,
        autoImune_points,
        pressaoAlta_points,
        diabetes_points,
        depressao_points,
        criseAnsiedade_points,
        nivelEstresse_points,
        acorda_points,
        insonia_points,
        unhas_points,
        cabelo_points,
        manchas_points,
        candidiase_points,
        constipado_points,
        diarreia_points,
        nauseas_points,
        cansaco_points,
        olhos_points,
        febresCalafrios_points,
        doencasDemoram_points,
        amigdalite_points,
        infeccaoUrinaria_points,
        aftas_points,
        viroses_points,
        gripesFrequentes_points,
        bmi_points,
      ];

      scoreList.forEach(function(item) {
        scoreImundidade += parseFloat(item);
      });

      return scoreImundidade;
    },
    scorePerc() {
      var maxPoints = 36;
      var scorePerc = Math.round((this.score / maxPoints) * 100);

      return scorePerc;
    },
    pacientePreAtendimento: function() {
      return this.$store.state.firestore.pacientePreAtendimento;
    },
    pacienteFirestore: function() {
      console.log(this.$store.state.firestore.pacientePreAtendimentoData);
      return this.$store.state.firestore.pacientePreAtendimentoData;
    },
    pacienteInfos: function() {
      return this.$store.state.firestore.pacienteInfos;
    },
    imunidadeInfos() {
      return this.$store.state.firestore.imunidadeInfos;
    },
    imunidadeTestesDone() {
      return this.$store.state.firestore.imunidadeTestesDone;
    },
    imunidadeTestesDoneLength() {
      return this.imunidadeTestesDone.length > 0;
    },
    nowDateStr() {
      var d = new Date();
      var day = d.getDate();
      var month = d.getMonth() + 1;
      var year = d.getFullYear();

      var dayStr;
      if (day < 10) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var monthStr;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }

      var dateStr = dayStr + "/" + monthStr + "/" + year.toString();

      return dateStr;
    },
    anamnesesNr: function() {
      return this.$store.state.firestore.anamnesesNr;
    },
    allAnamneseNotes: function() {
      return this.$store.state.firestore.allAnamneseNotes;
    },
    atendimentoPadrao() {
      var cond1 = this.pacienteFirestore.data.preAtendimentoToFill;
      var cond2 = this.pacienteInfos.pesoAtual == "";

      if (cond1 && cond2) {
        // still need filling out
        return true;
      } else if (cond1 && !cond2) {
        // has been filled out partially
        return true;
      } else if (!cond1 && !cond2) {
        // has been filled out completely
        return true;
      } else if (!cond1 && cond2) {
        // must not be filled out
        return false;
      } else {
        return true;
      }
    },
    apenasEstet() {
      var cond1 = this.pacienteFirestore.data.estetToFill;
      var cond2 = this.pacienteInfos.estet1 == null;

      if (cond1 && cond2) {
        // still need filling out
        return true;
      } else if (cond1 && !cond2) {
        // has been filled out partially
        return true;
      } else if (!cond1 && !cond2) {
        // has been filled out completely
        return true;
      } else if (!cond1 && cond2) {
        // must not be filled out
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    checkFormE() {
      if (this.$refs.formE.validate()) {
        var estetResps = [
          this.pacienteInfos.estet1,
          this.pacienteInfos.estet2,
          this.pacienteInfos.estet3,
          this.pacienteInfos.estet4,
          this.pacienteInfos.estet5,
          this.pacienteInfos.estet6,
          this.pacienteInfos.estet7,
          this.pacienteInfos.estet8,
          this.pacienteInfos.estet9,
          this.pacienteInfos.estet10,
        ];

        var estetCondNok = estetResps.includes("Não");

        if (estetCondNok) {
          alert("Paciente tem contra-indicações para tratamentos estéticos!");
        }
        this.submit();
      }
    },
    pacIndTextCheck() {
      if (
        this.pacienteInfos.indicacao == undefined ||
        this.pacienteInfos.indicacao == null ||
        this.pacienteInfos.indicacao == "" ||
        this.channels.slice(0, 6).includes(this.pacienteInfos.indicacao)
      ) {
        this.pacIndText = false;
      } else {
        this.pacIndText = true;
      }
    },
    labelAcceptE(bool) {
      if (bool) {
        return "Exames: Contato de parceiros (laboratórios) autorizado!";
      } else {
        return "Exames: Contato de parceiros (laboratórios) não autorizado!";
      }
    },
    labelAcceptF(bool) {
      if (bool) {
        return "Fórmulas: Contato de parceiros (farmácias de manipulação) autorizado!";
      } else {
        return "Fórmulas: Contato de parceiros (farmácias de manipulação) não autorizado!";
      }
    },
    twoZeros(nr) {
      var t100 = nr * 100;
      var rounded = Math.round(t100);
      return rounded / 100;
    },
    getDataStr(millis) {
      var date = new Date(millis);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear().toString();
      var dayString;
      var monthString;
      if (day < 10) {
        dayString = "0" + day.toString();
      } else {
        dayString = day.toString();
      }
      if (month < 10) {
        monthString = "0" + month.toString();
      } else {
        monthString = month.toString();
      }
      return dayString + "/" + monthString + "/" + year;
    },
    changePercGordura(percGordura, asg) {
      this.compos.percGorduraAbs = (this.compos.pesoAtual / 100) * percGordura;
      this.percGordura_asg = asg;
    },
    changeAbsGordura(percGorduraAbs, asg) {
      this.compos.percGordura = (percGorduraAbs / this.compos.pesoAtual) * 100;
      this.percGordura_asg = asg;
    },
    changeMassaMagra(massaMagra, asg) {
      this.compos.massaMagraAbs = (this.compos.pesoAtual / 100) * massaMagra;
      this.massaMagra_asg = asg;
    },
    changeMassaMagraAbs(massaMagraAbs, asg) {
      this.compos.massaMagra = (massaMagraAbs / this.compos.pesoAtual) * 100;
      this.massaMagra_asg = asg;
    },
    changeAgua(agua, asg) {
      this.compos.aguaAbs = (this.compos.pesoAtual / 100) * agua;
      this.agua_asg = asg;
    },
    changeAguaAbs(aguaAbs, asg) {
      this.compos.agua = (aguaAbs / this.compos.pesoAtual) * 100;
      this.agua_asg = asg;
    },
    changePesoAtual(pesoAtual) {
      if (this.percGordura_asg) {
        this.compos.percGorduraAbs =
          (pesoAtual / 100) * this.compos.percGordura;
      } else {
        this.compos.percGordura =
          (this.compos.percGorduraAbs / pesoAtual) * 100;
      }

      if (this.massaMagra_asg) {
        this.compos.massaMagraAbs = (pesoAtual / 100) * this.compos.massaMagra;
      } else {
        this.compos.massaMagra = (this.compos.massaMagraAbs / pesoAtual) * 100;
      }

      if (this.agua_asg) {
        this.compos.aguaAbs = (pesoAtual / 100) * this.compos.agua;
      } else {
        this.compos.agua = (this.compos.aguaAbs / pesoAtual) * 100;
      }
    },
    visBioimpAll() {
      // if (this.gotAtendimentos !== 1) {
      //   this.$store.dispatch("firestore/pacienteAtendimentosListN", {
      //     pacienteEmail: this.pacientePreAtendimento,
      //   });
      // }
      this.showBioimpAll = true;
      // this.gotAtendimentos = 1;
    },
    voltar() {
      this.$router.replace("MeusPacientes");
    },
    readyToAtend(timestamp) {
      var d = new Date(timestamp.seconds * 1000);
      var dNow = new Date();
      var dMillis = dNow.getTime();
      var atendMillis = 1 * 60 * 60 * 1000 + d.getTime();
      return dMillis > atendMillis;
    },
    getJoinDateStr(timestamp) {
      var d = new Date(timestamp.seconds * 1000);
      var day = d.getDate();
      var dayStr;
      if (day <= 9) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var mes = d.getMonth() + 1;
      var mesStr;
      if (mes <= 9) {
        mesStr = "0" + mes.toString();
      } else {
        mesStr = mes.toString();
      }
      var ano = d.getFullYear();
      var hours = d.getHours() - 4;
      var hoursStr;
      if (hours <= 9) {
        hoursStr = "0" + hours.toString();
      } else {
        hoursStr = hours.toString();
      }
      var min = d.getMinutes();
      var minStr;
      if (hours <= 9) {
        minStr = "0" + min.toString();
      } else {
        minStr = min.toString();
      }
      var dateStr =
        dayStr +
        "/" +
        mesStr +
        "/" +
        ano.toString() +
        " " +
        hoursStr +
        ":" +
        minStr;

      return dateStr;
    },
    getAtendimentoDateStr(timestamp) {
      var d = new Date(timestamp.seconds * 1000);
      var day = d.getDate();
      var dayStr;
      if (day <= 9) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var mes = d.getMonth() + 1;
      var mesStr;
      if (mes <= 9) {
        mesStr = "0" + mes.toString();
      } else {
        mesStr = mes.toString();
      }
      var ano = d.getFullYear();
      var hours = d.getHours() - 2;
      var hoursStr;
      if (hours <= 9) {
        hoursStr = "0" + hours.toString();
      } else {
        hoursStr = hours.toString();
      }
      var min = d.getMinutes();
      var minStr;
      if (hours <= 9) {
        minStr = "0" + min.toString();
      } else {
        minStr = min.toString();
      }
      var dateStr =
        dayStr +
        "/" +
        mesStr +
        "/" +
        ano.toString() +
        " " +
        hoursStr +
        ":" +
        minStr;

      return dateStr;
    },
    calcBmi(kg, m) {
      return Math.round((kg / (m * m)) * 1000000) / 100;
    },
    handleExam1(file) {
      this.exam1 = file;
    },
    handleExam2(file) {
      this.exam2 = file;
    },
    handleExam3(file) {
      this.exam3 = file;
    },
    openTeste(teste) {
      this.testeDateStr = teste.dateStr;
      // this.$store.dispatch("firestore/setImunidadeInfosVis", {
      //   pacienteEmail: this.pacientePreAtendimento,
      //   id: id,
      // });
      this.imunidadeInfosVis = teste.imunidadeInfos;
      this.testeVisId = teste.id;
      this.testeDialog = true;
    },
    setBirthday() {
      if (
        this.birthdayYear !== null &&
        this.birthdayMonths !== null &&
        this.birthdayDays !== null
      ) {
        var datestring =
          this.birthdayDays.toString() +
          this.birthdayMonths.toString() +
          this.birthdayYear.toString();
        this.pacienteFirestore.dataNascimento = datestring;
        firebase
          .firestore()
          .collection("users")
          .doc(this.pacientePreAtendimento)
          .update({
            dataNascimento: datestring,
          });
      }
    },
    getAge(birthdayStr) {
      if (birthdayStr !== null) {
        if (birthdayStr.substring(4, 5) !== "-") {
          // console.log(birthdayStr);
          var dayStr = birthdayStr.substring(0, 2);
          var monthStr = birthdayStr.substring(2, 4);
          var yearStr = birthdayStr.substring(4, 8);
          var dateStr = yearStr + "-" + monthStr + "-" + dayStr;
          var ageDateX = new Date(dateStr + " 00:00:00");
          var nowDateX = new Date();
          var yearThenX = ageDateX.getFullYear();
          var yearNowX = nowDateX.getFullYear();
          var ageX = Math.abs(yearThenX - yearNowX);
          return ageX.toString() + " anos";
        } else {
          var ageDate = new Date(birthdayStr + " 00:00:00");
          var nowDate = new Date();
          var yearThen = ageDate.getFullYear();
          var yearNow = nowDate.getFullYear();
          var age = Math.abs(yearThen - yearNow);
          return age.toString() + " anos";
        }
      } else {
        return "";
      }
    },
    async submitImunidade() {
      var date = new Date();

      var monthStr = date.getMonth() + 1;
      var simpleDateStr =
        date.getFullYear().toString() +
        "-" +
        monthStr.toString() +
        "-" +
        date.getDate().toString();

      if (this.pacienteFirestore.data.imunidadeToFill) {
        if (
          // this.$refs.formImunidade.validate() &&
          this.$refs.formAnamneseImunidade.validate()
        ) {
          var infos = {};
          if (this.exam1 !== null) {
            console.log("Enviando exame 1!");
            await firebase
              .storage()
              .ref("examsImunidade")
              .child(
                this.pacientePreAtendimento + "_" + simpleDateStr + "_exam1"
              )
              .put(this.exam1)
              .then(async (snapshot) => {
                infos.exam1 = await snapshot.ref.getDownloadURL();
                console.log(infos.exam1);
              });
          } else {
            infos.exam1 = null;
          }

          if (this.exam2 !== null) {
            console.log("Enviando exame 1!");
            await firebase
              .storage()
              .ref("examsImunidade")
              .child(
                this.pacientePreAtendimento + "_" + simpleDateStr + "_exam2"
              )
              .put(this.exam2)
              .then(async (snapshot) => {
                infos.exam2 = await snapshot.ref.getDownloadURL();
                console.log(infos.exam2);
              });
          } else {
            infos.exam2 = null;
          }

          if (this.exam3 !== null) {
            console.log("Enviando exame 3!");
            await firebase
              .storage()
              .ref("examsImunidade")
              .child(
                this.pacientePreAtendimento + "_" + simpleDateStr + "_exam3"
              )
              .put(this.exam3)
              .then(async (snapshot) => {
                infos.exam3 = await snapshot.ref.getDownloadURL();
                console.log(infos.exam3);
              });
          } else {
            infos.exam3 = null;
          }

          this.imunidadeInfos.exam1 = infos.exam1 == null ? "" : infos.exam1;
          this.imunidadeInfos.exam2 = infos.exam2 == null ? "" : infos.exam2;
          this.imunidadeInfos.exam3 = infos.exam3 == null ? "" : infos.exam3;

          this.$store.dispatch("firestore/nutriImunidadeSubmit", {
            pacienteEmail: this.pacientePreAtendimento,
            score: this.score,
            imunidadeInfos: this.imunidadeInfos,
          });

          this.$store.dispatch("firestore/nutriGetImunidadeTestesDone", {
            pacienteEmail: this.pacientePreAtendimento,
          });
          this.scoreDialog = true;
          this.imunidadePanelOpen = [];
          this.imunidadePanelOpen1 = [];
        }
      } else {
        this.dadosErradosDialog = true;
        this.scoreDialog = false;
      }
    },
    async updateTeste() {
      var date = new Date();

      var monthStr = date.getMonth() + 1;
      var simpleDateStr =
        date.getFullYear().toString() +
        "-" +
        monthStr.toString() +
        "-" +
        date.getDate().toString();

      this.saved = false;
      this.progressDialog = true;
      var infos = {};

      if (
        this.$refs.imunidadeVis.validate() &&
        this.$refs.anamneseImunidadeVis.validate()
      ) {
        if (this.exam1 !== null) {
          console.log("Enviando exame 1!");
          await firebase
            .storage()
            .ref("examsImunidade")
            .child(this.pacientePreAtendimento + "_" + simpleDateStr + "_exam1")
            .put(this.exam1)
            .then(async (snapshot) => {
              infos.exam1 = await snapshot.ref.getDownloadURL();
              console.log(infos.exam1);
            });
        } else {
          infos.exam1 = null;
        }

        if (this.exam2 !== null) {
          console.log("Enviando exame 1!");
          await firebase
            .storage()
            .ref("examsImunidade")
            .child(this.pacientePreAtendimento + "_" + simpleDateStr + "_exam2")
            .put(this.exam2)
            .then(async (snapshot) => {
              infos.exam2 = await snapshot.ref.getDownloadURL();
              console.log(infos.exam2);
            });
        } else {
          infos.exam2 = null;
        }

        if (this.exam3 !== null) {
          console.log("Enviando exame 3!");
          await firebase
            .storage()
            .ref("examsImunidade")
            .child(this.pacientePreAtendimento + "_" + simpleDateStr + "_exam3")
            .put(this.exam3)
            .then(async (snapshot) => {
              infos.exam3 = await snapshot.ref.getDownloadURL();
              console.log(infos.exam3);
            });
        } else {
          infos.exam3 = null;
        }

        if (this.imunidadeInfosVis.exam1 == "" && infos.exam1 !== null) {
          this.imunidadeInfosVis.exam1 = infos.exam1;
        }

        if (this.imunidadeInfosVis.exam2 == "" && infos.exam2 !== null) {
          this.imunidadeInfosVis.exam2 = infos.exam2;
        }

        if (this.imunidadeInfosVis.exam3 == "" && infos.exam3 !== null) {
          this.imunidadeInfosVis.exam3 = infos.exam3;
        }

        this.$store.dispatch("firestore/nutriImunidadeUpdate", {
          id: this.testeVisId,
          pacienteEmail: this.pacientePreAtendimento,
          imunidadeInfos: this.imunidadeInfosVis,
          score: this.score,
        });

        this.$store.dispatch("firestore/nutriGetImunidadeTestesDone", {
          pacienteEmail: this.pacientePreAtendimento,
        });
        this.testeDialog = false;
        this.saved = true;
        this.scoreDialog = true;
        this.imunidadePanelOpend = [];
        this.imunidadePanelOpen1d = [];
      } else {
        this.dadosErradosDialog = true;
        this.scoreDialog = false;
        this.saved = false;
        this.progressDialog = false;
      }
    },
    async submit() {
      this.progressDialog = true;

      if (
        this.compos.taxaMetabolica !== 0 &&
        this.compos.taxaMetabolica !== null &&
        this.compos.taxaMetabolica !== undefined
      ) {
        this.noTaxa = false;
        var date = new Date();
        var milliseconds = date.getTime();
        var day;
        var month;
        var mes = date.getMonth() + 1;
        var dia = date.getDate();
        if (dia < 10) {
          day = "0" + dia.toString();
        } else {
          day = dia.toString();
        }
        if (mes < 10) {
          month = "0" + mes.toString();
        } else {
          month = mes.toString();
        }
        // var data = `${day}/${month}/${date.getFullYear().toString()}`
        var data = day + "/" + month + "/" + date.getFullYear().toString();

        if (this.isFranquia) {
          await firebase
            .firestore()
            .collection("users")
            .doc(this.pacientePreAtendimento)
            .update({
              acceptsParceiroF: this.acceptsParceiroF,
              acceptsParceiroE: this.acceptsParceiroE,
            });
        }

        await firebase
          .firestore()
          .collection("users")
          // .doc(sessionStorage.getItem("current-user"))
          // .collection("pacientes")
          .doc(this.pacientePreAtendimento)
          .collection("atendimentos")
          .doc(milliseconds.toString())
          .set({
            data: milliseconds,
            compos: this.compos,
            medidas: this.medidas,
            fotoFrenteUrl: "",
            fotoAtrasUrl: "",
            fotoEsquerdaUrl: "",
            fotoDireitaUrl: "",
            bioimp: "",
            exam1: "",
            exam2: "",
            exam3: "",
            exam1Nome: null,
            exam2Nome: null,
            exam3Nome: null,
            sangue: this.sangueObjs,
            anotacoes: null,
          });

        await firebase
          .firestore()
          .collection("users")
          // .doc(sessionStorage.getItem("current-user"))
          // .collection("pacientes")
          .doc(this.pacientePreAtendimento)
          .collection("timeline")
          .doc(milliseconds.toString())
          .set({
            docId: milliseconds.toString(),
            colId: "atendimentos",
            nome: "avaliação",
            type: "atendimento",
            icon: "mdi-finance",
            date: data,
            to: "/PacienteEvolucao",
            text: "Uma nova avaliação foi feita!",
            toText: "Todas as avaliações",
            color: "#614021",
            download: false,
          });

        this.$store.dispatch(
          "firestore/pacienteAtendimento",
          this.pacientePreAtendimento
        );

        this.$store.dispatch("firestore/persistPaciente", {
          pId: this.pacientePreAtendimento,
        });

        this.$store.dispatch("firestore/nutriPreAtendimentoSubmit", {
          pacienteInfos: this.pacienteInfos,
          pacienteEmail: this.pacientePreAtendimento,
        });

        if (this.anamneseContent !== "") {
          var d = new Date();
          this.$store.dispatch("firestore/nutriAnamneseSubmit", {
            pacienteEmail: this.pacientePreAtendimento,
            content: this.anamneseContent,
            index: this.anamnesesNr + 1,
            dateStr: this.nowDateStr,
            millis: d.getTime(),
          });
        }

        this.saved = true;

        this.$router.push("MeusPacientes");
      } else {
        this.noTaxa = true;
      }
    },
    getScoreColor(score) {
      var color;
      if (score <= 2) {
        color = "#1cbd00";
      } else if (score <= 5) {
        color = "#fcff00";
      } else if (score <= 10) {
        color = "#ff8a00";
      } else if (score <= 18) {
        color = "#ff1100";
      } else if (score > 18) {
        color = "#ad0000";
      }
      return color;
    },
    getScoreDef(score) {
      var def;
      if (score <= 2) {
        def = "Superimunidade";
      } else if (score <= 5) {
        def = "Boa imunidade";
      } else if (score <= 10) {
        def = "Reforçar imunidade";
      } else if (score <= 18) {
        def = "Baixa imunidade";
      } else if (score > 18) {
        def = "Imunidade crítica";
      }
      return def;
    },
    makePerc(score) {
      return Math.round((score / 36) * 100);
    },
  },
  // firestore: function () {
  //   return {
  //     pacienteFirestore: firebase
  //       .firestore()
  //       .collection("users")
  //       .doc(sessionStorage.getItem("current-user"))
  //       .collection("pacientes")
  //       .doc(this.pacientePreAtendimento),
  //   };
  // },
  mounted() {
    console.log(this.pacientePreAtendimento);
    this.$store.dispatch("firestore/pacienteAtendimentosListN", {
      pacienteEmail: this.pacientePreAtendimento,
    });
    this.$store.dispatch("firestore/nutriGetPacienteInfos", {
      pacienteEmail: this.pacientePreAtendimento,
    });
    this.$store.dispatch("firestore/nutriGetImunidadeInfos", {
      pacienteEmail: this.pacientePreAtendimento,
    });
    this.$store.dispatch("firestore/nutriGetAnamnesesNr", {
      pacienteEmail: this.pacientePreAtendimento,
    });
    this.$store.dispatch("firestore/getAllAnamneseNotes", {
      pacienteEmail: this.pacientePreAtendimento,
    });
    this.$store.dispatch("firestore/nutriGetImunidadeTestesDone", {
      pacienteEmail: this.pacientePreAtendimento,
    });
    console.log(this.lastAtendimento);

    setTimeout(() => {
      if (this.pacienteFirestore.data.acceptsParceiroF !== undefined) {
        this.acceptsParceiroF = this.pacienteFirestore.data.acceptsParceiroF;
      }
      if (this.pacienteFirestore.data.acceptsParceiroE !== undefined) {
        this.acceptsParceiroE = this.pacienteFirestore.data.acceptsParceiroE;
      }
    }, 2000);
  },
};
</script>

<style></style>
