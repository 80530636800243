var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.alertNovaMensagem),callback:function ($$v) {_vm.alertNovaMensagem=$$v},expression:"alertNovaMensagem"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("Uma mensagem nova!")]),_c('v-card-subtitle',{staticClass:"d-flex justify-center"},[_vm._v("Um(a) paciente mandou uma nova mensagem")]),_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"large":"","color":"red"}},[_vm._v("mdi-email-alert-outline")])],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"color":"#D6DBDF"},on:{"click":function($event){_vm.alertNovaMensagem = false}}},[_vm._v("Fechar")]),_c('v-btn',{attrs:{"to":"/Chat","color":"#c5d13f"},on:{"click":function($event){_vm.alertNovaMensagem = false}}},[_vm._v("Visualizar")])],1)],1)],1),(_vm.userInfos !== null && _vm.isFranquia)?_c('v-navigation-drawer',{staticClass:"grey lighten-5",attrs:{"expand-on-hover":_vm.expandOnHover,"mini-variant":_vm.miniVariant,"right":_vm.right,"permanent":_vm.permanent},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(
        _vm.funcionarioOnline.papel === 'Proprietária' ||
        _vm.funcionarioOnline.papel === 'Administradora' ||
        _vm.funcionarioOnline.papel === 'Nutricionista 1'
      )?_c('v-list-item',{class:_vm.miniVariant && 'px-0',attrs:{"two-line":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"to":"/personalData"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icons.mdiAccountDetailsOutline))])],1)]}}],null,false,1482519600)},[_c('span',[_vm._v("Editar dados da clínica / Dados pessoais")])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.userInfos.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.userInfos.addressCity))])],1)],1):_vm._e(),_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":""}},[_c('v-divider'),(
          _vm.funcionarioOnline.papel === 'Proprietária' ||
          _vm.funcionarioOnline.papel === 'Administradora'
        )?_c('div',_vm._l((_vm.itemsAdmin),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Nutricionista 1')?_c('div',_vm._l((_vm.itemsNutricionista1),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Nutricionista 2')?_c('div',_vm._l((_vm.itemsNutricionista2),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Nutricionista 3')?_c('div',_vm._l((_vm.itemsNutricionista2),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Recepcionista')?_c('div',_vm._l((_vm.itemsRecepcionista),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Esteticista')?_c('div',_vm._l((_vm.itemsEsteticista),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Estagiária')?_c('div',_vm._l((_vm.itemsEstagiaria),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e()],1)],1):(_vm.userInfos !== null && !_vm.isFranquia)?_c('v-navigation-drawer',{staticClass:"grey lighten-5",attrs:{"expand-on-hover":_vm.expandOnHover,"mini-variant":_vm.miniVariant,"right":_vm.right,"permanent":_vm.permanent},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(
        _vm.funcionarioOnline.papel === 'Proprietária' ||
        _vm.funcionarioOnline.papel === 'Administradora' ||
        _vm.funcionarioOnline.papel === 'Nutricionista 1'
      )?_c('v-list-item',{class:_vm.miniVariant && 'px-0',attrs:{"two-line":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"to":"/personalData"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icons.mdiAccountDetailsOutline))])],1)]}}],null,false,1482519600)},[_c('span',[_vm._v("Editar dados da clínica / Dados pessoais")])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.userInfos.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.userInfos.addressCity))])],1)],1):_vm._e(),_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":""}},[_c('v-divider'),(
          _vm.funcionarioOnline.papel === 'Proprietária' ||
          _vm.funcionarioOnline.papel === 'Administradora'
        )?_c('div',_vm._l((_vm.itemsAdmin_nF),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Nutricionista 1')?_c('div',_vm._l((_vm.itemsNutricionista1_nF),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Nutricionista 2')?_c('div',_vm._l((_vm.itemsNutricionista2_nF),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Nutricionista 3')?_c('div',_vm._l((_vm.itemsNutricionista2_nF),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Recepcionista')?_c('div',_vm._l((_vm.itemsRecepcionista_nF),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Esteticista')?_c('div',_vm._l((_vm.itemsEsteticista_nF),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e(),(_vm.funcionarioOnline.papel === 'Estagiária')?_c('div',_vm._l((_vm.itemsEstagiaria_nF),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-list-item-icon',[(_vm.chatUnansweredP(item.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-banner',{attrs:{"color":"grey lighten-3","single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"slot":"icon","color":"#c5d13f","size":"35"},slot:"icon"},[_c('v-icon',{attrs:{"size":"80%"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('v-divider'),_vm._l((item.subitems),function(subitem,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: subitem.route },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-icon',[(_vm.chatUnansweredP(subitem.title))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiChatAlert))]):_c('v-icon',[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)]}}],null,true)})],1)})],2)],1)],1)}),1):_vm._e()],1)],1):(_vm.userInfos == undefined || _vm.userInfos == null)?_c('v-navigation-drawer',{staticClass:"grey lighten-5",attrs:{"expand-on-hover":_vm.expandOnHover,"mini-variant":_vm.miniVariant,"right":_vm.right,"permanent":_vm.permanent},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }