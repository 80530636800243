<template>
  <v-container>
    <div class="d-flex flex-column">
      <v-card
        class="pa-5 my-1"
        tile
        style="background-color: rgba(197, 209, 63, 0.6)"
      >
        <!-- <v-row class="mx-4">
          <v-text-field
            v-model="orientacaoP.nome"
            solo
            label="Título"
            @change="onUpdate"
            clearable
          ></v-text-field>
        </v-row> -->
        <v-row class="mx-4">
          <v-autocomplete
            label="Orientações salvos"
            no-data-text="..."
            :items="orientacaoList"
            v-model="orientacaoItemId"
            style="width: 100%; max-width: 250px"
            @change="onOrientacaoSelected(orientacaoItemId)"
          ></v-autocomplete>
        </v-row>
        <v-row class="mx-4">
          <v-textarea
            v-model="orientacaoP.orientacaoText"
            solo
            label="Orientações"
            height="250"
            @change="onUpdate"
            clearable
          ></v-textarea>
        </v-row>
        <v-row class="mx-4">
          <v-textarea
            v-model="orientacaoP.observacoes"
            solo
            label="Observações"
            height="100"
            @change="onUpdate"
            clearable
          ></v-textarea>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mdiClose, mdiPlus, mdiAutorenew } from "@mdi/js";

export default {
  data: function () {
    return {
      icons: { mdiClose, mdiPlus, mdiAutorenew },
      orientacaoP: {
        nome: "",
        orientacaoText: "",
        observacoes: "",
      },
      orientacaoItemId: null,
    };
  },
  computed: {
    orientacaoList() {
      return this.$store.state.firestore.orientacaoList;
    },
  },
  methods: {
    onUpdate() {
      console.log(this.orientacaoP);
      this.$store.dispatch("firestore/orientacaoPUpdate", {
        orientacaoP: this.orientacaoP,
      });
    },
    onOrientacaoSelected(orientacaoItemId) {
      var orientacaoSelecionada = this.orientacaoList.find((item) => item.value == orientacaoItemId);
      this.orientacaoP = orientacaoSelecionada.data;
      this.onUpdate();
    },
  },
  mounted() {
    this.$store.dispatch("firestore/getOrientacaoList");
  },
};
</script>