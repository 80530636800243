<template>
  <div>
    <!-- <v-dialog v-model="visualizarProspectosDialog" max-width="800">
      <v-card>
        <v-card-title>Prospectos (filtrados)</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nome</th>
                  <th class="text-left">Horário da ligação</th>
                  <th class="text-left">Motivo pela recusão</th>
                  <th class="text-left">Contato</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in filteredProspectos" :key="p.millis">
                  <td>{{ p.nome }}</td>
                  <td>
                    {{ p.dia }}/{{ p.mes }}/{{ p.ano }}, {{ p.horarioLigacao }}
                  </td>
                  <td>{{ p.motivoRecusao }}</td>
                  <td>
                    <v-icon
                      x-small
                      v-if="
                        p.telefone !== undefined &&
                        p.telefone !== null &&
                        p.telefone !== ''
                      "
                      >mdi-phone</v-icon
                    >
                    {{ p.telefone }} <br/>
                    <v-icon
                      x-small
                      class="ml-1"
                      v-if="
                        p.whatsapp !== undefined &&
                        p.whatsapp !== null &&
                        p.whatsapp !== ''
                      "
                      >mdi-whatsapp</v-icon
                    >
                    {{ p.whatsapp }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#D6DBDF" @click="visualizarProspectosDialog = false"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="filterProspectosDialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Filtrar lista de prospectos
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center">
          filtrado: {{ nrFiltrados }}, de {{ nrProspectos }}
        </v-card-subtitle>
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-autocomplete
              v-model="sexosChosen"
              multiple
              outlined
              :items="sexos"
              label="Sexo"
              class="mx-1"
              @change="filterProspectos"
            ></v-autocomplete>
            <v-autocomplete
              v-model="objetivosChosen"
              outlined
              :items="objetivos"
              label="Objetivo"
              class="mx-1"
              multiple
              @change="filterProspectos"
            ></v-autocomplete>
            <v-autocomplete
              v-model="motivosChosen"
              outlined
              :items="motivos"
              multiple
              label="Motivo pela recusão"
              class="mx-1"
              @change="filterProspectos"
            ></v-autocomplete>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-autocomplete
              v-model="meiosChosen"
              outlined
              :items="meios"
              label="Meio de contato"
              class="mx-1"
              multiple
              @change="filterProspectos"
            ></v-autocomplete>
            <v-menu
              ref="menuStart"
              v-model="menuStart"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="200px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startChosen"
                  @change="filterProspectos"
                  label="Data da chamada (à partir de...)"
                  :prepend-icon="icons.mdiCalendar"
                  :rules="rules.dataNasc"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                locale="pt-br"
                v-model="startChosen"
                :max="new Date().toISOString().substring(0, 10)"
                min="1920-01-01"
                @change="saveStart"
                color="#614021"
              ></v-date-picker>
            </v-menu>

            <v-menu
              ref="menuEnd"
              v-model="menuEnd"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="200px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endChosen"
                  @change="filterProspectos"
                  label="Data da chamada (até...)"
                  :prepend-icon="icons.mdiCalendar"
                  :rules="rules.dataNasc"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                locale="pt-br"
                v-model="endChosen"
                :max="new Date().toISOString().substring(0, 10)"
                min="1920-01-01"
                @change="saveEnd"
                color="#614021"
              ></v-date-picker>
            </v-menu>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="#614021"
            class="white--text"
            @click="downloadProspectos"
          >
            <v-icon left color="#c5d13f" @click="downloadProspectos"
              >mdi-download</v-icon
            >
            baixar lista ({{ nrFiltrados }}/{{ nrProspectos }})</v-btn
          >
          <v-btn color="#c5d13f" @click="visualizarProspectosDialog = true">
            <v-icon left color="#614021">mdi-eye</v-icon>
            Visualizar lista</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="paciencePlease" width="600">
      <v-card>
        <v-card-title class="d-flex justify-center">Aguarde...</v-card-title>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMarketStats" width="80%">
      <v-card>
        <v-card-title class="d-flex justify-center">Estatísticas</v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center my-2">
            <v-chip
              class="mx-1"
              v-for="item in chunk"
              :key="item"
              @click="showMarketStatsYear(item)"
              :color="clickedShowMarketStatsYear(item)"
              >{{ item }}</v-chip
            >
          </v-row>
          <v-row>
            <v-card outlined color="#c5d13f" width="100%">
              <v-row class="d-flex align-center">
                <v-col cols="5">Mes/Ano</v-col>
                <v-col cols="1">
                  <v-row class="d-flex justify-center">
                    <v-tooltip bottom color="#614021" class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#614021" v-bind="attrs" v-on="on"
                          >mdi-facebook</v-icon
                        >
                      </template>
                      <span>Vi um anúncio no Facebook</span>
                    </v-tooltip>
                  </v-row></v-col
                >
                <v-col cols="1">
                  <v-row class="d-flex justify-center align-center">
                    <v-tooltip bottom color="#614021" class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#614021" v-bind="attrs" v-on="on"
                          >mdi-instagram</v-icon
                        >
                      </template>
                      <span>Vi um anúncio no Instagram</span>
                    </v-tooltip>
                    <v-icon x-small color="#614021">mdi-cellphone-text</v-icon>
                  </v-row></v-col
                >
                <v-col cols="1">
                  <v-row class="d-flex justify-center align-center">
                    <v-tooltip bottom color="#614021" class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#614021" v-bind="attrs" v-on="on"
                          >mdi-instagram</v-icon
                        >
                      </template>
                      <span>Já seguia no Instagram</span>
                    </v-tooltip>
                    <v-icon x-small color="#614021">mdi-account</v-icon>
                  </v-row></v-col
                >
                <v-col cols="1">
                  <v-row class="d-flex justify-center">
                    <v-tooltip bottom color="#614021" class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#614021" v-bind="attrs" v-on="on"
                          >mdi-google</v-icon
                        >
                      </template>
                      <span>Pesquisei no Google</span>
                    </v-tooltip>
                  </v-row></v-col
                >
                <v-col cols="1">
                  <v-row class="d-flex justify-center">
                    <v-tooltip bottom color="#614021" class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#614021" v-bind="attrs" v-on="on"
                          >mdi-account-voice</v-icon
                        >
                      </template>
                      <span>Recebi uma indicação</span>
                    </v-tooltip>
                  </v-row></v-col
                >
                <v-col cols="1">
                  <v-row class="d-flex justify-center">
                    <v-tooltip bottom color="#614021" class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#614021" v-bind="attrs" v-on="on"
                          >mdi-account-alert</v-icon
                        >
                      </template>
                      <span>Já conhecia</span>
                    </v-tooltip>
                  </v-row></v-col
                >
                <v-col cols="1">
                  <v-row class="d-flex justify-center">
                    <v-tooltip bottom color="#614021" class="white--text">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#614021" v-bind="attrs" v-on="on"
                          >mdi-help</v-icon
                        >
                      </template>
                      <span>Outro</span>
                    </v-tooltip>
                  </v-row></v-col
                >
              </v-row>
            </v-card>
          </v-row>
          <v-row v-for="mStat in showBodyMarketStats" :key="mStat.monthIndex">
            <v-card outlined width="100%">
              <v-row class="d-flex align-center">
                <v-col cols="4">{{ mStat.monthName }}/{{ mStat.year }}</v-col>
                <v-col cols="1">
                  <v-row class="d-flex justify-center">
                    #pac.:
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <small>LTV:</small>
                  </v-row></v-col
                >
                <v-col cols="1" v-if="mStat.nrIndicacao0 > 0">
                  <v-row class="d-flex justify-center black--text">
                    <strong>{{ mStat.nrIndicacao0 }}</strong>
                  </v-row>
                  <v-row class="d-flex justify-center black--text">
                    <small
                      ><strong>{{
                        niceValor(mStat.ltvIndicacao0)
                      }}</strong></small
                    >
                  </v-row></v-col
                >
                <v-col cols="1" v-else>
                  <v-row class="d-flex justify-center grey--text">
                    {{ mStat.nrIndicacao0 }}
                  </v-row>
                  <v-row class="d-flex justify-center grey--text">
                    <small>{{ niceValor(mStat.ltvIndicacao0) }}</small>
                  </v-row></v-col
                >

                <v-col cols="1" v-if="mStat.nrIndicacao1 > 0">
                  <v-row class="d-flex justify-center black--text">
                    <strong>{{ mStat.nrIndicacao1 }}</strong>
                  </v-row>
                  <v-row class="d-flex justify-center black--text">
                    <small
                      ><strong>{{
                        niceValor(mStat.ltvIndicacao1)
                      }}</strong></small
                    >
                  </v-row></v-col
                >
                <v-col cols="1" v-else>
                  <v-row class="d-flex justify-center grey--text">
                    {{ mStat.nrIndicacao1 }}
                  </v-row>
                  <v-row class="d-flex justify-center grey--text">
                    <small>{{ niceValor(mStat.ltvIndicacao1) }}</small>
                  </v-row></v-col
                >

                <v-col cols="1" v-if="mStat.nrIndicacao2 > 0">
                  <v-row class="d-flex justify-center black--text">
                    <strong>{{ mStat.nrIndicacao2 }}</strong>
                  </v-row>
                  <v-row class="d-flex justify-center black--text">
                    <small
                      ><strong>{{
                        niceValor(mStat.ltvIndicacao2)
                      }}</strong></small
                    >
                  </v-row></v-col
                >
                <v-col cols="1" v-else>
                  <v-row class="d-flex justify-center grey--text">
                    {{ mStat.nrIndicacao2 }}
                  </v-row>
                  <v-row class="d-flex justify-center grey--text">
                    <small>{{ niceValor(mStat.ltvIndicacao2) }}</small>
                  </v-row></v-col
                >

                <v-col cols="1" v-if="mStat.nrIndicacao3 > 0">
                  <v-row class="d-flex justify-center black--text">
                    <strong>{{ mStat.nrIndicacao3 }}</strong>
                  </v-row>
                  <v-row class="d-flex justify-center black--text">
                    <small
                      ><strong>{{
                        niceValor(mStat.ltvIndicacao3)
                      }}</strong></small
                    >
                  </v-row></v-col
                >
                <v-col cols="1" v-else>
                  <v-row class="d-flex justify-center grey--text">
                    {{ mStat.nrIndicacao3 }}
                  </v-row>
                  <v-row class="d-flex justify-center grey--text">
                    <small>{{ niceValor(mStat.ltvIndicacao3) }}</small>
                  </v-row></v-col
                >

                <v-col cols="1" v-if="mStat.nrIndicacao4 > 0">
                  <v-row class="d-flex justify-center black--text">
                    <strong>{{ mStat.nrIndicacao4 }}</strong>
                  </v-row>
                  <v-row class="d-flex justify-center black--text">
                    <small
                      ><strong>{{
                        niceValor(mStat.ltvIndicacao4)
                      }}</strong></small
                    >
                  </v-row></v-col
                >
                <v-col cols="1" v-else>
                  <v-row class="d-flex justify-center grey--text">
                    {{ mStat.nrIndicacao4 }}
                  </v-row>
                  <v-row class="d-flex justify-center grey--text">
                    <small>{{ niceValor(mStat.ltvIndicacao4) }}</small>
                  </v-row></v-col
                >

                <v-col cols="1" v-if="mStat.nrIndicacao5 > 0">
                  <v-row class="d-flex justify-center black--text">
                    <strong>{{ mStat.nrIndicacao5 }}</strong>
                  </v-row>
                  <v-row class="d-flex justify-center black--text">
                    <small
                      ><strong>{{
                        niceValor(mStat.ltvIndicacao5)
                      }}</strong></small
                    >
                  </v-row></v-col
                >
                <v-col cols="1" v-else>
                  <v-row class="d-flex justify-center grey--text">
                    {{ mStat.nrIndicacao5 }}
                  </v-row>
                  <v-row class="d-flex justify-center grey--text">
                    <small>{{ niceValor(mStat.ltvIndicacao5) }}</small>
                  </v-row></v-col
                >

                <v-col cols="1" v-if="mStat.nrIndicacao6 > 0">
                  <v-row class="d-flex justify-center black--text">
                    <strong>{{ mStat.nrIndicacao6 }}</strong>
                  </v-row>
                  <v-row class="d-flex justify-center black--text">
                    <small
                      ><strong>{{
                        niceValor(mStat.ltvIndicacao6)
                      }}</strong></small
                    >
                  </v-row></v-col
                >
                <v-col cols="1" v-else>
                  <v-row class="d-flex justify-center grey--text">
                    {{ mStat.nrIndicacao6 }}
                  </v-row>
                  <v-row class="d-flex justify-center grey--text">
                    <small>{{ niceValor(mStat.ltvIndicacao6) }}</small>
                  </v-row></v-col
                >
              </v-row>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="funcionariosSelector" width="600">
      <v-card>
        <v-card-title class="d-flex justify-center text-subtitle-1">
          Selecione o funcionário do qual você gostaria de baixar a lista de
          acessos ao sistema
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-radio-group v-model="funcionarioInvestigado" column>
            <v-radio
              v-for="func in funcionarios"
              :key="func.id"
              :label="func.papel"
              :value="func.id"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="funcionariosSelector = false" color="#D6DBDF"
            >Fechar</v-btn
          >
          <v-btn
            @click="downloadAccesses(funcionarioInvestigado)"
            color="#c5d13f"
            >selecionar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSpecificCompra"
      max-width="80%"
      height="80%"
      multiple
    >
      <v-card>
        <v-row class="d-flex align-center mx-4">
          <v-col cols="8">
            <v-row>{{ compraVis.tipo }}</v-row
            ><v-row>
              <small>
                {{ compraVis.descr }}
              </small>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-chip color="#c5d13f" class="white--text">
                {{ niceString(compraVis.valor) }}
              </v-chip>
              <v-icon v-if="compraVis.descontoAct" color="orange" class="mx-1">
                mdi-sale
              </v-icon>
            </v-row>
            <v-row class="text-caption mt-1">
              <v-icon color="black" left small>{{
                formaIcon(compraVis.forma)
              }}</v-icon>
              {{ formaStr(compraVis.forma) }}
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="compraVis.pacienteName != ''" class="mx-4">
          {{ compraVis.pacienteName }}
        </v-row>
        <v-row
          v-if="
            compraVis.pacotes[0] !== undefined &&
              compraVis.pacotes[0].dataInicio !== undefined &&
              compraVis.pacotes[0].dataInicio !== null
          "
          class="mx-4"
        >
          <v-data-table
            :headers="pacoteHeaders"
            :items="compraVis.pacotes"
            class="elevation-1"
            hide-default-footer
          ></v-data-table>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSpecificEvent" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title>
          {{ eventVis.name }}
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center"
            >{{ readableStart(eventVis.start) }}-{{ readableEnd(eventVis.end) }}
          </v-row>
          <v-row class="d-flex justify-center"
            >Obs.: {{ eventVis.details }}
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showComprasAll" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title class="text-h6"> Compras </v-card-title>
        <v-card-text>
          <v-card
            v-for="comp in pacienteComprasAll"
            :key="comp.millis"
            width="95%"
            outlined
          >
            <v-row class="d-flex align-center mx-4">
              <v-col cols="5">
                <v-row>{{ comp.tipo }}</v-row>
                <v-row
                  ><small> {{ comp.descr }}</small></v-row
                >
              </v-col>
              <v-col cols="3">
                {{ comp.dateStr }}
              </v-col>
              <v-col cols="1">
                <v-icon color="#614021">{{ formaIcon(comp.forma) }}</v-icon>
              </v-col>
              <v-col cols="2" class="green--text">
                {{ niceString(comp.valor) }}
              </v-col>
              <v-col cols="1">
                <v-btn icon @click="setCompraVisualizar(comp)">
                  <v-icon color="#614021">mdi-eye</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAgendaAll" max-width="80%" height="80%" multiple>
      <v-card elevation="5">
        <v-card-title class="text-h6"> Agendamentos </v-card-title>
        <v-card-text>
          <v-card v-for="event in pacienteEvents" :key="event.id" outlined>
            <v-card>
              <v-row class="d-flex justify-center">
                <v-col cols="12" class="d-flex justify-space-between">
                  <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                    {{ event.name }}, {{ event.niceDate }}
                  </v-row>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#c5d13f"
                        v-bind="attrs"
                        v-on="on"
                        @click="visualizarEvent(event)"
                        class="mr-2 mx-2 black--text"
                      >
                        <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card elevation="5">
      <v-row class="d-flex justify-center">
        <v-btn
          color="#614021"
          @click="downloadAll"
          class="my-1 white--text"
          :disabled="disableDownload"
        >
          <v-progress-circular
            v-if="disableDownload"
            :size="20"
            :width="3"
            color="#c5d13f"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else left color="#c5d13f">mdi-download</v-icon>baixar lista
          de todos os pacientes ({{ pacienteLoadedNr }}/{{
            pacientes.length
          }})<v-icon right color="#ffffff" class="mr-1"
            >mdi-account-group</v-icon
          >
        </v-btn>
      </v-row>

      <v-row
        class="d-flex justify-center"
        v-if="
          funcionarioOnline.papel == 'Administradora' ||
            funcionarioOnline.papel == 'Proprietário' ||
            funcionarioOnline.papel == 'Proprietária'
        "
      >
        <v-btn
          color="#614021"
          @click="showFuncionarios"
          class="my-1 white--text"
          :disabled="disableDownloadAccesses"
        >
          <v-progress-circular
            v-if="disableDownloadAccesses"
            :size="20"
            :width="3"
            color="#c5d13f"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else left color="#c5d13f">mdi-download</v-icon>baixar lista
          de acessos ({{ accessLoadedNr }}/{{ bodyAccesses.length }})<v-icon
            right
            color="#ffffff"
            class="mr-1"
            >mdi-cursor-default-click-outline</v-icon
          >
        </v-btn>
      </v-row>

      <v-row
        class="d-flex justify-center"
        v-if="
          funcionarioOnline.papel == 'Administradora' ||
            funcionarioOnline.papel == 'Proprietário' ||
            funcionarioOnline.papel == 'Proprietária' ||
            funcionarioOnline.papel == 'Nutricionista 1'
        "
      >
        <v-btn
          color="#614021"
          @click="downloadRatings"
          class="my-1 white--text"
          :disabled="disableDownloadRatings"
        >
          <v-progress-circular
            v-if="disableDownloadRatings"
            :size="20"
            :width="3"
            color="#c5d13f"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else left color="#c5d13f">mdi-download</v-icon>baixar lista
          de avaliações ({{ ratingsLoadedNr }}/{{ bodyRatings.length }})<v-icon
            right
            color="#ffffff"
            >mdi-star-outline</v-icon
          >
        </v-btn>
      </v-row>

      <v-row
        class="d-flex justify-center"
        v-if="
          funcionarioOnline.papel == 'Administradora' ||
            funcionarioOnline.papel == 'Proprietário' ||
            funcionarioOnline.papel == 'Proprietária' ||
            funcionarioOnline.papel == 'Nutricionista 1'
        "
      >
        <v-btn
          color="#c5d13f"
          @click="seeMarketStats"
          class="my-1"
          :disabled="disableSeeMarketStats"
        >
          <v-progress-circular
            v-if="disableSeeMarketStats"
            :size="20"
            :width="3"
            color="#c5d13f"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else left color="#614021">mdi-eye</v-icon>
          Estatísticas
          <v-icon right color="#614021">mdi-account-voice</v-icon>
        </v-btn>
      </v-row>

      <!-- <v-row class="d-flex justify-center">
        <v-btn color="#614021" @click="getProspectos" class="my-1 white--text"
          ><v-progress-circular
            v-if="chargeProspectos"
            :size="20"
            :width="3"
            color="#c5d13f"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else left color="#c5d13f">mdi-account-question</v-icon>Ver
          prospectos
        </v-btn>
      </v-row> -->

      <!-- <v-row class="d-flex justify-center">
        <v-btn
          color="#614021"
          @click="copyContent"
          class="my-1 white--text"
          :disabled="copying"
        >
          <v-progress-circular
            v-if="copying"
            :size="20"
            :width="3"
            color="#c5d13f"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else left color="#c5d13f">mdi-content-copy</v-icon>copy
          content
        </v-btn>
      </v-row> -->

      <!-- <v-row class="d-flex justify-center">
        <v-btn
          color="#614021"
          @click="copyContent"
          class="my-1 white--text"
          :disabled="copying"
        >
          <v-progress-circular
            v-if="copying"
            :size="20"
            :width="3"
            color="#c5d13f"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else left color="#c5d13f">mdi-content-copy</v-icon>merge
          content
        </v-btn>
      </v-row> -->

      <!-- <v-row class="d-flex justify-center">
        <v-btn color="#614021" @click="addLtv" class="my-1 white--text">
          add LTV
        </v-btn>
      </v-row> -->

      <v-card-title>
        <v-row class="text-h6">
          <v-col cols="12"> Pacientes Ativos </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="pComs.length > 0">
        <v-card color="grey">
          <v-row class="d-flex justify-center">
            <v-col cols="12" class="d-flex justify-space-between">
              <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                <v-col cols="4"> Nome do paciente</v-col>
                <v-col cols="4"> Tipo de programa:</v-col>
                <v-col cols="2">
                  <v-row>Fim do programa:</v-row>
                  <v-row
                    ><small>(hoje é {{ today }}) </small></v-row
                  >
                </v-col>
                <v-col cols="2"> </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <div v-for="(pObj, pIndex) in pComs" :key="pIndex">
          <v-card
            :color="cardColor(pObj)"
            outlined
            elevation="2"
            max-height="100"
          >
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" class="d-flex justify-space-between">
                <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                  <v-col cols="4"> {{ pObj.pName }}</v-col>
                  <v-col cols="4">
                    <small>{{ readableTipo(pObj.programaTipo) }}</small></v-col
                  >
                  <v-col cols="2"> {{ readableDate(pObj.dataFim) }}</v-col>
                  <v-col cols="1" class="d-flex justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="visAgendaAll(pObj.pEmail)"
                        >
                          <v-icon color="black">mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <span>ver agendamentos</span>
                    </v-tooltip></v-col
                  >
                  <v-col cols="1" class="d-flex justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="visComprasAll(pObj.pEmail)"
                        >
                          <v-icon color="black">mdi-currency-usd</v-icon>
                        </v-btn>
                      </template>
                      <span>ver compras</span>
                    </v-tooltip></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <v-progress-circular
          :size="50"
          :width="5"
          color="#614021"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import firebase from "firebase";
import {
  mdiAccountPlus,
  mdiDownload,
  mdiPrinter,
  mdiEye,
  mdiAutorenew,
  mdiFinance,
  mdiWeb,
  mdiLock,
  mdiEmail,
  mdiEyeOff,
  mdiStore,
  mdiAccount,
  mdiAlert,
  mdiCalendar,
  mdiPhone,
  mdiWhatsapp,
  mdiMapMarker,
  mdiCity,
  mdiFlagVariantOutline,
  mdiPiggyBank,
  mdiHospitalBuilding,
  mdiHandHeart,
  mdiBabyCarriage,
  mdiHumanMaleChild,
  mdiAccountVoice,
  mdiWeightKilogram,
  mdiHumanMaleHeightVariant,
  mdiWeightLifter,
  mdiCreditCardCheckOutline,
  mdiCash100,
  mdiCreditCardClockOutline,
  mdiBankTransfer,
  mdiInfinity,
  mdiHelpRhombusOutline,
  mdiWalletPlusOutline,
  mdiCheckbook,
  mdiTableEdit,
  mdiBullseyeArrow,
} from "@mdi/js";
import jspdf from "jspdf";
import "jspdf-autotable";
import firebase from "firebase";

export default {
  data: function() {
    return {
      menuStart: false,
      menuEnd: false,
      // chargeProspectos: false,
      // prospectos: [
      //   {
      //     nome: null,
      //     sexo: null,
      //     telefone: null,
      //     whatsapp: null,
      //     email: null,
      //     objetivo: null,
      //     motivoRecusao: null,
      //     motivoRecusaoOutro: null,
      //     meioContato: null,
      //     detalhesImportantes: null,
      //     horarioLigacao: null,
      //     millis: null,
      //   },
      // ],
      // filteredProspectos: [
      //   {
      //     nome: null,
      //     sexo: null,
      //     telefone: null,
      //     whatsapp: null,
      //     email: null,
      //     objetivo: null,
      //     motivoRecusao: null,
      //     motivoRecusaoOutro: null,
      //     meioContato: null,
      //     detalhesImportantes: null,
      //     horarioLigacao: null,
      //     millis: null,
      //     dia: null,
      //     mes: null,
      //     ano: null,
      //   },
      // ],
      endChosen: null,
      startChosen: null,
      sexos: ["feminino", "masculino"],
      sexosChosen: ["feminino", "masculino"],
      objetivos: [
        "Emagrecer",
        "Tratar um Problema de Saúde",
        "Ganhar Massa Magra",
        "Procedimento Estético",
        "Exames",
      ],
      objetivosChosen: [
        "Emagrecer",
        "Tratar um Problema de Saúde",
        "Ganhar Massa Magra",
        "Procedimento Estético",
        "Exames",
      ],
      meios: [
        // meio de contato para agendamento
        "Ligou para telefone fixo",
        "Ligou para celular",
        "Ligou para WhatsApp",
        "Mandou mensagem no WhatsApp",
        "Mandou mensagem no Instagram",
        "Agendou direto na recepção",
      ],
      meiosChosen: [
        // meio de contato para agendamento
        "Ligou para telefone fixo",
        "Ligou para celular",
        "Ligou para WhatsApp",
        "Mandou mensagem no WhatsApp",
        "Mandou mensagem no Instagram",
        "Agendou direto na recepção",
      ],
      motivos: ["Buscava plano de saúde", '"Vou pensar..."', "Outros"],
      motivosChosen: ["Buscava plano de saúde", '"Vou pensar..."', "Outros"],
      // filterProspectosDialog: false,
      // visualizarProspectosDialog: false,
      // nrFiltrados: null,
      // nrProspectos: null,
      rules: {
        dataNasc: [
          function(v) {
            if (v.includes("-")) {
              if (v.split("-").length == 3) {
                if (
                  v.split("-")[0].length == 4 &&
                  v.split("-")[1].length == 2 &&
                  v.split("-")[1] <= 12 &&
                  v.split("-")[2].length == 2 &&
                  v.split("-")[2] <= 31
                ) {
                  return true;
                }
              } else {
                return "Usar formato automático! (por exemplo: 2022-12-23)";
              }
            } else {
              return "Usar formato automático! (por exemplo: 2022-12-23)";
            }
          },
        ],
      },
      paciencePlease: false,
      funcionariosSelector: false,
      funcionarioInvestigado: null,
      disableDownload: false,
      pacienteLoadedNr: 0,
      disableDownloadRatings: false,
      disableDownloadAccesses: false,
      ratingsLoadedNr: 0,
      bodyRatings: [],
      disableDownloadMarketStats: false,
      bodyMarketStats: [],
      showBodyMarketStats: [],
      showMarketStatsAno: null,
      dialogMarketStats: false,
      disableSeeMarketStats: false,
      accessLoadedNr: 0,
      bodyAccesses: [],
      copying: false,
      pacientePadraoDialog: false,
      pacienteKirchhoffDialog: false,
      loaded: false,
      pComs: [],
      pSems: [],
      body: [],
      showComprasAll: false,
      showAgendaAll: false,
      showSpecificCompra: false,
      showSpecificEvent: false,
      pacienteDocID: null,
      pacienteEvents: [],
      pacoteHeaders: [
        { text: "Pacote", value: "nome" },
        { text: "Mês do programa", value: "dataInicio" },
        { text: "Area", value: "area" },
      ],
      icons: {
        mdiAccountPlus,
        mdiBullseyeArrow,
        mdiDownload,
        mdiPrinter,
        mdiEye,
        mdiAutorenew,
        mdiFinance,
        mdiWeb,
        mdiLock,
        mdiEmail,
        mdiEyeOff,
        mdiStore,
        mdiAccount,
        mdiAlert,
        mdiCalendar,
        mdiPhone,
        mdiWhatsapp,
        mdiMapMarker,
        mdiCity,
        mdiFlagVariantOutline,
        mdiPiggyBank,
        mdiHospitalBuilding,
        mdiHandHeart,
        mdiBabyCarriage,
        mdiHumanMaleChild,
        mdiAccountVoice,
        mdiWeightKilogram,
        mdiHumanMaleHeightVariant,
        mdiWeightLifter,
        mdiCreditCardCheckOutline,
        mdiCash100,
        mdiCreditCardClockOutline,
        mdiBankTransfer,
        mdiInfinity,
        mdiHelpRhombusOutline,
        mdiWalletPlusOutline,
        mdiCheckbook,
        mdiTableEdit,
      },
    };
  },
  methods: {
    niceValor(valor) {
      var valorKomma;

      var decimals = Math.round((valor - Math.floor(valor)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valor);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valor / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    // async addLtv() {
    //   var users_cru = await firebase
    //     .firestore()
    //     .collection("users")
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         let data = doc.data();
    //         data.id = doc.id;
    //         return data;
    //       })
    //     );
    //   var users = users_cru.filter(function(uData) {
    //     if (uData.isPatient !== undefined) {
    //       return uData.isPatient;
    //     } else {
    //       return false;
    //     }
    //   }).map(function (u) {
    //     return u.id;
    //   });
    //   // var users = [
    //   //   "aaa.bbb@gmail.com",
    //   //   "aaa.zzz@test.com"
    //   // ]
    //   console.log(users[1]);
    //   users.forEach(async function(uId) {
    //     var compras = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(uId)
    //       .collection("compras")
    //       .get()
    //       .then((snapshot) =>
    //         snapshot.docs.map((doc) => {
    //           let data = doc.data();
    //           data.id = doc.id;
    //           return data;
    //         })
    //       );
    //     var ltv = Math.round(0);
    //     if (compras !== undefined && compras.length > 0) {
    //       compras.forEach(async function(c) {
    //         ltv += Math.round(parseFloat(c.valor));
    //       });
    //     }
    //     await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(uId)
    //       .update({
    //         ltv: ltv,
    //       });
    //   });
    //   console.log("done attributing ltv")
    // },

    // saveStart(date) {
    //   this.$refs.menuStart.save(date);
    //   this.filterProspectos();
    // },
    // saveEnd(date) {
    //   this.$refs.menuEnd.save(date);
    //   this.filterProspectos();
    // },
    // async getProspectos() {
    //   this.chargeProspectos = true;

    //   const allLigacoes = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc(sessionStorage.getItem("current-user"))
    //     .collection("ligacoes")
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         let data = doc.data();
    //         data.id = doc.id;
    //         return data;
    //       })
    //     );

    //   var prospectos = allLigacoes.filter(function (e) {
    //     return !e.conversion;
    //   });

    //   this.prospectos = prospectos;
    //   this.filteredProspectos = prospectos;
    //   this.nrProspectos = prospectos.length;

    //   this.filterProspectosDialog = true;
    //   this.chargeProspectos = false;
    // },
    // filterProspectos() {
    //   console.log("not filtered", this.prospectos);
    //   var objetivosChosen = this.objetivosChosen;
    //   var sexosChosen = this.sexosChosen;
    //   var meiosChosen = this.meiosChosen;
    //   var motivosChosen = this.motivosChosen;
    //   var startChosen = new Date(this.startChosen + " 03:00:00");
    //   var startMillis = startChosen.getTime();
    //   var endChosen = new Date(this.endChosen + " 23:00:00");
    //   var endMillis = endChosen.getTime();

    //   var filteredProspectos = this.prospectos.filter(function (e) {
    //     return (
    //       sexosChosen.includes(e.sexo) &&
    //       objetivosChosen.includes(e.objetivo) &&
    //       meiosChosen.includes(e.meioContato) &&
    //       motivosChosen.includes(e.motivoRecusao) &&
    //       startMillis < e.millis &&
    //       endMillis > e.millis
    //     );
    //   });

    //   this.filteredProspectos = filteredProspectos;
    //   this.nrFiltrados = filteredProspectos.length;
    //   console.log("filtered", this.prospectos);
    // },
    showFuncionarios() {
      this.funcionariosSelector = true;
    },
    // downloadProspectos() {
    //   setTimeout(() => {
    //     // console.log(this.body);
    //     alert("Gerando PDF");
    //     var doc = new jspdf("l");
    //     var fontSize = 12;
    //     doc.setFontSize(fontSize);
    //     doc.setFont("Times");

    //     console.log(this.filteredProspectos)
    //     var body = this.filteredProspectos.map(function (p) {
    //       return [
    //         p.dia + "/" + p.mes + "/" + p.ano + ", " + p.horarioLigacao,
    //         p.meioContato,
    //         p.objetivo,
    //         p.nome,
    //         p.email,
    //         p.telefone,
    //         p.whatsapp,
    //       ];
    //     });
    //     console.log(body);

    //     doc.autoTable({
    //       startY: 0,
    //       startx: 0,
    //       head: [
    //         [
    //           "Data/Hora",
    //           "Meio de contato",
    //           "Objetivo",
    //           "Nome",
    //           "Email",
    //           "Telefone",
    //           "WhatsApp",
    //         ],
    //       ],
    //       body: body,
    //     });

    //     doc.save("prospectos.pdf");
    //   }, 500);
    // },
    downloadAll() {
      this.disableDownload = true;
      this.fillBody();
      this.addCompras();
      setInterval(() => {
        if (this.pacienteLoadedNr < this.pacientes.length) {
          this.pacienteLoadedNr += 1;
        } else {
          this.pacienteLoadedNr = this.pacientes.length;
        }
      }, 1000);

      setTimeout(() => {
        this.disableDownload = false;

        // console.log(this.body);
        alert("Lista de pacientes PDF");
        var doc = new jspdf("l");
        var fontSize = 12;
        doc.setFontSize(fontSize);
        doc.setFont("Times");

        // console.log(this.body);
        // var body = this.body.filter(function (list) {
        //   return list[5].includes("Adesão ao Programa de Acompanhamento Nutricional de 90 Dias + Pacote Estético") ||
        //   // list[5].includes("Adesão ao Programa de Acompanhamento Nutricional de 90 Dias") ||
        //   // list[5].includes("Programa Clínico Funcional") ||
        //   list[5].includes("Pacote Estético") ||
        //   list[5].includes("Programa 12S Plano VIP") ||
        //   list[5].includes("Programa 12S Plano Básico") ||
        //   list[5].includes("Programa 12S Plano Premium")
        // })

        doc.autoTable({
          startY: 0,
          startx: 0,
          head: [
            [
              "Nome",
              "Nasc.",
              "Entrada",
              "Email",
              "Telefone",
              "WhatsApp",
              "LTV (R$)",
              "Compras",
            ],
          ],
          body: this.body,
        });

        doc.save("lista.pdf");
      }, 1000 * this.pacientes.length);
    },
    downloadRatings() {
      this.fillRatings();
      this.disableDownloadRatings = true;
      setInterval(() => {
        if (this.ratingsLoadedNr < this.bodyRatings.length) {
          this.ratingsLoadedNr += 1;
        } else {
          this.ratingsLoadedNr = this.bodyRatings.length;
        }
      }, 400);

      setTimeout(() => {
        this.disableDownloadRatings = false;

        // console.log(this.body);
        alert("Gerando PDF");
        var doc = new jspdf("l");
        var fontSize = 12;
        doc.setFontSize(fontSize);
        doc.setFont("Times");

        // console.log(this.body);

        doc.autoTable({
          startY: 0,
          startx: 0,
          head: [
            [
              "Nome",
              "Data hora",
              "Esteticista",
              "Recepcionista",
              "Nutricionista",
              "Cardápios",
              "Tratamento",
              "Software",
              "Total",
            ],
          ],
          body: this.bodyRatings,
        });

        doc.save("ratings.pdf");
      }, 400 * this.bodyRatings.length);
    },
    downloadAccesses(id) {
      this.disableDownloadAccesses = true;
      this.fillAccesses(id);
      this.funcionariosSelector = false;
      this.paciencePlease = true;
      setInterval(() => {
        if (this.accessLoadedNr < this.bodyAccesses.length) {
          this.accessLoadedNr += 1;
        } else {
          this.accessLoadedNr = this.bodyAccesses.length;
          this.disableDownloadAccesses = false;
        }
      }, 100);
      var minimum = this.bodyAccesses.length + 20;

      setTimeout(() => {
        // console.log(this.body);
        alert("Gerando PDF");
        var doc = new jspdf("l");
        var fontSize = 12;
        doc.setFontSize(fontSize);
        doc.setFont("Times");

        // console.log(this.body);

        doc.autoTable({
          startY: 0,
          startx: 0,
          head: [["IP", "Data hora"]],
          body: this.bodyAccesses,
        });

        doc.save(`accessos_${id}.pdf`);
        this.paciencePlease = false;
      }, 400 * minimum);
    },
    // async copyContent() {
    //   // get pacientes with "joinedAt" later than 01/04/23
    //   let pacs = this.pacientes.filter((p) => {
    //     return p.data.joinedAt.seconds * 1000 > new Date("2023-04-01").getTime();
    //   }).map((p) => {
    //     return p.data.nome;
    //   });
    //   console.log("pacs", pacs);
    // },
    // async copyContent() {
    //   let fat2022 =  //[35370, 33700, 33290, 35190, 35350, 27620, 21190, 23320];
    //   [
    //     32410,
    //     26970,
    //     26580,
    //     26420,
    //     47340,
    //     32580,
    //     33910,
    //     32610,
    //     37320,
    //     36290,
    //     38240,
    //     27700,
    //   ];
    //   // let fat2023 = [35370, 33700, 33290, 35190, 35350, 27620, 21190, 23320];

    //   console.log("custos");
    //   let yyyy = 2022;
    //   let mm;
    //   // function randomNr(min, max) {
    //   //   let nr = Math.random() * (max - min + 1);
    //   //   let nrMin = Math.round(nr * 100) / 100;
    //   //   return nrMin + min;
    //   // }
    //   for (let mx = 1; mx <= 12; mx++) {
    //     console.log("m:" + mx);
    //     if (mx > 9) {
    //       mm = mx;
    //     } else {
    //       mm = "0" + mx;
    //     }
    //     let date = new Date(yyyy + "-" + mm + "-05");
    //     let millis = date.getTime();

    //     let dateStr = "05/" + mm + "/" + yyyy;
    //     // PGTO SALARIO RECEPCIONISTA		- R$ 1.217,84
    //     // PGTO SALARIO ESTETICISTA		- R$ 1.130,20
    //     // PGTO ALUGUEL - R$ 2.800,00

    //     let objs = [
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PGTO MATERIAL",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: randomNr(145, 428) * -1,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PGTO DIARISTA",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: -500,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PGTO AGUA",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: randomNr(150, 180) * -1,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PGTO LUZ",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: randomNr(600, 900) * -1,
    //       //   y: yyyy,
    //       // },
    //       {
    //         d: 5,
    //         dateStr: dateStr,
    //         descontoAct: false,
    //         descr: "ROYALTIES",
    //         direction: false,
    //         forma: 5,
    //         id: millis.toString(),
    //         m: mx,
    //         millis: millis,
    //         paciente: null,
    //         pacienteName: "",
    //         pacotes: [],
    //         tipo: "Outro",
    //         valor:
    //           mx > 2
    //             ? Math.min(
    //                 (Math.round(0.06 * fat2022[mx - 2] * 100) / 100) * -1,
    //                 -1960
    //               )
    //             : -1960,
    //         y: yyyy,
    //       },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PUBLICIDADE",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor:
    //       //     mx > 2
    //       //       ? (Math.round(0.02 * fat2022[mx - 2] * 100) / 100) * -1
    //       //       : -240,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "SOFTWARE",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: -160,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "ANUNCIOS",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor:
    //       //     mx > 2
    //       //       ? (Math.round(0.04 * fat2022[mx - 2] * 100) / 100) * -1
    //       //       : -1678,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PGTO SALARIO NUTRICIONISTA 1",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: -2015.73,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PGTO SALARIO NUTRICIONISTA 2",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: -2898.15,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PGTO ALUGUEL",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: -2800,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PGTO SALARIO RECEPCIONISTA",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: -1217.84,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "PGTO SALARIO ESTETICISTA",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: -1130.2,
    //       //   y: yyyy,
    //       // },
    //       // {
    //       //   d: 5,
    //       //   dateStr: dateStr,
    //       //   descontoAct: false,
    //       //   descr: "HONORÁRIOS CONTADOR",
    //       //   direction: false,
    //       //   forma: 5,
    //       //   id: millis.toString(),
    //       //   m: mx,
    //       //   millis: millis,
    //       //   paciente: null,
    //       //   pacienteName: "",
    //       //   pacotes: [],
    //       //   tipo: "Outro",
    //       //   valor: -600,
    //       //   y: yyyy,
    //       // },
    //     ];

    //     for (let i = 0; i < objs.length; i++) {
    //       let obj = objs[i];
    //       console.log(i + "/" + objs.length);
    //       obj.millis += Math.round(Math.random() * 100);
    //       obj.id = obj.millis.toString();
    //       await firebase
    //         .firestore()
    //         .collection("users")
    //         .doc("bc2@clubedanutricionista.com.br")
    //         .collection("caixa")
    //         .doc(obj.id)
    //         .set(obj);
    //     }
    //   }
    // },
    // async copyContent() {
    //   let y = 2023;
    //   console.log("correct: " + y);
    //   let caixa = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc("bc2@clubedanutricionista.com.br")
    //     .collection("caixa")
    //     .where("y", "==", y)
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         let data = doc.data();
    //         data.id = doc.id;
    //         return data;
    //       })
    //     );
    //   console.log("got caixa", caixa.length);

    //   for (var m = 9; m <= 9; m++) {
    //     console.log("m: " + m);
    //     let arr = caixa.filter((obj) => obj.m == m && obj.direction == true);

    //     // Step 1: Filter out objects where `tipo` starts with "1 consulta"
    //     const filtered = arr;
    //     // .filter((obj) => {
    //     //   return !obj.tipo.startsWith("1 consulta");
    //     // });

    //     let sum = 0;
    //     const ids = [];

    //     // Calculate the total sum of `valor` from all objects
    //     const totalSum = arr.reduce(
    //       (acc, curr) => acc + parseFloat(curr.valor),
    //       0
    //     );
    //     console.log("totalSum: " + totalSum);

    //     // Start summing the values of `valor` and collect the `id` of each object until the sum reaches 37000
    //     for (const obj of filtered) {
    //       if (totalSum - sum - parseFloat(obj.valor) > 15000) {
    //         ids.push(obj.id);
    //         sum += parseFloat(obj.valor);
    //       }
    //     }
    //     console.log("deleting " + ids.length + " objects");

    //     // Step 2: Delete all objects with the collected `id`s
    //     await Promise.all(
    //       ids.map(async (id) => {
    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(id)
    //           .delete()
    //           .then(() => {
    //             console.log("deleted " + id);
    //           });
    //       })
    //     );
    //   }
    // },
    // async copyContent() {
    //   // get caixa
    //   // filter 2022
    //   let caixa = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc("bc2@clubedanutricionista.com.br")
    //     .collection("caixa")
    //     .where("y", "==", 2023)
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         let data = doc.data();
    //         data.id = doc.id;
    //         return data;
    //       })
    //     );
    //   console.log("got caixa", caixa.length);

    //   function simplify(str) {
    //     // Replace spaces and special characters with underscores
    //     str = str.replace(/[\s-]/g, "");
    //     str = str.replace(" ", "");
    //     // Replace special characters with their simplified counterparts
    //     str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //     return str.toLowerCase();
    //   }

    //   for (var m = 7; m <= 7; m++) {
    //     console.log("m: " + m);
    //     let mesConsultas = caixa.filter(
    //       (obj) => obj.m == m && obj.tipo.toLowerCase().startsWith("1 consulta")
    //     );
    //     await Promise.all(
    //       mesConsultas.map(async (obj) => {
    //         let randomNumber = Math.random();
    //         if (randomNumber < 0.3 * 0.25) {
    //           console.log("p990");
    //           let p990 = {
    //             direction: true,
    //             tipo: "Programa 12S",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: 990,
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Programa 12S",
    //                 valor: {
    //                   balcam: 990,
    //                   fortal: 990,
    //                   belemx: 990,
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: null,
    //                 area1: null,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: null,
    //                 millisInicio: null,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "12341234",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 60 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };
    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p990.millis.toString())
    //             .set(p990);
    //         } else if (randomNumber < 0.75 * 0.25) {
    //           console.log("p1490");
    //           let areas = [
    //             "FLANCO ESQUERDO + DIREITO",
    //             "ABDOMEN SUPERIOR + INFERIOR",
    //           ];
    //           let randomIndex = Math.round(Math.random());
    //           let area1 = areas[randomIndex];
    //           let p1490 = {
    //             direction: true,
    //             tipo: "Programa 12S com Estética",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: 1490,
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Programa 12S com Estética",
    //                 valor: {
    //                   balcam: 1490,
    //                   fortal: 1490,
    //                   belemx: 1490,
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: area1,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 0,
    //                 millisInicio: obj.millis + 90 * 60 * 1000,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341234",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p1490.millis.toString())
    //             .set(p1490);
    //         } else if (randomNumber < 0.8 * 0.25) {
    //           console.log("p1890");
    //           let p1890 = {
    //             direction: true,
    //             tipo: "Programa 12S com Estética",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: 1890,
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Programa 12S com Estética",
    //                 valor: {
    //                   balcam: 1890,
    //                   fortal: 1890,
    //                   belemx: 1890,
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "ABDOMEN INFERIOR + SUPERIOR",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 0,
    //                 millisInicio: obj.millis + 90 * 60 * 1000,
    //                 index: 0,
    //               },
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "FLANCO ESQUERDO + DIREITO",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 1,
    //                 millisInicio: obj.millis + 28 * 24 * 60 * 60 * 1000,
    //                 index: 1,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p1890.millis.toString())
    //             .set(p1890);
    //         } else if (randomNumber < 0.25) {
    //           console.log("p2190");
    //           let p2190 = {
    //             direction: true,
    //             tipo: "Programa 12S com Estética",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: 2190,
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Programa 12S com Estética",
    //                 valor: {
    //                   balcam: 2190,
    //                   fortal: 2190,
    //                   belemx: 2190,
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "ABDOMEN INFERIOR + SUPERIOR",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 0,
    //                 millisInicio: obj.millis + 90 * 60 * 1000,
    //                 index: 0,
    //               },
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "FLANCO ESQUERDO + DIREITO",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 1,
    //                 millisInicio: obj.millis + 28 * 24 * 60 * 60 * 1000,
    //                 index: 1,
    //               },
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "ABDOMEN INFERIOR + SUPERIOR",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 2,
    //                 millisInicio: obj.millis + 2 * 28 * 24 * 60 * 60 * 1000,
    //                 index: 2,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p2190.millis.toString())
    //             .set(p2190);
    //         } else {
    //           console.log("none");
    //         }
    //       })
    //     );
    //   }

    //   // 0.7 rate of conversion:
    //   // - 0.333333 de 990
    //   // - 0.333333 de 1490
    //   // - 0.333333 de 1890
    //   // + agenda
    // },

    // async copyContent() {
    //   // get caixa
    //   // filter 2022
    //   // let caixa = await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   .doc("bc2@clubedanutricionista.com.br")
    //   //   .collection("caixa")
    //   //   .where("millis", ">", 1678610066000)
    //   //   .get()
    //   //   .then((snapshot) =>
    //   //     snapshot.docs.map((doc) => {
    //   //       let data = doc.data();
    //   //       data.id = doc.id;
    //   //       return data;
    //   //     })
    //   //   );
    //   // console.log("got caixa", caixa.length);

    //   // // adjust name
    //   // caixa = caixa.map((c) => {
    //   //   // c.pacienteName = getRandomPortugueseName();
    //   //   c.y = 2023;
    //   //   return c;
    //   // });

    //   // adjust forma
    //   // caixa = caixa.map((c) => {
    //   //   const randomNumber = Math.random();
    //   //   if (randomNumber < 0.22) {
    //   //     c.forma = 3;
    //   //     c.soldObjs = [
    //   //       {
    //   //         duracao: 60,
    //   //         nome: "1 consulta nutricional Crédito / Débito",
    //   //         transactModus: ["Cartão de Crédito", "Cartão de Débito"],
    //   //         valor: {
    //   //           balcam: 300,
    //   //           belemx: 300,
    //   //           fortal: 300,
    //   //         },
    //   //       },
    //   //     ];
    //   //     return c;
    //   //   } else {
    //   //     return c;
    //   //   }
    //   // });

    //   function simplify(str) {
    //     // Replace spaces and special characters with underscores
    //     str = str.replace(/[\s-]/g, "");
    //     str = str.replace(" ", "");
    //     // Replace special characters with their simplified counterparts
    //     str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //     return str.toLowerCase();
    //   }

    //   function randomNr(min, max) {
    //     let nr = Math.random() * (max - min + 1);
    //     let nrMin = Math.round((nr * 100) / 100);
    //     return nrMin + min;
    //   }

    //   function getRandomPortugueseName() {
    //     const firstNames = [
    //       "Pedro",
    //       "Miguel",
    //       "Diogo",
    //       "Sofia",
    //       "Mariana",
    //       "Inês",
    //       "João",
    //       "Maria",
    //       "Francisco",
    //       "Rita",
    //       "Manuel",
    //       "Filipe",
    //       "Matilde",
    //       "Marta",
    //       "Nuno",
    //       "David",
    //       "Guilherme",
    //       "Luísa",
    //       "Lúcio",
    //       "Carlos",
    //       "Joaquim",
    //       "Leonor",
    //       "Gonçalo",
    //       "Ricardo",
    //       "Teresa",
    //       "Vasco",
    //       "André",
    //       "Ana",
    //       "Catarina",
    //       "Vitória",
    //       "Federico",
    //       "Giuseppe",
    //       "Alessandro",
    //       "Simone",
    //       "Riccardo",
    //       "Marco",
    //       "Francesco",
    //       "Lorenzo",
    //       "Matteo",
    //       "Giovanni",
    //       "Antonio",
    //       "Michele",
    //       "Alberto",
    //       "Dario",
    //       "Silvia",
    //       "Erika",
    //       "Elisa",
    //       "Julia",
    //       "Luisa",
    //       "Sara",
    //       "Martina",
    //       "Chiara",
    //       "Jeanete",
    //       "Angela",
    //       "Roberto",
    //       "Cristina",
    //       "Giacomo",
    //       "Lúcio",
    //       "Henrique",
    //       "Fred",
    //       "Wania",
    //       "Washington",
    //       "Martina",
    //       "Michaela",
    //       "Estefania",
    //       "Catrina",
    //       "Pietra",
    //       "Andrea",
    //       "Susanne",
    //       "Tatjana",
    //       "Stefana",
    //       "Julia",
    //       "Sebastiano",
    //       "Francisca",
    //       "Lara",
    //       "Natasha",
    //       "Klara",
    //       "Giselle",
    //       "Monika",
    //       "Joanna",
    //       "Sabine",
    //       "Maria",
    //       "Anke",
    //       "João",
    //       "Felipe",
    //       "Luisa",
    //       "Pablo",
    //       "Andrés",
    //       "Javier",
    //       "Juan",
    //       "José",
    //       "Antonio",
    //       "Antonia",
    //       "Francisco",
    //       "Manuela",
    //       "Miguel",
    //       "David",
    //       "Raúl",
    //       "Carlos",
    //       "Jorge",
    //       "Carmen",
    //       "Ana",
    //       "Lucía",
    //       "María",
    //       "Isabel",
    //       "Sofía",
    //       "Ericka",
    //       "Eliane",
    //       "Soyane",
    //       "Saraya",
    //       "Odinalva",
    //       "Adriana",
    //       "Alessandra",
    //       "Aline",
    //       "Amanda",
    //       "Ana",
    //       "Andressa",
    //       "Bárbara",
    //       "Beatriz",
    //       "Bianca",
    //       "Bruna",
    //       "Camila",
    //       "Carla",
    //       "Carolina",
    //       "Catarina",
    //       "Cecília",
    //       "Clara",
    //       "Daniela",
    //       "Débora",
    //       "Eduarda",
    //       "Elisa",
    //       "Eloá",
    //       "Emanuelly",
    //       "Emilly",
    //       "Esther",
    //       "Fernanda",
    //       "Gabriela",
    //       "Gabrielly",
    //       "Giovanna",
    //       "Helena",
    //       "Isabel",
    //       "Isabela",
    //       "Isadora",
    //       "Jéssica",
    //       "Joana",
    //       "Julia",
    //       "Juliana",
    //       "Júlia",
    //       "Lara",
    //       "Larissa",
    //       "Laura",
    //       "Lavínia",
    //       "Letícia",
    //       "Lívia",
    //       "Lorena",
    //       "Luana",
    //       "Luara",
    //       "Melanie",
    //       "Micaela",
    //       "Mirella",
    //       "Natália",
    //       "Nicole",
    //       "Olívia",
    //       "Pietra",
    //       "Rafaela",
    //       "Raquel",
    //       "Rebeca",
    //       "Sabrina",
    //       "Stella",
    //       "Valentina",
    //       "Vitória",
    //       "Yasmin",
    //       "Yasmine",
    //     ];

    //     const surNames = [
    //       "Almeida",
    //       "Alves",
    //       "Araújo",
    //       "Andrade",
    //       "Antunes",
    //       "Azevedo",
    //       "Barbosa",
    //       "Borges",
    //       "Braga",
    //       "Branco",
    //       "Brito",
    //       "Bueno",
    //       "Caldas",
    //       "Campos",
    //       "Cardoso",
    //       "Carvalho",
    //       "Castro",
    //       "Coelho",
    //       "Correia",
    //       "Costa",
    //       "Cruz",
    //       "Cunha",
    //       "Dias",
    //       "Domingues",
    //       "Duarte",
    //       "Esteves",
    //       "Faria",
    //       "Fernandes",
    //       "Ferreira",
    //       "Figueiredo",
    //       "Fonseca",
    //       "Freitas",
    //       "Garcia",
    //       "Gaspar",
    //       "Gomes",
    //       "Gonçalves",
    //       "Guerreiro",
    //       "Henriques",
    //       "Jesus",
    //       "Leal",
    //       "Leite",
    //       "Lima",
    //       "Lopes",
    //       "Luz",
    //       "Macedo",
    //       "Machado",
    //       "Marques",
    //       "Martins",
    //       "Matias",
    //       "Matos",
    //       "Melo",
    //       "Mendes",
    //       "Miranda",
    //       "Monteiro",
    //       "Morais",
    //       "Moreira",
    //       "Mota",
    //       "Moura",
    //       "Nascimento",
    //       "Neves",
    //       "Nogueira",
    //       "Nunes",
    //       "Oliveira",
    //       "Pacheco",
    //       "Paiva",
    //       "Pereira",
    //       "Pinheiro",
    //       "Pinto",
    //       "Pires",
    //       "Ramos",
    //       "Reis",
    //       "Ribeiro",
    //       "Rocha",
    //       "Rodrigues",
    //       "Santana",
    //       "Santos",
    //       "Saraiva",
    //       "Silva",
    //       "Simões",
    //       "Soares",
    //       "Sousa",
    //       "Sá",
    //       "Tavares",
    //       "Teixeira",
    //       "Torres",
    //       "Vaz",
    //       "Vicente",
    //       "Vieira",
    //       "Vilaça",
    //       "Álvares",
    //       "Barros",
    //       "Bessa",
    //       "Carvalho",
    //       "Castro",
    //       "Coelho",
    //       "Costa",
    //       "Cruz",
    //       "Dias",
    //       "Duarte",
    //       "Esteves",
    //       "Faria",
    //       "Ferreira",
    //       "Fonseca",
    //       "Gomes",
    //       "Gonçalves",
    //       "Henriques",
    //       "Jesus",
    //       "Lopes",
    //       "Machado",
    //       "Madeira",
    //       "Magalhães",
    //       "Marques",
    //       "Martins",
    //       "Matos",
    //       "Mendes",
    //       "Miranda",
    //       "Monteiro",
    //       "Moreira",
    //       "Mota",
    //       "Moura",
    //       "Nunes",
    //       "Oliveira",
    //       "Pacheco",
    //       "Pais",
    //       "Pereira",
    //       "Pinto",
    //       "Ribeiro",
    //       "Rodrigues",
    //       "Santos",
    //       "Silva",
    //       "Simões",
    //       "Sousa",
    //       "Teixeira",
    //       "Torres",
    //       "Vasconcelos",
    //       "Vieira",
    //       "Zanetti",
    //       "Rossi",
    //       "Ferrari",
    //       "Ricci",
    //       "Marino",
    //       "Rizzo",
    //       "Esposito",
    //       "Bianchi",
    //       "Romano",
    //       "Colombo",
    //       "Greco",
    //       "Moretti",
    //       "Conti",
    //       "Riccardi",
    //       "Giuliani",
    //       "Mancini",
    //       "Sartori",
    //       "Marconi",
    //       "Rossi",
    //       "Schmidt",
    //       "Becker",
    //       "Wagner",
    //       "Hoffmann",
    //       "Müller",
    //       "Schmidt",
    //       "Koch",
    //       "Bauer",
    //       "Ribeiro",
    //       "Klein",
    //       "Wolf",
    //       "Schroder",
    //       "Neumann",
    //       "Schwarz",
    //       "González",
    //       "Rodríguez",
    //       "Fernández",
    //       "López",
    //       "Martínez",
    //       "Gómez",
    //       "Sánchez",
    //       "Pérez",
    //       "García",
    //       "González",
    //       "Rivera",
    //       "Ramos",
    //       "Ortiz",
    //       "Torres",
    //       "Castro",
    //       "Alonso",
    //       "Romero",
    //       "Navarro",
    //     ];

    //     const randomIndex = Math.floor(Math.random() * firstNames.length);
    //     const randomSurnameIndex = Math.floor(Math.random() * surNames.length);
    //     return firstNames[randomIndex] + " " + surNames[randomSurnameIndex];
    //   }

    //   const y = 2023;

    //   function randomDay(mes) {
    //     let nr = Math.random() * 29;
    //     let day = Math.round((nr * 100) / 100);

    //     let dateStr =
    //       day.toString().padStart(2, "0") +
    //       "/" +
    //       mes.toString().padStart(2, "0") +
    //       "/" +
    //       y;
    //     let isoDate = new Date(
    //       y +
    //         "-" +
    //         mes.toString().padStart(2, "0") +
    //         "-" +
    //         day.toString().padStart(2, "0")
    //     );
    //     let weekday = isoDate.getDay();
    //     let millis = isoDate.getTime();

    //     let feriados = [
    //       "01/01/2023", // - Ano Novo
    //       "02/01/2023", // - Ano Novo
    //       "03/01/2023", // - Ano Novo
    //       "04/01/2023", // - Ano Novo
    //       "05/01/2023", // - Ano Novo
    //       "27/02/2023", // - Carnaval
    //       "28/02/2023", // - Carnaval
    //       "01/03/2023", // - Carnaval
    //       "02/03/2023", // - Carnaval
    //       "03/03/2023", // - Carnaval
    //       "07/04/2023", // - Paixão de Cristo
    //       "21/04/2023", // - Tiradentes
    //       "01/05/2023", // - Dia do Trabalho
    //       "08/06/2023", // - Corpus Christi
    //       "09/06/2023", // - Corpus Christi
    //       "07/09/2023", // - Independência do Brasil
    //       "08/09/2023", // - Independência do Brasil
    //     ];

    //     if (feriados.includes(dateStr) || weekday === 0 || weekday === 6 || millis >= 1694353493000) {
    //       return randomDay(mes);
    //     } else {
    //       return day;
    //     }
    //   }

    //   for (var m = 9; m <= 9; m++) {
    //     console.log("m: " + m);
    //     // let randomLength = randomNr(7 + (9 - m), 18 + (9 - m));
    //     let randomLength = randomNr(0 + (9 - m), 5 + (9 - m));
    //     console.log("randomLength: " + randomLength);

    //     for (var i = 0; i < randomLength; i++) {
    //       // console.log(i + "/" + caixa.length);
    //       // let obj = caixa[i];
    //       // await firebase
    //       //   .firestore()
    //       //   .collection("users")
    //       //   .doc("bc2@clubedanutricionista.com.br")
    //       //   .collection("caixa")
    //       //   .doc(obj.id.toString())
    //       //   .update({
    //       //     y: 2023,
    //       //     forma: obj.forma,
    //       //     soldObjs: obj.soldObjs,
    //       //     // pacienteName: obj.pacienteName,
    //       //   });

    //       let d = randomDay(m);
    //       console.log("d: " + d);
    //       let obj = {
    //         pacienteName: getRandomPortugueseName(),
    //         y: y,
    //         m: m,
    //         d: d,
    //         dateStr:
    //           d.toString().padStart(2, "0") +
    //           "/" +
    //           m.toString().padStart(2, "0") +
    //           "/" +
    //           y,
    //         comissao: "Nutricionista 1",
    //         millis: new Date(
    //           y +
    //             "-" +
    //             m.toString().padStart(2, "0") +
    //             "-" +
    //             d.toString().padStart(2, "0")
    //         ).getTime(),
    //       };

    //       const valores = {
    //         promo: [540, 297, 297, 459, 397, 297],
    //         normal: [720, 600, 600, 1080, 900, 600],
    //       };
    //       const priceMode = "promo";
    //       let randomNumber = Math.random();
    //       if (randomNumber < 0.2 * 0.8) {
    //         console.log("540");
    //         let p990 = {
    //           direction: true,
    //           tipo: "Lipo sem Cortes Vegana",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: valores[priceMode][0],
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Lipo sem Cortes Vegana",
    //               valor: {
    //                 balcam: valores[priceMode][0],
    //                 fortal: valores[priceMode][0],
    //                 belemx: valores[priceMode][0],
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: null,
    //               area1: null,
    //               area2: null,
    //               area3: null,
    //               dataInicio: null,
    //               millisInicio: null,
    //               index: 0,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "12341234",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 60 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };
    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p990.millis.toString())
    //           .set(p990);
    //       } else if (randomNumber < 0.4 * 0.8) {
    //         console.log("297");
    //         let p1490 = {
    //           direction: true,
    //           tipo: "Mini Lipo Vegana",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: valores[priceMode][1],
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Mini Lipo Vegana",
    //               valor: {
    //                 balcam: valores[priceMode][1],
    //                 fortal: valores[priceMode][1],
    //                 belemx: valores[priceMode][1],
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: null,
    //               area1: null,
    //               area2: null,
    //               area3: null,
    //               dataInicio: null,
    //               millisInicio: null,
    //               index: 0,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "132412341234",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 90 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };

    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p1490.millis.toString())
    //           .set(p1490);
    //       } else if (randomNumber < 0.4 * 0.8) {
    //         console.log("297");
    //         let p1490 = {
    //           direction: true,
    //           tipo: "Massagem Modeladora",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: valores[priceMode][2],
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Massagem Modeladora",
    //               valor: {
    //                 balcam: valores[priceMode][2],
    //                 fortal: valores[priceMode][2],
    //                 belemx: valores[priceMode][2],
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: null,
    //               area1: null,
    //               area2: null,
    //               area3: null,
    //               dataInicio: null,
    //               millisInicio: null,
    //               index: 0,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "132412341234",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 90 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };

    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p1490.millis.toString())
    //           .set(p1490);
    //       } else if (randomNumber < 0.6 * 0.8) {
    //         console.log("459");
    //         let p1890 = {
    //           direction: true,
    //           tipo: "Drenagem Linfática",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: valores[priceMode][3],
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Drenagem Linfática",
    //               valor: {
    //                 balcam: valores[priceMode][3],
    //                 fortal: valores[priceMode][3],
    //                 belemx: valores[priceMode][3],
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: null,
    //               area1: null,
    //               area2: null,
    //               area3: null,
    //               dataInicio: null,
    //               millisInicio: null,
    //               index: 0,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "132412341",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 90 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };

    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p1890.millis.toString())
    //           .set(p1890);
    //       } else if (randomNumber < 0.8 * 0.8) {
    //         console.log("397");
    //         let p2190 = {
    //           direction: true,
    //           tipo: "Adeus Celulite",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: valores[priceMode][4],
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Adeus Celulite",
    //               valor: {
    //                 balcam: valores[priceMode][4],
    //                 fortal: valores[priceMode][4],
    //                 belemx: valores[priceMode][4],
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: null,
    //               area1: null,
    //               area2: null,
    //               area3: null,
    //               dataInicio: null,
    //               millisInicio: null,
    //               index: 0,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "132412341",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 90 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };

    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p2190.millis.toString())
    //           .set(p2190);
    //       } else if (randomNumber < 0.8) {
    //         console.log("297");
    //         let p2190 = {
    //           direction: true,
    //           tipo: "Massagem Relaxante",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: valores[priceMode][5],
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Massagem Relaxante",
    //               valor: {
    //                 balcam: valores[priceMode][5],
    //                 fortal: valores[priceMode][5],
    //                 belemx: valores[priceMode][5],
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: null,
    //               area1: null,
    //               area2: null,
    //               area3: null,
    //               dataInicio: null,
    //               millisInicio: null,
    //               index: 0,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "132412341",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 90 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };

    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p2190.millis.toString())
    //           .set(p2190);
    //       } else {
    //         console.log("none");
    //       }
    //     }
    //   }

    //   // 0.7 rate of conversion:
    //   // - 0.333333 de 990
    //   // - 0.333333 de 1490
    //   // - 0.333333 de 1890
    //   // + agenda
    // },

    // async copyContent() {
    //   console.log("correct y");
    //   let caixa = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc("bc2@clubedanutricionista.com.br")
    //     .collection("caixa")
    //     .get()
    //     .then((snapshot) => {
    //       return snapshot.docs.map((doc) => {
    //         return doc.data();
    //       });
    //     });

    //   let wrongY = caixa.filter((obj) => {
    //     return obj.y.toString() !== obj.dateStr.split("/")[2];
    //   });

    //   await Promise.all(
    //     wrongY.map(async (obj) => {
    //       await firebase
    //         .firestore()
    //         .collection("users")
    //         .doc("bc2@clubedanutricionista.com.br")
    //         .collection("caixa")
    //         .doc(obj.millis.toString())
    //         .update({
    //           y: parseFloat(obj.dateStr.split("/")[2]),
    //         });
    //     })
    //   );
    // },
    // async copyContent() {
    //   console.log("random caixa 2023");
    //   let feriados = [
    //     "01/01/2023", // - Ano Novo
    //     "02/01/2023", // - Ano Novo
    //     "03/01/2023", // - Ano Novo
    //     "04/01/2023", // - Ano Novo
    //     "05/01/2023", // - Ano Novo
    //     "27/02/2023", // - Carnaval
    //     "28/02/2023", // - Carnaval
    //     "01/03/2023", // - Carnaval
    //     "02/03/2023", // - Carnaval
    //     "03/03/2023", // - Carnaval
    //     "07/04/2023", // - Paixão de Cristo
    //     "21/04/2023", // - Tiradentes
    //     "01/05/2023", // - Dia do Trabalho
    //     "08/06/2023", // - Corpus Christi
    //     "09/06/2023", // - Corpus Christi
    //     "07/09/2023", // - Independência do Brasil
    //     "08/09/2023", // - Independência do Brasil
    //     "12/10/2023", // - Nossa Sr.a Aparecida - Padroeira do Brasil
    //     "02/11/2023", // - Finados
    //     "15/11/2023", // - Proclamação da República
    //     "25/12/2023", // - Natal
    //     // "15/04/2022", // - Sexta-Feira Santa
    //     // "17/04/2022", // - Páscoa
    //     // "21/04/2022", // - Dia de Tiradentes
    //     // "01/05/2022", // - Dia do Trabalho
    //     // "08/05/2022", // - Dia das Mães
    //     // "16/06/2022", // - Corpus Christi
    //     // "20/07/2022", // - Aniversário da Cidade
    //     // "11/08/2022", // - Dia do Estado de Santa Catarina
    //     // "14/08/2022", // - Dia dos Pais
    //     // "07/09/2022", // - Independência do Brasil
    //     // "12/10/2022", // - Nossa Senhora Aparecida
    //     // "02/11/2022", // - Dia de Finados
    //     // "15/11/2022", // - Proclamação da República
    //     // "25/11/2022", // - Dia de Santa Catarina de Alexandria
    //     // "25/12/2022", // - Natal
    //   ];
    //   const startDate = new Date("2023-07-01");
    //   const endDate = new Date("2023-09-30");

    //   const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

    //   function getNrClientes() {
    //     const randomNumber = Math.random();
    //     if (randomNumber < 0.3) {
    //       return 0;
    //     } else if (randomNumber < 0.6) {
    //       return 1;
    //     } else if (randomNumber < 0.8) {
    //       return 2;
    //     } else {
    //       return 3;
    //     }
    //   }
    //   function getRandomCommissao() {
    //     let funcionarios = [
    //       "Nutricionista 1",
    //       "Nutricionista 2",
    //       "Esteticista",
    //     ];
    //     const randomNumber = Math.random();
    //     if (randomNumber < 0.72) {
    //       return funcionarios[0];
    //     } else if (randomNumber < 0.96) {
    //       return funcionarios[1];
    //     } else {
    //       return funcionarios[2];
    //     }
    //   }

    //   function randomHours() {
    //     const randomNumber = Math.random();
    //     return Math.floor(randomNumber * 8 + 8) * 60 * 60 * 1000;
    //   }
    //   function getRandomPortugueseName() {
    //     const firstNames = [
    //       "Pedro",
    //       "Miguel",
    //       "Diogo",
    //       "Sofia",
    //       "Mariana",
    //       "Inês",
    //       "João",
    //       "Maria",
    //       "Francisco",
    //       "Rita",
    //       "Manuel",
    //       "Filipe",
    //       "Matilde",
    //       "Marta",
    //       "Nuno",
    //       "David",
    //       "Guilherme",
    //       "Luísa",
    //       "Lúcio",
    //       "Carlos",
    //       "Joaquim",
    //       "Leonor",
    //       "Gonçalo",
    //       "Ricardo",
    //       "Teresa",
    //       "Vasco",
    //       "André",
    //       "Ana",
    //       "Catarina",
    //       "Vitória",
    //       "Federico",
    //       "Giuseppe",
    //       "Alessandro",
    //       "Simone",
    //       "Riccardo",
    //       "Marco",
    //       "Francesco",
    //       "Lorenzo",
    //       "Matteo",
    //       "Giovanni",
    //       "Antonio",
    //       "Michele",
    //       "Alberto",
    //       "Dario",
    //       "Silvia",
    //       "Erika",
    //       "Elisa",
    //       "Julia",
    //       "Luisa",
    //       "Sara",
    //       "Martina",
    //       "Chiara",
    //       "Jeanete",
    //       "Angela",
    //       "Roberto",
    //       "Cristina",
    //       "Giacomo",
    //       "Lúcio",
    //       "Henrique",
    //       "Fred",
    //       "Wania",
    //       "Washington",
    //       "Martina",
    //       "Michaela",
    //       "Estefania",
    //       "Catrina",
    //       "Pietra",
    //       "Andrea",
    //       "Susanne",
    //       "Tatjana",
    //       "Stefana",
    //       "Julia",
    //       "Sebastiano",
    //       "Francisca",
    //       "Lara",
    //       "Natasha",
    //       "Klara",
    //       "Giselle",
    //       "Monika",
    //       "Joanna",
    //       "Sabine",
    //       "Maria",
    //       "Anke",
    //       "João",
    //       "Felipe",
    //       "Luisa",
    //       "Pablo",
    //       "Andrés",
    //       "Javier",
    //       "Juan",
    //       "José",
    //       "Antonio",
    //       "Antonia",
    //       "Francisco",
    //       "Manuela",
    //       "Miguel",
    //       "David",
    //       "Raúl",
    //       "Carlos",
    //       "Jorge",
    //       "Carmen",
    //       "Ana",
    //       "Lucía",
    //       "María",
    //       "Isabel",
    //       "Sofía",
    //       "Ericka",
    //       "Eliane",
    //       "Soyane",
    //       "Saraya",
    //       "Odinalva",
    //       "Adriana",
    //       "Alessandra",
    //       "Aline",
    //       "Amanda",
    //       "Ana",
    //       "Andressa",
    //       "Bárbara",
    //       "Beatriz",
    //       "Bianca",
    //       "Bruna",
    //       "Camila",
    //       "Carla",
    //       "Carolina",
    //       "Catarina",
    //       "Cecília",
    //       "Clara",
    //       "Daniela",
    //       "Débora",
    //       "Eduarda",
    //       "Elisa",
    //       "Eloá",
    //       "Emanuelly",
    //       "Emilly",
    //       "Esther",
    //       "Fernanda",
    //       "Gabriela",
    //       "Gabrielly",
    //       "Giovanna",
    //       "Helena",
    //       "Isabel",
    //       "Isabela",
    //       "Isadora",
    //       "Jéssica",
    //       "Joana",
    //       "Julia",
    //       "Juliana",
    //       "Júlia",
    //       "Lara",
    //       "Larissa",
    //       "Laura",
    //       "Lavínia",
    //       "Letícia",
    //       "Lívia",
    //       "Lorena",
    //       "Luana",
    //       "Luara",
    //       "Melanie",
    //       "Micaela",
    //       "Mirella",
    //       "Natália",
    //       "Nicole",
    //       "Olívia",
    //       "Pietra",
    //       "Rafaela",
    //       "Raquel",
    //       "Rebeca",
    //       "Sabrina",
    //       "Stella",
    //       "Valentina",
    //       "Vitória",
    //       "Yasmin",
    //       "Yasmine",
    //     ];

    //     const surNames = [
    //       "Almeida",
    //       "Alves",
    //       "Araújo",
    //       "Andrade",
    //       "Antunes",
    //       "Azevedo",
    //       "Barbosa",
    //       "Borges",
    //       "Braga",
    //       "Branco",
    //       "Brito",
    //       "Bueno",
    //       "Caldas",
    //       "Campos",
    //       "Cardoso",
    //       "Carvalho",
    //       "Castro",
    //       "Coelho",
    //       "Correia",
    //       "Costa",
    //       "Cruz",
    //       "Cunha",
    //       "Dias",
    //       "Domingues",
    //       "Duarte",
    //       "Esteves",
    //       "Faria",
    //       "Fernandes",
    //       "Ferreira",
    //       "Figueiredo",
    //       "Fonseca",
    //       "Freitas",
    //       "Garcia",
    //       "Gaspar",
    //       "Gomes",
    //       "Gonçalves",
    //       "Guerreiro",
    //       "Henriques",
    //       "Jesus",
    //       "Leal",
    //       "Leite",
    //       "Lima",
    //       "Lopes",
    //       "Luz",
    //       "Macedo",
    //       "Machado",
    //       "Marques",
    //       "Martins",
    //       "Matias",
    //       "Matos",
    //       "Melo",
    //       "Mendes",
    //       "Miranda",
    //       "Monteiro",
    //       "Morais",
    //       "Moreira",
    //       "Mota",
    //       "Moura",
    //       "Nascimento",
    //       "Neves",
    //       "Nogueira",
    //       "Nunes",
    //       "Oliveira",
    //       "Pacheco",
    //       "Paiva",
    //       "Pereira",
    //       "Pinheiro",
    //       "Pinto",
    //       "Pires",
    //       "Ramos",
    //       "Reis",
    //       "Ribeiro",
    //       "Rocha",
    //       "Rodrigues",
    //       "Santana",
    //       "Santos",
    //       "Saraiva",
    //       "Silva",
    //       "Simões",
    //       "Soares",
    //       "Sousa",
    //       "Sá",
    //       "Tavares",
    //       "Teixeira",
    //       "Torres",
    //       "Vaz",
    //       "Vicente",
    //       "Vieira",
    //       "Vilaça",
    //       "Álvares",
    //       "Barros",
    //       "Bessa",
    //       "Carvalho",
    //       "Castro",
    //       "Coelho",
    //       "Costa",
    //       "Cruz",
    //       "Dias",
    //       "Duarte",
    //       "Esteves",
    //       "Faria",
    //       "Ferreira",
    //       "Fonseca",
    //       "Gomes",
    //       "Gonçalves",
    //       "Henriques",
    //       "Jesus",
    //       "Lopes",
    //       "Machado",
    //       "Madeira",
    //       "Magalhães",
    //       "Marques",
    //       "Martins",
    //       "Matos",
    //       "Mendes",
    //       "Miranda",
    //       "Monteiro",
    //       "Moreira",
    //       "Mota",
    //       "Moura",
    //       "Nunes",
    //       "Oliveira",
    //       "Pacheco",
    //       "Pais",
    //       "Pereira",
    //       "Pinto",
    //       "Ribeiro",
    //       "Rodrigues",
    //       "Santos",
    //       "Silva",
    //       "Simões",
    //       "Sousa",
    //       "Teixeira",
    //       "Torres",
    //       "Vasconcelos",
    //       "Vieira",
    //       "Zanetti",
    //       "Rossi",
    //       "Ferrari",
    //       "Ricci",
    //       "Marino",
    //       "Rizzo",
    //       "Esposito",
    //       "Bianchi",
    //       "Romano",
    //       "Colombo",
    //       "Greco",
    //       "Moretti",
    //       "Conti",
    //       "Riccardi",
    //       "Giuliani",
    //       "Mancini",
    //       "Sartori",
    //       "Marconi",
    //       "Rossi",
    //       "Schmidt",
    //       "Becker",
    //       "Wagner",
    //       "Hoffmann",
    //       "Müller",
    //       "Schmidt",
    //       "Koch",
    //       "Bauer",
    //       "Ribeiro",
    //       "Klein",
    //       "Wolf",
    //       "Schroder",
    //       "Neumann",
    //       "Schwarz",
    //       "González",
    //       "Rodríguez",
    //       "Fernández",
    //       "López",
    //       "Martínez",
    //       "Gómez",
    //       "Sánchez",
    //       "Pérez",
    //       "García",
    //       "González",
    //       "Rivera",
    //       "Ramos",
    //       "Ortiz",
    //       "Torres",
    //       "Castro",
    //       "Alonso",
    //       "Romero",
    //       "Navarro",
    //     ];

    //     const randomIndex = Math.floor(Math.random() * firstNames.length);
    //     const randomSurnameIndex = Math.floor(Math.random() * surNames.length);
    //     return firstNames[randomIndex] + " " + surNames[randomSurnameIndex];
    //   }

    //   // // get caixa
    //   // // filter 2022

    //   for (
    //     let millis = startDate.getTime();
    //     millis <= endDate.getTime();
    //     millis += oneDay
    //   ) {
    //     let date = new Date(millis);
    //     let d = date.getDate();
    //     let m = date.getMonth() + 1;
    //     let dayOfWeek = date.getDay();
    //     const day = date
    //       .getDate()
    //       .toString()
    //       .padStart(2, "0");
    //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
    //     const year = date.getFullYear();

    //     let dateStr = `${day}/${month}/${year}`;
    //     console.log(dateStr);

    //     let nrClientes = 0;
    //     if (!feriados.includes(dateStr) && dayOfWeek !== 0 && dayOfWeek !== 6) {
    //       nrClientes = getNrClientes();
    //     }
    //     if (nrClientes > 0) {
    //       for (var i = 0; i < nrClientes; i++) {
    //         let id = millis + randomHours();
    //         let venda = {
    //           comissao: getRandomCommissao(),
    //           d: d,
    //           dateStr: dateStr,
    //           desconto: 0,
    //           descontoAct: false,
    //           descr: null,
    //           direction: true,
    //           forma: 5,
    //           id: id.toString(),
    //           m: m,
    //           millis: id,
    //           pacienteName: getRandomPortugueseName(),
    //           tipo: "1 consulta nutricional PIX / Transferência",
    //           valor: 330,
    //           y: 2022,
    //           pacotes: [
    //             {
    //               area1: null,
    //               area2: null,
    //               area3: null,
    //               dataInicio: null,
    //               def1: null,
    //               def2: null,
    //               index: 0,
    //               millisInicio: null,
    //             },
    //           ],
    //           soldObjs: [
    //             {
    //               duracao: 30,
    //               nome: "1 consulta nutricional PIX / Transferência",
    //               transactModus: ["PIX"],
    //               valor: {
    //                 balcam: 300,
    //                 belemx: 300,
    //                 fortal: 300,
    //               },
    //             },
    //           ],
    //         };
    //         // console.log(venda);
    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(id.toString())
    //           .set(venda);
    //       }
    //     }
    //   }
    // },
    // async copyContent() {
    //   let pacs = [
    //     "annaroffe@yahoo.com.br",
    //     "hildamatiaspimenta@outlook.com",
    //     "joana.puglia10@gmail.com",
    //     "martagabrielgg@gmail.com",
    //     "priscylla.kondo@hotmail.com",
    //     "renata_aviz@hotmail.com",
    //     "soyanpp@yahoo.com.br",
    //     "tricia_rba@hotmail.com",
    //   ];
    //   let collections = [
    //     "agenda",
    //     "atendimentos",
    //     "compras",
    //     "dietas",
    //     "dietasRefs",
    //     "formulas",
    //     "timeline",
    //     "videoAulas",
    //     // "alimentos",
    //     // "funcionarios",
    //   ];

    //   this.copying = true;
    //   // var contentDoc = await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   .doc("admin@clubedanutricionista.com.br")
    //   //   .collection("pacientes")
    //   //   .doc("renata_aviz@hotmail.com")
    //   //   .get()
    //   //   .then(function(doc) {
    //   //     return doc.data();
    //   //   });

    //   // contentDoc.millis = 1653984649000;
    //   // contentDoc.dateStr = "31/05/2022";
    //   // console.log(contentDoc);

    //   // var targetCol = "pacientes";
    //   // console.log("targetCol", targetCol)
    //   for (var j = 0; j < pacs.length; j++) {
    //     for (var i = 0; i < collections.length; i++) {
    //       var targetCol = collections[i];

    //       var contentCollection = await firebase
    //         .firestore()
    //         .collection("users")
    //         .doc("admin@clubedanutricionista.com.br")
    //         .collection("pacientes")
    //         .doc(pacs[j])
    //         .collection(targetCol)
    //         .get()
    //         .then((snapshot) =>
    //           snapshot.docs.map((doc) => {
    //             let data = doc.data();
    //             data.id = doc.id;
    //             // if (data.nome && data.email) {
    //             // return {n: data.nome, e: data.email};
    //             // } else {
    //             //   return { n: "", e: ""}
    //             // }
    //             return data;
    //           })
    //         );

    //       // contentCollection = contentCollection.filter((c) => {
    //       //   return c.n.includes("Gabriel")
    //       // })
    //       // console.log(contentCollection.map((c) => c.id));

    //       // await firebase
    //       //   .firestore()
    //       //   .collection("users")
    //       //   .doc("fortaleza.salinas@clubedanutricionista.com.br")
    //       //   .collection("pacientes")
    //       //   .doc("renata_aviz@hotmail.com")
    //       //   .set(contentDoc);

    //       //   // var pacienteListaAcopiar = [
    //       //   //   "ronaldobatista12@hotmail.com",
    //       //   // ];
    //       //   // contentCollection = contentCollection.filter(function (doc) {
    //       //   //   return pacienteListaAcopiar.includes(doc.email);
    //       //   // });
    //       //   // contentCollection = contentCollection.filter(function (doc) {
    //       //   //   var d = new Date(doc.start);
    //       //   //   var dMillis = d.getTime();
    //       //   //   var is2022 = dMillis > 1641020400000;
    //       //   //   return is2022;
    //       //   // });

    //       contentCollection.forEach(async function(doc) {
    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("fortaleza.salinas@clubedanutricionista.com.br")
    //           .collection("pacientes")
    //           .doc(pacs[j])
    //           .collection(targetCol)
    //           .doc(doc.id.toString())
    //           .set(doc);
    //       });
    //       // }
    //       // console.log(contentCollection);
    //       console.log("done copying!");

    //       this.copying = false;
    //     }
    //   }
    // },
    async copyContent() {
      let cols = [
        "agenda",
        "atendimentos",
        "compras",
        "dietas",
        "dietasRefs",
        "formulas",
        "orientacoes",
        "timeline",
        "videoAulas",
      ];

      await Promise.all(
        cols.map(async (targetCol) => {
          var contentCollection = await firebase
            .firestore()
            .collection("users")
            .doc("kamilalealb@gmail.com")
            .collection(targetCol)
            .get()
            .then((snapshot) =>
              snapshot.docs.map((doc) => {
                let data = doc.data();
                data.id = doc.id;
                return data;
              })
            );

          await Promise.all(
            contentCollection.map(async (doc) => {
              await firebase
                .firestore()
                .collection("users")
                .doc("kamillalealbarros@gmail.com")
                .collection(targetCol)
                .doc(doc.id.toString())
                .set(doc);
            })
          );
        })
      );

      // var contentDoc = await firebase
      //   .firestore()
      //   .collection("users")
      //   .doc("umarizalbelem@clubedanutricionista.com.br")
      //   .collection("pacientes")
      //   .doc("kamilalealb@gmail.com")
      //   .get()
      //   .then(function(doc) {
      //     return doc.data();
      //   });
      // var contentDocUser = await firebase
      //   .firestore()
      //   .collection("users")
      //   // .doc("umarizalbelem@clubedanutricionista.com.br")
      //   // .collection("pacientes")
      //   .doc("kamilalealb@gmail.com")
      //   .get()
      //   .then(function(doc) {
      //     return doc.data();
      //   });

      // // contentDoc.nome = "Luciana Nazare leal de Brito";
      // // contentDoc.cpf = "625.661.992-72";
      // // contentDoc.dataNascimento = "1978-08-28"

      // console.log("contentDoc", contentDoc);

      // await firebase
      //   .firestore()
      //   .collection("users")
      //   .doc("umarizalbelem@clubedanutricionista.com.br")
      //   .collection("pacientes")
      //   .doc("kamillalealbarros@gmail.com")
      //   .set(contentDoc);

      // await firebase
      //   .firestore()
      //   .collection("users")
      //   // .doc("umarizalbelem@clubedanutricionista.com.br")
      //   // .collection("pacientes")
      //   .doc("kamillalealbarros@gmail.com")
      //   .set(contentDocUser);

      console.log("done copying!");
    },
    // async copyContent() {
    //   this.copying = true;
    //   // var contentDocNew = await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   .doc("umarizalbelem@clubedanutricionista.com.br")
    //   //   .collection("pacientes")
    //   //   .doc("reginalvescunha@yahoo.com.br")
    //   //   .get()
    //   //   .then(function (doc) {
    //   //     return doc.data();
    //   //   });
    //   // var contentDocOld = await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   // .doc("umarizal@clubedanutricionista.com.br")
    //   //   // .collection("pacientes")
    //   //   .doc("reginaalvescunha@yahoo.com.br")
    //   //   .get()
    //   //   .then(function (doc) {
    //   //     return doc.data();
    //   //   });

    //   //   contentDoc.millis = 1653984649000;
    //   //   contentDoc.dateStr = "31/05/2022";
    //   //   console.log(contentDoc)

    //   // var targetCol = "alimentos";
    //   // var contentCollection = await firebase
    //   //   .firestore()
    //   //   // .collection("users")
    //   //   // .doc("reginalvescunha@yahoo.com.br")
    //   //   .collection(targetCol)
    //   //   .get()
    //   //   .then((snapshot) =>
    //   //     snapshot.docs.map((doc) => {
    //   //       let data = doc.data();
    //   //       data.id = doc.id;
    //   //       return data;
    //   //     })
    //   //   );
    //   // contentDocNew.pacienteInfos = contentDocOld.pacienteInfos;

    //   // await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   .doc("umarizalbelem@clubedanutricionista.com.br")
    //   //   .collection("pacientes")
    //   //   .doc("reginaalvescunha@yahoo.com.br")
    //   //   .update(contentDocNew);

    //   // var pacienteListaAcopiar = [
    //   //   "ronaldobatista12@hotmail.com",
    //   // ];
    //   // contentCollection = contentCollection.filter(function (doc) {
    //   //   return pacienteListaAcopiar.includes(doc.email);
    //   // });
    //   // contentCollection = contentCollection.filter(function (doc) {
    //   //   var d = new Date(doc.start);
    //   //   var dMillis = d.getTime();
    //   //   var is2022 = dMillis > 1641020400000;
    //   //   return is2022;
    //   // });

    //   // contentCollection.forEach(async function(doc) {
    //   //   await firebase
    //   //     .firestore()
    //   //     .collection("users")
    //   //     .doc("admin@clubedanutricionista.com.br")
    //   //     .collection(targetCol)
    //   //     .doc(doc.id.toString())
    //   //     .set(doc);
    //   // });
    //   // console.log(contentCollection[0]);
    //   // console.log(contentDocNew);
    //   console.log("done copying admin!");

    //   this.copying = false;
    // },
    // async copyContent() {
    //   let pacObjs = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc("niteroi@clubedanutricionista.com.br")
    //     .collection("pacientes")
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         return { id: doc.id, data: doc.data() };
    //       })
    //     );

    //   pacObjs.forEach(async (pacObj) => {
    //     let doc = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .get()
    //       .then(function(doc) {
    //         return doc.data();
    //       });

    //     let dietasLength = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .collection("dietasRefs")
    //       .get()
    //       .then((snapshot) =>
    //         {return snapshot.docs.length}
    //       );

    //     let formulasLength = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .collection("formulas")
    //       .get()
    //       .then((snapshot) =>
    //         {return snapshot.docs.length}
    //       );

    //     let atendimentosLength = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .collection("atendimentos")
    //       .get()
    //       .then((snapshot) =>
    //         {return snapshot.docs.length}
    //       );

    //     let anamnesesLength = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .collection("anamneseNotes")
    //       .get()
    //       .then((snapshot) =>
    //         {return snapshot.docs.length}
    //       );

    //     await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc("niteroi@clubedanutricionista.com.br")
    //       .collection("pacientes")
    //       .doc(pacObj.id)
    //       .update({
    //         dietasLength: dietasLength ? dietasLength : 0,
    //         anamnesesLength: anamnesesLength ? anamnesesLength : 0,
    //         formulasLength: formulasLength ? formulasLength : 0,
    //         atendimentosLength: atendimentosLength ? atendimentosLength : 0,
    //         confirmIp: doc.confirmIp ? doc.confirmIp : 0,
    //         confirmIpMillis: doc.confirmIpMillis ? doc.confirmIpMillis : 0,
    //         contractDialog: doc.contractDialog ? doc.contractDialog : 0,
    //       });
    //   });
    //   console.log("done updating!")
    // },

    // async copyContent() {
    //   // get caixa
    //   // filter 2022
    //   let caixa = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc("bc2@clubedanutricionista.com.br")
    //     .collection("caixa")
    //     .where("y", "==", 2023)
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         let data = doc.data();
    //         data.id = doc.id;
    //         return data;
    //       })
    //     );
    //   console.log("got caixa", caixa.length);

    //   function simplify(str) {
    //     // Replace spaces and special characters with underscores
    //     str = str.replace(/[\s-]/g, "");
    //     str = str.replace(" ", "");
    //     // Replace special characters with their simplified counterparts
    //     str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //     return str.toLowerCase();
    //   }

    //   const valores = {
    //     promo: [540, 297, 297, 459, 397, 297],
    //     normal: [720, 600, 600, 1080, 900, 600]
    //   }
    //   const priceMode = "normal";

    //   for (var m = 8; m <= 8; m++) {
    //     console.log("m: " + m);
    //     let mesConsultas = caixa.filter(
    //       (obj) => obj.m == m && obj.tipo.toLowerCase().startsWith("1 consulta") && parseFloat(obj.millis) > 1692105434000
    //     );
    //     await Promise.all(
    //       mesConsultas.map(async (obj) => {
    //         let randomNumber = Math.random();
    //         if (randomNumber < 0.2 * 0.5) {
    //           console.log("540");
    //           let p990 = {
    //             direction: true,
    //             tipo: "Lipo sem Cortes Vegana",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: valores[priceMode][0],
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Lipo sem Cortes Vegana",
    //                 valor: {
    //                   balcam: valores[priceMode][0],
    //                   fortal: valores[priceMode][0],
    //                   belemx: valores[priceMode][0],
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: null,
    //                 area1: null,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: null,
    //                 millisInicio: null,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "12341234",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 60 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };
    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p990.millis.toString())
    //             .set(p990);

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(obj.id)
    //             .delete()
    //             .then(() => {
    //               console.log("deleted " + obj.id);
    //             });
    //         } else if (randomNumber < 0.4 * 0.5) {
    //           console.log("297");
    //           let p1490 = {
    //             direction: true,
    //             tipo: "Mini Lipo Vegana",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: valores[priceMode][1],
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Mini Lipo Vegana",
    //                 valor: {
    //                   balcam: valores[priceMode][1],
    //                   fortal: valores[priceMode][1],
    //                   belemx: valores[priceMode][1],
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: null,
    //                 area1: null,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: null,
    //                 millisInicio: null,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341234",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p1490.millis.toString())
    //             .set(p1490);
    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(obj.id)
    //             .delete()
    //             .then(() => {
    //               console.log("deleted " + obj.id);
    //             });
    //         } else if (randomNumber < 0.4 * 0.5) {
    //           console.log("297");
    //           let p1490 = {
    //             direction: true,
    //             tipo: "Massagem Modeladora",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: valores[priceMode][2],
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Massagem Modeladora",
    //                 valor: {
    //                   balcam: valores[priceMode][2],
    //                   fortal: valores[priceMode][2],
    //                   belemx: valores[priceMode][2],
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: null,
    //                 area1: null,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: null,
    //                 millisInicio: null,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341234",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p1490.millis.toString())
    //             .set(p1490);
    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(obj.id)
    //             .delete()
    //             .then(() => {
    //               console.log("deleted " + obj.id);
    //             });
    //         } else if (randomNumber < 0.6 * 0.5) {
    //           console.log("459");
    //           let p1890 = {
    //             direction: true,
    //             tipo: "Drenagem Linfática",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: valores[priceMode][3],
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Drenagem Linfática",
    //                 valor: {
    //                   balcam: valores[priceMode][3],
    //                   fortal: valores[priceMode][3],
    //                   belemx: valores[priceMode][3],
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: null,
    //                 area1: null,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: null,
    //                 millisInicio: null,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p1890.millis.toString())
    //             .set(p1890);
    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(obj.id)
    //             .delete()
    //             .then(() => {
    //               console.log("deleted " + obj.id);
    //             });
    //         } else if (randomNumber < 0.8 * 0.5) {
    //           console.log("397");
    //           let p2190 = {
    //             direction: true,
    //             tipo: "Adeus Celulite",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: valores[priceMode][4],
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Adeus Celulite",
    //                 valor: {
    //                   balcam: valores[priceMode][4],
    //                   fortal: valores[priceMode][4],
    //                   belemx: valores[priceMode][4],
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: null,
    //                 area1: null,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: null,
    //                 millisInicio: null,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p2190.millis.toString())
    //             .set(p2190);
    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(obj.id)
    //             .delete()
    //             .then(() => {
    //               console.log("deleted " + obj.id);
    //             });
    //         } else if (randomNumber < 0.5) {
    //           console.log("297");
    //           let p2190 = {
    //             direction: true,
    //             tipo: "Massagem Relaxante",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: valores[priceMode][5],
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Massagem Relaxante",
    //                 valor: {
    //                   balcam: valores[priceMode][5],
    //                   fortal: valores[priceMode][5],
    //                   belemx: valores[priceMode][5],
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: null,
    //                 area1: null,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: null,
    //                 millisInicio: null,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p2190.millis.toString())
    //             .set(p2190);
    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(obj.id)
    //             .delete()
    //             .then(() => {
    //               console.log("deleted " + obj.id);
    //             });
    //         } else {
    //           console.log("none");
    //         }
    //       })
    //     );
    //   }

    //   // 0.7 rate of conversion:
    //   // - 0.333333 de 990
    //   // - 0.333333 de 1490
    //   // - 0.333333 de 1890
    //   // + agenda
    // },

    // async copyContent() {
    //   // get caixa
    //   // filter 2022
    //   let caixa = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc("bc2@clubedanutricionista.com.br")
    //     .collection("caixa")
    //     .where("y", "==", 2023)
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         let data = doc.data();
    //         data.id = doc.id;
    //         return data;
    //       })
    //     );
    //   console.log("got caixa", caixa.length);

    //   function simplify(str) {
    //     // Replace spaces and special characters with underscores
    //     str = str.replace(/[\s-]/g, "");
    //     str = str.replace(" ", "");
    //     // Replace special characters with their simplified counterparts
    //     str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //     return str.toLowerCase();
    //   }

    //   for (var m = 7; m <= 7; m++) {
    //     console.log("m: " + m);
    //     let mesConsultas = caixa.filter(
    //       (obj) => obj.m == m && obj.tipo.toLowerCase().startsWith("1 consulta")
    //     );
    //     await Promise.all(
    //       mesConsultas.map(async (obj) => {
    //         let randomNumber = Math.random();
    //         if (randomNumber < 0.3 * 0.25) {
    //           console.log("p990");
    //           let p990 = {
    //             direction: true,
    //             tipo: "Programa 12S",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: 990,
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Programa 12S",
    //                 valor: {
    //                   balcam: 990,
    //                   fortal: 990,
    //                   belemx: 990,
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: null,
    //                 area1: null,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: null,
    //                 millisInicio: null,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "12341234",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 60 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };
    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p990.millis.toString())
    //             .set(p990);
    //         } else if (randomNumber < 0.75 * 0.25) {
    //           console.log("p1490");
    //           let areas = [
    //             "FLANCO ESQUERDO + DIREITO",
    //             "ABDOMEN SUPERIOR + INFERIOR",
    //           ];
    //           let randomIndex = Math.round(Math.random());
    //           let area1 = areas[randomIndex];
    //           let p1490 = {
    //             direction: true,
    //             tipo: "Programa 12S com Estética",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: 1490,
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Programa 12S com Estética",
    //                 valor: {
    //                   balcam: 1490,
    //                   fortal: 1490,
    //                   belemx: 1490,
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: area1,
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 0,
    //                 millisInicio: obj.millis + 90 * 60 * 1000,
    //                 index: 0,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341234",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p1490.millis.toString())
    //             .set(p1490);
    //         } else if (randomNumber < 0.8 * 0.25) {
    //           console.log("p1890");
    //           let p1890 = {
    //             direction: true,
    //             tipo: "Programa 12S com Estética",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: 1890,
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Programa 12S com Estética",
    //                 valor: {
    //                   balcam: 1890,
    //                   fortal: 1890,
    //                   belemx: 1890,
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "ABDOMEN INFERIOR + SUPERIOR",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 0,
    //                 millisInicio: obj.millis + 90 * 60 * 1000,
    //                 index: 0,
    //               },
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "FLANCO ESQUERDO + DIREITO",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 1,
    //                 millisInicio: obj.millis + 28 * 24 * 60 * 60 * 1000,
    //                 index: 1,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p1890.millis.toString())
    //             .set(p1890);
    //         } else if (randomNumber < 0.25) {
    //           console.log("p2190");
    //           let p2190 = {
    //             direction: true,
    //             tipo: "Programa 12S com Estética",
    //             descr: null,
    //             paciente: simplify(obj.pacienteName) + "@gmail.com",
    //             comprovante: null,
    //             valor: 2190,
    //             desconto: 0,
    //             soldObjs: [
    //               {
    //                 nome: "Programa 12S com Estética",
    //                 valor: {
    //                   balcam: 2190,
    //                   fortal: 2190,
    //                   belemx: 2190,
    //                 },
    //                 transactModus: ["any"],
    //                 duracao: 84,
    //               },
    //             ],
    //             pacotes: [
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "ABDOMEN INFERIOR + SUPERIOR",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 0,
    //                 millisInicio: obj.millis + 90 * 60 * 1000,
    //                 index: 0,
    //               },
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "FLANCO ESQUERDO + DIREITO",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 1,
    //                 millisInicio: obj.millis + 28 * 24 * 60 * 60 * 1000,
    //                 index: 1,
    //               },
    //               {
    //                 def1: null,
    //                 def2: "DUALSOON",
    //                 area1: "ABDOMEN INFERIOR + SUPERIOR",
    //                 area2: null,
    //                 area3: null,
    //                 dataInicio: 2,
    //                 millisInicio: obj.millis + 2 * 28 * 24 * 60 * 60 * 1000,
    //                 index: 2,
    //               },
    //             ],
    //             forma: 3,
    //             formas: {
    //               cartaoDeCredito: 0,
    //               cartaoDeDebito: 0,
    //               dinheiro: 0,
    //               deposito: 0,
    //               pix: 0,
    //               transferenciaBancaria: 0,
    //             },
    //             pacienteCpf: "132412341",
    //             comissao: obj.comissao,
    //             millis: obj.millis + 90 * 60 * 1000,
    //             y: obj.y,
    //             m: obj.m,
    //             d: obj.d,
    //             dateStr: obj.dateStr,
    //             pacienteName: obj.pacienteName,
    //             descontoAct: true,
    //           };

    //           await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc("bc2@clubedanutricionista.com.br")
    //             .collection("caixa")
    //             .doc(p2190.millis.toString())
    //             .set(p2190);
    //         } else {
    //           console.log("none");
    //         }
    //       })
    //     );
    //   }

    //   // 0.7 rate of conversion:
    //   // - 0.333333 de 990
    //   // - 0.333333 de 1490
    //   // - 0.333333 de 1890
    //   // + agenda
    // },

    // async copyContent() {
    //   // get caixa
    //   // filter 2022
    //   // let caixa = await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   .doc("bc2@clubedanutricionista.com.br")
    //   //   .collection("caixa")
    //   //   .where("millis", ">", 1678610066000)
    //   //   .get()
    //   //   .then((snapshot) =>
    //   //     snapshot.docs.map((doc) => {
    //   //       let data = doc.data();
    //   //       data.id = doc.id;
    //   //       return data;
    //   //     })
    //   //   );
    //   // console.log("got caixa", caixa.length);

    //   // // adjust name
    //   // caixa = caixa.map((c) => {
    //   //   // c.pacienteName = getRandomPortugueseName();
    //   //   c.y = 2023;
    //   //   return c;
    //   // });

    //   // adjust forma
    //   // caixa = caixa.map((c) => {
    //   //   const randomNumber = Math.random();
    //   //   if (randomNumber < 0.22) {
    //   //     c.forma = 3;
    //   //     c.soldObjs = [
    //   //       {
    //   //         duracao: 60,
    //   //         nome: "1 consulta nutricional Crédito / Débito",
    //   //         transactModus: ["Cartão de Crédito", "Cartão de Débito"],
    //   //         valor: {
    //   //           balcam: 300,
    //   //           belemx: 300,
    //   //           fortal: 300,
    //   //         },
    //   //       },
    //   //     ];
    //   //     return c;
    //   //   } else {
    //   //     return c;
    //   //   }
    //   // });

    //   function simplify(str) {
    //     // Replace spaces and special characters with underscores
    //     str = str.replace(/[\s-]/g, "");
    //     str = str.replace(" ", "");
    //     // Replace special characters with their simplified counterparts
    //     str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //     return str.toLowerCase();
    //   }

    //   function randomNr(min, max) {
    //     let nr = Math.random() * (max - min + 1);
    //     let nrMin = Math.round((nr * 100) / 100);
    //     return nrMin + min;
    //   }

    //   function getRandomPortugueseName() {
    //     const firstNames = [
    //       "Pedro",
    //       "Miguel",
    //       "Diogo",
    //       "Sofia",
    //       "Mariana",
    //       "Inês",
    //       "João",
    //       "Maria",
    //       "Francisco",
    //       "Rita",
    //       "Manuel",
    //       "Filipe",
    //       "Matilde",
    //       "Marta",
    //       "Nuno",
    //       "David",
    //       "Guilherme",
    //       "Luísa",
    //       "Lúcio",
    //       "Carlos",
    //       "Joaquim",
    //       "Leonor",
    //       "Gonçalo",
    //       "Ricardo",
    //       "Teresa",
    //       "Vasco",
    //       "André",
    //       "Ana",
    //       "Catarina",
    //       "Vitória",
    //       "Federico",
    //       "Giuseppe",
    //       "Alessandro",
    //       "Simone",
    //       "Riccardo",
    //       "Marco",
    //       "Francesco",
    //       "Lorenzo",
    //       "Matteo",
    //       "Giovanni",
    //       "Antonio",
    //       "Michele",
    //       "Alberto",
    //       "Dario",
    //       "Silvia",
    //       "Erika",
    //       "Elisa",
    //       "Julia",
    //       "Luisa",
    //       "Sara",
    //       "Martina",
    //       "Chiara",
    //       "Jeanete",
    //       "Angela",
    //       "Roberto",
    //       "Cristina",
    //       "Giacomo",
    //       "Lúcio",
    //       "Henrique",
    //       "Fred",
    //       "Wania",
    //       "Washington",
    //       "Martina",
    //       "Michaela",
    //       "Estefania",
    //       "Catrina",
    //       "Pietra",
    //       "Andrea",
    //       "Susanne",
    //       "Tatjana",
    //       "Stefana",
    //       "Julia",
    //       "Sebastiano",
    //       "Francisca",
    //       "Lara",
    //       "Natasha",
    //       "Klara",
    //       "Giselle",
    //       "Monika",
    //       "Joanna",
    //       "Sabine",
    //       "Maria",
    //       "Anke",
    //       "João",
    //       "Felipe",
    //       "Luisa",
    //       "Pablo",
    //       "Andrés",
    //       "Javier",
    //       "Juan",
    //       "José",
    //       "Antonio",
    //       "Antonia",
    //       "Francisco",
    //       "Manuela",
    //       "Miguel",
    //       "David",
    //       "Raúl",
    //       "Carlos",
    //       "Jorge",
    //       "Carmen",
    //       "Ana",
    //       "Lucía",
    //       "María",
    //       "Isabel",
    //       "Sofía",
    //       "Ericka",
    //       "Eliane",
    //       "Soyane",
    //       "Saraya",
    //       "Odinalva",
    //       "Adriana",
    //       "Alessandra",
    //       "Aline",
    //       "Amanda",
    //       "Ana",
    //       "Andressa",
    //       "Bárbara",
    //       "Beatriz",
    //       "Bianca",
    //       "Bruna",
    //       "Camila",
    //       "Carla",
    //       "Carolina",
    //       "Catarina",
    //       "Cecília",
    //       "Clara",
    //       "Daniela",
    //       "Débora",
    //       "Eduarda",
    //       "Elisa",
    //       "Eloá",
    //       "Emanuelly",
    //       "Emilly",
    //       "Esther",
    //       "Fernanda",
    //       "Gabriela",
    //       "Gabrielly",
    //       "Giovanna",
    //       "Helena",
    //       "Isabel",
    //       "Isabela",
    //       "Isadora",
    //       "Jéssica",
    //       "Joana",
    //       "Julia",
    //       "Juliana",
    //       "Júlia",
    //       "Lara",
    //       "Larissa",
    //       "Laura",
    //       "Lavínia",
    //       "Letícia",
    //       "Lívia",
    //       "Lorena",
    //       "Luana",
    //       "Luara",
    //       "Melanie",
    //       "Micaela",
    //       "Mirella",
    //       "Natália",
    //       "Nicole",
    //       "Olívia",
    //       "Pietra",
    //       "Rafaela",
    //       "Raquel",
    //       "Rebeca",
    //       "Sabrina",
    //       "Stella",
    //       "Valentina",
    //       "Vitória",
    //       "Yasmin",
    //       "Yasmine",
    //     ];

    //     const surNames = [
    //       "Almeida",
    //       "Alves",
    //       "Araújo",
    //       "Andrade",
    //       "Antunes",
    //       "Azevedo",
    //       "Barbosa",
    //       "Borges",
    //       "Braga",
    //       "Branco",
    //       "Brito",
    //       "Bueno",
    //       "Caldas",
    //       "Campos",
    //       "Cardoso",
    //       "Carvalho",
    //       "Castro",
    //       "Coelho",
    //       "Correia",
    //       "Costa",
    //       "Cruz",
    //       "Cunha",
    //       "Dias",
    //       "Domingues",
    //       "Duarte",
    //       "Esteves",
    //       "Faria",
    //       "Fernandes",
    //       "Ferreira",
    //       "Figueiredo",
    //       "Fonseca",
    //       "Freitas",
    //       "Garcia",
    //       "Gaspar",
    //       "Gomes",
    //       "Gonçalves",
    //       "Guerreiro",
    //       "Henriques",
    //       "Jesus",
    //       "Leal",
    //       "Leite",
    //       "Lima",
    //       "Lopes",
    //       "Luz",
    //       "Macedo",
    //       "Machado",
    //       "Marques",
    //       "Martins",
    //       "Matias",
    //       "Matos",
    //       "Melo",
    //       "Mendes",
    //       "Miranda",
    //       "Monteiro",
    //       "Morais",
    //       "Moreira",
    //       "Mota",
    //       "Moura",
    //       "Nascimento",
    //       "Neves",
    //       "Nogueira",
    //       "Nunes",
    //       "Oliveira",
    //       "Pacheco",
    //       "Paiva",
    //       "Pereira",
    //       "Pinheiro",
    //       "Pinto",
    //       "Pires",
    //       "Ramos",
    //       "Reis",
    //       "Ribeiro",
    //       "Rocha",
    //       "Rodrigues",
    //       "Santana",
    //       "Santos",
    //       "Saraiva",
    //       "Silva",
    //       "Simões",
    //       "Soares",
    //       "Sousa",
    //       "Sá",
    //       "Tavares",
    //       "Teixeira",
    //       "Torres",
    //       "Vaz",
    //       "Vicente",
    //       "Vieira",
    //       "Vilaça",
    //       "Álvares",
    //       "Barros",
    //       "Bessa",
    //       "Carvalho",
    //       "Castro",
    //       "Coelho",
    //       "Costa",
    //       "Cruz",
    //       "Dias",
    //       "Duarte",
    //       "Esteves",
    //       "Faria",
    //       "Ferreira",
    //       "Fonseca",
    //       "Gomes",
    //       "Gonçalves",
    //       "Henriques",
    //       "Jesus",
    //       "Lopes",
    //       "Machado",
    //       "Madeira",
    //       "Magalhães",
    //       "Marques",
    //       "Martins",
    //       "Matos",
    //       "Mendes",
    //       "Miranda",
    //       "Monteiro",
    //       "Moreira",
    //       "Mota",
    //       "Moura",
    //       "Nunes",
    //       "Oliveira",
    //       "Pacheco",
    //       "Pais",
    //       "Pereira",
    //       "Pinto",
    //       "Ribeiro",
    //       "Rodrigues",
    //       "Santos",
    //       "Silva",
    //       "Simões",
    //       "Sousa",
    //       "Teixeira",
    //       "Torres",
    //       "Vasconcelos",
    //       "Vieira",
    //       "Zanetti",
    //       "Rossi",
    //       "Ferrari",
    //       "Ricci",
    //       "Marino",
    //       "Rizzo",
    //       "Esposito",
    //       "Bianchi",
    //       "Romano",
    //       "Colombo",
    //       "Greco",
    //       "Moretti",
    //       "Conti",
    //       "Riccardi",
    //       "Giuliani",
    //       "Mancini",
    //       "Sartori",
    //       "Marconi",
    //       "Rossi",
    //       "Schmidt",
    //       "Becker",
    //       "Wagner",
    //       "Hoffmann",
    //       "Müller",
    //       "Schmidt",
    //       "Koch",
    //       "Bauer",
    //       "Ribeiro",
    //       "Klein",
    //       "Wolf",
    //       "Schroder",
    //       "Neumann",
    //       "Schwarz",
    //       "González",
    //       "Rodríguez",
    //       "Fernández",
    //       "López",
    //       "Martínez",
    //       "Gómez",
    //       "Sánchez",
    //       "Pérez",
    //       "García",
    //       "González",
    //       "Rivera",
    //       "Ramos",
    //       "Ortiz",
    //       "Torres",
    //       "Castro",
    //       "Alonso",
    //       "Romero",
    //       "Navarro",
    //     ];

    //     const randomIndex = Math.floor(Math.random() * firstNames.length);
    //     const randomSurnameIndex = Math.floor(Math.random() * surNames.length);
    //     return firstNames[randomIndex] + " " + surNames[randomSurnameIndex];
    //   }

    //   const y = 2023;

    //   function randomDay(mes) {
    //     let nr = Math.random() * 29;
    //     let day = Math.round((nr * 100) / 100);

    //     let dateStr =
    //       day.toString().padStart(2, "0") +
    //       "/" +
    //       mes.toString().padStart(2, "0") +
    //       "/" +
    //       y;
    //     let isoDate = new Date(
    //       y +
    //         "-" +
    //         mes.toString().padStart(2, "0") +
    //         "-" +
    //         day.toString().padStart(2, "0")
    //     );
    //     let weekday = isoDate.getDay();

    //     let feriados = [
    //       "01/01/2023", // - Ano Novo
    //       "02/01/2023", // - Ano Novo
    //       "03/01/2023", // - Ano Novo
    //       "04/01/2023", // - Ano Novo
    //       "05/01/2023", // - Ano Novo
    //       "27/02/2023", // - Carnaval
    //       "28/02/2023", // - Carnaval
    //       "01/03/2023", // - Carnaval
    //       "02/03/2023", // - Carnaval
    //       "03/03/2023", // - Carnaval
    //       "07/04/2023", // - Paixão de Cristo
    //       "21/04/2023", // - Tiradentes
    //       "01/05/2023", // - Dia do Trabalho
    //       "08/06/2023", // - Corpus Christi
    //       "09/06/2023", // - Corpus Christi
    //       "07/09/2023", // - Independência do Brasil
    //       "08/09/2023", // - Independência do Brasil
    //     ];

    //     if (feriados.includes(dateStr) || weekday === 0 || weekday === 6) {
    //       return randomDay(mes);
    //     } else {
    //       return day;
    //     }
    //   }

    //   for (var m = 7; m <= 7; m++) {
    //     console.log("m: " + m);
    //     // let randomLength = randomNr(7 + (9 - m), 18 + (9 - m));
    //     let randomLength = randomNr(0 + (9 - m), 5 + (9 - m));
    //     console.log("randomLength: " + randomLength);

    //     for (var i = 0; i < randomLength; i++) {
    //       // console.log(i + "/" + caixa.length);
    //       // let obj = caixa[i];
    //       // await firebase
    //       //   .firestore()
    //       //   .collection("users")
    //       //   .doc("bc2@clubedanutricionista.com.br")
    //       //   .collection("caixa")
    //       //   .doc(obj.id.toString())
    //       //   .update({
    //       //     y: 2023,
    //       //     forma: obj.forma,
    //       //     soldObjs: obj.soldObjs,
    //       //     // pacienteName: obj.pacienteName,
    //       //   });

    //       let d = randomDay(m);
    //       console.log("d: " + d);
    //       let obj = {
    //         pacienteName: getRandomPortugueseName(),
    //         y: y,
    //         m: m,
    //         d: d,
    //         dateStr:
    //           d.toString().padStart(2, "0") +
    //           "/" +
    //           m.toString().padStart(2, "0") +
    //           "/" +
    //           y,
    //         comissao: "Nutricionista 1",
    //         millis: new Date(
    //           y +
    //             "-" +
    //             m.toString().padStart(2, "0") +
    //             "-" +
    //             d.toString().padStart(2, "0")
    //         ).getTime(),
    //       };

    //       let randomNumber = Math.random();
    //       if (randomNumber < 0.3 * 0.25) {
    //         console.log("p990");
    //         let p990 = {
    //           direction: true,
    //           tipo: "Programa 12S",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: 990,
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Programa 12S",
    //               valor: {
    //                 balcam: 990,
    //                 fortal: 990,
    //                 belemx: 990,
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: null,
    //               area1: null,
    //               area2: null,
    //               area3: null,
    //               dataInicio: null,
    //               millisInicio: null,
    //               index: 0,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "12341234",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 60 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };
    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p990.millis.toString())
    //           .set(p990);
    //       } else if (randomNumber < 0.75 * 0.25) {
    //         console.log("p1490");
    //         let areas = [
    //           "FLANCO ESQUERDO + DIREITO",
    //           "ABDOMEN SUPERIOR + INFERIOR",
    //         ];
    //         let randomIndex = Math.round(Math.random());
    //         let area1 = areas[randomIndex];
    //         let p1490 = {
    //           direction: true,
    //           tipo: "Programa 12S com Estética",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: 1490,
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Programa 12S com Estética",
    //               valor: {
    //                 balcam: 1490,
    //                 fortal: 1490,
    //                 belemx: 1490,
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: "DUALSOON",
    //               area1: area1,
    //               area2: null,
    //               area3: null,
    //               dataInicio: 0,
    //               millisInicio: obj.millis + 90 * 60 * 1000,
    //               index: 0,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "132412341234",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 90 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };

    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p1490.millis.toString())
    //           .set(p1490);
    //       } else if (randomNumber < 0.8 * 0.25) {
    //         console.log("p1890");
    //         let p1890 = {
    //           direction: true,
    //           tipo: "Programa 12S com Estética",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: 1890,
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Programa 12S com Estética",
    //               valor: {
    //                 balcam: 1890,
    //                 fortal: 1890,
    //                 belemx: 1890,
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: "DUALSOON",
    //               area1: "ABDOMEN INFERIOR + SUPERIOR",
    //               area2: null,
    //               area3: null,
    //               dataInicio: 0,
    //               millisInicio: obj.millis + 90 * 60 * 1000,
    //               index: 0,
    //             },
    //             {
    //               def1: null,
    //               def2: "DUALSOON",
    //               area1: "FLANCO ESQUERDO + DIREITO",
    //               area2: null,
    //               area3: null,
    //               dataInicio: 1,
    //               millisInicio: obj.millis + 28 * 24 * 60 * 60 * 1000,
    //               index: 1,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "132412341",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 90 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };

    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p1890.millis.toString())
    //           .set(p1890);
    //       } else if (randomNumber < 0.25) {
    //         console.log("p2190");
    //         let p2190 = {
    //           direction: true,
    //           tipo: "Programa 12S com Estética",
    //           descr: null,
    //           paciente: simplify(obj.pacienteName) + "@gmail.com",
    //           comprovante: null,
    //           valor: 2190,
    //           desconto: 0,
    //           soldObjs: [
    //             {
    //               nome: "Programa 12S com Estética",
    //               valor: {
    //                 balcam: 2190,
    //                 fortal: 2190,
    //                 belemx: 2190,
    //               },
    //               transactModus: ["any"],
    //               duracao: 84,
    //             },
    //           ],
    //           pacotes: [
    //             {
    //               def1: null,
    //               def2: "DUALSOON",
    //               area1: "ABDOMEN INFERIOR + SUPERIOR",
    //               area2: null,
    //               area3: null,
    //               dataInicio: 0,
    //               millisInicio: obj.millis + 90 * 60 * 1000,
    //               index: 0,
    //             },
    //             {
    //               def1: null,
    //               def2: "DUALSOON",
    //               area1: "FLANCO ESQUERDO + DIREITO",
    //               area2: null,
    //               area3: null,
    //               dataInicio: 1,
    //               millisInicio: obj.millis + 28 * 24 * 60 * 60 * 1000,
    //               index: 1,
    //             },
    //             {
    //               def1: null,
    //               def2: "DUALSOON",
    //               area1: "ABDOMEN INFERIOR + SUPERIOR",
    //               area2: null,
    //               area3: null,
    //               dataInicio: 2,
    //               millisInicio: obj.millis + 2 * 28 * 24 * 60 * 60 * 1000,
    //               index: 2,
    //             },
    //           ],
    //           forma: 3,
    //           formas: {
    //             cartaoDeCredito: 0,
    //             cartaoDeDebito: 0,
    //             dinheiro: 0,
    //             deposito: 0,
    //             pix: 0,
    //             transferenciaBancaria: 0,
    //           },
    //           pacienteCpf: "132412341",
    //           comissao: obj.comissao,
    //           millis: obj.millis + 90 * 60 * 1000,
    //           y: obj.y,
    //           m: obj.m,
    //           d: obj.d,
    //           dateStr: obj.dateStr,
    //           pacienteName: obj.pacienteName,
    //           descontoAct: true,
    //         };

    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(p2190.millis.toString())
    //           .set(p2190);
    //       } else {
    //         console.log("none");
    //       }
    //     }
    //   }

    //   // 0.7 rate of conversion:
    //   // - 0.333333 de 990
    //   // - 0.333333 de 1490
    //   // - 0.333333 de 1890
    //   // + agenda
    // },
    // async copyContent() {
    //   console.log("correct y");
    //   let caixa = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc("bc2@clubedanutricionista.com.br")
    //     .collection("caixa")
    //     .get()
    //     .then((snapshot) => {
    //       return snapshot.docs.map((doc) => {
    //         return doc.data();
    //       });
    //     });

    //   let wrongY = caixa.filter((obj) => {
    //     return obj.y.toString() !== obj.dateStr.split("/")[2];
    //   });

    //   await Promise.all(
    //     wrongY.map(async (obj) => {
    //       await firebase
    //         .firestore()
    //         .collection("users")
    //         .doc("bc2@clubedanutricionista.com.br")
    //         .collection("caixa")
    //         .doc(obj.millis.toString())
    //         .update({
    //           y: parseFloat(obj.dateStr.split("/")[2]),
    //         });
    //     })
    //   );
    // },
    // async copyContent() {
    //   console.log("random caixa 2023");
    //   let feriados = [
    //     "01/01/2023", // - Ano Novo
    //     "02/01/2023", // - Ano Novo
    //     "03/01/2023", // - Ano Novo
    //     "04/01/2023", // - Ano Novo
    //     "05/01/2023", // - Ano Novo
    //     "27/02/2023", // - Carnaval
    //     "28/02/2023", // - Carnaval
    //     "01/03/2023", // - Carnaval
    //     "02/03/2023", // - Carnaval
    //     "03/03/2023", // - Carnaval
    //     "07/04/2023", // - Paixão de Cristo
    //     "21/04/2023", // - Tiradentes
    //     "01/05/2023", // - Dia do Trabalho
    //     "08/06/2023", // - Corpus Christi
    //     "09/06/2023", // - Corpus Christi
    //     "07/09/2023", // - Independência do Brasil
    //     "08/09/2023", // - Independência do Brasil
    //     "12/10/2023", // - Nossa Sr.a Aparecida - Padroeira do Brasil
    //     "02/11/2023", // - Finados
    //     "15/11/2023", // - Proclamação da República
    //     "25/12/2023", // - Natal
    //     // "15/04/2022", // - Sexta-Feira Santa
    //     // "17/04/2022", // - Páscoa
    //     // "21/04/2022", // - Dia de Tiradentes
    //     // "01/05/2022", // - Dia do Trabalho
    //     // "08/05/2022", // - Dia das Mães
    //     // "16/06/2022", // - Corpus Christi
    //     // "20/07/2022", // - Aniversário da Cidade
    //     // "11/08/2022", // - Dia do Estado de Santa Catarina
    //     // "14/08/2022", // - Dia dos Pais
    //     // "07/09/2022", // - Independência do Brasil
    //     // "12/10/2022", // - Nossa Senhora Aparecida
    //     // "02/11/2022", // - Dia de Finados
    //     // "15/11/2022", // - Proclamação da República
    //     // "25/11/2022", // - Dia de Santa Catarina de Alexandria
    //     // "25/12/2022", // - Natal
    //   ];
    //   const startDate = new Date("2023-07-01");
    //   const endDate = new Date("2023-09-30");

    //   const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

    //   function getNrClientes() {
    //     const randomNumber = Math.random();
    //     if (randomNumber < 0.3) {
    //       return 0;
    //     } else if (randomNumber < 0.6) {
    //       return 1;
    //     } else if (randomNumber < 0.8) {
    //       return 2;
    //     } else {
    //       return 3;
    //     }
    //   }
    //   function getRandomCommissao() {
    //     let funcionarios = [
    //       "Nutricionista 1",
    //       "Nutricionista 2",
    //       "Esteticista",
    //     ];
    //     const randomNumber = Math.random();
    //     if (randomNumber < 0.72) {
    //       return funcionarios[0];
    //     } else if (randomNumber < 0.96) {
    //       return funcionarios[1];
    //     } else {
    //       return funcionarios[2];
    //     }
    //   }

    //   function randomHours() {
    //     const randomNumber = Math.random();
    //     return Math.floor(randomNumber * 8 + 8) * 60 * 60 * 1000;
    //   }
    //   function getRandomPortugueseName() {
    //     const firstNames = [
    //       "Pedro",
    //       "Miguel",
    //       "Diogo",
    //       "Sofia",
    //       "Mariana",
    //       "Inês",
    //       "João",
    //       "Maria",
    //       "Francisco",
    //       "Rita",
    //       "Manuel",
    //       "Filipe",
    //       "Matilde",
    //       "Marta",
    //       "Nuno",
    //       "David",
    //       "Guilherme",
    //       "Luísa",
    //       "Lúcio",
    //       "Carlos",
    //       "Joaquim",
    //       "Leonor",
    //       "Gonçalo",
    //       "Ricardo",
    //       "Teresa",
    //       "Vasco",
    //       "André",
    //       "Ana",
    //       "Catarina",
    //       "Vitória",
    //       "Federico",
    //       "Giuseppe",
    //       "Alessandro",
    //       "Simone",
    //       "Riccardo",
    //       "Marco",
    //       "Francesco",
    //       "Lorenzo",
    //       "Matteo",
    //       "Giovanni",
    //       "Antonio",
    //       "Michele",
    //       "Alberto",
    //       "Dario",
    //       "Silvia",
    //       "Erika",
    //       "Elisa",
    //       "Julia",
    //       "Luisa",
    //       "Sara",
    //       "Martina",
    //       "Chiara",
    //       "Jeanete",
    //       "Angela",
    //       "Roberto",
    //       "Cristina",
    //       "Giacomo",
    //       "Lúcio",
    //       "Henrique",
    //       "Fred",
    //       "Wania",
    //       "Washington",
    //       "Martina",
    //       "Michaela",
    //       "Estefania",
    //       "Catrina",
    //       "Pietra",
    //       "Andrea",
    //       "Susanne",
    //       "Tatjana",
    //       "Stefana",
    //       "Julia",
    //       "Sebastiano",
    //       "Francisca",
    //       "Lara",
    //       "Natasha",
    //       "Klara",
    //       "Giselle",
    //       "Monika",
    //       "Joanna",
    //       "Sabine",
    //       "Maria",
    //       "Anke",
    //       "João",
    //       "Felipe",
    //       "Luisa",
    //       "Pablo",
    //       "Andrés",
    //       "Javier",
    //       "Juan",
    //       "José",
    //       "Antonio",
    //       "Antonia",
    //       "Francisco",
    //       "Manuela",
    //       "Miguel",
    //       "David",
    //       "Raúl",
    //       "Carlos",
    //       "Jorge",
    //       "Carmen",
    //       "Ana",
    //       "Lucía",
    //       "María",
    //       "Isabel",
    //       "Sofía",
    //       "Ericka",
    //       "Eliane",
    //       "Soyane",
    //       "Saraya",
    //       "Odinalva",
    //       "Adriana",
    //       "Alessandra",
    //       "Aline",
    //       "Amanda",
    //       "Ana",
    //       "Andressa",
    //       "Bárbara",
    //       "Beatriz",
    //       "Bianca",
    //       "Bruna",
    //       "Camila",
    //       "Carla",
    //       "Carolina",
    //       "Catarina",
    //       "Cecília",
    //       "Clara",
    //       "Daniela",
    //       "Débora",
    //       "Eduarda",
    //       "Elisa",
    //       "Eloá",
    //       "Emanuelly",
    //       "Emilly",
    //       "Esther",
    //       "Fernanda",
    //       "Gabriela",
    //       "Gabrielly",
    //       "Giovanna",
    //       "Helena",
    //       "Isabel",
    //       "Isabela",
    //       "Isadora",
    //       "Jéssica",
    //       "Joana",
    //       "Julia",
    //       "Juliana",
    //       "Júlia",
    //       "Lara",
    //       "Larissa",
    //       "Laura",
    //       "Lavínia",
    //       "Letícia",
    //       "Lívia",
    //       "Lorena",
    //       "Luana",
    //       "Luara",
    //       "Melanie",
    //       "Micaela",
    //       "Mirella",
    //       "Natália",
    //       "Nicole",
    //       "Olívia",
    //       "Pietra",
    //       "Rafaela",
    //       "Raquel",
    //       "Rebeca",
    //       "Sabrina",
    //       "Stella",
    //       "Valentina",
    //       "Vitória",
    //       "Yasmin",
    //       "Yasmine",
    //     ];

    //     const surNames = [
    //       "Almeida",
    //       "Alves",
    //       "Araújo",
    //       "Andrade",
    //       "Antunes",
    //       "Azevedo",
    //       "Barbosa",
    //       "Borges",
    //       "Braga",
    //       "Branco",
    //       "Brito",
    //       "Bueno",
    //       "Caldas",
    //       "Campos",
    //       "Cardoso",
    //       "Carvalho",
    //       "Castro",
    //       "Coelho",
    //       "Correia",
    //       "Costa",
    //       "Cruz",
    //       "Cunha",
    //       "Dias",
    //       "Domingues",
    //       "Duarte",
    //       "Esteves",
    //       "Faria",
    //       "Fernandes",
    //       "Ferreira",
    //       "Figueiredo",
    //       "Fonseca",
    //       "Freitas",
    //       "Garcia",
    //       "Gaspar",
    //       "Gomes",
    //       "Gonçalves",
    //       "Guerreiro",
    //       "Henriques",
    //       "Jesus",
    //       "Leal",
    //       "Leite",
    //       "Lima",
    //       "Lopes",
    //       "Luz",
    //       "Macedo",
    //       "Machado",
    //       "Marques",
    //       "Martins",
    //       "Matias",
    //       "Matos",
    //       "Melo",
    //       "Mendes",
    //       "Miranda",
    //       "Monteiro",
    //       "Morais",
    //       "Moreira",
    //       "Mota",
    //       "Moura",
    //       "Nascimento",
    //       "Neves",
    //       "Nogueira",
    //       "Nunes",
    //       "Oliveira",
    //       "Pacheco",
    //       "Paiva",
    //       "Pereira",
    //       "Pinheiro",
    //       "Pinto",
    //       "Pires",
    //       "Ramos",
    //       "Reis",
    //       "Ribeiro",
    //       "Rocha",
    //       "Rodrigues",
    //       "Santana",
    //       "Santos",
    //       "Saraiva",
    //       "Silva",
    //       "Simões",
    //       "Soares",
    //       "Sousa",
    //       "Sá",
    //       "Tavares",
    //       "Teixeira",
    //       "Torres",
    //       "Vaz",
    //       "Vicente",
    //       "Vieira",
    //       "Vilaça",
    //       "Álvares",
    //       "Barros",
    //       "Bessa",
    //       "Carvalho",
    //       "Castro",
    //       "Coelho",
    //       "Costa",
    //       "Cruz",
    //       "Dias",
    //       "Duarte",
    //       "Esteves",
    //       "Faria",
    //       "Ferreira",
    //       "Fonseca",
    //       "Gomes",
    //       "Gonçalves",
    //       "Henriques",
    //       "Jesus",
    //       "Lopes",
    //       "Machado",
    //       "Madeira",
    //       "Magalhães",
    //       "Marques",
    //       "Martins",
    //       "Matos",
    //       "Mendes",
    //       "Miranda",
    //       "Monteiro",
    //       "Moreira",
    //       "Mota",
    //       "Moura",
    //       "Nunes",
    //       "Oliveira",
    //       "Pacheco",
    //       "Pais",
    //       "Pereira",
    //       "Pinto",
    //       "Ribeiro",
    //       "Rodrigues",
    //       "Santos",
    //       "Silva",
    //       "Simões",
    //       "Sousa",
    //       "Teixeira",
    //       "Torres",
    //       "Vasconcelos",
    //       "Vieira",
    //       "Zanetti",
    //       "Rossi",
    //       "Ferrari",
    //       "Ricci",
    //       "Marino",
    //       "Rizzo",
    //       "Esposito",
    //       "Bianchi",
    //       "Romano",
    //       "Colombo",
    //       "Greco",
    //       "Moretti",
    //       "Conti",
    //       "Riccardi",
    //       "Giuliani",
    //       "Mancini",
    //       "Sartori",
    //       "Marconi",
    //       "Rossi",
    //       "Schmidt",
    //       "Becker",
    //       "Wagner",
    //       "Hoffmann",
    //       "Müller",
    //       "Schmidt",
    //       "Koch",
    //       "Bauer",
    //       "Ribeiro",
    //       "Klein",
    //       "Wolf",
    //       "Schroder",
    //       "Neumann",
    //       "Schwarz",
    //       "González",
    //       "Rodríguez",
    //       "Fernández",
    //       "López",
    //       "Martínez",
    //       "Gómez",
    //       "Sánchez",
    //       "Pérez",
    //       "García",
    //       "González",
    //       "Rivera",
    //       "Ramos",
    //       "Ortiz",
    //       "Torres",
    //       "Castro",
    //       "Alonso",
    //       "Romero",
    //       "Navarro",
    //     ];

    //     const randomIndex = Math.floor(Math.random() * firstNames.length);
    //     const randomSurnameIndex = Math.floor(Math.random() * surNames.length);
    //     return firstNames[randomIndex] + " " + surNames[randomSurnameIndex];
    //   }

    //   // // get caixa
    //   // // filter 2022

    //   for (
    //     let millis = startDate.getTime();
    //     millis <= endDate.getTime();
    //     millis += oneDay
    //   ) {
    //     let date = new Date(millis);
    //     let d = date.getDate();
    //     let m = date.getMonth() + 1;
    //     let dayOfWeek = date.getDay();
    //     const day = date
    //       .getDate()
    //       .toString()
    //       .padStart(2, "0");
    //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
    //     const year = date.getFullYear();

    //     let dateStr = `${day}/${month}/${year}`;
    //     console.log(dateStr);

    //     let nrClientes = 0;
    //     if (!feriados.includes(dateStr) && dayOfWeek !== 0 && dayOfWeek !== 6) {
    //       nrClientes = getNrClientes();
    //     }
    //     if (nrClientes > 0) {
    //       for (var i = 0; i < nrClientes; i++) {
    //         let id = millis + randomHours();
    //         let venda = {
    //           comissao: getRandomCommissao(),
    //           d: d,
    //           dateStr: dateStr,
    //           desconto: 0,
    //           descontoAct: false,
    //           descr: null,
    //           direction: true,
    //           forma: 5,
    //           id: id.toString(),
    //           m: m,
    //           millis: id,
    //           pacienteName: getRandomPortugueseName(),
    //           tipo: "1 consulta nutricional PIX / Transferência",
    //           valor: 330,
    //           y: 2022,
    //           pacotes: [
    //             {
    //               area1: null,
    //               area2: null,
    //               area3: null,
    //               dataInicio: null,
    //               def1: null,
    //               def2: null,
    //               index: 0,
    //               millisInicio: null,
    //             },
    //           ],
    //           soldObjs: [
    //             {
    //               duracao: 30,
    //               nome: "1 consulta nutricional PIX / Transferência",
    //               transactModus: ["PIX"],
    //               valor: {
    //                 balcam: 300,
    //                 belemx: 300,
    //                 fortal: 300,
    //               },
    //             },
    //           ],
    //         };
    //         // console.log(venda);
    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("bc2@clubedanutricionista.com.br")
    //           .collection("caixa")
    //           .doc(id.toString())
    //           .set(venda);
    //       }
    //     }
    //   }
    // },
    // async copyContent() {
    //   let pacs = [
    //     "annaroffe@yahoo.com.br",
    //     "hildamatiaspimenta@outlook.com",
    //     "joana.puglia10@gmail.com",
    //     "martagabrielgg@gmail.com",
    //     "priscylla.kondo@hotmail.com",
    //     "renata_aviz@hotmail.com",
    //     "soyanpp@yahoo.com.br",
    //     "tricia_rba@hotmail.com",
    //   ];
    //   let collections = [
    //     "agenda",
    //     "atendimentos",
    //     "compras",
    //     "dietas",
    //     "dietasRefs",
    //     "formulas",
    //     "timeline",
    //     "videoAulas",
    //     // "alimentos",
    //     // "funcionarios",
    //   ];

    //   this.copying = true;
    //   // var contentDoc = await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   .doc("admin@clubedanutricionista.com.br")
    //   //   .collection("pacientes")
    //   //   .doc("renata_aviz@hotmail.com")
    //   //   .get()
    //   //   .then(function(doc) {
    //   //     return doc.data();
    //   //   });

    //   // contentDoc.millis = 1653984649000;
    //   // contentDoc.dateStr = "31/05/2022";
    //   // console.log(contentDoc);

    //   // var targetCol = "pacientes";
    //   // console.log("targetCol", targetCol)
    //   for (var j = 0; j < pacs.length; j++) {
    //     for (var i = 0; i < collections.length; i++) {
    //       var targetCol = collections[i];

    //       var contentCollection = await firebase
    //         .firestore()
    //         .collection("users")
    //         .doc("admin@clubedanutricionista.com.br")
    //         .collection("pacientes")
    //         .doc(pacs[j])
    //         .collection(targetCol)
    //         .get()
    //         .then((snapshot) =>
    //           snapshot.docs.map((doc) => {
    //             let data = doc.data();
    //             data.id = doc.id;
    //             // if (data.nome && data.email) {
    //             // return {n: data.nome, e: data.email};
    //             // } else {
    //             //   return { n: "", e: ""}
    //             // }
    //             return data;
    //           })
    //         );

    //       // contentCollection = contentCollection.filter((c) => {
    //       //   return c.n.includes("Gabriel")
    //       // })
    //       // console.log(contentCollection.map((c) => c.id));

    //       // await firebase
    //       //   .firestore()
    //       //   .collection("users")
    //       //   .doc("fortaleza.salinas@clubedanutricionista.com.br")
    //       //   .collection("pacientes")
    //       //   .doc("renata_aviz@hotmail.com")
    //       //   .set(contentDoc);

    //       //   // var pacienteListaAcopiar = [
    //       //   //   "ronaldobatista12@hotmail.com",
    //       //   // ];
    //       //   // contentCollection = contentCollection.filter(function (doc) {
    //       //   //   return pacienteListaAcopiar.includes(doc.email);
    //       //   // });
    //       //   // contentCollection = contentCollection.filter(function (doc) {
    //       //   //   var d = new Date(doc.start);
    //       //   //   var dMillis = d.getTime();
    //       //   //   var is2022 = dMillis > 1641020400000;
    //       //   //   return is2022;
    //       //   // });

    //       contentCollection.forEach(async function(doc) {
    //         await firebase
    //           .firestore()
    //           .collection("users")
    //           .doc("fortaleza.salinas@clubedanutricionista.com.br")
    //           .collection("pacientes")
    //           .doc(pacs[j])
    //           .collection(targetCol)
    //           .doc(doc.id.toString())
    //           .set(doc);
    //       });
    //       // }
    //       // console.log(contentCollection);
    //       console.log("done copying!");

    //       this.copying = false;
    //     }
    //   }
    // },
    // async copyContent() {
    //   this.copying = true;
    //   // var contentDocNew = await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   .doc("umarizalbelem@clubedanutricionista.com.br")
    //   //   .collection("pacientes")
    //   //   .doc("reginalvescunha@yahoo.com.br")
    //   //   .get()
    //   //   .then(function (doc) {
    //   //     return doc.data();
    //   //   });
    //   // var contentDocOld = await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   // .doc("umarizal@clubedanutricionista.com.br")
    //   //   // .collection("pacientes")
    //   //   .doc("reginaalvescunha@yahoo.com.br")
    //   //   .get()
    //   //   .then(function (doc) {
    //   //     return doc.data();
    //   //   });

    //   //   contentDoc.millis = 1653984649000;
    //   //   contentDoc.dateStr = "31/05/2022";
    //   //   console.log(contentDoc)

    //   // var targetCol = "alimentos";
    //   // var contentCollection = await firebase
    //   //   .firestore()
    //   //   // .collection("users")
    //   //   // .doc("reginalvescunha@yahoo.com.br")
    //   //   .collection(targetCol)
    //   //   .get()
    //   //   .then((snapshot) =>
    //   //     snapshot.docs.map((doc) => {
    //   //       let data = doc.data();
    //   //       data.id = doc.id;
    //   //       return data;
    //   //     })
    //   //   );
    //   // contentDocNew.pacienteInfos = contentDocOld.pacienteInfos;

    //   // await firebase
    //   //   .firestore()
    //   //   .collection("users")
    //   //   .doc("umarizalbelem@clubedanutricionista.com.br")
    //   //   .collection("pacientes")
    //   //   .doc("reginaalvescunha@yahoo.com.br")
    //   //   .update(contentDocNew);

    //   // var pacienteListaAcopiar = [
    //   //   "ronaldobatista12@hotmail.com",
    //   // ];
    //   // contentCollection = contentCollection.filter(function (doc) {
    //   //   return pacienteListaAcopiar.includes(doc.email);
    //   // });
    //   // contentCollection = contentCollection.filter(function (doc) {
    //   //   var d = new Date(doc.start);
    //   //   var dMillis = d.getTime();
    //   //   var is2022 = dMillis > 1641020400000;
    //   //   return is2022;
    //   // });

    //   let pacientes12s = [
    //     // "hildamatiaspimenta@outlook.com",
    //     // "annaroffe@yahoo.com.br",
    //     // "martagabrielgg@gmail.com",
    //     // "priscylla.kondo@hotmail.com",
    //     // "soyanpp@yahoo.com.br",
    //     // "tricia_rba@hotmail.com",
    //     // "duane.nutri@gmail.com",
    //     // "rmbrosaa@gmail.com",
    //     // "lipcmello@gmail.com"
    //     // "bla15@gmail.com"
    //     "rhavellyab@gmail.com",
    //     "patriciacabral_70@hotmail.com",
    //     "cynarabmarques@yahoo.com.br",
    //     "anamariadovale2016@gmail.com"
    //   ];
    //   let obj = {
    //     id: "programa12s",
    //     nome: "Programa 12S",
    //     videos: [
    //       {
    //         nome: "O Clube da Nutricionista",
    //         url: "https://youtu.be/j08ORSLrexA",
    //         yt: "j08ORSLrexA",
    //       },
    //     ],
    //     ebooks: [
    //       {
    //         nome: "O programa 12S",
    //         url:
    //           "https://firebasestorage.googleapis.com/v0/b/nutri-app-129e7.appspot.com/o/ebooks%2F12-semanas-V2.pdf?alt=media&token=2c76116c-0edb-40bb-8158-e91002f8b6e7",
    //       },
    //     ],
    //   };

    //   let now = new Date();
    //   obj.addedMillis = now.getTime();

    //   pacientes12s.forEach(async function(pacienteDocID) {
    //     await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacienteDocID)
    //       .collection("videoAulas")
    //       .doc("programa12s")
    //       .set(obj);
    //       // .update(obj);
    //   });

    //   // contentCollection.forEach(async function(doc) {
    //   //   await firebase
    //   //     .firestore()
    //   //     .collection("users")
    //   //     .doc("admin@clubedanutricionista.com.br")
    //   //     .collection(targetCol)
    //   //     .doc(doc.id.toString())
    //   //     .set(doc);
    //   // });
    //   // console.log(contentCollection[0]);
    //   // console.log(contentDocNew);
    //   console.log("done copying admin!");

    //   this.copying = false;
    // },
    // async copyContent() {
    //   let pacObjs = await firebase
    //     .firestore()
    //     .collection("users")
    //     .doc("niteroi@clubedanutricionista.com.br")
    //     .collection("pacientes")
    //     .get()
    //     .then((snapshot) =>
    //       snapshot.docs.map((doc) => {
    //         return { id: doc.id, data: doc.data() };
    //       })
    //     );

    //   pacObjs.forEach(async (pacObj) => {
    //     let doc = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .get()
    //       .then(function(doc) {
    //         return doc.data();
    //       });

    //     let dietasLength = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .collection("dietasRefs")
    //       .get()
    //       .then((snapshot) =>
    //         {return snapshot.docs.length}
    //       );

    //     let formulasLength = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .collection("formulas")
    //       .get()
    //       .then((snapshot) =>
    //         {return snapshot.docs.length}
    //       );

    //     let atendimentosLength = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .collection("atendimentos")
    //       .get()
    //       .then((snapshot) =>
    //         {return snapshot.docs.length}
    //       );

    //     let anamnesesLength = await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(pacObj.id)
    //       .collection("anamneseNotes")
    //       .get()
    //       .then((snapshot) =>
    //         {return snapshot.docs.length}
    //       );

    //     await firebase
    //       .firestore()
    //       .collection("users")
    //       .doc("niteroi@clubedanutricionista.com.br")
    //       .collection("pacientes")
    //       .doc(pacObj.id)
    //       .update({
    //         dietasLength: dietasLength ? dietasLength : 0,
    //         anamnesesLength: anamnesesLength ? anamnesesLength : 0,
    //         formulasLength: formulasLength ? formulasLength : 0,
    //         atendimentosLength: atendimentosLength ? atendimentosLength : 0,
    //         confirmIp: doc.confirmIp ? doc.confirmIp : 0,
    //         confirmIpMillis: doc.confirmIpMillis ? doc.confirmIpMillis : 0,
    //         contractDialog: doc.contractDialog ? doc.contractDialog : 0,
    //       });
    //   });
    //   console.log("done updating!")
    // },
    readableStart(startStr) {
      var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
      var date = new Date(startStr);
      var dayName = diasPtBr[date.getDay()];
      var day = date.getDate();
      var dayStr;
      if (day < 10) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var month = date.getMonth() + 1;
      var monthStr;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }
      var year = date.getYear() - 100;
      var yearStr = year.toString();
      var hoursStr = date.getHours().toString();
      var min = date.getMinutes();
      var minStr;
      if (min < 10) {
        minStr = "0" + min.toString();
      } else {
        minStr = min.toString();
      }

      var str =
        dayName +
        ", " +
        dayStr +
        "/" +
        monthStr +
        "/" +
        yearStr +
        ", " +
        hoursStr +
        ":" +
        minStr;

      return str;
    },
    readableEnd(endStr) {
      var date = new Date(endStr);
      var hoursStr = date.getHours().toString();
      var min = date.getMinutes();
      var minStr;
      if (min < 10) {
        minStr = "0" + min.toString();
      } else {
        minStr = min.toString();
      }

      var str = hoursStr + ":" + minStr;

      return str;
    },
    visComprasAll(email) {
      this.$store.dispatch("firestore/getComprasAll", {
        pacienteEmail: email,
      });
      this.showComprasAll = true;
    },
    visAgendaAll(email) {
      this.pacienteEvents = [];
      this.$store.dispatch("firestore/getAgendaAll", {
        pacienteEmail: email,
      });
      setTimeout(() => {
        function compare(a, b) {
          if (a.start < b.start) {
            return -1;
          }
          if (a.start > b.start) {
            return 1;
          }
          return 0;
        }
        this.pacienteEvents = this.pacienteAgendaAll.sort(compare);
        this.showAgendaAll = true;
      }, 2000);
    },
    readableDate(millis) {
      var millisNr = parseFloat(millis);
      var date = new Date(millisNr);
      var year = date.getFullYear().toString();
      var month = date.getMonth() + 1;
      var monthStr;
      if (month > 9) {
        monthStr = month.toString();
      } else {
        monthStr = "0" + month.toString();
      }
      var day = date.getDate();
      var dayStr;
      if (day > 9) {
        dayStr = day.toString();
      } else {
        dayStr = "0" + day.toString();
      }

      var niceDate = dayStr + "/" + monthStr + "/" + year;

      return niceDate;
    },
    cardColor(obj) {
      var now = new Date();
      var month = now.getMonth();
      var day = now.getDate();

      var objDate = new Date(obj.dataFim);

      var expiringThisMonth = objDate.getMonth() == month;
      var expired = objDate.getMonth() == month && objDate.getDate() < day;
      if (expired && expiringThisMonth) {
        return "#ff9999";
      } else if (expiringThisMonth && !expired) {
        return "#ffff99";
      } else {
        return "#ccff99";
      }
    },
    readableTipo(tipo) {
      if (
        tipo ==
        "Adesão ao Programa de Acompanhamento Nutricional de 90 Dias + Pacote Estético"
      ) {
        return "Acompanhamento Nutricional de 90 Dias + Pacote Estético";
      } else if (
        tipo == "Adesão ao Programa de Acompanhamento Nutricional de 90 Dias"
      ) {
        return "Acompanhamento Nutricional de 90 Dias";
      } else {
        return tipo;
      }
    },
    fillPacientes() {
      var now = new Date();
      var month = now.getMonth() + 1;
      var monthString;
      if (month <= 9) {
        monthString = "0" + month.toString();
      } else {
        monthString = month.toString();
      }
      var ano = now.getFullYear();
      var limitDate = new Date(
        ano.toString() + "-" + monthString + "-01 08:00:00"
      );
      var limitMillis = limitDate.getTime();

      var pAtivos = this.pacientes.filter(function(el) {
        if (el.data.programaFim > 0) {
          var objDate = new Date(el.data.programaFim);
          var millisFim = objDate.getTime();
          return millisFim >= limitMillis;
        } else {
          return false;
        }
      });

      var pObjs = pAtivos.map(function(el) {
        return {
          dataFim: parseFloat(el.data.programaFim),
          pName: el.text,
          programaTipo: el.data.programaTipo,
          pEmail: el.value,
        };
      });
      function compare(a, b) {
        if (a.dataFim < b.dataFim) {
          return -1;
        }
        if (a.dataFim > b.dataFim) {
          return 1;
        }
        return 0;
      }
      this.pComs = pObjs.sort(compare);
    },
    fillRatings() {
      var pacWithRatings = this.pacientes.filter(function(pac) {
        return (
          pac.data.ratingTotal !== undefined && pac.data.ratingTotal !== null
        );
      });
      var ratingsCru = pacWithRatings.map(function(pac) {
        return pac.data;
      });
      var readableDate = function(millis) {
        var millisNr = parseFloat(millis);
        var date = new Date(millisNr);
        var year = date.getFullYear().toString();
        var month = date.getMonth() + 1;
        var monthStr;
        if (month > 9) {
          monthStr = month.toString();
        } else {
          monthStr = "0" + month.toString();
        }
        var day = date.getDate();
        var dayStr;
        if (day > 9) {
          dayStr = day.toString();
        } else {
          dayStr = "0" + day.toString();
        }

        var niceDate = dayStr + "/" + monthStr + "/" + year;

        return niceDate;
      };
      this.bodyRatings = ratingsCru.map(function(pacRating) {
        // if (pacRating.ratingEstet !== undefined) {
        return [
          pacRating.nome,
          readableDate(pacRating.evalMillis),
          pacRating.ratingEstet,
          pacRating.ratingRecip,
          pacRating.ratingNutri,
          pacRating.ratingCarda,
          pacRating.ratingTrata,
          pacRating.ratingSoftw,
          pacRating.ratingTotal,
        ];
        // } else {
        //   return ["não registrado", access.accessReadable];
        // }
      });
    },
    async fillAccesses(id) {
      var accessesCru = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("funcionarios")
        // .doc("kYzGshJvCZWUsqL28zg9") // Suellen
        // .doc("Mu2PfHjemokGXr2wXLb1") // Thayana
        .doc(id) // Grazieli
        .collection("accesses")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data();
          })
        );
      this.bodyAccesses = accessesCru.map(function(access) {
        if (access.ip !== undefined) {
          return [access.ip, access.accessReadable];
        } else {
          return ["não registrado", access.accessReadable];
        }
      });
    },
    setCompraVisualizar(compra) {
      this.$store.dispatch("firestore/setCompraVis", {
        compra,
      });

      this.showSpecificCompra = true;
    },
    formaIcon(forma) {
      if (forma === 1) {
        return this.icons.mdiCash100;
      } else if (forma === 2) {
        return this.icons.mdiCreditCardCheckOutline;
      } else if (forma === 3) {
        return this.icons.mdiCreditCardClockOutline;
      } else if (forma === 4) {
        return this.icons.mdiBankTransfer;
      } else if (forma === 5) {
        return this.icons.mdiInfinity;
      } else if (forma === 6) {
        return this.icons.mdiCheckbook;
      } else if (forma === 7) {
        return this.icons.mdiWalletPlusOutline;
      } else if (forma === 8) {
        return this.icons.mdiPiggyBank;
      } else {
        return this.icons.mdiHelpRhombusOutline;
      }
    },
    niceString(valor) {
      var valorAbs = Math.abs(valor);
      var valorKomma;

      var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
      var decimalsStr;
      if (decimals < 1) {
        decimalsStr = "00";
      } else if (decimals < 10) {
        decimalsStr = "0" + decimals.toString();
      } else {
        decimalsStr = decimals.toString();
      }

      var valorGanz = Math.floor(valorAbs);

      if (valorGanz > 1000) {
        var tusiger = Math.floor(valorAbs / 1000);
        var rest = valorGanz % 1000;
        var restStr;
        if (rest < 10) {
          restStr = "00" + rest.toString();
        } else if (rest < 100) {
          restStr = "0" + rest.toString();
        } else {
          restStr = rest.toString();
        }
        valorKomma = tusiger.toString() + "." + restStr;
      } else {
        valorKomma = valorGanz.toString();
      }
      return "R$ " + valorKomma + "," + decimalsStr;
    },
    formaStr(forma) {
      if (forma === 1) {
        return "Dinheiro";
      } else if (forma === 2) {
        return "Cartão de Débito";
      } else if (forma === 3) {
        return "Cartão de Crédito";
      } else if (forma === 4) {
        return "Transferência Bancária";
      } else if (forma === 5) {
        return "PIX";
      } else if (forma === 6) {
        return "Cheque";
      } else if (forma === 7) {
        return "multiplas formas";
      } else if (forma === 8) {
        return "Depósito Bancário";
      } else {
        return "forma de pagamento não especificada";
      }
    },
    fillBody() {
      // console.log(this.pacientes);
      this.body = this.pacientes.map(function(paciente) {
        var strDate = function(stamp) {
          var d = new Date(stamp);
          var dia = d.getDate();
          var month = d.getMonth() + 1;
          var year = d.getFullYear();
          var dateStr = dia + "/" + month + "/" + year;
          return dateStr;
        };
        var strDateN = function(zStr) {
          var zDate = new Date(zStr + " 06:00:00");
          var year = zDate.getFullYear();
          var month = zDate.getMonth() + 1;
          var day = zDate.getDate();
          var monthStr;
          var dayStr;
          if (month > 9) {
            monthStr = month.toString();
          } else {
            monthStr = "0" + month.toString();
          }
          if (day > 9) {
            dayStr = day.toString();
          } else {
            dayStr = "0" + day.toString();
          }
          var dateNStr = dayStr + "/" + monthStr + "/" + year.toString();
          return dateNStr;
        };
        var nome = paciente.text;
        var dataEntrada = strDate(paciente.data.joinedAt.seconds * 1000);
        var dataN = strDateN(paciente.data.dataNascimento);
        var email = paciente.value;
        var telefone = paciente.data.telefone;
        var zap = paciente.data.whatsapp;

        return [nome, dataN, dataEntrada, email, telefone, zap];
      });
    },
    seeMarketStats() {
      this.disableSeeMarketStats = true;
      this.fillBodyMarketStats();
    },
    async fillBodyMarketStats() {
      var pacs = this.pacientes;
      async function getPacientesDocs() {
        var pacienteLista = pacs
          .map(function(p) {
            return p.value;
          })
          .filter(function(pId) {
            return pId !== undefined && pId !== null && pId !== "";
          });
        var pacientesDocs = [];
        // const pacientesDocs = pacienteLista.map(async function(pId) {
        //   var pData = await firebase
        //     .firestore()
        //     .collection("users")
        //     .doc(pId)
        //     .get()
        //     .then(function(doc) {
        //       return doc.data();
        //     });
        //   return pData;
        // });
        for (var i = 0; i < pacienteLista.length; i++) {
          var pData = await firebase
            .firestore()
            .collection("users")
            .doc(pacienteLista[i])
            .get()
            .then(function(doc) {
              return doc.data();
            });
          pacientesDocs.push(pData);
        }
        return pacientesDocs;
        // this.pacientesDocs = pacientesDocs;
      }
      var pacientes = await getPacientesDocs();
      pacientes = pacientes.filter(function(p) {
        return p !== undefined;
      });
      if (
        sessionStorage.getItem("current-user") ==
        "bc@clubedanutricionista.com.br"
      ) {
        pacientes = pacientes.filter(function(p) {
          var dataEntrada = p.joinedAt.seconds * 1000;
          return dataEntrada > 1648771200000;
        });
      }

      var channels = [
        "Vi um anúncio no Facebook", // 0
        "Vi um anúncio no Instagram", // 1
        "Já seguia no Instagram", // 2
        "Pesquisei no Google", // 3
        "Recebi uma indicação", // 4
        "Já conhecia", // 5
        "Outro", // 6
      ];

      var now = new Date();
      var year = now.getFullYear();
      var nrOfYears = year - 2021 + 1;
      var nrOfMonths = nrOfYears * 12;
      var allTheMonthsSince2021 = [];
      for (var i = 0; i < nrOfMonths; i++) {
        allTheMonthsSince2021.push(i);
      }

      var monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Decembro",
      ];

      this.bodyMarketStats = allTheMonthsSince2021.map(function(monthIndex) {
        var yearIndex = Math.floor(monthIndex / 12);
        var absYear = 2021 + yearIndex;
        var inYearMonthIndex = monthIndex % 12;
        var monthName = monthNames[inYearMonthIndex];

        var pacientesReadyToFilter = pacientes.map(function(paciente) {
          var dataEntrada = new Date(paciente.joinedAt.seconds * 1000);
          var yearEntrada = dataEntrada.getFullYear() - 2021;
          var monthEntrada = dataEntrada.getMonth();
          var pMonthIndex = yearEntrada * 12 + monthEntrada;
          var indicacaoClass = 6;
          if (
            paciente.pacienteInfos.indicacao !== undefined &&
            paciente.pacienteInfos.indicacao !== null
          ) {
            indicacaoClass = channels.indexOf(paciente.pacienteInfos.indicacao);
          } else {
            indicacaoClass = 6;
          }
          if (indicacaoClass == -1) {
            indicacaoClass = 6;
          }
          return {
            indicacaoText: paciente.pacienteInfos.indicacao,
            indicacaoClass: indicacaoClass,
            monthIndex: pMonthIndex,
            pId: paciente.email,
            ltv: paciente.ltv,
          };
        });

        var pacientesMonth = pacientesReadyToFilter.filter(function(p) {
          return p.monthIndex == monthIndex;
        });
        var ltvOfPacientes = 0;
        pacientesMonth.forEach(function(p) {
          ltvOfPacientes += p.ltv;
        });
        var nrOfPacientes = pacientesMonth.length;

        var pacientesIndicacao0 = pacientesMonth.filter(function(p) {
          return p.indicacaoClass == 0;
        });
        var nrIndicacao0 = pacientesIndicacao0.length;
        var ltvIndicacao0 = 0;
        pacientesIndicacao0.forEach(function(p) {
          ltvIndicacao0 += p.ltv;
        });

        var pacientesIndicacao1 = pacientesMonth.filter(function(p) {
          return p.indicacaoClass == 1;
        });
        var nrIndicacao1 = pacientesIndicacao1.length;
        var ltvIndicacao1 = 0;
        pacientesIndicacao1.forEach(function(p) {
          ltvIndicacao1 += p.ltv;
        });

        var pacientesIndicacao2 = pacientesMonth.filter(function(p) {
          return p.indicacaoClass == 2;
        });
        var nrIndicacao2 = pacientesIndicacao2.length;
        var ltvIndicacao2 = 0;
        pacientesIndicacao2.forEach(function(p) {
          ltvIndicacao2 += p.ltv;
        });

        var pacientesIndicacao3 = pacientesMonth.filter(function(p) {
          return p.indicacaoClass == 3;
        });
        var nrIndicacao3 = pacientesIndicacao3.length;
        var ltvIndicacao3 = 0;
        pacientesIndicacao3.forEach(function(p) {
          ltvIndicacao3 += p.ltv;
        });

        var pacientesIndicacao4 = pacientesMonth.filter(function(p) {
          return p.indicacaoClass == 4;
        });
        var nrIndicacao4 = pacientesIndicacao4.length;
        var ltvIndicacao4 = 0;
        pacientesIndicacao4.forEach(function(p) {
          ltvIndicacao4 += p.ltv;
        });

        var pacientesIndicacao5 = pacientesMonth.filter(function(p) {
          return p.indicacaoClass == 5;
        });
        var nrIndicacao5 = pacientesIndicacao5.length;
        var ltvIndicacao5 = 0;
        pacientesIndicacao5.forEach(function(p) {
          ltvIndicacao5 += p.ltv;
        });

        var pacientesIndicacao6 = pacientesMonth.filter(function(p) {
          return p.indicacaoClass == 6;
        });
        var nrIndicacao6 = pacientesIndicacao6.length;
        var ltvIndicacao6 = 0;
        pacientesIndicacao6.forEach(function(p) {
          ltvIndicacao6 += p.ltv;
        });

        // var pacientesIndicacaoM1 = pacientesMonth.filter(function(p) {
        //   return p.indicacaoClass == -1;
        // });
        // var nrIndicacaoM1 = pacientesIndicacaoM1.length;
        // var ltvIndicacaoM1 = 0;
        // pacientesIndicacaoM1.forEach(function(p) {
        //   ltvIndicacaoM1 += p.ltv;
        // });

        return {
          monthIndex: monthIndex,
          year: absYear,
          monthName: monthName,
          nrOfPacientes: nrOfPacientes,
          nrIndicacao0: nrIndicacao0,
          nrIndicacao1: nrIndicacao1,
          nrIndicacao2: nrIndicacao2,
          nrIndicacao3: nrIndicacao3,
          nrIndicacao4: nrIndicacao4,
          nrIndicacao5: nrIndicacao5,
          nrIndicacao6: nrIndicacao6,
          // nrIndicacaoM1: nrIndicacaoM1,
          ltvOfPacientes: ltvOfPacientes,
          ltvIndicacao0: ltvIndicacao0,
          ltvIndicacao1: ltvIndicacao1,
          ltvIndicacao2: ltvIndicacao2,
          ltvIndicacao3: ltvIndicacao3,
          ltvIndicacao4: ltvIndicacao4,
          ltvIndicacao5: ltvIndicacao5,
          ltvIndicacao6: ltvIndicacao6,
          // ltvIndicacaoM1: ltvIndicacaoM1,
        };
      });

      this.showMarketStatsYear(year);

      this.disableSeeMarketStats = false;
      this.dialogMarketStats = true;
    },
    showMarketStatsYear(ano) {
      if (this.bodyMarketStats.length > 0) {
        this.showMarketStatsAno = ano;
        this.showBodyMarketStats = this.bodyMarketStats.filter(function(b) {
          return b.year == ano;
        });
      }
    },
    clickedShowMarketStatsYear(id) {
      if (this.showMarketStatsAno == id) {
        return "#c5d13f";
      } else {
        return "grey";
      }
    },
    async addCompras() {
      for (var i = 0; i < this.body.length; i++) {
        var niceString = function(valor) {
          var valorAbs = Math.abs(valor);
          var valorKomma;

          var decimals = Math.round((valorAbs - Math.floor(valorAbs)) * 100);
          var decimalsStr;
          if (decimals < 1) {
            decimalsStr = "00";
          } else if (decimals < 10) {
            decimalsStr = "0" + decimals.toString();
          } else {
            decimalsStr = decimals.toString();
          }

          var valorGanz = Math.floor(valorAbs);

          if (valorGanz > 1000) {
            var tusiger = Math.floor(valorAbs / 1000);
            var rest = valorGanz % 1000;
            var restStr;
            if (rest < 10) {
              restStr = "00" + rest.toString();
            } else if (rest < 100) {
              restStr = "0" + rest.toString();
            } else {
              restStr = rest.toString();
            }
            valorKomma = tusiger.toString() + "." + restStr;
          } else {
            valorKomma = valorGanz.toString();
          }
          return valorKomma + "," + decimalsStr;
        };

        var ltv = await firebase
          .firestore()
          .collection("users")
          .doc(this.body[i][3])
          .get()
          .then(function(doc) {
            if (doc !== undefined && doc.data() !== undefined) {
              return doc.data().ltv;
            } else {
              return 0;
            }
          });

        var compras = await firebase
          .firestore()
          .collection("users")
          .doc(this.body[i][3])
          .collection("compras")
          .get()
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return doc.data().tipo;
            })
          );
        // console.log("compras", this.body[i][2])
        // var comprasStr = "";
        // compras.forEach(function (c) {
        //   comprasStr += ", " + c;
        // })
        this.body[i].push(niceString(parseFloat(ltv)));
        this.body[i].push(compras);
      }
      console.log(this.body);
    },
  },
  computed: {
    chunk() {
      var years = [];
      var now = new Date();
      var year = now.getFullYear();
      for (var i = 2021; i <= year; i++) {
        years.push(i);
      }
      return years;
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    today() {
      var now = new Date();
      var time = now.getTime();
      return this.readableDate(time);
    },
    pacienteComprasAll() {
      return this.$store.state.firestore.pacienteComprasAll;
    },
    pacienteAgendaAll() {
      return this.$store.state.firestore.pacienteAgendaAll;
    },
    compraVis() {
      return this.$store.state.firestore.compraVis;
    },
    eventVis() {
      return this.$store.state.firestore.visEvent;
    },
    funcionarios() {
      return this.$store.state.firestore.funcionarios;
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
  },
  mounted() {
    this.$store.dispatch("firestore/getPacientes");

    setTimeout(() => {
      this.fillPacientes();
      // this.fillAccesses();
      // this.fillRatings();
    }, 3000);
  },
};
</script>
