<template>
  <div>
    <v-dialog max-width="600" v-model="chooseDateTimeDialog">
      <v-card>
        <v-card-title class="d-flex justify-center">
          "{{ eventEdit.name }}"
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="eventEdit.niceDateShort"
                :items="dias"
                menu-props="auto"
                label="Data *"
                hide-details
                prepend-icon="mdi-calendar"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="eventEdit.startTime"
                :items="horarios"
                menu-props="auto"
                label="Horário de inicio *"
                hide-details
                prepend-icon="mdi-clock-time-four-outline"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="eventEdit.endTime"
                :items="horarios"
                menu-props="auto"
                label="Horário de termino *"
                hide-details
                prepend-icon="mdi-clock-time-four-outline"
                single-line
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="transformStartEnd"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-model="chooseDateTimeDialogE">
      <v-card>
        <v-card-title class="d-flex justify-center">
          "{{ eventEdit.name }}"
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="eventEdit.niceDateShort"
                :items="dias"
                menu-props="auto"
                label="Data *"
                hide-details
                prepend-icon="mdi-calendar"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="eventEdit.startTime"
                :items="horarios"
                menu-props="auto"
                label="Horário de inicio *"
                hide-details
                prepend-icon="mdi-clock-time-four-outline"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="eventEdit.endTime"
                :items="horarios"
                menu-props="auto"
                label="Horário de termino *"
                hide-details
                prepend-icon="mdi-clock-time-four-outline"
                single-line
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="transformStartEndE"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-stepper non-linear>
      <v-stepper-header>
        <v-stepper-step editable step="1"> Consultas </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable step="2">
          Tratamentos Estéticos
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row class="d-flex justify-center">
            <v-card width="95%">
              <div
                v-if="
                  mov.tipo === 'Programa 12S Online' ||
                    mov.tipo === 'Programa 12S' ||
                    mov.tipo === 'Programa 12S com Estética' ||
                    mov.tipo === 'Programa Clínico Funcional' ||
                    mov.tipo === 'Programa Modulação Intestinal' ||
                    mov.tipo === 'Programa Clínico Funcional (plano família)' ||
                    mov.tipo ===
                      'Programa Modulação Intestinal (plano família)' ||
                    mov.tipo === 'Programa Clínico Funcional com Estética' ||
                    mov.tipo === 'Programa Modulação Intestinal com Estética' ||
                    mov.tipo ===
                      'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)' ||
                    mov.tipo ===
                      'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)'
                "
              >
                <v-row class="mx-1">
                  <v-col cols="12" md="3">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[2].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[2])"
                      >
                        {{ dataToSetList[2].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[3].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[3])"
                      >
                        {{ dataToSetList[3].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div
                v-else-if="mov.tipo === 'Pacote 90 dias Protocolo Kirchhoff'"
              >
                <v-row class="mx-1">
                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[2].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[2])"
                      >
                        {{ dataToSetList[2].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Consulta'">
                <v-row class="mx-1">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Pacote Estético'">
                <v-row class="d-flex justify-center">
                  <v-btn @click="step = 2" color="#c5d13f">
                    <v-icon left color="#614021">mdi-calendar-edit</v-icon>
                    Agendar Pacotes de Estética</v-btn
                  >
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Sessão Estética'">
                <v-row class="d-flex justify-center">
                  <v-btn @click="step = 2" color="#c5d13f">
                    <v-icon left color="#614021">mdi-calendar-edit</v-icon>
                    Agendar Sessão de Estética</v-btn
                  >
                </v-row>
              </div>
              <div
                v-else-if="mov.tipo === 'Protocolo Harmonização Corporal 24H'"
              >
                <v-row class="mx-1">
                  <v-col cols="12" md="6">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div
                v-else-if="mov.tipo === 'Protocolo Detox + Lipodraining 24H'"
              >
                <v-row class="mx-1">
                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[2].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[2])"
                      >
                        {{ dataToSetList[2].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Protocolo Lipodraining 24H'">
                <v-row class="d-flex justify-center mx-1">
                  <v-alert type="warning">
                    Não inclui consultas nutricionais
                  </v-alert>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Protocolo Glúteo de Ouro'">
                <v-row class="d-flex justify-center mx-1">
                  <v-alert type="warning">
                    Não inclui consultas nutricionais
                  </v-alert>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Power Shape 30'">
                <v-row class="mx-1">
                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[1].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTime(dataToSetList[1])"
                      >
                        {{ dataToSetList[1].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Hoje
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ typesPt[type] }}</span>
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Dia</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Semana</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Mês</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 dias</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600" @mousemove="hourInfoMouseFollow">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  locale="pt"
                  first-time="08:00"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @mousemove:time="getCalendarDayTime"
                  @mousedown:event="startDrag"
                  @mouseup:event="endDrag"
                  @change="getEvents"
                >
                  <template v-slot:event="{ event, eventSummary }">
                    <div
                      class="v-event-draggable"
                      v-html="eventSummary()"
                    ></div>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-row class="d-flex justify-center">
            <v-card width="95%">
              <div
                v-if="
                  mov.tipo === 'Protocolo Harmonização Corporal 24H' ||
                    mov.tipo === 'Lipo Vegana' ||
                    mov.tipo == 'Mini Lipo Vegana'
                "
              >
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title
                      class="text-caption d-flex justify-center"
                      v-if="pacoteToShow.def2"
                    >
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.nr"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                        >
                          {{sess.name }}, {{ sess.niceDate }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-if="mov.tipo === 'Protocolo Lipodraining 24H'">
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.id"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                        >
                          {{ sess.niceDate }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-if="mov.tipo === 'Protocolo Glúteo de Ouro'">
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.id"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                        >
                          {{ sess.niceDate }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-if="mov.tipo === 'Protocolo Detox + Lipodraining 24H'">
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.id"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                        >
                          {{ sess.niceDate }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div
                v-if="
                  mov.tipo === 'Protocolo de Clareamento (Pacote de 4 sessões)'
                "
              >
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.id"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                        >
                          {{ sess.niceDate }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-if="mov.tipo === 'Power Shape 30'">
                <v-row class="d-flex justify-center">
                  <v-card outlined width="95%">
                    <v-card-title class="text-caption d-flex justify-center">
                      {{ pacoteToShow.def2 }}</v-card-title
                    >
                    <!-- <v-card-title class="text-caption d-flex justify-center">
                      {{ mov.tipo }}</v-card-title
                    > -->
                    <v-card-text>
                      <v-row
                        v-for="sess in pacoteToShow.sessoes"
                        :key="sess.id"
                        class="d-flex justify-center"
                      >
                        <v-chip
                          class="d-flex justify-center text-caption my-1"
                          @click="chooseDataTimeE(sess)"
                        >
                          {{ sess.niceDate }}
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div v-else-if="mov.tipo === 'Consulta'">
                <v-row class="d-flex justify-center">
                  <v-btn large color="#c5d13f" to="/MeusPacientes">
                    Fechar
                  </v-btn>
                </v-row>
              </div>
              <div
                v-else-if="
                  mov.tipo === 'Pacote Estético' ||
                    mov.tipo === 'Programa 12S com Estética' ||
                    mov.tipo === 'Programa Clínico Funcional com Estética' ||
                    mov.tipo === 'Programa Modulação Intestinal com Estética'
                "
              >
                <div
                  v-for="(referredMesItem, refMesIndex) in referredMesPacList"
                  :key="refMesIndex"
                >
                  <v-row class="text-caption d-flex justify-center">
                    {{ referredMesItem.mesName }}
                  </v-row>
                  <v-row
                    v-for="pac in referredMesItem.pacs"
                    :key="pac.index"
                    class="d-flex justify-center"
                  >
                    <v-card outlined width="95%">
                      <v-card-title class="text-caption d-flex justify-center">
                        {{ pac.def2 }}</v-card-title
                      ><v-card-text>
                        <v-row
                          v-for="sess in pac.sessoes"
                          :key="sess.nr"
                          class="d-flex justify-center"
                        >
                          <v-chip
                            class="d-flex justify-center text-caption my-1"
                            @click="chooseDataTimeE(sess)"
                          >
                            {{ sess.niceDate }}
                          </v-chip>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </div>
              </div>
              <div v-else-if="mov.tipo === 'Sessão Estética'">
                <v-row class="mx-1">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-row class="d-flex justify-center text-caption">
                      {{ dataToSetList[0].name }}
                    </v-row>
                    <v-row class="d-flex justify-center text-caption">
                      <v-chip
                        class="d-flex justify-center text-caption"
                        @click="chooseDataTimeE(dataToSetList[0])"
                      >
                        {{ dataToSetList[0].niceDate }}
                      </v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div
                v-else-if="
                  mov.tipo === 'Programa 12S Online' ||
                    mov.tipo === 'Programa 12S' ||
                    mov.tipo ===
                      'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)' ||
                    mov.tipo ===
                      'Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)'
                "
              >
                <v-row class="d-flex justify-center mx-1">
                  <v-alert type="warning">
                    Não inclui tratamentos estéticos
                  </v-alert>
                </v-row>
              </div>
            </v-card>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Hoje
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ typesPt[type] }}</span>
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Dia</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Semana</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Mês</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 dias</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600" @mousemove="hourInfoMouseFollow">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  locale="pt"
                  first-time="08:00"
                  :events="eventsEstetica"
                  :event-color="getEventColor"
                  :type="type"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @mousemove:time="getCalendarDayTime"
                  @mousedown:event="startDrag"
                  @mouseup:event="endDragEstetica"
                  @change="getEventsEstetica"
                >
                  <template v-slot:event="{ event, eventSummary }">
                    <div
                      class="v-event-draggable"
                      v-html="eventSummary()"
                    ></div>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Agenda",
  data() {
    return {
      referredMesPacList: [],
      pacoteToShow: { def2: "", sessoes: [{ nr: 0, niceDateStr: "" }] },
      eventEdit: {
        name: "1ª Consulta",
        niceDate: "seg.,",
        niceDateShort: "seg.,",
        patient: "Paciente Exemplo",
        //   patientId: this.$store.state.firestore.mov.paciente,
        patientId: "paciente@exemplo.com",
        newPatient: false,
        start: "2021-02-07 8:00:00",
        end: "2021-02-07 9:00:00",
        details: "Agendamento provisório",
        color: "blue",
        timed: true,
        id: "abc",
        startTime: "8:00",
        endTime: "9:00",
      },
      dataToSetList: [
        {
          name: "1ª Consulta",
          niceDate: "seg.,",
          patient: "Paciente Exemplo",
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: "paciente@exemplo.com",
          newPatient: false,
          start: "2021-02-07 8:00:00",
          end: "2021-02-07 9:00:00",
          details: "Agendamento provisório",
          color: "blue",
          timed: true,
          id: "abc",
          startTime: "8:00",
          endTime: "9:00",
        },
        {
          name: "1ª Consulta",
          niceDate: "seg.,",
          patient: "Paciente Exemplo",
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: "paciente@exemplo.com",
          newPatient: false,
          start: "2021-02-07 8:00:00",
          end: "2021-02-07 9:00:00",
          details: "Agendamento provisório",
          color: "blue",
          timed: true,
          id: "abc",
          startTime: "8:00",
          endTime: "9:00",
        },
        {
          name: "1ª Consulta",
          niceDate: "seg.,",
          patient: "Paciente Exemplo",
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: "paciente@exemplo.com",
          newPatient: false,
          start: "2021-02-07 8:00:00",
          end: "2021-02-07 9:00:00",
          details: "Agendamento provisório",
          color: "blue",
          timed: true,
          id: "abc",
          startTime: "8:00",
          endTime: "9:00",
        },
        {
          name: "1ª Consulta",
          niceDate: "seg.,",
          patient: "Paciente Exemplo",
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: "paciente@exemplo.com",
          newPatient: false,
          start: "2021-02-07 8:00:00",
          end: "2021-02-07 9:00:00",
          details: "Agendamento provisório",
          color: "blue",
          timed: true,
          id: "abc",
          startTime: "8:00",
          endTime: "9:00",
        },
      ],
      dataToSetPacM1: [],
      dataToSetPacM2: [],
      dataToSetPacM3: [],
      chooseDateTimeDialogE: false,
      chooseDateTimeDialog: false,
      diasPtBr: ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."],
      atendimentoTipos: [
        "1ª Consulta",
        "2ª Consulta",
        "3ª Consulta",
        "Avaliação Final",
      ],
      atendimentoTiposKirchhoff: [
        "1ª Avaliação Protocolo Kirchhoff",
        "2ª Avaliação Pacote Protocolo Kirchhoff",
        "3ª Avaliação Pacote Protocolo Kirchhoff",
      ],
      atendimentoTiposColor: {
        "1ª Consulta": "#FFB400",
        "Retorno de Consulta Avulsa": "#0000FF",
        "2ª Consulta": "#0000FF",
        "3ª Consulta": "#0000FF",
        "Avaliação Final": "#0000FF",
        Bioimpedância: "#06c980",
        "Coleta de Exames": "#06c980",
        "Leitura de Exames": "#06c980",
        "1ª Avaliação Protocolo Kirchhoff": "#7806c9",
        "2ª Avaliação Pacote Protocolo Kirchhoff": "#c78cf1",
        "3ª Avaliação Pacote Protocolo Kirchhoff": "#c78cf1",
      },
      dateToSet: {
        nome: null,
      },
      horarios: [
        "7:00",
        "7:15",
        "7:30",
        "7:45",
        "8:00",
        "8:15",
        "8:30",
        "8:45",
        "9:00",
        "9:15",
        "9:30",
        "9:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00",
        "16:15",
        "16:30",
        "16:45",
        "17:00",
        "17:15",
        "17:30",
        "17:45",
        "18:00",
        "18:15",
        "18:30",
        "18:45",
        "19:00",
        "19:15",
        "19:30",
        "19:45",
        "20:00",
        "20:15",
        "20:30",
        "20:45",
        "21:00",
        "21:15",
        "21:30",
        "21:45",
        "22:00",
      ],
      diasIndices: [
        -15,
        -14,
        -13,
        -12,
        -11,
        -10,
        -9,
        -8,
        -7,
        -6,
        -5,
        -4,
        -3,
        -2,
        -1,
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
      ],
      rules: {
        requiredField: [(v) => !!v || "Campo obrigatório"],
      },
      menu: null,
      menu2: null,
      dragEvent: null,
      niceDateShort: null,
      atendimento: null,
      startTime: null,
      currentTime: "",
      newPatient: false,
      description: null,
      focus: "",
      patient: "",
      patientId: "",
      selectedTime: null,
      selectedEvent: {},
      dialog: false,
      type: "week",
      typesPt: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
        "4day": "4 Dias",
      },
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedElement: null,
      selectedOpen: false,
      editEvent: false,
      events: [],
      eventsEstetica: [],
      // pacotes1month: [
      //   { index: 0, nome: "", sessoes: [{ nr: 0, niceDateStr: "" }] },
      // ],
      // pacotes2month: [
      //   { index: 0, nome: "", sessoes: [{ nr: 0, niceDateStr: "" }] },
      // ],
      // pacotes3month: [
      //   { index: 0, nome: "", sessoes: [{ nr: 0, niceDateStr: "" }] },
      // ],
    };
  },
  computed: {
    mov() {
      return this.$store.state.firestore.mov;
    },
    pacienteId() {
      return this.$store.state.firestore.mov.paciente;
    },
    patientName() {
      return this.$store.state.firestore.mov.pacienteName;
    },
    pacientes() {
      return this.$store.state.firestore.pacientes;
    },
    dias() {
      if (this.eventEdit.start !== "2021-02-07 8:00:00") {
        var chosenDate = new Date(this.eventEdit.start);
        chosenDate = chosenDate.getTime();
        var list = this.diasIndices.map(function(index) {
          var newDateNr = 86400000 * index + chosenDate;
          var newDate = new Date(newDateNr);
          var diasPtBr = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayName = diasPtBr[newDate.getDay()];
          var dayStr;
          if (newDate.getDate() < 10) {
            dayStr = "0" + newDate.getDate().toString();
          } else {
            dayStr = newDate.getDate().toString();
          }
          var monthStr;
          var month = newDate.getMonth() + 1;
          if (month < 10) {
            monthStr = "0" + month.toString();
          } else {
            monthStr = month.toString();
          }
          var year = newDate.getYear() - 100;
          var yearStr = year.toString();

          return dayName + ", " + dayStr + "/" + monthStr + "/" + yearStr;
        });
        return list;
      } else {
        var d = new Date();
        var currentDate = d.getTime();
        var listCurrent = this.diasIndices.map(function(index) {
          var newDateNr = 86400000 * index + currentDate;
          var newDate = new Date(newDateNr);
          var diasPtBr = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayName = diasPtBr[newDate.getDay()];
          var dayStr;
          if (newDate.getDate() < 10) {
            dayStr = "0" + newDate.getDate().toString();
          } else {
            dayStr = newDate.getDate().toString();
          }
          var monthStr;
          var month = newDate.getMonth() + 1;
          if (month < 10) {
            monthStr = "0" + month.toString();
          } else {
            monthStr = month.toString();
          }
          var year = newDate.getYear() - 100;
          var yearStr = year.toString();

          return dayName + ", " + dayStr + "/" + monthStr + "/" + yearStr;
        });
        return listCurrent;
      }
    },
    chosenDataYMD() {
      var splits = this.niceDateShort.split("/");
      var year = splits[2];
      var month = splits[1];
      var days = splits[0];
      return "20" + year + "-" + month + "-" + days.substring(6);
    },
    endTime() {
      if (
        this.startTime != null &&
        this.atendimento == this.atendimentoTipos[0]
      ) {
        var splits = this.startTime.split(":");
        var hours = parseInt(splits[0]);
        hours += 1;
        var newEndTime = hours
          .toString()
          .concat(":")
          .concat(splits[1]);
        return newEndTime;
      } else if (
        this.startTime != null &&
        this.atendimento != this.atendimentoTipos[0]
      ) {
        var splitsR = this.startTime.split(":");
        var hoursR = parseInt(splitsR[0]);
        var minutesR = parseInt(splitsR[1]);
        minutesR += 30;
        if (minutesR > 59) {
          minutesR -= 60;
          hoursR += 1;
        }
        if (minutesR == 0) {
          minutesR = "00";
        }
        var newEndTimeR = hoursR
          .toString()
          .concat(":")
          .concat(minutesR.toString());
        return newEndTimeR;
      } else {
        return null;
      }
    },
  },
  methods: {
    async mountingFunction() {
      function roundTimeQuarterHour(time) {
        var timeToReturn = new Date(time);

        timeToReturn.setMilliseconds(
          Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000
        );
        timeToReturn.setSeconds(Math.ceil(timeToReturn.getSeconds() / 60) * 60);
        timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);
        return timeToReturn;
      }

      var aDate = new Date();
      var aRoundedDate = roundTimeQuarterHour(aDate);

      var createEvent = function(
        nome,
        monthsFromNow,
        weekOfMonth,
        dayOfWeek,
        pacienteId,
        patientName,
        area,
        period
      ) {
        var refId = firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agendaEstetica")
          .doc();
        var id = refId.id;

        function roundTimeQuarterHour(time) {
          var timeToReturn = new Date(time);

          timeToReturn.setMilliseconds(
            Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000
          );
          timeToReturn.setSeconds(
            Math.ceil(timeToReturn.getSeconds() / 60) * 60
          );
          timeToReturn.setMinutes(
            Math.ceil(timeToReturn.getMinutes() / 15) * 15
          );
          return timeToReturn;
        }

        var aDate = new Date();
        var aRoundedDate = roundTimeQuarterHour(aDate);

        var startDateN =
          aRoundedDate.getTime() +
          monthsFromNow * 30 * 24 * 60 * 60 * 1000 +
          weekOfMonth * 7 * 24 * 60 * 60 * 1000 +
          dayOfWeek * 24 * 60 * 60 * 1000;
        var startDate = new Date(startDateN);
        var endDate;
        var endDateNx = startDateN + period * 60 * 1000;
        endDate = new Date(endDateNx);
        var startMinStr;
        var startMinStrX = startDate.getMinutes();
        if (startMinStrX < 10) {
          startMinStr = "0" + startMinStrX.toString();
        } else {
          startMinStr = startMinStrX.toString();
        }
        var endMinStr;
        var endMinStrX = endDate.getMinutes();
        if (endMinStrX < 10) {
          endMinStr = "0" + endMinStrX.toString();
        } else {
          endMinStr = endMinStrX.toString();
        }

        var startTime = startDate.getHours().toString() + ":" + startMinStr;
        var endTime = endDate.getHours().toString() + ":" + endMinStr;

        var startYStrX = startDate.getYear() + 1900;
        var startYStr = startYStrX.toString();
        var startYStrShortX = startDate.getYear() - 100;
        var startYStrShort = startYStrShortX.toString();

        var startMStr;
        var startMStrX = startDate.getMonth() + 1;
        if (startMStrX < 10) {
          startMStr = "0" + startMStrX.toString();
        } else {
          startMStr = startMStrX.toString();
        }

        var startDStr;
        var startDStrX = startDate.getDate();
        if (startDStrX < 10) {
          startDStr = "0" + startDStrX.toString();
        } else {
          startDStr = startDStrX.toString();
        }

        var startStr =
          startYStr +
          "-" +
          startMStr +
          "-" +
          startDStr +
          " " +
          startTime +
          ":00";
        var endStr =
          startYStr + "-" + startMStr + "-" + startDStr + " " + endTime + ":00";
        var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
        var day = diasPtBr[startDate.getDay()];
        var niceDateShort =
          day + ", " + startDStr + "/" + startMStr + "/" + startYStrShort;
        var niceDateStr =
          day +
          ", " +
          startDStr +
          "/" +
          startMStr +
          "/" +
          startYStrShort +
          ", " +
          startTime +
          "-" +
          endTime;

        var obj = {
          name: nome,
          niceDate: niceDateStr,
          niceDateShort: niceDateShort,
          patient: patientName,
          patientId: pacienteId,
          newPatient: false,
          start: startStr,
          end: endStr,
          details: area,
          color: "blue",
          timed: true,
          id: id, //Math.round(Math.random()*(1000000000000)).toString(),
          startTime: startTime,
          endTime: endTime,
          autoset: true,
          area: area,
        };
        console.log(obj);
        return obj;
      };

      var createEvent12S = function(
        nome,
        weeksFromNow,
        dayOfWeek,
        pacienteId,
        patientName,
        area,
        period
      ) {
        var refId = firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agendaEstetica")
          .doc();
        var id = refId.id;

        function roundTimeQuarterHour(time) {
          var timeToReturn = new Date(time);

          timeToReturn.setMilliseconds(
            Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000
          );
          timeToReturn.setSeconds(
            Math.ceil(timeToReturn.getSeconds() / 60) * 60
          );
          timeToReturn.setMinutes(
            Math.ceil(timeToReturn.getMinutes() / 15) * 15
          );
          return timeToReturn;
        }

        var aDate = new Date();
        var aRoundedDate = roundTimeQuarterHour(aDate);

        var startDateN =
          aRoundedDate.getTime() +
          weeksFromNow * 7 * 24 * 60 * 60 * 1000 +
          dayOfWeek * 24 * 60 * 60 * 1000;
        var startDate = new Date(startDateN);
        var endDate;
        var endDateNx = startDateN + period * 60 * 1000;
        endDate = new Date(endDateNx);
        var startMinStr;
        var startMinStrX = startDate.getMinutes();
        if (startMinStrX < 10) {
          startMinStr = "0" + startMinStrX.toString();
        } else {
          startMinStr = startMinStrX.toString();
        }
        var endMinStr;
        var endMinStrX = endDate.getMinutes();
        if (endMinStrX < 10) {
          endMinStr = "0" + endMinStrX.toString();
        } else {
          endMinStr = endMinStrX.toString();
        }

        var startTime = startDate.getHours().toString() + ":" + startMinStr;
        var endTime = endDate.getHours().toString() + ":" + endMinStr;

        var startYStrX = startDate.getYear() + 1900;
        var startYStr = startYStrX.toString();
        var startYStrShortX = startDate.getYear() - 100;
        var startYStrShort = startYStrShortX.toString();

        var startMStr;
        var startMStrX = startDate.getMonth() + 1;
        if (startMStrX < 10) {
          startMStr = "0" + startMStrX.toString();
        } else {
          startMStr = startMStrX.toString();
        }

        var startDStr;
        var startDStrX = startDate.getDate();
        if (startDStrX < 10) {
          startDStr = "0" + startDStrX.toString();
        } else {
          startDStr = startDStrX.toString();
        }

        var startStr =
          startYStr +
          "-" +
          startMStr +
          "-" +
          startDStr +
          " " +
          startTime +
          ":00";
        var endStr =
          startYStr + "-" + startMStr + "-" + startDStr + " " + endTime + ":00";
        var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
        var day = diasPtBr[startDate.getDay()];
        var niceDateShort =
          day + ", " + startDStr + "/" + startMStr + "/" + startYStrShort;
        var niceDateStr =
          day +
          ", " +
          startDStr +
          "/" +
          startMStr +
          "/" +
          startYStrShort +
          ", " +
          startTime +
          "-" +
          endTime;

        var obj = {
          name: nome,
          niceDate: niceDateStr,
          niceDateShort: niceDateShort,
          patient: patientName,
          patientId: pacienteId,
          newPatient: false,
          start: startStr,
          end: endStr,
          details: area,
          color: "blue",
          timed: true,
          id: id, //Math.round(Math.random()*(1000000000000)).toString(),
          startTime: startTime,
          endTime: endTime,
          autoset: true,
          area: area,
        };
        return obj;
      };

      if (
        this.mov.tipo == "Programa Clínico Funcional" ||
        this.mov.tipo == "Programa Clínico Funcional (plano família)"
      ) {
        for (var i = 0; i < 4; i++) {
          var nome = this.atendimentoTipos[i];

          var refId = firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc();
          var id = refId.id;

          var startDateN =
            aRoundedDate.getTime() + i * 28 * 24 * 60 * 60 * 1000;
          var startDate = new Date(startDateN);
          var endDate;
          if (nome === "1ª Consulta") {
            var endDateN = startDateN + 60 * 60 * 1000;
            endDate = new Date(endDateN);
          } else {
            var endDateNx = startDateN + 30 * 60 * 1000;
            endDate = new Date(endDateNx);
          }
          var startMinStr;
          var startMinStrX = startDate.getMinutes();
          if (startMinStrX < 10) {
            startMinStr = "0" + startMinStrX.toString();
          } else {
            startMinStr = startMinStrX.toString();
          }
          var endMinStr;
          var endMinStrX = endDate.getMinutes();
          if (endMinStrX < 10) {
            endMinStr = "0" + endMinStrX.toString();
          } else {
            endMinStr = endMinStrX.toString();
          }

          var startTime = startDate.getHours().toString() + ":" + startMinStr;
          var endTime = endDate.getHours().toString() + ":" + endMinStr;

          var startYStrX = startDate.getYear() + 1900;
          var startYStr = startYStrX.toString();
          var startYStrShortX = startDate.getYear() - 100;
          var startYStrShort = startYStrShortX.toString();

          var startMStr;
          var startMStrX = startDate.getMonth() + 1;
          if (startMStrX < 10) {
            startMStr = "0" + startMStrX.toString();
          } else {
            startMStr = startMStrX.toString();
          }

          var startDStr;
          var startDStrX = startDate.getDate();
          if (startDStrX < 10) {
            startDStr = "0" + startDStrX.toString();
          } else {
            startDStr = startDStrX.toString();
          }

          var startStr =
            startYStr +
            "-" +
            startMStr +
            "-" +
            startDStr +
            " " +
            startTime +
            ":00";
          var endStr =
            startYStr +
            "-" +
            startMStr +
            "-" +
            startDStr +
            " " +
            endTime +
            ":00";
          var diasPtBr = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var day = diasPtBr[startDate.getDay()];
          var niceDateShort =
            day + ", " + startDStr + "/" + startMStr + "/" + startYStrShort;
          var niceDateStr =
            day +
            ", " +
            startDStr +
            "/" +
            startMStr +
            "/" +
            startYStrShort +
            ", " +
            startTime +
            "-" +
            endTime;

          var newDoc = {
            movMillis: this.mov.millis.toString(),
            name: nome,
            niceDate: niceDateStr,
            niceDateShort: niceDateShort,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStr,
            end: endStr,
            details: "Clínico Funcional",
            color: nome === "1ª Consulta" ? "#FFB400" : "blue",
            timed: true,
            id: id, //Math.round(Math.random().multiply(1000000000000)).toString(),
            startTime: startTime,
            endTime: endTime,
            autoset: true,
          };

          this.dataToSetList[i] = newDoc;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc(id)
            .set(newDoc);

          await firebase
            .firestore()
            .collection("users")
            .doc(this.mov.paciente)
            .collection("agenda")
            .doc(id)
            .set(newDoc);

          var d = new Date(startStr);
          var millis = d.getTime();
          var sub24 = 24 * 60 * 60 * 1000;
          var millis_1 = millis - sub24;
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(id)
            .set({
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1,
              name: nome,
              niceDate: niceDateStr,
              niceDateShort: niceDateShort,
              patient: this.mov.pacienteName,
              patientId: this.mov.paciente,
              newPatient: false,
              start: startStr,
              end: endStr,
              details: "Clínico Funcional",
              timed: true,
              startTime: startTime,
              endTime: endTime,
              autoset: true,
              id: id,
            });
        }
      } else if (
        this.mov.tipo == "Programa Modulação Intestinal" ||
        this.mov.tipo == "Programa Modulação Intestinal (plano família)"
      ) {
        for (var j = 0; j < 4; j++) {
          var nome_2 = this.atendimentoTipos[j];

          var refId_2 = firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc();
          var id_2 = refId_2.id;

          var startDateN_2 =
            aRoundedDate.getTime() + j * 28 * 24 * 60 * 60 * 1000;
          var startDate_2 = new Date(startDateN_2);
          var endDate_2;
          if (nome === "1ª Consulta") {
            var endDateN_2 = startDateN_2 + 60 * 60 * 1000;
            endDate_2 = new Date(endDateN_2);
          } else {
            var endDateNx_2 = startDateN_2 + 30 * 60 * 1000;
            endDate_2 = new Date(endDateNx_2);
          }
          var startMinStr_2;
          var startMinStrX_2 = startDate_2.getMinutes();
          if (startMinStrX_2 < 10) {
            startMinStr_2 = "0" + startMinStrX_2.toString();
          } else {
            startMinStr_2 = startMinStrX_2.toString();
          }
          var endMinStr_2;
          var endMinStrX_2 = endDate_2.getMinutes();
          if (endMinStrX_2 < 10) {
            endMinStr_2 = "0" + endMinStrX_2.toString();
          } else {
            endMinStr_2 = endMinStrX_2.toString();
          }

          var startTime_2 =
            startDate_2.getHours().toString() + ":" + startMinStr_2;
          var endTime_2 = endDate_2.getHours().toString() + ":" + endMinStr_2;

          var startYStrX_2 = startDate_2.getYear() + 1900;
          var startYStr_2 = startYStrX_2.toString();
          var startYStrShortX_2 = startDate_2.getYear() - 100;
          var startYStrShort_2 = startYStrShortX_2.toString();

          var startMStr_2;
          var startMStrX_2 = startDate_2.getMonth() + 1;
          if (startMStrX_2 < 10) {
            startMStr_2 = "0" + startMStrX_2.toString();
          } else {
            startMStr_2 = startMStrX_2.toString();
          }

          var startDStr_2;
          var startDStrX_2 = startDate_2.getDate();
          if (startDStrX_2 < 10) {
            startDStr_2 = "0" + startDStrX_2.toString();
          } else {
            startDStr_2 = startDStrX_2.toString();
          }

          var startStr_2 =
            startYStr_2 +
            "-" +
            startMStr_2 +
            "-" +
            startDStr_2 +
            " " +
            startTime_2 +
            ":00";
          var endStr_2 =
            startYStr_2 +
            "-" +
            startMStr_2 +
            "-" +
            startDStr_2 +
            " " +
            endTime_2 +
            ":00";
          var diasPtBr_2 = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var day_2 = diasPtBr_2[startDate_2.getDay()];
          var niceDateShort_2 =
            day_2 +
            ", " +
            startDStr_2 +
            "/" +
            startMStr_2 +
            "/" +
            startYStrShort_2;
          var niceDateStr_2 =
            day_2 +
            ", " +
            startDStr_2 +
            "/" +
            startMStr_2 +
            "/" +
            startYStrShort_2 +
            ", " +
            startTime_2 +
            "-" +
            endTime_2;

          var newDoc_2 = {
            movMillis: this.mov.millis.toString(),
            name: nome_2,
            niceDate: niceDateStr_2,
            niceDateShort: niceDateShort_2,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStr_2,
            end: endStr_2,
            details: "Modulação Intestinal",
            color: nome_2 === "1ª Consulta" ? "#FFB400" : "blue",
            timed: true,
            id: id_2, //Math.round(Math.random().multiply(1000000000000)).toString(), //id_2,
            startTime: startTime_2,
            endTime: endTime_2,
            autoset: true,
          };

          this.dataToSetList[j] = newDoc_2;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc(id_2)
            .set(newDoc_2);

          await firebase
            .firestore()
            .collection("users")
            .doc(this.mov.paciente)
            .collection("agenda")
            .doc(id_2)
            .set(newDoc_2);

          var d_2 = new Date(startStr_2);
          var millis_2 = d_2.getTime();
          var sub24_2 = 24 * 60 * 60 * 1000;
          var millis_1_2 = millis_2 - sub24_2;
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(id_2)
            .set({
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1_2,
              name: nome_2,
              niceDate: niceDateStr_2,
              niceDateShort: niceDateShort_2,
              patient: this.mov.pacienteName,
              //   patientId: this.$store.state.firestore.mov.paciente,
              patientId: this.mov.paciente,
              newPatient: false,
              start: startStr_2,
              end: endStr_2,
              details: "Modulação Intestinal",
              color: nome_2 === "1ª Consulta" ? "#FFB400" : "blue",
              timed: true,
              id: id_2, //Math.round(Math.random().multiply(1000000000000)).toString(), //id_2,
              startTime: startTime_2,
              endTime: endTime_2,
              autoset: true,
            });
        }
      } else if (
        this.mov.tipo == "Programa 12S Online" ||
        this.mov.tipo == "Programa 12S" ||
        this.mov.tipo ===
          "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (a vista)" ||
        this.mov.tipo ===
          "Programa 12S com o Exame Nutrigenético (DNA equilibrio) (parcelado)"
      ) {
        for (var iPo = 0; iPo < 4; iPo++) {
          var nomePo = this.atendimentoTipos[iPo];

          var refIdPo = firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc();
          var idPo = refIdPo.id;

          var startDateNPo =
            aRoundedDate.getTime() + iPo * 28 * 24 * 60 * 60 * 1000;
          var startDatePo = new Date(startDateNPo);
          var endDatePo;
          if (nomePo === "1ª Consulta") {
            var endDateNPo = startDateNPo + 60 * 60 * 1000;
            endDatePo = new Date(endDateNPo);
          } else {
            var endDateNxPo = startDateNPo + 30 * 60 * 1000;
            endDatePo = new Date(endDateNxPo);
          }
          var startMinStrPo;
          var startMinStrXPo = startDatePo.getMinutes();
          if (startMinStrXPo < 10) {
            startMinStrPo = "0" + startMinStrXPo.toString();
          } else {
            startMinStrPo = startMinStrXPo.toString();
          }
          var endMinStrPo;
          var endMinStrXPo = endDatePo.getMinutes();
          if (endMinStrXPo < 10) {
            endMinStrPo = "0" + endMinStrXPo.toString();
          } else {
            endMinStrPo = endMinStrXPo.toString();
          }

          var startTimePo =
            startDatePo.getHours().toString() + ":" + startMinStrPo;
          var endTimePo = endDatePo.getHours().toString() + ":" + endMinStrPo;

          var startYStrXPo = startDatePo.getYear() + 1900;
          var startYStrPo = startYStrXPo.toString();
          var startYStrShortXPo = startDatePo.getYear() - 100;
          var startYStrShortPo = startYStrShortXPo.toString();

          var startMStrPo;
          var startMStrXPo = startDatePo.getMonth() + 1;
          if (startMStrXPo < 10) {
            startMStrPo = "0" + startMStrXPo.toString();
          } else {
            startMStrPo = startMStrXPo.toString();
          }

          var startDStrPo;
          var startDStrXPo = startDatePo.getDate();
          if (startDStrXPo < 10) {
            startDStrPo = "0" + startDStrXPo.toString();
          } else {
            startDStrPo = startDStrXPo.toString();
          }

          var startStrPo =
            startYStrPo +
            "-" +
            startMStrPo +
            "-" +
            startDStrPo +
            " " +
            startTimePo +
            ":00";
          var endStrPo =
            startYStrPo +
            "-" +
            startMStrPo +
            "-" +
            startDStrPo +
            " " +
            endTimePo +
            ":00";
          var diasPtBrPo = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayPo = diasPtBrPo[startDatePo.getDay()];
          var niceDateShortPo =
            dayPo +
            ", " +
            startDStrPo +
            "/" +
            startMStrPo +
            "/" +
            startYStrShortPo;
          var niceDateStrPo =
            dayPo +
            ", " +
            startDStrPo +
            "/" +
            startMStrPo +
            "/" +
            startYStrShortPo +
            ", " +
            startTimePo +
            "-" +
            endTimePo;

          var newDocPo = {
            movMillis: this.mov.millis.toString(),
            name: nomePo,
            niceDate: niceDateStrPo,
            niceDateShort: niceDateShortPo,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStrPo,
            end: endStrPo,
            details: "12S Básico",
            color: nomePo === "1ª Consulta" ? "#FFB400" : "blue",
            timed: true,
            id: idPo, // Math.round(Math.random().multiply(1000000000000)).toString(), //idP,
            startTime: startTimePo,
            endTime: endTimePo,
            autoset: true,
          };

          this.dataToSetList[iPo] = newDocPo;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc(idPo)
            .set(newDocPo);

          await firebase
            .firestore()
            .collection("users")
            .doc(this.mov.paciente)
            .collection("agenda")
            .doc(idPo)
            .set(newDocPo);

          var d_Po = new Date(startStrPo);
          var millis_Po = d_Po.getTime();
          var sub24_Po = 24 * 60 * 60 * 1000;
          var millis_1Po = millis_Po - sub24_Po;
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(idPo)
            .set({
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1Po,
              name: nomePo,
              niceDate: niceDateStrPo,
              niceDateShort: niceDateShortPo,
              patient: this.mov.pacienteName,
              //   patientId: this.$store.state.firestore.mov.paciente,
              patientId: this.mov.paciente,
              newPatient: false,
              start: startStrPo,
              end: endStrPo,
              details: "12S Básico",
              color: nomePo === "1ª Consulta" ? "#FFB400" : "blue",
              timed: true,
              id: idPo, // Math.round(Math.random().multiply(1000000000000)).toString(), //idP,
              startTime: startTimePo,
              endTime: endTimePo,
              autoset: true,
            });
        }
      } else if (this.mov.tipo === "Pacote Estético") {
        console.log("mov", this.mov);
        var pIdpac = this.mov.paciente;
        var pNamepac = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);

        var monthsFromNowList = [];
        var sessoesList = [];

        for (var iPx = 0; iPx < this.mov.pacotes.length; iPx++) {
          var sessoes1pac = this.mov.pacotes[iPx];
          var monthsFromNow = parseInt(sessoes1pac.dataInicio);

          if (sessoes1pac.def1 == "TRIPLO") {
            sessoes1pac.area =
              sessoes1pac.area1 +
              " + " +
              sessoes1pac.area2 +
              " + " +
              sessoes1pac.area3;
          } else if (sessoes1pac.def1 == "DUO") {
            sessoes1pac.area = sessoes1pac.area1; // + " + " + sessoes1pac.area2;
          } else if (sessoes1pac.def1 == "UNO") {
            sessoes1pac.area = sessoes1pac.area1;
          }

          if (sessoes1pac.def2 == "HIBRIUS") {
            sessoes1pac.sessoes = [
              createEvent(
                "HIBRIUS + Massagem (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "HIBRIUS + Massagem (2)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "HIBRIUS + Massagem (3)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "HIBRIUS + Massagem (4)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (sessoes1pac.def2 == "DUALSOON") {
            sessoes1pac.sessoes = [
              createEvent(
                "DUALSOON (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "DUALSOON (2)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "DUALSOON (3)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "DUALSOON (4)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (sessoes1pac.def2 == "ENDERMOTERAPIA") {
            sessoes1pac.sessoes = [
              createEvent(
                "ENDERMOTERAPIA (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "ENDERMOTERAPIA (2)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "ENDERMOTERAPIA (3)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "ENDERMOTERAPIA (4)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (sessoes1pac.def2 == "DRENAGEM LINFATICA") {
            sessoes1pac.sessoes = [
              createEvent(
                "DRENAGEM LINFATICA (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "DRENAGEM LINFATICA (2)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "DRENAGEM LINFATICA (3)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "DRENAGEM LINFATICA (4)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (sessoes1pac.def2 == "MASSAGEM RELAXANTE") {
            sessoes1pac.sessoes = [
              createEvent(
                "MASSAGEM RELAXANTE (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM RELAXANTE (2)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM RELAXANTE (3)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM RELAXANTE (4)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (sessoes1pac.def2 == "REFLEXOLOGIA PODAL (10 sessões)") {
            sessoes1pac.sessoes = [
              createEvent(
                "REFLEXOLOGIA PODAL (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (2)",
                monthsFromNow,
                0,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (3)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (4)",
                monthsFromNow,
                1,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (5)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (6)",
                monthsFromNow,
                2,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (7)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (8)",
                monthsFromNow,
                3,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (9)",
                monthsFromNow,
                4,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (10)",
                monthsFromNow,
                4,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (
            sessoes1pac.def2 == "REFLEXOLOGIA PODAL + ESCALDA PES (8 sessões)"
          ) {
            sessoes1pac.sessoes = [
              createEvent(
                "REFLEXOLOGIA PODAL + ESCALDA PES (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL + ESCALDA PES (2)",
                monthsFromNow,
                0,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL + ESCALDA PES (3)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL + ESCALDA PES (4)",
                monthsFromNow,
                1,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL + ESCALDA PES (5)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL + ESCALDA PES (6)",
                monthsFromNow,
                2,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL + ESCALDA PES (7)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL + ESCALDA PES (8)",
                monthsFromNow,
                3,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (sessoes1pac.def2 == "TERAPIA COM VENTOSAS (10 sessões)") {
            sessoes1pac.sessoes = [
              createEvent(
                "TERAPIA COM VENTOSAS (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "TERAPIA COM VENTOSAS (2)",
                monthsFromNow,
                0,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "TERAPIA COM VENTOSAS (3)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "TERAPIA COM VENTOSAS (4)",
                monthsFromNow,
                1,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "TERAPIA COM VENTOSAS (5)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "TERAPIA COM VENTOSAS (6)",
                monthsFromNow,
                2,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "TERAPIA COM VENTOSAS (7)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "TERAPIA COM VENTOSAS (8)",
                monthsFromNow,
                3,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "TERAPIA COM VENTOSAS (9)",
                monthsFromNow,
                4,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "TERAPIA COM VENTOSAS (10)",
                monthsFromNow,
                4,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (sessoes1pac.def2 == "MASSAGEM REDUTORA") {
            sessoes1pac.sessoes = [
              createEvent(
                "MASSAGEM REDUTORA (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (2)",
                monthsFromNow,
                0,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (3)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (4)",
                monthsFromNow,
                1,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (5)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (6)",
                monthsFromNow,
                2,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (7)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (8)",
                monthsFromNow,
                3,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (sessoes1pac.def2 == "CORRENTE RUSSA") {
            sessoes1pac.sessoes = [
              createEvent(
                "CORRENTE RUSSA (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (2)",
                monthsFromNow,
                0,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (3)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (4)",
                monthsFromNow,
                1,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (5)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (6)",
                monthsFromNow,
                2,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (7)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (8)",
                monthsFromNow,
                3,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                30
              ),
            ];
          } else if (sessoes1pac.def2 == "AURICULOTERAPIA (10 sessões)") {
            sessoes1pac.sessoes = [
              createEvent(
                "AURICULOTERAPIA (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "AURICULOTERAPIA (2)",
                monthsFromNow,
                0,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "AURICULOTERAPIA (3)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "AURICULOTERAPIA (4)",
                monthsFromNow,
                1,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "AURICULOTERAPIA (5)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "AURICULOTERAPIA (6)",
                monthsFromNow,
                2,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "AURICULOTERAPIA (7)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "AURICULOTERAPIA (8)",
                monthsFromNow,
                3,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "AURICULOTERAPIA (9)",
                monthsFromNow,
                4,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "AURICULOTERAPIA (10)",
                monthsFromNow,
                4,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
            ];
          } else if (sessoes1pac.def2 == "DRY NEEDLING (10 sessões)") {
            sessoes1pac.sessoes = [
              createEvent(
                "DRY NEEDLING (1)",
                monthsFromNow,
                0,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "DRY NEEDLING (2)",
                monthsFromNow,
                0,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "DRY NEEDLING (3)",
                monthsFromNow,
                1,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "DRY NEEDLING (4)",
                monthsFromNow,
                1,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "DRY NEEDLING (5)",
                monthsFromNow,
                2,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "DRY NEEDLING (6)",
                monthsFromNow,
                2,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "DRY NEEDLING (7)",
                monthsFromNow,
                3,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "DRY NEEDLING (8)",
                monthsFromNow,
                3,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "DRY NEEDLING (9)",
                monthsFromNow,
                4,
                0,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
              createEvent(
                "DRY NEEDLING (10)",
                monthsFromNow,
                4,
                3,
                pIdpac,
                pNamepac,
                sessoes1pac.area,
                60
              ),
            ];
          } else {
            sessoes1pac.sessoes = [];
          }

          console.log("sessoes1", sessoes1pac);
          sessoes1pac.sessoes.forEach(async function(sess) {
            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("agendaEstetica")
              .doc(sess.id)
              .set(sess);

            await firebase
              .firestore()
              .collection("users")
              .doc(pIdpac)
              .collection("agenda")
              .doc(sess.id)
              .set(sess);
            var d = new Date();
            var millis = d.getTime();
            var sub24 = 24 * 60 * 60 * 1000;
            var millis_1 = millis - sub24;

            var sessDoc = {
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1,
              name: sess.name,
              niceDate: sess.niceDate,
              niceDateShort: sess.niceDateShort,
              patient: sess.patient,
              patientId: sess.patientId,
              newPatient: sess.newPatient,
              start: sess.start,
              end: sess.end,
              details: sess.details,
              id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
              startTime: sess.startTime,
              endTime: sess.endTime,
              autoset: true,
              area: sess.area,
            };

            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("lembretes")
              .doc(sess.id)
              .set(sessDoc);
          });
          monthsFromNowList.push(monthsFromNow);
          sessoesList.push(sessoes1pac);
        }

        this.referredMesPacList = monthsFromNowList
          .filter(function(item, pos) {
            return monthsFromNowList.indexOf(item) == pos;
          })
          .map(function(mfN) {
            var d = new Date();
            var m = d.getMonth();
            var y = d.getFullYear();
            var monthTexts = [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Augosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Decembro",
            ];
            var monthIndex = m + mfN;
            if (monthIndex > 11) {
              monthIndex = monthIndex - 11;
              y = y + 1;
            }
            return {
              mesName: monthTexts[monthIndex] + "/" + y,
              pacs: sessoesList.filter(function(el) {
                return el.dataInicio == mfN;
              }),
            };
          });
      } else if (
        this.mov.tipo === "Programa 12S com Estética" ||
        this.mov.tipo === "Programa Clínico Funcional com Estética" ||
        this.mov.tipo === "Programa Modulação Intestinal com Estética"
      ) {
        for (var iPo12 = 0; iPo12 < 4; iPo12++) {
          var nomePo12 = this.atendimentoTipos[iPo12];

          var refIdPo12 = firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc();
          var idPo12 = refIdPo12.id;

          var startDateNPo12 =
            aRoundedDate.getTime() + iPo12 * 28 * 24 * 60 * 60 * 1000;
          var startDatePo12 = new Date(startDateNPo12);
          var endDatePo12;
          if (nomePo12 === "1ª Consulta") {
            var endDateNPo12 = startDateNPo12 + 60 * 60 * 1000;
            endDatePo12 = new Date(endDateNPo12);
          } else {
            var endDateNxPo12 = startDateNPo12 + 30 * 60 * 1000;
            endDatePo12 = new Date(endDateNxPo12);
          }
          var startMinStrPo12;
          var startMinStrXPo12 = startDatePo12.getMinutes();
          if (startMinStrXPo12 < 10) {
            startMinStrPo12 = "0" + startMinStrXPo12.toString();
          } else {
            startMinStrPo12 = startMinStrXPo12.toString();
          }
          var endMinStrPo12;
          var endMinStrXPo12 = endDatePo12.getMinutes();
          if (endMinStrXPo12 < 10) {
            endMinStrPo12 = "0" + endMinStrXPo12.toString();
          } else {
            endMinStrPo12 = endMinStrXPo12.toString();
          }

          var startTimePo12 =
            startDatePo12.getHours().toString() + ":" + startMinStrPo12;
          var endTimePo12 =
            endDatePo12.getHours().toString() + ":" + endMinStrPo12;

          var startYStrXPo12 = startDatePo12.getYear() + 1900;
          var startYStrPo12 = startYStrXPo12.toString();
          var startYStrShortXPo12 = startDatePo12.getYear() - 100;
          var startYStrShortPo12 = startYStrShortXPo12.toString();

          var startMStrPo12;
          var startMStrXPo12 = startDatePo12.getMonth() + 1;
          if (startMStrXPo12 < 10) {
            startMStrPo12 = "0" + startMStrXPo12.toString();
          } else {
            startMStrPo12 = startMStrXPo12.toString();
          }

          var startDStrPo12;
          var startDStrXPo12 = startDatePo12.getDate();
          if (startDStrXPo12 < 10) {
            startDStrPo12 = "0" + startDStrXPo12.toString();
          } else {
            startDStrPo12 = startDStrXPo12.toString();
          }

          var startStrPo12 =
            startYStrPo12 +
            "-" +
            startMStrPo12 +
            "-" +
            startDStrPo12 +
            " " +
            startTimePo12 +
            ":00";
          var endStrPo12 =
            startYStrPo12 +
            "-" +
            startMStrPo12 +
            "-" +
            startDStrPo12 +
            " " +
            endTimePo12 +
            ":00";
          var diasPtBrPo12 = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayPo12 = diasPtBrPo12[startDatePo12.getDay()];
          var niceDateShortPo12 =
            dayPo12 +
            ", " +
            startDStrPo12 +
            "/" +
            startMStrPo12 +
            "/" +
            startYStrShortPo12;
          var niceDateStrPo12 =
            dayPo12 +
            ", " +
            startDStrPo12 +
            "/" +
            startMStrPo12 +
            "/" +
            startYStrShortPo12 +
            ", " +
            startTimePo12 +
            "-" +
            endTimePo12;

          var newDocPo12 = {
            movMillis: this.mov.millis.toString(),
            name: nomePo12,
            niceDate: niceDateStrPo12,
            niceDateShort: niceDateShortPo12,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStrPo12,
            end: endStrPo12,
            details: "12S",
            color: nomePo12 === "1ª Consulta" ? "#FFB400" : "blue",
            timed: true,
            id: idPo12, // Math.round(Math.random().multiply(1000000000000)).toString(), //idP,
            startTime: startTimePo12,
            endTime: endTimePo12,
            autoset: true,
          };

          this.dataToSetList[iPo12] = newDocPo12;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc(idPo12)
            .set(newDocPo12);

          await firebase
            .firestore()
            .collection("users")
            .doc(this.mov.paciente)
            .collection("agenda")
            .doc(idPo12)
            .set(newDocPo12);

          var d_Po12 = new Date(startStrPo12);
          var millis_Po12 = d_Po12.getTime();
          var sub24_Po12 = 24 * 60 * 60 * 1000;
          var millis_1Po12 = millis_Po12 - sub24_Po12;
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(idPo12)
            .set({
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1Po12,
              name: nomePo12,
              niceDate: niceDateStrPo12,
              niceDateShort: niceDateShortPo12,
              patient: this.mov.pacienteName,
              //   patientId: this.$store.state.firestore.mov.paciente,
              patientId: this.mov.paciente,
              newPatient: false,
              start: startStrPo12,
              end: endStrPo12,
              details: this.mov.tipo,
              color: nomePo12 === "1ª Consulta" ? "#FFB400" : "blue",
              timed: true,
              id: idPo12, // Math.round(Math.random().multiply(1000000000000)).toString(), //idP,
              startTime: startTimePo12,
              endTime: endTimePo12,
              autoset: true,
            });
        }

        console.log("mov", this.mov);
        var pIdpac12s = this.mov.paciente;
        var pNamepac12s = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);

        var monthsFromNowList12s = [];
        var sessoesList12s = [];

        for (var iP12s = 0; iP12s < this.mov.pacotes.length; iP12s++) {
          var sessoes1pac12s = this.mov.pacotes[iP12s];
          var monthsFromNow12s = parseInt(sessoes1pac12s.dataInicio);
          sessoes1pac12s.area = sessoes1pac12s.area1;

          if (sessoes1pac12s.def2 == "HIBRIUS") {
            sessoes1pac12s.sessoes = [
              createEvent(
                "HIBRIUS + Massagem (1)",
                monthsFromNow12s,
                0,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "HIBRIUS + Massagem (2)",
                monthsFromNow12s,
                1,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "HIBRIUS + Massagem (3)",
                monthsFromNow12s,
                2,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "HIBRIUS + Massagem (4)",
                monthsFromNow12s,
                3,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
            ];
          } else if (sessoes1pac12s.def2 == "DUALSOON") {
            sessoes1pac12s.sessoes = [
              createEvent(
                "DUALSOON (1)",
                monthsFromNow12s,
                0,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "DUALSOON (2)",
                monthsFromNow12s,
                1,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "DUALSOON (3)",
                monthsFromNow12s,
                2,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "DUALSOON (4)",
                monthsFromNow12s,
                3,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
            ];
          } else if (sessoes1pac12s.def2 == "ENDERMOTERAPIA") {
            sessoes1pac12s.sessoes = [
              createEvent(
                "ENDERMOTERAPIA (1)",
                monthsFromNow12s,
                0,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "ENDERMOTERAPIA (2)",
                monthsFromNow12s,
                1,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "ENDERMOTERAPIA (3)",
                monthsFromNow12s,
                2,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "ENDERMOTERAPIA (4)",
                monthsFromNow12s,
                3,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
            ];
          } else if (sessoes1pac12s.def2 == "DRENAGEM LINFATICA") {
            sessoes1pac12s.sessoes = [
              createEvent(
                "DRENAGEM LINFATICA (1)",
                monthsFromNow12s,
                0,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "DRENAGEM LINFATICA (2)",
                monthsFromNow12s,
                1,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "DRENAGEM LINFATICA (3)",
                monthsFromNow12s,
                2,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "DRENAGEM LINFATICA (4)",
                monthsFromNow12s,
                3,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
            ];
          } else if (sessoes1pac12s.def2 == "MASSAGEM RELAXANTE") {
            sessoes1pac12s.sessoes = [
              createEvent(
                "MASSAGEM RELAXANTE (1)",
                monthsFromNow12s,
                0,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM RELAXANTE (2)",
                monthsFromNow12s,
                1,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM RELAXANTE (3)",
                monthsFromNow12s,
                2,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM RELAXANTE (4)",
                monthsFromNow12s,
                3,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
            ];
          } else if (sessoes1pac12s.def2 == "REFLEXOLOGIA PODAL") {
            sessoes1pac12s.sessoes = [
              createEvent(
                "REFLEXOLOGIA PODAL (1)",
                monthsFromNow12s,
                0,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (2)",
                monthsFromNow12s,
                0,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (3)",
                monthsFromNow12s,
                1,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (4)",
                monthsFromNow12s,
                1,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (5)",
                monthsFromNow12s,
                2,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (6)",
                monthsFromNow12s,
                2,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (7)",
                monthsFromNow12s,
                3,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "REFLEXOLOGIA PODAL (8)",
                monthsFromNow12s,
                3,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
            ];
          } else if (sessoes1pac12s.def2 == "MASSAGEM REDUTORA") {
            sessoes1pac12s.sessoes = [
              createEvent(
                "MASSAGEM REDUTORA (1)",
                monthsFromNow12s,
                0,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (2)",
                monthsFromNow12s,
                0,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (3)",
                monthsFromNow12s,
                1,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (4)",
                monthsFromNow12s,
                1,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (5)",
                monthsFromNow12s,
                2,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (6)",
                monthsFromNow12s,
                2,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (7)",
                monthsFromNow12s,
                3,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "MASSAGEM REDUTORA (8)",
                monthsFromNow12s,
                3,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
            ];
          } else if (sessoes1pac12s.def2 == "CORRENTE RUSSA") {
            sessoes1pac12s.sessoes = [
              createEvent(
                "CORRENTE RUSSA (1)",
                monthsFromNow12s,
                0,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (2)",
                monthsFromNow12s,
                0,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (3)",
                monthsFromNow12s,
                1,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (4)",
                monthsFromNow12s,
                1,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (5)",
                monthsFromNow12s,
                2,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (6)",
                monthsFromNow12s,
                2,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (7)",
                monthsFromNow12s,
                3,
                0,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
              createEvent(
                "CORRENTE RUSSA (8)",
                monthsFromNow12s,
                3,
                3,
                pIdpac12s,
                pNamepac12s,
                sessoes1pac12s.area,
                30
              ),
            ];
          } else {
            sessoes1pac12s.sessoes = [];
          }

          console.log("sessoes1", sessoes1pac12s);
          sessoes1pac12s.sessoes.forEach(async function(sess) {
            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("agendaEstetica")
              .doc(sess.id)
              .set(sess);

            await firebase
              .firestore()
              .collection("users")
              .doc(pIdpac12s)
              .collection("agenda")
              .doc(sess.id)
              .set(sess);
            var d = new Date();
            var millis = d.getTime();
            var sub24 = 24 * 60 * 60 * 1000;
            var millis_1 = millis - sub24;

            var sessDoc = {
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1,
              name: sess.name,
              niceDate: sess.niceDate,
              niceDateShort: sess.niceDateShort,
              patient: sess.patient,
              patientId: sess.patientId,
              newPatient: sess.newPatient,
              start: sess.start,
              end: sess.end,
              details: sess.details,
              id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
              startTime: sess.startTime,
              endTime: sess.endTime,
              autoset: true,
              area: sess.area,
            };

            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("lembretes")
              .doc(sess.id)
              .set(sessDoc);
          });
          monthsFromNowList12s.push(monthsFromNow12s);
          sessoesList12s.push(sessoes1pac12s);
        }

        this.referredMesPacList = monthsFromNowList12s
          .filter(function(item, pos) {
            return monthsFromNowList12s.indexOf(item) == pos;
          })
          .map(function(mfN) {
            var d = new Date();
            var m = d.getMonth();
            var y = d.getFullYear();
            var monthTexts = [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Augosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Decembro",
            ];
            var monthIndex = m + mfN;
            if (monthIndex > 11) {
              monthIndex = monthIndex - 11;
              y = y + 1;
            }
            return {
              mesName: monthTexts[monthIndex] + "/" + y,
              pacs: sessoesList12s.filter(function(el) {
                return el.dataInicio == mfN;
              }),
            };
          });
      } else if (this.mov.tipo === "1 Consulta") {
        var nome1 = this.atendimentoTipos[0];

        var refId1 = firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agenda")
          .doc();
        var id1 = refId1.id;

        var startDateN1 = aRoundedDate.getTime();
        var startDate1 = new Date(startDateN);
        var endDate1;
        var endDateN1 = startDateN1 + 60 * 60 * 1000;
        endDate1 = new Date(endDateN1);
        var startMinStr1;
        var startMinStrX1 = startDate1.getMinutes();
        if (startMinStrX1 < 10) {
          startMinStr1 = "0" + startMinStrX1.toString();
        } else {
          startMinStr1 = startMinStrX1.toString();
        }
        var endMinStr1;
        var endMinStrX1 = endDate1.getMinutes();
        if (endMinStrX1 < 10) {
          endMinStr1 = "0" + endMinStrX1.toString();
        } else {
          endMinStr1 = endMinStrX1.toString();
        }

        var startTime1 = startDate1.getHours().toString() + ":" + startMinStr1;
        var endTime1 = endDate1.getHours().toString() + ":" + endMinStr1;

        var startYStrX1 = startDate1.getYear() + 1900;
        var startYStr1 = startYStrX1.toString();
        var startYStrShortX1 = startDate1.getYear() - 100;
        var startYStrShort1 = startYStrShortX1.toString();

        var startMStr1;
        var startMStrX1 = startDate1.getMonth() + 1;
        if (startMStrX1 < 10) {
          startMStr1 = "0" + startMStrX1.toString();
        } else {
          startMStr1 = startMStrX1.toString();
        }

        var startDStr1;
        var startDStrX1 = startDate1.getDate();
        if (startDStrX1 < 10) {
          startDStr1 = "0" + startDStrX1.toString();
        } else {
          startDStr1 = startDStrX1.toString();
        }

        var startStr1 =
          startYStr1 +
          "-" +
          startMStr1 +
          "-" +
          startDStr1 +
          " " +
          startTime1 +
          ":00";
        var endStr1 =
          startYStr1 +
          "-" +
          startMStr1 +
          "-" +
          startDStr1 +
          " " +
          endTime1 +
          ":00";
        var diasPtBr1 = [
          "Dom.",
          "Seg.",
          "Ter.",
          "Qua.",
          "Qui.",
          "Sex.",
          "Sab.",
        ];
        var day1 = diasPtBr1[startDate1.getDay()];
        var niceDateShort1 =
          day1 + ", " + startDStr1 + "/" + startMStr1 + "/" + startYStrShort1;
        var niceDateStr1 =
          day1 +
          ", " +
          startDStr1 +
          "/" +
          startMStr1 +
          "/" +
          startYStrShort1 +
          ", " +
          startTime1 +
          "-" +
          endTime1;

        var newDoc1 = {
          movMillis: this.mov.millis.toString(),
          name: nome1,
          niceDate: niceDateStr1,
          niceDateShort: niceDateShort1,
          patient: this.mov.pacienteName,
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: this.mov.paciente,
          newPatient: false,
          start: startStr1,
          end: endStr1,
          details: "Agendamento provisório",
          color: "red",
          timed: true,
          id: id1,
          startTime: startTime1,
          endTime: endTime1,
          autoset: true,
        };

        this.dataToSetList[0] = newDoc1;

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agenda")
          .doc(id1)
          .set(newDoc1);

        await firebase
          .firestore()
          .collection("users")
          .doc(this.mov.paciente)
          .collection("agenda")
          .doc(id1)
          .set(newDoc1);
        var d11 = new Date(startStr1);
        var millis11 = d11.getTime();
        var sub2411 = 24 * 60 * 60 * 1000;
        var millis_11 = millis11 - sub2411;
        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("lembretes")
          .doc(id1)
          .set({
            tipo: "confirm",
            done: false,
            status: "notDueYet",
            dueDate: millis_11,
            name: nome1,
            niceDate: niceDateStr1,
            niceDateShort: niceDateShort1,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStr1,
            end: endStr1,
            details: "Agendamento provisório",
            color: "red",
            timed: true,
            id: id1,
            startTime: startTime1,
            endTime: endTime1,
            autoset: true,
          });
      } else if (this.mov.tipo === "Sessão Estética") {
        var duration = 30;
        if (this.mov.descr === "Sessão de MASSAGEM RELAXANTE") {
          duration = 60;
        }
        if (
          this.mov.descr ===
          "Sessão de MASSAGEM MODULADORA ou DRENAGEM CORPO TODO"
        ) {
          duration = 60;
        }
        var nome2 = this.mov.descr;

        var refId2 = firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agenda")
          .doc();
        var id2 = refId2.id;

        var startDateN2 = aRoundedDate.getTime();
        var startDate2 = new Date(startDateN);
        var endDate2;
        var endDateN2 = startDateN2 + duration * 60 * 1000;
        endDate2 = new Date(endDateN2);
        var startMinStr2;
        var startMinStrX2 = startDate2.getMinutes();
        if (startMinStrX2 < 10) {
          startMinStr2 = "0" + startMinStrX2.toString();
        } else {
          startMinStr2 = startMinStrX2.toString();
        }
        var endMinStr2;
        var endMinStrX2 = endDate2.getMinutes();
        if (endMinStrX2 < 10) {
          endMinStr2 = "0" + endMinStrX2.toString();
        } else {
          endMinStr2 = endMinStrX2.toString();
        }

        var startTime2 = startDate2.getHours().toString() + ":" + startMinStr2;
        var endTime2 = endDate2.getHours().toString() + ":" + endMinStr2;

        var startYStrX2 = startDate2.getYear() + 1900;
        var startYStr2 = startYStrX2.toString();
        var startYStrShortX2 = startDate2.getYear() - 100;
        var startYStrShort2 = startYStrShortX2.toString();

        var startMStr2;
        var startMStrX2 = startDate2.getMonth() + 1;
        if (startMStrX2 < 10) {
          startMStr2 = "0" + startMStrX2.toString();
        } else {
          startMStr2 = startMStrX2.toString();
        }

        var startDStr2;
        var startDStrX2 = startDate2.getDate();
        if (startDStrX2 < 10) {
          startDStr2 = "0" + startDStrX2.toString();
        } else {
          startDStr2 = startDStrX2.toString();
        }

        var startStr2 =
          startYStr2 +
          "-" +
          startMStr2 +
          "-" +
          startDStr2 +
          " " +
          startTime2 +
          ":00";
        var endStr2 =
          startYStr2 +
          "-" +
          startMStr2 +
          "-" +
          startDStr2 +
          " " +
          endTime2 +
          ":00";
        var diasPtBr2 = [
          "Dom.",
          "Seg.",
          "Ter.",
          "Qua.",
          "Qui.",
          "Sex.",
          "Sab.",
        ];
        var day2 = diasPtBr2[startDate2.getDay()];
        var niceDateShort2 =
          day2 + ", " + startDStr2 + "/" + startMStr2 + "/" + startYStrShort2;
        var niceDateStr2 =
          day2 +
          ", " +
          startDStr2 +
          "/" +
          startMStr2 +
          "/" +
          startYStrShort2 +
          ", " +
          startTime2 +
          "-" +
          endTime2;

        var newDoc2 = {
          movMillis: this.mov.millis.toString(),
          name: nome2,
          niceDate: niceDateStr2,
          niceDateShort: niceDateShort2,
          patient: this.mov.pacienteName,
          //   patientId: this.$store.state.firestore.mov.paciente,
          patientId: this.mov.paciente,
          newPatient: false,
          start: startStr2,
          end: endStr2,
          details: "Agendamento provisório",
          color: "red",
          timed: true,
          id: id2,
          startTime: startTime2,
          endTime: endTime2,
          autoset: true,
        };

        this.dataToSetList[0] = newDoc2;

        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("agendaEstetica")
          .doc(id2)
          .set(newDoc2);

        await firebase
          .firestore()
          .collection("users")
          .doc(this.mov.paciente)
          .collection("agenda")
          .doc(id2)
          .set(newDoc2);
        var d2 = new Date(startStr2);
        var millis2 = d2.getTime();
        var sub242 = 24 * 60 * 60 * 1000;
        var millis22 = millis2 - sub242;
        await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("lembretes")
          .doc(id2)
          .set({
            tipo: "confirm",
            done: false,
            status: "notDueYet",
            dueDate: millis22,
            name: nome2,
            niceDate: niceDateStr2,
            niceDateShort: niceDateShort2,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStr2,
            end: endStr2,
            details: "Agendamento provisório",
            color: "red",
            timed: true,
            id: id2,
            startTime: startTime2,
            endTime: endTime2,
            autoset: true,
          });
      } else if (this.mov.tipo === "Pacote 90 dias Protocolo Kirchhoff") {
        for (var jk = 0; jk < 3; jk++) {
          var nomeJ = this.atendimentoTiposKirchhoff[jk];

          var refIdJ = firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc();
          var idJ = refIdJ.id;

          var startDateNJ =
            aRoundedDate.getTime() + jk * 30 * 24 * 60 * 60 * 1000;
          var startDateJ = new Date(startDateNJ);
          var endDateJ;
          var endDateNJx = startDateNJ + 30 * 60 * 1000;
          endDateJ = new Date(endDateNJx);
          var startMinStrJ;
          var startMinStrXJ = startDateJ.getMinutes();
          if (startMinStrXJ < 10) {
            startMinStrJ = "0" + startMinStrXJ.toString();
          } else {
            startMinStrJ = startMinStrXJ.toString();
          }
          var endMinStrJ;
          var endMinStrXJ = endDateJ.getMinutes();
          if (endMinStrXJ < 10) {
            endMinStrJ = "0" + endMinStrXJ.toString();
          } else {
            endMinStrJ = endMinStrXJ.toString();
          }

          var startTimeJ =
            startDateJ.getHours().toString() + ":" + startMinStrJ;
          var endTimeJ = endDateJ.getHours().toString() + ":" + endMinStrJ;

          var startYStrXJ = startDateJ.getYear() + 1900;
          var startYStrJ = startYStrXJ.toString();
          var startYStrShortXJ = startDateJ.getYear() - 100;
          var startYStrShortJ = startYStrShortXJ.toString();

          var startMStrJ;
          var startMStrXJ = startDateJ.getMonth() + 1;
          if (startMStrXJ < 10) {
            startMStrJ = "0" + startMStrXJ.toString();
          } else {
            startMStrJ = startMStrXJ.toString();
          }

          var startDStrJ;
          var startDStrXJ = startDateJ.getDate();
          if (startDStrX < 10) {
            startDStrJ = "0" + startDStrXJ.toString();
          } else {
            startDStrJ = startDStrXJ.toString();
          }

          var startStrJ =
            startYStrJ +
            "-" +
            startMStrJ +
            "-" +
            startDStrJ +
            " " +
            startTimeJ +
            ":00";
          var endStrJ =
            startYStrJ +
            "-" +
            startMStrJ +
            "-" +
            startDStrJ +
            " " +
            endTimeJ +
            ":00";
          var diasPtBrJ = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayJ = diasPtBrJ[startDateJ.getDay()];
          var niceDateShortJ =
            day + ", " + startDStrJ + "/" + startMStrJ + "/" + startYStrShortJ;
          var niceDateStrJ =
            dayJ +
            ", " +
            startDStrJ +
            "/" +
            startMStrJ +
            "/" +
            startYStrShortJ +
            ", " +
            startTimeJ +
            "-" +
            endTimeJ;

          var newDocJ = {
            movMillis: this.mov.millis.toString(),
            name: nomeJ,
            niceDate: niceDateStrJ,
            niceDateShort: niceDateShortJ,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStrJ,
            end: endStrJ,
            details: "Protocolo Kirchhoff",
            color: this.atendimentoTiposColor[nomeJ],
            timed: true,
            id: idJ,
            startTime: startTimeJ,
            endTime: endTimeJ,
            autoset: true,
          };

          this.dataToSetList[jk] = newDocJ;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc(idJ)
            .set(newDocJ);

          await firebase
            .firestore()
            .collection("users")
            .doc(this.mov.paciente)
            .collection("agenda")
            .doc(idJ)
            .set(newDocJ);
          var dJ = new Date(startStrJ);
          var millisJ = dJ.getTime();
          var sub24J = 24 * 60 * 60 * 1000;
          var millis_1_J = millisJ - sub24J;
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(idJ)
            .set({
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1_J,
              name: nomeJ,
              niceDate: niceDateStrJ,
              niceDateShort: niceDateShortJ,
              patient: this.mov.pacienteName,
              //   patientId: this.$store.state.firestore.mov.paciente,
              patientId: this.mov.paciente,
              newPatient: false,
              start: startStrJ,
              end: endStrJ,
              details: "Protocolo Kirchhoff",
              color: this.atendimentoTiposColor[nomeJ],
              timed: true,
              id: idJ,
              startTime: startTimeJ,
              endTime: endTimeJ,
              autoset: true,
            });
        }
      } else if (this.mov.tipo === "Protocolo Harmonização Corporal 24H") {
        for (var iP_H = 0; iP_H < 2; iP_H++) {
          var HatendimentoTipos = [
            "1ª Consulta Protocolo Harmonização Corporal 24H",
            "Avaliação Final Protocolo Harmonização Corporal 24H",
          ];
          var nomeP_H = HatendimentoTipos[iP_H];

          var refIdP_H = firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc();
          var idP_H = refIdP_H.id;

          var startDateNP_H =
            aRoundedDate.getTime() + iP_H * 28 * 24 * 60 * 60 * 1000;
          var startDateP_H = new Date(startDateNP_H);
          var endDateP_H;
          if (nomeP_H === "1ª Consulta Protocolo Harmonização Corporal 24H") {
            var endDateNP_H = startDateNP_H + 60 * 60 * 1000;
            endDateP_H = new Date(endDateNP_H);
          } else {
            var endDateNxP_H = startDateNP_H + 30 * 60 * 1000;
            endDateP_H = new Date(endDateNxP_H);
          }
          var startMinStrP_H;
          var startMinStrXP_H = startDateP_H.getMinutes();
          if (startMinStrXP_H < 10) {
            startMinStrP_H = "0" + startMinStrXP_H.toString();
          } else {
            startMinStrP_H = startMinStrXP_H.toString();
          }
          var endMinStrP_H;
          var endMinStrXP_H = endDateP_H.getMinutes();
          if (endMinStrXP_H < 10) {
            endMinStrP_H = "0" + endMinStrXP_H.toString();
          } else {
            endMinStrP_H = endMinStrXP_H.toString();
          }

          var startTimeP_H =
            startDateP_H.getHours().toString() + ":" + startMinStrP_H;
          var endTimeP_H =
            endDateP_H.getHours().toString() + ":" + endMinStrP_H;

          var startYStrXP_H = startDateP_H.getYear() + 1900;
          var startYStrP_H = startYStrXP_H.toString();
          var startYStrShortXP_H = startDateP_H.getYear() - 100;
          var startYStrShortP_H = startYStrShortXP_H.toString();

          var startMStrP_H;
          var startMStrXP_H = startDateP_H.getMonth() + 1;
          if (startMStrXP_H < 10) {
            startMStrP_H = "0" + startMStrXP_H.toString();
          } else {
            startMStrP_H = startMStrXP_H.toString();
          }

          var startDStrP_H;
          var startDStrXP_H = startDateP_H.getDate();
          if (startDStrXP_H < 10) {
            startDStrP_H = "0" + startDStrXP_H.toString();
          } else {
            startDStrP_H = startDStrXP_H.toString();
          }

          var startStrP_H =
            startYStrP_H +
            "-" +
            startMStrP_H +
            "-" +
            startDStrP_H +
            " " +
            startTimeP_H +
            ":00";
          var endStrP_H =
            startYStrP_H +
            "-" +
            startMStrP_H +
            "-" +
            startDStrP_H +
            " " +
            endTimeP_H +
            ":00";
          var diasPtBrP_H = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayP_H = diasPtBrP_H[startDateP_H.getDay()];
          var niceDateShortP_H =
            dayP_H +
            ", " +
            startDStrP_H +
            "/" +
            startMStrP_H +
            "/" +
            startYStrShortP_H;
          var niceDateStrP_H =
            dayP_H +
            ", " +
            startDStrP_H +
            "/" +
            startMStrP_H +
            "/" +
            startYStrShortP_H +
            ", " +
            startTimeP_H +
            "-" +
            endTimeP_H;

          var newDocP_H = {
            movMillis: this.mov.millis.toString(),
            name: nomeP_H,
            niceDate: niceDateStrP_H,
            niceDateShort: niceDateShortP_H,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStrP_H,
            end: endStrP_H,
            details: "Protocolo Harmonização Corporal 24H",
            color: nomeP_H === "1ª Consulta" ? "#FFB400" : "blue",
            timed: true,
            id: idP_H,
            startTime: startTimeP_H,
            endTime: endTimeP_H,
            autoset: true,
          };

          this.dataToSetList[iP_H] = newDocP_H;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc(idP_H)
            .set(newDocP_H);

          await firebase
            .firestore()
            .collection("users")
            .doc(this.mov.paciente)
            .collection("agenda")
            .doc(idP_H)
            .set(newDocP_H);
          var dH = new Date(startStrP_H);
          var millisH = dH.getTime();
          var sub24H = 24 * 60 * 60 * 1000;
          var millis_1_H = millisH - sub24H;
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(idP_H)
            .set({
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1_H,
              name: nomeP_H,
              niceDate: niceDateStrP_H,
              niceDateShort: niceDateShortP_H,
              patient: this.mov.pacienteName,
              //   patientId: this.$store.state.firestore.mov.paciente,
              patientId: this.mov.paciente,
              newPatient: false,
              start: startStrP_H,
              end: endStrP_H,
              details: "Protocolo Harmonização Corporal 24H",
              color: nomeP_H === "1ª Consulta" ? "#FFB400" : "blue",
              timed: true,
              id: idP_H,
              startTime: startTimeP_H,
              endTime: endTimeP_H,
              autoset: true,
            });
        }

        console.log("mov", this.mov);
        var pIdP_H = this.mov.paciente;
        var pNameP_H = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);
        var sessoes1P_H = this.mov.pacotes[0];
        var areaStrP_H = sessoes1P_H.area1 + " + " + sessoes1P_H.area2;

        sessoes1P_H.sessoes = [
          createEvent12S(
            "Massagem Modeladora (1)",
            0,
            2,
            pIdP_H,
            pNameP_H,
            areaStrP_H,
            30
          ),
          createEvent12S(
            "Massagem Modeladora (2)",
            0,
            5,
            pIdP_H,
            pNameP_H,
            areaStrP_H,
            30
          ),
          createEvent12S(
            "Massagem Modeladora (3)",
            1,
            2,
            pIdP_H,
            pNameP_H,
            areaStrP_H,
            30
          ),
          createEvent12S(
            "Massagem Modeladora (4)",
            1,
            5,
            pIdP_H,
            pNameP_H,
            areaStrP_H,
            30
          ),
          createEvent12S(
            "Massagem Modeladora (5)",
            2,
            2,
            pIdP_H,
            pNameP_H,
            areaStrP_H,
            30
          ),
          createEvent12S(
            "Massagem Modeladora (6)",
            2,
            5,
            pIdP_H,
            pNameP_H,
            areaStrP_H,
            30
          ),
          createEvent12S(
            "Massagem Modeladora (7)",
            3,
            2,
            pIdP_H,
            pNameP_H,
            areaStrP_H,
            30
          ),
          createEvent12S(
            "Massagem Modeladora (8)",
            3,
            5,
            pIdP_H,
            pNameP_H,
            areaStrP_H,
            30
          ),
        ];

        console.log("sessoes1", sessoes1P_H);
        // sessoes1P_3.forEach(function (pac) {
        //   console.log("pac must have .sessoes", pac);
        sessoes1P_H.sessoes.forEach(async function(sess) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agendaEstetica")
            .doc(sess.id)
            .set(sess);

          await firebase
            .firestore()
            .collection("users")
            .doc(pIdP_H)
            .collection("agenda")
            .doc(sess.id)
            .set(sess);
          var d = new Date();
          var millis = d.getTime();
          var sub24 = 24 * 60 * 60 * 1000;
          var millis_1 = millis - sub24;

          var sessDoc = {
            tipo: "confirm",
            done: false,
            status: "notDueYet",
            dueDate: millis_1,
            name: sess.name,
            niceDate: sess.niceDate,
            niceDateShort: sess.niceDateShort,
            patient: sess.patient,
            patientId: sess.patientId,
            newPatient: sess.newPatient,
            start: sess.start,
            end: sess.end,
            details: sess.details,
            id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
            startTime: sess.startTime,
            endTime: sess.endTime,
            autoset: true,
            area: sess.area,
          };

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(sess.id)
            .set(sessDoc);
        });
        // });
        this.pacoteToShow = sessoes1P_H;
      } else if (this.mov.tipo === "Protocolo Detox + Lipodraining 24H") {
        for (var iP_dl = 0; iP_dl < 3; iP_dl++) {
          var DlatendimentoTipos = [
            "1ª Consulta Protocolo Detox + Lipodraining 24H",
            "Retorno Protocolo Detox + Lipodraining 24H",
            "Avaliação Final Protocolo Detox + Lipodraining 24H",
          ];
          var nomeP_dl = DlatendimentoTipos[iP_dl];

          var refIdP_dl = firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc();
          var idP_dl = refIdP_dl.id;

          var startDateNP_dl =
            aRoundedDate.getTime() + iP_dl * 14 * 24 * 60 * 60 * 1000;
          var startDateP_dl = new Date(startDateNP_dl);
          var endDateP_dl;
          if (nomeP_dl === "1ª Consulta Protocolo Detox + Lipodraining 24H") {
            var endDateNP_dl = startDateNP_dl + 60 * 60 * 1000;
            endDateP_dl = new Date(endDateNP_dl);
          } else {
            var endDateNxP_dl = startDateNP_dl + 30 * 60 * 1000;
            endDateP_dl = new Date(endDateNxP_dl);
          }
          var startMinStrP_dl;
          var startMinStrXP_dl = startDateP_dl.getMinutes();
          if (startMinStrXP_dl < 10) {
            startMinStrP_dl = "0" + startMinStrXP_dl.toString();
          } else {
            startMinStrP_dl = startMinStrXP_dl.toString();
          }
          var endMinStrP_dl;
          var endMinStrXP_dl = endDateP_dl.getMinutes();
          if (endMinStrXP_dl < 10) {
            endMinStrP_dl = "0" + endMinStrXP_dl.toString();
          } else {
            endMinStrP_dl = endMinStrXP_dl.toString();
          }

          var startTimeP_dl =
            startDateP_dl.getHours().toString() + ":" + startMinStrP_dl;
          var endTimeP_dl =
            endDateP_dl.getHours().toString() + ":" + endMinStrP_dl;

          var startYStrXP_dl = startDateP_dl.getFullYear();
          var startYStrP_dl = startYStrXP_dl.toString();
          var startYStrShortXP_dl = startDateP_dl.getYear() - 100;
          var startYStrShortP_dl = startYStrShortXP_dl.toString();

          var startMStrP_dl;
          var startMStrXP_dl = startDateP_dl.getMonth() + 1;
          if (startMStrXP_dl < 10) {
            startMStrP_dl = "0" + startMStrXP_dl.toString();
          } else {
            startMStrP_dl = startMStrXP_dl.toString();
          }

          var startDStrP_dl;
          var startDStrXP_dl = startDateP_dl.getDate();
          if (startDStrXP_dl < 10) {
            startDStrP_dl = "0" + startDStrXP_dl.toString();
          } else {
            startDStrP_dl = startDStrXP_dl.toString();
          }

          var startStrP_dl =
            startYStrP_dl +
            "-" +
            startMStrP_dl +
            "-" +
            startDStrP_dl +
            " " +
            startTimeP_dl +
            ":00";
          var endStrP_dl =
            startYStrP_dl +
            "-" +
            startMStrP_dl +
            "-" +
            startDStrP_dl +
            " " +
            endTimeP_dl +
            ":00";
          var diasPtBrP_dl = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayP_dl = diasPtBrP_dl[startDateP_dl.getDay()];
          var niceDateShortP_dl =
            dayP_dl +
            ", " +
            startDStrP_dl +
            "/" +
            startMStrP_dl +
            "/" +
            startYStrShortP_dl;
          var niceDateStrP_dl =
            dayP_dl +
            ", " +
            startDStrP_dl +
            "/" +
            startMStrP_dl +
            "/" +
            startYStrShortP_dl +
            ", " +
            startTimeP_dl +
            "-" +
            endTimeP_dl;

          var newDocP_dl = {
            movMillis: this.mov.millis.toString(),
            name: nomeP_dl,
            niceDate: niceDateStrP_dl,
            niceDateShort: niceDateShortP_dl,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStrP_dl,
            end: endStrP_dl,
            details: "Protocolo Detox + Lipodraining 24H",
            color:
              nomeP_dl === "1ª Consulta Protocolo Detox + Lipodraining 24H"
                ? "#FFB400"
                : "blue",
            timed: true,
            id: idP_dl,
            startTime: startTimeP_dl,
            endTime: endTimeP_dl,
            autoset: true,
          };

          this.dataToSetList[iP_dl] = newDocP_dl;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc(idP_dl)
            .set(newDocP_dl);

          await firebase
            .firestore()
            .collection("users")
            .doc(this.mov.paciente)
            .collection("agenda")
            .doc(idP_dl)
            .set(newDocP_dl);
          var ddl = new Date(startStrP_dl);
          var millis_dl = ddl.getTime();
          var sub24_dl = 24 * 60 * 60 * 1000;
          var millis_1_dl = millis_dl - sub24_dl;
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(idP_dl)
            .set({
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1_dl,
              name: nomeP_dl,
              niceDate: niceDateStrP_dl,
              niceDateShort: niceDateShortP_dl,
              patient: this.mov.pacienteName,
              //   patientId: this.$store.state.firestore.mov.paciente,
              patientId: this.mov.paciente,
              newPatient: false,
              start: startStrP_dl,
              end: endStrP_dl,
              details: "Protocolo Detox + Lipodraining 24H",
              timed: true,
              id: idP_dl,
              startTime: startTimeP_dl,
              endTime: endTimeP_dl,
              autoset: true,
            });
        }

        console.log("mov", this.mov);
        var pIdP_dl = this.mov.paciente;
        var pNameP_dl = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);
        var sessoes1P_dl = this.mov.pacotes[0];
        var areaStrP_dl;
        if (this.mov.pacotes[0].def1 == "2 áreas") {
          areaStrP_dl = sessoes1P_dl.area1 + " + " + sessoes1P_dl.area2;

          sessoes1P_dl.sessoes = [
            createEvent12S(
              "Drenagem Linfática (1)",
              0,
              2,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (2)",
              0,
              5,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (3)",
              1,
              2,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (4)",
              1,
              5,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (5)",
              2,
              2,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (6)",
              2,
              5,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (7)",
              3,
              2,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (8)",
              3,
              5,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              30
            ),
          ];

          console.log("sessoes1", sessoes1P_dl);
          // sessoes1P_3.forEach(function (pac) {
          //   console.log("pac must have .sessoes", pac);
          sessoes1P_dl.sessoes.forEach(async function(sess) {
            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("agendaEstetica")
              .doc(sess.id)
              .set(sess);

            await firebase
              .firestore()
              .collection("users")
              .doc(pIdP_dl)
              .collection("agenda")
              .doc(sess.id)
              .set(sess);
            var d = new Date();
            var millis = d.getTime();
            var sub24 = 24 * 60 * 60 * 1000;
            var millis_1 = millis - sub24;

            var sessDoc = {
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1,
              name: sess.name,
              niceDate: sess.niceDate,
              niceDateShort: sess.niceDateShort,
              patient: sess.patient,
              patientId: sess.patientId,
              newPatient: sess.newPatient,
              start: sess.start,
              end: sess.end,
              details: sess.details,
              id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
              startTime: sess.startTime,
              endTime: sess.endTime,
              autoset: true,
              area: sess.area,
            };

            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("lembretes")
              .doc(sess.id)
              .set(sessDoc);
          });
          // });
          this.pacoteToShow = sessoes1P_dl;
        } else {
          areaStrP_dl = "Corpo inteiro";

          sessoes1P_dl.sessoes = [
            createEvent12S(
              "Drenagem Linfática Corporal (1)",
              0,
              2,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              60
            ),
            createEvent12S(
              "Drenagem Linfática Corporal (2)",
              1,
              2,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              60
            ),
            createEvent12S(
              "Drenagem Linfática Corporal (3)",
              2,
              2,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              60
            ),
            createEvent12S(
              "Drenagem Linfática Corporal (4)",
              3,
              2,
              pIdP_dl,
              pNameP_dl,
              areaStrP_dl,
              60
            ),
          ];

          console.log("sessoes1", sessoes1P_dl);
          // sessoes1P_3.forEach(function (pac) {
          //   console.log("pac must have .sessoes", pac);
          sessoes1P_dl.sessoes.forEach(async function(sess) {
            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("agendaEstetica")
              .doc(sess.id)
              .set(sess);

            await firebase
              .firestore()
              .collection("users")
              .doc(pIdP_dl)
              .collection("agenda")
              .doc(sess.id)
              .set(sess);
            var d = new Date();
            var millis = d.getTime();
            var sub24 = 24 * 60 * 60 * 1000;
            var millis_1 = millis - sub24;

            var sessDoc = {
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1,
              name: sess.name,
              niceDate: sess.niceDate,
              niceDateShort: sess.niceDateShort,
              patient: sess.patient,
              patientId: sess.patientId,
              newPatient: sess.newPatient,
              start: sess.start,
              end: sess.end,
              details: sess.details,
              id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
              startTime: sess.startTime,
              endTime: sess.endTime,
              autoset: true,
              area: sess.area,
            };

            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("lembretes")
              .doc(sess.id)
              .set(sessDoc);
          });
          // });
          this.pacoteToShow = sessoes1P_dl;
        }
      } else if (this.mov.tipo === "Power Shape 30") {
        for (var iPs30 = 0; iPs30 < 2; iPs30++) {
          var Ps30atendimentoTipos = [
            "Bioimpedância inicial Power Shape 30",
            "Bioimpedância final Power Shape 30",
          ];
          var nomePs30 = Ps30atendimentoTipos[iPs30];

          var refIdPs30 = firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc();
          var idPs30 = refIdPs30.id;

          var startDateNPs30 =
            aRoundedDate.getTime() + iPs30 * 28 * 24 * 60 * 60 * 1000;
          var startDatePs30 = new Date(startDateNPs30);
          var endDatePs30;
          var endDateNxPs30 = startDateNPs30 + 30 * 60 * 1000;
          endDatePs30 = new Date(endDateNxPs30);
          var startMinStrPs30;
          var startMinStrXPs30 = startDatePs30.getMinutes();
          if (startMinStrXPs30 < 10) {
            startMinStrPs30 = "0" + startMinStrXPs30.toString();
          } else {
            startMinStrPs30 = startMinStrXPs30.toString();
          }
          var endMinStrPs30;
          var endMinStrXPs30 = endDatePs30.getMinutes();
          if (endMinStrXPs30 < 10) {
            endMinStrPs30 = "0" + endMinStrXPs30.toString();
          } else {
            endMinStrPs30 = endMinStrXPs30.toString();
          }

          var startTimePs30 =
            startDatePs30.getHours().toString() + ":" + startMinStrPs30;
          var endTimePs30 =
            endDatePs30.getHours().toString() + ":" + endMinStrPs30;

          var startYStrXPs30 = startDatePs30.getFullYear();
          var startYStrPs30 = startYStrXPs30.toString();
          var startYStrShortXPs30 = startDatePs30.getYear() - 100;
          var startYStrShortPs30 = startYStrShortXPs30.toString();

          var startMStrPs30;
          var startMStrXPs30 = startDatePs30.getMonth() + 1;
          if (startMStrXPs30 < 10) {
            startMStrPs30 = "0" + startMStrXPs30.toString();
          } else {
            startMStrPs30 = startMStrXPs30.toString();
          }

          var startDStrPs30;
          var startDStrXPs30 = startDatePs30.getDate();
          if (startDStrXPs30 < 10) {
            startDStrPs30 = "0" + startDStrXPs30.toString();
          } else {
            startDStrPs30 = startDStrXPs30.toString();
          }

          var startStrPs30 =
            startYStrPs30 +
            "-" +
            startMStrPs30 +
            "-" +
            startDStrPs30 +
            " " +
            startTimePs30 +
            ":00";
          var endStrPs30 =
            startYStrPs30 +
            "-" +
            startMStrPs30 +
            "-" +
            startDStrPs30 +
            " " +
            endTimePs30 +
            ":00";
          var diasPtBrPs30 = [
            "Dom.",
            "Seg.",
            "Ter.",
            "Qua.",
            "Qui.",
            "Sex.",
            "Sab.",
          ];
          var dayPs30 = diasPtBrPs30[startDatePs30.getDay()];
          var niceDateShortPs30 =
            dayPs30 +
            ", " +
            startDStrPs30 +
            "/" +
            startMStrPs30 +
            "/" +
            startYStrShortPs30;
          var niceDateStrPs30 =
            dayPs30 +
            ", " +
            startDStrPs30 +
            "/" +
            startMStrPs30 +
            "/" +
            startYStrShortPs30 +
            ", " +
            startTimePs30 +
            "-" +
            endTimePs30;

          var newDocPs30 = {
            movMillis: this.mov.millis.toString(),
            name: nomePs30,
            niceDate: niceDateStrPs30,
            niceDateShort: niceDateShortPs30,
            patient: this.mov.pacienteName,
            //   patientId: this.$store.state.firestore.mov.paciente,
            patientId: this.mov.paciente,
            newPatient: false,
            start: startStrPs30,
            end: endStrPs30,
            details: "Power Shape 30",
            color:
              nomePs30 === "Bioimpedância inicial Power Shape 30"
                ? "#FFB400"
                : "blue",
            timed: true,
            id: idPs30,
            startTime: startTimePs30,
            endTime: endTimePs30,
            autoset: true,
          };

          this.dataToSetList[iPs30] = newDocPs30;

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agenda")
            .doc(idPs30)
            .set(newDocPs30);

          await firebase
            .firestore()
            .collection("users")
            .doc(this.mov.paciente)
            .collection("agenda")
            .doc(idPs30)
            .set(newDocPs30);
          var dPs30 = new Date(startStrPs30);
          var millis_Ps30 = dPs30.getTime();
          var sub24_Ps30 = 24 * 60 * 60 * 1000;
          var millis_1_Ps30 = millis_Ps30 - sub24_Ps30;
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(idPs30)
            .set({
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1_Ps30,
              name: nomePs30,
              niceDate: niceDateStrPs30,
              niceDateShort: niceDateShortPs30,
              patient: this.mov.pacienteName,
              //   patientId: this.$store.state.firestore.mov.paciente,
              patientId: this.mov.paciente,
              newPatient: false,
              start: startStrPs30,
              end: endStrPs30,
              details: "Power Shape 30",
              timed: true,
              id: idPs30,
              startTime: startTimePs30,
              endTime: endTimePs30,
              autoset: true,
            });
        }

        console.log("mov", this.mov);
        var pIdPs30 = this.mov.paciente;
        var pNamePs30 = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);
        var sessoes1Ps30 = this.mov.pacotes[0];
        var areaStrPs30 = sessoes1Ps30.area1;

        var monthsFromNowPs30 = parseInt(sessoes1Ps30.dataInicio);

        if (sessoes1Ps30.def2 == "HIBRIUS") {
          sessoes1Ps30.sessoes = [
            createEvent(
              "HIBRIUS + Massagem (1)",
              monthsFromNowPs30,
              0,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "HIBRIUS + Massagem (2)",
              monthsFromNowPs30,
              1,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "HIBRIUS + Massagem (3)",
              monthsFromNowPs30,
              2,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "HIBRIUS + Massagem (4)",
              monthsFromNowPs30,
              3,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
          ];
        } else if (sessoes1Ps30.def2 == "DUALSOON") {
          sessoes1Ps30.sessoes = [
            createEvent(
              "DUALSOON (1)",
              monthsFromNowPs30,
              0,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "DUALSOON (2)",
              monthsFromNowPs30,
              1,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "DUALSOON (3)",
              monthsFromNowPs30,
              2,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "DUALSOON (4)",
              monthsFromNowPs30,
              3,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
          ];
        } else if (sessoes1Ps30.def2 == "ENDERMOTERAPIA") {
          sessoes1Ps30.sessoes = [
            createEvent(
              "ENDERMOTERAPIA (1)",
              monthsFromNowPs30,
              0,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "ENDERMOTERAPIA (2)",
              monthsFromNowPs30,
              1,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "ENDERMOTERAPIA (3)",
              monthsFromNowPs30,
              2,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "ENDERMOTERAPIA (4)",
              monthsFromNowPs30,
              3,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
          ];
        } else if (sessoes1Ps30.def2 == "DRENAGEM LINFATICA") {
          sessoes1Ps30.sessoes = [
            createEvent(
              "DRENAGEM LINFATICA (1)",
              monthsFromNowPs30,
              0,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "DRENAGEM LINFATICA (2)",
              monthsFromNowPs30,
              1,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "DRENAGEM LINFATICA (3)",
              monthsFromNowPs30,
              2,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "DRENAGEM LINFATICA (4)",
              monthsFromNowPs30,
              3,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
          ];
        } else if (sessoes1Ps30.def2 == "MASSAGEM RELAXANTE") {
          sessoes1Ps30.sessoes = [
            createEvent(
              "MASSAGEM RELAXANTE (1)",
              monthsFromNowPs30,
              0,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM RELAXANTE (2)",
              monthsFromNowPs30,
              1,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM RELAXANTE (3)",
              monthsFromNowPs30,
              2,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM RELAXANTE (4)",
              monthsFromNowPs30,
              3,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
          ];
        } else if (sessoes1Ps30.def2 == "REFLEXOLOGIA PODAL") {
          sessoes1Ps30.sessoes = [
            createEvent(
              "REFLEXOLOGIA PODAL (1)",
              monthsFromNowPs30,
              0,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "REFLEXOLOGIA PODAL (2)",
              monthsFromNowPs30,
              0,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "REFLEXOLOGIA PODAL (3)",
              monthsFromNowPs30,
              1,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "REFLEXOLOGIA PODAL (4)",
              monthsFromNowPs30,
              1,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "REFLEXOLOGIA PODAL (5)",
              monthsFromNowPs30,
              2,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "REFLEXOLOGIA PODAL (6)",
              monthsFromNowPs30,
              2,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "REFLEXOLOGIA PODAL (7)",
              monthsFromNowPs30,
              3,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "REFLEXOLOGIA PODAL (8)",
              monthsFromNowPs30,
              3,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
          ];
        } else if (sessoes1Ps30.def2 == "MASSAGEM REDUTORA") {
          sessoes1Ps30.sessoes = [
            createEvent(
              "MASSAGEM REDUTORA (1)",
              monthsFromNowPs30,
              0,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM REDUTORA (2)",
              monthsFromNowPs30,
              0,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM REDUTORA (3)",
              monthsFromNowPs30,
              1,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM REDUTORA (4)",
              monthsFromNowPs30,
              1,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM REDUTORA (5)",
              monthsFromNowPs30,
              2,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM REDUTORA (6)",
              monthsFromNowPs30,
              2,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM REDUTORA (7)",
              monthsFromNowPs30,
              3,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "MASSAGEM REDUTORA (8)",
              monthsFromNowPs30,
              3,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
          ];
        } else if (sessoes1Ps30.def2 == "CORRENTE RUSSA") {
          sessoes1Ps30.sessoes = [
            createEvent(
              "CORRENTE RUSSA (1)",
              monthsFromNowPs30,
              0,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "CORRENTE RUSSA (2)",
              monthsFromNowPs30,
              0,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "CORRENTE RUSSA (3)",
              monthsFromNowPs30,
              1,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "CORRENTE RUSSA (4)",
              monthsFromNowPs30,
              1,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "CORRENTE RUSSA (5)",
              monthsFromNowPs30,
              2,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "CORRENTE RUSSA (6)",
              monthsFromNowPs30,
              2,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "CORRENTE RUSSA (7)",
              monthsFromNowPs30,
              3,
              0,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
            createEvent(
              "CORRENTE RUSSA (8)",
              monthsFromNowPs30,
              3,
              3,
              pIdPs30,
              pNamePs30,
              areaStrPs30,
              30
            ),
          ];
        } else {
          sessoes1Ps30.sessoes = [];
        }

        console.log("sessoes1", sessoes1Ps30);
        // sessoes1P_3.forEach(function (pac) {
        //   console.log("pac must have .sessoes", pac);
        sessoes1Ps30.sessoes.forEach(async function(sess) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agendaEstetica")
            .doc(sess.id)
            .set(sess);

          await firebase
            .firestore()
            .collection("users")
            .doc(pIdPs30)
            .collection("agenda")
            .doc(sess.id)
            .set(sess);
          var d = new Date();
          var millis = d.getTime();
          var sub24 = 24 * 60 * 60 * 1000;
          var millis_1 = millis - sub24;

          var sessDoc = {
            tipo: "confirm",
            done: false,
            status: "notDueYet",
            dueDate: millis_1,
            name: sess.name,
            niceDate: sess.niceDate,
            niceDateShort: sess.niceDateShort,
            patient: sess.patient,
            patientId: sess.patientId,
            newPatient: sess.newPatient,
            start: sess.start,
            end: sess.end,
            details: sess.details,
            id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
            startTime: sess.startTime,
            endTime: sess.endTime,
            autoset: true,
            area: sess.area,
          };

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(sess.id)
            .set(sessDoc);
        });
        // });
        this.pacoteToShow = sessoes1Ps30;
      } else if (this.mov.tipo === "Protocolo Lipodraining 24H") {
        console.log("mov", this.mov);
        var pIdP_dl0 = this.mov.paciente;
        var pNameP_dl0 = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);
        var sessoes1P_dl0 = this.mov.pacotes[0];
        var areaStrP_dl0;
        if (this.mov.pacotes[0].def1 == "2 áreas") {
          areaStrP_dl0 = sessoes1P_dl0.area1 + " + " + sessoes1P_dl0.area2;

          sessoes1P_dl0.sessoes = [
            createEvent12S(
              "Drenagem Linfática (1)",
              0,
              2,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (2)",
              0,
              5,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (3)",
              1,
              2,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (4)",
              1,
              5,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (5)",
              2,
              2,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (6)",
              2,
              5,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (7)",
              3,
              2,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              30
            ),
            createEvent12S(
              "Drenagem Linfática (8)",
              3,
              5,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              30
            ),
          ];

          console.log("sessoes1", sessoes1P_dl0);
          // sessoes1P_3.forEach(function (pac) {
          //   console.log("pac must have .sessoes", pac);
          sessoes1P_dl0.sessoes.forEach(async function(sess) {
            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("agendaEstetica")
              .doc(sess.id)
              .set(sess);

            await firebase
              .firestore()
              .collection("users")
              .doc(pIdP_dl0)
              .collection("agenda")
              .doc(sess.id)
              .set(sess);
            var d = new Date();
            var millis = d.getTime();
            var sub24 = 24 * 60 * 60 * 1000;
            var millis_1 = millis - sub24;

            var sessDoc = {
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1,
              name: sess.name,
              niceDate: sess.niceDate,
              niceDateShort: sess.niceDateShort,
              patient: sess.patient,
              patientId: sess.patientId,
              newPatient: sess.newPatient,
              start: sess.start,
              end: sess.end,
              details: sess.details,
              id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
              startTime: sess.startTime,
              endTime: sess.endTime,
              autoset: true,
              area: sess.area,
            };

            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("lembretes")
              .doc(sess.id)
              .set(sessDoc);
          });
          // });
          this.pacoteToShow = sessoes1P_dl0;
        } else {
          areaStrP_dl0 = "Corpo inteiro";

          sessoes1P_dl.sessoes = [
            createEvent12S(
              "Drenagem Linfática Corporal (1)",
              0,
              2,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              60
            ),
            createEvent12S(
              "Drenagem Linfática Corporal (2)",
              1,
              2,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              60
            ),
            createEvent12S(
              "Drenagem Linfática Corporal (3)",
              2,
              2,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              60
            ),
            createEvent12S(
              "Drenagem Linfática Corporal (4)",
              3,
              2,
              pIdP_dl0,
              pNameP_dl0,
              areaStrP_dl0,
              60
            ),
          ];

          console.log("sessoes1", sessoes1P_dl0);
          // sessoes1P_3.forEach(function (pac) {
          //   console.log("pac must have .sessoes", pac);
          sessoes1P_dl0.sessoes.forEach(async function(sess) {
            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("agendaEstetica")
              .doc(sess.id)
              .set(sess);

            await firebase
              .firestore()
              .collection("users")
              .doc(pIdP_dl0)
              .collection("agenda")
              .doc(sess.id)
              .set(sess);
            var d = new Date();
            var millis = d.getTime();
            var sub24 = 24 * 60 * 60 * 1000;
            var millis_1 = millis - sub24;

            var sessDoc = {
              tipo: "confirm",
              done: false,
              status: "notDueYet",
              dueDate: millis_1,
              name: sess.name,
              niceDate: sess.niceDate,
              niceDateShort: sess.niceDateShort,
              patient: sess.patient,
              patientId: sess.patientId,
              newPatient: sess.newPatient,
              start: sess.start,
              end: sess.end,
              details: sess.details,
              id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
              startTime: sess.startTime,
              endTime: sess.endTime,
              autoset: true,
              area: sess.area,
            };

            await firebase
              .firestore()
              .collection("users")
              .doc(sessionStorage.getItem("current-user"))
              .collection("lembretes")
              .doc(sess.id)
              .set(sessDoc);
          });
          // });
          this.pacoteToShow = sessoes1P_dl0;
        }
      } else if (this.mov.tipo === "Protocolo Glúteo de Ouro") {
        console.log("mov", this.mov);
        var pIdGl = this.mov.paciente;
        var pNameGl = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);
        var sessoes1Gl = this.mov.pacotes[0];
        var areaStrGl = "GLÚTEO";

        sessoes1Gl.sessoes = [
          createEvent12S(
            "Glúteo de Ouro (1)",
            0,
            2,
            pIdGl,
            pNameGl,
            areaStrGl,
            60
          ),
          createEvent12S(
            "Glúteo de Ouro (2)",
            1,
            2,
            pIdGl,
            pNameGl,
            areaStrGl,
            60
          ),
          createEvent12S(
            "Glúteo de Ouro (3)",
            2,
            2,
            pIdGl,
            pNameGl,
            areaStrGl,
            60
          ),
          createEvent12S(
            "Glúteo de Ouro (4)",
            3,
            2,
            pIdGl,
            pNameGl,
            areaStrGl,
            60
          ),
          createEvent12S(
            "Glúteo de Ouro (5)",
            4,
            2,
            pIdGl,
            pNameGl,
            areaStrGl,
            60
          ),
          createEvent12S(
            "Glúteo de Ouro (6)",
            5,
            2,
            pIdGl,
            pNameGl,
            areaStrGl,
            60
          ),
          createEvent12S(
            "Glúteo de Ouro (7)",
            6,
            2,
            pIdGl,
            pNameGl,
            areaStrGl,
            60
          ),
          createEvent12S(
            "Glúteo de Ouro (8)",
            7,
            2,
            pIdGl,
            pNameGl,
            areaStrGl,
            60
          ),
        ];

        console.log("sessoes1", sessoes1Gl);
        // sessoes1P_3.forEach(function (pac) {
        //   console.log("pac must have .sessoes", pac);
        sessoes1Gl.sessoes.forEach(async function(sess) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agendaEstetica")
            .doc(sess.id)
            .set(sess);

          await firebase
            .firestore()
            .collection("users")
            .doc(pIdGl)
            .collection("agenda")
            .doc(sess.id)
            .set(sess);
          var d = new Date();
          var millis = d.getTime();
          var sub24 = 24 * 60 * 60 * 1000;
          var millis_1 = millis - sub24;

          var sessDoc = {
            tipo: "confirm",
            done: false,
            status: "notDueYet",
            dueDate: millis_1,
            name: sess.name,
            niceDate: sess.niceDate,
            niceDateShort: sess.niceDateShort,
            patient: sess.patient,
            patientId: sess.patientId,
            newPatient: sess.newPatient,
            start: sess.start,
            end: sess.end,
            details: sess.details,
            id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
            startTime: sess.startTime,
            endTime: sess.endTime,
            autoset: true,
            area: sess.area,
          };

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(sess.id)
            .set(sessDoc);
        });
        // });
        this.pacoteToShow = sessoes1Gl;
      } else if (
        this.mov.tipo === "Protocolo de Clareamento (Pacote de 4 sessões)"
      ) {
        console.log("mov", this.mov);
        var pIdc4 = this.mov.paciente;
        var pNamec4 = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);
        var sessoes1c4 = this.mov.pacotes[0];
        var areaStrc4 = "Clareamento";

        sessoes1c4.sessoes = [
          createEvent12S(
            "Clareamento (1)",
            0,
            2,
            pIdc4,
            pNamec4,
            areaStrc4,
            60
          ),
          createEvent12S(
            "Clareamento (2)",
            1,
            2,
            pIdc4,
            pNamec4,
            areaStrc4,
            60
          ),
          createEvent12S(
            "Clareamento (3)",
            2,
            2,
            pIdc4,
            pNamec4,
            areaStrc4,
            60
          ),
          createEvent12S(
            "Clareamento (4)",
            3,
            2,
            pIdc4,
            pNamec4,
            areaStrc4,
            60
          ),
        ];

        console.log("sessoes1", sessoes1c4);
        // sessoes1P_3.forEach(function (pac) {
        //   console.log("pac must have .sessoes", pac);
        sessoes1c4.sessoes.forEach(async function(sess) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agendaEstetica")
            .doc(sess.id)
            .set(sess);

          await firebase
            .firestore()
            .collection("users")
            .doc(pIdc4)
            .collection("agenda")
            .doc(sess.id)
            .set(sess);
          var d = new Date();
          var millis = d.getTime();
          var sub24 = 24 * 60 * 60 * 1000;
          var millis_1 = millis - sub24;

          var sessDoc = {
            tipo: "confirm",
            done: false,
            status: "notDueYet",
            dueDate: millis_1,
            name: sess.name,
            niceDate: sess.niceDate,
            niceDateShort: sess.niceDateShort,
            patient: sess.patient,
            patientId: sess.patientId,
            newPatient: sess.newPatient,
            start: sess.start,
            end: sess.end,
            details: sess.details,
            id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
            startTime: sess.startTime,
            endTime: sess.endTime,
            autoset: true,
            area: sess.area,
          };

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(sess.id)
            .set(sessDoc);
        });
        // });
        this.pacoteToShow = sessoes1c4;
      } else if (this.mov.tipo === "Lipo Vegana") {
        console.log("mov", this.mov);
        var pIdLVg = this.mov.paciente;
        var pNameLVg = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);
        var sessoes1LVg = {};
        var areaStrLVg = "";
        var monthsFromNowLVg = 0;

        sessoes1LVg.sessoes = [
          createEvent(
            "Lipo Vegana (1)",
            monthsFromNowLVg,
            0,
            0,
            pIdLVg,
            pNameLVg,
            areaStrLVg,
            60
          ),
          createEvent(
            "Lipo Vegana (2)",
            monthsFromNowLVg,
            1,
            0,
            pIdLVg,
            pNameLVg,
            areaStrLVg,
            60
          ),
          createEvent(
            "Lipo Vegana (3)",
            monthsFromNowLVg,
            2,
            0,
            pIdLVg,
            pNameLVg,
            areaStrLVg,
            60
          ),
          createEvent(
            "Lipo Vegana (4)",
            monthsFromNowLVg,
            3,
            0,
            pIdLVg,
            pNameLVg,
            areaStrLVg,
            60
          ),
        ];

        console.log("sessoes1", sessoes1LVg);
        // sessoes1P_3.forEach(function (pac) {
        //   console.log("pac must have .sessoes", pac);
        sessoes1LVg.sessoes.forEach(async function(sess) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agendaEstetica")
            .doc(sess.id)
            .set(sess);

          await firebase
            .firestore()
            .collection("users")
            .doc(pIdLVg)
            .collection("agenda")
            .doc(sess.id)
            .set(sess);
          var d = new Date();
          var millis = d.getTime();
          var sub24 = 24 * 60 * 60 * 1000;
          var millis_1 = millis - sub24;

          var sessDoc = {
            tipo: "confirm",
            done: false,
            status: "notDueYet",
            dueDate: millis_1,
            name: sess.name,
            niceDate: sess.niceDate,
            niceDateShort: sess.niceDateShort,
            patient: sess.patient,
            patientId: sess.patientId,
            newPatient: sess.newPatient,
            start: sess.start,
            end: sess.end,
            details: sess.details,
            id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
            startTime: sess.startTime,
            endTime: sess.endTime,
            autoset: true,
            area: sess.area,
          };

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(sess.id)
            .set(sessDoc);
        });
        // });
        this.pacoteToShow = sessoes1LVg;
      } else if (this.mov.tipo === "Mini Lipo Vegana") {
        console.log("mov", this.mov);
        var pIdLVg2 = this.mov.paciente;
        var pNameLVg2 = this.mov.pacienteName;
        console.log("mov.pacotes", this.mov.pacotes);
        var sessoes1LVg2 = {};
        var areaStrLVg2 = "";
        var monthsFromNowLVg2 = 0;

        sessoes1LVg2.sessoes = [
          createEvent(
            "Mini Lipo Vegana (1)",
            monthsFromNowLVg2,
            0,
            0,
            pIdLVg2,
            pNameLVg2,
            areaStrLVg2,
            30
          ),
          createEvent(
            "Mini Lipo Vegana (2)",
            monthsFromNowLVg2,
            0,
            4,
            pIdLVg2,
            pNameLVg2,
            areaStrLVg2,
            30
          ),
          createEvent(
            "Mini Lipo Vegana (3)",
            monthsFromNowLVg2,
            1,
            0,
            pIdLVg2,
            pNameLVg2,
            areaStrLVg2,
            30
          ),
          createEvent(
            "Mini Lipo Vegana (4)",
            monthsFromNowLVg2,
            1,
            4,
            pIdLVg2,
            pNameLVg2,
            areaStrLVg2,
            30
          ),
          createEvent(
            "Mini Lipo Vegana (5)",
            monthsFromNowLVg2,
            2,
            0,
            pIdLVg2,
            pNameLVg2,
            areaStrLVg2,
            30
          ),
        ];

        console.log("sessoes1", sessoes1LVg2);
        // sessoes1P_3.forEach(function (pac) {
        //   console.log("pac must have .sessoes", pac);
        sessoes1LVg2.sessoes.forEach(async function(sess) {
          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("agendaEstetica")
            .doc(sess.id)
            .set(sess);

          await firebase
            .firestore()
            .collection("users")
            .doc(pIdLVg2)
            .collection("agenda")
            .doc(sess.id)
            .set(sess);
          var d = new Date();
          var millis = d.getTime();
          var sub24 = 24 * 60 * 60 * 1000;
          var millis_1 = millis - sub24;

          var sessDoc = {
            tipo: "confirm",
            done: false,
            status: "notDueYet",
            dueDate: millis_1,
            name: sess.name,
            niceDate: sess.niceDate,
            niceDateShort: sess.niceDateShort,
            patient: sess.patient,
            patientId: sess.patientId,
            newPatient: sess.newPatient,
            start: sess.start,
            end: sess.end,
            details: sess.details,
            id: sess.id, //Math.round(Math.random()*(1000000000000)).toString(),
            startTime: sess.startTime,
            endTime: sess.endTime,
            autoset: true,
            area: sess.area,
          };

          await firebase
            .firestore()
            .collection("users")
            .doc(sessionStorage.getItem("current-user"))
            .collection("lembretes")
            .doc(sess.id)
            .set(sessDoc);
        });
        // });
        this.pacoteToShow = sessoes1LVg2;
      }
    },
    async updateEvent(event) {
      var startDate = new Date(event.start);
      var millis = startDate.getTime();
      var sub = 24 * 60 * 60 * 1000;
      var millis_1 = millis - sub;
      var endDate = new Date(event.end);
      var startMinStr;
      var startMinStrX = startDate.getMinutes();
      if (startMinStrX < 10) {
        startMinStr = "0" + startMinStrX.toString();
      } else {
        startMinStr = startMinStrX.toString();
      }
      var endMinStr;
      var endMinStrX = endDate.getMinutes();
      if (endMinStrX < 10) {
        endMinStr = "0" + endMinStrX.toString();
      } else {
        endMinStr = endMinStrX.toString();
      }

      var startTime = startDate.getHours().toString() + ":" + startMinStr;
      var endTime = endDate.getHours().toString() + ":" + endMinStr;

      var startYStrX = startDate.getYear() + 1900;
      var startYStr = startYStrX.toString();
      var startYStrShortX = startDate.getYear() - 100;
      var startYStrShort = startYStrShortX.toString();

      var startMStr;
      var startMStrX = startDate.getMonth() + 1;
      if (startMStrX < 10) {
        startMStr = "0" + startMStrX.toString();
      } else {
        startMStr = startMStrX.toString();
      }

      var startDStr;
      var startDStrX = startDate.getDate();
      if (startDStrX < 10) {
        startDStr = "0" + startDStrX.toString();
      } else {
        startDStr = startDStrX.toString();
      }

      var startStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + startTime + ":00";
      var endStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + endTime + ":00";
      var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
      var day = diasPtBr[startDate.getDay()];
      var niceDateShort =
        day + ", " + startDStr + "/" + startMStr + "/" + startYStrShort;
      var niceDateStr =
        day +
        ", " +
        startDStr +
        "/" +
        startMStr +
        "/" +
        startYStrShort +
        ", " +
        startTime +
        "-" +
        endTime;

      var updateDoc = {
        niceDate: niceDateStr,
        niceDateShort: niceDateShort,
        start: startStr,
        end: endStr,
        startTime: startTime,
        endTime: endTime,
        autoset: false,
      };

      var updateDocLembrete = {
        done: false,
        status: "notDueYet",
        dueDate: millis_1,
        niceDate: niceDateStr,
        niceDateShort: niceDateShort,
        start: startStr,
        end: endStr,
        startTime: startTime,
        endTime: endTime,
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .doc(event.id)
        .update(updateDoc);

      if (event.patientId !== "") {
        await firebase
          .firestore()
          .collection("users")
          .doc(event.patientId)
          .collection("agenda")
          .doc(event.id)
          .update(updateDoc);
      }

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("lembretes")
        .doc(event.id)
        .update(updateDocLembrete);

      this.getEvents();
      // this.getEventsEstetica();
    },
    async updateEventEstetica(event) {
      var startDate = new Date(event.start);
      var endDate = new Date(event.end);
      var startMinStr;
      var startMinStrX = startDate.getMinutes();
      if (startMinStrX < 10) {
        startMinStr = "0" + startMinStrX.toString();
      } else {
        startMinStr = startMinStrX.toString();
      }
      var endMinStr;
      var endMinStrX = endDate.getMinutes();
      if (endMinStrX < 10) {
        endMinStr = "0" + endMinStrX.toString();
      } else {
        endMinStr = endMinStrX.toString();
      }

      var startTime = startDate.getHours().toString() + ":" + startMinStr;
      var endTime = endDate.getHours().toString() + ":" + endMinStr;

      var startYStrX = startDate.getYear() + 1900;
      var startYStr = startYStrX.toString();
      var startYStrShortX = startDate.getYear() - 100;
      var startYStrShort = startYStrShortX.toString();

      var startMStr;
      var startMStrX = startDate.getMonth() + 1;
      if (startMStrX < 10) {
        startMStr = "0" + startMStrX.toString();
      } else {
        startMStr = startMStrX.toString();
      }

      var startDStr;
      var startDStrX = startDate.getDate();
      if (startDStrX < 10) {
        startDStr = "0" + startDStrX.toString();
      } else {
        startDStr = startDStrX.toString();
      }

      var startStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + startTime + ":00";
      var endStr =
        startYStr + "-" + startMStr + "-" + startDStr + " " + endTime + ":00";
      var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
      var day = diasPtBr[startDate.getDay()];
      var niceDateShort =
        day + ", " + startDStr + "/" + startMStr + "/" + startYStrShort;
      var niceDateStr =
        day +
        ", " +
        startDStr +
        "/" +
        startMStr +
        "/" +
        startYStrShort +
        ", " +
        startTime +
        "-" +
        endTime;

      var updateDoc = {
        niceDate: niceDateStr,
        niceDateShort: niceDateShort,
        start: startStr,
        end: endStr,
        startTime: startTime,
        endTime: endTime,
        autoset: false,
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .doc(event.id)
        .update(updateDoc);

      if (event.patientId !== "") {
        await firebase
          .firestore()
          .collection("users")
          .doc(event.patientId)
          .collection("agenda")
          .doc(event.id)
          .update(updateDoc);
      }
      this.getEventsEstetica();
    },
    endDrag() {
      this.updateEvent(this.dragEvent);
      this.dragEvent = null;
    },
    endDragEstetica() {
      this.updateEventEstetica(this.dragEvent);
      this.dragEvent = null;
    },
    startDrag({ event }) {
      if (event) {
        this.dragEvent = event;
      }
    },
    clearInfo() {
      this.dialog = false;
      this.patient = "";
      this.patientId = "";
      this.newPatient = false;
      this.startTime = null;
      this.endTime = null;
      this.description = null;
      this.selectedTime = null;
      this.niceDateShort = null;
    },
    hourInfoMouseFollow(e) {
      try {
        var chip = document.getElementById("DateChip");
        if (
          (this.type === "week" || this.type === "day") &&
          e.target.className == "v-calendar-daily__day-interval"
        ) {
          chip.style.display = "block";
          chip.style.left = e.clientX - 300 + "px";
          chip.style.top = e.layerY + "px";
        } else {
          chip.style.display = "none";
        }
      } catch (err) {
        err;
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    async deleteEvent(eventId) {
      var other_type;
      if (this.type !== "week") {
        other_type = "week";
      } else {
        other_type = "day";
      }
      var a = this.type;
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .doc(eventId)
        .delete();
      this.type = other_type;
      setTimeout(() => {
        this.type = a;
      }, 1);
      this.selectedOpen = false;
    },
    async getEvents() {
      var events = [];
      var eventList = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      eventList.forEach(function(event) {
        var autosetColor = function(e) {
          if (e.autoset == undefined) {
            return e.color;
          } else {
            if (e.autoset) {
              return "#99ff99";
            } else {
              return e.color;
            }
          }
        };
        events.push({
          name: event.name,
          patient: event.patient,
          patientId: event.patientId,
          newPatient: event.newPatient,
          start: new Date(event.start),
          end: new Date(event.end),
          details: event.details,
          color: autosetColor(event),
          timed: event.timed,
          id: event.id,
          startTime: event.startTime,
          endTime: event.endTime,
          niceDateShort: event.niceDateShort,
        });
      });
      this.events = events;
    },
    async getEventsEstetica() {
      var eventsEstetica = [];
      var eventListEstetica = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
      eventListEstetica.forEach(function(event) {
        var autosetColor = function(e) {
          if (e.autoset == undefined) {
            return e.color;
          } else {
            if (e.autoset) {
              return "#99ff99";
            } else {
              return e.color;
            }
          }
        };
        eventsEstetica.push({
          name: event.name,
          patient: event.patient,
          patientId: event.patientId,
          newPatient: event.newPatient,
          start: new Date(event.start),
          end: new Date(event.end),
          details: event.details,
          color: autosetColor(event),
          timed: event.timed,
          id: event.id,
          startTime: event.startTime,
          endTime: event.endTime,
          niceDateShort: event.niceDateShort,
        });
      });
      this.eventsEstetica = eventsEstetica;
    },
    getCalendarDayTime(time) {
      // this.currentTime = time.time;
      var splitsT = time.time.split(":");
      var hoursT = parseInt(splitsT[0]);
      var minutesT = parseInt(splitsT[1]);
      if (minutesT < 15) {
        minutesT = "00";
      } else if (minutesT >= 15 && minutesT < 30) {
        minutesT = "15";
      } else if (minutesT >= 30 && minutesT < 45) {
        minutesT = "30";
      } else if (minutesT >= 45 && minutesT < 59) {
        minutesT = "45";
      }
      var roundedTime = hoursT
        .toString()
        .concat(":")
        .concat(minutesT);
      this.currentTime = roundedTime;

      if (this.dragEvent !== null) {
        var start = new Date(this.dragEvent.start);
        var end = new Date(this.dragEvent.end);
        var startS = start.getTime();
        var endS = end.getTime();
        var duration = endS - startS;

        var dayStr;
        var day = time.day;
        if (day < 10) {
          dayStr = "0" + day.toString();
        } else {
          dayStr = day.toString();
        }
        var monthStr;
        var month = time.month;
        if (month < 10) {
          monthStr = "0" + month.toString();
        } else {
          monthStr = month.toString();
        }
        var string =
          time.year.toString() +
          "-" +
          monthStr +
          "-" +
          dayStr +
          " " +
          roundedTime +
          ":00";
        var newStart = new Date(string);
        var newStartS = newStart.getTime();
        var newEndS = newStartS + duration;
        var newEnd = new Date(newEndS);

        var newEndMinutes;
        if (newEnd.getMinutes() < 10) {
          newEndMinutes = "0" + newEnd.getMinutes().toString();
        } else {
          newEndMinutes = newEnd.getMinutes().toString();
        }
        this.dragEvent.start = string;
        this.dragEvent.end =
          time.year.toString() +
          "-" +
          monthStr +
          "-" +
          dayStr +
          " " +
          newEnd.getHours().toString() +
          ":" +
          newEndMinutes +
          ":00";

        this.dragEvent.startTime = roundedTime;
        this.dragEvent.endTime =
          newEnd.getHours().toString() + ":" + newEndMinutes;
      }
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    chooseDataTime(event) {
      this.eventEdit = event;
      this.chooseDateTimeDialog = true;
    },
    chooseDataTimeE(event) {
      this.eventEdit = event;
      this.chooseDateTimeDialogE = true;
    },
    toYMD(string) {
      var splits = string.split("/");
      var year = splits[2];
      var month = splits[1];
      var days = splits[0];
      return "20" + year + "-" + month + "-" + days.substring(6);
    },
    async transformStartEnd() {
      this.eventEdit.start = `${this.toYMD(this.eventEdit.niceDateShort)} ${
        this.eventEdit.startTime
      }:00`;
      this.eventEdit.end = `${this.toYMD(this.eventEdit.niceDateShort)} ${
        this.eventEdit.endTime
      }:00`;

      this.eventEdit.niceDate =
        this.eventEdit.niceDateShort +
        ", " +
        this.eventEdit.startTime +
        "-" +
        this.eventEdit.endTime;

      console.log(this.eventEdit);

      var eventToSave = {
        niceDate: this.eventEdit.niceDate,
        niceDateShort: this.eventEdit.niceDateShort,
        start: this.eventEdit.start,
        end: this.eventEdit.end,
        startTime: this.eventEdit.startTime,
        endTime: this.eventEdit.endTime,
        autoset: false,
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agenda")
        .doc(this.eventEdit.id)
        .update(eventToSave);

      await firebase
        .firestore()
        .collection("users")
        .doc(this.mov.paciente)
        .collection("agenda")
        .doc(this.eventEdit.id)
        .update(eventToSave);

      this.getEvents();
      this.chooseDateTimeDialog = false;
    },
    async transformStartEndE() {
      this.eventEdit.start = `${this.toYMD(this.eventEdit.niceDateShort)} ${
        this.eventEdit.startTime
      }:00`;
      this.eventEdit.end = `${this.toYMD(this.eventEdit.niceDateShort)} ${
        this.eventEdit.endTime
      }:00`;

      this.eventEdit.niceDate =
        this.eventEdit.niceDateShort +
        ", " +
        this.eventEdit.startTime +
        "-" +
        this.eventEdit.endTime;

      console.log(this.eventEdit);

      var eventToSave = {
        niceDate: this.eventEdit.niceDate,
        niceDateShort: this.eventEdit.niceDateShort,
        start: this.eventEdit.start,
        end: this.eventEdit.end,
        startTime: this.eventEdit.startTime,
        endTime: this.eventEdit.endTime,
        autoset: false,
      };

      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("agendaEstetica")
        .doc(this.eventEdit.id)
        .update(eventToSave);

      await firebase
        .firestore()
        .collection("users")
        .doc(this.mov.paciente)
        .collection("agenda")
        .doc(this.eventEdit.id)
        .update(eventToSave);

      this.getEventsEstetica();
      this.chooseDateTimeDialogE = false;
    },
  },
  mounted() {
    // this.$refs.calendar.checkChange();

    if (this.$store.state.firestore.pacientes.length === 0) {
      this.$store.dispatch("firestore/getPacientes");
    }

    // var patientName = this.$store.state.firestore.mov.pacienteName;
    var patientName = this.mov.pacienteName;
    console.log(patientName);
    this.mountingFunction();
  },
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
