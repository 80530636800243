var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Fotos ")]),_c('v-card-text',_vm._l((_vm.atendimentos),function(atendimento,atendimentoIndex){return _c('div',{key:atendimentoIndex},[(atendimento.fotoFrenteUrl !== '')?_c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"text-subtitle-1 ml-2 d-flex align-center"},[_vm._v(" "+_vm._s(_vm.labels[atendimentoIndex])+" ")])],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mx-2 black--text",attrs:{"href":atendimento.fotoFrenteUrl,"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#614021"}},[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v("Frente")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mx-2 black--text",attrs:{"href":atendimento.fotoAtrasUrl,"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#614021"}},[_vm._v("mdi-account-reactivate")])],1)]}}],null,true)},[_c('span',[_vm._v("Trás")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mx-2 black--text",attrs:{"href":atendimento.fotoEsquerdaUrl,"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#614021"}},[_vm._v("mdi-account-arrow-left")])],1)]}}],null,true)},[_c('span',[_vm._v("Esquerda")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mx-2 black--text",attrs:{"href":atendimento.fotoDireitaUrl,"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#614021"}},[_vm._v("mdi-account-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Direita")])])],1)],1)],1)],1):_c('div')])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }