<template>
  <div>
    <v-dialog v-model="visualizarDialog" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title>
          {{ eventVis.name }}
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center"
            >{{ readableStart(eventVis.start) }}-{{ readableEnd(eventVis.end) }}
          </v-row>
          <v-row class="d-flex justify-center"
            >Obs.: {{ eventVis.details }}
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title class="text-h6"> Agendamentos </v-card-title>
      <v-card-text>
        <v-card v-for="event in events" :key="event.name" outlined>
          <v-card :color="event.color">
            <v-row class="d-flex justify-center">
              <v-col cols="12" class="d-flex justify-space-between">
                <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                  {{ event.name }}, {{ readableStart(event.start) }}-{{
                    readableEnd(event.end)
                  }}
                  <div v-if="event.isNew" class="red--text">
                    <strong> NOVO ! </strong>
                  </div>
                </v-row>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#c5d13f"
                      v-bind="attrs"
                      v-on="on"
                      @click="visualizar(event)"
                      class="mr-2 mx-2 black--text"
                    >
                      <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import firebase from "firebase";
import { mdiEye } from "@mdi/js";
export default {
  data() {
    return {
      currentTime: "",
      visualizarDialog: false,
      events: [],
      icons: { mdiEye },
    };
  },
  computed: {
    eventVis() {
      return this.$store.state.firestore.visEvent;
    },
    pacienteAgendaAll() {
      return this.$store.state.firestore.pacienteAgendaAll;
    },
  },
  // firestore() {
  //   return {
  //     events: firebase
  //       .firestore()
  //       .collection("users")
  //       .doc(sessionStorage.getItem("current-user"))
  //       .collection("agenda"),
  //   };
  // },
  methods: {
    visualizar(event) {
      this.$store.dispatch("firestore/visEvent", event);
      this.visualizarDialog = true;
    },
    readableStart(startStr) {
      var diasPtBr = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];
      var date = new Date(startStr);
      var dayName = diasPtBr[date.getDay()];
      var day = date.getDate();
      var dayStr;
      if (day < 10) {
        dayStr = "0" + day.toString();
      } else {
        dayStr = day.toString();
      }
      var month = date.getMonth() + 1;
      var monthStr;
      if (month < 10) {
        monthStr = "0" + month.toString();
      } else {
        monthStr = month.toString();
      }
      var year = date.getYear() - 100;
      var yearStr = year.toString();
      var hoursStr = date.getHours().toString();
      var min = date.getMinutes();
      var minStr;
      if (min < 10) {
        minStr = "0" + min.toString();
      } else {
        minStr = min.toString();
      }

      var str =
        dayName +
        ", " +
        dayStr +
        "/" +
        monthStr +
        "/" +
        yearStr +
        ", " +
        hoursStr +
        ":" +
        minStr;

      return str;
    },
    readableEnd(endStr) {
      var date = new Date(endStr);
      var hoursStr = date.getHours().toString();
      var min = date.getMinutes();
      var minStr;
      if (min < 10) {
        minStr = "0" + min.toString();
      } else {
        minStr = min.toString();
      }

      var str = hoursStr + ":" + minStr;

      return str;
    },
  },
  mounted() {
    this.$store.dispatch("firestore/getAgendaAll", {
      pacienteEmail: sessionStorage.getItem("current-user"),
    });
    setTimeout(() => {
      function compare(a, b) {
        if (a.start < b.start) {
          return -1;
        }
        if (a.start > b.start) {
          return 1;
        }
        return 0;
      }
      this.events = this.pacienteAgendaAll.sort(compare);
      console.log(this.events[0])
    }, 1500);
  },
};
</script>