<template>
  <div>
    <v-dialog v-model="verCursoDialog" width="600">
      <v-card width="100%">
        <v-card-title class="d-flex justify-center"
          >Assistir curso "{{ cursoToWatch.order }}.
          {{ cursoToWatch.nome }} "</v-card-title
        >
        <v-card-text v-if="loadingAulas">
          <v-row class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#614021"
              indeterminate
            ></v-progress-circular
          ></v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row
            class="d-flex justify-center align-center"
            v-for="aula in aulas"
            :key="aula.id"
          >
            <v-card width="95%" outlined>
              <v-row class="d-flex align-center" style="width: 100%">
                <v-col cols="6">
                  {{ aula.order }}. <strong>{{ aula.nome }}</strong>
                </v-col>
                <v-col cols="4">
                  <v-progress-linear
                    v-model="aula.progress"
                    :color="colorizeProgress(aula.progress)"
                    height="16"
                    class="mx-1"
                  >
                    <template v-slot:default="{ value }">
                      <strong>{{ Math.floor(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </v-col>
                <v-col cols="2" class="d-flex justify-center">
                  <v-tooltip bottom color="#c5d13f">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="#c5d13f"
                        @click="viewVideo(aula)"
                      >
                        <v-icon color="#c5d13f">
                          mdi-play-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Assistir "{{ aula.order }}. {{ aula.nome }}"</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewVideoDialog" persistent 
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card v-if="aulaToWatch.nome !== ''">
        <v-toolbar dark color="#614021">
          <v-btn icon dark @click="closeViewVideo(aulaToWatch)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ aulaToWatch.nome }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="closeViewVideo(aulaToWatch)"> Fechar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- <v-card-title>{{ aulaToWatch.nome }}</v-card-title> -->
        <!-- <v-card-subtitle>{{ aulaToWatch.text }}</v-card-subtitle> -->
        <v-card-text class="d-flex justify-center">
          <!-- <v-row class="d-flex justify-center my-2"> -->
            <v-card width="90%" elevation="4" class="d-flex justify-center">
              <!-- <vue-plyr
                :emit="['ready', 'ended', 'pause']"
                ref="plyr"
                @ready="playerReady(aulaToWatch)"
                @ended="setProgress(aulaToWatch)"
                @pause="setProgress(aulaToWatch)"
                ><div
                  data-plyr-provider="youtube"
                  :data-plyr-embed-id="aulaToWatch.link"
                ></div>
              </vue-plyr> -->
              <vue-plyr
                :emit="['ready', 'ended', 'pause']"
                @ready="playerReady(aulaToWatch)"
                @ended="setProgress(aulaToWatch)"
                @pause="setProgress(aulaToWatch)"
                ref="plyr"
                style="width: 100%"
              >
                <div class="plyr__video-embed">
                  <iframe
                    :src="aulaToWatch.link"
                    frameborder="0" 
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; modest-branding" 
                    allowfullscreen
                    allowtransparency
                  ></iframe>
                </div>
              </vue-plyr>

              <!-- <iframe id="player" type="text/html" width="680" height="500"
                :src="makeLink(aulaToWatch.link)"
                frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; modest-branding" allowfullscreen></iframe> -->
            </v-card>
          <!-- </v-row> -->
        </v-card-text>
        <v-card-subtitle class="d-flex justify-center">{{ aulaToWatch.text }}</v-card-subtitle>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="#c5d13f" @click="closeViewVideo(aulaToWatch)"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card width="100%">
      <v-card-title class="d-flex justify-center">
        Cursos do software
      </v-card-title>
      <v-card-text v-if="loadingCursos">
        <v-row class="d-flex justify-center">
          <v-progress-circular
            :size="50"
            :width="5"
            color="#614021"
            indeterminate
          ></v-progress-circular
        ></v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row
          v-for="curso in cursos"
          :key="curso.order"
          class="d-flex justify-center align-center"
        >
          <v-card outlined width="90%">
            <v-row class="d-flex align-center">
              <v-col cols="6" class="d-flex align-center">
                {{ curso.order }}. <strong>{{ curso.nome }}</strong>
              </v-col>
              <v-col cols="4">
                <v-progress-linear
                  v-model="curso.progress"
                  :color="colorizeProgress(curso.progress)"
                  height="16"
                  class="mx-1"
                >
                  <template v-slot:default="{ value }">
                    <strong>{{ Math.floor(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
              <v-col cols="2" class="d-flex justify-center align-center">
                <v-tooltip bottom color="#c5d13f">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="#c5d13f"
                      @click="verCurso(curso)"
                    >
                      <v-icon color="#c5d13f">
                        mdi-play-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Assistir "{{ curso.order }}. {{ curso.nome }}"</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  data() {
    return {
      papeis: [
        "Nutricionista 1",
        "Nutricionista 2",
        "Nutricionista 3",
        "Recepcionista",
        "Esteticista",
        "Administradora",
        "Proprietária",
        "Estagiária",
      ],
      cursos: [{ nome: null, order: null }],
      cursoToWatch: { nome: null, order: null, id: null },
      viewVideoLink: "",
      viewVideoDialog: false,
      verCursoDialog: false,
      aulaToWatch: {
        order: null,
        nome: "",
        link: "",
        text: null,
        authAdmin: null,
        authNutri1: null,
        authNutri2: null,
        authRecep: null,
        authEstet: null,
        authEstag: null,
        progress: null,
        duration: null,
      },
      loadingCursos: false,
      loadingAulas: false,
      aulas: [
        {
          order: null,
          nome: null,
          link: null,
          text: null,
          authAdmin: null,
          authNutri1: null,
          authNutri2: null,
          authRecep: null,
          authEstet: null,
          authEstag: null,
        },
      ],
    };
  },
  methods: {
    // makeLink(link) {
    //   return "http://www.youtube.com/embed/" + link + "?autoplay=0&showinfo=0&rel=0";
    // },
    playerReady(aula) {
      setTimeout(() => {
        // console.log(this.player)
        this.player.currentTime = Math.floor(
          (aula.progress / 100) * this.player.duration
        );
        console.log("ready: aula.progress", this.player.currentTime);
      }, 1000);
    },
    async setProgress(aula) {
      console.log("this.player.duration", this.player.duration);
      var newAulaProgress = Math.round(
        (this.player.currentTime / this.player.duration) * 100
      );
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("funcionarios")
        .doc(this.funcionarioOnline.id)
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(this.cursoToWatch.id)
        .collection("aulas")
        .doc(aula.id)
        .set({
          progress: newAulaProgress,
        });
      var aulasProgresses = await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("funcionarios")
        .doc(this.funcionarioOnline.id)
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(this.cursoToWatch.id)
        .collection("aulas")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            return doc.data().progress;
          })
        );

      console.log("aulasProgresses", aulasProgresses);

      // var aulasProgresses = this.aulas.map(function (a) {
      //     return a.progress
      // })

      var sumAulasProgresses = Math.round(0);
      aulasProgresses.forEach(function(p) {
        sumAulasProgresses += Math.round(parseFloat(p));
      });

      var newCursoProgress = Math.floor(sumAulasProgresses / this.aulas.length);
      // console.log("this.aulas.length", this.aulas.length)
      await firebase
        .firestore()
        .collection("users")
        .doc(sessionStorage.getItem("current-user"))
        .collection("funcionarios")
        .doc(this.funcionarioOnline.id)
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(this.cursoToWatch.id)
        .set({
          progress: newCursoProgress,
        });

      this.getAulas();
      this.getCursos();
    },
    verCurso(curso) {
      this.cursoToWatch = curso;
      this.getAulas();
      this.verCursoDialog = true;
    },
    colorizeProgress(perc) {
      if (perc < 25) {
        return "red";
      } else if (perc >= 25 && perc < 50) {
        return "orange";
      } else if (perc >= 50 && perc < 75) {
        return "yellow";
      } else if (perc >= 75 && perc < 100) {
        return "green";
      } else {
        return "grey";
      }
    },
    // assistir(obj) {
    //   var raw = `https://www.youtube.com/embed/${obj.yt}?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`
    //   this.assistirObj = obj;
    //   this.assistirObj.link = raw;
    //   console.log(this.assistirObj.link)

    //   this.assistirDialog = true;
    // }, 
    viewVideo(aula) {
      var raw = `https://www.youtube.com/embed/${aula.link}?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`
      aula.link = raw;
      this.aulaToWatch = aula;
      // console.log("aulaToWatch.link", this.aulaToWatch.link);
      this.viewVideoDialog = true;
      this.playerReady(aula);
    },
    closeViewVideo(aula) {
      this.setProgress(aula);
      this.aulaToWatch = 
        {
          order: null,
          nome: "",
          link: "",
          text: null,
          authAdmin: null,
          authNutri1: null,
          authNutri2: null,
          authRecep: null,
          authEstet: null,
          authEstag: null,
        };
      this.viewVideoDialog = false;
      this.player.currentTime = 0;
    },
    async getCursos() {
      this.loadingCursos = true;
      var funcOnlineId = this.funcionarioOnline.id;
      var getUserProgress = async function(id) {
        var progress = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("funcionarios")
          .doc(funcOnlineId)
          .collection("treinamentos")
          .doc("software")
          .collection("cursos")
          .doc(id)
          .get()
          .then(function(doc) {
            if (doc.data() !== undefined) {
              return doc.data().progress;
            } else {
              return 0;
            }
          });
        if (progress !== undefined) {
          return Math.floor(parseFloat(progress));
        } else {
          return 0;
        }
      };
      var cursosGot = await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      for (var i = 0; i < cursosGot.length; i++) {
        cursosGot[i].progress = await getUserProgress(cursosGot[i].id);
      }

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }

      var cursosSorted = cursosGot.sort(compare);

      this.cursos = cursosSorted;
      this.loadingCursos = false;
    },
    async getAulas() {
      this.loadingAulas = true;
      console.log(this.cursoToWatch.id);
      var cId = this.cursoToWatch.id;
      var funcOnlineObj = this.funcionarioOnline;
      var getUserProgress = async function(id) {
        var progress = await firebase
          .firestore()
          .collection("users")
          .doc(sessionStorage.getItem("current-user"))
          .collection("funcionarios")
          .doc(funcOnlineObj.id)
          .collection("treinamentos")
          .doc("software")
          .collection("cursos")
          .doc(cId)
          .collection("aulas")
          .doc(id)
          .get()
          .then(function(doc) {
            if (doc.data() !== undefined) {
              return doc.data().progress;
            } else {
              return 0;
            }
          });
        if (progress !== undefined) {
          return Math.floor(parseFloat(progress));
        } else {
          return 0;
        }
      };

      var aulasGot = await firebase
        .firestore()
        .collection("treinamentos")
        .doc("software")
        .collection("cursos")
        .doc(this.cursoToWatch.id)
        .collection("aulas")
        .get()
        .then((snapshot) =>
          snapshot.docs.map((doc) => {
            let data = doc.data();
            data.id = doc.id;
            return data;
          })
        );

      for (var i = 0; i < aulasGot.length; i++) {
        aulasGot[i].progress = await getUserProgress(aulasGot[i].id);
      }

      function compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }
      var funcOnline = this.funcionarioOnline.papel;
      var aulasFiltered = aulasGot.filter(function(a) {
        if (funcOnline == "Administradora" || funcOnline == "Proprietária") {
          return a.authAdmin;
        } else if (funcOnline == "Nutricionista 1") {
          return a.authNutri1;
        } else if (
          funcOnline == "Nutricionista 2" ||
          funcOnline == "Nutricionista 3"
        ) {
          return a.authNutri2;
        } else if (funcOnline == "Recepcionista") {
          return a.authRecep;
        } else if (funcOnline == "Esteticista") {
          return a.authEstet;
        } else if (funcOnline == "Estagiária") {
          return a.authEstag;
        }
      });
      var aulasSorted = aulasFiltered.sort(compare);

      this.aulas = aulasSorted;
      this.loadingAulas = false;
    },
  },
  computed: {
    player() {
      return this.$refs.plyr.player;
    },
    funcionarioOnline() {
      return this.$store.state.firestore.funcionarioOnline;
    },
  },
  mounted() {
    this.getCursos();
  },
};
</script>
