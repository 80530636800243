<template>
  <div>
    <v-dialog v-model="dialogVisualizar" max-width="80%" height="80%" multiple>
      <v-card>
        <v-card-title class="text-p black--text">
          {{ receitaVis.titulo }} ({{ receitaVis.rendimento }}
          {{ porcao(receitaVis.rendimento) }})
        </v-card-title>
        <v-card-text class="ml-6">
          <v-row> <strong> Modo de Preparo: </strong> </v-row>
          <v-row> {{ receitaVis.modoPreparo }} </v-row>
          <v-row class="mt-1">
            <strong> Ingredientes: </strong>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Alimento</th>
                  <th class="text-left">Quantidade</th>
                  <th class="text-left">Medida caseira</th>
                  <th class="text-left">Gramas totais</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="alimentoR in receitaVis.alimentosList"
                  :key="alimentoR.nome"
                >
                  <td>{{ alimentoR.nome }}</td>
                  <td>{{ alimentoR.quantidade }}</td>
                  <td>{{ alimentoR.medida }}</td>
                  <td>{{ alimentoR.gramas }}g</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card elevation="5">
      <v-card-title class="text-h6">
        <v-row class="d-flex justify-center my-2">Receitas </v-row>
        <v-row class="d-flex justify-center mx-4">
          <v-chip
            v-for="letter in letters"
            :key="letter"
            @click="filterLetter(letter)"
          >
            {{ letter }}
          </v-chip>
        </v-row></v-card-title
      >
      <v-card-text v-if="receitasLetter.length > 0">
        <v-card
          v-for="(receita, receitaIndex) in receitasLetter"
          :key="receitaIndex"
          outlined
        >
          <v-row class="d-flex justify-center">
            <v-col cols="12" class="d-flex justify-space-between">
              <v-row class="text-subtitle-1 ml-2 d-flex align-center">
                {{ receita.data.titulo }}
              </v-row>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#614021"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 mx-2 black--text"
                    icon
                    @click="visualizar(receita.data)"
                  >
                    <v-icon color="#614021">{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Receita</span>
              </v-tooltip>

              <v-btn
                color="#614021"
                class="mr-2 mx-2 black--text"
                @click="editarReceita(receita.value)"
                text
              >
                <v-icon color="#c5d13f" left>{{
                  icons.mdiPlaylistEdit
                }}</v-icon>
                Editar
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 mx-2 black--text"
                    icon
                    @click="deleteReceita(receita.value)"
                  >
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>Deletar receita</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <v-progress-circular
          :size="50"
          :width="5"
          color="#614021"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiPlaylistEdit, mdiEye, mdiTrashCanOutline } from "@mdi/js";
import router from "@/router";

export default {
  data: function () {
    return {
      dialogVisualizar: false,
      panelReceitas: [],
      icons: {
        mdiPlaylistEdit,
        mdiEye,
        mdiTrashCanOutline,
      },
      receitasLetter: [],
      receita: {
        id: "",
      },
      chosenLetter: null,
      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "outros",
      ],
    };
  },
  computed: {
    receitas() {
      // var alphabet = function (objs) {
      //   function compare(a, b) {
      //     if (a.data.titulo < b.data.titulo) {
      //       return -1;
      //     }
      //     if (a.data.titulo > b.data.titulo) {
      //       return 1;
      //     }
      //     return 0;
      //   }

      //   return objs.sort(compare);
      // };
      return this.$store.state.firestore.receitas;
      // return alphabet(this.$store.state.firestore.receitas);
    },
    receitaVis() {
      return this.$store.state.firestore.receitaVis;
    },
  },
  methods: {
    filterLetter(letter) {
      this.chosenLetter = letter;
      if (letter !== "outros") {
        var receitasLetter = this.receitas.filter(function (el) {
          if (el.text.length > 0) {
            return el.text.toUpperCase().substring(0, 1) == letter;
          } else {
            return false;
          }
        });
        this.receitasLetter = receitasLetter;
      } else {
        var receitasNoLetter = this.receitas.filter(function (el) {
          return el.text.length < 1;
        });

        this.receitasLetter = receitasNoLetter;
      }
    },
    visualizar(rec) {
      console.log(rec);
      this.$store.dispatch("firestore/makeReceitaVis", { rec });
      this.dialogVisualizar = true;
    },
    porcao(nr) {
      if (nr > 1) {
        return "porções";
      } else {
        return "porção";
      }
    },
    editarReceita(id) {
      var receita = this.receitas.find((item) => item.value == id);
      if (receita !== undefined) {
        this.$store.dispatch("firestore/receitaId", {
          receitaId: id,
        });
        this.$store.dispatch("firestore/receitaUpdate", {
          receita: receita.data,
        });
        router.replace("/AdminEditarReceita");
      }
    },
    deleteReceita(id) {
      console.log(id);
      this.$store.dispatch("firestore/receitaDelete", {
        receita: id,
      });
      this.$store.dispatch("firestore/getReceitas");
      if (this.chosenLetter !== null) {
        this.filterLetter(this.chosenLetter);
      }
    },
  },
  mounted() {
    // if (this.$store.state.firestore.receitas.length === 0) {
    this.$store.dispatch("firestore/getReceitas");
    // }
    this.chosenLetter = "A";
    this.filterLetter(this.chosenLetter);
  },
};
</script>